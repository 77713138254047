export interface IReceipt {
    receiptId?: number;
    receiptDate?: string;
    referenceNo?: string;
    toAccountId?: number;
    toAccountName?: string;
    creditAmount?: number;
    chequeNo?: string;
    creditDate?: string;
    creditBankName?: string;
    creditNarration?: string;
    debitAccountId?: number;
    debitAccountName?: string;
    debitAmount?: string;
    debitNarration?: string;
    receiptDebits?: IDebitAmount[];
    receiptCredits?: ICreditAmount[];
    employeeId?: number;
}
export interface ICreditAmount {
    toAccountName?: string;
    creditAccountId?: number;
    creditAmount?: number;
    chequeNo?: string;
    creditDate?: string;
    creditBankName?: string;
    creditNarration?: string;
}
export interface IDebitAmount {
    debitAccountId?: number;
    debitAccountName?: string;
    debitAmount?: number;
    debitNarration?: string;
}
export interface ILedgerRequest {
    flag?: string;
    type?: string;
}

export class IJournalSave {
    journaltId?: number = 0;
    employeeId?: number = 0;
    journalDate?: string = new Date().toISOString().substr(0, 10);
    journalNo?: string = "";
    referenceNo?: string = "";
    journalDebit?: IJournalDebit[];
    journalCredit?: IJournalCredit[];
}

export class IJournalDebit {
    debitAmount?: number = 0;
    debitNarration?: string = "";
    debitAccountId?: number = 0;
    debitAccountName?: string = "";
    dbtype?: string = "";
}

export class IJournalCredit {
    creditAccountName?: string = "";
    creditAmount?: number = 0;
    chequeNo?: string = "";
    creditDate?: string = new Date().toISOString().substr(0, 10);
    creditBankName?: string = "";
    creditNarration?: string = "";
    creditAccountId?: number = 0;
    cbtype?: string = "";
}