export class IInterviewProcess {
    intprocessid?: number = 0
    jobcode?: number = 0
    interviewdate?: string = new Date()?.toISOString()?.substr(0, 10)
    callletterno?: number = 0
    empid?: number = 0
    feedback?: string = ""
    interviewProcessdetails?: IInterviewProcessDetials[] = [];
}

export class IInterviewProcessDetials {
    intprocessid?: number = 0
    phaseno?: string = ""
    process?: string = ""
    feedback?: string = ""
    nextPhases?: string = ""
    conductby?: string = ""
}