import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';


const InwardOutletDetail = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState(true);
  const { inwardOultetStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getInwardOutlet, getInwardOutletDetails, inwardOutltes, inwardOutlteDetails } = inwardOultetStore;
  const [isInwardOutletDetails, setInwardOutletDetailsModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteInwardOutlet = async (inwardOutletId) => {
    setLoading(true);
    const status = await inwardOultetStore.deleteInwardOutletMaster(inwardOutletId);

    if (status === 'Success') {
      getInwardOutltes();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    getInwardOutltes();
  }

  async function getInwardOutltes() {
    const userRights = await userCreationStore?.getUserRight('Inward_outlet')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await getInwardOutlet();
    setLoading(false);
  }

  async function handleInwardOutletDetails(inwardOutletId, e) {
    e.preventDefault();

    await getInwardOutletDetails(inwardOutletId);
    setInwardOutletDetailsModal(true);
  }
  const handleClose = () => {
    setInwardOutletDetailsModal(false)
  }

  const handleCreate = () => {
    navigate('/Admin/InwardOutlet');
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      getInwardOutltes();
      isCurrenPage.current = false
    }
    return () => { }
  }, [])


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (inwardOutltes && inwardOutltes?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(inwardOutltes)
    }
  }, [inwardOutltes])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(inwardOutltes?.slice().sort((a, b) => b.inwardOutletId - a.inwardOutletId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>

              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={inwardOutltes}
                searchTitles={[{ key: "issuedByName", value: "Issued By" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{inwardOutltes?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>

              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Outward To OutletNo </th>
                      <th scope='col'>Outlet Name</th>
                      {/* <th scope='col'>Warehouse Qty </th> */}
                      <th scope='col'>Issued By</th>
                      <th scope='col'>Received By</th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      ((isSearch ? filteredData : currentPageData))?.map((val, key) => {
                        return (
                          <tr key={key}>

                            <td>{val.inwardOutletId} </td>
                            <td>{moment(val.inwardOutletDate).format('DD-MMM-YYYY')}  </td>
                            <td>{val.outwardOutletNo} </td>
                            <td>{val.outletname} </td>
                            {/* <td>{val.warehouseStock}</td> */}
                            <td>{val.issuedByName}</td>
                            <td>{val.receivedByName}</td>
                            <td><button disabled={!userRights?.view} onClick={(e) => handleInwardOutletDetails(val.inwardOutletId, e)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInwardOutlet(val.inwardOutletId)} /></button></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>

              {isInwardOutletDetails ?
                <Modal show={isInwardOutletDetails} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Item Name</th>
                            <th scope="col">Batch No</th>
                            <th scope="col">Qty</th>
                            {/* <th scope="col">Delete</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            inwardOutlteDetails.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.inwardOutletItemName}</td>
                                  <td>{val.inwardOutletBatchNo}</td>
                                  <td>{val.inwardOutletquantity}</td>
                                  {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={()=>deleteInwardOutletDetail(val.inwardOutletDetailId)}/></button></td> */}
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
              }

            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>

  );

});

export default InwardOutletDetail;
