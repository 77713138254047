import { IIAppoinmentJobDet, IIAppoinmentOtherDoc, IIAppoinmentProcess, IIAppoinmentSalaryDet } from "../model";

export function addSalaryValidation(appoinmentSalaryDet: IIAppoinmentSalaryDet) {
    let error: any = {};
    if (!appoinmentSalaryDet?.asD_AppoinAmt) {
        error.asD_AppoinAmt = 'Enter Amount'
    }
    if (!appoinmentSalaryDet?.asD_AppoinDescId) {
        error.asD_AppoinDescId = 'Select Description'
    }
    if (!appoinmentSalaryDet?.asD_AppoinSalType) {
        error.asD_AppoinSalType = 'Select Salary Type'
    }
    return error
}

export function addOtherDocValidation(appoinDocDet: IIAppoinmentOtherDoc) {
    let error: any = {};
    if (!appoinDocDet?.aoD_AppoinDocName) {
        error.aoD_AppoinDocName = 'Enter Document Name'
    }
    if (!appoinDocDet?.aoD_AppoinDocType) {
        error.aoD_AppoinDocType = 'Select Document Type'
    }
    if (!appoinDocDet?.aoD_AppoinOtherDoc) {
        error.aoD_AppoinOtherDoc = 'Select Image'
    }
    return error
}

export function addJobValidation(appionJobDet: IIAppoinmentJobDet) {
    let error: any = {};
    if (!appionJobDet?.ajD_AppoinJobDuty) {
        error.ajD_AppoinJobDuty = 'Enter Job Duty'
    }
    if (!appionJobDet?.ajD_AppoinDesign) {
        error.ajD_AppoinDesign = 'Enter Job Designation'
    }
    return error
}

export function saveValidation(appionmentProcess: IIAppoinmentProcess) {
    let error: any = {};
    if (!appionmentProcess?.appoinBioID) {
        error.appoinBioID = 'Enter Biometric Id'
    }
    if (!appionmentProcess?.appoinEmpId) {
        error.appoinEmpId = 'Select Generated By'
    } 
    if (!appionmentProcess?.appoinOfferId) {
        error.appoinOfferId = 'Select Offer Letter No.'
    } 
    if (!appionmentProcess?.appoinJobLoc) {
        error.appoinJobLoc = 'Enter Job Location'
    } 
    if (!appionmentProcess?.appoinCompName) {
        error.appoinCompName = 'Enter Company Name'
    } 
    if (!appionmentProcess?.appoinTime) {
        error.appoinTime = 'Select Time'
    } 
    if (!appionmentProcess?.appoinmentsalarydet?.length) {
        error.appoinmentsalarydet = 'Fill Salary Details'
    } 
    if (!appionmentProcess?.appoinmentjobdet?.length) {
        error.appoinmentjobdet = 'Fill Job Detials'
    } 
    return error;
}