import { makeAutoObservable } from 'mobx';
import apiAgent from '../../../APIAgent';

class StockSummaryStore {

    constructor() {
        makeAutoObservable(this);
    }

    getWarehouseStockSummary = async (stockSummaryBody) => {
        try {
            const stockSummaryDetails = await apiAgent.stockSummary.getWarehouseStockSummary(stockSummaryBody);
            return JSON.parse(stockSummaryDetails);
        } catch (error) {
            console.log(error)
        }
    }

    getWarehouseStockSummaryBatchwise = async (stockSummaryBody) => {
        try {
            const stockSummaryDetails = await apiAgent.stockSummary.getWarehouseStockSummaryBatchwise(stockSummaryBody);
            return JSON.parse(stockSummaryDetails);
        } catch (error) {
            console.log(error)
        }
    }

    // importExportFormSave
    importExportFormSave = async (supplierMasters) => {
        try {
            const status = await apiAgent.stockSummary.importExportFormSave(supplierMasters);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default StockSummaryStore;