import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const MDApprovalEntryDetail = observer((): JSX.Element => {
  const { brandStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/MDApprovalEntry')
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  async function fetchPreLoadngData() {
    const userRights = await userCreationStore?.getUserRight('MD_approval_entry')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    // await brandStore.getBrand();
    // setLoading(false)
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={brandStore?.brandList}
                searchTitles={[{ key: "Date", value: "date" }, { key: "employee", value: "Employee" }, { key: "outDate", value: "Out Date" }, { key: "outTime", value: "Out Time" }, { key: "inDate", value: "in Date" }, { key: "inTime", value: "In Time" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Date</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">Leave Type</th>
                      <th scope="col">In Time</th>
                      <th scope="col">Out Time</th>
                      <th scope="col">Break Time</th>
                      <th scope="col">Worked Time</th>
                      <th scope="col">Total Time</th>
                      <th scope="col">OT Hours</th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {!brandStore.isLoading &&
                      ((isSearch ? filteredData : brandStore?.brandList)?.slice().sort((a, b) => b.brandId - a.brandId))?.map((val, key) => {
                        return ( */}
                    <tr>
                      <td>{'1'}</td>
                      <td>{'12-Mar-2023'}</td>
                      <td>{'Employee'}</td>
                      <td>{'Present'}</td>
                      <td>{''}</td>
                      <td>{'6:00 PM'}</td>
                      <td>{'7:00 PM'}</td>
                      <td>{'1:00 PM'}</td>
                      <td>{'6:00 PM'}</td>
                      <td>{'7:00 PM'}</td>
                      <td>{'1:00 PM'}</td>
                      <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                      <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                      <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                    </tr>
                    {/* )
                      }
                      )} */}
                  </tbody>
                </table>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>

  )
})

export default MDApprovalEntryDetail;