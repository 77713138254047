
function OutletStockSummaryValidation(stockSummary) {
    let errors: any = {};

    if (!stockSummary.toDate) {
        errors.toDate = "Enter To Date";
    }
    return errors;
};

export default OutletStockSummaryValidation;