import React from 'react';
import logo from './logo.svg';
import {useNavigate} from "react-router-dom"
import {Button,Container, Form, Image, InputGroup} from 'react-bootstrap';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  const navigate = useNavigate();
  return (
    <div className='footer'>          
      <div className='NewsletteSignup'>
        <Container fluid>
          <div className='row'>
            <div className='col-sm-7'>
              <h4>Newsletter Signup</h4>
              <p>Sign up to Our Newsletter & get attractive Offers by subscribing to our newsletters.</p>
            </div>
            <div className='col-sm-4 footersearch'>
              <InputGroup className="">
                <Form.Control
                  placeholder="Subscribe to "
                  aria-label="Subscribe to Newsletter"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2">
                  Subscribe
                </Button>
              </InputGroup>
            </div>
          </div>
        </Container>
      </div>
      <Container fluid>
      <div className='vertical-space-10'></div>
        <div className='row'>
          <div className='col-sm-5'>
            <h4>ABOUT US</h4>
            <p>Established/ Founded in 2015 as PLANET ENTERPRISES and had been involved with FMCG. Especially Indian Devotional items. We started with manufacturing / Trading the 100% Natural and traditional materials. Founded in 2020, GANGA SUPERMARKET - Grocery Store (Offline & Online Store) and started the Ganga Supermarket second outlet at Namakkal (Offline Store) in 2022. Planet Enterprises had been involved with technical activities of all Electrical Equipment's and Technical Field Service of AC High Voltage Machines (Motors & Generators). In recent years, we had also successfully qualified for the ABB AB Machines, Sweden and had been involved with the Technical Field Service of AC High Voltage Motors & Generators.</p>
          </div>
          <div className='col-sm-7'>
            <div className='row'>
              <div className='col-sm-4'>
                <h4>CONTACT US</h4>
                <p><b>Warehouse:</b> 4/426 HA Ganga Nagar, Thuraiyur Road, Namakkal-637002</p>
                <p><b>Outlet:</b> 3/824-A, Near Ponni gas Godown, Thuraiyur Road, Namakkal-637002</p>
                <p><b>91-9043426746 </b></p>
                <p><b>customer.care@gangasupermarket.com</b></p>
              </div>   
              <div className='col-sm-4'>
                <h4>TESTIMONIALS</h4>
                <a>Reviews in Google Business Profile</a>
              </div>
              <div className='col-sm-4'>
                <h4>OTHER LINKS</h4>
                <ul className='list-unstyled'>
                  <li>About Us</li>
                  <li>Privacy Policy</li>
                  <li>Return Policy</li>
                  <li>Shipping & Delivery Policy</li>
                  <li>Users Terms & Conditions</li>
                  <li>Contact Us</li>
                  <li>Site Map</li>
                  <li>login</li>
                </ul>
              </div> 
            </div>
          </div>       
        </div>            
        <div className='vertical-space-10'></div>
        <div className='bottomFooter'>
          <ul className='list-unstyled'>
            <li><Image src={require("../../gsmecom/images/paypal.png")} /></li>
            <li><Image src={require("../../gsmecom/images/visa.png")} /></li>
            <li><Image src={require("../../gsmecom/images/card.png")} /></li>
          </ul>
          <p>Our Products Never Lie <br/>Ganga Supermarket © 2023</p>
        </div>
      </Container>
    </div> 
  );
}

export default Footer;
