import { faCartShopping } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Container, Image, Modal } from "react-bootstrap"
import StarIcon from '@mui/icons-material/Star';

import '../../../Ecom.css';
import '../categories/productByCategory.css'

interface QuickViewProps {
    modalItem: any,
    setModalItem: (item: any) => void,
    addToCart: (item: any) => void,
}

const QuickView: React.FC<QuickViewProps> = ({ modalItem, setModalItem, addToCart }) => {
    return (
        <Modal show={modalItem?.posItemId !== undefined} onHide={() => setModalItem({})} className='PriceHistoryModel flexImp'>
            <Modal.Body>
                <Container>
                    <div className='product-id-detail' style={{ marginTop: '0px' }}>
                        <div className="quick-view">
                            <div className='quick-imgs'>
                                <div className="img-box selected">
                                    <Image src={`data:image/png;base64,${modalItem?.imageData}`} width={90} height={90} />
                                </div>
                            </div>
                        </div>
                        <div className='left-body' >
                            <div className="image-container" >
                                <Image src={`data:image/png;base64,${modalItem?.imageData}`} width={90} height={90} className='view-box' />
                            </div>
                            <div className="action-bars">
                                <div className="btn">
                                    <button style={{ color: 'white', gap: '16px' }} onClick={() => addToCart(modalItem)} >
                                        Add to Cart <FontAwesomeIcon icon={faCartShopping} />
                                    </button>
                                    <button className="invert" style={{ background: 'white', }}>
                                        Buy Now
                                    </button>
                                </div>
                            </div>
                            <div className="action-bars quick-view ">
                                <div className="similar-prod">
                                    5 Similar Products
                                </div>
                                <div className="quick-imgs flex gap-2">
                                    <div className="img-box selected">
                                        <Image src={require("../../../../gsmecom/images/product.jpg")} width={90} height={90} />
                                    </div>
                                    <div className="img-box">
                                        <Image src={require("../../../../gsmecom/images/product.jpg")} width={90} height={90} />
                                    </div>
                                    <div className="img-box">
                                        <Image src={require("../../../../gsmecom/images/product.jpg")} width={90} height={90} />
                                    </div>
                                    <div className="img-box">
                                        <Image src={require("../../../../gsmecom/images/product.jpg")} width={90} height={90} />
                                    </div>
                                    <div className="img-box">
                                        <Image src={require("../../../../gsmecom/images/product.jpg")} width={90} height={90} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="full-width right-body">
                            <div className="content-box">
                                <div className="title">
                                    {modalItem?.posItemName}
                                </div>
                                <div className="" style={{ paddingTop: '16px', display: 'flex' }}>
                                    <div className='purch-rate'>&#8377;
                                        {modalItem?.posItemSellingRate ?? 0}
                                        <span className='tax'>Inc Tax</span>
                                    </div>
                                    <div className="flex-center">
                                        <div className='flex-center qty-inpt' style={{ background: '#dc3545', }}
                                            onClick={() => {
                                                modalItem?.posItemQty! > 1 &&
                                                    setModalItem({ ...modalItem, posItemQty: (modalItem?.posItemQty! - 1) })
                                            }}
                                        >
                                            -
                                        </div>
                                        <input type='text' className='full-height' placeholder='Enter Qty...' value={modalItem?.posItemQty} style={{ fontSize: '16px', textAlign: 'center' }} />
                                        <div className='flex-center qty-inpt'
                                            onClick={() => {
                                                modalItem?.posItemQty < modalItem?.availableQty &&
                                                    setModalItem({ ...modalItem, posItemQty: (modalItem?.posItemQty! + 1) })
                                            }}
                                            style={{ background: '#0ac074' }}
                                        >
                                            +
                                        </div>
                                    </div>
                                </div>
                                <div className="" style={{ marginTop: '12px' }}>
                                    <div className="CartLayout ">
                                        <div className='RatingBg'>
                                            <span className=""></span> 3.7
                                            <StarIcon sx={{ display: 'inline-block', alignSelf: 'center', fontSize: '24px', paddingLeft: '4px' }} />
                                        </div>
                                        <div className='Reviews'>
                                            120382 Views
                                        </div>
                                    </div>
                                </div>
                                <div className="out-of-stock" style={{ visibility: !modalItem?.availableQty ? 'hidden' : 'visible', }}>
                                    Out of Stock*
                                </div>
                            </div>
                            <div className="content-box">
                                <div className="title mb-4">
                                    Select Size
                                </div>
                                <div className="flex">
                                    {['S', 'M', 'L', 'XL', 'XXL']?.map((size, index) => {
                                        return (
                                            <div key={index} className="size-container">
                                                {size}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="content-box">
                                <div className="title mb-4">
                                    Product Details
                                </div>
                                <div className="prod-det">
                                    <span className="">
                                        Name :
                                    </span>
                                    <span className="" style={{ marginLeft: '4px' }}>
                                        {modalItem?.posItemName}
                                    </span>
                                </div>
                                <div className="prod-det">
                                    <span className="">
                                        Brand :
                                    </span>
                                    <span className="" style={{ marginLeft: '4px' }}>
                                        {modalItem?.posItemName}
                                    </span>
                                </div>
                                <div className="prod-det">
                                    <span className="">
                                        Net Quantity :
                                    </span>
                                    <span className="" style={{ marginLeft: '4px' }}>
                                        {modalItem?.availableQty ?? 0}
                                    </span>
                                </div>
                                <div className="prod-det">
                                    <span className="">
                                        Net Weight :
                                    </span>
                                    <span className="" style={{ marginLeft: '4px' }}>
                                        {modalItem?.posItemName}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default QuickView;