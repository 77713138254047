
import {IStockAdjustmentModel } from '../model/index';
import apiAgent from '../../../APIAgent';
import { runInAction, observable } from 'mobx';

class StockAdjustmentStore {
    @observable stockAdjustmentWarehouseDetails = new Map();
    @observable stockAdjustmentWarehouse = new Map();

saveStockAdjustmentWarehouse=async (stockAdjustmentWarehouse:IStockAdjustmentModel)=> {
    try {
        debugger
      const status= await apiAgent.stockAdjustmentWarehouseData.saveStockAdjustmentWarehouse(stockAdjustmentWarehouse);
        return status;
    }
    catch (error) {
        console.log(error);
    }
}

getStockAdjustmentWarehouse = async (wid,id) => {
    try {
        const stockAdjustmentData = await apiAgent.stockAdjustmentWarehouseData.getStockAdjustmentWarehouse(wid,id);
        runInAction(() => {
            this.stockAdjustmentWarehouse = JSON.parse(stockAdjustmentData);
        })
        return JSON.parse(stockAdjustmentData);
    }
    catch (error) {
        console.log(error);
    }
}

deleteStockAdjustmentWarehouse = async (wid,id) => {
    try {      
    const status=  await apiAgent.stockAdjustmentWarehouseData.deleteStockAdjustmentWarehouse(wid,id);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}

}
export default StockAdjustmentStore;