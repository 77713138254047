import { action, set, makeObservable, observable, runInAction } from 'mobx';
import supplierMasterDetails, { IsupplierMaster } from '../model/index';
import apiAgent from '../../../APIAgent';
import { ISupplierMaster } from '../../../common/commonStore/model';

class supplierMasterStore {
    supplierMasterDetls = supplierMasterDetails;
    @observable supplierMasterList = new Map();
    isLoading = true;
    @observable supplierMasterLists = new Map<any, any>();
    @observable supplierDetls = new Map();
    @observable isEdit = false;
    @observable supplierStatus = new Map();

    constructor() {
        makeObservable(this)
    }

    saveSupplierMaster = async (supplierMaster: IsupplierMaster) => {
        try {
            const status = await apiAgent.supplierMasters.saveSupplier(supplierMaster);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    fetchRights = async (empid,formName) => {    
        try {
            const userScreenData = await apiAgent.userCreationMasters.getUserScreenRights(empid, formName);            
            let userRights = JSON.parse(userScreenData)             
            return userRights;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSupplierMaster = async () => {

        try {
            const supplierMasterData = await apiAgent.supplierMasters.getSupplierMaster();
            console.log(JSON.parse(supplierMasterData), 'supplierMasterData', supplierMasterData)
            this.supplierMasterList = JSON.parse(supplierMasterData);

            runInAction(() => {
                this.supplierMasterList = JSON.parse(supplierMasterData);
                this.isLoading = false;
            })
            return JSON.parse(supplierMasterData);
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteSupplier = async (supplierId) => {
        try {
            const status = await apiAgent.supplierMasters.deleteSupplier(supplierId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getSupplierSearch = async (SearchOptions) => {
        try {
            const supplierMasterData = await apiAgent.supplierMasters.getSupplierSearch(SearchOptions);
            this.supplierMasterList = supplierMasterData;

            runInAction(() => {
                this.supplierMasterList = supplierMasterData;

            })
            return this.supplierMasterList;


        }
        catch (error) {
            console.log(error);
        }
    }
    SupplierDetails = async (supplierId: Number) => {

        try {
            const supplierData = await apiAgent.supplierMasters.SupplierDetails(supplierId);

            runInAction(() => {
                this.supplierDetls = supplierData;

            });
            return this.supplierDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    updateSupplier = async (supplierMasters: ISupplierMaster) => {
        try {
            const status = await apiAgent.supplierMasters.updateSupplier(supplierMasters);
            runInAction(() => {
                this.supplierStatus = status;
            });

            return this.supplierStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    importExportFormSave = async (supplierMasters) => {
        try {
            const status = await apiAgent.supplierMasters.importExportFormSave(supplierMasters);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    @action setSupplierMaster = (state: IsupplierMaster) => {
        this.supplierMasterList.set(0, state);
    }
    @action setSupplierMasterList = (state: IsupplierMaster[]) => {
        set(state, this.supplierMasterList);
        return this.supplierMasterList;
    }
}
export default supplierMasterStore;