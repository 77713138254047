import { action } from 'mobx';
//import {numeric} from '../../../common/commonStore/const';
function assignOutletManagerValidation(OutletManagerDetails){
 
  
  let errors :any={};

  
  if(!OutletManagerDetails.date){
    errors.date = "select Date";
  }
  if(!OutletManagerDetails.outletName){
    errors.outletName = "select Outlet Name";
  }
  if(!OutletManagerDetails.employeeName){
    errors.employeeId = "select Manager";
  }
 
  return errors;
};
  
  export default assignOutletManagerValidation;