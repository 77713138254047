import { observable } from "mobx";
export interface IsubCategory {
    subCategoryId?: number;
    categoryName?: string;
    subCategoryName?: string;
    nobatch?: string;
    sudexo?: number;
}
export interface IsubCategorySearchoptions {
    searchOption?: string;
    searchValue?: string;
}

const subCategoryDetails = observable<IsubCategory>({
    subCategoryId: 0,
    categoryName: '',
    subCategoryName: '',


});

export default subCategoryDetails;