import { INewItemReqest, INewItemRequestDetail } from "../model";

export function SubmitValidation(obj: INewItemReqest) {
    let error: any = {}
    if (!obj?.reqdate) {
        error.reqdate = "Enter Date"
    }
    if (!obj?.outletid) {
        error.outletid = "Select Outlet"
    }
    if (!obj?.requiestby) {
        error.requiestby = "Select Employee"
    }
    if (!obj?.neweitemRequestDetails?.length) {
        error.neweitemRequestDetails = "Add Some Items to Grid"
    }
    return error;
}

export function AddValidation(obj: INewItemRequestDetail) {
    let error: any = {}
    if (!obj?.itemname) {
        error.itemname = "Enter Item Name"
    }
    if (!obj?.remarks) {
        error.remarks = "Enter Remarks"
    }
    return error
}