import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Modal, Image, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { IPOSItemRequest, IRetrunReplacement } from './model';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { IPOSProductDetails, } from '../../Admin/POS/model';
import AutoComplete from '../../common/shared/autoComplete';
import BarcodeBatches from '../../Admin/POS/common/batchDetail';
import replaceReturnValidation from './validation';


interface SalesReturnReplaceProps {
  fromPos?: boolean,
  emitOption?: Function;
}

const radio_props = [{ val: "Replacement", key: 'replacement' }, { val: "Return", key: 'return' }];
const posinvoiceradio_props = [{ val: "POS", key: 'pos' }, { val: "Invoice", key: 'invoice' }];
const replacementOptions = [{ val: "Same Item", key: 'sameitem' }, { val: "Other Item", key: 'otheritem' }];

const SalesReturnOrReplacement: React.FC<SalesReturnReplaceProps> = observer(({ fromPos, emitOption }): JSX.Element => {

  const { supplierMasterStore, salesReplaceRetrunStore, memberStore, posMasterStore } = useStores();

  console.log(memberStore?.entryNo?.entryNo, 'memberStore>>')

  const navigate = useNavigate();

  const [replacereturn, setReplaceReturn] = useState<IRetrunReplacement>(new IRetrunReplacement())
  const [posDetail, setPosDetail] = useState<any>({})
  const [option, setOption] = useState<any>({});

  const [isLoading, setLoading] = useState(true);
  const [isSpinner, setSpinner] = useState(false);
  const [errors, setErrors] = useState<any>({})
  const [isLoadModal, setLoadModal] = useState(false);
  const [isBarcodeBatches, setBarcodeBatches] = useState({ bool: false, batchArr: Array<any>() })
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 
 
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    navigate('/Admin/salesretrun/list');
  }

  const handleCancel = (e) => {
    navigate('/Admin/salesretrun/list');
  }

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setReplaceReturn({ ...replacereturn, [name]: value })
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    setOption({ ...option, [name]: value })
    if (value === 'replacement') {
      setReplaceReturn({ ...replacereturn, isReplacement: true, isReurn: false });
    } else if (value === 'return') {
      setReplaceReturn({ ...replacereturn, isReplacement: false, isReurn: true })
    }
  }

  const handleposInvoceRadioChange = (event) => {
    const { name, value } = event.target;
    setOption({ ...option, [name]: value })
    if (value === 'invoice') {
      setReplaceReturn({ ...replacereturn, isInvoice: true, isPos: false });
    } else if (value === 'pos') {
      setReplaceReturn({ ...replacereturn, isInvoice: false, isPos: true })
    }
  }

  const handlereplacementChange = (e) => {
    const { name, value } = e.target;
    setOption({ ...option, [name]: value })
    if (value === 'sameitem') {
      setReplaceReturn({ ...replacereturn, isSameItem: true, isOtherItem: false });
    } else if (value === 'otheritem') {
      setReplaceReturn({ ...replacereturn, isSameItem: false, isOtherItem: true })
    }
  }

  async function handleSearchPos() {
    setErrors({})
    if (replacereturn?.posId) {
      setSpinner(true);
      const itemsList = await salesReplaceRetrunStore.loadPOSItems(replacereturn?.posId);
      if (!itemsList?.length) {
        let error: any = {};
        error.posnone = "No items in this bill."
        setErrors(error)
      } else {
        setErrors({})
      }
      setSpinner(false);
    }
  }

  async function handleSearchInvoice() {
    setErrors({})
    if (replacereturn?.invoiceId) {
      setSpinner(true);
      const itemList = await salesReplaceRetrunStore.getInvoceItems(replacereturn.invoiceId);
      if (!itemList?.length) {
        let error: any = {};
        error.posnone = "No items in this bill."
        setErrors(error)
      } else {
        setErrors({})
      }
      setSpinner(false);
    }
  }

  const handlePosItem = async (name, event, val) => {
    if (val !== null) {
      setSpinner(true);
      let posItemRequest: any = {};
      posItemRequest.itemId = val.itemId;
      posItemRequest.posId = val?.posid
      posItemRequest.invoiceId = 0;
      setReplaceReturn({ ...replacereturn, itemId: val.itemId, posid: val?.posid });
      await salesReplaceRetrunStore.loadPOSItemBatch(posItemRequest);
      setSpinner(false);
    } else {
      replacereturn.itemName = "";
    }
  }

  const handleInvoiceItem = async (name, event, val) => {
    debugger
    if (val !== null) {
      setSpinner(true);
      let posItemRequest: IPOSItemRequest = {};
      posItemRequest.itemId = val.itemid;
      posItemRequest.invoiceId = val?.invoicemasterid;
      posItemRequest.posId = 0;
      setReplaceReturn({ ...replacereturn, itemId: val.itemid, invoiceId: val?.invoicemasterid });
      await salesReplaceRetrunStore.loadPOSItemBatch(posItemRequest);
      if (replacereturn.isReplacement) {
        await salesReplaceRetrunStore.loadReplacementItemBatch(val.itemId);
      }
      setSpinner(false);
    }
    else {
      replacereturn.itemName = "";
    }
  }

  const handlePosItemBatch = (name, event, val) => {

    if (name === "barcode" && val != null) {
      setReplaceReturn({ ...replacereturn, barcode: val.barcode });

    } else if (name === "batchNo" && val !== null) {
      setReplaceReturn({ ...replacereturn, batchId: val.batchId, batchNo: val.batchNo, returnAmount: val.repamount, returnQty: val.repqty });
    }
  }

  const handleOtherItem = async (name, event, val) => {

    if (replacereturn.isReplacement) {
      await salesReplaceRetrunStore.loadReplacementItemBatch(val.itemId);
    }
    getOtherItemDetails(val?.itemId)
  }

  function getOtherItemDetails(itemId) {
    let itemBatchList: any[] = []
    itemBatchList = posMasterStore?.loadPOSProducts?.filter((obj) => obj?.itemid === itemId)
    if (itemBatchList?.length > 1) {
      // open batch modal to choose batch details
      setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: itemBatchList })
    } else if (itemBatchList?.length === 1) {
      // add to cart function 
      // array has one obj send it to add to cart function
      let itemDetail: IPOSProductDetails = {
        posItemId: itemBatchList![0]?.itemid,
        posItemName: itemBatchList![0]?.itemname,
        posItemQty: 1,
        posItemMRP: Number(itemBatchList![0]?.Mrprate),
        posItemSellingRate: Number(itemBatchList![0]?.SellingRate),
        posTaxPercentage: itemBatchList![0]?.taxper,
        posDiscountPercentage: itemBatchList![0]?.Discount_Pv,
        posDiscountValue: itemBatchList![0]?.Discount_Pv,
        posDiscountMode: itemBatchList![0]?.Dis_Mode,
        posbatchNo: itemBatchList![0]?.batchno
      }
      addToCart(itemDetail)
    }
  }

  function addToCart(itemBatch) {

    let taxValue: number = 0;
    let discountValue: number = 0;
    let totalPrice: number = 0;

    if (itemBatch?.posTaxPercentage) {
      taxValue = (Number(itemBatch?.posItemSellingRate!) - (itemBatch?.posItemSellingRate))?.toFixed(2) as any;
    }

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    const netAmount = (Number(totalPrice))?.toFixed(2);

    itemBatch.posItemQty = itemBatch.posItemQty;
    itemBatch.posTaxValue = Number(taxValue);
    itemBatch.posDiscountValue = discountValue;
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount) - replacereturn.returnAmount!;


    if (replacereturn?.isOtherItem) {
      replacereturn.replaceBatchNo = itemBatch?.posbatchNo;
      replacereturn.replaceBarcode = itemBatch?.posBarcode;
      replacereturn.replaceQty = itemBatch?.posItemQty;
      replacereturn.replaceItemName = itemBatch?.posItemName;
      replacereturn.replaceItemId = itemBatch?.posItemId;
      setReplaceReturn(replacereturn)
    }

    setPosDetail(itemBatch)
  }

  const saveReturnReplacement = async (e) => {

    replacereturn.enteredBy = Number(Cookies.get('userId'));
    replacereturn.returnQty = Number(replacereturn.returnQty);
    replacereturn.terminalId = Number(Cookies.get('terminalId'))
    replacereturn.outletId = Number(Cookies.get('outletId'))
    const { ['posId']: removedKey, ...rest } = replacereturn;

    let error: any = {}
    error = replaceReturnValidation(replacereturn, option)
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      const status = await salesReplaceRetrunStore.saveSalesReplacement(rest);

      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      } else {
        setLoading(false);
        setFailureModal(true);
      }

      if (status === "Success") {
        if (replacereturn.isOtherItem) {
          posDetail.returnAmount = replacereturn?.returnAmount;
          // emitPosItemList(posDetail)
        }
      }
    }

  }

  const isInitialRender = useRef(true);

  async function preLoadingData() {
    setLoading(false);
    let outletId = Number(Cookies.get('outletId'));
    await Promise.all([salesReplaceRetrunStore.loadPosIds(), salesReplaceRetrunStore.loadInvoiceIds(),
    memberStore.getEntryNo('SalesReplaceReturn'),
    posMasterStore.getPosItemMaster(), posMasterStore.loadPOSProductList(outletId)
    ]);
  }

  // useEffect(() => {
  //   if (isInitialRender.current) {
  //     preLoadingData();
  //     isInitialRender.current = false;
  //   }
  //   return () => { };
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Sales_return_or_replacement';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              preLoadingData();
              isInitialRender.current = false;
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/salesretrun/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        } else {

          setLoadModal(true)
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  const handleFailureModalClose = () => setFailureModal(false);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <div className='container'>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox' style={{ width: '100%' }}>
          <div className='hrBox'>
            <h3>Sales Return / Replacement</h3>
          </div>
          <div className='inputBoxLists pd-b-0'>
            {isLoading ? <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> : isSpinner ? <div className='SpinnerBox'>
              <Spinner animation="grow" size="sm" />
            </div> : null}
            <div className="LgInputListsBox mr-left-0">
              <div className='row'>

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Return No <span>*</span></label>
                    <input type="text" className='TypeaheadSelectInput2'
                      value={replacereturn.returnNo = memberStore?.entryNo?.entryNo ? memberStore?.entryNo?.entryNo + 1 : 1}
                      disabled style={{ width: "100%" }} placeholder='Return No..'></input>
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Return Date <span>*</span></label>
                    <input type="date" className='TypeaheadSelectInput2' style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                      value={replacereturn.returnDate = replacereturn.returnDate ?? new Date().toISOString().substr(0, 10)}
                      onChange={handleInputChange}
                      name='returnDate' data-date="" data-date-format="DD MMMM YYYY"></input>
                    {errors.returnDate && <p style={{ color: 'red' }}>{errors.returnDate}</p>}
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                <div className="col-md-4"></div>

                <div className='col-md-4' >
                  <div className='row'>
                    {radio_props?.map((val, key) =>
                      <div className="col-md-6" key={key}>
                        <div className='inputBox'>
                          <label>{val?.val}</label>
                          <input type='radio' className='' onChange={handleRadioChange} value={val.key} name='options'></input>&nbsp;
                        </div>
                      </div>
                    )}
                  </div>
                  {errors?.options && <p style={{ color: 'red' }}>{errors?.options}</p>}
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-4' >
                  <div className='row'>
                    {posinvoiceradio_props?.map((val, key) =>
                      <div className="col-md-6">
                        <div className='inputBox'>
                          <label>{val?.val}</label>
                          <input type='radio' className='' onChange={handleposInvoceRadioChange} value={val.key} name='returnOptions'></input>&nbsp;
                        </div>
                      </div>
                    )}
                  </div>
                  {(errors?.options || errors.returnOptions) && <p style={{ color: 'red' }}>{errors?.options ? errors?.options : errors?.returnOptions}</p>}
                  <div className='vertical-space-10'></div>
                </div>

                {replacereturn.isReplacement ?
                  <> {replacementOptions.map((val, key) =>
                    <div className='col-md-2' >
                      <div className='inputBox'>
                        <label>{val.val}</label>
                        <input type='radio' className='' onChange={handlereplacementChange} value={val.key} name='replacementOptions'></input>&nbsp;
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  )}
                  </> : null
                }

                <div className='vertical-space-20'></div>

                {replacereturn?.isPos && <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>POS No  <span>*</span></label>
                    <div className="input-group flex">
                      <input type="text" className="form-control" style={{ width: "80%" }}
                        value={replacereturn?.posId} onChange={handleInputChange} name='posId'
                        placeholder='Enter POS No..' aria-describedby="search-button"></input>
                      <div className="input-group-append">
                        <button onClick={handleSearchPos} style={{ height: '50px' }} className="btn btn-outline-secondary" type="button" id="search-button">
                          <FontAwesomeIcon icon={faSearch} />
                        </button>
                      </div>
                    </div>
                    {errors.posId && <p style={{ color: 'red' }}>{errors.posId}</p>}
                    {errors.posnone && <p style={{ color: 'red' }}>{errors.posnone}</p>}
                  </div>
                  <div className='vertical-space-20'></div>
                </div>}

                {replacereturn.isInvoice ?
                  <div className='col-md-4'>
                    <div className='inputBox'>
                      <label>Invoice No  <span>*</span></label>
                      <div className="input-group flex">
                        <input type="text" className="form-control" style={{ width: "80%" }}
                          onChange={handleInputChange} name='invoiceId'
                          placeholder='Enter Invoice No..' aria-describedby="search-button"></input>
                        <div className="input-group-append">
                          <button onClick={handleSearchInvoice} style={{ height: '50px' }} className="btn btn-outline-secondary" type="button" id="search-button">
                            <FontAwesomeIcon icon={faSearch} />
                          </button>
                        </div>
                      </div>
                      {errors.invoiceId && <p style={{ color: 'red' }}>{errors.invoiceId}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div> : null}

                {(replacereturn.isPos && salesReplaceRetrunStore?.posItems?.length) ? <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Item Name  <span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={salesReplaceRetrunStore.posItems}
                      onChange={(event, val) => handlePosItem('itemId', event, val)}
                      renderInput={(params: any) =>
                        <TextField  {...params} style={{ width: '100%', height: '50px' }}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select Item..'
                          name='itemId'
                        />}
                    />
                    {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div> : null}

                {(replacereturn.isInvoice && salesReplaceRetrunStore?.loadInvoiceItems?.length) ? <div className='col-md-5'>
                  <div className='inputBox'>
                    <label>Item Name  <span>*</span></label>
                    <AutoComplete getOptionLabel='itemname' placeholder='Select Item..' options={salesReplaceRetrunStore?.loadInvoiceItems}
                      emitOption={(option) => handleInvoiceItem('itemId', {}, option)} />
                    {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div> : null}

                {(salesReplaceRetrunStore?.posItemBatch?.length > 0) && <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Barcode  <span>*</span></label>
                    {/* <input value={replacereturn?.barcode} /> */}
                    <AutoComplete
                      clsName='autoComplete full-width'
                      placeholder="Select Barcode.."
                      emitOption={(val) => handlePosItemBatch('barcode', {}, val)}
                      getOptionLabel='barcode'
                      options={salesReplaceRetrunStore?.posItemBatch} />
                    {errors.barcode && <p style={{ color: 'red' }}>{errors.barcode}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>}

                {(salesReplaceRetrunStore?.posItemBatch?.length > 0) && <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Batch No  <span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={salesReplaceRetrunStore?.posItemBatch}
                      onChange={(event, val) => handlePosItemBatch('batchNo', event, val)}
                      renderInput={(params: any) =>
                        <TextField  {...params} style={{ width: '100%' }}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select Batch..'
                          name='batchNo'

                        />}
                    />
                    {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>}

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Return Qty <span>*</span></label>
                    <input type="text" className='TypeaheadSelectInput2' disabled style={{ width: "100%" }}
                      value={replacereturn?.returnQty} onChange={handleInputChange} name='returnQty'
                      placeholder='Return Qty..'></input>
                    {errors.returnQty && <p style={{ color: 'red' }}>{errors.returnQty}</p>}
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Return Amount <span>*</span></label>
                    <input type="text" disabled className='TypeaheadSelectInput2' style={{ width: "100%" }}
                      value={replacereturn?.returnAmount} onChange={handleInputChange} name='returnAmount'
                      placeholder='Return Amount..'></input>
                    {errors.returnAmount && <p style={{ color: 'red' }}>{errors.returnAmount}</p>}
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Reason <span>*</span></label>
                    <input type="text" className='TypeaheadSelectInput2' style={{ width: "100%" }}
                      value={replacereturn.reason} onChange={handleInputChange} name='reason'
                      placeholder='Reason..'></input>
                    {errors.reason && <p style={{ color: 'red' }}>{errors.reason}</p>}
                  </div>
                  <div className='vertical-space-20'></div>
                </div>

                {replacereturn.isReplacement && replacereturn.isOtherItem ?
                  <>
                    <div className='col-md-5'>
                      <div className='inputBox'>
                        <label>Other Item Name  <span>*</span></label>
                        <AutoComplete
                          clsName='autoComplete full-width'
                          placeholder="Select Product/Item.."
                          emitOption={(val) => handleOtherItem('otherItemId', {}, val)}
                          getOptionLabel='label'
                          options={posMasterStore?.posItemMaster} />
                        {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                    {replacereturn?.returnQty &&
                      <>
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label>Item Name <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posItemName} disabled style={{ width: "100%" }} placeholder='Item Name..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-2'>
                          <div className='inputBox'>
                            <label>MRP <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posItemMRP} disabled style={{ width: "100%" }} placeholder='MRP..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-2'>
                          <div className='inputBox'>
                            <label>Item qty <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posItemQty}
                              disabled style={{ width: "100%" }} placeholder='Item qty..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label>Selling Price <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posItemSellingRate} disabled style={{ width: "100%" }} placeholder='Selling Price...'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label>Tax <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posTaxValue} disabled style={{ width: "100%" }} placeholder='Tax..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label>Discount <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posDiscountValue} disabled style={{ width: "100%" }} placeholder='Discount..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-2'>
                          <div className='inputBox'>
                            <label>Total <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posTotalPrice} disabled style={{ width: "100%" }} placeholder='Total..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-2'>
                          <div className='inputBox'>
                            <label>Net Amount <span>*</span></label>
                            <input type='text' className='TypeaheadSelectInput2' value={posDetail?.posNetAmount} disabled style={{ width: "100%" }} placeholder='Net Amount..'></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                      </>
                    }
                  </> : <>
                  </>
                }

              </div>
            </div>
            <div className='btnBox'>
              <button className='secondaryBtn' onClick={handleCancel}>List</button>
              <button className='dfBtn' type='submit' onClick={saveReturnReplacement}>Submit</button>
            </div>
          </div>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <BarcodeBatches isitemmodel={isBarcodeBatches?.bool}
          itemModalClose={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}
          itemImage={''}
          memberCatId={''}
          itemBatchNo={isBarcodeBatches?.batchArr}
          addtoCart={(obj: any) => { addToCart(obj); }}
          setBarcodeBatches={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}

        />

      </div>
    );
  }
});

export default SalesReturnOrReplacement;
