import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IVerifyOTP } from './model';
import { encryptData } from '../Helper';
import {useNavigate} from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import useEcomStores from '../hooks/index';

const verifyOTP = observer((): JSX.Element => {
  const { ecomMemberStore } = useEcomStores();
  const [verifyOTP, setVerifyOTP] = useState<IVerifyOTP>({});
  const [isPassword, setPassword] = useState(false);
  const[isOTP,setOTP]=useState(true);
  const [errors,setErrors]=useState<any>({});
  const navigate = useNavigate();
  const [isLoading,setLoading]=useState(false);

  const handleVerifyOTP = async (e) => {
    setOTP(false)
    e.preventDefault();
    const status = await ecomMemberStore.verifyOTP(verifyOTP);
    if (status.message === 'OTP Verified') {
      setOTP(false);
      setPassword(true);
    }
    else
    {
      setOTP(true)
    }
  }
const handleInputChange=(e)=>{
const {name,value} =e.target;
setVerifyOTP({...verifyOTP,[name]:value})
}

async function handleSubmit(e) {
  setOTP(false);
  
  e.preventDefault();
  if(verifyOTP.email !==verifyOTP.confirmEmail){
    setOTP(false);
    errors.confirmEmail='Passwords does not match!';
    setErrors(errors);
  }
  else
  {
    verifyOTP.confirmEmail = encryptData(verifyOTP.confirmEmail)
    const status = await ecomMemberStore.UpdateEmail(verifyOTP);
    if (status.message === 'Success') {
      setLoading(false);
      setOTP(false);
      navigate('/')
    }
  }
} 

  useEffect(() => {
    setVerifyOTP(ecomMemberStore.memberResponse);
    
  }, [])
  return (
    <>
    {
isLoading?<div className='SpinnerBox'>
<Spinner animation="border" role="status">
  <span className="visually-hidden">Loading...</span>
</Spinner>
</div> :
<>
<div className="Login">
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Verify OTP</h3>
        </div>
        <div className='inputBoxLists'>
          {
            isOTP?
            <div className='row'>
               <div className='col-md-8'>
              <div className='inputBox'>
                <label>Enter Your OTP <span>*</span></label>
                <input  type="text" className='' style={{ width: "100%" }} placeholder='Enter Your OTP..'
                name='otp' value={verifyOTP.otp} onChange={handleInputChange}
                />
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='btnBox'>
            <button className='verifybtn' onClick={handleVerifyOTP}><span>Verify</span></button>
          </div>
              </div>
              
            :null
          }
          <div className='vertical-space-10'></div>
          {
            isPassword ? <div className='row'>
              <div className='col-md-8'>
                <div className='inputBox'>
                  <label>Enter Password <span>*</span></label>
                  <input  type="Password" className='' 
                  name='email' 
                  style={{ width: "100%" }} placeholder='Enter Your Password..'
                  onChange={handleInputChange}></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-8'>
                <div className='inputBox'>
                  <label>Confirm Password <span>*</span></label>
                  <input  type="Password" className='' style={{ width: "100%" }} 
                  onChange={handleInputChange}
                  name='confirmEmail' 
                  placeholder='Enter Confirm Password..'></input>
                </div>
                {errors.confirmEmail && <p style={{color:'red'}}>{errors.confirmEmail}</p>}
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>
                <button className='dfBtn' type='submit' onClick={handleSubmit}><span>Submit</span></button>
              </div>
            </div> : null
          }
        </div>
      </div>
    </div>
</>
    }
   
    </>
    
  );
})

export default verifyOTP;
