import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IAssignOrderSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';



function AssignOrderDetail() {
  const [assignOrderSearchOptions, setAssignOrderSearchOptions] = useState<IAssignOrderSearchoptions>({})
  const { assignOrderStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const [isAssignOrderShow, setAssignOrderModal] = useState(false);
  const navigate = useNavigate();
  const { getAssignOrderDetails, assignOrderDetail, getAssignOrder, assignOrderList } = assignOrderStore;
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const sortedData = assignOrderStore?.assignOrderList?.length > 0 && assignOrderStore?.assignOrderList?.slice()?.sort((a, b) => {
    const dateA = new Date(a.orderassigndate);
    const dateB = new Date(b.orderassigndate);

    if (dateA > dateB) return -1;
    if (dateB < dateA) return 1;
    return 0;
  });

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const getAssignOrderDetail = async (orderassignid) => {

    const data = await getAssignOrderDetails(orderassignid);

    if (data !== undefined || data !== null) {
      setAssignOrderModal(true);
    }
  }
  const handleClose = () => {
    setAssignOrderModal(false);
  }
  const handleCreate = () => {
    navigate('/Admin/AssignOnlineOrder');
  }

  const deleteAssignOrderMaster = async (orderdespatchid) => {
    setLoading(true);
    const status = await assignOrderStore.deleteAssignOrderMaster(orderdespatchid);

    if (status === 'Success') {
      fetchAssignOrder();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteAssignOrderDetail = async (orderassigndid) => {

    setAssignOrderModal(false);
    setLoading(true);
    const status = await assignOrderStore.deleteAssignOrderDetail(orderassigndid);

    if (status === 'Success') {
      fetchAssignOrder();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchAssignOrder();
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignOrderSearchOptions({ ...assignOrderSearchOptions, [name]: value });
  }

  const handleSearchAssign = async (e) => {
    setLoading(true);
    await assignOrderStore.getAssignOrderSearch(assignOrderSearchOptions);
    setLoading(false);
  }

  async function fetchAssignOrder() {
    const userRights = await userCreationStore?.getUserRight('Assign_online_order')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await assignOrderStore.getAssignOrder();
    setLoading(false);
  }

  const isCurrentRef = useRef(true)

  useEffect(() => {
    if (isCurrentRef.current) {
      fetchAssignOrder();
      isCurrentRef.current = false
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>

            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={sortedData}
                searchTitles={[{ key: "Orderthrough", value: "Assigned By" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='inputBoxLists'>
                <div className='vertical-space-20'></div>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
                  </div>
                  <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="tables table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Assigned By </th>
                      <th scope='col'>Outlet  </th>
                      <th scope='col'>Warehouse  </th>
                      <th scope='col'>Schedule Date  </th>
                      <th scope='col'>Order No  </th>
                      <th scope='col'>Order Date  </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{moment(val.orderassigndate).format('DD-MMM-YYYY')}</td>
                            <td>{val.orderthrough}</td>
                            <td>{val.outletid}</td>
                            <td>{val.warehouseId}</td>
                            <td>{moment(val.scheduledate).format('DD-MMM-YYYY')}</td>
                            <td>{val.orderno}</td>
                            <td>{moment(val.orderdate).format('DD-MMM-YYYY')}</td>
                            <td><button disabled={!userRights?.view} onClick={() => getAssignOrderDetail(val.orderassignid)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignOrderMaster(val.orderassignid)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>

              <Modal show={isAssignOrderShow} onHide={handleClose} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Detail</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>

                          <th scope='col'>Item Name </th>
                          <th scope='col'>Qty</th>
                          <th scope='col'>Delete </th>
                        </tr>
                      </thead>
                      <tbody>
                        {assignOrderDetail?.length > 0 && assignOrderDetail?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.assignOrderitemName}</td>
                              <td>{val.assignOrderitemQty}</td>
                              <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignOrderDetail(val.orderassigndid)} /></button></td>
                            </tr>
                          )
                        })
                        }
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>

  );
};



export default AssignOrderDetail;
