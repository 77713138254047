import { action, set, makeObservable, observable, runInAction } from 'mobx';

import apiAgent from '../../../APIAgent';

class AssignFreeItemStore {
    
    @observable entryNo= new Map();
    @observable Items =new Map();
    @observable freeItems =new Map();
    @observable batchNos =new Map();
    @observable assignFreeItems =new Map();
    @observable assignFreeItemDetail =new Map();
    constructor(){
        makeObservable(this);
    }
getEntryNo = async (flag)=>{
    try{
        const entryNo = await apiAgent.assignFreeItem.getEntryNo(flag);
        
        runInAction(()=>{
            this.entryNo = entryNo;
        })
        return this.entryNo;
    }
    catch(error){
    console.log(error);
    }
}
getItems = async ()=>{
    try{
        const items = await apiAgent.assignFreeItem.loadItems();
        
        runInAction(()=>{
            this.Items = items;
        })
        return this.Items;
    }
    catch(error){
    console.log(error);
    }
}
getFreeItems = async ()=>{
    try{
        const items = await apiAgent.assignFreeItem.loadOtherItmes();
        
        runInAction(()=>{
            this.freeItems = items;
        })
        return this.freeItems;
    }
    catch(error){
    console.log(error);
    }
}
getBatchNos = async (itemId)=>{
    try{
        const batchnos = await apiAgent.assignFreeItem.loadBatchNos(itemId);
        
        runInAction(()=>{
            this.batchNos = batchnos;
        })
        return batchnos;
    }
    catch(error){
    console.log(error);
    }
}
SaveAssignFreeItem=async(assignFreeItem)=>{
    try{
        const status = await apiAgent.assignFreeItem.SaveAssignFreeItem(assignFreeItem);
        return status;
    }
    catch(error){
    console.log(error);
    }
}
getAssignFreeItems = async ()=>{
    try{
        const freeItems = await apiAgent.assignFreeItem.getAssignFreeItems();
        
        runInAction(()=>{
            this.assignFreeItems = JSON.parse(freeItems);
        })
        return JSON.parse(freeItems);
    }
    catch(error){
    console.log(error);
    }
}
getAssignFreeItemDetail= async (assignFreeId)=>{
    try{
        const freeItem = await apiAgent.assignFreeItem.getAssignFreeItemDetail(assignFreeId);
        
        runInAction(()=>{
            this.assignFreeItemDetail = freeItem;
        })
        return this.assignFreeItemDetail;
    }
    catch(error){
    console.log(error);
    }
}
getAssignFreeItemSearch= async (assignFreeItemSearch)=>{
    try{
        const freeItem = await apiAgent.assignFreeItem.getAssignFreeItemSearch(assignFreeItemSearch);
        
        runInAction(()=>{
            this.assignFreeItems = freeItem;
        })
        return this.assignFreeItems;
    }
    catch(error){
    console.log(error);
    }
}
deleteAssignFreeItem= async (assignFreeId)=>{
    try{
        const status = await apiAgent.assignFreeItem.deleteAssignFreeItem(assignFreeId);
        
        return status;
    }
    catch(error){
    console.log(error);
    }
}
}

export default AssignFreeItemStore;