import React, { useState } from "react"
import { Image, Modal } from "react-bootstrap";
import { IIPostSalesDetails } from "../../model";
import { QRCode } from 'react-qrcode-logo'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface BatchDetailProps {
    show: boolean,
    onHide: () => void,
    itemImage: any,
    itemBatchNo: Array<any>,
    memberCatId: string,
    addtoCart: (item: any) => void,
}

const BatchModal: React.FC<BatchDetailProps> = ({ show, onHide, itemImage, itemBatchNo, memberCatId, addtoCart }) => {

    const [posBatchDetails, setPOSBatchDetails] = useState<IIPostSalesDetails[]>([]);
    const [errors, setErrors] = useState<any>({})

    const [multiErr, setMultiErr] = useState<any>([])

    function handlebatchdetails(e, val) {
        const existing = posBatchDetails?.find(m => m?.posbatchNo === val?.batchno)
        if (existing && Object.keys(existing)?.length != 0) {
            setPOSBatchDetails(posBatchDetails.map((item, i) => {
                if (item.posbatchNo === val.batchno) {
                    return { ...item, posItemQty: Number(e.target.value) };
                }
                return item;
            }));
        } else {
            let posBatchDetail: IIPostSalesDetails = {};
            posBatchDetail.posbatchNo = val.batchno;
            posBatchDetail.posItemQty = Number(e.target.value)
            posBatchDetail.posItemId = val.itemid;
            posBatchDetail.posItemName = val.itemname;
            posBatchDetail.checked = true
            posBatchDetail.posMinSalQty = val?.minsalqty;
            posBatchDetail.posItemMRP = val.Mrprate;
            posBatchDetail.posItemSellingRate = (memberCatId === 'B2B' ? val.BTBSellingRate : val?.BTCSellingRate);
            posBatchDetail.posTaxPercentage = val?.taxper;
            posBatchDetail.posDiscountPercentage = val?.Discount_PV;
            posBatchDetail.posDiscountValue = val?.Discount_PV;
            posBatchDetail.posDiscountMode = val?.Dis_Mode;
            posBatchDetail.availableQty = val?.stock;
            posBatchDetail.posBarcode = val?.Barcode;
            posBatchDetail.posSubCategoryId = Number(val?.subcategory);
            setPOSBatchDetails([...posBatchDetails, posBatchDetail])
        }
    }

    const handlebatchNo = (e, value) => {
        let { checked } = e.target;
        const existing = posBatchDetails.filter(m => m?.posbatchNo === value.batchno)
        if (checked == true) {
            if (existing.length === 0) {
                let posBatchDetail: IIPostSalesDetails = {};
                posBatchDetail.posbatchNo = value.batchno;
                posBatchDetail.posItemId = value.itemid;
                posBatchDetail.posItemName = value.itemname;
                posBatchDetail.checked = checked;
                posBatchDetail.posMinSalQty = value.minsalqty;
                posBatchDetail.posItemMRP = value.Mrprate;
                posBatchDetail.posItemSellingRate = (memberCatId === 'B2B' ? value.BTBSellingRate : value?.BTCSellingRate);
                posBatchDetail.posTaxPercentage = value?.taxper;
                posBatchDetail.posDiscountPercentage = value?.Discount_PV;
                posBatchDetail.posDiscountValue = value?.Discount_PV;
                posBatchDetail.posDiscountMode = value?.Dis_Mode;
                posBatchDetail.availableQty = value?.stock;
                posBatchDetail.posBarcode = value?.Barcode;
                posBatchDetail.posSubCategoryId = Number(value?.subcategory);
                setPOSBatchDetails([...posBatchDetails, posBatchDetail])
            }
        } else {
            const posBatchDetail = posBatchDetails.filter(m => m.posbatchNo !== value.batchno)
            setPOSBatchDetails([])
            setPOSBatchDetails(posBatchDetail)
        }
    }

    function calculateAvailQty(batchList: any[]) {
        if (batchList?.length > 0) {
            let totalAvailQty = batchList?.reduce((acc, val) => acc + val.stock, 0)
            return totalAvailQty
        }
    }

    function isFloat(x) { return !!(x % 1); }

    function emitBatchCart() {
        if (posBatchDetails?.length) {
            setErrors({})
            let filArr = posBatchDetails?.filter((m) => m?.checked == true)
            debugger
            let mulErr: any[] = []
            filArr?.forEach((obj, i) => {
                if (obj?.posMinSalQty) {
                    let dividVal = obj?.posItemQty! / Number(obj?.posMinSalQty! ?? 1)
                    let isDivisable = isFloat(dividVal)
                    let error: any = {
                        posItemId: obj?.posItemId,
                        isDivisable: isDivisable,
                        posbatchNo: obj?.posbatchNo,
                        errmsg: 'Enter Value muplity of Min. Sale Qty'
                    }
                    if (isDivisable) {
                        mulErr.push(error)
                    }
                }
            })
            filArr?.forEach((obj, i) => {
                debugger
                if (!mulErr?.length && obj?.posItemQty) {
                    console.log(obj, 'obj>>')
                    addtoCart(obj)
                }
            })
            setMultiErr([...mulErr])
            if (mulErr?.length === 0) {
                setPOSBatchDetails([])
                onHide()
            }
        } else {
            let error = {
                batchChoosen: 'Select a Product to add'
            }
            setErrors(error)
        }
    }

    function onitemModalClose() {
        setPOSBatchDetails([])
        setErrors({})
        onHide()
    }

    return (
        <Modal show={show} onHide={onitemModalClose} className="PriceHistoryModel">
            <Modal.Header closeButton><h5>Batch Details</h5></Modal.Header>
            <Modal.Body>
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className="flex">
                        {itemImage ?
                            <div className='imgBox'>
                                <Image style={{ width: '210px', marginRight: '30px' }} src={`data:image/jpeg;base64,${itemImage}`} />
                            </div> :
                            <div className='imgBox'>
                                <Image style={{ width: '210px', marginRight: '30px' }} src={require('../..../../../../../../../Uploads/No-Image-Placeholder.png')} />
                            </div>
                        }
                        <div className='inputFormBox'>
                            <div className='col-md-12'>
                                {itemBatchNo?.length > 0 ? itemBatchNo[0]?.itemname : ""}
                            </div>
                            <div className='col-md-12'>
                                <div className='tableBox'>
                                    {errors.batchChoosen && <p style={{ color: 'red' }}>{errors.batchChoosen}</p>}
                                    <table className="table table-striped" style={{ overflow: 'auto' }}>
                                        <thead>
                                            <tr>
                                                <th scope="col"> </th>
                                                <th scope="col"> BatchNo</th>
                                                <th scope="col">Batch Qty</th>
                                                <th></th>
                                                <th scope="col">Available Qty</th>
                                                <th scope="col">MRP</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {itemBatchNo?.length > 0 && itemBatchNo?.map((val, key) => {
                                                let findErr = multiErr?.find((err) => err?.posItemId === val?.itemid && err?.posbatchNo === val?.batchno)
                                                return val.stock > 0 && (
                                                    <tr key={key} className='self-center'>
                                                        <td>
                                                            <input type="checkbox" onChange={(e) => handlebatchNo(e, val)}></input>
                                                        </td>
                                                        <td><QRCode value={val.batchno} size={100} /></td>
                                                        <td>
                                                            <input type="number" placeholder='Batch Qty' style={{ width: '100%' }} className='TypeaheadSelectInput2'
                                                                name="batchqty" onChange={(e) => Number(e.target.value) <= val.stock ? handlebatchdetails(e, val) : {}}>
                                                            </input>
                                                            {findErr?.errmsg && <p style={{ color: 'red' }}>{findErr?.errmsg}</p>}
                                                        </td>
                                                        <td></td>
                                                        <td>{val?.stock}</td>
                                                        <td>{val?.Mrprate}</td>
                                                        <td>{memberCatId === 'B2B' ? val.BTBSellingRate : val?.BTCSellingRate}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>Total: {calculateAvailQty(itemBatchNo)}</td>
                                                <td></td>
                                                <td className='col-sm-3'>
                                                    <button className='adddfBtn' style={{ background: '#0ac074' }} onClick={emitBatchCart}>
                                                        <FontAwesomeIcon icon={faShoppingCart} />
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BatchModal;