import { action, set, makeObservable, observable, runInAction } from 'mobx';
import LedgerMasterDetails, { ILedgerMaster } from '../model/index';
import apiAgent from '../../../APIAgent';

class LedgerMasterStore {
    ledgerMasterDetls = LedgerMasterDetails;
    @observable ledgerMasterList = new Map();
    isLoading = true;
    @observable ledgerMasterLists = new Map<any, any>();
    //status = string;
    @observable isEdit = false;
    @observable ledgerMasterStatus = new Map();
    constructor() {
        makeObservable(this);
    }

    saveLedgerMaster = async (ledgerMaster: ILedgerMaster) => {
        try {
            const status = await apiAgent.ledgerMaster.saveLedgerMaster(ledgerMaster);

            if (status !== undefined) {
                return status;

            }

        }
        catch (error) {
            console.log(error);
        }
    }
    getLedgerMaster = async () => {

        try {
            const ledgerMasterData = await apiAgent.ledgerMaster.getLedgerMaster();

            runInAction(() => {
                this.ledgerMasterList = JSON.parse(ledgerMasterData);
            })
            return JSON.parse(ledgerMasterData);
        }
        catch (error) {
            console.log(error);
        }
    }
    updateLedgerMaster = async (ledgerMaster: ILedgerMaster) => {

        try {
            const status = await apiAgent.ledgerMaster.updateLedgerMaster(ledgerMaster);
            runInAction(() => {
                this.ledgerMasterStatus = status;
            });

            return this.ledgerMasterStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    LedgerMasterDetails = async (ledgerId: Number) => {

        try {
            const ledgerMasterData = await apiAgent.ledgerMaster.LedgerMasterDetails(ledgerId);

            return ledgerMasterData;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteLedgerMaster = async (ledgerId) => {
        try {
            const status = await apiAgent.ledgerMaster.deleteLedgerMaster(ledgerId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLedgerMasterSearch = async (SearchOptions) => {
        try {
            const data = await apiAgent.ledgerMaster.getLedgerMasterSearch(SearchOptions);
            this.ledgerMasterList = data;

            runInAction(() => {
                this.ledgerMasterList = data;

            })
            return this.ledgerMasterList;

        }
        catch (error) {
            console.log(error);
        }
    }

    saveBillEntry = async (billEntry) => {
        try {
            const status = await apiAgent.ledgerMaster.saveBillEntry(billEntry);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getBillEntryMaster = async () => {
        try {
            const data = await apiAgent.ledgerMaster.getBillEntryMaster();
            return JSON.parse(data)
        }
        catch (error) {
            console.log(error);
        }
    }

    getBillENtryDetails = async (billEntrystId) => {
        try {
            const data = await apiAgent.ledgerMaster.getBillENtryDetails(billEntrystId);
            return data
        }
        catch (error) {
            console.log(error);
        }
    }

    getBillENtryTaxDetails = async (billEntrystId) => {
        try {
            const data = await apiAgent.ledgerMaster.getBillENtryTaxDetails(billEntrystId);
            return data
        }
        catch (error) {
            console.log(error);
        }
    }

    @action setLedgerMaster = (state: ILedgerMaster) => {
        this.ledgerMasterList.set(0, state);
    }
    @action setLedgerMasterList = (state: ILedgerMaster[]) => {
        set(state, this.ledgerMasterList);
        return this.ledgerMasterList;
    }
}
export default LedgerMasterStore;