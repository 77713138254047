import { IPOSProductDetails, IPosBatchDetails } from "../../../gsmadmin/Admin/POS/model";
import Cookies from 'js-cookie';

const outletID = Number(Cookies.get('outletId'))

export function isEqual(currentItem, newItem) {
    return (
        currentItem?.posItemMRP == newItem?.posItemMRP &&
        currentItem?.posItemSellingRate == newItem?.posItemSellingRate
    )
}

export function checkForItemOffer(itemDetail: IPOSProductDetails, assignOffer: any[], posItemOffer: any[]) {
    let offerDiscoung: number = 0;
    if (posItemOffer?.length) {
        posItemOffer?.map((offers) => {
            if (new Date() < new Date(offers?.effectivetoDate)) {
                if (offers?.AssinOfferType === "products" && offers?.itemId && itemDetail?.posItemId === offers?.itemId) {
                    if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
                        let sumSellingRate = itemDetail?.posItemSellingRate! * itemDetail?.posItemQty!
                        if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
                            offerDiscoung = offers.Discount_PV!;
                            if (offers?.offerid && !(assignOffer?.some(offer => offer?.offerid === offers?.offerid))) {
                                assignOffer?.push(offers)
                                // setAssignedOff([...assignOffer, offers])
                            }
                        } else {
                            offerDiscoung = 0;
                            let index = assignOffer?.findIndex((obj) => obj?.offerid === offers?.offerid)
                            if (index >= 0) {
                                assignOffer?.splice(index, 1)
                                // setAssignedOff([...assignOffer])
                            }
                        }
                    } else if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc%") {
                        let sumTotalPrice = (Number(itemDetail?.posItemSellingRate!) * Number(itemDetail?.posItemQty!))?.toFixed(2) as any;
                        if (sumTotalPrice >= offers.From_QV && sumTotalPrice <= offers.To_QV) {
                            offerDiscoung = sumTotalPrice * (itemDetail?.posDiscountPercentage! / 100)
                        } else {
                            offerDiscoung = 0;
                        }
                    } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
                        let itemQty = itemDetail?.posItemQty!;
                        if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
                            offerDiscoung = offers.Discount_PV!;
                            if (offers?.offerid && !(assignOffer?.some(offer => offer?.offerid === offers?.offerid))) {
                                assignOffer?.push(offers)
                                // setAssignedOff([...assignOffer, offers])
                            }
                        } else {
                            offerDiscoung = 0;
                            let index = assignOffer?.findIndex((obj) => obj?.offerid === offers?.offerid)
                            if (index >= 0) {
                                assignOffer?.splice(index, 1)
                                // setAssignedOff([...assignOffer])
                            }
                        }
                    } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc%") {
                        let itemQty = itemDetail?.posItemQty!;
                        if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
                            offerDiscoung = itemQty * (itemDetail?.posDiscountPercentage! / 100)
                        } else {
                            offerDiscoung = 0;
                        }
                    } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Value") {

                    } else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Disc%") {

                    }
                }
                if (offers?.AssinOfferType === "category" && offers?.categoryId && itemDetail?.posSubCategoryId === offers?.categoryId) {
                    if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
                        let sumSellingRate = itemDetail?.posItemSellingRate! * itemDetail?.posItemQty!
                        if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
                            offerDiscoung = offers.Discount_PV!;
                            if (offers?.offerid && !(assignOffer?.some(offer => offer?.offerid === offers?.offerid))) {
                                assignOffer?.push(offers)
                                // setAssignedOff([...assignOffer, offers])
                            }
                        } else {
                            offerDiscoung = 0;
                            let index = assignOffer?.findIndex((obj) => obj?.offerid === offers?.offerid)
                            if (index >= 0) {
                                assignOffer?.splice(index, 1)
                                // setAssignedOff([...assignOffer])
                            }
                        }
                    } if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc%") {

                    } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
                        let itemQty = itemDetail?.posItemQty!;
                        if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
                            offerDiscoung = offers.Discount_PV!;
                            if (offers?.offerid && !(assignOffer?.some(offer => offer?.offerid === offers?.offerid))) {
                                assignOffer?.push(offers)
                                // setAssignedOff([...assignOffer, offers])
                            }
                        } else {
                            offerDiscoung = (itemDetail?.posItemMRP! - itemDetail?.posItemSellingRate!);
                            assignOffer = assignOffer?.filter((obj) => obj?.offerid != offers?.offerid)
                            // setAssignedOff(assignOffer?.filter((obj) => obj?.offerid != offers?.offerid))
                        }
                    } else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc%") {

                    }
                }
            }
        })
    }
    return { assignOffer, offerDiscoung }
}

export function checkForFreeItem(itemDetail: IPOSProductDetails, posFreeItems: any[]) {
    let freeItems = posFreeItems?.filter((obj) => obj?.itemid === itemDetail?.posItemId);
    const freeItemRet = freeItems?.map((obj) => {
        let freeItem: IPOSProductDetails;
        if (new Date() < new Date(obj?.efftodate)) {
            if (obj?.isbatch === "Yes" && obj?.batch_no === itemDetail?.posbatchNo) {
                return freeItem = {
                    posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
                    posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
                    posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
                    posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
                    posItemQty: itemDetail?.posItemQty! * obj?.freeqty,
                    posTaxValue: 0,
                    posDiscountValue: 0,
                    posTotalPrice: 0,
                    posNetAmount: 0,
                    posTaxPercentage: 0,
                    posDiscountPercentage: 0,
                }
            } else if (obj?.issales === "Yes" && obj?.saleqty) {
                const divisions = Math.floor(itemDetail?.posItemQty! / obj?.saleqty);
                return freeItem = {
                    posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
                    posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
                    posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
                    posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
                    // posbatchNo: itemDetail?.posbatchNo,
                    posItemQty: divisions * obj?.freeqty,
                    posTaxValue: 0,
                    posDiscountValue: 0,
                    posTotalPrice: 0,
                    posNetAmount: 0,
                    posTaxPercentage: 0,
                    posDiscountPercentage: 0
                }
            }
        }
    })

    return freeItemRet?.filter((obj) => obj != undefined);
}

export function updateBatchList(batchObj, isexistsItem, posBatchDetails: IPosBatchDetails[], posItemDetils: IPOSProductDetails[], freeItems?: any, isRemove?: boolean) {
    if (!isexistsItem) {
        let batchItem: IPosBatchDetails = {
            itemId: freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId,
            barcode: freeItems?.posFreeItemId ? freeItems?.posBarcode?.toString() : batchObj?.barcode,
            batchNo: freeItems?.posFreeItemId ? freeItems?.posbatchNo : batchObj?.batchNo,
            batchQty: freeItems?.posFreeItemId ? freeItems?.posItemQty : batchObj?.batchQty
        }
        posBatchDetails?.push(batchItem);
        return posBatchDetails;
    } else if (isRemove || (!posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) &&
        posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)))) {

        return (posBatchDetails?.filter((batches) => (batches?.barcode != batchObj?.barcode && batches?.itemId != (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && batches?.batchNo != batchObj?.batchNo)))

    } else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
        (posBatchDetails?.map((obj) => {
            if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.batchNo === obj?.batchNo) {
                return obj = batchObj;
            }
            return obj
        }))
        return posBatchDetails;
    } else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && !posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
        if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) {
            posBatchDetails?.push(batchObj)
            return (posBatchDetails);
        }
    }
}

export function useCartQuantityUpdater(type: string, obj: IPOSProductDetails, posItemDetils: IPOSProductDetails[], qty?: number) {
    let existsItem = posItemDetils?.find((items) => isEqual(items, obj));
    if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
        if (type == 'add') {
            existsItem!.posItemQty! += qty!;
        } else {
            existsItem!.posItemQty! -= qty!;
        }
    } else if (type == 'add') {
        obj.posItemQty = 0;
        obj.posItemQty! += qty!;
    } else {
        obj.posItemQty = 0
        obj.posItemQty! -= qty!;
    }
    return obj;
}

export const shuffleArray = (array: any[]): any[] => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};

export const comparePosItemMasterArrays = (array1: any[], array2: any[]): any[] => {
    const array3: any[] = [];
    let memeberType = '';
    // Iterate over both arrays simultaneously
    for (let i = 0; i < Math.max(array1.length, array2.length); i++) {
        if (i < array1.length && i < array2.length) {
            // If both arrays have objects at current index
            if (array1[i].itemid === array2[i].itemId) {
                let Products = {
                    posItemId: array1[i].itemid,
                    posItemName: array1[i]?.itemname,
                    posSubCategoryId: array1[i]?.subcategory,
                    posUnitOdMeasure: array1[i]?.unitOfMeasure,
                    posBarcode: array1[i]?.Barcode,
                    posbatchNo: array1[i]?.batchno,
                    availableQty: array1[i]?.stock,
                    posItemQty: 1,
                    posItemMRP: array1[i]?.Mrprate,
                    posItemSellingRate: memeberType === 'B2B' ? array1[i]?.BTBSellingRate : array1[i].BTCSellingRate,
                    posDiscountPercentage: array1[i]?.Discount_PV,
                    posDiscountValue: array1[i]?.Discount_PV,
                    posDiscountMode: array1[i]?.Dis_Mode,
                    posTaxPercentage: array1[i]?.taxper,
                    posCategory: array1[i]?.category,
                    imageData: array2[i]?.imageData,
                    filePath: (array2[i]?.fileName)
                }
                array3.push(Products); // Push object from array2 to array3 if IDs match
            } else {
                let Products = {
                    posItemId: array2[i]?.itemId,
                    posItemName: array2[i]?.itemName,
                    posSubCategoryId: array2[i]?.subCategoryId,
                    posUnitOdMeasure: array2[i]?.unitName,
                    posBarcode: "",
                    posbatchNo: "",
                    availableQty: array2[i]?.currentStock,
                    posItemQty: 1,
                    posItemMRP: array2[i]?.mrp,
                    posItemSellingRate: array2[i]?.purchasePrice,
                    posDiscountPercentage: 0,
                    posDiscountValue: 0,
                    posDiscountMode: "",
                    posTaxPercentage: 0,
                    posCategory: array2[i]?.itemCategory,
                    imageData: array2[i]?.imageData,
                    filePath: (array2[i]?.fileName)
                }
                array3.push(Products); // Push object from array1 to array3 if IDs don't match
            }
        } else if (i < array1.length) {
            let Products = {
                posItemId: array1[i].itemid,
                posItemName: array1[i]?.itemname,
                posSubCategoryId: array1[i]?.subcategory,
                posUnitOdMeasure: array1[i]?.unitOfMeasure,
                posBarcode: array1[i]?.Barcode,
                posbatchNo: array1[i]?.batchno,
                availableQty: array1[i]?.stock,
                posItemQty: 1,
                posItemMRP: array1[i]?.Mrprate,
                posItemSellingRate: memeberType === 'B2B' ? array1[i]?.BTBSellingRate : array1[i].BTCSellingRate,
                posDiscountPercentage: array1[i]?.Discount_PV,
                posDiscountValue: array1[i]?.Discount_PV,
                posDiscountMode: array1[i]?.Dis_Mode,
                posTaxPercentage: array1[i]?.taxper,
                posCategory: array1[i]?.category,
                imageData: array2[i]?.imageData,
                filePath: (array2[i]?.fileName)
            }
            // If only array1 has object at current index
            array3.push(Products);
        } else if (i < array2.length) {
            let Products = {
                posItemId: array2[i]?.itemId,
                posItemName: array2[i]?.itemName,
                posSubCategoryId: array2[i]?.subCategoryId,
                posUnitOdMeasure: array2[i]?.unitName,
                posBarcode: "",
                posbatchNo: "",
                availableQty: array2[i]?.currentStock,
                posItemQty: 1,
                posItemMRP: array2[i]?.mrp,
                posItemSellingRate: array2[i]?.purchasePrice,
                posDiscountPercentage: 0,
                posDiscountValue: 0,
                posDiscountMode: "",
                posTaxPercentage: 0,
                posCategory: array2[i]?.itemCategory,
                imageData: array2[i]?.imageData,
                filePath: (array2[i]?.fileName)
            }
            // If only array2 has object at current index
            array3.push(Products);
        }
    }

    return array3;
}