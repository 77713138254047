
function counterOpenValidation(counterOpenEntry){
 
  
    let errors :any={};
  
    if(!counterOpenEntry.denomationDate){
      
      errors.denomationDate = "Select Counter Open Date";
    }
    if(!counterOpenEntry.denomationTime){
      errors.denomationTime = "Select Counter Open Time";
    }
    if(!counterOpenEntry.denomationCounterid){
      errors.denomationCounterid = "Select Counter Number";
    }
    if(!counterOpenEntry.denomationAmount){
      errors.denomationAmount = "Enter Amount";
    }
    
    
    return errors;
  };
    
    export default counterOpenValidation;