import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from "mobx-react";
import { IDepartment } from './model';
import useStores from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import departmentValidation from './validation';
import { Modal, Image, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';


const DepartmentMaster = observer((): JSX.Element => {
  const [departmentDetails, setdepartmentDetails] = useState<IDepartment>({});
  const { supplierMasterStore, departmentStore, employeeStore } = useStores();
  const [errors, setErrors] = useState<any>({})
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleChangeview = () => {
    navigate('/Admin/departmentmaster/list');
  }

  const { departmentId } = useParams();

  const saveDepartment = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = departmentValidation(departmentDetails)
    setErrors(error)

    if (Object.keys(error).length === 0) {
      setLoading(true);
      let status: string = '';
      if (departmentId !== undefined) {
        status = await departmentStore.updateDepartment(departmentDetails);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await departmentStore.saveDepartment(departmentDetails);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  };

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setdepartmentDetails({ ...departmentDetails, [name]: value });
  };
  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/departmentmaster/list');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  async function mapItemData(DepartemntDels) {

    departmentDetails.departmentId = DepartemntDels.departmentId
    departmentDetails.department = DepartemntDels.department;
    departmentDetails.description = DepartemntDels.description;
    setdepartmentDetails(departmentDetails);
  }

  async function fetchPreLoadingData() {
    if (departmentId !== undefined) {
      const data = await departmentStore.DepartmentDetails(parseInt(departmentId!));
      mapItemData(data);
    }
    else {
      const data = await employeeStore.getEntryNo('DepartmentMaster');
      if (data !== undefined) {
        departmentDetails.departmentId = data.entryNo;
        setdepartmentDetails(departmentDetails);
      }
    }
    setLoading(false)
  }

  // const isCurrenPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchPreLoadingData();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Department_master';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/departmentmaster/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Department Master</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='inputBox'>
              <label>Department Id</label>
              <input type="text" className='' name='departmentId'
                value={departmentDetails.departmentId} disabled
                style={{ width: "80%" }} placeholder='Employee ID..'></input>
              <div className='vertical-space-10'></div>
            </div>
            <div className='inputBox'>
              <label>Department <span>*</span></label>
              <input type="text" className='' style={{ width: "80%" }}
                onChange={handleInputchnage}
                name="department"
                value={departmentDetails.department}
                placeholder='Enter Department..'></input>
              {errors.department && <p style={{ color: 'red' }}>{errors.department}</p>}
              <div className='vertical-space-10'></div>
            </div>
            <div className='inputBox'>
              <label>Description</label>
              <textarea className='' style={{ width: "100%" }}
                onChange={handleInputchnage}
                name="description"
                value={departmentDetails.description = departmentDetails.description ?? ""}
                placeholder='Enter Description..'></textarea>
              {errors.description && <p style={{ color: 'red' }}>{errors.description}</p>}
            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleChangeview}>List</button>
            <button className='dfBtn' type='submit' onClick={saveDepartment}> Submit</button>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
});

export default DepartmentMaster;
