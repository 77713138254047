import { action, set, makeObservable, observable, runInAction } from 'mobx';
import TerminalCreationDetails, {  ITerminalCreation } from '../model/index';
import apiAgent from '../../../APIAgent';

class TerminalCreationStore {
    terminalCreationDetls =TerminalCreationDetails;
    @observable terminalCreationList= new Map();
    isLoading=true;
    @observable terminalCreationLists=new Map<any, any>();   
    @observable isEdit=false;
    @observable TerminalCreationStatus = new Map();
    @observable TerminalDetls = new Map();
    @observable entryNo= new Map();
    @observable terminalList=new Map();

    constructor(){
        makeObservable(this);
    }

     saveTerminalCreation = async (terminalCreation: ITerminalCreation) => {
        try {
            
           const status=  await apiAgent.terminalCreation.saveTerminalCreation(terminalCreation);           
           if(status!==undefined){
            return status;           
           }          
        }
        catch (error) {
            console.log(error);
        }
    }
    getTerminal  = async (SearchOptions)=> {
        try {
          const terminalCreationData=await apiAgent.terminalCreation.getTerminal(SearchOptions);
            this.terminalCreationList = terminalCreationData;
            
            runInAction(() => {
                this.terminalCreationList = terminalCreationData;
                
            })
            return this.terminalCreationList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    
    getTerminalCreation = async ()=>{
        try{
            const terminalCreationData = await apiAgent.terminalCreation.getTerminalCreation();
            this.terminalCreationList = terminalCreationData;
            
            runInAction(()=>{
                this.terminalCreationList = terminalCreationData;
                this.isLoading=false;
            })
            return this.terminalCreationList;
        }
        catch(error){
            console.log(error);
    }
   }
   updateTerminalCreation = async (terminalCreation: ITerminalCreation) => {
    
    try {      
    const status=  await apiAgent.terminalCreation.updateTerminalCreation(terminalCreation);     
    runInAction(() => {
    this.TerminalCreationStatus = status;
    });

    return this.TerminalCreationStatus;
    }
    catch (error) {
        console.log(error);
    }
}
deleteTerminalCreation = async (terminalId) => {
    try {      
    const status=  await apiAgent.terminalCreation.deleteTerminalCreation(terminalId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}
TerminalDetails  = async (terminalId:Number)=> {
    
    try {
    const teminalData= await apiAgent.terminalCreation.TerminalDetails(terminalId);
        
      return teminalData;
    }
    catch (error) {
        console.log(error);
    }
}
getEntryNo = async (flag)=>{
    try{
        const entryNo = await apiAgent.terminalCreation.getEntryNo(flag);
        
        runInAction(()=>{
            this.entryNo = entryNo;
        })
        return this.entryNo;
    }
    catch(error){
    console.log(error);
    }
}
}

export default TerminalCreationStore;