import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import AutoComplete from "../../common/shared/autoComplete";
// import { IMembershipSave, IMemebershipDetails } from "./model";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useStores from "../../hooks";
import { useNavigate } from "react-router-dom";
import { removeDuplicates } from "../../common/shared/utils";
import { IAssignMemberShipSave } from "./model";
import { SubmitValidation } from "./validation";
import { IConfirmPaymentPayload, IPaymentRequest } from "../../Admin/POS/model";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';

const AssignMembership = () => {

    const { supplierMasterStore, memberStore, posMasterStore } = useStores()
    const navigate = useNavigate();
    const { assignId } = useParams<{ assignId: string }>();
    const { getMembershipPlan, memberPlanList, getNewmember, assignMembershipSave, assignMembershipEdit } = memberStore;


    const [assignMemberSave, setAssignMemberSave] = useState<IAssignMemberShipSave>(new IAssignMemberShipSave())
    console.log(assignMemberSave, 'assignMemberSave');

    const [newmemberList, setnewmemberList] = useState<any[]>([])

    const [memberDetail, setMemberDetail] = useState<IPaymentRequest>({})

    const [isEligible, setEligible] = useState({ bool: false, message: '' })

    const [errors, setError] = useState<any>({})
    const [isLoading, setLoading] = useState(true)
    const [isSpinner, setSpinner] = useState(false)

    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function handleInputChange(e) {
        debugger
        const { name, value } = e.target;
        if (name === 'effectfrom' && value != null) {
            let expiryDate = new Date(value);
            expiryDate.setDate(expiryDate.getDate() + Number(assignMemberSave.duration!))
            setAssignMemberSave({ ...assignMemberSave, effectfrom: value, effectto: moment(expiryDate).format('YYYY-MM-DD') })
        } else if (name === "paymemntmode" && value != null) {
            let effecfromDate = moment(assignMemberSave.effectfrom, 'YYYY-MM-DD').toDate();
            let expiryDate = new Date(effecfromDate);
            expiryDate.setDate(expiryDate.getDate() + Number(assignMemberSave.duration || 0));
            setAssignMemberSave({ ...assignMemberSave, [name]: value, effectto: moment(expiryDate).format('YYYY-MM-DD') })
        }
    }

    function handleAutoComplete(name, value) {
        if (name === "memberid" && value != null) {
            setAssignMemberSave({ ...assignMemberSave, memberid: value?.memberId?.toString(), mobileno: value?.mobileNo })
            let address = value?.doorNo! + ',' + value?.area! + ',' + value?.city! + ',' + value?.state! + ',' + value?.pincode!
            setMemberDetail({ ...memberDetail, name: value?.memberName, email: value?.email, phoneNumber: value?.mobileNo, address: address, })
        } else if (name === "planid" && value != null) {
            if (checkEligible(value?.PlanName) == 'member not choosed') {
            } else if (checkEligible(value?.PlanName) === "eligible") {
                setAssignMemberSave({ ...assignMemberSave, planid: value?.Membershipid, duration: value?.Duration, amount: value?.Amount })
            } else {
                setEligible({ ...isEligible, bool: true, message: 'You are not eligible for B2B Plan.' });
            }
        } else if (name === "duration" && value != null) {
            setAssignMemberSave({ ...assignMemberSave, duration: value?.Duration, amount: value?.Amount })
            setMemberDetail({ ...memberDetail, amount: value?.Amount })
        }
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }


    async function submitFunc() {
        let error: any = {};
        error = SubmitValidation(assignMemberSave)
        setError(error)


        if (Object.keys(error).length === 0) {

            setLoading(true)
            if (assignMemberSave?.paymemntmode === "Online") {
                onlinePayment();
            } else {
                saveApiCall()
            }
        }
    }

    function checkEligible(planName: string): string {
        let splitName = planName?.split('-');
        if (splitName[0] == 'B2C') {
            if (!assignMemberSave?.memberid) {
                setEligible({ ...isEligible, bool: true, message: 'Choose Member First' });
                return 'member not choosed';
            } else {
                return 'eligible';
            }
        } else if (splitName[0] == 'B2B') {
            if (!assignMemberSave?.memberid) {
                setEligible({ ...isEligible, bool: true, message: 'Choose Member First' });
                return 'member not choosed';
            } else {
                let findObj = newmemberList?.find((member) => {
                    if (member?.memberId == assignMemberSave?.memberid) {
                        return member;
                    }
                })
                //   let bool = findObj?.totalBills >= 3;
                let bool = true;
                return bool ? 'eligible' : '';
            }
        }
        return '';
    }

    async function saveApiCall() {
        if (assignId !== undefined) {
            const status = await memberStore.assignMembershipEdit(assignMemberSave);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
        else {
            const status = await memberStore.assignMembershipSave(assignMemberSave);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }

    }

    async function onlinePayment() {

        const response = await posMasterStore.ProceedPayment(memberDetail);

        const options = {
            amount: memberDetail.amount,
            name: 'Ganga Super Market',
            description: 'Ganga Super Market',
            image: '../../../image/Ganga_SuperMarket-1.png',
            order_id: response?.orderId,
            handler: async (response) => {
                setSpinner(true)
                let confirmPaymentPayload: IConfirmPaymentPayload = {}
                confirmPaymentPayload.razorpay_order_id = response?.razorpay_order_id;
                confirmPaymentPayload.razorpay_payment_id = response?.razorpay_payment_id;
                confirmPaymentPayload.razorpay_signature = response?.razorpay_signature;

                const staus = await posMasterStore.confirmPayment(confirmPaymentPayload);

                if (staus === 'Payment Successful') {
                    //   savePOSMaster();
                    saveApiCall()
                }
            },
            prefill: {
                name: memberDetail?.name,
                email: memberDetail?.email
            },
            theme: {
                color: '#DC3545'
            }
        };

        const _window = window as any;
        const rzp1 = new _window.Razorpay(options);
        rzp1.open();

        setSpinner(false)
    }

    const handleSuccessModalClose = () => {
        navigate('/Admin/AssignMembershipList')
        setSuccessModal(false);
    }


    async function fetchpreLoadingData() {
        setLoading(false);
        const [newMemberList] = await Promise.all([getNewmember(), getMembershipPlan(0)]);
        setnewmemberList([...newMemberList]);
    }

    async function getMemberDet() {
        if (assignId !== undefined) {
            const membershipPlanList = await memberStore.getAssignMebership(assignId);
            const filterPlanList = membershipPlanList.filter((planval) => planval.AssignMembershipid == assignId)
            console.log(filterPlanList, 'filterPlanList');

            if (filterPlanList && filterPlanList.length > 0) {
                filterPlanList.forEach(plan => {
                    const updatedMasters = {
                        ...assignMemberSave,
                        assignMembershipid: plan.AssignMembershipid,
                        memberid: plan.Memberid.toString(),
                        mobileno: plan.Mobileno.toString(),
                        planid: plan.planid,
                        //    duration: plan.PlanName,
                        amount: plan.Amount,
                        effectfrom: formatDate(plan.Effectfrom),
                        effectto: formatDate(plan.Effectto),
                        paymemntmode: plan.Paymemntmode,
                        memberName: plan.membername,
                        planName: plan.PlanName
                    };

                    //     console.log(updatedMasters, 'updatedMasters');

                    setAssignMemberSave(updatedMasters);


                    //     setMemberDetailObj(prevState => ({
                    //         ...prevState,
                    //         ...updatedDetails
                    //     }));
                });


                // console.log(memberShipArr, 'memberShipArr'); 
                // console.log(memberDetailObj, 'memberDetailObj'); 
            }
        }
    }

    const isInitialRender = useRef(true);

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         fetchpreLoadingData();
    //         getMemberDet();
    //         isInitialRender.current = false;
    //     }
    //     return () => { };
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Assign_membership';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchpreLoadingData(); 
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/outlet/list');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    const handleFailureModalClose = () => setFailureModal(false);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {

        return (
            <>
                {/* {
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>} */}
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox '>
                        <div className='hrBox'>
                            <h3>Assign Membership</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='row'>
                                {isSpinner &&
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div>}
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Member <span>*</span></label>
                                        <AutoComplete options={newmemberList} placeholder={'Member...'}
                                            value={assignMemberSave?.memberName}
                                            emitOption={(option: any) => handleAutoComplete('memberid', option)}
                                            getOptionLabel='memberName' />
                                        {errors.memberid && <p style={{ color: 'red' }}>{errors.memberid}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Mobile No<span>*</span> </label>
                                        <input type="text" className='' disabled style={{ width: "100%" }} name='mobileno'
                                            value={assignMemberSave?.mobileno} placeholder='Mobile No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Plan <span>*</span></label>
                                        <AutoComplete options={removeDuplicates(memberPlanList, 'Membershipid')} placeholder={'Plan...'}
                                            value={assignMemberSave?.planName}
                                            emitOption={(option: any) => handleAutoComplete('planid', option)}
                                            getOptionLabel='PlanName' />
                                        {errors.planid && <p style={{ color: 'red' }}>{errors.planid}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                {assignMemberSave?.planid &&
                                    <>
                                        <div className='col-md-6'>
                                            <div className='inputBox'>
                                                <label>Duration <span>*</span></label>
                                                <AutoComplete options={memberPlanList?.filter((obj) => obj?.Membershipid === assignMemberSave?.planid)}
                                                    placeholder={'Duration...'}
                                                    emitOption={(option: any) => handleAutoComplete('duration', option)}
                                                    getOptionLabel='Duration' />
                                                {errors.duration && <p style={{ color: 'red' }}>{errors.duration}</p>}
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='inputBox'>
                                                <label>Amount<span>*</span> </label>
                                                <input type="text" className='' disabled style={{ width: "100%" }} name='amount'
                                                    value={assignMemberSave?.amount} placeholder='Amunt..'></input>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </div>
                                    </>}
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Purchase Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                            name='effectfrom'
                                            onChange={handleInputChange}
                                            value={assignMemberSave.effectfrom = assignMemberSave.effectfrom ?? new Date().toISOString().substr(0, 10)}
                                            data-date="" data-date-format="DD MMMM YYYY"></input>
                                        {errors.effectfrom && <p style={{ color: 'red' }}>{errors.effectfrom}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='inputBox'>
                                        <label>Effective To <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                            name='effectto'
                                            value={assignMemberSave.effectto = assignMemberSave.effectto}
                                            disabled
                                            data-date="" data-date-format="DD MMMM YYYY"></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='inputBox'>
                                        <label>Paymode<span>*</span></label>
                                        <select className="form-select" name="paymemntmode" onChange={handleInputChange} value={assignMemberSave?.paymemntmode}>
                                            <option value="">Not Select</option>
                                            <option value="Cash">Cash</option>
                                            <option value="Online">Online</option>
                                            {/* <option value="Card">Card</option> */}
                                        </select>
                                        {errors.paymemntmode && <p style={{ color: 'red' }}>{errors.paymemntmode}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>

                            <div className='vertical-space-10'></div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                                <button className='dfBtn' type='submit' onClick={submitFunc} >Submit</button>
                            </div>

                        </div>

                        <Modal show={isEligible?.bool} onHide={() => setEligible({ bool: false, message: '' })} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>

                                    <div className='imgBox'>
                                        <Image src={require('../../../../image/warning.png')} />
                                    </div>
                                    <h4>{isEligible?.message}</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setEligible({ bool: false, message: '' })}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../../image/checked.png')} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>

                                    <div className='imgBox'>
                                        <Image src={require('../../../../image/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </>
        )
    }
}

export default AssignMembership;