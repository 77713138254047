
function invoiceValidation(invoice, invoiceAddress, isAgainstPo) {

  let errors: any = {};

  debugger
  if (!invoice.invoiceDate) {
    errors.invoiceDate = "Select Invoice Date";
  }
  if (!invoice.memberId && isAgainstPo == "") {
    errors.memberId = "Select Customer";
  }
  if (!invoice.paymentMode) {
    errors.paymentMode = "Select Payment Mode";
  }
  if (invoice.memberId && isAgainstPo === "") {
    if (!invoiceAddress.invoiceAddressId && Object.keys(invoiceAddress).length === 0) {
      errors.addressId = "Select/Add Address";
    }
  }
  debugger
  if (!invoice.salesOrderNo && isAgainstPo != "") {
    errors.salesOrderNo = "Select Sales Order No";
  }
  if (!invoice.outletId) {
    errors.outletId = "Select Outlet Name";
  }
  return errors;
};

export default invoiceValidation;