import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import { useNavigate } from "react-router-dom"
import { Button, Navbar, Nav, Container, NavDropdown, Form, Image, Carousel, InputGroup, Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cookies from 'js-cookie';

const Header = ((): JSX.Element => {
  const [isLoggetIn, setLoggedIn] = useState(false);
  const [isAdminLoggetIn, setAdminLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleCartDetails = () => {

    navigate('/cartsview');
  }

  const handleLogout = () => {
    Cookies.remove('ProductCarts');
    Cookies.remove('memberName');
    Cookies.remove('memberId');
    Cookies.remove('cartCount');
    navigate('/')
  }

  useEffect(() => {
    if (Cookies.get('ProductCarts') !== undefined) {
      setLoggedIn(false);
      setAdminLoggedIn(false);
    }
    if (Cookies.get('memberName') !== undefined) {
      setLoggedIn(true);
      setAdminLoggedIn(false);
    } else {
      setLoggedIn(false);
      setAdminLoggedIn(false);
    }
  }, [])

  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 }
  ];
  return (
    <div className='header' >


      <Navbar expand="lg" className="">
        <Container fluid>
          <Navbar.Brand href="http://localhost:3000"><Image src={require("../../gsmecom/images/GangaSupermarket.png")} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            {/* <Nav
              className=""
              style={{ maxHeight: '46x', width:'30%' }}
              navbarScroll
            >
              <div className='locationInputBox' style={{ width: '100%'}}>
                <Autocomplete size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={top100Films}
                  // getOptionLabel={(option: any) => option.offerName}
                  renderInput={(params: any) =>
                    <TextField  {...params} style={{ width: '100%', color: '#fff' }}
                      id="outlined-size-small"
                      color='info'
                      size="small"
                      type="text"
                      placeholder='Select Supplier..'
                    />}
                />
              </div>
            </Nav> */}
            <Form className="searchInputBox">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success"><FontAwesomeIcon icon={faSearch} /></Button>
            </Form>
            {
              isLoggetIn ?
                <div className='menuHeader'>
                  <div className='maindropdown'>
                    <div className="dropdown">
                      <button className="dropbtn2"> <Image src={require('../../../image/user.png')} rounded />&nbsp; {Cookies.get('memberName')}</button>
                      <div className="dropdown-content" style={{ fontSize: '14px' }}>
                        <a href="accountClick">Your Account</a>
                        <a href="trackingorder">Your Order</a>
                        <a href="wishlist">Your wish List</a>
                        <a href="#">Your Recommendations</a>
                        <a href="#">Your Membership</a>
                        <a href="#">Switch Account</a>
                        <a href="#">Your Subscribe & Item</a>
                        <a href="#">Your Shopping List</a>
                        <a href="#" onClick={handleLogout} >Sign Out</a>
                      </div>
                    </div>
                  </div>
                </div>
                // <Navbar.Collapse id="basic-navbar-nav">
                //   <Nav className="">
                //     <Dropdown>
                //         <Dropdown.Toggle id="dropdown-basic">
                //         <Image src={require('../../../image/user.png')} rounded />&nbsp; {Cookies.get('memberName')}
                //         </Dropdown.Toggle>
                //         <Dropdown.Menu>
                //         <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>                    
                //         <Dropdown.Item  onClick={handleLogout}>Log Out</Dropdown.Item>                    
                //         </Dropdown.Menu>
                //     </Dropdown>
                //   </Nav>
                // </Navbar.Collapse>
                : <Nav.Link href="" className='LoginBtn' style={{ visibility: (Cookies?.get('memberId') != undefined) ? 'hidden' : 'visible' }} onClick={() => navigate("/Login")}>Login</Nav.Link>

            }

            {
              !isAdminLoggetIn ? <Nav.Link href="" className='LoginBtn' onClick={() => navigate("/Admin")}>Admin</Nav.Link>
                : null
            }

          </Navbar.Collapse>
          <Button className='cartBtn' onClick={handleCartDetails}>

            <Image src={require("../../gsmecom/images/cart.png")} />
            {/* <i className="fa" ></i> */}
            <span className='badge badge-warning' id='lblCartCount' > {Cookies.get('cartCount') ? Cookies.get('cartCount') : null} </span>
          </Button>
        </Container>
      </Navbar>
      <div className='menuHeader'>
        <div className='maindropdown'>
          <div className="dropdown">
            <button className="dropbtn">Home</button>
            <div className="dropdown-content">
              <a href="#">Specials</a>
              <a href="#">Account</a>
              <a href="#">Card</a>
              <a href="#">Checkout</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">Cooking Materials</button>
            <div className="dropdown-content">
              <a href="#">Grains & Rice </a>
              <a href="#">Pulses</a>
              <a href="#">Spices & Nuts</a>
              <a href="#">Flous & Masalas</a>
              <a href="#">Oil & Condiments</a>
              <a href="#">Ready For Cook </a>
              <a href="#">Diary Products </a>
              <a href="#">Other Ingredients </a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">Beverages Mix & Snacks</button>
            <div className="dropdown-content">
              <a href="#">Tea & Coffee</a>
              <a href="#">Health Drinks</a>
              <a href="#">Snacks</a>
              <a href="#">Biscuits</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">Personal Care</button>
            <div className="dropdown-content">
              <a href="#">Oral Care</a>
              <a href="#">Body Care</a>
              <a href="#">Hair Care</a>
              <a href="#">Face Care</a>
              <a href="#">Baby Care</a>
              <a href="#">Shaving & Hair Removal</a>
              <a href="#">Feminine Hygiene</a>
            </div>
          </div>
          <div className="dropdown">
            <button className="dropbtn">House Hold Care & Cleaning</button>
            <div className="dropdown-content">
              <a href="#">Laundry Detergents</a>
              <a href="#">Utensil Cleaning / Dish Wash</a>
              <a href="#">Household Cleaners & Freshener</a>
              <a href="#">Cleaning Tools</a>
              <a href="#">Insect Repellents</a>
              <a href="#">Pooja / Devotional Products</a>
              <a href="#">Miscellaneous </a>
              <a href="#">Stationary</a>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
})

export default Header;
