import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes, Link, useNavigate } from 'react-router-dom';
import { Nav, Accordion, Navbar, Container, Button, InputGroup, Form, NavDropdown, Dropdown, Image, Placeholder, Modal } from "react-bootstrap";
import { faAdd, faBars, faHome, faJetFighterUp, faHomeAlt, faPenToSquare, faSearch, faTrash, faTrashAlt, faUser, faXmark, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardDetail from './DashboardDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './styles/fontstyle.css';
import Outlet from './views/gsmadmin/Admin/Outlet/Outlet';
import Warehouse from './views/gsmadmin/Admin/warehouse/warehouse';
import DashBoard from './DashBoard';
import NewMemberRegistration from './views/gsmadmin/BackOffice/NewMember/NewMemberRegistration';
import ItemMaster from './views/gsmadmin/Warehouse/ItemMaster/itemMaster'
import SupplierMaster from './views/gsmadmin/Warehouse/SupplierMaster/supplierMaster';
import DepartmentMaster from './views/gsmadmin/HR/Department/DepartmentMaster';
import DesignationMaster from './views/gsmadmin/HR/Desigination/DesignationMaster';
import Brand from './views/gsmadmin/Warehouse/Brand/Brand';
import ItemInward from './views/gsmadmin/Warehouse/itemInward/itemInward';
import NewMember from './views/gsmadmin/BackOffice/NewMember/NewMemberRegistration';
import OutletList from './views/gsmadmin/Admin/Outlet/OutletList';
import WarehouseList from './views/gsmadmin/Admin/warehouse/WarehouseList';
import NewMemberList from './views/gsmadmin/BackOffice/NewMember/NewMemberList';
import BrandList from './views/gsmadmin/Warehouse/Brand/BrandList';
import SupplierMasterList from './views/gsmadmin/Warehouse/SupplierMaster/supplierMasterList';
import DepartmentList from './views/gsmadmin/HR/Department/DepartmentList';
import DesignationList from './views/gsmadmin/HR/Desigination/DesignationList';
import ItemInwardDetail from './views/gsmadmin/Warehouse/itemInward/itemInwardDetails';
import ItemMasterDetail from './views/gsmadmin/Warehouse/ItemMaster/itemMasterDetails';
import InwardOutlet from './views/gsmadmin/Stores/InwardOulet/InwardOutlet';
import ManagePriceChange from './views/gsmadmin/CRM/PriceChangeUpdate/ManagePriceChange';
import AssignOfferDicount from './views/gsmadmin/CRM/AssignOfferDiscount/AssignOfferDiscount';

import UnitOfMeasurementList from './views/gsmadmin/Warehouse/UnitOfMeasure/UnitOfMeasurementList';
import AssignOfferDicountList from './views/gsmadmin/CRM/AssignOfferDiscount/AssignOffetDiscountList';
import ManagePriceChangeList from './views/gsmadmin/CRM/PriceChangeUpdate/ManagePriceChangeList';
import OutwardToOutlet from './views/gsmadmin/Warehouse/OutwardtoOutlet/OutwardToOutlet';
import OutwardToOutletDetail from './views/gsmadmin/Warehouse/OutwardtoOutlet/OutwardToOutletDetail';
import AssignBatchNo from './views/gsmadmin/Warehouse/assignBatchNo/assignBatchNo';
import AssignBatchNoDetail from './views/gsmadmin/Warehouse/assignBatchNo/AssignBatchNoDetail';
import UnitOfMeasure from './views/gsmadmin/Warehouse/UnitOfMeasure/UnitOfMeasure';
import Employee from './views/gsmadmin/HR/Employee/Employee';
import POS from './views/gsmadmin/Admin/POS/Pos';
import InwardOutletDetail from './views/gsmadmin/Stores/InwardOulet/InwardOutletDetail';
import AssignOutletManager from './views/gsmadmin/Admin/assignOutletManager/AssignOutletManager';
import AssignOutletManagerDetail from './views/gsmadmin/Admin/assignOutletManager/AssignOutletManagerDetail';
import AssignWarehouseManager from './views/gsmadmin/Admin/assignWareHouseManager/AssignWarehouseManager';
import AssignWarehouseManagerDetail from './views/gsmadmin/Admin/assignWareHouseManager/AssignWarehouseManagerDetail';
import AssignPOSStaffDetail from './views/gsmadmin/BackOffice/assignPOSStaff/AssignPOSStaffDetail';
import AssignPOSStaff from './views/gsmadmin/BackOffice/assignPOSStaff/AssignPOSStaff';
import SubCategory from './views/gsmadmin/Warehouse/SubCategory/SubCategory';
import SubCategoryDetail from './views/gsmadmin/Warehouse/SubCategory/SubCategoryDetail';

import RackAllotment from './views/gsmadmin/Stores/rackAllotment/RackAllotment';
import RackAllotmentDetail from './views/gsmadmin/Stores/rackAllotment/RackAllotmentDetail';
import LedgerGroup from './views/gsmadmin/Accounts/LedgerGroup/LedgerGroup';
import LedgerMaster from './views/gsmadmin/Accounts/LedgerMaster/LedgerMaster';
import LedgerGroupDetail from './views/gsmadmin/Accounts/LedgerGroup/LedgerGroupDetail';
import LedgerMasterDetail from './views/gsmadmin/Accounts/LedgerMaster/LedgerMasterDetail';
import OutletItemRequest from './views/gsmadmin/Stores/outletItemRequest/OutletItemRequest';
import OutletRequestItemDetails from './views/gsmadmin/Stores/outletItemRequest/OutletItemRequestDetail';
import UserCreation from './views/gsmadmin/Admin/userCreation/UserCreation';
import Employeelist from './views/gsmadmin/HR/Employee/Employeelist';
import ManageOffersOrDiscounts from './views/gsmadmin/CRM/ManageOfferOrDiscount/ManageOfferOrDiscount';
import ManageOffersOrDiscountsList from './views/gsmadmin/CRM/ManageOfferOrDiscount/ManageOfferOrDiscountList';
import ItemConversion from './views/gsmadmin/Warehouse/itemConversion/ItemConversion';
import ItemConversionDetail from './views/gsmadmin/Warehouse/itemConversion/ItemConversionDetail';
import TeamMaster from './views/gsmadmin/Warehouse/teamMaster/TeamMaster';
import TeamMasterDetail from './views/gsmadmin/Warehouse/teamMaster/TeamMasterDetail';
import PackingDistribution from './views/gsmadmin/Warehouse/packingDistribution/PackingDistribution';
import PackingDistributionDetail from './views/gsmadmin/Warehouse/packingDistribution/PackingDistributionDetail';
import PackingInward from './views/gsmadmin/Warehouse/packingInward/PackingInward';
import PackingInwardDetail from './views/gsmadmin/Warehouse/packingInward/PackingInwardDetail';
import UserCreationList from './views/gsmadmin/Admin/userCreation/UserCreationDetail';

import AssignBatchPacking from './views/gsmadmin/Warehouse/assignBatchPacking/AssignBatchPacking';
import AssignBatchPackingDetail from './views/gsmadmin/Warehouse/assignBatchPacking/AssignBatchPackingDetail';
import TerminalCreation from './views/gsmadmin/BackOffice/terminalCreation/TerminalCreation';
import TerminalCreationDetail from './views/gsmadmin/BackOffice/terminalCreation/TerminalCreationDetail';
import ShiftMaster from './views/gsmadmin/BackOffice/shiftMaster/ShiftMaster';
import ShiftMasterDetail from './views/gsmadmin/BackOffice/shiftMaster/ShiftMasterDetail';
import SellingPriceUpdate from './views/gsmadmin/Admin/sellingPriceUpdate/SellingPriceUpdate';
import Home from './views/gsmecom/Home/Home';
import Registration from './views/gsmecom/Login/Registration';
import Login from './views/gsmecom/Login/Login';
import OTP from './views/gsmecom/Login/OTP';
import Category from './views/gsmecom/Category/Category';
import Forgot from './views/gsmecom/Login/Forgot';

import { render } from 'react-dom'
import InvoicePrint from './views/gsmadmin/Admin/Invoice';
import AssignProductGroupingDetail from './views/gsmadmin/Admin/assignProduct/AssignProductGroupingDetail';
import AssignProductGrouping from './views/gsmadmin/Admin/assignProduct/AssignProductGrouping';
import Products from './views/gsmecom/Products/product';
import Carts from './views/gsmecom/cart/Cart';
import CheckOut from './views/gsmecom/CheckOut/checkout';
import ManageLoyalty from './views/gsmadmin/CRM/ManageLoyalty/ManageLoyalty';
import ManageLoyaltyList from './views/gsmadmin/CRM/ManageLoyalty/ManageLoyaltyList';
import AssignRedemption from './views/gsmadmin/CRM/AssignRedemption/AssignRedemption';
import AssignRedemptionList from './views/gsmadmin/CRM/AssignRedemption/AssignRedemptionList';
import AccountClick from './views/gsmecom/Account/AccountClick';
import TrackingOrder from './views/gsmecom/TrackingOrder/TrackingOrder';
import TrackingPackage from './views/gsmecom/TrackingPackage/TrackingPackage';
import WishList from './views/gsmecom/WishList/WaihList';
import StockAnalysis from './views/gsmadmin/Reports/StockAnalysis/StockAnalysis';
import AssignOrder from './views/gsmadmin/Admin/AssignOrder/AssignOrder';
import AssignOrderDetail from './views/gsmadmin/Admin/AssignOrder/AssignOrderDetail';
import DespatchEntry from './views/gsmadmin/Stores/despatchEntry/DespatchEntry';
import DespatchEntryDetail from './views/gsmadmin/Stores/despatchEntry/DespathEntryDetail';
import OutletStockAnalysis from './views/gsmadmin/Reports/OutetStockAnalysis/OutletStockAnalysis';
import CourierPartnerCreation from './views/gsmadmin/Admin/CourierPartnerCreation/CourierPartnerCreation';
import CourierPartnerCreationDetail from './views/gsmadmin/Admin/CourierPartnerCreation/CourierPartnerCreationDetail';
import AdminLogin from './views/gsmadmin/Login/AdminLogin';
import Cookies from 'js-cookie';
import DamageEntryOutlet from './views/gsmadmin/Warehouse/DamageEntryOutlet/DamageEntryOutlet';
import DamageEntryOutletDetail from './views/gsmadmin/Warehouse/DamageEntryOutlet/DamageEntryOutletDetail';
import DamageEntryWarehouse from './views/gsmadmin/Warehouse/DamageEntryWarehouse/DamageEntryWarehouse';
import GenerateInvoiceDetail from './views/gsmadmin/Admin/GenerateInvoice/GenerateInvoice';
import SalesRegister from './views/gsmadmin/Admin/SalesRegister/SalesRegister';
import DamageEntryWarehouseDetail from './views/gsmadmin/Warehouse/DamageEntryWarehouse/DamageEntryWarehouseDetail';
// import InwardRegister from './views/gsmadmin/Admin/InwardRegister/inwardRegister';
import CourierEntry from './views/gsmadmin/Stores/CourierEntry/CourierEntry';
import CourierEntryDetail from './views/gsmadmin/Stores/CourierEntry/CourierEntryDetail';
import ReceiptEntry from './views/gsmadmin/Accounts/ReceiptEntry/ReceiptEntry';
import AssignFreeItem from './views/gsmadmin/CRM/AssignFreeItem/AssignFreeItem';
import SalesReturnOrReplacement from './views/gsmadmin/BackOffice/SalesReturnOrReplacement/SalesReturnOrReplacement';
import Invoice from './views/gsmadmin/BackOffice/Invoice/Invoice';
import AssignFreeItemList from './views/gsmadmin/CRM/AssignFreeItem/AssignFreeItemList';
import SalesReturnOrReplacementList from './views/gsmadmin/BackOffice/SalesReturnOrReplacement/SalesReturnOrReplacementList';
import MemberPointsStatement from './views/gsmadmin/Admin/MemberPointsStatement/MemberPointsStatement';
import PartyLedger from './views/gsmadmin/Admin/PartyLedger/PartyLedger';
import MinimumStockReport from './views/gsmadmin/Admin/MinmumStockLevel/MinimumStockReport';
import PurchaseReturn from './views/gsmadmin/BackOffice/PurchaseReturn/PurchaseReturn';
import Indent from './views/gsmadmin/Warehouse/Indent/Indent';
import IndentDetail from './views/gsmadmin/Warehouse/Indent/IndentDetail';
import CounterOpeningEntry from './views/gsmadmin/BackOffice/CounterOpeningEntry/CounterOpeningEntry';
import ClosingCashHandover from './views/gsmadmin/BackOffice/ClosingCashHandover/ClosingCashHandover';
import ClosingCashHandoverDetail from './views/gsmadmin/BackOffice/ClosingCashHandover/ClosingCashHandoverDetail';
import CounterOpeningEntryDetail from './views/gsmadmin/BackOffice/CounterOpeningEntry/CounterOpeningEntryDetail';
import PaymentVoucher from './views/gsmadmin/Accounts/PaymentVoucher/PaymentVoucher';
import PaymentVoucherDetail from './views/gsmadmin/Accounts/PaymentVoucher/PaymentVoucherDetail';
import PurchaseReturnDetail from './views/gsmadmin/BackOffice/PurchaseReturn/PurchaseReturnDetail';
import PartyLedgerDetail from './views/gsmadmin/Admin/PartyLedger/PartyLedgerDetail';
import InvoiceDetail from './views/gsmadmin/BackOffice/Invoice/InvoiceDetail';
import ReceiptEntryDetail from './views/gsmadmin/Accounts/ReceiptEntry/ReceiptEntryDetail';
import PurchaseBill from './views/gsmadmin/Accounts/PurchaseBill/PurchaseBill';
import PurchaseBillDetail from './views/gsmadmin/Accounts/PurchaseBill/PurchaseBillDetail';
import QuoteFromSupplier from './views/gsmadmin/Admin/QuoteFromSupplier/QuoteFromSupplier';
import QuoteFromSupplierDetail from './views/gsmadmin/Admin/QuoteFromSupplier/QuoteFromSupplierDetail';
import PurchaseApproval from './views/gsmadmin/Warehouse/PurchaseApproval/PurchaseApproval';
import PurchaseApprovalDetail from './views/gsmadmin/Warehouse/PurchaseApproval/PurchaseApprovalDetail';
import PurchaseOrder from './views/gsmadmin/Warehouse/PurchaseOrder/PurchaseOrder';
import DirectInwardDetail from './views/gsmadmin/Stores/directInwardOutlet/directInwardDetails';
import DirectInwardOutlet from './views/gsmadmin/Stores/directInwardOutlet/directInward';
import DirectInwardOutletDetail from './views/gsmadmin/Stores/directInwardOutlet/directInwardDetails';
// import SalesSummary from './views/gsmadmin/Reports/SalesSummaryItemWise/SalesSummary';
import StockSummary from './views/gsmadmin/Reports/StockSummary/StockSummary';
import DashBoards from './DashBoards';
import OutletStockSummary from './views/gsmadmin/Reports/OutletStockSummary/OutletStockSummary';
import SalesSummaryItemWise from './views/gsmadmin/Reports/SalesSummaryItemWise/SalesSummaryItemWise';
import PurchaseBillRegister from './views/gsmadmin/Reports/PurchaseBillRegister/PurchaseBillRegister';
import CostomerFeedback from './views/gsmadmin/CRM/CustomerFeedBack/CustomerFeedback';
import ExpiryRegister from './views/gsmadmin/Reports/ExpiryRegister/ExpiryRegister';
import InwardRegister from './views/gsmadmin/Reports/InwardRegister/inwardRegister';
import PurchaseOrderList from './views/gsmadmin/Warehouse/PurchaseOrder/PurchaseOrderList';
import FactoryIcon from '@mui/icons-material/Factory';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import BarcodePriceHistory from './views/gsmadmin/Reports/BarcodePriceHistory/BarcodePriceHistory';
import ItemwiseMargin from './views/gsmadmin/Reports/ItemwiseMargin/ItemwiseMargin';
import PriceListing from './views/gsmadmin/Reports/PriceListing/PriceListing';
import RepackRegister from './views/gsmadmin/Reports/RepackRegister/RepackRegister';
import SalesReturnRegister from './views/gsmadmin/Reports/SalesReturnRegister/SalesReturnRegister';
import StockSummaryBatchwise from './views/gsmadmin/Reports/StockSummaryBatchwise/StockSummaryBatchwise';
import StockSummaryPickList from './views/gsmadmin/Reports/StockSummaryPickList/StockSummaryPickList';
import StockTakingImport from './views/gsmadmin/Reports/StockTakingImport/StockTakingImport';
import StockValueSummary from './views/gsmadmin/Reports/StockValueSummary/StockValueSummary';
import StockTransferInReg from './views/gsmadmin/Reports/StockTransferInReg/StockTransferInReg';
import StockTransgerOutReg from './views/gsmadmin/Reports/StockTransferOutReg/StockTransferOutReg';
import AssignNotificationRolls from './views/gsmadmin/Admin/AssignNotificationRolls/AssignNotificationRolls';
import BasicMenu from './views/gsmadmin/common/shared/languageTranslator';
import { initReactI18next, useTranslation } from "react-i18next";
import i18next from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import NotificationBar from './views/gsmadmin/common/shared/notificationBar';
import MachineMaster from './views/gsmadmin/Warehouse/machineMaster/machineMaster';
import PackingPlan from './views/gsmadmin/Warehouse/packingPlan/packingPlan';
import ProducationEntry from './views/gsmadmin/Warehouse/productionEntry/productionEntry';
import MachineMasterDetail from './views/gsmadmin/Warehouse/machineMaster/machineMasterDetail';
import PackingPlanList from './views/gsmadmin/Warehouse/packingPlan/packingPlanList';
import ProductionEntryList from './views/gsmadmin/Warehouse/productionEntry/productionEntryDetail';
// import ItemInWards from './views/gsmadmin/Warehouse/itemInward/itemInwards';
import JournalEntry from './views/gsmadmin/Accounts/JournalEntry/journalEntry';
import StockToWarehouse from './views/gsmadmin/Warehouse/stocktoWarehouse/stocktoWarehouse';
import JournalEntryDetail from './views/gsmadmin/Accounts/JournalEntry/journalEntryDetail';
// import StockSummaryItemWise from './views/gsmadmin/Reports/StockSummaryItemwise/stockSummaryItemwise';
import NewItemRequest from './views/gsmadmin/Warehouse/newItemRequest/newItemRequest';
import StockSummaryWarehouse from './views/gsmadmin/Reports/stockSummaryWarehouse/stockSummaryItemwiseWarehouse';
import StockSummaryOutlet from './views/gsmadmin/Reports/StockSummaryOutlet/stockSummaryItemwiseOutlet';
import StockToWareHouseDetail from './views/gsmadmin/Warehouse/stocktoWarehouse/stockToWarehouseDetail';
// import DirectInWardOutlets from './views/gsmadmin/Stores/directInwardOutlet/directInwards';
import LoosedConversion from './views/gsmadmin/Warehouse/looseConversion/looseConversion';
import PosSale from './views/gsmadmin/Admin/POS/posSample';
import DamageTransfer from './views/gsmadmin/Stores/damageTransfer/damageTransfer';
import DamageEntryApproval from './views/gsmadmin/Warehouse/damageEntryApproval/damageEntryApproval';
import LoosedConversionDetail from './views/gsmadmin/Warehouse/looseConversion/looseConversionDetail';
import DamageTransferDetail from './views/gsmadmin/Stores/damageTransfer/damageTransferDetail';
import UnfoldMoreDoubleIcon from '@mui/icons-material/UnfoldMoreDouble';
import DamageEntryApprovalDetail from './views/gsmadmin/Warehouse/damageEntryApproval/damageEntryApprovalDetail';
import MembershipPlan from './views/gsmadmin/CRM/MembershipPlan/membershipPlan';
import AssignMembership from './views/gsmadmin/CRM/AssignMembership/AssignMembership';
import MembershipPlanList from './views/gsmadmin/CRM/MembershipPlan/membershipPlanList';
import AssignMembershipList from './views/gsmadmin/CRM/AssignMembership/AssignMembershipList';
import NewItemReqDetails from './views/gsmadmin/Warehouse/newItemRequest/newItemRequestDetail';
import AssignNotificationRollsList from './views/gsmadmin/Admin/AssignNotificationRolls/AssignNotificationRollsDetails';
import DebitNote from './views/gsmadmin/Accounts/DebitNote/debitNote';
import DebitNoteDetails from './views/gsmadmin/Accounts/DebitNote/debitNoteDetail';
import CreditNote from './views/gsmadmin/Accounts/CreditNote/creditNote';
import CreditNoteDetails from './views/gsmadmin/Accounts/CreditNote/creditNoteDetail';
import SellingPriceUpdateB2B from './views/gsmadmin/Admin/SellingPriceUpdateB2B/sellingPriceUpdateB2B';
import SellingPriceUpdateB2C from './views/gsmadmin/Admin/SellingPriceUpdateB2C/sellingPriceUpdateB2C';
import RejectionStock from './views/gsmadmin/Reports/RejectionStock/rejectionStock';
import RejectionConversion from './views/gsmadmin/Warehouse/RejectionConversion/rejectionConversion';
import RejectionStockList from './views/gsmadmin/Warehouse/RejectionConversion/rejectionConversionList';
//import ItemMaster from './views/gsmadmin/Warehouse/ItemMaster/itemMaster'
//const ItemMaster = React.lazy(() => import('./views/gsmadmin/Warehouse/ItemMaster/itemMaster'));

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import B2BVerificatioin from './views/gsmadmin/CRM/B2BVerfification/b2bverification';
import AssignBatchOutlet from './views/gsmadmin/NA/assignBatchOutlet/assignBatchOutlet';
import AssignBatchOutletDetail from './views/gsmadmin/NA/assignBatchOutlet/assignBatchOutletDetail';
import CategoryMaster from './views/gsmadmin/Payrol/CategoryMaster';
import IncentiveAndRecoveryMaster from './views/gsmadmin/Payrol/Incentive&RecoveryMaster';
import DeductionMaster from './views/gsmadmin/Payrol/DeductionMaster';
import CategoryMasterDetail from './views/gsmadmin/Payrol/CategoryMaster/CategoryMasterDetail';
import IncentiveAndRecoveryMasterList from './views/gsmadmin/Payrol/Incentive&RecoveryMaster/incentiveRecoveryDetail';
import DeductionMasterDetail from './views/gsmadmin/Payrol/DeductionMaster/DeductionMasterDetail';
import LeaveSettingMaster from './views/gsmadmin/Payrol/LeaveSettingMaster';
import LeaveSettingMasterDetail from './views/gsmadmin/Payrol/LeaveSettingMaster/LeaveSettingDetail';
import BreakingInOutEntry from './views/gsmadmin/Payrol/BreakInOutEntry';
import BreakInOutEntryDetail from './views/gsmadmin/Payrol/BreakInOutEntry/BreakInOutEntryDetail';
import ManualAttendanceEntry from './views/gsmadmin/Payrol/ManualAttendanceEntry';
import ManualAttendanceEntryDetail from './views/gsmadmin/Payrol/ManualAttendanceEntry/ManualAttendanceDetail';
import MDApprovalEntry from './views/gsmadmin/Payrol/MDApprovalEntry';
import MDApprovalEntryDetail from './views/gsmadmin/Payrol/MDApprovalEntry/MDApprovalEntryDetail';
import SalaryGeneration from './views/gsmadmin/Payrol/SalaryGeneration';
import SalaryGenerationDetail from './views/gsmadmin/Payrol/SalaryGeneration/SalaryGenerationDetail';
import LoanAndAdvanceRequest from './views/gsmadmin/Payrol/LoanAndAdvanceRequest';
import LoadAndAdvanceRequestDetail from './views/gsmadmin/Payrol/LoanAndAdvanceRequest/LoanAndAdvanceRequestDetail';
import LoanAndAdvanceApproval from './views/gsmadmin/Payrol/LoanAndAdvanceApproval';
import LoadAndAdvanceApprovalDetail from './views/gsmadmin/Payrol/LoanAndAdvanceApproval/LoanAndAdvanceApprovalDetail';
import JobMaster from './views/gsmadmin/HR/JobMaster/jobMaster';
import JobMasterList from './views/gsmadmin/HR/JobMaster/JobMasterList';
import JobVacancy from './views/gsmadmin/HR/JobVacancy/jobVacancy';
import JobVacancyList from './views/gsmadmin/HR/JobVacancy/JobVacancyList';
import VacancyApproval from './views/gsmadmin/HR/VacancyApproval/vacancyApproval';
import ResumeBank from './views/gsmadmin/HR/ResumeBank/ResumeBank';
import ResumeBankList from './views/gsmadmin/HR/ResumeBank/ResumeBankList';
import ResumeShortlisting from './views/gsmadmin/HR/ResumeShortlisting/resumeShortlisting';
import InterviewMaster from './views/gsmadmin/HR/InterviewMaster/interviewMaster';
import InterviewMasterList from './views/gsmadmin/HR/InterviewMaster/InterviewMasterList';
import InterviewLetter from './views/gsmadmin/HR/InterviewLetter/interviewLetter';
import InterviewProcess from './views/gsmadmin/HR/InterviewProcess';
import InterviewProcessList from './views/gsmadmin/HR/InterviewProcess/InterviewProcessList';
import SelectionProcess from './views/gsmadmin/HR/SelectionProcess';
import SelectionApproval from './views/gsmadmin/HR/SelectionApproval/selectionApproval';
import OfferLetterGeneration from './views/gsmadmin/HR/OfferLetterGeneration/offerLetterGeneration';
import AppointmentProcess from './views/gsmadmin/HR/AppointmentProcess/appoinmentProcess';
import GSTRAnnexure from './views/gsmadmin/Reports/GSTRAnnexure/gstrAnnexure';
import GSTRAnnexure2 from './views/gsmadmin/Reports/GSTRAnnexure2/gstrAnnexure2';
import PurchaseOrderDirect from './views/gsmadmin/Purchase/PurchaseOrderDirect/PurchaseOrderDirect';
import PurchaseOrderDirectList from './views/gsmadmin/Purchase/PurchaseOrderDirect/PurchaseOrderDirectList';
import PurchaseWorkOrder from './views/gsmadmin/Purchase/PurchaseWorkOrder/PurchaseWorkOrder';
import PurchaseWordOrderList from './views/gsmadmin/Purchase/PurchaseWorkOrder/PurchaseWorkOrderList';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import BannerUpdate from './views/gsmadmin/ECommerce/bannerUpdate/bannerUpdate';
import PromotionApprovalMaster from './views/gsmadmin/HR/PromotionApprovalMaster/promotionApproval';
import IncreamentApprovalMaster from './views/gsmadmin/HR/IncreamentApprovalMaster/increamentApproval';
import ResignationEntry from './views/gsmadmin/HR/ResignationEntry/resignationEntry';
import ResignationApproval from './views/gsmadmin/HR/ResignationApproval';
import Rejoining from './views/gsmadmin/HR/Rejoining/rejoining';
import AppointmentProcessList from './views/gsmadmin/HR/AppointmentProcess/AppointmentProcessList';
import PromotionApprovalMasterList from './views/gsmadmin/HR/PromotionApprovalMaster/PromotionApprovalMasterList';
import IncreamentEntryList from './views/gsmadmin/HR/IncreamentEntry/IncreamentEntryList';
import IncreamentApprovalMasterList from './views/gsmadmin/HR/IncreamentApprovalMaster/IncreamentApprovalMasterList';
import ResignationEntryList from './views/gsmadmin/HR/ResignationEntry/ResignationEntryList';
import ResignationApprovalList from './views/gsmadmin/HR/ResignationApproval/ResignationApprovalList';
import RejoiningList from './views/gsmadmin/HR/Rejoining/RejoiningList';
import SelectionProcessList from './views/gsmadmin/HR/SelectionProcess/selectionProcessList';
import SelectionApprovalList from './views/gsmadmin/HR/SelectionApproval/selectionApprovalList';
import EmployeePromotionList from './views/gsmadmin/HR/EmployeePromotion/EmployeePromotionList';
import EmployeePromotion from './views/gsmadmin/HR/EmployeePromotion/employeePromotion';
import IncrementEntry from './views/gsmadmin/HR/IncreamentEntry/incrementEntry';
import LeaveRequest from './views/gsmadmin/Payrol/leaveRequest/leaveRequest';
import AttendanceReport from './views/gsmadmin/HRReports/AttendanceReport';
import WeeklyAttendanceReport from './views/gsmadmin/HRReports/WeeklyAttendanceReport';
import DailyAttendanceReport from './views/gsmadmin/HRReports/DailyAttendanceReport';
import AttendanceRegister from './views/gsmadmin/HRReports/AttendanceRegister';
import MonthlyAbsentRegister from './views/gsmadmin/HRReports/MonthlyAbsentRegister';
import TimeSheetReport from './views/gsmadmin/HRReports/TimeSheetReport';
import TimeSheetReportOT from './views/gsmadmin/HRReports/OTShiftTimeSheetReport';
import PunchingRegister from './views/gsmadmin/HRReports/PunchingRegister';
import SalaryRegister from './views/gsmadmin/HRReports/SalaryRegister';
import StrengthRegister from './views/gsmadmin/HRReports/StrengthRegister';
import SalaryRevisionRegistry from './views/gsmadmin/HRReports/SalaryRevisionRegister';
import EmployeeInformationReport from './views/gsmadmin/HRReports/EmployeeInformationReport';
import ReleavedEmployee from './views/gsmadmin/HRReports/ReleavedEmployee';
import RejoinedEmployee from './views/gsmadmin/HRReports/RejoinedEmployee';
import LeaveRequestList from './views/gsmadmin/Payrol/leaveRequest/leaveRequestList';
import LeaveApproval from './views/gsmadmin/Payrol/leaveApproval/leaveApproval';
import LeaveApprovalList from './views/gsmadmin/Payrol/leaveApproval/leaveApprovalList';
import VacancyApprovalList from './views/gsmadmin/HR/VacancyApproval/vacancyApprovalList';
import ResumeShortlistedList from './views/gsmadmin/HR/ResumeShortlisting/resumeShortlistingList';
import InterviewLetterList from './views/gsmadmin/HR/InterviewLetter/interviewLetterList';
import OfferLetterList from './views/gsmadmin/HR/OfferLetterGeneration/offerLetterList';
import BillEntry from './views/gsmadmin/Accounts/BillEntry/billEntry';
import CashBook from './views/gsmadmin/Accounts/cashBook/cashBook';
import BankBook from './views/gsmadmin/Accounts/bankBook/bankBook';
import DayBook from './views/gsmadmin/Accounts/dayBook/dayBook';
import TrailBalance from './views/gsmadmin/Accounts/trialBalance/trialBalnace';
import CustomerFeedBackDetails from './views/gsmadmin/CRM/CustomerFeedBack/CustomerFeedBackDetail';
import BillEntryDetails from './views/gsmadmin/Accounts/BillEntry/billEntryDetail';
import OutletReturnForm from './views/gsmadmin/Stores/outletReturnForm/outletReturnForm';
import EmbedPDFViewer from './views/gsmadmin/common/shared/pdfviewer/embedPdfViewer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import OutletReturnFormList from './views/gsmadmin/Stores/outletReturnForm/outletReturnFormList';
import RejectionStockOutlet from './views/gsmadmin/Reports/rejectionStockOutlet/rejectionStoctOutlet';
import SalesOrder from './views/gsmadmin/BackOffice/salesOrder/salesOrder';
import SalesOrderList from './views/gsmadmin/BackOffice/salesOrder/salesOrderList';
import WareStockSummaryBatchwise from './views/gsmadmin/Reports/wareStockSummaryBatchwise/wareStockSummaryBatchwise';
import StockAnalysisBatchwise from './views/gsmadmin/Reports/stockAnalysisBatchwise/stockAnalysisBatchwise';
import UserAllocation from './views/gsmadmin/Admin/assignRoles/assignRoles';
import PosSaleSave from './views/gsmadmin/Admin/posSale/posSaleSave';
import CategoryAssignOffers from './views/gsmadmin/CRM/CategoryAssignOffers/CategoryAssignOffers';
import CategoryAssignMasterDetail from './views/gsmadmin/CRM/CategoryAssignOffers/CategoryAssignDetail';
import StockAdjustmentWarehouse from './views/gsmadmin/Admin/stockadjustment/StockAdjustment';
import StockAdjustmentWarehouseList from './views/gsmadmin/Admin/stockadjustment/StockAdjustmentDetails';
import StockAdjustmentOutlet from './views/gsmadmin/BackOffice/stockadjustmentoutlet/StockAdjustment';
import StockAdjustmentOutletList from './views/gsmadmin/BackOffice/stockadjustmentoutlet/StockAdjustmentDetails';

const userManual = require('./files/GSM_User_Manual.pdf');

i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  returnObjects: true,
})

const App = () => {
  const { t } = useTranslation()
  const sideNavAccordian: any = t("sideNavAccordian");
  const [showSidebar, setShowSidebar] = useState(false);
  const [isCounterIn, setCounterIn] = useState(false);
  const [isAdminLoggetIn, setAdminLoggedIn] = useState(false);
  const [quickAccess, setQuickAccess] = useState(true)
  const [showPdf, setShowPDF] = useState(false)
  const navigate = useNavigate();

  const employeename = Cookies.get('employee_name');
  function ShowSidebarMenu() {
    if (!showSidebar) {
      setShowSidebar(true)
      setCounterIn(false);
    }
    else {
      setShowSidebar(false)
      setCounterIn(false);
    }
  }
  async function loadData() {

    if (window.location.pathname === '/Admin/POS') {
      setCounterIn(true);
    }
    else {
      setCounterIn(false);
    }
  }
  async function loadHome() {

    if (Cookies.get('isAdminLogin')) {

      // setShowSidebar(true)
      setAdminLoggedIn(true)
    }
    else {
      setShowSidebar(false)
      setAdminLoggedIn(false);
    }
    // if (window.location.pathname === '/AdminLogin') {
    //   setAdminLoggedIn(true);
    //   setShowSidebar(true)
    // }
    // else {
    //   setAdminLoggedIn(false);
    // }
  }
  const handleLogout = () => {
    Cookies.remove('isAdminLogin');
    navigate('/admin');
  }

  

  useEffect(() => {
    loadData();
    loadHome();
  })


  return (
    // <CookiesProvider></CookiesProvider>


    <div className='Dashboard'>

      {isAdminLoggetIn ?
        <div className='header'>
          <Container fluid>
            <Navbar>
              <div className='menuBox'>
                <Button className="SideMenuBtn" onClick={ShowSidebarMenu}>
                  <FontAwesomeIcon icon={!showSidebar ? faBars : faXmark} />
                </Button>
                <Link className="HomeBtn" to="/admin/home"><FontAwesomeIcon icon={faHomeAlt} /></Link>
              </div>
              <InputGroup className="SearchInput width-auto">
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2">
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </InputGroup>

              {
                isCounterIn ?
                  <><Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                      <Nav className="">
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <Image src={require('./image/userCreation.png')} rounded />&nbsp; {Cookies.get('counterNumber')}
                          </Dropdown.Toggle>
                        </Dropdown>

                      </Nav>
                    </Navbar.Collapse>
                  </>
                  : null
              }
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="">
                  <Nav.Link href=''>
                    <ChangeCircleIcon onClick={() => setQuickAccess(true)} sx={{ fontSize: '27px', display: 'flex', color: 'white', marginTop: '12px' }} />
                  </Nav.Link>
                  <Nav.Link href=''><BasicMenu /></Nav.Link>
                  <Nav.Link href=''><QuestionMarkIcon onClick={() => setShowPDF(true)} sx={{ fontSize: '27px', display: 'flex', color: 'white', marginTop: '12px' }} /></Nav.Link>
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <Image src={require('./image/user.png')} rounded />&nbsp; {Cookies.get('employee_name')}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Link href="" className='notification'>
                    <NotificationBar />
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

          </Container>
        </div>
        : null}
      <div className='DashboardBody'>
        <Nav className={showSidebar ? "sidebarShow sidebar" : "sidebarHide sidebar"} activeKey="/home" >
          <div className="sidebar-sticky"></div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header><AddBusinessIcon />&nbsp;E-Commerce</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/Banner"><ViewCarouselIcon />&nbsp;Banner</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><Image src={require('./image/admin.png')} />&nbsp; {sideNavAccordian?.admin}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li ><Link to="/Admin/POS" ><Image src={require('./image/POS.png')} />  &nbsp; POS</Link></li>
                  <li><Link to="/Admin/outlet"><Image src={require('./image/registration.png')} />&nbsp; {sideNavAccordian?.outletsRegistration}</Link></li>
                  <li><Link to="/Admin/Warehouse"><Image src={require('./image/registration.png')} />&nbsp; {sideNavAccordian?.warehouseRegistration}</Link></li>
                  <li><Link to="/Admin/AssignOutletManager"><Image src={require('./image/OutletManagers.png')} />&nbsp; {sideNavAccordian?.assignOutletManagers}</Link></li>
                  <li><Link to="/Admin/StockAdjustment/Warehouse"><Image src={require('./image/OutletManagers.png')} />&nbsp; {sideNavAccordian?.stockAdjustmentwarehouse}</Link></li>
                  <li><Link to="/Admin/AssignWarehouseManager"><Image src={require('./image/warehouseManager.png')} />&nbsp; {sideNavAccordian?.assignWarehouseManages}</Link></li>
                  <li><Link to="/Admin/usercreation"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.userCreation}</Link></li>
                  {/* <li><Link to="/Admin/SellingPriceUpdate"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.sellingPriceUpdate}</Link></li> */}
                  <li><Link to="/Admin/SellingPriceUpdateB2B"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.sellingPriceUpdateb2b}</Link></li>
                  <li><Link to="/Admin/SellingPriceUpdateB2C"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.sellingPriceUpdateb2c}</Link></li>
                  <li><Link to="/Admin/AssignOnlineOrder"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.assignOnlineOrder}</Link></li>
                  <li><Link to="/Admin/CourierPartnerCreation"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.courierPartnerCreation}</Link></li>
                  {/* Change To Warehouse */}
                  {/* <li><Link to="/Admin/AssignProduct"><Image src={require('./image/registration.png')} />&nbsp; Assign Product </Link></li> */}

                  {/* Change To Accounts */}
                  {/* <li><Link to="/Admin/OnlineInvoiceDetail"><Image src={require('./image/role.png')} />&nbsp;Online Invoice</Link></li>                  
                  <li><Link to="/Admin/SalesRegister"><Image src={require('./image/role.png')} />&nbsp;Sales Register</Link></li>                   */}

                  {/* Change To Reports */}
                  {/* <li><Link to="/Admin/InwardRegister"><Image src={require('./image/role.png')} />&nbsp;Inward Register</Link></li>                  
                  <li><Link to="/Admin/MinimumStockReport"><Image src={require('./image/role.png')} />&nbsp;Minmum Stock Level</Link></li>                  
                  <li><Link to="/Admin/MemberPointsStatement"><Image src={require('./image/role.png')} />&nbsp;Member Points Statement</Link></li>                   */}

                  {/* Change To Accounts */}
                  {/* <li><Link to="/Admin/PartyLedger"><Image src={require('./image/role.png')} />&nbsp;Party Ledger</Link></li>   */}

                  {/* <li><Link to="/Admin/QuoteFromSupplier"><Image src={require('./image/role.png')} />&nbsp;Quote From Supplier</Link></li> 
                    */}
                  {/* <li><Link to="/Admin/QuoteFromSupplier"><Image src={require('./image/role.png')} />&nbsp;Quote From Supplier</Link></li> */}
                  <li><Link to="/Admin/userRights"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.assignRoles}</Link></li>
                  <li><Link to="/Admin/NotificationRolls"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.assignNotificationRoles}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><Image src={require('./image/hr.png')} />&nbsp; {sideNavAccordian?.purchase}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/Indent"><Image src={require('./image/DamageEntry.png')} />&nbsp; {sideNavAccordian?.indent}</Link></li>
                  <li><Link to="/Admin/QuoteFromSupplier"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.quoteFromSupplier}</Link></li>
                  <li><Link to="/Admin/SupplierMaster"><Image src={require('./image/SupplierMaster.png')} />&nbsp;{sideNavAccordian?.supplierMaster} </Link></li>
                  <li><Link to="/Admin/PurchaseApproval"><Image src={require('./image/DamageEntry.png')} />&nbsp; {sideNavAccordian?.purchaseApproval}</Link></li>
                  <li><Link to="/Admin/PurchaseOrder"><Image src={require('./image/DamageEntry.png')} />&nbsp; {sideNavAccordian?.purchaseOrder}</Link></li>
                  <li><Link to="/Admin/PurchaseOrderDirect"><Image src={require('./image/DamageEntry.png')} />&nbsp; {sideNavAccordian?.purchaseOrderDirect}</Link></li>
                  <li><Link to="/Admin/PurchaseWorkOrder"><Image src={require('./image/DamageEntry.png')} />&nbsp; {sideNavAccordian?.purchaseWorkOrder}</Link></li>
                  <li><Link to="/Admin/PurchaseReturn"><Image src={require('./image/productReturn.png')} />&nbsp; {sideNavAccordian?.purchaseReturn}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header><Image src={require('./image/Backoffice.png')} />&nbsp; {sideNavAccordian?.backOffice}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/Member"><Image src={require('./image/registration.png')} />&nbsp; {sideNavAccordian?.newMemberRegistration}</Link></li>
                  <li><Link to="/Admin/AssignPOSStaff"><Image src={require('./image/SupplierMaster.png')} />&nbsp;{sideNavAccordian?.assignPOSStaff}</Link></li>
                  <li><Link to="/Admin/Terminal"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.terminalCreation}</Link></li>
                  <li><Link to="/Admin/ShiftMaster"><Image src={require('./image/userCreation.png')} />&nbsp; {sideNavAccordian?.shiftMaster}</Link></li>
                  <li><Link to="/Admin/SalesReturnOrReplacement"><Image src={require('./image/productReturn.png')} />&nbsp; {sideNavAccordian?.salesReturnOrReplacement}</Link></li>
                  <li><Link to="/Admin/CounterOpeningEntry"><Image src={require('./image/productReturn.png')} />&nbsp; {sideNavAccordian?.counterOpeningEntry}</Link></li>
                  <li><Link to="/Admin/ClosingCashHandover"><Image src={require('./image/productReturn.png')} />&nbsp; {sideNavAccordian?.closingCashHandover}</Link></li>
                  <li><Link to="/Admin/Invoice"><Image src={require('./image/productReturn.png')} />&nbsp; {sideNavAccordian?.invoice}</Link></li>
                  <li><Link to="/Admin/SalesOrder"><Image src={require('./image/productReturn.png')} />&nbsp; Sales Order</Link></li>
                  <li><Link to="/Admin/StockAdjustment/Outlet"><Image src={require('./image/productReturn.png')} />&nbsp; Stock Adjustment Entry - Outlet</Link></li>
                  {/*  */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header><Image src={require('./image/crm.png')} />&nbsp; CRM</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/ManageOffersOrDiscounts"><Image src={require('./image/Discounts.png')} />&nbsp;{sideNavAccordian?.manageOffersOrDiscounts} </Link></li>
                  <li><Link to="/Admin/AssignOfferDiscount"><Image src={require('./image/complain.png')} />&nbsp;{sideNavAccordian?.assignOffersOrDiscounts} </Link></li>
                  <li><Link to="/Admin/ManageLoyalty"><Image src={require('./image/complain.png')} />&nbsp; {sideNavAccordian?.manageLoyalty} </Link></li>
                  <li><Link to="/Admin/AssignRedemption"><Image src={require('./image/complain.png')} />&nbsp; {sideNavAccordian?.assignRedemption} </Link></li>
                  <li><Link to="/Admin/AssignFreeItem"><Image src={require('./image/complain.png')} />&nbsp; {sideNavAccordian?.assignFreeItem} </Link></li>
                  <li><Link to="/Admin/MembershipPlan"><Image src={require('./image/target.png')} />&nbsp; Membership Plan </Link></li>
                  <li><Link to="/Admin/AssignMembership"><Image src={require('./image/complain.png')} />&nbsp; Assign Membership </Link></li>
                  <li><Link to="/Admin/CustomerFeedBack"><Image src={require('./image/feedback.png')} />&nbsp; {sideNavAccordian?.customersFeedback} </Link></li>
                  <li><Link to="/Admin/CategoryAssignOffers"><Image src={require('./image/feedback.png')} />&nbsp; {sideNavAccordian?.categoryAssignOffers} </Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header><Image src={require('./image/warehouse.png')} />&nbsp; {sideNavAccordian?.warehouse}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/ItemMaster"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.itemMaster}</Link></li>
                  {/*  */}
                  <li><Link to="/Admin/Category"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Category Master'}</Link></li>
                  <li><Link to="/Admin/SubCategory"><Image src={require('./image/SubCategory.png')} />&nbsp; {sideNavAccordian?.subcategory}</Link></li>
                  <li><Link to="/Admin/Brand"><Image src={require('./image/brand.png')} />&nbsp;{sideNavAccordian?.brand}</Link></li>
                  <li><Link to="/Admin/ItemInward"><Image src={require('./image/inward.png')} />&nbsp; {sideNavAccordian?.itemInward}</Link></li>
                  <li><Link to="/Admin/OutwardToOutlet"><Image src={require('./image/outlet.png')} />&nbsp; {sideNavAccordian?.outwardToOutlet}</Link></li>
                  <li><Link to="/Admin/LoosedConversion"><Image src={require('./image/outlet.png')} />&nbsp; Loosed Conversion</Link></li>
                  <li><Link to="/Admin/UnitOfMeasurements"><Image src={require('./image/measurements.png')} />&nbsp;  {sideNavAccordian?.unitOfMeasurements}</Link></li>
                  <li><Link to="/Admin/DamageEntryWareHouse"><Image src={require('./image/DamageEntry.png')} />&nbsp;  {sideNavAccordian?.damageEntryWarehouse}</Link></li>
                  {/* Change to Purchase */}
                  <li><Link to="/Admin/DamageEntryApproval"><Image src={require('./image/DamageEntry.png')} />&nbsp;Damage Entry Approval</Link></li>
                  <li><Link to="/Admin/RejectionConversion"><Image src={require('./image/DamageEntry.png')} />&nbsp;Rejection Conversions</Link></li>
                  {/* <li><Link to="/Admin/PurchaseApproval"><Image src={require('./image/DamageEntry.png')} />&nbsp; Purchase Approval</Link></li>
                  <li><Link to="/Admin/PurchaseOrder"><Image src={require('./image/DamageEntry.png')} />&nbsp;Purchase Order</Link></li> */}
                  <li><Link to="/Admin/AssignProduct"><Image src={require('./image/registration.png')} />&nbsp; {sideNavAccordian?.assignProducts} </Link></li>
                  <li><Link to="/"><Image src={require('./image/StockUpdate.png')} />&nbsp; {sideNavAccordian?.stockUpdate}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header><FactoryIcon />&nbsp; {sideNavAccordian?.factory}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/StockToWarehouse"><Image src={require('./image/DamageEntry.png')} />&nbsp;Stock to Warehouse</Link></li>
                  <li><Link to="/Admin/ItemConversion"><Image src={require('./image/measurements.png')} />&nbsp; {sideNavAccordian?.itemConversion}</Link></li>
                  <li><Link to="/Admin/TeamMaster"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.teamMaster}</Link></li>
                  <li><Link to="/Admin/PackingDistribution"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.packingDistribution}</Link></li>
                  <li><Link to="/Admin/PackingPlan"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.packingPlan}</Link></li>
                  <li><Link to="/Admin/ProductionEntry"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.productionEntry}</Link></li>
                  <li><Link to="/Admin/MachineMaster"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.machineMaster}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header><Image src={require('./image/feedback.png')} />&nbsp; {sideNavAccordian?.reports}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/StockAnalysis"><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.warehouseStockReport} </Link></li>
                  <li><Link to="/Admin/StockSummary"><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.warehouseStockSummary} </Link></li>
                  <li><Link to="/Admin/WareStockSummaryBatchwise"><Image src={require('./image/Ledger.png')} />&nbsp; Warehouse Stock - Batch wise </Link></li>
                  <li><Link to="/Admin/OutletStockAnalysis"><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.outletStockAnalysis} </Link></li>
                  <li><Link to="/Admin/OutletStockSummary"><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.outletStockSummary} </Link></li>
                  <li><Link to="/Admin/StockAnalysisBatchwise"><Image src={require('./image/Ledger.png')} />&nbsp; Outlet Stock - Batch wise </Link></li>
                  <li><Link to='/Admin/BarcodePriceHistory'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.barcodePriceHistory} </Link></li>
                  <li><Link to='/Admin/ItemwiseMargin'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.itemwiseMargin} </Link></li>
                  <li><Link to='/Admin/PriceListing'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.priceListing} </Link></li>
                  <li><Link to='/Admin/RepackRegister'><Image src={require('./image/Ledger.png')} />&nbsp;{sideNavAccordian?.repackRegister} </Link></li>
                  <li><Link to="/Admin/RejectionStock"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.rejectionStockwarehouse} </Link></li>
                  <li><Link to="/Admin/RejectionStockOutlet"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.rejectionStockoutlet} </Link></li>
                  <li><Link to="/Admin/SalesSummaryItemWise"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.salesSummaryItemwise} </Link></li>
                  <li><Link to="/Admin/StockSummaryOutlet"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.stockSummaryItemwiseOutlet} </Link></li>
                  <li><Link to="/Admin/StockSummaryWarehouse"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.stockSummaryItemwiseWarehouse} </Link></li>
                  <li><Link to='/Admin/SalesReturnRegister'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.salesReturnRegister} </Link></li>
                  <li><Link to='/Admin/StockSummaryBatchwise'><Image src={require('./image/Ledger.png')} />&nbsp;  {sideNavAccordian?.stockSummaryBatchwise} </Link></li>
                  <li><Link to='/Admin/StockSummaryPicklist'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.stockSummaryPicklist} </Link></li>
                  <li><Link to='/Admin/StockTakingImport'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.stockTakingImport} </Link></li>
                  <li><Link to='/Admin/StockValueSummary'><Image src={require('./image/Ledger.png')} />&nbsp;  {sideNavAccordian?.stockValueSummary} </Link></li>
                  <li><Link to='/Admin/StockTransferInReg'><Image src={require('./image/Ledger.png')} />&nbsp;  {sideNavAccordian?.stockTransferInReg} </Link></li>
                  <li><Link to='/Admin/StockTransgerOutReg'><Image src={require('./image/Ledger.png')} />&nbsp;  {sideNavAccordian?.stockTransferOutReg} </Link></li>
                  <li><Link to='/Admin/ExpiryRegister'><Image src={require('./image/Ledger.png')} />&nbsp; {sideNavAccordian?.expiryRegister} </Link></li>
                  <li><Link to="/Admin/PurchaseBillRegister"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.purchaseBillRegister} </Link></li>
                  <li><Link to="/Admin/InwardRegister"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.inwardRegister} </Link></li>
                  <li><Link to="/Admin/MinimumStockReport"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.minimumStockReport} </Link></li>
                  <li><Link to="/Admin/MemberPointsStatement"><Image src={require('./image/role.png')} />&nbsp; {sideNavAccordian?.membberPointStatement} </Link></li>
                  <li><Link to="/Admin/SalesRegister"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.salesRegister}</Link></li>
                  <li><Link to="/Admin/PartyLedger"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.partyLedger}</Link></li>
                  <li><Link to="/Admin/GSTRAnnexure"><Image src={require('./image/role.png')} />&nbsp;GSTR Annexure</Link></li>
                  <li><Link to="/Admin/GSTRAnnexure/2"><Image src={require('./image/role.png')} />&nbsp;GSTR Annexure - 2</Link></li>
                </ul>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header><Image src={require('./image/stores.png')} />&nbsp; {sideNavAccordian?.stores}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/OutletItemRequest"><Image src={require('./image/OutletItemRequest.png')} />&nbsp;{sideNavAccordian?.outletItemRequest}</Link></li>
                  <li><Link to="/Admin/InwardOutlet"><Image src={require('./image/inward.png')} />&nbsp;{sideNavAccordian?.inwardOutlet}</Link></li>
                  <li><Link to="/Admin/RackAllotment"><Image src={require('./image/stores.png')} />&nbsp;{sideNavAccordian?.rackAllotment}</Link></li>
                  <li><Link to="/Admin/DespatchEntry"><Image src={require('./image/stores.png')} />&nbsp; {sideNavAccordian?.despatchEntry}</Link></li>
                  <li><Link to="/Admin/CourierEntry"><Image src={require('./image/stores.png')} />&nbsp; {sideNavAccordian?.courierEntry}</Link></li>
                  <li><Link to="/Admin/NewItemRequest"><Image src={require('./image/registration.png')} />&nbsp; New Item Request</Link></li>
                  {/* <li><Link to="/"><Image src={require('./image/DamageEntry.png')} />&nbsp; Damage/Rejection entry</Link></li>
                  <li><Link to="/"><Image src={require('./image/productReturn.png')} />&nbsp; Purchase return</Link></li>
                  <li><Link to="/"><Image src={require('./image/warehouseManager.png')} />&nbsp; Create purchase orders </Link></li>
                  <li><Link to="/"><Image src={require('./image/SupplierMaster.png')} />&nbsp; Suppilers Quotations </Link></li> */}
                  <li><Link to="/"><Image src={require('./image/Discounts.png')} />&nbsp; {sideNavAccordian?.assignSupplierDiscounts}</Link></li>
                  <li><Link to="/Admin/DirectInward"><Image src={require('./image/inward.png')} />&nbsp;{sideNavAccordian?.directInward}</Link></li>
                  <li><Link to="/Admin/DamageEntryOutlet"><Image src={require('./image/DamageEntry.png')} />&nbsp;  {sideNavAccordian?.damageEntryOutlet}</Link></li>
                  <li><Link to="/Admin/DamageTransfer"><Image src={require('./image/BatchNo.png')} />&nbsp;Damage Transfer</Link></li>
                  <li><Link to="/Admin/OutletReturnForm"><Image src={require('./image/BatchNo.png')} />&nbsp;Outlet Return Form</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
              <Accordion.Header><Image src={require('./image/hr.png')} />&nbsp; HR</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/designationmaster"><Image src={require('./image/Designation.png')} />&nbsp;{sideNavAccordian?.designationMaster}</Link></li>
                  <li><Link to="/Admin/departmentmaster"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.departmentMaster}</Link></li>
                  <li><Link to="/Admin/employeeregistraion"><Image src={require('./image/registration.png')} />&nbsp;{sideNavAccordian?.employeeRegistration}</Link></li>
                  <li><Link to="/Admin/JobMaster"><Image src={require('./image/registration.png')} />&nbsp;{'Job Master'}</Link></li>
                  <li><Link to="/Admin/JobVacancy"><Image src={require('./image/registration.png')} />&nbsp;{'Job Vacancy'}</Link></li>
                  <li><Link to="/Admin/VacancyApproval"><Image src={require('./image/registration.png')} />&nbsp;{'Vacancy Approval - Waiting for Approval'}</Link></li>
                  <li><Link to="/Admin/ResumeBank"><Image src={require('./image/registration.png')} />&nbsp;{'Resume Bank'}</Link></li>
                  <li><Link to="/Admin/ResumeShortlisting"><Image src={require('./image/registration.png')} />&nbsp;{'Resume Shortlisting'}</Link></li>
                  <li><Link to="/Admin/InterviewMaster"><Image src={require('./image/registration.png')} />&nbsp;{'Interview Master'}</Link></li>
                  <li><Link to="/Admin/InterviewLetter"><Image src={require('./image/registration.png')} />&nbsp;{'Interview Letter'}</Link></li>
                  <li><Link to="/Admin/InterviewProcess"><Image src={require('./image/registration.png')} />&nbsp;{'Interview Process'}</Link></li>
                  <li><Link to="/Admin/SelectionProcess"><Image src={require('./image/registration.png')} />&nbsp;{'Selection Process'}</Link></li>
                  <li><Link to="/Admin/SelectionApproval"><Image src={require('./image/registration.png')} />&nbsp;{'Selection Process Approval'}</Link></li>
                  <li><Link to="/Admin/OfferLetterGeneration"><Image src={require('./image/registration.png')} />&nbsp;{'Offer Letter Generation'}</Link></li>
                  <li><Link to="/Admin/AppointmentProcess"><Image src={require('./image/registration.png')} />&nbsp;{'Appointment Process'}</Link></li>
                  <li><Link to="/Admin/EmployeePromotion"><Image src={require('./image/registration.png')} />&nbsp;{'Employee Promotion'}</Link></li>
                  <li><Link to="/Admin/PromotionApproval"><Image src={require('./image/registration.png')} />&nbsp;{'Promotion Approval'}</Link></li>
                  <li><Link to="/Admin/IncrementMaster"><Image src={require('./image/registration.png')} />&nbsp;{'Increment Master'}</Link></li>
                  <li><Link to="/Admin/IncrementApproval"><Image src={require('./image/registration.png')} />&nbsp;{'Increment Approval'}</Link></li>
                  <li><Link to="/Admin/ResignationMaster"><Image src={require('./image/registration.png')} />&nbsp;{'Resignation Master'}</Link></li>
                  <li><Link to="/Admin/ResignationApproval"><Image src={require('./image/registration.png')} />&nbsp;{'Resignation Approval'}</Link></li>
                  <li><Link to="/Admin/Rejoining"><Image src={require('./image/registration.png')} />&nbsp;{'Rejoining Master'}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header><DoNotDisturbIcon />&nbsp;HR Reports</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/AttendanceReport"><Image src={require('./image/BatchNo.png')} />&nbsp; Attendance Report</Link></li>
                  <li><Link to="/Admin/WeeklyAttendanceReport"><Image src={require('./image/measurements.png')} />&nbsp;Weekly Attendance Report</Link></li>
                  <li><Link to="/Admin/DailyAttendanceReport"><Image src={require('./image/BatchNo.png')} />&nbsp; Daily Attendance Report</Link></li>
                  <li><Link to="/Admin/AttendanceRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Attendance Register</Link></li>
                  <li><Link to="/Admin/MonthlyAbsentRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Monthly Absent Register</Link></li>
                  <li><Link to="/Admin/TimeSheetRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Time Sheet Register</Link></li>
                  <li><Link to="/Admin/TimeSheetRegisterOT"><Image src={require('./image/BatchNo.png')} />&nbsp; Time Sheet Register - OT</Link></li>
                  <li><Link to="/Admin/PunchingRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Punching Register</Link></li>
                  <li><Link to="/Admin/SalaryRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Salary Register</Link></li>
                  <li><Link to="/Admin/StrengthRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Strength Register</Link></li>
                  <li><Link to="/Admin/SalaryRevisionRegister"><Image src={require('./image/BatchNo.png')} />&nbsp; Salary Revision Register</Link></li>
                  <li><Link to="/Admin/EmployeeInformationReport"><Image src={require('./image/BatchNo.png')} />&nbsp;Employee Information Report</Link></li>
                  <li><Link to="/Admin/ReleavedEmployee"><Image src={require('./image/BatchNo.png')} />&nbsp;Releaved Employee</Link></li>
                  <li><Link to="/Admin/RejoinedEmployee"><Image src={require('./image/BatchNo.png')} />&nbsp;Rejoined Employee</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
              <Accordion.Header><Image src={require('./image/Accounts.png')} />&nbsp; {sideNavAccordian?.accounts}</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/LedgerGroup"><Image src={require('./image/Ledger.png')} />&nbsp;{sideNavAccordian?.ledgerGroup}</Link></li>
                  <li><Link to="/Admin/LedgerMaster"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.ledgerMaster}</Link></li>
                  <li><Link to="/Admin/ReceiptEntry"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.receiptEntry}</Link></li>
                  <li><Link to="/Admin/PaymentVoucher"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.paymentVoucher}</Link></li>
                  <li><Link to="/Admin/JournalEntry"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.journalEntry}</Link></li>
                  <li><Link to="/Admin/DebitNote"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.debitNote}</Link></li>
                  <li><Link to="/Admin/CreditNote"><Image src={require('./image/ItemMaster.png')} />&nbsp; {sideNavAccordian?.creditNote}</Link></li>
                  <li><Link to="/Admin/CashBook"><Image src={require('./image/ItemMaster.png')} />&nbsp;Cash Book</Link></li>
                  <li><Link to="/Admin/BankBook"><Image src={require('./image/ItemMaster.png')} />&nbsp;Bank Book</Link></li>
                  <li><Link to="/Admin/DayBook"><Image src={require('./image/ItemMaster.png')} />&nbsp;Day Book</Link></li>
                  <li><Link to="/Admin/TrailBalance"><Image src={require('./image/ItemMaster.png')} />&nbsp;Trail Balance</Link></li>
                  <li><Link to="/Admin/PurchaseBill"><Image src={require('./image/ItemMaster.png')} />&nbsp;{sideNavAccordian?.purchaseBill}</Link></li>
                  <li><Link to="/Admin/BillEntry"><Image src={require('./image/ItemMaster.png')} />&nbsp;Bill Entry</Link></li>
                  <li><Link to="/Admin/OnlineInvoiceDetail"><Image src={require('./image/role.png')} />&nbsp;{sideNavAccordian?.onlineInvoice}</Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header><DoNotDisturbIcon />&nbsp; Payroll</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/IncentiveRecovery"><Image src={require('./image/measurements.png')} />&nbsp; {'Incentive & Recovery Master'}</Link></li>
                  <li><Link to="/Admin/Deduction"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Deduction Master'}</Link></li>
                  <li><Link to="/Admin/LeaveSetting"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Leave Setting Master'}</Link></li>
                  <li><Link to="/Admin/BreakInOutEntry"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Break IN / OUT Entry'}</Link></li>
                  <li><Link to="/Admin/ManualAttendanceEntry"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Manual Attendance Entry'}</Link></li>
                  <li><Link to="/Admin/MDApprovalEntry"><Image src={require('./image/BatchNo.png')} />&nbsp; {'MD Approval Entry'}</Link></li>
                  <li><Link to="/Admin/SalaryGeneration"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Salary Generation'}</Link></li>
                  <li><Link to="/Admin/LoanAdvanceRequest"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Loan & Advance Request'}</Link></li>
                  <li><Link to="/Admin/LoanAdvanceApproval"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Loan & Advance Approval'}</Link></li>
                  <li><Link to="/Admin/LeaveRequest"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Leave Request'}</Link></li>
                  <li><Link to="/Admin/LeaveApproval"><Image src={require('./image/BatchNo.png')} />&nbsp; {'Leave Approval'}</Link></li>
                  {/*  */}
                  {/* <li><Link to="/Admin/AssignBatchOutlet"><Image src={require('./image/BatchNo.png')} />&nbsp;{sideNavAccordian?.assignBatchOutlet}</Link></li> */}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
              <Accordion.Header><DoNotDisturbIcon />&nbsp; N/A</Accordion.Header>
              <Accordion.Body>
                <ul className='list-unstyled'>
                  <li><Link to="/Admin/AssignBatchPacking"><Image src={require('./image/BatchNo.png')} />&nbsp; {sideNavAccordian?.assignBatchPacking}</Link></li>
                  <li><Link to="/Admin/PackingInward"><Image src={require('./image/measurements.png')} />&nbsp; {sideNavAccordian?.packingInward}</Link></li>
                  <li><Link to="/Admin/AssignBatchNo"><Image src={require('./image/BatchNo.png')} />&nbsp; {sideNavAccordian?.assignBatchNo}</Link></li>
                  <li><Link to="/Admin/AssignBatchOutlet"><Image src={require('./image/BatchNo.png')} />&nbsp;{sideNavAccordian?.assignBatchOutlet}</Link></li>
                  <li><Link to="/"><Image src={require('./image/complain.png')} />&nbsp; {sideNavAccordian?.customerComplaints} </Link></li>
                  <li><Link to="/Admin/B2BVerification"><Image src={require('./image/verification.png')} />&nbsp; {sideNavAccordian?.b2bVerficiation} </Link></li>
                  <li><Link to="/"><Image src={require('./image/salesperson.png')} />&nbsp; {sideNavAccordian?.allotSectionSalesPerson} </Link></li>
                  <li><Link to="/"><Image src={require('./image/target.png')} />&nbsp; {sideNavAccordian?.assignSalesTargets} </Link></li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Nav>
        <div className='DashboardDetail'>
          {/* <DashboardDetail/> */}
          {/* <div>hello</div> */}
          {/* <BrowserRouter>
            <Routes>
              <Route path="/" element={<DashboardDetail></DashboardDetail>} />
              
            </Routes>
            </BrowserRouter> */}
          {/* <DashboardDetail/> */}

          <Routes>

            <Route path="/Admin" element={<AdminLogin />} />
            <Route path="/Admin/Home" element={<DashBoards setQuickAccess={setQuickAccess} quickAccess={quickAccess} />} />
            <Route path="/Admin/usercreation" element={<UserCreation />} />
            <Route path="/Admin/usercreation/:userId" element={<UserCreation />} />
            <Route path="/Admin/usercreation/list" element={<UserCreationList />} />

            <Route path="/Admin/outlet" element={<Outlet />} />
            <Route path="/Admin/outlet/list" element={<OutletList />} />

            <Route path="/Admin/Warehouse" element={<Warehouse />} />
            <Route path="/Admin/Warehouse/list" element={<WarehouseList />} />

            <Route path="/Admin/ManageOffersOrDiscounts" element={<ManageOffersOrDiscounts />} />
            <Route path="/Admin/ManageOffersOrDiscounts/:offerId" element={<ManageOffersOrDiscounts />} />
            <Route path="/Admin/ManageOffersOrDiscounts/list" element={<ManageOffersOrDiscountsList />} />

            {/* <Route path="/Admin/ManagePriceChange" element={<ManagePriceChange/>}/> */}
            <Route path="/Admin/ManagePriceChange/list" element={<ManagePriceChangeList />} />

            <Route path="/Admin/AssignOfferDiscount" element={<AssignOfferDicount />} />
            <Route path="/Admin/AssignOfferDiscount/:assignofferid" element={<AssignOfferDicount />} />
            <Route path="/Admin/AssignOfferDiscount/list" element={<AssignOfferDicountList />} />

            <Route path="/Admin/Member" element={<NewMember />} />
            <Route path="/Admin/Member/list" element={<NewMemberList />} />

            <Route path="/Admin/NewItemRequest" element={<NewItemRequest />} />
            <Route path="/Admin/NewItemRequest/:reqId" element={<NewItemRequest />} />
            <Route path="/Admin/NewItemRequest/NewItemRequestList" element={<NewItemReqDetails />} />

            <Route path="/Admin/Brand" element={<Brand />} />
            <Route path="/Admin/Brand/:brandId" element={<Brand />} />
            <Route path="/Admin/Brand/list" element={<BrandList />} />

            <Route path="/Admin/ItemInward" element={<ItemInward />} />
            <Route path="/Admin/ItemInward/itemInwardDetails" element={<ItemInwardDetail />} />

            <Route path="/Admin/OutletItemRequest" element={<OutletItemRequest />} />
            <Route path="/Admin/OutletItemRequest/OutletItemRequestDetails" element={<OutletRequestItemDetails />} />

            <Route path="/Admin/ItemMaster/" element={<ItemMaster />} />
            <Route path="/Admin/ItemMaster/:itemId" element={<ItemMaster />} />
            <Route path="/Admin/ItemMaster/ItemMasterDetails" element={<ItemMasterDetail />} />

            <Route path="/Admin/LoosedConversion" element={<LoosedConversion />} />
            <Route path="/Admin/LoosedConversion/LoosedConversionDetail" element={<LoosedConversionDetail />} />

            <Route path="/Admin/OutwardToOutlet" element={<OutwardToOutlet />} />
            <Route path="/Admin/OutwardToOutlet/OutwardToOutletDetail" element={<OutwardToOutletDetail />} />

            <Route path="/Admin/AssignBatchNo" element={<AssignBatchNo />} />
            <Route path='/Admin/AssignBatchNo/AssignBatchNoDetail' element={<AssignBatchNoDetail />} />

            <Route path="/Admin/SubCategory" element={<SubCategory />} />
            <Route path="/Admin/SubCategory/:subCategoryId" element={<SubCategory />} />
            <Route path='/Admin/SubCategory/SubCategoryDetail' element={<SubCategoryDetail />} />

            <Route path="/Admin/SupplierMaster" element={<SupplierMaster />} />
            <Route path="/Admin/SupplierMaster/list" element={<SupplierMasterList />} />
            <Route path="/Admin/SupplierMaster/:supplierId" element={<SupplierMaster />} />

            <Route path="/Admin/departmentmaster" element={<DepartmentMaster />} />
            <Route path="/Admin/departmentmaster/:departmentId" element={<DepartmentMaster />} />
            <Route path="/Admin/departmentmaster/list" element={<DepartmentList />} />

            <Route path="/Admin/designationmaster" element={<DesignationMaster />} />
            <Route path="/Admin/designationmaster/:designationId" element={<DesignationMaster />} />
            <Route path="/Admin/designationmaster/list" element={<DesignationList />} />

            <Route path="/Admin/UnitOfMeasurements" element={<UnitOfMeasure />} />
            <Route path="/Admin/UnitOfMeasurements/:unitId" element={<UnitOfMeasure />} />
            <Route path="/Admin/UnitOfMeasurements/list" element={<UnitOfMeasurementList />} />

            <Route path="/Admin/ItemConversion" element={<ItemConversion />} />
            <Route path="/Admin/ItemConversion/:itemconvertId" element={<ItemConversion />} />
            <Route path="/Admin/ItemConversion/ItemConversionDetail" element={<ItemConversionDetail />} />

            <Route path="/Admin/TeamMaster" element={<TeamMaster />} />
            <Route path="/Admin/TeamMaster/:teamId" element={<TeamMaster />} />
            <Route path="/Admin/TeamMaster/TeamMasterDetail" element={<TeamMasterDetail />} />

            <Route path="/Admin/PackingPlan" element={<PackingPlan />} />
            <Route path="/Admin/PackingPlan/:Packingid" element={<PackingPlan />} />
            <Route path="/Admin/PackingPlan/PackingPlanList" element={<PackingPlanList />} />

            <Route path="/Admin/ProductionEntry" element={<ProducationEntry />} />
            <Route path="/Admin/ProductionEntry/:Pid" element={<ProducationEntry />} />
            <Route path="/Admin/ProductionEntry/ProductionEntryDetail" element={<ProductionEntryList />} />

            <Route path="/Admin/MachineMaster" element={<MachineMaster />} />
            <Route path="/Admin/MachineMaster/:Mcode" element={<MachineMaster />} />
            <Route path="/Admin/MachineMaster/MachineMasterDetail" element={<MachineMasterDetail />} />

            <Route path="/Admin/PackingDistribution" element={<PackingDistribution />} />
            <Route path="/Admin/PackingDistribution/PackingDistributionDetail" element={<PackingDistributionDetail />} />

            <Route path="/Admin/PackingInward" element={<PackingInward />} />
            <Route path="/Admin/PackingInward/PackingInwardDetail" element={<PackingInwardDetail />} />

            <Route path="/Admin/employeeregistraion" element={<Employee />} />
            <Route path="/Admin/employeeregistraion/:empId" element={<Employee />} />
            <Route path="/Admin/employeeregistraion/list" element={<Employeelist />} />

            <Route path="/Admin/InwardOutlet" element={<InwardOutlet />} />
            <Route path="/Admin/InwardOutlet/InwardOutletDetail" element={<InwardOutletDetail />} />

            <Route path="/Admin/POS" element={<POS />} />
            <Route path="/Admin/POSSample" element={<PosSaleSave />} />
            {/* <Route path="/Admin/POSSample" element={<PosSale />} /> */}

            <Route path="/Admin/Banner" element={<BannerUpdate />} />

            <Route path="/Admin/AssignOutletManager" element={<AssignOutletManager />} />
            <Route path="/Admin/AssignOutletManager/AssignOutletManagerDetail" element={<AssignOutletManagerDetail />} />

            <Route path="/Admin/StockAdjustment/Warehouse" element={<StockAdjustmentWarehouse />} />
            <Route path="/Admin/StockAdjustment/Warehouse/:stockadjsid" element={<StockAdjustmentWarehouse />} />
            <Route path="/Admin/StockAdjustmentList/Warehouse" element={<StockAdjustmentWarehouseList />} />
            
            <Route path="/Admin/StockAdjustment/Outlet" element={<StockAdjustmentOutlet />} />
            <Route path="/Admin/StockAdjustmentList/Outlet" element={<StockAdjustmentOutletList />} />


            <Route path="/Admin/AssignWarehouseManager" element={<AssignWarehouseManager />} />
            <Route path="/Admin/AssignWarehouseManager/AssignWarehouseManagerDetail" element={<AssignWarehouseManagerDetail />} />

            <Route path="/Admin/AssignPOSStaff" element={<AssignPOSStaff />} />
            <Route path="/Admin/AssignPOSStaff/AssignPOSStaffDetail" element={<AssignPOSStaffDetail />} />

            <Route path="/Admin/RackAllotment" element={<RackAllotment />} />
            <Route path="/Admin/RackAllotment/RackAllotmentDetail" element={<RackAllotmentDetail />} />

            <Route path="/Admin/LedgerGroup" element={<LedgerGroup />} />
            <Route path="/Admin/LedgerGroup/:groupId" element={<LedgerGroup />} />
            <Route path="/Admin/LedgerGroup/LedgerGroupDetail" element={<LedgerGroupDetail />} />

            <Route path="/Admin/LedgerMaster" element={<LedgerMaster />} />
            <Route path="/Admin/LedgerMaster/:ledgerId" element={<LedgerMaster />} />
            <Route path="/Admin/LedgerMaster/LedgerMasterDetail" element={<LedgerMasterDetail />} />

            <Route path="/Admin/AssignBatchPacking" element={<AssignBatchPacking />} />
            <Route path="/Admin/AssignBatchPacking/AssignBatchPackingDetail" element={<AssignBatchPackingDetail />} />
            <Route path="/Admin/Terminal" element={<TerminalCreation />} />
            <Route path="/Admin/Terminal/:terminalId" element={<TerminalCreation />} />
            <Route path="/Admin/Terminal/TerminalDetail" element={<TerminalCreationDetail />} />

            <Route path="/Admin/ShiftMaster" element={<ShiftMaster />} />
            <Route path="/Admin/ShiftMaster/:shiftId" element={<ShiftMaster />} />
            <Route path="/Admin/ShiftMaster/ShiftMasterDetail" element={<ShiftMasterDetail />} />

            <Route path="/Admin/SellingPriceUpdate" element={<SellingPriceUpdate />} />

            <Route path="/Admin/SellingPriceUpdateB2B" element={<SellingPriceUpdateB2B />} />
            <Route path="/Admin/SellingPriceUpdateB2C" element={<SellingPriceUpdateB2C />} />

            <Route path="/Admin/InvoicePrint" element={<InvoicePrint />} />

            <Route path='/Admin/AssignProduct' element={<AssignProductGrouping />} />
            <Route path='/Admin/AssignProductDetails' element={<AssignProductGroupingDetail />} />

            <Route path='/Admin/AssignProduct/:productId' element={<AssignProductGrouping />} />

            <Route path="/Admin/ManageLoyalty" element={<ManageLoyalty />} />
            <Route path="/Admin/ManageLoyalty/:loyalId" element={<ManageLoyalty />} />
            <Route path="/Admin/ManageLoyalty/ManageLoyaltyList" element={<ManageLoyaltyList />} />

            <Route path="/Admin/AssignRedemption" element={<AssignRedemption />} />
            <Route path="/Admin/AssignRedemption/:assignRedemId" element={<AssignRedemption />} />
            <Route path="/Admin/AssignRedemption/AssignRedemptionList" element={<AssignRedemptionList />} />

            <Route path='/Admin/CustomerFeedBack' element={<CostomerFeedback />} />
            <Route path='/Admin/CustomerFeedBack/List' element={<CustomerFeedBackDetails />} />

            <Route path='/Admin/CategoryAssignOffers' element={<CategoryAssignOffers />} />
            <Route path='/Admin/CategoryAssignOffers/:offercustmid' element={<CategoryAssignOffers />} />
            <Route path='/Admin/CategoryAssignOffers/List' element={<CategoryAssignMasterDetail />} />
            {/*  */}
            
            <Route path="/Admin/B2BVerification" element={<B2BVerificatioin />} />

            <Route path="/Admin/StockAnalysis" element={<StockAnalysis />} />
            <Route path="/Admin/StockSummary" element={<StockSummary />} />

            <Route path="/Admin/WareStockSummaryBatchwise" element={<WareStockSummaryBatchwise />} />
            <Route path="/Admin/StockAnalysisBatchwise" element={<StockAnalysisBatchwise />} />
            {/*  */}

            <Route path="/Admin/OutletStockAnalysis" element={<OutletStockAnalysis />} />
            <Route path="/Admin/OutletStockSummary" element={<OutletStockSummary />} />

            <Route path="/Admin/ExpiryRegister" element={<ExpiryRegister />} />
            <Route path="/Admin/BarcodePriceHistory" element={<BarcodePriceHistory />} />
            <Route path="/Admin/ItemwiseMargin" element={<ItemwiseMargin />} />
            <Route path="/Admin/PriceListing" element={<PriceListing />} />
            <Route path="/Admin/RepackRegister" element={<RepackRegister />} />
            <Route path="/Admin/SalesReturnRegister" element={<SalesReturnRegister />} />
            <Route path="/Admin/StockSummaryBatchwise" element={<StockSummaryBatchwise />} />
            <Route path="/Admin/StockSummaryPicklist" element={<StockSummaryPickList />} />
            <Route path="/Admin/StockTakingImport" element={<StockTakingImport />} />
            <Route path="/Admin/StockValueSummary" element={<StockValueSummary />} />
            <Route path="/Admin/StockTransferInReg" element={<StockTransferInReg />} />
            <Route path="/Admin/StockTransgerOutReg" element={<StockTransgerOutReg />} />

            <Route path="/Admin/AssignOnlineOrder" element={<AssignOrder />} />
            <Route path="/Admin/AssignOnlineOrder/AssignOrderDetail" element={<AssignOrderDetail />} />

            <Route path="/Admin/DespatchEntry" element={<DespatchEntry />} />
            <Route path="/Admin/DespatchEntry/DespatchEntryDetail" element={<DespatchEntryDetail />} />

            <Route path="/Admin/CourierPartnerCreation" element={<CourierPartnerCreation />} />
            <Route path="/Admin/CourierPartnerCreation/CourierPartnerCreationDetail" element={<CourierPartnerCreationDetail />} />

            <Route path="/Admin/NotificationRolls" element={<AssignNotificationRolls />} />
            <Route path="/Admin/NotificationRolls/List" element={<AssignNotificationRollsList />} />

            <Route path="/Admin/userRights" element={<UserAllocation />} />

            <Route path="/Admin/DamageEntryOutlet" element={<DamageEntryOutlet />} />
            <Route path="/Admin/DamageEntryOutlet/DamageEntryOutletDetail" element={<DamageEntryOutletDetail />} />

            <Route path="/Admin/DamageEntryWareHouse" element={<DamageEntryWarehouse />} />
            <Route path="/Admin/DamageEntryWarehouse/DamageEntryWarehouseDetail" element={<DamageEntryWarehouseDetail />} />

            <Route path="/Admin/StockToWarehouse" element={<StockToWarehouse />} />
            <Route path="/Admin/StockToWarehouse/:stockToWid" element={<StockToWarehouse />} />
            <Route path="/Admin/StockToWarehouse/List" element={<StockToWareHouseDetail />} />

            <Route path="/Admin/OnlineInvoiceDetail" element={<GenerateInvoiceDetail />} />

            <Route path="/Admin/SalesRegister" element={<SalesRegister />} />

            <Route path="/Admin/StockSummaryOutlet" element={<StockSummaryOutlet />} />
            <Route path="/Admin/StockSummaryWarehouse" element={<StockSummaryWarehouse />} />

            <Route path="/Admin/RejectionStock" element={<RejectionStock />} />
            <Route path="/Admin/RejectionStockOutlet" element={<RejectionStockOutlet />} />
            {/*  */}

            <Route path="/Admin/SalesSummaryItemWise" element={<SalesSummaryItemWise />} />

            <Route path="/Admin/PurchaseBillRegister" element={<PurchaseBillRegister />} />

            <Route path="/Admin/BillEntry" element={<BillEntry />} />
            <Route path="/Admin/BillEntry/List" element={<BillEntryDetails />} />

            <Route path="/Admin/InwardRegister" element={<InwardRegister />} />

            <Route path="/Admin/CourierEntry" element={<CourierEntry />} />
            <Route path="/Admin/CourierEntry/CourierEntryDetail" element={<CourierEntryDetail />} />

            <Route path="/Admin/ReceiptEntry" element={<ReceiptEntry />} />
            <Route path="/Admin/ReceiptEntry/ReceiptEntryDetail" element={<ReceiptEntryDetail />} />

            <Route path="/Admin/JournalEntry" element={<JournalEntry />} />
            <Route path="/Admin/JournalEntry/JournalEntryDetail" element={<JournalEntryDetail />} />

            <Route path="/Admin/DebitNote" element={<DebitNote />} />
            <Route path="/Admin/DebitNote/DebitNoteDetail" element={<DebitNoteDetails />} />

            <Route path="/Admin/CreditNote" element={<CreditNote />} />
            <Route path="/Admin/CreditNote/CreditNoteDetail" element={<CreditNoteDetails />} />

            <Route path="/Admin/CashBook" element={<CashBook />} />
            <Route path="/Admin/BankBook" element={<BankBook />} />
            <Route path="/Admin/DayBook" element={<DayBook />} />
            <Route path="/Admin/TrailBalance" element={<TrailBalance />} />
            {/*  */}

            <Route path="/Admin/MembershipPlan" element={<MembershipPlan />} />
            <Route path="/Admin/MembershipPlan/:memberplanId" element={<MembershipPlan />} />
            <Route path="/Admin/MembershipPlanList" element={<MembershipPlanList />} />

            <Route path="/Admin/AssignMembership" element={<AssignMembership />} />
            <Route path="/Admin/AssignMembership/:assignId" element={<AssignMembership />} />
            <Route path="/Admin/AssignMembershipList" element={<AssignMembershipList />} />

            <Route path="/Admin/AssignFreeItem" element={<AssignFreeItem />} />
            <Route path="/Admin/AssignFreeItemList" element={<AssignFreeItemList />} />
            <Route path="/Admin/AssignFreeItem/:itemfreeId" element={<AssignFreeItem />} />

            <Route path="/Admin/SalesReturnOrReplacement" element={<SalesReturnOrReplacement />} />
            <Route path="/Admin/salesretrun/list" element={<SalesReturnOrReplacementList />} />

            <Route path="/Admin/Invoice" element={<Invoice />} />
            <Route path="/Admin/Invoice/InvoiceDetail" element={<InvoiceDetail />} />

            <Route path="/Admin/SalesOrder" element={<SalesOrder />} />
            <Route path="/Admin/SalesOrder/List" element={<SalesOrderList />} />

            <Route path="/Admin/MinimumStockReport" element={<MinimumStockReport />} />

            <Route path='/Admin/MemberPointsStatement' element={<MemberPointsStatement />} />

            <Route path='/Admin/PartyLedger' element={<PartyLedger />} />
            <Route path='/Admin/PartyLedger/PartyLedgerDetail' element={<PartyLedgerDetail />} />

            {/* /Admin/GSTRAnnexure */}
            <Route path='/Admin/GSTRAnnexure' element={<GSTRAnnexure />} />

            <Route path='/Admin/GSTRAnnexure/2' element={<GSTRAnnexure2 />} />

            <Route path='/Admin/PurchaseReturn' element={<PurchaseReturn />} />
            <Route path='/Admin/PurchaseReturn/PurchaseReturnDetail' element={<PurchaseReturnDetail />} />

            <Route path='/Admin/Indent' element={<Indent />} />
            <Route path='/Admin/Indent/IndentDetail' element={<IndentDetail />} />

            <Route path='/Admin/CounterOpeningEntry' element={<CounterOpeningEntry />} />
            <Route path='/Admin/CounterOpeningEntry/:denominationid' element={<CounterOpeningEntry />} />
            <Route path='/Admin/CounterOpeningEntry/CounterOpeningEntryDetail' element={<CounterOpeningEntryDetail />} />

            <Route path='/Admin/ClosingCashHandover' element={<ClosingCashHandover />} />
            <Route path='/Admin/ClosingCashHandover/:denominationid' element={<ClosingCashHandover />} />
            <Route path='/Admin/ClosingCashHandover/ClosingCashHandoverDetail' element={<ClosingCashHandoverDetail />} />

            <Route path='/Admin/PaymentVoucher' element={<PaymentVoucher />} />
            <Route path='/Admin/PaymentVoucher/:vchr_no' element={<PaymentVoucher />} />
            <Route path='/Admin/PaymentVoucher/PaymentVoucherDetail' element={<PaymentVoucherDetail />} />

            <Route path='/Admin/PurchaseBill' element={<PurchaseBill />} />
            <Route path='/Admin/PurchaseBill/PurchaseBillDetail' element={<PurchaseBillDetail />} />

            <Route path='/Admin/QuoteFromSupplier' element={<QuoteFromSupplier />} />
            <Route path='/Admin/QuoteFromSupplier/QuoteFromSupplierDetail' element={<QuoteFromSupplierDetail />} />

            <Route path='/Admin/PurchaseApproval' element={<PurchaseApproval />} />
            <Route path='/Admin/PurchaseApproval/PurchaseApprovalDetail' element={<PurchaseApprovalDetail />} />

            <Route path='/Admin/PurchaseOrder' element={<PurchaseOrder />} />
            <Route path='/Admin/PurchaseOrder/:purchOrdId' element={<PurchaseOrder />} />
            <Route path='/Admin/PurchaseOrder/PurchaseOrderList' element={<PurchaseOrderList />} />

            <Route path='/Admin/PurchaseOrderDirect' element={<PurchaseOrderDirect />} />
            <Route path='/Admin/PurchaseOrderDirect/:purchOrdId' element={<PurchaseOrderDirect />} />
            <Route path='/Admin/PurchaseOrderDirect/List' element={<PurchaseOrderDirectList />} />


            <Route path='/Admin/PurchaseWorkOrder' element={<PurchaseWorkOrder />} />
            <Route path='/Admin/PurchaseWorkOrder/:purchOrdId' element={<PurchaseWorkOrder />} />
            <Route path='/Admin/PurchaseWorkOrder/List' element={<PurchaseWordOrderList />} />

            <Route path='/Admin/DirectInward' element={<DirectInwardOutlet />} />
            {/* <Route path='/Admin/DirectInwards' element={<DirectInWardOutlets />} /> */}
            <Route path='/Admin/DirectInward/DirectInwardDetail' element={<DirectInwardOutletDetail />} />

            <Route path='/Admin/AssignBatchOutlet' element={<AssignBatchOutlet />} />
            <Route path='/Admin/AssignBatchOutlet/AssignBatchOutletDetail' element={<AssignBatchOutletDetail />} />

            <Route path='/Admin/DamageEntryApproval' element={<DamageEntryApproval />} />
            <Route path='/Admin/DamageEntryApproval/DamageEntryApprovalDetails' element={<DamageEntryApprovalDetail />} />

            <Route path='/Admin/RejectionConversion' element={<RejectionConversion />} />
            <Route path="/Admin/RejectionConversionList" element={<RejectionStockList />} />

            <Route path='/Admin/DamageTransfer' element={<DamageTransfer />} />
            <Route path='/Admin/DamageTransfer/DamageTransferDetail' element={<DamageTransferDetail />} />

            <Route path='/Admin/OutletReturnForm' element={<OutletReturnForm />} />
            <Route path='/Admin/OutletReturnForm/List' element={<OutletReturnFormList />} />

            <Route path='/Admin/Category' element={<CategoryMaster />} />
            <Route path='/Admin/Category/:categoryId' element={<CategoryMaster />} />
            <Route path='/Admin/Category/List' element={<CategoryMasterDetail />} />

            <Route path='/Admin/IncentiveRecovery' element={<IncentiveAndRecoveryMaster />} />
            <Route path='/Admin/IncentiveRecovery/IncentiveRecoveryList' element={<IncentiveAndRecoveryMasterList />} />

            <Route path='/Admin/Deduction' element={<DeductionMaster />} />
            <Route path='/Admin/Deduction/DeductionList' element={<DeductionMasterDetail />} />

            <Route path='/Admin/LeaveSetting' element={<LeaveSettingMaster />} />
            <Route path='/Admin/LeaveSetting/leaveSettingList' element={<LeaveSettingMasterDetail />} />

            <Route path='/Admin/BreakInOutEntry' element={<BreakingInOutEntry />} />
            <Route path='/Admin/BreakInOutEntry/BreakInOutEntryList' element={<BreakInOutEntryDetail />} />

            <Route path='/Admin/ManualAttendanceEntry' element={<ManualAttendanceEntry />} />
            <Route path='/Admin/ManualAttendanceEntry/list' element={<ManualAttendanceEntryDetail />} />

            <Route path='/Admin/MDApprovalEntry' element={<MDApprovalEntry />} />
            <Route path='/Admin/MDApprovalEntry/list' element={<MDApprovalEntryDetail />} />

            <Route path='/Admin/SalaryGeneration' element={<SalaryGeneration />} />
            <Route path='/Admin/SalaryGeneration/SalaryGenerationList' element={<SalaryGenerationDetail />} />

            <Route path='/Admin/LoanAdvanceRequest' element={<LoanAndAdvanceRequest />} />
            <Route path='/Admin/LoanAdvanceRequest/LoanAdvanceRequestList' element={<LoadAndAdvanceRequestDetail />} />

            <Route path='/Admin/LoanAdvanceApproval' element={<LoanAndAdvanceApproval />} />
            <Route path='/Admin/LoanAdvanceApproval/LoanAdvanceApprovalList' element={<LoadAndAdvanceApprovalDetail />} />

            <Route path='/Admin/LeaveRequest' element={<LeaveRequest />} />
            <Route path='/Admin/LeaveRequest/List' element={<LeaveRequestList />} />

            <Route path='/Admin/LeaveApproval' element={<LeaveApproval />} />
            <Route path='/Admin/LeaveApproval/List' element={<LeaveApprovalList />} />

            <Route path='/Admin/JobMaster' element={<JobMaster />} />
            <Route path='/Admin/JobMaster/list' element={<JobMasterList />} />

            <Route path='/Admin/JobVacancy' element={<JobVacancy />} />
            <Route path='/Admin/JobVacancy/list' element={<JobVacancyList />} />

            <Route path='/Admin/VacancyApproval' element={<VacancyApproval />} />
            <Route path='/Admin/VacancyApproval/List' element={<VacancyApprovalList />} />

            <Route path='/Admin/ResumeBank' element={<ResumeBank />} />
            <Route path='/Admin/ResumeBank/ResumeBankList' element={<ResumeBankList />} />

            <Route path='/Admin/ResumeShortlisting' element={<ResumeShortlisting />} />
            <Route path='/Admin/ResumeShortlisting/List' element={<ResumeShortlistedList />} />

            <Route path='/Admin/InterviewMaster' element={<InterviewMaster />} />
            <Route path='/Admin/InterviewMaster/InterviewMasterList' element={<InterviewMasterList />} />

            <Route path='/Admin/InterviewLetter' element={<InterviewLetter />} />
            <Route path='/Admin/InterviewLetter/List' element={<InterviewLetterList />} />

            <Route path='/Admin/InterviewProcess' element={<InterviewProcess />} />
            <Route path='/Admin/InterviewProcess/List' element={<InterviewProcessList />} />

            <Route path='/Admin/SelectionProcess' element={<SelectionProcess />} />
            <Route path='/Admin/SelectionProcess/List' element={<SelectionProcessList />} />

            <Route path='/Admin/SelectionApproval' element={<SelectionApproval />} />
            <Route path='/Admin/SelectionApproval/List' element={<SelectionApprovalList />} />

            <Route path='/Admin/OfferLetterGeneration' element={<OfferLetterGeneration />} />
            <Route path='/Admin/OfferLetterGeneration/List' element={<OfferLetterList />} />

            <Route path='/Admin/AppointmentProcess' element={<AppointmentProcess />} />
            <Route path='/Admin/AppointmentProcess/List' element={<AppointmentProcessList />} />

            <Route path='/Admin/EmployeePromotion' element={<EmployeePromotion />} />
            <Route path='/Admin/EmployeePromotion/List' element={<EmployeePromotionList />} />

            <Route path='/Admin/PromotionApproval' element={<PromotionApprovalMaster />} />
            <Route path='/Admin/PromotionApproval/List' element={<PromotionApprovalMasterList />} />

            <Route path='/Admin/IncrementMaster' element={<IncrementEntry />} />
            <Route path='/Admin/IncrementMaster/List' element={<IncreamentEntryList />} />

            <Route path='/Admin/IncrementApproval' element={<IncreamentApprovalMaster />} />
            <Route path='/Admin/IncrementApproval/List' element={<IncreamentApprovalMasterList />} />

            <Route path='/Admin/ResignationMaster' element={<ResignationEntry />} />
            <Route path='/Admin/ResignationMaster/List' element={<ResignationEntryList />} />

            <Route path='/Admin/ResignationApproval' element={<ResignationApproval />} />
            <Route path='/Admin/ResignationApproval/List' element={<ResignationApprovalList />} />

            <Route path='/Admin/Rejoining' element={<Rejoining />} />
            <Route path='/Admin/Rejoining/List' element={<RejoiningList />} />

            <Route path='/Admin/AttendanceReport' element={<AttendanceReport />} />

            <Route path='/Admin/WeeklyAttendanceReport' element={<WeeklyAttendanceReport />} />

            <Route path='/Admin/DailyAttendanceReport' element={<DailyAttendanceReport />} />

            <Route path='/Admin/AttendanceRegister' element={<AttendanceRegister />} />

            <Route path='/Admin/MonthlyAbsentRegister' element={<MonthlyAbsentRegister />} />

            <Route path='/Admin/TimeSheetRegister' element={<TimeSheetReport />} />

            <Route path='/Admin/TimeSheetRegisterOT' element={<TimeSheetReportOT />} />

            <Route path='/Admin/PunchingRegister' element={<PunchingRegister />} />

            <Route path='/Admin/SalaryRegister' element={<SalaryRegister />} />

            <Route path='/Admin/StrengthRegister' element={<StrengthRegister />} />

            <Route path='/Admin/SalaryRevisionRegister' element={<SalaryRevisionRegistry />} />

            <Route path='/Admin/EmployeeInformationReport' element={<EmployeeInformationReport />} />

            <Route path='/Admin/ReleavedEmployee' element={<ReleavedEmployee />} />

            <Route path='/Admin/RejoinedEmployee' element={<RejoinedEmployee />} />

            {/* Ecom Naviagtion */}

            <Route path="/" element={<Home />} />
            <Route path="/Registration" element={<Registration />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/Category" element={<Category />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/products/:productId" element={<Products />} />
            <Route path="/cartsview" element={<Carts />} />
            <Route path="/checkoutorder" element={<CheckOut />} />
            <Route path="/accountClick" element={<AccountClick />} />
            <Route path="/trackingorder" element={<TrackingOrder />} />
            <Route path="/trackingpackage" element={<TrackingPackage />} />
            <Route path="/wishlist" element={<WishList />} />
          </Routes>
        </div>
      </div>
      <EmbedPDFViewer fileUrl={userManual} showPdf={showPdf} setShowPDF={(value) => setShowPDF(value)} />
    </div>


  )
}

export default App;