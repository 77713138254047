import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import { Autocomplete, TextField } from '@mui/material';
import moment from "moment";
import { getCurrentMonthFirstDate, sortedData } from "../../common/shared/utils";

interface ExcelSheetData {
    Date: string,
    Item: string,
    Type: string,
    Unit_Name: string,
    Barcode: string,
    Batch_No: string,
    Rejection_Stock: string,
}

class ISearchCriteria {
    fromdate: string = getCurrentMonthFirstDate()?.firstDate;
    todate: string = getCurrentMonthFirstDate()?.currentDate
}

const RejectionStockOutlet = () => {

    const { warehouseStores } = useStores();

    const [rejecConvJson, setRejecConvJson] = useState<any[]>([]);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [localFields, setLocalFields] = useState<ISearchCriteria>(new ISearchCriteria())
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false, brand: false })

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;
        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false, brand: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false, brand: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true, brand: false })
        }
        if (value === "Brand") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: true })
        }
    }

    // function handleValue(type: string, event, value) {
    //     if (type == 'subCategoryId') {
    //         setLocalFields({ ...localFields, [type]: value.subCategoryId });
    //     } else if (type === "brand") {
    //         setLocalFields({ ...localFields, [type]: value.brandCode });
    //     } else {
    //         setLocalFields({ ...localFields, [type]: value.itemId });
    //     }
    // }

    function validation(data) {
        let error: any = {}
        if (!data?.fromdate) {
            error.fromdate = 'Select From Date'
        }
        if (!data?.todate) {
            error.todate = 'Select To Date'
        }
        return error
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = validation(localFields)
        setErrors({ ...error });
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const rejeConvJSON = await warehouseStores?.damageOutletRejectionDetails(localFields?.fromdate, localFields?.todate)
            if (rejeConvJSON?.length) {
                setRejecConvJson([...sortedData(rejeConvJSON, 'DOM_Date')])
            }

        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        if (stockSummary?.length) {
            let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
                const excelObj: ExcelSheetData = {
                    Date: moment(stock?.DOM_Date).format("DD-MMM-YYYY"),
                    Item: stock?.itemname,
                    Type: stock?.DOT_Type,
                    Unit_Name: stock?.Unitname,
                    Barcode: stock?.Barcode,
                    Batch_No: stock?.BatchNo,
                    Rejection_Stock: stock?.DOT_Qty,
                }
                return excelObj;
            })
            exportToExcel(excelArr)
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Rejection Stock - Outlet", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Rejection Stock - Outlet ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchInitialLoad() {
        setLoading(false)
    }

    const isCurrenPage = useRef(true)
    useEffect(() => {
        if (isCurrenPage.current) {
            fetchInitialLoad()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Rejection Stock - Outlet</h3>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className='inputBox'>
                                            <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                                            <input type="date" style={{ width: "100%" }}
                                                name="fromdate"
                                                onChange={handleInputChange} value={localFields?.fromdate}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                            ></input>
                                        </div>
                                        {errors.fromdate && <p style={{ color: 'red' }}>{errors.fromdate}</p>}
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='inputBox'>
                                            <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                                            <input type="date" style={{ width: "100%" }}
                                                name="todate"
                                                onChange={handleInputChange} value={localFields?.todate}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                            ></input>
                                        </div>
                                        {errors.todate && <p style={{ color: 'red' }}>{errors.todate}</p>}
                                    </div>
                                    <div className="col-sm-4"></div>
                                    {/* <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                            <div className='inputBox' style={{ marginTop: '5px', padding: "10px 10px" }}>
                                                <select style={{ width: "175px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                                    <option value="">Select Search Criteria</option>
                                                    <option value="ItemName">Item Name</option>
                                                    <option value="Type">CateGory </option>
                                                    <option value="SubCategory">Sub Type </option>
                                                    <option value="Brand">Brand</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                            {searchCriteria?.category ?
                                                <>
                                                    <div className='inputBox'>
                                                        <select style={{ marginTop: "0px", height: "47px" }} className="form-selected" onChange={handleInputChange} id="Type" name="category">
                                                            <option value="" selected>Not Select</option>
                                                            <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                            <option value="Pets">Pets</option>
                                                            <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                            <option value="Grocery">Grocery</option>
                                                        </select>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </> : searchCriteria?.itemName || searchCriteria?.subCategory || searchCriteria?.brand ?
                                                    <div className='inputBox'>
                                                        <Autocomplete size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={searchCriteria?.itemName ? loadItemConversions : searchCriteria?.subCategory ? loadSubcategories : loadbrands}
                                                            getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                            onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : searchCriteria?.subCategory ? handleValue('subCategoryId', event, val) : handleValue("brand", event, val)}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                                    name={searchCriteria?.itemName ? 'itemId' : searchCriteria?.subCategory ? 'subcategory' : 'brandCompany'}
                                                                />}
                                                        />
                                                    </div> :
                                                    <div className="">

                                                    </div>
                                            }
                                        </div> */}
                                    <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                        <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                    </div>
                                    <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                        <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(rejecConvJson)}>Excel</button>
                                    </div>
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Unit name</th>
                                            {/* <th scope="col">Supplier Id</th> */}
                                            <th scope="col">Barcode</th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">Rejection Stock</th>
                                            {/* <th scope="col">Rate</th> */}
                                            {/* <th scope="col">Total</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rejecConvJson?.length > 0 ?
                                            rejecConvJson?.map((items, index) => {
                                                console.log(rejecConvJson, 'rejecConvJson>>')
                                                return (
                                                    <tr key={index}>
                                                        <td>{new Date(items?.DOM_Date)?.toISOString()?.substr(0, 10)}</td>
                                                        <td>{items?.itemname}</td>
                                                        <td>{items?.DOT_Type}</td>
                                                        <td>{items?.Unitname}</td>
                                                        {/* <td>{items?.DOM_SupplierId}</td> */}
                                                        <td>{items?.Barcode}</td>
                                                        <td>{items?.BatchNo}</td>
                                                        <td>{items?.DOT_Qty}</td>
                                                        {/* <td>{items?.DOT_Rate}</td> */}
                                                        {/* <td>{items?.DOT_TotalAmt}</td> */}
                                                    </tr>
                                                )
                                            }) : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default RejectionStockOutlet;