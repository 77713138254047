import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { ITeamMasterSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const TeamMasterDetail = observer((): JSX.Element => {
  const [teamMasterSearchOptions, setTeamMasterSearchOptions] = useState<ITeamMasterSearchoptions>({})
  const { teamMasterStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  console.log(userRights,'userRights');
  
  const { getTeamMasterDetails, teamMasterDetail } = teamMasterStore;
  const [isLoading, setLoading] = useState(true);
  const [isTeamMasterShow, setTeamMasterModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteTeam = async (teamMasterId) => {
    setLoading(true);
    const status = await teamMasterStore.deleteTeamMaster(teamMasterId);

    if (status === 'Success') {
      fetchTeamMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const editTeamMaster = (teamId) => {
    navigate('/Admin/TeamMaster/' + teamId);
  }

  const deleteTeamDetail = async (itemConversionDetailId) => {
    setTeamMasterModal(false);
    setLoading(true);
    const status = await teamMasterStore.deleteTeamMasterDetail(itemConversionDetailId);

    if (status === 'Success') {
      fetchTeamMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchTeamMaster();
  }

  const getTeamMasterDetail = async (employeeId) => {
    const data = await getTeamMasterDetails(employeeId);
    if (data !== undefined || data !== null) {
      setTeamMasterModal(true);
    }
  }

  const handleCreate = () => {
    navigate('/Admin/TeamMaster');
  }

  const handleClose = () => {
    setTeamMasterModal(false);
  }

  async function fetchTeamMaster() {
    setLoading(false)
    const userRights = await Promise.all([userCreationStore?.getUserRight('Team_master'), teamMasterStore?.getTeamMaster()])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchTeamMaster();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (teamMasterStore?.teamMasterList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(teamMasterStore?.teamMasterList)
    }
  }, [teamMasterStore?.teamMasterList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(teamMasterStore?.teamMasterList?.slice().sort((a, b) => b.teamMasterId - a.teamMasterId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={teamMasterStore?.teamMasterList}
                searchTitles={[{ key: "teamMasterName", value: "Team Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{teamMasterStore?.teamMasterList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th scope='col'> Team ID </th> */}
                      <th scope='col'> Team Name </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                      <th scope='col'> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{val.teamMasterId}</td> */}
                            <td>{val.teamMasterName}</td>
                            <td><button disabled={!userRights?.[0]?.view} onClick={() => getTeamMasterDetail(val.teamMasterId)}>Detail</button></td>
                            <td><button disabled={!userRights?.[0]?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.[0]?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editTeamMaster(val.teamMasterId)}/></button></td>
                            <td><button disabled={!userRights?.[0]?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteTeam(val.teamMasterId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
              {
                isTeamMasterShow ?
                  <Modal show={isTeamMasterShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Employee ID</th>
                              <th scope="col">Employee Name</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              teamMasterDetail?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.employeeId}</td>
                                    <td>{val.employeeName}</td>
                                    {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteTeamDetail(val.teamMasterDetailId)} /></button></td> */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default TeamMasterDetail;
