function replaceReturnValidation(returnReplacement, options) {

    let errors: any = {};
    if (Object.keys(options).length === 0) {
        errors.options = "Select Options";
    } else {
        if (!options?.options) {
            errors.option = 'Select Options'
        }
        if (!options?.returnOptions) {
            errors.returnOptions = 'Select Bill Type'
        }
    }
    if (!returnReplacement.returnDate) {
        errors.returnDate = "Select  Retrun Date";
    }
    if (!returnReplacement.invoiceId && returnReplacement.isInvoice) {
        errors.invoiceId = "Select Invoice No";
    }
    if (!returnReplacement.posId && returnReplacement.isPos) {
        errors.invoiceId = "Select Pos No";
    }
    if (!returnReplacement.itemId) {
        errors.itemId = "Select Item";
    }
    if (!returnReplacement.batchNo) {
        errors.batchNo = "Select Batch";
    }
    if (!returnReplacement.returnQty) {
        errors.returnQty = "Enter Return Quantity";
    }
    if (!returnReplacement.returnAmount) {
        errors.returnAmount = "Enter Return Amount";
    }
    if (!returnReplacement.reason) {
        errors.reason = "Enter Return Reason";
    }
    return errors;
};

export default replaceReturnValidation;