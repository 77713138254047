import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faL, faPenToSquare, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Spinner, Image, Modal, Button } from 'react-bootstrap';
import { IOutletSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const OutletList = observer((): JSX.Element => {

  const navigate = useNavigate();
  const { outletStores, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const editOutlet = (outletId) => {
    outletStores.getOutletDetails(outletId);
    //history.push('/src/views/Admin/Outlet/Outlet.tsx')
  }

  const deleteOutlet = async (outletId) => {
    setLoading(true);
    const status = await outletStores.deleteOutlet(outletId);
    if (status === 'Success') {
      // fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    fetchPreLoadngData();
    setSuccessModal(false);

  }

  const handleCreate = () => {
    navigate('/Admin/outlet');
  }

  async function fetchPreLoadngData() {
    let search: IOutletSearchoptions = {
      searchOption: '',
      searchValue: '',
    }
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Outlet_registration'), outletStores.getOutlets(search)]);
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    setLoading(false);
  }

  const isCurrentPage = useRef(true);
  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (outletStores?.outletList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(outletStores?.outletList)
    }
  }, [outletStores?.outletList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(outletStores?.outletList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='inputBoxLists'>
                  <div className='ItemInwardInputBox'>
                    <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={outletStores?.outletList}
                      searchTitles={[{ key: "mobileNo", value: "Mobile No" }, { key: "fssaiNumber", value: "FSSAI No" }]}
                      emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                    <div className='vertical-space-20'></div>
                    <div className='row'>
                      <div className='col-sm-5'>
                        <div className='btnBox'>
                          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletStores?.outletList?.length} Records</button>
                        </div>
                      </div>
                      <div className=' col-sm-7' >
                        <Stack spacing={2}>
                          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                        </Stack>
                      </div>
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='tableListDetails'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Outlet</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Bank Account</th>
                            <th scope="col">FSSAI</th>
                            <th scope="col">Dt Of Incorp.</th>
                            <th scope="col">Address</th>
                            <th scope="col">Create</th>
                            <th scope="col">Update</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(isSearch ? filteredData : currentPageData).length === 0 ? <tr>No records</tr> :
                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.outletName}</td>
                                  <td>{val.emailID}</td>
                                  <td>{val.mobileNo}</td>
                                  <td>{val.bankAccount}</td>
                                  <td>{val.fssaiNumber}</td>
                                  <td>{val.dateOfIncrorpeted}</td>
                                  <td>{val.address}</td>
                                  <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                  <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editOutlet(val.outletId)} /></button></td>
                                  <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteOutlet(val.outletId)} /></button></td>
                                </tr>
                              )
                            }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Deleted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>
      }

    </>
  );
});

export default OutletList;


