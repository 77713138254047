import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';

class MemberPointsStore {
    @observable memberPointsDetails= new Map();   
    @observable mobileNos= new Map();   

    getMemberPointsStatement  = async (memberPoinstRequest)=> {
        try {
          const pointsResponse= await apiAgent.adminReports.getMemberPointsStatement(memberPoinstRequest);
            
            runInAction(() => {
                this.memberPointsDetails = pointsResponse;
            });
            return this.memberPointsDetails;
        }
        catch (error) {
            console.log(error);
        }
    }  
    loadMobileNumbers=async()=>{
        try {
            const mobilesNos= await apiAgent.adminReports.loadMobileNumbers();
              
              runInAction(() => {
                  this.mobileNos = mobilesNos;
              });
              return this.mobileNos;
          }
          catch (error) {
              console.log(error);
          }
    }

    importExportFormSave = async (supplierMasters) => {
        try {
            const status = await apiAgent.adminReports.importExportFormSave(supplierMasters);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default MemberPointsStore;