import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Image, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ProgressBar from '../../common/shared/progressbar';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const ShiftMasterDetail = observer((): JSX.Element => {
  const { shiftMasterStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getShiftMaster, shiftMasterList, getshiftMasterDetls, getShiftMasterDetails } = shiftMasterStore;
  const [isLoading, setLoading] = useState(true);
  const [isShiftMaster, setShiftMasterModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  
  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const editShiftMasterDetials = (shiftId) => {
    navigate('/Admin/ShiftMaster/' + shiftId);
  }

  const deleteShiftMaster = async (shiftId) => {
    setLoading(true);
    const status = await shiftMasterStore.deleteShiftMaster(shiftId);

    if (status === 'Success') {
      fetchShiftMaster();
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteShiftDetail = async (shiftdetid) => {
    setLoading(true);
    const status = await shiftMasterStore.deleteShiftDetail(shiftdetid);

    if (status === 'Success') {
      fetchShiftMaster();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchShiftMaster();
  }

  async function handleShiftMasters(shiftId, e) {
    e.preventDefault();
    await getShiftMasterDetails(shiftId);
    setShiftMasterModal(true);
  }

  const handleClose = () => {
    setShiftMasterModal(false)
  }

  const handleCreate = () => {
    navigate('/Admin/ShiftMaster')
  }

  async function fetchShiftMaster() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Shift_master'), getShiftMaster()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCurrenPage = useRef(true)
  useEffect(() => {
    if (isCurrenPage.current) {
      fetchShiftMaster();
      isCurrenPage.current = false;
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (shiftMasterList && shiftMasterList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(shiftMasterList)
    }
  }, [shiftMasterList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(shiftMasterList?.slice()?.sort((a, b) => b.shiftId - a.shiftId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={shiftMasterList}
                searchTitles={[{ key: "shiftName", value: "Shift Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{shiftMasterList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Shift ID </th>
                      <th scope='col'>Shift Name </th>
                      <th scope='col'>Starting Time </th>
                      <th scope='col'>Ending Time </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.shiftId} </td>
                            <td>{val.shiftName} </td>
                            <td>{moment(val.startingTime).format('hh:mm:ss A')}</td>
                            <td>{moment(val.endingTime).format('hh:mm:ss A')}</td>
                            <td><button disabled={!userRights?.view} className='changeBtn' onClick={(e) => handleShiftMasters(val.shiftId, e)}>Break Details</button></td>
                            <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit' ><FontAwesomeIcon icon={faPenToSquare} onClick={() => editShiftMasterDetials(val.shiftId)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteShiftMaster(val.shiftId)} /></button></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              {
                isShiftMaster ?
                  <Modal show={isShiftMaster} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Break Name</th>
                              <th scope="col">Starting Time</th>
                              <th scope="col">Ending Time</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {getshiftMasterDetls.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.breakTime} </td>
                                  <td>{moment(val.startingTime).format('hh:mm:ss A')}</td>
                                  <td>{moment(val.endingTime).format('hh:mm:ss A')}</td>
                                  {/* <td><button className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteShiftDetail(val.shiftdetid)} /></button></td> */}
                                </tr>
                              )
                            }
                            )}
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  );

});

export default ShiftMasterDetail;
