import { makeObservable } from "mobx";
import apiAgent from "../../APIAgent";

class IHRReports {
    constructor() {
        makeObservable(this);
    }

    getReportAttendence = async (month, year) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportAttendence(month, year);
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportBalance = async (month, year) => {
        try {
            const reportBalance = await apiAgent.hrReportsstore.getReportBalance(month, year);
            return JSON.parse(reportBalance);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportWeeklyAttendence = async (sdate, edate) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportWeeklyAttendence(sdate, edate);
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportWeeklyBalance = async (sdate, edate) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportWeeklyBalance(sdate, edate);
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReporDailyAttendence = async (sdate, edate, empId) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReporDailyAttendence(sdate, edate, empId);
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportAttendenceRegister = async (month, year) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportAttendenceRegister(month, year);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportAbsentAttendence = async (month, year, empId) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportAbsentAttendence(month, year, empId);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportAbsentBalance = async (month, year, empId) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportAbsentBalance(month, year, empId);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportTimeSheetRegister = async (sdate, edate, empId) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportTimeSheetRegister(sdate, edate, empId);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportOTTimeSheetRegister = async (sdate, edate, empId) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportOTTimeSheetRegister(sdate, edate, empId);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }
    getReportSalaryRegister = async (month, year) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportSalaryRegister(month, year);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportStrengthRegister = async (sdate) => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportStrengthRegister(sdate);
            return reportAttendacne;
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportEmpInformationRegister = async () => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportEmpInformationRegister();
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }
    getReportRelievedEmployee = async () => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportRelievedEmployee();
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }

    getReportRejoinedEmployee = async () => {
        try {
            const reportAttendacne = await apiAgent.hrReportsstore.getReportRejoinedEmployee();
            return JSON.parse(reportAttendacne);
        }
        catch (error) {
            console.log(error);
        }
    }


}

export default IHRReports;