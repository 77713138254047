// SalesGraph.tsx

import * as React from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns'; // Import the adapter for date-fns
import { Chart, ChartData, ChartOptions, Tooltip } from 'chart.js';
import { LineController, LineElement, PointElement } from 'chart.js';
import { TimeScale, LinearScale } from 'chart.js';
import { useTranslation } from 'react-i18next';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useState } from 'react';
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import moment from 'moment';

Chart.register(LineController, LineElement, PointElement, TimeScale, LinearScale, Tooltip);


const SalesGraph = ({ graphData }) => {
    const { t } = useTranslation();
    const dashBoardKeys: any = t("dashBoardKeys")

    const [open, setOpen] = useState(false)
    const [state, setState] = useState([{ startDate: new Date(), endDate: addDays(new Date(), 7), key: 'selection' }]);

    const maxBillValue = React.useMemo(() => {
        let maxValue: number = 0;
        if (graphData?.length) {
            maxValue = graphData?.reduce((maxValue, currentObject) => {
                return Math.max(maxValue, currentObject.billvalue);
            }, -Infinity);
        }
        return maxValue;
    }, [graphData])

    const sortedData = React.useMemo(() => {
        let sortedData: any[] = [];
        if (graphData?.length) {
            sortedData = graphData?.slice()?.sort((a, b) => {
                const dateA = new Date(a.billdate);
                const dateB = new Date(b.billdate);

                if (dateA > dateB) {
                    return -1;
                }
                if (dateB < dateA) {
                    return 1;
                }
            })?.filter(obj => new Date(obj?.billdate) >= state[0]?.startDate && new Date(obj?.billdate) <= state[0]?.endDate);
        }
        return sortedData;
    }, [state, graphData])

    const chartData = {
        labels: sortedData?.map((entry) => entry.billdate),
        datasets: [
            {
                label: 'Sales Price',
                data: sortedData?.map((entry) => entry.billvalue),
                borderColor: 'blue',
                fill: true,
            },
        ],
    };

    const options: ChartOptions<'line'> = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        day: 'd-MMM',
                    },
                },
                grid: {
                    display: false, // Disable vertical gridlines
                },
            },
            y: {
                position: 'right',
                beginAtZero: true,
                max: maxBillValue
            },
        },
        animation: {
            duration: 1000, // Set animation duration in milliseconds
            easing: 'easeInOutQuad', // Set easing function (linear, easeInQuad, easeOutQuad, easeInOutQuad, etc.)
        },
    };

    return (
        <div className="">
            <div className="" style={{ display: 'flex', borderBottom: '1px solid lightgray', padding: '10px', justifyContent: 'space-between' }}>
                <div className="" style={{ fontSize: '18px' }}>
                    {dashBoardKeys?.salesGraph}
                </div>
                <div className='calenderWrap'>
                    <input
                        value={`${moment(state[0]?.startDate).format('DD/MM/YYYY')} to ${moment(state[0]?.endDate).format('DD/MM/YYYY')}`}
                        readOnly
                        onClick={() => setOpen(open => !open)}
                        style={{ padding: '6px', width: '100%' }}
                    />
                    <div>
                        {open && (
                            <DateRange
                                onChange={item => setState([item.selection])}
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={state}
                                direction="horizontal"
                                className="calenderElement"
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="" style={{ padding: '30px', }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default SalesGraph;
