import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const CategoryMasterDetail = observer((): JSX.Element => {

  const { subCategoryStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [categoryList, setCategoryList] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/Category')
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  async function handleDelete(catid: any) {
    setLoading(true)
    const status = await subCategoryStore.deleteCategoryMaster(catid)
    if (status === 'Success') {
      setSuccessModal(true)
      fetchPreLoadngData();
    } else {
      setFailureModal(true)
    }
    setLoading(false)
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    const [userRights, categoryMasterDetails] = await Promise.all([userCreationStore?.getUserRight('Category_master'), subCategoryStore.loadCatergoryMaster()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    setCategoryList([...categoryMasterDetails])
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (categoryList && categoryList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(categoryList)
    }
  }, [categoryList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(categoryList?.slice().sort((a, b) => b?.MC_ID - a?.MC_ID), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={categoryList}
              searchTitles={[{ key: "Emp_Category_Name", value: "Category Name" }]}
              emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
            <div className='vertical-space-20'></div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{categoryList?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Category Name</th>
                    <th scope="col">Create</th>
                    <th scope="col">Update</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {(isSearch ? filteredData : currentPageData)?.map((val, key) => {
                    return (
                      <tr>
                        <td>{val?.MC_ID}</td>
                        <td>{val?.MC_Name}</td>
                        <td><button disabled={!userRights?.add} className='Create' onClick={handleCreate} ><FontAwesomeIcon icon={faAdd} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit' onClick={() => { }}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete' onClick={() => handleDelete(val?.MC_ID)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                      </tr>
                    )
                  }
                  )}
                </tbody>
              </table>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
      }
    </>

  )
})

export default CategoryMasterDetail;