import React, { useEffect, useMemo, useRef, useState } from "react"
import { PartyLedgerSearch } from "../../Admin/PartyLedger/model"
import useStores from "../../hooks"
import LedgerSearchValidation from "../../Admin/PartyLedger/validation"
import moment from "moment"
import { Spinner } from "react-bootstrap"

interface ExcelSheetData {
    Ledger_Name?: string,
    Opening_Credit?: any,
    Opening_Debit?: any,
    Credit?: any,
    Debit?: any,
    Closing_Credit?: any,
    Closing_Debit?: any,
}

const TrailBalance = () => {
    const [dayBookSearch, setDayBookSearch] = useState<PartyLedgerSearch>(new PartyLedgerSearch())
    const [dayBookLedgerStatement, setDayBookLedgerStatement] = useState<any[]>([])

    const [ledgerNameArr, setLedgerNameArr] = useState<any[]>([])
    const [selectedLedger, setSelectedLedger] = useState<any>({})

    const { partyLedgerStore } = useStores();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({})

    function handleInputChange(event) {
        const { name, value } = event?.target;
        setDayBookSearch({ ...dayBookSearch, [name]: value })
    }

    async function fetchPartyLedgerList() {
        let error: any = {};
        if (dayBookSearch?.searchDatefrom && dayBookSearch?.searchDateto) {
            if (Object.keys(error).length === 0) {
                setIsLoading(true);
                const dayBookLedgerStatement = await partyLedgerStore.getTrialBalance(dayBookSearch);
                if (dayBookLedgerStatement?.length) {
                    setDayBookLedgerStatement([...(dayBookLedgerStatement)]);
                }
                setIsLoading(false);
            }
        } else {
            if (!dayBookSearch.searchDatefrom) {
                error.searchDatefrom = "Enter From Date";
            }
            if (!dayBookSearch.searchDateto) {
                error.searchDateto = "Enter To Date";
            }
        }
        setErrors(error);
    }

    function createExportExcelObj(partyLedgerDetails: any[]) {
        let excelArr: ExcelSheetData[] = partyLedgerDetails?.map((statement) => {
            const excelObj: ExcelSheetData = {
                Ledger_Name: statement?.sort_order === 1 ? statement?.group_name : statement?.ledger_accout_name,
                Opening_Credit: statement?.OpeningCredit > 0 ? statement?.OpeningCredit : '0.00',
                Opening_Debit: statement?.OpeningDebit > 0 ? statement?.OpeningDebit : '0.00',
                Credit: statement?.Credit > 0 ? statement?.Credit : '0.00',
                Debit: statement?.Debit > 0 ? statement?.Debit : '0.00',
                Closing_Credit: statement?.ClosingCredit > 0 ? statement?.ClosingCredit : '0.00',
                Closing_Debit: statement?.ClosingDebit > 0 ? statement?.ClosingDebit : '0.00',
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Trail Balance Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Trail Balance Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function handlePrint() {
        let printContents = document.getElementById('printablediv')!.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    async function fetchPartyLedgerName() {
        let ledgerNameSearchOption = {
            searchOption: "LedgerAccountName",
            searchValue: ""
        };
        const ledgerNameArry = await partyLedgerStore.loadPartyLedgername(ledgerNameSearchOption)
        if (ledgerNameArry?.length) {
            setLedgerNameArr([...ledgerNameArry])
        }
    }

    function handleChangeclear() {
        setDayBookSearch(new PartyLedgerSearch());
        setDayBookLedgerStatement([]);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPartyLedgerName()
            isCurrentPage.current = false
        }
    }, [])

    return (
        <div className="contaienr">
            {isLoading ? <div className='SpinnerBox'>
                <Spinner animation="grow" size="sm" />
            </div> : null}
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
                <div className='hrBox'>
                    <h3>Trail Balance</h3>
                </div>
                <div className='inputBoxLists'>
                    <div className='ItemInwardInputBox'>
                        <div className="row">
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>From Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        onChange={handleInputChange}
                                        name="searchDatefrom"
                                        value={dayBookSearch?.searchDatefrom}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>To Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        value={dayBookSearch?.searchDateto}
                                        name="searchDateto"
                                        onChange={handleInputChange}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-20'></div>
                            </div>
                            <div className="col-sm-8" style={{ marginTop: '30px' }}>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={fetchPartyLedgerList}> Search</button>
                                    <button className='secondaryBtn' onClick={handlePrint}> Print</button>
                                    <button className='secondaryBtn' onClick={() => createExportExcelObj(dayBookLedgerStatement)}>Excel</button>
                                    <button className="secondaryBtn" onClick={handleChangeclear}> Clear</button>
                                    <button className="secondaryBtn" onClick={() => { }}> Close </button>
                                </div>
                            </div>
                        </div>
                        <div style={dayBookLedgerStatement?.length > 0 ? { visibility: 'visible', width: '100%' } : { visibility: "hidden" }}>
                            <div id='printablediv' className="tableListDetails">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colSpan={2}>Opening</th>
                                            <th colSpan={2}>Transaction</th>
                                            <th colSpan={2}>Closing</th>
                                        </tr>
                                        <tr>
                                            <th scope="col">Ledger Name</th>
                                            <th scope="col">Debit</th>
                                            <th scope="col">Credit</th>
                                            <th scope="col">Debit</th>
                                            <th scope="col">Credit</th>
                                            <th scope="col">Debit</th>
                                            <th scope="col">Credit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dayBookLedgerStatement?.map((statement, index) => {
                                            return (
                                                <tr>
                                                    <td scope='col' style={statement?.sort_order === 1 ?
                                                        { borderBottom: '1px solid #dee2e6', padding: '8px', color: 'darkmagenta', fontSize: '15px', fontWeight: '600' } :
                                                        statement?.sort_order === 3 ? { borderBottom: '1px solid #dee2e6', padding: '8px', color: 'red', textAlign: 'end', fontSize: '15px', fontWeight: '600' } :
                                                            {}}>
                                                        {statement?.sort_order === 1 ? statement?.group_name : statement?.ledger_accout_name}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.OpeningCredit > 0 ? statement?.OpeningCredit : '0.00'}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.OpeningDebit > 0 ? statement?.OpeningDebit : '0.00'}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.Credit > 0 ? statement?.Credit : '0.00'}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.Debit > 0 ? statement?.Debit : '0.00'}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.ClosingCredit > 0 ? statement?.ClosingCredit : '0.00'}
                                                    </td>

                                                    <td scope='col' style={statement?.sort_order === 1 ? { color: 'darkmagenta' } : statement?.sort_order === 3 ? { color: 'red' } : {}}>
                                                        {statement?.ClosingDebit > 0 ? statement?.ClosingDebit : '0.00'}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='vertical-space-20'></div>
                        </div >
                    </div >
                </div >
            </div >
        </div >
    )
}

export default TrailBalance;