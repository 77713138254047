import { observable } from "mobx";
export interface IMemberLogin {
    memberId?: number;
    memberName?: string;
    gender?: string;
    customerType?: string;
    customerCategory?: string;
    registrationType?: string;
    doorNo?: string;
    area?: string;
    city?: string;
    state?: string;
    country?: string;
    pinCode?: string;
    mobileNo?: string;
    panNo?: string;
    aadharNo?: string;
    dob?: string;
    age?: number;
    anniverseryDate?: string;
    email?: string;
    otp?: string;
}
export interface IVerifyOTP {
    memberId?: number;
    emailId?: string;
    otp?: number;
    email?: string;
    confirmEmail?: string;
}

export interface ILogin {
    userName?: string;
    password?: string;
}

const MemberLoginDetails = observable<IMemberLogin>({
    memberId: 0,
    memberName: '',
    gender: '',
    customerType: '',
    customerCategory: '',
    registrationType: '',
    doorNo: '',
    area: '',
    city: '',
    state: '',
    country: '',
    pinCode: '',
    mobileNo: '',
    panNo: '',
    aadharNo: '',
    dob: '',
    age: 0,
    anniverseryDate: '',
    email: '',
    otp: ''
});

export default MemberLoginDetails;