import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IDespatchEntrySearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

function DespatchEntryDetail() {
  const { despatchStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { getDespatchEntryDetails, despatchEntryDetail } = despatchStore;
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isDespatchShow, setDespatchModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const getDespatchEntryDetail = async (orderdespatchid) => {

    const data = await getDespatchEntryDetails(orderdespatchid);

    if (data !== undefined || data !== null) {
      setDespatchModal(true);
    }
  }
  const handleClose = () => {
    setDespatchModal(false);
  }

  const deleteDespatchEntryMaster = async (orderdespatchid) => {
    setLoading(true);
    const status = await despatchStore.deleteDespatchEntryMaster(orderdespatchid);

    if (status === 'Success') {
      fetchAssignOrder();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteDespatchEntryDetail = async (orderdespatchdid) => {

    setDespatchModal(false);
    setLoading(true);
    const status = await despatchStore.deleteDespatchEntryDetail(orderdespatchdid);

    if (status === 'Success') {
      fetchAssignOrder();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchAssignOrder();
  }

  const handleCreate = () => {
    navigate('/Admin/DespatchEntry');
  }

  async function fetchAssignOrder() {
    const userRights = await userCreationStore?.getUserRight('Despatch_entry')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await despatchStore.getDespatchEntry();
    setLoading(false);
  }

  const handleChangeclear = () => {
    setLoading(true);
    fetchAssignOrder();
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchAssignOrder();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);


  const sortedData = despatchStore?.despatchEntryList?.length && despatchStore?.despatchEntryList?.slice().sort((a, b) => {
    const dateA = new Date(a.orderdespatchdate);
    const dateB = new Date(b.orderdespatchdate);

    if (dateA > dateB) return -1;
    if (dateB < dateA) return 1;
    return 0;
  })


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData && sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={sortedData}
                searchTitles={[{ key: "issuedByName", value: "Issued By" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>

                      <th scope='col'>Despatch Date </th>
                      <th scope='col'>Order No</th>
                      <th scope='col'>Order Date</th>
                      <th scope='col'>Despatch By </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{moment(val.orderdespatchdate).format('DD-MMM-YYYY')}</td>
                            <td>{val.orderno}</td>
                            <td>{moment(val.orderdate).format('DD-MMM-YYYY')}</td>
                            <td>{val.warename}</td>
                            <td><button disabled={!userRights?.view} onClick={() => getDespatchEntryDetail(val.orderdespatchid)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDespatchEntryMaster(val.orderdespatchid)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
              {
                isDespatchShow ?
                  <Modal show={isDespatchShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Item Id</th>
                              <th scope="col">Batch No</th>
                              <th scope="col">Despatch Qty</th>
                              <th scope="col">MRP</th>
                              <th scope="col">B2B price</th>
                              <th scope="col">B2C price</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              despatchEntryDetail?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.despatchEntryItemId}</td>
                                    <td>{val.orderdespatchbatchno}</td>
                                    <td>{val.despatchEntrydesqty}</td>
                                    <td>{val.despatchEntrymrp}</td>
                                    <td>{val.despatchEntryb2Bprice}</td>
                                    <td>{val.despatchEntryb2Cprice}</td>
                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteDespatchEntryDetail(val.orderdespatchdid)} /></button></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

};

export default DespatchEntryDetail;
