
import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../APIAgent';

class IPayrollStore {
    constructor() {
        makeObservable(this);
    }

    saveCategory = async (category) => {
        try {
            const status = await apiAgent.payroll.saveCatogery(category);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadCatergoryMaster = async () => {
        try {
            const loadCategoryMasters = await apiAgent.payroll.loadCatergoryMaster();
            return JSON.parse(loadCategoryMasters);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveIncentiveRecoverySave = async (incentive) => {
        try {
            const status = await apiAgent.payroll.saveIncentiveRecoverySave(incentive);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadIncentiveRecoverMaster = async () => {
        try {
            const loadIncentiveRecoverMasters = await apiAgent.payroll.loadIncentiveRecoverMaster();
            return JSON.parse(loadIncentiveRecoverMasters);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveDeductionMaster = async (deduction) => {
        try {
            const status = await apiAgent.payroll.saveDeductionMaster(deduction);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadDeductionMaster = async () => {
        try {
            const loadDeductionMasters = await apiAgent.payroll.loadDeductionMaster();
            return JSON.parse(loadDeductionMasters);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveLeaveMaster = async (leaveSetting) => {
        try {
            const status = await apiAgent.payroll.saveLeaveMaster(leaveSetting);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getLeaveSettingJSON = async () => {
        try {
            const loadLeaveSettingJSON = await apiAgent.payroll.getLeaveSettingJSON();
            return JSON.parse(loadLeaveSettingJSON);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveManualAttendance = async (leaveSetting) => {
        try {
            const status = await apiAgent.payroll.saveManualAttendance(leaveSetting);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getManualAttendance = async () => {
        try {
            const loadManualAttendanceJSON = await apiAgent.payroll.getManualAttendance();
            return JSON.parse(loadManualAttendanceJSON);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadManualAttendanceJSON = async () => {
        try {
            const loadManualAttendanceJSONs = await apiAgent.payroll.loadManualAttendanceJSON();
            return JSON.parse(loadManualAttendanceJSONs);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveMDApprovalEntrySave = async (mdLeaveApproval) => {
        try {
            const status = await apiAgent.payroll.saveMDApprovalEntrySave(mdLeaveApproval);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveLoanAndAdvance = async (loanAndAdvance) => {
        try {
            const status = await apiAgent.payroll.saveLoanAndAdvance(loanAndAdvance);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadLoanAndAdvance = async () => {
        try {
            const loadLoanAndAdvanceJSON = await apiAgent.payroll.loadLoanAndAdvance();
            return JSON.parse(loadLoanAndAdvanceJSON);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveLoanAdvanceApproval = async (loanAndAdvanceApproval) => {
        try {
            const status = await apiAgent.payroll.saveLoanAdvanceApproval(loanAndAdvanceApproval);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadLoanandadvanceApproval = async () => {
        try {
            const loadLoanAndAdvanceJSON = await apiAgent.payroll.loadLoanandadvanceApproval();
            return JSON.parse(loadLoanAndAdvanceJSON);
        }
        catch (error) {
            console.log(error);
        }
    }

    getAttendanceForSalary = async (body) => {
        try {
            const attendanceForSalaryGener = await apiAgent.payroll.getAttendanceForSalary(body);
            return JSON.parse(attendanceForSalaryGener);
        }
        catch (error) {
            console.log(error);
        }
    }

    getLoadBalance = async () => {
        try {
            const loadBalanceList = await apiAgent.payroll.getLoadBalance();
            return JSON.parse(loadBalanceList)
        }
        catch (error) {
            console.log(error)
        }
    }

    getElClBalance = async (body) => {
        try {
            const loadElClBalance = await apiAgent.payroll.getElClBalance(body);
            return JSON.parse(loadElClBalance)
        }
        catch (error) {
            console.log(error)
        }
    }

    saveAllowanceDeduction = async (body, type) => {
        try {
            const status = await apiAgent.payroll.saveAllowanceDeduction(body, type);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveSalaryGeneration = async (body) => {
        try {
            const status = await apiAgent.payroll.saveSalaryGeneration(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getSalaryGeneration = async (body) => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.getSalaryGeneration(body)
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }

    getAllowanceDetails = async (body) => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.getAllowanceDetails(body)
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }

    getDeductionDetails = async (body) => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.getDeductionDetails(body)
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }

    saveLeaveRequest = async (body) => {
        try {
            const status = await apiAgent.payroll.saveLeaveRequest(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveLeaveRequestApproval = async (body) => {
        try {
            const status = await apiAgent.payroll.saveLeaveRequestApproval(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadLeaveRecordsDetail = async () => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.loadLeaveRecordsDetail()
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }

    saveEmployeeBreakInOutMaster = async (body) => {
        try {
            const status = await apiAgent.payroll.saveEmployeeBreakInOutMaster(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadBreakInOutMaster = async () => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.loadBreakInOutMaster()
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }

    loadBreakInOutDetail = async () => {
        try {
            const loadSalaryGeneration = await apiAgent.payroll.loadBreakInOutDetail()
            return JSON.parse(loadSalaryGeneration)
        }
        catch (error) {
            console.log(error)
        }
    }
}

export default IPayrollStore;