import { observable } from "mobx";
export interface IUnitOfMeasurement{
    unitId?:number;
    unitName?:string;
    unitType?:string;
    unitdescription?:string;
   
}

export interface IUnitSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const UnitOfMeasurementDetails=observable<IUnitOfMeasurement>({
    unitId:0,
    unitName:'',
    unitType:'',
    unitdescription:'',
    
});

export default UnitOfMeasurementDetails;