import { observable } from "mobx";
export interface ILedgerGroup{
    groupId?:number;
    ledgerGroupName?:string;
    under?:string;
    undergroup?:string;
    subGroup?:string;   
   
}
export interface ILedgerGroupSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const LedgerGroupDetails=observable<ILedgerGroup>({
    groupId:0,
    ledgerGroupName:'',
    under:'',
    undergroup:'',
    subGroup:''
});

export default LedgerGroupDetails;