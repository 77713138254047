export class IIAppoinmentProcess {
    appoinRecordId?: number = 0
    appoinDate?: string = new Date()?.toISOString()?.substr(0, 10)
    appoinEmpId?: number = 0
    appoinOfferId?: number = 0
    appoinJobCode?: string = ""
    appoinCandidate?: string = ""
    appoinDOJ?: string = new Date()?.toISOString()?.substr(0, 10)
    appoinBioID?: number = 0
    appoinJobLoc?: string = ""
    appoinCompName?: string = ""
    appoinPhotoDoc?: string = ""
    appoinTime?: string = ""
    appoinTicketNo?: string = ""
    appoinTestDoc?: string = ""
    appoinmentsalarydet?: IIAppoinmentSalaryDet[] = []
    appoinmentotherdoc?: IIAppoinmentOtherDoc[] = []
    appoinmentjobdet?: IIAppoinmentJobDet[] = []
    appoinmentempmst?: IIAppoinmentEmpSts[] = []
}

export class IIAppoinmentSalaryDet {
    asD_AppoinRecordId?: number = 0
    asD_AppoinSalType?: string = ""
    asD_AppoinAmt?: number = 0
    asD_AppoinDescId?: number = 0
}

export class IIAppoinmentOtherDoc {
    aoD_AppoinRecordId?: number = 0
    aoD_AppoinDocType?: string = ""
    aoD_AppoinDocName?: string = ""
    aoD_AppoinOtherDoc?: string = ""
}

export class IIAppoinmentJobDet {
    ajD_AppoinRecordId?: number = 0
    ajD_AppoinDesign?: string = ""
    ajD_AppoinJobDuty?: string = ""
}

export class IIAppoinmentEmpSts {
    aE_AppoinCandidate?: string = ""
    aE_AppoinDOJ?: string = ""
    aE_AppoinBioID?: number = 0
    aE_AppoinCompName?: string = ""
    aE_AppoinPhotoDoc?: string = ""
    aE_AppoinSalType?: string = ""
    aE_AppoinAmt?: number = 0
}