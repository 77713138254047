import React, { useEffect, useMemo, useRef, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { IAssignOfferAndDiscountSearchoptions } from '../../CRM/AssignOfferDiscount/model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';


const ReceiptEntryDetail = observer((): JSX.Element => {
  const [receiptSearch, setReceiptSearch] = useState<IAssignOfferAndDiscountSearchoptions>({})
  const [isCredit, setCredit] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { receiptStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate("/Admin/ReceiptEntry")
  }

  const handleModalPopup = async (receiptId) => {
    setLoading(true);
    await receiptStore.getReceiptDetails(receiptId);
    setLoading(false);

    setCredit(true);
  }

  const handleModalClose = () => {
    setCredit(false)
  }

  async function fetchPreLoadingData() {
    const userRights = await userCreationStore?.getUserRight('Receipt_entry')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await receiptStore.getReceipts();
    setLoading(false);
  }

  const isCurrentRef = useRef(true)

  useEffect(() => {
    if (isCurrentRef.current) {
      fetchPreLoadingData();
      isCurrentRef.current = false
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (receiptStore?.receipts && receiptStore?.receipts?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(receiptStore?.receipts)
    }
  }, [receiptStore?.receipts])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(receiptStore?.receipts?.slice()?.sort((a, b) => b.receiptId - a.receiptId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={receiptStore?.receipts}
        searchTitles={[{ key: "receiptId", value: "Receipt Id" }, { key: "referenceNo", value: "Ref No" }]}
        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
      <div className='vertical-space-20'></div>
      <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='btnBox'>
          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{receiptStore?.receipts?.length} Records</button>
        </div>
        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
        </Stack>
      </div>
      <div className='vertical-space-20'></div>
      <div className='tableListDetails'>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> : null
        }
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Rec. No    </th>
              <th scope='col'>Date  </th>
              <th scope='col'>Ref No </th>
              <th scope='col'></th>
              <th scope='col'>Create </th>
              <th scope='col'>Update </th>
              <th scope='col'>Delete </th>
            </tr>
          </thead>
          <tbody>
            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
              (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.receiptId}</td>
                    <td>{val.receiptDate}</td>
                    <td>{val.referenceNo}</td>
                    <td><button disabled={!userRights?.view} onClick={(e) => handleModalPopup(val.receiptId)}>Detail</button></td>
                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      {
        receiptStore.receiptsDetils.receiptCredits ?
          <Modal show={isCredit} onHide={handleModalClose} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Credit & Debit Detail </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Cr Account</th>
                      <th scope="col">Cr Amount</th>
                      <th scope="col">Cheque No </th>
                      <th scope="col">Date</th>
                      <th scope="col">Bank Name</th>
                      <th scope="col">Narration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      receiptStore.receiptsDetils.receiptCredits.length > 0 &&
                      receiptStore.receiptsDetils.receiptCredits.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.creditAccountName}</td>
                            <td>{val.creditAmount}</td>
                            <td>{val.chequeNo}</td>
                            <td>{val.creditDate}</td>
                            <td>{val.creditBankName}</td>
                            <td>{val.creditNarration}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
              <div className='vertical-space-10'></div>
              <div className='tableBox'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">By Account</th>
                      <th scope="col">Dr Amount </th>
                      <th scope="col">Narration</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      receiptStore.receiptsDetils.receiptDebits.length > 0 &&
                      receiptStore.receiptsDetils.receiptDebits.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.debitAccountName}</td>
                            <td>{val.debitAmount}</td>
                            <td>{val.debitNarration}</td>
                          </tr>
                        )
                      })
                    }
                    {/* <tr>
                  <td>Account</td>
                  <td>1800</td>
                  <td>Narration</td>
                </tr> */}
                  </tbody>
                </table>

              </div>
            </Modal.Body>
          </Modal> : null
      }

    </div>
  );

});

export default ReceiptEntryDetail;
