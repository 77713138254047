import { makeObservable } from "mobx";
import apiAgent from "../../../APIAgent";
import { IIEmployeePromotion } from "../model";
import { IIPromotionApproval } from "../../PromotionApprovalMaster/model";

class IEmployeePromotionStore {
    constructor() {
        makeObservable(this);
    }

    saveEmployeePromotion = async (employee: IIEmployeePromotion) => {
        try {
            const status = await apiAgent.employeepromotion.saveEmployeePromotion(employee);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmpPromotion = async () => {
        try {
            const loadEmpPromotions = await apiAgent.employeepromotion.loadEmpPromotion();
            return JSON.parse(loadEmpPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployeePromotionDet = async () => {
        try {
            const loadEmployeePromotionDets = await apiAgent.employeepromotion.loadEmployeePromotionDet();
            return JSON.parse(loadEmployeePromotionDets);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployeePromoSalDet = async () => {
        try {
            const loadEmployeePromoSalDets = await apiAgent.employeepromotion.loadEmployeePromoSalDet();
            return JSON.parse(loadEmployeePromoSalDets);
        }
        catch (error) {
            console.log(error);
        }
    }
    savePromotionApproval = async (promotionApproval: IIPromotionApproval) => {
        try {
            const status = await apiAgent.employeepromotion.savePromotionApproval(promotionApproval);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadPromotionApprovalList = async () => {
        try {
            const loadPromotions = await apiAgent.employeepromotion.loadPromotionApprovalList();
            return JSON.parse(loadPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveEmployeeIncrement = async (employee: IIEmployeePromotion) => {
        try {
            const status = await apiAgent.employeepromotion.saveEmployeeIncrement(employee);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployeeIncrement = async () => {
        try {
            const loadEmpPromotions = await apiAgent.employeepromotion.loadEmployeeIncrement();
            return JSON.parse(loadEmpPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployeeIncrementdet = async () => {
        try {
            const loadEmployeePromotionDets = await apiAgent.employeepromotion.loadEmployeeIncrementdet();
            return JSON.parse(loadEmployeePromotionDets);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadEmployeeIncrementSalDet = async () => {
        try {
            const loadEmployeePromoSalDets = await apiAgent.employeepromotion.loadEmployeeIncrementSalDet();
            return JSON.parse(loadEmployeePromoSalDets);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveIncrementApproval = async (promotionApproval: IIPromotionApproval) => {
        try {
            const status = await apiAgent.employeepromotion.saveIncrementApproval(promotionApproval);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadIncrementApproval = async () => {
        try {
            const loadPromotions = await apiAgent.employeepromotion.loadIncrementApproval();
            return JSON.parse(loadPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveResignationMaster = async (employee: IIEmployeePromotion) => {
        try {
            const status = await apiAgent.employeepromotion.saveResignationMaster(employee);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadResignationMaster = async () => {
        try {
            const loadResignationList = await apiAgent.employeepromotion.loadResignationMaster();
            return JSON.parse(loadResignationList);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveResignationApproval = async (resignationApproval) => {
        try {
            const status = await apiAgent.employeepromotion.saveResignationApproval(resignationApproval);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadResignationApproval = async () => {
        try {
            const loadPromotions = await apiAgent.employeepromotion.loadResignationApproval();
            return JSON.parse(loadPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadResignationApprovalApproved = async () => {
        try {
            const loadPromotions = await apiAgent.employeepromotion.loadResignationApprovalApproved();
            return JSON.parse(loadPromotions);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveRejoinMaster = async (resignationApproval) => {
        try {
            const status = await apiAgent.employeepromotion.saveRejoinMaster(resignationApproval);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
}

export default IEmployeePromotionStore