import React,{useEffect,useState} from 'react';
import {useNavigate} from "react-router-dom";
import Header from '../Header/Header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import Footer from '../Footer/Footer';

const TrackingOrder=observer(():JSX.Element=> {
  const navigate = useNavigate();
  const [isLoading,setLoading]=useState(true);
  
  const handleTrackingPackage=()=>{
    navigate('/trackingpackage')
  }

  return (
    <>
    {
      
      <>
    <div className="App">
      <Header />      
     <div className='orderpage'>        
             <div className="topnavbar">
                <a >Your Order</a>               
                <div className="search-container ">
                    <form action="/action_page.php">
                    <input  type="text" placeholder="Search All Orders.." name="search" /> &nbsp;
                    <span className='btnBox'>                    
                    <button className='secondaryBtn'>Search Order</button></span>
                    </form>                   
                </div>
            </div>
                    
            <div className="topnav">
                <a className="active" href="#home">Orders</a>
                <a href="#news">Buy Again</a>
                <a href="#contact">Not yet Shipped</a>
                <a href="#about">Cancel Order</a>
           </div>
           <hr></hr>
           <div className='orderselect'>
                <label >2 Orders Placed in</label>
                <select name="" id="">
                   <option value="">--select--</option>
                    <option value="">Last Purchase</option>
                    <option value="">Last Week</option>
                    <option value="">Last Month</option>
                    <option value="">Last 3 Month</option>
                    <option value="">Last 6 Month</option>
                    <option value="">Last One Year</option>
                </select>
            </div>
           
            <div className="grid-container1">
              <div className="grid-item1" >                                 
                <div ><span id="name">Order Placed</span> </div>
                <div ><span id="price">03-Nov-2023</span></div>                    
             </div>
             <div className="grid-item1 " >                                 
               <div > <span id="name">Total</span></div>
               <div><span id="price"> &#x20b9;3300.00</span></div>                   
             </div>
             <div className="grid-item1 " >                                 
               <div ><span id="name">Ship To</span></div>
               <div><span id="price"><a href=''>GSM</a></span></div>                   
             </div>           
             <div className="grid-item1" >                                 
                <div ><span id="name">Order Id</span>&nbsp;&nbsp;&nbsp;
                   <span id="price">764385445634-8978-88697867</span></div>
                 <div ><span id="price"><a href=''>View Order Details </a></span>&nbsp;<span id="price">|</span>&nbsp;&nbsp;
                   <span id="price"><a href=''>Invoice </a></span>
                </div>
             </div>
             <div className="grid-item1" > 
             </div>
            
          </div>
          <div className='vertical-space-10'></div>
             
        <div className='row ' style={{width:'99%'}}>
        <hr className='trackinghr'></hr>
            <div className='col-sm-9 orderitemimg'>                
                <h6>Arriving Wednesday Between 9AM - 8PM</h6>
                <Image  className='accountimg' src={require("../../gsmecom/images/product.jpg")} />&nbsp;
               <span> Lava A1 Josh with BOL Keypad Mobile, Bolne wala Phone, Caller Speak </span>&nbsp;              
                <br></br>               
                <div className='vertical-space-10'></div> 
               <Image  className='accountimg' src={require("../../gsmecom/images/product.jpg")} />&nbsp;
               <span> Lava A1 Josh with BOL Keypad Mobile, Bolne wala Phone, Caller Speak </span>                               
            </div>
            <div className='col-sm-3 orderitemimg2'>            
             <button className='trackthisbtn  full-width' onClick={handleTrackingPackage}> Track Package </button>
             <button className='trackthisbtn2  full-width'>View Or Edit Order </button>
             <button className='trackthisbtn3  full-width'>Write a Product Review</button>
            </div>
                       
            <div className='vertical-space-10'></div> 
             </div> 

             <hr></hr>
             <div className="grid-container1">
              <div className="grid-item1" >                                 
                <div ><span id="name">Order Placed</span></div>
                <div ><span id="price">03-Nov-2023</span></div>                    
             </div>
             <div className="grid-item1 " >                                 
               <div > <span id="name">Total</span></div>
               <div><span id="price"> &#x20b9;3300.00</span></div>                   
             </div>
             <div className="grid-item1 " >                                 
               <div ><span id="name">Ship To</span></div>
               <div><span id="price"><a href=''>GSM</a></span></div>                   
             </div>           
             <div className="grid-item1" >                                 
                <div ><span id="name">Order Id</span>&nbsp;&nbsp;&nbsp;
                   <span id="price">764385445634-8978-88697867</span></div>
                 <div ><span id="price"><a href=''>View Order Details </a></span>&nbsp;<span id="price">|</span>&nbsp;&nbsp;
                   <span id="price"><a href=''>Invoice </a></span>
                </div>
             </div>            
          </div>
          <div className='vertical-space-10'></div>

             <div className='row ' style={{width:'99%'}}>
        <hr className='trackinghr'></hr>
            <div className='col-sm-9 orderitemimg'>                
                <h6>Arriving Wednesday Between 9AM - 8PM</h6>
                <Image  className='accountimg' src={require("../../gsmecom/images/product.jpg")} />
                Lava A1 Josh with BOL Keypad Mobile, Bolne wala Phone, Caller Speak                                
            </div>
            <div className='col-sm-3 orderitemimg2'>            
             <button className='trackthisbtn  full-width' onClick={handleTrackingPackage}> Track Package </button>
             <button className='trackthisbtn2  full-width'>View Or Edit Order </button>
             <button className='trackthisbtn3  full-width'>Write a Product Review</button>
            </div>            
            <div className='vertical-space-60'></div> 
             </div>            
           </div>
            
        <Footer/>
        </div>
       
    
      </>
    }
    </>
  
  );
})

export default TrackingOrder;
