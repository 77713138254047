import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { ILogin, IMemberLogin } from './model';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { encryptData } from '../Helper';
import Cookies from 'js-cookie';
import useEcomStores from '../hooks/index';
import { ICartProduct } from '../Products/model';

const Login = observer((): JSX.Element => {

  const { productStore, ecomMemberStore, ecomHomeStore } = useEcomStores();


  const { saveProductCart, getCartDetails } = ecomHomeStore;

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [loginDetails, setLoginDetails] = useState<ILogin>({})

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    loginDetails.password = encryptData(loginDetails.password);

    const status = await ecomMemberStore.getLogin(loginDetails);

    if (status !== undefined) {
      let productCart: ICartProduct = {};
      Cookies.set('memberId', status?.memberId);
      Cookies.set('memberName', status?.memberName);
      Cookies.set('customerType', status?.customerType)
      Cookies.set('cartCount', status?.cartProductsCount);

      if (Cookies.get('cartLogin') === 'cartafterLogin') {
        productCart.memberId = Number(Cookies.get('memberId'));
        if (Cookies.get('Products') !== undefined) {
          let cartList = JSON.parse(Cookies.get('Products'));
          cartList.forEach(element => {
            element.memberId = Number(Cookies.get('memberId'));
          });
          const cartResponse = await productStore.saveProductCart(cartList);
          if (cartResponse?.message === 'Success') {
            Cookies.set('cartCount', cartResponse.cartCount);
            Cookies.remove('ProductCarts');
            Cookies.remove('cartCount');
            Cookies.set('cartCount', status.cartProductsCount);
            navigate('/checkoutorder');
            return;
          }
        }
      }

      addCookieProdToCart(status)

      Cookies.set('memberId', status.memberId);
      Cookies.set('memberName', status.memberName);
      Cookies.set('cartCount', status.cartProductsCount);
      Cookies.set('customerType', status?.customerType)
      navigate('/')
    }
  }

  async function addCookieProdToCart(status) {
    debugger
    let productCarts: ICartProduct[] = [];
    let ecomProdList: any[] = [];
    if (Cookies.get('ProductCarts') !== undefined) {
      ecomProdList = JSON.parse(Cookies.get('ProductCarts'))
      productCarts = ecomProdList?.map((items) => {
        let productCart: ICartProduct = {
          cartId: 0,
          productId: items.productId,
          productQty: items.productQty ?? 1,
          webName: items?.webName,
          barcode: items.barcode,
          batchNo: items.batchNo,
          memberId: Number(status?.memberId ?? 0),
        }
        return productCart;
      })
      if (status?.memberId !== undefined) {
        const cartResponse = await saveProductCart(productCarts);
        if (cartResponse !== undefined && cartResponse.message === 'Success') {
          const cartItems = await getCartDetails(status?.memberId)
          if (cartItems?.length) {
            Cookies.set('cartCount', cartItems?.length);
          } else {
            Cookies.set('cartCount', cartResponse?.cartCount);
          }
        }
      }
    } else if (status?.memberId !== undefined) {
      const cartItems = await getCartDetails(status?.memberId)
      if (cartItems?.length) {
        Cookies.set('cartCount', cartItems?.length);
      }
    }
  }

  const handleRegister = () => {
    navigate('/Registration');
  }

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className="Login">
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Login</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='inputBox'>
                        <label>User Name <span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }}
                          placeholder='Enter Email..'
                          value={loginDetails.userName} name='userName'
                          onChange={handleInputChange} required></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='inputBox'>
                        <label>Password <span>*</span></label>
                        <input type="password" className='' style={{ width: "100%" }} placeholder='Password..'
                          value={loginDetails.password} name='password'
                          onChange={handleInputChange} required></input>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleRegister}>Sign Up</button>
                  <button className='dfBtn' type='submit' onClick={handleSubmit}>Login</button>
                </div>
                <div>
                  <a href='/forgot' >Forgot Password</a>
                </div>
              </div>
              <div className='vertical-space-20'></div>
            </div>
          </>
      }

    </>

  );
})

export default Login;
