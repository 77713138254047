import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import useStores from "../../hooks";
import { IIVacancyApproval } from "../JobMaster/model";
import moment from "moment";
const VacancyApproval = () => {

    const { jobMasterStore, commonStore } = useStores();

    const { loadEmployee, loadEmployees } = commonStore;

    const navigate = useNavigate();

    const [vacancyApprovalList, setVacancyApprovalList] = useState<IIVacancyApproval[]>(new Array<IIVacancyApproval>())
    const [jobVacancyList, setJobVacancyList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/VacancyApproval/list');
    }

    function handleCheckBox(event, value) {
        if (!vacancyApprovalList?.some((each) => each?.jvid === value?.Jvid) && event.target.checked) {
            let obj: IIVacancyApproval = {
                ...value,
                workmanagerid: value?.Workmanager,
                status: 'Yes',
            }
            setVacancyApprovalList([...vacancyApprovalList, obj])
        } else {
            let findIndex = vacancyApprovalList?.findIndex((each) => each?.jvid === value?.Jvid)
            if (findIndex) {
                vacancyApprovalList?.splice(findIndex, 1)
                setVacancyApprovalList([...vacancyApprovalList])
            }
        }
    }

    async function handleSaveCategory() {
        if (vacancyApprovalList?.length) {
            setLoading(true)
            // const status = 'Success';
            const status = await jobMasterStore?.saveVacancyApproval(vacancyApprovalList)
            if (status === 'Success') {
                setLoading(false)
                setSuccessModal(true)
            } else {
                setLoading(false)
                setFailureModal(true)
            }
            setErrors({})
        } else {
            let error: any = {
                select: 'Check Jobs Vacancy Before Submit'
            }
            setErrors({ ...error })
        }
    }

    function handleToCategoryList() {
        // navigate('/Admin/JobMaster/JobMasterList')
    }

    async function initialApiCall() {
        await loadEmployee()
        let jobVacancyList: any[] = [];
        jobVacancyList = await jobMasterStore.loadPendingVacanceyApp()
        setJobVacancyList([...jobVacancyList])
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox' style={{ width: "780px" }}>
                            <div className='hrBox'>
                                <h3>Vacancy Approval - Waiting for Approval</h3>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Hiring Manager</th>
                                            <th scope="col">No of Position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobVacancyList?.map((each, index) => {
                                            return each?.Approvalstatus == 'No' && (
                                                <tr key={index}>
                                                    <td><input type="checkbox" onChange={(e) => handleCheckBox(e, each)} /></td>
                                                    <td>{moment(each?.Jvdate)?.format('DD-MMM-YYYY')}</td>
                                                    <td>{each?.Jvcode}</td>
                                                    <td>{each?.Jtitle}</td>
                                                    <td>{each?.Worklocation}</td>
                                                    <td>{`${loadEmployees?.find(obj => obj?.employeeId == each?.Workmanager)?.employeeName}`}</td>
                                                    <td>{each?.NoofPosision}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors?.select && <p style={{ color: 'red' }}>{errors.select}</p>}
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default VacancyApproval;