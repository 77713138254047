import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { IISelectionProcess } from "./model";
import useStores from "../../hooks";
import { Autocomplete, Pagination, Stack, TextField } from "@mui/material";
import { currentPageDatas, removeDuplicates } from "../../common/shared/utils";

const SelectionProcess = () => {

    const { jobMasterStore, interviewStore } = useStores();

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isModal, setModal] = useState({ bool: false, index: 0 })

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [selectionProcess, setSelectionProcess] = useState<IISelectionProcess[]>(new Array<IISelectionProcess>())

    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])
    const [interviewProcessList, setInterviewProcessList] = useState<any[]>([])

    // console.log(interviewProcessList, 'interviewProcessList>>')
    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/SelectionProcess/List')
    }

    function handleSelectionApproval() {
        navigate('/Admin/SelectionProcess/List')
    }

    async function handleSaveCategory() {
        let error: any = {};
        debugger
        if (selectionProcess?.length) {
            if (selectionProcess?.some((obj) => obj?.selectionType && (obj?.selectionType != ""))) {
                setLoading(true)
                const entryNo = await jobMasterStore.getHREntryNo('Selectionprocess');
                selectionProcess?.forEach((each) => {
                    if (each?.intprocessid) {
                        each.intselectionid = Number(entryNo?.entrynumber)
                    }
                })
                const status = await interviewStore.saveInterviewSelectionMaster(selectionProcess);
                if (status === 'Sucess') {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            } else {
                debugger
                let find = selectionProcess?.find((obj) => obj?.selectionType === "")
                error = {
                    Intprocessid: find?.intprocessid,
                    selectionType: 'Select Selection Type',
                }
            }
        } else {
            error = {
                selectProcess: 'Check Selection Process to Submit',
            }
        }
        setErrors({ ...error })
    }

    function handleUpdateStatus(val, each) {
        let error: any = {};
        if (!selectionProcess?.some((obj) => obj?.intprocessid === each?.Intprocessid)) {
            error.Intprocessid = each?.Intprocessid;
            error.checkfirst = 'Check Approval status';
        } else {
            selectionProcess?.forEach((obj) => {
                if (obj?.intprocessid === each?.Intprocessid) {
                    obj['selectionType'] = val?.value;
                }
                return obj;
            })
            currentPageData?.forEach((obj) => {
                if (obj?.Intprocessid === each?.Intprocessid) {
                    obj['selection'] = val;
                }
                return obj;
            })
            setCurrentPageData([...currentPageData])
            setSelectionProcess([...selectionProcess])
        }
        setErrors({ ...error })
    }

    function handleCheckBox(event, value) {
        if (!selectionProcess?.some((each) => each?.intprocessid === value?.intprocessid) && event.target.checked) {
            let obj: IISelectionProcess = {
                intselectionid: 0,
                intprocessid: value?.Intprocessid,
                jobcode: Number(value?.Jobcode),
                interviewdate: value.Interviewdate,
                callletterno: Number(value?.Callletterno),
                empid: Number(value?.Empid),
                feedback: value?.Feedback,
                selectionType: ""
            }
            setSelectionProcess([...selectionProcess, obj])
        } else {
            let findIndex = selectionProcess?.findIndex((each) => each?.intprocessid === value?.intprocessid)
            if (findIndex >= 0) {
                selectionProcess?.splice(findIndex, 1)
                setSelectionProcess([...selectionProcess])
            }
        }
    }

    async function initialApiCall() {
        const processList = await interviewStore?.loadInterviewProcessMaster();
        if (processList?.length) {
            setInterviewProcessList([...processList]);
        }
        const interviewletter = await interviewStore.loadInterviewLetter()
        setInterviewLetterList([...interviewletter])
        setLoading(false)
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    // console.log(interviewProcessList, 'interviewProcessList>>', interviewLetterList)
    useEffect(() => {
        if (interviewProcessList && interviewProcessList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(interviewProcessList)
        }
    }, [interviewProcessList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(removeDuplicates(interviewProcessList, 'Intprocessid'), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>
                            <div className='hrBox'>
                                <h3>Selection Process</h3>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{interviewProcessList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Select</th>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Letter No</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Qualification</th>
                                            <th scope="col">Conduct by</th>
                                            <th scope="col">Selection Type</th>
                                            <th scope="col">Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <input type="checkbox" onChange={(event) => handleCheckBox(event, each)} />
                                                        {((errors?.Intprocessid == each?.Intprocessid) && errors?.checkfirst) && <p style={{ color: 'red' }}>{errors?.checkfirst}</p>}
                                                    </td>
                                                    <td>{each?.Jobcode}</td>
                                                    <td>{each?.Callletterno}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)?.Candidatename}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)?.EducationQualification}</td>
                                                    <td>{each?.Conductby}</td>
                                                    <td>
                                                        <Autocomplete size="small" disablePortal id="combo-box-demo"
                                                            options={[{ type: 'Selected', value: 'Selected' }, { type: 'Rejected', value: 'Rejected' }, { type: 'Hold', value: 'Hold' }]}
                                                            onChange={(event, val) => handleUpdateStatus(val, each)}
                                                            getOptionLabel={(option: any) => option.value}
                                                            value={each?.selection}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params} style={{ width: '36ch' }}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="Selection Type..."
                                                                    name='selectionType'
                                                                />}
                                                        />
                                                        {((errors?.Intprocessid == each?.Intprocessid) && errors?.selectionType) && <p style={{ color: 'red' }}>{errors?.selectionType}</p>}
                                                    </td>
                                                    <td><button className='' onClick={() => setModal({ bool: true, index: each?.Intprocessid })}>Details</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors?.selectProcess && <p style={{ color: 'red' }}>{errors?.selectProcess}</p>}
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSelectionApproval}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isModal?.bool} onHide={() => setModal({ bool: false, index: 0 })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Detail</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Round</th>
                                                    <th scope="col">Process</th>
                                                    <th scope="col">Next Round</th>
                                                    <th scope="col">FeedBack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {interviewProcessList?.map((each, index) => {
                                                    return isModal?.index === each?.Intprocessid && (
                                                        <tr key={index}>
                                                            <td className='capitalize'>{each?.Phaseno}</td>
                                                            <td>{each?.Process}</td>
                                                            <td>{each?.NextPhases}</td>
                                                            <td>{each?.Feedback1}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </div>
    )
}

export default SelectionProcess;