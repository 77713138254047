import { faBell } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useMemo, useRef, useState } from "react"
import useStores from "../../hooks"
import Badge from '@mui/material/Badge';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import moment from "moment";

const audioFile = require("../../../../audio/notification.mp3")

const NotificationBar = observer((): JSX.Element => {
    const { commonStore, minimumStockStore } = useStores()
    const { loadNotificationMasters, loadNotification, closeNotification } = commonStore
    const userid = Number(Cookies.get('employeeId'));
    const [showDrawer, setShowDrawer] = useState(false)

    const navigate = useNavigate();

    const locationTrue = window?.location?.pathname?.includes('Admin');

    const [notifications, setNotifications] = useState<any[]>(loadNotification)

    function handleVisible() {
        fetchData()
        if (notifications?.length) {
            if (locationTrue) {
                setShowDrawer(true);
            }
        } else {
            if (locationTrue) {
                setShowDrawer(true);
            }
            setTimeout(() => {
                setShowDrawer(false)
            }, 2000)
        }
        prevArrayLengthRef.current = loadNotification?.length;
    }

    async function handleAccept(nid: any) {
        if (nid) {
            let filter = notifications?.filter(notify => notify?.nid != nid)
            if (filter?.length === 0 && locationTrue) {
                setShowDrawer(false)
            }
            setNotifications(filter)
        }
        await closeNotification(nid)
    }

    const prevArrayLengthRef = useRef<number>(loadNotification?.length);
    const prevLength = prevArrayLengthRef.current ?? 0;
    const currentLength = loadNotification?.length ?? 0;

    const isActive = useMemo(() => {
        if (currentLength > prevLength && locationTrue) {
            setShowDrawer(true);
            const audio = new Audio(audioFile);
            audio.play();
        } else if (prevLength === 0) {
            setShowDrawer(false)
        }
    }, [prevLength, currentLength])

    const fetchData = async () => {
        await loadNotificationMasters(userid)
        if (locationTrue) {
            setShowDrawer(true)
        }
    };

    useEffect(() => {

        prevArrayLengthRef.current = loadNotification?.length;

        const fetchInterval = setInterval(() => {
            const lastFetchTime = localStorage.getItem('lastFetchTime');
            const currentTime = new Date().getTime();
            const fiveMinutes = 1 * 60 * 1000; // 1 minutes in milliseconds

            if (!lastFetchTime || currentTime - parseInt(lastFetchTime) >= fiveMinutes) {
                fetchData();
                localStorage.setItem('lastFetchTime', currentTime.toString());
            }
        }, 1 * 60 * 1000);

        return () => clearInterval(fetchInterval);
    }, [])

    useEffect(() => {
        setNotifications(loadNotification)
    }, [loadNotification])

    return (
        <div className="" style={{ position: 'relative' }}>
            <Badge badgeContent={currentLength > prevLength && loadNotification?.length} max={9}>
                <FontAwesomeIcon icon={faBell} onClick={handleVisible} />
            </Badge>
            {showDrawer && <div style={{
                border: "1px solid lightgray", color: 'black', backgroundColor: 'white', zIndex: 10, position: "absolute", right: '5px', marginTop: "6px", borderRadius: '12px', minWidth: '500px', maxHeight: '350px', overflow: 'scroll',
            }}>
                <div className="py-3 px-4 full-width" style={{}}>
                    <div className="" style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px', fontWeight: '900' }}>
                        Notifications
                        <CloseRoundedIcon onClick={() => setShowDrawer(false)} sx={{ border: 'none', background: 'lightgray', borderRadius: '50%', fontSize: '20px', padding: '4px' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', fontSize: '16px', fontWeight: '700', paddingTop: '10px', paddingBottom: '10px', borderBottom: '1px solid lightgray' }}>
                        {notifications?.length ? notifications?.map((notification, index) => {
                            return (
                                <div key={index} className="flex gap-4 alignItemCenter py-2">
                                    <span>
                                        {notification?.aleartMessage}
                                    </span>
                                    <button className='dfBtn' type='submit' onClick={() => notification?.nid ? handleAccept(notification?.nid) : navigate('/Admin/MinimumStockReport')}>{notification?.nid ? 'Accept' : 'Visit'}</button>
                                </div>
                            )
                        }) : <>No Notification found</>}
                    </div>
                </div>
            </div>}
        </div>
    )
})

export default NotificationBar