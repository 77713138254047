import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { IIPromotionApproval } from "./model";
import useStores from "../../hooks";

const PromotionApprovalMaster = () => {

    const { employeeStore, payrollStore, departmentStore, designationStore, employeePromotionStore, jobMasterStore } = useStores();

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [employeePromotionList, setEmployeePromotionList] = useState<any>()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [categoryList, setCategoryList] = useState<any[]>([])
    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])


    const [promotionApprovalList, setPromotionApprovalList] = useState<IIPromotionApproval[]>(new Array<IIPromotionApproval>())
    const [approvalDate, setApprovalDate] = useState(new Date()?.toISOString()?.substr(0, 10))

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
        setSuccessModal(false)
    }

    function handleSelectManager(event, each) {
        let error: any = {}
        if (each?.checked) {
            promotionApprovalList?.forEach((obj) => {
                if (obj?.epM_ID === each?.EPM_ID) {
                    obj['epM_ApprovedBy'] = event?.target?.value;
                }
                return obj
            });
            employeePromotionList?.forEach((obj) => {
                if (obj?.EPM_ID === each?.EPM_ID) {
                    obj['epM_ApprovedBy'] = event?.target?.value;
                }
                return obj;
            })
        } else {
            error.epM_ID = each?.EPM_ID;
            error.epM_ApprovedStatus = 'Select Approved Status';
        }
        setEmployeePromotionList([...employeePromotionList])
        setPromotionApprovalList([...promotionApprovalList])
        setErrors({ ...error })
    }

    async function handleCheckBox(event, each) {
        let error: any = {}
        const { checked } = event?.target;
        if (checked) {
            let findObj: IIPromotionApproval = new IIPromotionApproval();
            employeePromotionList?.forEach((obj) => {
                if (obj?.EPM_ID === each?.EPM_ID) {
                    obj['checked'] = true;
                }
                return obj;
            })
            findObj['epM_ApprovedStatus'] = 'Yes';
            findObj['epM_ID'] = each?.EPM_ID;
            promotionApprovalList?.push(findObj);
        } else {
            let findIndex = promotionApprovalList?.findIndex((obj) => obj?.epM_ID === each?.EPM_ID)
            if (findIndex !== -1) {
                promotionApprovalList?.splice(findIndex, 1)
            }
            employeePromotionList?.forEach((obj) => {
                if (obj?.EPM_ID === each?.EPM_ID) {
                    obj['checked'] = false;
                }
                return obj;
            })
        }
        setErrors({ ...error })
        setEmployeePromotionList([...employeePromotionList])
        setPromotionApprovalList([...promotionApprovalList])
    }

    async function handleSaveCategory() {
        let error: any = {}
        if (promotionApprovalList?.length) {
            if (promotionApprovalList?.some((each) => each?.epM_ApprovedBy != "")) {
                promotionApprovalList?.forEach((each) => {
                    each['epM_ApprovedDate'] = approvalDate;
                    return each;
                })
                console.log(promotionApprovalList, 'promotionApprovalList>>')
                const status = await employeePromotionStore?.savePromotionApproval(promotionApprovalList)
                if (status === 'Success') {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            } else {
                error.epM_ID = promotionApprovalList?.find((each) => each?.epM_ApprovedBy != "")?.epM_ID;
                error.epM_ApprovedBy = 'Select Approved By'
            }
        } else {
            if (!promotionApprovalList?.length) {
                error.promotionApproval = 'Check Status from Grid'
            }
        }
        setErrors({ ...error })
    }

    function handleToPromotionApprovalMasterListList() {
        navigate('/Admin/PromotionApproval/List')
    }



    function updatePromoList(master, promoDetails) {
        console.log(master, promoDetails,'Detailss')
        let employeeMasterDetail: any[] = Object.assign([], master);
        promoDetails?.forEach((promoDet) => {
            const { EPD_EPMID, ...familyInfo } = promoDet;
            employeeMasterDetail?.forEach((employee) => {
                const { EPM_ID, ...employeeInfo } = employee;
                if (EPM_ID === EPD_EPMID) {
                    employee['EPM_CatID'] = promoDet?.EPD_CatID
                    employee['EPM_DegnID'] = promoDet?.EPD_DegnID
                    employee['EPM_DeptID'] = promoDet?.EPD_DeptID
                }
            })
        })
        return employeeMasterDetail;
    }


    async function additionalApis() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        setEmployeeMaster([...employeeMaster])
        setCategoryList([...categoryMasterDetails])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
    }

    async function initialApiCall() {
        const employeePromotion = await employeePromotionStore?.loadEmpPromotion()
        const employeePromotionDet = await employeePromotionStore?.loadEmployeePromotionDet()

        updatePromoList(employeePromotion, employeePromotionDet)
        if (employeePromotion?.length) {
            setEmployeePromotionList([...employeePromotion])
        }
        setLoading(false)
        additionalApis()
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Promotion Approval Master</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Approval Date <span>*</span></label>
                                            <input type="date" className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY" data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="epM_ApprovedDate"
                                                onChange={(event) => setApprovalDate(event?.target?.value)}
                                                value={approvalDate}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Employee ID</th>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Is Approved</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {employeePromotionList?.map((each, index) => {
                                            console.log(employeePromotionList, 'employeePromotionList>>')
                                            return (
                                                <tr key={index}>
                                                    <td>{each?.EPM_ID}</td>
                                                    <td>{each?.EPM_TicketNo}</td>
                                                    <td>{each?.Employee_Name}</td>
                                                    <td>{categoryList?.find((obj) => obj?.Categoryid === each?.EPM_CatID)?.Emp_Category_Name}</td>
                                                    <td>{designationList?.find((cate) => cate?.designationId === each?.EPM_DegnID)?.designationName}</td>
                                                    <td>{departmentList?.find((cate) => cate?.departmentId === each?.EPM_DeptID)?.department}</td>
                                                    <td className='row'>
                                                        <div className="col-md-12">
                                                            <div className='inputBox'>
                                                                <select className="full-width" name="type"
                                                                    value={each?.epM_ApprovedBy}
                                                                    onChange={(event) => handleSelectManager(event, each)} id="Openingtype" >
                                                                    <option value="" selected>--Select--</option>
                                                                    {employeeMaster?.map((each) => {
                                                                        return (
                                                                            <option value={each?.Employee_Name}>{each?.Employee_Name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            {((errors?.epM_ID === each?.EPM_ID) && errors?.epM_ApprovedBy) ?
                                                                <p style={{ color: 'red' }}>{errors?.epM_ApprovedBy}</p> : null}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input checked={each?.checked ? true : false} type="checkbox" onChange={(event) => handleCheckBox(event, each)}></input>
                                                        {((errors?.epM_ID === each?.EPM_ID) && errors?.epM_ApprovedStatus) && <p style={{ color: 'red' }}>{errors?.epM_ApprovedStatus}</p>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors?.promotionApproval && <p style={{ color: 'red' }}>{errors?.promotionApproval}</p>}
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleToPromotionApprovalMasterListList}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </div>
    )
}

export default PromotionApprovalMaster;