import { IPurchaseOrderSaveModal } from "../modal";

export function ScheduleValidation(purchaseApproval){
    let errors :any={};

    // if(!purchaseApproval.schedule_no){
    //     errors.schedule_no = "Enter Schedule No";
    // }
    if(!purchaseApproval.delivery_date){
        errors.delivery_date = "Select Delivery Date";
    } 
    if(!purchaseApproval.schedule_qty){
        errors.schedule_qty = "Enter Schedule Qty";
    }   
    return errors;
};
export function PurchaseSaveValidation (purchaseSave: IPurchaseOrderSaveModal) {
    let errors :any={};

     
    if(!purchaseSave.purchase_Order_Date){
        errors.purchase_Order_Date = "Enter Date";
    }
    if(!purchaseSave.supplier_id){
        errors.supplier_id = "Select Supplier";
    } 
    // if(!purchaseSave.remarks){
    //     errors.remarks = "Enter Remarks";
    // }
    if(!purchaseSave.despatch_to || !purchaseSave.contactperson || !purchaseSave.contactno){
        errors.despatch_to = "Enter Dispatch Details";
    }
    if(!purchaseSave.purchaseOrderDetails?.length){
        errors.select_item = "Select Items";
    } 
    // if(!purchaseSave.delivery_date){
    //     errors.delivery_date = "Enter Delivery Date";
    // }
    // if(!purchaseSave.payment_mode){
    //     errors.payment_mode = "Enter Mode of Payment";
    // }
    // if(!purchaseSave.povalidity_date){
    //     errors.povalidity_date = "Enter PO Validity Date";
    // }
    // if(!purchaseSave.packing_forward){
    //     errors.packing_forward = "Enter Mode of Transport";
    // }
    // if(!purchaseSave.quotation_refno){
    //     errors.quotation_refno = "Enter Quoutations Ref.No";
    // } 
    // if(!purchaseSave.transport){
    //     errors.transport = "Enter Transport Terms";
    // }
    // if(!purchaseSave.payment_terms){
    //     errors.payment_terms = "Enter Payment Terms";
    // } 
    if(!purchaseSave.purchaseOrderTeamsandcondition?.length){
        errors.terms_conditions = "Add Terms and Conditions";
    }  
    
    return errors;
}