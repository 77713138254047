export class ExcelSheetData {
    Date?: string = ""
    Item_Name?: string = ""
    UOM?: string = ""
    Barcode?: string = ""
    Batchno?: string = ""
    Opening_Stk?: string = ""
    In_qty?: number = 0
    Out_qty?: number = 0
    Closing?: number = 0
}

export class IExcelSheetData {
    Warehouse_Name?: string = ""
    Outlet_Name?: string = ""
    Item_Name?: string = ""
    Unit_Of_Measure?: string = ""
    Barcode?: string = ""
    Batchno?: string = ""
    Stock?: number = 0
    MrpRate?: number = 0
    B2C_SellingRate?: number = 0
    B2B_SellingRate?: number = 0
}

export class IIExceluploadModal {
    impsno?: 1
    outletstock_import?: IIExcelUpload[] = []
}

export class IIExcelUpload {
    warehouseName?: string = ""
    outletName?: string = ""
    itemname?: string = ""
    unitOfMeasure?: string = ""
    barcode?: string = ""
    batchno?: string = ""
    stock?: number = 0
    mrprate?: number = 0
    btcSellingRate?: number = 0
    btbSellingRate?: number = 0
}

export function excelKeyupdate(data: IExcelSheetData[]) {
    let excelArr: IIExcelUpload[] = []
    excelArr = data?.map((each: IExcelSheetData) => {
        let excleObj: IIExcelUpload = {
            warehouseName: each?.Warehouse_Name,
            outletName: each?.Outlet_Name,
            itemname: each?.Item_Name,
            unitOfMeasure: each?.Unit_Of_Measure,
            barcode: each?.Barcode?.toString(),
            batchno: each?.Batchno?.toString(),
            stock: !isNaN(Number(each.Stock)) ? Number(each.Stock) : 0,
            mrprate: !isNaN(Number(each.MrpRate)) ? Number(each.MrpRate) : 0,
            btcSellingRate: !isNaN(Number(each.B2C_SellingRate)) ? Number(each.B2C_SellingRate) : 0,
            btbSellingRate: !isNaN(Number(each.B2B_SellingRate)) ? Number(each.B2B_SellingRate) : 0,
        }
        return excleObj
    })
    return excelArr;
}