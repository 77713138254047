import { styled, alpha } from '@mui/material/styles';
import React, { useEffect, useState } from "react"
import { Button, Container, Image, Nav, Navbar } from "react-bootstrap"
import Cookies from 'js-cookie';
import InputBase from '@mui/material/InputBase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    // alpha(theme.palette.common.white, 0.15)
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.95),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        fontSize: '16px',
        color: 'black',
        paddingLeft: `calc(1em + ${theme.spacing(6)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '46ch',
            height: '6ch',
        },
    },
}));

interface HeaderCompProps {
    moveToCart: () => void;
}

const HeaderComp: React.FC<HeaderCompProps> = ({ moveToCart }) => {

    const [isLoggetIn, setLoggedIn] = useState(false);
    const [isAdminLoggetIn, setAdminLoggedIn] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (Cookies.get('ProductCarts') !== undefined) {
            setLoggedIn(false);
            setAdminLoggedIn(false);
        }
        if (Cookies.get('memberId') !== undefined) {
            setLoggedIn(true);
            setAdminLoggedIn(false);
        } else {
            setLoggedIn(false);
            setAdminLoggedIn(false);
        }
    }, [])

    const mapObject: { [key: string]: string[] } = {
        "Home": ["Specials", "Account", "Card", "Checkout"],
        "Cooking Materials": ["Grains & Rice ", "Pulses", "Spices & Nuts", "Flous & Masalas", "Oil & Condaiments", "Ready For Cook", "Dairy Products", "Other ingredients"],
        "Beverages Mix & Snacks": ["Tea & Coffee", "Health Drinks", "Snacks", "Biscuits"],
        "Personal Care": ["Oral Care", "Body Care", "Hair Care", "Face Care", "Baby Care", "Shaving & Hair Removal", "Feminine Hygiene"],
        "House Hold Care & Cleaning": ["Laundry Detergents", "Utensil Cleaning / Dish Wash", "Household Cleaners & Freshener", "Cleaning Tools", "Insect Repellents", "Pooja / Devotional Products", "Miscellaneous", "Stationary"],
    };
    return (
        <div className="header">
            <Navbar expand="lg" className="">
                <Container fluid style={{ justifyContent: 'flex-start' }}>
                    <Navbar.Brand href="http://localhost:3000"><Image src={require("../../gsmecom/images/GangaSupermarket.png")} /></Navbar.Brand>
                    <Search>
                        <SearchIconWrapper>
                            <FontAwesomeIcon icon={faSearch} style={{ height: '24px', paddingLeft: '4px' }} />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Try Masala or Search Product Code…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Navbar.Collapse id="navbarScroll" className='navBar'>
                        {!isLoggetIn && <Nav.Link href="" className='LoginBtn' onClick={() => navigate("/Login")}>Login</Nav.Link>}
                        {!isAdminLoggetIn && <Nav.Link href="" className='LoginBtn' onClick={() => navigate("/Admin")}>Admin</Nav.Link>}
                    </Navbar.Collapse>
                    <Button className='cartBtn' onClick={moveToCart}>
                        <Image src={require("../../gsmecom/images/cart.png")} />
                        <span className='badge badge-warning' id='lblCartCount' > {Cookies.get('cartCount') ? Cookies.get('cartCount') : null} </span>
                    </Button>
                </Container>
            </Navbar>
            <div className='menuHeader'>
                <div className='maindropdown'>
                    {Object?.entries(mapObject)?.map(([key, values], index) => {
                        return (
                            <div className="dropdown" key={index}>
                                <button className="dropbtn">{key}</button>
                                <div className="dropdown-content">
                                    {values?.map((obj, ind) => {
                                        return (
                                            <a key={ind} href="#">{obj}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default HeaderComp