import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IMemberPointsStatementRequest {
    memberId?: number = 0;
    startDate?: string = getCurrentMonthFirstDate()?.firstDate;
    endDate?: string = getCurrentMonthFirstDate()?.currentDate;
    mobileNo?: string = "";
    memberName?: string = "";
}

export class IExcelSheetData {
    Member_Name?: string = ""
    Bill_Type?: string = ""
    Earned_Points?: number = 0
    Redeem_Points?: number = 0
    Balance_Point?: number = 0
}

export class IIExceluploadModal {
    impsno?: 1
    earnedPoints_import?: IIExcelUpload[] = []
}

export class IIExcelUpload {
    memberName?: string = ""
    billType?: string = ""
    earnedPoints?: number = 0
    randampoints?: number = 0
    balancepoint?: number = 0
}

export function excelKeyupdate(data: IExcelSheetData[]) {
    let excelArr: IIExcelUpload[] = []
    excelArr = data?.map((each: IExcelSheetData) => {
        let excleObj: IIExcelUpload = {
            balancepoint: !isNaN(Number(each.Balance_Point)) ? Number(each.Balance_Point) : 0,
            billType: each?.Bill_Type?.toString(),
            earnedPoints: !isNaN(Number(each.Earned_Points)) ? Number(each.Earned_Points) : 0,
            memberName: each?.Member_Name?.toString(),
            randampoints: !isNaN(Number(each.Redeem_Points)) ? Number(each.Redeem_Points) : 0,
        }
        return excleObj
    })
    return excelArr;
}