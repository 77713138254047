import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { Autocomplete, TextField } from "@mui/material";
import useStores from "../../hooks";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import moment from "moment";

class FieldsProps {
    sdate?: string = getCurrentMonthFirstDate()?.firstDate;
    edate?: string = getCurrentMonthFirstDate()?.currentDate;
    empid?: number = 0;
}

interface ExcelSheetData {
    S_No?: string,
    Employee_Code?: string,
    Employee_Name?: string,
    Attend_Date?: string,
    In_Time?: string,
    Break_Out_Time?: string,
    Break_In_Time?: string,
    Out_Time?: string,
    Total_Hours?: string,
    Present?: string,
    OT_hours?: string
}

const TimeSheetReport = () => {

    const { hrReporsStore, employeeStore } = useStores()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSpinner, setSpinner] = useState(false);

    const [localFields, setLocalFields] = useState<FieldsProps>(new FieldsProps())
    const [timeSheetReports, setAttendanceReport] = useState<any[]>([])

    console.log(timeSheetReports, 'timeSheetReports>>')

    function createExportExcelObj(timeSheetReports: any[]) {
        if (timeSheetReports?.length >= 1) {
            let excelArr: ExcelSheetData[] = timeSheetReports?.map((employee, index) => {
                const { Empid, Employee_Name, AttnDate, InTime, BreakOutTime, BreakInTime, OutTime, TotalHours, Present, OThours } = employee;
                const excelObj: ExcelSheetData = {
                    S_No: (index + 1)?.toString(),
                    Employee_Code: Empid,
                    Employee_Name: Employee_Name,
                    Attend_Date: moment(AttnDate)?.format('DD-MMM-YYYY'),
                    In_Time: InTime ? moment(InTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_Out_Time: BreakOutTime ? moment(BreakOutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_In_Time: BreakInTime ? moment(BreakInTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Out_Time: OutTime ? moment(OutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Total_Hours: TotalHours ? moment(TotalHours, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Present: Present ? moment(Present, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    OT_hours: OThours ? moment(OThours, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                };
                return excelObj;
            })
            exportToExcel(excelArr)
        } else {
            alert(` No Data found `);
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Time Sheet Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Time Sheet Report ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function handleGenerate(event) {
        let error: any = {}
        if (localFields?.sdate && localFields?.edate && localFields?.empid) {
            setSpinner(true)
            const timeSheetReport = await hrReporsStore?.getReportTimeSheetRegister(localFields?.sdate, localFields?.edate, localFields?.empid)
            if (timeSheetReport?.length) {
                setAttendanceReport([...timeSheetReport])
            }
            setSpinner(false)
        } else if (event) {
            if (!localFields?.sdate) {
                error.sdate = 'Select From Date'
            }
            if (!localFields?.edate) {
                error.edate = 'Select year'
            }
            if (!localFields?.empid) {
                error.empid = 'Select Employee'
            }
        }
        setErrors({ ...error })
    }

    async function initialApiCalls() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false
        }
    }, [])

    console.log(timeSheetReports, 'timeSheetReports>>')

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }} >
                            <div className='hrBox'>
                                <h3>Time Sheet Report</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="sdate"
                                                value={localFields?.sdate}
                                                onChange={(event) => setLocalFields({ ...localFields, sdate: event.target.value })}
                                            ></input>
                                            {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>To Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="edate"
                                                disabled
                                                value={localFields?.edate}
                                                onChange={(event) => setLocalFields({ ...localFields, edate: event.target.value })}
                                            ></input>
                                            {errors.edate && <p style={{ color: "red" }}>{errors.edate}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>
                                                Employee<span>*</span>
                                            </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employeeMaster}
                                                getOptionLabel={(option: any) => option.Employee_Name}
                                                onChange={(event, val) => setLocalFields({ ...localFields, empid: val?.EmployeeId })}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Name..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        {errors.empid && <p style={{ color: "red" }}>{errors.empid}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => createExportExcelObj(timeSheetReports)}>
                                                Excel
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Need only for Print */}
                            {/* <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>Ganga Super Market Address</th>
                                            <th>
                                                <div>Time Card</div>
                                                <div>Monthly Consolidated Report</div>
                                                <div>Form 25, see rule 100</div>
                                            </th>
                                            <th>
                                                <div>DOJ : 12-Apr-2024</div>
                                                <div>Normal Wages : Shift</div>
                                                <div>OT : </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>From 12-Apr-2024 To 12-Apr-2025</td>
                                            <td></td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div className="row" style={{ margin: '20px' }}>
                                <div className="col-sm-4">
                                    <div>Employee Name:</div>
                                    <div>Father Name:</div>
                                </div>
                                <div className="col-sm-4">
                                    <div>Employee Code:</div>
                                    <div></div>
                                </div>
                                <div className="col-sm-4">
                                    <div>Department:</div>
                                    <div>Designation:</div>
                                </div>
                            </div> */}
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>S.No</th>
                                            {/* <th rowSpan={2}>Employee Name</th> */}
                                            <th rowSpan={2}>AttDate</th>
                                            <th className="text-center" colSpan={4}>Shift Time</th>
                                            <th rowSpan={2}>Total Hrs</th>
                                            <th rowSpan={2}>Present</th>
                                            <th rowSpan={2}>OT</th>
                                        </tr>
                                        <tr>
                                            <th>In Time</th>
                                            <th>Break Out</th>
                                            <th>Break In</th>
                                            <th>Out Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {timeSheetReports?.map((each, index) => {
                                            return (!localFields?.empid || each?.Empid === localFields?.empid) && (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    {/* <td>{each?.Employee_Name}</td> */}
                                                    <td>{moment(each?.AttnDate)?.format('DD-MMM-YYYY')}</td>
                                                    <td>{each?.InTime ? moment(each?.InTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.BreakOutTime ? moment(each?.BreakOutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.BreakInTime ? moment(each?.BreakInTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.OutTime ? moment(each?.OutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.TotalHours ? moment(each?.TotalHours, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.Present ? moment(each?.Present, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                    <td>{each?.OThours ? moment(each?.OThours, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}

export default TimeSheetReport;