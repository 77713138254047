import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useStores from "../../hooks";
import { ILoanAndAdvance } from "./model";
import { validate } from "./validation";
import { Autocomplete, TextField } from "@mui/material";

const LoanAndAdvanceRequest = () => {

    const navigate = useNavigate();

    const { employeeStore, memberStore, payrollStore } = useStores();

    const [employeeList, setEmployeeList] = useState<any>([])

    const [loanAndAdvance, setLoadAndAdvance] = useState<ILoanAndAdvance>(new ILoanAndAdvance());

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);


    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate("/Admin/LoanAdvanceRequest/LoanAdvanceRequestList");
    };

    async function handleSaveCategory() {
        let error: any = {}
        error = validate(loanAndAdvance)
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const status = await payrollStore?.saveLoanAndAdvance(loanAndAdvance)
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToLoanAndAdvanceRequestList() {
        navigate("/Admin/LoanAdvanceRequest/LoanAdvanceRequestList");
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        if (name === 'amountDeductmode' && value === "total") {
            setLoadAndAdvance({ ...loanAndAdvance, [name]: value, noofdue: 0 });
        } else {
            setLoadAndAdvance({ ...loanAndAdvance, [name]: (name == 'empid' || name == 'noofdue') ? Number(value) : value });
        }
    }

    const amountDeductionMode = [{ key: 'total', value: "Total" }, { key: 'monthly', value: "Monthly" },]

    async function initialApiCall() {
        const entryNo = await memberStore.getEntryNo('Loansandadvance');
        setLoadAndAdvance({ ...loanAndAdvance, loanid: (entryNo?.entryNo ?? 0) + 1 })
        const employeeList = await employeeStore.loadEmployeeMaster();
        setEmployeeList([...employeeList])
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        <div className="hrBox">
                            <h3>Loan & Advanced Request</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>ID</label>
                                        <input type="text" style={{ width: "100%" }}
                                            name='brandId' disabled
                                            value={loanAndAdvance?.loanid}
                                            className='brandinput' placeholder='Code..'></input>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Date <span>*</span></label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="advdate"
                                            value={loanAndAdvance.advdate = loanAndAdvance?.advdate! ?? new Date().toISOString().substr(0, 10)}
                                            onChange={handleInputChange}
                                        ></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.advdate && (<p style={{ color: "red" }}>{errors.advdate}</p>)}
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Loan Type <span>*</span></label>
                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                            name="loanType" value={loanAndAdvance?.loanType} onChange={handleInputChange}
                                        >
                                            <option value="" selected>
                                                - - Select - -
                                            </option>
                                            <option value="Loan">Loan</option>
                                            <option value="Advance">Advance</option>
                                        </select>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.loanType && (<p style={{ color: "red" }}>{errors.loanType}</p>)}
                                </div>
                                <div className="col-sm-12">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Employee Name<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={employeeList}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            value={employeeList?.find((obj) => obj?.EmployeeId == loanAndAdvance?.empid)}
                                            onChange={(evt, val) => setLoadAndAdvance({ ...loanAndAdvance, empid: val?.EmployeeId })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Employee..'
                                                    name='empid'
                                                />}
                                        />
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.empid && (<p style={{ color: "red" }}>{errors.empid}</p>)}
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Department</label>
                                        <input type="text" style={{ width: "100%" }}
                                            name='brandId' disabled
                                            value={employeeList?.find((obj) => obj?.EmployeeId == loanAndAdvance?.empid)?.department1 ?? ''}
                                            className='brandinput' placeholder='department..'></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Designation</label>
                                        <input type="text" style={{ width: "100%" }}
                                            name='brandId' disabled
                                            value={employeeList?.find((obj) => obj?.EmployeeId == loanAndAdvance?.empid)?.designation1 ?? ''}
                                            className='brandinput' placeholder='designation..'></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Amount Request<span>*</span></label>
                                        <input type="text" style={{ width: "100%" }}
                                            name='loanAmount'
                                            value={loanAndAdvance.loanAmount}
                                            onChange={handleInputChange}
                                            className='brandinput' placeholder='Enter Amount Request'></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.loanAmount && (<p style={{ color: "red" }}>{errors.loanAmount}</p>)}
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Reason <span>*</span> </label>
                                        <textarea
                                            className=""
                                            name='reason'
                                            value={loanAndAdvance.reason}
                                            onChange={handleInputChange}
                                            style={{ width: "100%" }}
                                            placeholder="Enter Reason.."
                                        ></textarea>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.reason && (<p style={{ color: "red" }}>{errors.reason}</p>)}
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Deduction Mode <span>*</span>  </label>
                                        <select
                                            className="form-selected full-width"
                                            name="amountDeductmode"
                                            value={loanAndAdvance?.amountDeductmode}
                                            onChange={handleInputChange}
                                            style={{ fontSize: "16px" }}
                                        >
                                            <option value="" selected>
                                                - - Select - -
                                            </option>
                                            {amountDeductionMode?.map((obj) => {
                                                return (
                                                    <option value={`${obj?.key}`}>{obj?.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                    {errors.amountDeductmode && (<p style={{ color: "red" }}>{errors.amountDeductmode}</p>)}
                                </div>
                                <div className="col-sm-4">
                                    {loanAndAdvance?.amountDeductmode === 'monthly' && <div className="inputBox">
                                        <label style={{ color: 'white', fontSize: '16px' }}>
                                            <span style={{ color: 'white' }}>*</span>
                                        </label>
                                        <select
                                            className="form-selected full-width"
                                            name="noofdue"
                                            value={loanAndAdvance?.noofdue}
                                            onChange={handleInputChange}
                                            style={{ fontSize: "16px" }}
                                        >
                                            <option value="" selected> - -Select- -</option>
                                            {Array.from([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12])?.map((each, index) => {
                                                return (
                                                    <option value={`${each}`}>{each}</option>
                                                )
                                            })}
                                        </select>
                                        <div className="vertical-space-10"></div>
                                        {errors.noofdue && (<p style={{ color: "red" }}>{errors.noofdue}</p>)}
                                    </div>}
                                </div>
                                <div className="col-sm-4">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Due Per Month</label>
                                        <input type="number" style={{ width: "100%" }}
                                            value={loanAndAdvance?.amountDeductmode == 'total' ? Number(loanAndAdvance?.loanAmount) : loanAndAdvance?.noofdue ? (Number(loanAndAdvance?.loanAmount) / Number(loanAndAdvance?.noofdue)) : 0}
                                            disabled
                                            className='brandinput' placeholder='Enter Due Per Month'></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToLoanAndAdvanceRequestList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    );
};

export default LoanAndAdvanceRequest;
