import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Denomination } from '../CounterOpeningEntry/model';
import { ICounterClose } from './model';
import closingCashHandoverValidation from './validation';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import { removeDuplicates } from '../../common/shared/utils';
import Cookies from 'js-cookie';

const denominations: Denomination[] = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
    { value: 2000, label: "2000" }
]

function ClosingCashHandover() {
    const navigate = useNavigate();
    const [model, setModel] = useState(false);
    const [closingCashHandOversub, setClosingCashHandOverSub] = useState<ICounterClose>({})
    const [denominationQtyTotal, setDenominationQtyTotal] = useState<Record<number, number>>(
        Object.fromEntries(denominations.map(denomination => [denomination.value, 0]))
    )
    const { supplierMasterStore, posMasterStore, commonStore } = useStores();
    const [isLoadModal, setLoadModal] = useState(false);
    const { loadEmployees, loadEmployee } = commonStore;
    const [isLoading, setLoading] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 
    const [errors, setErrors] = useState<any>({})

    const modelShow = () => setModel(true);
    const modelClose = () => {
        setClosingCashHandOverSub({
            ...closingCashHandOversub,
            denomationAmount: calculateTotal(),
            counter_opening_details_denomation: Object.entries(denominationQtyTotal).map(
                ([label, value]) => ({
                    denomationno: Number(label),
                    denomationCount: value,
                    denomationAmount: value * Number(label),
                    denomationdid: 0
                })
            ) as any
        })
        setModel(false)
    };

    const { denominationid } = useParams();

    function handleQuantityChange(value: number, quantity: number) {
        setDenominationQtyTotal((prevQuantities) => ({
            ...prevQuantities,
            [value]: quantity
        }))
    }

    function calculateTotal() {
        return Object.entries(denominationQtyTotal).reduce((total, [value, quantity]) => total + Number(value) * quantity, 0)
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    const handleSuccessModalClose = () => {
        handleCancel();
        setSuccessModal(false)
    };

    const handleCancel = () => {
        navigate('/Admin/ClosingCashHandover/ClosingCashHandoverDetail');
    }

    const handleInputchange = (e) => {
        const { name, value } = e.target;
        setClosingCashHandOverSub({ ...closingCashHandOversub, [name]: value });
    }

    const handlePOSCounter = (option, name) => {
        setClosingCashHandOverSub({ ...closingCashHandOversub, [name]: option })
    }

    async function handleSubmit(e) {
        setLoading(true)
        e.preventDefault();
        let error: any = {};
        error = closingCashHandoverValidation(closingCashHandOversub);
        setErrors(error)

        let status: string = '';

        if (Object.keys(error).length === 0) {

            if (denominationid != undefined) {
                closingCashHandOversub.denomationmid = Number(denominationid);
            } else {
                closingCashHandOversub.denomationmid = 0;
            }

            status = await posMasterStore.submitClosingCashHandOver(closingCashHandOversub);

            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }

        }
    }

    async function fetchPreLoadingData() {
        if (denominationid != undefined) {
            let denomination = {
                denominationid: denominationid
            }
            const closingHandOverData = await posMasterStore.getClosingHandOverUpdateDetail(denomination)
        } else {
            await posMasterStore.getPosCounter();
        }
        await loadEmployee();
        setLoading(false);
    }

    // const isCurrenPage = useRef(true)
    // useEffect(() => {
    //     if (isCurrenPage.current) {
    //         fetchPreLoadingData();
    //         isCurrenPage.current = false;
    //     }
    //     return () => { }
    // }, []);
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Closing_cash_handover';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchPreLoadingData();

                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/ClosingCashHandover/ClosingCashHandoverDetail');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {

        return (
            <>
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox '>
                        <div className='hrBox'>
                            <h3>Closing Cash Handover</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className='row'>
                                    {
                                        isLoading ?
                                            <div className='SpinnerBox'>
                                                <Spinner animation="border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>
                                            </div> : null
                                    }
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label> Date <span>*</span></label>
                                            <input type="date" style={{ width: "100%" }}
                                                onChange={handleInputchange}
                                                value={closingCashHandOversub.denomationDate = closingCashHandOversub.denomationDate ?? new Date().toISOString().substr(0, 10)}
                                                name="denomationDate"
                                                placeholder="DD-MMM-YYYY" data-date=""
                                                data-date-format="DD MMMM YYYY"
                                            ></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label> Time <span>*</span></label>
                                            <input type="time" style={{ width: "100%" }}
                                                name="denomationTime"
                                                onChange={handleInputchange}
                                                placeholder="DD-MMM-YYYY" data-date=""
                                                data-date-format="DD MMMM YYYY"></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>

                                    <div className='col-md-10'>
                                        <div className='inputBox'>
                                            <label>Counter No <span>*</span></label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={posMasterStore.posCounter}
                                                getOptionLabel={(option: any) => option.counterNumber}
                                                onChange={(event, option) => handlePOSCounter(option.counterNumber, 'denomationCounterid')}
                                                loading={true}
                                                renderInput={(params: any) =>
                                                    <TextField {...params} style={{ width: '100%' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Counter No..'
                                                        name='counterNo'

                                                    />}
                                            />
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className='inputBox'>
                                            <label>Amount <span>*</span></label>
                                            <input type="text" className='' style={{ width: "100%" }}
                                                value={calculateTotal()} onChange={handleInputchange}
                                                readOnly placeholder='Amount..'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <button onClick={modelShow} className='dfBtn' style={{ width: "100%" }}> Denomination</button>
                                    </div>
                                    <div className='col-md-10'>
                                        <div className='inputBox'>
                                            <label>POS Staff  <span>*</span></label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={removeDuplicates(loadEmployees, 'employeeName')}
                                                getOptionLabel={(option: any) => option.employeeName}
                                                onChange={(event, option) => handlePOSCounter(option.employeeId, 'posempid')}
                                                loading={true}
                                                renderInput={(params: any) =>
                                                    <TextField {...params} style={{ width: '100%' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='POS Staff'
                                                        name='counterNo'

                                                    />}
                                            />
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-10'>
                                        <div className='inputBox'>
                                            <label>Handover Receiver<span>*</span></label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={removeDuplicates(loadEmployees, 'employeeName')}
                                                getOptionLabel={(option: any) => option.employeeName}
                                                onChange={(event, option) => handlePOSCounter(option.employeeId, 'receiveempid')}
                                                loading={true}
                                                renderInput={(params: any) =>
                                                    <TextField {...params} style={{ width: '100%' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Handover Receiver ..'
                                                        name='counterNo'
                                                    />}
                                            />
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                    <Modal show={model} onHide={modelClose} className='PriceHistoryModel'>
                        <Modal.Header closeButton>
                            <Modal.Title>Denomination Window</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='CustomerDetail'>
                                <div className='tableBox'>
                                    <table className="table">
                                        <tbody>
                                            {denominations?.map((denomination) => {
                                                return (
                                                    <tr key={denomination.value}>
                                                        <td>{denomination.label}</td>
                                                        <td>
                                                            <input value={denominationQtyTotal[denomination.value]} style={{ maxWidth: "72px" }} type="text" min="0" onChange={(e) => handleQuantityChange(denomination.value, Number(e.target.value))} />
                                                        </td>
                                                        <td>{denomination.value * denominationQtyTotal[denomination.value]}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    <div className='flex justifyContentEnd pd-12-16'>
                                        {calculateTotal()}
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../../image/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        )
    }
}

export default ClosingCashHandover;