import { observable } from "mobx";
export interface IOutlet{
    outletId?:number;
    outletName?:string;
    emailID?:string;
    mobileNo?:number;
    bankAccount?:string;
    fssainumber?:string;
    dateOfIncrorpeted?:string;
    doorNo?:string;
    area?:string;
    city?:string;
    state?:string;
    pinCode?:number;
    country?:string;
    prefix?:string;
}

export interface IOutletSearchoptions{
    searchOption?:string;
searchValue?:string;
}

const outletDetails=observable<IOutlet>({
    outletId:0,
    outletName:'',
    emailID:'',
    mobileNo:0,
    bankAccount:'',
    fssainumber:'',
dateOfIncrorpeted:'',
    doorNo:'',
    area:'',
    city:'',
    state:'',
    pinCode:0,
    country:'',
    prefix:''
});

export default outletDetails;