import { action } from 'mobx';
function courierEntryValidation(courierEntryDetails){
 
  
  let errors :any={};

  if(!courierEntryDetails.despatchNo){
    errors.despatchNo = "Select Despatch No";
  }
  if(!courierEntryDetails.courierPatnerId){
    errors.courierPatnerId = "Select Courier Name";
  }
  if(!courierEntryDetails.docketNo){
    errors.docketNo = "Enter Docket No";
  }
 

  return errors;
};
  
  export default courierEntryValidation;