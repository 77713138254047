import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { IIJobMaster } from "./model";
import { saveValidation } from "./validation";
import useStores from "../../hooks";

const JobMaster = () => {

    const { jobMasterStore } = useStores()

    const navigate = useNavigate();

    const [jobMaster, setJobMaster] = useState<IIJobMaster>(new IIJobMaster())

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/JobMaster/list');
    }
    async function handleSaveCategory() {
        let error: any = {};
        error = saveValidation(jobMaster)
        setErrors({ ...error })
        jobMaster.jobid = Number(jobMaster?.jobcode);
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const status = await jobMasterStore.saveJobMaster(jobMaster)
            if (status === 'Success') {
                setLoading(false)
                setSuccessModal(true)
            } else {
                setLoading(false)
                setFailureModal(true)
            }
        }
    }

    function handleChangeInput(name, value) {
        setJobMaster({ ...jobMaster, [name]: value })
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('JobMaster');
        setJobMaster({ ...jobMaster, jobcode: entryNo?.entrynumber })
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox'>
                            <div className='hrBox'>
                                <h3>Job Master</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Job Code</label>
                                            <input type="text"
                                                name='jobcode' disabled
                                                style={{ width: '100%' }}
                                                value={jobMaster?.jobcode}
                                                className='brandinput' placeholder='Job Code..'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Job Title <span>*</span></label>
                                            <input type="text"
                                                name='jobtitle'
                                                value={jobMaster?.jobtitle} onChange={(evt) => handleChangeInput('jobtitle', evt.target.value)}
                                                className='brandinput' style={{ width: '100%' }} placeholder='Enter Job Title..'></input>
                                        </div>
                                        {errors.jobtitle && <p style={{ color: 'red' }}>{errors.jobtitle}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-12'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Description <span>*</span></label>
                                            <textarea name='jobDescription' className='brandinput' style={{ width: '100%' }} placeholder='Enter Description..'
                                                value={jobMaster?.jobDescription} onChange={(evt) => handleChangeInput('jobDescription', evt.target.value)}
                                            ></textarea>
                                        </div>
                                        {errors.jobDescription && <p style={{ color: 'red' }}>{errors.jobDescription}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default JobMaster;