import { action, set, makeObservable, observable, runInAction } from 'mobx';
import designationDetails, { IDesignation } from '../model/index';
import apiAgent from '../../../APIAgent';

class designationStore {
    designationDetls = designationDetails;
    @observable designationList = new Map();
    isLoading = true;
    @observable designationLists = new Map<any, any>();
    @observable isEdit = false;
    @observable designationStatus = new Map();
    constructor() {
        makeObservable(this)
    }

    @action saveDesignation = async (designation: IDesignation) => {
        try {

            const status = await apiAgent.designationMasters.saveDesignation(designation);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDesignation = async () => {
        try {
            const designationData = await apiAgent.designationMasters.getDesignation();
            this.designationList = designationData;

            runInAction(() => {
                this.designationList = designationData;
                this.isLoading = false;
            })
            return designationData;
        }
        catch (error) {
            console.log(error);
        }
    }
    updateDesignation = async (designation: IDesignation) => {

        try {
            const status = await apiAgent.designationMasters.updateDesignation(designation);
            runInAction(() => {
                this.designationStatus = status;
            });

            return this.designationStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    DesignationDetails = async (designationId: Number) => {

        try {
            const DesignationData = await apiAgent.designationMasters.DesignationDetails(designationId);

            return DesignationData;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDesignation = async (designationId) => {
        try {
            const status = await apiAgent.designationMasters.deleteDesignation(designationId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDesignationSearch = async (SearchOptions) => {
        try {
            const data = await apiAgent.designationMasters.getDesignationSearch(SearchOptions);
            this.designationList = data;

            runInAction(() => {
                this.designationList = data;

            })
            return this.designationList;

        }
        catch (error) {
            console.log(error);
        }
    }
    @action setDesignation = (state: IDesignation) => {
        this.designationList.set(0, state);
    }
    @action setDesignationList = (state: IDesignation[]) => {
        set(state, this.designationList);
        return this.designationList;
    }

}
export default designationStore;