import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../EcomAPI/index';
import { IProductDeatils } from '../model';


class CheckoutStore {
   
   isLoading= false;
     
   saveProduct=async (products:IProductDeatils)=> {
        
        try {
          const status= await apiAgent.checkout.saveProduct(products);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    
}
export default CheckoutStore;