import { ILoanAndAdvance } from "../model"

export function validate(loanAndAdv: ILoanAndAdvance) {
    let error: any = {}
    if (!loanAndAdv?.advdate) {
        error.advdate = 'Enter date'
    }
    if (!loanAndAdv?.amountDeductmode) {
        error.amountDeductmode = 'Select Deduct Mode'
    }
    if (loanAndAdv?.amountDeductmode === 'monthly' && !loanAndAdv?.noofdue) {
        error.noofdue = 'Select No of Due'
    }
    if (!loanAndAdv?.empid) {
        error.empid = 'Select Employee'
    }
    if (!loanAndAdv?.loanAmount) {
        error.loanAmount = 'Enter Loan Amount'
    }
    if (!loanAndAdv?.loanType) {
        error.loanType = 'Select Loan Type'
    }
    if (loanAndAdv?.loanType == 'monthly' && !loanAndAdv?.noofdue) {
        error.noofdue = 'Select No of Due'
    }
    if (!loanAndAdv?.reason) {
        error.reason = 'Enter Reason'
    }
    return error;
}