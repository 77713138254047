import React,{useEffect,useState} from 'react';
import {useNavigate} from "react-router-dom";
import Header from '../Header/Header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Footer from '../Footer/Footer';

const WishList=observer(():JSX.Element=> {
  const navigate = useNavigate();
 
  return (
    <>
    {
      
      <>
    <div className="App">
      <Header />      
     <div className='orderpage'>
        
             <div className="topnavbar">
                <a >Your Wish List</a>               
                <div className="search-container">
                    <form action="/action_page.php">
                    <input  type="text" placeholder="Search.." name="search"/> &nbsp;
                    <span className='btnBox'>                    
                    <button className='secondaryBtn'>Search Order</button></span>
                    </form>
                </div>
            </div>
                    
            <div className="topnav">
                <a className="active" href="#home">Your List</a>
                <a href="#news">Your Idea Lists</a>
                <a href="#contact">Your Family</a>                
           </div>
           <hr></hr>
                        
       
        <div className='row '>      
            <div className='col-sm-8 orderitemimg'>                   
                <Image  className='accountimg' src={require("../../gsmecom/images/product.jpg")} />
                Lava A1 Josh with BOL Keypad Mobile, Bolne wala Phone, Caller Speak                                
            </div>
            <div className='col-sm-4 orderitemimg2'>            
             <button className='trackthisbtn  full-width'> Add To Cart </button>            
             <button className='trackthisbtn3  full-width'>Delete</button>
             <a href="" className='trackthisbtn4  full-width'>Add comments and priority</a>         
            </div>
           
            </div>       
            <div className='vertical-space-120'></div> 
          </div>  
        <Footer/>
     </div>
    
      </>
    }
    </>
  
  );
})

export default WishList;
