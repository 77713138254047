import { IIPostSalesDetails } from "../../model";

export const addProductToBillingList = (product: IIPostSalesDetails, quantity: number, posFreeItems: any[], billingList: IIPostSalesDetails[]) => {
    console.log(product, 'product>>')
    let freeItems = posFreeItems?.filter((obj) => obj?.batch_no === product?.posbatchNo && obj?.itemid === product?.posItemId);

    const freeItem: any = freeItems?.find(
        item => item?.isbatch === "Yes" && item?.itemid === product?.posItemId && item?.batch_no === product?.posbatchNo
    );

    const addOrUpdatePosItem = (orgItem: IIPostSalesDetails, freeItem: any, quantityToAdd: number, isFreeItem: boolean = false) => {
        const itemIndex = billingList?.findIndex(
            item => item?.posbatchNo === (isFreeItem ? freeItem?.batch_no : orgItem?.posbatchNo) &&
                (isFreeItem ? item?.posFreeItemId : item?.posItemId) === (isFreeItem ? freeItem?.itemid : orgItem?.posItemId)
        );

        if (itemIndex !== -1) {
            billingList[itemIndex].posItemQty! += quantityToAdd;
        } else {
            if (isFreeItem) {
                billingList?.push({
                    posFreeItemId: freeItem?.is_same_item ? freeItem?.itemid : freeItem?.otheritemid,
                    posItemName: freeItem?.is_same_item ? freeItem?.itemname : freeItem?.otheritem,
                    posbatchNo: freeItem?.is_same_item ? freeItem?.batch_no : freeItem?.otheritembatchno,
                    posBarcode: freeItem?.is_same_item ? orgItem?.posBarcode?.toString() : freeItem?.otheritembarcode,
                    posItemQty: quantityToAdd,
                    posTaxValue: 0,
                    posDiscountValue: 0,
                    posTotalPrice: 0,
                    posNetAmount: 0,
                    posTaxPercentage: 0,
                    posDiscountPercentage: 0,
                })
            } else {
                billingList?.push({
                    ...orgItem,
                    posItemQty: quantityToAdd
                })
            }
        }

    }

    let remainingQty = quantity;
    let freeItemCycleCount = freeItem ? freeItem?.freeqty : 0;
    let cycleCount = 0;

    while (remainingQty > 0) {
        // Add the product
        addOrUpdatePosItem(product, undefined, 1)
        remainingQty--;

        // Process free items based on the specified pattern
        if (freeItem && remainingQty > 0) {
            for (let i = 0; i < freeItemCycleCount && remainingQty > 0; i++) {
                let tempObj = new IIPostSalesDetails();
                addOrUpdatePosItem(tempObj, freeItem, 1, true)
                remainingQty--;
            }
            cycleCount++;
        }
    }

    return billingList;
};