import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IIEmployee } from '../model';

class IEmployeeStore {
    @observable employeeDetls = new Map();
    @observable employeeList = new Map();
    @observable entryNo = new Map();
    @observable employeeMaster = new Map();


    @observable employeeMasterDetails = new Array<any>()
    constructor() {
        makeObservable(this);
    }

    saveEmployee = async (employee: IIEmployee) => {
        try {

            const status = await apiAgent.employee.saveEmployee(employee);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }

    updateEmployee = async (employee: IIEmployee) => {
        try {

            const status = await apiAgent.employee.updateEmployee(employee);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }

    saveEmployeeImage = async (employeeImage, empId) => {
        try {

            const formdata = new FormData();
            const extension = employeeImage.name.split('.').pop();

            formdata.append('file', employeeImage, empId + '.' + extension);

            const status = await apiAgent.employee.saveEmployeeImage(formdata, empId);

            if (status !== null) {
                return status;
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    loadEmployeeMaster = async () => {
        try {
            const employeeMaster = await apiAgent.employee.loadEmployeeMaster();
            runInAction(() => {
                this.employeeMaster = JSON.parse(employeeMaster)
            })
            return JSON.parse(employeeMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadEmployeeOfficial = async () => {
        try {
            const employeeOfficial = await apiAgent.employee.loadEmployeeOfficial();
            return JSON.parse(employeeOfficial);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadEmployeeSalaryDetails = async () => {
        try {
            const employeeSalaries = await apiAgent.employee.loadEmployeeSalaryDetails();
            return JSON.parse(employeeSalaries);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadEmployeeMasterDetails = async () => {
        try {
            let employeeMaster: any;
            let employeeFamilies: any;
            let employeeOfficials: any;
            let employeeSalaries: any;
            let employeeEducations: any;
            let employeeExperiences: any;
            let employeeNominees: any;
            let employeeContacts: any

            employeeMaster = await apiAgent.employee.loadEmployeeMaster();
            employeeFamilies = await apiAgent.employee.loadEmployeeFamilyDetails();
            employeeOfficials = await apiAgent.employee.loadEmployeeOfficial();
            employeeSalaries = await apiAgent.employee.loadEmployeeSalaryDetails();
            employeeEducations = await apiAgent.employee.loadEmployeeEducation();
            employeeExperiences = await apiAgent.employee.loadEmployeeExperience();
            employeeNominees = await apiAgent.employee.loadEmployeeNominee();
            employeeContacts = await apiAgent.employee.loadEmployeeContact()

            const mergedData = mergeEmployeeDetails(
                JSON.parse(employeeMaster), JSON.parse(employeeFamilies),
                JSON.parse(employeeOfficials), JSON.parse(employeeSalaries),
                JSON.parse(employeeEducations), JSON.parse(employeeExperiences),
                JSON.parse(employeeNominees), JSON.parse(employeeContacts)
            )

            console.log(mergedData, 'mergedData>>>')

            runInAction(() => {
                this.employeeMasterDetails = mergedData
            })

            return mergedData;
        }
        catch (error) {
            console.log(error);
        }
    }

    getEmployees = async () => {
        try {
            const employeeData = await apiAgent.employee.getEmployees();

            runInAction(() => {
                this.employeeList = employeeData;
            })
            return JSON.parse(employeeData)
        }
        catch (error) {
            console.log(error);
        }
    }

    getEntryNo = async (flag) => {
        try {
            const entryNo = await apiAgent.employee.getEntryNo(flag);

            runInAction(() => {
                this.entryNo = entryNo;
            })
            return entryNo
        }
        catch (error) {
            console.log(error);
        }
    }

    getEmployeeSearch = async (SearchOptions) => {
        try {
            const data = await apiAgent.employee.getEmployeeSearch(SearchOptions);
            this.employeeList = data;

            runInAction(() => {
                this.employeeList = data;

            })
            return this.employeeList;

        }
        catch (error) {
            console.log(error);
        }
    }

    deleteEmployee = async (empId) => {
        try {
            const status = await apiAgent.employee.deleteEmployee(empId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}

const mergeEmployeeDetails = (
    employeeMaster: any[], familyDetails: any[],
    officialDetails: any[], salaryDetails: any[],
    educationDetails: any[], experienceDetails: any[],
    nomineeDetails: any[], contactDetails: any[]
): any[] => {

    console.log(
        employeeMaster, familyDetails,
        officialDetails, salaryDetails,
        educationDetails, experienceDetails,
        nomineeDetails, contactDetails, 'mergeEmployeeDetails>>')

    let employeeMasterDetail: any[] = Object.assign([], employeeMaster);

    familyDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employeeFamily?.length) {
                    employee.employeeFamily = []
                }
                employee.employeeFamily.push(familyInfo)
            }
        })
    })

    officialDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employeOfficial?.length) {
                    employee.employeOfficial = []
                }
                employee.employeOfficial.push(familyInfo)
            }
        })
    })

    salaryDetails?.forEach((family) => {
        const { Employeeid1, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid1) {
                if (!employee?.employeSalary?.length) {
                    employee.employeSalary = []
                }
                employee.employeSalary.push(familyInfo)
            }
        })
    })

    educationDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employEducation?.length) {
                    employee.employEducation = []
                }
                employee.employEducation.push(familyInfo)
            }
        })
    })

    experienceDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employExperience?.length) {
                    employee.employExperience = []
                }
                employee.employExperience.push(familyInfo)
            }
        })
    })

    nomineeDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employeNominee?.length) {
                    employee.employeNominee = []
                }
                employee.employeNominee.push(familyInfo)
            }
        })
    })
    contactDetails?.forEach((family) => {
        const { Employeeid, ...familyInfo } = family;
        employeeMasterDetail?.forEach((employee) => {
            const { EmployeeId, ...employeeInfo } = employee;
            if (EmployeeId === Employeeid) {
                if (!employee?.employeeContact?.length) {
                    employee.employeeContact = []
                }
                employee.employeeContact.push(familyInfo)
            }
        })
    })

    const mergedEmployees: any[] = employeeMasterDetail;
    return mergedEmployees;
};
export default IEmployeeStore;