import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import { IStockAnalysis } from '../model/index';
import apiAgent from '../../../APIAgent';
import { access, accessSync } from 'fs';

class StockAnalysisStore {
    @observable stockReportDetails = new Map();


    constructor() {
        makeAutoObservable(this);
    }

    getStockAnalysis = async (stockAnalysis: IStockAnalysis) => {
        try {
            const data = await apiAgent.stockAnalysis.getStockAnalysis(stockAnalysis);
            runInAction(() => {
                this.stockReportDetails = JSON.parse(data);
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }

    getStockAnalysisBatchwise = async (stockAnalysis: IStockAnalysis) => {
        try {
            const data = await apiAgent.stockAnalysis.getStockAnalysisBatchwise(stockAnalysis);
            runInAction(() => {
                this.stockReportDetails = JSON.parse(data);
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }

    importExportFormSave = async (supplierMasters) => {
        try {
            const status = await apiAgent.stockAnalysis.importExportFormSave(supplierMasters);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }


}
export default StockAnalysisStore;