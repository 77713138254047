import { observable } from "mobx";
export interface IPackingInward{
    packingInwardId?:number;
    packingInwardDate?:string;
    teamId?:number;    
    teamName?:string; 
    itemId?:number;   
    batchNo?:string;
    packingQty?:string;   
    itemUOM?:string;   
    itemName?:string;         
    packingInwardDetails?:PackingInwardDetails[];      
   
}
export interface PackingInwardDetails{
    InwardpackingInwardId?:number;
    InwardPackingItemId?:number;   
    InwardBatchNo?:string;
    InwardPackingQty?:string;   
    InwardItemUOM?:string;   
    InwardItemName?:string;  
    
}
export interface IPackingInwardSearchoptions{
    searchOption?:string;
    searchValue?:string;
}
const packingInwardDetails=observable<IPackingInward>({
    packingInwardId:0,
    packingInwardDate:'',    
    teamId:0,
    teamName:'',
    batchNo:'',
    packingQty:'',
    itemUOM:'',
    itemName:'',
    itemId:0,
    packingInwardDetails:[],
    
});

export default packingInwardDetails;