import React, { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { IIJobVacancy } from "../JobMaster/model";
import useStores from "../../hooks";
import { saveValidation } from "./validation";
import AutoComplete from "../../common/shared/autoComplete";
import { removeDuplicates } from "../../common/shared/utils";

const JobVacancy = () => {

    const { jobMasterStore, commonStore } = useStores()

    const { loadEmployee, loadEmployees, loadWarehouse, loadWarehouses, loadOutlet, loadOutlets, } = commonStore;

    const navigate = useNavigate();

    const [jobVacancy, setJobVacancy] = useState<IIJobVacancy>(new IIJobVacancy())

    const [jobMaster, setJobMaster] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/JobVacancy/list');
    }
    async function handleSaveCategory() {
        let error: any = {};
        error = saveValidation(jobVacancy)
        setErrors({ ...error })


        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const entryNo = await jobMasterStore.getHREntryNo('JobVacency');
            jobVacancy.jvcode = entryNo?.entrynumber;
            jobVacancy.jvid = Number(entryNo?.entrynumber);
            const status = await jobMasterStore.saveJobVacancy(jobVacancy)
            if (status === 'Success') {
                setLoading(false)
                setSuccessModal(true)
            } else {
                setLoading(false)
                setFailureModal(true)
            }
        }
    }

    function handleChangeInput(event) {
        const { name, value } = event?.target;
        if (name === 'createdBy' || name === '') {
            setJobVacancy({ ...jobVacancy, [name]: Number(value) })
        } else {
            setJobVacancy({ ...jobVacancy, [name]: value })
        }
    }

    function handleAutoComplte(name, value) {
        if (name === 'jvcode') {
            let findObj = jobMaster?.find((each) => each?.Jobcode === value)
            if (findObj) {
                setJobVacancy({ ...jobVacancy, [name]: findObj?.Jobcode, jtitle: findObj?.Jobtitle, jDescription: findObj?.JobDescription })
            }
        } else {
            setJobVacancy({ ...jobVacancy, [name]: value })
        }
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('JobVacency');
        const jobMaster = await jobMasterStore?.loadJobMaster()
        await loadEmployee()
        await loadOutlet()
        await loadWarehouse()
        setJobMaster([...jobMaster])
        setJobVacancy({ ...jobVacancy, jvid: Number(entryNo?.entrynumber) })
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox' style={{ width: '50%' }}>
                            <div className='hrBox'>
                                <h3>Job Vacancy</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label>Code</label>
                                            <input type="text"
                                                name='brandId' disabled
                                                style={{ width: '100%' }}
                                                value={jobVacancy?.jvid}
                                                className='brandinput'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label>Job Date <span>*</span></label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="jvdate"
                                                value={jobVacancy?.jvdate}
                                                onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-4"></div>
                                    <div className="col-sm-6">
                                        <div className="inputBox" >
                                            <label > Job Code<span>*</span></label>
                                            <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                                name="jvcode"
                                                value={jobVacancy?.jvcode}
                                                onChange={(e) => handleAutoComplte('jvcode', e.target.value)}
                                            >
                                                <option value="" selected>- - Select - -</option>
                                                {jobMaster?.map((obj) => {
                                                    return (
                                                        <option value={obj?.Jobcode}>{obj?.Jobcode}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.jvcode && <p style={{ color: 'red' }}>{errors.jvcode}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Job Title <span>*</span></label>
                                            <input type="text"
                                                name='jtitle'
                                                style={{ width: '100%' }}
                                                disabled
                                                value={jobVacancy?.jtitle}
                                                className='brandinput' placeholder='Enter Job Title..'></input>
                                            {errors.jtitle && <p style={{ color: 'red' }}>{errors.jtitle}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Job Description <span>*</span></label>
                                            <input type="text" disabled className='brandinput' placeholder='Enter Job Description..' style={{ width: '100%' }}
                                                name='jDescription' value={jobVacancy?.jDescription}
                                            ></input>
                                            {errors.jDescription && <p style={{ color: 'red' }}>{errors.jDescription}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="inputBox" >
                                            <label > Work Location<span>*</span></label>
                                            <select className="form-selected full-width"
                                                name='worklocation' value={jobVacancy?.worklocation} onChange={handleChangeInput}
                                            >
                                                <option value="" selected>- - Select - -</option>
                                                {[...loadOutlets, ...loadWarehouses]?.map((obj) => {
                                                    let returnObj = {
                                                        ...obj,
                                                        worklocatoin: obj?.warehouseId ? obj?.warehouseName : obj?.outletName
                                                    }
                                                    return (
                                                        <option value={`${returnObj?.worklocatoin}`}>{returnObj?.worklocatoin}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.worklocation && <p style={{ color: 'red' }}>{errors.worklocation}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="inputBox" >
                                            <label>Work Manager <span>*</span></label>
                                            <AutoComplete clsName="full-width" options={removeDuplicates(loadEmployees, 'employeeName')} getOptionLabel="employeeName"
                                                value={loadEmployees?.find(each => each?.employeeId == jobVacancy?.workmanagerid)?.employeeName}
                                                emitOption={(option) => handleAutoComplte('workmanagerid', option?.employeeId)} placeholder={"Select Work Manager"} />
                                        </div>
                                        {errors.workmanager && <p style={{ color: 'red' }}>{errors.workmanager}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >No of Position <span>*</span></label>
                                            <input type="text" style={{ width: '100%' }} className='brandinput' placeholder='Enter no of position..'
                                                name='noofPosision' value={jobVacancy?.noofPosision} onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.noofPosision && <p style={{ color: 'red' }}>{errors.noofPosision}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Experience Required  (In Years) <span>*</span></label>
                                            <input type="text" style={{ width: '100%' }} className='brandinput' placeholder='Enter Experience Required'
                                                name='experience_Required' value={jobVacancy?.experience_Required} onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.experience_Required && <p style={{ color: 'red' }}>{errors.experience_Required}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Skills Required <span>*</span></label>
                                            <input type="text" style={{ width: '100%' }} className='brandinput' placeholder='Enter Skills Required..'
                                                name='skills_Required' value={jobVacancy?.skills_Required} onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.skills_Required && <p style={{ color: 'red' }}>{errors.skills_Required}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Qualification <span>*</span></label>
                                            <input type="text" style={{ width: '100%' }} className='brandinput' placeholder='Enter Qualification..'
                                                name='qualification' value={jobVacancy?.qualification} onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.qualification && <p style={{ color: 'red' }}>{errors.qualification}</p>}
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="inputBox" >
                                            <label> Created By<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={removeDuplicates(loadEmployees, 'employeeName')} getOptionLabel="employeeName"
                                                value={loadEmployees?.find((each) => each?.employeeId === jobVacancy?.createdBy)?.employeeName ?? ''}
                                                emitOption={(option) => handleAutoComplte('createdBy', option?.employeeId)} placeholder={"Select Created By"} />
                                        </div>
                                        {errors.createdBy && <p style={{ color: 'red' }}>{errors.createdBy}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className='col-sm-6'>
                                        <div className='inputBox'>
                                            <label >Remarks </label>
                                            <input type="text"
                                                style={{ width: '100%' }}
                                                className='brandinput' placeholder='Enter Remarks..'
                                                name='remark' value={jobVacancy?.remark} onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div >
    )
}

export default JobVacancy;