
function ProductionEntryValidation(machineMasterDet) {
  let errors: any = {};
  if (!machineMasterDet.proddate) {
    errors.proddate = "Enter Date";
  }
  if (!machineMasterDet.teamid) {
    errors.teamid = "Enter Team No...";
  }
  if (!machineMasterDet.machineid) {
    errors.machineid = "Enter Machine No...";
  }
  if (!machineMasterDet.productionentrydetails?.length) {
    errors.lengthnon = "Add Table Data";
  }

  return errors;
};

export default ProductionEntryValidation;

export function ProductionItemsValidation(machineMasterDet) {
  let errors: any = {};
  if (!machineMasterDet.itemid) {

    errors.itemid = "Enter Item";
  }
  if (!machineMasterDet.batchno) {
    errors.batchno = "Enter Batch No";
  }
  if (!machineMasterDet.wastage) {
    errors.wastage = "Enter Wastage";
  }
  if (!machineMasterDet.wRemarks) {
    errors.wRemarks = "Enter W.Remarks";
  }
  if (!machineMasterDet.returnQty) {
    errors.returnQty = "Enter Return Qty";
  }
  if (!machineMasterDet.rremarks) {
    errors.rremarks = "Enter R.Remarks";
  }
  return errors;
}


export function ProductionEntryDetValidation(productionEntryDet) {
  let errors: any = {};
  if (!productionEntryDet.itemid) {
    errors.itemid = "Select Item";
  }
  if (!productionEntryDet.batchno) {
    errors.batchno = "Select Batch No";
  }
  if (!productionEntryDet.qty) {
    errors.qty = "Enter Qty";
  }
  return errors;
}

export function ProductionWastedValidation(productionWasted) {
  let errors: any = {};
  if (!productionWasted.itemid) {
    errors.itemid = "Select Item";
  }
  if (!productionWasted.batchno) {
    errors.batchno = "Select Batch No";
  }
  if (!productionWasted.wastage) {
    errors.wastage = "Enter Wastage";
  }
  if (!productionWasted.wRemarks) {
    errors.wRemarks = "Enter Wastage Remarks";
  }
  if (!productionWasted.returnQty) {
    errors.returnQty = "Enter Return Qty";
  }
  if (!productionWasted.rremarks) {
    errors.rremarks = "Enter Return Remarks";
  }
  return errors;
}