import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { IInterviewProcess, IInterviewProcessDetials } from "./model";
import { Autocomplete, TextField } from "@mui/material";
import useStores from "../../hooks";
import { addValidation, saveValidation } from "./validaiton";
import { removeDuplicates } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";

const InterviewProcess = () => {

    const { jobMasterStore, commonStore, interviewStore } = useStores();
    const { loadEmployee, loadEmployees } = commonStore;

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [interviewMaster, setInterviewMaster] = useState<any[]>([])
    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])

    const [interviewProcessMaster, setInterviewProcessMaster] = useState<IInterviewProcess>(new IInterviewProcess())
    const [interviewProcessDetail, setInterviewProcessDetail] = useState<IInterviewProcessDetials>(new IInterviewProcessDetials())

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/InterviewProcess/List');
    };

    function handleToDeductionList() {
        navigate("/Admin/InterviewProcess/List");
    }

    function handleInputChange(event) {
        const { name, value } = event?.target;
        handleAutoComplete(name, value);
    }

    function handleAutoComplete(name, value) {
        setInterviewProcessMaster({ ...interviewProcessMaster, [name]: value })
    }

    function handleAddClick(index) {
        let error: any = {}
        if (index >= 0) {
            interviewProcessMaster?.interviewProcessdetails?.splice(index, 1)
        } else {
            error = addValidation(interviewProcessDetail);
            debugger
            if (!interviewProcessMaster?.interviewProcessdetails?.every(obj => obj.nextPhases !== 'No')) {
                error.nextPhases = 'There is no Next phase'
            }
            if (Object.keys(error)?.length === 0) {
                let obj = Object.assign({}, interviewProcessDetail);
                obj.intprocessid = interviewProcessMaster?.intprocessid;
                interviewProcessMaster?.interviewProcessdetails?.push(obj)
                setInterviewProcessDetail(new IInterviewProcessDetials())
            }
        }
        setInterviewProcessMaster({ ...interviewProcessMaster })
        setErrors({ ...error })
    }

    async function handleSaveCategory() {
        let error: any = {}
        error = saveValidation(interviewProcessMaster)
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            const status = await interviewStore.saveInterviewProcessMaster(interviewProcessMaster);
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    const phases = [{ key: 'round1', value: 'Round 1' }, { key: 'round2', value: 'Round 2' }, { key: 'round3', value: 'Round 3' }]

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('Interviewprocess');
        setInterviewProcessMaster({
            ...interviewProcessMaster,
            intprocessid: Number(entryNo?.entrynumber),
        })
        const interviewMaster = await interviewStore.loadInterviewMaster();
        if (interviewMaster?.length) {
            setInterviewMaster([...interviewMaster])
        }
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        const interviewletter = await interviewStore.loadInterviewLetter()
        setInterviewLetterList([...interviewletter])
        await loadEmployee()
        setLoading(false)
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        {/* <form > */}
                        <div className="hrBox">
                            <h3>Interview Process</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Interview Process Code</label>
                                        <input type="text" name='brandId' disabled
                                            value={interviewProcessMaster?.intprocessid} style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Interview Date</label>
                                        <input type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date="" data-date-format="DD MMMM YYYY"
                                            name="interviewdate"
                                            value={interviewProcessMaster?.interviewdate}
                                            onChange={handleInputChange}
                                        ></input>
                                        {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Job Code<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={jobMaster}
                                            getOptionLabel={(option: any) => option.Jobcode}
                                            onChange={(event, val) => handleAutoComplete('jobcode', Number(val?.Jobcode))}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Job Code..'
                                                    name='jobcode'
                                                />}
                                        />
                                    </div>
                                    {errors?.jobcode && <p style={{ color: 'red' }}>{errors?.jobcode}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Title</label>
                                        <input type="text"
                                            name='brandId' disabled
                                            value={jobMaster?.find((each) => each?.Jobcode == interviewProcessMaster?.jobcode)?.Jobtitle ?? ''}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Description</label>
                                        <textarea
                                            name='brandId' disabled
                                            value={jobMaster?.find((each) => each?.Jobcode == interviewProcessMaster?.jobcode)?.JobDescription ?? ''}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></textarea>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Call Letter No<span>*</span></label>
                                        <Autocomplete size="small" disablePortal
                                            id="combo-box-demo"
                                            options={removeDuplicates(interviewLetterList, 'Candidatename')}
                                            getOptionLabel={(option: any) => option.Interviewdid}
                                            onChange={(event, val) => setInterviewProcessMaster({ ...interviewProcessMaster, callletterno: val?.Interviewdid, empid: val?.Employeeid })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Call letter No..'
                                                    name='Interviewdid'
                                                />}
                                        />
                                    </div>
                                    {errors?.callletterno && <p style={{ color: 'red' }}>{errors?.callletterno}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Candidate Name</label>
                                        <input type="text" name='brandId' disabled
                                            style={{ width: "100%" }}
                                            value={interviewLetterList?.find((each) => each?.Interviewdid === interviewProcessMaster?.callletterno)?.Candidatename}
                                            className='brandinput' ></input>
                                    </div>
                                </div>
                                <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <label style={{ fontSize: '20px' }}>Interview Round Details</label>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Round <span>*</span></label>
                                        <AutoComplete placeholder='Rounds..' getOptionLabel={'GID_Phases'}
                                            options={interviewMaster?.filter((each) => each?.Jobcode === interviewProcessMaster?.jobcode)}
                                            value={interviewProcessDetail?.phaseno ?? ''}
                                            clsName="capitalize full-width"
                                            emitOption={(val) => setInterviewProcessDetail({ ...interviewProcessDetail, phaseno: val?.GID_Phases, process: val?.GID_Process })}
                                        />
                                    </div>
                                    {errors?.phaseno && <p style={{ color: 'red' }}>{errors?.phaseno}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Process <span>*</span></label>
                                        <input type="text" name='brandId'
                                            value={interviewProcessDetail?.process} disabled
                                            style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                    {errors?.process && <p style={{ color: 'red' }}>{errors?.process}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>FeedBack <span>*</span></label>
                                        <textarea name=''
                                            value={interviewProcessDetail?.feedback} onChange={(e) => setInterviewProcessDetail({ ...interviewProcessDetail, feedback: (e.target.value) })}
                                            className='full-width' ></textarea>
                                    </div>
                                    {errors?.feedback && <p style={{ color: 'red' }}>{errors?.feedback}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Conduct By <span>*</span></label>
                                        <AutoComplete placeholder='Conduct By..' getOptionLabel={'employeeName'}
                                            options={removeDuplicates(loadEmployees, 'employeeName')}
                                            value={interviewProcessDetail?.conductby ?? ''}
                                            clsName="capitalize"
                                            emitOption={(val) => setInterviewProcessDetail({ ...interviewProcessDetail, conductby: val?.employeeName })}
                                        />
                                    </div>
                                    {errors?.conductby && <p style={{ color: 'red' }}>{errors?.conductby}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    {interviewProcessMaster?.interviewProcessdetails?.every(obj => obj.nextPhases !== 'No') &&
                                        <FormControl>
                                            <label style={{ fontSize: '16px' }}>Next Phase <span>*</span></label>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={interviewProcessDetail?.nextPhases}
                                                onChange={(e) => setInterviewProcessDetail({ ...interviewProcessDetail, nextPhases: (e.target.value) })}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>}
                                    {errors?.nextPhases && <p style={{ color: 'red' }}>{errors?.nextPhases}</p>}
                                </div>
                                <div className="col-sm-6">
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={() => handleAddClick(-1)}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Round</th>
                                                    <th scope="col">Process</th>
                                                    <th scope="col">Next Round</th>
                                                    <th scope="col">FeedBack</th>
                                                    <th scope="col">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {interviewProcessMaster?.interviewProcessdetails?.map((each, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{each?.phaseno}</td>
                                                            <td>{each?.process}</td>
                                                            <td>{each?.nextPhases}</td>
                                                            <td>{each?.feedback}</td>
                                                            <td>
                                                                <button className="delete">
                                                                    <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddClick(index)}
                                                                    />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    {errors?.interviewProcessdetails && <p style={{ color: 'red' }}>{errors?.interviewProcessdetails}</p>}
                                </div>
                                <div className="col-sm-12">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Feedback</label>
                                        <textarea name='feedback' value={interviewProcessMaster?.feedback}
                                            onChange={(e) => setInterviewProcessMaster({ ...interviewProcessMaster, feedback: e.target.value })}
                                            className='full-width' ></textarea>
                                    </div>
                                    {errors?.feedbackmaster && <p style={{ color: 'red' }}>{errors?.feedbackmaster}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToDeductionList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    )
}

export default InterviewProcess;