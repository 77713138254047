import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const SelectionApprovalList = observer((): JSX.Element => {
    const { jobMasterStore, interviewStore } = useStores();

    const { loadInterviewLetter, loadSelectionApproval } = interviewStore;

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])
    const [interviewProcessList, setInterviewProcessList] = useState<any[]>([])

    const [selectionApprovalList, setSelectionApprovalList] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [onOpenDetail, setOnOpenDetail] = useState({ bool: false, id: 0, clNo: 0 })

    const deleteEmployee = async (empId) => {

    }

    const handleFailureModalClose = () => setFailureModal(false);

    const editEmployee = (empId) => { }

    const handleCreate = () => {
        navigate('/Admin/ResumeBank');
    }

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
    }

    const fetchOutletItemData = async () => {
        const selectionApprovalList = await loadSelectionApproval();
        setSelectionApprovalList([...selectionApprovalList])
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        const processList = await interviewStore?.loadInterviewProcessMaster();
        if (processList?.length) {
            setInterviewProcessList([...processList]);
        }
        const interviewletter = await loadInterviewLetter()
        setInterviewLetterList([...interviewletter])
        setLoading(false);
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletItemData();
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    // console.log(interviewProcessList, 'interviewProcessList>>', currentPageData)
    useEffect(() => {
        if (selectionApprovalList && selectionApprovalList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(selectionApprovalList)
        }
    }, [selectionApprovalList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(selectionApprovalList?.slice()?.sort((a, b) => b.Intselectionappid - a.Intselectionappid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <div className='container-fluid'>
                        <div className='vertical-space-20'></div>
                        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={selectionApprovalList}
                            searchTitles={[{ key: "Intselectionappid", value: "Approval Id" }, { key: "Conductby", value: "Conduct by" }]}
                            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                        <div className='vertical-space-20'></div>
                        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='btnBox'>
                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{selectionApprovalList?.length} Records</button>
                            </div>
                            <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                            </Stack>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='tableListDetails'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Approval Id</th>
                                        <th scope='col'>Process Id </th>
                                        <th scope='col'>Date  </th>
                                        <th scope='col'>Candidate Name</th>
                                        <th scope='col'>Edu. Qualification</th>
                                        <th scope='col'>Job Code</th>
                                        <th scope='col'>Job Title</th>
                                        <th scope='col'>Job Description</th>
                                        <th scope='col'>Feedback</th>
                                        <th scope='col'>Conductby  </th>
                                        <th scope='col'></th>
                                        <th scope='col'>Create </th>
                                        <th scope='col'>Update </th>
                                        <th scope='col'>Delete </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isSearch ? filteredData : currentPageData)?.length ?
                                        (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                            return val?.Intselectionappid > 0 && (
                                                <tr key={key}>
                                                    <td>{val.Intselectionappid}</td>
                                                    <td>{val.Intprocessid}</td>
                                                    <td>{moment(val?.Interviewdate)?.format('DD-MMM-YYYY')}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === val?.Callletterno)?.Candidatename}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === val?.Callletterno)?.EducationQualification}</td>
                                                    <td>{val?.Jobcode}</td>
                                                    <td>{jobMaster?.find((each) => Number(each?.Jobcode) === Number(val?.Jobcode))?.Jobtitle}</td>
                                                    <td style={{ maxWidth: '300px' }}>{jobMaster?.find((each) => Number(each?.Jobcode) === Number(val?.Jobcode))?.JobDescription}</td>
                                                    <td>{val.Feedback}</td>
                                                    <td>{val.ApprovedBy}</td>
                                                    <td><button className='' onClick={() => { setOnOpenDetail({ bool: true, id: val?.Intprocessid, clNo: val?.Callletterno }) }}>Details</button></td>
                                                    <td><button className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                    <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editEmployee(val.empId)} /></button></td>
                                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteEmployee(val.empId)} /></button></td>
                                                </tr>
                                            )
                                        }) : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Modal show={onOpenDetail?.bool} onHide={() => { setOnOpenDetail({ bool: false, id: 0, clNo: 0 }) }} className='PriceHistoryModel'>
                            <Modal.Header closeButton>
                                <Modal.Title>Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Round</th>
                                                <th scope="col">Process</th>
                                                <th scope="col">Next Round</th>
                                                <th scope="col">FeedBack</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {interviewProcessList?.map((each, index) => {
                                                return (onOpenDetail?.id === each?.Intprocessid && onOpenDetail?.clNo === each?.Callletterno) && (
                                                    <tr key={index}>
                                                        <td className='capitalize'>{each?.Phaseno}</td>
                                                        <td>{each?.Process}</td>
                                                        <td>{each?.NextPhases}</td>
                                                        <td>{each?.Feedback1}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Deleted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
            }
        </>
    );
});

export default SelectionApprovalList;
