import { useEffect, useMemo, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import { PartyLedgerSearch } from "../../Admin/PartyLedger/model";
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { currentPageDatas } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import LedgerSearchValidation from "../../Admin/PartyLedger/validation";


interface ExcelSheetData {
    Date?: string,
    Particulars?: string,
    Type?: string,
    Bill_No?: string,
    Credit?: number,
    Debit?: number,
}

const DayBook = () => {

    const [dayBookSearch, setDayBookSearch] = useState<PartyLedgerSearch>(new PartyLedgerSearch())
    const [dayBookLedgerStatement, setDayBookLedgerStatement] = useState<any[]>([])

    const [ledgerNameArr, setLedgerNameArr] = useState<any[]>([])
    const [selectedLedger, setSelectedLedger] = useState<any>({})

    const { partyLedgerStore } = useStores();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({})

    function handleChangeclear() {
        setDayBookSearch(new PartyLedgerSearch());
        setDayBookLedgerStatement([]);
    }

    function handleInputChange(event) {
        const { name, value } = event?.target;
        setDayBookSearch({ ...dayBookSearch, [name]: value })
    }

    async function fetchPartyLedgerList() {
        let error: any = {};
        error = LedgerSearchValidation(dayBookSearch);
        setErrors(error);
        if (Object.keys(error).length === 0) {
            setIsLoading(true);
            const dayBookLedgerStatement = await partyLedgerStore.getLedgerDayBook(dayBookSearch);
            setDayBookLedgerStatement([...(dayBookLedgerStatement)]);
            setIsLoading(false);
        }
    }

    function createExportExcelObj(partyLedgerDetails: any[]) {
        let excelArr: ExcelSheetData[] = partyLedgerDetails?.map((partyLedger) => {
            const excelObj: ExcelSheetData = {
                Date: (partyLedger?.vchr_date && partyLedger?.sort_order === 1) ? moment(partyLedger?.vchr_date).format("DD-MMM-YYYY") : '',
                Particulars: (partyLedger?.ledger_accout_name + " " + partyLedger?.vchr_narration),
                Type: partyLedger?.Transaction_Type,
                Bill_No: (partyLedger?.sort_order === 2 || partyLedger?.sort_order === 3) ? partyLedger?.check_date : partyLedger?.vchr_no,
                Credit: partyLedger?.Credit > 0 ? partyLedger?.Credit : '0.00',
                Debit: partyLedger?.Debit > 0 ? partyLedger?.Debit : '0.00',
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Day Book Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Day Book Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function handlePrint() {
        let printContents = document.getElementById('printablediv')!.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    async function fetchPartyLedgerName() {
        let ledgerNameSearchOption = {
            searchOption: "LedgerAccountName",
            searchValue: ""
        };
        const ledgerNameArry = await partyLedgerStore.loadPartyLedgername(ledgerNameSearchOption)
        if (ledgerNameArry?.length) {
            setLedgerNameArr([...ledgerNameArry])
        }
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPartyLedgerName();
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    const sortedData = useMemo(() => {
        const listOfData = dayBookLedgerStatement?.slice()?.sort((a: any, b: any) => {
            const dateA = new Date(a?.entrydate);
            const dateB = new Date(b?.entrydate);

            if (dateA > dateB) return -1;
            if (dateB < dateA) return 1;
            return 0;
        });
        return listOfData?.length ? listOfData : []
    }, [dayBookLedgerStatement])

    return (
        <div className="contaienr">
            {isLoading ? <div className='SpinnerBox'>
                <Spinner animation="grow" size="sm" />
            </div> : null}
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
                <div className='hrBox'>
                    <h3>Day Book</h3>
                </div>
                <div className='inputBoxLists'>
                    <div className='ItemInwardInputBox'>
                        <div className="row">
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>From Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        onChange={handleInputChange}
                                        name="searchDatefrom"
                                        value={dayBookSearch?.searchDatefrom}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>To Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        value={dayBookSearch?.searchDateto}
                                        name="searchDateto"
                                        onChange={handleInputChange}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-20'></div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>Ledger Name <span>*</span></label>
                                    <AutoComplete options={ledgerNameArr?.length ? ledgerNameArr : []} placeholder='Party Name...'
                                        emitOption={(option) => { setDayBookSearch({ ...dayBookSearch, ledgername: option?.ledgeraccoutname, ledgerId: option?.ledger_accout_id }); setSelectedLedger(option) }} getOptionLabel='ledgeraccoutname' clsName='full-width' />
                                </div>
                                {errors?.ledgername && <p style={{ color: 'red' }}>{errors?.ledgername}</p>}
                            </div>
                            <div className="col-sm-5" style={{ marginTop: '30px' }}>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={fetchPartyLedgerList}> Search</button>
                                    <button className='secondaryBtn' onClick={handlePrint}> Print</button>
                                    <button className='secondaryBtn' onClick={() => createExportExcelObj(sortedData)}>Excel</button>
                                    <button className="secondaryBtn" onClick={handleChangeclear}> Clear</button>
                                    <button className="secondaryBtn" onClick={() => { }}> Close </button>
                                </div>
                            </div>
                        </div>
                        <div style={sortedData?.length > 0 ? { visibility: 'visible', width: '100%' } : { visibility: "hidden" }}>
                            <div id='printablediv' className="tableListDetails">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Bill No.</th>
                                            <th scope="col">Credit</th>
                                            <th scope="col">Debit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textTransform: 'uppercase', color: 'blue' }} colSpan={6}>{selectedLedger?.ledgeraccoutname}</td>
                                        </tr>
                                        {dayBookLedgerStatement?.map((statement) => {
                                            return (
                                                <tr>
                                                    <td scope='col'>{(statement?.vchr_date && statement?.sort_order === 1) ? moment(statement?.vchr_date).format("DD-MMM-YYYY") : ''}</td>

                                                    <td scope='col'>{(statement?.ledger_accout_name + " " + statement?.vchr_narration)}</td>

                                                    <td scope='col'>{statement?.Transaction_Type}</td>

                                                    <td scope='col'>
                                                        {(statement?.sort_order === 2 || statement?.sort_order === 3) ? statement?.check_date : statement?.vchr_no}
                                                    </td>

                                                    <td scope='col'>{statement?.Credit > 0 ? statement?.Credit : '0.00'}</td>

                                                    <td scope='col'>{statement?.Debit > 0 ? statement?.Debit : '0.00'}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='vertical-space-20'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DayBook;