import { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { IIBrakInOutEntry, IIBreakinoutEmployeeDet } from "./model";
import useStores from "../../hooks";
import { addValidation, saveValidation } from "./validation";

const BreakingInOutEntry = () => {

    const { employeeStore, memberStore, payrollStore } = useStores();

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [breakInoutMaster, setBreakInOutMaster] = useState<IIBrakInOutEntry>(new IIBrakInOutEntry())
    const [breakInoutDet, setBreakInoutDet] = useState<IIBreakinoutEmployeeDet>(new IIBreakinoutEmployeeDet())

    console.log(breakInoutMaster, 'breakInoutMaster>>')
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate("/Admin/BreakInOutEntry/BreakInOutEntryList");
    };

    async function handleSaveCategory() {
        let error: any = {}
        error = saveValidation(breakInoutMaster)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            const status = await payrollStore.saveEmployeeBreakInOutMaster(breakInoutMaster);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToDeductionList() {
        navigate("/Admin/BreakInOutEntry/BreakInOutEntryList");
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setBreakInOutMaster({ ...breakInoutMaster, [name]: name === 'biO_EmpID' ? Number(value) : value })
    }

    function hadnleDetInputChange(event) {
        const { name, value } = event.target;
        setBreakInoutDet({ ...breakInoutDet, [name]: value })
    }

    function handleAddToGrid(index) {
        let error: any = {};
        if (index >= 0) {
            breakInoutMaster?.employeebreakinoutdet?.slice(index, 1)
        } else {
            breakInoutDet['biD_MID'] = breakInoutMaster?.biO_ID;
            breakInoutDet['biD_EmpID'] = breakInoutMaster?.biO_EmpID;
            error = addValidation(breakInoutDet);
            if (Object.keys(error)?.length === 0) {
                breakInoutMaster?.employeebreakinoutdet?.push(breakInoutDet);
                setBreakInoutDet(new IIBreakinoutEmployeeDet())
            }
        }
        setErrors({ ...error })
        setBreakInOutMaster({ ...breakInoutMaster })
    }

    async function initialApiCalls() {
        const entryNos = await memberStore?.getEntryNo('BreakInOut')
        setBreakInOutMaster({ ...breakInoutMaster, biO_ID: entryNos?.entryNo, biO_EntryNo: (entryNos?.entryNo)?.toString() })
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false;
        }
    }, [])
    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        <div className="hrBox">
                            <h3>Break IN / OUT Entry</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Record No</label>
                                        <input type="text" name='brandId' disabled
                                            value={breakInoutMaster?.biO_EntryNo} style={{ width: "100%" }}
                                            className='brandinput' placeholder='Record No'></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Date</label>
                                        <input type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY" data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="biO_Date"
                                            value={breakInoutMaster?.biO_Date}
                                            onChange={handleInputChange}
                                        ></input>
                                    </div>
                                    {errors.biO_Date && <p style={{ color: "red" }}>{errors.biO_Date}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Employee<span>*</span></label>
                                        <select className="full-width" name="biO_EmpID"
                                            value={breakInoutMaster?.biO_EmpID}
                                            onChange={handleInputChange} id="Openingtype" >
                                            <option value="" selected>--Select--</option>
                                            {employeeMaster?.map((each) => {
                                                return (
                                                    <option value={each?.EmployeeId}>{each?.Employee_Name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors.biO_EmpID && <p style={{ color: "red" }}>{errors.biO_EmpID}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Out Date </label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="biD_Date"
                                            value={breakInoutDet?.biD_Date}
                                            onChange={hadnleDetInputChange}
                                        ></input>
                                    </div>
                                    {errors.biD_Date && <p style={{ color: "red" }}>{errors.biD_Date}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Out Time</label>
                                        <input
                                            type="time"
                                            className="brandinput"
                                            placeholder="Days"
                                            style={{ width: "100%" }}
                                            name="biD_OutTime"
                                            value={breakInoutDet?.biD_OutTime}
                                            onChange={hadnleDetInputChange}
                                        ></input>
                                    </div>
                                    {errors.biD_OutTime && <p style={{ color: "red" }}>{errors.biD_OutTime}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> In Date </label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="biD_InDate"
                                            value={breakInoutDet?.biD_InDate}
                                            onChange={hadnleDetInputChange}
                                        ></input>
                                    </div>
                                    {errors.biD_InDate && <p style={{ color: "red" }}>{errors.biD_InDate}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> In Time  </label>
                                        <input
                                            type="time"
                                            className="brandinput"
                                            placeholder="Days"
                                            style={{ width: "100%" }}
                                            name="biD_InTime"
                                            value={breakInoutDet?.biD_InTime}
                                            onChange={hadnleDetInputChange}
                                        ></input>
                                    </div>
                                    {errors.biD_InTime && <p style={{ color: "red" }}>{errors.biD_InTime}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6"></div>
                                <div className="col-sm-6">
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={handleAddToGrid}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Employee</th>
                                        <th scope="col">Out Date</th>
                                        <th scope="col">Out Time</th>
                                        <th scope="col">In Date</th>
                                        <th scope="col">In Time</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {breakInoutMaster?.employeebreakinoutdet?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{employeeMaster?.find((each) => each?.EmployeeId === val?.biD_EmpID)?.Employee_Name}</td>
                                                <td>{moment(val?.biD_Date).format('DD-MMM-YYYY')}</td>
                                                <td>{val?.biD_OutTime}</td>
                                                <td>{moment(val?.biD_InDate).format('DD-MMM-YYYY')}</td>
                                                <td>{val?.biD_InTime}</td>
                                                <td><button className="delete"><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddToGrid(key)} /></button></td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {errors?.employeebreakinoutdet && <p style={{ color: 'red' }}>{errors?.employeebreakinoutdet}</p>}
                        </div>

                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToDeductionList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button>
                        </div>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className="SubmitModal">
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    )
}

export default BreakingInOutEntry;