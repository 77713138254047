import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IReceipt } from '../model';
import { IStockWarehouse } from '../../../Warehouse/stocktoWarehouse/modal';

class ReceiptStore {
    @observable receipts = new Map();
    @observable receiptsDetils = new Map();
    @observable byLedgers = new Map();
    @observable toLedgers = new Map();
    @observable stockToWarehouseList = new Map();
    @observable stockToWarehouseItem = new Map();
    @observable loadWarehousePackingItems = new Map()
    constructor() {
        makeObservable(this);
    }
    saveReceipt = async (receipt) => {
        try {
            const status = await apiAgent.receipt.saveReceipt(receipt);

            if (status !== undefined) {
                return status;

            }

        }
        catch (error) {
            console.log(error);
        }
    }
    saveStockToWare = async (stockToWareSave: IStockWarehouse) => {
        try {

            const saveState = await apiAgent.receipt.saveStockToWarehouse(stockToWareSave);

            return saveState
        } catch (error) {
            console.log(error)
        }
    }
    saveJournal = async (journal) => {
        try {
            const status = await apiAgent.receipt.saveJournal(journal);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getReceipts = async () => {
        try {
            const receipts = await apiAgent.receipt.getReceipts();

            runInAction(() => {
                this.receipts = receipts;
            })
            return this.receipts;

        }
        catch (error) {
            console.log(error);
        }
    }
    loadWarehousePackingItem = async () => {
        try {
            const loadWarehousePackingItems = await apiAgent.receipt.loadWarehousePackingItems();
            runInAction(() => {
                this.loadWarehousePackingItems = JSON.parse(loadWarehousePackingItems);
            });
            return JSON.parse(loadWarehousePackingItems);
        } catch (error) {
            console.log(error)
        }
    }
    getReceiptDetails = async (receiptId) => {
        try {
            const receipts = await apiAgent.receipt.getReceiptDetails(receiptId);

            runInAction(() => {
                this.receiptsDetils = receipts;
            })
            return this.receiptsDetils;

        }
        catch (error) {
            console.log(error);
        }
    }
    getReceiptSearch = async (receiptSearchOptions) => {
        try {
            const receipts = await apiAgent.receipt.getReceiptSearch(receiptSearchOptions);

            runInAction(() => {
                this.receipts = receipts;
            })
            return this.receipts;

        }
        catch (error) {
            console.log(error);
        }
    }
    getByLedgers = async (ledgerRequest) => {
        try {
            const ledgrs = await apiAgent.receipt.getLedgers(ledgerRequest);

            runInAction(() => {
                this.byLedgers = ledgrs;
            })
            return ledgrs

        }
        catch (error) {
            console.log(error);
        }
    }
    getToLedgers = async (ledgerRequest) => {
        try {
            const ledgers = await apiAgent.receipt.getLedgers(ledgerRequest);

            runInAction(() => {
                this.toLedgers = ledgers;
            })
            return ledgers;

        }
        catch (error) {
            console.log(error);
        }
    }
    getStockToWarehouseDetails = async (stockId) => {
        try {
            const stockToWarehouse = await apiAgent.receipt.getStockToWarehouseDetails(stockId);

            runInAction(() => {
                if (stockId) {
                    this.stockToWarehouseItem = JSON.parse(stockToWarehouse)
                } else {
                    this.stockToWarehouseList = JSON.parse(stockToWarehouse);
                }
            })
            return JSON.parse(stockToWarehouse)

        }
        catch (error) {
            console.log(error);
        }
    }
}
export default ReceiptStore;