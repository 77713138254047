import { action, set, makeObservable, observable, runInAction } from 'mobx';
import OutletManagerDetails, { IAssignOrder } from '../model/index';
import apiAgent from '../../../APIAgent';

class AssignOrderStore {
    
    @observable OutletManagerList= new Map();
    isOutletManagerRequest = true;
     assignOrderList= new Map();
    assignOrderDetail = new Map();
   @observable entryNo = new Map();
   assignOrderItem = new Map();

     saveAssignOrder = async (AssignOrder: IAssignOrder) => {
        
        try {
          
          const status=  await apiAgent.AssignOrder.saveAssignOrder(AssignOrder);
          if(status==="Success"){
            
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.adminModule.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getAssignOrder=async ()=> {
        
        try {
          const getAssignOrder= await apiAgent.AssignOrder.getAssignOrder();
            
            runInAction(() => {
                
                this.assignOrderList = getAssignOrder;
            });
            return this.assignOrderList;
        }
        
        catch (error) {
            console.log(error);
        }
    }
    getAssignOrderDetails=async (orderassignid)=> {
        
        try {
          const getAssignOrder= await apiAgent.AssignOrder.getAssignOrderDetails(orderassignid);
            
            runInAction(() => {
                
                this.assignOrderDetail = getAssignOrder;
            });
            return this.assignOrderDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignOrderSearch  = async (SearchOptions)=> {
        
        try {
          const Data=await apiAgent.AssignOrder.getAssignOrderSearch(SearchOptions);
            this.assignOrderList = Data;
            
            runInAction(() => {
                this.assignOrderList = Data;
                
            })
            return this.assignOrderList;       
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignOrderDetail = async (orderassigndid) => {
        
        try {      
        const status=  await apiAgent.AssignOrder.deleteAssignOrderDetail(orderassigndid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    deleteAssignOrderMaster = async (orderassignid) => {
        try {      
        const status=  await apiAgent.AssignOrder.deleteAssignOrderMaster(orderassignid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadAssignOrderItem=async (orderMasterid)=> {
        
        try {
          const assignOrderItem= await apiAgent.AssignOrder.loadAssignOrderItem(orderMasterid);
            
          runInAction(() => {
            this.assignOrderItem = assignOrderItem;
            
        });
            return this.assignOrderItem;
        }
        catch (error) {
            console.log(error);
        }
    }
     

}
export default AssignOrderStore;