import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import managePriceChangeDetail, { IManagePriceChange } from '../model/index';

class managePriceChangeStore {
    @observable managePriceChangeDetls = managePriceChangeDetail;
    @observable managePriceChangeList= new Map();
    isLoading=true;
    @observable managePriceChangeLists= new Map<any,any>();
    managePriceChangeDetail = new Map();
    isInwardLoding=true;
    constructor(){
        makeObservable(this);
    }
     saveManagePriceChange= async (managePriceChange: IManagePriceChange) => {
        try {
          const status=  await apiAgent.managePriceChange.saveManagePriceChange(managePriceChange);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getManagePriceChanges = async ()=>{
        try{
            const managePriceChangeData=await apiAgent.managePriceChange.getManagePriceChanges();
            
            runInAction(()=>{
                
                this.managePriceChangeList = managePriceChangeData;
                this.isLoading = false;
            })
            return this.managePriceChangeList;
        }
        catch(error){
            console.log(error);
        }
    }
    getManagePriceChangesDetails = async (itemid?:number) =>{
        try{
            const managePriceData = await apiAgent.managePriceChange.getManagePriceChangesDetails(itemid);
            runInAction(()=>{
                
                this.managePriceChangeDetail = managePriceData;
            })
            return this.managePriceChangeDetail;
        }
        catch(error){
            console.log(error);
        }
    }
    
    @action setManagePriceChange = (state:IManagePriceChange) =>{
        this.managePriceChangeList.set(0,state);
    }
    @action setManagePriceChangeList = (state: IManagePriceChange[]) =>{
        set(state,this.managePriceChangeList);
        return this.managePriceChangeList;
    }
}
export default managePriceChangeStore;