import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import OutletStockSummaryValidation from '../OutletStockSummary/validation';
import { IOutletStockAnalysis } from '../OutetStockAnalysis/model';
import AutoComplete from '../../common/shared/autoComplete';
import { sortedData } from '../../common/shared/utils';

interface ExcelSheetData {
    Date: string,
    Item: string,
    Category: string,
    Sub_Category: string,
    Sales_Qty: number,
    Sales_Value: number
}

const SalesSummaryItemWise = observer((): JSX.Element => {

    const { salesSummaryItemWiseStore, commonStore } = useStores();
    const { loadOutlet, loadOutlets, loadSubcategory,
        loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;

    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false })
    const [salesSummaryItemWiseFields, setsalesSummaryItemWiseFields] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis())
    const [salesSummariesItemWise, setSalesSummaryItemWise] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && salesSummariesItemWise?.length > 0) {
            return sortedData(salesSummariesItemWise, 'outwardDate')?.filter((stock) => {
                if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setsalesSummaryItemWiseFields({ ...salesSummaryItemWiseFields, [name]: value })
    }

    function handleSearchInput(e) {
        setSearchValue(e.target.value);
    }

    function handleQuoteSearch() {

    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;

        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true })
        }
    }

    const handleOutletValue = (name: any, val: any) => {
        if (val != null) {
            setsalesSummaryItemWiseFields({ ...salesSummaryItemWiseFields, [name]: val.outletid });
        } else {
            salesSummaryItemWiseFields.outletId = 0;
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setsalesSummaryItemWiseFields({ ...salesSummaryItemWiseFields, [type]: value.subCategoryId });
        } else {
            setsalesSummaryItemWiseFields({ ...salesSummaryItemWiseFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = OutletStockSummaryValidation(salesSummaryItemWiseFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                "outletId": salesSummaryItemWiseFields?.outletId,
                "itemId": salesSummaryItemWiseFields?.itemId,
                "subCategoryId": salesSummaryItemWiseFields?.subCategoryId,
                "category": salesSummaryItemWiseFields?.category,
                "fromDate": salesSummaryItemWiseFields?.fromDate,
                "toDate": salesSummaryItemWiseFields?.toDate
            }
            const stockSummaryDetails = await salesSummaryItemWiseStore.getsalesSummaryItemWise(body);
            if (stockSummaryDetails?.length) {
                setSalesSummaryItemWise([...sortedData(stockSummaryDetails, 'outwardDate')])
            }
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Date: moment(stock?.outwardDate).format("DD-MMM-YYYY"),
                Item: stock?.itemname,
                Category: stock?.category,
                Sub_Category: stock?.subcategoryname,
                Sales_Qty: stock?.inqty,
                Sales_Value: stock?.amount
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Sales Summary Itemwise", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Sales Summary Itemwise ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function calcSalesTotal(saleSummary: any[], type: string) {
        if (type == 'saleQty') {
            const totalSaleQty = saleSummary?.reduce((acc, obj: any) => acc + (obj.inqty ?? 0), 0)
            return totalSaleQty;
        } else {
            const totalSaleValue = saleSummary?.reduce((acc, obj: any) => acc + (obj.amount ?? 0), 0)
            return totalSaleValue;
        }
    }

    async function fetchsalesSummaryItemWise() {
        await loadOutlet();
        await loadItemConversion();
        await loadSubcategory();
        setLoading(false)
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchsalesSummaryItemWise()
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    return (
        <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
            <div className='' style={{ width: '100%' }}>
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Sales Summary Item Wise</h3>
                    </div>
                    {loading ? <div className='SpinnerBox'><Spinner animation="border" role="status"></Spinner> </div> : <></>}
                    <div className='inputBoxLists'>
                        <div className='ItemInwardInputBox'>
                            <div className="row">
                                <div className="col-sm-1">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                                        <input type="date" style={{ width: '100%' }}
                                            name="fromDate"
                                            onChange={handleInputChange}
                                            value={salesSummaryItemWiseFields?.fromDate}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                </div>
                                <div className="col-sm-1">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }}
                                            name="toDate"
                                            onChange={handleInputChange}
                                            value={salesSummaryItemWiseFields?.toDate}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                    <div className='inputBox'>
                                        <AutoComplete options={loadOutlets} getOptionLabel='outletName' placeholder='Select Outlet Name..'
                                            clsName='full-width' emitOption={(option) => handleOutletValue('outletId', option)}
                                        />
                                    </div>
                                    {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                    <div className="input-group mb-3">
                                        <input type="text"
                                            style={{ height: "47px" }}
                                            name='searchValue'
                                            value={searchValue}
                                            onChange={handleSearchInput}
                                            className="form-control" placeholder="Search Here.."
                                            aria-label="Search Outlet..."
                                            aria-describedby="search-button"
                                        ></input>
                                        <div className="input-group-append">
                                            <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                                                id="search-button">
                                                <FontAwesomeIcon icon={faSearch}
                                                    onClick={handleQuoteSearch}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                    <div className='inputBox' style={{ marginTop: '5px', padding: "10px 10px" }}>
                                        <select style={{ width: "175px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                            <option value="">Select Search Criteria</option>
                                            <option value="ItemName">Item Name</option>
                                            <option value="Category">CateGory </option>
                                            <option value="SubCategory">Sub Category </option>
                                        </select>
                                    </div>
                                </div>
                                <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                    {searchCriteria?.category ?
                                        <>
                                            <div className='inputBox'>
                                                <select style={{ marginTop: "0px", height: "47px" }} className="form-selected" onChange={handleInputChange} id="Category" name="category">
                                                    <option value="" selected>Not Select</option>
                                                    <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                    <option value="Pets">Pets</option>
                                                    <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                    <option value="Grocery">Grocery</option>
                                                </select>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </> : searchCriteria?.itemName || searchCriteria?.subCategory ?
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={searchCriteria?.itemName ? loadItemConversions : loadSubcategories}
                                                    getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                    onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : handleValue('subCategoryId', event, val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                            name={searchCriteria?.itemName ? 'itemId' : 'subcategory'}
                                                        />}
                                                />
                                            </div> :
                                            <div className=""></div>
                                    }
                                </div>
                                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                    <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                </div>
                                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                    <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(salesSummariesItemWise)}>Excel</button>
                                </div>
                            </div>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='tableListDetails'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Date</th>
                                        <th scope="col">Item Name</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Sub Category</th>
                                        <th scope="col">Sales Qty</th>
                                        <th scope="col">Sales Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchValue?.length > 0 ? filteredData : salesSummariesItemWise)?.map((stock, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{moment(stock?.outwardDate)?.format('DD-MMM-YYYY')}</td>
                                                <td scope="col">{stock?.itemname}</td>
                                                <td scope="col">{stock?.category}</td>
                                                <td scope="col">{stock?.subcategoryname}</td>
                                                <td scope='col'>{stock?.inqty}</td>
                                                <td scope="col">{stock?.amount}</td>
                                                {/* <td scope="col">{stock?.balanceqty}</td> */}
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total</td>
                                        <td>{calcSalesTotal(searchValue?.length > 0 ? filteredData : salesSummariesItemWise, 'saleQty')}</td>
                                        <td>{calcSalesTotal(searchValue?.length > 0 ? filteredData : salesSummariesItemWise, 'saleValue')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default SalesSummaryItemWise;