import { getCurrentMonthFirstDate } from "../../../common/shared/utils";
import { IIEmployee, IIEmployeeContact, IIEmployeeDetails, IIEmployeeExperience, IIEmployeeNominee, IIEmployeeOfficial, IIEmployeeSalary } from "../model";

export function employeeValidation(employee) {

  let errors: any = {};
  if (!employee.biometricId) {
    errors.biometricId = "Enter Biometric ID";
  }
  if (!employee.employeeName) {
    errors.employeeName = "Enter Employee Name";
  }
  if (!employee.fatherName) {
    errors.fatherName = "Enter Father Name";
  }
  if (!employee.dob) {
    errors.dob = "Select Date of birth";
  }
  if (!employee.doj) {
    errors.doj = "Select Date of Joining";
  }
  if (!employee.designationId) {
    errors.designation = "Select Designation";
  }
  if (!employee.prefix) {
    errors.prefix = "Select Prefix";
  }
  if (!employee.departmentId) {
    errors.department = "Select Department";
  }
  if (!employee.mobileNo) {
    errors.mobileNo = "Enter Mobile No."
  } else if (!(employee.mobileNo.length > 9)) {
    errors.mobileNo = "Enter Valid Mobile No."
  }
  if (!employee.emailId) {
    errors.emailId = "Enter Email Id"
  } else if (!validate(employee.emailId)) {
    errors.emailId = "Enter Valid Email"
  }
  return errors;
};

function validate(value) {
  var re = /\S+@\S+\.\S+/;
  return re.test(value);
}

export function employeeMasterValidation(employeeMaster: IIEmployee) {
  let error: any = {};
  if (!employeeMaster.biometric_id) {
    error.biometric_id = 'Enter Biometric Id'
  }
  if (!employeeMaster?.prefix) {
    error.prefix = 'Select Prefix'
  }
  if (!employeeMaster.employee_Name) {
    error.employee_Name = 'Enter Employee Name'
  }
  if (!employeeMaster.emptype) {
    error.category = 'Select Employee Category'
  }
  if (!employeeMaster?.doj) {
    error.doj = 'Enter DOJ'
  }
  if (!employeeMaster.age || employeeMaster.age < 15) {
    error.dob = 'Enter Correct DOB'
  }
  if (!employeeMaster.employee_Fname) {
    error.employee_Fname = 'Enter Father Name'
  }
  if (!employeeMaster.gender) {
    error.gender = 'Select Gender'
  }
  // if (!employeeMaster.employeecontact?.length) {
  //   employeeMaster.employeecontact = new Array<IIEmployeeContact>()
  //   employeeMaster.employeecontact![0] = new IIEmployeeContact()
  //   if (!employeeMaster.employeecontact![0].mobileNo) {
  //     error.mobileNo = 'Enter Mobile No'
  //   }
  //   if (!employeeMaster.employeecontact![0].presentAddress) {
  //     error.presentAddress = 'Enter Present Address'
  //   }
  //   if (!employeeMaster.employeecontact![0].permantentAddress) {
  //     error.permantentAddress = 'Enter Permanent Address'
  //   }
  //   if (employeeMaster.employeecontact![0].emailId && !validate(employeeMaster.employeecontact![0].emailId)) {
  //     error.emailId = "Enter Valid Email"
  //   }
  // }
  // if (!employeeMaster.employeedetails?.length) {
  //   employeeMaster.employeedetails = new Array<IIEmployeeDetails>()
  //   employeeMaster.employeedetails![0] = new IIEmployeeDetails()
  //   if (!employeeMaster.employeedetails![0]?.department) {
  //     error.department = 'Enter Department'
  //   }
  //   if (!employeeMaster.employeedetails![0].designation) {
  //     error.designation = 'Enter Designation'
  //   }
  // }
  // if (!employeeMaster.employeOfficial?.length) {
  //   error.employeeOfficial = 'Fill Mandatory Fields'
  // }
  // if (!employeeMaster.employeSalary?.length) {
  //   error.employeSalary = 'Fill Mandatory Fields'
  // }
  // if (!employeeMaster.employExperience?.length) {
  //   error.employExperience = 'Add Employee Experience'
  // }
  // if (!employeeMaster.employeNominee?.length) {
  //   error.employeNominee = 'Add Nominee Details'
  // }

  return error;
}

export function employeeDetailsValidation(employeeDetails: IIEmployeeDetails) {
  let error: any = {}
  if (!employeeDetails?.department) {
    error.department = 'Enter Department'
  }
  if (!employeeDetails.designation) {
    error.designation = 'Enter Designation'
  }
  return error;
}

export function employeeExpValidation(employeeExp: IIEmployeeExperience) {
  let error: any = {};
  if (!employeeExp.designation) {
    error.designationI = 'Enter Designation'
  }
  if (!employeeExp.lastEmployer) {
    error.lastEmployer = 'Enter Last Employer'
  }
  if (!employeeExp.occupation) {
    error.occupation = 'Enter Occupation'
  }
  if (!employeeExp.yearofExperience) {
    error.yearofExperience = 'Enter Year of Exp.'
  }
  if (!employeeExp.qualification) {
    error.qualification = 'Enter Qualification'
  }
  return error
}

export function employeeNomineeValidation(employeeNominee: IIEmployeeNominee) {
  let error: any = {};
  if (!employeeNominee.nomAddress) {
    error.nomAddress = 'Enter Address'
  }
  if (!employeeNominee.nomAge) {
    error.nomAge = 'Enter Correct DOB'
  }
  if (!employeeNominee.nomMobileno) {
    error.nomMobileno = 'Enter Mobile No.'
  }
  if (!employeeNominee.nomName) {
    error.nomName = 'Enter Nominee Name'
  }
  if (!employeeNominee.relationship) {
    error.relationship = 'Enter Relationship'
  }
  return error
}

export function employeeOfficialValidation(employeeOfficial: IIEmployeeOfficial) {
  let error: any = {}
  if (!employeeOfficial.companyname) {
    error.companyname = 'Enter Company Name'
  }
  if (!employeeOfficial.category) {
    error.category = 'Enter Employee Category'
  }
  if (!employeeOfficial.shithours) {
    error.shithours = 'Enter Shift Hours'
  }
  if (!employeeOfficial.empstatus) {
    error.empstatus = 'Select Emp. Status'
  }
  return error
}

export function employeeSalaryValidation(employeeSalary: IIEmployeeSalary) {
  let error: any = {}
  if (!employeeSalary.amount) {
    error.amount = "Enter Amount"
  }
  if (!employeeSalary.earnType) {
    error.earnType = "Select Earn Type"
  }
  if (!employeeSalary.grossSalary) {
    error.grossSalary = "Enter Gross Salary"
  }
  if (!employeeSalary.paymentmode) {
    error.paymentmode = "Select Payment Mode"
  }
  if (!employeeSalary.salarytype) {
    error.salarytype = "Select Salary Type"
  }

  return error;
}

export function employeeContactValidation(employeeContact: IIEmployeeContact) {
  let error: any = {}
  if (!employeeContact.mobileNo) {
    error.mobileNo = 'Enter Mobile No'
  }
  if (!employeeContact.present_Add) {
    error.present_Add = 'Enter Present Address'
  }
  if (!employeeContact.permantent_Add) {
    error.permantent_Add = 'Enter Permanent Address'
  }
  if (employeeContact.emailId && !validate(employeeContact.emailId)) {
    error.emailId = "Enter Valid Email"
  }
  return error
}

export function updateEmployeeEdit() {

}