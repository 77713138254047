import { action, set, makeObservable, observable, runInAction } from 'mobx';
import OutletManagerDetails, { IOutletManager } from '../model/index';
import apiAgent from '../../../APIAgent';

class OutletManagerStore {
    // UserCreationDetls = UserCreationDetails;
    // @observable userCreationList= new Map();
    @observable OutletManagerList= new Map();
    isOutletManagerRequest = true;
   @observable entryNo = new Map();
    

     saveOutletManager = async (OutletManager: IOutletManager) => {
        try {
          
          const status=  await apiAgent.outletManagerMasters.saveOutletManager(OutletManager);
          if(status==="Success"){
            
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }

     getOutletManager = async ()=>{
        try{
            
            const outletManagerData = await apiAgent.outletManagerMasters.getOutletManager();
            runInAction(() => {
                
                this.OutletManagerList = outletManagerData;
                this.isOutletManagerRequest = false;
            });
            
            return this.OutletManagerList;
        }
        catch(error){
            console.log(error);
    }
}

getEntryNo = async (flag)=>{
    try{
        const entryNo = await apiAgent.adminModule.getEntryNo(flag);
        
        runInAction(()=>{
            this.entryNo = entryNo;
        })
        return this.entryNo;
    }
    catch(error){
    console.log(error);
    }
}
getOutletManagerSearch  = async (SearchOptions)=> {
    try {
      const outletManagerData=await apiAgent.outletManagerMasters.getOutletManagerSearch(SearchOptions);
        this.OutletManagerList = outletManagerData;
        
        runInAction(() => {
            this.OutletManagerList = outletManagerData;
            
        })
        return this.OutletManagerList;
    
     
    }
    catch (error) {
        console.log(error);
    }
}
deleteOutletManager = async (omId) => {
    try {      
    const status=  await apiAgent.outletManagerMasters.deleteOutletManager(omId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}

}
export default OutletManagerStore;