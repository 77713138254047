import React, { useEffect, useRef, useState } from "react"
import Cookies from 'js-cookie';
import useStores from "../../hooks";
import { Container, Modal } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import { IEntryNoRequest, IIPosFreeItem, IIPosStockMaster, IIPostSalesDetails, IMemberDetails, IPOSBillingSave, IPosItemMaster } from "./model";
import { addProductToBillingList } from "./common/functions";
import BatchModal from "./common/components/batches";
import { faShoppingCart, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PosSaleSave = () => {

    const { posMasterStore } = useStores()

    const [posItemMasterList, setPosItemMasterList] = useState<IPosItemMaster[]>([])
    const [posItemwithStockList, setPosItemStockList] = useState<IIPosStockMaster[]>([])
    const [posFreeItemList, setPosFreeItems] = useState<IIPosFreeItem[]>([])

    console.log(posItemwithStockList, 'posItemwithStockList>>')

    const [posSalesSave, setPosSalesSave] = useState<IPOSBillingSave>(new IPOSBillingSave())
    const [posSaleDetail, setPosSaleDetail] = useState<IIPostSalesDetails[]>([])

    const [isFreeAvail, setFreeAvail] = useState(false)
    const [freeItemAvail, setFreeItemAvail] = useState<any>({})

    const [errors, setErrors] = useState<any>({})

    const [memberDetail, setMemberDetail] = useState<IMemberDetails>({});

    const [barcode, setBarcode] = useState('')
    const [isBarcodeBatches, setBarcodeBatches] = useState({ bool: false, batchArr: Array<any>() })

    function handleItemDetails(item: any) {
        let itemObj = posItemwithStockList?.find((obj) => obj?.itemid === item?.itemId)
        getPosBatchStocks(itemObj?.Barcode ? itemObj?.Barcode : itemObj?.Gsmcode)
    }

    function getPosBatchStocks(barcode) {
        setBarcode('');
        let itemBatchList: any[] = []
        itemBatchList = posItemwithStockList?.filter((obj) => obj?.Barcode == barcode)
        if (itemBatchList?.length === 0) {
            itemBatchList = posItemwithStockList?.filter((obj) => obj?.Gsmcode === barcode)
        }
        if (barcode) {
            if (itemBatchList?.length > 1) {
                setBarcodeBatches({ ...isBarcodeBatches, bool: true, batchArr: itemBatchList })
            } else if (itemBatchList?.length === 1) {
                let itemDetail: IIPostSalesDetails = {
                    posItemId: itemBatchList![0]?.itemid,
                    availableQty: itemBatchList![0]?.stock,
                    posItemName: itemBatchList![0]?.itemname,
                    posItemQty: 1,
                    posMinSalQty: itemBatchList[0]?.minsalqty,
                    posItemMRP: Number(itemBatchList![0]?.Mrprate),
                    posItemSellingRate: Number(memberDetail?.memberCatId === 'B2B' ? itemBatchList![0].BTBSellingRate : itemBatchList![0]?.BTCSellingRate),
                    posTaxPercentage: itemBatchList![0]?.taxper,
                    posDiscountPercentage: itemBatchList![0]?.Discount_Pv,
                    posDiscountValue: itemBatchList![0]?.Discount_Pv,
                    posDiscountMode: itemBatchList![0]?.Dis_Mode,
                    posbatchNo: itemBatchList![0]?.batchno,
                    posBarcode: itemBatchList![0]?.Barcode,
                    posSubCategoryId: Number(itemBatchList![0]?.subcategory)
                }
                let isexistsItem = posSaleDetail?.some((items) => isEqual(items, itemDetail));
                let existsItem = posSaleDetail?.find((items) => isEqual(items, itemDetail));
                itemDetail.posItemQty = isexistsItem ? (Number(existsItem?.posItemQty) + 1) : 1;
                itemDetail.posItemQty = itemDetail?.posItemQty * Number(itemDetail?.posMinSalQty != 0 ? itemDetail?.posMinSalQty : 1)
                if (itemDetail?.availableQty!) {
                    const itemList = addProductToBillingList(itemDetail, itemDetail?.posItemQty!, posFreeItemList, posSaleDetail)
                    console.log(itemList, 'itemList>>')
                }
            }
        }
    }

    function addToCart(itemDetail) {
        const itemList = addProductToBillingList(itemDetail, itemDetail?.posItemQty!, posFreeItemList, posSaleDetail)
        console.log(itemList, 'itemList>>')
    }

    function updateCartQuantityUpdater(type, item, qty) {

    }

    function isEqual(currentItem, newItem) {
        return (
            currentItem?.posItemId == newItem?.posItemId &&
            currentItem?.posItemMRP == newItem?.posItemMRP &&
            currentItem?.posItemSellingRate == newItem?.posItemSellingRate
        )
    }

    function checkForFreeItem(item) {
        let findIndex = posFreeItemList?.findIndex((each) => each?.itemid === item?.itemId)
        if (findIndex !== -1) {
            setFreeAvail(true)
        }

        return posFreeItemList[findIndex]
    }

    function checkItemExists(item) {
        let findIndex = posSaleDetail?.findIndex((each) => each?.posItemId === item?.itemId)
    }

    async function initialApiCall() {
        let request: IEntryNoRequest = {}
        if (Cookies.get('outletId') !== undefined) {
            request.outletId = Number(Cookies.get('outletId'));
            request.flag = 'POSMaster'
        }
        const entryNo = await posMasterStore.getPosBillNo(request);
        setPosSalesSave({ ...posSalesSave, billNo: entryNo?.billNo });
        const posItemMaster = await posMasterStore.getPosItemMaster();
        setPosItemMasterList([...posItemMaster])
        const posProductList = await posMasterStore.loadPOSProductList(request?.outletId);
        setPosItemStockList([...posProductList])
        const posFreeItems = await posMasterStore.getPosFreeItems();
        setPosFreeItems([...posFreeItems])
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage?.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <Container fluid className='Pos full-height'>
            <div className="flex-center-between">
                <div>
                    <TextField size="small" placeholder="Bill No" value={posSalesSave?.billNo ?? ""} />
                    <div className='vertical-space-20'></div>
                </div>
                <div>
                    <TextField size="small" placeholder="Bill No" value={posSalesSave?.billDate} />
                    <div className='vertical-space-20'></div>
                </div>
                <div>
                    <Autocomplete
                        options={posItemMasterList}
                        getOptionLabel={(option: any) => option?.itemName}
                        onChange={(event, val) => handleItemDetails(val)}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} size="small" sx={{ height: '100%' }} placeholder="Select Product / Item" />}
                    />
                    {errors?.itemId ? <p style={{ color: 'red', marginBottom: '0' }}>{errors?.itemId}</p> : <div className='vertical-space-20'></div>}
                </div>
            </div>
            <div className='tableBox '  >
                <table className="table " >
                    <thead>
                        <tr >
                            <th scope="col"></th>
                            <th scope="col">S.No</th>
                            <th scope="col">Product</th>
                            <th scope="col">Qty</th>
                            <th scope="col">MRP</th>
                            <th scope="col">Sell.Price</th>
                            <th scope="col">Tax</th>
                            <th scope="col">Dis</th>
                            <th scope="col">Total</th>
                            <th scope="col">Net Amount</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {posSaleDetail?.map((val, key) => {
                            return (val?.posItemQty! > 0) && (
                                <tr key={key}>
                                    <td
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#dc3545', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                                    // onClick={() => { val?.posItemQty! > 1 && updateCartQuantityUpdater('minus', val, 1) }}
                                    >
                                        -
                                    </td>
                                    <td>{key + 1}</td>
                                    <td> {val.posItemName}</td>
                                    <td>{val.posItemQty}</td>
                                    <td> {val.posItemMRP} </td>
                                    <td> {val.posItemSellingRate}</td>
                                    <td> {val.posTaxValue! + '(' + Number(val.posTaxPercentage) + '%)'}</td>
                                    {val?.posDiscountMode === "Value" ?
                                        <td> {(val?.posDiscountValue as any)?.toFixed(2)}</td>
                                        :
                                        <td> {val.posDiscountValue?.toFixed(2) + '(' + val.posDiscountPercentage + '%)'}</td>
                                    }
                                    <td>{val.posTotalPrice}</td>
                                    <td> {val.posNetAmount?.toFixed(2)}</td>
                                    {/* <td><button><FontAwesomeIcon icon={faEdit} className='inputPlaceholderIcon' onClick={() => handleEditItem(val.posItemId)} /></button></td> */}
                                    {/* <td><FontAwesomeIcon icon={faTrash} className='inputPlaceholderIcon' onClick={() => handleDeleteItem(val?.posItemId, val?.posBarcode, val.posbatchNo)} /></td> */}
                                    <td
                                        onClick={() => { val?.posItemQty! < val?.availableQty! && updateCartQuantityUpdater('add', val, 1) }}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0ac074', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
                                    >
                                        +
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {errors.Product && <p style={{ color: 'red' }}>{errors.Product}</p>}
            </div>
            <Modal show={isFreeAvail} onHide={() => setFreeAvail(false)} className="PriceHistoryModel">
                <Modal.Header closeButton><h5>Free Item Available</h5></Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
            {isBarcodeBatches?.bool &&
                <BatchModal show={isBarcodeBatches?.bool}
                    onHide={() => setBarcodeBatches({ ...isBarcodeBatches, bool: false, batchArr: [] })}
                    itemBatchNo={isBarcodeBatches?.batchArr}
                    itemImage={""} addtoCart={addToCart} memberCatId="B2B" />}
        </Container>
    )
}

export default PosSaleSave;