import React,{useEffect,useState} from 'react';
import {useNavigate} from "react-router-dom";
import Header from '../Header/Header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { Image, Spinner } from 'react-bootstrap';
import Footer from '../Footer/Footer';

const AccountClick=observer(():JSX.Element=> {
  const navigate = useNavigate();
  const [isLoading,setLoading]=useState(true);
  
  return (
    <>
    {
      
      <>
      
    <div className="App" >
      <Header />      
      <div className="grid-container">
        <div className="grid-item">
          <a href='trackingorder'><div> <Image  className='accountimg' src={require("../../gsmecom/images/open-box.png")} /> </div>           
          Your Orders <h6>Track, return, or buy things again</h6></a>
         </div>
        <div className="grid-item">
        <a href='Registration'><div><Image  className='accountimg' src={require("../../gsmecom/images/lock.png")} /></div>
          Login & Security <h6>Edit login, name, and mobile number</h6></a>
        </div>
        <div className="grid-item">
          <a href=''><div><Image  className='accountimg' src={require("../../gsmecom/images/team.png")} /></div>
          Member <h6>View benefits and payment settings</h6></a>
        </div>  
        <div className="grid-item">
          <a href='youraddress'> <div><Image  className='accountimg' src={require("../../gsmecom/images/map.png")} /></div>
          Your Addresses <h6>Edit addresses for orders and gifts</h6></a>
        </div>
        <div className="grid-item">
          <a href=''><div><Image  className='accountimg' src={require("../../gsmecom/images/credit-card.png")} /></div>
          Payment Option <h6>Edit or add payment methods</h6></a>
        </div>
        <div className="grid-item">
          <a href=''> <div> <Image  className='accountimg' src={require("../../gsmecom/images/balance.png")} /></div>
          GSM pay Balance <h6>Add money to your balance</h6></a>
        </div>  
        <div className="grid-item">
          <a href=''><div> <Image  className='accountimg' src={require("../../gsmecom/images/operator.png")} /></div>
          Contact us <h6>Want to chat now or get a call from us</h6></a>
        </div>
        <div ></div>             
        <div className='vertical-space-120'></div>   
      </div>      
      <Footer/>    
    </div>
    
      </>
    }
    </>
  
  );
})

export default AccountClick;
