import React, { useEffect, useRef, useState } from "react"
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import { Autocomplete, TextField } from "@mui/material";
import NestedList from "./nesterList";
import useStores from "../../hooks";
import { removeDuplicates } from "../../common/shared/utils";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const FormsList = {
    'Admin': [],
    'Purchase': [],
    'BackOffice': [],
    'CRM': [],
    'Warehouse': [],
    'Factory': [],
    'Reports': [],
    'Outlet': [],
    'HR': [],
    'HRReports': [],
    'Accounts': [],
    'Payrolls': [],
    'N/A': []
}

export class IUserAllocation {
    empid = 0
    moduleid = 0
    modulename = ""
    formname = ""
    formurl = ""
    rights = ""
    userightsid = 0
    status = ""
}

const UserAllocation = () => {

    let empid = Number(Cookies.get('employeeId'))
    console.log(empid, 'empid');


    const { supplierMasterStore, userCreationStore, employeeStore } = useStores();

    const { userrightsSave, loandUserrights, deleteUserrights, importExportForm, loadformsname, getUserCreation } = userCreationStore;

    const checkboxOptions = [
        { name: 'add', label: 'Add', char: 'a' },
        { name: 'edit', label: 'Edit', char: 'e' },
        { name: 'delete', label: 'Delete', char: 'd' },
        { name: 'view', label: 'View', char: 'v' },
        { name: 'print', label: 'Print', char: 'p' },
        { name: 'update', label: 'Update', char: 'u' },
    ];

    const [userAllocation, setUserAllocation] = useState(new IUserAllocation())

    const [mapOfModForm, setMapofModForm] = useState(FormsList)
    const [selectedModule, setSelectedModule] = useState("")
    const [isAccessModal, setAccessModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [userRights, setRightsObj] = useState<any>({})
    const [employeeList, setEmployeeList] = useState<any>([])
    console.log(employeeList, 'employeeList>>')
    const [errors, setErrors] = useState<any>({})
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    function handleAutoComplete(name, value) {
        if (selectedModule && value) {
            setLoading(true)
            saveCallBackfunction()
        }
        setUserAllocation({ ...userAllocation, [name]: Number(value) })
        setLoading(false)
    }


    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }

    async function updateModuleData(modulename, empid) {
        const formsList = await loadformsname(modulename)
        let updatedList = formsList?.map((each) => {
            if (each?.moduleid) {
                each.moduleid = Number(each.moduleid)
                return each
            }
            return each
        })
        const userRights = await loandUserrights(empid, modulename, 'loadgrid')
        const newItemsMap: any = new Map(userRights?.map((item: any) => [item?.moduleid, item]));
        console.log(newItemsMap, updatedList, 'newItemsMap>>')
        const updatedItems = updatedList?.map(item => {
            // If there's a matching id in newItemsMap, replace the item
            if (newItemsMap?.get(item?.moduleid)) {
                item['rights'] = newItemsMap?.get(item?.moduleid)?.rights;
                item['checkedStates'] = updateCheckOptions(newItemsMap.get(item?.moduleid)?.rights);
            } else {
                item['checkedStates'] = updateCheckOptions("");
            }
            return item;
        });
        setMapofModForm(prevState => ({
            ...prevState,
            [modulename]: updatedItems,
        }));
    }

    async function handleSelectModule(modulename) {
        let error: any = {}
        if (modulename && userAllocation?.empid) {
            setSelectedModule(modulename)
            updateModuleData(modulename, userAllocation?.empid)
        } else if (!userAllocation?.empid) {
            error.empid = "Select Employee"
        } else if (modulename) {
            error.moduleid = 'Select Module'
        }
        setErrors({ ...error })
    }

    const handleCheckboxChange = (event, formDet) => {
        const { name, checked } = event.target;
        if (name === 'all') {
            // If 'All' checkbox is changed, update all checkboxes
            const newCheckedStates = {};
            checkboxOptions.forEach(option => {
                newCheckedStates[option.name] = checked;
            });
            newCheckedStates['all'] = checked;
            formDet.checkedStates = newCheckedStates;
        } else {
            // Update the individual checkbox
            if (!formDet.checkedStates) {
                formDet.checkedStates = {}
                checkboxOptions.forEach(option => {
                    formDet.checkedStates[option.name] = false;
                });
            }
            formDet.checkedStates[name] = checked;
        }

        setMapofModForm(prevState => {
            const forms = prevState[selectedModule];
            if (!forms) return prevState;

            const updatedForms = forms.map(form =>
                form?.moduleid === formDet?.moduleid ? { ...form, checkedStates: formDet?.checkedStates } : form
            );

            return {
                ...prevState,
                [selectedModule]: updatedForms
            };
        });
    };

    function updateCheckOptions(finalStr) {
        const newCheckedStates = {};
        checkboxOptions.forEach(option => {
            newCheckedStates[option.name] = (finalStr && finalStr?.length > 0) ? finalStr.includes(option.char) : false;
        });
        newCheckedStates['all'] = checkboxOptions.every(option => newCheckedStates[option.name]);
        return newCheckedStates;
    }

    function saveCallBackfunction() {
        setMapofModForm(prevState => ({
            ...prevState,
            "N/A": FormsList?.["N/A"],
            'Admin': FormsList?.Admin,
            'Purchase': FormsList?.Purchase,
            'BackOffice': FormsList?.BackOffice,
            'CRM': FormsList?.CRM,
            'Warehouse': FormsList?.Warehouse,
            'Factory': FormsList?.Factory,
            'Reports': FormsList?.Reports,
            'Outlet': FormsList?.Outlet,
            'HR': FormsList?.HR,
            'HRReports': FormsList?.HRReports,
            'Accounts': FormsList?.Accounts,
            'Payrolls': FormsList?.Payrolls,
        }))
        setSelectedModule("")
    }

    function handleUpdateSaveList() {
        const updatedUserAllocationList: any[] = []
        const forms: any[] = Object.values(mapOfModForm).flat();
        forms?.map((form) => {
            let userAllObj: any = {};
            const finalStr = checkboxOptions
                .filter(option => form?.checkedStates[option.name])
                .map(option => option.char)
                .join('');
            userAllObj = {
                empid: userAllocation?.empid,
                modulename: selectedModule,
                rights: finalStr,
                status: 'insert',
                moduleid: form?.moduleid,
                formname: form?.formname,
                formurl: ""
            }
            //   if (userAllObj?.rights?.length) {
            updatedUserAllocationList?.push(userAllObj)
            //  }
            return form
        })
        console.log(updatedUserAllocationList, 'updatedUserAllocationList>>')
        return updatedUserAllocationList
    }

    async function handleSave() {
        let empidpass;
        let userAllocationList = handleUpdateSaveList()
        if (userAllocationList?.length) {
            setLoading(true)
            // const entryNo = await getEntryNo('UserRights');
            let entryNo = 0;
            const updatedList = userAllocationList?.map(each => {
                if (each?.empid) {
                    empidpass = each?.empid;
                    return { ...each, userrightsid: Number(3) };
                }
                return each;
            });
            const status = await userrightsSave(updatedList)
            if (status === 'Success') {
                setLoading(false)
                setSuccessModal(true)
                setUserAllocation(new IUserAllocation())
                setUserAllocation(prevState => ({
                    ...prevState,
                    empid: empidpass,
                }));
                saveCallBackfunction()
            } else {
                setLoading(false)
                setFailureModal(true)
            }
        }
    }

    async function initialApiCall() {
        const userRights = await Promise.all([userCreationStore?.getUserRight('Assign_roles')])
        console.log(userRights, 'userRightschk');
        setRightsObj([userRights]);
        console.log(userRights[0]?.add, 'userRights');

        if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
            setAccessModal(false);
            setLoadModal(false);
        } else {
            setAccessModal(true);
            setLoadModal(true);
        }
        const employeeList = await getUserCreation();
        setEmployeeList([...employeeList])
    }

    const isCurrentPage = useRef(true);

    // useEffect(() => {
    //     if (isCurrentPage.current) {
    //         initialApiCall()
    //         // getImportExportItemMaster()
    //         isCurrentPage.current = false;
    //     }
    //     return () => { }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    const isCurrenPage = useRef(true)
    // const empid = Number(Cookies.get('userId'));
    const formName = 'Assign_roles';

    useEffect(() => {
 
        const fetchRights = async () => {
 
            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);
                 console.log(data,"Dataright")
                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            initialApiCall()

                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/userRights');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }
                else {
                    setLoadModal(true)
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    async function getImportExportItemMaster() {
        const tableHeadData = await importExportForm(1)
        console.log(tableHeadData, 'tableHeadData>>')
    }

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {
        return (
            <div>
                {isLoading && <div className='SpinnerBox'>
                    <Spinner animation="grow" size="sm" />
                </div>}
                <div className="container mt-6">
                    <div className="vertical-space-40"></div>
                    <div className="outletInputField inputFormBox LgInputField">
                        <div className='hrBox'>
                            <h3>Assign Roles</h3>
                        </div>
                        <div className="inputBoxLists ">
                            <div className="row">
                                <div className="col-sm-4">
                                    <NestedList
                                        mapOfModForm={mapOfModForm}
                                        selectedMod={selectedModule}
                                        setSelectedMod={handleSelectModule}
                                    />
                                </div>
                                <div className="col-sm-8">
                                    <div className="row">
                                        <div className="col-sm-12">User Rights for {selectedModule}</div>
                                        <div className="vertical-space-10"></div>
                                        <div className="col-md-12">
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo" disabled={empid !== 1}
                                                    options={removeDuplicates(employeeList, 'user_name')}
                                                    value={employeeList.userId}
                                                    // value={employeeList?.find((each) => each?.EmployeeId === userAllocation?.empid)}
                                                    getOptionLabel={(option: any) => option?.user_name}
                                                    onChange={(event, newValue) => handleAutoComplete("empid", newValue?.userId)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params}
                                                            fullWidth
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Employee..'
                                                            name='empid'
                                                        />}
                                                />
                                            </div>
                                            {errors.empid && <p style={{ color: 'red' }}>{errors.empid}</p>}
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="vertical-space-20"></div>
                                            <div className="tableListDetails" style={{ width: '100%' }}>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Detail</th>
                                                            <th scope="col">All</th>
                                                            <th scope="col">Add</th>
                                                            <th scope="col">Edit</th>
                                                            <th scope="col">Delete</th>
                                                            <th scope="col">View</th>
                                                            <th scope="col">Print</th>
                                                            <th scope="col">Update</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Object.entries(mapOfModForm)?.map((modules: [string, any[]], index) => {
                                                            return selectedModule === modules[0] && (
                                                                <>
                                                                    {modules[1]?.map((each) => {
                                                                        let eachName = each?.formname?.split('_or_')?.join('/')?.split('_and_')?.join('&')?.split('_')?.join(" ")
                                                                        return (
                                                                            <tr style={{ textAlign: 'center' }}>
                                                                                <td>{eachName}</td>
                                                                                <td><input
                                                                                    type="checkbox"
                                                                                    name="all"
                                                                                    style={{ width: '16px', height: '16px' }}
                                                                                    checked={each?.checkedStates?.all}
                                                                                    onChange={(event) => handleCheckboxChange(event, each)}
                                                                                /></td>
                                                                                {checkboxOptions.map(option => (
                                                                                    <td key={option.name}>
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            style={{ width: '16px', height: '16px' }}
                                                                                            name={option.name}
                                                                                            checked={each?.checkedStates[option?.name]}
                                                                                            onChange={(event) => handleCheckboxChange(event, each)}
                                                                                        />
                                                                                    </td>
                                                                                ))}
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {(errors.moduleid || errors?.rights) && <p style={{ color: 'red' }}>{errors.moduleid ?? errors?.rights}</p>}
                                        </div>
                                    </div>
                                    <div className="vertical-space-20"></div>
                                    <div className="btnBox">

                                        <button className="dfBtn" style={{ marginRight: '10px' }} type="submit" onClick={handleSave} disabled={!canAdd}
                                            title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
                                        > Save </button>
                                        <button className="dfBtn" >List</button>                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../../image/checked.png')} />
                            </div>
                            <h4>Succesfully Submitted</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../../image/warning.png')} />
                            </div>
                            <h4>Failed</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setFailureModal(false)}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        )
    }

}

export default UserAllocation;