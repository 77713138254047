import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { IOutletStockAnalysis } from './model';
import { Autocomplete, TextField, ThemeProvider, createTheme } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Modal } from 'react-bootstrap';
import { ISalesRegister, ISalesRegisterDetails, ISalesRegisterRequest } from './model';

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react';
import { currentPageDatas } from '../../common/shared/utils';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

export interface ExcelSheetData {
  Outlet_Name?: string;
  POS_Id?: number,
  Bill_No?: string;
  Bill_Date?: string;
  Bdate?: string;
  Bill_Type?: string,
  Customer_Type?: string,
  ctype?: string,
  M_Id?: number,
  Member_Name?: string,
  Mobile_No?: string;
  Item_Id?: number,
  Item_Name?: string,
  Categry?: string,
  HSN_Code?: string,
  Barcode?: string,
  Batch_No?: string,
  Remarks?: string,
  Qty?: number,
  Unit_Name?: string,
  Free_Qty?: number,
  Rate?: number,
  Amount?: number,
  Discount_Value?: number,
  Discount_Per?: number,
  CGSTP?: number,
  CGST_Value?: number,
  SGSTP?: number,
  SGST_Value?: number,
  Total_Value?: number,
  Invoiceamount?: number,
  Cash_Amount?: number,
  Redeem_Amount?: number,
  Sales_Return?: number,
  Card_Amount?: number,
  QR_Code_Amount?: number,
  Online_Store_Order?: number,
  Online_Bank_TR?: number,
  Employee_Name?: string,
  Create_DateTime?: string
}

const SalesRegister = observer((): JSX.Element => {

  const { salesRegisterStore, posMasterStore, invoiceStore, commonStore } = useStores();

  const { loadOutlets, loadOutlet } = commonStore;

  const [isLoading, setLoading] = useState(true);
  const [view, setView] = useState(false);
  const [print, setPrint] = useState(false);

  const [salesRegisterRequest, setSalesRegisterRequest] = useState<ISalesRegisterRequest>(new ISalesRegisterRequest())

  const [salesRegisterDetails, setSalesRegisterDetails] = useState<ISalesRegisterDetails[]>([]);
  const [posInvoiceDetails, setPosInvoiceDetails] = useState<any>({})

  console.log(posInvoiceDetails, 'posInvoiceDetails>>')
  const viewClose = () => setView(false);

  const viewShow = async (posid: any, flag: 'POSMASTER' | 'INVOICEMASTER') => {
    var res = await salesRegisterStore.loadOutletItemsDetails(posid, flag);
    setSalesRegisterDetails(res);
    setView(true);
  }

  const exportExcelDataDetails = async (excelDataDetails) => {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Results');

    worksheet.pageSetup = {
      fitToPage: true,
      fitToHeight: 1,
      fitToWidth: 1,
      orientation: 'landscape',
      paperSize: 9,
    };

  // Create subtitle strings

  const subtitleStart = `Date From : ${moment(salesRegisterRequest?.startDate).format('DD-MM-YYYY')} `;
  const subtitleEnd = `  To : ${moment(salesRegisterRequest?.endDate).format('DD-MM-YYYY')}`;
  const subtitleCombined = `Report Generated on: ${moment(new Date().toLocaleDateString()).format('DD-MM-YYYY')}`; // Static string with current date

 

    worksheet.mergeCells('A1:AP1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = "GANGA SUPER MARKET";
    titleCell.font = { size: 17, bold: true };
    titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

    worksheet.mergeCells('A2:AP2');
    const subtitleCell = worksheet.getCell('A2');
    subtitleCell.value = "SALES REGISTER " + subtitleStart + subtitleEnd;
    subtitleCell.font = { size: 13, bold: true };
    subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };


    worksheet.mergeCells('B5:E5');
    const subtitleRCell = worksheet.getCell('B5');
    subtitleRCell.value = subtitleCombined;
    subtitleRCell.font = { size: 13, bold: true };
    subtitleRCell.alignment = { horizontal: 'left', vertical: 'middle' };
    
    worksheet.addRow([]);

    const addBorders = (cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    };

    const addBordersToMergedCells = (worksheet, startCell, endCell) => {
      const startRow = parseInt(startCell[1]);
      const endRow = parseInt(endCell[1]);
      const startCol = startCell.charCodeAt(0) - 64;
      const endCol = endCell.charCodeAt(0) - 64;

      for (let row = startRow; row <= endRow; row++) {
        for (let col = startCol; col <= endCol; col++) {
          addBorders(worksheet.getCell(row, col));
        }
      }
    };

    //worksheet.mergeCells('B7:C7');
    const SubHeadCell0 = worksheet.getCell('B7');
    SubHeadCell0.value = "SNo";
    SubHeadCell0.font = { bold: true };
    SubHeadCell0.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'B7', 'B8');

    //worksheet.mergeCells('D7:E7');
    const SubHeadCell2 = worksheet.getCell('C7');
    SubHeadCell2.value = "Outlet Name";
    SubHeadCell2.font = { bold: true };
    SubHeadCell2.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'C7', 'C7');

    //worksheet.mergeCells('F7:G7');
    const SubHeadCell3 = worksheet.getCell('D7');
    SubHeadCell3.value = "Bill No";
    SubHeadCell3.font = { bold: true };
    SubHeadCell3.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'D7', 'D7');

   // worksheet.mergeCells('H7:I7');
    const SubHeadCell4 = worksheet.getCell('E7');
    SubHeadCell4.value = "Bill Date";
    SubHeadCell4.font = { bold: true };
    SubHeadCell4.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'E7', 'E7');

   // worksheet.mergeCells('J7:K7');
    const SubHeadCell5 = worksheet.getCell('F7');
    SubHeadCell5.value = "Bill Type";
    SubHeadCell5.font = { bold: true };
    SubHeadCell5.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'F7', 'F7');

    //worksheet.mergeCells('L7:M7');
    const SubHeadCell6 = worksheet.getCell('G7');
    SubHeadCell6.value = "Customer_Type";
    SubHeadCell6.font = { bold: true };
    SubHeadCell6.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'G7', 'G7');

    //worksheet.mergeCells('N7:O7');
    const SubHeadCell7 = worksheet.getCell('H7');
    SubHeadCell7.value = "Member Id";
    SubHeadCell7.font = { bold: true };
    SubHeadCell7.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'H7', 'H7');

   // worksheet.mergeCells('I7:I7');
    const SubHeadCell8 = worksheet.getCell('I7');
    SubHeadCell8.value = "Member Name";
    SubHeadCell8.font = { bold: true };
    SubHeadCell8.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'I7', 'I7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell9 = worksheet.getCell('J7');
    SubHeadCell9.value = "Mobile No";
    SubHeadCell9.font = { bold: true };
    SubHeadCell9.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'J7', 'J7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell10 = worksheet.getCell('K7');
    SubHeadCell10.value = "Item Id";
    SubHeadCell10.font = { bold: true };
    SubHeadCell10.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'K7', 'K7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell11 = worksheet.getCell('L7');
    SubHeadCell11.value = "Item Name";
    SubHeadCell11.font = { bold: true };
    SubHeadCell11.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'L7', 'L7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell12 = worksheet.getCell('M7');
    SubHeadCell12.value = "Categry";
    SubHeadCell12.font = { bold: true };
    SubHeadCell12.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'M7', 'M7');

    //worksheet.mergeCells('I7:I7');
    const SubHeadCell13 = worksheet.getCell('N7');
    SubHeadCell13.value = "HSN Code";
    SubHeadCell13.font = { bold: true };
    SubHeadCell13.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'N7', 'N7');

    const SubHeadCell14 = worksheet.getCell('O7');
    SubHeadCell14.value = "Barcode";
    SubHeadCell14.font = { bold: true };
    SubHeadCell14.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'O7', 'O7');

    const SubHeadCell15 = worksheet.getCell('P7');
    SubHeadCell15.value = " Batch No";
    SubHeadCell15.font = { bold: true };
    SubHeadCell15.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'P7', 'P7');

    const SubHeadCell16 = worksheet.getCell('Q7');
    SubHeadCell16.value = "Remarks";
    SubHeadCell16.font = { bold: true };
    SubHeadCell16.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Q7', 'Q7');

    const SubHeadCell17 = worksheet.getCell('R7');
    SubHeadCell17.value = " Qty";
    SubHeadCell17.font = { bold: true };
    SubHeadCell17.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'R7', 'R7');

    const SubHeadCell18 = worksheet.getCell('S7');
    SubHeadCell18.value = " Unit Name";
    SubHeadCell18.font = { bold: true };
    SubHeadCell18.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'S7', 'S7');

    const SubHeadCell19 = worksheet.getCell('T7');
    SubHeadCell19.value = "Free Qty";
    SubHeadCell19.font = { bold: true };
    SubHeadCell19.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'T7', 'T7');

    const SubHeadCell20 = worksheet.getCell('U7');
    SubHeadCell20.value = " Rate";
    SubHeadCell20.font = { bold: true };
    SubHeadCell20.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'U7', 'U7');

    const SubHeadCell21 = worksheet.getCell('V7');
    SubHeadCell21.value = " Amount";
    SubHeadCell21.font = { bold: true };
    SubHeadCell21.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'V7', 'V7');

    const SubHeadCell22 = worksheet.getCell('W7');
    SubHeadCell22.value = " Discount Per";
    SubHeadCell22.font = { bold: true };
    SubHeadCell22.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'W7', 'W7');

    const SubHeadCell23 = worksheet.getCell('X7');
    SubHeadCell23.value = " Discount Value";
    SubHeadCell23.font = { bold: true };
    SubHeadCell23.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'X7', 'X7');

    const SubHeadCell24 = worksheet.getCell('Y7');
    SubHeadCell24.value = " CGST Per";
    SubHeadCell24.font = { bold: true };
    SubHeadCell24.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Y7', 'Y7');

    const SubHeadCell25 = worksheet.getCell('Z7');
    SubHeadCell25.value = " CGST Value";
    SubHeadCell25.font = { bold: true };
    SubHeadCell25.alignment = { horizontal: 'center', vertical: 'middle' };
    addBordersToMergedCells(worksheet, 'Z7', 'Z7');

    const SubHeadCell26 = worksheet.getCell('AA7');
    SubHeadCell26.value = " SGST Per";
    SubHeadCell26.font = { bold: true };
    SubHeadCell26.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AA7'));
 

    const SubHeadCell27 = worksheet.getCell('AB7');
    SubHeadCell27.value = " SGST Value";
    SubHeadCell27.font = { bold: true };
    SubHeadCell27.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AB7'));

    const SubHeadCell28 = worksheet.getCell('AC7');
    SubHeadCell28.value = " IGST Per";
    SubHeadCell28.font = { bold: true };
    SubHeadCell28.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AC7'));

    const SubHeadCell29 = worksheet.getCell('AD7');
    SubHeadCell29.value = " IGST Value";
    SubHeadCell29.font = { bold: true };
    SubHeadCell29.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AD7'));

    const SubHeadCell30 = worksheet.getCell('AE7');
    SubHeadCell30.value = " Total Value";
    SubHeadCell30.font = { bold: true };
    SubHeadCell30.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AE7'));

    const SubHeadCell31 = worksheet.getCell('AF7');
    SubHeadCell31.value = "  Invoice Amount";
    SubHeadCell31.font = { bold: true };
    SubHeadCell31.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AF7'));

    const SubHeadCell33 = worksheet.getCell('AG7');
    SubHeadCell33.value = " Cash Amount";
    SubHeadCell33.font = { bold: true };
    SubHeadCell33.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AG7'));

    const SubHeadCell34 = worksheet.getCell('AH7');
    SubHeadCell34.value = " Redeem Amount";
    SubHeadCell34.font = { bold: true };
    SubHeadCell34.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AH7'));

    const SubHeadCell35 = worksheet.getCell('AI7');
    SubHeadCell35.value = "Sales Return";
    SubHeadCell35.font = { bold: true };
    SubHeadCell35.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AI7'));

    const SubHeadCell36 = worksheet.getCell('AJ7');
    SubHeadCell36.value = "Card Amount";
    SubHeadCell36.font = { bold: true };
    SubHeadCell36.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AJ7'));

    const SubHeadCell37 = worksheet.getCell('AK7');
    SubHeadCell37.value = "QR Code Amount";
    SubHeadCell37.font = { bold: true };
    SubHeadCell37.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AK7'));

    const SubHeadCell38 = worksheet.getCell('AL7');
    SubHeadCell38.value = "Online Store Order";
    SubHeadCell38.font = { bold: true };
    SubHeadCell38.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AL7'));

    const SubHeadCell39 = worksheet.getCell('AM7');
    SubHeadCell39.value = "Online_Bank_TR";
    SubHeadCell39.font = { bold: true };
    SubHeadCell39.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AM7'));

    const SubHeadCell40 = worksheet.getCell('AN7');
    SubHeadCell40.value = "Employee_Name";
    SubHeadCell40.font = { bold: true };
    SubHeadCell40.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AN7'));

    const SubHeadCell41 = worksheet.getCell('AO7');
    SubHeadCell41.value = "Create_DateTime";
    SubHeadCell41.font = { bold: true };
    SubHeadCell41.alignment = { horizontal: 'center', vertical: 'middle' };
    addBorders(worksheet.getCell('AO7'));


    // assessments.forEach((assessment, index) => {
    //   const colStart = 5 + index * 2;

    //   const testCell = worksheet.getCell(7, colStart);
    //   testCell.value = assessment.test;
    //   testCell.font = { bold: true };
    //   testCell.alignment = { horizontal: 'center', vertical: 'middle' };
    //   addBorders(testCell);

    //   const weightCell = worksheet.getCell(7, colStart + 1);
    //   weightCell.value = assessment.weight;
    //   weightCell.font = { bold: true };
    //   weightCell.alignment = { horizontal: 'center', vertical: 'middle' };
    //   addBorders(weightCell);
    // });

    const testColumnIndex = 5;
    const weightColumnIndex = 6;
    const widthInInches = 3;
    const widthInChars = widthInInches * 5.1;

    // assessments.forEach((_, index) => {
    //   worksheet.getColumn(testColumnIndex + index * 2).width = widthInChars;
    //   worksheet.getColumn(weightColumnIndex + index * 2).width = widthInChars;
    // });

    // const studentsArray = Object.entries(groupedResults).flatMap(([studentRegNo, assessments]) => {
    //   // const totalPoints = assessments.reduce((sum, assessment) => sum + assessment.ASD_Points, 0);
    //   // const totalWeight = assessments.reduce((sum, assessment) => sum + assessment.ASD_Weight, 0);
    //   // const grade = assessments.AS_Grade || '';
    //   // const testname = assessments.ASD_AssignDesc;

    //   return [{
    //     // testName: testname,
    //     Studentregno: studentRegNo,
    //     // totalScore: totalPoints,
    //     // weight: totalWeight,
    //     // Grade: grade,
    //     assessments
    //   }];
    // });

    excelDataDetails.forEach((datadet, index) => {
      console.log(datadet,'datadet');
      
      const rowIndex = index + 8;

      const seqNoCell = worksheet.getCell(rowIndex, 2);
      seqNoCell.value = index + 1;
      seqNoCell.alignment = { horizontal: 'right', vertical: 'middle' };
      addBorders(seqNoCell);

      const OutletnameNumberCell = worksheet.getCell(rowIndex, 3);
      OutletnameNumberCell.value = datadet.Outletname;
      OutletnameNumberCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(3).width = 30;
      addBorders(OutletnameNumberCell);

      const BillnoNumberCell = worksheet.getCell(rowIndex, 4);
      BillnoNumberCell.value = datadet.Billno;
      BillnoNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(4).width = 15;
      addBorders(BillnoNumberCell);

      const BilldateNumberCell = worksheet.getCell(rowIndex, 5);
      BilldateNumberCell.value = datadet.billdate;
      BilldateNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(5).width = 15;
      addBorders(BilldateNumberCell);

      const BilltypeNumberCell = worksheet.getCell(rowIndex, 6);
      BilltypeNumberCell.value = datadet.BillType;
      BilltypeNumberCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(6).width = 15;
      addBorders(BilltypeNumberCell);

      const Customer_TypeCell = worksheet.getCell(rowIndex, 7);
      Customer_TypeCell.value = datadet.CustomerType;
      Customer_TypeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(7).width = 15;
      addBorders(Customer_TypeCell);

      const midCell = worksheet.getCell(rowIndex, 8);
      midCell.value = datadet.mid;
      midCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(8).width = 12;
      addBorders(midCell);

      const membernameCell = worksheet.getCell(rowIndex, 9);
      membernameCell.value = datadet.membername;
      membernameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(9).width = 40;
      addBorders(membernameCell);

      const MobilenoCell = worksheet.getCell(rowIndex, 10);
      MobilenoCell.value = datadet.Mobileno;
      MobilenoCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(10).width = 15;
      addBorders(MobilenoCell);

      const itemidCell = worksheet.getCell(rowIndex, 11);
      itemidCell.value = datadet.itemid;
      itemidCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(11).width = 12;
      addBorders(itemidCell);
       
      const itemnameCell = worksheet.getCell(rowIndex, 12);
      itemnameCell.value = datadet.itemname;
      itemnameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(12).width = 60;
      addBorders(itemnameCell);

      const CategoryCell = worksheet.getCell(rowIndex, 13);
      CategoryCell.value = datadet.Category;
      CategoryCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(13).width = 20;
      addBorders(CategoryCell);

      const HSNCodeCell = worksheet.getCell(rowIndex, 14);
      HSNCodeCell.value = datadet.HSNCode;
      HSNCodeCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(14).width = 15;
      addBorders(HSNCodeCell);

      const BarcodeCell = worksheet.getCell(rowIndex, 15);
      BarcodeCell.value = datadet.Barcode;
      BarcodeCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(15).width = 20;
      addBorders(BarcodeCell);

      const batch_noCell = worksheet.getCell(rowIndex, 16);
      batch_noCell.value = datadet.batch_no;
      batch_noCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(16).width = 20;
      addBorders(batch_noCell);

      const RemarksCell = worksheet.getCell(rowIndex, 17);
      RemarksCell.value = datadet.Remarks;
      RemarksCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(17).width = 20;
      addBorders(RemarksCell);

      const QtyCell = worksheet.getCell(rowIndex, 18);
      QtyCell.value = datadet.Qty;
      QtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(18).width = 10;
      addBorders(QtyCell);

      const UnitnameCell = worksheet.getCell(rowIndex, 19);
      UnitnameCell.value = datadet.Unitname;
      UnitnameCell.alignment = { horizontal: 'center', vertical: 'middle' };
      worksheet.getColumn(19).width = 15;
      addBorders(UnitnameCell);

      const freeqtyCell = worksheet.getCell(rowIndex, 20);
      freeqtyCell.value = datadet.freeqty;
      freeqtyCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(20).width = 10;
      addBorders(freeqtyCell);

      const rateCell = worksheet.getCell(rowIndex, 21);
      rateCell.value = datadet.rate;
      rateCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(21).width = 10;
      addBorders(rateCell);

      const AmountCell = worksheet.getCell(rowIndex, 22);
      AmountCell.value = datadet.Amount;
      AmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(22).width = 15;
      addBorders(AmountCell);

      
      const DiscountPerCell = worksheet.getCell(rowIndex, 23);
      DiscountPerCell.value = datadet.DiscountPer;
      DiscountPerCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(23).width = 15;
      addBorders(DiscountPerCell);

      const DiscountvalueCell = worksheet.getCell(rowIndex, 24);
      DiscountvalueCell.value = datadet.Discountvalue;
      DiscountvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(24).width = 15;
      addBorders(DiscountvalueCell);


      const CGSTPCell = worksheet.getCell(rowIndex, 25);
      CGSTPCell.value = datadet.CGSTP;
      CGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(25).width = 15;
      addBorders(CGSTPCell);

      const CGSTValueCell = worksheet.getCell(rowIndex, 26);
      CGSTValueCell.value = datadet.CGSTValue;
      CGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(26).width = 15;
      addBorders(CGSTValueCell);

      const SGSTPCell = worksheet.getCell(rowIndex, 27);
      SGSTPCell.value = datadet.SGSTP;
      SGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(27).width = 15;
      addBorders(SGSTPCell);

      const SGSTValueCell = worksheet.getCell(rowIndex, 28);
      SGSTValueCell.value = datadet.SGSTValue;
      SGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(28).width = 15;
      addBorders(SGSTValueCell);

      const IGSTPCell = worksheet.getCell(rowIndex, 29);
      IGSTPCell.value = 0;
      IGSTPCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(29).width = 15;
      addBorders(IGSTPCell);

      const IGSTValueCell = worksheet.getCell(rowIndex, 30);
      IGSTValueCell.value = 0;
      IGSTValueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(30).width = 15;
      addBorders(IGSTValueCell);

      const TotalvalueCell = worksheet.getCell(rowIndex, 31);
      TotalvalueCell.value =  datadet.Totalvalue;
      TotalvalueCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(31).width = 15;
      addBorders(TotalvalueCell);

      const InvoiceamountCell = worksheet.getCell(rowIndex, 32);
      InvoiceamountCell.value =  datadet.Invoiceamount;
      InvoiceamountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(32).width = 15;
      addBorders(InvoiceamountCell);

      const CashAmountCell = worksheet.getCell(rowIndex, 33);
      CashAmountCell.value =  datadet.CashAmount;
      CashAmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(33).width = 15;
      addBorders(CashAmountCell);

      const RedeemAmountCell = worksheet.getCell(rowIndex, 34);
      RedeemAmountCell.value =  datadet.RedeemAmount;
      RedeemAmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(34).width = 15;
      addBorders(RedeemAmountCell);

      const SalesReturnCell = worksheet.getCell(rowIndex, 35);
      SalesReturnCell.value =  datadet.SalesReturn;
      SalesReturnCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(35).width = 15;
      addBorders(SalesReturnCell);

      const CardAmountCell = worksheet.getCell(rowIndex, 36);
      CardAmountCell.value =  datadet.CardAmount;
      CardAmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(36).width = 15;
      addBorders(CardAmountCell);

      const QRcodeAmountCell = worksheet.getCell(rowIndex, 37);
      QRcodeAmountCell.value =  datadet.QRcodeAmount;
      QRcodeAmountCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(37).width = 17;
      addBorders(QRcodeAmountCell);

      const OnlinestoreorderCell = worksheet.getCell(rowIndex, 38);
      OnlinestoreorderCell.value =  datadet.Onlinestoreorder;
      OnlinestoreorderCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(38).width = 17;
      addBorders(OnlinestoreorderCell);

      const OnlinebankTRCell = worksheet.getCell(rowIndex, 39);
      OnlinebankTRCell.value =  datadet.OnlinebankTR;
      OnlinebankTRCell.alignment = { horizontal: 'right', vertical: 'middle' };
      worksheet.getColumn(39).width = 17;
      addBorders(OnlinebankTRCell);

      const Employee_NameCell = worksheet.getCell(rowIndex, 40);
      Employee_NameCell.value =  datadet.Employee_Name;
      Employee_NameCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(40).width = 20;
      addBorders(Employee_NameCell);

      const CrdateCell = worksheet.getCell(rowIndex, 41);
      CrdateCell.value =  datadet.createdatetime;
      CrdateCell.alignment = { horizontal: 'left', vertical: 'middle' };
      worksheet.getColumn(41).width = 20;
      addBorders(CrdateCell);
      
      const lastRow = worksheet.lastRow?.number;
      const sumamount1 = `SUM(V7:V${lastRow})`
      const sumdiscount2 = `SUM(X7:X${lastRow})`
      const sumcgst3 = `SUM(Z7:Z${lastRow})`
      const sumsgst4 = `SUM(AB7:AB${lastRow})`
      const sumtotal5 = `SUM(AE7:AE${lastRow})`
      const suminvoice6 = `SUM(AF7:AF${lastRow})`
      const sumcash7 = `SUM(AG7:AG${lastRow})`
      const sumreadm8 = `SUM(AH7:AH${lastRow})`
      const sumreturn9 = `SUM(AI7:AI${lastRow})`
      const sumcard10 = `SUM(AJ7:AJ${lastRow})`
      const sumqrcode11 = `SUM(AK7:AK${lastRow})`
      const sumonline12 = `SUM(AL7:AL${lastRow})`
      const sumbanktr13 = `SUM(AM7:AM${lastRow})`

       worksheet.getCell(`Q${Number(lastRow) + 1}`).value = 'Total';

        worksheet.getCell(`V${Number(lastRow) + 1}`).value = { formula: sumcash7 };
        worksheet.getCell(`X${Number(lastRow) + 1}`).value = { formula: sumdiscount2 };
        worksheet.getCell(`Z${Number(lastRow) + 1}`).value = { formula: sumcgst3 };
        worksheet.getCell(`AB${Number(lastRow) + 1}`).value = { formula: sumsgst4 };
        worksheet.getCell(`AE${Number(lastRow) + 1}`).value = { formula: sumtotal5 };
        worksheet.getCell(`AF${Number(lastRow) + 1}`).value = { formula: suminvoice6 };
        worksheet.getCell(`AG${Number(lastRow) + 1}`).value = { formula: sumcash7 };
        worksheet.getCell(`AH${Number(lastRow) + 1}`).value = { formula: sumreadm8 };
        worksheet.getCell(`AI${Number(lastRow) + 1}`).value = { formula: sumreturn9 };
        worksheet.getCell(`AJ${Number(lastRow) + 1}`).value = { formula: sumcard10 };
        worksheet.getCell(`AK${Number(lastRow) + 1}`).value = { formula: sumqrcode11 };
        worksheet.getCell(`AL${Number(lastRow) + 1}`).value = { formula: sumonline12 };
        worksheet.getCell(`AM${Number(lastRow) + 1}`).value = { formula: sumbanktr13 };
   

 
      
      // const finalScoreCell = worksheet.getCell(rowIndex, 41);
      // finalScoreCell.alignment = { horizontal: 'center', vertical: 'middle' };
      // addBorders(finalScoreCell);
      // worksheet.getColumn(41).width = 15;
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, `Sales_Register_Report${new Date().toISOString().split('T')[0]}.xlsx`);

  }
  const handleSalesRegister = async (e) => {
    e.preventDefault();
    setLoading(true)
    await salesRegisterStore.loadOutletItems(salesRegisterRequest);
    setLoading(false)
  }

  async function createExportExcelObj() {
    const salesRegisterData = await salesRegisterStore.loadOutletItemsExport(salesRegisterRequest);
    console.log(salesRegisterData, 'salesRegisterData');
    
    exportExcelDataDetails(salesRegisterData)
    // let excelArr: ExcelSheetData[] = stockReportDetails?.map((stockReport) => {
    //   const excelObj: ExcelSheetData = {
    //     Type: stockReport?.type,
    //     Bill_No: stockReport?.billno,
    //     Date: stockReport?.billdate,
    //     Sales_Person: stockReport?.employeename,
    //     No_Of_Items: stockReport?.nos,
    //     Bill_Value: stockReport?.billvalue,
    //     T_GST_Amount: stockReport?.netvalue,
    //   }
    //   return excelObj;
    // })
    // exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Sales Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Sales Register ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const printClose = () => setPrint(false);
  const printShow = async (type, posid) => {
    if (type === "Invoice") {
      const invoiceDetails = await invoiceStore?.getInvoiceBillPrint(posid)
      if (invoiceDetails) {
        setPosInvoiceDetails(invoiceDetails)
      }
    } else {
      const invoiceDetails = await posMasterStore.getInvoiceDetails(posid)
      if (invoiceDetails) {
        setPosInvoiceDetails(invoiceDetails)
      }
    }
    setPrint(true)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalesRegisterRequest({ ...salesRegisterRequest, [name]: value })
  }

  const handleOutlets = (name: any, event: object, val: any) => {
    if (val != null) {
      setSalesRegisterRequest({ ...salesRegisterRequest, outletId: val.outletid })
      Cookies.set('outletName', val.outletName);
    } else {
      salesRegisterRequest.outletId = 0;
    }
  }

  async function fetchOultetItems() {
    await loadOutlet();
    salesRegisterRequest.outletId = Number(Cookies.get('outletId'));
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchOultetItems();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  function calcRoundOff() {
    const total = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const decimalValue = Math.floor(total);
    const roundOff = total - decimalValue;
    const roundoffValue = total % 1 >= 0.5 ? `- ${roundOff?.toFixed(2)}` : `+ ${roundOff?.toFixed(2)}`
    return roundoffValue
  }

  function calcTotalAmt() {
    const total: number = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const roundedTotal = Math.round(total);
    const totalAmount = roundedTotal + Number(posInvoiceDetails?.deliverycharge);

    return totalAmount;
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (salesRegisterStore?.outletItemDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(salesRegisterStore?.outletItemDetails)
    }
  }, [salesRegisterStore?.outletItemDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(salesRegisterStore?.outletItemDetails, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  const convertArrayToMap = (students: any[]): Map<string, any[]> => {
    return students?.reduce((map, student) => {
      if (!map.has(student?.taxper)) {
        map.set(student?.taxper, []);
      }
      map.get(student?.taxper)!.push(student);
      return map;
    }, new Map<string, any[]>());
  };

  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Sales Register</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='ItemInwardInputBox'>
                    <div className='row'>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>From Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="startDate" onChange={handleInputChange} value={salesRegisterRequest?.startDate}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <div className='inputBox'>
                          <label>To Date <span>*</span></label>
                          <input type="date" style={{ width: "80%" }}
                            name="endDate" onChange={handleInputChange} value={salesRegisterRequest?.endDate}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                        </div>
                        <div className='vertical-space-20'></div>
                      </div>
                      <div className='col-sm-3' style={{ marginTop: '17px' }}>
                        <div className='inputBox'>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadOutlets}
                            getOptionLabel={(option: any) => option.outletName}
                            onChange={(event, val) => handleOutlets('outletId', event, val)}
                            value={loadOutlets.find((option) => option.outletName === Cookies.get('outletName'))}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '35ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Outlet Name..'
                                name='outletName'
                              />}
                          />
                        </div>
                        {/* <div className='vertical-space-10'></div> */}
                      </div>
                      <div className="col-sm-5">
                        <div className='btnBox' style={{ marginTop: '23px' }}>
                          <button className='secondaryBtn'
                            onClick={() => createExportExcelObj()}
                          >Excel</button>
                          <button className='secondaryBtn' type='submit' onClick={handleSalesRegister}>Generate</button>
                        </div>
                      </div>
                    </div>
                    <div className='vertical-space-10'></div>
                    <div className='row'>
                      <div className='col-sm-5'>
                        <div className='btnBox'>
                          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{salesRegisterStore?.outletItemDetails?.length} Records</button>
                        </div>
                      </div>
                      <div className=' col-sm-7' >
                        <Stack spacing={2}>
                          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                        </Stack>
                      </div>
                    </div>
                    <div className='tableListDetails'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th scope="col">Bill No</th>
                            <th scope="col"> Date</th>
                            <th scope="col">Counter No  </th>
                            <th scope="col">Sales Person </th>
                            <th scope="col">No Of Items</th>
                            <th scope="col">Bill Value</th>
                            <th scope="col">Net Amount</th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData?.length > 0 &&
                            currentPageData?.map((val, key) => {
                              console.log(currentPageData, 'currentPageData>>')
                              return (
                                <tr key={key}>
                                  <td>{val.type}</td>
                                  <td>{val.billno}</td>
                                  <td>{moment(val.billdate).format('DD-MMM-YYYY')} </td>
                                  <td>5</td>
                                  <td>{val.employeename}</td>
                                  <td>{val.nos} </td>
                                  <td>{val.billvalue} </td>
                                  <td>{val.netvalue}</td>
                                  <td><button onClick={(e: any) => viewShow(val.posid, 'INVOICEMASTER')} className='tablemodeltext'>Invoice</button></td>
                                  <td><button onClick={(e: any) => viewShow(val.posid, 'POSMASTER')} className='tablemodeltext'>POS</button></td>
                                  <td><button onClick={() => printShow(val.type, val?.posid)} className='tablemodeltext'>Print</button></td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    <Modal show={view} onHide={viewClose} className='PriceHistoryModel'>
                      <Modal.Header closeButton>
                        <Modal.Title>View</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">ItemName</th>
                                <th scope="col"> Qty</th>
                                <th scope="col">Rate </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                salesRegisterDetails.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.itemname}</td>
                                      <td>{val.qty}</td>
                                      <td>{val.rate}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal show={print} onHide={printClose} className='PriceHistoryModel'>
                      <Modal.Header closeButton>
                        <Modal.Title>Print</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div id='printablediv' className='printablediv'>

                          <div className='Details Success'>
                            <div id='printablediv' className='printablediv'>
                              <div className="address">
                                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Ganga Super Market</h6>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Namakkal, 33 - Tamil Nadu, 637002</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>GSTIN / UIN: 33BZUPS6837H1ZL</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>FSSAI: 10020042007183</p>
                              </div>
                              <div className="taxinvoice">
                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Tax Invoice</span>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.salesorderno}</span>
                                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.billdate}</span>
                                </div>
                                <p style={{ fontFamily: 'system-ui', fontWeight: '700', marginTop: '12px' }} className='invoiceSpan'>{posInvoiceDetails?.paymentmode}</p>
                              </div>
                              <div className="Customer" style={{ marginTop: '0px' }}>
                                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Customer:{posInvoiceDetails.memberName}</h6>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Dr.No.: {posInvoiceDetails?.doorNo}{" "}{posInvoiceDetails.area}</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{`${posInvoiceDetails?.city}, ${posInvoiceDetails?.state}, ${posInvoiceDetails?.pinCode}`}</p>
                                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Mobile: {posInvoiceDetails?.mobileNo}</p>
                              </div>
                              <hr />
                              <h5 className="address">SKU - HSNC - Item Description</h5>
                              <table className="itemtable"  >
                                <thead>
                                  <tr >
                                    <th className="heading">Item</th>
                                    <th className="heading">Qty</th>
                                    <th className="heading">MRP</th>
                                    <th className="heading">Price</th>
                                    <th className="heading">Disc</th>
                                    <th className="heading">Total</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {convertArrayToMap(posInvoiceDetails?.posbilldetailsprint)?.size > 0 ?
                                    Array.from(convertArrayToMap(posInvoiceDetails?.posbilldetailsprint))?.map(([key, value], ind) => {
                                      return (
                                        <>
                                          <div style={{ color: 'black', padding: '10px 0px 0px 16px', fontWeight: '700', fontSize: '15px' }}>
                                            {`${ind + 1}) CGST ${key ?? 0}% & SGST ${key ?? 0}%`}
                                          </div>
                                          {value?.map((val, index) => {
                                            return val?.qty > 0 && (
                                              <tr key={index}>
                                                <td style={{ padding: '0px 15px' }}>
                                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.hsncode}</div>
                                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.itemid === 0 ? "Free" : val.itemname}</div>
                                                </td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.qty}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.mrp}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.sellingPrice}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.discountvalue}</td>
                                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.amount}</td>
                                              </tr>
                                            )
                                          })}
                                        </>
                                      )
                                    }) : null}
                                </tbody>
                              </table>
                              <hr style={{ margin: 0 }} />
                              <table className="amounttable">
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Qty: {posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.qty!, 0)}</td>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Items: {posInvoiceDetails?.posbilldetailsprint?.length}</td>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Delivery Charges</td>
                                  <td></td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.deliverycharge}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Round off:</td>
                                  <td></td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcRoundOff()}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Redeem Amount</td>
                                  <td> </td>
                                  <td></td>
                                  <td style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.redeemPointsamt}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0 }} className="tablesummeryvalue">Amount Paid</td>
                                  <td> </td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcTotalAmt()?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td style={{ padding: 0, }} className="tablesummeryvalue">You Saved Today</td>
                                  <td> </td>
                                  <td></td>
                                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>
                                    {(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.discountvalue!, 0 + Number(posInvoiceDetails?.offerdiscount)))?.toFixed(2)}
                                  </td>
                                </tr>
                              </table>
                              <table className="itemtable"  >
                                <thead>
                                  <tr>
                                    <th className="heading">S.No</th>
                                    <th className="heading">Taxable <br /> amount</th>
                                    <th className="heading">CGST</th>
                                    <th className="heading">SGST</th>
                                    <th className="heading">IGST</th>
                                    <th className="heading">CESS</th>
                                    <th className="heading">Total <br /> amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {posInvoiceDetails?.posbilltaxdetailsprint?.map((val, key) => {
                                    return (
                                      <tr key={key}>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{key + 1}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount - val?.taxvalue)?.toFixed(2)}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.cgstvalue}` + `(${val?.cgstper}%)`}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.sgstvalue}` + `(${val?.sgstper}%)`}</td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount)?.toFixed(2)}</td>
                                      </tr>
                                    )
                                  })
                                  }
                                  <tr style={{ margin: '1rem 0', color: 'inherit', border: 0, borderTop: '1px solid rgb(11,56,93, 0.25)' }}></tr>
                                  <tr>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + (v?.amount! - v.taxvalue!), 0))?.toFixed(2)}</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.cgstvalue!, 0)}</td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.sgstvalue!, 0)}</td>
                                    <td></td>
                                    <td></td>
                                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0))?.toFixed(2)}</td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="bottom">
                                <h4>Thank You</h4>
                                <p>Customer Care: 9043426746</p>
                              </div>
                            </div>
                          </div>

                          <div className="bottomDate">
                            <p>{moment().format('DD-MM-YYYY')}</p>
                          </div>

                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </>

      }
    </>

  )
})

export default SalesRegister;
