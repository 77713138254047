import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Accordion, Button, Image, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Form, Modal } from 'react-bootstrap';
import useStores from '../../hooks';
import { IPurchaseOrderSaveModal, ProductItemList, PurchaseOrderDetails, PurchaseOrderSchedule, PurchaseOrderTeamsandcondition } from './modal';
import { PurchaseSaveValidation, ScheduleValidation } from './validation';
import Cookies from 'js-cookie';
import AutoComplete from '../../common/shared/autoComplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';



function PurchaseOrder() {
    const { purchaseOrderStore, indentStore, supplierMasterStore, outletStores, warehouseStores } = useStores()

    const { purchOrdId } = useParams();
    const navigate = useNavigate();
    const { loadSupplierName, loadSameAddress, loadSupplierItemList } = purchaseOrderStore;

    const [loader, setLoader] = useState(true);
    const [successModal, setSuccessModal] = useState(false);
    const [failureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [purchaseOrderSave, setPurchaseOrderSave] = useState<IPurchaseOrderSaveModal>(new IPurchaseOrderSaveModal())
    const [purchaseOrderSchedule, setPurchaseOrderSchedule] = useState<PurchaseOrderSchedule>(new PurchaseOrderSchedule())
    const [purchaseOrderSchArr, setPurchasePrderSchArr] = useState<PurchaseOrderSchedule[]>(new Array<PurchaseOrderSchedule>())

    const [supplierName, setSupplierName] = useState<any[]>([]);
    const [itemLists, setItemLists] = useState<ProductItemList[]>([])
    const [termsAndConditons, setTermsAndCondition] = useState<string>('');

    const [supplierList, setSupplierList] = useState<any[]>([])
    const [outletList, setOutletList] = useState<any[]>([])
    const [warehouseList, setWarehouseList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({})

    const [purchaserOrderError, setPurchaseOrderError] = useState<any>({})

    const [show, setShow] = useState(false);
    const [invoice, setInvoice] = useState({ itemid: '', qty: 0, appNo: '', show: false });

    const [despatchName, setDespatchName] = useState({ outName: '', wareName: '' })
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);
    const handleSuccessModalClose = () => {
        navigate('/Admin/PurchaseOrder/PurchaseOrderList')
    }

    const invoiceShow = (itemid, qty, show, appNo) => {
        let filterArr = purchaseOrderSave?.purchaseOrderSchedule?.filter((each) => each?.appNo === appNo && each?.itemid === itemid)
        if (filterArr?.length) {
            setPurchasePrderSchArr(prevArray => [...prevArray, ...filterArr!]);
        }
        setInvoice({ ...invoice, itemid: itemid, qty: qty, show: show, appNo: appNo })
    };

    function handleInputChange(e) {
        const { name, value } = e.target;
        setPurchaseOrderSave({ ...purchaseOrderSave, [name]: value })
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
      }
    

    function hanldeScheduleChange(e) {
        const { name, value } = e.target;
        setPurchaseOrderSchedule({ ...purchaseOrderSchedule, [name]: value })
    }

    function updatePurchaseOrderSchedule(itemid, qty, appNo) {
        let error: any = {}
        error = ScheduleValidation(purchaseOrderSchedule)

        const currentQty = purchaseOrderSchArr?.reduce((a, v) => a + Number(v?.schedule_qty), 0)

        if (currentQty + Number(purchaseOrderSchedule?.schedule_qty!) <= qty) {
            if (Object.keys(error)?.length === 0) {

                if (!purchaseOrderSave?.purchaseOrderSchedule?.length) {
                    purchaseOrderSave!.purchaseOrderSchedule! = [];
                }

                let object = Object.assign({}, purchaseOrderSchedule)
                object.schedule_no = (purchaseOrderSchArr?.length + 1);
                object.itemid = itemid;
                object.gsM_Purchase_Order_Details_Id = 0
                object.appNo = appNo;

                setPurchasePrderSchArr(prevArray => [...prevArray, object]);

                purchaseOrderSave?.purchaseOrderSchedule?.push(object!)
                setPurchaseOrderSave(purchaseOrderSave)
                setPurchaseOrderSchedule(new PurchaseOrderSchedule())
                setErrors({});
            } else {
                setErrors(error);
            }
        } else {
            error = {
                add_error: 'Schedule Qty to not be more than Approved Qty.'
            }
            setErrors(error);
        }
    }

    const handleRadioChange = async (event, supplier) => {
        if (supplier?.supplierid) {
            const itemList = await loadSupplierItemList(supplier?.suppliername);

            if (itemList?.length) {
                setItemLists([...itemList])
            }
        }
        setPurchaseOrderSave({ ...purchaseOrderSave, supplier_name: supplier?.suppliername, supplier_id: supplier?.supplierid })
    }

    async function handleCheckAddress(e) {
        if (purchaseOrderSave?.isChecked) {
            setPurchaseOrderSave({
                ...purchaseOrderSave,
                despatch_to: '',
                despatch_address1: '',
                despatch_address2: '',
                despatch_address3: '',
                despatch_city: '',
                despatch_state: '',
                isChecked: false
            })
        } else {
            if (purchaseOrderSave?.supplier_name) {
                setLoader(true);
                updateDespatchAddress('supplier', purchaseOrderSave?.supplier_id)
                setLoader(false)
            } else {
                modalClose()
                alert('Select pending supplier first')
            }
        }
    }

    async function updateDespatchAddress(type, id) {
        if (type && id) {
            setLoader(true);
            let address: any = {};
            if (type == 'outlet' && outletList?.length) {
                let outletAddress = outletList?.find(each => each?.outletId === id);
                outletAddress['Name'] = outletAddress?.outletName;
                address = outletAddress;
                setDespatchName({ ...despatchName, outName: outletAddress?.Name, wareName: '' })
            } else if (type == 'warehouse' && warehouseList?.length) {
                let wareAddress = warehouseList?.find(each => each?.warehouseId == id);
                wareAddress['Name'] = wareAddress?.warehouseName;
                address = wareAddress;
                setDespatchName({ ...despatchName, outName: '', wareName: wareAddress?.Name })
            } else if (type == 'supplier' && supplierList?.length) {
                setDespatchName({ ...despatchName, outName: '', wareName: '' })
                let suppAddress: any = {};
                suppAddress['Name'] = 'Ganga Super Market';
                suppAddress['doorNo'] = '3 / 824 A';
                suppAddress['area'] = 'Thuraiyur Road, Near Ponni Gas Godown';
                suppAddress['city'] = 'Namakkal';
                suppAddress['state'] = 'Tamil Nadu';
            }
            setPurchaseOrderSave({
                ...purchaseOrderSave,
                despatch_to: address?.Name,
                despatch_address1: address?.doorNo ?? address?.doorNoStreet,
                despatch_address2: address?.area,
                despatch_address3: address?.despatchaddress3,
                despatch_city: address?.city,
                despatch_state: address?.state,
                isChecked: type == 'supplier' ? true : false,
            })
            setLoader(false)
        }
    }

    async function handleCheckItems(items: any) {

        setItemLists((prevArray) => prevArray?.map((obj) =>
            obj.rowno === items?.rowno ? { ...obj, isChecked: obj?.isChecked ? false : true } : obj
        ))

        let myArray: any = Object.assign([], purchaseOrderSave?.purchaseOrderDetails)
        const index = myArray?.findIndex(obj => obj.rowno === items.rowno);

        if (items?.isChecked) {
            const newArray = [...myArray];
            newArray.splice(index, 1);
            purchaseOrderSave!.purchaseOrderDetails! = newArray;
            setPurchaseOrderSave(purchaseOrderSave);
        } else {
            let itemObj: PurchaseOrderDetails = {
                approved_qty: items?.approval_qty?.toString() as any,
                purchase_qty: items?.indent_qty?.toString() as any,
                purchase_rate: items?.rate?.toString() as any,
                itemid: Number(items?.itemid),
                discount_per: items?.discount?.toString() ?? '0',
                purchase_approval_no: items?.purchase_approval_no?.toString(),
                approvalid: Number(items?.rowno),
                tax_per: items?.taxper?.toString(),
                tax_amount: items?.tax_total?.toString(),
            }
            if (!purchaseOrderSave?.purchaseOrderDetails) {
                purchaseOrderSave!.purchaseOrderDetails! = []
            }
            purchaseOrderSave?.purchaseOrderDetails?.push(itemObj)
            setPurchaseOrderSave(purchaseOrderSave)
        }

    }

    function handleAddTermCondition() {
        if (termsAndConditons != '') {
            if (!purchaseOrderSave?.purchaseOrderTeamsandcondition) {
                purchaseOrderSave!.purchaseOrderTeamsandcondition! = []
            }

            let temObj: PurchaseOrderTeamsandcondition = {
                gsM_Purchase_Order_Details_Id: 0,
                ternsdetails: termsAndConditons
            }
            purchaseOrderSave?.purchaseOrderTeamsandcondition?.push(temObj)
            setPurchaseOrderSave(purchaseOrderSave)
            setTermsAndCondition('')
        }
    }

    async function savePurchaseOrder() {
        let saveObj = Object.assign({}, purchaseOrderSave);
        // saveObj!.purchase_Order_No = '0';
        saveObj!.sub_total = purchaseOrderSave?.sub_total?.toString() as any;
        saveObj!.tax_amount = purchaseOrderSave?.tax_amount?.toString() as any;
        let entered_by = Number(Cookies.get('employeeId'));
        saveObj!.entered_by = entered_by ?? 0;
        saveObj!.special_instruction = '';
        saveObj!.supplier_ref = '';
        saveObj!.discount = purchaseOrderSave?.discount ? purchaseOrderSave?.discount?.toString() as any : '0';
        saveObj!.packing_charges = purchaseOrderSave?.packing_charges ? purchaseOrderSave?.packing_charges?.toString() as any : '0';
        saveObj!.despatch_address2 = purchaseOrderSave?.despatch_address2 ?? '';
        saveObj!.despatch_address3 = purchaseOrderSave?.despatch_address3 ?? '';

        // saveObj!.round_off = Number(purchaseOrderSave?.round_off)?.toString()

        let error: any = {};

        error = PurchaseSaveValidation(saveObj)
        setPurchaseOrderError(error)

        if (Object.keys(error).length === 0) {
            setLoader(true)
            const status = await purchaseOrderStore.savePurchaseOrder(saveObj)
            if (status?.statusMessage === "Success") {
                setLoader(false)
                setSuccessModal(true)
            } else {
                setLoader(false)
                setFailureModal(true);
            }
        }
    }

    function handleCancelClick() {
        navigate('/Admin/PurchaseOrder/PurchaseOrderList')
    }

    function handleCloseSchedule() {

        let error: any = {}

        const schQtyTotal = purchaseOrderSchArr?.reduce((acc, obj) => acc + Number(obj?.schedule_qty), 0)

        if (schQtyTotal === invoice?.qty) {
            setInvoice({ ...invoice, itemid: '', qty: 0, appNo: '', show: false })
            setPurchasePrderSchArr([]);
        } else {
            error = {
                add_error: 'Total schedule qty, is not equal to approval qty.'
            }
            setErrors(error);
        }
    }

    const itemListData = React.useMemo(() => {
        if (itemLists?.length && purchaseOrderSave) {
            let sub_total = itemLists?.reduce((acc, obj) => acc + (obj?.isChecked ? obj.item_total : 0), 0);
            let tax_amount = itemLists?.reduce((acc, obj) => acc + (obj?.isChecked ? obj.tax_total : 0), 0);

            purchaseOrderSave!.sub_total! = sub_total
            purchaseOrderSave!.tax_amount = tax_amount

            let net_amount = sub_total + tax_amount + Number(purchaseOrderSave?.packing_charges! ?? 0) - Number(purchaseOrderSave?.discount ?? 0)

            // const decimalValue = Math.floor(net_amount);
            // const roundOff = net_amount - decimalValue;

            const decimalValue = Math.round(net_amount);
            const roundOff = net_amount - decimalValue;

            // const roundoffValue = net_amount % 1 >= 0.5 ? `+ ${Math.abs(roundOff)?.toFixed(2)}` : `- ${Math.abs(roundOff)?.toFixed(2)}`

            const roundoffValue = Math.abs(roundOff)?.toFixed(2);

            purchaseOrderSave!.round_off = roundoffValue;

            purchaseOrderSave!.net_amount = Math.round(net_amount)?.toString() as any
            setPurchaseOrderSave(purchaseOrderSave)
        }
    }, [itemLists, purchaseOrderSave?.packing_charges, purchaseOrderSave?.discount])

    async function fetchSupplier() {
        if (purchOrdId) {

        }
        setLoader(false);
        const [entryNo, supplierMaster, supplierDetails, warehouseList, outletList] = await Promise.all([
            indentStore.getEntryNo('PurchaseOrder'), supplierMasterStore.getSupplierMaster(),
            loadSupplierName(), warehouseStores.getWarehouse(), outletStores.getOutlets({ searchOption: '', searchValue: '' }),
        ])
        if (entryNo) {
            setPurchaseOrderSave({ ...purchaseOrderSave, purchase_Order_No: (Number(entryNo?.entrynumber) + 1)?.toString() })
        }
        if (supplierMaster?.length) {
            setSupplierList([...updateSupplierList(supplierMaster)]);
        }
        setWarehouseList([...warehouseList])
        if (outletList?.length) {
            setOutletList([...updateOutletList(outletList)]);
        }
        setSupplierName(supplierDetails)
    }

    function updateOutletList(outletList: any[]) {
        let outlerRetArr: any[] = [];
        if (outletList?.length) {
            let addressArr: any[] = [];
            outletList?.forEach((each, index) => {
                addressArr = each?.address?.split(', ')
                if (addressArr?.length) {
                    each['doorNo'] = addressArr[0];
                    each['area'] = addressArr[1];
                    each['city'] = addressArr[2];
                    each['state'] = addressArr[3];
                    each['country'] = 'India';
                    each['pincode'] = addressArr[4];
                }
                return each;
            })
            outlerRetArr = outletList;
        }
        return outlerRetArr;
    }

    function updateSupplierList(supplierList) {
        let supplierArrList: any[] = [];
        if (supplierList?.length) {
            let addressArr: any[] = [];
            supplierList?.forEach((each, index) => {
                addressArr = each?.address?.split(',')
                if (addressArr?.length) {
                    each['doorNo'] = addressArr[0];
                    each['area'] = addressArr[1];
                    each['city'] = addressArr[2];
                    each['state'] = addressArr[3];
                    each['country'] = addressArr[4];
                }
                return each;
            })
            supplierArrList = supplierList
        }
        return supplierArrList;
    }

    const isCurrentPage = useRef(true)

    // useEffect(() => {
    //     if (isCurrentPage?.current) {
    //         fetchSupplier()
    //         isCurrentPage.current = false;
    //     }
    //     return () => { }
    // }, []);

    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Purchase_order';
  
    useEffect(() => {
  
      const fetchRights = async () => {
  
        try {
          const data = await supplierMasterStore.fetchRights(empid, formName);
   
          if (Array.isArray(data) && data.length > 0) {
            const specificFieldValue = data[0].sts;
            const rightsValue = data[0].rights;
            const lettersArray = rightsValue.split('');
            if (specificFieldValue === 1) {
              if (isCurrenPage.current) {
                fetchSupplier()
                isCurrenPage.current = false
                if (lettersArray.includes('a')) {
                  setCanAdd(true);
                } else {
                    navigate('/Admin/PurchaseOrder/PurchaseOrderList')
                }
                if (lettersArray.includes('e')) {
                  setCanEdit(true);
                }
                // if (lettersArray.includes('v')) {
                //   setCanView(true); 
                // }     
                // if (lettersArray.includes('p')) {
                //   setCanPrint(true); 
                // }       
                // if (lettersArray.includes('u')) {
                //   setCanUpdate(true); 
                // }                     
              }
            } else if (specificFieldValue === 0) {
              setLoadModal(true)
            }
          }
         
        } catch (error) {
          console.error("Error fetching rights:", error);
        }
      };
      fetchRights();
    }, [empid, formName]);
  
    const handleFailureModalClose = () => setFailureModal(false);
  
    if (isLoadModal) {
      return (
        <div className="container">
          {isLoadModal && <h1>Access Denied</h1>}
          <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/warning.png')} />
                </div>
                <h4>Access Denied</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
    else { 

    return (
        <>
            {loader ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Purchase Order</h3>
                    </div>
                    <div className='inputBoxLists accordionLists'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Purchase Order Info</Accordion.Header>
                                <Accordion.Body>
                                    <div className='ItemInwardInputBox'>
                                        <div className='row'>
                                            <div className='col-sm-7'>
                                                <div className='row'>
                                                    <div className='col-sm-4' >
                                                        <div className='inputBox'>
                                                            <label>PO. No<span>*</span></label>
                                                            <input value={purchaseOrderSave?.purchase_Order_No} type="text" style={{ width: "100%" }}
                                                                placeholder="PO. No" disabled></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <div className='inputBox'>
                                                            <label> Date <span>*</span></label>
                                                            <input value={purchaseOrderSave!.purchase_Order_Date} onChange={handleInputChange} name='purchase_Order_Date' style={{ width: "100%" }}
                                                                placeholder="DD-MMM-YYYY" data-date="" type="date"
                                                                data-date-format="DD MM YYYY"></input>
                                                            {purchaserOrderError.purchase_Order_Date && <p style={{ color: 'red' }}>{purchaserOrderError.purchase_Order_Date}</p>}
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-sm-6' >
                                                        <div className='inputBox'>
                                                            <label>Supplier <span>*</span></label>
                                                            <input value={purchaseOrderSave?.supplier_name} name='supplier_name' style={{ width: "100%" }}
                                                                placeholder="Supplier" readOnly data-date="" ></input>
                                                            {/* {purchaserOrderError.supplier_id && <p style={{ color: 'red' }}>{purchaserOrderError.supplier_id}</p>} */}
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-sm-3' style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <button className='dfBtn' style={{ width: '75%' }} onClick={modalShow}>Despatch To</button>
                                                        <div className='vertical-space-5'></div>
                                                        {purchaserOrderError.despatch_to && <p style={{ color: 'red' }}>{purchaserOrderError.despatch_to}</p>}
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <div className='inputBox'>
                                                            <label>Remarks </label>
                                                            <textarea value={purchaseOrderSave?.remarks} name='remarks' className='' onChange={handleInputChange} style={{ width: "100%" }}
                                                                placeholder='Remarks..'></textarea>
                                                            {purchaserOrderError.remarks && <p style={{ color: 'red' }}>{purchaserOrderError.remarks}</p>}
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-1'></div>
                                            <div className='col-sm-4'>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"></th>
                                                                <th scope="col">Pending Supplier</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {supplierName?.map((supplier, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td><input checked={supplier?.suppliername == purchaseOrderSave?.supplier_name} type='radio'
                                                                            name='radio' onChange={(e) => handleRadioChange(e, supplier)} /></td>
                                                                        <td>{supplier?.suppliername}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {purchaserOrderError?.supplier_id && <p style={{ color: 'red' }} >{purchaserOrderError?.supplier_id}</p>}
                                                <div className='vertical-space-10'></div>
                                            </div>
                                        </div>
                                        <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Despatch Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='outletInputField inputFormBox '>
                                                    <div className='inputBoxLists'>
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <div className='inputradio'>
                                                                    <input onChange={handleCheckAddress} checked={purchaseOrderSave?.isChecked} type='checkbox' value={'on'} name='SameAddress' aria-label="lorem ipsum"></input> &nbsp;
                                                                    <label>Billing Address</label>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-md-6'></div>
                                                            <div className='col-md-6'>
                                                                <div className='inputBox'>
                                                                    <label>Outlet Name<span>*</span></label>
                                                                    <AutoComplete disabled={purchaseOrderSave?.isChecked} clsName='full-width'
                                                                        value={despatchName?.outName} options={outletList} emitOption={(option) => updateDespatchAddress('outlet', option?.outletId)}
                                                                        placeholder='Outlet Name' getOptionLabel='outletName' />
                                                                    <div className='vertical-space-20'></div>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <div className='inputBox'>
                                                                    <label>Warehouse Name<span>*</span></label>
                                                                    <AutoComplete disabled={purchaseOrderSave?.isChecked} clsName='full-width'
                                                                        value={despatchName?.wareName} options={warehouseList} emitOption={(option) => updateDespatchAddress('warehouse', option?.warehouseId)}
                                                                        placeholder='Warehouse Name' getOptionLabel='warehouseName' />
                                                                    <div className='vertical-space-20'></div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>Despatch To<span>*</span></label>
                                                                    <input disabled={purchaseOrderSave?.isChecked}
                                                                        value={purchaseOrderSave?.despatch_to}
                                                                        type="text" style={{ width: "100%" }}
                                                                        onChange={handleInputChange} name='despatch_to'
                                                                        placeholder="Despatch Door No" ></input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>Despatch Door No<span>*</span></label>
                                                                    <input disabled={purchaseOrderSave?.isChecked}
                                                                        value={purchaseOrderSave?.despatch_address1}
                                                                        type="text" style={{ width: "100%" }}
                                                                        onChange={handleInputChange} name='despatch_address1'
                                                                        placeholder="Despatch Door No" ></input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label> Area<span>*</span></label>
                                                                    <input type="text" style={{ width: "100%" }}
                                                                        disabled={purchaseOrderSave?.isChecked}
                                                                        value={purchaseOrderSave?.despatch_address2}
                                                                        placeholder="Area" onChange={handleInputChange}
                                                                        name='despatch_address2'></input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label> City<span>*</span></label>
                                                                    <input type="text" style={{ width: "100%" }}
                                                                        disabled={purchaseOrderSave?.isChecked} value={purchaseOrderSave?.despatch_city}
                                                                        placeholder="City" onChange={handleInputChange} name='despatch_city'>
                                                                    </input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>State<span>*</span></label>
                                                                    <input type="text" style={{ width: "100%" }}
                                                                        disabled={purchaseOrderSave?.isChecked} value={purchaseOrderSave?.despatch_state}
                                                                        placeholder="State" onChange={handleInputChange} name='despatch_state' >
                                                                    </input>
                                                                </div>
                                                                <div className='vertical-space-20'></div>
                                                            </div>
                                                            <div className='col-sm-6' ></div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label> Contact Person <span>*</span></label>
                                                                    <input type="text" style={{ width: "100%" }}
                                                                        value={purchaseOrderSave?.contactperson}
                                                                        onChange={handleInputChange} name='contactperson' placeholder="Contact Person" >
                                                                    </input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label> Contact No<span>*</span></label>
                                                                    <input type="number" style={{ width: "100%" }}
                                                                        value={purchaseOrderSave?.contactno}
                                                                        max={10}
                                                                        onChange={(e) => e.target.value?.length < 11 && handleInputChange(e)} name='contactno' placeholder="Contact No" >
                                                                    </input>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Item Info</Accordion.Header>
                                <Accordion.Body>
                                    <div className='container-fluid'>
                                        <div className='vertical-space-20'></div>
                                        {purchaserOrderError.select_item && <p style={{ color: 'red' }}>{purchaserOrderError.select_item}</p>}
                                        <div className='tableListDetails' style={{ maxHeight: '300px', overflow: 'scroll' }}>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope='col'></th>
                                                        {/* <th scope='col'>Indent   </th> */}
                                                        <th scope='col'>Item Name  </th>
                                                        <th scope='col'>UOM  </th>
                                                        <th scope='col'>App. No  </th>
                                                        <th scope='col'>App.Qty  </th>
                                                        {/* <th scope='col'>Qty </th> */}
                                                        <th scope='col'>Rate</th>
                                                        <th scope='col'>Item Total</th>
                                                        {/* <th scope='col'>Supplier</th> */}
                                                        <th scope='col'>Details</th>
                                                        {/* <th scope='col'>Packing Name</th> */}
                                                        {/* <th scope='col'>Packing Charges</th>
                                                        <th scope='col'>Tax</th> */}
                                                        <th scope='col'>Per</th>
                                                        <th scope='col'>Tax Amt</th>
                                                        <th scope='col'>Net Amt</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {itemLists?.map((items, index) => {
                                                        console.log(itemLists, 'itemLists>>')
                                                        return (
                                                            <tr key={index}>
                                                                <td><input onChange={() => handleCheckItems(items)} checked={items?.isChecked} type='checkbox'></input></td>
                                                                <td>{items?.ITEM_NAME}</td>
                                                                <td>{items?.Unitname}</td>
                                                                <td style={{ whiteSpace: 'nowrap' }}>{items?.purchase_approval_no}</td>
                                                                <td>{items?.approval_qty}</td>
                                                                <td>{items?.rate} </td>
                                                                <td>{items?.item_total}</td>
                                                                {/* <td>{items?.SupplierName}</td> */}
                                                                <td><button onClick={() => invoiceShow(items?.itemid, items?.approval_qty, true, items?.purchase_approval_no)} className='tablemodeltext'>Schedule</button></td>
                                                                {/* <td>{items?.details}</td> */}
                                                                <td>{items?.taxper}</td>
                                                                <td>{items?.tax_total}</td>
                                                                <td>{items?.nettotal}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                        <div className='row'>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Sub Total</label>
                                                    <input readOnly value={purchaseOrderSave?.sub_total} type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Packing Charges</label>
                                                    <input name='packing_charges' value={purchaseOrderSave?.packing_charges} onChange={handleInputChange}
                                                        type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Discount</label>
                                                    <input name='discount' onChange={handleInputChange} value={purchaseOrderSave?.discount}
                                                        type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Tax Amount</label>
                                                    <input name='tax_amount' readOnly
                                                        value={purchaseOrderSave?.tax_amount} type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Round Off</label>
                                                    <input name='round_off' onChange={handleInputChange}
                                                        value={purchaseOrderSave?.round_off} type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-9' ></div>
                                            <div className='col-sm-3' >
                                                <div className='inputBoxInline'>
                                                    <label>Net Total</label>
                                                    <input name='net_amount' readOnly
                                                        value={purchaseOrderSave?.net_amount}
                                                        type="text" style={{ width: "100%" }}
                                                        placeholder="" ></input>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                        </div>
                                        {/* <Draggable  handle="#draggable-dialog-title" cancel={'[class*="modal-dialog"]'}> */}
                                        <Modal show={invoice?.show} onHide={handleCloseSchedule} className='PriceHistoryModel'>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Schedule </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='inputBox' style={{ fontSize: 'larger' }}>Approval Qty: {invoice?.qty}</div>
                                                <div className='outletInputField inputFormBox '>
                                                    <div className='inputBoxLists'>
                                                        <div className='row'>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>Schedule No<span>*</span></label>
                                                                    <input name='schedule_no'
                                                                        value={purchaseOrderSchArr?.length + 1}
                                                                        type="text" style={{ width: "100%" }}
                                                                        placeholder="Schedule No"></input>
                                                                    {errors?.schedule_no != '' && <p style={{ color: 'red' }}>{errors?.schedule_no}</p>}
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>Schedule Date<span>*</span></label>
                                                                    <input name='delivery_date' onChange={hanldeScheduleChange}
                                                                        value={purchaseOrderSchedule.delivery_date}
                                                                        type="date" placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY" style={{ width: "100%" }}></input>
                                                                    {errors?.delivery_date && <p style={{ color: 'red' }}>{errors?.delivery_date}</p>}
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-6' >
                                                                <div className='inputBox'>
                                                                    <label>Schedule Qty<span>*</span></label>
                                                                    <input name='schedule_qty' onChange={hanldeScheduleChange}
                                                                        value={purchaseOrderSchedule?.schedule_qty} type="text" style={{ width: "100%" }}
                                                                        placeholder="Schedule Qty"></input>
                                                                    {errors?.schedule_qty && <p style={{ color: 'red' }}>{errors?.schedule_qty}</p>}
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                            <div className='col-sm-2'>
                                                                <button className='dfBtn' onClick={() => updatePurchaseOrderSchedule(invoice?.itemid, invoice?.qty, invoice?.appNo)}>Add</button>
                                                            </div>
                                                            {errors?.add_error && <p style={{ color: 'red' }}>{errors?.add_error}</p>}
                                                            <div className='col-md-12'>
                                                                <div className='tableBox'>
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Schedule No</th>
                                                                                <th scope="col">Schedule Date </th>
                                                                                <th scope="col">Schedule Qty</th>
                                                                                <th scope='col'></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {purchaseOrderSchArr?.map((schedule, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{schedule?.schedule_no}</td>
                                                                                        <td>{schedule?.delivery_date}</td>
                                                                                        <td>{schedule?.schedule_qty}</td>
                                                                                        <td><button className='delete' onClick={() => setPurchasePrderSchArr(prevArr => {
                                                                                            return prevArr?.filter((ea, ind) => ind != index)
                                                                                        })}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className='vertical-space-10'></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                        {/* </Draggable> */}
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Other Info</Accordion.Header>
                                <Accordion.Body>
                                    <div className='ItemInwardInputBox'>
                                        <div className='row'>
                                            <div className='col-sm-2' >
                                                <div className='inputBox'>
                                                    <label>Delivery Date<span>*</span></label>
                                                    <input type="date"
                                                        value={purchaseOrderSave!.delivery_date}
                                                        name='delivery_date'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}
                                                        placeholder="DD-MMM-YYYY" data-date=""
                                                        data-date-format="DD MM YYYY"
                                                    ></input>
                                                    {purchaserOrderError.delivery_date && <p style={{ color: 'red' }}>{purchaserOrderError.delivery_date}</p>}

                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-4' >
                                                <div className='inputBox'>
                                                    <label>Mode Of Payment</label>
                                                    <input type="text" placeholder="Mode Of Payment"
                                                        value={purchaseOrderSave?.payment_mode}
                                                        name='payment_mode'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.payment_mode && <p style={{ color: 'red' }}>{purchaserOrderError.payment_mode}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2' >
                                                <div className='inputBox'>
                                                    <label>PO Validity</label>
                                                    <input placeholder="DD-MMM-YYYY" data-date="" type="date"
                                                        data-date-format="DD MM YYYY"
                                                        value={purchaseOrderSave!.povalidity_date}
                                                        name='povalidity_date'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.povalidity_date && <p style={{ color: 'red' }}>{purchaserOrderError.povalidity_date}</p>}

                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-4' >
                                                <div className='inputBox'>
                                                    <label>Mode Of Dispatch</label>
                                                    <input type="text" placeholder="Mode Of Dispatch"
                                                        value={purchaseOrderSave?.packing_forward}
                                                        name='packing_forward'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.packing_forward && <p style={{ color: 'red' }}>{purchaserOrderError.packing_forward}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-4' >
                                                <div className='inputBox'>
                                                    <label>Quotation Ref.No</label>
                                                    <input type="text" placeholder="Quotation Ref. No"
                                                        value={purchaseOrderSave?.quotation_refno}
                                                        name='quotation_refno'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.quotation_refno && <p style={{ color: 'red' }}>{purchaserOrderError.quotation_refno}</p>}

                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2' >
                                                <div className='inputBox'>
                                                    <label>Transport Terms</label>
                                                    <input type="text" placeholder="Transport Terms"
                                                        value={purchaseOrderSave?.transport}
                                                        name='transport'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.transport && <p style={{ color: 'red' }}>{purchaserOrderError.transport}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2' >
                                                <div className='inputBox'>
                                                    <label>Payment Term</label>
                                                    <input type="text" placeholder="Payment Term"
                                                        value={purchaseOrderSave?.payment_terms}
                                                        name='payment_terms'
                                                        onChange={handleInputChange}
                                                        style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.payment_terms && <p style={{ color: 'red' }}>{purchaserOrderError.payment_terms}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Terms And Conditions</Accordion.Header>
                                <Accordion.Body>
                                    <div className='ItemInwardInputBox'>
                                        <div className='row'>
                                            <div className='col-sm-6' >
                                                <div className='inputBox'>
                                                    <label>Terms & Conditions<span>*</span></label>
                                                    <input onChange={(e) => setTermsAndCondition(e.target.value)} value={termsAndConditons}
                                                        type="text" placeholder="Terms & Conditions" style={{ width: "100%" }}></input>
                                                    {purchaserOrderError.terms_conditions && <p style={{ color: 'red' }}>{purchaserOrderError.terms_conditions}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-2'>
                                                <button className='dfBtn' onClick={handleAddTermCondition}>Add</button>
                                            </div>
                                            <div className='col-md-7'>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Terms & Conditions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {purchaseOrderSave?.purchaseOrderTeamsandcondition?.map((terms, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{terms?.ternsdetails}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Modal show={successModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={failureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                    <div className='btnBox'>
                        <button className='secondaryBtn' onClick={handleCancelClick}>List</button>
                        <button className='dfBtn' type='submit' onClick={savePurchaseOrder}>Submit</button>
                    </div>
                </div>
            }
        </>
    );
    }
};

export default PurchaseOrder;
