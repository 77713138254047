import { IStockWarehouse, StockToWarehouseDetails } from "../modal";

export function StkToWrhDetailValidation(stockToWarehouseDetail: StockToWarehouseDetails, stckToWrhsSave: IStockWarehouse) {
    let error: any = {}

    if (!stockToWarehouseDetail?.pid) {
        error.pid = 'Select Production Entry No'
    }
    if (!stockToWarehouseDetail?.Itemid) {
        error.Itemid = "Select Item"
    }
    if (!stockToWarehouseDetail?.batchNo) {
        error.batchNo = "Select Batch No"
    }
    if (!stockToWarehouseDetail?.qty) {
        error.qty = "Enter Quantity"
    }
    if (Number(stockToWarehouseDetail?.balQty!) < Number(stockToWarehouseDetail?.qty!)) {
        error.qty = 'Exceeds from Balance Quantity'
    }
    debugger
    if (stckToWrhsSave?.stocktoWarehousedetails?.length) {
        let filterArray = stckToWrhsSave?.stocktoWarehousedetails?.filter(each => each?.Itemid === stockToWarehouseDetail?.Itemid && each?.batchNo === stockToWarehouseDetail?.batchNo)
        if (filterArray?.length) {
            let totalQty = filterArray?.reduce((a, v) => a = a + v?.qty!, 0);
            if (Number(stockToWarehouseDetail?.balQty!) < (Number(totalQty!) + Number(stockToWarehouseDetail?.qty!))) {
                error.qty = 'Total Qty Exceeds from Balance Quantity'
            }
        }
    }
    return error;
}

export function StkToWrhValidation(stockToWarehouse: IStockWarehouse) {
    let error: any = {}

    if (!stockToWarehouse?.stocktoWDate) {
        error.stocktoWDate = "Enter Date"
    }
    if (!stockToWarehouse?.warehouseId) {
        error.warehouseId = "Select Warehouse"
    }
    if (!stockToWarehouse?.stocktoWarehousedetails?.length) {
        error.stocktoWarehousedetails = "Add Items Grid to Save"
    }
    return error;
}