export class IIBillEntry {
    bill_entry_st_id?: number = 0
    record_no?: string = ""
    record_date?: string = new Date()?.toISOString()?.substr(0, 10)
    bill_no?: string = ""
    bill_date?: string = new Date()?.toISOString()?.substr(0, 10)
    bill_amount?: number = 0
    supplier_ledger_accout_id?: number = 0
    round_off?: number = 0
    narration?: string = ""
    ac_year?: string = ""
    enter_date?: string = new Date()?.toISOString()?.substr(0, 10)
    user_id?: number = 0
    tdS_applicable?: string = ""
    vchr_id?: number = 0
    billentrydetail?: IIBillEntryDetails[] = []
    billentrytaxdetail?: IIBillEntryTaxDetails[] = []
}

export class IIBillEntryDetails {
    // load from payment voucher - by acc
    input_ser_ledger_accout_id?: number = 0 // rep id
    amount?: number = 0 // rep amount or input
    input_Service_Ledger_Id?: number = 0 // rep id
    supplier_Ledger_Id?: number = 0 // supplier_ledger_accout_id
    gst_LedgerId?: number = 0
    cgsT_Per?: number = 0
    cgsT_Amount?: number = 0
    sgsT_Per?: number = 0
    sgsT_Amount?: number = 0
    igsT_Per?: number = 0
    igsT_Amount?: number = 0
    total_Amount?: number = 0
}

export class IIBillEntryTaxDetails {
    gst_LedgerId?: number = 0
    st_ledger_accout_id?: number = 0 // input_ser_ledger_accout_id
    taX_VALUE?: number = 0
    inpuT_SERVICE_DETAILS_ID?: number = 0  // input_ser_ledger_accout_id
    service_tax_ledger_id?: number = 0
    amount?: number = 0
    taxcal_amount: number = 0;
}
