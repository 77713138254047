import { runInAction, set, makeObservable, observable, action } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IManageLoyalty } from '../model/index';

class ManageLoyaltyStore {
    @observable manageLoyaltyDetls = new Map();
    @observable manageLoyaltyList= new Map<number,IManageLoyalty>();
    isInwardLoding=true;
    getmanageLoyaltys = new Map();
    getmanageLoyaltyDetls = new Map();    
       

   
     saveManageLoyalty = async (manageLoyalty: IManageLoyalty[]) => {
        try {
          const status=  await apiAgent.manageLoyalty.saveManageLoyalty(manageLoyalty);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getManageLoyalty=async (id)=> {
        
        try {
          const getmanageLoyalty= await apiAgent.manageLoyalty.getManageLoyalty(id);
            
            runInAction(() => {
                
                this.getmanageLoyaltys = getmanageLoyalty;
            });
            return this.getmanageLoyaltys;
        }
        catch (error) {
            console.log(error);
        }
    }
    getManageLoyaltyDetails=async (manageLoyaltyId)=> {
        
        try {
          const manageLoyalty= await apiAgent.manageLoyalty.getManageLoyaltyDetails(manageLoyaltyId);
            
            runInAction(() => {
                
                this.getmanageLoyaltyDetls = manageLoyalty;
            });
            return this.getmanageLoyaltyDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
   getManageLoyaltySearch   = async (SearchOptions)=> {
        try {
          const manageLoyaltyData=await apiAgent.manageLoyalty.getManageLoyaltySearch(SearchOptions);
            this.getmanageLoyaltys = manageLoyaltyData;
            
            runInAction(() => {
                this.getmanageLoyaltys = manageLoyaltyData;
                
            })
            return this.getmanageLoyaltys;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteManageLoyaltyDetail = async (manageLoyaltyDetailId) => {
        try {      
        const status=  await apiAgent.manageLoyalty.deleteManageLoyaltyDetail(manageLoyaltyDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteManageLoyalty = async (manageLoyaltyId) => {
        try {      
        const status=  await apiAgent.manageLoyalty.deleteManageLoyalty(manageLoyaltyId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
   
}
export default ManageLoyaltyStore;