export interface ILoadLedger {
    flag: string;
    type: string;
}

export class IPaymentVoucherSave {
    paymentId?: number;
    employeeId?: number;
    paymentDate?: string;
    referenceNo?: string;
    // option?: string;
    paymentDebit?: IPaymentDebit[];
    paymentCredit?: IPaymentCredit[];
    purchasebill?: IPurchasebill[];
}

export class IPaymentDebit {
    debitAmount?: number;
    debitNarration?: string;
    debitAccountId?: number;
    debitAccountName?: string;
    debitDate?: string;
    chequeNo?: string;
    dbtype?: string
}

export class IPaymentCredit {
    creditAccountName?: string;
    creditAmount?: number;
    chequeNo?: string;
    creditDate?: string;
    creditBankName?: string;
    creditNarration?: string;
    creditAccountId?: number;
    cbtype?: string
}

export class IPurchasebill {
    billtype?: string;
    record_no?: number;
    billno?: string;
    billdate?: string;
    netAmount?: number
}