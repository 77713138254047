import moment from "moment"

export class IIInterviewLetter {
    intrviewid?: number = 0
    date?: string = new Date()?.toISOString()?.substr(0, 10)
    interviewdate?: string = new Date()?.toISOString()?.substr(0, 10)
    interviewTime?: string = ""
    description?: string = ""
    mode?: string = ""
    hiringmanager?: string = ""
    currentcity?: string = ""
    jobid?: number = 0
    jobcode?: string = ""
    jobdescription?: string = ""
    jobTitle?: string = ""
    employeeid?: number = 0
    resumeBankID?: number = 0
    location?: string = ""
}