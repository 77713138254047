import { IAssignOfferDiscountModel } from "../../../CRM/AssignOfferDiscount/model";


function stockAdjustmentValidation(assignOfferandDicount, options) {

  let errors: any = {};
  if (Object.keys(options).length === 0) {
    errors.options = "Select Options";
  }
  if (options.options === 'products') {
    if (!assignOfferandDicount.assignItemId) {
      errors.assignItemId = "Select Item Name";
    }
  }
  if (options.options === 'category') {
    if (!assignOfferandDicount.assignCategoryId) {
      errors.assignCategoryId = "Enter Item Category";
    }
  }

  if (!assignOfferandDicount.assignDiscountOfferId) {
    errors.assignDiscountOfferId = "Select Discount";
  }
  if (!assignOfferandDicount.assignEffectiveFromDate) {
    errors.assignEffectiveFromDate = "Select Effect From Date";
  }
  if (!assignOfferandDicount.assignEffectiveToDate) {
    errors.assignEffectiveToDate = "Select Effect To Date";
  }

  return errors;
};

export function saveValidation(assignOffers: IAssignOfferDiscountModel) {
  let errors: any = {}
  if (!assignOffers.assignOffetDetails) {
    errors.assignOffetDetails = "Select Effect To Date";
  }

  return errors;
}

export default stockAdjustmentValidation;