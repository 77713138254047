import { observable } from "mobx";
export interface IAssignOfferDiscount {
    assignOfferDiscount?: IAssignOfferAndDiscount[];

}
export interface IAssignOfferAndDiscount {
    itemId?: number;
    offerId?: number;
    itemCategory?: string;
    categoryId?: number;
    itemName?: string;
    offerName?: string;
    effectFromDate?: string;
    effectToDate?: string;
    employeeName?: string;
    assignedById?: number;
    billValue?: number;
    everyBilling?: number;
    approvalRequest?: string;
    subCategoryName?: string;
    assignOffetDetails?: IAssignOfferAndDiscountDetails[];
    isApprovalChecked?: boolean;

}

export interface IAssignOfferAndDiscountSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

export class IAssignOfferDiscountModel {
    assignOfferId?: number;
    assignOffeDetailId?: number;
    assignedById?: number; // avial
    approvalRequest?: string;// avial
    assinOfferType?: string;
    offerId?: number;
    assignOffetDetails?: IAssignOfferAndDiscountDetails[]
}

export class IAssignOfferAndDiscountDetails {
    assignOffeId?: number;
    assignOffeDetailId?: number;
    assignOfferItemId?: number;
    assignItemId?: number; // avial
    assignOfferItemName?: string; // avial
    assignCategoryId?: number; // avial
    assignBillValue?: number; // avial
    assignEveryBilling?: number;// avial
    assignCategoryName?: string;// avial
    assignDiscountOfferId?: number;// avial
    assignOfferName?: string;
    assignEffectiveFromDate?: string;// avial
    assignEffectiveToDate?: string;// avial
}

const assignOfferDiscountDetail = observable<IAssignOfferAndDiscount>({
    itemId: 0,
    offerId: 0,
    itemCategory: '',
    categoryId: 0,
    itemName: '',
    offerName: '',
    effectFromDate: '',
    effectToDate: '',
    employeeName: '',
    assignedById: 0,
    billValue: 0,
    everyBilling: 0,
    approvalRequest: '',
    subCategoryName: '',
    assignOffetDetails: [],
    isApprovalChecked: false
});

export default assignOfferDiscountDetail;