import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import moment from 'moment';

const InterviewProcessList = () => {

    const { interviewStore, jobMasterStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [interviewProcessList, setInterviewProcessList] = useState<any[]>([])

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])

    const [isModal, setModal] = useState({ bool: false, index: 0 })

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/InterviewProcess')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Interview_process')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const processList = await interviewStore?.loadInterviewProcessMaster();
        if (processList?.length) {
            setInterviewProcessList([...processList]);
        }
        const jobMaster = await jobMasterStore?.loadJobMaster();
        setJobMaster([...jobMaster])

        const interviewletter = await interviewStore.loadInterviewLetter();
        setInterviewLetterList([...interviewletter])
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (interviewProcessList && interviewProcessList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(interviewProcessList)
        }
    }, [interviewProcessList])

    const goToPage = (value: number) => {
        let sortedData = interviewProcessList?.slice()?.sort((a, b) => b.Intprocessid - a.Intprocessid);
        const currentPageList = currentPageDatas(removeDuplicates(sortedData, 'Intprocessid'), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={interviewProcessList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{interviewProcessList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Job Description</th>
                                            <th scope="col">Interview Date</th>
                                            <th scope="col">Call Letter No</th>
                                            <th scope="col">Candidate Name</th>
                                            <th scope='col'>Conduct by</th>
                                            <th scope='col'></th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td >{each?.Jobcode}</td>
                                                    <td >{jobMaster?.find((obj) => obj?.Jobcode == each?.Jobcode)?.Jobtitle}</td>
                                                    <td style={{ maxWidth: '300px' }}>{jobMaster?.find((obj) => obj?.Jobcode == each?.Jobcode)?.JobDescription}</td>
                                                    <td >{moment(each?.Interviewdate)?.format('DD-MMM-YYYY')}</td>
                                                    <td >{each?.Callletterno}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)?.Candidatename}</td>
                                                    <td >{each?.Conductby}</td>
                                                    <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => setModal({ bool: true, index: each?.Intprocessid })}>Details</button></td>
                                                    <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={isModal?.bool} onHide={() => setModal({ bool: false, index: 0 })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Detail</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Round</th>
                                                    <th scope="col">Process</th>
                                                    <th scope="col">Next Round</th>
                                                    <th scope="col">FeedBack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {interviewProcessList?.map((each, index) => {
                                                    return isModal?.index === each?.Intprocessid && (
                                                        <tr key={index}>
                                                            <td className='capitalize'>{each?.Phaseno}</td>
                                                            <td>{each?.Process}</td>
                                                            <td>{each?.NextPhases}</td>
                                                            <td>{each?.Feedback1}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default InterviewProcessList;