import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

function PaymentVoucherDetail() {

  const navigate = useNavigate()
  const { paymentVocherStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [paymentVoucherList, setPaymentVoucherList] = useState<any[]>([])
  const [paymentByDetails, setPaymentByDetails] = useState<any[]>([])
  const [paymentToDetails, setPaymentToDetails] = useState<any[]>([])
  const [paymentBillDetails, setPaymentBillDetails] = useState<any[]>([])

  const [loader, setLoader] = useState(true)
  const [byAccount, setByAccount] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const byAccountShow = async (vocno) => {
    let body = {
      actype: "payment",
      vocno: vocno
    }
    const paymetnList = await paymentVocherStore.voucherMasterGet(body);

    const paymentByDet = paymetnList?.filter((obj) => obj?.vchr_tb === "By")
    setPaymentByDetails(paymentByDet);

    const paymentToDet = paymetnList?.filter((obj) => obj?.vchr_tb === "To")
    setPaymentToDetails(paymentToDet);

    setByAccount(true)
  }

  const paymentDetails = async (vocon) => {
    const paymentDetailList = await paymentVocherStore?.loadPaymentBillDetail(vocon);
    setPaymentBillDetails([...paymentDetailList])
    setPaymentModal(true)
  }

  const byAccountClose = () => setByAccount(false);

  async function fetchPreLoadingData() {
    const userRights = await userCreationStore?.getUserRight('Payment_voucher')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    let body = {
      actype: "payment",
    }
    const paymetnList = await paymentVocherStore.voucherMasterGet(body);
    setPaymentVoucherList(paymetnList)
    setLoader(false)
  }

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadingData();
      isInitialRender.current = false;
    } else {
    }
    return () => {
      // Cleanup logic (if needed)
    };
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (paymentVoucherList && paymentVoucherList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(paymentVoucherList)
    }
  }, [paymentVoucherList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(paymentVoucherList?.slice()?.sort((a, b) => b.vchr_no - a.vchr_no), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {loader ? <div className='SpinnerBox'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> : <div className='container-fluid'>
        <div className='vertical-space-20'></div>
        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={paymentVoucherList}
          searchTitles={[{ key: "vchr", value: "Ref No" }, { key: "ledger_accout_name", value: "Ledger Name" }]}
          emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
        <div className='vertical-space-20'></div>
        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{paymentVoucherList?.length} Records</button>
          </div>
          <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
        <div className='vertical-space-20'></div>
        <div className='tableListDetails'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope='col'>Voucher No</th>
                <th scope='col'> Date</th>
                <th scope='col'>Ref No</th>
                <th scope='col'>Ledger Name</th>
                <th scope='col'></th>
                <th scope='col'></th>
                <th scope='col'>Add</th>
                <th scope='col'>Edit</th>
                <th scope='col'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {(isSearch ? filteredData : currentPageData)?.length > 0 ?
                (isSearch ? filteredData : currentPageData)?.map((oobj, index) => {
                  return (
                    <tr key={index}>
                      <td>{oobj?.vchr_no}</td>
                      <td>{moment(oobj?.vchr_date).format("DD-MMM-YYYY")}</td>
                      <td>{oobj?.refno}</td>
                      <td>{oobj?.ledger_accout_name}</td>
                      <td><button disabled={!userRights?.view} onClick={() => byAccountShow(oobj?.vchr_no)} className='tablemodeltext'> Detail</button></td>
                      <td><button disabled={!userRights?.view} onClick={() => paymentDetails(oobj?.vchr_no)} className='tablemodeltext'>Bill Detail</button></td>
                      <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/PaymentVoucher')}><FontAwesomeIcon icon={faAdd} /></button></td>
                      <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/PaymentVoucher/' + oobj?.vchr_no)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                      <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                    </tr>
                  )
                }) : null}
            </tbody>
          </table>
        </div>


        <Modal show={byAccount} onHide={byAccountClose} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>By Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>By Account </th>
                    <th scope='col'> Cr Account   </th>
                    <th scope='col'>Check No  </th>
                    <th scope='col'>Date  </th>
                    <th scope='col'>Narration  </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentByDetails?.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <td>{payment?.ledger_accout_name}</td>
                        <td>{payment?.vchr_cr_amount}</td>
                        <td>{payment?.check_no}</td>
                        <td>{moment(payment?.vchr_date).format("DD-MMM-YYYY")}</td>
                        <td>{payment?.vchr_narration}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>To Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>To Account </th>
                    <th scope='col'> Dr Account   </th>
                    <th scope='col'>Narration  </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentToDetails?.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <td>{payment?.ledger_accout_name}</td>
                        <td>{payment?.vchr_dr_amount}</td>
                        <td>{payment?.vchr_narration}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={paymentModal} onHide={() => setPaymentModal(false)} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Payment Bill Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Record No. </th>
                    <th scope='col'>Bill Type</th>
                    <th scope='col'>Bill No </th>
                    <th scope='col'>Bill Date </th>
                    <th scope='col'>Net Total</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentBillDetails?.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <td>{payment?.record_no}</td>
                        <td>{payment?.bill_type}</td>
                        <td>{payment?.bill_no}</td>
                        <td>{moment(payment?.bill_date).format("DD-MMM-YYYY")}</td>
                        <td>{payment?.Net_total}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </div>}
    </>
  );

};

export default PaymentVoucherDetail;
