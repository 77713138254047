import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { IIRejoinMaster } from "./model";
import moment from "moment";
import { removeDuplicates } from "../../common/shared/utils";

const Rejoining = () => {

    const { employeePromotionStore, jobMasterStore } = useStores()

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [resignationApproved, setResignationApproved] = useState<any>([])

    const [rejoinMaster, setRejoinMaster] = useState<IIRejoinMaster[]>(new Array<IIRejoinMaster>())
    const [approvalDate, setApprovalDate] = useState(new Date()?.toISOString()?.substr(0, 10))
    const [recordNo, setRecordNo] = useState(0)

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
        setSuccessModal(false)
    }
    function handleToRejoiningList() {
        navigate('/Admin/Rejoining/RejoiningList')
    }

    function handleInputChange(event, each) {
        const { name, value } = event?.target;
        rejoinMaster?.forEach((obj) => {
            if (obj?.eR_RMID === each?.eR_RMID) {
                obj[name] = (name === 'eR_TicketNo') ? Number(value) : value;
            }
            return obj;
        })
        setRejoinMaster([...rejoinMaster])
    }

    async function saveRejoinMaster() {
        let error: any = {}
        if (rejoinMaster?.filter((each) => each?.eR_TicketNo != 0)?.length) {
            const entryNo = await jobMasterStore.getHREntryNo('RejoiningMaster');
            rejoinMaster?.forEach((each) => {
                each['eR_ID'] = Number(entryNo?.entrynumber);
                each['eR_RecordNo'] = Number(entryNo?.entrynumber);
                return each;
            })
            const status = await employeePromotionStore?.saveRejoinMaster(removeDuplicates(rejoinMaster, 'eR_EmpId')?.filter((each) => each?.eR_TicketNo != 0))
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            error.rejoinMaster = 'Update ticket no to submit'
        }
        setErrors({ ...error })
    }
    async function handleSaveCategory(each: IIRejoinMaster) {
        let error: any = {}
        if (each?.eR_TicketNo && each?.eR_RJDate) {
            each['approvalDate'] = approvalDate;
        } else {
            if (!each?.eR_TicketNo) {
                error.eR_EmpId = each?.eR_EmpId;
                error.eR_TicketNo = 'Enter Employee ID.';
            }
        }
        setErrors({ ...error })
    }

    function updateRejoin(resignationList) {
        let rejoinMasterList: IIRejoinMaster[] = new Array<IIRejoinMaster>();
        resignationList?.forEach((each, index) => {
            let rejoinMaster: IIRejoinMaster = {
                approvalDate: approvalDate,
                eR_CatID: each?.RM_CatID ?? 0,
                eR_DegnID: each?.RM_DegnID ?? 0,
                eR_DeptID: each?.RM_DeptID ?? 0,
                eR_EmpId: each?.RM_EmpID ?? 0,
                eR_ID: 0,
                eR_RecordNo: recordNo,
                eR_RJDate: new Date()?.toISOString()?.substr(0, 10),
                eR_RMID: each?.RM_ID,
                eR_TicketNo: 0,
            }
            rejoinMasterList?.push(rejoinMaster);
        })
        return rejoinMasterList;
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('RejoiningMaster');
        setRecordNo(Number(entryNo?.entrynumber));
        const resignationApproved = await employeePromotionStore?.loadResignationApprovalApproved()
        if (resignationApproved?.length) {
            setRejoinMaster([...updateRejoin(resignationApproved)])
            setResignationApproved([...resignationApproved])
        }
        setLoading(false)
        // additionalApis()
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);


    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>
                            <div className='hrBox'>
                                <h3>Rejoining</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Record No <span style={{}}>*</span></label>
                                            <input
                                                type="text" disabled
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                name="fromdate"
                                                value={recordNo}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Approval Date <span style={{}}>*</span></label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="fromdate"
                                                value={approvalDate}
                                                onChange={(event) => setApprovalDate(event?.target?.value)}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Date of Joining</th>
                                            <th scope="col">Employee ID</th>
                                            <th scope="col">Rejoining Date</th>
                                            <th scope="col">Update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rejoinMaster?.map((each, index) => {
                                            return (
                                                <tr>
                                                    <td>{resignationApproved?.find((obj) => obj?.RM_EmpID === each?.eR_EmpId)?.Employee_Name}</td>
                                                    <td>{resignationApproved?.find((obj) => obj?.RM_DeptID === each?.eR_DeptID)?.Department}</td>
                                                    <td>{resignationApproved?.find((obj) => obj?.RM_DegnID === each?.eR_DegnID)?.Designation}</td>
                                                    <td>{resignationApproved?.find((obj) => obj?.RM_CatID === each?.eR_CatID)?.Category}</td>
                                                    <td>{moment(resignationApproved?.find((obj) => obj?.RM_EmpID === each?.eR_EmpId)?.RM_DOJ).format('DD-MMM-YYYY')}</td>
                                                    <td>
                                                        <input type="text" className='' name='eR_TicketNo'
                                                            value={each?.eR_TicketNo}
                                                            onChange={(event) => handleInputChange(event, each)}
                                                            style={{ width: "100%" }} placeholder='Employee ID..'></input>
                                                        {(errors?.eR_EmpId === each?.eR_EmpId && errors?.eR_TicketNo) && <p style={{ color: 'red' }}>{errors?.eR_TicketNo}</p>}
                                                    </td>
                                                    <td>
                                                        <input type="date" className="full-width" placeholder="DD-MMM-YYYY"
                                                            value={each?.eR_RJDate}
                                                            name="eR_RJDate"
                                                            onChange={(event) => handleInputChange(event, each)}
                                                            data-date="" data-date-format="DD MMMM YYYY"></input>
                                                    </td>
                                                    <td><button onClick={() => handleSaveCategory(each)}>Update</button></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                                {errors?.rejoinMaster && <p style={{ color: 'red' }}>{errors?.rejoinMaster}</p>}
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={() => { handleToRejoiningList() }}>List</button>
                                <button className='dfBtn' onClick={saveRejoinMaster}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default Rejoining;