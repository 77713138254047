import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class PartyLedgerSearch {
    searchDatefrom?: string = getCurrentMonthFirstDate()?.firstDate;
    searchDateto?: string = getCurrentMonthFirstDate()?.currentDate;
    ledgername?: string = "";
    ledgerId?: number = 0
}

export class PartyLedgerBalance {
    Credit?: number;
    debit?: number;
    balance?: number;
}

export class PartyLedgerStatement {
    vchr_date?: string;
    vchr_no?: number;
    vchr_ledger_accout_id?: number;
    ledger_accout_name?: string;
    debit?: number;
    Credit?: number;
    vchr_type?: string;
    transaction_Type?: string;
    entrydate?: string;
}

const balanceAmtDetails = observable<PartyLedgerBalance>({
    Credit: 0,
    debit: 0,
    balance: 0
})








export interface ILedgerNameSearchOption {
    searchOption?: string;
    searchValue?: string;
}

export class PartyLedgerName {
    entryDate?: string;
    ledgerGroup?: string;
    ledgerGroupId?: number;
    ledgerId?: number;
    ledgerName?: string;
    openingBalance?: number;
    percentage?: number;
    type?: string;
    userId?: 0
}

const partLedgerNameReq = observable<PartyLedgerName>({
    entryDate: "",
    ledgerGroup: "",
    ledgerGroupId: 0,
    ledgerId: 0,
    ledgerName: "",
    openingBalance: 0,
    percentage: 0,
    type: "",
    userId: 0
});