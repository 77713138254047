
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import { ILedgerGroup } from './model';
import useStores from '../../hooks';
import ledgerGroupValidation from './validation';
import Cookies from 'js-cookie';


const LedgerGroup = observer((): JSX.Element => {
  const [ledgerGroup, setLedgerGroup] = useState<ILedgerGroup>({});
  const { supplierMasterStore, ledgerGroupStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleChangeview = () => {
    navigate('/Admin/LedgerGroup/LedgerGroupDetail');
  }
  const { groupId } = useParams();

  async function saveLedgerGroup(e) {
    e.preventDefault();
    let error: any = {};
    error = ledgerGroupValidation(ledgerGroup);
    setErrors(error)
    if (Object.keys(error).length === 0) {

      setLoading(true);
      let status: string = '';
      if (groupId !== undefined) {
        status = await ledgerGroupStore.updateLedgerGroup(ledgerGroup);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const status = await ledgerGroupStore.saveLedgerGroup(ledgerGroup);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }


  const handleInputchnage = (e) => {

    const { name, value } = e.target;

    setLedgerGroup({ ...ledgerGroup, [name]: value });

  };
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/LedgerGroup/LedgerGroupDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  async function mapItemData(LedgerDels) {

    ledgerGroup.groupId = LedgerDels.groupId
    ledgerGroup.ledgerGroupName = LedgerDels.ledgerGroupName;
    ledgerGroup.subGroup = LedgerDels.subGroup;
    ledgerGroup.undergroup = LedgerDels.undergroup;
    setLedgerGroup(ledgerGroup);
  }

  async function fetchPreLoadingData() {
    if (groupId !== undefined) {
      const data = await ledgerGroupStore.LedgerGroupDetails(parseInt(groupId!));
      mapItemData(data);
    }
    else {
      const data = await ledgerGroupStore.getEntryNo('LedgerGroups');
      if (data !== undefined) {
        ledgerGroup.groupId = data.entryNo + 1;
        setLedgerGroup(ledgerGroup);
      }
    }
    await ledgerGroupStore.getEntryNo('LedgerGroups');
    setLoading(false);
  }

  // const isCurrentPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchPreLoadingData();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Ledger_group';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/LedgerGroup/LedgerGroupDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>
        {
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>}
        <>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox'>
            <div className='hrBox'>
              <h3>Ledger Group</h3>
            </div>
            <form autoComplete='off'>
              <div className='inputBoxLists'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Ledger Group ID </label>
                      <input type="text" disabled
                        name='groupId'
                        value={ledgerGroup.groupId}
                        className='' style={{ width: "100%" }} placeholder='Ledger Group ID..'></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Ledger Group <span>*</span></label>
                      <input type="text" className=''
                        onChange={handleInputchnage}
                        name="ledgerGroupName"
                        value={ledgerGroup.ledgerGroupName}
                        style={{ width: "100%" }} placeholder='Ledger Group..'></input>
                      {errors.ledgerGroupName && <p style={{ color: 'red' }}>{errors.ledgerGroupName}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                  <div className='col-md-12'>
                    <div className='inputBox'>
                      <label>Sub Group <span>*</span></label>
                      <input type="text" className=''
                        onChange={handleInputchnage}
                        name="subGroup"
                        value={ledgerGroup.subGroup}
                        style={{ width: "100%" }} placeholder='Sub Group..'></input>
                      {errors.subGroup && <p style={{ color: 'red' }}>{errors.subGroup}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                  <div className='col-md-12'>
                    <div className='inputBox'>
                      <label>Under Group <span>*</span></label>
                      <select className="form-selected"
                        name='undergroup'
                        value={ledgerGroup.undergroup}
                        onChange={handleInputchnage}
                        style={{ width: "100%" }} id="under">
                        <option value="" >Select Under group</option>
                        <option value="Primary">Primary</option>
                        <option value="Liabilities">Liabilities</option>
                        <option value="Assets"> Assets</option>
                        <option value="Expenses"> Expenses</option>
                      </select>
                      {errors.undergroup && <p style={{ color: 'red' }}>{errors.undergroup}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                </div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                <button className='dfBtn' type='submit' onClick={saveLedgerGroup} >Submit</button>
              </div>
            </form>

            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>


      </>
    );
  }
});

export default LedgerGroup;
