export class IICustomerFeedback {
    feeddate?: string = ""
    name?: string = ""
    lastname?: string = ""
    email?: string = ""
    email_Commands?: number = 0
    city?: string = ""
    state?: string = ""
    testimonial?: number = 0
    hear_about_us?: string = ""
    overallRateing?: string = ""
    reason_For_Rating?: string = ""
    favorable_impression?: string = ""
    speedy_polite?: string = ""
    informativeandcontieous?: string = ""
    appropriate?: string = ""
    allterations?: string = ""
    purchased_items?: string = ""
}