import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../common/shared/progressbar';
import { IsubCategorySearchoptions } from './model';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';


const SubCategoryDetail = observer((): JSX.Element => {

  const [isLoading, setLoading] = useState(true);
  const { subCategoryStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/SubCategory')
  }

  const editSubCategoryDetials = (subCategoryId) => {
    navigate('/Admin/SubCategory/' + subCategoryId);
  }

  const deleteSubCategoryDetials = async (subCategoryId) => {
    setLoading(true);
    const status = await subCategoryStore.deleteSubcategory(subCategoryId);

    if (status === 'Success') {
      loadSubcategory();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    loadSubcategory();
  }

  async function loadSubcategory() {
    const userRights = await userCreationStore?.getUserRight('Sub_category')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await subCategoryStore.getSubcategory();
    setLoading(false);
  }
  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      loadSubcategory();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (subCategoryStore?.subCategoryList && subCategoryStore?.subCategoryList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(subCategoryStore?.subCategoryList)
    }
  }, [subCategoryStore?.subCategoryList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(subCategoryStore?.subCategoryList?.slice().sort((a, b) => b.subCategoryId - a.subCategoryId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={subCategoryStore?.subCategoryList}
                searchTitles={[{ key: "subCategoryName", value: "Sub Category Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{subCategoryStore?.subCategoryList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>No</th>
                      <th scope='col'>Category</th>
                      <th scope='col'>Sub Category</th>
                      <th scope='col'>Create</th>
                      <th scope='col'>Update</th>
                      <th scope='col'>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      ((isSearch ? filteredData : currentPageData)).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.subCategoryId}</td>
                            <td>{val.categoryName}</td>
                            <td>{val.subCategoryName}</td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editSubCategoryDetials(val.subCategoryId)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteSubCategoryDetials(val.subCategoryId)} /></button></td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>

  );

});

export default SubCategoryDetail;
