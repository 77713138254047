import React, { useState, useEffect, } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IAssignBatchNo, IAssignBatchNoDetails, ILoadOptions } from './model';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import assignBatchNoValidation from './validation';
import moment from 'moment';
import { removeDuplicates } from '../../common/shared/utils';

const AssignBatchNo = observer((): JSX.Element => {
  const { commonStore, assignBatchNoStore, itemMasterStore } = useStores();
  const [assignBatchNo, setAssignBatchNo] = useState<IAssignBatchNo>({});
  const [assignBatchNoDetails, setAssignBatchNoDetails] = useState<IAssignBatchNoDetails>({});
  const [assignBatchNoDetail, setAssignBatchNoDetail] = useState<IAssignBatchNoDetails[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [supplierName, setSupplierName] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [inwardId, setInwardId] = useState(null);
  const [inwarddate, setInwarddate] = useState('');
  const [itemName, setItemName] = useState('');
  const [loadInwardItem, setLoadInwardItems] = useState([]);
  const [batchqty, setBatchqty] = useState(0);
  const [mfg_Date, setMfg_Date] = useState('');
  const [exp_Date, setExp_Date] = useState('');

  const [isSpinner, setSpinner] = useState(false);
  const [options, setOptions] = useState<ILoadOptions>({});


  const { loadSupplierMastes, loadSuppliers, loadInwardItems,
    loadIteminward, isloadItemInwardLoading, loadItemInwards,
    isloadEmployeeLoading, loadEmployee, loadEmployees, loadItems } = commonStore;

  const handleAssignBatchNo = (event: any) => {
     
    const { name, value } = event.target;
    if(name==='mfg_Date' && assignBatchNoDetails.expiryDays!==0){
      let expiryDate = new Date(value);
      const exp = expiryDate.setDate(expiryDate.getDate()+assignBatchNoDetails.expiryDays!)
      assignBatchNoDetails.exp_Date = moment(exp).format('YYYY-MM-DD');
       

    };
    setAssignBatchNoDetails({ ...assignBatchNoDetails, [name]: value });
  }
  function handleChangeInput(event: any) {
     
    const { name, value } = event.target;
    
    setAssignBatchNo({ ...assignBatchNo, [name]: value })
  }

  async function addAssignBatchNo(e) {
  
    let assignBatchDetail: IAssignBatchNoDetails = {};
    e.preventDefault();
    let error: any = {};
    error = assignBatchNoValidation(assignBatchNo, assignBatchNoDetails);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      
      const runningN0 = assignBatchNoDetail.length;
      const CheckQty = assignBatchNoDetail.find(m => m.itemId === assignBatchNoDetails.itemId);
      const addQty = CheckQty?.batchqty! + Number(assignBatchNoDetails.batchqty);
      if (addQty > Number(assignBatchNoDetails.existingQty!)) {
        error.batchError = 'Item Quantity is ' + assignBatchNoDetails.existingQty + ' but adding ' + addQty;
        setErrors(error);
        return
      }
      if (Number(assignBatchNoDetails.batchqty) > Number(assignBatchNoDetails.existingQty)) {
        error.batchError = 'Item Quantity is ' + assignBatchNoDetails.existingQty + ' but adding ' + Number(assignBatchNoDetails.batchqty);
        setErrors(error);
        setItemName('');
        return
      }


        if (Number(assignBatchNoDetails.batchqty) === Number(assignBatchNoDetails.existingQty)) {
          loadItems.filter(m=>m.itemId!==assignBatchNoDetails.itemId);
        }
        assignBatchDetail.batchNo = assignBatchNo.supplierId!.toString()
          + '/' + assignBatchNoDetails.inwardmasterid?.toString()
          + '/' + moment(assignBatchNoDetails.inwarddate).format('YYYYMMDD') +
          '/' + assignBatchNoDetails.itemId?.toString() + '/' +
          assignBatchNoDetails.mfg_Date?.replace(/[: -]/g, '') + '/' +
          assignBatchNoDetails.exp_Date?.replace(/[: -]/g, '')
          + '/' + assignBatchNoDetails.batchqty?.toString() + '-00' + '/' + runningN0 + '/' + 1;
        assignBatchDetail.inwarddate = moment(assignBatchNoDetails.inwarddate).format('YYYY-MM-DD');

        assignBatchDetail.inwardQty = Number(assignBatchNoDetails.inwardQty) - Number(assignBatchNoDetails.batchqty);
        assignBatchDetail.inwardmasterid = Number(assignBatchNoDetails.inwardmasterid);
        assignBatchDetail.qty = Number(assignBatchNoDetails.batchqty);
        assignBatchDetail.batchqty = Number(assignBatchNoDetails.batchqty);
        assignBatchDetail.itemId = assignBatchNoDetails.itemId;
        assignBatchDetail.itemName = assignBatchNoDetails.itemName;
        assignBatchDetail.mfg_Date=assignBatchNoDetails.mfg_Date
        assignBatchDetail.exp_Date=assignBatchNoDetails.exp_Date;
        assignBatchNoDetails.inwardQty = Number(assignBatchNoDetails.inwardQty) - Number(assignBatchNoDetails.batchqty);

        setSubmitbtn(true);
        setAssignBatchNoDetail([...assignBatchNoDetail, assignBatchDetail]);

     // }
      setMfg_Date('');
      setExp_Date('');
      setBatchqty(0);
      setItemName('');
      setAssignBatchNoDetails({ ...assignBatchNoDetails, batchqty: 0, mfg_Date: '', exp_Date: '', itemName: '', itemId: 0 ,inwardQty:0});
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setEmployeeName(val.employeeName)
      setAssignBatchNo({ ...assignBatchNo, [name]: val.employeeId });
    }
    else {

    }
  }
  const handleSupplierValue = async (name: any, event: object, val: any) => {
    
    if (val != null) {
      options.flag = 'InwardNo';
      options.supplierId = Number(val.supplierId)
      setSpinner(true);
      setSupplierName(val.supplierName);
      await loadIteminward(options);
      setAssignBatchNo({ ...assignBatchNo, [name]: val.supplierId });
      setSpinner(false);

    }
    else {
      assignBatchNo.supplierId = 0;
    }
  }

  const handleItemInwardValue = async (name: any, event: object, val: any) => {
    if (val != null) {
      setSpinner(true);
      options.flag = 'Items';
      options.supplierId = assignBatchNo.supplierId;
      options.inwardNo = val.inwardId;
      await loadInwardItems(options);
      setInwardId(val.inwardId)
      setInwarddate(val.inwardDate)
      setItemName(val.itemName)
      setBatchqty(val.batchqty)
      setMfg_Date(val.mfg_Date)
      setExp_Date(val.exp_Date)
      setAssignBatchNoDetails({
        ...assignBatchNoDetails, [name]: val.inwardId, inwarddate: val.inwardDate
      });
    }
    else {
      assignBatchNoDetails.inwardmasterid = 0;
    }
    setSpinner(false);
  }
  const handleItemValue = (name: any, event: object, val: any) => {
     
    let qty :number=0;
    if (val != null) {
      setItemName(val.itemName)
      
     const checkQty = assignBatchNoDetail.filter(m=>m.itemId===val.itemId);
     if(checkQty.length>0){
      assignBatchNoDetail.forEach(element => {
        if(element.itemId===val.itemId){
          qty += element.qty!;
        }
        
      })
      qty=val.qty-qty;
     }
     else
     {
      
      qty=val.qty;
     }
     
    }
    
     setAssignBatchNoDetails({ ...assignBatchNoDetails, [name]: val.itemId, itemName: val.itemName, inwardQty: qty, qty: qty, existingQty: val.qty,expiryDays:val.expiryDays});

  }
  const handleFailureModalClose = () => setFailureModal(false);
  async function saveAssignBatchNo(e) {
    setLoading(true);
    assignBatchNo.assignDetails = assignBatchNoDetail;
    assignBatchNo.assignNo = Number(assignBatchNo.assignNo)
    assignBatchNo.supplierId = Number(assignBatchNo.supplierId);
    const entryNos = await itemMasterStore.getEntryNo('AssignBatchNo');
    assignBatchNo.assignNo = entryNos.entryNo + 1;
    
    const status = await assignBatchNoStore.saveAssignBatchNo(assignBatchNo);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleCancel = () => {
    navigate('/Admin/AssignBatchNo/AssignBatchNoDetail');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignBatchNo/AssignBatchNoDetail');
  }
  async function fetchAssignDefaultLoad() {
    await loadSupplierMastes('InwardSupplier');
    await loadEmployee();
    await itemMasterStore.getEntryNo('AssignBatchNo');
    setLoading(false)
  }

  useEffect(() => {
    fetchAssignDefaultLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Assign Batch No</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      {
                        isSpinner ?
                          <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                          </div> : null
                      }
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Entry No <span>*</span></label>
                          <input  type="text"
                            name='assignNo' disabled
                            value={assignBatchNo.assignNo = itemMasterStore.entryNo.entryNo + 1}
                            onChange={handleChangeInput}
                            className='' style={{ width: "100%" }} placeholder='Entry No..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input  type="date"
                            name='assigndate' value={assignBatchNo.assigndate}
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.assigndate && <p style={{ color: 'red' }}>{errors.assigndate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Supplier <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadSuppliers}
                            value={supplierName}
                            onChange={(event, val) => handleSupplierValue('supplierId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Supplier..'
                                name='supplier'
                              />}
                          />
                          {errors.supplierId && <p style={{ color: 'red' }}>{errors.supplierId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='inputBox'>
                          <label>Created by </label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={removeDuplicates(loadEmployees, 'employeeName')}
                            value={employeeName}
                            // getOptionLabel={(option: any) => option.employeeName}
                            onChange={(event, val) => handleEmployeeValue('createdBy', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Created by..'
                                name='employeeName'
                              />}
                          />
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Inward No <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadItemInwards}
                            value={inwardId}
                            onChange={(event, val) => handleItemInwardValue('inwardmasterid', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '25ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Inward No..'
                                name='inwardId'
                              />}
                          />
                          {errors.inwardId && <p style={{ color: 'red' }}>{errors.inwardId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Inward Date <span>*</span></label>
                          <input  value={inwarddate}
                            defaultValue={moment(assignBatchNoDetails.inwarddate).format('YYYY-MM-DD')} name='inwarddate'
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="YYYY MMMM DD"></input>
                          {errors.inwarddate && <p style={{ color: 'red' }}>{errors.inwarddate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Item <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadItems}
                            value={itemName}
                            onChange={(event, val) => handleItemValue('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Item..'
                                name='itemName'
                              />}
                          />
                          {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-2'>
                        <div className='inputBox'>
                          <label>Qty </label>
                          <input 
                            value={assignBatchNoDetails.inwardQty} name='inwardQty'
                            onChange={handleAssignBatchNo} disabled
                            className='fullInput' style={{ width: "100%" }} placeholder='Qty..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-2'>
                        <div className='inputBox'>
                          <label>Ass. Qty <span>*</span></label>
                          <input  value={assignBatchNoDetails.batchqty}
                            name='batchqty'
                            onChange={handleAssignBatchNo}
                            className='fullInput' style={{ width: "100%" }} placeholder='Qty..'></input>
                          {errors.batchqty && <p style={{ color: 'red' }}>{errors.batchqty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Mfg Date <span>*</span></label>
                          <input  type="date"
                            name='mfg_Date' value={assignBatchNoDetails.mfg_Date}

                            onChange={handleAssignBatchNo}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.mfg_Date && <p style={{ color: 'red' }}>{errors.mfg_Date}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                     {
                      assignBatchNoDetails.expiryDays!==0? <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Expiry Date <span>*</span></label>
                        <input  type="date"
                          name='exp_Date' value={assignBatchNoDetails.exp_Date} disabled
                          onChange={handleAssignBatchNo}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.exp_Date && <p style={{ color: 'red' }}>{errors.exp_Date}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>: <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Expiry Date <span>*</span></label>
                          <input  type="date"
                            name='exp_Date' value={assignBatchNoDetails.exp_Date}
                            onChange={handleAssignBatchNo}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.exp_Date && <p style={{ color: 'red' }}>{errors.exp_Date}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                     }
                      <div className='col-sm-2'>
                        <button className='dfBtn pd-0-12 full-width' type='submit' onClick={addAssignBatchNo}>ADD</button>
                      </div>
                    </div>
                    {
                      errors.batchError && <p style={{ color: 'red' }}>{errors.batchError}</p>
                    }
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Inward No</th>
                          <th scope="col">Inward Date</th>
                          <th scope="col">Item</th>
                          <th scope="col">Ass.Qty</th>
                          <th scope="col">Mfg Date</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col">Batch No</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody style={{ verticalAlign: 'middle' }}>
                        {
                          assignBatchNoDetail.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.inwardmasterid}</td>
                                <td>{val.inwarddate}</td>
                                <td>{val.itemName}</td>
                                <td>{val.qty}</td>
                                <td>{moment(val.mfg_Date).format('DD-MM-YYYY')}</td>
                                <td>{moment(val.exp_Date).format('DD-MM-YYYY')}</td>
                                <td><QRCode value={val.batchNo} size={100} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveAssignBatchNo}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>

  );

});

export default AssignBatchNo;
