import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { Autocomplete, TextField } from "@mui/material";
import AutoComplete from "../../common/shared/autoComplete";
import { months, yearsArray } from "../json";
import useStores from "../../hooks";
import moment from "moment";
import { filterItemsWithNumericKeys, initializeAttendanceForMonth } from "../../common/shared/utils";

class MonthAbsentReg {
    month: number = months[new Date()?.getMonth()]?.month
    year: string = yearsArray?.find((each) => each?.key === new Date()?.getFullYear()?.toString())?.value!
    empId: number = 0
}

const MonthlyAbsentRegister = () => {

    const { employeeStore, hrReporsStore } = useStores()

    const [isLoading, setLoading] = useState(false)
    const [isSpinner, setSpinner] = useState(false);

    const [errors, setErrors] = useState<any>({});
    const [localFields, setLocalFields] = useState<MonthAbsentReg>(new MonthAbsentReg())

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [attendanceReport, setAttendanceReport] = useState<any[]>([])

    const getDaysInMonth = (monthName: string, year: number): number => {
        const monthIndex = moment().month(monthName).month();
        const daysInMonth = moment({ year, month: monthIndex }).daysInMonth();
        return daysInMonth;
    };

    let findDaysInMonth = getDaysInMonth(months?.find((obj) => obj?.month == localFields?.month)?.value!, Number(localFields?.year))

    function updatedArrayFunc(yearAttendance) {
        let attendanceArray: any = [];
        yearAttendance?.map((each) => {
            let employeeAttd: any = {
                empid: each?.empid,
                employeeName: each?.Employee_Name,
                DoB: each?.DoB,
                doj: each?.doj,
                totaldays: each?.totaldays,
                shift: each?.shift,
                attendance: filterItemsWithNumericKeys(yearAttendance?.filter((obj) => obj?.empid == each?.empid)),
            }
            attendanceArray?.push(employeeAttd)
        })
        return attendanceArray;
    }

    async function handleGenerate(event) {
        let error: any = {}
        if (localFields?.month && localFields?.year) {
            setSpinner(true)
            const yearAttendance = await hrReporsStore?.getReportAbsentAttendence(localFields?.month, Number(localFields?.year))
            const balanceElCl = await hrReporsStore?.getReportAbsentBalance(localFields?.month, Number(localFields?.year))
            console.log(yearAttendance, balanceElCl, 'yearAttendance>>')
            let updatedArray: any = [];
            updatedArray = updatedArrayFunc(yearAttendance)
            updatedArray?.forEach((family) => {
                balanceElCl?.forEach((employee) => {
                    if (family?.empid === employee?.empid) {
                        family['PR'] = employee?.PR;
                        family['LOP'] = employee?.LOP;
                        family['SH'] = employee?.SH;
                        family['AB'] = employee?.AB;
                        family['WO'] = employee?.WO;
                        family['FH'] = employee?.FH;
                        family['NH'] = employee?.NH;
                        family['CL'] = employee?.CL;
                        family['EL'] = employee?.EL;
                        family['SL'] = employee?.SL;
                    }
                    return family
                })
            })
            if (updatedArray?.length && findDaysInMonth) {
                setAttendanceReport([...initializeAttendanceForMonth(updatedArray, findDaysInMonth)])
            }
            setSpinner(false)
        } else if (event) {
            if (!localFields?.month) {
                error.month = 'Select month'
            }
            if (!localFields?.year) {
                error.year = 'Select year'
            }
        }
        setErrors({ ...error })
    }

    async function initialApicalls() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        handleGenerate(null)
        setLoading(false);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage?.current) {
            initialApicalls()
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField' >
                            <div className='hrBox'>
                                <h3>Monthly Absent Register</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Year<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={yearsArray} value={localFields?.year} getOptionLabel='value'
                                                emitOption={(option) => setLocalFields({ ...localFields, year: option?.value })} placeholder='Select Year..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Month<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={months} value={months?.find((each) => each?.month === localFields?.month)?.value} getOptionLabel='value'
                                                emitOption={(option) => setLocalFields({ ...localFields, month: option?.month })} placeholder='Select Month..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}> Employee<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={employeeMaster} value={employeeMaster?.find((each) => each?.EmployeeId === localFields?.empId)?.Employee_Name}
                                                getOptionLabel='Employee_Name' emitOption={(option) => setLocalFields({ ...localFields, empId: option?.EmployeeId })} placeholder='Select Name..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Print
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Employee Code</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col" className=""><div>D.O.B</div><div>D.O.J</div></th>
                                            <th scope="col" className=""><div>GR</div><div>Sft</div></th>
                                            {findDaysInMonth && Array.from(Array(findDaysInMonth).keys())?.map((each, index) => {
                                                return (
                                                    <th scope="col" key={index}>
                                                        <div>{index + 1}</div>
                                                        <div>{'S'}</div>
                                                    </th>
                                                )
                                            })}
                                            <th scope="col">PR</th>
                                            <th scope="col">LOP</th>
                                            <th scope="col">SH</th>
                                            <th scope="col">AB</th>
                                            <th scope="col">WO</th>
                                            <th scope="col">FH</th>
                                            <th scope="col">NH</th>
                                            <th scope="col">CL</th>
                                            <th scope="col">EL</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">Total Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceReport?.length > 0 &&
                                            attendanceReport?.map((each, index) => {
                                                console.log(attendanceReport, 'attendanceReport>>')
                                                return (!localFields?.empId || each?.empid === localFields?.empId) && (
                                                    <tr key={index}>
                                                        <td>{each?.empid}</td>
                                                        <td>{each?.empid}</td>
                                                        <td>{each?.employeeName}</td>
                                                        <td>
                                                            <div style={{ whiteSpace: 'nowrap' }}>{moment(each?.DoB)?.format('DD-MMM-YYYY')}</div>
                                                            <div style={{ whiteSpace: 'nowrap' }}>{each?.doj}</div>
                                                        </td>
                                                        <td>
                                                            <div>I</div>
                                                            <div>II</div>
                                                        </td>
                                                        {each?.attendance?.map((obj, ind) => {
                                                            return (
                                                                <td key={ind}>
                                                                    <div>{obj?.present ?? 'PR'}</div>
                                                                    <div>{obj?.present ? 'A' : 'S'}</div>
                                                                </td>
                                                            )
                                                        })}
                                                        <td>{each?.PR}</td>
                                                        <td>{each?.LOP}</td>
                                                        <td>{each?.SH}</td>
                                                        <td>{each?.AB}</td>
                                                        <td>{each?.WO}</td>
                                                        <td>{each?.FH}</td>
                                                        <td>{each?.NH}</td>
                                                        <td>{each?.CL}</td>
                                                        <td>{each?.EL}</td>
                                                        <td>{each?.SL}</td>
                                                        <td>{each?.totaldays}</td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="vertical-space-20"></div>
                        </div>
                    </>
            }

        </div>
    )
}

export default MonthlyAbsentRegister;