import React, { useState,useEffect,ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {Button, Form, Image, Modal, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { QRCode } from 'react-qrcode-logo';
import { IAssignBatchPackingSearchoptions } from './model';

const AssignBatchPackingDetail = observer((): JSX.Element => {
  const[assignBatchPackingSearchOptions,setAssignBatchPackingSearchOptions]=useState<IAssignBatchPackingSearchoptions>({})
  const { assignBatchPackingStore } = useStores();
  const { getAssignBatchPacking, getassignBatchPackings, getassignBatchPackingDetls, getAssignBatchPackingDetails } = assignBatchPackingStore;
  const [isLoading, setLoading] = useState(true);
  const [isAssignBatchPacking, setAssignBatchPackingModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const handleChangeclear=()=> {
    setLoading(true);
    fetchAssignBatchPacking();      
}
   
  const deleteAssignBatchPackingMaster = async(packingAssignBatchid) => {
    setLoading(true);
    const status = await assignBatchPackingStore.deleteAssignBatchPackingMaster(packingAssignBatchid); 
     
    if(status==='Success'){
      fetchAssignBatchPacking();
      setLoading(false);
      setSuccessModal(true);
    }
    else
    {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteAssignBatchPackingDetail = async(packingAssignBatchdid) => {
    setAssignBatchPackingModal(false)
    setLoading(true);
    const status = await assignBatchPackingStore.deleteAssignBatchPackingDetail(packingAssignBatchdid); 
     
    if(status==='Success'){
      fetchAssignBatchPacking();
      setLoading(false);
      setSuccessModal(true);
    }
    else
    {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchAssignBatchPacking();
  }

  async function fetchAssignBatchPacking() {    
   await getAssignBatchPacking();   
      setLoading(false);    
  }

 

  const handleInputChange=(e)=>{
    const {name,value}=e.target;
    setAssignBatchPackingSearchOptions({...assignBatchPackingSearchOptions,[name]:value});
    
  }
    const handleSearchInput=async(e)=>{
      const {name,value}=e.target;
      
      setAssignBatchPackingSearchOptions({...assignBatchPackingSearchOptions,[name]:value});      
  
    }
  const handleSearchAssignBatchPacking=async(e)=>{
    
    setLoading(true);
      await assignBatchPackingStore.getAssignBatchPackingSearch(assignBatchPackingSearchOptions);
      setLoading(false);
    }

  const handleCreate=()=>{
    navigate('/Admin/AssignBatchPacking');
  }

  async function handleAssignDetails(packingAssignBatchid, e) {
    e.preventDefault();    
    await getAssignBatchPackingDetails(packingAssignBatchid);
    setAssignBatchPackingModal(true);
  }

  const handleClose = () => {
    setAssignBatchPackingModal(false)
  }
  useEffect(() => {
    fetchAssignBatchPacking();
  },[])
  return (
    <>
      {
        isLoading ?
        <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          </div>:
          <>
    <div className='container-fluid'>
    <div className='vertical-space-20'></div>
      <div className='SearchBox col-md-8'>
      <select className="form-selected" id="Mode" name="searchOption" onChange={handleSearchInput}>
              <option value="">Select Search Criteria</option>
              <option  value="TeamName">Team Name</option>                 
              <option  value="EmployeeName"> Created By  </option>                     
            </select>
        <div className="input-group mb-3">
          <input  type="text" 
          name='searchValue'                  
          value={assignBatchPackingStore.searchValue}
          onChange={handleInputChange}
          className="form-control" placeholder="Search Here.." aria-label="Search Here" aria-describedby="search-button"></input>
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" id="search-button"><FontAwesomeIcon icon={faSearch} onClick={handleSearchAssignBatchPacking}/></button>
          </div>
        </div>
        <div className='btnBox'>
                <button className='searchclearBtn' onClick={handleChangeclear}>Clear</button>         
      </div>
      </div>
      <div className='tableListDetails'>
        <table className="table table-striped">
          <thead>
            <tr>                     
              <th scope='col'>Entry No </th>
              <th scope='col'>Date  </th>
              <th scope='col'>Team </th>
              <th scope='col'>Created by </th> 
              <th scope='col'>Warehouse  </th>                  
              <th scope='col'> </th>
              <th scope='col'>Create </th>
              <th scope='col'>Update </th>
              <th scope='col'>Delete </th>
            </tr>
          </thead>
          <tbody>
          {
                    (getassignBatchPackings.slice().sort((a, b)=>b.packingAssignBatchid - a.packingAssignBatchid)).map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.packingAssignBatchid} </td>
                          <td>{moment(val.packingAssignBatchdate).format('DD-MMM-YYYY')}</td>
                          <td>{val.packingTeamName} </td>
                          <td>{val.createdBy}</td>
                          <td>{val.wareHouseName}</td>
                          <td><button className='changeBtn' onClick={(e) => handleAssignDetails(val.packingAssignBatchid, e)}>Batch Packing Details</button></td>
                          <td><button className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                          <td><button className='Edit' ><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                          <td><button className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignBatchPackingMaster(val.packingAssignBatchid)} /></button></td>
                        </tr>
                      )
                    })
                  }
          </tbody>
        </table>
      </div>
      {
       isAssignBatchPacking ?
      <Modal show={isAssignBatchPacking} onHide={handleClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                           
                  <th scope="col">Item</th>
                  <th scope="col">Packing Date</th>                 
                  <th scope="col">Qty</th>
                  <th scope="col">Mfg Date</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Batch No</th>
                  <th scope='col'>Delete </th>
                </tr>
              </thead>
              <tbody>
              {
                            getassignBatchPackingDetls.map((val, key) => {
                              return (
                                <tr key={key}>
                                  
                                 
                                  <td>{val.packingInwardid} </td>
                                  <td>{moment(val.packingInwardDate).format('DD-MMM-YYYY')}</td>                                 
                                  <td>{val.packingQty}</td>                                 
                                  <td>{moment(val.mfg_date).format('DD-MMM-YYYY')}</td>
                                  <td>{moment(val.expiry_date).format('DD-MMM-YYYY')}</td>
                                  <td><QRCode value={val.batchno}/></td>
                                  <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignBatchPackingDetail(val.packingAssignBatchdid)} /></button></td>
                                </tr>
                              )
                            })
                          }
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>:null
     }      
    </div>
    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>
                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
        </Modal>
    </>
    }
    </>
  );

});

export default AssignBatchPackingDetail;
