import { action, set, makeObservable, observable, runInAction } from 'mobx';
import UserCreationDetails, { IUserCreation } from '../model/index';
import apiAgent from '../../../APIAgent';
import Cookies from 'js-cookie';


class UserCreationStore {

    @observable userCreationList = new Map();
    isuserCreationRequest = true;


    saveUserCreation = async (UserCreation: IUserCreation) => {
        try {
            const status = await apiAgent.userCreationMasters.saveUserCreation(UserCreation);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

   updateUserCreation = async (UserCreation: IUserCreation) => {
        try {
            const status = await apiAgent.userCreationMasters.updateUserCreation(UserCreation);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getUserCreation = async () => {
        try {

            const userCreationData = await apiAgent.userCreationMasters.getUserCreation();

            runInAction(() => {

                this.userCreationList = userCreationData;
                this.isuserCreationRequest = false;
            });


            return this.userCreationList;
        }
        catch (error) {
            console.log(error);
        }

    }
    getUserCreationSearch = async (SearchOptions) => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.getUserCreationSearch(SearchOptions);
            this.userCreationList = userCreationData;

            runInAction(() => {
                this.userCreationList = userCreationData;

            })
            return this.userCreationList;


        }
        catch (error) {
            console.log(error);
        }
    }
    deleteUserCreation = async (userId) => {
        try {
            const status = await apiAgent.userCreationMasters.deleteUserCreation(userId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    alertNotifications = async (alertBody) => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.alertNotifications(alertBody);
            this.userCreationList = userCreationData;

            runInAction(() => {
                this.userCreationList = userCreationData;

            })
            return userCreationData


        }
        catch (error) {
            console.log(error);
        }
    }
    loadAssignNotificationRolls = async (alertBody) => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.loadAssignNotificationRolls(alertBody);
            return JSON.parse(userCreationData)
        }
        catch (error) {
            console.log(error);
        }
    }
    loadForms = async () => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.loadForms();
            runInAction(() => {
                this.userCreationList = userCreationData;
                this.isuserCreationRequest = false;
            });
            return userCreationData;
        }
        catch (error) {
            console.log(error);
        }
    }

    userrightsSave = async (alertBody) => {
        try {
            const status = await apiAgent.userCreationMasters.userrightsSave(alertBody);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }
    loadformsname = async (body) => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.loadformsname(body);
            return userCreationData
        }
        catch (error) {
            console.log(error);
        }
    }
    loandUserrights = async (empid, modulename, status) => {
        try {
            const userCreationData = await apiAgent.userCreationMasters.loandUserrights(empid, modulename, status);
            return JSON.parse(userCreationData)
        }
        catch (error) {
            console.log(error);
        }
    }
    getUserRight = async (modulename) => {
        const empid = Number(Cookies.get('userId'))
        try {
            const userCreationData = await apiAgent.userCreationMasters.loandUserrights(empid, modulename, 'loadrights');
            let rightsObj: any = {};
            let userRights = JSON.parse(userCreationData)
            console.log(userRights,'userrightssetuserRights');     
            if (empid === 1) {
                userRights[0].rights = 'aedvpu';
                rightsObj = returnRights(userRights);
            } else if(empid !== 0) {                
                rightsObj = returnRights(userRights);           
            }  else {                
                userRights[0].rights = 'aedvpu'; //null;
                rightsObj = returnRights(userRights);           
            }
            console.log(rightsObj, userRights, empid, userCreationData, 'rightsObj>>')
            return rightsObj ?? {}
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteUserrights = async (userId) => {
        try {
            const status = await apiAgent.userCreationMasters.deleteUserrights(userId);
            return status
        }
        catch (error) {
            console.log(error);
        }
    }


}
export default UserCreationStore;

const checkboxOptions = [
    { name: 'add', label: 'Add', char: 'a' },
    { name: 'edit', label: 'Edit', char: 'e' },
    { name: 'delete', label: 'Delete', char: 'd' },
    { name: 'view', label: 'View', char: 'v' },
    { name: 'print', label: 'Print', char: 'p' },
    { name: 'update', label: 'Update', char: 'u' },
];

function returnRights(rightsData: any) {
    if (rightsData?.length && rightsData[0]?.rights) {
        let userRights = rightsData[0]?.rights;
        const newCheckedStates = {};
        checkboxOptions.forEach(option => {
            newCheckedStates[option.name] = userRights?.includes(option.char);
        });
        return newCheckedStates
    }
}