import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from "mobx-react";
import { useParams } from 'react-router-dom';
import { IUserCreation } from './model';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import userCreationValidation from './validation';
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { Autocomplete, TextField } from '@mui/material';
import apiAgent from '../../APIAgent';
import Cookies from 'js-cookie';

const UserCreation = observer((): JSX.Element => {
  const [UserCreationDetails, setUserCreationDetails] = useState<IUserCreation>({});
  const { supplierMasterStore, userCreationStore, commonStore, outletManagerStore } = useStores();
  console.log(commonStore, 'commonStore');
  const [userRights, setRightsObj] = useState<any[]>([])
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const [errors, setErrors] = useState<any>({});
  const { userId } = useParams<{ userId: string }>();
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 


  const saveUserCreation = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = userCreationValidation(UserCreationDetails);

    setErrors(error)
    if (Object.keys(error).length === 0) {
      setLoading(true);
      if (userId !== undefined) {
        const status = await userCreationStore.updateUserCreation(UserCreationDetails);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        const entryNos = await outletManagerStore.getEntryNo('UserCreation');
        UserCreationDetails.UserId = entryNos.entryNo + 1;
        UserCreationDetails.Code = entryNos.entryNo + 1;
        const status = await userCreationStore.saveUserCreation(UserCreationDetails);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else if (status === "Exist") {
          setLoading(false);
          errors.userExist = "User already exist";
          setErrors(errors);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }
  const handleEmployeeValue = (name, event, val) => {
    if (val != null) {
      setUserCreationDetails({ ...UserCreationDetails, [name]: val.employeeId, ['employeeName']: val.employeeName });

    }
    else {
      UserCreationDetails.employeeName = '';
    }
  }

  async function getUserCreationDet() {

    if (userId !== undefined) {
      try {
        const data = await apiAgent.userCreationMasters.getUserCreation();
        console.log(data, 'data');
        const matchedUser = data.find(user => user.userId == userId);

        if (matchedUser) {
          console.log(matchedUser, 'matchedUser');
          const updatedDetails = {
            ...UserCreationDetails,
            UserId: matchedUser.userId,
            Management: matchedUser.management,
            Staff: matchedUser.staff,
            Code: matchedUser.code,
            employeeName: matchedUser.employeename,
            employeeId: matchedUser.employeeId,
            User_name: matchedUser.user_name,
            Password: matchedUser.password,
            Options: matchedUser.options
          };
          setUserCreationDetails(updatedDetails);
          console.log(UserCreationDetails, 'UserCreationDetails');
          console.log(commonStore.getEmployees, 'commonStore.getEmployees');

        }
      } catch (error) {
       
      }
    }
  }

  const handleCreate = () => {
    navigate('/Admin/UserCreation/list');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/UserCreation/list');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleInputchnage = (e) => {
    let { name, value } = e.target;

    if (value === 'Management') {
      UserCreationDetails.Management = true;
      UserCreationDetails.Staff = false;
      UserCreationDetails.Options = 'Management';
    }
    else if (value === 'Staff') {
      UserCreationDetails.Staff = true;
      UserCreationDetails.Management = false;
      UserCreationDetails.Options = 'Staff';
    }
    setUserCreationDetails({ ...UserCreationDetails, [name]: value });
  };

  async function fetchPreLoadingData() {
    setLoading(false);
    await Promise.all([commonStore.getEmployee(), outletManagerStore.getEntryNo('UserCreation')]);
    const userRights = await Promise.all([userCreationStore?.getUserRight('User_creation')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }

  const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     setLoadModal(true);
  //     fetchPreLoadingData();
  //     getUserCreationDet();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [userRights])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'User_creation';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
             
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/outlet/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }
       
      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <>
        {
          isLoading ? <ProgressBar />
            :
            <>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>User Creation</h3>
                </div>
                <form >
                  <div className='inputBoxLists'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Management</label>
                          <input type='radio' className='' name='options' aria-label="lorem ipsum" checked={UserCreationDetails?.Options === 'Management'} value='Management' onChange={handleInputchnage}></input>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Staff</label>
                          <input type='radio' className='' name='options' aria-label="lorem ipsum" checked={UserCreationDetails?.Options === 'Staff'} value='Staff' onChange={handleInputchnage}></input>
                        </div>
                      </div>
                      {errors.options && <p style={{ color: 'red' }}>{errors.options}</p>}
                      <div className='vertical-space-10'></div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Code</label>
                          <input type="text" className='' style={{ width: "100%" }}
                            onChange={handleInputchnage}
                            name="Code"
                            value={UserCreationDetails.Code = outletManagerStore.entryNo.entryNo + 1}
                            placeholder='Ledger Group ID..' disabled></input>
                          {errors.Code && <p style={{ color: 'red' }}>{errors.Code}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      {
                        UserCreationDetails.Options === 'Staff' ? <div className='col-md-12'>
                          <div className='inputBox'>
                            <label>Employee Name<span>*</span></label>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={commonStore.getEmployees}
                              getOptionLabel={(option: any) => option.employeeName}
                              onChange={(event, val) => handleEmployeeValue('employeeId', event, val)}
                              value={commonStore.getEmployees?.find((option) => option.employeeName === UserCreationDetails.employeeName)}
                              renderInput={(params: any) =>
                                <TextField  {...params} style={{ width: '20ch' }}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Employee Name..'
                                  name='Employee_name'
                                />}
                            />

                            {errors.Employee_name && <p style={{ color: 'red' }}>{errors.Employee_name}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div> : null
                      }


                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>User Name <span>*</span></label>
                          <input type="text" className='' style={{ width: "100%" }}
                            onChange={handleInputchnage}
                            name="User_name"
                            value={UserCreationDetails.User_name}
                            placeholder='User Name..'></input>
                          {errors.User_name && <p style={{ color: 'red' }}>{errors.User_name}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label> Password <span>*</span></label>
                          <input type="Password" className='' style={{ width: "100%" }}
                            onChange={handleInputchnage}
                            name="Password"
                            value={UserCreationDetails.Password}
                            placeholder='Password..'></input>
                          {errors.Password && <p style={{ color: 'red' }}>{errors.Password}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    {
                      errors.userExist && <p style={{ color: 'red' }}>{errors.userExist}</p>
                    }
                  </div>
                  <div className='btnBox'>
                    <button className='secondaryBtn' onClick={handleCreate}>List</button>
                    <button className="dfBtn" type="submit" onClick={saveUserCreation} disabled={!canAdd}
                title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
              >
                Submit
              </button>
                  </div>
                </form>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
        }

      </>
    );
  }
});

export default UserCreation;
