import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { excelKeyupdate, IExcelSheetData, IIExceluploadModal, IMemberPointsStatementRequest } from './model';
import useStores from '../../hooks';
import { Autocomplete, Checkbox, Pagination, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import { convertExcelToArrayOfObjects } from '../../common/shared/convertExcToarrObj/covertExctoArrObj';

interface ExcelSheetData {
  Bill_No?: string;
  Date?: string;
  Bill_Type?: string;
  Bill_Value?: string;
  Points_Earned?: string;
  Points_Redemption?: string;
}

interface ExcelObject {
  [key: string]: any;
}

const MemberPointsStatement = observer((): JSX.Element => {
  const { memberPointsStore } = useStores();

  const { importExportFormSave } = memberPointsStore;

  const [isSpinner, setSpinner] = useState(true);
  const [request, setRequest] = useState<IMemberPointsStatementRequest>(new IMemberPointsStatementRequest());

  const [errors, setErrors] = useState<any>({})

  const [isLoading, setLoading] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isExcel, setExcel] = useState(false)
  const [data, setData] = useState<ExcelObject[]>([]);

  console.log(data, 'data>>')
  const handeInputChange = (e) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  }

  const handleReport = async () => {
    if (request?.startDate && request?.endDate && request?.memberId) {
      setSpinner(true);
      await memberPointsStore.getMemberPointsStatement(request);
      setSpinner(false);
      setErrors({})
    } else {
      let error: any = {
        memberId: 'Select Mobile No.'
      }
      setErrors({ ...error })
    }
  }

  function excelNewSheet() {
    let excelArr = new Array<IExcelSheetData>();
    let intitalObj = new IExcelSheetData();
    excelArr.push(intitalObj)
    const csvContent = [Object.keys(excelArr[0]).join(',')].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Member Point Statement ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function createExportExcelObj(membersPointStatement: any[]) {
    if (membersPointStatement?.length > 0) {
      let excelArr: ExcelSheetData[] = membersPointStatement?.map((member) => {
        const excelObj: ExcelSheetData = {
          Bill_No: member.billNo,
          Date: moment(member.billNDate).format('DD-MM-YYYY'),
          Bill_Type: member.billType,
          Bill_Value: member.billValue,
          Points_Earned: member.earnedPoints,
          Points_Redemption: member.redeemPoints,
        }
        return excelObj;
      })
      exportToExcel(excelArr)
    } else {
      alert(` No Data found `);
    }
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Member Points Statement", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Member Points Statement ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleUploadExcel() {
    let excleMaster: IIExceluploadModal = {};
    let updatedArr = excelKeyupdate(data);
    excleMaster.impsno = 1;
    excleMaster.earnedPoints_import = updatedArr;
    const status = await importExportFormSave(excleMaster)
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const result = await convertExcelToArrayOfObjects(file);
        setData(result);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    }
  };

  async function fetchMemberPointsStatement() {
    await memberPointsStore.loadMobileNumbers();
    setSpinner(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchMemberPointsStatement();
      isCurrentPage.current = false
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (memberPointsStore.memberPointsDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(memberPointsStore?.memberPointsDetails)
    }
  }, [memberPointsStore.memberPointsDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(memberPointsStore.memberPointsDetails, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {isLoading ?
        <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> : <div className='container'>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox LgInputField'>
            <div className='hrBox'>
              <h3>Member Points Statement</h3>
            </div>
            <div className='inputBoxLists'>
              <div className='ItemInwardInputBox'>
                <div className='row'>
                  {
                    isSpinner ?
                      <div className='SpinnerBox'>
                        <Spinner animation="grow" size="sm" />
                      </div> : null
                  }
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>From Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={request?.startDate} name='startDate' onChange={handeInputChange}
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>To Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={request?.endDate} name='endDate' onChange={handeInputChange}
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>Mobile No <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={removeDuplicates(memberPointsStore?.mobileNos, 'mobileNo')}
                        onChange={(event, val: any) => val != null && setRequest({ ...request, mobileNo: val?.mobileNo, memberId: val?.memberId, memberName: val?.memberName })}
                        loading={true}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '100%' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select MobileNo..'
                            name='mobileNo'

                          />}
                      />
                    </div>
                    {errors?.memberId && <p style={{ color: 'red' }}>{errors?.memberId}</p>}
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>Member Name<span>*</span></label>
                      <input type="text" style={{ width: "100%" }} disabled value={request.memberName}></input>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className='btnBox' style={{ marginTop: '25px' }}>
                      <button className='secondaryBtn' onClick={() => createExportExcelObj(memberPointsStore?.memberPointsDetails)}>Excel</button>
                      <button className='secondaryBtn' type='submit' onClick={handleReport}>Generate</button>
                      <button className='secondaryBtn' onClick={excelNewSheet}>Export</button>
                      <button className='secondaryBtn' onClick={() => setExcel(true)}>Import</button>
                    </div>
                  </div>
                </div>
                <div className='vertical-space-10'></div>
                <div style={{ width: '100%', display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                  <div>
                    <div className='btnBox'>
                      <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{memberPointsStore?.memberPointsDetails?.length} Records</button>
                    </div>
                  </div>
                  <div >
                    <Stack spacing={2}>
                      <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                  </div>
                </div>
                <div className='vertical-space-10'></div>
                <div className='tableListDetails'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col"> Bill No </th>
                        <th scope="col"> Date </th>
                        <th scope="col">Bill Type(POS / Online)   </th>
                        <th scope="col">Bill Value </th>
                        <th scope="col">Points Earned</th>
                        <th scope="col">Points Redemption</th>

                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData?.length > 0 &&
                        currentPageData?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.billNo}</td>
                              <td>{moment(val.billDate).format('DD-MM-YYYY')}</td>
                              <td>{val.billType}</td>
                              <td>{val.billValue}</td>
                              <td>{val.earnedPoints}</td>
                              <td>{val.redeemPoints}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className='btnBox'>
                    <div className='inputradio'>
                      <label>Balance Points  :  <span> {memberPointsStore.memberPointsDetails.length > 0 ? memberPointsStore.memberPointsDetails.reduce((a, v) => a = a + v.earnedPoints!, 0) - memberPointsStore.memberPointsDetails.reduce((a, v) => a = a + v.redeemPoints!, 0) : 0}</span></label><br />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <Modal show={isExcel} onHide={() => { setExcel(false); setData([]) }} className='PriceHistoryModel'>
            <Modal.Header closeButton>
              <Modal.Title>Excel Import </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='imgBox'>
                <div className='col-sm-8'>
                  <div className='inputBox'>
                    <label>Upload Excel <span>*</span></label>
                    <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleUploadExcel}>
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Deleted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setFailureModal(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>}
    </>

  )
})

export default MemberPointsStatement;