import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faPrint, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import numberToWords from 'number-to-words';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const OutwardToOutletDetail = observer((): JSX.Element => {
  const { outwardtoOutletStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getOutwardOutlets, getoutwardlist, getOutwardOutletsDetls, getoutwardDetlslist } = outwardtoOutletStore;
  const [isLoading, setLoading] = useState(true);
  const [outwardsToOutletDetails, setOutwardsToOutletDetails] = useState<any>();
  const [isOutwardShow, setOutwardModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteOutwardMaster = async (outwardId) => {
    setLoading(true);
    const status = await outwardtoOutletStore.deleteOutwardOutletMaster(outwardId);

    if (status === 'Success') {
      fetchOutwrads();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchOutwrads();
  }

  async function fetchOutwrads() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Outward_to_outlet'), getOutwardOutlets()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage?.current) {
      fetchOutwrads();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  const editOutward = (entryNo) => {

  }

  const handleOutwardDetails = async (outwardId) => {
    const data = await getOutwardOutletsDetls(outwardId);
    if (data !== undefined) {
      setOutwardModal(true);
    }
  }
  const handleClose = () => {
    setOutwardModal(false);
  }
  const handleCreate = () => {
    navigate('/Admin/OutwardToOutlet');
  }

  const objectRef = useRef<any>({});

  async function handlePrint(val: any) {
    const getOutletDCData = await outwardtoOutletStore.getOutletDCprint(val?.outwardId);
    objectRef.current = getOutletDCData;
    let logo = require('../../../../image/Ganga_Supermarket-1.png')

    const printWindow = window.open("", "_blank");

    console.log(getOutletDCData, 'getOutletDCData>>')
    if (printWindow) {
      printWindow.document.write(
        `
      <html>
        <head>
         <title style="text-align: center ; padding-right: 100px;"  class="centered-div">. </title>
          <style>
            /* Add any additional styles here */
            .table {
              /* Add table styles here */
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              /* Add th and td styles here */
              // border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              /* Add th styles here */
              background-color: #f2f2f2;
            }
            body {
              margin: 0;
              height: 100vh;
              // display: flex;
              // justify-content: center;
              // align-items: center;
            }
            .container {
             display:flex;
            }
        
            .centered-div {
              text-align: center;
            }
            
            .right-div {

            }
            .border-top {
              border-top: 1px solid #ddd; /* Add top border to the element with this class */
          }
          .border-bottom {
              border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
          }
          .border-right {
            border-right: 1px solid #ddd; /* Add top border to the element with this class */
        }
        .border-left {
            border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
        }
        .border{
          /* border: 1px solid #ddd;*/
        }
        .content {
          padding: 20px;
          page-break-before: always; /* Ensure Content 1 starts on a new page */
      }
      @page {
        size: auto;
        margin: 0mm;
        border: 2px solid #ddd;
    }
          </style>
        </head>
        <body >
        <div class = "border content">
        <!-- <div class="container"> -->
        <div style="height: 25px;"></div>
        <div  class="centered-div">Delivery Challan(Original)</div>
        <div style="height: 25px;"></div>
        <!-- <div  class="right-div">(Original)</div>
        </div> -->
          <table class="table table-striped">
            <thead>
            <tr>
                <td colspan="2" class="border-right border-top border-left border-bottom pad-left">
                    <div style="display: flex; align-items: center; justify-content: space-evenly;">
                        <img src=`+ logo + ` width="260" height="170" />
                    </div>
                </td>
                <td colspan="2" class="border-right border-top border-bottom pad-left">
                <div style="display: flex; align-items: center; justify-content: space-evenly;">
                    <div style="text-align: center;">
                        <div style="font-weight: 800; font-size: x-large;">Ganga Super Market</div>
                        <div>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</div>
                        <div>Namakkal - 637002, Tamil Nadu</div>
                        <div>GSTIN/UIN: 33BZUPS6837H1ZL</div>
                        <div>FSSAI No: 10020042007183</div>
                        <div>Contact No: `+ getOutletDCData[0]?.contactno + `</div>
                        <div>Email: gsmsupermarket@gmail.com</div>
                    </div>
                </div>
            </td>
            </tr>
              <tr >
                <th class="border-bottom border-top border-left" scope="col">Ganga Super Market</th>
                <th class="border-bottom border-top  border-right border-left" scope="col">DC No</th>
                <th class="border-bottom border-top  border-right" scope="col">Date</th>
              </tr>
            </thead>` +
        `
          <thead style="padding-bottom: 10px;">
          <tr>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        ` <div>` + getOutletDCData[0]?.warename + "," + ` </div> <div>` + getOutletDCData[0]?.doorno + "," + getOutletDCData[0]?.area + "," + getOutletDCData[0]?.city + "," + getOutletDCData[0]?.pincode + `</div>`


        + `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.outwardId +
        `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outwardDate).format("DD-MMM-YYYY") +
        `</th>
          </tr>
        </thead>
        <thead style="margin-bottom: 20px;">
          <tr>
            <th class="border-bottom border-top border-left" scope="col">Despatch To</th>
            <th class="border-bottom border-top border-left border-right" scope="col">Request No</th>
            <th class="border-bottom border-top border-right" scope="col">Date</th>
          </tr>
        </thead>
        ` +
        `
        <thead>
        <tr>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">`
        +
        ` <div>` + getOutletDCData[0]?.outletname + "," + ` </div> <div>` + getOutletDCData[0]?.outdoorno + "," + getOutletDCData[0]?.outarea + "," + getOutletDCData[0]?.outcity + "," + getOutletDCData[0]?.outpincode + `</div>`


        +
        `</th>          
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.outletItemId +
        `</th>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outletItemDate).format("DD-MMM-YYYY") +
        `</th>
        </tr>
      </thead>
      </table>
      
      <table  class="border-top" style="width: 100%">
          <thead>
            <tr class="">
              <th  class="border-bottom border-left " scope="col">S.No</th>
              <th class="border-left border-right border-bottom " scope="col">Item Description</th>
              <th class=" border-right border-bottom " scope="col">UOM</th>
              <th class=" border-right border-bottom " scope="col">Qty</th>
              <th class=" border-right border-bottom " scope="col">Amount</th>
              <th class="border-bottom border-right" scope="col" style="padding-top: 25px;padding-left: 30px;">Value</th>
              </tr>          
          </thead>
          <thead>
            <tr class="border-bottom">`+
        `<tbody class="border-bottom border-left border-right">
                  ${getOutletDCData.map((each, index) => `
                    <tr class="">
                      <td class="  border-bottom border-left">${index + 1}</td>
                      <td class=" border-left border-right  border-bottom">${each?.itemname}</td>
                      <td class=" border-bottom border-right">${each?.unitname}</td>
                      <td class=" border-bottom border-right">${each?.outletQty}</td>
                      <td class=" border-bottom border-right">${each?.mrp}</td>
                      <td class=" border-bottom border-right" style="padding-top: 8px; padding-left: 30px; font-weight: 300;">${each?.outletQty * each?.mrp}</td>
                    </tr>
                  `).join('')}
              </tbody>
            </tr>         
          </thead>
            <thead>
              <tr class="border-bottom border-left border-right">
                <th class="border-bottom border-left" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom border-left" scope="col">Total</th>
                <th class="border-bottom border-left border-right" scope="col"></th>
                <th class="border-bottom border-right" scope="col">`+ getOutletDCData?.reduce((a, v) => a = a + (v?.outletQty * v?.mrp), 0) + `</th>
              </tr>        
            </thead>
          </table>
          <table style="width: 100%">
          <thead>
          <tr style="height: 50px;"/>
          <tr>
            <th scope="col">
            <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords.toWords(getOutletDCData?.reduce((a, v) => a = a + (v?.outletQty * v?.mrp), 0)) + `</div>
            <div>Amount (In words)</div>
            </th>
          </tr>
        </thead>
        <div style="height: 30px;"></div>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;"> For Ganga Super Market</th>
          </tr>        
        </thead>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;">  Authorised Signatory</th>
          </tr>       
        </thead>
        </table>
          <div style="height: 50px;"></div>
          <div class="centered-div">This is a Computer generated Document</div>
         </div>         
        </body>
      </html>
    `
      );
      printWindow.document.write(
        `
      <html>
        <head>
         <title style="text-align: center ; padding-right: 100px;"  class="centered-div">. </title>
          <style>
            /* Add any additional styles here */
            .table {
              /* Add table styles here */
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              /* Add th and td styles here */
              // border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              /* Add th styles here */
              background-color: #f2f2f2;
            }
            body {
              margin: 0;
              height: 100vh;
              // display: flex;
              // justify-content: center;
              // align-items: center;
            }
            .container {
             display:flex;
            }
        
            .centered-div {
              text-align: center;
            }
            
            .right-div {

            }
            .border-top {
              border-top: 1px solid #ddd; /* Add top border to the element with this class */
          }
          .border-bottom {
              border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
          }
          .border-right {
            border-right: 1px solid #ddd; /* Add top border to the element with this class */
        }
        .border-left {
            border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
        }
        .border{
          /* border: 1px solid #ddd;*/
        }
        .content {
          padding: 20px;
          page-break-before: always; /* Ensure Content 1 starts on a new page */
      }
      @page {
        size: auto;
        margin: 0mm;
        border: 2px solid #ddd;
    }
          </style>
        </head>
        <body >
        <div class = "border content">
        <!-- <div class="container"> -->
        <div style="height: 25px;"></div>
        <div  class="centered-div">Delivery Challan(Duplicate)</div>
        <div style="height: 25px;"></div>
        <!-- <div  class="right-div">(Duplicate)</div>
        </div> -->
          <table class="table table-striped">
            <thead>
              <tr >
                <th class="border-bottom border-top border-left" scope="col">Ganga Super Market</th>
                <th class="border-bottom border-top  border-right border-left" scope="col">DC No</th>
                <th class="border-bottom border-top  border-right" scope="col">Date</th>
              </tr>
            </thead>` +
        `
          <thead style="padding-bottom: 10px;">
          <tr>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        ` <div>` + getOutletDCData[0]?.warename + "," + ` </div> <div>` + getOutletDCData[0]?.doorno + "," + getOutletDCData[0]?.area + "," + getOutletDCData[0]?.city + "," + getOutletDCData[0]?.pincode + `</div>`


        + `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.outwardId +
        `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outwardDate).format("DD-MMM-YYYY") +
        `</th>
          </tr>
        </thead>
        <thead style="margin-bottom: 20px;">
          <tr>
            <th class="border-bottom border-top border-left" scope="col">Despatch To</th>
            <th class="border-bottom border-top border-left border-right" scope="col">Request No</th>
            <th class="border-bottom border-top border-right" scope="col">Date</th>
          </tr>
        </thead>
        ` +
        `
        <thead>
        <tr>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">`
        +
        ` <div>` + getOutletDCData[0]?.outletname + "," + ` </div> <div>` + getOutletDCData[0]?.outdoorno + "," + getOutletDCData[0]?.outarea + "," + getOutletDCData[0]?.outcity + "," + getOutletDCData[0]?.outpincode + `</div>`


        +
        `</th>          
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.outletItemId +
        `</th>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outletItemDate).format("DD-MMM-YYYY") +
        `</th>
        </tr>
      </thead>
      </table>
      
      <table  class="border-top" style="width: 100%">
          <thead>
            <tr class="">
              <th  class="border-bottom border-left " scope="col">S.No</th>
              <th class="border-left border-right border-bottom " scope="col">Item Description</th>
              <th class=" border-right border-bottom " scope="col">UOM</th>
              <th class=" border-right border-bottom " scope="col">Qty</th>
              <th class=" border-right border-bottom " scope="col">Amount</th>
              <th class="border-bottom border-right" scope="col" style="padding-top: 25px;padding-left: 30px;">Value</th>
              </tr>          
          </thead>
          <thead>
            <tr class="border-bottom">`+
        `<tbody class="border-bottom border-left border-right">
                  ${getOutletDCData.map((each, index) => `
                    <tr class="">
                      <td class="  border-bottom border-left">${index + 1}</td>
                      <td class=" border-left border-right  border-bottom">${each?.itemname}</td>
                      <td class=" border-bottom border-right">${each?.unitname}</td>
                      <td class=" border-bottom border-right">${each?.outletQty}</td>
                      <td class=" border-bottom border-right">${each?.mrp}</td>
                      <td class=" border-bottom border-right" style="padding-top: 8px; padding-left: 30px; font-weight: 300;">${each?.outletQty * each?.mrp}</td>
                    </tr>
                  `).join('')}
              </tbody>
            </tr>         
          </thead>
            <thead>
              <tr class="border-bottom border-left border-right">
                <th class="border-bottom border-left" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom border-left" scope="col">Total</th>
                <th class="border-bottom border-left border-right" scope="col"></th>
                <th class="border-bottom border-right" scope="col">`+ getOutletDCData[0]?.amount + `</th>
              </tr>        
            </thead>
          </table>
          <table style="width: 100%">
          <thead>
          <tr style="height: 50px;"/>
          <tr>
            <th scope="col">
            <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords.toWords(getOutletDCData[0]?.amount) + `</div>
            <div>Amount (In words)</div>
            </th>
          </tr>
        </thead>
        <div style="height: 30px;"></div>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;"> For Ganga Super Market</th>
          </tr>        
        </thead>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;">  Authorised Signatory</th>
          </tr>       
        </thead>
        </table>
          <div style="height: 50px;"></div>
          <div class="centered-div">This is a Computer generated Document</div>
         </div>         
        </body>
      </html>
    `
      );

      printWindow.document.close();
      // printWindow.location.replace("Naveen");
      printWindow.print();
    } else {
      console.error("Unable to open print window.");
    }
  };

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (getoutwardlist && getoutwardlist?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(getoutwardlist)
    }
  }, [getoutwardlist])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(getoutwardlist?.slice().sort((a, b) => b.outwardId - a.outwardId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getoutwardlist}
                searchTitles={[{ key: "outletName", value: "Outlet Name" }, { key: "createdByName", value: "Employee Name" }, { key: "wareHouseName", value: "Warehouse Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getoutwardlist?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Outlet Name </th>
                      <th scope='col'>Created by </th>
                      {/* <th scope='col'>Item  </th> */}
                      <th scope='col'>Warehouse  </th>
                      <th></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                      <th scope="col">Print </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.outwardId}</td>
                            <td>{moment(val.outwardDate).format("DD-MMM-YYYY")}</td>
                            <td>{val.outletName} </td>
                            <td>{val.createdByName}</td>
                            {/* <td>{val.itemName} </td> */}
                            <td>{val.wareHouseName}</td>
                            <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => handleOutwardDetails(val.outwardId)}>Outward Details</button></td>
                            <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate} ><FontAwesomeIcon icon={faAdd} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit' onClick={() => editOutward(val.itemId)} ><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete' onClick={() => deleteOutwardMaster(val.outwardId)} ><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                            <td>
                              <button disabled={!userRights?.print} className="Edit" onClick={() => { setOutwardsToOutletDetails(val.outwardId); handlePrint(val); }}>
                                <FontAwesomeIcon icon={faPrint} />
                              </button>
                            </td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>
            </div>
            <Modal show={isOutwardShow} onHide={handleClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Outward Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Item Name</th>
                        <th scope="col">WarehouseStock</th>
                        <th scope="col">Outlet Qty</th>
                        <th scope="col">Outlet Request No</th>
                        <th scope="col">Barcode</th>
                        <th scope="col">Batch No</th>
                        {/* <th scope='col'>Delete </th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {getoutwardDetlslist?.length > 0 ? getoutwardDetlslist?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.itemName}</td>
                            <td>{val.outwardWarehosueStock}</td>
                            <td>{val.outwardOutletQty}</td>
                            <td>{val.outwardRequestNo}</td>
                            <td>{val.barcode}</td>
                            <td>{val.batchno}</td>
                            {/* <td><button className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteOutwardDetail(val.outwardDetailId)} /></button></td> */}
                          </tr>
                        )
                      }) : <>No Data</>
                      }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }

    </>
  );

});

export default OutwardToOutletDetail;
