 

import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from "mobx-react";
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IOutletManager } from './model';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import assignOutletManagerValidation from './validation';
import { removeDuplicates } from '../../common/shared/utils';
import Cookies from 'js-cookie';

const AssignOutletManager = observer((): JSX.Element => {

  const [OutletManagerDetails, setOutletManagerDetails] = useState<IOutletManager>({});

  const { supplierMasterStore,outletManagerStore, commonStore, userCreationStore } = useStores()

  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const { loadOutlet, loadOutlets, loadEmployee, loadEmployees } = commonStore;

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleOutletValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletManagerDetails({ ...OutletManagerDetails, [name]: val.outletId, ['outletName']: val.outletName });
    } else {
      OutletManagerDetails.outletName = '';
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletManagerDetails({ ...OutletManagerDetails, [name]: val.empId, ['employeeName']: val.employeeName });
    } else {
      OutletManagerDetails.employeeName = '';
    }
  }

  const saveOutletManager = async (e) => {
    e.preventDefault();
    let error: any = {};
    error = assignOutletManagerValidation(OutletManagerDetails);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setLoading(true);
      const entryNos = await outletManagerStore.getEntryNo('AssignOutletManager');
      OutletManagerDetails.oMId = entryNos.entryNo + 1;
      const status = await outletManagerStore.saveOutletManager(OutletManagerDetails);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      } else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  };

  const handleCancel = () => {
    navigate('/Admin/AssignOutletManager/AssignOutletManagerDetail');
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignOutletManager/AssignOutletManagerDetail');
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setOutletManagerDetails({ ...OutletManagerDetails, [name]: value });
  };


  async function fetchPreLoadngData() {
    setLoading(false)
    await Promise.all([loadOutlet(), loadEmployee(), outletManagerStore.getEntryNo('AssignOutletManager')])
    const userRights = await Promise.all([userCreationStore?.getUserRight('Assign_outlet_managers')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

  if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }

  // const isCurrentPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     setLoadModal(true);
  //     fetchPreLoadngData();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, []);

  
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Assign_outlet_managers';

  useEffect(() => {
    
    const fetchRights = async () => {
     
      try {
          const data = await supplierMasterStore.fetchRights(empid, formName);
      
         if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();
              
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/AssignOutletManager/AssignOutletManagerDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }
      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <div className='container'>
        {
          isLoading ? <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
        }

        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Assign Outlet Manager</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Entry No </label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="oMId"
                    value={OutletManagerDetails.oMId = outletManagerStore.entryNo.entryNo + 1}
                    placeholder='Entry No..' disabled></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    value={OutletManagerDetails.date = OutletManagerDetails?.date ?? new Date().toISOString().substr(0, 10)}
                    type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="date"
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                  {errors.date && <p style={{ color: 'red' }}>{errors.date}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Outlet Name <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={loadOutlets}
                    getOptionLabel={(option: any) => option.outletName}
                    onChange={(event, val) => handleOutletValue('outletId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Outlet Name..'
                        name='outletName'
                      />}
                  />
                  {errors.outletName && <p style={{ color: 'red' }}>{errors.outletName}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Manager <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={removeDuplicates(loadEmployees, 'employeeName')}
                    getOptionLabel={(option: any) => option.employeeName}
                    onChange={(event, val) => handleEmployeeValue('employeeId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Created by..'
                        name='employeeId'
                      />}
                  />
                  {errors.employeeId && <p style={{ color: 'red' }}>{errors.employeeId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Effective From <span>*</span></label>
                  <input type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="effectiveFrom"
                    value={OutletManagerDetails.effectiveFrom = OutletManagerDetails.effectiveFrom ?? new Date().toISOString().substr(0, 10)}
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={saveOutletManager}>Submit</button>
          </div>
        </div>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
});

export default AssignOutletManager;