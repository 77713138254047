export class IIEmployeePromotion {
    epM_ID?: number = 0
    epM_TicketNo?: number = 0
    epM_EmpID?: number = 0
    epM_CatID?: number = 0
    epM_DeptID?: number = 0
    epM_DegnID?: number = 0
    emppromotiondet?: IIEmpPromotionDet[] = []
    employeepromosaldet?: IIEmployeePromoSalDet[] = []
}

export class IIEmpPromotionDet {
    epD_Date: string = new Date()?.toISOString()?.substr(0, 10)
    epD_EffDate: string = new Date()?.toISOString()?.substr(0, 10)
    epD_CatID?: number = 0
    epD_DegnID?: number = 0
    epD_DeptID?: number = 0
    epD_SalType: string = ""
    epD_ID?: number = 0
}

export class IIEmployeePromoSalDet {
    epS_ID?: number = 0
    epS_GrossSal?: number = 0
    epS_EarnType: string = ""
    epS_Amount?: number = 0
}