import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import moment from 'moment';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IAssignPosSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const AssignPOSStaffDetail = observer((): JSX.Element => {
  const { assignPosStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/AssignPOSStaff');
  }

  const deleteAssignPosDetials = async (entryId) => {
    setLoading(true);
    const status = await assignPosStore.deleteAssignPos(entryId);

    if (status === 'Success') {
      fetchPreloadingData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreloadingData();
  }

  async function fetchPreloadingData() {
    setLoading(false)
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Assign_POS_Staff'), assignPosStore.getAssignPos()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      fetchPreloadingData();
      isCurrenPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (assignPosStore?.assignPosList && assignPosStore?.assignPosList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(assignPosStore?.assignPosList)
    }
  }, [assignPosStore?.assignPosList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(assignPosStore?.assignPosList?.slice()?.sort((a, b) => b.entryId - a.entryId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={assignPosStore?.assignPosList}
                searchTitles={[{ key: "employeeName", value: "Employee Name" }, { key: "shiftName", value: "Shift Name" }, { key: "counterNumber", value: "Counter Number" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{assignPosStore?.assignPosList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Counter Number </th>
                      <th scope='col'>Employee </th>
                      <th scope='col'>Shift </th>
                      <th scope='col'>Effective Form  </th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (!assignPosStore.isLoading && (isSearch ? filteredData : currentPageData)?.length > 0) &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.entryId}</td>
                            <td>{moment(val.entryDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.counterNumber}</td>
                            <td>{val.employeeName}</td>
                            <td>{val.shiftName}</td>
                            <td>{moment(val.effectDate).format('DD-MMM-YYYY')}</td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignPosDetials(val.entryId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>
                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  );

});

export default AssignPOSStaffDetail;
