export class IIPOSSaleSave {
    billNo?: number = 0
    billType?: string = ""
    billDate?: string = ""
    memberId?: number = 0
    mobileNo?: string = ""
    paymentMode?: string = ""
    cardno?: string = ""
    billValue?: number = 0
    discountValue?: number = 0
    taxValue?: number = 0
    othercharges?: string = ""
    netvalue?: number = 0
    employeeId?: number = 0
    terminalId?: number = 0
    deliveryCharges?: number = 0
    memberCategory?: string = ""
    posSalesDetails?: IIPOSItemsDetails[] = []
    posBatchDetails?: IIPOSBatchDetails[] = []
    address?: IIAddressSave;
    earnedPoints?: number = 0
    redeemPoints?: number = 0
    earnedPointsamt?: number = 0
    redeemPointsamt?: number = 0
    outletId?: number = 0
    offerDiscountValue?: number = 0
    isSameAddress?: boolean = true
    cashAmount?: number = 0
    redeemAmount?: number = 0
    salesReturn?: number = 0
    cardAmount?: number = 0
    qRcodeAmount?: number = 0
    onlinestoreorder?: number = 0
    onlinebankTR?: number = 0
    sodexo?: number = 0
}

export class IPaymentInfo {
    cashAmount?: number = 0
    redeemAmount?: number = 0
    salesReturn?: number = 0
    cardAmount?: number = 0
    qRcodeAmount?: number = 0
    onlinestoreorder?: number = 0
    onlinebankTR?: number = 0
    sodexo?: number = 0
    cashMode?: string =""
}

export class IIPOSItemsDetails {
    posid?: number = 0
    posItemId?: number = 0
    posItemQty?: number = 0
    posItemMRP?: number = 0
    posNetAmount?: number = 0
    posDiscountePercentage?: number = 0
    posDiscountValue?: number = 0
    posTaxValue?: number = 0
    posTaxPercentage?: number = 0
    posItemSellingRate?: number = 0
    posTaxId?: number = 0
    posFreeItemId?: number = 0

    //optional
    postRowId?: number = 0;
    posItemHsnCode?: string = "";
    posItemName?: string = "";
    posSubCategoryId?: number = 0;
    posItemDQty?: number = 0;
    posTotalPrice?: number = 0;
    posDiscountPercentage?: number = 0;
    posUnitOdMeasure?: string = "";
    posItemImage?: string = "";
    posComments?: string = "";
    posbatchNo?: string = "";
    posBarcode?: string = "";
    posbatchid?: number = 0;
    posbatchqty?: number = 0;
    posDiscountMode?: string = "";
    PosTaxId?: number = 0;
    memberType?: string = "";
    availableQty?: number = 0;
    outletId?: number = 0;
    checked?: boolean;

}

export class IIAddressSave {
    name?: string = ""
    mobileNumber?: string = ""
    doorNoStreet?: string = ""
    area?: string = ""
    landMark?: string = ""
    city?: string = ""
    state?: string = ""
    country?: string = ""
    pinCode?: string = ""
}

export class IIPOSBatchDetails {
    itemId?: number = 0
    batchId?: number = 0
    barcode?: string = ""
    batchNo?: string = ""
    batchqty?: number = 0
}

export class IEntryNoRequest {
    flag?: string = "";
    outletId?: number = 0;
}

export class IMemberDetails {
    memberName?: string = "";
    memberType?: string = "";
    memberCatId?: string = "";
    memberCategory?: string = "";
    memberAddress?: string = "";
    memberEmail?: string = "";
    memberId?: number = 0;
    mobileNo?: string = "";
    earnedPoints?: number = 0;
    balanceEarnedPoints?: number = 0;
    redeemPoints?: number = 0;
}