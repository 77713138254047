import React, { useState,useEffect,ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {Form, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function GenerateInvoiceDetail() {
  const [invoice, setInvoice] = useState(false);
  const [item, setItem] = useState(false);

  const invoiceClose = () => setInvoice(false);
  const invoiceShow = () => setInvoice(true);

  const itemClose = () => setItem(false);
  const itemShow = () => setItem(true);

  return (
    <div className='container-fluid'>
    <div className='vertical-space-20'></div>     
      <div className='tableListDetails'>
        <table className="table table-striped">
          <thead>
            <tr>                    
             
              <th scope='col'>Despatch No  </th>
              <th scope='col'>Despatch Date   </th>                       
              <th scope='col'>Order No  </th>                       
              <th scope='col'>Order Date  </th>                       
              <th scope='col'>Customer  </th>                       
              <th scope='col'>Billing Address  </th>                       
              <th scope='col'>Shipping Address  </th>                       
              <th scope='col'>GSTIN</th>                       
              <th scope='col'></th>
              <th scope='col'>Invoice</th>
              <th scope='col'></th>

            </tr>
          </thead>
          <tbody>
            <tr>
            
              <td>575/4565/5  </td>             
              <td>01-12-2023 </td>              
              <td>5656/3473 </td>              
              <td>31-11-2023 </td>              
              <td>Customer </td>              
              <td>Address </td>              
              <td>Address </td>              
              <td>54645634 </td>              
              <td><button onClick={invoiceShow} className='tablemodeltext'>Generate Invoice</button></td>
              <td ><input  type="text"/> </td>
              <td><button onClick={itemShow} className='tablemodeltext'>Item Details</button></td>

            </tr>           
          </tbody>
        </table>
      </div>
      <Modal show={invoice} onHide={invoiceClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Generate Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                 
                </tr>
              </thead>
              <tbody>
                <tr>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={item} onHide={itemClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                 
                </tr>
              </thead>
              <tbody>
                <tr>
                 
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

};

export default GenerateInvoiceDetail;
