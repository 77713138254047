import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { Autocomplete, TextField } from '@mui/material';
import { ICourierEntry } from './model';
import useStores from '../../hooks';
import courierEntryValidation from './validation';
import moment from 'moment';

function CourierEntry() {
  const [courierEntryDetails, setCourierEntryDetails] = useState<ICourierEntry>({});
  const { supplierMasterStore, courierEntryStore, inwardOultetStore } = useStores();
  const [isSpinner, setSpinner] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const [errors, setErrors] = useState<any>({});

  const employeename = Cookies.get('employee_name');
  const userId = Cookies.get('employeeId');
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/Admin/CourierEntry/CourierEntryDetail');
  }

  const saveCourierEntry = async (e) => {

    e.preventDefault();
    let error: any = {};
    error = courierEntryValidation(courierEntryDetails);
    setErrors(error)
    if (Object.keys(error).length === 0) {
      setLoading(true);
      const entryNos = await inwardOultetStore.getEntryNo('CourierEntry');

      courierEntryDetails.courierEntryid = entryNos.entryNo + 1
      courierEntryDetails.userId = Number(userId)
      courierEntryDetails.docketNo = Number(courierEntryDetails.docketNo)

      const status = await courierEntryStore.saveCourierEntry(courierEntryDetails);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      }

      else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }
  const handleDespatchNo = async (name, event, val) => {
    if (val != null) {


      setCourierEntryDetails({
        ...courierEntryDetails, [name]: val.despatchNo, ['despatchDate']: val.orderDespatchdate, orderNoDate: val.orderNoDate,
        customer: val.customer, shippingAddress: val.shippingAddress, customerId: val.customerId, orderMasterId: val.orderMasterId
      }
      );
    }
    else {
      courierEntryDetails.despatchNo = 0;
    }
  }



  const handleCourierNo = async (name, event, val) => {
    if (val != null) {
      setCourierEntryDetails({ ...courierEntryDetails, [name]: val.courierId, courierPatnerId: val.courierId, courierName: val.courierName });

    }
    else {
      courierEntryDetails.courieritemId = 0;
      courierEntryDetails.courierName = '';
    }
  }
  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setCourierEntryDetails({ ...courierEntryDetails, [name]: value });
  };

  const handleSuccessModalClose = () => {
    navigate('/Admin/CourierEntry/CourierEntryDetail');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const handleFailureModalClose = () => setFailureModal(false);

  async function fetchOultetItems() {

    await inwardOultetStore.getEntryNo('CourierEntry')
    await courierEntryStore.loadDespatchNo();
    await courierEntryStore.loadCourierName();

    setLoading(false);
  }

  //  useEffect(() => {
  //     fetchOultetItems();
  //   },[]);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Courier_entry';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchOultetItems();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/CourierEntry/CourierEntryDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {



    return (
      
        <>
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Courier Entry</h3>
              </div>
              <div className='inputBoxLists pd-b-0'>
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Enter  No  <span>*</span></label>
                        <input type="text" className=''
                          onChange={handleInputchnage}
                          name="courierEntryid"
                          value={courierEntryDetails.courierEntryid = inwardOultetStore.entryNo.entryNo + 1}
                          disabled style={{ width: "100%" }} placeholder='Despatch No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Enter Despatch No  <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={courierEntryStore.despatchNoDetails}
                          getOptionLabel={(option: any) => option.despatchNo}
                          onChange={(event, val) => handleDespatchNo('despatchNo', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '25ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Despatch No..'
                              name='despatchNo'
                            />}
                        />
                        {errors.despatchNo && <p style={{ color: 'red' }}>{errors.despatchNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label> Despatch Date <span>*</span></label>
                        <input type="text"
                          value={moment(courierEntryDetails.despatchDate).format('DD-MMM-YYYY')} name='despatchDate'
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Order No & Date <span>*</span></label>
                        <input type="text" className=''
                          value={courierEntryDetails.orderNoDate} name='orderMasterId'
                          style={{ width: "100%" }} placeholder=' Order No & Date..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label> Customer <span>*</span></label>
                        <input type="text" className=''
                          value={courierEntryDetails.customer} name='customerId'
                          style={{ width: "100%" }} placeholder=' Customer..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label> Shipping Address <span>*</span></label>
                        <input type="text" className=''
                          value={courierEntryDetails.shippingAddress} name='shippingAddress'
                          style={{ width: "100%" }} placeholder=' Shipping Address..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Courier Partner <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={courierEntryStore.courierNameDetails}
                          getOptionLabel={(option: any) => option.courierName}
                          onChange={(event, val) => handleCourierNo('courierPatnerId', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '25ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Courier Name ..'
                              name='courierPatnerId'
                            />}
                        />
                        {errors.courierPatnerId && <p style={{ color: 'red' }}>{errors.courierPatnerId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Docket No<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }}
                          value={courierEntryDetails.docketNo}
                          onChange={handleInputchnage}
                          name='docketNo'
                          placeholder='Docket No..'></input>
                        {errors.docketNo && <p style={{ color: 'red' }}>{errors.docketNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Name Of The User<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }} value={Cookies.get('employee_name')} placeholder='Name Of The User..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>

                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' type='submit' onClick={saveCourierEntry}>Submit</button>
              </div>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
 
    );
  }

};


export default CourierEntry;
