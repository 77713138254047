import { useEffect, useState, useRef } from "react";
import ProgressBar from "../../common/shared/progressbar";
import { IIBillEntry, IIBillEntryDetails, IIBillEntryTaxDetails } from "./model";
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { useNavigate } from "react-router-dom";
import { Modal, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const BillEntry = () => {

    const { jobMasterStore, supplierMasterStore, paymentVocherStore, ledgerMasterStore } = useStores()

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [supplierList, setSupplierList] = useState<any[]>([])
    const [byPaymentLedger, setByPayment] = useState<any[]>([])

    const [billEntry, setBillEntry] = useState<IIBillEntry>(new IIBillEntry())
    const [billEntryDetails, setBillEntryDetails] = useState<IIBillEntryDetails>(new IIBillEntryDetails())
    const [billEntryTaxDet, setBillEntryTaxDet] = useState<IIBillEntryTaxDetails>(new IIBillEntryTaxDetails())

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => navigate('/Admin/BillEntry/List');

    function handleInputChange(event) {
        const { name, value } = event?.target;
        handleAutoComplete(name, value)
    }

    function handleAutoComplete(name, value) {
        setBillEntry({ ...billEntry, [name]: value })
    }

    function handleAddTaxDet(index) {
        if (index !== -1) {
            billEntry?.billentrytaxdetail?.splice(index, 1)
            setBillEntry({ ...billEntry })
            setBillEntryTaxDet(new IIBillEntryTaxDetails())
        } else if (billEntryTaxDet?.inpuT_SERVICE_DETAILS_ID && billEntryTaxDet?.amount && billEntryTaxDet?.taX_VALUE && billEntryTaxDet?.service_tax_ledger_id) {
            billEntry.billentrytaxdetail?.push(billEntryTaxDet)
            setBillEntry({ ...billEntry })
            setBillEntryTaxDet(new IIBillEntryTaxDetails())
        }
    }

    function handleAddDetails(index) {
        if (billEntryDetails?.input_Service_Ledger_Id && billEntryDetails?.amount && billEntry?.supplier_ledger_accout_id) {
            billEntryDetails['supplier_Ledger_Id'] = billEntry?.supplier_ledger_accout_id
            billEntry.billentrydetail?.push(billEntryDetails)
            setBillEntry({ ...billEntry })
            setBillEntryDetails(new IIBillEntryDetails())
        }
    }

    async function handleSaveBillEntry() {
        let error: any = {}
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            const status = await ledgerMasterStore.saveBillEntry(billEntry)
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }


    function updateSupplierList(supplierList) {
        let supplierArrList: any[] = [];
        if (supplierList?.length) {
            let addressArr: any[] = [];
            supplierList?.forEach((each, index) => {
                addressArr = each?.address?.split(',')
                if (addressArr?.length) {
                    each['doorNo'] = addressArr[0];
                    each['area'] = addressArr[1];
                    each['city'] = addressArr[2];
                    each['state'] = addressArr[3];
                    each['country'] = addressArr[4];
                }
                return each;
            })
            supplierArrList = supplierList
        }
        return supplierArrList;
    }

    async function initialApiCals() {
        const entryNo = await jobMasterStore.getHREntryNo('BillEntry');
        setBillEntry({ ...billEntry, record_no: (entryNo?.entrynumber) })
        const supplierMaster = await supplierMasterStore.getSupplierMaster();
        if (supplierMaster?.length) {
            setSupplierList([...updateSupplierList(supplierMaster)])
        }
        let byPaym = {
            "flag": "BY",
            "type": "PAYMENT"
        }
        const byPayment = await paymentVocherStore?.loadLedgers(byPaym)
        setByPayment(byPayment)
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCals()
            isCurrentPage.current = false
        }
    }, [])

    function calcRoundOff() {
        let total: number = 0;
        let decimal = 0;
        let roundOff: number = 0;
        total = billEntry?.bill_amount ?? 0;
        decimal = Math.round(total);
        roundOff = total - decimal;
        let plusMinus = - roundOff;
        return plusMinus?.toFixed(2);
    }

    return (
        <div>
            {isLoading ? <ProgressBar /> :
                <div className="container">
                    <div className="outletInputField inputFormBox LgInputField">
                        <div className='hrBox'>
                            <h3>Bill Entry</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className="row">
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Record No </label>
                                        <input type="text" className='' style={{ width: "100%" }} name='record_no'
                                            value={billEntry?.record_no} disabled
                                            onChange={handleInputChange} placeholder='Record No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                            onChange={handleInputChange}
                                            value={billEntry?.record_date} name='record_date'
                                            data-date="" data-date-format="DD MMMM YYYY"></input>
                                        {errors.record_date && <p style={{ color: 'red' }}>{errors.record_date}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Bill No </label>
                                        <input type="text" className='' style={{ width: "100%" }} name='bill_no'
                                            value={billEntry?.bill_no}
                                            onChange={handleInputChange} placeholder='Bill No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Bill Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                            onChange={handleInputChange}
                                            value={billEntry.bill_date} name='bill_date'
                                            data-date="" data-date-format="DD MMMM YYYY"></input>
                                        {errors.bill_date && <p style={{ color: 'red' }}>{errors.bill_date}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className="col-md-4"></div>
                                <div className='col-md-5'>
                                    <div className='inputBox'>
                                        <label>Supplier </label>
                                        <AutoComplete clsName="full-width" options={supplierList} value={supplierList?.find((each) => each?.Supid === billEntry?.supplier_ledger_accout_id)?.SupplierName} getOptionLabel='SupplierName'
                                            emitOption={(option) => setBillEntry({ ...billEntry, supplier_ledger_accout_id: option?.Supid })} placeholder='Select Supplier..'
                                        />
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='inputBox'>
                                        <label>Bill Amount </label>
                                        <input type="number" className='' style={{ width: "100%" }} name='bill_amount'
                                            value={billEntry?.bill_amount}
                                            onChange={(event) => handleAutoComplete('bill_amount', Number(event?.target?.value))} placeholder='Bill Amount..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>Input Service </label>
                                                        <AutoComplete clsName="full-width" options={byPaymentLedger} value={byPaymentLedger?.find((each) => each?.ledgerAccountId === billEntryDetails?.input_ser_ledger_accout_id)?.ledgerAccountName ?? ""} getOptionLabel='ledgerAccountName'
                                                            emitOption={(option) => setBillEntryDetails({ ...billEntryDetails, input_Service_Ledger_Id: option?.ledgerAccountId, input_ser_ledger_accout_id: option?.ledgerAccountId })} placeholder='Select Input Services..'
                                                        />
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className='inputBox'>
                                                        <label>Amount</label>
                                                        <input type="number" className='' style={{ width: "100%" }} name='amount'
                                                            value={billEntryDetails?.amount}
                                                            onChange={(event) => setBillEntryDetails({ ...billEntryDetails, amount: Number(event?.target?.value) })}
                                                            placeholder='Amount..'></input>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-sm-4'>
                                                    <div className='btnBox justify-flex-start pd-t-0'>
                                                        <button className='dfBtn' type='submit' onClick={() => handleAddDetails(-1)}>ADD</button>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className='tableBox'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Input Service</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {billEntry?.billentrydetail?.map((each, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{byPaymentLedger?.find((obj) => obj?.ledgerAccountId === each?.input_ser_ledger_accout_id)?.ledgerAccountName}</td>
                                                                            <td>{each?.amount}</td>
                                                                            <td>
                                                                                <input type="checkbox"
                                                                                    onChange={(event) => setBillEntryTaxDet({
                                                                                        ...billEntryTaxDet,
                                                                                        st_ledger_accout_id: event?.target?.checked ? each?.input_ser_ledger_accout_id : 0,
                                                                                        inpuT_SERVICE_DETAILS_ID: event?.target?.checked ? each?.input_ser_ledger_accout_id : 0,
                                                                                        taxcal_amount: each?.amount!
                                                                                    })} />
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className='col-md-3'>
                                                    <div className='inputBox'>
                                                        <label>GST </label>
                                                        <AutoComplete clsName="full-width" getOptionLabel='ledgerAccountName'
                                                            options={byPaymentLedger} disabled={!billEntryTaxDet?.st_ledger_accout_id}
                                                            value={byPaymentLedger?.find((each) => each?.ledgerAccountId === billEntryTaxDet?.service_tax_ledger_id)?.ledgerAccountName ?? ""}
                                                            emitOption={(option) => setBillEntryTaxDet({ ...billEntryTaxDet, service_tax_ledger_id: option?.ledgerAccountId })} placeholder='Select GST..'
                                                        />
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='inputBox'>
                                                        <label>Tax Value </label>
                                                        <input type="text" className='' style={{ width: "100%" }} name='taX_VALUE'
                                                            value={billEntryTaxDet?.taX_VALUE} disabled={!billEntryTaxDet?.st_ledger_accout_id}
                                                            onChange={(event) => setBillEntryTaxDet({
                                                                ...billEntryTaxDet,
                                                                taX_VALUE: Number(event?.target?.value),
                                                                amount: billEntryTaxDet?.taxcal_amount! * (Number(event?.target?.value) / 100)
                                                            })} placeholder='tax value..'></input>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-md-3'>
                                                    <div className='inputBox'>
                                                        <label>Amount </label>
                                                        <input type="number" className='' style={{ width: "100%" }} name='amount'
                                                            value={billEntryTaxDet?.amount} disabled={!billEntryTaxDet?.st_ledger_accout_id}
                                                            onChange={(event) => setBillEntryTaxDet({ ...billEntryTaxDet, amount: Number(event?.target?.value) })}
                                                            placeholder='tax value..'></input>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                                <div className='col-sm-3'>
                                                    <div className='btnBox justify-flex-start pd-t-0'>
                                                        <button className='dfBtn' type='submit' onClick={() => handleAddTaxDet(-1)}>ADD</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className='tableBox'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">GST</th>
                                                                    <th scope="col">Tax value</th>
                                                                    <th scope="col">Amount</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {billEntry?.billentrytaxdetail?.map((each, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{byPaymentLedger?.find((obj) => obj?.ledgerAccountId === each?.service_tax_ledger_id)?.ledgerAccountName}</td>
                                                                            <td>{each?.taX_VALUE}</td>
                                                                            <td>{each?.amount}</td>
                                                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddTaxDet(index)} /></button></td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='vertical-space-20'></div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='col-md-3'>
                                    <div className='inputBox'>
                                        <label>Round Off </label>
                                        <input type="text" className='' style={{ width: "100%" }} name='round_off'
                                            value={billEntry.round_off! = Number(calcRoundOff())}
                                            disabled placeholder='Round off..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputBox'>
                                        <div className='vertical-space-20'></div>
                                        <input type="text" className='' style={{ width: "100%" }} name='record_no'
                                            value={billEntry?.record_no} disabled
                                            onChange={handleInputChange} placeholder='Record No..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputBox'>
                                        <label>Bill Amount</label>
                                        <input type="text" className='' style={{ width: "100%" }} name='bill_amount'
                                            value={billEntry.bill_amount} disabled
                                            placeholder='Bill Amount..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-8'>
                                    <div className='inputBox'>
                                        <label>Narration</label>
                                        <textarea className='' style={{ width: "100%" }} name='narration'
                                            value={billEntry?.narration}
                                            onChange={handleInputChange} placeholder='Narration..'></textarea>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' >List</button>
                            <button className='dfBtn' type='submit' onClick={handleSaveBillEntry} >Submit</button>
                        </div>
                    </div>
                </div>
            }
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/checked.png')} />
                        </div>

                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>

                        <div className='imgBox'>
                            <Image src={require('../../../../image/warning.png')} />
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BillEntry;