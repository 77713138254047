import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import { Modal } from 'react-bootstrap'

interface DeliveryAddressProps {
    isNewAddress: boolean,
    holdClose: () => void,
    countryOptions: Array<any>,
    handleCountryChange: (countryId, event, val) => void,
    errors: any,
    handleAddressInputChange: (e) => void,
    address: any,
    cityOptions: Array<any>,
    handleNewAddress: () => void,
    handleCityChange: (cityId, event, val) => void,
    isDelivery: boolean,
    handleInputChange: (e) => void,
    memberDetail: any,
    handleAddress: (e) => void,
    handleNewDeliverAddress: () => void,
}

const DeliveryAddressModal: React.FC<DeliveryAddressProps> = ({ isNewAddress, holdClose, countryOptions,
    handleCountryChange, errors, handleAddressInputChange, address, cityOptions, handleNewAddress,
    handleCityChange, isDelivery, handleInputChange, memberDetail, handleAddress, handleNewDeliverAddress }) => {
    return (
        <div className="">
            <Modal show={isNewAddress} onHide={holdClose} className=''>
                <Modal.Header closeButton>
                    <Modal.Title>Delivery Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col-sm-12'>
                        <div className=' inputFormBox'>
                            <div className='inputBoxLists' >
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='inputBox'>
                                            <label>Country / Region  <span>*</span></label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={countryOptions}
                                                getOptionLabel={(option: any) => option.name}
                                                onChange={(event, val) => handleCountryChange('countryId', event, val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params} style={{ width: '25ch' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Country/Region'
                                                        name='country'
                                                    />}
                                            />
                                            {errors.country && <p style={{ color: 'red' }}>{errors.country}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>

                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Full Name (First and Last name) </label>
                                            <input type="text" className='fullInput' name='name'
                                                onChange={handleAddressInputChange} value={address.name}
                                                style={{ width: "100%" }} placeholder='Enter Full Name..'></input>
                                            {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>

                                    </div>

                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Mobile Number</label>
                                            <input type="text" className='fullInput'
                                                onChange={handleAddressInputChange} value={address.mobileNumber}
                                                name='mobileNumber'
                                                style={{ width: "100%" }} placeholder='Enter Your Mobile Number..'></input>
                                            {errors.mobileNumber && <p style={{ color: 'red' }}>{errors.mobileNumber}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>

                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>PIN Code </label>
                                            <input type="text" className='fullInput'
                                                name='pinCode'
                                                onChange={handleAddressInputChange} value={address.pinCode}
                                                style={{ width: "100%" }} placeholder='Enter Your PIN Code..'></input>
                                            {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>

                                    </div>
                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Door No / Street </label>
                                            <input type="text" className='fullInput'
                                                name='doorNoStreet'
                                                onChange={handleAddressInputChange} value={address.doorNoStreet}
                                                style={{ width: "100%" }} placeholder='Enter Your DoorNo..'></input>
                                            {errors.doorNoStreet && <p style={{ color: 'red' }}>{errors.doorNoStreet}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>

                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Area</label>
                                            <input type="text" className='fullInput'
                                                name='area'
                                                onChange={handleAddressInputChange} value={address.area}
                                                style={{ width: "100%" }} placeholder='Enter Your Area..'></input>
                                            {errors.area && <p style={{ color: 'red' }}>{errors.area}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Landmark</label>
                                            <input type="text" className='fullInput'
                                                name='landMark'
                                                onChange={handleAddressInputChange} value={address.landMark}
                                                style={{ width: "100%" }} placeholder='Enter Your Landmark..'></input>
                                            {errors.landMark && <p style={{ color: 'red' }}>{errors.landMark}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>City</label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={cityOptions}
                                                getOptionLabel={(option: any) => option.name}
                                                onChange={(event, val) => handleCityChange('cityId', event, val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params} style={{ width: '21ch' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select City'
                                                        name='city'
                                                    />}
                                            />
                                            {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>State</label>
                                            <input type="text" className='fullInput'
                                                name='state' value={address.state}
                                                style={{ width: "100%" }} disabled></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='vertical-space-30'></div>
                                    <div className='col-sm-6 carddetailbtn2'>
                                        <button className='usethisbtn btn-warning' onClick={handleNewAddress}>Use this Address </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isDelivery} onHide={holdClose} className=''>
                <Modal.Header closeButton>
                    <Modal.Title>Delivery Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="">
                        <div className="inputFormBox">
                            <div className="inputBoxLists">
                                <label>{memberDetail?.memberAddress}</label>
                                <div className="inputBox">
                                    <input type='text' className='textbox' name='deliveryCharge' onChange={handleInputChange} placeholder='Delivery Charges' style={{ width: '60%' }} />
                                </div>
                                <div className='vertical-space-30'></div>
                                <div className="carddetailbtn2">
                                    <button className='usethisbtn btn-warning' onClick={handleAddress}>Use this Address </button>
                                    <button className='usethisbtn btn-warning' onClick={handleNewDeliverAddress}>Add New Address </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
export default DeliveryAddressModal;