import { faAdd, faPenToSquare, faPrint, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react'
import { Accordion, Button, Image, Modal, Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import { IPurchaseOrderSearchOptions } from './modal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import numberToWords from 'number-to-words';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const PurchaseOrderList = () => {

  const { purchaseOrderStore, supplierMasterStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();

  const [purcOdrSearchOption, setpurcOdrSearchOption] = useState<IPurchaseOrderSearchOptions>(new IPurchaseOrderSearchOptions())
  const [purchaseOrderList, setPurchaeOrderList] = useState<any[]>([])
  const [purcItemList, setPurcItemList] = useState<any[]>([])
  const [termsAndConditions, setTermsAndConditions] = useState<any[]>([])

  const [purhcaseSchedule, setPurchaseSchedule] = useState<any[]>([])
  const [scheduleModal, setSheduleModal] = useState({ bool: false, itemId: 0 })

  const [supplierList, setSupplierList] = useState<any[]>([])

  const [errors, setErrors] = useState<any>({})
  const [outwardsToOutletDetails, setOutwardsToOutletDetails] = useState<any>();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [isLoading, setIsLoading] = useState(true);
  const [isSpinner, setSpinner] = useState(false)
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [isFailureModal, setFailureModal] = useState(false)
  const [isExpRegShow, setExpRegShow] = useState(false)

  const objectRef = useRef<any>({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setpurcOdrSearchOption({ ...purcOdrSearchOption, [name]: value })
  }

  async function handlePrintItem(val) {
    setSpinner(true)
    const [getOutletDCData, loadPurchaseTermsNCondition, loadPurchaseSchedule] = await Promise.all([purchaseOrderStore.purchaseOrderPrint(val?.Purchase_Order_No),
    purchaseOrderStore.purchaseorderTeamsandcondition(), purchaseOrderStore?.purchaseOrderScheduleGet()
    ]);
    setSpinner(false)
    let filterArr: any[] = loadPurchaseTermsNCondition?.filter((each) => each?.Purchase_Order_Id == val?.purchase_order_id)
    let findSupplier = supplierList?.find((each) => each?.Supid === getOutletDCData[0]?.Supplier_id)
    let filterSchedule: any[] = loadPurchaseSchedule?.filter((each) => each?.Purchase_Order_Id == val?.purchase_order_id)
    console.log(filterSchedule, 'filterSchedule>>', getOutletDCData)
    objectRef.current = getOutletDCData;
    const printWindow = window.open("", "_blank");
    let logo = require('../../../../image/Ganga_Supermarket-1.png')
    if (printWindow) {
      printWindow.document.write(`<html>

<head>
    <title style="text-align: center ; padding-right: 100px;" class="centered-div">. </title>
    <style>
        /* Add any additional styles here */
        .font-bold {
            font-weight: 900;
        }

        .table {
            /* Add table styles here */
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
        }

        th,
        td {
            /* Add th and td styles here */
            padding: 0;
            height: 20px !important;
            text-align: left;
        }

        th {
            /* Add th styles here */
            background-color: #f2f2f2;
        }

        body {
            margin: 0;
            height: 100vh;
        }

        .container {
            display: flex;
        }

        .centered-div {
            text-align: center;
        }

        .right-div {
          text-align: right;
        }

        .border-top {
            border-top: 1px solid #ddd;
            /* Add top border to the element with this class */
        }

        .border-bottom {
            border-bottom: 1px solid #ddd;
            /* Add bottom border to the element with this class */
        }

        .border-right {
            border-right: 1px solid #ddd;
            /* Add top border to the element with this class */
        }

        .border-left {
            border-left: 1px solid #ddd;
            /* Add bottom border to the element with this class */
        }

        .border {
            /* border: 1px solid #ddd;*/
        }

        .content {
            padding: 20px;
            page-break-before: always;
        }

        .pad-left {
            padding: 4px 0px 4px 12px;
        }

        .pad {
            padding: 4px;
        }

        @page {
            size: auto;
            margin: 0mm;
            border: 2px solid #ddd;
        }
    </style>
</head>

<body>
    <div class="border content">
        <div style="height: 25px;"></div>
        <div class="centered-div font-bold">Purchase Order</div>
        <div style="height: 25px;"></div>

        <table style="width: 100%; border: 1px solid black">
            <tr>
                <td colspan="2" class="border-right border-top border-left border-bottom pad-left">
                    <div style="display: flex; align-items: center; justify-content: space-evenly;">
                        <img src=`+ logo + ` width="260" height="170" />
                    </div>
                </td>
                <td colspan="2" class="border-right border-top border-bottom pad-left">
                <div style="display: flex; align-items: center; justify-content: space-evenly;">
                    <div style="text-align: center;">
                        <div style="font-weight: 800; font-size: x-large;">Ganga Super Market</div>
                        <div>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</div>
                        <div>Namakkal - 637002, Tamil Nadu</div>
                        <div>GSTIN/UIN: 33BZUPS6837H1ZL</div>
                        <div>FSSAI No: 10020042007183</div>
                        <div>Contact No: `+ getOutletDCData[0]?.contactno + `</div>
                        <div>Email: gsmsupermarket@gmail.com</div>
                    </div>
                </div>
            </td>
            </tr>

            <tr>
                <td class="border-right border-bottom pad">
                    <div>
                        <span>Purchase Order No</span>
                        <div style="font-weight: 800;">` + getOutletDCData[0].Purchase_Order_No + `</div>
                    </div>
                </td>
                <td class="border-right border-bottom pad">
                    <div>
                        <span>Date</span>
                        <div style="font-weight: 800;"> ` + moment(getOutletDCData[0].outwardDate).format("DD-MMM-YYYY")
        + `</div>
                    </div>
                </td>
                <td class="border-right border-bottom pad">
                    <div>
                        <span>PO Validity</span>
                        <div style="font-weight: 800;">` +
        moment(getOutletDCData[0]?.Povalidity_date).format("DD-MMM-YYYY") + `</div>
                    </div>
                </td>
                <td class="border-right border-bottom pad">
                    <div>
                        <span> Mode / Terms of Payment</span>
                        <div style="font-weight: 800;">` + getOutletDCData[0].mode_of_payment + `/` + getOutletDCData[0]?.payment_terms + `</div>
                    </div>
                </td>
            </tr>
            <tr>
                <!-- <td class="border-right"></td> -->
                <td class="border-right border-bottom pad">
                    <div>
                        <span> Supplier' s Ref / Order No</span>
                        <div style="font-weight: 800;">` + getOutletDCData[0]?.supplier_ref + `</div>
                    </div>
                </td>
                <td class="border-right border-bottom pad">
                    <div>
                        <span> Other Reference(s)</span>
                        <div style="font-weight: 800;">` + getOutletDCData[0]?.quotation_refno + `</div>
                    </div>
                </td>
                <!-- <td class="border-right"></td> -->
                <td class="border-right border-bottom pad">
                    <div>
                        <span>Despatch through</span>
                        <div style="font-weight: 800;">` + getOutletDCData[0]?.packing_forward + `</div>
                    </div>
                </td>
                <td class="border-right border-bottom pad">
                    <div>
                        <span>Destination</span>
                        <div style="font-weight: 800;"></div>
                    </div>
                </td>
            </tr>

            <tr>
                <td colspan="2" class="border-right pad-left">
                    <span style="font-weight: 800;">Supplier</span>
                    <div>
                        <div style="font-weight: 800; padding: 4px 0; font-size: small;">`+ findSupplier?.SupplierName + `</div>
                        <div style="display: flex; flex-direction: column; gap: 4px">
                            <span> `+ findSupplier?.doorNo + ", " + findSupplier?.area + ", " + findSupplier?.city + ", " + findSupplier?.state + `</span>
                            <span>Phone : `+ findSupplier?.phoneNo + `</span>
                            <span>GSTIN : `+ findSupplier?.gstIn + `</span>
                        </div>
                    </div>
                </td>
                <td class="border-right pad-left" colspan="2">
                    <span style="font-weight: 800;">Despatch to:</span>
                    <div>
                        <div style="font-weight: 800; padding: 4px 0; font-size: small; text-transform: capitalize">`+ getOutletDCData[0]?.despatch_city + `</div>
                        <div style="display: flex; flex-direction: column;gap: 4px">
                            <span>` + getOutletDCData[0]?.contactperson + ", " + `</span>
                            <span>` + getOutletDCData[0]?.despatch_to + ", " + getOutletDCData[0]?.despatch_address1 + ", " + getOutletDCData[0]?.despatch_city + ", " + getOutletDCData[0]?.despatch_state + ", " + `</span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

        <table style="width: 100%; border: 1px solid black;">
            <tr>
                <th class="border-bottom border-left border-right" scope="col">S.No</th>
                <th class="border-bottom border-right" scope="col">Item Description</th>
                <th class="border-bottom border-right" scope="col">Schedule Date -- Qty</th>
                <th class="border-bottom border-right" scope="col">UOM</th>
                <th class="border-bottom border-right" scope="col">Quantity</th>
                <th class="border-bottom border-right" scope="col">Rate</th>
                <th class="border-bottom border-right" scope="col">Amount</th>
            </tr>
            ${getOutletDCData?.map((each, index) => `
                <tr>
                    <td class="border-bottom border-left border-right" scope="col">${index + 1}</td>
                    <td class="border-bottom border-right" scope="col">${each?.itemname}</td>
                    <td class="border-bottom border-right" scope="col">
                    ${filterSchedule?.map((schedule) => `
                      <div>
                        ${each?.Purchase_Order_Id === schedule?.Purchase_Order_Id && each?.itemid === schedule?.itemid ?
            ` ${moment(schedule?.delivery_date)?.format('DD-MMM-YYYY')} -- ${schedule?.schedule_qty}` :
            ""}
                      </div>
                    `)?.join('')}
                    </td >
                    <td class="border-bottom border-right" scope="col">${each?.Unitname}</td>
                    <td class="border-bottom border-right" scope="col">${each?.purchase_qty}</td>
                    <td class="border-bottom border-right" scope="col">${each?.purchase_rate}</td>
                    <td class="border-bottom border-right" scope="col">${(Number(each?.purchase_qty ?? 0) * Number(each?.purchase_rate ?? 0))?.toFixed(2)}</td>
                </tr >
            `)?.join('')}
            <tr>
                <td colspan="3" class="border-bottom"></td>
                <td colspan="2" class="border-right border-bottom" scope="col">SubTotal</td>
                <td class="border-right border-bottom" scope="col">`+ getOutletDCData[0]?.sub_total + `</td>
            </tr>
            <tr>
                <td colspan="3" class="border-bottom"></td>
                <td colspan="2" class="border-right border-bottom" scope="col">Packing Charges</td>
                <td class="border-right border-bottom" scope="col">`+ getOutletDCData[0]?.packing_charges + `</td>
            </tr>
            <tr>
                <td colspan="3" class="border-bottom"></td>
                <td colspan="2" class="border-right border-bottom" scope="col">Discount</td>
                <td class="border-right border-bottom" scope="col">`+ getOutletDCData[0]?.discount + `</td>
            </tr>
            <tr>
                <td colspan="3" class="border-bottom"></td>
                <td colspan="2" class="border-right border-bottom" scope="col">Tax Amount</td>
                <td class="border-right border-bottom" scope="col">`+ getOutletDCData[0]?.tax_amount + `</td>
            </tr>
            <tr>
                <td colspan="3" class="border-bottom"></td>
                <td colspan="2" class="border-right border-bottom" scope="col">Round Off</td>
                <td class="border-right border-bottom" scope="col">`+ getOutletDCData[0]?.round_off + `</td>
            </tr>
            <tr>
                <td colspan="3"></td>
                <td colspan="2" class="border-right" scope="col">Net Amount</td>
                <td class="border-right" scope="col">`+ getOutletDCData[0]?.net_amount + `</td>
            </tr>
        </table >
        <div style="height: 20px;"></div>
        <table>
            <tr style="margin-top: 10px;">
                <th scope="col">
                    <div>Amount Charageable (Inwords)</div>
                    <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords?.toWords(getOutletDCData[0]?.net_amount) + `</div>
                </th>
            </tr>
            <tr style="margin-top: 10px;">
              <th scope="col">Terms of Delivery</th >
            </tr >
            <tr>
              <td>
                ${filterArr?.map((obj) => (`<span>${obj?.Teamsandcondition}</span>`))}
              <br />
              </td>
            </tr>
            <tr>
              <th style="font-weight: 300; margin-top: 10px;">
                Declaration We declare that this invoice shows the actual price of the goods described and that
                all particulars are true and correct.
              </th>
            </tr>
          </table >
          <div style="height: 20px;"></div>
          <div class="right-div font-bold" style="height: 20px;">For Ganga Super Market</div>
          <div style="height: 30px;"></div>
          <div class="right-div font-bold" style="height: 20px;">Authorised Signatory</div>
          <div class="centered-div" style="height: 20px;">This is a Computer generated Document</div>
        </div >
      </body >
    </html >
  `)


      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Unable to open print window.");
    }
  };

  async function handleGenerate() {
    if (purcOdrSearchOption?.fromDate && purcOdrSearchOption?.toDate) {
      // setIsLoading(true)
      const purchaseOrders = await purchaseOrderStore.getPurchaseOrders(purcOdrSearchOption);
      if (purchaseOrders?.length) {
        setPurchaeOrderList([...purchaseOrders])
      } else {
        setPurchaeOrderList([])
      }
    }
  }

  useEffect(() => {
    handleGenerate()
  }, [purcOdrSearchOption?.fromDate, purcOdrSearchOption?.toDate])

  async function getExpiryRegDetail(purchase_order_id) {
    setSpinner(true)
    const [productDetails, loadPurchaseTermsNCondition, loadPurchaseSchedule] = await Promise.all([purchaseOrderStore.getPurchaseOrderDetails(purchase_order_id),
    purchaseOrderStore.purchaseorderTeamsandcondition(), purchaseOrderStore?.purchaseOrderScheduleGet()
    ])
    let filterSchedule: any[] = loadPurchaseSchedule?.filter((each) => each?.Purchase_Order_Id == purchase_order_id)
    setPurchaseSchedule([...filterSchedule])
    setSpinner(false)
    let filterArr: any[] = loadPurchaseTermsNCondition?.filter((each) => each?.Purchase_Order_Id == purchase_order_id)
    setTermsAndConditions([...filterArr])
    setPurcItemList(productDetails)
    setExpRegShow(true)
  }
  async function deleteExpReg(purchase_order_id) {
    let index = purchaseOrderList?.findIndex((order) => order?.purchase_order_id === purchase_order_id)
    if (index >= 0) {
      purchaseOrderList?.splice(index, 1)
      setPurchaeOrderList(purchaseOrderList)
      await purchaseOrderStore.deletePurchaseOrder(purchase_order_id)

    }
  }

  function handleCreate() {
    navigate('/Admin/PurchaseOrder')
  }

  function handleEdit(purchOrdId) {
    // navigate('/Admin/PurchaseOrder/' + purchOrdId)
  }

  function updateSupplierList(supplierList) {
    let supplierArrList: any[] = [];
    if (supplierList?.length) {
      let addressArr: any[] = [];
      supplierList?.forEach((each, index) => {
        addressArr = each?.address?.split(',')
        if (addressArr?.length) {
          each['doorNo'] = addressArr[0];
          each['area'] = addressArr[1];
          each['city'] = addressArr[2];
          each['state'] = addressArr[3];
          each['country'] = addressArr[4];
        }
        return each;
      })
      supplierArrList = supplierList
    }
    return supplierArrList;
  }

  async function initialApiCall() {
    setIsLoading(false)
    const [userRights, supplierMaster] = await Promise.all([userCreationStore?.getUserRight('Purchase_order'), supplierMasterStore.getSupplierMaster()])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    if (supplierMaster?.length) {
      setSupplierList([...updateSupplierList(supplierMaster)])
    }
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      initialApiCall();
      isCurrenPage.current = false;
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (purchaseOrderList && purchaseOrderList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(purchaseOrderList)
    }
  }, [purchaseOrderList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(purchaseOrderList?.slice()?.sort((a, b) => Number(b?.Purchase_Order_No?.split('-')[1]) - Number(a?.Purchase_Order_No?.split('-')[1])), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            {isSpinner &&
              <div className='SpinnerBox'>
                <Spinner animation="grow" size="sm" />
              </div>}
            <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
              <div className='' style={{ width: '90%' }}>
                <div className='container-fluid'>
                  <div className='vertical-space-20'></div>
                  <div className='outletInputField inputFormBox LgInputField'>
                    <div className="hrBox">
                      <h3>Purchase Order List</h3>
                    </div>
                    <div className='inputBoxLists'>
                      <div className='ItemInwardInputBox'>
                        <div className='row'>
                          <div className="col-sm-2">
                            <div className='inputBox'>
                              <label>From Date <span>*</span></label>
                              <input type="date"
                                value={purcOdrSearchOption.fromDate}
                                style={{ height: "51px", width: '100%' }}
                                name="fromDate"
                                onChange={handleInputChange}
                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                              ></input>
                            </div>
                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                          </div>
                          <div className="col-sm-2">
                            <div className='inputBox'>
                              <label>To Date <span>*</span></label>
                              <input type="date" style={{ height: "51px", width: '100%' }}
                                name="toDate"
                                onChange={handleInputChange} value={purcOdrSearchOption.toDate}
                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                              ></input>
                            </div>
                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                          </div>
                          <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={purchaseOrderList}
                            searchTitles={[{ key: "Purchase_Order_No", value: "PO No" }, { key: "SupplierName", value: "Vendor" }]}
                            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} isEmitClear emitClear={() => setpurcOdrSearchOption(new IPurchaseOrderSearchOptions())} />
                        </div>
                      </div>
                      <div className='vertical-space-20'></div>
                      <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='btnBox'>
                          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{purchaseOrderList?.length} Records</button>
                        </div>
                        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                        </Stack>
                      </div>
                      <div className='vertical-space-20'></div>
                      <div id='printablediv' className='tableListDetails'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope='col'>PO No</th>
                              <th scope='col'>Purchase Order Date</th>
                              <th scope='col'>Supplier Name</th>
                              <th scope='col'>Sub Total</th>
                              <th scope='col'>Tax Amount</th>
                              <th scope='col'>Discount</th>
                              <th scope='col'>Packing Charges</th>
                              <th scope='col'>Net Amount</th>
                              <th></th>
                              <th scope='col'>Create </th>
                              <th scope='col'>Update </th>
                              <th scope='col'>Delete </th>
                              <th scope='col'>Print</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(isSearch ? filteredData : currentPageData)?.length ?
                              (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.Purchase_Order_No}</td>
                                    <td>{val.podate}</td>
                                    <td>{val.SupplierName}</td>
                                    <td>{val.sub_total}</td>
                                    <td>{val.tax_amount}</td>
                                    <td>{val?.discount}</td>
                                    <td>{val?.packing_charges}</td>
                                    <td>{val.net_amount}</td>
                                    <td><button disabled={!userRights?.view} onClick={() => getExpiryRegDetail(val.purchase_order_id)}>Detail</button></td>
                                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => handleEdit(val.Purchase_Order_No)} /></button></td>
                                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteExpReg(val.purchase_order_id)} /></button></td>
                                    <td><button disabled={!userRights?.print} className="Edit" onClick={() => { setOutwardsToOutletDetails(val.outwardId); handlePrintItem(val); }}><FontAwesomeIcon icon={faPrint} /></button></td>
                                  </tr>
                                )
                              }
                              ) : <div>No Data Found</div>}
                          </tbody>
                        </table>
                      </div>

                      <Modal show={isExpRegShow} onHide={() => setExpRegShow(false)} className='PriceHistoryModel min-w'>
                        <Modal.Header closeButton>
                          <Modal.Title>Detail</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Purchase Info</Accordion.Header>
                              <Accordion.Body>
                                <div className='tableBox'>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope='col'>Despatch to</th>
                                        <th scope='col'>Door No</th>
                                        <th scope='col'>Area</th>
                                        <th scope='col'>City</th>
                                        <th scope='col'>State</th>
                                        <th scope='col'>Contact Person </th>
                                        <th scope='col'>Contact No</th>
                                        <th scope='col'>Remarks</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {purcItemList?.map((val, key) => {
                                        return key == 0 && (
                                          <tr key={key}>
                                            <td>{val.despatch_to}</td>
                                            <td>{val?.despatch_address1}</td>
                                            <td>{val.despatch_address2}</td>
                                            <td>{val.despatch_city}</td>
                                            <td>{val.despatch_state}</td>
                                            <td>{val.contactperson}</td>
                                            <td>{val.contactno}</td>
                                            <td>{val?.remarks}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                              <Accordion.Header>Item Info</Accordion.Header>
                              <Accordion.Body>
                                <div className='tableBox'>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope='col'>Item Name</th>
                                        <th scope='col'>Purc Qty</th>
                                        <th scope='col'>Purc Rate</th>
                                        <th scope='col'>Tax Amt</th>
                                        {/* <th scope='col'>Packing Ledger</th> */}
                                        <th scope='col'>Item Total</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {purcItemList?.map((val, key) => {
                                        console.log(purcItemList, 'purcItemList>>')
                                        return (
                                          <tr key={key}>
                                            <td>{val.itemname}</td>
                                            <td>{val.purchase_qty}</td>
                                            <td>{val.purchase_rate}</td>
                                            <td>{val.tax_amount}</td>
                                            {/* <td>{val.packing_ledger}</td> */}
                                            <td>{val.itemtotal1}</td>
                                            <td><button onClick={() => setSheduleModal({ bool: true, itemId: val?.itemid })}>Schedule</button></td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                              <Accordion.Header>Other Info</Accordion.Header>
                              <Accordion.Body>
                                <div className='tableBox'>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope='col'>Delivery Date</th>
                                        <th scope='col'>Mode of payment</th>
                                        <th scope='col'>PO Validity</th>
                                        <th scope='col'>Mode Of Dispatch</th>
                                        <th scope='col'>Quotation Ref.No</th>
                                        <th scope='col'>Transport Terms</th>
                                        <th scope='col'>Payment Term</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {purcItemList?.map((val, key) => {
                                        return key == 0 && (
                                          <tr key={key}>
                                            <td>{moment(val?.Delivery_date)?.format('DD-MMM-YYYY')}</td>
                                            <td>{val?.mode_of_payment}</td>
                                            <td>{moment(val?.Povalidity_date).format('DD-MMM-YYYY')}</td>
                                            <td>{val.packing_forward}</td>
                                            <td>{val.quotation_refno}</td>
                                            <td>{val.transport}</td>
                                            <td>{val.payment_terms}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                              <Accordion.Header>Terms and Conditions</Accordion.Header>
                              <Accordion.Body>
                                <div className='tableBox'>
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope='col'>Terms & Conditions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {termsAndConditions?.map((val, key) => {
                                        return (
                                          <tr key={key}>
                                            <td>{val?.Teamsandcondition}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </Modal.Body>
                      </Modal>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal show={scheduleModal?.bool} onHide={() => setSheduleModal({ bool: false, itemId: 0 })} className='PriceHistoryModal flexImp'>
              <Modal.Header closeButton>Schedule Deatils</Modal.Header>
              <Modal.Body>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope='col'>Schedule No</th>
                        <th scope='col'>Schedule Date</th>
                        <th scope='col'>Schedule Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purhcaseSchedule?.map((val, key) => {
                        return scheduleModal?.itemId === val?.itemid && (
                          <tr key={key}>
                            <td>{val?.schedule_no}</td>
                            <td>{moment(val?.delivery_date)?.format('DD-MMM-YYYY')}</td>
                            <td>{val?.schedule_qty}</td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );
}

export default PurchaseOrderList