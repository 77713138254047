import { Autocomplete, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import OutletStockSummaryValidation from './validation';
import { getCurrentMonthFirstDate } from '../../common/shared/utils';

interface ExcelSheetData {
    Date: string,
    Item: string,
    Category: string,
    Sub_Category: string,
    Brand: string,
    Barcode: string,
    Batch_No: string,
    Stock: string,
    UOM: string,
    Purch_Stock: string,
    Purc_UOM: string
}

class LocalFields {
    toDate?: string = getCurrentMonthFirstDate()?.currentDate;
    category?: string;
    subCategoryId?: number;
    itemId?: number;
    brand?: string
}
const StockSummaryWarehouse = observer((): JSX.Element => {

    const { outwardtoOutletStore, commonStore } = useStores();
    const { loadSubcategory,
        loadSubcategories, loadItemConversion, loadItemConversions, loadBrands, loadbrands } = commonStore;

    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false, brand: false })
    const [stockSummaryItemWiseFields, setStockSummaryItemWiseFields] = useState<LocalFields>(new LocalFields())
    const [stockSummaryItemWise, setStockSummaryItemWise] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && stockSummaryItemWise?.length > 0) {
            return stockSummaryItemWise?.filter((stock) => {
                if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setStockSummaryItemWiseFields({ ...stockSummaryItemWiseFields, [name]: value })
    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;
        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false, brand: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false, brand: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true, brand: false })
        }
        if (value === "Brand") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: true })
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setStockSummaryItemWiseFields({ ...stockSummaryItemWiseFields, [type]: value.subCategoryId });
        } else if (type === "brand") {
            setStockSummaryItemWiseFields({ ...stockSummaryItemWiseFields, [type]: value.brandCode });
        } else {
            setStockSummaryItemWiseFields({ ...stockSummaryItemWiseFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = OutletStockSummaryValidation(stockSummaryItemWiseFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                "warehouseId": 0,
                "itemId": stockSummaryItemWiseFields?.itemId,
                "subCategoryId": stockSummaryItemWiseFields?.subCategoryId,
                "category": stockSummaryItemWiseFields?.category?.toString(),
                "toDate": stockSummaryItemWiseFields?.toDate,
                "brand": stockSummaryItemWiseFields?.brand,
            }
            const stockSummaryDetails = await outwardtoOutletStore.getStockSummaryAsOnJson(body);
            setStockSummaryItemWise(Object.assign([], stockSummaryDetails))
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Date: moment(stock?.datetime).format("DD-MMM-YYYY"),
                Item: stock?.itemname,
                Category: stock?.Category,
                Sub_Category: stock?.subcategoryname,
                Brand: stock?.brandname,
                Barcode: stock?.barcode,
                Batch_No: stock?.batchno,
                Stock: stock?.balanceqty,
                UOM: stock?.convunitname ?? stock?.unitOfMeasure,
                Purch_Stock: stock?.billqty,
                Purc_UOM: stock?.purunitname ?? stock?.unitOfMeasure
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Sales Summary Itemwise - Warehouse", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Sales Summary Itemwise - Warehouse ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function calcSalesTotal(saleSummary: any[], type: string) {
        if (type == 'saleQty') {
            const totalSaleQty = saleSummary?.reduce((acc, obj: any) => acc + (obj.inqty ?? 0), 0)
            return totalSaleQty;
        } else {
            const totalSaleValue = saleSummary?.reduce((acc, obj: any) => acc + (obj.amount ?? 0), 0)
            return totalSaleValue;
        }
    }
    const isInitialRender = useRef(true);

    const objectMap: Map<string, any[]> = stockSummaryItemWise.reduce((map, obj) => {
        const key = obj.Warename;
        const currentArray = map.get(key) || []; // Get the existing array or create a new one
        currentArray.push(obj); // Push the object into the array
        map.set(key, currentArray); // Update the Map with the new array
        return map;
    }, new Map<string, any[]>());

    async function fetchStockSummary() {
        await loadItemConversion();
        await loadBrands()
        await loadSubcategory();
        setLoading(false)
    }

    useEffect(() => {
        if (isInitialRender.current) {
            fetchStockSummary();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])
    return (
        <>
            <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                <div className='' style={{ width: '100%' }}>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Stock Summary Item Wise - Warehouse</h3>
                        </div>
                        {loading ?
                            <div className='SpinnerBox'>
                                <Spinner animation="border" role="status"></Spinner>
                            </div> : <></>
                        }
                        <div className='inputBoxLists'>
                            <div className='ItemInwardInputBox'>
                                <div className="row">
                                    <div className="col-sm-2"></div>
                                    <div className="col-sm-2">
                                        <div className='inputBox'>
                                            <label style={{ fontSize: "small" }}>As on Date <span>*</span></label>
                                            <input type="date" style={{ width: "100%" }} name="toDate"
                                                onChange={handleInputChange}
                                                value={stockSummaryItemWiseFields.toDate}
                                                placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                            ></input>
                                        </div>
                                        {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                    </div>
                                    <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                        <div className='inputBox' style={{ marginTop: '5px', padding: "10px 10px" }}>
                                            <select style={{ width: "175px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                                <option value="">Select Search Criteria</option>
                                                <option value="ItemName">Item Name</option>
                                                <option value="Category">CateGory </option>
                                                <option value="SubCategory">Sub Category </option>
                                                <option value="Brand">Brand</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                        {searchCriteria?.category ?
                                            <>
                                                <div className='inputBox'>
                                                    <select style={{ marginTop: "0px", height: "47px" }} className="form-selected" onChange={handleInputChange} id="Category" name="category">
                                                        <option value="" selected>Not Select</option>
                                                        <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                        <option value="Pets">Pets</option>
                                                        <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                        <option value="Grocery">Grocery</option>
                                                    </select>
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </> : searchCriteria?.itemName || searchCriteria?.subCategory || searchCriteria?.brand ?
                                                <div className='inputBox'>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={searchCriteria?.itemName ? loadItemConversions : searchCriteria?.subCategory ? loadSubcategories : loadbrands}
                                                        getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                        onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : searchCriteria?.subCategory ? handleValue('subCategoryId', event, val) : handleValue("brand", event, val)}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params}
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                                name={searchCriteria?.itemName ? 'itemId' : searchCriteria?.subCategory ? 'subcategory' : 'brandCompany'}
                                                            />}
                                                    />
                                                </div> :
                                                <div className="">

                                                </div>
                                        }
                                    </div>
                                    <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                        <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                    </div>
                                    <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                        <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(stockSummaryItemWise)}>Excel</button>
                                    </div>
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Sub Category</th>
                                            <th scope="col">Brand</th>
                                            <th scope='col'>Barcode</th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">Stock</th>
                                            <th scope="col">UOM</th>
                                            <th scope="col">Purc. Stock</th>
                                            <th scope='col'>UOM</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(objectMap)?.map(([key, value]) => {
                                            return (
                                                <>
                                                    <h6 style={{ fontWeight: 800, color: 'currentcolor', padding: '19px 0 0 19px', textTransform: 'capitalize' }}>{key}</h6>
                                                    {value?.map((stock, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td scope="col">{stock?.itemname}</td>
                                                                <td scope="col">{stock?.Category}</td>
                                                                <td scope="col">{stock?.subcategoryname}</td>
                                                                <td scope='col'>{stock?.brandname}</td>
                                                                <td scope='col'>{stock?.barcode}</td>
                                                                <td scope="col">{stock?.batchno}</td>
                                                                <td scope="col">{stock?.balanceqty}</td>
                                                                <td scope='col'>{stock?.convunitname ?? stock?.unitOfMeasure}</td>
                                                                <td scope="col">{stock?.billqty}</td>
                                                                <td scope='col'>{stock?.purunitname ?? stock?.unitOfMeasure}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

export default StockSummaryWarehouse;