import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IIInterveiwMaster } from '../model';

class IInterviewStore {
    constructor() {
        makeObservable(this);
    }

    saveInterviewMaster = async (jobMaster: IIInterveiwMaster[]) => {
        try {
            const status = await apiAgent.interviewMaster.saveInterviewMaster(jobMaster);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadInterviewMaster = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadInterviewMaster();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveInterviewLetter = async (jobvacancy) => {
        try {
            const status = await apiAgent.interviewMaster.saveInterviewLetter(jobvacancy);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadInterviewLetter = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadInterviewLetter();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveInterviewProcessMaster = async (interviewProcess) => {
        try {
            const status = await apiAgent.interviewMaster.saveInterviewProcessMaster(interviewProcess);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadInterviewProcessMaster = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadInterviewProcessMaster();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveInterviewSelectionMaster = async (interviewSelection) => {
        try {
            const status = await apiAgent.interviewMaster.saveInterviewSelectionMaster(interviewSelection);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadInterviewSelectionMaster = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadInterviewSelectionMaster();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadIntervieProcessSelectiondetails = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadIntervieProcessSelectiondetails();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveSelectionApproval = async (selectionApproval) => {
        try {
            const status = await apiAgent.interviewMaster.saveSelectionApproval(selectionApproval);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadSelectionApproval = async () => {
        try {
            const jobMaster = await apiAgent.interviewMaster.loadSelectionApproval();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    
    saveOfferLetter = async (appoinmentProcess, empId) => {
        try {
            const status = await apiAgent.interviewMaster.saveOfferLetter(appoinmentProcess);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }


    loadOfferLetterMaster = async () => {
        try {
            const loadAppoinmentProcesss = await apiAgent.interviewMaster.loadOfferLetterMaster();
            return JSON.parse(loadAppoinmentProcesss);
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default IInterviewStore;