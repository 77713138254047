import moment from "moment";

export const areObjectsEqual = <T extends Record<string, any>>(obj1: T, obj2: T): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const value1 = obj1[key];
        const value2 = obj2[key];

        // You can customize the equality check based on the type of values
        if (value1 !== value2) {
            return false;
        }
    }

    return true;
};

interface CurrentPageProps {
    totalPages: number,
    currentPageData: Array<any>
}
export const currentPageDatas = (data: Array<any>, currentPage: number, itemsPerPage: number): CurrentPageProps => {
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    // Calculate the index range of data items for the current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, data?.length);
    // Get the data items for the current page
    const currentPageData = data?.slice(startIndex, endIndex);
    return { totalPages, currentPageData }
}

export const removeDuplicates = (array: any[], id): any[] => {
    if (array?.length) {
        const uniqueItems: Record<number, any> = {};
        array.forEach(supplier => {
            uniqueItems[supplier[`${id}`]] = supplier;
        });
        return Object.values(uniqueItems);
    } else {
        return [];
    }
};

export const extractUniqueCategories = (items: any[]): string[] => {
    // Use Set to store unique categories
    const uniqueCategories: Set<string> = new Set();
    // Iterate over items and add categories to the set
    items?.forEach(item => {
        if (item?.posCategory) {
            uniqueCategories.add(item?.posCategory);
        }
    });

    // Convert Set to array and return
    return Array.from(uniqueCategories);
};



export const calculateTimeDiff = (startTime, endTime) => {
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    // Create Date objects with today's date but different times
    const startDate = new Date();
    startDate.setHours(startHour, startMinute, 0, 0);

    const endDate = new Date();
    endDate.setHours(endHour, endMinute, 0, 0);

    // Calculate the time difference in milliseconds
    const timeDiffMs = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to hours and minutes
    const hoursDiff = Math.floor(timeDiffMs / (1000 * 60 * 60));
    const minutesDiff = Math.floor((timeDiffMs % (1000 * 60 * 60)) / (1000 * 60));

    let hoursDiffStr = hoursDiff < 10 ? `0${hoursDiff}` : `${hoursDiff}`
    let minutesDiffStr = minutesDiff < 10 ? `0${minutesDiff}` : `${minutesDiff}`

    return `${hoursDiffStr}:${minutesDiffStr}`
}

export const getMinutesFromMidnight = (time: string): number => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
};

export const calculateTimeDifference = (startTime: string, endTime: string): string => {
    const startMinutes = getMinutesFromMidnight(startTime);
    const endMinutes = getMinutesFromMidnight(endTime);

    // Handle the case when endTime is on the next day
    if (endMinutes < startMinutes) {
        return formatTime((endMinutes + 24 * 60) - startMinutes);
    }

    return formatTime(endMinutes - startMinutes);
};

export const calculateOTHours = (startTime: string, endTime: string, shiftDuration: string): string => {
    const parsedTime = moment(shiftDuration, 'HH');
    let findShifHRS: any = parsedTime.format('HH:mm');
    const inMinutes = getMinutesFromMidnight(startTime);
    const outMinutes = getMinutesFromMidnight(endTime);
    const shiftMinutes = getMinutesFromMidnight(findShifHRS?.toString());

    let totalWorkedMinutes = outMinutes - inMinutes;

    // Handle cases where time crosses midnight
    if (totalWorkedMinutes < 0) {
        totalWorkedMinutes += 24 * 60; // Assuming a 24-hour day
    }

    // Calculate overtime
    let overtimeMinutes = totalWorkedMinutes - shiftMinutes;
    if (overtimeMinutes < 0) {
        overtimeMinutes = 0; // No overtime if worked less than the shift duration
    }

    return formatTime(overtimeMinutes)
};

const formatTime = (minutes: number): string => {

    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    let hourStr = '00';
    let minStr = '00';

    if (hours < 10) {
        hourStr = `0${hours}`
    } else {
        hourStr = hours?.toString()
    }

    if (mins < 10) {
        minStr = `0${mins}`
    } else {
        minStr = mins?.toString()
    }
    return `${hourStr}:${minStr}`;
};

export const handleFindToDate = (value, type) => {
    const selectedFromDate = value;
    // Calculate toDate by adding 1 day to fromDate
    const fromDateObj = new Date(selectedFromDate);
    const toDateObj = new Date(fromDateObj);
    toDateObj.setDate(fromDateObj.getDate() + (type === "date" ? 1 : 0)); // Adding 1 day
    let num = type === "date" ? 10 : 16;
    return toDateObj.toISOString().slice(0, num);
};

export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

export function IsNumber(value: any, defaultValue?: any) {
    if (!isNaN(Number(value))) {
        return Number(value)
    } else {
        return defaultValue
    }
};

export function getCurrentMonthFirstDate() {
    const currentMonthFirstDate = moment().startOf('month').format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD')

    return { firstDate: currentMonthFirstDate, currentDate: currentDate };
}

export const getMonthDates = (month: number, year: number): { firstDate: Date, lastDate: Date } => {
    const firstDate = moment({ year, month: month - 1 }).startOf('month').toDate();
    const lastDate = moment({ year, month: month - 1 }).endOf('month').toDate();
    return { firstDate, lastDate };
};

export const splitWord = (word: string, length: number) => {
    const regex = new RegExp(`.{1,${length}}`, 'g');
    return word.match(regex) || [];
};

export const toFixedReduce = (originalNumber: any) => {
    let formattedNumber: string = '';
    if (originalNumber) {
        const parts: string[] = originalNumber?.toString()?.split('.');
        formattedNumber = `${parts[0]}.${parts[1].slice(0, 2)}`;
        return formattedNumber;
    } else {
        return formattedNumber
    }
}

export const fillArrayWithNulls = (daysInMonth: number, data: { day: number, value: any }[]): (any | null)[] => {
    const filledArray = new Array(daysInMonth).fill(null);
    data.forEach(item => {
        if (item.day > 0 && item.day <= daysInMonth) {
            filledArray[item.day - 1] = item.value;
        }
    });
    return filledArray;
}

export const filterItemsWithNumericKeys = (items: any[]): any[] => {
    let fileterdArray: any = [];
    items?.map((each) => {
        Object?.keys(each)?.map((key, index) => {
            return !isNaN(Number(key)) ? fileterdArray?.push({ day: key, present: each[`${key}`] }) : undefined
        })
    })
    return fileterdArray
};

export const initializeAttendanceForMonth = (students: any[], totalDays: number): any[] => {
    return students.map(student => {
        const attendanceMap = new Map<number, { day: string, present: string | null }>(
            student.attendance.map(record => [Number(record.day), record])
        );

        const fullAttendance = Array.from({ length: totalDays }, (_, i) => {
            const day = i + 1;
            return attendanceMap.get(day) || { day, present: null };
        });

        return { ...student, attendance: fullAttendance };
    });
};



const mergeArrays = (details: any[], dateQuantities: any[]): any[] => {
    return details.map(detail => {
        // Merge the detail with matching date and quantity
        return dateQuantities.map(dq => ({
            ...detail,
            deliveryDate: dq.delivery_date,
            scheQuantity: dq.schedule_qty,
        }));
    }).flat();
};

export function calculateItemAndFreeQty(totalQty: number, splitQty: number) {
    let itemCount: number = 0;
    let freeCount: number = 0;

    for (let i = 0; i < totalQty; i++) {
        if (i % (splitQty + 1) === 0) {
            itemCount++;
        } else {
            freeCount++;
        }
    }

    return { itemCount, freeCount };
}

export function sortedData(unOrderedList, date: string) {
    let orderedList = unOrderedList?.slice()?.sort((a, b) => {
        const dateA = new Date(a[date]);
        const dateB = new Date(b[date]);
        if (dateA > dateB) return -1;
        if (dateB < dateA) return 1;
        return 0;
    })
    return orderedList;
}