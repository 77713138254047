
function itemMasterValidation(itemDetails, suppliers, supplier) {
  let errors: any = {};
  if (!itemDetails.itemName) {
    errors.itemName = "Enter Item Name";
  }
  if (!itemDetails.itemCategory) {
    errors.itemCategory = "Select Item Category";
  }
  if (!itemDetails.subCategoryId) {
    errors.subcategoryName = "Select SubCategory";
  }
  if (!itemDetails.brand) {
    errors.brand = "Select Brand";
  }
  if (itemDetails.brandType === "Own Brand" && !itemDetails.expiryDays) {
    errors.expiryDays = "Enter Exipry Days";
    
  }
  // if (itemDetails.brandType === "Own Brand" && !itemDetails.extraCode) {
  //   errors.extraCode = "Enter Barcode";
  // }
  // if (!itemDetails.unitName) {
  //   errors.unitOfMeasure = "Select Unit Of Measure";
  // }
  // if (itemDetails?.ismsqty && !itemDetails?.msqty) {
  //   errors.msqty = 'Enter Min. Sales Qty'
  // }
  // if (itemDetails.brandType !== "Own Brand") {
  //   if (itemDetails !== undefined && suppliers.length === 0) {
  //     if (!supplier.supplierId) {
  //       errors.supplierId = "Select Supplier Name";
  //     }
  //     if (!supplier.commissionPercentage) {
  //       errors.commissionPercentage = "Enter Commission Percentage";
  //     }
  //   }
  // }
  return errors;
};

export default itemMasterValidation;