import { observable } from "mobx";
export interface IManagePriceChange{
    detls?:any[]
}
export interface IManagePriceOfChanges{
    mpcid?:number;
    itemid?:number;
    itemname?:string;
    lasterate?:string;
    createdby?:number;
    approvalRequest?:string;
    approvalStatus?:string;
    newrate?:string;
    effectdate?:string;
    mrp?:string;
    employeeName?:string;
}

export interface IManagePriceOfChangesSample{
    impcid?:number;
    iitemid?:number;
    iitemname?:string;
    ilasterate?:string;
    icreatedby?:number;
    approvalRequest?:string;
    iapprovalStatus?:string;
    inewrate?:string;
    ieffectdate?:string;
    imrp?:string;
}

export interface ISearchOptions{
    itemName?:string;
    createdBy?:string;
}

const managePriceChangeDetail=observable<IManagePriceOfChanges>({
    approvalRequest:'',
    mpcid:0,
    itemid:0,
    itemname:'',
    lasterate:'',
    createdby:0,
    approvalStatus:'',
    newrate:'',
    effectdate:'',
    mrp:'',
    employeeName:'',
})

export default managePriceChangeDetail;