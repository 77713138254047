import { action, set, makeObservable, observable, runInAction } from 'mobx';
import WareHouseManagerDetails, { IWareHouseManager } from '../model/index';
import apiAgent from '../../../APIAgent';

class WareHouseManagerStore {
    @observable WareHouseManagerList= new Map();
    isWareHouseManagerRequest = true;
    entryNo=new Map();
    

     saveWareHouseManager = async (WareHouseManager: IWareHouseManager) => {
        try {
          
          const status=  await apiAgent.wareHouseManagerMasters.saveWaeHouseManager(WareHouseManager);
          
          if(status==="Success"){
            
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }

     getWareHouseManager = async ()=>{
        try{
            
            const wareHouseManagerData = await apiAgent.wareHouseManagerMasters.getWareHouseManager();
            runInAction(() => {
                
                this.WareHouseManagerList = wareHouseManagerData;
                this.isWareHouseManagerRequest = false;
            });
            
            return this.WareHouseManagerList;
        }
        catch(error){
            console.log(error);
    }
}
getWareHouseManagerSearch  = async (SearchOptions)=> {
    try {
      const wareHouseManagerData=await apiAgent.wareHouseManagerMasters.getWareHouseManagerSearch(SearchOptions);
        this.WareHouseManagerList = wareHouseManagerData;
        
        runInAction(() => {
            this.WareHouseManagerList = wareHouseManagerData;
            
        })
        return this.WareHouseManagerList;
    
     
    }
    catch (error) {
        console.log(error);
    }
}
deleteWareHouseManager = async (wmId) => {
    try {      
    const status=  await apiAgent.wareHouseManagerMasters.deleteWareHouseManager(wmId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}

getEntryNo = async (flag)=>{
    try{
        const entryNo = await apiAgent.adminModule.getEntryNo(flag);
        
        runInAction(()=>{
            this.entryNo = entryNo;
        })
        return this.entryNo;
    }
    catch(error){
    console.log(error);
    }
}


}
export default WareHouseManagerStore;