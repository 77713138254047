import { observable } from "mobx";
export interface IOfferDisount{
    offerId?:number;
    offerName?:string;
    offerType?:string;   
    qtyofferdetail?:Qtyofferdetail[];
    valueOffermasterList?:ValueOffermasterList[];
    coponOffermasterList?:CoponOffermasterList[];
    
}
 
export interface Qtyofferdetail{
    rowId?:number;
    from_QV?:string;
    to_QV?:string;
    discount_PV?:string;
    dis_Mode?:string;
}

export interface ValueOffermasterList{
    rowId?:number;
    from_QV?:string;
    to_QV?:string;
    discount_PV?:string;
    dis_Mode?:string;
}

export interface CoponOffermasterList{
    rowId?:number;
    couponName?:string;
    discount_PV?:string;
    dis_Mode?:string;
}
export interface IOfferDisountSearchoptions{
    searchOption?:string;
    searchValue?:string;
}



const offerDiscountDetails=observable<IOfferDisount>({
    offerId:0,
    offerName:'',
    offerType:'',   
    qtyofferdetail:[], 
    valueOffermasterList:[], 
    coponOffermasterList:[], 
   
});

export default offerDiscountDetails;