export class IMembershipSave {
  mid?: number
  effectdate?: string
  planName?: string
  membershipdetails?: IMemebershipDetails[]
}

export class IMemebershipDetails {
  effectdate?: string;
  duration?: number;
  amount?: number;
}