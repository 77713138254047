import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const LeaveSettingMasterDetail = observer((): JSX.Element => {
  const { payrollStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();

  const [leaveSettingJson, setLeaveSettingJSON] = useState<any>([])

  const [isLoading, setLoading] = useState(true);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/LeaveSetting')
  }

  const editBrandDetials = (brandId) => {
    navigate('/Admin/Brand/' + brandId);
  }

  async function fetchPreLoadngData() {
    const userRights = await userCreationStore?.getUserRight('Leave_setting_master')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const leaveSettingJSON = await payrollStore.getLeaveSettingJSON()
    setLeaveSettingJSON([...leaveSettingJSON])
    setLoading(false)
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (leaveSettingJson && leaveSettingJson?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(leaveSettingJson)
    }
  }, [leaveSettingJson])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(leaveSettingJson?.slice()?.sort((a, b) => b.Leaveid - a.Leaveid), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={leaveSettingJson}
                searchTitles={[{ key: "Category", value: "Category" }, { key: "casualLeave", value: "Casual Leave" }, { key: "sickLeave", value: "Sick Leave" }, { key: "earnedLeave", value: "Earned Leave" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{leaveSettingJson?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th scope="col">S.No</th> */}
                      <th scope="col">Year</th>
                      <th scope="col">Category</th>
                      <th scope="col">Casual Leave</th>
                      <th scope="col">Sick Leave</th>
                      <th scope="col">Earned Leave</th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr>
                            {/* <td>{val?.Leaveid}</td> */}
                            <td>{val?.Lyear}</td>
                            <td>{val?.Category}</td>
                            <td>{val?.CacualLeave}</td>
                            <td>{val?.SickLeave}</td>
                            <td>{val?.EarnedLeave}</td>
                            <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </>
      }
    </>

  )
})

export default LeaveSettingMasterDetail;