export class IitemMaster{
    mid?: number;
    mcode?: string;
    machineNo?: string;
    machineName?: string;
    machineMake?: string;
    machinemodelno?: string;
    description?: string;
    doi?: string;
    manufact_year?: number;
    machineValue?: number;
    department?: string;
    maint_Card_No?: string;
    hsn?: string
  }
