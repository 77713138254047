import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import Cookies from 'js-cookie';
import { IOutletReturnForm, IOutletTransferDet } from './model';
import { saveValidation } from './validation';


const OutletReturnForm = observer((): JSX.Element => {

    const { supplierMasterStore, commonStore, outwardtoOutletStore, inwardOultetStore, damageEntryOutletStore, purchaseBillStore } = useStores();
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoadModal, setLoadModal] = useState(false);
    const [isSpinner, setSpinner] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    const { loadSupplierMastes, loadSuppliers, } = commonStore;

    const [damageEntryItemList, setDamageEntryItemList] = useState<any[]>([]);
    const [damageOutletTransfDet, setDamageOutletTransfDet] = useState<IOutletReturnForm>(new IOutletReturnForm())
    console.log(damageOutletTransfDet, 'damageOutletTransfDet');

    console.log(damageEntryItemList, 'damageEntryItemList>>')
    function handleInputchange(e) {
        const { name, value } = e.target;
        setDamageOutletTransfDet({ ...damageOutletTransfDet, [name]: value })
    }

    function handleAutoComplete(name, value) {
        if (name === "doM_SupplierId" && value != null) {
            setDamageOutletTransfDet({ ...damageOutletTransfDet, doM_SupplierId: value?.supplierId })
        }
    }

    async function addAssignBatchNo(value, inx, checked) {
        if (!damageOutletTransfDet?.damageoutlettransferdet?.some((obj, i) => obj?.doT_damageentrytransferdid === value?.damage_entry_details_id)) {
            let damageTransferDetails: IOutletTransferDet = {};
            damageTransferDetails.doT_OUTID = value?.damageoutlet;
            damageTransferDetails.doT_ItemID = value?.itemid;
            damageTransferDetails.doT_Batchno = value?.batch_no;
            damageTransferDetails.doT_Barcode = value?.Barcode;
            damageTransferDetails.doT_Qty = value?.damage_stk;
            damageTransferDetails.doT_Rate = value?.rate ? (Number(value?.rate ?? 0) * Number(value?.damage_stk ?? 0)) : (Number(value?.purchasePrice ?? 0) * Number(value?.damage_stk ?? 0));
            damageTransferDetails.doT_TotalAmt = value?.rate ? value?.rate : value?.purchasePrice;
            damageTransferDetails.doT_Type = 'Return'
            damageTransferDetails.doT_Reason = value?.Remarks;
            damageTransferDetails.doT_damageentrytransfermstid = damageOutletTransfDet.doM_EntryNo;
            damageTransferDetails.isChecked = checked;
            damageTransferDetails.master_id = value?.damage_entry_master_id;
            damageTransferDetails.doT_damageentrytransferdid = value?.damage_entry_details_id;
            damageOutletTransfDet?.damageoutlettransferdet?.push(damageTransferDetails)
            setDamageOutletTransfDet(damageOutletTransfDet)

        } else {
            let findInex = damageOutletTransfDet?.damageoutlettransferdet?.findIndex((obj, i) => obj?.doT_damageentrytransferdid === value?.damage_entry_details_id)
            if (findInex != -1) {
                damageOutletTransfDet?.damageoutlettransferdet?.splice(findInex, 1)
                setDamageOutletTransfDet(damageOutletTransfDet)
            }

        }
        damageEntryItemList.forEach((each) => {
            if (each?.damage_entry_details_id === value?.damage_entry_details_id) {
                each.isChecked = checked;
                //  each.rate = checked ? each?.rate : 0;
                each.type = checked ? each?.type : "";
                each.debitnote = checked ? each.debitnote : 0;
                each.debittype = checked ? each.debittype : "";
            }
            return each
        });
        setDamageEntryItemList([...damageEntryItemList])

        const totalAmount = damageEntryItemList.reduce((total, entry) => {
            if (entry.isChecked) {
                if (entry?.rate) {
                    return total + (Number(entry?.rate ?? 0) * Number(entry?.damage_stk ?? 0));
                }
                else {
                    return total + (Number(entry?.purchasePrice ?? 0) * Number(entry?.damage_stk ?? 0));
                }
            }
            return total;
        }, 0);
        console.log(totalAmount, 'totalAmount');

        setDamageOutletTransfDet((prev) => ({
            ...prev,
            rtnamount: totalAmount,
        }));
    }

    function handleRateUpdate(rate, masterid) {
        console.log(damageOutletTransfDet, 'damageOutletTransfDet');
        console.log(masterid, 'masterid');
        console.log(rate, 'rate');
        if (masterid) {
            damageOutletTransfDet?.damageoutlettransferdet?.forEach((each) => {
                if (each?.doT_damageentrytransferdid === masterid) {
                    each['doT_Rate'] = Number(rate);
                    each['doT_TotalAmt'] = (Number(each.doT_Qty!) * Number(rate));
                }
                return each;
            })
            setDamageOutletTransfDet({ ...damageOutletTransfDet })
            damageEntryItemList.forEach((each) => {
                if (each?.damage_entry_details_id === masterid) {
                    each['rate'] = Number(rate);
                }
                return each;
            });
            setDamageEntryItemList([...damageEntryItemList])
            const totalAmount = damageEntryItemList.reduce((total, entry) => {
                if (entry.isChecked) {
                    if (entry?.rate) {
                        return total + (Number(entry?.rate ?? 0) * Number(entry?.damage_stk ?? 0));
                    }
                    else {
                        return total + (Number(entry?.purchasePrice ?? 0) * Number(entry?.damage_stk ?? 0));
                    }
                }
                return total;
            }, 0);
            console.log(totalAmount, 'totalAmount');

            setDamageOutletTransfDet((prev) => ({
                ...prev,
                rtnamount: totalAmount,
            }));
        }
    }

    function handleTypeUpdate(type, masterid) {
        if (masterid) {
            damageOutletTransfDet?.damageoutlettransferdet?.forEach((each) => {
                if (each?.master_id === masterid) {
                    each['doT_Type'] = type
                }
                return each;
            })
            setDamageOutletTransfDet({ ...damageOutletTransfDet })
            damageEntryItemList.forEach((each) => {
                if (each?.damage_entry_master_id === masterid) {
                    each['type'] = type;
                }
                return each;
            });
            setDamageEntryItemList([...damageEntryItemList])
        }
    }

    async function hanldeGenerateDebiNo() {
        setSpinner(true)
        const entryNo = await purchaseBillStore.getPurchaseBillEntryNo('DebitNote');
        setDamageOutletTransfDet({ ...damageOutletTransfDet, doM_DebitNoteNo: (entryNo?.entryNo + 1)?.toString(), doM_DebitType: "Debit" })
        setSpinner(false)
    }

    async function saveAssignBatchNo(e) {
        e.preventDefault();
        let error: any = {}
        error = saveValidation(damageOutletTransfDet)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            const status = await damageEntryOutletStore?.saveDamageOutletTransferMst(damageOutletTransfDet)
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleCancel = () => {
        navigate('/Admin/OutletReturnForm/List');
    }
    const handleSuccessModalClose = () => {
        navigate('/Admin/OutletReturnForm/List');
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }


    async function fetchAssignDefaultLoad() {
        const entryNos = await outwardtoOutletStore.getEntryNo('DamageOutletTransfer');
        setDamageOutletTransfDet({ ...damageOutletTransfDet, doM_EntryNo: entryNos.entryNo })
        const damageEntryItemList = await damageEntryOutletStore.loadDamageTransferOutletDetails()
        setDamageEntryItemList([...damageEntryItemList])
        await loadSupplierMastes()
        setLoading(false)
    }

    const isInitialRender = useRef(true);

    // useEffect(() => {
    //     if (isInitialRender.current) {
    //         fetchAssignDefaultLoad();
    //         isInitialRender.current = false;
    //     } else {
    //     }
    //     return () => {
    //         // Cleanup logic (if needed)
    //     };
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Outlet_return_form';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchAssignDefaultLoad();
                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/OutletReturnForm/List');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);

    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {


        return (
            <>
                {/* {
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>} */}
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>Outlet Return Form</h3>
                        </div>
                        <div className='inputBoxLists' >
                            <div className='tableBox' style={{ maxHeight: '500px', overflow: 'scroll' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Barcode</th>
                                            <th scope="col">Batch No</th>
                                            <th scope="col">Qty</th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ verticalAlign: 'middle' }}>
                                        {damageEntryItemList?.slice()?.sort((a, b) => b.damage_entry_no - a.damage_entry_no)?.map((val, key) => {
                                            return val?.purchasePrice !== null && val?.damage_stk !== null ? (
                                                <tr key={key}>
                                                    <td><input type="checkbox" onChange={(event) => addAssignBatchNo(val, key, event?.target?.checked)} /></td>
                                                    <td>{val?.itemname}</td>
                                                    <td>{val?.Barcode}</td>
                                                    <td>{val?.batch_no}</td>
                                                    <td>{val?.damage_stk}</td>
                                                    {/* disabled={!val?.isChecked} */}
                                                    <td><input disabled={!val?.isChecked} defaultValue={val?.purchasePrice?.toFixed(2)} type='text' name='rate' step='0.01'
                                                        style={{ width: '70%' }} onChange={(event) => {
                                                            const value = parseFloat(event.target.value);
                                                            if (!isNaN(value)) {
                                                                handleRateUpdate(value.toFixed(2), val?.damage_entry_details_id);
                                                            }
                                                        }} ></input> </td>
                                                    <td>
                                                        {val?.rate !== undefined
                                                            ? (Number(val.rate) * Number(val.damage_stk ?? 0)).toFixed(3)
                                                            : (Number(val.purchasePrice ?? 0) * Number(val.damage_stk ?? 0)).toFixed(3)}
                                                    </td>
                                                    <td>{val?.Remarks}</td>
                                                    <td>
                                                        <select className="form-selected" onChange={(event) => handleTypeUpdate(event?.target?.value, val?.damage_entry_master_id)}
                                                            name="type" disabled={!val?.isChecked} value={val?.type}
                                                            style={{ width: "100%" }} id="Openingtype" >
                                                            {/* <option value="">Not Select</option>
                                                        <option value="Rejection">Rejection</option> */}
                                                            <option value="Return" selected>Return</option>
                                                        </select>
                                                    </td>
                                                </tr>
                                            ) : null;
                                        })}
                                        <tr>
                                            <td colSpan={3}></td>
                                            <td>{errors.rate && <p style={{ color: 'red' }}>{errors.rate}</p>}</td>
                                            <td colSpan={2}></td>
                                            <td>{errors.type && <p style={{ color: 'red' }}>{errors.type}</p>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {errors.damageoutlettransferdet && <p style={{ color: 'red' }}>{errors.damageoutlettransferdet}</p>}
                            <div className='LgInputListsBox mr-left-0'>
                                <div className='vertical-space-10'></div>
                                <div className='row'>
                                    {isSpinner &&
                                        <div className='SpinnerBox'>
                                            <Spinner animation="grow" size="sm" />
                                        </div>}
                                    <div className='vertical-space-10'></div>
                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Select Supplier <span>*</span></label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={loadSuppliers}
                                                getOptionLabel={(option: any) => option.label}
                                                onChange={(event, val) => handleAutoComplete('doM_SupplierId', val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        fullWidth
                                                        placeholder='Select Warehouse..'
                                                        name='doM_SupplierId'
                                                    />}
                                            />
                                            {errors.doM_SupplierId && <p style={{ color: 'red' }}>{errors.doM_SupplierId}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='inputBox'>
                                            <label>Debit Note <span>*</span></label>
                                            <input type="text" style={{ width: "100%" }} disabled
                                                name='subtotal' value={damageOutletTransfDet?.doM_DebitNoteNo}
                                            ></input>
                                            {errors.doM_DebitNoteNo && <p style={{ color: 'red' }}>{errors.doM_DebitNoteNo}</p>}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-5'>
                                        <div className='inputBox'>
                                            <label>Debit Amount <span>*</span></label>
                                            <input type="text" style={{ width: "100%" }} disabled
                                                name='subtotal' value={damageOutletTransfDet.rtnamount}
                                            ></input>
                                            {/* value={damageOutletTransfDet.rtnamount = damageEntryItemList?.reduce((a, v) => a = a + (v?.type === 'Return' ? Number(v?.rate ?? 0) * Number(v?.damage_stk ?? 0) : 0)), 0)} */}
                                            {/* {errors.doM_DebitNoteNo && <p style={{ color: 'red' }}>{errors.doM_DebitNoteNo}</p>} */}
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className="col-sm-2" style={{ marginTop: '24px' }}>
                                        <button className='secondaryBtn' type='submit'
                                            disabled={false}
                                            onClick={hanldeGenerateDebiNo}
                                        >Generate</button>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>DC No </label>
                                            <input type="text"
                                                name='doM_EntryNo' disabled
                                                value={damageOutletTransfDet?.doM_EntryNo}
                                                className='' style={{ width: "100%" }} placeholder='Entry No..'></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='inputBox'>
                                            <label>Date <span>*</span></label>
                                            <input type="date"
                                                name='doM_Date' value={damageOutletTransfDet?.doM_Date}
                                                onChange={handleInputchange}
                                                style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit'
                                onClick={saveAssignBatchNo}
                            >Submit</button>
                        </div>
                    </div>
                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/checked.png')} />
                                </div>

                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </>

        );
    }

});

export default OutletReturnForm;
