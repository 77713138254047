function assignFreeItemValidation(assignFreeItem, options, itemOptions) {

  let errors: any = {};
   
  if (Object.keys(options).length === 0) {
    errors.options = "Select Options";
  }
  if (!assignFreeItem.assignDate) {
    errors.assignDate = "Select  AssignDate";
  }
  if (!assignFreeItem.itemId) {
    errors.itemId = "Select Item Name";
  }
  if (Object.keys(itemOptions).length === 0) {
    errors.itemOptions = "Select Item Options";
  }
  if (options.options === 'batchNo' && assignFreeItem.itemId) {
    if (!assignFreeItem.batchNo) {
      errors.batchId = "Select Batch No";
    }
  }
  if (options.options === 'saleqty' ) {
    if (!assignFreeItem.entryQty) {
      errors.entryQty = "Enter Entry Quantity";
    }
  }
  if (itemOptions.itemoptions === 'otheritem') {
    if (!assignFreeItem.otherItemId) {
      errors.otherItemId = "Select Free Item";
    }
  }


  if (!assignFreeItem.freeQty) {
    errors.freeQty = "Enter Free Quantity";
  }
  if (!assignFreeItem.effectiveFrom) {
    errors.effectiveFrom = "Select Effective From Date";
  }
  if (!assignFreeItem.effectiveTo) {
    errors.effectiveTo = "Select Effective To date";
  }
  return errors;
};

export default assignFreeItemValidation;