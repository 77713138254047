import CryptoJS from 'crypto-js';
const key ='gsssoftwares';


export const encryptData = (text) => {
    const hash = CryptoJS.SHA256(key);
    const ciphertext = CryptoJS.AES.encrypt(text, hash, {
      mode: CryptoJS.mode.ECB,
    });
    return ciphertext.toString();
}