import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Autocomplete, TextField } from '@mui/material';
import { IDamageEntryWarehouse, IDamageEntryWarehouseDetails, IDamgeBatchNoRequest } from './model';
import useStores from '../../hooks';
import Cookies from 'js-cookie';
import DamageEntryWarehouseValidation from './validation';
import { QRCode } from 'react-qrcode-logo';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';


function DamageEntryWarehouse() {
  const [damageEntryWarehouse, setDamageEntryWarehouse] = useState<IDamageEntryWarehouse>({});
  const [damageEntryWarehouseDetail, setDamageEntryWarehouseDetail] = useState<IDamageEntryWarehouseDetails>({});
  const [damageEntryWarehouseDetails, setDamageEntryWarehouseDetails] = useState<IDamageEntryWarehouseDetails[]>([]);
  const navigate = useNavigate();
  const [isSpinner, setSpinner] = useState(false);
  const { DamageEntryWareHouseStore, commonStore, itemMasterStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [loadIItemData, setLoadIItemData] = useState<any[]>([])
  const { supplierMasterStore } = useStores();
  const { loadWarehouse, loadWarehouses, loadDamageWarehouseItemDetails, loadDamageWarehouseItem } = commonStore;
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setDamageEntryWarehouse({ ...damageEntryWarehouse, [name]: value });
  };

  const handleWarehouse = async (name, event, val) => {
    if (val != null) {
      setDamageEntryWarehouse({ ...damageEntryWarehouse, [name]: val.warehouseId });
      setLoading(true);
      const loadData = await loadDamageWarehouseItem(val.warehouseId);
      let updatedArr;
      if (loadData.length) {
        updatedArr = loadData.map((obj) => {
          let newObj = {
            itemid: obj?.itemid,
            label: obj?.itemname,
          }
          return newObj;
        })
      }
      setLoadIItemData(updatedArr)
      setLoading(false);
    }
    else {
      damageEntryWarehouse.warehouseId = 0;
    }
  }

  const handleDamageItem = async (name, val) => {
    if (val != null) {
      setDamageEntryWarehouse({ ...damageEntryWarehouse, itemId: val.itemid, itemName: val.label });
      setLoading(true);
      let request: IDamgeBatchNoRequest = {};
      request.itemId = val.itemid
      request.warehouseId = damageEntryWarehouse.warehouseId
      await DamageEntryWareHouseStore.loadDamageWarehouseItemBatchNo(request);
      setLoading(false);
    } else {
      damageEntryWarehouse.itemId = 0;
    }
  }
  const handleItemBatchNo = async (name: any, event: object, val: any) => {
    setDamageEntryWarehouse({ ...damageEntryWarehouse, batchNo: val.batchNo, currentStock: Number(val.currentStock) })
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const addDamageWarehouseEntry = (e) => {

    e.preventDefault();
    let newObject: IDamageEntryWarehouseDetails = {};
    let error: any = {};
    error = DamageEntryWarehouseValidation(damageEntryWarehouse);
    setErrors(error);
    if (Object.keys(error).length === 0) {

      damageEntryWarehouseDetail.damageBatchNo = damageEntryWarehouse.batchNo;
      damageEntryWarehouseDetail.damageDescription = damageEntryWarehouse.damageDescription;
      damageEntryWarehouseDetail.damageItemId = damageEntryWarehouse.itemId;
      damageEntryWarehouseDetail.damageItemQty = Number(damageEntryWarehouse.damageQty);
      damageEntryWarehouseDetail.damageItemName = damageEntryWarehouse.itemName;

      setDamageEntryWarehouseDetails([...damageEntryWarehouseDetails, damageEntryWarehouseDetail])
      setDamageEntryWarehouseDetail(newObject);
      setDamageEntryWarehouse({ ...damageEntryWarehouse, itemId: 0, itemName: '', damageQty: 0, damageDescription: '', batchNo: '', currentStock: 0, });
      setSubmitbtn(true);
    }
  }

  function handleRemove(index) {
    damageEntryWarehouseDetails?.splice(index, 1);
    setDamageEntryWarehouseDetails(_.cloneDeep(damageEntryWarehouseDetails))
  }

  const saveDamageEntryWarehouse = async (e) => {
    setLoading(true);
    e.preventDefault();
    damageEntryWarehouse.damageEntryWarehouseDetails = damageEntryWarehouseDetails
    damageEntryWarehouse.empId = Number(Cookies.get('employeeId'));
    const entryNos = await itemMasterStore.getEntryNo('DamageEntryWarehouse');
    damageEntryWarehouse.damageEntryId = entryNos.entryNo;
    const status = await DamageEntryWareHouseStore.saveDamageEntryWarehouse(damageEntryWarehouse);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleCancel = () => {
    navigate('/Admin/DamageEntryWarehouse/DamageEntryWarehouseDetail');
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/DamageEntryWarehouse/DamageEntryWarehouseDetail');
  }
  const handleFailureModalClose = () => setFailureModal(false);

  async function fetchOultetItems() {
    setLoading(false);
    await Promise.all([itemMasterStore.getEntryNo('DamageEntryWarehouse'), loadWarehouse()]);
  }



  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchOultetItems();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Damage_entry_warehouse';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchOultetItems();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/DamageEntryWarehouse/DamageEntryWarehouseDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);


  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>


        <div className='container'>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox'>
            <div className='hrBox'>
              <h3>Damage Entry (Warehouse)</h3>
            </div>
            <div className='inputBoxLists pd-b-0'>
              <div className='LgInputListsBox mr-left-0'>
                <div className='row'>
                  {
                    isSpinner ?
                      <div className='SpinnerBox'>
                        <Spinner animation="grow" size="sm" />
                      </div> : null
                  }
                  {/* <div className='col-md-6'>
                  <div className='inputBox'>
                    <label>Entry No <span>*</span></label>
                    <input type="text" className=''
                      name='damageid' disabled
                      value={damageEntryWarehouse.damageEntryId = itemMasterStore.entryNo.entryNo + 1}
                      onChange={handleInputchnage}
                      style={{ width: "100%" }} placeholder='Entry No..'></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div> */}
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Entry Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        onChange={handleInputchnage}
                        name="damageEntryDate"
                        value={damageEntryWarehouse.damageEntryDate = damageEntryWarehouse.damageEntryDate ?? new Date().toISOString().substr(0, 10)}
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      {errors.damageEntryDate && <p style={{ color: 'red' }}>{errors.damageEntryDate}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label> Warehouse Name  <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadWarehouses}
                        getOptionLabel={(option: any) => option.warehouseName}
                        onChange={(event, val) => handleWarehouse('warehouseId', event, val)}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '26ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Warehouse..'
                            name='warehouseName'
                          />}
                      />
                      {errors.warehouseId && <p style={{ color: 'red' }}>{errors.warehouseId}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label> Item Name<span>*</span></label>
                      <AutoComplete placeholder="Select Outlet" value={damageEntryWarehouse?.itemName}
                        emitOption={(val) => handleDamageItem('itemId', val)} getOptionLabel='itemname' options={loadIItemData} />
                      {errors.itemId && <p style={{ color: 'red' }}>{errors.itemId}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      {
                        isSpinner ?
                          <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                          </div> : null
                      }
                      <label>Batch No<span>*</span></label>
                      <AutoComplete placeholder="Select Outlet" value={damageEntryWarehouse?.batchNo}
                        emitOption={(val) => handleItemBatchNo('batchNo', {}, val)} getOptionLabel='batchNo' options={DamageEntryWareHouseStore?.loadDamageWarehouseItemBatch} />
                      {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-3'>
                    <div className='inputBox'>
                      <label>Current Stock </label>
                      <input type="text" className='' style={{ width: "100%" }}
                        value={damageEntryWarehouse.currentStock} name='currentStock'
                        placeholder='Current Stock..'></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                  <div className='col-md-3'>
                    <div className='inputBox'>
                      <label>Damage Qty<span>*</span></label>
                      <input type="text" className=''
                        onChange={(event) => Number(event?.target?.value) <= damageEntryWarehouse?.currentStock! ? handleInputchnage(event) : {}}
                        name="damageQty"
                        value={damageEntryWarehouse?.damageQty}
                        style={{ width: "100%" }} placeholder='Damage Qty..'></input>
                      {errors.damageQty && <p style={{ color: 'red' }}>{errors.damageQty}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                  <div className='col-md-9'>
                    <div className='inputBox'>
                      <label>Damage Description<span>*</span></label>
                      <textarea className='' style={{ width: "100%" }} placeholder='Damage Description..'
                        onChange={handleInputchnage}
                        name="damageDescription"
                        value={damageEntryWarehouse.damageDescription} ></textarea>
                      {errors.damageDescription && <p style={{ color: 'red' }}>{errors.damageDescription}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-2'>
                    <button className='dfBtn' onClick={addDamageWarehouseEntry}>ADD</button>
                  </div>
                  <div className='col-md-12'>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Item Name</th>
                            <th scope="col"> Qty </th>
                            <th scope="col"> Batch No </th>
                            <th scope="col"> Damage Description </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {damageEntryWarehouseDetails?.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.damageItemName}</td>
                                <td>{val.damageItemQty}</td>
                                <td><QRCode value={val.damageBatchNo} size={100} /></td>
                                <td>{val.damageDescription}</td>
                                <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                                  onClick={() => handleRemove(key)}>Remove</button></td>
                              </tr>
                            )
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                </div>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='btnBox'>
              <button className='secondaryBtn' onClick={handleCancel}>List</button>
              <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveDamageEntryWarehouse}>Submit</button>
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  }
};

export default DamageEntryWarehouse;
