
function brandValidation(brand){
  let errors :any={};
  
  if(!brand.brandCode){
    
    errors.brandCode = "Enter Brand";
  }
  if(!brand.brandCompany){
    errors.brandCompany = "Enter Company Name";
  }
  
    
  return errors;
};
  
  export default brandValidation;