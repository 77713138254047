import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../EcomAPI';
import { ICartProduct, IProduct, IProductMember } from '../../Products/model/index';


class ProductStore {
	@observable itemMasterDetails = new Map();
	saveProductCart = async (productCart: ICartProduct) => {
		try {
			const status = await apiAgent.product.saveProductCart(productCart);
			if (status !== undefined) {
				return status;
			}
		}
		catch (error) {
			console.log(error);
		}
	}

	getItemDetails = async (productMember:IProductMember) => {
		
		try {
			const itemDetails = await apiAgent.product.getItemDetails(productMember);
			
			runInAction(() => {
				this.itemMasterDetails = itemDetails;
			});
			return this.itemMasterDetails;
		}
		catch (error) {
			console.log(error);
		}
	}

}
export default ProductStore;