import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { IIAppoinmentEmpSts, IIAppoinmentJobDet, IIAppoinmentOtherDoc, IIAppoinmentProcess, IIAppoinmentSalaryDet } from "./model";
import useStores from "../../hooks";
import { addJobValidation, addOtherDocValidation, addSalaryValidation, saveValidation } from "./validation";
import AutoComplete from "../../common/shared/autoComplete";
import { removeDuplicates } from "../../common/shared/utils";

interface FileItem {
    id: number;
    file: File;
}

const AppointmentProcess = () => {

    const navigate = useNavigate();


    const { interviewStore, jobMasterStore, payrollStore, commonStore, appoinmentStore } = useStores()
    const { loadEmployee, loadEmployees } = commonStore;

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [viewModal, setViewModal] = useState(false)

    const [offerLetterMaster, setOfferLetterMaster] = useState<any[]>([])
    const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])
    const [jobMaster, setJobMaster] = useState<any[]>([])

    const [appoinmentProcess, setAppoinmentProcess] = useState<IIAppoinmentProcess>(new IIAppoinmentProcess())
    const [appoinSalaryDet, setAppoinSalaryDet] = useState<IIAppoinmentSalaryDet>(new IIAppoinmentSalaryDet())
    const [appionDocuDet, setAppoinDocDet] = useState<IIAppoinmentOtherDoc>(new IIAppoinmentOtherDoc())
    const [appionJobDet, setAppionJobDet] = useState<IIAppoinmentJobDet>(new IIAppoinmentJobDet())

    const [errors, setErrors] = useState<any>({});

    const [files, setFiles] = useState<FileList | null>(null)
    const [appoinDocFile, setAppoinDocFile] = useState<File[]>([])

    function handleInputChange(event) {
        const { name, value } = event.target;
        handleAutoComplete(name, value)
    }

    function handleAutoComplete(name, value) {
        if (name === 'appoinOfferId') {
            setAppoinmentProcess({
                ...appoinmentProcess,
                [name]: value?.OfferId, appoinJobCode: value?.OfferJobCode?.toString(),
                appoinCandidate: value?.OfferCandidate, appoinJobLoc: value?.OfferJobLoc,
            })
        } else if (name === 'appoinBioID') {
            setAppoinmentProcess({ ...appoinmentProcess, [name]: Number(value) })
        } else {
            setAppoinmentProcess({ ...appoinmentProcess, [name]: value })
        }
    }

    function handleAddSalaryDet(index) {
        let error: any = {};
        if (index >= 0) {
            appoinmentProcess?.appoinmentsalarydet?.splice(index, 1)
            setAppoinmentProcess({ ...appoinmentProcess })
        } else {
            error = addSalaryValidation(appoinSalaryDet)
            if (Object.keys(error)?.length === 0) {
                appoinSalaryDet.asD_AppoinRecordId = appoinmentProcess?.appoinRecordId;
                appoinmentProcess?.appoinmentsalarydet?.push(appoinSalaryDet)
                setAppoinmentProcess({ ...appoinmentProcess })
                setAppoinSalaryDet(new IIAppoinmentSalaryDet())
            }
        }
        setErrors({ ...error })
    }

    function handleAddOtehrDoc(index) {
        let error: any = {};
        if (index >= 0) {
            appoinmentProcess?.appoinmentotherdoc?.splice(index, 1)
            setAppoinmentProcess({ ...appoinmentProcess })
            setAppoinDocFile(prevFiles => prevFiles?.filter((file, ind) => ind != index))
        } else {
            error = addOtherDocValidation(appionDocuDet)
            if (Object.keys(error)?.length === 0) {
                appionDocuDet.aoD_AppoinRecordId = appoinmentProcess?.appoinRecordId;
                appoinmentProcess?.appoinmentotherdoc?.push(appionDocuDet)
                setAppoinmentProcess({ ...appoinmentProcess })
                setAppoinDocFile(prevFiles => [...prevFiles, files![0]]);
                setFiles(null)
                setAppoinDocDet(new IIAppoinmentOtherDoc())
            }
        }
        setErrors({ ...error })
    }

    function handleViewDoc() {
        if (appoinDocFile) {
            setViewModal(true)
        }
    }

    function handleAddJobDet(index) {
        let error: any = {};
        if (index >= 0) {
            appoinmentProcess?.appoinmentjobdet?.splice(index, 1)
            setAppoinmentProcess({ ...appoinmentProcess })
        } else {
            error = addJobValidation(appionJobDet)
            if (Object.keys(error)?.length === 0) {
                appionJobDet.ajD_AppoinRecordId = appoinmentProcess?.appoinRecordId;
                appoinmentProcess?.appoinmentjobdet?.push(appionJobDet)
                setAppoinmentProcess({ ...appoinmentProcess })
                setAppionJobDet(new IIAppoinmentJobDet())
            }
        }
        setErrors({ ...error })
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        handleToAppointmentProcessList()
    };

    async function handleSaveCategory() {
        let appionEmplist: any[] = [];
        appoinmentProcess?.appoinmentsalarydet?.map((each) => {
            let appionEmpDet: IIAppoinmentEmpSts = {
                aE_AppoinAmt: each?.asD_AppoinAmt,
                aE_AppoinSalType: each?.asD_AppoinSalType,
                aE_AppoinBioID: appoinmentProcess?.appoinBioID,
                aE_AppoinCandidate: appoinmentProcess?.appoinCandidate,
                aE_AppoinCompName: appoinmentProcess?.appoinCompName,
                aE_AppoinDOJ: appoinmentProcess?.appoinDOJ,
                aE_AppoinPhotoDoc: appoinmentProcess?.appoinPhotoDoc
            }
            appionEmplist?.push(appionEmpDet)
        })
        if (!appionEmplist?.length) {
            appoinmentProcess.appoinmentempmst = [];
        }
        appoinmentProcess.appoinmentempmst = appionEmplist;
        let error: any = {}
        error = saveValidation(appoinmentProcess)
        setErrors({ ...error })
        console.log(appoinmentProcess, 'appoinmentProcess>>', error)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const status = await appoinmentStore.saveAppoinmentProcess(appoinmentProcess);
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToAppointmentProcessList() {
        navigate("/Admin/AppointmentProcess/List");
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('Appoinmentprocess');
        setAppoinmentProcess({ ...appoinmentProcess, appoinRecordId: Number(entryNo?.entrynumber) })
        await loadEmployee()
        const offerLetter = await interviewStore?.loadOfferLetterMaster()
        setOfferLetterMaster([...offerLetter])
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
        setIncentiveMaster([...incentiveMaster])
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage?.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
    }, [])

    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <div className="container">
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox LgInputField">
                        <div className="hrBox">
                            <h3>Appointment Process</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Record No</label>
                                        <input type="text" name='appoinRecordId' className="full-width" disabled
                                            value={appoinmentProcess?.appoinRecordId} ></input>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Date</label>
                                        <input type="date"
                                            placeholder="DD/MMM/YYYY"
                                            className="full-width"
                                            data-date-format="DD MMMM YYYY"
                                            name="appoinDate"
                                            value={appoinmentProcess?.appoinDate}
                                            onChange={handleInputChange}
                                        ></input>
                                        {errors?.appoinDate && <p style={{ color: 'red' }}>{errors?.appoinDate}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Generated By<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={removeDuplicates(loadEmployees, 'employeeName')}
                                            getOptionLabel={(option: any) => option.employeeName}
                                            onChange={(event, val) => handleAutoComplete('appoinEmpId', val?.employeeId)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Name..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors?.appoinEmpId && <p style={{ color: 'red' }}>{errors?.appoinEmpId}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Offer Letter No<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={offerLetterMaster}
                                            getOptionLabel={(option: any) => option.OfferId}
                                            onChange={(event, val) => handleAutoComplete('appoinOfferId', val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Offer Letter..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors?.appoinOfferId && <p style={{ color: 'red' }}>{errors?.appoinOfferId}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Code</label>
                                        <input type="text" name='appoinJobCode' disabled
                                            value={appoinmentProcess?.appoinJobCode}
                                            className='full-width' ></input>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Candidate</label>
                                        <input type="text" name='appoinCandidate' disabled
                                            value={appoinmentProcess?.appoinCandidate}
                                            className='full-width' ></input>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Location</label>
                                        <input type="text"
                                            value={appoinmentProcess?.appoinJobLoc}
                                            name='appoinJobLoc'
                                            className='full-width' ></input>
                                        {errors?.appoinJobLoc && <p style={{ color: 'red' }}>{errors?.appoinJobLoc}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Date of Joining</label>
                                        <input type="date"
                                            placeholder="DD/MMM/YYYY"
                                            className="full-width"
                                            data-date-format="DD MMMM YYYY"
                                            onChange={handleInputChange}
                                            value={appoinmentProcess?.appoinDOJ}
                                            name='appoinDOJ'
                                        ></input>
                                        {errors?.appoinDOJ && <p style={{ color: 'red' }}>{errors?.appoinDOJ}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Time</label>
                                        <input type="time"
                                            className="full-width"
                                            onChange={handleInputChange}
                                            value={appoinmentProcess?.appoinTime}
                                            name="appoinTime"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                        ></input>
                                        {errors?.appoinTime && <p style={{ color: 'red' }}>{errors?.appoinTime}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Employee ID</label>
                                        <input type="text"
                                            className="full-width"
                                            onChange={handleInputChange}
                                            value={appoinmentProcess?.appoinTicketNo}
                                            name="appoinTicketNo"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                        ></input>
                                        {errors?.appoinTicketNo && <p style={{ color: 'red' }}>{errors?.appoinTicketNo}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Biometric ID</label>
                                        <input type="number"
                                            className="full-width"
                                            onChange={handleInputChange}
                                            value={appoinmentProcess?.appoinBioID}
                                            name="appoinBioID"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                        ></input>
                                        {errors?.appoinBioID && <p style={{ color: 'red' }}>{errors?.appoinBioID}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Company Name</label>
                                        <input type="text"
                                            className="full-width"
                                            onChange={handleInputChange}
                                            value={appoinmentProcess?.appoinCompName}
                                            name="appoinCompName"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                        ></input>
                                        {errors?.appoinCompName && <p style={{ color: 'red' }}>{errors?.appoinCompName}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <label style={{ fontSize: '20px' }}> Salary Details (Normal)</label>
                                    {errors?.appoinmentsalarydet && <p style={{ color: 'red' }}>{errors?.appoinmentsalarydet}</p>}
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Salary type<span>*</span></label>
                                        <select className="form-select" style={{ fontSize: "16px" }} name="asD_AppoinSalType"
                                            value={appoinSalaryDet?.asD_AppoinSalType}
                                            onChange={(event) => setAppoinSalaryDet({ ...appoinSalaryDet, asD_AppoinSalType: event?.target?.value })}>
                                            <option value="none">Select</option>
                                            <option value="monthlyBases">Monthly Basis</option>
                                            <option value="perDayBases">Per Day Basis</option>
                                            <option value="weeklyBases">Weekly Basis</option>
                                        </select>
                                    </div>
                                    {errors?.asD_AppoinSalType && <p style={{ color: 'red' }}>{errors?.asD_AppoinSalType}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Description<span>*</span></label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={incentiveMaster?.filter((each) => each?.IType === "Allowance")}
                                            getOptionLabel={(option: any) => option.IDescription}
                                            value={incentiveMaster?.find((each) => each?.incid == appoinSalaryDet?.asD_AppoinDescId)}
                                            onChange={(event, val) => setAppoinSalaryDet({ ...appoinSalaryDet, asD_AppoinDescId: val?.incid })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Salary type..'
                                                    name='asD_AppoinDescId'
                                                />}
                                        />
                                    </div>
                                    {errors?.asD_AppoinDescId && <p style={{ color: 'red' }}>{errors?.asD_AppoinDescId}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Amount</label>
                                        <input type="text" name='asD_AppoinAmt'
                                            value={appoinSalaryDet?.asD_AppoinAmt}
                                            onChange={(event) => setAppoinSalaryDet({ ...appoinSalaryDet, asD_AppoinAmt: Number(event?.target?.value) })}
                                            className='full-width' ></input>
                                    </div>
                                    {errors?.asD_AppoinAmt && <p style={{ color: 'red' }}>{errors?.asD_AppoinAmt}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={() => handleAddSalaryDet(-1)}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Earning Type</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appoinmentProcess?.appoinmentsalarydet?.map((salary, index) => {
                                        return (
                                            <tr>
                                                <td>{incentiveMaster?.find((each) => each?.incid == salary?.asD_AppoinDescId)?.IDescription}</td>
                                                <td>{salary?.asD_AppoinAmt}</td>
                                                <td>
                                                    <button className="delete">
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddSalaryDet(index)} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <label style={{ fontSize: '20px' }}> Other Documents</label>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Document Type</label>
                                        <input type="text" name='brandId'
                                            value={appionDocuDet?.aoD_AppoinDocType}
                                            onChange={(event) => setAppoinDocDet({ ...appionDocuDet, aoD_AppoinDocType: event.target.value })}
                                            className='full-width' ></input>
                                    </div>
                                    {errors?.aoD_AppoinDocType && <p style={{ color: 'red' }}>{errors?.aoD_AppoinDocType}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Document Name</label>
                                        <input type="text" name='brandId'
                                            value={appionDocuDet?.aoD_AppoinDocName}
                                            onChange={(event) => setAppoinDocDet({ ...appionDocuDet, aoD_AppoinDocName: event.target.value })}
                                            className='full-width' ></input>
                                    </div>
                                    {errors?.aoD_AppoinDocName && <p style={{ color: 'red' }}>{errors?.aoD_AppoinDocName}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox">
                                        <label>Document<span>*</span></label>
                                        <input type="file" className="full-width"
                                            onChange={(event) => { setFiles(event.target.files!); setAppoinDocDet({ ...appionDocuDet, aoD_AppoinOtherDoc: event.target.files![0]?.name }) }}
                                            accept="image/*" name="aoD_AppoinOtherDoc"
                                        />
                                        {errors?.aoD_AppoinDocName && <p style={{ color: 'red' }}>{errors?.aoD_AppoinDocName}</p>}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={() => handleAddOtehrDoc(-1)}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Document Name</th>
                                        <th scope="col">Document Type</th>
                                        <th scope="col">View</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appoinmentProcess?.appoinmentotherdoc?.map((document, index) => {
                                        return (
                                            <tr>
                                                <td>{document?.aoD_AppoinDocName}</td>
                                                <td>{document?.aoD_AppoinDocType}</td>
                                                <td><button className="" onClick={handleViewDoc}>View</button></td>
                                                <td>
                                                    <button className="delete">
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddOtehrDoc(index)} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <label style={{ fontSize: '20px' }}> Job Details</label>
                                    {errors?.appoinmentjobdet && <p style={{ color: 'red' }}>{errors?.appoinmentjobdet}</p>}
                                </div>
                                <div className='col-md-3'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}> Job Code<span>*</span> </label>
                                        <AutoComplete options={jobMaster} placeholder={'Job Code...'}
                                            clsName="full-width"
                                            emitOption={(value: any) => setAppionJobDet({ ...appionJobDet, ajD_AppoinDesign: value?.Jobtitle, ajD_AppoinJobDuty: value?.JobDescription })}
                                            getOptionLabel='Jobcode' />
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Designation</label>
                                        <input type="text" name='ajD_AppoinDesign'
                                            disabled value={appionJobDet?.ajD_AppoinDesign}
                                            className='full-width' ></input>
                                    </div>
                                    {errors?.ajD_AppoinDesign && <p style={{ color: 'red' }}>{errors?.ajD_AppoinDesign}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Duty</label>
                                        <input type="text" name='ajD_AppoinJobDuty'
                                            disabled value={appionJobDet?.ajD_AppoinJobDuty}
                                            className='full-width' ></input>
                                    </div>
                                    {errors?.ajD_AppoinJobDuty && <p style={{ color: 'red' }}>{errors?.ajD_AppoinJobDuty}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={() => handleAddJobDet(-1)}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Designation</th>
                                        <th scope="col">Job Duty</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {appoinmentProcess?.appoinmentjobdet?.map((job, index) => {
                                        return (
                                            <tr>
                                                <td>{job?.ajD_AppoinDesign}</td>
                                                <td>{job?.ajD_AppoinJobDuty}</td>
                                                <td>
                                                    <button className="delete">
                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddJobDet(index)} />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label>Testimonials<span>*</span></label>
                                        <input
                                            type="file"
                                            className=""
                                            style={{ width: "60%" }}
                                            accept="image/*"
                                            name="employeePhoto"
                                        //   onChange={handleChangeImage}
                                        />
                                        {errors.itemImage && (
                                            <p style={{ color: "red" }}>{errors.itemImage}</p>
                                        )}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label>Photo<span>*</span></label>
                                        <input
                                            type="file"
                                            className=""
                                            style={{ width: "60%" }}
                                            accept="image/*"
                                            name="employeePhoto"
                                        //   onChange={handleChangeImage}
                                        />
                                        {errors.itemImage && (
                                            <p style={{ color: "red" }}>{errors.itemImage}</p>
                                        )}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                            </div>
                        </div>

                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToAppointmentProcessList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button>
                        </div>
                        {/* </form> */}
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AppointmentProcess;