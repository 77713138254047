import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import assignBatchNoDetails, { IAssignBatchNo } from '../model/index';


class AssignBatchNoStore {
    @observable assignBatchNoDetls = assignBatchNoDetails;
    @observable assignBatchNoList= new Map<number,IAssignBatchNo>();
    isInwardLoding=true;
    getAssignbatchs = new Map();
    getAssignbatchDetls = new Map();
     saveAssignBatchNo = async (assignBatchNo: IAssignBatchNo) => {
        try {
          const status=  await apiAgent.assignBatchNo.saveAssignBatchNo(assignBatchNo);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    updateAssignBatchNo = async (assignBatchNo: IAssignBatchNo) => {
        try {
          const status=  await apiAgent.assignBatchNo.updateAssignBatchNo(assignBatchNo);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignbatch=async ()=> {
        
        try {
          const getBatchs= await apiAgent.assignBatchNo.getAssignbatch();
            
            runInAction(() => {
                
                this.getAssignbatchs = getBatchs;
            });
            return this.getAssignbatchs;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignbatchDetails=async (assignNo)=> {
        
        try {
          const getBatchs= await apiAgent.assignBatchNo.getAssignBatchDetls(assignNo);
            
            runInAction(() => {
                
                this.getAssignbatchDetls = getBatchs;
            });
            return this.getAssignbatchDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignbatchSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.assignBatchNo.getAssignbatchSearch(SearchOptions);
            this.getAssignbatchs = data;
            
            runInAction(() => {
                this.getAssignbatchs = data;
                
            })
            return this.getAssignbatchs;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignbatchetail = async (assignDetilsNo) => {
        try {      
        const status=  await apiAgent.assignBatchNo.deleteAssignbatchetail(assignDetilsNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignbatchMaster = async (assignNo) => {
        try {      
        const status=  await apiAgent.assignBatchNo.deleteAssignbatchMaster(assignNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default AssignBatchNoStore;