import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import { useTranslation } from 'react-i18next';

const languageCode = [
    { code: 'en', language: 'English' },
    { code: 'ta', language: 'தமிழ்' },
    { code: 'hi', language: 'हिंदी' },
]
export default function BasicMenu() {
    const { i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function changeLanguage(lng) {
        i18n?.changeLanguage(lng)
        handleClose()
    }

    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <LanguageIcon sx={{ fontSize: '27px', display: 'flex', color: 'white', marginTop: '6px' }} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {languageCode?.map((language, index) => {
                    return (
                        <MenuItem key={index} onClick={() => changeLanguage(language?.code)}>{language?.language}</MenuItem>
                    )
                })}
            </Menu>
        </div>
    );
}