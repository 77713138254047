
function subcategoryValidation(subcategory){
  let errors :any={};
  
  if(!subcategory.categoryName){
    
    errors.categoryName = "Enter Category Name";
  }
  if(!subcategory.subCategoryName){
    errors.subCategoryName = "Enter Subcategory Name";
  }
  
    
  return errors;
};
  
  export default subcategoryValidation;