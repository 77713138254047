
function closingCashHandoverValidation(closingCardData){
 
  
    let errors :any={};
  
    if(!closingCardData.denomationDate){
      
      errors.denomationDate = "Select Counter Open Date";
    }
    if(!closingCardData.denomationTime){
      errors.denomationTime = "Select Counter Open Time";
    }
    if(!closingCardData.denomationCounterid){
      errors.denomationCounterid = "Select Counter Number";
    }
    if(!closingCardData.denomationAmount){
      errors.denomationAmount = "Enter Amount";
    }
    if(!closingCardData.posempid) {
      errors.posempid = "Enter POS Staff"
    }
    if(!closingCardData.receiveempid) {
      errors.receiveempid = "Enter Handover Receiver"
    }
    
    
    return errors;
  };
    
    export default closingCashHandoverValidation;