import {createContext} from 'react';
import EcomHomeStore from '../../gsmecom/Home/store/index';
import ProductStore from '../../gsmecom/Products/store/index';
import EcomMemberStore from '../../gsmecom/Login/store/index';
import CartsDetailsStore from '../cart/store';
import CheckoutStore from '../CheckOut/store';

 interface IEcomStore{
    ecomHomeStore:EcomHomeStore;
    productStore:ProductStore;
    ecomMemberStore:EcomMemberStore;
    cartsDetailsStore:CartsDetailsStore;
    checkoutStore : CheckoutStore;
}

export const ecomStore:IEcomStore={
    ecomHomeStore: new EcomHomeStore(),
    productStore:new  ProductStore(),
    ecomMemberStore: new EcomMemberStore(),
    cartsDetailsStore:new CartsDetailsStore(),
    checkoutStore : new CheckoutStore()
};

const EcomStoreContext = createContext(ecomStore)

export default  EcomStoreContext;
