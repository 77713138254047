import React, { useEffect, useRef, useState } from "react"
import useStores from "../../hooks"
import { useNavigate } from "react-router-dom"
import ProgressBar from "../../common/shared/progressbar"
import SelectSearchCriteria from "../../common/shared/selectSearchCriteria"
import { currentPageDatas } from "../../common/shared/utils"
import { Pagination, Stack } from "@mui/material"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { Modal } from "react-bootstrap"

const BillEntryDetails = () => {

    const { ledgerMasterStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate()

    const [isLoading, setLoading] = useState(true);

    const [billEntryMaster, setBillEntryMaster] = useState<any>([])
    const [billEntryDetails, setBillEntryDetails] = useState<any>([])
    const [billEntryTaxDet, setBillEntryTaxDet] = useState<any>([])

    // console.log(billEntryDetails, billEntryTaxDet, 'billEntryMaster>>>')

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [isModal, setIsModal] = useState({ bool: false, billId: 0 })
    const [taxModal, setTaxModal] = useState({ bool: false, billId: 0 })

    async function handleEntryDetails(billId) {
        const billMaster = await ledgerMasterStore.getBillENtryDetails(billId)
        if (billMaster?.length) {
            setBillEntryDetails([...billMaster])
        }
        setIsModal({ bool: true, billId: billId })
    }

    async function handleTaxDetails(billId) {
        const billMaster = await ledgerMasterStore.getBillENtryTaxDetails(billId)
        if (billMaster?.length) {
            setBillEntryTaxDet([...billMaster])
        }
        setTaxModal({ bool: true, billId: billId })
    }

    async function initialApiCall() {
        const userRights = await userCreationStore?.getUserRight('Bill_entry')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const billMaster = await ledgerMasterStore.getBillEntryMaster()
        if (billMaster?.length) {
            setBillEntryMaster([...billMaster])
        }
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (billEntryMaster && billEntryMaster?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(billEntryMaster)
        }
    }, [billEntryMaster])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(billEntryMaster?.slice()?.sort((a, b) => b.vchr_no - a.vchr_no), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div>
            {isLoading ? <ProgressBar /> : null}
            <div className='container-fluid'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={billEntryMaster}
                    searchTitles={[{ key: "vchr_no", value: "Voucher No" }, { key: "ledger_accout_name", value: "Ledger Account Name" }]}
                    emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='vertical-space-20'></div>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{billEntryMaster?.length} Records</button>
                    </div>
                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope='col'>Record No  </th>
                                <th scope='col'> Date   </th>
                                <th scope='col'>Bill No  </th>
                                <th scope='col'>Round off</th>
                                <th scope='col'>Bill Amount</th>
                                <th scope='col'>Narration</th>
                                <th scope='col'></th>
                                <th scope='col'></th>
                                <th scope='col'>Add</th>
                                <th scope='col'>Edit</th>
                                <th scope='col'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                (isSearch ? filteredData : currentPageData)?.map((oobj) => {
                                    return (
                                        <tr>
                                            <td>{oobj?.bill_entry_st_id}</td>
                                            <td>{moment(oobj?.bill_date).format("DD-MMM-YYYY")}</td>
                                            <td>{oobj?.bill_no}</td>
                                            <td>{oobj?.round_off}</td>
                                            <td>{oobj?.bill_amount}</td>
                                            <td>{oobj?.narration}</td>
                                            <td><button disabled={!userRights?.view} className='tablemodeltext' onClick={() => handleEntryDetails(oobj?.bill_entry_st_id)}> Bill Detail</button></td>
                                            <td><button disabled={!userRights?.view} className='tablemodeltext' onClick={() => handleTaxDetails(oobj?.bill_entry_st_id)}> Tax Detail</button></td>
                                            <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/BillEntry')}><FontAwesomeIcon icon={faAdd} /></button></td>
                                            <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/BillEntry/' + oobj?.vchr_no)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                        </tr>
                                    )
                                })}

                        </tbody>
                    </table>
                </div>

                <Modal show={isModal?.bool} onHide={() => setIsModal({ bool: false, billId: 0 })} className='PriceHistoryModel'>
                    <Modal.Header>
                        <Modal.Title>Bill Entry Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='tableBox'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Record No</th>
                                        <th scope='col'>Ledger Id</th>
                                        <th scope='col'>Amount  </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {billEntryDetails?.map((payment) => {
                                        return payment?.bill_entry_st_id === isModal?.billId && (
                                            <tr>
                                                <td>{payment?.bill_entry_st_id}</td>
                                                <td>{payment?.Input_Service_Ledger_Id}</td>
                                                <td>{payment?.AMOUNT}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={taxModal?.bool} onHide={() => setTaxModal({ bool: false, billId: 0 })} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                        <Modal.Title>Bill Tax Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='tableBox'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Record No</th>
                                        <th scope='col'> Ledger Id </th>
                                        <th scope='col'>Tax Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {billEntryTaxDet?.map((payment) => {
                                        console.log(billEntryDetails, taxModal, 'billEntryTaxDet>>')
                                        return payment?.bill_entry_st_id === taxModal?.billId && (
                                            <tr>
                                                <td>{payment?.bill_entry_st_id}</td>
                                                <td>{payment?.st_ledger_accout_id}</td>
                                                <td>{payment?.TAX_VALUE}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default BillEntryDetails;