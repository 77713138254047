import { action, set, makeObservable, observable, runInAction } from 'mobx';
import subCategoryDetails, { IsubCategory } from '../model/index';
import apiAgent from '../../../APIAgent';
import { stat } from 'fs';

class SubCategoryStore {
  @observable subCategoryDetls = subCategoryDetails;
  @observable subCategoryList = new Map();
  @observable isEdit = false;
  @observable SubcategoryStatus = new Map();

  savesubCategory = async (subCategory: IsubCategory) => {
    try {
      const status = await apiAgent.subcategory.saveSubCategory(subCategory);
      return status;
    }
    catch (error) {
      console.log(error);
    }
  }

  getSubcategory = async () => {
    try {
      const data = await apiAgent.subcategory.getSubcategory();
      runInAction(() => {
        this.subCategoryList = data;
      })
      return this.subCategoryList;
    }
    catch (error) {
      console.log(error);
    }
  }
  updateSubcategory = async (subcategory: IsubCategory) => {

    try {
      const status = await apiAgent.subcategory.updateSubcategory(subcategory);
      runInAction(() => {
        this.SubcategoryStatus = status;
      });

      return this.SubcategoryStatus;
    }
    catch (error) {
      console.log(error);
    }
  }
  SubcategoryDetails = async (subCategoryId: Number) => {

    try {
      const subcategoryData = await apiAgent.subcategory.SubcategoryDetails(subCategoryId);

      return subcategoryData;
    }
    catch (error) {
      console.log(error);
    }
  }
  deleteSubcategory = async (subCategoryId) => {
    try {
      const status = await apiAgent.subcategory.deleteSubcategory(subCategoryId);
      return status;
    }
    catch (error) {
      console.log(error);
    }
  }
  getSubcategorySearch = async (SearchOptions) => {
    try {
      const data = await apiAgent.subcategory.getSubcategorySearch(SearchOptions);
      this.subCategoryList = data;

      runInAction(() => {
        this.subCategoryList = data;

      })
      return this.subCategoryList;

    }
    catch (error) {
      console.log(error);
    }
  }

  saveCategory = async (category: any) => {
    try {
      const status = await apiAgent.subcategory.saveCategory(category);
      return status;
    }
    catch (error) {
      console.log(error);
    }
  }
  loadCatergoryMaster = async () => {
    try {
      const data = await apiAgent.subcategory.getCategory();
      return JSON.parse(data);
    }
    catch (error) {
      console.log(error);
    }
  }
  deleteCategoryMaster = async (subCategoryId) => {
    try {
      const status = await apiAgent.subcategory.deleteCategory(subCategoryId);
      return status;
    }
    catch (error) {
      console.log(error);
    }
  }
}
export default SubCategoryStore;