import { action } from 'mobx';
//import {numeric} from '../../../common/commonStore/const';
function outletValidator(outlets){
 
  
  let errors :any={};
  const numeric = /^[0-9\b]+$/
  const zipCode=/[1-9][0-9]{5}$/
  
  if(!outlets.outletName){
    errors.outletName = "Enter Outlet Name";
  }
  if(!outlets.dateOfIncrorpeted){
    errors.dateOfIncrorpeted = "Select Date of Incorporated";
  }
 
  if(!outlets.pinCode){
    errors.pinCode = "Enter Pin Code";
  }
  if(!outlets.city){
    errors.city = "Select City";
  }
  if(!outlets.fssainumber){
    errors.fssainumber = "Enter Fssai Number";
  }
  if(!zipCode.test(outlets.pinCode)){
    errors.pinCode = "Enter Valid PinCode";
  }
  if(!outlets.prefix){
    errors.prefix ="Enter prefix/sholud be 3 characters"
  }

  return errors;
};
  
  export default outletValidator;