
function purchaseBillValidation(purchaseBill,purchaseBillDetails){
    let errors :any={};
    
     
    
      if(!purchaseBill.purchaseBillDate){
        errors.purchaseBillDate = "Select PurchaseBill Date";
      }
      if(!purchaseBill.purchaseBillSupplierId){
        errors.purchaseBillSupplierId = "Select Supplier";
      }
      if(!purchaseBill.purchaseBillNo){
        errors.purchaseBillNo = "Enter Bill No";
      }
       if(!purchaseBill.billDate){
        errors.billDate = "Select Bill Date";
      }
     if(!purchaseBill.remarks){
        errors.remarks = "Enter Remarks";
      }
      if(purchaseBillDetails.length===0){
        errors.purchaseBillDetails = "Select Inward";
      }
      
    return errors;
  };
    
    export default purchaseBillValidation;