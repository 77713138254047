export class IDeductionModalFields {
    Employeeid?: number = 0;
    Percentage?: number = 0;
    Deductiontype?: string = "";
    additional?: boolean = false;
    additionalAmt?: number = 0;
}

export class IAllowancdModalFields {
    Employeeid?: number = 0;
    Amount?: number = 0;
    EarnType?: string = "";
    additional?: boolean = false;
    additionalAmt?: number = 0;
}