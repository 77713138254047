import { createContext } from 'react';
import outletStore from '../Admin/Outlet/store/index';
import warehouseStore from '../Admin/warehouse/store';
import BrandStore from '../Warehouse/Brand/store';
import commonStore from '../common/commonStore';
import ItemInwardStore from '../Warehouse/itemInward/store';
import ItemMasterStore from '../Warehouse/ItemMaster/store';
import supplierMasterStore from '../Warehouse/SupplierMaster/store';
import departmentStore from '../HR/Department/store';
import designationStore from '../HR/Desigination/store';
import MemberStore from '../BackOffice/NewMember/store';

import AssignBatchNoStore from '../Warehouse/assignBatchNo/store';
import UnitOfMeasurementStore from '../Warehouse/UnitOfMeasure/store';
import OutwardtoOutletStore from '../Warehouse/OutwardtoOutlet/store';
import AssignOfferDiscountStores from '../CRM/AssignOfferDiscount/store';
import OfferDiscountStore from '../CRM/ManageOfferOrDiscount/store';
import ManagePriceChangeStore from '../CRM/PriceChangeUpdate/store';
import OutletItemRequestStore from '../Stores/outletItemRequest/store';
import IEmployeeStore from '../HR/Employee/store';
import ItemConversionStore from '../Warehouse/itemConversion/store';
import TeamMasterStore from '../Warehouse/teamMaster/store';
import LedgerGroupStore from '../Accounts/LedgerGroup/store';
import LedgerMasterStore from '../Accounts/LedgerMaster/store';
import UserCreationStore from '../Admin/userCreation/store';
import OutletManagerStore from '../Admin/assignOutletManager/store';
import WareHouseManagerStore from '../Admin/assignWareHouseManager/store';
import SubCategoryStore from '../Warehouse/SubCategory/store';
import PackingDistributionStore from '../Warehouse/packingDistribution/store';
import PackingInwardStore from '../Warehouse/packingInward/store';
import AssignBatchPackingStore from '../Warehouse/assignBatchPacking/store';
import TerminalCreationStore from '../BackOffice/terminalCreation/store';
import ShiftMasterStore from '../BackOffice/shiftMaster/store';
import InwardOultetStore from '../Stores/InwardOulet/store';
import PosMasterStore from '../Admin/POS/store';
import AssignPosStore from '../BackOffice/assignPOSStaff/store';
import RackAllotmentStore from '../Stores/rackAllotment/store';
import SellingPriceUpdateStore from '../Admin/sellingPriceUpdate/store';
import AssignProductStore from '../Admin/assignProduct/store';
import ManageLoyaltyStore from '../CRM/ManageLoyalty/store';
import AssignRedemptionStore from '../CRM/AssignRedemption/store';
import StockAnalysisStore from '../Reports/StockAnalysis/store';
import AssignOrderStore from '../Admin/AssignOrder/store';
import DespatchStore from '../Stores/despatchEntry/store';
import OutletStockAnalysisStore from '../Reports/OutetStockAnalysis/store';
import CourierPartnerStore from '../Admin/CourierPartnerCreation/store';
import AdminMemberStore from '../Login/store';
import DamageEntryOutletStore from '../Warehouse/DamageEntryOutlet/store';
import DamageEntryWareHouseStore from '../Warehouse/DamageEntryWarehouse/store';
import SalesRegisterStore from '../Admin/SalesRegister/store';
import CourierEntryStore from '../Stores/CourierEntry/store';
import InwardRegisterStore from '../Admin/InwardRegister/store';
import AssignFreeItemStore from '../CRM/AssignFreeItem/store';
import SalesReplaceRetrunStore from '../BackOffice/SalesReturnOrReplacement/store';
import MemberPointsStore from '../Admin/MemberPointsStatement/store';
import MinimumStockStore from '../Admin/MinmumStockLevel/store';
import CounterOpenStore from '../BackOffice/CounterOpeningEntry/store';
import InvoiceStore from '../BackOffice/Invoice/store';
import ReceiptStore from '../Accounts/ReceiptEntry/store';
import IndentStore from '../Warehouse/Indent/store';
import QuoteFromSupplierStore from '../Admin/QuoteFromSupplier/store';
import PurchaseBillStore from '../Accounts/PurchaseBill/store';
import DirectInwardStore from '../Stores/directInwardOutlet/store';
import AssignBatchOutletStore from '../Stores/assignBatchOutlet/store';
import PartyLedgerStore from '../Admin/PartyLedger/store';
import PurchaseApprovalStore from '../Warehouse/PurchaseApproval/store';
import StockSummaryStore from '../Reports/StockSummary/store';
import OutletStockSummaryStore from '../Reports/OutletStockSummary/store';
import SalesSummaryItemWiseStore from '../Reports/SalesSummaryItemWise/store';
import PurchaseBillRegStore from '../Reports/PurchaseBillRegister/store';
import PurchaseOrderStore from '../Warehouse/PurchaseOrder/store';
import ExpiryRegisterStore from '../Reports/ExpiryRegister/store';
import PaymentVoucherStore from '../Accounts/PaymentVoucher/store';
import BarcodePriceHistoryStore from '../Reports/BarcodePriceHistory/store';
import ItemwiseMarginStore from '../Reports/ItemwiseMargin/store';
import MachineMasterStore from '../Warehouse/machineMaster/store';
import IPayrollStore from '../Payrol/store';
import IGSTRAnnexureStore from '../Reports/GSTRAnnexure/store';
import IJobMasterStore from '../HR/JobMaster/store';
import IResumeBankStore from '../HR/ResumeBank/store';
import IInterviewStore from '../HR/InterviewMaster/store';
import IEmployeePromotionStore from '../HR/EmployeePromotion/store';
import IAppoinmentStore from '../HR/AppointmentProcess/store';
import IHRReports from '../HRReports/store';
import CategoryAssignMasterStore from '../CRM/CategoryAssignOffers/store';
import StockAdjustmentStore from '../Admin/stockadjustment/store';
import StockAdjustmentOutletStore from '../BackOffice/stockadjustmentoutlet/store';

interface IStore {
    outletStores: outletStore;
    warehouseStores?: warehouseStore;
    brandStore: BrandStore;
    commonStore: commonStore;
    inwardStore: ItemInwardStore;
    itemMasterStore: ItemMasterStore;
    supplierMasterStore: supplierMasterStore;
    departmentStore: departmentStore;
    designationStore: designationStore;
    memberStore: MemberStore;
    outletItemRequestStore: OutletItemRequestStore;
    assignBatchNoStore: AssignBatchNoStore;
    assignOfferDiscountStores: AssignOfferDiscountStores;
    unitOfMeasurementStore: UnitOfMeasurementStore;
    outwardtoOutletStore: OutwardtoOutletStore;
    stockAdjustmentStore: StockAdjustmentStore;
    stockAdjustmentOutletStore: StockAdjustmentOutletStore,
    offerDiscountStore: OfferDiscountStore;
    managePriceChangeStore: ManagePriceChangeStore;
    employeeStore: IEmployeeStore;
    jobMasterStore: IJobMasterStore;
    resumeBankStore: IResumeBankStore;
    interviewStore: IInterviewStore,
    appoinmentStore: IAppoinmentStore;
    gstrStore: IGSTRAnnexureStore;
    payrollStore: IPayrollStore;
    hrReporsStore: IHRReports;
    employeePromotionStore: IEmployeePromotionStore;
    itemConversionStore: ItemConversionStore;
    teamMasterStore: TeamMasterStore;
    categoryAssignMasterStore: CategoryAssignMasterStore;
    ledgerGroupStore: LedgerGroupStore;
    ledgerMasterStore: LedgerMasterStore;
    userCreationStore: UserCreationStore;
    outletManagerStore: OutletManagerStore;
    wareHouseManagerStore: WareHouseManagerStore;
    subCategoryStore: SubCategoryStore;
    packingDistributionStore: PackingDistributionStore;
    packingInwardStore: PackingInwardStore;
    assignBatchPackingStore: AssignBatchPackingStore;
    terminalCreationStore: TerminalCreationStore;
    shiftMasterStore: ShiftMasterStore;
    inwardOultetStore: InwardOultetStore;
    assignPosStore: AssignPosStore;
    posMasterStore: PosMasterStore;
    rackAllotmentStore: RackAllotmentStore;
    sellingPriceUpdateStore: SellingPriceUpdateStore;
    assignProductStore: AssignProductStore;
    manageLoyaltyStore: ManageLoyaltyStore;
    assignRedemptionStore: AssignRedemptionStore;
    stockAnalysisStore: StockAnalysisStore;
    stockSummaryStore: StockSummaryStore;
    assignOrderStore: AssignOrderStore;
    despatchStore: DespatchStore;
    outletStockAnalysisStore: OutletStockAnalysisStore;
    courierPartnerStore: CourierPartnerStore;
    adminMemberStore: AdminMemberStore;
    damageEntryOutletStore: DamageEntryOutletStore;
    DamageEntryWareHouseStore: DamageEntryWareHouseStore;
    courierEntryStore: CourierEntryStore,
    salesRegisterStore: SalesRegisterStore,
    inwardRegisterStore: InwardRegisterStore,
    assignFreeItemStore: AssignFreeItemStore,
    salesReplaceRetrunStore: SalesReplaceRetrunStore,
    memberPointsStore: MemberPointsStore,
    minimumStockStore: MinimumStockStore,
    counterOpenStore: CounterOpenStore,
    invoiceStore: InvoiceStore,
    receiptStore: ReceiptStore;
    indentStore: IndentStore;
    quoteFromSupplierStore: QuoteFromSupplierStore;
    purchaseBillStore: PurchaseBillStore;
    directInwardStore: DirectInwardStore;
    assignBatchOutletStore: AssignBatchOutletStore;
    partyLedgerStore: PartyLedgerStore;
    purchaseApproval: PurchaseApprovalStore,
    outletStockSummaryStore: OutletStockSummaryStore,
    salesSummaryItemWiseStore: SalesSummaryItemWiseStore,
    purchaseBillRegStore: PurchaseBillRegStore,
    purchaseOrderStore: PurchaseOrderStore,
    expiryRegStore: ExpiryRegisterStore,
    paymentVocherStore: PaymentVoucherStore,
    barcodePriceHistoryStore: BarcodePriceHistoryStore,
    itemwiseMarginStore: ItemwiseMarginStore,
    machineMasterStore: MachineMasterStore,
}

export const store: IStore = {
    outletStores: new outletStore(),
    warehouseStores: new warehouseStore(),
    brandStore: new BrandStore(),
    commonStore: new commonStore(),
    inwardStore: new ItemInwardStore(),
    itemMasterStore: new ItemMasterStore(),
    supplierMasterStore: new supplierMasterStore(),
    departmentStore: new departmentStore(),
    designationStore: new designationStore(),
    memberStore: new MemberStore(),
    outletItemRequestStore: new OutletItemRequestStore(),
    assignBatchNoStore: new AssignBatchNoStore(),
    assignOfferDiscountStores: new AssignOfferDiscountStores(),
    unitOfMeasurementStore: new UnitOfMeasurementStore(),
    outwardtoOutletStore: new OutwardtoOutletStore(),
    stockAdjustmentStore: new StockAdjustmentStore(),
    stockAdjustmentOutletStore: new StockAdjustmentOutletStore(),
    offerDiscountStore: new OfferDiscountStore(),
    managePriceChangeStore: new ManagePriceChangeStore(),
    employeeStore: new IEmployeeStore(),
    jobMasterStore: new IJobMasterStore(),
    resumeBankStore: new IResumeBankStore(),
    interviewStore: new IInterviewStore(),
    appoinmentStore: new IAppoinmentStore(),
    gstrStore: new IGSTRAnnexureStore(),
    payrollStore: new IPayrollStore(),
    hrReporsStore: new IHRReports(),
    employeePromotionStore: new IEmployeePromotionStore(),
    itemConversionStore: new ItemConversionStore(),
    teamMasterStore: new TeamMasterStore(),
    categoryAssignMasterStore: new CategoryAssignMasterStore(),
    ledgerGroupStore: new LedgerGroupStore(),
    ledgerMasterStore: new LedgerMasterStore(),
    userCreationStore: new UserCreationStore(),
    outletManagerStore: new OutletManagerStore(),
    wareHouseManagerStore: new WareHouseManagerStore(),
    subCategoryStore: new SubCategoryStore(),
    packingDistributionStore: new PackingDistributionStore(),
    packingInwardStore: new PackingInwardStore(),
    assignBatchPackingStore: new AssignBatchPackingStore(),
    terminalCreationStore: new TerminalCreationStore(),
    shiftMasterStore: new ShiftMasterStore(),
    inwardOultetStore: new InwardOultetStore(),
    assignPosStore: new AssignPosStore(),
    posMasterStore: new PosMasterStore(),
    rackAllotmentStore: new RackAllotmentStore(),
    sellingPriceUpdateStore: new SellingPriceUpdateStore(),
    assignProductStore: new AssignProductStore(),
    manageLoyaltyStore: new ManageLoyaltyStore(),
    assignRedemptionStore: new AssignRedemptionStore(),
    stockAnalysisStore: new StockAnalysisStore(),
    stockSummaryStore: new StockSummaryStore(),
    assignOrderStore: new AssignOrderStore(),
    despatchStore: new DespatchStore(),
    outletStockAnalysisStore: new OutletStockAnalysisStore(),
    courierPartnerStore: new CourierPartnerStore(),
    adminMemberStore: new AdminMemberStore(),
    damageEntryOutletStore: new DamageEntryOutletStore(),
    DamageEntryWareHouseStore: new DamageEntryWareHouseStore(),
    courierEntryStore: new CourierEntryStore(),
    salesRegisterStore: new SalesRegisterStore(),
    inwardRegisterStore: new InwardRegisterStore(),
    assignFreeItemStore: new AssignFreeItemStore(),
    salesReplaceRetrunStore: new SalesReplaceRetrunStore(),
    memberPointsStore: new MemberPointsStore(),
    minimumStockStore: new MinimumStockStore(),
    counterOpenStore: new CounterOpenStore(),
    invoiceStore: new InvoiceStore(),
    receiptStore: new ReceiptStore(),
    indentStore: new IndentStore(),
    quoteFromSupplierStore: new QuoteFromSupplierStore(),
    purchaseBillStore: new PurchaseBillStore(),
    directInwardStore: new DirectInwardStore(),
    assignBatchOutletStore: new AssignBatchOutletStore(),
    partyLedgerStore: new PartyLedgerStore(),
    purchaseApproval: new PurchaseApprovalStore(),
    outletStockSummaryStore: new OutletStockSummaryStore(),
    salesSummaryItemWiseStore: new SalesSummaryItemWiseStore(),
    purchaseBillRegStore: new PurchaseBillRegStore(),
    purchaseOrderStore: new PurchaseOrderStore(),
    expiryRegStore: new ExpiryRegisterStore(),
    paymentVocherStore: new PaymentVoucherStore(),
    barcodePriceHistoryStore: new BarcodePriceHistoryStore(),
    itemwiseMarginStore: new ItemwiseMarginStore(),
    machineMasterStore: new MachineMasterStore()
};

const StoreContext = createContext(store)

export default StoreContext;
