import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { set } from 'mobx';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas, getCurrentMonthFirstDate, removeDuplicates } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import { SearchDates } from './model';
import moment from 'moment';

const QuoteFromSupplierDetail = observer((): JSX.Element => {
  const navigate = useNavigate();
  const { quoteFromSupplierStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const [rate, setRate] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [searchFileds, setSearchFields] = useState<SearchDates>(new SearchDates())

  const [loadQuoteFromSupplierList, setloadQuoteFromSupplierList] = useState<any[]>([])

  function handleCreate() {
    navigate("/Admin/QuoteFromSupplier")
  }

  const rateClose = () => setRate(false);

  const supplierMasterDetails = async (supplierId) => {
    setLoading(true);
    await quoteFromSupplierStore.loadSupplierDetails(supplierId);
    setLoading(false);
    setRate(true);

  }

  async function preLoadingFunctions() {
    setLoading(false);
    const [userRights, loadQuoteFromSupplierList] = await Promise.all([userCreationStore?.getUserRight('Quote_from_supplier'), quoteFromSupplierStore.loadQuoteFromSupplierDetails()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    if (loadQuoteFromSupplierList?.length) {
      setloadQuoteFromSupplierList([...loadQuoteFromSupplierList])
    } else {
      setloadQuoteFromSupplierList([])
    }
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      preLoadingFunctions();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (loadQuoteFromSupplierList && loadQuoteFromSupplierList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(loadQuoteFromSupplierList)
    }
  }, [loadQuoteFromSupplierList])

  const goToPage = (value: number) => {
    let ascendingOrder = loadQuoteFromSupplierList?.slice().sort((a, b) => Number(b?.quoteno?.split('-')[1]) - Number(a?.quoteno?.split('-')[1]))
    const currentPageList = currentPageDatas(removeDuplicates(ascendingOrder, 'quoteno'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  function handleFindDate(date) {
    if (searchFileds?.fromdate && searchFileds?.todate) {
      const yesterday = new Date(searchFileds?.fromdate);
      yesterday.setDate(yesterday.getDate() - 1)
      if (new Date(date) >= yesterday && new Date(date) < new Date(searchFileds?.todate)) {
        return true;
      } else {
        return false
      }
    } else if (searchFileds?.fromdate) {
      if (new Date(date) > new Date(searchFileds?.fromdate)) {
        return true
      } else {
        return false
      }
    } else if (searchFileds?.todate) {
      if (new Date(date) < new Date(searchFileds?.todate)) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>

      <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
        <div className='inputBoxLists'>
          <div className='ItemInwardInputBox'>
            <div className='row'>
              <div className="col-md-2">
                <label>From Date</label>
                <div className="inputBox">
                  <input type="date"
                    placeholder="DD/MMM/YYYY"
                    data-date="" style={{ width: "100%" }}
                    data-date-format="DD MMMM YYYY"
                    value={searchFileds?.fromdate}
                    onChange={(e) => setSearchFields({ ...searchFileds, fromdate: e.target.value })}
                    name="fromdate"
                  ></input>
                </div>
                <div className="vertical-space-10"></div>
              </div>
              <div className="col-md-2">
                <div className="inputBox">
                  <label> To Date</label>
                  <input type="date"
                    style={{ width: "100%" }}
                    placeholder="DD/MMM/YYYY"
                    data-date=""
                    data-date-format="DD MMMM YYYY"
                    value={searchFileds?.todate}
                    onChange={(e) => setSearchFields({ ...searchFileds, todate: e.target.value })}
                    name="todate"
                  ></input>
                </div>
                <div className="vertical-space-10"></div>
              </div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={loadQuoteFromSupplierList}
                searchTitles={[{ key: "quoteno", value: "Quote No" }, { key: "Employee_Name", value: "Entered By" }, { key: "itemname", value: "Item Name" }, { key: "SupplierName", value: "Supplier" }]}
                emitClear={() => setSearchFields(new SearchDates())}
                emitSearchArray={(filterArray: any) => setFilteredData(removeDuplicates(filterArray, 'quoteno'))} isEmitClear />
            </div>
            <div className='vertical-space-20'></div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(loadQuoteFromSupplierList, 'quoteno')?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              {isLoading ?
                <div className='SpinnerBox'>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div> : null}
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Quote No</th>
                    <th scope='col'>Quote Date</th>
                    <th scope='col'>Entered By</th>
                    <th scope='col'></th>
                    <th scope='col'>Create </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                      return ((searchFileds?.fromdate && searchFileds?.todate) ? handleFindDate(val?.quotedate) : val?.quotedate) && (
                        <tr key={key}>
                          <td>{val.quoteno} </td>
                          <td>{moment(val.quotedate)?.format('DD-MM-YYYY')} </td>
                          <td>{val.Employee_Name} </td>
                          <td><button disabled={!userRights?.view} onClick={() => supplierMasterDetails(val.quotefromsupplierid)} >Rates</button></td>
                          <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                          <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                          <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={rate} onHide={rateClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Rates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Item Name</th>
                  <th scope="col">Supplier Name</th>
                  <th scope="col">Rate </th>
                  <th scope="col">Eff. From  </th>
                </tr>
              </thead>
              <tbody>
                {
                  quoteFromSupplierStore?.loadSuppliers?.length > 0 ?
                    quoteFromSupplierStore?.loadSuppliers?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.itemname}</td>
                          <td>{val.supplierName}</td>
                          <td>{val.rate}</td>
                          <td>{val.effectiveFrom}</td>
                        </tr>
                      )
                    }) : <>No Data</>
                }
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );

});

export default QuoteFromSupplierDetail;
