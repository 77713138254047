import { action, set, makeObservable, observable, runInAction } from 'mobx';
import brandDetails, { IBrand } from '../model/index';
import apiAgent from '../../../APIAgent';

class BrandStore {
    brandDetls = brandDetails;
    @observable brandList= new Map();
    isLoading=true;
    @observable brandLists = new Map<any, any>();
    @observable  entryNo=new Map();
    @observable isEdit=false;
    @observable BrandStatus = new Map();

    constructor() {
        makeObservable(this)
    }

     saveBrand = async (brand: IBrand) => {
        try {
          const status=  await apiAgent.brand.saveBrand(brand);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getBrand = async ()=>{
        try{
            const brandData = await apiAgent.brand.getBrand();
            this.brandList = brandData;
            
            runInAction(()=>{
                this.brandList = brandData;
                this.isLoading=false;
            })
            return brandData;
        }
        catch(error){
        console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.brand.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return entryNo
        }
        catch(error){
        console.log(error);
        }
    }

    updateBrand = async (brand: IBrand) => {
        
        try {      
        const status=  await apiAgent.brand.updateBrand(brand);     
        runInAction(() => {
        this.BrandStatus = status;
        });
    
        return this.BrandStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteBrand = async (brandId) => {
        try {      
        const status=  await apiAgent.brand.deleteBrand(brandId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    BrandDetails  = async (brandId:Number)=> {
    
        try {
        const brandsData= await apiAgent.brand.BrandDetails(brandId);
            
          return brandsData;
        }
        catch (error) {
            console.log(error);
        }
    }
    getBrandSearch  = async (SearchOptions)=> {
        try {
          const brandData=await apiAgent.brand.getBrandSearch(SearchOptions);
            this.brandList = brandData;
            
            runInAction(() => {
                this.brandList = brandData;
                
            })
            return this.brandList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }

    @action getBrandDetails =async (brandId)=>{
        try{
            const brandData = await apiAgent.brand.getBrandDetails(brandId);  
        }
        catch(error){
            console.log(error);
        }
    }    
    
    @action setBrand = (state:IBrand)=>{
        this.brandList.set(0,state);
    }
    @action setBrandList = (state:IBrand[])=>{
        set(state,this.brandList);
        return this.brandList;
    }
}
export default BrandStore;