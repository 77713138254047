import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import useStores from "../../hooks";
import { IIInterveiwMaster, IIInterviewMasterDetails } from "./model";
import { addValidation, saveValidation } from "./validation";

const InterviewMaster = () => {

    const navigate = useNavigate();

    const { interviewStore, jobMasterStore } = useStores()

    const [errors, setErrors] = useState<any>({});
    const [jobMaster, setJobMaster] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [interviewFields, setInterviewFields] = useState<IIInterveiwMaster>(new IIInterveiwMaster());
    const [interviewList, setInterviewList] = useState<IIInterviewMasterDetails[]>(new Array<IIInterviewMasterDetails>());

    const handleFailureModalClose = () => setFailureModal(false);

    async function handleSaveCategory() {
        let error: any = {}
        error = saveValidation(interviewList)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const entryNo = await jobMasterStore.getHREntryNo('Interviewmaster');
            interviewFields.interviewid = Number(entryNo?.entrynumber);
            interviewFields.interviewcode = Number(entryNo?.entrynumber);
            interviewFields.interviewmstdet = interviewList;
            const status = await interviewStore?.saveInterviewMaster(interviewFields);
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToDeductionList() {
        navigate("/Admin/InterviewMaster/InterviewMasterList");
    }

    const phases = [{ key: 'Round 1', value: 'Round 1' }, { key: 'Round 2', value: 'Round 2' }, { key: 'Round 3', value: 'Round 3' }]

    function handleAddInterview(index: number) {
        if (index! >= 0) {
            interviewList?.splice(index, 1)
            setInterviewList([...interviewList])
        } else {
            let error: any = {};
            error = addValidation(interviewFields);
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                let interviewObj: IIInterviewMasterDetails = {
                    giD_Duration: interviewFields?.duration,
                    giD_Intmasterid: interviewFields?.interviewid,
                    giD_Jobcode: interviewFields?.jobcode,
                    giD_Phases: interviewFields?.phases,
                    giD_Process: interviewFields?.process,
                }
                interviewList?.push(interviewObj);
                setInterviewList([...interviewList])
                setInterviewFields({
                    ...interviewFields, phases: "", process: "", duration: '00:00'
                })
                setHours('00')
                setMinutes('00')
            }
        }
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('Interviewmaster');
        setInterviewFields({
            ...interviewFields,
            interviewid: Number(entryNo?.entrynumber),
            interviewcode: Number(entryNo?.entrynumber)
        })
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        setLoading(false)
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    const [hours, setHours] = useState<string>('00');
    const [minutes, setMinutes] = useState<string>('00');

    function handleOnChange(duration) {
        let { hours, minutes } = duration;
        let padHour = (hours)?.toString()?.padStart(2, '0');
        let padMin = minutes?.toString()?.padStart(2, '0')
        setInterviewFields({ ...interviewFields, duration: `${padHour}:${padMin}` })
    }
    const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value.length > 2) {
            value = value.slice(-2); // keep only last two digits
        }
        if (value === '' || value === '0' || value === '00') {
            setHours('00');
            handleOnChange({ hours: 0, minutes: parseInt(minutes, 10) });
            return;
        }
        if (!isNaN(Number(value)) && Number(value) <= 23) {
            setHours(value);
            handleOnChange({ hours: parseInt(value, 10), minutes: parseInt(minutes, 10) });
        }
    };

    const handleMinutesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value.length > 2) {
            value = value.slice(-2); // keep only last two digits
        }
        if (value === '') {
            setMinutes('0');
            handleOnChange({ hours: parseInt(hours, 10), minutes: 0 });
            return;
        }
        if (!isNaN(Number(value))) {
            const newMinutes = Math.min(59, parseInt(value, 10)); // restrict minutes to 0-59
            setMinutes(newMinutes.toString());
            handleOnChange({ hours: parseInt(hours, 10), minutes: newMinutes });
        }
    };


    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        <div className="hrBox">
                            <h3>Interview Master</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Interview Code</label>
                                        <input type="text"
                                            name='brandId' disabled
                                            value={interviewFields?.interviewid}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Interview Date</label>
                                        <input type="date" name="fromdate"
                                            className="inputBoxDate"
                                            value={new Date()?.toISOString()?.substr(0, 10)}
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date="" data-date-format="DD MMMM YYYY"
                                        ></input>
                                        {errors.brandCode && (<p style={{ color: "red" }}>{errors.brandCode}</p>)}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Job Code<span>*</span> </label>
                                        <select name="unittype" className="form-selected full-width"
                                            value={interviewFields?.jobcode} onChange={(e) => setInterviewFields({ ...interviewFields, jobcode: Number(e.target.value) })}
                                        >
                                            <option value="" selected>- - Select - -</option>
                                            {jobMaster?.map((obj) => {
                                                return (
                                                    <option value={obj?.Jobcode}>{obj?.Jobcode}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors.jobcode && (<p style={{ color: "red" }}>{errors.jobcode}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Title</label>
                                        <input type="text"
                                            name='brandId' disabled
                                            value={jobMaster?.find((each) => each?.Jobcode == interviewFields?.jobcode)?.Jobtitle}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Description</label>
                                        <textarea name='brandId' disabled
                                            value={jobMaster?.find((each) => each?.Jobcode == interviewFields?.jobcode)?.JobDescription}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></textarea>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Rounds<span>*</span></label>
                                        <select style={{ fontSize: "16px" }} name="unittype"
                                            className="form-selected full-width"
                                            value={interviewFields?.phases} onChange={(e) => setInterviewFields({ ...interviewFields, phases: (e.target.value) })}
                                        >
                                            <option value="" selected>- - Select - -</option>
                                            {phases?.map((obj) => {
                                                return (
                                                    <option value={`${obj?.key}`}>{obj?.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors.phases && (<p style={{ color: "red" }}>{errors.phases}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Process<span>*</span></label>
                                        <input type="text"
                                            name='brandId'
                                            value={interviewFields?.process} onChange={(e) => setInterviewFields({ ...interviewFields, process: (e.target.value) })}
                                            style={{ width: "100%" }}
                                            className='brandinput' ></input>
                                    </div>
                                    {errors.process && (<p style={{ color: "red" }}>{errors.process}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Duration<span>*</span></label>
                                        <input type="text" style={{ width: '50%', }} value={hours} onChange={handleHoursChange} maxLength={2} />
                                        <input type="text" style={{ width: '50%', }} value={minutes} onChange={handleMinutesChange} maxLength={2} />
                                    </div>
                                    {errors.duration && (<p style={{ color: "red" }}>{errors.duration}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="btnBox">
                                        <button className="dfBtn"
                                            onClick={() => handleAddInterview(-1)}>
                                            Add
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Job Code</th>
                                        <th scope="col">Round</th>
                                        <th scope="col">Process</th>
                                        <th scope="col">Duration</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {interviewList?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{val?.giD_Jobcode}</td>
                                                <td>{val?.giD_Phases}</td>
                                                <td>{val?.giD_Process}</td>
                                                <td>{val?.giD_Duration}</td>
                                                <td>
                                                    <button className="delete">
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            onClick={() => {
                                                                handleAddInterview(key);
                                                            }}
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToDeductionList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleToDeductionList}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleToDeductionList}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    )
}

export default InterviewMaster;