import React, { useState, useEffect, useRef, useMemo } from 'react'
import { LightTooltip } from '../../../../gsmadmin/common/shared/lightToolPit'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faSearch } from '@fortawesome/free-solid-svg-icons';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import '../../../Ecom.css';
import './productByCategory.css'
import QuickView from '../quickView/quickView';

interface ProductsByCategoryProps {
    combinedItemData: Array<any>,
    handleNavigate: (itemId) => void,
    categoryName: string;
    addToCart: (product) => void
}

const ProductsByCategory: React.FC<ProductsByCategoryProps> = ({ combinedItemData, handleNavigate, categoryName, addToCart }) => {

    const [modalItem, setModalItem] = useState<any>({})

    function handleOpenModal(itemId) {
        if (combinedItemData?.length && itemId) {
            let findItem = combinedItemData?.find((each) => each?.posItemId === itemId)
            setModalItem({ ...findItem })
        }
    }
    return (
        <>
            <div className='categories container'>
                <div className='vertical-space-60'></div>
                <div className='title'><span>{categoryName}</span></div>
                <div className='vertical-space-20'></div>
                <div className="section">
                    <OwlCarousel className='owl-theme' items={5} autoplayTimeout={5000} autoplay={true} loop margin={4} dots={false} nav={true}>
                        {combinedItemData?.map((val, index) => {
                            return (
                                <div key={index} className='slide-product owl-carousel-item'>
                                    <div className="product-item-grid">
                                        <div className='product-element-top wd-quick-shop'>
                                            <div className="product-image-link">
                                                <img className='attachment-medium size-medium wd-lazy-load wd-lazy-fade wd-loaded'
                                                    src={`data:image/png;base64,${val?.imageData}`} alt='Product-Image' width={300} height={200} />
                                            </div>
                                            <div className="wd-buttons wd-pos-r">
                                                <span>
                                                    <LightTooltip placement="left" arrow style={{ background: 'black', color: 'white' }} title="Quick View">
                                                        <FontAwesomeIcon icon={faSearch} style={{ height: '24px', padding: '12px' }} onClick={() => handleOpenModal(val?.posItemId)} />
                                                    </LightTooltip>
                                                </span>
                                                <span>
                                                    <LightTooltip placement="left" arrow style={{ background: 'black', color: 'white' }} title="Add to wishlist">
                                                        <FavoriteBorderIcon style={{ fontSize: '28px', margin: '4px' }} onClick={() => handleOpenModal(val?.posItemId)} />
                                                    </LightTooltip>
                                                </span>
                                            </div>
                                        </div>
                                        <h3 className='wd-entities-title' onClick={() => handleNavigate(val?.posItemId)} style={{ textTransform: 'uppercase' }}>
                                            <a href='#'>{val?.posItemName}</a>
                                        </h3>
                                        <span className='price'>
                                            <del>
                                                <span className='woocommerce-Price-amount amount'>
                                                    <bdi><span>₹</span> {Number(val?.posItemSellingRate)?.toFixed(0)}</bdi>
                                                </span>
                                            </del>
                                            <ins className='woocommerce-Price-amount amount'>
                                                <bdi><span>₹</span> {Number(val?.posItemMRP)?.toFixed(0)}  </bdi>
                                            </ins>
                                        </span>
                                        <div className='add-to-cart' onClick={() => addToCart(val)}>
                                            <button className='cart-button'><FontAwesomeIcon icon={faCartShopping} /><span className='text-uppercase pt-1'>Add To Cart</span></button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </OwlCarousel>
                </div>
            </div>
            <QuickView modalItem={modalItem} setModalItem={setModalItem} addToCart={addToCart} />
        </>
    )
}
export default ProductsByCategory