import SalesGraph from "./views/gsmadmin/common/shared/lineGraph";
import LocalMallIcon from '@mui/icons-material/LocalMall';
import GroupsIcon from '@mui/icons-material/Groups';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';
import PieChart from "./views/gsmadmin/common/shared/pieChart";
import { Image, Modal } from "react-bootstrap";
import { useEffect, useMemo, useRef, useState } from "react";
import StoreIcon from '@mui/icons-material/Store';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useStores from "./views/gsmadmin/hooks";
import Cookies from 'js-cookie';
import moment from "moment";
import { ISalesRegisterRequest } from "./views/gsmadmin/Admin/SalesRegister/model";
import FloatingActionButtonZoom from "./views/gsmecom/common/shared/fab";

interface DashBoardProps {
    setQuickAccess: (bool: boolean) => void;
    quickAccess: boolean;
}
const DashBoards = ({ setQuickAccess, quickAccess }) => {

    const { salesRegisterStore } = useStores();
    const [salesRegisterRequest, setSalesRegisterRequest] = useState<ISalesRegisterRequest>({})


    const navigate = useNavigate()
    const { t } = useTranslation();
    const quickAccessTab: any = t("quickAccessTab")
    const dashBoardKeys: any = t("dashBoardKeys")

    const todayData = useMemo(() => {
        if (salesRegisterStore?.outletItemDetails?.length) {
            let findTodayData = salesRegisterStore?.outletItemDetails?.filter((obj) => moment(obj?.billdate).format('DD-MM-YYYY') == '27-02-2024')
            let findYesterData = salesRegisterStore?.outletItemDetails?.filter((obj) => moment(obj?.billdate).format('DD-MM-YYYY') == '26-02-2024')

            let todaySale = findTodayData?.reduce((a, v) => a = a + v?.billvalue, 0)
            let yesDaySale = findYesterData?.reduce((a, v) => a = a + v?.billvalue, 0)

            let dataMap = { daySale: todaySale, prevSale: yesDaySale }

            return dataMap;
        }
    }, [salesRegisterStore?.outletItemDetails])

    const saleDetails = [
        { name: dashBoardKeys?.todaySalesAmount, amount: todayData?.daySale ?? 0, isYesterday: true, ystrdySale: todayData?.prevSale ?? 0, icon: 'LocalMallIcon' },
        { name: dashBoardKeys?.todayOrders, amount: 1, isYesterday: false, ystrdySale: 0 },
        { name: dashBoardKeys?.totalOrders, amount: 1, isYesterday: false, ystrdySale: 0 },
        { name: dashBoardKeys?.totalCustomers, amount: 1, isYesterday: false, ystrdySale: 0 },
        {
            name: dashBoardKeys?.totalSalesAmount, amount: salesRegisterStore?.outletItemDetails?.length ?
                salesRegisterStore?.outletItemDetails?.reduce((a, v) => a = a + v?.billvalue, 0) : 0, isYesterday: false, ystrdySale: 0
        },
    ]

    const ordersOverview = [
        { title: dashBoardKeys?.todayOverview, totalOrder: 1, orderValue: 58, purchase: 0, otherExpenses: 0 },
        { title: dashBoardKeys?.weeklyOverview, totalOrder: 1, orderValue: 57, purchase: 0, otherExpenses: 0 },
        { title: dashBoardKeys?.monthlyOverview, totalOrder: 1, orderValue: 57, purchase: 0, otherExpenses: 0 },
        { title: dashBoardKeys?.yearlyOverview, totalOrder: 1, orderValue: 58, purchase: 0, otherExpenses: 0 },
    ]

    const quickAccArr = [quickAccessTab?.directInward, quickAccessTab?.salesOrder, quickAccessTab?.directInvoice, "POS", quickAccessTab?.purchaseOrder, quickAccessTab?.purchaseReturn, quickAccessTab?.salesReturn]

    function handleNavigate(nav) {
        if (nav === quickAccessTab?.directInward) return null
        else if (nav === quickAccessTab?.salesOrder) return null
        else if (nav === quickAccessTab?.directInvoice) return null
        else if (nav === 'POS') navigate('/Admin/POS');
        else if (nav === quickAccessTab?.purchaseOrder) navigate('/Admin/PurchaseOrder')
        else if (nav === quickAccessTab?.purchaseReturn) navigate('/Admin/PurchaseReturn')
        else if (nav === quickAccessTab?.salesReturn) return null
    }

    async function initialApiCall() {
        salesRegisterRequest.startDate = '2023-01-01';
        salesRegisterRequest.endDate = moment().format('YYYY-MM-DD');
        salesRegisterRequest.outletId = Number(Cookies.get('outletId'));

        await salesRegisterStore.loadOutletItems(salesRegisterRequest);
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div className="flex flex-col alignItemCenter full-width relative">

            <Modal show={quickAccess} onHide={() => setQuickAccess(false)} className='PriceHistoryModel' style={{ marginTop: '60px' }}>
                <div className="dashboard-modal">
                    <ClearRoundedIcon onClick={() => setQuickAccess(false)} sx={{ position: 'absolute', fontSize: '35px', top: '20px', right: '20px', color: 'rgb(107 114 128)' }} />
                    <div className="main-content gap-4" >
                        <div className="col-span-6 image-content bg-dashboard ">
                            <div className="heading-ganga">
                                <div className="p-3 pb-2">
                                    {t("logoName")}
                                </div>
                            </div>
                            <Image src={require('./image/pos-dashboard.webp')} height={500} />
                        </div>
                        <div className="col-span-6 h-87">
                            <div className="grid-content">
                                {t("quickAccess")}
                                <div className="border-b"></div>
                            </div>
                            <div className="main-content gap-2 mt-4">
                                {quickAccArr?.map((access, index) => {
                                    const geticon = (name: string) => {
                                        if (name === quickAccessTab?.directInward) return <StoreIcon sx={{ fontSize: '40px' }} />;
                                        else if (name === quickAccessTab?.salesOrder) return <AttachMoneyIcon sx={{ fontSize: '40px' }} />;
                                        else if (name === quickAccessTab?.directInvoice) return <ReceiptIcon sx={{ fontSize: '40px' }} />;
                                        else if (name === 'POS') return <PointOfSaleIcon sx={{ fontSize: '40px' }} />;
                                        else if (name === quickAccessTab?.purchaseOrder) return <ReceiptLongIcon sx={{ fontSize: '40px' }} />
                                        else if (name === quickAccessTab?.purchaseReturn) return <PublishedWithChangesIcon sx={{ fontSize: '40px' }} />
                                        else if (name === quickAccessTab?.salesReturn) return <ReplayCircleFilledIcon sx={{ fontSize: '40px' }} />
                                    }
                                    const getBg = (name: string) => {
                                        if (name == quickAccessTab?.directInward) return 'tomato';
                                        else if (name === quickAccessTab?.salesOrder) return 'rgb(220, 53, 69)';
                                        else if (name === quickAccessTab?.directInvoice) return 'steelblue';
                                        else if (name === 'POS') return 'skyblue';
                                        else if (name === quickAccessTab?.purchaseOrder) return 'burlywood';
                                        else if (name === quickAccessTab?.purchaseReturn) return 'rgb(10, 192, 116)';
                                        else if (name === quickAccessTab?.salesReturn) return 'mediumslateblue';
                                    }
                                    return (
                                        <div key={index} onClick={() => handleNavigate(access)} className={`col-span-4 quick-access p-3`} style={{ background: `${getBg(access)}` }}>
                                            <div className="flex flex-col alignItemCenter justifyContentCenter gap-2 full-height">
                                                {geticon(access)}
                                                <div className="w-full flex alignItemCenter justifyContentCenter" style={{ fontSize: '14px' }}>
                                                    {access}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="" style={{ width: '90%' }}>
                <div className="flex gap-4 full-width" style={{ paddingTop: '30px', paddingBottom: '20px' }}>
                    {saleDetails.map((item, index) => {
                        const geticon = (name: string) => {
                            if (name == dashBoardKeys?.todaySalesAmount) return <LocalMallIcon sx={{ fontSize: '40px' }} />;
                            else if (name === dashBoardKeys?.todayOrders) return <HexagonOutlinedIcon sx={{ fontSize: '55px' }} />;
                            else if (name === dashBoardKeys?.totalOrders) return <HexagonOutlinedIcon sx={{ fontSize: '55px' }} />;
                            else if (name === dashBoardKeys?.totalCustomers) return <GroupsIcon sx={{ fontSize: '55px' }} />;
                            else if (name === dashBoardKeys?.totalSalesAmount) return <LocalMallIcon sx={{ fontSize: '40px' }} />
                        }
                        const getColorBg = (name: string) => {
                            if (name == dashBoardKeys?.todaySalesAmount) return { bgColor: 'black', color: 'white' };
                            else if (name === dashBoardKeys?.todayOrders) return { bgColor: 'lightslategray', color: 'white' };
                            else if (name === dashBoardKeys?.totalOrders) return { bgColor: 'royalblue', color: 'white' };
                            else if (name === dashBoardKeys?.totalCustomers) return { bgColor: 'darkorange', color: 'white' };
                            else if (name === dashBoardKeys?.totalSalesAmount) return { bgColor: 'maroon', color: 'white' };
                        }
                        return (
                            <div key={index} data-cue="slideInLeft" data-show="true"
                                data-group="features-3"
                                style={{
                                    animationName: 'slideInLeft',
                                    animationDuration: '700ms', animationTimingFunction:
                                        'ease', animationDelay: `${index * 300}ms`, animationDirection:
                                        'normal', animationFillMode: 'both'
                                }} className="full-width">
                                <div style={{ background: `${getColorBg(item?.name)?.bgColor}`, color: `${getColorBg(item?.name)?.color}`, display: 'flex', alignItems: 'center', gap: '20px', border: '1px solid lightgray', borderRadius: '4px', padding: '20px', height: '160px' }}>
                                    {geticon(item?.name)}
                                    <div className="">
                                        <div style={{ fontSize: '18px' }}>{item?.name}</div>
                                        <div style={{ fontSize: '34px', fontWeight: 'bold' }}>&#8377;{(item?.amount)?.toFixed(2)}</div>
                                        {item?.isYesterday && <div style={{ fontSize: '12px' }}>{dashBoardKeys?.ystrSaleAmount}: &#8377;{(item?.ystrdySale)?.toFixed(2)}</div>}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="" style={{ padding: '20px 0 20px 0', display: 'flex', width: '90%' }}>
                <div className="" style={{ background: 'white', width: '55%', borderRadius: '5px', border: '1px solid lightgray', }}>
                    <SalesGraph graphData={salesRegisterStore?.outletItemDetails} />
                </div>
                <div className="" style={{ width: '5%' }}></div>
                <div className="" style={{ background: 'white', width: '40%', borderRadius: '5px', border: '1px solid lightgray' }}>
                    <PieChart />
                </div>
            </div>

            <div className="" style={{ width: '90%' }}>
                <div className="flex gap-4 full-width" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    {ordersOverview?.map((item, index) => {
                        const getColorBg = (name: string) => {
                            if (name == dashBoardKeys?.todayOverview) return { bgColor: 'darkred', color: 'white' };
                            else if (name === dashBoardKeys?.weeklyOverview) return { bgColor: 'purple', color: 'white' };
                            else if (name === dashBoardKeys?.monthlyOverview) return { bgColor: 'green', color: 'white' };
                            else if (name === dashBoardKeys?.yearlyOverview) return { bgColor: 'gold', color: 'inherit' };
                        }
                        return (
                            <div key={index} data-cue="slideInLeft" data-show="true"
                                data-group="features-3"
                                style={{
                                    animationName: 'slideInLeft',
                                    animationDuration: '700ms', animationTimingFunction:
                                        'ease', animationDelay: `${index * 300}ms`, animationDirection:
                                        'normal', animationFillMode: 'both'
                                }} className="col-span-3 full-width">
                                <div className="border  shadow-md" style={{ borderRadius: '0.25rem' }}>
                                    <div className="p-3 text-lg"
                                        style={{ color: `${getColorBg(item?.title)?.color}`, fontSize: '1.125rem', borderTopRightRadius: '0.25rem', borderTopLeftRadius: '0.25rem', background: `${getColorBg(item?.title)?.bgColor}` }}>
                                        {item?.title}
                                    </div>
                                    <div className="px-3 py-2">
                                        <div className="flex justifyContentBetween alignItemCenter py-1" style={{ fontSize: '1rem', color: 'dimgray' }}>
                                            <div className="">{dashBoardKeys?.totalOrder}</div><div>${item?.totalOrder}</div>
                                        </div>
                                        <div className="flex justifyContentBetween alignItemCenter py-1" style={{ fontSize: '1rem', color: 'dimgray' }}>
                                            <div className="">{dashBoardKeys?.ordervalue}</div><div>${item?.orderValue}</div>
                                        </div>
                                        <div className="flex justifyContentBetween alignItemCenter py-1" style={{ fontSize: '1rem', color: 'dimgray' }}>
                                            <div className="">{dashBoardKeys?.purchase}</div><div>${item?.purchase}</div>
                                        </div>
                                        <div className="flex justifyContentBetween alignItemCenter py-1" style={{ fontSize: '1rem', color: 'dimgray' }}>
                                            <div className="">{dashBoardKeys?.otherExpenses}</div><div>${item?.otherExpenses}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="gap-5" style={{ display: 'flex', width: '90%', padding: '20px 0 20px 0', }}>
                <div style={{ background: 'white', width: '100%', borderRadius: '5px', border: '1px solid lightgray', }}>
                    <SalesGraph graphData={salesRegisterStore?.outletItemDetails} />
                </div>
                <div style={{ background: 'white', width: '100%', borderRadius: '5px', border: '1px solid lightgray', }}>
                    <SalesGraph graphData={salesRegisterStore?.outletItemDetails} />
                </div>
            </div>

            {/* <FloatingActionButtonZoom /> */}

        </div>
    )
}

export default DashBoards;