import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../EcomAPI/index';
import { ICartProduct } from '../../Products/model';


class EcomHomeStore {

    @observable ecomItemImgs = new Map();
    @observable ecomItemDetails = new Array<any>()

    isLoading = false;

    getLoadItemImages = async () => {
        try {
            const ecomItemImgs = await apiAgent.ecomHome.getLoadItemImages();
            runInAction(() => {
                this.ecomItemImgs = ecomItemImgs;
                this.isLoading = true;
            });
            return ecomItemImgs;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOnlineItems = async () => {
        try {
            const ecomItemDetails = await apiAgent.ecomHome.getOnlineItems();
            runInAction(() => {
                this.ecomItemDetails = ecomItemDetails;
            });
            return JSON.parse(ecomItemDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveProductCart = async (productCart: ICartProduct) => {
        try {
            const status = await apiAgent.ecomHome.saveProductCart(productCart);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getCartDetails = async (memberId) => {
        try {
            const cartDetails = await apiAgent.ecomHome.getCartDetails(memberId);
            return JSON.parse(cartDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default EcomHomeStore;