import React, { useState, useEffect, } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import moment from 'moment';
import { IAssignBatchOutlet, IAssignBatchOutletDetails, ILoadOptions } from './model';
import assignBatchOutletValidation from './validation';
import { removeDuplicates } from '../../common/shared/utils';

const AssignBatchOutlet = observer((): JSX.Element => {
  const { commonStore, assignBatchOutletStore,inwardOultetStore } = useStores();
  const [assignBatchOutlet, setAssignBatchOutlet] = useState<IAssignBatchOutlet>({});
  const [assignBatchOutletDetails, setAssignBatchOutletDetails] = useState<IAssignBatchOutletDetails>({});
  const [assignBatchOutletDetail, setAssignBatchOutletDetail] = useState<IAssignBatchOutletDetails[]>([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [supplierName, setSupplierName] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [inwardId, setInwardId] = useState(null);
  const [inwarddate, setInwarddate] = useState('');
  const [itemName, setItemName] = useState('');
  const [batchqty, setBatchqty] = useState(0);
  const [mfg_Date, setMfg_Date] = useState('');
  const [exp_Date, setExp_Date] = useState('');

  const [isSpinner, setSpinner] = useState(false);
  const [options, setOptions] = useState<ILoadOptions>({});


  const { loadSupplierMastes, loadSuppliers, loadInwardItems,
    loadIteminward, isloadItemInwardLoading, loadItemInwards,
    isloadEmployeeLoading, loadEmployee, loadEmployees, loadItems } = commonStore;

  const handleAssignBatchNo = (event: any) => {
     
    const { name, value } = event.target;
    if(name==='mfg_Date' && assignBatchOutletDetails.expiryDays!==0){
      let expiryDate = new Date(value);
      const exp = expiryDate.setDate(expiryDate.getDate()+assignBatchOutletDetails.expiryDays!)
      assignBatchOutletDetails.exp_Date = moment(exp).format('YYYY-MM-DD');
       

    };
    setAssignBatchOutletDetails({ ...assignBatchOutletDetails, [name]: value });
  }
  function handleChangeInput(event: any) {
     
    const { name, value } = event.target;
    
    setAssignBatchOutlet({ ...assignBatchOutlet, [name]: value })
  }

  async function addAssignBatchNo(e) {
  
    let assignBatchDetail: IAssignBatchOutletDetails = {};
    e.preventDefault();
    let error: any = {};
    error = assignBatchOutletValidation(assignBatchOutlet, assignBatchOutletDetails);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      
      const runningN0 = assignBatchOutletDetail.length;
      const CheckQty = assignBatchOutletDetail.find(m => m.itemId === assignBatchOutletDetails.itemId);
      const addQty = CheckQty?.batchqty! + Number(assignBatchOutletDetails.batchqty);
      if (addQty > Number(assignBatchOutletDetails.existingQty!)) {
        error.batchError = 'Item Quantity is ' + assignBatchOutletDetails.existingQty + ' but adding ' + addQty;
        setErrors(error);
        return
      }
      if (Number(assignBatchOutletDetails.batchqty) > Number(assignBatchOutletDetails.existingQty)) {
        error.batchError = 'Item Quantity is ' + assignBatchOutletDetails.existingQty + ' but adding ' + Number(assignBatchOutletDetails.batchqty);
        setErrors(error);
        setItemName('');
        return
      }


        if (Number(assignBatchOutletDetails.batchqty) === Number(assignBatchOutletDetails.existingQty)) {
          assignBatchOutletStore.directInwardItems.filter(m=>m.itemId!==assignBatchOutletDetails.itemId);
        }
        assignBatchDetail.batchNo = assignBatchOutlet.supplierId!.toString()
          + '/' + assignBatchOutletDetails.inwardmasterid?.toString()
          + '/' + moment(assignBatchOutletDetails.inwarddate).format('YYYYMMDD') +
          '/' + assignBatchOutletDetails.itemId?.toString() + '/' +
          assignBatchOutletDetails.mfg_Date?.replace(/[: -]/g, '') + '/' +
          assignBatchOutletDetails.exp_Date?.replace(/[: -]/g, '')
          + '/' + assignBatchOutletDetails.batchqty?.toString() + '-00' + '/' + runningN0 + '/' + 1;
        assignBatchDetail.inwarddate = moment(assignBatchOutletDetails.inwarddate).format('YYYY-MM-DD');

        assignBatchDetail.inwardQty = Number(assignBatchOutletDetails.inwardQty) - Number(assignBatchOutletDetails.batchqty);
        assignBatchDetail.inwardmasterid = Number(assignBatchOutletDetails.inwardmasterid);
        assignBatchDetail.qty = Number(assignBatchOutletDetails.batchqty);
        assignBatchDetail.batchqty = Number(assignBatchOutletDetails.batchqty);
        assignBatchDetail.itemId = assignBatchOutletDetails.itemId;
        assignBatchDetail.itemName = assignBatchOutletDetails.itemName;
        assignBatchDetail.mfg_Date=assignBatchOutletDetails.mfg_Date
        assignBatchDetail.exp_Date=assignBatchOutletDetails.exp_Date;
        assignBatchOutletDetails.inwardQty = Number(assignBatchOutletDetails.inwardQty) - Number(assignBatchOutletDetails.batchqty);

        setSubmitbtn(true);
        setAssignBatchOutletDetail([...assignBatchOutletDetail, assignBatchDetail]);

     // }
      setMfg_Date('');
      setExp_Date('');
      setBatchqty(0);
      setItemName('');
      setAssignBatchOutletDetails({ ...assignBatchOutletDetails, batchqty: 0, mfg_Date: '', exp_Date: '', itemName: '', itemId: 0 ,inwardQty:0});
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setEmployeeName(val.employeeName)
      setAssignBatchOutlet({ ...assignBatchOutlet, [name]: val.employeeId });
    }
    else {

    }
  }
  const handleSupplierValue = async (name: any, event: object, val: any) => {
    
    if (val != null) {
      
      setSpinner(true);
      setSupplierName(val.supplierName);
      await assignBatchOutletStore.loadDirectInwards(val.supplierId);
      setAssignBatchOutlet({ ...assignBatchOutlet, [name]: val.supplierId });
      setSpinner(false);

    }
    else {
      assignBatchOutlet.supplierId = 0;
    }
  }

  const handleItemInwardValue = async (name: any, event: object, val: any) => {
    if (val != null) {
      setSpinner(true);
      options.flag = 'Items';
      options.supplierId = assignBatchOutlet.supplierId;
      options.inwardNo = val.inwardId;
      await assignBatchOutletStore.loadDirectInwardItems(options);
      setInwardId(val.inwardId)
      setInwarddate(val.inwardDate)
      setItemName(val.itemName)
      setBatchqty(val.batchqty)
      setMfg_Date(val.mfg_Date)
      setExp_Date(val.exp_Date)
      setAssignBatchOutletDetails({
        ...assignBatchOutletDetails, [name]: val.inwardId, inwarddate: val.inwardDate
      });
    }
    else {
      assignBatchOutletDetails.inwardmasterid = 0;
    }
    setSpinner(false);
  }
  const handleItemValue = (name: any, event: object, val: any) => {
     
    let qty :number=0;
    if (val != null) {
      setItemName(val.itemName)
      
     const checkQty = assignBatchOutletDetail.filter(m=>m.itemId===val.itemId);
     if(checkQty.length>0){
      assignBatchOutletDetail.forEach(element => {
        if(element.itemId===val.itemId){
          qty += element.qty!;
        }
        
      })
      qty=val.qty-qty;
     }
     else
     {
      
      qty=val.qty;
     }
     
    }
    
     setAssignBatchOutletDetails({ ...assignBatchOutletDetails, [name]: val.itemId, itemName: val.itemName, inwardQty: qty, qty: qty, existingQty: val.qty,expiryDays:val.expiryDays});

  }
  const handleFailureModalClose = () => setFailureModal(false);
  async function saveAssignBatchNo(e) {
     
    e.preventDefault();
    setLoading(true);
    assignBatchOutlet.assignOutletDetails = assignBatchOutletDetail;
    assignBatchOutlet.assignNo = Number(assignBatchOutlet.assignNo)
    assignBatchOutlet.supplierId = Number(assignBatchOutlet.supplierId);
    const entryNos = await inwardOultetStore.getEntryNo('AssignBatchOutlet');
    assignBatchOutlet.assignNo = entryNos.entryNo;
    
    const status = await assignBatchOutletStore.saveAssignBatchOutlet(assignBatchOutlet);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleCancel = () => {
    navigate('/Admin/AssignBatchOutlet/AssignBatchOutletDetail');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignBatchOutlet/AssignBatchOutletDetail');
  }
  async function fetchAssignDefaultLoad() {
    await loadSupplierMastes('InwardSupplier');
    await loadEmployee();
    await inwardOultetStore.getEntryNo('AssignBatchOutlet');
    setLoading(false)
  }

  useEffect(() => {
    fetchAssignDefaultLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null}
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Assign Batch-Direct</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      {
                        isSpinner ?
                          <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                          </div> : null
                      }
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Entry No <span>*</span></label>
                          <input  type="text"
                            name='assignNo' disabled
                            value={assignBatchOutlet.assignNo = inwardOultetStore.entryNo.entryNo}
                            onChange={handleChangeInput}
                            className='' style={{ width: "100%" }} placeholder='Entry No..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input  type="date"
                            name='assigndate' value={assignBatchOutlet.assigndate}
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.assigndate && <p style={{ color: 'red' }}>{errors.assigndate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Supplier <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadSuppliers}
                            value={supplierName}
                            onChange={(event, val) => handleSupplierValue('supplierId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Supplier..'
                                name='supplier'
                              />}
                          />
                          {errors.supplierId && <p style={{ color: 'red' }}>{errors.supplierId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-12'>
                        <div className='inputBox'>
                          <label>Created by </label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={removeDuplicates(loadEmployees, 'employeeName')}
                            value={employeeName}
                            // getOptionLabel={(option: any) => option.employeeName}
                            onChange={(event, val) => handleEmployeeValue('createdBy', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Created by..'
                                name='employeeName'
                              />}
                          />
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Inward No <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={assignBatchOutletStore.directInwards}
                            value={inwardId}
                            onChange={(event, val) => handleItemInwardValue('inwardmasterid', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '25ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Inward No..'
                                name='inwardId'
                              />}
                          />
                          {errors.inwardId && <p style={{ color: 'red' }}>{errors.inwardId}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Inward Date <span>*</span></label>
                          <input  value={moment(inwarddate).format('DD-MM-YYYY')}
                            defaultValue={moment(assignBatchOutletDetails.inwarddate).format('DD-MM-YYYY')} 
                            disabled
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="YYYY MMMM DD"></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Item <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={assignBatchOutletStore.directInwardItems}
                            value={itemName}
                            onChange={(event, val) => handleItemValue('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '40ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Item..'
                                name='itemId'
                              />}
                          />
                          {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-2'>
                        <div className='inputBox'>
                          <label>Qty </label>
                          <input 
                            value={assignBatchOutletDetails.inwardQty} name='inwardQty'
                            onChange={handleAssignBatchNo} disabled
                            className='fullInput' style={{ width: "100%" }} placeholder='Qty..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-2'>
                        <div className='inputBox'>
                          <label>Ass. Qty <span>*</span></label>
                          <input  value={assignBatchOutletDetails.batchqty}
                            name='batchqty'
                            onChange={handleAssignBatchNo}
                            className='fullInput' style={{ width: "100%" }} placeholder='Qty..'></input>
                          {errors.batchqty && <p style={{ color: 'red' }}>{errors.batchqty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Mfg Date <span>*</span></label>
                          <input  type="date"
                            name='mfg_Date' value={assignBatchOutletDetails.mfg_Date}

                            onChange={handleAssignBatchNo}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.mfg_Date && <p style={{ color: 'red' }}>{errors.mfg_Date}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                     {
                      assignBatchOutletDetails.expiryDays!==0? <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Expiry Date <span>*</span></label>
                        <input  type="date"
                          name='exp_Date' value={assignBatchOutletDetails.exp_Date} disabled
                          onChange={handleAssignBatchNo}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.exp_Date && <p style={{ color: 'red' }}>{errors.exp_Date}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>: <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Expiry Date <span>*</span></label>
                          <input  type="date"
                            name='exp_Date' value={assignBatchOutletDetails.exp_Date}
                            onChange={handleAssignBatchNo}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.exp_Date && <p style={{ color: 'red' }}>{errors.exp_Date}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                     }
                      <div className='col-sm-2'>
                        <button className='dfBtn pd-0-12 full-width' type='submit' onClick={addAssignBatchNo}>ADD</button>
                      </div>
                    </div>
                    {
                      errors.batchError && <p style={{ color: 'red' }}>{errors.batchError}</p>
                    }
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Inward No</th>
                          <th scope="col">Inward Date</th>
                          <th scope="col">Item</th>
                          <th scope="col">Ass.Qty</th>
                          <th scope="col">Mfg Date</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col">Batch No</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody style={{ verticalAlign: 'middle' }}>
                        {
                          assignBatchOutletDetail.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.inwardmasterid}</td>
                                <td>{val.inwarddate}</td>
                                <td>{val.itemName}</td>
                                <td>{val.qty}</td>
                                <td>{moment(val.mfg_Date).format('DD-MM-YYYY')}</td>
                                <td>{moment(val.exp_Date).format('DD-MM-YYYY')}</td>
                                <td><QRCode value={val.batchNo} size={100} /></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveAssignBatchNo}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
         
    </>

  );

});

export default AssignBatchOutlet;
