import { makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IInvoice } from '../model';

class InvoiceStore {
    @observable entryNo = new Map();
    @observable memberDetails = new Map();
    @observable shippingAddress = new Map();
    @observable getProductDetails = new Map();
    @observable invoices = new Map();
    @observable invoicesProducts = new Map();
    constructor() {
        makeObservable(this)
    }
    getMembers = async () => {
        const members = await apiAgent.invoice.getMembers();
        runInAction(() => {
            this.memberDetails = JSON.parse(members);
        });
        return JSON.parse(members);
    }
    getShippingAddress = async (memberId) => {
        const address = await apiAgent.invoice.getShippingAddress(memberId);
        runInAction(() => {
            this.shippingAddress = address;
        });
        return this.shippingAddress;
    }
    getProducts = async (invoiceProductRequest) => {
        const products = await apiAgent.invoice.getProducts(invoiceProductRequest);
        runInAction(() => {
            this.getProductDetails = products;
        });
        return this.getProductDetails;
    }
    saveInvoiceEntry = async (invoiceDet) => {
        try {
            const status = await apiAgent.invoice.saveInvoiceEntry(invoiceDet);
            if (status !== undefined) {
                return status;
            }
        } catch (error) {
            console.log(error);
        }
    }
    loadSalesOrederDetails = async () => {
        const products = await apiAgent.invoice.loadSalesOrederDetails();
        return JSON.parse(products);
    }
    loadSalesOrderLedger = async () => {
        const products = await apiAgent.invoice.loadSalesOrderLedger();
        return JSON.parse(products);
    }
    salesOrderSave = async (salesOrder) => {
        try {
            const status = await apiAgent.invoice.salesOrderSave(salesOrder);
            if (status !== undefined) {
                return status;
            }
        } catch (error) {
            console.log(error);
        }
    }
    saveDirectInvoicesave = async (invoiceMaster: IInvoice) => {
        try {
            const status = await apiAgent.invoice.saveDirectInvoicesave(invoiceMaster);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getInvoices = async () => {
        const invoices = await apiAgent.invoice.getInvoices();
        runInAction(() => {
            this.invoices = invoices;
        });
        return this.invoices;
    }
    getInvoiceProduts = async (invoiceId) => {
        const products = await apiAgent.invoice.getInvoiceProduts(invoiceId);
        runInAction(() => {
            this.invoicesProducts = products;
        });
        return this.invoicesProducts;
    }
    getInvoceSearch = async (outletId) => {
        const invoices = await apiAgent.invoice.getInvoceSearch(outletId);
        runInAction(() => {
            this.invoices = invoices;
        });
        return this.invoices;
    }
    getInvoiceBillPrint = async (posid?: number) => {
        try {
            const invoiceDetails = await apiAgent.invoice.getInvoiceBillPrint(posid);
            return invoiceDetails
        } catch (error) {
            console.log(error)
        }
    }
}

export default InvoiceStore;