import { observable } from "mobx";
export interface IAssignBatchPacking{
    packingAssignBatchid?:number;
    packingAssignBatchdate?:string;
    packingTeamid?:number; 
    packingTeamName?:string; 
    packingInwardid?:number;
    packingInwardDate?:string;    
    packingInwardItemid?:number;
    packingQty?:number;       
    mfg_Date?:string;
    expiry_date?:string;
    batchNo?:string;
    existingQty?:string;
    qty?:number;
    getqty?:number;  
    itemname?:string;  
    assignBatchPackingDetails?:AssignBatchPackingDetails[];
    createdBy?:number;
    teamName?:string;
    warehouseId?:number;
    wareHouse?:string;
    expiryDays?:number;
}
export interface AssignBatchPackingDetails{
    packingAssignBatchid?:number;
    assignBatchpackingInwardid?:number;
    assignBatchpackingPackingInwardDate?:string;    
    assignBatchpackingInwardItemid?:number;
    assignBatchpackingQty?:number;       
    assignBatchpackingMfg_Date?:string;
    assignBatchpackingExpiry_date?:string;
    assignBatchpackingBatchNo?:string;
    assignBatchpackingExistingQty?:string;    
    assignBatchpackingGetqty?:number;  
    assignBatchpackingItemname?:string;
}

export interface IAssignBatchPackingSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const assignBatchPackingDetails=observable<IAssignBatchPacking>({
    packingAssignBatchid:0,
    packingAssignBatchdate:'',
    packingTeamid:0,  
    packingTeamName:'', 
    packingInwardItemid:0,
    packingQty:0,       
    mfg_Date:'',
    expiry_date:'',
    batchNo:'',
    existingQty:'',
    qty:0,
    getqty:0,  
    itemname:'',
    teamName:'',
    warehouseId:0,
    wareHouse:'',
    assignBatchPackingDetails:[],
    
});

export default assignBatchPackingDetails;