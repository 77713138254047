import { observable } from "mobx";
export interface ISellingPriceUpdate {
    itemId?: number;
    itemName?: string;
    Batchno?: string;
    mrp?: string;
    newMrp?: string;
    effectiveDateTime?: string;
    b2B?: string;
    b2C?: string;
    b2BNewPrice?: string;
    b2CNewPrice?: string;
    b2BStP?: string;
    b2CStP?: string;
    b2EffectiveDateTime?: string;

}

export interface IMRPPrice {
    itemId?: number;
    itemMrpId?: number;
    itemName?: string;
    Batchno?: string;
    mrp?: number;
    newMRP?: number;
    effectiveDate?: string;
    oldEffectiveDate?: string
}
export interface ISellingPrice {
    itemId?: number;
    itemMrpId?: number;
    itemName?: string;
    Batchno?: string;
    b2BPrice?: number;
    b2BNewPrice?: number;
    b2CPrice?: number;
    b2CnewPrice?: number;
    b2BStPer?: number;
    b2CStPer?: number;
    effectiveDate?: string;
    oldEffectiveDate?: string;
    mrpPrice?: number;
}
export interface ISellingPriceUpdateSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

const sellingPriceUpdateDetails = observable<ISellingPriceUpdate>({
    itemId: 0,
    itemName: '',
    Batchno: '',
    mrp: '',
    newMrp: '',
    effectiveDateTime: '',
    b2B: '',
    b2C: '',
    b2BNewPrice: '',
    b2CNewPrice: '',
    b2BStP: '',
    b2CStP: '',
    b2EffectiveDateTime: '',


});

export default sellingPriceUpdateDetails;