export class ILoanAndAdvance {
    loanid?: number = 0
    advdate?: string = new Date().toISOString().substr(0, 10)
    empid?: number = 0
    loanType?: string = ""
    loanAmount?: string = ""
    reason?: string = ""
    amountDeductmode?: string = ""
    noofdue?: number = 0
    appstatus?: string = "No"
}