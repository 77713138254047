export class IIOfferLetter {
    offerId?: number = 0
    offDate: string = new Date()?.toISOString()?.substr(0, 10)
    offerJobCode: string = ""
    offerJobTitle: string = ""
    offerDesc: string = ""
    offerIntrCode: string = ""
    offerCandidate: string = ""
    offerJobLoc: string = ""
    offerRepDate: string = new Date()?.toISOString()?.substr(0, 10)
    offerBaseSal?: number = 0
    offerHRA?: number = 0
    offerConveyance?: number = 0
    offerDescr: string = ""
    offerMode: string = ""
    offerCatId?: number = 0
    offerDesignId?: number = 0
    offerEmpId?: number = 0

    //dummy
    designation?: string = ""
}