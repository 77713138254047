import React, { useEffect, useRef, useState } from "react"
import useStores from "../../hooks"
import { useNavigate } from "react-router-dom"
import { currentPageDatas } from "../../common/shared/utils"
import { Modal, Spinner } from "react-bootstrap"
import SelectSearchCriteria from "../../common/shared/selectSearchCriteria"
import { Pagination, Stack, TextField } from "@mui/material"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdd, faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons"

const CustomerFeedBackDetails = () => {

    const { memberStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate()

    const [feedBackMaster, setFeedBackMaster] = useState<any[]>([])
    const [feedBackDetail, setFeedBackDetail] = useState<any[]>([])

    // console.log(feedBackDetail, 'feedBackDetail>>')
    const [loader, setLoader] = useState(true)
    const [feedBackModal, setFeedBackModal] = useState({ bool: false, feedid: 0 });

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    async function getFeedBackDetails(feedid) {
        const feedBackMaster = await memberStore?.getCustomerFeedBack(2)
        let filtedArry = feedBackMaster?.filter((each) => each?.feedid === feedid)
        setFeedBackDetail([...feedBackMaster])
        setFeedBackModal({ bool: true, feedid: feedid })
    }
    async function initialApiCalls() {
        // 
        const userRights = await userCreationStore?.getUserRight('Customers_feedback')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const feedBackMaster = await memberStore?.getCustomerFeedBack(1)
        console.log(feedBackMaster, 'feedBackMaster>>')
        setFeedBackMaster([...feedBackMaster])
        setLoader(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false
        }
        return () => { }
    }, [])


    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (feedBackMaster && feedBackMaster?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(feedBackMaster)
        }
    }, [feedBackMaster])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(feedBackMaster?.slice()?.sort((a, b) => b.feedid - a.feedid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loader ? <div className='SpinnerBox'>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div> : <div className='container-fluid'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={feedBackMaster}
                    searchTitles={[{ key: "vchr_no", value: "Voucher No" }, { key: "ledger_accout_name", value: "Ledger Account Name" }]}
                    emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='vertical-space-20'></div>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{feedBackMaster?.length} Records</button>
                    </div>
                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope='col'>Feed Back No  </th>
                                <th scope='col'> Date   </th>
                                <th scope='col'>Name  </th>
                                <th scope='col'>Email  </th>
                                <th scope='col'>City  </th>
                                <th scope='col'></th>
                                <th scope='col'>Add</th>
                                <th scope='col'>Edit</th>
                                <th scope='col'>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                (isSearch ? filteredData : currentPageData)?.map((oobj, index) => {
                                    return (
                                        <tr>
                                            <td>{oobj?.feedid}</td>
                                            <td>{moment(oobj?.feeddate).format("DD-MMM-YYYY")}</td>
                                            <td>{`${oobj?.Name} ${" "} ${oobj?.Lastname}`}</td>
                                            <td>{oobj?.Email}</td>
                                            <td>{oobj?.City}</td>
                                            <td><button disabled={!userRights?.view} onClick={() => getFeedBackDetails(index)} className='tablemodeltext'> Detail</button></td>
                                            <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/CustomerFeedBack')}><FontAwesomeIcon icon={faAdd} /></button></td>
                                            <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/CustomerFeedBack/' + oobj?.feedid)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                        </tr>
                                    )
                                })}

                        </tbody>
                    </table>
                </div>

                <Modal show={feedBackModal?.bool} onHide={() => setFeedBackModal({ bool: false, feedid: 0 })} className='PriceHistoryModel flexImp'>
                    <Modal.Header closeButton>
                        <Modal.Title>Feed back Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex justify-start items-center gap-4" style={{ display: "flex", alignItems: "center" }}>
                            <div className="pt-2" style={{ fontSize: "large" }}>
                                May We Contact You Via E-mail About Your Comments?
                            </div>
                            <div className="pt-1" style={{ fontSize: "large" }}>
                                {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Email_Commands ? 'Yes' : "No"}
                            </div>
                        </div>
                        <div className="flex justify-start items-center gap-4" style={{ display: "flex", alignItems: "center" }}>
                            <div className="pt-2" style={{ fontSize: "large" }}>
                                May We Your Comments as a Testimonial?
                            </div>
                            <div className="pt-1" style={{ fontSize: "large" }}>
                                {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Testimonial ? 'Yes' : "No"}
                            </div>
                        </div>
                        <div>
                            <div
                                className="text-[#FF6868]"
                                style={{ color: "#FF6868", fontSize: "medium", marginTop: '4px' }}
                            >
                                How did you hear about us?
                            </div>
                            <div className="py-2">
                                <TextField name="hear_about_us" disabled id="outlined-basic"
                                    sx={{ width: "50%" }} variant="outlined"
                                    value={feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.hear_about_us} />
                            </div>
                        </div>
                        <div className="">
                            <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>
                                How satisfied are you with the customer service you received at
                                this store?
                            </div>
                            <div
                                style={{
                                    display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F0F0F0",
                                }}
                            >
                                <div>Overall Rating</div>
                                <div className="row" style={{ marginRight: "50px" }}>
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.overallRateing}
                                </div>
                            </div>
                            <div>
                                <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>Please let us know the reason for the above rating</div>
                                <TextField name="reason_For_Rating" disabled value={feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Reason_For_Rating}
                                    id="outlined-multiline-flexible" sx={{ width: "75%" }} variant="outlined" multiline maxRows={4} />
                            </div>
                            <div>
                                <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>How satisfied are you with the following services?(Please check 'Not applicable' if you did not use the service.)</div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "#F0F0F0",
                                    width: "100%"
                                }}
                            >
                                <div style={{ width: "45%" }}>Did the store staff's attitude , attire and product knowledge leave a favorable impression?</div>
                                <div className="row full-width">
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.favorable_impression}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                }}
                            >
                                <div style={{ width: "45%" }}>was service at the cash register speedy and polite?</div>
                                <div className="row full-width">
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Speedy_polite}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#F0F0F0",
                                    width: "100%"
                                }}
                            >
                                <div style={{ width: "45%" }}>Was service on the sales floor informative and counteous?</div>
                                <div className="row full-width">
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Informativeandcontieous}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%"
                                }}
                            >
                                <div style={{ width: "45%" }}>
                                    Was service in the filling rooms counteous and appropriate?</div>
                                <div className="row full-width">
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Appropriate}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#F0F0F0",
                                    width: "100%"
                                }}
                            >
                                <div style={{ width: "45%" }}>
                                    Were you satisfied with your alteration?</div>
                                <div className="row full-width">
                                    {feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.Allterations}
                                </div>
                            </div>
                            <div style={{ paddingTop: "16px" }}>
                                <div>Please use the space provided for any comments you have regarding your purchased items.</div>
                                <div style={{ paddingBottom: "8px" }}>{'(If possible , please provide the item name and 13-digit item code printed on your receipt)'}</div>
                                <TextField name="purchased_items" disabled value={feedBackDetail?.find((each, ind) => ind === feedBackModal?.feedid)?.purchased_items}
                                    id="outlined-multiline-flexible" sx={{ width: "75%" }} variant="outlined" multiline maxRows={4} />
                            </div>
                        </div>
                        {/* <div className='tableBox'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Over All Ratings  </th>
                                        <th scope='col'>Allterations </th>
                                        <th scope='col'> Appropriate   </th>
                                        <th scope='col'>Informativeandcontieous  </th>
                                        <th scope='col'>Reason_For_Rating  </th>
                                        <th scope='col'>purchased_items  </th>
                                        <th scope='col'>Date  </th>
                                        <th scope='col'>favorable_impression  </th>
                                        <th scope='col'>Speedy_polite  </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feedBackDetail?.map((payment) => {
                                        return (
                                            <tr>
                                                <td>{payment?.Allterations}</td>
                                                <td>{payment?.Appropriate}</td>
                                                <td>{payment?.Informativeandcontieous}</td>
                                                <td>{payment?.Reason_For_Rating}</td>
                                                <td>{payment?.overallRateing}</td>
                                                <td>{payment?.purchased_items}</td>
                                                <td>{moment(payment?.feeddate).format("DD-MMM-YYYY")}</td>
                                                <td>{payment?.favorable_impression}</td>
                                                <td>{payment?.Speedy_polite}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                    </Modal.Body>
                </Modal>
            </div>}
        </>
    )
}

export default CustomerFeedBackDetails;