import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";

const CategoryMaster = () => {

  const navigate = useNavigate();

  const { subCategoryStore } = useStores();

  const { saveCategory, loadCatergoryMaster } = subCategoryStore

  const [errors, setErrors] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [category, setCategory] = useState<any>({});
  const [categoryList, setCategoryList] = useState<any[]>([]);

  const handleCancel = () => navigate('/Admin/Category/List');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/Category/List');
    setSuccessModal(false)
  };

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSave = async (e) => {
    // Save category code and description
    if (!category?.mC_Name) {
      setErrors({ ...errors, mC_Name: 'Enter Category Name' });
    } else if (!categoryList?.some((obj) => obj?.MC_Name === category?.mC_Name)) {
      let tempCat = {
        MC_ID: categoryList?.length + 1,
        mC_Name: category?.mC_Name,
      }
      const status = await saveCategory(tempCat);
      if (status === "Success") {
        setSuccessModal(true);
        let tempCat = {
          MC_ID: categoryList?.length + 1,
          MC_Name: category?.mC_Name,
        }
        categoryList?.push(tempCat);
        setCategoryList([...categoryList])
        setCategory({ MC_ID: 0, mC_Name: '' });
        setErrors({});
      } else {
        setFailureModal(true);
      }
    } else {
      setErrors({ ...errors, mC_Name: 'Category already exists' });
    }
  }

  const fetchCategories = async () => {
    // Fetch category list
    setLoading(false)
    const categorylist = await loadCatergoryMaster();
    setCategoryList([...categorylist]);
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      // Fetch category details
      fetchCategories();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  return (
    <div>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Category Master</h3>
              </div>
              <div className='inputBoxLists'>
                <div className='vertical-space-10'></div>
                <div className='inputBox'>
                  <label style={{ fontSize: '16px' }}>Category Name <span>*</span></label>
                  <input type="text"
                    name='mC_Name'
                    value={category?.mC_Name} onChange={handleInputChange}
                    className='brandinput' placeholder='Enter Category Name..'></input>
                  {errors.mC_Name && <p style={{ color: 'red' }}>{errors.mC_Name}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' onClick={handleSave}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </div>
  )
}

export default CategoryMaster;