
function DamageEntryWarehouseValidation(damageEntryWarehouse){
  let errors :any={};
  
    
    if(!damageEntryWarehouse.damageEntryDate){
      errors.damageEntryDate = "Enter Date";
    }
    if(!damageEntryWarehouse.warehouseId){
      errors.warehouseId = "Select Warehouse Name";
    }
    if(!damageEntryWarehouse.itemId){
      errors.itemId = "Select Item Name";
    }
    if(!damageEntryWarehouse.batchNo){
      errors.batchNo = "Select BatchNo ";
    }
    if(!damageEntryWarehouse.damageQty){
      errors.damageQty = "Damage Qty does not exist Stock Qty";
    }
    if(!damageEntryWarehouse.damageDescription){
      errors.damageDescription = "Enter Damage Description";
    }
  return errors;
};
  
  export default DamageEntryWarehouseValidation;