import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class SalesSummaryItemWiseStore {

    constructor() {
        makeAutoObservable(this);
    }

    getsalesSummaryItemWise = async (salesSummaryBody) => {
        try {
            const data = await apiAgent.salesSummaryItemWise.getSaleSummaryItemWise(salesSummaryBody);
            return data;
        }
        catch (error) {
            console.log(error);
        }
    }
    
}
export default SalesSummaryItemWiseStore;