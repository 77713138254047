
function warehouseManagerValidation(WareHouseManagerDetails){
 
  
  let errors :any={};

  
  if(!WareHouseManagerDetails.Date){
    errors.Date = "Select Date";
  }
  if(!WareHouseManagerDetails.warehouseName){
    errors.wareHouseId = "Select Warehouse";
  }
  if(!WareHouseManagerDetails.employeeName){
    errors.employeeId = "Select Manager";
  }
 
  return errors;
};
  
  export default warehouseManagerValidation;