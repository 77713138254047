import { observable } from "mobx";
export interface IItemConversion{
    itemConversionId?:number;
    itemConversionDate?:string;
    itemId?:number;    
    itemUOM?:string;
    itemConversionDetails?:IItemConversionOfDetails[];
    itemName?:string;
    unitOfMeasure?:string;

}
export interface IItemConversionOfDetails{
    itemConversionId?:number;
    conversionItemId?:number;
    conversionUOM?:string;
    conversionQty?:number;
    conversionItemUOM?:string;
    unitName?:string;
    itemName?:string;
    itemconversiondtlid?:number;
}
export interface IItemConversionSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const itemConversionDetails=observable<IItemConversion>({
    itemConversionId:0,
    itemConversionDate:'',
    itemName:'',
    itemId:0,
    itemUOM:'',  
    unitOfMeasure:'',  
    itemConversionDetails:[],
    
});

export default itemConversionDetails;