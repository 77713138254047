
function  receiptValidation(receipt){
    let errors :any={};
if(!receipt.receiptDate){
    errors.receiptDate='Select Receipt Entry Date'
}
if(!receipt.referenceNo){
    errors.referenceNo='Enter Receipt Reference No'
}
    return errors;
};
function receiptCreditValidation(receipt){
    let errors :any={};

if(!receipt.toAccountId){
    errors.toAccountId='Enter Receipt Reference No'
}
if(!receipt.creditAmount){
    errors.creditAmount='Enter Receipt Reference No'
}


    return errors;
};
function receiptDebitValidation(receipt){
    let errors :any={};
if(!receipt.debitAccountId){
    errors.debitAccountId='Select By Account'
}
if(!receipt.debitAmount){
    errors.debitAmount='Enter Debit Amount'
}

    return errors;
};

export {receiptDebitValidation,receiptCreditValidation,receiptValidation}