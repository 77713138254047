import { observable } from "mobx";
export interface IBrand{
    brandId?:number;
    brandCode?:string;
    brandCompany?:string;
}
export interface IBrandSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const brandDetails=observable<IBrand>({
    brandId:0,
    brandCode:'',
    brandCompany:'',
    
});

export default brandDetails;