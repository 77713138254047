import { runInAction, set, makeObservable, observable, action } from 'mobx';
import apiAgent from '../../../APIAgent';
import packingInwardDetails, { IPackingInward } from '../model/index';

class PackingInwardStore {
    @observable packingInwardDetls = packingInwardDetails;
    @observable packingInwardList= new Map<number,IPackingInward>();
    getPackingInwards = new Map();
    packingInwardDetails = new Map();    
    isLoading=true;
    @observable entryNo= new Map();

    constructor(){
        makeObservable(this);
    }
     savePackingInward = async (packingInward: IPackingInward) => {
        try {
          const status=  await apiAgent.packingInward.savePackingInward(packingInward);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingInward=async ()=> {
        
        try {
          const getPackingInward= await apiAgent.packingInward.getPackingInward();
            
            runInAction(() => {
                
                this.getPackingInwards = getPackingInward;
            });
            return this.getPackingInwards;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingInwardDetails=async (packingInwardId)=> {
        
        try {
          const getPackingInward= await apiAgent.packingInward.getPackingInwardDetails(packingInwardId);
            
            runInAction(() => {
                
                this.packingInwardDetails = getPackingInward;
            });
            return this.packingInwardDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.packingInward.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getPackingInwardSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.packingInward.getPackingInwardSearch(SearchOptions);
            this.getPackingInwards = Data;
            
            runInAction(() => {
                this.getPackingInwards = Data;
                
            })
            return this.getPackingInwards;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deletePackingInwardDetail = async (packingInwardDetailId) => {
        try {      
        const status=  await apiAgent.packingInward.deletePackingInwardDetail(packingInwardDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deletePackingInwardMaster = async (packingInwardId) => {
        try {      
        const status=  await apiAgent.packingInward.deletePackingInwardMaster(packingInwardId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    @action setPackingInward = (state:IPackingInward) =>{
        this.packingInwardList.set(0,state);
    }
    @action setPackingInwardList = (state: IPackingInward[]) =>{
        set(state,this.packingInwardList);
        return this.packingInwardList;
    }
}
export default PackingInwardStore;