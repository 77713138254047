import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';
import { currentPageDatas, getCurrentMonthFirstDate, getMonthDates } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import { cloneDeep } from 'lodash';

const ManualAttendanceEntryDetail = observer((): JSX.Element => {
  const { payrollStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [searchFields, setSearchFields] = useState<any>({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [manualAttendanceList, setManualAttendanceList] = useState<any[]>([])

  function handleSearchInputChange(event) {
    const { name, value } = event.target;
    setSearchFields({ ...searchFields, [name]: value })
  }

  const handleCreate = () => {
    navigate('/Admin/ManualAttendanceEntry')
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  async function fetchPreLoadngData() {
    const userRights = await userCreationStore?.getUserRight('Manual_attendance_entry')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const manualAttendanceList = await payrollStore.getManualAttendance()
    setManualAttendanceList([...manualAttendanceList])
    setLoading(false)
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  // console.log(manualAttendanceList, 'manualAttendanceList>>')
  useEffect(() => {
    if (manualAttendanceList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(manualAttendanceList)
    }
  }, [manualAttendanceList, filteredData?.length, searchFields])

  const goToPage = (value: number) => {
    let updateArray: any[] = (isSearch ? filteredData : manualAttendanceList)
    updateArray?.forEach((val) => {
      if (val?.InDate, val?.Outdate) {
        val['avail'] = handleFindDates(val, val?.InDate, val?.Outdate)
      }
      return val
    })

    const currentPageList = currentPageDatas(updateArray?.filter((each) => each?.avail)?.slice()?.sort((a, b) => b?.Attnid - a?.Attnid), value, 30)
    setTotalPage(cloneDeep(currentPageList?.totalPages))
    setCurrentPageData([...currentPageList?.currentPageData])
  };

  function handleFindDates(val, inDate, outDate) {
    if (searchFields?.fromDate && searchFields?.toDate) {
      const yesterday = new Date(searchFields?.fromDate);
      if (val?.levtype !== 'PR') {
        if (new Date(inDate) >= yesterday && moment(inDate)?.format('MM') <= moment(searchFields?.toDate)?.format('MM')) {
          return true
        } else {
          return false
        }
      } else {
        if (new Date(inDate) >= yesterday && new Date(outDate) <= new Date(searchFields?.toDate) && new Date(inDate) <= new Date(outDate)) {
          return true
        } else {
          return false
        }
      }
    }
  }

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className="col-sm-2">
                      <div className="inputBox">
                        <label style={{ fontSize: '16px' }}>In Date</label>
                        <input
                          type="date"
                          className="inputBoxDate"
                          style={{ width: "100%", fontWeight: "lighter" }}
                          placeholder="DD/MMM/YYYY"
                          data-date=""
                          data-date-format="DD MMMM YYYY"
                          name="fromDate" value={searchFields.fromDate} onChange={handleSearchInputChange}
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <div className="col-sm-2">
                      <div className="inputBox">
                        <label style={{ fontSize: '16px' }}>Out Date</label>
                        <input
                          type="date"
                          className="inputBoxDate"
                          style={{ width: "100%", fontWeight: "lighter" }}
                          placeholder="DD/MMM/YYYY"
                          data-date=""
                          data-date-format="DD MMMM YYYY"
                          name="toDate" value={searchFields.toDate} onChange={handleSearchInputChange}
                        ></input>
                      </div>
                      <div className="vertical-space-10"></div>
                    </div>
                    <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={manualAttendanceList}
                      searchTitles={[{ key: "Employee_Name", value: "Employee Name" }]}
                      emitSearchArray={(filterArray: any) => setFilteredData(filterArray)}
                      isEmitClear emitClear={() => setSearchFields({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })} />
                  </div>
                </div>
                <div className='inputBoxLists'>
                  <div className='vertical-space-20'></div>
                  <div className='row'>
                    <div className='col-sm-5'>
                      <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{(isSearch ? filteredData : manualAttendanceList)?.filter((each) => each?.avail)?.length} Records</button>
                      </div>
                    </div>
                    <div className=' col-sm-7' >
                      <Stack spacing={2}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                      </Stack>
                    </div>
                  </div>
                  <div className='tableListDetails'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Present</th>
                          <th scope="col">Leave Type</th>
                          <th scope="col">In Date</th>
                          <th scope="col">In Time</th>
                          <th scope="col">Out Date</th>
                          <th scope="col">Out Time</th>
                          <th scope="col">Create</th>
                          <th scope="col">Update</th>
                          <th scope="col">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(currentPageData)?.length > 0 &&
                          (currentPageData)?.map((val, key) => {
                            return val?.avail && (
                              <tr>
                                <td>{val?.Employee_Name}</td>
                                <td>{val?.levtype == 'PR' ? "Present" : "Absent"}</td>
                                <td>{val?.levtype}</td>
                                <td>{moment(val?.InDate).format('DD-MMM-YYYY')}</td>
                                <td>{val?.levtype == 'PR' ? moment(val?.InTime).format('HH:MM') : '---'}</td>
                                <td>{val?.levtype == 'PR' ? moment(val?.Outdate).format('DD-MMM-YYYY') : '---'}</td>
                                <td>{val?.levtype == 'PR' ? moment(val?.OutTme).format('HH:MM') : '---'}</td>
                                <td><button disabled={!userRights?.add} className='Create' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                                <td><button disabled={!userRights?.edit} className='Edit' onClick={() => { }}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                <td><button disabled={!userRights?.delete} className='delete' onClick={() => { }}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                              </tr>
                            )
                          }
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>

  )
})

export default ManualAttendanceEntryDetail;