import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import { Autocomplete, TextField } from '@mui/material';
import moment from "moment";
import { sortedData } from "../../common/shared/utils";

interface ExcelSheetData {
    Date: string,
    Item: string,
    Category: string,
    Sub_Category: string,
    Brand: string,
    Barcode: string,
    Batch_No: string,
    Rejection_Stock: string,
}

const RejectionStock = () => {

    const { warehouseStores, commonStore } = useStores();
    const { loadSubcategory, loadSubcategories, loadItemConversion, loadItemConversions, loadBrands, loadbrands } = commonStore;

    const [rejecConvJson, setRejecConvJson] = useState<any[]>([]);

    // console.log(rejecConvJson, 'rejecConvJson>>')

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [localFields, setLocalFields] = useState<any>({})
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false, brand: false })

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;
        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false, brand: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false, brand: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true, brand: false })
        }
        if (value === "Brand") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false, brand: true })
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setLocalFields({ ...localFields, [type]: value.subCategoryId });
        } else if (type === "brand") {
            setLocalFields({ ...localFields, [type]: value.brandCode });
        } else {
            setLocalFields({ ...localFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        let error: any = {}
        // error = OutletStockSummaryValidation(stockSummaryItemWiseFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                "itemId": localFields?.itemId,
                "subCategoryId": localFields?.subCategoryId,
                "brand": localFields?.brand,
                "category": localFields?.category?.toString(),
                "toDate": localFields?.toDate
            }
            const rejeConvJSON = await warehouseStores.rejectionStockAsonJson(body)
            if (rejeConvJSON?.length) {
                setRejecConvJson([...sortedData(rejeConvJSON, 'EntryDate')])
            }
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        if (stockSummary?.length) {
            let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
                const excelObj: ExcelSheetData = {
                    Date: moment(stock?.EntryDate).format("DD-MMM-YYYY"),
                    Item: stock?.itemname,
                    Category: stock?.Category,
                    Brand: stock?.brandname,
                    Barcode: stock?.barcode,
                    Batch_No: stock?.batchno,
                    Sub_Category: stock?.subcategoryname,
                    Rejection_Stock: stock?.balanceqty,
                }
                return excelObj;
            })
            exportToExcel(excelArr)
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Rejection Stock - Warehouse", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Rejection Stock - Warehouse ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchInitialLoad() {
        await loadItemConversion();
        await loadBrands()
        await loadSubcategory();
        setLoading(false)
    }

    const isCurrenPage = useRef(true)
    useEffect(() => {
        if (isCurrenPage.current) {
            fetchInitialLoad()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='full-width'>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Rejection Stock - Warehouse</h3>
                            </div>
                            <div className='vertical-space-20'></div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2"></div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label style={{ fontSize: "small" }}>As on Date <span>*</span></label>
                                                <input type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={localFields.toDate = localFields?.toDate ?? new Date().toISOString().substr(0, 10)}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                            <div className='inputBox' style={{ marginTop: '5px', padding: "10px 10px" }}>
                                                <select style={{ width: "175px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                                    <option value="">Select Search Criteria</option>
                                                    <option value="ItemName">Item Name</option>
                                                    <option value="Category">CateGory </option>
                                                    <option value="SubCategory">Sub Category </option>
                                                    <option value="Brand">Brand</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                            {searchCriteria?.category ?
                                                <>
                                                    <div className='inputBox'>
                                                        <select style={{ marginTop: "0px", height: "47px" }} className="form-selected" onChange={handleInputChange} id="Category" name="category">
                                                            <option value="" selected>Not Select</option>
                                                            <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                            <option value="Pets">Pets</option>
                                                            <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                            <option value="Grocery">Grocery</option>
                                                        </select>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </> : searchCriteria?.itemName || searchCriteria?.subCategory || searchCriteria?.brand ?
                                                    <div className='inputBox'>
                                                        <Autocomplete size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={searchCriteria?.itemName ? loadItemConversions : searchCriteria?.subCategory ? loadSubcategories : loadbrands}
                                                            getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                            onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : searchCriteria?.subCategory ? handleValue('subCategoryId', event, val) : handleValue("brand", event, val)}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                                    name={searchCriteria?.itemName ? 'itemId' : searchCriteria?.subCategory ? 'subcategory' : 'brandCompany'}
                                                                />}
                                                        />
                                                    </div> :
                                                    <div className="">

                                                    </div>
                                            }
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(rejecConvJson)}>Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>

                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Entry Date</th>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Sub Category</th>
                                                <th scope="col">Brand</th>
                                                <th scope="col">Barcode</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Rejection Stock</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rejecConvJson?.length > 0 ? rejecConvJson?.map((items, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{moment(items?.EntryDate)?.format('DD-MM-YYYY')}</td>
                                                        <td>{items?.itemname}</td>
                                                        <td>{items?.Category}</td>
                                                        <td>{items?.subcategoryname}</td>
                                                        <td>{items?.brandname}</td>
                                                        <td>{items?.barcode}</td>
                                                        <td>{items?.batchno}</td>
                                                        <td>{items?.balanceqty}</td>
                                                    </tr>
                                                )
                                            }) : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default RejectionStock;