function addressValidation(address){
    
      let errors :any={};
        
        if(!address.name){
          errors.name = "Enter Name";
        }
        if(!address.country){
          errors.country = "Select Contry/Region";
        }
        if(!address.area){
          errors.area = "Enter Area";
        }
        if(!address.doorNoStreet){
            errors.doorNoStreet = "Enter DoorNo & Street";
          }
          if(!address.landMark){
            errors.landMark = "Enter LandMark";
          }
          if(!address.city){
            errors.city = "Select city";
          }
          if(!address.mobileNumber){
            errors.mobileNumber = "Enter MobileNo";
          }
          if(!address.pinCode){
            errors.pinCode = "Enter PinCode";
          }
      return errors;
    };
      
      export default addressValidation;