import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { Button, Container, Image, Carousel, Modal } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useEcomStores from '../hooks/index';
import Cookies from 'js-cookie';
import { ICartProduct, IProductMember } from '../Products/model';
import HeaderComp from '../Header/newHeader';
import useStores from '../../gsmadmin/hooks';


import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import './Home.css'
import ProductsByCategory from '../common/components/categories/productByCategory';
import { IPOSProductDetails, IPosBatchDetails } from '../../gsmadmin/Admin/POS/model';
import { checkForFreeItem, checkForItemOffer, comparePosItemMasterArrays, isEqual, shuffleArray, updateBatchList } from '../common/commonFunc';
import { extractUniqueCategories } from '../../gsmadmin/common/shared/utils';

const Home = observer((): JSX.Element => {

  let memberId = (Cookies.get('memberId'));
  let customerType = Cookies.get('customerType');

  const navigate = useNavigate();

  const { posMasterStore, itemMasterStore } = useStores();
  const { cartsDetailsStore, productStore, ecomHomeStore } = useEcomStores();

  const { getOnlineItems, getLoadItemImages, saveProductCart } = ecomHomeStore;

  const [isLoading, setLoading] = useState(true);

  console.log(isLoading,'isLoading>>')

  const [ecomProdList, setEcomProdList] = useState<any[]>([])


  const [posProductList, setPosProductList] = useState<any[]>([])
  const [posItemOffers, setPosItemOffers] = useState<any[]>(posMasterStore?.getItemOffers)
  const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)

  const [posItemDetils, setPOStItemDetails] = useState<any[]>([]);
  const [assignedOffers, setAssignedOff] = useState<any[]>([])
  const [posBatchDetails, setPOSBatchDetails] = useState<IPosBatchDetails[]>(new Array<IPosBatchDetails>());

  const combinedItemData = useMemo(() => {
    if (itemMasterStore?.itemMasterList?.length) {
      let productLists: any[] = updateProductsArray();
      return productLists!;
    }
  }, [itemMasterStore?.itemMasterList, posMasterStore?.loadPOSProducts])

  function updateProductsArray() {
    let products = posProductList?.map((each) => {
      let updateObj: any = {
        posItemId: each?.itemid,
        posItemSellingRate: Number(customerType?.split('-')![0] === 'B2B' ? each?.BTBSellingRate : each?.BTCSellingRate),
        posbatchNo: each?.batchno,
        posCategory: each?.category,
        posItemMRP: each?.Mrprate,
        posBarcode: each?.Barcode,
        posbatchqty: each?.stock,
        posItemName: each?.itemname,
        posSubCategoryId: each?.subcategory,
        availableQty: each?.stock,
        posComments: each?.comments,
        posUnitOdMeasure: each?.unitOfMeasure,
        posItemQty: 1,
        posTaxPercentage: each?.taxper,
        posDiscountPercentage: each?.Discount_Pv,
        posDiscountValue: each?.Discount_Pv,
        posDiscountMode: each?.Dis_Mode,
      }
      return updateObj;
    })
    products = itemMasterStore?.itemMasterList?.map((item) => {
      let updateObj: any = {
        posItemId: item?.itemId,
        posItemSellingRate: item?.purchasePrice ?? 0,
        posbatchNo: item?.batchno ?? '',
        posCategory: item?.itemCategory ?? '',
        posItemMRP: item?.mrp ?? 0,
        posBarcode: item?.Barcode ?? '',
        posbatchqty: item?.stock ?? 0,
        posItemName: item?.itemName ?? '',
        posSubCategoryId: item?.subcategory ?? '',
        availableQty: item?.stock ?? 0,
        posComments: item?.comments ?? '',
        posUnitOdMeasure: item?.unitName ?? '',
        posItemQty: 1,
        posTaxPercentage: item?.taxper ?? 0,
        posTaxValue: 0,
        posDiscountPercentage: item?.Discount_Pv ?? 0,
        posDiscountValue: item?.Discount_Pv ?? 0,
        posDiscountMode: item?.Dis_Mode ?? '',
        posTotalPrice: 0,
        posNetAmount: 0,
        imageData: item?.imageData,
      }
      return updateObj;
    })
    return products;
  }

  const topBrands = [
    require("../../gsmecom/images/brand/1679293536809.png"),
    require("../../gsmecom/images/brand/2560px-Clean_and_clear_logo.svg.png"),
    require("../../gsmecom/images/brand/64476c72005b84792cedef56_white.svg"),
    require("../../gsmecom/images/brand/Lakme_Logo.png"),
    require("../../gsmecom/images/brand/Pringles_2021.svg.png"),
    require("../../gsmecom/images/brand/download (1).png"),
    require("../../gsmecom/images/brand/download.png"),
    require("../../gsmecom/images/brand/stationary-memories-camlin-story.avif"),
    require("../../gsmecom/images/brand/1679293536809.png"),
    require("../../gsmecom/images/brand/2560px-Clean_and_clear_logo.svg.png"),
    require("../../gsmecom/images/brand/64476c72005b84792cedef56_white.svg"),
    require("../../gsmecom/images/brand/Lakme_Logo.png"),
    require("../../gsmecom/images/brand/Pringles_2021.svg.png"),
    require("../../gsmecom/images/brand/download (1).png"),
    require("../../gsmecom/images/brand/download.png"),
    require("../../gsmecom/images/brand/stationary-memories-camlin-story.avif"),
    require("../../gsmecom/images/brand/1679293536809.png"),
    require("../../gsmecom/images/brand/2560px-Clean_and_clear_logo.svg.png"),
    require("../../gsmecom/images/brand/64476c72005b84792cedef56_white.svg"),
    require("../../gsmecom/images/brand/Lakme_Logo.png"),
    require("../../gsmecom/images/brand/Pringles_2021.svg.png"),
    require("../../gsmecom/images/brand/download (1).png"),
    require("../../gsmecom/images/brand/download.png"),
    require("../../gsmecom/images/brand/stationary-memories-camlin-story.avif")
  ]

  function handleNavigate(itemId) {
    if (itemId) {
      navigate(`/products/${itemId}`)
    }
  }

  async function updateCartCount() {
    if (posItemDetils?.length > 0) {
      let productCarts: ICartProduct[] = posItemDetils?.map((product) => {
        let productCart: ICartProduct = {
          productId: product.posItemId,
          productQty: product.posItemQty ?? 1,
          webName: "",
          barcode: product.posBarcode,
          batchNo: product.posbatchNo,
          memberId: Number(memberId) ?? 0,
        }
        return productCart;
      })
      if (memberId !== undefined) {
        const cartResponse = await productStore.saveProductCart(productCarts);
        if (cartResponse !== undefined && cartResponse.message === 'Success') {
          Cookies.set('cartCount', cartResponse.cartCount);
        }
      } else {
        let data: any = [];
        if (Cookies.get('ProductCarts') !== undefined) {
          data = JSON.parse(Cookies.get('ProductCarts'));
          data = [...data, ...productCarts]
          Cookies.set('ProductCarts', JSON.stringify(data))
          Cookies.set('cartCount', data.length);
        } else {
          Cookies.set('ProductCarts', JSON.stringify(productCarts));
          Cookies.set('cartCount', productCarts.length);
        }
      }
    }
  }

  async function moveToCart() {
    updateCartCount()
    navigate('/cartsview');
  }

  function addToCart(itemBatch: IPOSProductDetails) {
    let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemBatch))
    let taxValue: number = 0;
    let discountValue: number = 0;
    let offerDiscountValue: number = 0;
    let totalPrice: number = 0;

    if (itemBatch?.posTaxPercentage) {
      taxValue = ((Number(itemBatch?.posItemSellingRate) - Number(itemBatch?.posItemSellingRate!)) / (1 + itemBatch?.posTaxPercentage! / 100))?.toFixed(2) as any;
    }

    let { assignOffer, offerDiscoung } = checkForItemOffer(itemBatch, assignedOffers, posItemOffers)
    setAssignedOff([...assignOffer])

    offerDiscountValue = offerDiscoung;

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

    itemBatch.posItemQty = itemBatch?.posItemQty;
    itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
    itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
    itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
    itemBatch.posTaxValue = Number(taxValue);
    itemBatch.posDiscountValue = discountValue + offerDiscountValue;
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount);

    const freeItems: any[] = checkForFreeItem(itemBatch, posFreeItems)

    if (!isexistsItem) {
      posItemDetils?.push(itemBatch);
      if (freeItems) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      }
      setPOStItemDetails([...posItemDetils, ...freeItems])
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode?.toString(),
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils,)
      setPOSBatchDetails([...batchDetails])
    } else {
      setPOStItemDetails(posItemDetils.map((item, i) => {
        if (item.posItemId === itemBatch.posItemId && itemBatch?.posbatchNo === item?.posbatchNo) {
          return item = itemBatch;
        }
        return item;
      }));
      const updatedArray = posItemDetils?.map((each, ind) => {
        const matchingItem = freeItems?.find((item, ind) =>
          !item?.posItemId &&
          item?.posFreeItemId === each?.posFreeItemId &&
          item?.posbatchNo === each?.posbatchNo
        )
        return matchingItem || each
      })
      setPOStItemDetails([...updatedArray]);
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode,
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      if (freeItems) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      } else if (batchObj?.itemId) {
        const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils,)
        setPOSBatchDetails([...batchDetails])
      }
    }
    toast('Item added to Cart');
    // alert('Item added to Cart')
  }

  async function fetchPosMasterApis() {
    let outletId = Number(Cookies.get('outletId'));
    if (outletId) {
      const posProductList = await posMasterStore.loadPOSProductList(outletId);
      setPosProductList([])
      setPosProductList([...posProductList])
    }
    const posItemOffer = await posMasterStore.getPosItemOffers()
    const posFreeItems = await posMasterStore.getPosFreeItems();
    setPosFreeItems([...posFreeItems])
    setPosItemOffers([...posItemOffer])
  }

  async function fetchPreloadingData() {
    if (memberId !== undefined) {
      let cartProduct: IProductMember = {};
      cartProduct.memberId = Number(memberId);
      cartProduct.productId = 0;
      const data = await cartsDetailsStore.getCartDetails(cartProduct);
      if (data !== undefined) {
        Cookies.set('cartCount', data.length);
      } else {
        Cookies.set('cartCount', 0);
      }
    }
    fetchPosMasterApis()
    await itemMasterStore.getItemMasters();
  }

  async function addProdToCart(item) {
    let productCarts: ICartProduct[] = [];

    let filterItems = ecomProdList?.filter((each) => each?.posItemId === item?.posItemId);
    let findItem = filterItems?.find((each) => each?.posItemId === item?.posItemId && each?.posbatchNo === item?.posbatchNo)

    let productCart: ICartProduct = {
      cartId: 0,
      productId: findItem.posItemId,
      productQty: findItem.posItemQty ?? 1,
      webName: "",
      barcode: findItem.posBarcode,
      batchNo: findItem.posbatchNo,
      memberId: Number(memberId ?? 0),
    }

    productCarts?.push(productCart)

    if (memberId !== undefined) {
      const cartResponse = await saveProductCart(productCarts);
      if (cartResponse !== undefined && cartResponse.message === 'Success') {
        Cookies.set('cartCount', cartResponse?.cartCount);
      }
    } else {
      let data: any = [];
      if (Cookies.get('ProductCarts') !== undefined) {
        data = JSON.parse(Cookies.get('ProductCarts'));
        data = [...data, ...productCarts]
        Cookies.set('ProductCarts', JSON.stringify(data))
        Cookies.set('cartCount', data.length);
      } else {
        Cookies.set('ProductCarts', JSON.stringify(productCarts));
        Cookies.set('cartCount', productCarts.length);
      }
    }
  }

  function updateProductDetails(ecomItemDetails, imageList) {
    if (ecomItemDetails?.length) {
      let products: any[] = ecomItemDetails?.map((each) => {
        let filterItem = ecomItemDetails?.filter((item) => item?.itemid === each?.itemid)
        let updateObj: any = {
          posItemId: each?.itemid,
          posItemName: each?.itemname,
          posBarcode: each?.Barcode,
          posbatchNo: each?.batchno,
          posbatchqty: each?.stock,
          availableQty: filterItem?.length ? filterItem?.reduce((a, v) => a = a + v?.stock!, 0) : 0,
          posItemSellingRate: Number(customerType?.split('-')![0] === 'B2B' ? each?.BTBSellingRate : each?.BTCSellingRate),
          posCategory: each?.category,
          posItemMRP: each?.Mrprate,
          posSubCategoryId: each?.subcategory,
          posSubCategory: each?.subcategoryname,
          posComments: each?.comments,
          posUnitOdMeasure: each?.unitOfMeasure,
          posItemQty: 1,
          posTaxPercentage: each?.taxper,
          posDiscountPercentage: each?.Discount_PV,
          posDiscountValue: each?.Discount_PV,
          posDiscountMode: each?.Dis_Mode,
        }
        return updateObj;
      })
      if (products?.length && imageList?.length) {
        imageList?.forEach((item: any) => {
          const { itemId, } = item;
          products?.forEach((product) => {
            const { posItemId } = product;
            if (posItemId === itemId) {
              product.imageData = item?.itemImage
            }
            return product
          })
        })
      }
      setEcomProdList([...products])
    }
  }

  async function fetchInitialApiCall() {
    setLoading(false);
    const ecomItemDetails = await getOnlineItems();
    const imageList = await getLoadItemImages();
    updateProductDetails(ecomItemDetails, imageList)
  }
  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchInitialApiCall()
      isCurrentPage.current = false;
    }
    return () => { }
  }, [customerType])

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox1'>
            <div className="spinner-6"></div>
          </div> :
          <>
            <div className="App">
              <div className="" style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1000 }}>
                <HeaderComp moveToCart={moveToCart} />
              </div>

              <Carousel className='banner'>
                <Carousel.Item className='bannerCarouseltem'>
                  <div className='bannerCarousel'>
                    <Image src={require("../../gsmecom/images/banner.jpg")} />
                  </div>
                </Carousel.Item>
                <Carousel.Item className='bannerCarouseltem'>
                  <div className='bannerCarousel'>
                    <Image src={require("../../gsmecom/images/banner.jpg")} />
                  </div>
                </Carousel.Item>
                <Carousel.Item className='bannerCarouseltem'>
                  <div className='bannerCarousel'>
                    <Image src={require("../../gsmecom/images/banner.jpg")} />
                  </div>
                </Carousel.Item>
              </Carousel>

              <div className='features'>
                <Container fluid>
                  <div className='vertical-space-30'></div>
                  <OwlCarousel className='owl-theme' items={6} autoplayTimeout={3000} loop margin={12} dots={false} autoplay={true} nav>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/FreeShipping.png")} />
                        </div>
                        <h4>Free shipping</h4>
                        <label>*All over in Tamilnadu & Pondicherry</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/payment.png")} />
                        </div>
                        <h4>Easy Payment</h4>
                        <label>All kind of payment accepted</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/calendar.png")} />
                        </div>
                        <h4>Everyday Shipping</h4>
                        <label>*For All over India</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/formula.png")} />
                        </div>
                        <h4>Chemical Free Products</h4>
                        <label>Traditional Rice & Cereals</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/delivery.png")} />
                        </div>
                        <h4>Pick-up Available</h4>
                        <label>In-store shopping & Pick-up Available @ Namakkal</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/credit.png")} />
                        </div>
                        <h4>Sodexo Payment</h4>
                        <label>Sodexo Payment Gateway</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/online-store.png")} />
                        </div>
                        <h4>B2B Online Store</h4>
                        <label>Business to Business purchase available</label>
                      </div>
                    </div>
                    <div className='item'>
                      <div className='featuresList'>
                        <div className='imgBox'>
                          <Image src={require("../../gsmecom/images/product.png")} />
                        </div>
                        <h4>Large Variety</h4>
                        <label>1,000+ Products</label>
                      </div>
                    </div>
                  </OwlCarousel>
                </Container>
              </div>

              {ecomProdList?.length ?
                <>
                  <ProductsByCategory categoryName='Featured' combinedItemData={shuffleArray(ecomProdList)} handleNavigate={handleNavigate} addToCart={addProdToCart} />
                  <ProductsByCategory categoryName='HOT PICKS' combinedItemData={shuffleArray(ecomProdList)} handleNavigate={handleNavigate} addToCart={addProdToCart} />
                  <ProductsByCategory categoryName='NEW ARRIVALS' combinedItemData={shuffleArray(ecomProdList)} handleNavigate={handleNavigate} addToCart={addProdToCart} />
                  <ProductsByCategory categoryName='Best Sellers' combinedItemData={shuffleArray(ecomProdList)} handleNavigate={handleNavigate} addToCart={addProdToCart} />
                  <ProductsByCategory categoryName='Special Offers' combinedItemData={shuffleArray(ecomProdList)} handleNavigate={handleNavigate} addToCart={addProdToCart} />
                </> : null}

              <div className="categories container">
                <div className='vertical-space-50'></div>
                <div className='title'>
                  <span>
                    <div className='main'>FEATURED CATEGORIES</div>
                    <div className='subtitle'>FEATURED CATEGORIES</div>
                  </span>
                </div>
                <div className='vertical-space-20'></div>
                <div className='section'>
                  <div className="hover01 column">
                    {extractUniqueCategories(ecomProdList)?.map((each, index) => {
                      return (
                        <div key={index}>
                          <figure><img src="https://picsum.photos/300/200?image=244" /></figure>
                          <span>{each}</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="categories container">
                <div className='vertical-space-50'></div>
                <div className='title'><span>Brands</span></div>
                <div className='vertical-space-20'></div>
                <div className="section">
                  <OwlCarousel className='owl-theme' items={12} autoplayTimeout={6000} loop margin={20} dots={true} autoplay={true} nav={true}>
                    {topBrands?.map((each, index) => {
                      return (
                        <div key={index} className='item'>
                          <div className='TopBrandsList'>
                            <div className='imgBox'>
                              <Image src={each} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </OwlCarousel>
                  <div className='vertical-space-20'></div>
                </div>
              </div>

              <Footer />
            </div>
          </>
      }
    </>

  );
})

export default Home;
