
function indentValidation(indent) {
  let errors: any = {};

  if (!indent.indentdate) {
    errors.indentdate = "Enter Date";
  }
  if (!indent.itemname) {
    errors.itemname = "Select Item Name";
  }
  if (!indent.indentqty) {
    errors.indentqty = "Enter Qty";
  }
  return errors;
};

export function saveValidation(indent) {
  let errors: any = {};

  if (!indent.indentfrom) {
    errors.indentfrom = "Select Raised From";
  }
  if (indent?.indentfrom === 'Outlet' && !indent.outletid) {
    errors.outletid = "Select Outlet Name";
  }
  if (indent?.indentfrom === 'Warehouse' && !indent.warehouseid) {
    errors.warehouseid = "Select Warehouse Name";
  }
  if (!indent.raisedby) {
    errors.raisedby = "Select Raised By";
  }
  if (!indent?.generalIndentDetails?.length) {
    errors.generalIndentDetails = 'Add items to Grid'
  }
  return errors;
};

export default indentValidation;