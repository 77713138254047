import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../gsmecom/EcomAPI';
import { ILogin } from '../../../gsmecom/Login/model';

class AdminMemberStore {
   @observable adminLoginDetails=new Map();
    constructor() {
        makeObservable(this)
    }
    getAdminLogin=async (adminLogin: ILogin) => {
        try {
            
          const status =  await apiAgent.memberLogin.getAdminLogin(adminLogin);
          
          
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
}
export default AdminMemberStore