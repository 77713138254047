import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

interface ExcelSheetData {
    Mcode?: string, MachineName?: string, MachineNo?: string, MachineMake?: string, ModelNo?: string, DOI?: string,
    Description?: string, Manufact_year?: string, Department?: string, MachineValue?: string, Maint_Card_No?: string, HSN?: string,
}

const tableHeadings = ["Mcode", "MachineName", "MachineNo", "MachineMake", "ModelNo", "DOI", "Description", "Manufact_year",
    "Department", "MachineValue", "Maint_Card_No", "HSN", "Create", "Update", "Delete"];

const MachineMasterDetail = observer((): JSX.Element => {

    const [isSpinner, setSpinner] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const { machineMasterStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate()

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/MachineMaster')
    }

    function editMachineMaster(Mcode) {
        navigate('/Admin/MachineMaster/' + Mcode)
    }

    const deleteLedgerGroupDetials = async (Mcode) => {
        setSpinner(true);
        const status = await machineMasterStore.deleteMachineMaster(Mcode);

        if (status === 'Success') {
            fetchMachineMasterList();
            setSpinner(false);
            setSuccessModal(true);
        }
        else {
            setSpinner(false);
            setFailureModal(true);
        }
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        fetchMachineMasterList();
        setSuccessModal(false);

    }


    async function fetchMachineMasterList() {
        setSpinner(false);
        const [userRights] = await Promise.all([userCreationStore?.getUserRight('Machine_master'), machineMasterStore.getMachineMasterList()])
        if (userRights) {
            setRightsObj({ ...userRights })
        }
    }

    function createExportExcelObj(minimumStockReport: any[]) {
        let excelArr: ExcelSheetData[] = minimumStockReport?.map((minimumReport: ExcelSheetData) => {
            const excelObj: ExcelSheetData = {
                Mcode: minimumReport?.Mcode,
                MachineName: minimumReport?.MachineName,
                MachineNo: minimumReport?.MachineNo,
                MachineMake: minimumReport?.MachineMake,
                ModelNo: minimumReport?.ModelNo,
                DOI: minimumReport?.DOI,
                Description: minimumReport?.Description,
                Manufact_year: minimumReport?.Manufact_year,
                Department: minimumReport?.Department,
                MachineValue: minimumReport?.MachineValue,
                Maint_Card_No: minimumReport?.Maint_Card_No,
                HSN: minimumReport?.HSN,
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Minimum Stock Report",
            Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Minimum Stock Report ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchMachineMasterList();
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (machineMasterStore?.machineMasterList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(machineMasterStore?.machineMasterList)
        }
    }, [machineMasterStore?.machineMasterList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(machineMasterStore?.machineMasterList?.slice()?.sort((a, b) => b.Mcode - a.Mcode), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <div className="flex alignItemCenter justifyContentCenter">
                <div className='outletInputField inputFormBox LgInputField' style={{ width: '80%' }}>
                    <div className='hrBox'>
                        <h3>Machine Master Details</h3>
                    </div>
                    <div className='vertical-space-20'></div>
                    <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={currentPageData}
                        searchTitles={[{ key: "MachineName", value: "Machine Name" }]}
                        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                    <div className='vertical-space-20'></div>
                    <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
                        <div>
                            <div className='btnBox'>
                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{machineMasterStore?.machineMasterList?.length} Records</button>
                            </div>
                        </div>
                        <div >
                            <Stack spacing={2}>
                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                            </Stack>
                        </div>
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='tableListDetails'>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    {tableHeadings?.map((heading, index) => {
                                        return (
                                            <th key={index}>{heading}</th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {(isSearch ? filteredData : currentPageData).length > 0 &&
                                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{val?.Mcode}</td>
                                                <td>{val?.MachineName}</td>
                                                <td>{val?.MachineNo}</td>
                                                <td>{val?.MachineMake}</td>
                                                <td>{val?.ModelNo}</td>
                                                <td>{moment(val?.DOI).format('DD-MMM-YYYY')}</td>
                                                <td>{val?.Description}</td>
                                                <td>{val?.Manufact_year}</td>
                                                <td>{val?.Department}</td>
                                                <td>{val?.MachineValue}</td>
                                                <td>{val?.Maint_Card_No}</td>
                                                <td>{val?.HSN}</td>
                                                <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editMachineMaster(val.Mcode)} /></button></td>
                                                <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgerGroupDetials(val.Mcode)} /></button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>

                        </table>
                    </div>

                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../../image/checked.png')} />
                                </div>

                                <h4>Succesfully Deleted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>

                                <div className='imgBox'>
                                    <Image src={require('../../../../image/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div className='vertical-space-20'></div>
                </div>
            </div>
        </div>
    );

});

export default MachineMasterDetail;