import React, { useState, useEffect, ChangeEvent, useMemo, useReducer, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

interface ExcelSheetData {
  Item_Name?: string,
  Min_Stock?: string,
  All_Outlets?: string,
  All_Warehouses?: string
}

const MinimumStockReport = observer((): JSX.Element => {
  const [isSpinner, setSpinner] = useState(true);
  const { minimumStockStore } = useStores();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  async function fetchMinimumStock() {
    await minimumStockStore.getMinimumStock();
    setSpinner(false);

  }

  function createExportExcelObj(minimumStockReport: any[]) {
    let excelArr: ExcelSheetData[] = minimumStockReport?.map((minimumReport) => {
      const excelObj: ExcelSheetData = {
        Item_Name: minimumReport?.itemCode,
        Min_Stock: minimumReport?.stockLimit,
        All_Outlets: minimumReport?.outletstock,
        All_Warehouses: minimumReport?.warehousestock
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Minimum Stock Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Minimum Stock Report ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const isCurrentPage = useRef(true)
  useEffect(() => {
    if (isCurrentPage.current) {
      fetchMinimumStock();
      isCurrentPage.current = false
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (minimumStockStore?.minimumStocks?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(minimumStockStore?.minimumStocks)
    }
  }, [minimumStockStore?.minimumStocks])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(minimumStockStore?.minimumStocks, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <div className=' inputFormBox LgInputField'>
        <div className='hrBox'>
          <h3>Current Stock</h3>
        </div>
      </div>

      <div className='vertical-space-20'></div>

      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={currentPageData}
        searchTitles={[{ key: "itemName", value: "Item Name" }]}
        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} children={<div className='btnBox'><button className='searchclearBtn' onClick={() => createExportExcelObj(minimumStockStore.minimumStocks)}> Excel</button></div>} />

      <div className='vertical-space-20'></div>
      <div style={{ width: '100%', display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
        <div>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{minimumStockStore?.minimumStocks?.length} Records</button>
          </div>
        </div>
        <div >
          <Stack spacing={2}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
      </div>
      <div className='vertical-space-20'></div>

      <div className='tableListDetails' style={{ width: "100%" }}>
        {
          isSpinner ?
            <div className='SpinnerBox'>
              <Spinner animation="grow" size="sm" />
            </div> : null
        }
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Item Name & Item Code</th>
              <th scope='col'>Min Stock  </th>
              <th scope='col'>All Outlets </th>
              <th scope='col'>All Warehouse </th>

            </tr>
          </thead>
          <tbody>
            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
              (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.itemName}</td>
                    <td>{val.stockLimit}</td>
                    <td>{val.outletstock}</td>
                    <td>{val.warehousestock}</td>
                  </tr>
                )
              })
            }
          </tbody>

        </table>
      </div>

    </div>
  );

});

export default MinimumStockReport;