import { action,set,observable,runInAction } from "mobx";
import apiAgent from "../../../APIAgent";
import {IIndent} from "../model";

class IndentStore{
    @observable outletItemDetails= new Map();   
    isLoading=true;
    entryNo = new Map();
    @observable indentList= new Map();
    indentLists= new Map();
    indentListDetail = new Map();

    saveIndent = async (indent :IIndent)=>{
        try{
            const status = await apiAgent.indent.saveIndent(indent);        
            return status;
        }
        catch(error){
            console.log(error);
        }
    }

    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.indent.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }

    getIndent=async ()=> {
        
        try {
          const getindent= await apiAgent.indent.getIndent();
            
            runInAction(() => {
                
                this.indentList = getindent;
            });
            return this.indentList;
        }
        catch (error) {
            console.log(error);
        }
    }

    getIndentDetails=async (indentid)=> {
        
        try {
          const getindent= await apiAgent.indent.getIndentDetails(indentid);
            
            runInAction(() => {
                
                this.indentListDetail = getindent;
            });
            return this.indentListDetail;
        }
        catch (error) {
            console.log(error);
        }
    }

    getIndentSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.indent.getIndentSearch(SearchOptions);
            this.indentList = Data;
            
            runInAction(() => {
                this.indentList = Data;
                
            })
            return this.indentList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteIndent= async (indentid) => {
        try {      
        const status=  await apiAgent.indent.deleteIndent(indentid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
     
    @action setIndent = (state:IIndent) =>{
        this.indentList.set(0,state);
    }
    @action setIndentList = (state: IIndent[]) =>{
        set(state,this.indentList);
        return this.indentList;
    }
     
}
export default IndentStore;