import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class OutletStockSummaryStore {
    @observable outletstockReportDetails = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    getOutletStockSummary = async (outletStockSummaryBody) => {
        try {
            const data = await apiAgent.outletStockSummary.getOutletStockSummary(outletStockSummaryBody);
            runInAction(() => {
                this.outletstockReportDetails = data;
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    
}
export default OutletStockSummaryStore;