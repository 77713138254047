import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { ISalesRegister } from '../model';

class SalesRegisterStore {
    @observable outletItemDetails= new Map();   
    @observable outletItemDetailslst= new Map();  
    @observable outletItemDetailsExport= new Map();  
    entryNo = new Map(); 

    loadOutletItemsExport = async (salesRegisterRequestExport) => {
        try {
            const data = await apiAgent.salesRegister.loadOutletItemsExport(salesRegisterRequestExport);
            runInAction(() => {
                this.outletItemDetailsExport = data;
            });
            return JSON.parse(data);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadOutletItems  = async (salesRegisterRequest)=> {
         
        try {
            this.outletItemDetails = new Map();
          const inwardData= await apiAgent.salesRegister.loadOutletItems(salesRegisterRequest);
             
            runInAction(() => {
                this.outletItemDetails = inwardData;
            });
            return this.outletItemDetails;
        }
        catch (error) {
            console.log(error);
        }
    }    
    loadOutletItemsDetails  = async (posid:any, flag)=> {
        try {
          const inwardData= await apiAgent.salesRegister.loadOutletItemsDetails(posid, flag);
            
            runInAction(() => {
                this.outletItemDetailslst = inwardData;
            });
            return this.outletItemDetailslst;
        }
        catch (error) {
            console.log(error);
        }
    }         
}

export default SalesRegisterStore;