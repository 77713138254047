
function assignRedemptionValidation(assignRedemption) {


    let errors: any = {};
    // if (!assignRedemption?.Membershipid) {
    //     errors.planname = 'Select Plan Name'
    // }
    if (!assignRedemption.category) {
        errors.category = "Select Category";
    }
    if (!assignRedemption.amountPerPoints) {
        errors.amountPerPoints = "Enter Amount";
    }
    if (!assignRedemption.billValueFrom) {
        errors.billValueFrom = "Enter BillValue From";
    }
    if (!assignRedemption.billValueTo) {
        errors.billValueTo = "Enter BillValue To";
    }
    if (!assignRedemption.percentage) {
        errors.percentage = "Enter Percentage";
    }


    return errors;
};

export default assignRedemptionValidation;