import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import useStores from "../../hooks";
import { IManualAttendance, handleAddDay } from "./model";
import { calculateOTHours, calculateTimeDifference, getMinutesFromMidnight, handleFindToDate } from "../../common/shared/utils";
import { Autocomplete, TextField } from "@mui/material";

const ManualAttendanceEntry = () => {

    const navigate = useNavigate();

    const { payrollStore, employeeStore } = useStores()

    const [list, setList] = useState<any>([]);
    const [employeeList, setEmployeeList] = useState<any>([])

    const [manualAttendanceEntry, setManualAttendanceEntry] = useState<IManualAttendance>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate('/Admin/ManualAttendanceEntry/list');
        setSuccessModal(false)
    };

    async function handleSaveManualAttendance() {
        if (list?.length) {
            setLoading(true)
            const status = await payrollStore.saveManualAttendance(list)
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToDeductionList() {
        navigate("/Admin/ManualAttendanceEntry/list");
    }

    function handleChangeInput(event) {
        const { name, value } = event.target;
        if (name === 'inDate') {
            setManualAttendanceEntry({ ...manualAttendanceEntry, [name]: value, outdate: value });
        } else {
            setManualAttendanceEntry({ ...manualAttendanceEntry, [name]: value });
        }
    }

    function handleAutoComplete(name, value) {
        setManualAttendanceEntry({ ...manualAttendanceEntry, [name]: Number(value?.EmployeeId), otApplicable: value?.OTApplicable, shiftHrs: value?.Shithours });
    }

    const present = [{ key: 'Present', value: 'Present' }, { key: 'Absent', value: 'Absent' },]
    const leaveType = [{ key: 'wo', value: 'WO' }, { key: 'cl', value: 'CL' }, { key: 'pl', value: 'PL' }, { key: 'lop', value: 'LOP' }, { key: 'sl', value: 'SL' }]

    function handleAddToGrid(index) {
        if (index >= 0) {
            list?.splice(index, 1)
            setList([...list])
        } else {
            let error: any = {}
            error = validate(manualAttendanceEntry)
            setErrors({ ...error });
            if (Object.keys(error).length === 0) {
                manualAttendanceEntry.attnDate = manualAttendanceEntry?.inDate;
                let leaveIndate = manualAttendanceEntry?.inDate;
                if (manualAttendanceEntry?.attntype === "Present") {
                    manualAttendanceEntry.levtype = "PR";
                } else {
                    manualAttendanceEntry.attnDate = manualAttendanceEntry?.inDate;
                    manualAttendanceEntry.inTime = "";
                    manualAttendanceEntry.outTme = "";
                    manualAttendanceEntry.outdate = "";
                }
                if (manualAttendanceEntry?.inTime! && manualAttendanceEntry?.outTme!) {
                    manualAttendanceEntry.workingtime = calculateTimeDifference(manualAttendanceEntry?.inTime!, manualAttendanceEntry?.outTme!)?.toString();
                    manualAttendanceEntry.workingminuts = getMinutesFromMidnight(manualAttendanceEntry?.workingtime)
                    if (manualAttendanceEntry?.otApplicable) {
                        const timeString = calculateOTHours(manualAttendanceEntry?.inTime!, manualAttendanceEntry?.outTme!, manualAttendanceEntry?.shiftHrs!);
                        const hour = parseInt(timeString.split(':')[0], 10);
                        manualAttendanceEntry.oThours = Number(hour);
                    } else {
                        manualAttendanceEntry.oThours = 0;
                    }
                } else {
                    manualAttendanceEntry.workingtime = "";
                    manualAttendanceEntry.workingminuts = 0;
                }
                list?.push(manualAttendanceEntry);
                setList([...list]);
                let newObj = new IManualAttendance();
                newObj['empid'] = manualAttendanceEntry?.empid;
                newObj['otApplicable'] = manualAttendanceEntry?.otApplicable;
                newObj['shiftHrs'] = manualAttendanceEntry?.shiftHrs;
                if (manualAttendanceEntry?.inDate && manualAttendanceEntry?.outdate) {
                    newObj['inDate'] = handleAddDay(manualAttendanceEntry?.inDate as any);
                    newObj['outdate'] = handleAddDay(manualAttendanceEntry?.outdate as any)
                } else {
                    newObj['inDate'] = handleAddDay(leaveIndate as any)
                    newObj['outdate'] = handleAddDay(leaveIndate as any)
                }
                setManualAttendanceEntry(newObj)
            }
        }
    }

    function validate(data) {
        let error: any = {}
        if (!manualAttendanceEntry?.empid) {
            error.empid = 'Select Employee';
        }
        if (!manualAttendanceEntry?.attntype) {
            error.attntype = 'Select Present/Absent';
        }
        if (manualAttendanceEntry?.attntype === 'Present') {
            if (!manualAttendanceEntry?.outTme) {
                error.outTme = 'Select Out Time';
            }
            if (!manualAttendanceEntry?.outdate) {
                error.outdate = 'Select Out Date';
            }
            if (!manualAttendanceEntry?.inDate) {
                error.inDate = 'Select In Date';
            }
            if (!manualAttendanceEntry?.inTime) {
                error.inTime = 'Select In Time';
            }
        } else if (manualAttendanceEntry?.attntype === 'Absent') {
            if (!manualAttendanceEntry?.levtype) {
                error.levtype = 'Select Leave Type';
            }
        }
        if (list?.length && manualAttendanceEntry?.empid &&
            list?.some((obj) => obj?.empid == manualAttendanceEntry?.empid) &&
            list?.some((obj) => obj?.inDate == manualAttendanceEntry?.inDate)) {
            error.exists = 'Attendance exists for this employee'
        }
        return error;
    }

    function updateEmpDetail(employeeOfficial, employeeList) {
        employeeOfficial?.forEach((family) => {
            const { Employeeid, ...familyInfo } = family;
            employeeList?.forEach((employee) => {
                const { EmployeeId, ...employeeInfo } = employee;
                if (EmployeeId === Employeeid) {
                    employee['OTApplicable'] = family?.OTApplicable;
                    employee['Shithours'] = family?.Shithours;
                }
            })
        })

        return employeeList;
    }

    async function initialApiCall() {
        const employeeList = await employeeStore.loadEmployeeMaster();
        const employeeOfficial = await employeeStore.loadEmployeeOfficial()
        setEmployeeList([...updateEmpDetail(employeeOfficial, employeeList)])
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])


    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="container">
                        <div className="vertical-space-20"></div>
                        <div className="outletInputField inputFormBox LgInputField">
                            <div className="hrBox">
                                <h3>Manual Attendance Entry</h3>
                            </div>
                            <div className="inputBoxLists">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Employee<span>*</span> </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employeeList}
                                                value={employeeList?.find((obj) => obj?.EmployeeId == manualAttendanceEntry?.empid)}
                                                getOptionLabel={(option: any) => option.Employee_Name}
                                                onChange={(event, val) => handleAutoComplete('empid', val)}
                                                renderInput={(params: any) =>
                                                    <TextField {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Employee..'
                                                        name="empid"
                                                    />}
                                            />
                                        </div>
                                        {errors.empid && (<p style={{ color: "red" }}>{errors.empid}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Present/Absent<span>*</span> </label>
                                            <select
                                                className="form-selected full-width"
                                                name="attntype"
                                                value={manualAttendanceEntry?.attntype}
                                                onChange={handleChangeInput}
                                                style={{ fontSize: "16px" }}
                                            >
                                                <option value="" selected>
                                                    - - - Select - - -
                                                </option>
                                                {present?.map((obj) => {
                                                    return (
                                                        <option value={`${obj?.key}`}>{obj?.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.attntype && (<p style={{ color: "red" }}>{errors.attntype}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}> In Date </label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="inDate"
                                                value={manualAttendanceEntry.inDate = manualAttendanceEntry?.inDate ?? new Date().toISOString()?.substr(0, 10)}
                                                onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.inDate && (<p style={{ color: "red" }}>{errors.inDate}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}> In Time </label>
                                            <input type="time" name="inTime"
                                                value={manualAttendanceEntry?.inTime}
                                                defaultValue={'09:00'}
                                                onChange={handleChangeInput}
                                                style={{ width: "100%" }}
                                                className="brandinput"
                                                placeholder="Days"
                                            ></input>
                                        </div>
                                        {errors.inTime && <p style={{ color: "red" }}>{errors.inTime}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}> Out Date </label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                min={handleFindToDate(manualAttendanceEntry.inDate ? manualAttendanceEntry?.inDate : new Date(), 'date')}
                                                name="outdate"
                                                value={manualAttendanceEntry.outdate = manualAttendanceEntry.outdate ?? new Date().toISOString()?.substr(0, 10)}
                                                onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.outdate && <p style={{ color: "red" }}>{errors.outdate}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>Out Time</label>
                                            <input
                                                type="time"
                                                name="outTme"
                                                value={manualAttendanceEntry?.outTme}
                                                defaultValue={'17:00'}
                                                onChange={handleChangeInput}
                                                style={{ width: "100%" }}
                                                className="brandinput"
                                                placeholder="Days"
                                            ></input>
                                        </div>
                                        {errors.outTme && <p style={{ color: "red" }}>{errors.outTme}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    {manualAttendanceEntry?.attntype === "Present" ? (
                                        <>
                                            <div className="col-sm-4"></div>
                                        </>
                                    ) : manualAttendanceEntry?.attntype === "Absent" ? (
                                        <>
                                            <div className="col-sm-4">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}>Leave Type<span>*</span></label>
                                                    <select
                                                        className="form-selected full-width"
                                                        name="levtype"
                                                        value={manualAttendanceEntry?.levtype}
                                                        onChange={handleChangeInput}
                                                        style={{ fontSize: "16px" }}
                                                    >
                                                        <option value="" selected>
                                                            - - - Select - - -
                                                        </option>
                                                        {leaveType?.map((obj) => {
                                                            return (
                                                                <option value={`${obj?.key}`}>{obj?.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {errors.levtype && <p style={{ color: "red" }}>{errors.levtype}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                        </>
                                    ) : null}
                                    <div className="btnBox">
                                        <button className="dfBtn" onClick={handleAddToGrid}>
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Employee</th>
                                            <th scope="col">Present/Absent</th>
                                            <th scope="col">Leave Type</th>
                                            <th scope="col">In Date</th>
                                            <th scope="col">In Time</th>
                                            {/* <th scope="col">Out Date</th> */}
                                            <th scope="col">Out Time</th>
                                            <th scope="col">Working Time</th>
                                            <th scope="col">OT Hours</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map((val: IManualAttendance, key) => {
                                            let otTime = calculateOTHours(val?.inTime!, val?.outTme!, val?.shiftHrs!);
                                            return (
                                                <tr key={key}>
                                                    <td>{employeeList?.find((obj) => obj?.EmployeeId == val?.empid)?.Employee_Name}</td>
                                                    <td>{val?.attntype}</td>
                                                    <td>{val?.levtype}</td>
                                                    <td>{val?.inDate ? moment(val?.inDate).format('DD-MMM-YYYY') : ''}</td>
                                                    <td>{val?.inTime}</td>
                                                    {/* <td>{val?.outdate ? moment(val?.outdate).format('DD-MMM-YYYY') : ''}</td> */}
                                                    <td>{val?.outTme}</td>
                                                    <td>{val?.workingtime}</td>
                                                    <td>{val?.otApplicable && val?.attntype == 'Present' ? otTime : '00:00'}</td>
                                                    <td><button className="delete" onClick={() => handleAddToGrid(key)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {errors.exists && (<p style={{ color: "red" }}>{errors.exists}</p>)}
                            </div>

                            <div className="btnBox">
                                <button className="secondaryBtn" onClick={handleToDeductionList}>
                                    List
                                </button>
                                <button className="dfBtn" onClick={handleSaveManualAttendance}>


                                    Submit
                                </button>
                            </div>

                            <Modal
                                show={isSuccessModal}
                                onHide={handleSuccessModalClose}
                                className="SubmitModal"
                            >
                                <Modal.Body>
                                    <div className="Details Success">
                                        <div className="imgBox">
                                            <Image src={require("../../../../image/checked.png")} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal
                                show={isFailureModal}
                                onHide={handleFailureModalClose}
                                className="SubmitModal"
                            >
                                <Modal.Body>
                                    <div className="Details Success">
                                        <div className="imgBox">
                                            <Image src={require("../../../../image/warning.png")} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default ManualAttendanceEntry;