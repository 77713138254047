import React, { useState, useEffect, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
const ManagePriceChangeList = observer((): JSX.Element => {
  const { managePriceChangeStore } = useStores();
  const { getManagePriceChanges, managePriceChangeList,getManagePriceChangesDetails,
    managePriceChangeDetail} = managePriceChangeStore
  const [isLoading, setLoading] = useState(true);
  const [priceHitory, setPriceHitory] = useState(false);
  const priceHitoryClose = () => setPriceHitory(false);
  const navigate = useNavigate();


  async function fetchManagePrice() {
    await getManagePriceChanges();
    setLoading(false);
  }
  useEffect(() => {
    fetchManagePrice();
  });

  const editManagePrice=(mpcid)=>{
    
  }

  const deleteManagePrice=(mpcid)=>{

  }
  const handleCreate=()=>{
    navigate('/Admin/ManagePriceChange');
  }
  const handleHistoryDetails=async(itemid)=>{
    await   getManagePriceChangesDetails(itemid);
    setPriceHitory(true);
  }

  return (
    <>
      {
        isLoading ?
          <Spinner animation="border" role="status">
            {/* <span className="">Loading...</span> */}
          </Spinner> : <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <div className='SearchBox'>
              <div className="input-group mb-3">
              {/* <select className="form-selected"
                name='offerType' 
                style={{width:"100%"}} id="OfferType">
                  <option value="none" selected>Not Select</option>
                  <option value="Qty_Discount">Qty Discount</option>
                  <option value="Value_Discount">Value Discount</option>
                  <option value="Coupon_Discount">Coupon Discount</option>
                </select> */}
                <input  type="text" className="form-control" placeholder="Search Here.." aria-label="Search Here" aria-describedby="search-button"></input>
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button" id="search-button"><FontAwesomeIcon icon={faSearch} /></button>
                </div>
              </div>
            </div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Item Name </th>
                    
                    <th scope='col'>New Rate </th>
                    <th scope='col'>Effective Date & Time </th>
                    <th scope='col'>Created By </th>
                    <th scope='col'>Approval Request </th>
                    <th scope='col'></th>
                    <th scope='col'>Create </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    managePriceChangeList.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.itemname} </td>
                          <td>{val.newrate}</td>
                          <td>{val.effectdate}</td>
                          <td>{val.createdby} </td>
                          <td>{val.approvalRequired} </td>
                     <td><button className='changeBtn' onClick={()=>handleHistoryDetails(val.itemId)}>History</button></td>

                          <td><button className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
              <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={()=>editManagePrice(val.mpcid)}/></button></td>
              <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={()=>deleteManagePrice(val.mpcid)}/></button></td>
                        </tr>
                      )
                    })
                  }

                </tbody>
              </table>
            </div>
            {
        priceHitory?
        <Modal show={priceHitory} onHide={priceHitoryClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Last Price History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Rate</th>                        
                  <th scope="col">Created By</th>
                </tr>
              </thead>
              <tbody>
                {
                  managePriceChangeDetail.map((val,key)=>{
                    return(
                      <tr key={key}>
                      <td>{val.newrate}</td>
                      <td>{val.approveddatetime}</td>
                      <td>{val.createdby}</td>
                    </tr>
                    )
                  })
                }           
              
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>:null
      }   
          </div>
      }
    </>
  );

});

export default ManagePriceChangeList;
