import { IIEmployeeIncrementDet, IIEmployeeIncrementSalDet, IIIncrementApproval } from "../model"

export function employeeSalaryValidation(employeeSalary: IIEmployeeIncrementSalDet) {
    let error: any = {}
    if (!employeeSalary.eiS_Amount) {
        error.eiS_Amount = "Enter Amount"
    }
    if (!employeeSalary.eiS_EarnType) {
        error.eiS_EarnType = "Select Earn Type"
    }
    if (!employeeSalary.eiS_GrossSal) {
        error.eiS_GrossSal = "Enter Gross Salary"
    }
    return error;
}

export function employeePromoDetValidation(employeePromoDet: IIEmployeeIncrementDet) {
    let error: any = {}
    // if (!employeePromoDet.epD_CatID) {
    //      error.epD_CatID = "Select Category"
    // }
    // if (!employeePromoDet.epD_DegnID) {
    //     error.epD_DegnID = "Select Designation"
    // }
    // if (!employeePromoDet.epD_DeptID) {
    //     error.epD_DeptID = "Select Department"
    // }
    if (!employeePromoDet.eiD_SalType) {
        error.eiD_SalType = "Select Salary Type"
    }
    return error;
}

export function saveValidation(employeePromotioni: IIIncrementApproval) {
    let error: any = {}
    if (!employeePromotioni.eiM_TicketNo) {
        error.eiM_TicketNo = "Select Employee ID"
    }
    if (!employeePromotioni.employeeincrementdet?.length) {
        error.employeeincrementdet = "Enter Promotion Details"
    }
    if (!employeePromotioni.employeeincrementsaldet?.length) {
        error.employeeincrementsaldet = "Enter Salary Details"
    }
    return error;
}