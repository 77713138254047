import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useStores from "../../hooks";


class IDeductionMaster {
  dedid?: number = 0;
  deductiontype?: string = ""
  percentage?: number = 0
  effectdate?: string = ""
}
const DeductionMaster = () => {

  const navigate = useNavigate();

  const { payrollStore, memberStore } = useStores()

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [deduction, setDeduction] = useState<IDeductionMaster>({});
  const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])


  const [list, setList] = useState<IDeductionMaster[]>([]);
  const [errors, setErrors] = useState<any>({});

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    handleToDeductionList()
    // navigate('/Admin/Brand/list');
    setSuccessModal(false)
  };

  async function handleSaveDeductionMaster() {
    let error: any = {}
    if (!list?.length) {
      error.list = 'Add Data to grid';
    }
    setErrors({ ...error });
    if (Object.keys(error)?.length === 0) {
      setLoading(true)
      const status = await payrollStore.saveDeductionMaster(list![0]!)
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true);
      } else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }

  function handleToDeductionList() {
    navigate("/Admin/Deduction/DeductionList");
  }

  function handleChangeInput(label, value) {
    setDeduction({ ...deduction, [label]: value });
  }

  function handleAddToGrid(index) {
    if (index >= 0) {
      list?.splice(index, 1)
      setList([...list])
    } else {
      let error: any = {}
      if (!deduction?.deductiontype) {
        error.deductiontype = 'Select Deduction Type';
      }
      if (!deduction?.effectdate) {
        error.effectdate = 'Select Date';
      }
      if (!deduction?.percentage) {
        error.percentage = 'Enter Percentage';
      }
      setErrors({ ...error });
      if (Object.keys(error).length === 0) {
        list?.push(deduction);
        setList([...list]);
        setDeduction(new IDeductionMaster())
      }
    }
  }

  async function initialApiCall() {
    const entryNo = await memberStore.getEntryNo('EmpDeduction');
    setDeduction({ ...deduction, dedid: (entryNo?.entryNo ?? 0) + 1 })
    const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
    setIncentiveMaster([...incentiveMaster])
    setLoading(false)
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      initialApiCall()
      isCurrenPage.current = false;
    }
  }, [])

  return (
    <div>
      {isLoading ? (
        <ProgressBar />
      ) : (
        <>
          <div className="vertical-space-20"></div>
          <div className="outletInputField inputFormBox">
            <div className="hrBox">
              <h3>Deduction Master</h3>
            </div>
            <div className="inputBoxLists">
              <div className="row">
                <div className="col-sm-6">
                  <div className='inputBox'>
                    <label>Code</label>
                    <input type="text" name='dedid' disabled value={deduction.dedid}
                      className='full-width' placeholder='Code..'></input>
                  </div>
                  <div className="vertical-space-20"></div>
                </div>
                <div className="col-sm-6">
                  <div className="inputBox">
                    <label style={{ fontSize: '16px' }}>Deduction<span>*</span></label>
                    <select className="form-selected full-width" name="unittype" value={deduction?.deductiontype}
                      onChange={(evt) => handleChangeInput("deductiontype", evt.target.value)} style={{ fontSize: "16px" }}
                    >
                      <option value="" selected>- - - Select - - - </option>
                      {incentiveMaster?.map((incentive) => {
                        return incentive?.IType == 'Deduction' && (
                          <option value={incentive?.IDescription}>{incentive?.IDescription}</option>
                        )
                      })}
                    </select>
                  </div>
                  {errors.deductiontype && (<p style={{ color: "red" }}>{errors.deductiontype}</p>)}
                  <div className="vertical-space-20"></div>
                </div>
                <div className="col-sm-6">
                  <div className="inputBox">
                    <label style={{ fontSize: '16px' }}> Percentage <span>*</span> </label>
                    <input type="number" name="brandCode" value={deduction?.percentage}
                      onChange={(evt) => handleChangeInput("percentage", Number(evt?.target?.value))}
                      className="full-width" placeholder="Enter Percentage.."
                    ></input>
                    {errors.percentage && (<p style={{ color: "red" }}>{errors.percentage}</p>)}
                  </div>
                  <div className="vertical-space-10"></div>
                </div>
                <div className="col-sm-6">
                  <div className="inputBox">
                    <label style={{ fontSize: '16px' }}>Effective From<span>*</span></label>
                    <input type="date" className="inputBoxDate"
                      style={{ width: "100%", fontWeight: "lighter" }}
                      placeholder="DD/MMM/YYYY" name="fromdate"
                      data-date="" data-date-format="DD MMMM YYYY"
                      value={deduction.effectdate = deduction?.effectdate ?? new Date().toISOString().substr(0, 10)}
                      onChange={(evt) => handleChangeInput("effectdate", evt.target.value)}
                    ></input>
                  </div>
                  {errors.effectdate && (<p style={{ color: "red" }}>{errors.effectdate}</p>)}
                  <div className="vertical-space-10"></div>
                </div>
              </div>
            </div>
            <div className="btnBox">
              <button className="dfBtn" onClick={handleAddToGrid}>
                Add
              </button>
            </div>
            <div className="tableListDetails" style={{ marginBottom: "10px" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Deduction</th>
                    <th scope="col">Percentage</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {list?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val?.dedid}</td>
                        <td>{val?.effectdate}</td>
                        <td>{val?.percentage}</td>
                        <td> <button className="delete" onClick={() => handleAddToGrid(key)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {errors.list && (<p style={{ color: "red" }}>{errors.list}</p>)}
            </div>
            <div className="btnBox">
              <button className="secondaryBtn" onClick={handleToDeductionList}>
                List
              </button>
              <button className="dfBtn" onClick={handleSaveDeductionMaster}>

                Submit
              </button>
            </div>

            <Modal
              show={isSuccessModal}
              onHide={handleSuccessModalClose}
              className="SubmitModal"
            >
              <Modal.Body>
                <div className="Details Success">
                  <div className="imgBox">
                    <Image src={require("../../../../image/checked.png")} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={isFailureModal}
              onHide={handleFailureModalClose}
              className="SubmitModal"
            >
              <Modal.Body>
                <div className="Details Success">
                  <div className="imgBox">
                    <Image src={require("../../../../image/warning.png")} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      )
      }
    </div >
  );
};

export default DeductionMaster;
