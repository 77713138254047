import React, { useState, useEffect, useRef } from 'react';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { IRackAllotmentSearchoptions } from './model';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const RackAllotmentDetail = observer((): JSX.Element => {
  const { rackAllotmentStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getRackAllotment, getrackAllotments, getrackAllotmentDetls, getRackAllotmentDetails } = rackAllotmentStore;
  const [isLoading, setLoading] = useState(true);
  const [isRackAllotmentShow, setRackAllotmentModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteRackBatchMaster = async (rackEntryNo) => {
    setLoading(true);
    const status = await rackAllotmentStore.deleteRackBatchMaster(rackEntryNo);

    if (status === 'Success') {
      fetchRackAllotment();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const deleteRackBatchDetail = async (rackEntryDetailNo) => {
    setRackAllotmentModal(false);
    setLoading(true);
    const status = await rackAllotmentStore.deleteRackBatchDetail(rackEntryDetailNo);

    if (status === 'Success') {
      fetchRackAllotment();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchRackAllotment();
  }

  async function fetchRackAllotment() {
    const userRights = await userCreationStore?.getUserRight('Rack_allotment')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await getRackAllotment();
    setLoading(false);

  }

  const handleCreate = () => {
    navigate('/Admin/RackAllotment');
  }


  const isCurrentaPage = useRef(true)

  useEffect(() => {
    if (isCurrentaPage.current) {
      fetchRackAllotment();
      isCurrentaPage.current = false
    }
    return () => { }
  }, []);

  async function handleRackAllot(rackEntryNo, e) {
    e.preventDefault();
    await getRackAllotmentDetails(rackEntryNo);
    setRackAllotmentModal(true);
  }

  const handleClose = () => {
    setRackAllotmentModal(false)
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (getrackAllotments && getrackAllotments?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(getrackAllotments)
    }
  }, [getrackAllotments])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(getrackAllotments.slice().sort((a, b) => b.rackEntryNo - a.rackEntryNo), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getrackAllotments}
                searchTitles={[{ key: "issuedByName", value: "Issued By" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getrackAllotments?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Outlet Name </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.rackEntryNo}</td>
                            <td>{moment(val.entryDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.outletname}</td>
                            <td><button disabled={!userRights?.view} onClick={(e) => handleRackAllot(val.rackEntryNo, e)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Create' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button> </td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteRackBatchMaster(val.rackEntryNo)} /></button></td>
                          </tr>
                        )
                      }
                      )}

                  </tbody>
                </table>
              </div>

              {

                isRackAllotmentShow ?
                  <Modal show={isRackAllotmentShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Rack Allotment Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Item Name</th>
                              <th scope="col">Batch No</th>
                              <th scope="col">Qty</th>
                              <th scope="col">Rack No</th>
                              <th scope="col">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              getrackAllotmentDetls?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.rackAllotitemName}</td>
                                    <td>{val.rackAllotbatchNo}</td>
                                    <td>{val.rackAllotbatchQty}</td>
                                    <td>{val.rackAllotrackNo}</td>
                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteRackBatchDetail(val.rackEntryDetailNo)} /></button></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default RackAllotmentDetail;
