import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IAssignBatchOutlet } from '../model';


class AssignBatchOutletStore {
    @observable assignBatchOutletList= new Map();
    isInwardLoding=true;
    getAssignbatchsOutlet = new Map();
    getAssignbatchOutletDetls = new Map();
    directInwards = new Map();
    directInwardItems= new Map();
     saveAssignBatchOutlet = async (assignBatchNo: IAssignBatchOutlet) => {
        try {
          const status=  await apiAgent.assignBatchOutlet.saveAssignBatchOutlet(assignBatchNo);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    
    getAssignbatchOutlet=async ()=> {
        
        try {
          const getBatchs= await apiAgent.assignBatchOutlet.getAssignbatchOutlet();
            
            runInAction(() => {
                
                this.getAssignbatchsOutlet = getBatchs;
            });
            return this.getAssignbatchsOutlet;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignbatchOutletDetails=async (assignNo)=> {
        
        try {
          const getBatchs= await apiAgent.assignBatchOutlet.getAssignbatchOutletDetails(assignNo);
            
            runInAction(() => {
                
                this.getAssignbatchOutletDetls = getBatchs;
            });
            return this.getAssignbatchOutletDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignbatchOutletSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.assignBatchOutlet.getAssignbatchOutletSearch(SearchOptions);
            this.getAssignbatchsOutlet = data;
            
            runInAction(() => {
                this.getAssignbatchsOutlet = data;
                
            })
            return this.getAssignbatchsOutlet;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDirectInwards  = async (supplierId)=> {
        try {
          const data=await apiAgent.assignBatchOutlet.loadDirectInwards(supplierId);
            
            runInAction(() => {
                this.directInwards = data;
                
            })
            return this.directInwards;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDirectInwardItems= async (loadOptions)=> {
        try {
          const data=await apiAgent.assignBatchOutlet.loadDirectInwardItems(loadOptions);
            
            runInAction(() => {
                this.directInwardItems = data;
                
            })
            return this.directInwardItems;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignbatchOutletDetail = async (assignDetilsNo) => {
        try {      
        const status=  await apiAgent.assignBatchOutlet.deleteAssignbatchOultetDetail(assignDetilsNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignbatchOutletMaster = async (assignNo) => {
        try {      
        const status=  await apiAgent.assignBatchOutlet.deleteAssignbatchOutletMaster(assignNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default AssignBatchOutletStore;