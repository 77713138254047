export class IPOSBillingSave {
    billNo?: number = 0
    billType?: string = ""
    billDate?: string = new Date()?.toISOString()?.substr(0, 10)
    memberId?: number = 0
    mobileNo?: string = ""
    paymentMode?: string = ""
    cardno?: string = ""
    billValue?: number = 0
    discountValue?: number = 0
    taxValue?: number = 0
    othercharges?: string = ""
    netvalue?: number = 0
    employeeId?: number = 0
    terminalId?: number = 0
    deliveryCharges?: number = 0
    memberCategory?: string = ""
    posSalesDetails?: IIPostSalesDetails[] = []
    posBatchDetails?: IIPostBatchDetails[] = []
    posSalescoinchange?: IIPosSalesCoinChange[] = []
    address?: IIPosAddress = {}
    earnedPoints?: number = 0
    redeemPoints?: number = 0
    earnedPointsamt?: number = 0
    redeemPointsamt?: number = 0
    outletId?: number = 0
    offerDiscountValue?: number = 0
    isSameAddress: boolean = true
    cashAmount?: number = 0
    redeemAmount?: number = 0
    salesReturn?: number = 0
    cardAmount?: number = 0
    qRcodeAmount?: number = 0
    onlinestoreorder?: number = 0
    onlinebankTR?: number = 0
    sodexo?: number = 0
    replacementposid?: number = 0
}

export class IIPostSalesDetails {
    posid?: number = 0
    posItemId?: number = 0
    posItemQty?: number = 0
    posItemMRP?: number = 0
    posNetAmount?: number = 0
    posDiscountePercentage?: number = 0
    posDiscountValue?: number = 0
    posTaxValue?: number = 0
    posTaxPercentage?: number = 0
    posItemSellingRate?: number = 0
    posTaxId?: number = 0
    posFreeItemId?: number = 0

    availableQty?: number = 0 //optional
    posItemName?: string = "" //optional
    posMinSalQty?: number = 0 //optional
    posDiscountPercentage?: number = 0 //optional
    posDiscountMode?: string = "" // optional
    posbatchNo?: string = ""// optional
    posBarcode?: string = ""// optional
    posSubCategoryId?: number = 0 // optional
    posTotalPrice?: number = 0 // optional
    checked?: boolean = false // optional
}

export class IIPostBatchDetails {
    itemId?: number = 0
    batchId?: number = 0
    barcode?: string = ""
    batchNo?: string = ""
    batchqty?: number = 0
}

export class IIPosSalesCoinChange {
    posid?: number = 0
    itemid?: number = 0
    barcode?: string = ""
    batchcode?: string = ""
    qty?: number = 0
    rate?: number = 0
    amount?: number = 0
}

export class IIPosAddress {
    name?: string = ""
    mobileNumber?: string = ""
    doorNoStreet?: string = ""
    area?: string = ""
    landMark?: string = ""
    city?: string = ""
    state?: string = ""
    country?: string = ""
    pinCode?: string = ""
}

/// other classes

export class IPosItemMaster {
    brandtype?: string = ""
    itemId?: number = 0
    itemName?: string = ""
    label?: string = ""
}

export class IMemberDetails {
    memberName?: string = "";
    memberType?: string = "";
    memberCatId?: string = "";
    memberCategory?: string = "";
    memberAddress?: string = "";
    memberEmail?: string = "";
    memberId?: number = 0;
    mobileNo?: string = "";
    earnedPoints?: number = 0;
    balanceEarnedPoints?: number = 0;
    redeemPoints?: number = 0;
    lastpurchasevalue?: number = 0
}

export class IIPosStockMaster {
    BTBSellingRate?: string = ""
    BTCSellingRate?: string = ""
    Barcode?: string = ""
    Comments?: string = ""
    CopponName?: string = ""
    Dis_Mode?: string = ""
    Discount_PV?: number = 0
    FilePath?: string = ""
    From_QV?: number = 0
    Gsmcode?: string = ""
    Hsncode?: string = ""
    Mrprate?: string = ""
    To_QV?: number = 0
    assignId?: number = 0
    batchno?: string = ""
    category?: string = ""
    coinchange?: number = 0
    effectdate?: string = ""
    itemid?: number = 0
    itemname?: string = ""
    minsalqty?: number = 0
    stock?: number = 0
    subcategory?: string = ""
    taxid?: number = 0
    taxname?: string = ""
    taxper?: number = 0
    unitOfMeasure?: string = ""
}

export class IIPosFreeItem {
    assignedby?: string = ""
    assignfreeitemdate?: string = ""
    assignfreeitemid?: number = 0
    batch_no?: string = ""
    efffromdate?: string = ""
    efftodate?: string = ""
    freeqty?: number = 0
    is_same_item?: boolean = false
    isbatch?: string = 'No'
    issales?: string = 'No'
    itemid?: number = 0
    itemname?: string = ""
    other_itemid?: number = 0
    otheritem?: string = ""
    otheritem1?: string = ""
    otheritembarcode?: string = ""
    otheritembatchno?: string = ""
    otheritemid?: number = 0
    saleqty?: number = 0
}

export class IEntryNoRequest {
    flag?: string = "";
    outletId?: number = 0;
}