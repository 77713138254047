import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';

class PartyLedgerStore {
    @observable partyLedgerName = new Map();
    @observable partyLedgerStatement = new Map();

    loadPartyLedgername = async (SearchOptions) => {
        try {
            const PartyLedgerName = await apiAgent.partyLedger.getLedgerAccountSearch(SearchOptions);

            runInAction(() => {
                this.partyLedgerName = PartyLedgerName;
            })

            return JSON.parse(PartyLedgerName)
        }
        catch (error) {
            console.log(error);
        }
    }

    loadPartyLedgerList = async (PartyLedgerOptions) => {
        try {
            const PartyLedgerList = await apiAgent.partyLedger.getLedgerAccountStatement(PartyLedgerOptions);

            runInAction(() => {
                this.partyLedgerStatement = PartyLedgerList;
            })

            return JSON.parse(PartyLedgerList)
        }
        catch (error) {
            console.log(error)
        }
    }
    getLedgerCashBook = async (cashBookSearchOptions) => {
        try {
            const PartyLedgerList = await apiAgent.partyLedger.getLedgerCashBook(cashBookSearchOptions);

            runInAction(() => {
                this.partyLedgerStatement = PartyLedgerList;
            })

            return PartyLedgerList
        }
        catch (error) {
            console.log(error)
        }
    }
    getLedgerDayBook = async (PartyLedgerOptions) => {
        try {
            const PartyLedgerList = await apiAgent.partyLedger.getLedgerDayBook(PartyLedgerOptions);
            runInAction(() => {
                this.partyLedgerStatement = PartyLedgerList;
            })
            return PartyLedgerList
        }
        catch (error) {
            console.log(error)
        }
    }

    getLedgerBankBook = async (PartyLedgerOptions) => {
        try {
            const PartyLedgerList = await apiAgent.partyLedger.getLedgerBankBook(PartyLedgerOptions);
            runInAction(() => {
                this.partyLedgerStatement = PartyLedgerList;
            })
            return PartyLedgerList
        }
        catch (error) {
            console.log(error)
        }
    }
    getTrialBalance = async (PartyLedgerOptions) => {
        try {
            const PartyLedgerList = await apiAgent.partyLedger.getTrialBalance(PartyLedgerOptions);
            runInAction(() => {
                this.partyLedgerStatement = PartyLedgerList;
            })
            return PartyLedgerList
        }
        catch (error) {
            console.log(error)
        }
    }
}

export default PartyLedgerStore;