import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import moment from 'moment';

const InterviewLetterList = () => {

    const { interviewStore, jobMasterStore, employeeStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/InterviewProcess')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Interview_letter')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const jobMaster = await jobMasterStore?.loadJobMaster();
        setJobMaster([...jobMaster])
        const interviewletter = await interviewStore.loadInterviewLetter();
        setInterviewLetterList([...interviewletter])
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (interviewLetterList && interviewLetterList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(interviewLetterList)
        }
    }, [interviewLetterList])

    console.log(interviewLetterList, 'interviewLetterList>>', currentPageData)

    const goToPage = (value: number) => {
        let sortedData = interviewLetterList?.slice()?.sort((a, b) => b.Interviewdid - a.Interviewdid);
        const currentPageList = currentPageDatas(sortedData, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={interviewLetterList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{interviewLetterList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Job Description</th>
                                            <th scope="col">Interview Date</th>
                                            <th scope="col">Call Letter No</th>
                                            <th scope="col">Candidate Name</th>
                                            <th scope='col'>Conduct by</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td >{each?.jobcode}</td>
                                                        <td >{jobMaster?.find((obj) => obj?.Jobcode == each?.jobcode)?.Jobtitle}</td>
                                                        <td style={{ maxWidth: '300px' }}>{jobMaster?.find((obj) => obj?.Jobcode == each?.jobcode)?.JobDescription}</td>
                                                        <td >{moment(each?.Interviewdateon)?.format('DD-MMM-YYYY')}</td>
                                                        <td >{each?.Interviewdid}</td>
                                                        <td >{each?.Candidatename}</td>
                                                        <td >{employeeMaster?.find((obj) => obj?.EmployeeId == each?.Hiringmanager)?.Employee_Name}</td>
                                                        <td><button disabled={!userRights?.view} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.add} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default InterviewLetterList;