export class INewItemReqest {
    reqid?: number;
    reqdate?: string;
    outletid?: number;
    requiestby?: number;
    neweitemRequestDetails?: INewItemRequestDetail[]
}

export class INewItemRequestDetail  {
    itemname?: string;
    remarks?: string;
}