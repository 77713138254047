import { IIBrakInOutEntry, IIBreakinoutEmployeeDet } from "../model";

export function addValidation(emplBreakDe: IIBreakinoutEmployeeDet) {
    let error: any = {}
    if (!emplBreakDe?.biD_EmpID) {
        error.biO_EmpID = 'Select Employee'
    }
    if (!emplBreakDe?.biD_Date) {
        error.biD_Date = 'Select Out Date';
    }
    if (!emplBreakDe?.biD_InTime) {
        error.biD_InTime = 'Select In Time';
    }
    if (!emplBreakDe?.biD_InDate) {
        error.biD_InDate = 'Select In Date';
    }
    if (!emplBreakDe?.biD_OutTime) {
        error.biD_OutTime = 'Select Out Time';
    }

    return error;
}

export function saveValidation(empBreakMaster: IIBrakInOutEntry) {
    let error: any = {}
    if (!empBreakMaster?.biO_Date) {
        error.biO_Date = 'Select Date'
    }
    if (!empBreakMaster?.biO_EmpID) {
        error.biO_EmpID = 'Select Employee'
    }
    if (!empBreakMaster?.employeebreakinoutdet?.length) {
        error.employeebreakinoutdet = 'Add Break In/Out to Grid'
    }
    return error;
}