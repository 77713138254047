 
import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Country, State, City } from 'country-state-city';
import { Form, Modal, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IWarehouse } from './model';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import warehouseValidator from './validators';
import { error } from 'console';
import Cookies from 'js-cookie';


const Warehouse = observer((): JSX.Element => {
  const [cityOptions, setCityOptions] = useState([]);
  const [warehouse, setWarehouseModel] = useState<IWarehouse>({})
  const { warehouseStores, userCreationStore } = useStores();
  const { supplierMasterStore } = useStores();
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])
  
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 


  const handleChangeview = () => {
    navigate('/Admin/Warehouse/list');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/Warehouse/list');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const getStateCountry = (e: any, value: any) => {
    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      warehouse.country = countries[0].name
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      warehouse.state = states[0].name;
      warehouse.city = value.name;
    }
    else {
      warehouse.country = '';
      warehouse.state = '';
    }
  }

  const saveWarehouse = async (e) => {

    e.preventDefault();
    let error: any = {};
    error = warehouseValidator(warehouse);
    setErrors(error);
    if (Object.keys(error).length === 0) {

      const status = await warehouseStores.saveWarehouse(warehouse);
      if (status === "Success") {
        setSuccessModal(true)
      }
      else {
        setFailureModal(true)
      }
    }
  }


  const handleFailureModalClose = () => setFailureModal(false);
  function handleChangeInput(event: any) {

    const { name, value } = event.target;

    setWarehouseModel({ ...warehouse, [name]: value })
  }

  async function fetchPreLoadingData() {
    const userRights = await Promise.all([userCreationStore?.getUserRight('Warehouse_registration')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }

  // useEffect(() => {
  //   setLoadModal(true);
  //   fetchPreLoadingData();
  //   let getCities: any;
  //   getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
  //   setCityOptions(getCities);

  // }, [cityOptions])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Warehouse_registration';

  useEffect(() => {
    
    const fetchRights = async () => {
     
      try {
          const data = await supplierMasterStore.fetchRights(empid, formName);
      
         if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              let getCities: any;
              getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
              setCityOptions(getCities);
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/Warehouse/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }
      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);



  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
  return (
    <>
      <div className='vertical-space-20'></div>
      <div className='outletInputField inputFormBox'>
        <div className='hrBox'>
          <h3>Warehouse Registration</h3>
        </div>
        <form  >
          <div className='inputBoxLists'>
            <div className='inputBox '>
              <label>Warehouse Name <span>*</span></label>
              <input type="text" className='' style={{ width: "75%" }}
                value={warehouse.warehouseName}
                onChange={handleChangeInput}
                name='warehouseName'
                placeholder='Enter Warehouse Name..' />
              {errors.warehouseName && <p style={{ color: 'red' }}>{errors.warehouseName}</p>}
            </div>
            <div className='vertical-space-10'></div>
            <div className='inputBox'>
              <label>FSSAI Number <span>*</span></label>
              <input type="text" className='fullInput'
                style={{ width: "75%" }}
                value={warehouse.fssainumber}
                onChange={handleChangeInput}
                name='fssaiNumber'
                placeholder='Enter FSSAI Number..' ></input>
              {errors.fssaiNumber && <p style={{ color: 'red' }}>{errors.fssaiNumber}</p>}
            </div>
            <div className='vertical-space-10'></div>
            <div className='inputBox'>
              <label>Date of Incorporated<span>*</span></label>
              <input type="date" placeholder="DD-MMM-YYYY" style={{ width: "40%" }}
                name='dateOfIncrorpeted' value={warehouse.dateOfIncrorpeted}
                data-date="" data-date-format="DD MMMM YYYY" onKeyDown={(event) => event.preventDefault()}
                onChange={handleChangeInput}></input>

              {errors.dateOfIncrorpeted && <p style={{ color: 'red' }}>{errors.dateOfIncrorpeted}</p>}
            </div>
            <div className='vertical-space-10'></div>
            <div className='inputBox'>
              <label>Door No / Street </label>
              <input type="text" className='fullInput'
                value={warehouse.doorNoStreet}
                onChange={handleChangeInput}
                name='doorNoStreet'
                style={{ width: "100%" }} placeholder='Enter Address..'></input>
            </div>
            <div className='vertical-space-10'></div>
            <div className='row'>
              <div className='col-md-6 col-sm-8'>
                <div className='inputBox'>
                  <label>Area</label>
                  <input type="text" className='fullInput' style={{ width: "100%" }}
                    value={warehouse.area}
                    onChange={handleChangeInput}
                    name='area'
                    placeholder='Enter Area..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>City <span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={cityOptions}
                    getOptionLabel={(option: any) => option.name}
                    onChange={getStateCountry}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '25ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select City'
                        name='city'
                      />}
                  />
                  {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>State </label>
                  <Form.Group style={{ width: "100%" }}>
                    <TextField
                      value={warehouse.state}
                      placeholder='Select State'
                      onChange={handleChangeInput}
                      name='state'
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Country </label>
                  <Form.Group style={{ width: "100%" }}>
                    <TextField
                      type="text"
                      placeholder='Select Country'
                      value={warehouse.country}
                      onChange={handleChangeInput}
                      name='country'
                    />
                  </Form.Group>
                  <div className='vertical-space-10'></div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>PIN Code <span>*</span></label>
                  <input type="text" className='fullInput'
                    value={warehouse.pinCode}
                    onChange={handleChangeInput}
                    name='pincode'
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    style={{ width: "100%" }} maxLength={6} placeholder='Enter PinCode..'></input>
                </div>
                {errors.pincode && <p style={{ color: 'red' }}>{errors.pincode}</p>}
                <div className='vertical-space-10'></div>

              </div>
            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleChangeview}>List</button>
            <button className="dfBtn" type="submit" onClick={saveWarehouse} disabled={!canAdd}
                title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
              >
                Submit
              </button>
            
           
          </div>
        </form>

        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>
              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
});

export default Warehouse;
