function packingInwardValidation(packingInward){
    let errors :any={};

    if(!packingInward.packingInwardDate){
      
        errors.packingInwardDate = "Enter PackingInward Date";
    }
    if(!packingInward.teamName){
      
        errors.teamName = "Enter TeamMaster Name ";
    }
    if(!packingInward.itemName){
      
        errors.itemName = "Enter Item Name ";
    }   
    if(!packingInward.packingQty){
      
        errors.packingQty = "Enter Packing Qty  ";
    }
    return errors;
};
export default packingInwardValidation
