import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import PurchaseBillRegisterValidation from './validation';
import { currentPageDatas } from '../../common/shared/utils';
import { IOutletStockAnalysis } from '../OutetStockAnalysis/model';

interface ExcelSheetData {
    Supplier_Name: string,
    BillNo: number,
    Bill_Date: string,
    Bill_Value: number,
    Tax: number,
    Taxable_Value: number,
    IGST: number,
    CGST: number,
    SGST: number,
    CESS: number
}

const PurchaseBillRegister = observer((): JSX.Element => {

    const { purchaseBillRegStore } = useStores();

    const [outletSummaryFields, setoutletSummaryFields] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis())

    const [loading, setLoading] = useState(true);

    const [outletStockSummaries, setOutStockSummaryies] = useState<any[]>([]);
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (outletStockSummaries?.length > 0) {
            let filterFroMArry = outletStockSummaries?.map((stock) => {
                if (handleFindDate(stock?.bill_date)) {
                    return stock
                } else {
                    return undefined
                }
            })?.filter((each) => each != undefined);
            return filterFroMArry;
        } else {
            return [];
        }
    }, [outletSummaryFields, outletStockSummaries])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setoutletSummaryFields({ ...outletSummaryFields, [name]: value })
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = PurchaseBillRegisterValidation(outletSummaryFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const stockSummaryDetails = await purchaseBillRegStore.getPurchaseBillRegDetails();
            setOutStockSummaryies([...stockSummaryDetails])
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Supplier_Name: stock?.supplierName,
                BillNo: stock?.bill_no,
                Bill_Date: moment(stock?.bill_date)?.format('DD-MMM-YYYY'),
                Bill_Value: stock?.bill_Amount,
                Tax: stock?.tax_per,
                Taxable_Value: stock?.taxable_Value,
                IGST: stock?.igsT_AMT,
                CGST: stock?.cgsT_AMT,
                SGST: stock?.sgsT_AMT,
                CESS: stock?.cess_AMT
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function findGrandTotal(array: Array<any>, key: string) {
        let totalValues = array?.reduce((a, v) => a = a + v[key]!, 0)
        return totalValues ?? 0
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {

        let lastRow = ['Grand Totals', '', '',
            findGrandTotal(excelArr, 'Bill_Value'), '', findGrandTotal(excelArr, 'Taxable_Value'),
            findGrandTotal(excelArr, 'IGST'), findGrandTotal(excelArr, 'CGST'),
            findGrandTotal(excelArr, 'SGST'), findGrandTotal(excelArr, 'CESS')]
        const csvContent = ["Purchase Bill Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(',')), lastRow].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Purchase Bill Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function calcSalesTotal(purchaseBillReg: any[], type: string) {
        if (type == 'bill_Amount') {
            const totalSaleQty = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.bill_Amount ?? 0), 0)
            return totalSaleQty;
        } else if (type == 'taxable_Value') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.taxable_Value ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'igsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.igsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'cgsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.cgsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'sgsT_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.sgsT_AMT ?? 0), 0)
            return totalSaleValue;
        } else if (type == 'cess_AMT') {
            const totalSaleValue = purchaseBillReg?.reduce((acc, obj: any) => acc + (obj.cess_AMT ?? 0), 0)
            return totalSaleValue;
        }
    }

    async function fetchOutletStockSummary() {
        handleGenerateStock()
        setLoading(false)
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletStockSummary()
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (outletStockSummaries?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(outletStockSummaries)
        }
    }, [outletStockSummaries])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(outletStockSummaries, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    function handleFindDate(date) {
        if (outletSummaryFields?.fromDate && outletSummaryFields?.toDate) {
            const yesterday = new Date(outletSummaryFields?.fromDate);
            yesterday.setDate(yesterday.getDate() - 1)
            if (new Date(date) >= yesterday && new Date(date) < new Date(outletSummaryFields?.toDate)) {
                return true;
            } else {
                return false
            }
        } else if (outletSummaryFields?.fromDate) {
            if (new Date(date) > new Date(outletSummaryFields?.fromDate)) {
                return true
            } else {
                return false
            }
        } else if (outletSummaryFields?.toDate) {
            if (new Date(date) < new Date(outletSummaryFields?.toDate)) {
                return true
            } else {
                return false
            }
        }
    }


    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '80%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Purchase Bill Register</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input type="date" style={{ width: '100%' }}
                                                    name="fromDate"
                                                    onChange={handleInputChange} value={outletSummaryFields?.fromDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={outletSummaryFields?.toDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-2" style={{ marginTop: '24px', paddingLeft: "65px" }}>
                                            <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(outletStockSummaries)}>Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletStockSummaries?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope='col'>Supplier Name</th>
                                                <th scope="col">Bill No</th>
                                                <th scope="col">Bill Date</th>
                                                <th scope="col">Bill Value</th>
                                                <th scope='col'>Tax %</th>
                                                <th scope="col">Taxable Value</th>
                                                <th scope="col">IGST</th>
                                                <th scope="col">CGST</th>
                                                <th scope="col">SGST</th>
                                                <th scope="col">CESS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : currentPageData)?.length > 0 &&
                                                ((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : currentPageData)?.map((stock, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td scope="col" className='capitalize'>{stock?.supplierName}</td>
                                                            <td scope="col">{stock?.bill_no}</td>
                                                            <td scope='col'>{moment(stock?.bill_date)?.format('DD-MMM-YY')}</td>
                                                            <td scope="col">{stock?.bill_Amount}</td>
                                                            <td scope='col'>{stock?.tax_per} %</td>
                                                            <td scope="col">{stock?.taxable_Value}</td>
                                                            <td scope="col">{stock?.igsT_AMT}</td>
                                                            <td scope="col">{stock?.cgsT_AMT}</td>
                                                            <td scope="col">{stock?.sgsT_AMT}</td>
                                                            <td scope="col">{stock?.cess_AMT}</td>
                                                        </tr>
                                                    )
                                                })}
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'bill_Amount')}</td>
                                                <td></td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'taxable_Value')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'igsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'cgsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'sgsT_AMT')}</td>
                                                <td>{calcSalesTotal((outletSummaryFields?.fromDate && outletSummaryFields?.toDate) ? filteredData : outletStockSummaries, 'cess_AMT')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default PurchaseBillRegister;