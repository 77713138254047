
function designationValidation(designationDetails){
    let errors :any={};
    
      if(!designationDetails.designationName){
        errors.designationName = "Enter Designation Name";
      }
      // if(!designationDetails.description){
      //   errors.description = "Enter Designation Description";
      // }
    return errors;
  };
    
    export default designationValidation;