import { action } from 'mobx';
function userCreationValidation(UserCreationDetails) {


  let errors: any = {};

  if (!UserCreationDetails.Code) {
    errors.Code = "Enter Code";
  }
  if (UserCreationDetails.Staff) {
    if (!UserCreationDetails.employeeId) {
      errors.Employee_name = "Select Employee Name";
    }
  }
  if (!UserCreationDetails?.Staff && !UserCreationDetails?.Management) {
    errors.options = 'Select Management or Staff'
  }
  if (!UserCreationDetails.User_name) {
    errors.User_name = "Enter User Name";
  }
  if (!UserCreationDetails.Password) {
    errors.Password = "Enter Password";
  }

  return errors;
};

export default userCreationValidation;