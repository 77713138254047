import { observable } from "mobx";
export interface ITerminalCreation{
    terminalId?:number;
    terminalNo?:number;
    outletId?:number;
    counterNumber?:string;
    terminalName?:string;
    brand?:string;
    installationDate?:string;
    outletName?:string;    
}

export interface ITerminalSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const TerminalCreationDetails=observable<ITerminalCreation>({
    terminalId:0,
    terminalNo:0,
    outletId:0,
    counterNumber:'',
    terminalName:'',
    brand:'',
    installationDate:'',
    outletName:''
    
});

export default TerminalCreationDetails;