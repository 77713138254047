import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import assignBatchPackingDetails, { IAssignBatchPacking } from '../model/index';


class AssignBatchPackingStore {
    @observable assignBatchPackingDetls = assignBatchPackingDetails;
    @observable assignBatchPackingList= new Map<number,IAssignBatchPacking>();
    isInwardLoding=true;
    getassignBatchPackings = new Map();
    getassignBatchPackingDetls = new Map();
    @observable entryNo= new Map();
     saveAssignBatchPacking = async (assignBatchPacking: IAssignBatchPacking) => {
        try {
          const status=  await apiAgent.assignBatchPacking.saveAssignBatchPacking(assignBatchPacking);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignBatchPacking=async ()=> {
        
        try {
          const getBatchPacking= await apiAgent.assignBatchPacking.getAssignBatchPacking();
            
            runInAction(() => {
                
                this.getassignBatchPackings = getBatchPacking;
            });
            return this.getassignBatchPackings;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignBatchPackingDetails=async (packingAssignBatchid)=> {
        
        try {
          const getBatchs= await apiAgent.assignBatchPacking.getAssignBatchPackingDetails(packingAssignBatchid);
            
            runInAction(() => {
                
                this.getassignBatchPackingDetls = getBatchs;
            });
            return this.getassignBatchPackingDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.assignBatchPacking.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getAssignBatchPackingSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.assignBatchPacking.getAssignBatchPackingSearch(SearchOptions);
            this.getassignBatchPackings = data;
            
            runInAction(() => {
                this.getassignBatchPackings = data;
                
            })
            return this.getassignBatchPackings;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignBatchPackingDetail = async (packingAssignBatchdid) => {
        try {      
        const status=  await apiAgent.assignBatchPacking.deleteAssignBatchPackingDetail(packingAssignBatchdid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignBatchPackingMaster = async (packingAssignBatchid) => {
        try {      
        const status=  await apiAgent.assignBatchPacking.deleteAssignBatchPackingMaster(packingAssignBatchid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default AssignBatchPackingStore;