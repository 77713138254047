import React, { useEffect, useMemo, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import { Autocomplete, Pagination, Stack, TextField } from "@mui/material";
import moment from "moment";
import { currentPageDatas, getCurrentMonthFirstDate } from "../../common/shared/utils";

interface ExcelSheetData {
    ProductCode: string, ProductName: string, GroupName: string, Location: string, UOM: string, Barcode: string, BatchNo: string, MRP: string, PurchaseRate: string, MarginPer: string,
    MarginAmount: string, SellingRate: string, DiscountPer: string, Discount: string, IsActive: string, UpdatedBy: string, UpdatedDate: string
}

const tableHeadings = ["ProductCode", "ProductName", "GroupName", "Location", "UOM", "Barcode", "BatchNo", "MRP", "PurchaseRate", "MarginPer",
    "MarginAmount", "SellingRate", "DiscountPer", "Discount", "IsActive", "UpdatedBy", "UpdatedDate"];
const BarcodePriceHistory = () => {

    const { barcodePriceHistoryStore, commonStore } = useStores();
    const { loadOutlet, loadOutlets, } = commonStore;

    const [priceHistoryList, setPriceHistoryList] = useState<any[]>([])

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})
    const [localFields, setLocalFields] = useState<any>({})
    const [searchValue, setSearchValue] = useState('');

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && priceHistoryList?.length > 0) {
            return priceHistoryList?.filter((priceHistory) => {
                if (priceHistory?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return priceHistory;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value });
    }

    const handleOutletValue = (name: any, event: object, val: any) => {
        if (val != null) {
            setLocalFields({ ...localFields, [name]: val.outletid });
        }
        else {
            localFields.outletId = 0;
        }
    }

    async function handleGenerateStock() {

        setLoading(true)
        let error: any = {}
        if (!localFields?.fromDate) {
            error.fromDate = "Enter From Date"
        }
        if (!localFields?.toDate) {
            error.toDate = "Enter To Date"
        }
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            const barcodePriceHistoryList = await barcodePriceHistoryStore.loadBarcodePriceHistory(localFields)
            setPriceHistoryList(barcodePriceHistoryList);

        }

        setLoading(false)

    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                ProductCode: stock?.productcode, ProductName: stock?.itemname,
                GroupName: stock?.groupname, Location: stock?.outletname, UOM: stock?.unitofmesurement,
                Barcode: stock?.barcode, BatchNo: stock?.batchNo, MRP: stock?.mrp,
                PurchaseRate: stock?.purchaserate, MarginPer: stock?.marginper, MarginAmount: stock?.marginamount,
                SellingRate: stock?.sellingrate, DiscountPer: stock?.discountper, Discount: stock?.discountamount,
                IsActive: stock?.isactive, UpdatedBy: stock?.updateby, UpdatedDate: stock?.updatedate,
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Barcode Price History", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Barcode Price History ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchBarcodePriceHistory() {
        await loadOutlet();
        setLoading(false)
    }

    useEffect(() => {
        fetchBarcodePriceHistory()
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])


    useEffect(() => {
        if (priceHistoryList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(priceHistoryList)
        }
    }, [priceHistoryList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(priceHistoryList, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '100%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Barcode Price History</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input type="date" style={{ width: '100%' }}
                                                    name="fromDate"
                                                    onChange={handleInputChange} value={localFields.fromDate = localFields?.fromDate ?? getCurrentMonthFirstDate()?.firstDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={localFields.toDate = localFields?.toDate ?? getCurrentMonthFirstDate()?.currentDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                    style={{ height: "51px" }}
                                                    name='searchValue'
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    className="form-control" placeholder="Search Here.."
                                                    aria-label="Search Outlet..."
                                                    aria-describedby="search-button"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={loadOutlets}
                                                    getOptionLabel={(option: any) => option.outletName}
                                                    onChange={(event, val) => handleOutletValue('outletId', event, val)}
                                                    value={loadOutlets.find((option) => option.outletId == localFields?.outletId)}
                                                    renderInput={(params: any) =>
                                                        <TextField {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Outlet Name..'
                                                            name='outletName'
                                                        />
                                                    }
                                                />
                                            </div>
                                            {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                                        </div>
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={handleGenerateStock}
                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={() => createExportExcelObj(priceHistoryList)}
                                            >Excel</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{priceHistoryList?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>

                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {tableHeadings?.map((heading, index) => {
                                                    return (
                                                        <th key={index}>{heading}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(searchValue?.length > 0 ? filteredData : currentPageData)?.length ? (searchValue?.length > 0 ? filteredData : currentPageData)?.map((priceHistory, index) => {
                                                return (
                                                    <tr>
                                                        <td>{priceHistory?.productcode}</td>
                                                        <td>{priceHistory?.itemname}</td>
                                                        <td>{priceHistory?.groupname}</td>
                                                        <td>{priceHistory?.outletname}</td>
                                                        <td>{priceHistory?.unitofmesurement}</td>
                                                        <td>{priceHistory?.barcode}</td>
                                                        <td>{priceHistory?.batchNo}</td>
                                                        <td>{priceHistory?.mrp}</td>
                                                        <td>{priceHistory?.purchaserate}</td>
                                                        <td>{priceHistory?.marginper}</td>
                                                        <td>{priceHistory?.marginamount}</td>
                                                        <td>{priceHistory?.sellingrate}</td>
                                                        <td>{priceHistory?.discountper}</td>
                                                        <td>{priceHistory?.discountamount}</td>
                                                        <td>{priceHistory?.isactive}</td>
                                                        <td>{priceHistory?.updateby}</td>
                                                        <td>{priceHistory?.updatedate}</td>
                                                    </tr>
                                                )
                                            }) : <div>No data found</div>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default BarcodePriceHistory;