import React, { useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import { Accordion } from "react-bootstrap";
import { Autocomplete, TextField, Checkbox } from "@mui/material";

const PunchingRegister = () => {
    const employee = [{ key: 'name', value: 'name' }, { key: 'name', value: 'name' }, { key: 'name', value: 'name' }]
    const currentYear = new Date().getFullYear();

    const yearsArray = Array.from({ length: 10 }, (_, index) => {
        const year = currentYear + index;
        return { key: year.toString(), value: year.toString() };
    });
    const months = [
        { key: "january", value: "January" },
        { key: "february", value: "February" },
        { key: "march", value: "March" },
        { key: "april", value: "April" },
        { key: "may", value: "May" },
        { key: "june", value: "June" },
        { key: "july", value: "July" },
        { key: "august", value: "August" },
        { key: "september", value: "September" },
        { key: "october", value: "October" },
        { key: "november", value: "November" },
        { key: "december", value: "December" }
    ];

    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [category, setCategory] = useState<any>({})
    const handleFailureModalClose = () => setFailureModal(false);
    const [onOpenDetail, setOnOpenDetail] = useState(false)

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
    }
    async function handleSaveCategory() {

    }

    function handleClickDetail() {
        setOnOpenDetail(true)
    }

    function handleChangeInput(label, value) {
        setCategory({ ...category, [label]: value })
    }


    function handleToSelectionApprovalList() {
        navigate('/Admin/SelectionApproval/SelectionApprovalList')
    }
    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{width:"50%"}} >
                            <div className='hrBox'>
                                <h3>Punching Report</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{fontSize:'16px'}}>From Date</label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="fromdate"
                                            value={new Date().toISOString().substr(0, 10) ?? new Date()}
                                            // onChange={(evt) => {
                                            //     handleChangeInput("outDate", evt.target.value);
                                            // }}
                                        ></input>
                                        {errors.brandCode && (
                                            <p style={{ color: "red" }}>{errors.brandCode}</p>
                                        )}
                                    </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{fontSize:'16px'}}>To Date</label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="fromdate"
                                            value={new Date().toISOString().substr(0, 10) ?? new Date()}
                                            // onChange={(evt) => {
                                            //     handleChangeInput("outDate", evt.target.value);
                                            // }}
                                        ></input>
                                        {errors.brandCode && (
                                            <p style={{ color: "red" }}>{errors.brandCode}</p>
                                        )}
                                    </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4"></div>
                                    <div className="col-sm-6">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>
                                                Employee<span>*</span>
                                            </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employee}
                                                getOptionLabel={(option: any) => option.value}
                                                // onChange={(event, val) => handleReceiptLedgerGroup('ledgerGroupId', event, val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Name..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-6">
                                    <div className="" style={{marginTop:'30px'}}>
                                        {/* <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                            View
                                        </button> */}
                                        <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                            Print
                                        </button>
                                        <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                            Clear 
                                        </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            {/* <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Employee Code</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col" className=""><div>D.O.B</div><div>D.O.J</div></th>
                                            <th scope="col" className=""><div>GR</div><div>Sft</div></th>
                                            {[1,2,3,4,5,6]?.map((each)=>{return(<th scope="col" className=""><div>{each}</div><div>{'S'}</div></th>)})}
                                            <th scope="col">PR</th>
                                            <th scope="col">SH</th>
                                            <th scope="col">AB</th>
                                            <th scope="col">WO</th>
                                            <th scope="col">FH</th>
                                            <th scope="col">NH</th>
                                            <th scope="col">CL</th>
                                            <th scope="col">EL</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">Total Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Employee Code</td>
                                            <td>Employee Name</td>
                                            <td className=""><div>12-Apr-2001</div><div>12-Apr-2024</div></td>
                                            <td className=""><div>I</div><div>II</div></td>
                                            {[1,2,3,4,5,6]?.map((each)=>{return(<td className=""><div>{each}</div><div>{'S'}</div></td>)})}
                                            <td>5</td>
                                            <td>1</td>
                                            <td>5</td>
                                            <td>1</td>
                                            <td>5</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>1</td>
                                            <td>31</td>
                                           
                                        </tr>

                                    </tbody>
                                </table>
                            </div> */}
                            {/* <div className='btnBox'>
                                <button className='secondaryBtn' onClick={() => { handleToSelectionApprovalList() }}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div> */}
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            {/* <Image src={require('../../../../image/checked.png')} /> */}
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            {/* <Image src={require('../../../../image/warning.png')} /> */}
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default PunchingRegister;