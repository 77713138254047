import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { IILeaveReqApproval } from "./model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPenToSquare, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { currentPageDatas, getCurrentMonthFirstDate } from "../../common/shared/utils";
import SelectSearchCriteria from "../../common/shared/selectSearchCriteria";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";

const LeaveApprovalList = () => {

    const { employeeStore, payrollStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [leaveReqApprovedList, setLeaveReqApprovedList] = useState<any[]>([])

    const [searchFields, setSearchFields] = useState<any>({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    function handleSearchInputChange(event) {
        const { name, value } = event.target;
        setSearchFields({ ...searchFields, [name]: value })
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
        setSuccessModal(false)
    }

    const handleCreate = () => {
        navigate('/Admin/LeaveApproval')
    }

    async function additionalApis() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
    }

    async function initialApiCall() {
        const userRights = await userCreationStore?.getUserRight('Leave_approval')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const leaveReqList = await payrollStore.loadLeaveRecordsDetail()
        setLeaveReqApprovedList([...leaveReqList])
        setLoading(false)
        additionalApis()
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (leaveReqApprovedList && leaveReqApprovedList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(leaveReqApprovedList)
        }
    }, [leaveReqApprovedList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(leaveReqApprovedList?.slice()?.sort((a, b) => b.Loanid - a.Loanid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    function handleFindDates(fromDate, toDate) {
        if (searchFields?.fromDate && searchFields?.toDate) {
            const yesterday = new Date(searchFields?.fromDate);
            if (new Date(fromDate) >= yesterday && new Date(toDate) <= new Date(searchFields?.toDate) && new Date(fromDate) <= new Date(toDate)) {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Leave Approved List</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="fromDate" value={searchFields.fromDate} onChange={handleSearchInputChange}
                                            ></input>
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>To Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="toDate" value={searchFields.toDate} onChange={handleSearchInputChange}
                                            ></input>
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={leaveReqApprovedList}
                                        searchTitles={[{ key: "Employee_Name", value: "Employee Name" }, { key: "levtype", value: "Leave Type" }]}
                                        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)}
                                        isEmitClear emitClear={() => setSearchFields({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })} />
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{leaveReqApprovedList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">From Date</th>
                                            <th scope="col">To Date</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Leave type</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((each, index) => {
                                                return (((searchFields?.fromDate && searchFields?.toDate) ? handleFindDates(each?.LR_FromDate, each?.LR_ToDate) : each?.LR_FromDate) && each?.LR_Appstatus == 'Yes') && (
                                                    <tr key={index}>
                                                        <td style={{ textTransform: 'capitalize' }}>{each?.LR_MDNO}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{moment(each?.LR_FromDate)?.format('DD-MMM-YYYY')}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{moment(each?.LR_ToDate)?.format('DD-MMM-YYYY')}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{employeeMaster?.find((obj) => obj?.EmployeeId == each?.LR_EmpID)?.Employee_Name}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{each?.LR_LevType}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{each?.LR_Reason}</td>
                                                        <td style={{ textTransform: 'capitalize' }}>{each?.LR_ApprovedBy}</td>
                                                        <td><button disabled={!userRights?.add} onClick={handleCreate} className='Create'><FontAwesomeIcon icon={faAdd} /></button></td>
                                                        <td><button disabled={!userRights?.edit} onClick={() => { }} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                        <td><button disabled={!userRights?.delete} onClick={() => { }} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>

                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />/
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </div>
    )
}

export default LeaveApprovalList;