export class IISalesOrder {
    sOrdMDate?: string = new Date()?.toISOString()?.substr(0, 10)
    sOrdMCustID?: number = 0
    sOrdMCustName?: string = ""
    sOrdMDoorNo?: string = ""
    sOrdMArea?: string = ""
    sOrdMCity?: string = ""
    sOrdCusType?: string = ""
    sOrdMState?: string = ""
    sOrdMPincode?: string = ""
    sOrdMConactNo?: string = ""
    salesorderdet?: IISalesOrderDet[] = []
    salesorderledger?: IISalesOrderLedger[] = []
}

export class IISalesOrderDet {
    sOrdDItemID?: string = ""
    sOrdDItemName?: string = "" // optional
    sOrdDQty?: string = ""
    sOrdDMRP?: string = ""
    sOrdDRate?: string = ""
    sOrdDDis?: string = ""
    sOrdDGSTAmt?: string = ""
    sOrdDGST?: string = ""
    sOrdDAmt?: string = ""
}

export class IISalesOrderLedger {
    saleOrdLedgerId?: number = 0
    saleOrdLedgername?: string = ""
    saleOrdLedgerAmount?: number = 0
}