import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import moment from "moment";

class DailyAttendanceProps {
    sdate: string = getCurrentMonthFirstDate()?.firstDate;
    edate: string = getCurrentMonthFirstDate()?.currentDate;
    empId: number = 0
}

const DailyAttendanceReport = () => {

    const { hrReporsStore, employeeStore } = useStores()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true)
    const [isSpinner, setSpinner] = useState(false);
    const [errors, setErrors] = useState<any>({});

    const [localFields, setLocalFields] = useState<DailyAttendanceProps>(new DailyAttendanceProps())

    const [attendanceReport, setAttendanceReport] = useState<Map<string, any[]>>()

    async function convertObj() {
        if (localFields?.sdate && localFields?.edate) {
            setSpinner(true)
            const dailyAttendance = await hrReporsStore.getReporDailyAttendence(localFields?.sdate, localFields?.edate, localFields?.empId)
            if (dailyAttendance?.length) {
                let convertedObj = convertArrayToMap(dailyAttendance)
                setAttendanceReport(convertedObj)
            }
            setSpinner(false)
        }
    }

    const convertArrayToMap = (students: any[]): Map<string, any[]> => {
        return students.reduce((map, student) => {
            if (!map.has(student?.AttnDate)) {
                map.set(student?.AttnDate, []);
            }
            map.get(student?.AttnDate)!.push(student);
            return map;
        }, new Map<string, any[]>());
    };

    async function initialApiCall() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        convertObj()
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField' >
                            <div className='hrBox'>
                                <h3>Daily Attendance Report</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="sdate"
                                                value={localFields?.sdate}
                                                onChange={(event) => setLocalFields({ ...localFields, sdate: event?.target?.value })}
                                            ></input>
                                            {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>To Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="edate"
                                                value={localFields?.edate}
                                                onChange={(event) => setLocalFields({ ...localFields, edate: event?.target?.value })}
                                            ></input>
                                            {errors.edate && <p style={{ color: "red" }}>{errors.edate}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}> Employee<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={employeeMaster} value={employeeMaster?.find((each) => each?.EmployeeId === localFields?.empId)?.Employee_Name}
                                                getOptionLabel='Employee_Name' emitOption={(option) => setLocalFields({ ...localFields, empId: option?.EmployeeId })} placeholder='Select Name..'
                                            />
                                        </div>
                                        {errors.empId && <p style={{ color: "red" }}>{errors.empId}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="" style={{ marginTop: '27px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={convertObj}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Print
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Employee Name</th>
                                            <th>In time</th>
                                            <th>Out time</th>
                                            <th>Break Out time</th>
                                            <th>Break In time</th>
                                            <th>Total time</th>
                                            <th>OT Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceReport != undefined &&
                                            [...attendanceReport!.entries()!].map(([AttnDate, studentDetails]) => (
                                                <>
                                                    <div style={{ borderBottom: '1px solid #dee2e6', padding: '8px', color: 'black', fontSize: '15px', fontWeight: '600' }}>{moment(AttnDate)?.format('DD-MMM-YYYY')}</div>
                                                    <>
                                                        {studentDetails.map((student, index) => {
                                                            return (!localFields?.empId || localFields?.empId === student?.Empid) && (
                                                                <tr key={index}>
                                                                    <td>{student?.Employee_Name}</td>
                                                                    <td>{student?.InTime ? moment(student?.InTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                                    <td>{student?.OutTime ? moment(student?.OutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                                    <td>{student?.BreakOutTime ? moment(student?.BreakOutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                                    <td>{student?.BreakInTime ? moment(student?.BreakInTime, "HH:mm:ss")?.format('hh:mm a') : '00:00'}</td>
                                                                    <td>{student?.Totaltime ? moment(student?.Totaltime, "HH:mm:ss")?.format('hh:mm') : '00:00'}</td>
                                                                    <td>{student?.OThours}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </>
                                                </>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}

export default DailyAttendanceReport;