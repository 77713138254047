
function ledgerMasterValidation(ledgerMaster){
  let errors :any={};
  
   
  
    if(!ledgerMaster.ledgerName){
      errors.ledgerName = "Enter Ledger Name";
    }
    if(!ledgerMaster.ledgerGroup){
      errors.ledgerGroup = "Select Group Name";
    }
    if(!ledgerMaster.openingBalance){
      errors.openingBalance = "Enter Opening Balance";
    }
     if(!ledgerMaster.type){
      errors.type = "Select Opening Type";
    }
   if(!ledgerMaster.entryDate){
      errors.entryDate = "Select Date";
    }
    if(!ledgerMaster.percentage){
      errors.percentage = "Enter Percentage";
    }
  return errors;
};
  
  export default ledgerMasterValidation;