export class IIBrakInOutEntry {
    biO_ID?: number = 0
    biO_EntryNo?: string = ""
    biO_Date?: string = new Date().toISOString().substr(0, 10)
    biO_EmpID?: number = 0
    employeebreakinoutdet?: IIBreakinoutEmployeeDet[] = []
}

export class IIBreakinoutEmployeeDet {
    biD_MID?: number = 0
    biD_Date?: string = new Date().toISOString().substr(0, 10)
    biD_EmpID?: number = 0
    biD_OutTime?: string = ""
    biD_InDate?: string = new Date().toISOString().substr(0, 10)
    biD_InTime?: string = ""
}