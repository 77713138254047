import { useEffect, useRef, useState } from "react";
import ProgressBar from '../../common/shared/progressbar';
import AutoComplete from "../../common/shared/autoComplete";
import { months, yearsArray } from "../json";
import useStores from "../../hooks";
import { Spinner } from "react-bootstrap";

class DailyAttendanceProps {
    month: number = months[new Date()?.getMonth()]?.month
    year: string = yearsArray?.find((each) => each?.key === new Date()?.getFullYear()?.toString())?.value!
    category: string = ""
    departementId: number = 0
    department: string = ""
}

const AttendanceRegister = () => {

    const { hrReporsStore, payrollStore, departmentStore } = useStores()

    const [categoryList, setCategoryList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSpinner, setSpinner] = useState(false);

    const [localFields, setLocalFields] = useState<DailyAttendanceProps>(new DailyAttendanceProps())

    const [attendanceReport, setAttendanceReport] = useState<any[]>([])
    const [groupedItems, setGroupedItems] = useState<{ [key: string]: any[] }>({})

    const filterAndGroupAttendance = (
        data: any[],
        filters: DailyAttendanceProps
    ) => {
        let filteredData = data;

        if (filters?.category) {
            filteredData = filteredData?.filter(
                (item) => item?.Category === filters?.category
            );
        }

        if (filters?.department) {
            filteredData = filteredData?.filter(
                (item) => item?.department === filters?.departementId
            );
        }

        const groupedData: { [key: string]: any[] } = {};

        if (filters?.category && filters?.department) {
            filteredData?.forEach((item) => {
                const key = `${item.Category}/${item.departmentName}`;
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(item);
            });
        } else if (filters?.department) {
            filteredData?.forEach((item) => {
                const key = item.departmentName;
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(item);
            });
        } else {
            filteredData?.forEach((item) => {
                const key = item.Category;
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(item);
            });
        }

        return groupedData;
    };

    useEffect(() => {
        if (attendanceReport?.length) {
            const groupedData = filterAndGroupAttendance(attendanceReport, localFields)
            setGroupedItems({ ...groupedData })
            console.log(groupedData, 'groupedData>>>')
        }
    }, [attendanceReport?.length, localFields?.category, localFields?.departementId])

    async function fetchdata(departmentList) {
        setSpinner(true)
        const attendReg = await hrReporsStore?.getReportAttendenceRegister(localFields?.month, localFields?.year);
        if (attendReg?.length) {
            attendReg?.forEach((each) => {
                let findDepartment = departmentList?.find((obj) => obj?.departmentId === each?.department);
                each['departmentName'] = findDepartment?.department;
                return each
            })
            setAttendanceReport([...attendReg]);
        }
        setSpinner(false)
    }

    async function initialApicalls() {
        const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
        const departmentList = await departmentStore.getDepartment();
        setCategoryList([...categoryMasterDetails])
        setDepartmentList([...departmentList])
        fetchdata(departmentList);
        setLoading(false);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage?.current) {
            initialApicalls()
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField' >
                            <div className='hrBox'>
                                <h3>Attendance Register</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Year<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={yearsArray} value={localFields?.year} getOptionLabel='value'
                                                emitOption={(option) => setLocalFields({ ...localFields, year: option?.value })} placeholder='Select Year..'
                                            />
                                        </div>
                                        {errors?.year && <p style={{ color: 'red' }}>{errors?.year}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Month<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={months} value={months?.find((each) => each?.month === localFields?.month)?.value} getOptionLabel='value'
                                                emitOption={(option) => setLocalFields({ ...localFields, month: option?.month })} placeholder='Select Month..'
                                            />
                                        </div>
                                        {errors?.month && <p style={{ color: 'red' }}>{errors?.month}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Category<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={categoryList} value={localFields?.category} getOptionLabel='Emp_Category_Name'
                                                emitOption={(option) => setLocalFields({ ...localFields, category: option?.Emp_Category_Name })} placeholder='Select Category..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Department</label>
                                            <AutoComplete clsName="full-width" options={departmentList} value={localFields?.department} getOptionLabel='department'
                                                emitOption={(option) => setLocalFields({ ...localFields, departementId: option?.departmentId, department: option?.department })} placeholder='Select department..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => fetchdata(departmentList)}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Excel
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => setLocalFields(new DailyAttendanceProps())}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Employee ID</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Present</th>
                                            <th scope="col">OT</th>
                                            <th scope="col">Total</th>
                                            <th scope="col">Leave Days</th>
                                            {/* <th scope="col" className=""><div>D.O.B</div><div>D.O.J</div></th>
                                            <th scope="col" className=""><div>GR</div><div>Sft</div></th> */}
                                            {/* {[1,2,3,4,5,6]?.map((each)=>{return(<th scope="col" className=""><div>{each}</div><div>{'S'}</div></th>)})} */}
                                            {/* <th scope="col">PR</th>
                                            <th scope="col">SH</th>
                                            <th scope="col">AB</th>
                                            <th scope="col">WO</th>
                                            <th scope="col">FH</th>
                                            <th scope="col">NH</th>
                                            <th scope="col">CL</th>
                                            <th scope="col">EL</th>
                                            <th scope="col">SL</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(groupedItems).map(key => (
                                            <>
                                                <h2>{key}</h2>
                                                {groupedItems[key].map((item, index) => (
                                                    <tr key={item?.empId}>
                                                        <td>{index + 1}</td>
                                                        <td>{item?.empid}</td>
                                                        <td>{item?.empname}</td>
                                                        <td>{item?.designation}</td>
                                                        <td>{item?.PR}</td>
                                                        <td>{item?.OT}</td>
                                                        <td>{item?.TOTAL}</td>
                                                        <td>{item?.TOTLEAVE}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="vertical-space-20"></div>
                        </div>
                    </>
            }

        </div>
    )
}

export default AttendanceRegister;