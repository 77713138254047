
function StockSummaryValidation(stockSummary) {
    let errors: any = {};

    if (!stockSummary.fromDate) {
        errors.fromDate = "Enter From Date";
    }
    if (!stockSummary.toDate) {
        errors.toDate = "Enter To Date";
    }
    if(!stockSummary.warehouseId) {
        errors.warehouseId = "Select Warehouse"
    }
    return errors;
};

export default StockSummaryValidation;