import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { IManageLoyaltySearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';

const ManageLoyaltyList = observer((): JSX.Element => {
  const { manageLoyaltyStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getManageLoyalty, getmanageLoyaltys, getmanageLoyaltyDetls } = manageLoyaltyStore;
  const [isLoading, setLoading] = useState(true);
  const [isManageConversionModal, setManageConversionModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [filteredDataDet, setFilteredDataDet] = useState<any[]>([])
  const deleteManageLoyalty = async (manageLoyaltyId) => {
    debugger
    setLoading(true);
    const status = await manageLoyaltyStore.deleteManageLoyalty(manageLoyaltyId);

    if (status === 'Success') {
      ManageLoyaltyDetail();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteManageLoyaltyDetail = async (manageLoyaltyDetailId) => {
    setManageConversionModal(false);
    setLoading(true);
    const status = await manageLoyaltyStore.deleteManageLoyaltyDetail(manageLoyaltyDetailId);

    if (status === 'Success') {
      ManageLoyaltyDetail();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
  }

  async function ManageLoyaltyDetail() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Manage_loyalty')])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const getManageMst = await getManageLoyalty(0); 
    setCurrentPageData(removeDuplicates(getManageMst,'mlslno'))
  }

  const getOfferDiscountDetails = async (id) => {
    const getManageDet = await getManageLoyalty(id);
    setCurrentPageDataDet(getManageDet);
    setManageConversionModal(true);
  }

  const handleCreate = () => {
    navigate('/Admin/ManageLoyalty');
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      ManageLoyaltyDetail();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const handleClose = () => {
    setManageConversionModal(false);
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])
  console.log(currentPageData, 'currentPageData');

  const [currentPageDataDet, setCurrentPageDataDet] = useState<any[]>([])

  // useEffect(() => {
  //   if (getmanageLoyaltys && getmanageLoyaltys?.length) {
  //     goToPage(1)
  //   } else {
  //     setCurrentPageData(removeDuplicates(getmanageLoyaltys,'mlslno'))
  //   }
  // }, [getmanageLoyaltys])

  const editLoyalty = (loyalId) => {
    navigate('/Admin/ManageLoyalty/' + loyalId);
  }

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(getmanageLoyaltys?.slice()?.sort((a, b) => b.manageLoyaltyId - a.manageLoyaltyId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(removeDuplicates(currentPageList?.currentPageData,'mlslno'))
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>

              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getmanageLoyaltys}
                searchTitles={[{ key: "category", value: "Category" }]}
                emitSearchArray={(filterArray: any) => {
                  setFilteredData(removeDuplicates(filterArray,'mlslno'));
                }}
              />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getmanageLoyaltys?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th scope='col'>Entry No </th> */}
                      <th scope='col'>Category  </th>
                      {/* <th scope='col'>Bill From</th>
                      <th scope='col'>Bill To</th>
                      <th scope='col'>Points</th> */}
                      <th scope='col'> </th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{val.manageLoyaltyId}</td> */}
                            <td>{val.category}</td>
                            {/* <td>{val.billFrom}</td>
                            <td>{val.billTo}</td>
                            <td>{val.points}</td> */}
                            <td><button onClick={() => getOfferDiscountDetails(val.mlslno)}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Create' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button> </td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare}  onClick={() => editLoyalty(val.mlslno)}  /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteManageLoyalty(val.mlslno)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
              {
                isManageConversionModal ?
                  <Modal show={isManageConversionModal} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>

                              <th scope="col">Bill Value From</th>
                              <th scope="col">Bill Value To </th>
                              <th scope="col">Points</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {getmanageLoyaltyDetls?.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.billValueFrom}</td>
                                  <td>{val.billValueTo}</td>
                                  <td>{val.points}</td> */}
                            {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteManageLoyaltyDetail(val.manageLoyaltyDetailId)} /></button></td> */}
                            {/* </tr>
                              )
                            })
                            } */}
                            {(isSearch ? filteredDataDet : currentPageDataDet)?.length > 0 &&
                              (isSearch ? filteredDataDet : currentPageDataDet).map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.billFrom}</td>
                                    <td>{val.billTo}</td>
                                    <td>{val.points}</td>
                                  </tr>
                                )
                              }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default ManageLoyaltyList;
