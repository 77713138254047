
function itemConversionValidation(itemOfConversion,itemConversion){
    let errors :any={};
    
    
      debugger
      // if(!itemConversion.itemConversionDate){
      //   errors.itemConversionDate = "Select Date";
      // }
      if(!itemConversion.itemName){
        errors.itemId = "Select Item Name";
      }
        if(!itemConversion.itemUOM){
        errors.itemUOM = "Enter UOM";
      }
      if(!itemOfConversion.conversionItemId){
        errors.conversionItemId = "Select Conversion Item";
      }
      if(!itemOfConversion.conversionQty){
        errors.conversionQty = "Enter Conversion Quantity";
      }
      // if(!itemOfConversion.unitId){
      //   errors.unitId = "Select Conversion Item";
      // }
     
    
    return errors;
  };
    
    export default itemConversionValidation;