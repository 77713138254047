import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IInwardOutlet } from '../model';



class InwardOultetStore {
    @observable entryNo = new Map();
    @observable loadOutward = new Map();
    @observable loadOutwardDetails = new Map();
    @observable inwardOutltes = new Map();
    @observable inwardOutlteDetails = new Map();
    constructor() {
        makeObservable(this);
    }
    getEntryNo = async (flag) => {
        try {
            const entryNo = await apiAgent.InwardOutlet.getEntryNo(flag);

            runInAction(() => {
                this.entryNo = entryNo;
            })
            return entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLoadOutward = async (outletId) => {
        try {
            const outwards = await apiAgent.InwardOutlet.getLoadOutward(outletId);

            runInAction(() => {
                this.loadOutward = outwards;
            })
            return this.loadOutward;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLoadOutwardDetails = async (outwardId) => {
        try {

            const outwardDetails = await apiAgent.InwardOutlet.getLoadOutwardDetails(outwardId);

            runInAction(() => {
                this.loadOutwardDetails = outwardDetails;
            })
            return outwardDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardOutlet = async () => {
        try {
            const inwardOutlet = await apiAgent.InwardOutlet.getInwardOutlet();

            runInAction(() => {
                this.inwardOutltes = inwardOutlet;
            })
            return this.inwardOutltes;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardOutletDetails = async (InwardOutletId) => {
        try {
            const inwardOutletDetls = await apiAgent.InwardOutlet.getInwardOutletDetails(InwardOutletId);

            runInAction(() => {
                this.inwardOutlteDetails = inwardOutletDetls;
            })
            return this.inwardOutlteDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveInwardOutlet = async (inwardOutlets: IInwardOutlet) => {
        try {
            const status = await apiAgent.InwardOutlet.saveInwardOutlet(inwardOutlets);
            return status;

        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardOutletSearch = async (SearchOptions) => {
        try {
            const Data = await apiAgent.InwardOutlet.getInwardOutletSearch(SearchOptions);
            this.inwardOutltes = Data;

            runInAction(() => {
                this.inwardOutltes = Data;

            })
            return this.inwardOutltes;


        }
        catch (error) {
            console.log(error);
        }
    }
    deleteInwardOutletDetail = async (inwardOutletDetailId) => {
        try {
            const status = await apiAgent.InwardOutlet.deleteInwardOutletDetail(inwardOutletDetailId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteInwardOutletMaster = async (inwardOutletId) => {
        try {
            const status = await apiAgent.InwardOutlet.deleteInwardOutletMaster(inwardOutletId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default InwardOultetStore;