function rackAllotmentValidation(rackAllotment){
    let errors :any={};

    if(!rackAllotment.entryDate){
      
        errors.entryDate = "Enter Entry Date";
    }
    if(!rackAllotment.outletName){
      
        errors.outletName = "Select Outlet Name ";
    }
    if(!rackAllotment.rackNo){
      
        errors.rackNo = "Enter Rack No";
    }
    if(!rackAllotment.itemId){
      
        errors.itemId = "Select Item Name";
    }
    if(!rackAllotment.batchNo){
      
        errors.batchNo = "Select Batch No";
    }
    
    return errors;
};
export default rackAllotmentValidation