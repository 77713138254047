import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {

    const { t } = useTranslation();
    const dashBoardKeys: any = t("dashBoardKeys")

    const data = {
        labels: ['Dine In', 'Online', 'Take Way', 'Third Party', 'QR'],
        datasets: [
            {
                label: 'Order Type',
                data: [100, 0, 0, 0, 0],
                backgroundColor: ['royalblue', 'aqua', 'darkorange', 'red', 'lightslategray']
            }
        ],
        hoverOffset: 4
    }
    return (
        <div className="">
            <div className="" style={{ display: 'flex', borderBottom: '1px solid lightgray', padding: '10px', justifyContent: 'space-between' }}>
                <div className="" style={{ fontSize: '18px' }}>
                {dashBoardKeys?.orderTypeBasedOverview}
                </div>
                <div className="" style={{ border: '1px solid lightgray', borderRadius: '4px', padding: '10px' }}>
                    12/01/2023-12/31/2023
                </div>
            </div>
            <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="" style={{ padding: '30px', width: '80%' }}>
                    <Pie data={data} />
                </div>
            </div>
        </div>
    )
}

export default PieChart;