import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { filterItemsWithNumericKeys, initializeAttendanceForMonth } from "../../common/shared/utils";

interface ExcelSheetData {
  Employee_Code?: number;
  Ticket_No?: number;
  Employee_Name?: string;
  [key: number]: string;
  PR?: number;
  CL?: number;
  SL?: number;
  TL?: number;
  LOP?: number;
  WO?: number;
  PL?: number;
}

const AttendanceReport = () => {

  const { hrReporsStore, employeeStore } = useStores()

  const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

  const [isSpinner, setSpinner] = useState(false);

  const currentYear = new Date().getFullYear();

  const yearsArray = Array.from({ length: 10 }, (_, index) => {
    const year = currentYear + index;
    return { key: year.toString(), value: year.toString() };
  });
  const months = [
    { key: "january", value: "January", month: 1, },
    { key: "february", value: "February", month: 2, },
    { key: "march", value: "March", month: 3, },
    { key: "april", value: "April", month: 4, },
    { key: "may", value: "May", month: 5, },
    { key: "june", value: "June", month: 6, },
    { key: "july", value: "July", month: 7, },
    { key: "august", value: "August", month: 8, },
    { key: "september", value: "September", month: 9, },
    { key: "october", value: "October", month: 10, },
    { key: "november", value: "November", month: 11, },
    { key: "december", value: "December", month: 12, }
  ];

  const navigate = useNavigate();

  const [errors, setErrors] = useState<any>({});

  const [isLoading, setLoading] = useState(true);

  const objectRef = useRef<any>({});

  async function handlePrint(val: any) {
    const getOutletDCData = []
    objectRef.current = getOutletDCData;
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(
        `
            <html>
              <head>
               <title style="text-align: center ; padding-right: 100px;"  class="centered-div">Download </title>
                <style>
                  /* Add any additional styles here */
                  .table {
                    /* Add table styles here */
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                  }
                  th, td {
                    /* Add th and td styles here */
                    // border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                  }
                  th {
                    /* Add th styles here */
                    background-color: #f2f2f2;
                  }
                  body {
                    margin: 0;
                    height: 100vh;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                  }
                  .container {
                   display:flex;
                  }
                
                  .column {
                    flex: 1;
                    padding: 10px;
                    border: 1px solid #ccc;
                  }
                
                  .row {
                    margin-bottom: 10px;
                  }
              
                  .centered-div {
                    text-align: center;
                  }
                  
                  .right-div {
      
                  }
                  .border-top {
                    border-top: 1px solid #ddd; /* Add top border to the element with this class */
                }
                .border-bottom {
                    border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
                }
                .border-right {
                  border-right: 1px solid #ddd; /* Add top border to the element with this class */
              }
              .border-left {
                  border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
              }
              .border{
                /* border: 1px solid #ddd;*/
              }
              .content {
                padding: 20px;
                page-break-before: always; /* Ensure Content 1 starts on a new page */
            }
            @page {
              size: auto;
              margin: 0mm;
              border: 2px solid #ddd;
          }
                </style>
              </head>
              <body >
              <div class = "border content">
                <div class="" style="border: 1px solid #ccc;">
                   <div style="height: 25px;"></div>
                   <div class="centered-div" style="font-weight:600">Ganga Super Market</div>
                   <div class="centered-div" style="margin-top:20px">Address: John Smith,123 Main Street,Cityville, State 12345 ,Country</div>
                   <div style="height: 25px;"></div>
                </div>
                <div class="container" style="display: flex; flex-direction: row ; justify-content: space-between; align-items: center; border: 1px solid #ccc; padding:20px">
                  <div class="">Period From: 1-Apr-2024</div>
                  <div class="">Period To: 31-Apr-2024</div>
                </div>
                <div class="container">
                  <div class="column">
                     <div class="row" style="font-weight:600">Employee Code</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">Employee ID</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">Employee Name</div>
                  </div>
                  <div class="column">
                     <div class="row" style="font-weight:600">1</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">2</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">3</div>
                  </div>
                  <div class="column">
                     <div class="row" style="font-weight:600">4</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">5</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">6</div>
                  </div>
                  <div class="column">
                  <div class="row" style="font-weight:600">7</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">8</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">9</div>
               </div>
               <div class="column">
                  <div class="row" style="font-weight:600">10</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">11</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">12</div>
               </div>
               <div class="column">
                    <div class="row" style="font-weight:600">13</div>
                  </div>
                  <div class="column">
                     <div class="row" style="font-weight:600">14</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">15</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">16</div>
                  </div>
                  <div class="column">
                  <div class="row" style="font-weight:600">17</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">18</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">19</div>
               </div>
               <div class="column">
                  <div class="row" style="font-weight:600">20</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">21</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">22</div>
               </div>
               <div class="column">
                    <div class="row" style="font-weight:600">23</div>
                  </div>
                  <div class="column">
                     <div class="row" style="font-weight:600">24</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">25</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">26</div>
                  </div>
                  <div class="column">
                  <div class="row" style="font-weight:600">27</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">28</div>
               </div>
               <div class="column">
                 <div class="row" style="font-weight:600">29</div>
               </div>
               <div class="column">
                  <div class="row" style="font-weight:600">30</div>
               </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">PR</div>
                  </div>
                  <div class="column">
                     <div class="row" style="font-weight:600">CL</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">SL</div>
                  </div>
                  <div class="column">
                    <div class="row" style="font-weight:600">PL</div>
                  </div>
                  <div class="column">
                  <div class="row" style="font-weight:600">WL</div>
                </div>
                <div class="column">
                  <div class="row" style="font-weight:600">LOP</div>
                </div>
                <div class="column">
                  <div class="row" style="font-weight:600">OT</div>
                </div>
              </div>
              <div class="container">
                <div class="column">
                <!-- style="font-weight:600" -->
                  <div class="row" >Earning :20000</div>
                </div>
                <div class="column">
                  <div class="row" >Deduction :5000 </div>
                </div>
                <div class="column">
                  <div class="row" >PF No :364523 </div>
                  <div class="row" >ESI No :3634244523 </div>
                </div>
              </div>
              <div class="container">
                <div class="column">
                  <div class="row">Total :20000 </div>
                </div>
                <div class="column">
                  <div class="row">Total :5000 </div>
                </div>
                <div class="column">
                </div>
              </div>
              <div class="container" >
                <div class="column">
                  <div class="row">Net Amount Paid</div>
                  <div class="row" style="margin-left:130px">Rs:20,000</div>
                  <div class="row">Amount In Words:</div>
                </div>
              </div>
              <div class="" style="display: flex; flex-direction: row ; justify-content: space-between; align-items: center; border: 1px solid #ccc; padding:30px">
                  <div class="row">Employee's Signature</div>
                  <div class="row">Authorised Signature</div>
              </div>
               </div>         
              </body>
            </html> `);

      printWindow.document.close();
      printWindow.print();
    } else {
      console.error("Unable to open print window.");
    }
  };

  function createExportExcelObj(attendanceReport: any[]) {
    if (attendanceReport?.length >= 1) {
      let excelArr: ExcelSheetData[] = attendanceReport?.map((employee) => {
        const { empid, employeeName, PR, cl, SL, PL, WO, LOP, TL, attendance } = employee;
        const excelObj: ExcelSheetData = {
          Employee_Code: empid,
          Ticket_No: empid,
          Employee_Name: employeeName,
          PR: PR,
          CL: cl,
          SL: SL,
          PL: PL,
          WO: WO,
          LOP: LOP,
          TL: TL,
        };
        attendance.forEach(record => {
          excelObj[record.day] = record.present;
        });
        return excelObj;
      })
      exportToExcel(excelArr)
      console.log(excelArr, 'excelArr>>', attendanceReport)
    } else {
      alert(` No Data found `);
    }
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Attendance Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Attendance Report ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const [month, setMonth] = useState<any>(months[new Date()?.getMonth()]?.month)
  const [year, setYear] = useState<any>(yearsArray?.find((each) => each?.key === new Date()?.getFullYear()?.toString())?.value)
  const [employeeId, setEmployeeId] = useState<number>(0)

  const [attendanceReport, setAttendanceReport] = useState<any[]>([])

  const getDaysInMonth = (monthName: string, year: number): number => {
    const monthIndex = moment().month(monthName).month();
    const daysInMonth = moment({ year, month: monthIndex }).daysInMonth();
    return daysInMonth;
  };

  let findDaysInMonth = getDaysInMonth(months?.find((obj) => obj?.month == month)?.value!, Number(year))

  function updatedArrayFunc(yearAttendance) {
    let attendanceArray: any = [];
    yearAttendance?.map((each) => {
      let employeeAttd: any = {
        empid: each?.empid,
        employeeName: each?.Employee_Name,
        attendance: filterItemsWithNumericKeys(yearAttendance?.filter((obj) => obj?.empid == each?.empid)),
      }
      attendanceArray?.push(employeeAttd)
    })
    return attendanceArray;
  }

  async function handleGenerate(event) {
    let error: any = {}
    if (month && year) {
      setSpinner(true)
      const yearAttendance = await hrReporsStore?.getReportAttendence(month, Number(year))
      const balanceElCl = await hrReporsStore?.getReportBalance(month, Number(year))
      let updatedArray: any = [];
      updatedArray = updatedArrayFunc(yearAttendance)
      updatedArray?.forEach((family) => {
        balanceElCl?.forEach((employee) => {
          if (family?.empid === employee?.empid) {
            family['LOP'] = employee?.LOP;
            family['PL'] = employee?.PL;
            family['PR'] = employee?.PR;
            family['SL'] = employee?.SL;
            family['TL'] = employee?.TL;
            family['WO'] = employee?.WO;
            family['el'] = employee?.el;
            family['cl'] = employee?.cl;
          }
          return family
        })
      })
      if (updatedArray?.length && findDaysInMonth) {
        setAttendanceReport([...initializeAttendanceForMonth(updatedArray, findDaysInMonth)])
      }
      setSpinner(false)
    } else if (event) {
      if (!month) {
        error.month = 'Select month'
      }
      if (!year) {
        error.year = 'Select year'
      }
    }
    setErrors({ ...error })
  }

  async function initialApiCalls() {
    const employeeMaster = await employeeStore.loadEmployeeMaster();
    setEmployeeMaster([...employeeMaster])
    setLoading(false)
    handleGenerate(null)
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      initialApiCalls()
      isCurrentPage.current = false
    }
  }, [])

  return (
    <div>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
              <div className='hrBox'>
                <h3>Attendance Report</h3>
              </div>
              {
                isSpinner ?
                  <div className='SpinnerBox'>
                    <Spinner animation="grow" size="sm" />
                  </div> : null
              }
              <div className='inputBoxLists'>
                <div className='row'>
                  <div className="col-sm-2">
                    <div className="inputBox" >
                      <label style={{ fontSize: '16px' }}>Year<span>*</span></label>
                      <AutoComplete clsName="full-width" options={yearsArray} value={year} getOptionLabel='value'
                        emitOption={(option) => setYear(option?.value)} placeholder='Select Year..'
                      />
                    </div>
                    {errors?.year && <p style={{ color: 'red' }}>{errors?.year}</p>}
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-2">
                    <div className="inputBox" >
                      <label style={{ fontSize: '16px' }}>Month<span>*</span></label>
                      <AutoComplete clsName="full-width" options={months} value={months?.find((each) => each?.month === month)?.value} getOptionLabel='value'
                        emitOption={(option) => setMonth(option?.month)} placeholder='Select Month..'
                      />
                    </div>
                    {errors?.month && <p style={{ color: 'red' }}>{errors?.month}</p>}
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-3">
                    <div className="inputBox" >
                      <label style={{ fontSize: '16px' }}> Employee<span>*</span></label>
                      <AutoComplete clsName="full-width" options={employeeMaster} value={employeeMaster?.find((each) => each?.EmployeeId === employeeId)?.Employee_Name}
                        getOptionLabel='Employee_Name' emitOption={(option) => setEmployeeId(option?.EmployeeId)} placeholder='Select Name..'
                      />
                    </div>
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-4">
                    <div className="" style={{ marginTop: '30px' }}>
                      <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                        View
                      </button>
                      <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { createExportExcelObj(attendanceReport) }}>
                        Excel
                      </button>
                      <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Employee Code</th>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Employee Name</th>
                      {findDaysInMonth && Array.from(Array(findDaysInMonth).keys())?.map((each, index) => {
                        return (
                          <th scope="col" key={index}>{index + 1}</th>
                        )
                      })}
                      <th scope="col">PR</th>
                      <th scope="col">CL</th>
                      <th scope="col">SL</th>
                      <th scope="col">PL</th>
                      <th scope="col">WO</th>
                      <th scope="col">LOP</th>
                      <th scope="col">TL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceReport?.length > 0 &&
                      attendanceReport?.map((each, index) => {
                        return (!employeeId || each?.empid === employeeId) && (
                          <tr key={index}>
                            <td>{each?.empid}</td>
                            <td>{each?.empid}</td>
                            <td>{each?.employeeName}</td>
                            {each?.attendance?.map((obj, ind) => {
                              return (
                                <td key={ind}>{obj?.present}</td>
                              )
                            })}
                            <td>{each?.PR}</td>
                            <td>{each?.cl}</td>
                            <td>{each?.SL}</td>
                            <td>{each?.PL}</td>
                            <td>{each?.WO}</td>
                            <td>{each?.LOP}</td>
                            <td>{each?.TL}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
      }

    </div>
  )
}

export default AttendanceReport;