import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import shiftMasterDetails, { IShiftMaster } from '../model/index';


class AssignBatchPackingStore {
    @observable shiftMasterDetls = shiftMasterDetails;
    @observable shiftMasterList= new Map<number,IShiftMaster>();
    isInwardLoding=true;
    @observable getshiftMasters = new Map();
    getshiftMasterDetls = new Map();
    @observable entryNo= new Map();    
    
     saveShiftMaster = async (shiftMaster: IShiftMaster) => {
        try {
          const status=  await apiAgent.shiftMaster.saveShiftMaster(shiftMaster);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }

    updateShiftMaster = async (shiftMaster: IShiftMaster) => {
        try {
            const status = await apiAgent.shiftMaster.updateShiftMaster(shiftMaster);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getShiftMaster=async ()=> {        
        try {
          const getshiftMaster= await apiAgent.shiftMaster.getShiftMaster();
          
            runInAction(() => {
                
                this.shiftMasterList = getshiftMaster;
            });
            return this.shiftMasterList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getShiftMasterDetails=async (shiftId)=> {
        
        try {
          const getShift= await apiAgent.shiftMaster.getShiftMasterDetails(shiftId);
           
            runInAction(() => {
                
                this.getshiftMasterDetls = getShift;
            });
            return this.getshiftMasterDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.shiftMaster.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getShiftMasterSearch  = async (SearchOptions)=> {
        try {
          const shiftMasterData=await apiAgent.shiftMaster.getShiftMasterSearch(SearchOptions);
            this.shiftMasterList = shiftMasterData;
            
            runInAction(() => {
                this.shiftMasterList = shiftMasterData;
                
            })
            return this.shiftMasterList;       
         
        }
        catch (error) {
            console.log(error);
        }
    }

    deleteShiftMaster = async (shiftId) => {
        try {      
        const status=  await apiAgent.shiftMaster.deleteShiftMaster(shiftId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteShiftDetail = async (shiftdetid) => {
        try {      
        const status=  await apiAgent.shiftMaster.deleteShiftDetail(shiftdetid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default AssignBatchPackingStore;