import { runInAction, set, makeObservable, observable, action } from 'mobx';
import apiAgent from '../../../APIAgent';
import itemConversionDetails, { IItemConversion } from '../model/index';

class ItemConversionStore {
    @observable itemConversionDetls = itemConversionDetails;
    @observable itemConversionList= new Map<number,IItemConversion>();
    getItemConversions = new Map();
    itemConversionDetails = new Map();
    isInwardLoding=true;
    isLoading=true;
    constructor(){
        makeObservable(this);
    }
     saveItemConversion = async (itemConversion: IItemConversion) => {
        try {
          const status=  await apiAgent.itemConversion.saveItemConversion(itemConversion);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemConversion=async ()=> {
        
        try {
          const getConversion= await apiAgent.itemConversion.getItemConversion();
            
            runInAction(() => {
                
                this.getItemConversions = getConversion;
            });
            return this.getItemConversions;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemConversionDetails=async (itemConversionId)=> {
        
        try {
          const getConversion= await apiAgent.itemConversion.getItemConversionDetails(itemConversionId);
            
            runInAction(() => {
                
                this.itemConversionDetails = getConversion;
            });
            return this.itemConversionDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getItemConversionSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.itemConversion.getItemConversionSearch(SearchOptions);
            this.getItemConversions = Data;
            
            runInAction(() => {
                this.getItemConversions = Data;
                
            })
            return this.getItemConversions;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteItemConversionDetail = async (itemConversionDetailId) => {
        try {      
        const status=  await apiAgent.itemConversion.deleteItemConversionDetail(itemConversionDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteItemConversionMaster = async (itemConversionId) => {
        try {      
        const status=  await apiAgent.itemConversion.deleteItemConversionMaster(itemConversionId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    @action setItemConversion = (state:IItemConversion) =>{
        this.itemConversionList.set(0,state);
    }
    @action setItemConversionList = (state: IItemConversion[]) =>{
        set(state,this.itemConversionList);
        return this.itemConversionList;
    }
}
export default ItemConversionStore;