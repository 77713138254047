import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import moment from "moment";
import useStores from "../../hooks";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";

class FieldsProps {
    sdate?: string = getCurrentMonthFirstDate()?.firstDate;
    edate?: string = getCurrentMonthFirstDate()?.currentDate;
    empId: number = 0
    employeeName: string = ""
    departementId: number = 0
    department: string = ""
}

interface ExcelSheetData {
    S_No?: string,
    Employee_Code?: string,
    Employee_Name?: string,
    Attend_Date?: string,
    In_Time?: string,
    Break_Out_Time?: string,
    Break_In_Time?: string,
    Out_Time?: string,
    OT_hours?: string
}

const SalaryRegister = () => {
    const { hrReporsStore, employeeStore, departmentStore } = useStores()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);

    const [localFields, setLocalFields] = useState<FieldsProps>(new FieldsProps())
    const [salaryRegister, setSalaryRegister] = useState<any[]>([])

    // console.log(salaryRegister, 'salaryRegister>>')

    function createExportExcelObj(timeSheetReports: any[]) {
        if (timeSheetReports?.length >= 1) {
            let excelArr: ExcelSheetData[] = timeSheetReports?.map((employee, index) => {
                const { Empid, Employee_Name, AttnDate, InTime, BreakOutTime, BreakInTime, OutTime, TotalHours, Present, OThours } = employee;
                const excelObj: ExcelSheetData = {
                    S_No: (index + 1)?.toString(),
                    Employee_Code: Empid,
                    Employee_Name: Employee_Name,
                    Attend_Date: moment(AttnDate)?.format('DD-MMM-YYYY'),
                    In_Time: InTime ? moment(InTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_Out_Time: BreakOutTime ? moment(BreakOutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_In_Time: BreakInTime ? moment(BreakInTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Out_Time: OutTime ? moment(OutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    OT_hours: OThours ? moment(OThours, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                };
                return excelObj;
            })
            exportToExcel(excelArr)
        } else {
            alert(` No Data found `);
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Salary Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Salary Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function handleGenerate(event) {
        let error: any = {}
        if (localFields?.sdate && localFields?.edate) {
            setSpinner(true)
            const salaryRegister = await hrReporsStore?.getReportSalaryRegister(localFields?.sdate, localFields?.edate)
            if (salaryRegister?.length) {
                setSalaryRegister([...salaryRegister])
            }
            setSpinner(false)
        } else if (event) {
            if (!localFields?.sdate) {
                error.sdate = 'Select From Date'
            }
            if (!localFields?.edate) {
                error.edate = 'Select To Date'
            }
        }
        setErrors({ ...error })
    }

    async function initialApiCalls() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        const departmentList = await departmentStore.getDepartment();
        setDepartmentList([...departmentList])
        handleGenerate(null)
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false
        }
    }, [])
    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>
                            <div className='hrBox'>
                                <h3>Salary Register</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="sdate"
                                                value={localFields?.sdate}
                                                onChange={(event) => setLocalFields({ ...localFields, sdate: event.target.value })}
                                            ></input>
                                            {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>To Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="edate"
                                                value={localFields?.edate}
                                                onChange={(event) => setLocalFields({ ...localFields, edate: event.target.value })}
                                            ></input>
                                            {errors.edate && <p style={{ color: "red" }}>{errors.edate}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Employee<span>*</span></label>
                                            <AutoComplete clsName="full-width" options={employeeMaster} value={localFields?.employeeName} getOptionLabel='Employee_Name'
                                                emitOption={(option) => setLocalFields({ ...localFields, employeeName: option?.Employee_Name, empId: option?.EmployeeId })} placeholder='Select Category..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Department</label>
                                            <AutoComplete clsName="full-width" options={departmentList} value={localFields?.department} getOptionLabel='department'
                                                emitOption={(option) => setLocalFields({ ...localFields, departementId: option?.departmentId, department: option?.department })} placeholder='Select department..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                                                Search
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => createExportExcelObj(salaryRegister)}>
                                                Excel
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => setLocalFields(new FieldsProps())}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Employee ID</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">UAN</th>
                                            <th scope="col">ESIC</th>
                                            <th scope="col">Salary/Day</th>
                                            <th scope="col">Present Days</th>
                                            <th scope="col">Payable Days</th>
                                            <th scope="col">OT HRS</th>
                                            <th scope="col">Basics</th>
                                            <th scope="col">DA</th>
                                            <th scope="col">HRA</th>
                                            <th scope="col">OT Salary</th>
                                            <th scope="col">Advance</th>
                                            <th scope="col">Total Salary</th>
                                            <th scope="col">Total Deduction</th>
                                            <th scope="col">Net Salary</th>
                                            <th scope="col">Bank</th>
                                            <th scope="col">Cash</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salaryRegister?.map((each, index) => {
                                            return (!localFields?.empId || each?.empid === localFields?.empId) &&
                                                (!localFields?.departementId || each?.DepartmentID === localFields?.departementId) && (
                                                    <tr key={index}>
                                                        <td>{each?.empid}</td>
                                                        <td>{each?.Employee_Name}</td>
                                                        <td>{each?.uanno}</td>
                                                        <td>{each?.esino}</td>
                                                        <td>{each?.SalaryDay}</td>
                                                        <td>{each?.PresentDays}</td>
                                                        <td>{each?.PayableDays}</td>
                                                        <td>{each?.OTHours}</td>
                                                        <td>{each?.BasicSalary}</td>
                                                        <td>{each?.empid}</td>
                                                        <td>{each?.HRASalary}</td>
                                                        <td>{each?.OTSalary}</td>
                                                        <td>{each?.Advance}</td>
                                                        <td>{each?.TotalSalary}</td>
                                                        <td>{each?.TotalDeduction}</td>
                                                        <td>{each?.NetSalary}</td>
                                                        <td>{each?.Bank}</td>
                                                        <td>{each?.Cash}</td>
                                                    </tr>
                                                )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}

export default SalaryRegister;