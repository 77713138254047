export class IIInterveiwMaster {
    interviewid?: number = 0
    interviewcode?: number = 0
    jobcode?: number = 0
    phases?: string = ""
    process?: string = ""
    duration?: string = "00:00"
    interviewmstdet?: IIInterviewMasterDetails[] = []
}

export class IIInterviewMasterDetails {
    giD_Intmasterid?: number = 0
    giD_Jobcode?: number = 0
    giD_Phases?: string = ""
    giD_Process?: string = ""
    giD_Duration?: string = '00:00'
}