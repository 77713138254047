import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import rackallotmentdts, { IRackAllotment } from '../model/index';


class RackAllotmentStore {
    @observable rackAllotmentDetls = rackallotmentdts;
    @observable rackAllotmentList= new Map<number,IRackAllotment>();
    isInwardLoding=true;
    getrackAllotments = new Map();
    getrackAllotmentDetls = new Map();
    @observable entryNo= new Map();

     saveRackAllotment = async (rackAllotment: IRackAllotment) => {
        try {
          const status=  await apiAgent.rackAllotment.saveRackAllotment(rackAllotment);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getRackAllotment=async ()=> {
        
        try {
          const getrackAllotment= await apiAgent.rackAllotment.getRackAllotment();
            
            runInAction(() => {
                
                this.getrackAllotments = getrackAllotment;
            });
            return this.getrackAllotments;
        }
        catch (error) {
            console.log(error);
        }
    }
    getRackAllotmentDetails=async (rackEntryNo)=> {
        
        try {
          const getRack= await apiAgent.rackAllotment.getRackAllotmentDetails(rackEntryNo);
            
            runInAction(() => {
                
                this.getrackAllotmentDetls = getRack;
            });
            return this.getrackAllotmentDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadRackBatchDetail=async (itemId)=> {
        
        try {
          const getRack= await apiAgent.rackAllotment.loadRackBatchDetail(itemId);
            
            runInAction(() => {
                
                this.getrackAllotmentDetls = getRack;
            });
            return this.getrackAllotmentDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.rackAllotment.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getRackBatchSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.rackAllotment.getRackBatchSearch(SearchOptions);
            this.getrackAllotments = Data;
            
            runInAction(() => {
                this.getrackAllotments = Data;
                
            })
            return this.getrackAllotments;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteRackBatchDetail = async (rackEntryDetailNo) => {
        try {      
        const status=  await apiAgent.rackAllotment.deleteRackBatchDetail(rackEntryDetailNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteRackBatchMaster = async (rackEntryNo) => {
        try {      
        const status=  await apiAgent.rackAllotment.deleteRackBatchMaster(rackEntryNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default RackAllotmentStore;