import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { IPaymentInfo } from '../model/posmodal';
import { Autocomplete } from '@mui/material';
import { IPosSalescoinchange } from '../model';
import { Image, Button } from 'react-bootstrap';

interface PyamentOptionProps {
  posDetail: any,
  show: boolean,
  returnAmt?: number,
  payabaleAmount: number,
  modalClose: () => void,
  handleRadioChange: (e) => void,
  errors: any,
  posProductList: Array<any>;
  processtoBill: (paymentAmount: IPaymentInfo, posSalescoinchange: IPosSalescoinchange[] | null) => void,
  sudexoAmt: number,
}

const PaymentOptionModal: React.FC<PyamentOptionProps> = ({ posDetail, show, returnAmt, payabaleAmount, modalClose, handleRadioChange, posProductList, errors, processtoBill, sudexoAmt }) => {
  // console.log(posProductList, 'posProductList>>')
  const [changeOpt, setChaneOpt] = useState('')

  const [paymentAmount, setPaymentAmount] = useState<IPaymentInfo>(new IPaymentInfo())

  const [posSalescoinchange, setPosSalescoinchange] = useState<IPosSalescoinchange[]>([])

  const [error, setError] = useState<any>({})

  const [sumAmountPaid, setSumAmountPaid] = useState<number>(0)
  const [balanceAmount, setBalanceAmount] = useState<number>(0)
  const [balanceAmountDisplay, setBalanceAmountDisplay] = useState<number>(0)
  const [isCheckQtyModal, setCheckQtyModal] = useState(false);

  function handleChangeOpt(event) {
    setChaneOpt(event?.target?.value)
  }

  const handleCheckQtyModalClose = () => setCheckQtyModal(false);

  function hanleCoinchange(item) {
    let posSaleChange = new IPosSalescoinchange()
    if (item?.itemid) {
      let itemQty = Math.floor(Number(Math.abs(balanceAmount) ?? 0) / Number(item?.BTCSellingRate));
      if (itemQty <= 0) {
        setCheckQtyModal(true)
      }
      posSaleChange = {
        amount: (itemQty * Number(item?.BTCSellingRate ?? 0)),
        barcode: item?.Barcode,
        batchcode: item?.batchno,
        itemid: item?.itemid,
        qty: itemQty,
        rate: Number(item?.BTCSellingRate ?? 0),
      }
      if (posSaleChange?.amount !== undefined && itemQty > 0) {
        setBalanceAmountDisplay(0)
      }
      console.log(posSaleChange, 'posSaleChange>>')
      let arrCoinChange = new Array<IPosSalescoinchange>();
      arrCoinChange?.push(posSaleChange);
      setPosSalescoinchange([...arrCoinChange])
      console.log(arrCoinChange, 'arrCoinChange');

    }
  }

  useEffect(() => {
    console.log(posSalescoinchange, 'posSalescoinchangeueeffect')
  }, [posSalescoinchange])

  function updatePayment() {
    let sumOfAmount: number = 0;
    sumOfAmount = (paymentAmount?.cardAmount! ?? 0) + (paymentAmount?.cashAmount! ?? 0) + (paymentAmount?.onlinebankTR! ?? 0) + (paymentAmount?.onlinestoreorder! ?? 0) +
      (paymentAmount?.qRcodeAmount! ?? 0) + (paymentAmount?.redeemAmount! ?? 0) + (paymentAmount?.salesReturn! ?? 0) + (paymentAmount?.sodexo! ?? 0);
    let amountReturn: number = payabaleAmount - sumOfAmount;
    setBalanceAmount(amountReturn)
    setBalanceAmountDisplay(amountReturn)
    setSumAmountPaid(sumOfAmount)
  }

  useEffect(() => {
    updatePayment()
  }, [paymentAmount, returnAmt, posDetail, sudexoAmt])

  function handleAmountPaid(event) {
    const { name, value } = event?.target;
    setPaymentAmount({ ...paymentAmount, [name]: Number(value) })
  }

  function confirmOrder(event) {
    let error: any = {}
    if ((sumAmountPaid) >= payabaleAmount) {
      if (changeOpt === 'Cash' || changeOpt === 'CoinChange') {
        processtoBill(paymentAmount, posSalescoinchange)
      } else if (Math.abs(balanceAmount) <= 0) {
        processtoBill(paymentAmount, null)
      } else {
        processtoBill(paymentAmount, null)
      }
    } else {
      error.amountShortage = 'Paid amount is not eqaul to Payable Amount'
    }
    setError({ ...error })
  }

  return (
    <>
      <Modal show={show} onHide={modalClose} className='PriceHistoryModel paymentOptionModel' style={{ marginTop: '73px' }}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Cash  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.cashAmount} onChange={handleAmountPaid} name="cashAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Redeem Amount:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.redeemAmount = (posDetail?.isReedem && posDetail?.redeemPointsamt) ? Number(posDetail?.redeemPointsamt) : 0}
                aria-readonly name="redeemAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Sales Return  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.salesReturn = Number(returnAmt! ?? 0)} name="salesReturn" aria-readonly />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Card  :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.cardAmount} onChange={handleAmountPaid} name="cardAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>QR Code:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.qRcodeAmount} onChange={handleAmountPaid} name="qRcodeAmount" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Store Order :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.onlinestoreorder} onChange={handleAmountPaid} name="onlinestoreorder" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Online Bank Transfer :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount?.onlinebankTR} onChange={handleAmountPaid} name='onlinebankTR' />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>SODEXO:</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined"
                value={paymentAmount.sodexo = Number(Math.round(sudexoAmt) ?? 0)} onChange={handleAmountPaid} name="sodexo" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Payable Amount :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={(payabaleAmount)?.toFixed(0)} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: 'black', }}>Total Amount :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={sumAmountPaid} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '3px 0px', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 'larger', fontWeight: '600', color: balanceAmountDisplay > 0 ? 'red' : 'green', }}>{balanceAmountDisplay < 0 ? "Amount should be Return" : "Amount Should be Paid"} :</div>
              <TextField size='small' id="outlined-basic" type='number' placeholder='0.00' variant="outlined" value={Math.abs(balanceAmountDisplay)?.toFixed(0)} />
            </div>
            {error?.amountShortage && <p style={{ color: 'red' }}>{error?.amountShortage}</p>}
          </div>
          <div style={{ border: '1px dashed gray', borderTop: '1px', borderRight: '1px', borderLeft: '1px', padding: '4px 0px' }}></div>
          <div>
            <div className='vertical-space-10'></div>
            <RadioButtonsGroup paymentMode={changeOpt} handleChange={handleChangeOpt} radioArray={[{ key: 'Cash', value: 'Cash' }, { key: 'Coin Change', value: 'CoinChange' }]} />
            <div>
              <Autocomplete size="small"
                disablePortal
                id="combo-box-demo"
                options={posProductList?.filter((each) => each?.coinchange === 1)}
                disabled={changeOpt !== 'CoinChange'}
                getOptionLabel={(option: any) => option.itemname}
                onChange={(event, val) => hanleCoinchange(val)}
                renderInput={(params: any) =>
                  <TextField  {...params}
                    id="outlined-size-small"
                    color='info'
                    size="small"
                    type="text"
                    placeholder='Coin change..'
                    name='coinChange'
                  />}
              />
            </div>
          </div>
          <div style={{ border: '1px dashed gray', borderTop: '1px', borderRight: '1px', borderLeft: '1px', padding: '4px 0px' }}></div>
          <div>
            <div className='vertical-space-10'></div>
            <div className='btnBox'>
              <button className='dfBtn width-auto' type='submit' onClick={confirmOrder}>Confirm Bill</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isCheckQtyModal} onHide={handleCheckQtyModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Please Select Correct Item</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCheckQtyModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal> : null
    </>

  )
}
export default PaymentOptionModal;

function RadioButtonsGroup({ paymentMode, handleChange, radioArray }: { paymentMode: string, handleChange: (event: any) => void, radioArray: Array<{ key: string, value: string }> }) {
  return (
    <FormControl style={{ width: '100%' }}>
      <FormLabel id="demo-radio-buttons-group-label">Change Option</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={paymentMode}
        onChange={handleChange}
      >
        {radioArray?.map((each, index) => {
          return (
            <FormControlLabel key={index} id={each?.key} value={each?.value} control={<Radio />} label={each?.key} />
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}
