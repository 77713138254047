import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Country, State, City } from 'country-state-city';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DespatchEntryDetails, IDespatchEntry } from './model';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import moment from 'moment';
import DespatchStore from './store';
import Cookies from 'js-cookie';


function DespatchEntry() {
  const [isOutelt, setOutelt] = useState(false);
  const [isWarewhouse, setWarewhouse] = useState(false);
  const [despatchEntry, setDespatchEntry] = useState<IDespatchEntry>(new IDespatchEntry());
  const [despatchEntryDetail, setDespatchEntryDetail] = useState<DespatchEntryDetails>({});
  const [despatchEntryDetails, setDespatchEntryDetails] = useState<DespatchEntryDetails[]>([]);
  const navigate = useNavigate();
  const { supplierMasterStore, despatchStore, commonStore, inwardOultetStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [itemlstBatch, setitemlstBatch] = useState([]);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const { loadWarehouse, loadWarehouses, loadOutlet, loadOutlets,
    loadEmployee, loadEmployees, loadDespatchOrderDetails, loadDespatchOrder } = commonStore;

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setDespatchEntry({ ...despatchEntry, [name]: value });
  };

  const handleCancel = () => {
    navigate('/Admin/DespatchEntry/DespatchEntryDetail');
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/DespatchEntry/DespatchEntryDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  const handleOutletValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setDespatchEntry({ ...despatchEntry, [name]: val.outletid, ['outletName']: val.outletName });
    }
    else {
      despatchEntry.outletName = '';
    }

  }

  const handleWarehouse = (name: any, event: object, val: any) => {
    if (val != null) {
      setDespatchEntry({ ...despatchEntry, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    }
    else {
      despatchEntry.wareHouse = '';
    }
  }
  const handleOrderDetails = async (name: any, event: object, val: any) => {

    setDespatchEntry({
      ...despatchEntry, [name]: val.orderNo, ["orderNoId"]: val.orderNoId, ["orderMasterid"]: val.orderMasterid,
      ["orderdate"]: val.orderdate, ["itemid"]: val.itemid, ["itemname"]: val.itemname,
      ["uom"]: val.uom,
    });
    setSpinner(true)

    var res = await despatchStore.loaddespatchitemdetails(val.orderMasterid);
    setSpinner(false)
  }
  const handleItemValue = async (name: any, event: object, val: any) => {

    setDespatchEntry({ ...despatchEntry, itemid: val.itemid, itemname: val.itemname, itemQty: val.itemqty, uom: val.uom, qty: val.qty, batchNo: val.batchNo, mrp: val.mrp, b2bprice: val.b2bprice, b2cprice: val.b2cprice })

    var res = await despatchStore.loaddespatchitemdetailsBatch(val.itemid);
    setitemlstBatch(res);
  }

  const saveDespatchEntry = async (e) => {

    e.preventDefault();
    despatchEntry.assigndespatchdetails = despatchEntryDetails
    const EntryNos = await inwardOultetStore.getEntryNo('DespatchEntry');
    despatchEntry.orderdespatchid = EntryNos.entryNo + 1

    const status = await despatchStore.saveDespatchEntry(despatchEntry);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const addDespatchEntry = () => {

    let newObject: DespatchEntryDetails = {};
    despatchEntryDetail.orderdespatchid = despatchEntry.orderdespatchid;
    despatchEntryDetail.DespatchEntryItemId = despatchEntry.itemid;
    despatchEntryDetail.orderdespatchitemname = despatchEntry.itemname;
    despatchEntryDetail.DespatchEntryqty = despatchEntry.qty;
    despatchEntryDetail.orderdespatchbatchno = despatchEntry.batchNo;
    despatchEntryDetail.DespatchEntrydesqty = Number(despatchEntry.desqty);
    despatchEntryDetail.DespatchEntrymrp = Number(despatchEntry.mrp);
    despatchEntryDetail.DespatchEntryb2Bprice = Number(despatchEntry.b2bprice);
    despatchEntryDetail.DespatchEntryb2Cprice = Number(despatchEntry.b2cprice);


    setDespatchEntryDetails([...despatchEntryDetails, despatchEntryDetail])
    setDespatchEntryDetail(newObject);
  }

  async function fetchOultetItems() {
    await loadWarehouse();
    await loadOutlet();
    await loadEmployee();
    await loadDespatchOrder();
    await inwardOultetStore.getEntryNo('DespatchEntry');
    setLoading(false);
  }

  // useEffect(() => {
  //   fetchOultetItems();
  // });

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'Assginedin' && value === '') {
      setOutelt(false);
      setWarewhouse(false);
    }

    if (value === 'Outlet') {
      setOutelt(true);
      setWarewhouse(false);
    }
    if (value === 'Warehouse') {
      setOutelt(false);
      setWarewhouse(true);
    }
    setDespatchEntry({ ...despatchEntry, [name]: value })
  }

  const [singleSelections, setSingleSelections] = useState([]);
  const [priceHitory, setPriceHitory] = useState(false);
  const priceHitoryClose = () => setPriceHitory(false);
  const priceHitoryShow = () => setPriceHitory(true);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Despatch_entry';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchOultetItems();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/DespatchEntry/DespatchEntryDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <div className='container'>
        
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Despatch Entry</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='row'>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Despatch No</label>
                  <input type="text" className=''
                    onChange={handleInputchnage}
                    name="orderdespatchid"
                    value={despatchEntry.orderdespatchid = inwardOultetStore.entryNo.entryNo + 1}
                    disabled style={{ width: "100%" }} placeholder='Despatch No..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="orderdespatchdate"
                    value={despatchEntry?.orderdespatchdate}
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-3'>
                <div className='inputBox'>
                  <label>Despatch By <span>*</span></label>
                  <select className="form-selected" style={{ width: "100%" }} onChange={handleChangeInput} id="Category" name="orderthrough">
                    <option value="" selected>Not Select</option>
                    <option value="Outlet">Outlet </option>
                    <option value="Warehouse">Warehouse</option>
                  </select>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {
                isOutelt ?
                  <>
                    <div className='col-md-5'>
                      <label>Outlet Name <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadOutlets}
                        getOptionLabel={(option: any) => option.outletName}
                        onChange={(event, val) => handleOutletValue('outletid', event, val)}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '30ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Outlet Name..'
                            name='outletid'
                          />}
                      />
                    </div>
                  </> : null
              }
              {
                isWarewhouse ?
                  <>
                    <div className='col-md-5'>
                      <label>Warehouse <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadWarehouses}
                        getOptionLabel={(option: any) => option.warehouseName}
                        onChange={(event, val) => handleWarehouse('warehouseId', event, val)}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '30ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Warehouse..'
                            name='warehouseId'
                          />}
                      />
                    </div>
                  </> : null

              }

              {/* <div className='col-md-4'></div>       */}
              <div className='col-md-4'>
                <div className='inputBox'>
                  <label>Order No<span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={loadDespatchOrderDetails}
                    getOptionLabel={(option: any) => option.orderNo}
                    onChange={(event, val) => handleOrderDetails('orderNoId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Order No..'
                        name='orderNoId'
                      />}
                  />
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Order Date<span>*</span></label>
                  <input type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="orderdate"
                    value={despatchEntry?.orderdate}
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {despatchStore?.despatchitem?.length && <div className='col-md-5'>
                <div className='inputBox'>
                  {
                    isSpinner ?
                      <div className='SpinnerBox'>
                        <Spinner animation="grow" size="sm" />
                      </div> : null
                  }
                  <label>Item Name </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={despatchStore?.despatchitem}
                    getOptionLabel={(option: any) => option.itemname}
                    onChange={(event, val) => handleItemValue('itemid', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Item..'
                        name='itemid'
                      />}
                  />
                  <div className='vertical-space-10'></div>
                </div>


                <div className='vertical-space-10'></div>
              </div>}
              <div className='col-md-2' >
                <div className='inputBox' >
                  <label>Order Qty<span>*</span></label>
                  <input type="text" className=''
                    value={despatchEntry.qty}
                    name="qty"
                    style={{ width: "100%" }} placeholder='Order Qty..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>UOM <span>*</span></label>
                  <input type="text" className=''
                    value={despatchEntry.uom}
                    name="uom"
                    style={{ width: "100%" }} placeholder='UOM..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label> Despatch Qty<span>*</span></label>
                  <input type="text" className=''
                    value={despatchEntry.desqty}
                    onChange={handleInputchnage}
                    name="desqty"
                    style={{ width: "100%" }} placeholder='Despatch Qty..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {itemlstBatch?.length ? <div className='col-md-5'>
                <div className='inputBox'>
                  <label> Batch No<span>*</span></label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={itemlstBatch}
                    getOptionLabel={(option: any) => option.batchNo}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '40ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Batchno..'
                        name="batchNo"
                      />}
                  />
                </div>
                <div className='vertical-space-10'></div>
              </div> : null}
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>MRP <span>*</span></label>
                  <input type="text" className=''
                    value={despatchEntry.mrp}
                    name="mrp"
                    style={{ width: "100%" }} placeholder='MRP..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>B2B Price</label>
                  <input type="text" className=''
                    value={despatchEntry.b2bprice}
                    name="b2bprice"
                    style={{ width: "100%" }} placeholder='B2B Price..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>B2C Price </label>
                  <input type="text" className=''
                    value={despatchEntry.b2cprice}
                    name="b2cprice"
                    style={{ width: "100%" }} placeholder='B2C Price..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Current Stock </label>
                  <input type="text" className=''
                    value={despatchEntry.b2cprice}
                    name="b2cprice" disabled
                    style={{ width: "100%" }} placeholder='Current Stock..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox' style={{ visibility: "hidden" }}>
                  <label>Order Master ID<span>*</span></label>
                  <input type="text" className=''
                    value={despatchEntry.orderMasterid}
                    name="orderMasterid"
                    style={{ width: "100%" }} placeholder='Order Qty..'></input>
                </div>

              </div>
              <div className='col-sm-2'>
                <button className='dfBtn' onClick={addDespatchEntry}>Add</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope="col">Order No</th>
                        <th scope="col">Order Date</th>  */}
                        <th scope="col">Item Name</th>
                        <th scope="col">Order Qty</th>
                        <th scope="col">Despatch Qty</th>
                        <th scope="col">MRP</th>
                        <th scope="col">B2B price</th>
                        <th scope="col">B2C price</th>

                      </tr>
                    </thead>
                    <tbody>
                      {
                        despatchEntryDetails.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.DespatchEntryItemId}</td>
                              <td>{val.DespatchEntryqty}</td>
                              <td>{val.DespatchEntrydesqty}</td>
                              <td>{val.DespatchEntrymrp}</td>
                              <td>{val.DespatchEntryb2Bprice}</td>
                              <td>{val.DespatchEntryb2Cprice}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-10'></div>
              </div>

            </div>
          </div>
          <div className='vertical-space-10'></div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={saveDespatchEntry} >Submit</button>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </div>

    );
  }
};

export default DespatchEntry;
