import { runInAction, set, makeObservable, observable } from 'mobx';
import { IItemInward,IInwardDetails } from '../model/index';
import apiAgent from '../../../APIAgent';

class ItemInwardStore {
    @observable itemInwardDetls = new Map();
    @observable itemInwardList= new Map();
    @observable iteminwardDetails=new Map();
    @observable loadItemMasters = new Map();
    itemMasters = []; 
    @observable ledgers = new Map();
    isInwardLoding=true;

     saveItemInward = async (itemInward: IItemInward) => {
        try {
          const status=  await apiAgent.itemInward.saveItemInward(itemInward);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardMaster  = async ()=> {
        try {
          const inwardData= await apiAgent.itemInward.getInwardMaster();
            
            runInAction(() => {
                this.itemInwardList = inwardData;
                this.isInwardLoding = false;
            });
            return this.itemInwardList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardDetails = async (inwardNo)=> {
        try {
          const inwardDetls= await apiAgent.itemInward.getInwardDetails(inwardNo);
            runInAction(() => {
                this.iteminwardDetails = inwardDetls;
            });
            return this.iteminwardDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.itemInward.getInwardSearch(SearchOptions);
            this.itemInwardList = data;
            
            runInAction(() => {
                this.itemInwardList = data;
                
            })
            return this.itemInwardList;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteInwardDetail = async (inwardDetailNo) => {
        try {      
        const status=  await apiAgent.itemInward.deleteInwardDetail(inwardDetailNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteInwardMaster = async (inwardNo) => {
        try {      
        const status=  await apiAgent.itemInward.deleteInwardMaster(inwardNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInwardItemMaster = async (inwardNo) => {
        try {
            const itemMaster = await apiAgent.commonMasters.loadItemMaster();
            runInAction(() => {
                this.loadItemMasters = itemMaster;
            })
           return this.loadItemMasters;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInwardItemMasterJSON =  async () => {
        try {
            const itemMaster = await apiAgent.commonMasters.loadItemMasterJSON();
           return JSON.parse(itemMaster);
        }
        catch (error) {
            console.log(error);
        }
    }
    getLedgers = async (inwardNo) => {
        try {
            const ledgers = await apiAgent.itemInward.getLedgers(inwardNo);
            runInAction(() => {
                this.ledgers = ledgers;
            })
           return ledgers;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPurchaseOrderDetail = async(supid) => {
        try {
            const purchaseOrders = await apiAgent.itemInward.getPurchaseOrderDetails(supid);
            return JSON.parse(purchaseOrders)
        } catch (error) {
            console.log(error)
        }
    }
    getBarcodeInward = async(supid) => {
        try {
            const purchaseOrders = await apiAgent.itemInward.getBarcodeInward(supid);
            return JSON.parse(purchaseOrders)
        } catch (error) {
            console.log(error)
        }
    }
}
export default ItemInwardStore;