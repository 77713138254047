import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { Accordion, Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const ResumeBankList = observer((): JSX.Element => {
    const { employeeStore, jobMasterStore, resumeBankStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const { loadResumeBankMasterDetail } = resumeBankStore;
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [resumeBankList, setResumeBankList] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [onOpenDetail, setOnOpenDetail] = useState({ bool: false, id: 0 })

    const deleteEmployee = async (empId) => {
        setLoading(true);
        const status = await employeeStore.deleteEmployee(empId);

        if (status === 'Success') {
            fetchOutletItemData();
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const editEmployee = (empId) => { }

    const handleCreate = () => {
        navigate('/Admin/ResumeBank');
    }

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
    }

    const fetchOutletItemData = async () => {
        const userRights = await userCreationStore?.getUserRight('Resume_bank')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const resumeBankMaster = await loadResumeBankMasterDetail();
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        setResumeBankList([...resumeBankMaster])
        setLoading(false);
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletItemData();
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (resumeBankList && resumeBankList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(resumeBankList)
        }
    }, [resumeBankList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(resumeBankList?.slice()?.sort((a, b) => b.ResumBankid - a.ResumBankid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={resumeBankList}
                                searchTitles={[{ key: "ResumBankid", value: "Resume ID" }, { key: "Jobid", value: "Job Code" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{resumeBankList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope='col'>Resume ID </th>
                                            <th scope='col'>Date  </th>
                                            <th scope='col'>Job Code</th>
                                            <th scope='col'>Job Title</th>
                                            <th scope='col'>Job Description</th>
                                            <th scope='col'>Refference Id </th>
                                            <th scope='col'>Resume  </th>
                                            <th scope='col'>Photo</th>
                                            <th scope='col'>Description</th>
                                            <th scope='col'></th>
                                            <th scope='col'>Create </th>
                                            <th scope='col'>Update </th>
                                            <th scope='col'>Delete </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length ?
                                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.ResumBankid}</td>
                                                        <td>{moment(val?.date)?.format('DD-MMM-YYYY')}</td>
                                                        <td>{val?.Jobid}</td>
                                                        <td>{jobMaster?.find((each) => each?.Jobcode == val?.Jobid)?.Jobtitle}</td>
                                                        <td style={{ maxWidth: '300px' }}>{jobMaster?.find((each) => each?.Jobcode == val?.Jobid)?.JobDescription}</td>
                                                        <td>{val?.Empid}</td>
                                                        <td>{val.Resumepath}</td>
                                                        <td>{val.Empphotopath}</td>
                                                        <td>{val.Description}</td>
                                                        <td><button disabled={!userRights?.view} className='' onClick={() => { setOnOpenDetail({ bool: true, id: val?.ResumBankid }) }}>Details</button></td>
                                                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editEmployee(val.empId)} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteEmployee(val.empId)} /></button></td>
                                                    </tr>
                                                )
                                            }) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={onOpenDetail?.bool} onHide={() => { setOnOpenDetail({ bool: false, id: 0 }) }} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Candidate Details</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">DOB  </th>
                                                                <th scope="col">Gender </th>
                                                                <th scope="col">Martial Status  </th>
                                                                <th scope="col">Blood Group </th>
                                                                <th scope="col">Edu Qualification  </th>
                                                                <th scope="col">Current Company</th>
                                                                <th scope="col">Experience</th>
                                                                <th scope="col">Last Month Salary  </th>
                                                                <th scope="col">Notice Period  </th>
                                                                <th scope="col">Current City  </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(isSearch ? filteredData : currentPageData)?.length ?
                                                                (isSearch ? filteredData : currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.candidate?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Candidatename}</td>
                                                                                <td>{moment(obj.Dateofbirth).format('DD-MMM-YYYY')}</td>
                                                                                <td>{obj?.Gender}</td>
                                                                                <td>{obj.Martialstatus}</td>
                                                                                <td>{obj.BloodGroup}</td>
                                                                                <td>{obj.EducationQualification}</td>
                                                                                <td>{obj?.CurrentCompanyDetails}</td>
                                                                                <td>{`${obj.ExpYear}y${obj.ExpMonth}m`}</td>
                                                                                <td>{obj.LastMonthSalary}</td>
                                                                                <td>{obj.NoticePeriod}</td>
                                                                                <td>{obj?.Currentcity}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Contact Details</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Door No</th>
                                                                <th scope="col">Street </th>
                                                                <th scope="col">Area  </th>
                                                                <th scope='col'>City </th>
                                                                <th scope="col">State</th>
                                                                <th scope="col">Pincode  </th>
                                                                <th scope="col">Country </th>
                                                                <th scope="col">Mobile  </th>
                                                                <th scope="col">Email  </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/*  */}
                                                            {(isSearch ? filteredData : currentPageData)?.length ?
                                                                (isSearch ? filteredData : currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.contact?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Doorno}</td>
                                                                                <td>{obj.Street}</td>
                                                                                <td>{obj?.Area}</td>
                                                                                <td>{obj.City}</td>
                                                                                <td>{obj.State}</td>
                                                                                <td>{obj.Pincode}</td>
                                                                                <td>{obj.Country}</td>
                                                                                <td>{obj.Mobile}</td>
                                                                                <td>{obj.Email}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Work Experience</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Company</th>
                                                                <th scope="col">Job Title </th>
                                                                <th scope='col'>From </th>
                                                                <th scope='col'>To </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(isSearch ? filteredData : currentPageData)?.length ?
                                                                (isSearch ? filteredData : currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.employExperience?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Company}</td>
                                                                                <td>{obj.Jobtitle}</td>
                                                                                <td>{moment(obj?.Fromdate)?.format('DD-MMM-YYYY')}</td>
                                                                                <td>{moment(obj?.Todate)?.format('DD-MMM-YYYY')}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Key Skills</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Key Skills</th>
                                                                <th scope="col">Total Experience </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(isSearch ? filteredData : currentPageData)?.length ?
                                                                (isSearch ? filteredData : currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.keySkill?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Keyskills}</td>
                                                                                <td>{`${obj.Exp_year}y${obj.Exp_month}m`}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Languages Known</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Language Known</th>
                                                                <th scope="col">To Speak </th>
                                                                <th scope="col">To Read </th>
                                                                <th scope="col">To Write </th>
                                                                {/* <th scope='col'>Delete </th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(isSearch ? filteredData : currentPageData)?.length ?
                                                                (isSearch ? filteredData : currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.language?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.MLanguage}</td>
                                                                                <td>{obj.ToSpeak}</td>
                                                                                <td>{obj?.ToRead}</td>
                                                                                <td>{obj.ToWrite}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Modal.Body>
                            </Modal>

                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../gsmecom/images/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    );
});

export default ResumeBankList;
