import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import exp from 'constants';

class PurchaseBillStore {
    @observable entryNo = new Map();
    @observable loadSuppliers = new Map();
    @observable inwards = new Map();
    @observable inwardDetail = new Map();
    @observable puchaseBillDetails = new Map();
    @observable puchaseDetails = new Map();
    constructor() {
        makeObservable(this);
    }
    getPurchaseBillEntryNo = async (flag) => {

        try {
            const entryNo = await apiAgent.helper.getWareHosueModuleEntryNo(flag);

            runInAction(() => {
                this.entryNo = entryNo;
            })
            return entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardSupliers = async () => {

        try {
            const suppliers = await apiAgent.puchaseBill.getInwardSupliers();

            runInAction(() => {
                this.loadSuppliers = suppliers;
            })
            return this.loadSuppliers;
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwards = async (supplierId) => {

        try {
            const inwards = await apiAgent.puchaseBill.getInwards(supplierId);

            runInAction(() => {
                this.inwards = inwards;
            })
            return inwards
        }
        catch (error) {
            console.log(error);
        }
    }
    getInwardDetail = async (inwardRequest) => {

        try {
            const inwardDetail = await apiAgent.puchaseBill.getInwardDetail(inwardRequest);

            runInAction(() => {
                this.inwardDetail = JSON.parse(inwardDetail);
            })
            return JSON.parse(inwardDetail);
        }
        catch (error) {
            console.log(error);
        }
    }
    savePurchaseBill = async (purchaseBill) => {

        try {
            const status = await apiAgent.puchaseBill.savePurchaseBill(purchaseBill);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPurchaseBillMasterDetails = async () => {

        try {
            const purchaseBillDetails = await apiAgent.puchaseBill.getPurchaseBillMasterDetails();

            runInAction(() => {
                this.puchaseBillDetails = purchaseBillDetails;
            })
            return this.puchaseBillDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPurchseBillReturnJSON = async () => {

        try {
            const purchaseBillDetails = await apiAgent.puchaseBill.getPurchseBillReturnJSON();

            return JSON.parse(purchaseBillDetails)
        }
        catch (error) {
            console.log(error);
        }
    }
    savePurchaseReturn = async (purchaseReturn) => {

        try {
            const status = await apiAgent.puchaseBill.savePurchaseReturn(purchaseReturn);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    purchasereturndetailsJson = async (id?: number) => {

        try {
            const purchaseBillDetails = await apiAgent.puchaseBill.purchasereturndetailsJson(id);
            return JSON.parse(purchaseBillDetails)
        }
        catch (error) {
            console.log(error);
        }
    } 
    purchasereturndetailsprint = async (id?: number) => {

        try {
            const purchaseBillDetails = await apiAgent.puchaseBill.purchasereturndetailsprint(id);
            return JSON.parse(purchaseBillDetails)
        }
        catch (error) {
            console.log(error);
        }
    }
    // 
    getPurchaseBillSearch = async (supplierId) => {

        try {
            const purchaseBillDetails = await apiAgent.puchaseBill.getPurchaseBillSearch(supplierId);

            runInAction(() => {
                this.puchaseBillDetails = purchaseBillDetails;
            })
            return this.puchaseBillDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPurchaseBillDetails = async (billMasterID) => {

        try {
            const puchaseDetails = await apiAgent.puchaseBill.getPurchaseBillDetails(billMasterID);

            runInAction(() => {
                this.puchaseDetails = puchaseDetails;
            })
            return this.puchaseDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default PurchaseBillStore;