import React from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import App from './App';
import DashBoard from './DashBoard';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  <BrowserRouter>
    <App/>
  </BrowserRouter>
  </React.StrictMode>
);


reportWebVitals();
