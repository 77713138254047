
function PackingPlanValidation(machineMasterDet) {
  let errors: any = {};
  if (!machineMasterDet.packingdate) {

    errors.packingdate = "Enter Date";
  }
  if (!machineMasterDet.teamid) {
    errors.teamid = "Select Team";
  }
  if (!machineMasterDet.machineid) {
    errors.machineid = "Enter Machine/Manual";
  }
  if (!machineMasterDet.productid) {
    errors.productid = "Enter Product";
  }
  if (!machineMasterDet.qty) {
    errors.qty = "Enter QTY";
  }
  if (!machineMasterDet.unitid) {
    errors.unitid = "Enter UOM";
  }
  return errors;
};

export default PackingPlanValidation;