import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const LoosedConversionDetail = () => {

    const { warehouseStores, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate()

    const [assignOrderSearchOptions, setAssignOrderSearchOptions] = useState<any>({})
    const [loosedConversionDetail, setLoosedConversinDetail] = useState<any[]>([])
    const [loosedConversionItems, setLoosedConversionItems] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isDetail, setDetail] = useState(false);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/LoosedConversion');
    }

    async function loadDetails(id) {
        const loosedConversionItems = await warehouseStores.loosedConversionDetailJSON(id)
        setLoosedConversionItems([])
        setLoosedConversionItems([...loosedConversionItems])
        setDetail(true)
    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadingData() {
        setLoading(false)
        const [userRights, loosedConversionDetail] = await Promise.all([userCreationStore?.getUserRight('Loosed_conversion'), warehouseStores.loosedConversionDetailJSON()])
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        setLoosedConversinDetail([...loosedConversionDetail])
    }

    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadingData();
            isInitialRender.current = false;
        }
        return () => { };
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (loosedConversionDetail && loosedConversionDetail?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(loosedConversionDetail)
        }
    }, [loosedConversionDetail])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(loosedConversionDetail?.slice()?.sort((a, b) => b.EntryNo - a.EntryNo), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <>
                    <div className='container-fluid'>
                        <div className='vertical-space-20'></div>
                        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={loosedConversionDetail}
                            searchTitles={[{ key: "ledgerGroupName", value: "Group Name" }, { key: "subGroup", value: "Sub Group" }, { key: "under", value: "Under Group" }]}
                            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                        <div className='vertical-space-20'></div>
                        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='btnBox'>
                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{loosedConversionDetail?.length} Records</button>
                            </div>
                            <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                            </Stack>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='tableListDetails'>
                            <table className="tables table-striped">
                                <thead>
                                    <tr>
                                        <th scope='col'>Entry No</th>
                                        <th scope='col'>Entry Date</th>
                                        <th scope='col'></th>
                                        <th scope='col'>Create </th>
                                        <th scope='col'>Update </th>
                                        <th scope='col'>Delete </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                        (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val?.EntryNo}</td>
                                                    <td>{moment(val.EntryDate).format('DD-MMM-YYYY')}</td>
                                                    <td><button disabled={!userRights?.view} onClick={() => loadDetails(val?.EntryNo)}>Detail</button></td>
                                                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                </tr>
                                            )
                                        }
                                        )}
                                </tbody>
                            </table>
                        </div>
                        <Modal show={isDetail} onHide={() => setDetail(false)} className='PriceHistoryModel'>
                            <Modal.Body>
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Item Name</th>
                                                <th scope="col">Barcode</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Qty</th>
                                                <th scope="col">UOM</th>
                                                <th scope='col'>Converted Item</th>
                                                {/* <th scope='col'>Barcode</th> */}
                                                <th scope='col'>Batch No</th>
                                                <th scope='col'>Converted Qty</th>
                                                <th scope='col'>Converted UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ verticalAlign: 'middle' }}>
                                            {loosedConversionItems?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val?.itemname}</td>
                                                        <td>{val?.Barcode}</td>
                                                        <td>{val?.batchno}</td>
                                                        <td>{val?.Qty}</td>
                                                        <td>{val?.Unitname}</td>
                                                        <td>{val?.Convitemname}</td>
                                                        {/* <td>{val?.ConvertBarcode}</td> */}
                                                        <td>{val?.ConvertBatchno}</td>
                                                        <td>{val?.ConvertedQty}</td>
                                                        <td>{val?.convunitname}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </>}
        </>
    )
}

export default LoosedConversionDetail;