import React, { useEffect, useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useEcomStores from '../hooks/index';
import { ILogin, IVerifyOTP } from './model';
import { encryptData } from '../Helper';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';

const Forgot = observer((): JSX.Element => {
	const [isLoading, setLoading] = useState(false);
	const [isShowGetOTP, setShowGetOTP] = useState(true);
	const [isShowVerifyOTP, setShowVerifyOTP] = useState(false);
	const [forgotPassword, setForgotPassword] = useState<IVerifyOTP>({});
	const { ecomMemberStore } = useEcomStores();
	const [errors,setErrors]=useState<any>({});
	const navigate = useNavigate();
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setForgotPassword({ ...forgotPassword, [name]: value })
	}

	const handleVerifyOTP = async (e) => {
		e.preventDefault();
    const status = await ecomMemberStore.verifyOTP(forgotPassword);
    if (status.message === 'OTP Verified') {
      setShowVerifyOTP(false);
    }
    else
    {
		setShowVerifyOTP(true)
    }
	}

	const handleSubmit =async (e) => {
		e.preventDefault();
		if(forgotPassword.email !==forgotPassword.confirmEmail){
			
			errors.confirmEmail='Passwords does not match!';
			setErrors(errors);
		  }
		  else
		  {
			forgotPassword.confirmEmail = encryptData(forgotPassword.confirmEmail)
			const status = await ecomMemberStore.UpdateEmail(forgotPassword);
			if (status.message === 'Success') {
			  setLoading(false);
			  navigate('/')
			}
		  }
	}
	const handleGetOtp = async (e) => {
		
		e.preventDefault()
		const memberData = await ecomMemberStore.checkEmailExist(forgotPassword.emailId);
		if(memberData!==undefined){
			forgotPassword.memberId = memberData.memberId;
		setForgotPassword(forgotPassword);
			setShowGetOTP(false);
			setShowVerifyOTP(true)
		}
		
	}

	return (
		<>
			{
				isLoading ? <div className='SpinnerBox'>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading...</span>
					</Spinner>
				</div> :
					<>
						<div className="Login">
							<div className='vertical-space-20'></div>
							<div className='outletInputField inputFormBox'>
								<div className='hrBox'>
									<h3>Forgot  Password</h3>
								</div>
								<div className='inputBoxLists'>
									<div className='row'>
										<div className='col-md-8'>
											<div className='inputBox'>
												<label>Enter Email <span>*</span></label>
												<input  type="text" className='' style={{ width: "100%" }} placeholder='Email Address..'
													name='emailId' value={forgotPassword.emailId} onChange={handleInputChange}
													disabled={!isShowGetOTP}
												/>
											</div>
											<div className='vertical-space-10'></div>
										</div>
										{
											isShowGetOTP ? <div className='btnBox'>
												<button className='verifybtn' onClick={handleGetOtp}><span>Get OTP</span></button>
											</div> : null
										}
										{

											isShowVerifyOTP ?
												<>
													<div className='col-md-8'>
														<div className='inputBox'>
															<label>Verify OTP <span>*</span></label>
															<input  type="text" className='' style={{ width: "100%" }} placeholder='OTP..'
																name='otp' onChange={handleInputChange}
															/>
														</div>
														<div className='vertical-space-10'></div>
													</div>
													<div className='btnBox'>
														<button className='verifybtn' onClick={handleVerifyOTP}><span>Verify</span></button>
													</div>
												</>
												: null

										}


									</div>

									<div className='row'>
										<div className='col-md-6'>
											<div className='inputBox'>
												<label>Enter Password <span>*</span></label>
												<input  type="Password" className=''
													name='email'
													style={{ width: "100%" }} placeholder='Password..'
													onChange={handleInputChange}></input>
											</div>
											<div className='vertical-space-10'></div>
										</div>
										<div className='col-md-6'>
											<div className='inputBox'>
												<label>Enter Confirm Password <span>*</span></label>
												<input  type="Password" className=''
													name='confirmEmail'
													style={{ width: "100%" }} placeholder='Confirm Password..'
													onChange={handleInputChange}></input>
											</div>
											<div className='vertical-space-10'></div>
										</div>
										<div className='btnBox'>

											<button className='dfBtn' type='submit' onClick={handleSubmit}><span>Submit</span></button>
										</div>
									</div>

								</div>
							</div>
						</div>
					</>
			}

		</>

	);
})

export default Forgot;
