import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';
import { Denomination, ICounterOpenEntry, ICounterOpeningSave } from './model';
import counterOpenValidation from './validation';
import Cookies from 'js-cookie';

function CounterOpeningEntry() {
  const denominations: Denomination[] = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 5, label: "5" },
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
    { value: 500, label: "500" },
    { value: 2000, label: "2000" }
  ]

  const [counterOpenEntry, setCounterOpenEntry] = useState<ICounterOpeningSave>({});

  const [denominationQtyTotal, setDenominationQtyTotal] = useState<Record<number, number>>(
    Object.fromEntries(denominations.map(denomination => [denomination.value, 0]))
  )
  const { denominationid } = useParams();
  const navigate = useNavigate();
  const { supplierMasterStore, posMasterStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [model, setModel] = useState(false);
  const [error, setErrors] = useState<any>({})
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const modelShow = () => setModel(true);
  const modelClose = () => {
    setCounterOpenEntry({
      ...counterOpenEntry,
      denomationAmount: calculateTotal(),
      counter_opening_details_denomation: Object.entries(denominationQtyTotal).map(
        ([label, value]) => ({
          denomationno: Number(label),
          denomationCount: value,
          denomationAmount: value * Number(label),
          denomationdid: 0
        })
      ) as any
    })
    setModel(false);
  };

  const handleCancel = () => {
    navigate('/Admin/CounterOpeningEntry/CounterOpeningEntryDetail')
  }

  async function handleSubmit(e) {
    e.preventDefault();
    let error: any = {};
    error = counterOpenValidation(counterOpenEntry);
    setErrors(error)


    let status: string = '';

    if (Object.keys(error).length === 0) {
      setLoading(true)

      if (denominationid != undefined) {
        counterOpenEntry.denomationmid = Number(denominationid);
      } else {
        counterOpenEntry.denomationmid = 0;
      }

      status = await posMasterStore.submitCounterOpeningSave(counterOpenEntry);

      if (status === 'Success') {
        setLoading(false);
        setSuccessModal(true);
      }
      else {
        setLoading(false);
        setFailureModal(true);
      }

    }
  }

  const handlePOSCounter = (val, counterId) => {
    setCounterOpenEntry({ ...counterOpenEntry, denomationCounterid: val.counterNumber });
  }

  const handleInputchange = (e) => {
    const { name, value } = e.target;
    setCounterOpenEntry({ ...counterOpenEntry, [name]: value });
  }

  async function fetchPreLoadingData() {
    setLoading(false);
    if (denominationid != undefined) {
      let denomination = {
        denominationid: denominationid
      }
      const counterEntryData = await posMasterStore.getCounterEntryUpdateDetails(denomination);
    } else {
      await posMasterStore.getPosCounter();
    }
  }

  function handleQuantityChange(value: number, quantity: number) {
    setDenominationQtyTotal((prevQuantities) => ({
      ...prevQuantities,
      [value]: quantity
    }))
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  function calculateTotal() {
    return Object.entries(denominationQtyTotal).reduce((total, [value, quantity]) => total + Number(value) * quantity, 0)
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    handleCancel()
    setSuccessModal(false)
  };

  // const isCurrenPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchPreLoadingData();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Courier_opening_entry';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();

              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/CounterOpeningEntry/CounterOpeningEntryDetail')
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <div className='container'>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox '>
          <div className='hrBox'>
            <h3>Counter Opening Entry</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='row'>
                {
                  isLoading ?
                    <div className='SpinnerBox'>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div> : null
                }
                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label> Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date=""
                      name='denomationDate' value={counterOpenEntry.denomationDate = counterOpenEntry.denomationDate ?? new Date().toISOString().substr(0, 10)} onChange={handleInputchange}
                      data-date-format="DD MMMM YYYY"></input>
                    {error.denomationDate && <p style={{ color: 'red' }}>{error.denomationDate}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label> Time <span>*</span></label>
                    <input type="time" style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date=""
                      name='denomationTime' value={counterOpenEntry.denomationTime} onChange={handleInputchange}
                      data-date-format="DD MMMM YYYY"></input>
                    {error.denomationTime && <p style={{ color: 'red' }}>{error.denomationTime}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-10'>
                  <div className='inputBox'>
                    <label>Counter No <span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={posMasterStore.posCounter}
                      getOptionLabel={(option: any) => option.counterNumber}
                      onChange={(event, val) => handlePOSCounter(val, '')}
                      loading={true}
                      renderInput={(params: any) =>
                        <TextField  {...params} style={{ width: '100%' }}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select Counter No..'
                          name='counterNo'

                        />}
                    />
                    {error.counterNumber && <p style={{ color: 'red' }}>{error.counterNumber}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-4'>
                  <div className='inputBox'>
                    <label>Amount <span>*</span></label>
                    <input type="text" className='' style={{ width: "100%" }}
                      name='denomationAmount' value={calculateTotal()} onChange={handleInputchange}
                      placeholder='Amount..' readOnly></input>
                    {error.denomationAmount && <p style={{ color: 'red' }}>{error.denomationAmount}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-4'>
                  <button onClick={modelShow} className='dfBtn' style={{ width: "100%" }}> Denomination</button>
                </div>
              </div>
            </div>
          </div>

          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
        <Modal show={model} onHide={modelClose} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Denomination Window</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='CustomerDetail'>
              <div className='tableBox'>
                <table className="table">
                  <tbody>
                    {denominations?.map((denomination) => {
                      return (
                        <tr key={denomination.value}>
                          <td>{denomination.label}</td>
                          <td>
                            <input value={denominationQtyTotal[denomination.value]} style={{ maxWidth: "72px" }} type="text" min="0" onChange={(e) => handleQuantityChange(denomination.value, Number(e.target.value))} />
                          </td>
                          <td>{denomination.value * denominationQtyTotal[denomination.value]}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className='flex justifyContentEnd pd-12-16'>
                  {calculateTotal()}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../../image/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../../image/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default CounterOpeningEntry;