import apiAgent from "../../../APIAgent"

class IGSTRAnnexureStore {
    getGstrAnnexureList = async (body) => {
        try {
            const gstrAnnexureList = await apiAgent.gstrAnnexure.getGstrAnnexureList(body);
            return JSON.parse(gstrAnnexureList)
        } catch (error) {
            console.log(error)
        }
    }
    
    getGstr2AnnexureList = async (body) => {
        try {
            const gstrAnnexureList = await apiAgent.gstrAnnexure.getGstr2AnnexureList(body);
            return JSON.parse(gstrAnnexureList)
        } catch (error) {
            console.log(error)
        }
    }
}

export default IGSTRAnnexureStore;