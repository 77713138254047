
function unitofmeasureValidation(unitOfMeasurement){
  let errors :any={};
  
   
    if(!unitOfMeasurement.unitName){
      errors.unitName = "Enter Unit Name";
    }
    // if(!unitOfMeasurement.unitdescription){
    //   errors.unitdescription = "Enter Unit Description";
    // }
    
  
  return errors;
};
  
  export default unitofmeasureValidation;