import { observable } from "mobx";
export interface IMember {
    memberId?: number;
    memberName?: string;
    dateofRegn?: string;
    mobileNo?: string;
    doorNo?: string;
    area?: string;
    city?: string;
    state?: string;
    country?: string;
    customertype?: string;
    customerCategory?: string;
    gender?: string;
    panNo?: string;
    aadharNumber?: string;
    dob?: string;
    anniversaryDate?: string;
    age?: number;
    email?: string;
    pinCode?: number;
    gstIN?: string;
    registrationType?: string;
}
export interface IMemberSearchoptions {
    searchOption?: string;
    searchValue?: string;
}
const newMemberDetails = observable<IMember>({
    memberId: 0,
    memberName: '',
    dateofRegn: '',
    mobileNo: '',
    doorNo: '',
    area: '',
    city: '',
    state: '',
    country: '',
    customertype: '',
    customerCategory: '',
    gender: '',
    panNo: '',
    aadharNumber: '',
    dob: '',
    anniversaryDate: '',
    age: 0,
    email: '',
    pinCode: 0,
    gstIN: '',
    registrationType: ''
});

export default newMemberDetails;

export class IExcelSheetData {
    Date_of_Regn?: string = ""
    Member_Name?: string = ""
    Membership_Plan?: string = ""
    Mobile_no?: string = ""
    Door_no?: string = ""
    Area?: string = ""
    City?: string = ""
    State?: string = ""
    Pincode?: number = 0
    Country?: string = ""
    Date_of_birth?: string = ""
    Age?: string = ""
    Anniversary_Date?: string = ""
    Gender?: string = ""
    Pan_number?: string = ""
    Aadhar_number?: string = ""
    Email_id?: string = ""
}

export class IIExceluploadModal {
    impsno?: 1
    member_import?: IIExcelUpload[] = []
}

export class IIExcelUpload {
    dateofRegn?: string = ""
    membername?: string = ""
    membershipPlan?: string = ""
    mobileno?: string = ""
    doorno?: string = ""
    area?: string = ""
    city?: string = ""
    state?: string = ""
    pin?: number = 0
    country?: string = ""
    dateofbirth?: string = ""
    age?: string = ""
    anniversarydate?: string = ""
    gender?: string = ""
    pannumber?: string = ""
    aadharnumber?: string = ""
    emailid?: string = ""
}

export function excelKeyupdate(data: IExcelSheetData[]) {
    let excelArr: IIExcelUpload[] = []
    excelArr = data?.map((each: IExcelSheetData) => {
        let excleObj: IIExcelUpload = {
            area: each?.Area?.toString(),
            city: each?.City,
            country: each?.Country,
            aadharnumber: each?.Aadhar_number?.toString(),
            doorno: each?.Door_no?.toString(),
            age: each?.Age?.toString(),
            anniversarydate: each?.Anniversary_Date,
            mobileno: each?.Mobile_no?.toString(),
            dateofbirth: each?.Date_of_birth,
            pin: isNaN(Number(each.Pincode)) ? Number(each.Pincode) : 0,
            state: each?.State,
            dateofRegn: each?.Date_of_Regn,
            emailid: each?.Email_id,
            gender: each?.Gender,
            membername: each?.Member_Name,
            membershipPlan: each?.Membership_Plan,
            pannumber: each?.Pan_number
        }
        return excleObj
    })
    return excelArr;
}