import { action, set, makeObservable, observable, runInAction } from 'mobx';
import OutletManagerDetails, { IDespatchEntry } from '../model/index';
import apiAgent from '../../../APIAgent';

class DespatchStore {
    
    @observable OutletManagerList= new Map();
    isOutletManagerRequest = true;
    despatchEntryList= new Map();
     despatchEntryDetail = new Map();
     loaddespatchitem = new Map();
     despatchitem =new Map();
   @observable entryNo = new Map();
   

     saveDespatchEntry = async (DespatchEntry: IDespatchEntry) => {
        
        try {
          
          const status=  await apiAgent.DespatchEntry.saveDespatchEntry(DespatchEntry);
          if(status==="Success"){
            
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.adminModule.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }
    getDespatchEntry=async ()=> {
        
        try {
          const getDespatchEntry= await apiAgent.DespatchEntry.getDespatchEntry();
            
            runInAction(() => {
                
                this.despatchEntryList = getDespatchEntry;
            });
            return this.despatchEntryList;
        }
        
        catch (error) {
            console.log(error);
        }
    }
    getDespatchEntryDetails=async (orderdespatchid)=> {
        
        try {
          const getDespatchEntry= await apiAgent.DespatchEntry.getDespatchEntryDetails(orderdespatchid);
            
            runInAction(() => {
                
                this.despatchEntryDetail = getDespatchEntry;
            });
            return this.despatchEntryDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDespatchEntrySearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.DespatchEntry.getDespatchEntrySearch(SearchOptions);
            this.despatchEntryList = Data;
            
            runInAction(() => {
                this.despatchEntryList = Data;
                
            })
            return this.despatchEntryList;       
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDespatchEntryDetail = async (orderdespatchdid) => {
        
        try {      
        const status=  await apiAgent.DespatchEntry.deleteDespatchEntryDetail(orderdespatchdid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDespatchEntryMaster = async (orderdespatchid) => {
        try {      
        const status=  await apiAgent.DespatchEntry.deleteDespatchEntryMaster(orderdespatchid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loaddespatchitemdetails=async (orderMasterid)=> {  
        try {
            
          const despatchitem= await apiAgent.DespatchEntry.loaddespatchitemdetails(orderMasterid);
            
          runInAction(() => {
            this.despatchitem = despatchitem;
            
        });
            return this.despatchitem;
        }
        catch (error) {
            console.log(error);
        }
    }
    loaddespatchitemdetailsBatch=async (orderMasterid)=> {  
        try {
            
          const despatchitem= await apiAgent.DespatchEntry.loaddespatchitemdetailsBatch(orderMasterid);
            
          runInAction(() => {
            this.despatchitem = despatchitem;
            
        });
            return this.despatchitem;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default DespatchStore;