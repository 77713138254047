import React, { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from "react-bootstrap";
import SelectSearchCriteria from "../../common/shared/selectSearchCriteria";
import useStores from "../../hooks";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { currentPageDatas, removeDuplicates } from "../../common/shared/utils";
import { Pagination, Stack } from "@mui/material";
import { Button, Image, Modal } from 'react-bootstrap';

const MembershipPlanList = () => {
    const { memberStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();

    const [memberPlanList, setMemberPlanList] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [isLoading, setLoading] = useState(false);
    const [showDetails, setDetails] = useState(0)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    function handleCreate() {
        navigate(`/Admin/MembershipPlan`)
    }

    const editMemberDetials = (memberplanId) => {
        navigate('/Admin/MembershipPlan/' + memberplanId);
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchMembershipList();
    }

    async function fetchMembershipList() {
        const userRights = await userCreationStore?.getUserRight('Membership_plan')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const membershipPlanList = await memberStore.getMembershipPlan(0);
        setMemberPlanList([...membershipPlanList])
        setLoading(false)
    }

    const deleteMemberDetials = async (membershipid) => {
        setLoading(true);
        const status = await memberStore.deleteMembershipPlan(membershipid);
        if (status === 'Success') {
            fetchMembershipList();
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchMembershipList();
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (memberPlanList && memberPlanList.length) {
            goToPage(1)
        } else {
            setCurrentPageData(memberPlanList)
        }
    }, [memberPlanList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(removeDuplicates(memberPlanList, 'Membershipid')?.slice()?.sort((a, b) => b?.Membershipid - a?.Membershipid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {isLoading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> :
                <>
                    <div className='container-fluid'>
                        <div className='vertical-space-20'></div>
                        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={memberPlanList}
                            searchTitles={[{ key: "PlanName", value: "Plan Name" }]}
                            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                        <div className='vertical-space-20'></div>
                        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className='btnBox'>
                                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(memberPlanList, 'Membershipid')?.length} Records</button>
                            </div>
                            <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                            </Stack>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='tableListDetails'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Plan Name</th>
                                        <th scope="col">Effect. Date</th>
                                        <th scope="col"></th>
                                        <th scope="col">Create</th>
                                        <th scope="col">Update</th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                        (isSearch ? filteredData : currentPageData).map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val.PlanName}</td>
                                                    <td>{moment(val.Effectdate).format("DD-MMM-YYYY")}</td>
                                                    <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => setDetails(val?.Membershipid)}>Details</button></td>
                                                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editMemberDetials(val.Membershipid)} /></button></td>
                                                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteMemberDetials(val.Membershipid)} /></button></td>
                                                </tr>
                                            )
                                        }
                                        )}
                                </tbody>
                            </table>
                        </div>

                        <Modal show={showDetails != 0} onHide={() => setDetails(0)} className='PriceHistoryModel'>
                            <Modal.Header closeButton>
                                <Modal.Title>Detail</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='tableBox w-max-content'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {/* <th scope="col">Plan Name</th>
                                                <th scope="col">Effect. Date</th> */}
                                                <th scope="col">Duration</th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {((isSearch ? filteredData : memberPlanList).filter((obj) => obj?.Membershipid === showDetails))?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        {/* <td>{val.PlanName}</td>
                                                        <td>{moment(val.Effectdate).format("DD-MMM-YYYY")}</td> */}
                                                        <td>{val.Duration}</td>
                                                        <td>{val.Amount}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Deleted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>

                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>

                
            }
        </>
    )
}

export default MembershipPlanList;