
function LedgerSearchValidation(ledgerGroup) {
  let errors: any = {};
  if (!ledgerGroup.searchDatefrom) {
    errors.searchDatefrom = "Enter From Date";
  }
  if (!ledgerGroup.searchDateto) {
    errors.searchDateto = "Enter To Date";
  }
  if (!ledgerGroup.ledgername) {
    errors.ledgername = "Select Ledger Name";
  }
  return errors;
};

export default LedgerSearchValidation;