import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, faUpload } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IWarehouseSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const WarehouseList = observer((): JSX.Element => {
  const { warehouseStores, userCreationStore } = useStores();
  const navigate = useNavigate();

  const [userRights, setRightsObj] = useState<any>({})

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const editWarehouse = (warehouseId) => {
    warehouseStores.getWarehouseDetails(warehouseId);
  }

  const deleteWareHouseDetials = async (warehouseId) => {
    setLoading(true);
    const status = await warehouseStores.deleteWarehouse(warehouseId);
    if (status === 'Success') {
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  const handleCreate = () => {
    navigate('/Admin/Warehouse');
  }

  async function fetchPreLoadngData() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Warehouse_registration'), warehouseStores?.getWarehouse()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (warehouseStores?.warehouseList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(warehouseStores?.warehouseList)
    }
  }, [warehouseStores?.warehouseList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(warehouseStores?.warehouseList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='outletInputField inputFormBox LgInputField'>
              <div className='container-fluid'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={warehouseStores?.warehouseList}
                  searchTitles={[{ key: "warehouseName", value: "Warehouse Name" }, { key: "fssaiNumber", value: "FSSAI No" }, { key: "city", value: "City" }]}
                  emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-sm-5'>
                      <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{warehouseStores?.warehouseList?.length} Records</button>
                      </div>
                    </div>
                    <div className=' col-sm-7' >
                      <Stack spacing={2}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Warehouse Name</th>
                        <th scope="col">FSSAI No</th>
                        <th scope="col">Date of Incorporated</th>
                        <th scope="col">Door no, Street</th>
                        <th scope="col">Area</th>
                        <th scope="col">City</th>
                        <th scope="col">State</th>
                        <th scope='col'>Pincode</th>
                        <th scope="col">Create</th>
                        <th scope="col">Update</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(!warehouseStores.isLoading && (isSearch ? filteredData : currentPageData)?.length > 0) &&
                        (isSearch ? filteredData : currentPageData).map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.warehouseName}</td>
                              <td>{val.fssaiNumber}</td>
                              <td>{val.dateOfIncrorpeted}</td>
                              <td>{val.doorNoStreet}</td>
                              <td>{val.area}</td>
                              <td>{val.city}</td>
                              <td>{val.state}</td>
                              <td>{val?.pincode}</td>
                              <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                              <td><button disabled={!userRights?.edit} className='edit'><FontAwesomeIcon icon={faFileEdit} onClick={() => editWarehouse(val.warehouseId)} /></button></td>
                              <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteWareHouseDetials(val.warehouseId)} /></button></td>
                            </tr>
                          )
                        }
                        )}
                    </tbody>
                  </table>
                </div>

                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>
                      <h4>Succesfully Deleted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>
      }

    </>
  )
})

export default WarehouseList