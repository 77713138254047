import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import moment from 'moment';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { IItemInwardSearchoptions } from './model';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const ItemInwardDetail = observer((): JSX.Element => {
  const [inwardSearchOptions, setInwardSearchOptions] = useState<IItemInwardSearchoptions>({})
  const { inwardStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getInwardMaster, itemInwardList, getInwardDetails, iteminwardDetails, getLedgers, ledgers } = inwardStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isInwardDetails, setInwardDetailsModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLedgers, setLedgers] = useState(false);

  const [inwardDetail, setInwardDetail] = useState<any>({})

  const [searchValue, setSearchValue] = useState('');

  const filteredData = useMemo(() => {
    if (searchValue != '' && searchValue?.length > 0 && itemInwardList?.length > 0) {
      return itemInwardList?.filter((stock) => {
        if (stock[inwardSearchOptions?.searchOption!]?.toString()?.toLowerCase().includes(searchValue?.toLowerCase())) {
          return stock;
        }
      })
    } else {
      return [];
    }
  }, [searchValue])

  const netAmount = useMemo(() => {

    //calculate net amont
    let totalNetAmount: number = iteminwardDetails?.length ? iteminwardDetails.reduce((a, v) => a = a + v.inwardTotalValue!, 0) : 0;
    if (!ledgers?.length) {
      if (inwardDetail.discountAmount && totalNetAmount && inwardDetail?.discountType !== "None") {
        return Math.round(totalNetAmount - (inwardDetail?.discountAmount ?? 0))
      } else {
        return Math.round(totalNetAmount)
      }
    } else if (ledgers?.length) {
      return Math.round(totalNetAmount + ledgers?.reduce((a, v) => a = a + v.ledgerAmount!, 0) - Number(inwardDetail.discountAmount ?? 0))
    }
    return 0;
  }, [ledgers?.length, inwardDetail, iteminwardDetails])


  const handleChangeclear = () => {
    setSearchValue('');
    setInwardSearchOptions({ ...inwardSearchOptions, searchOption: '' });
  }

  const deleteInwardMaster = async (inwardNo) => {
    setLoading(true);
    const status = await inwardStore.deleteInwardMaster(inwardNo);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteInwardDetail = async (inwardDetailNo) => {
    setInwardDetailsModal(false);
    setLoading(true);
    const status = await inwardStore.deleteInwardDetail(inwardDetailNo);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadngData();
  }

  async function fetchPreLoadngData() {
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Item_inward'), getInwardMaster()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage?.current) {
      fetchPreLoadngData();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const handleSearchInput = async (e) => {
    const { name, value } = e.target;
    setInwardSearchOptions({ ...inwardSearchOptions, [name]: value });
  }


  const editItemInwardDetials = (inwardNo) => {

  }
  const deleteLedgers = (ledgerDetlId) => {

  }

  const handleCreate = () => {
    navigate('/Admin/ItemInward')
  }

  const getInwardItem = async (inwardNo) => {
    const data = await getInwardDetails(inwardNo);
    await getLedgers(inwardNo);
    if (data !== undefined || data !== null) {
      setInwardDetailsModal(true);
    }
  }
  const handleClose = () => {
    setInwardDetailsModal(false);
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (itemInwardList && itemInwardList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(itemInwardList)
    }
  }, [itemInwardList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(itemInwardList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <div className='SearchBox col-md-8'>
                <select className="form-selected" id="Mode" name="searchOption" value={inwardSearchOptions?.searchOption} onChange={handleSearchInput}>
                  <option value="">Select Search Criteria</option>
                  <option value="supplierName">Supplier Name</option>
                  <option value="wareHouseName">Warehouse Name </option>
                </select>
                <div className="input-group mb-3">
                  <input type="text"
                    name='searchValue'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    className="form-control" placeholder="Search Here.." aria-label="Search Here" aria-describedby="search-button"></input>
                </div>
                <div className='btnBox'>
                  <button className='searchclearBtn' onClick={handleChangeclear}>Clear</button>
                </div>
              </div>
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{itemInwardList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Id</th>
                      <th scope='col'>Date</th>
                      <th scope='col'>Supplier</th>
                      <th scope='col'>Warehouse</th>
                      <th scope='col'>Bill Number</th>
                      <th scope='col'>Bill Date</th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(searchValue?.length > 0 ? filteredData : currentPageData)?.length ?
                      (searchValue?.length > 0 ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.inwardNo}</td>
                            <td>{moment(val.inwarddate).format('DD-MM-YYYY')}</td>
                            <td>{val.supplierName}</td>
                            <td>{val.wareHouseName}</td>
                            <td>{val.billNo}</td>
                            <td>{moment(val.billDate).format('DD-MM-YYYY')}</td>
                            <td><button disabled={!userRights?.view} onClick={() => { getInwardItem(val.inwardNo); setInwardDetail(val) }}>Detail</button></td>
                            <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editItemInwardDetials(val.InwardNo)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInwardMaster(val.inwardNo)} /></button></td>
                          </tr>
                        )
                      }) : null
                    }
                  </tbody>
                </table>
              </div>

              <Modal show={isInwardDetails} onHide={handleClose} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Item Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">ItemName</th>
                          <th scope="col">Bill Quantity </th>
                          <th scope="col">Free Quantity  </th>
                          <th scope="col">Mfg Date  </th>
                          <th scope="col">Exp Date  </th>
                          <th scope="col">Barcode </th>
                          <th scope="col">Batch No </th>
                          <th scope="col">MRP</th>
                          <th scope="col">Pur. Rate</th>
                          <th scope="col">Discount % </th>
                          <th scope='col'>Discount Value</th>
                          <th scope="col">GST %</th>
                          <th scope="col">GST Value</th>
                          <th scope="col">Total Value</th>
                          {/* <th scope='col'>Delete </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {iteminwardDetails?.length ? iteminwardDetails?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.inwardItemName}</td>
                              <td>{(val.inwardItemQuantity.toFixed(3))}</td>
                              <td>{val?.bundleqty ? (val?.bundleqty).toFixed(3) : (val.inwardFreeQuantiity).toFixed(3)}</td>
                              <td>{moment(val.mfg_Date)?.format("DD-MMM-YYYY")}</td>
                              <td>{moment(val.exp_Date)?.format("DD-MMM-YYYY")}</td>
                              <td>{val.barcode}</td>
                              <td>{val.batchNo}</td>
                              <td>{val.inwardItemMrpRate}</td>
                              <td>{val.inwardItemRate}</td>
                              <td>{val.inwardDisPercentage}</td>
                              <td>{val.inwardDiscountvalue}</td>
                              <td>{val.inwardGstPercentage}</td>
                              <td>{val.inwardGstValue}</td>
                              <td>{val.inwardTotalValue}</td>
                              {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteInwardDetail(val.inwardDetailNo)} /></button></td> */}
                            </tr>
                          )
                        }) : null
                        }

                      </tbody>
                    </table>
                  </div>
                </Modal.Body>

                <Modal.Header>
                  <Modal.Title>Bill Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Discount Type</th>
                          <th scope="col">Discount % </th>
                          <th scope='col'>Discount Amount </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{inwardDetail.discountType}</td>
                          <td>{inwardDetail.discount}</td>
                          <td>{inwardDetail.discountAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>

                <Modal.Header>
                  <Modal.Title>Ledger Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Id</th>
                          <th scope="col">Ledger Name </th>
                          <th scope="col">Ledger Amount </th>
                          {/* <th scope='col'>Delete </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {ledgers?.length ? ledgers?.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.ledgerDetlId}</td>
                              <td>{val.ledgerName}</td>
                              <td>{val.ledgerAmount}</td>
                              {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td> */}
                            </tr>
                          )
                        }) : null}
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <h4>Net Amount: {netAmount}</h4>
                </Modal.Footer>
              </Modal>
              {
                isLedgers ?
                  <Modal show={isLedgers} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Ledgers</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>

                              <th scope="col">Id</th>
                              <th scope="col">Ledger Name </th>
                              <th scope="col">Ledger Amount </th>
                              <th scope='col'>Delete </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ledgers?.length ?
                              ledgers?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.ledgerDetlId}</td>
                                    <td>{val.ledgerName}</td>
                                    <td>{val.ledgerAmount}</td>
                                    <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td>
                                  </tr>
                                )
                              }) : null
                            }

                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default ItemInwardDetail;
