export class IIIncrementApproval {
    eiM_ID?: number = 0
    eiM_TicketNo?: number = 0
    eiM_EmpID?: number = 0
    eiM_CatID?: number = 0
    eiM_DeptID?: number = 0
    eiM_DegnID?: number = 0

    employeeincrementdet?: IIEmployeeIncrementDet[] = []
    employeeincrementsaldet?: IIEmployeeIncrementSalDet[] = []
}

export class IIEmployeeIncrementDet {
    eiD_Date?: string = new Date()?.toISOString()?.substr(0, 10)
    eiD_EffDate?: string = new Date()?.toISOString()?.substr(0, 10)
    eiD_SalType?: string = ""
    eiD_ID?: number = 0
}

export class IIEmployeeIncrementSalDet {
    eiS_ID?: number = 0
    eiS_GrossSal?: number = 0
    eiS_EarnType?: string = ""
    eiS_Amount?: number = 0
}