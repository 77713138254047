import { getCurrentMonthFirstDate } from "../../../common/shared/utils"

export class IIJobMaster {
    jobid?: number = 0
    jobcode?: string = ""
    jobtitle?: string = ""
    jobDescription?: string = ""
}

export class IIJobVacancy {
    jvid?: number = 0
    jvdate?: string = getCurrentMonthFirstDate()?.currentDate
    jvcode?: string = ""
    jtitle?: string = ""
    jDescription?: string = ""
    worklocation?: string = ""
    workmanagerid?: number = 0
    noofPosision?: string = ""
    experience_Required?: string = ""
    skills_Required?: string = ""
    qualification?: string = ""
    createdBy?: number = 0
    remark?: string = ""
    status?: string = "No"
}

export class IIVacancyApproval {
    jvid?: number = 0
    jvdate?: string = ""
    jvcode?: string = ""
    jtitle?: string = ""
    jDescription?: string = ""
    worklocation?: string = ""
    workmanagerid?: number = 0
    noofPosision?: string = ""
    experience_Required?: string = ""
    skills_Required?: string = ""
    qualification?: string = ""
    createdBy?: number = 0
    remark?: string = ""
    status?: string = ""
}