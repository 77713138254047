import React, { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { Autocomplete, Pagination, Stack, TextField } from "@mui/material";
import { Accordion } from "react-bootstrap";
import { currentPageDatas, removeDuplicates } from "../../common/shared/utils";
import useStores from "../../hooks";
import { IISuccessApproval } from "./model";

const SelectionApproval = () => {

    const navigate = useNavigate();

    const handleFailureModalClose = () => setFailureModal(false);

    const { jobMasterStore, commonStore, interviewStore } = useStores();
    const { loadEmployee, loadEmployees } = commonStore;

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isModal, setModal] = useState({ bool: false, index: 0 })

    const [approvedBy, setApprovedBy] = useState("")
    // const [selectionTypes, setSelectionType] = useState({ type: 'none', value: 'None' })

    const [selectionApproval, setSelectionApproval] = useState<IISuccessApproval[]>(new Array<IISuccessApproval>())

    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])
    const [selectionProcessList, setSelectionProcessList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const handleSuccessModalClose = () => {
        navigate('/Admin/SelectionApproval/List');
    }

    async function handleSaveCategory() {
        let error: any = {};
        if (selectionApproval?.length && approvedBy) {
            if (selectionApproval?.some((obj) => obj?.selectionType && (obj?.selectionType != ""))) {
                setLoading(true)
                const entryNo = await jobMasterStore.getHREntryNo('Selectionapproval');
                selectionApproval?.forEach((each) => {
                    if (each?.intprocessid) {
                        each.intselectionappid = Number(entryNo?.entrynumber);
                        each.approvedBy = approvedBy;
                    }
                })
                // const status = 'Sucess';
                const status = await interviewStore.saveSelectionApproval(selectionApproval);
                if (status === 'Sucess') {
                    fetchOutletItemData()
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            } else {
                let find = selectionApproval?.find((obj) => obj?.selectionType === "")
                error = {
                    Intselectionid: find?.intselectionid,
                    selectionType: 'Select Selection Type',
                }
            }
        } else {
            if (!approvedBy) {
                error.approvedBy = "Select Approved By";
            }
            if (!selectionApproval?.length) {
                error.selectProcess = 'Check Selection Process to Submit';
            }
        }
        setErrors({ ...error })
    }

    function handleUpdateStatus(val, each) {
        let error: any = {};
        if (!selectionApproval?.some((obj) => obj?.intselectionid === each?.Intselectionid)) {
            error.Intselectionid = each?.Intselectionid;
            error.checkfirst = 'Check Approval status';
        } else {
            selectionApproval?.forEach((obj) => {
                if (obj?.intselectionid === each?.Intselectionid) {
                    obj['selectionType'] = val?.value;
                }
                return obj;
            })
            currentPageData?.forEach((obj) => {
                if (obj?.Intselectionid === each?.Intselectionid) {
                    obj['selection'] = val;
                }
                return obj;
            })
            setCurrentPageData([...currentPageData])
            setSelectionApproval([...selectionApproval])
        }
        setErrors({ ...error })
    }

    function handleCheckBox(event, value) {
        if (!selectionApproval?.some((each) => each?.intselectionid === value?.Intselectionid) && event.target.checked) {
            let obj: IISuccessApproval = {
                intselectionid: value?.Intselectionid ?? 1,
                intprocessid: value?.Intprocessid,
                jobcode: Number(value?.Jobcode),
                interviewdate: value.Interviewdate,
                callletterno: Number(value?.Callletterno),
                empid: Number(value?.Empid),
                feedback: value?.Feedback,
            }
            setSelectionApproval([...selectionApproval, obj])
        } else {
            let findIndex = selectionApproval?.findIndex((each) => each?.intselectionid === value?.Intselectionid)
            if (findIndex >= 0) {
                selectionApproval?.splice(findIndex, 1)
                setSelectionApproval([...selectionApproval])
            }
        }
    }

    const fetchOutletItemData = async () => {
        const processList = await interviewStore.loadInterviewSelectionMaster()
        if (processList?.length) {
            setSelectionProcessList([...processList])
        }
        const interviewletter = await interviewStore.loadInterviewLetter()
        if (interviewletter?.length) {
            setInterviewLetterList([...interviewletter])
        }
        await loadEmployee()
        setLoading(false);
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            fetchOutletItemData()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (selectionProcessList && selectionProcessList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(selectionProcessList)
        }
    }, [selectionProcessList])

    const goToPage = (value: number) => {
        let sortedData = selectionProcessList?.slice()?.sort((a, b) => b.Intselectionid - a.Intselectionid)
        const currentPageList = currentPageDatas(removeDuplicates(sortedData, 'Intselectionid'), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Selection Process Approval</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Date <span>*</span></label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="fromdate"
                                                value={new Date()?.toISOString()?.substr(0, 10)}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}> Approved By<span>*</span> </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={removeDuplicates(loadEmployees, 'employeeName')}
                                                getOptionLabel={(option: any) => option.employeeName}
                                                onChange={(event, val) => setApprovedBy(val?.employeeName)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params} style={{ width: '36ch' }}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Ledger..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        {errors?.approvedBy && <p style={{ color: 'red' }}>{errors?.approvedBy}</p>}
                                    </div>
                                </div>
                            </div>
                            {/* <div className='vertical-space-20'></div> */}
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(selectionProcessList, 'Intprocessid')?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            {/* <div className='vertical-space-20'></div> */}
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Select</th>
                                            <th scope="col">Process Id</th>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Call Letter No</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Mobile No</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Selection Type</th>
                                            <th scope="col">Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData?.map((each, index) => {
                                            return each?.Intselectionid > 0 && (
                                                <tr key={index}>
                                                    <td>
                                                        <input type="checkbox" onChange={(event) => handleCheckBox(event, each)} />
                                                        {((errors?.Intselectionid == each?.Intselectionid) && errors?.checkfirst) && <p style={{ color: 'red' }}>{errors?.checkfirst}</p>}
                                                    </td>
                                                    <td>{each?.Intselectionid}</td>
                                                    <td>{each?.Jobcode}</td>
                                                    <td>{each?.Callletterno}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)?.Candidatename}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)?.EducationQualification}</td>
                                                    <td>{each?.Conductby}</td>
                                                    <td>
                                                        <Autocomplete size="small" disablePortal id="combo-box-demo"
                                                            options={[{ type: 'Selected', value: 'Selected' }, { type: 'Rejected', value: 'Rejected' }, { type: 'Hold', value: 'Hold' }]}
                                                            onChange={(event, val) => handleUpdateStatus(val, each)} getOptionLabel={(option: any) => option.value}
                                                            value={each?.selection}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params} style={{ width: '36ch' }}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="Selection Type..."
                                                                    name='selectionType'
                                                                />}
                                                        />
                                                        {((errors?.Intselectionid == each?.Intselectionid) && errors?.selectionType) && <p style={{ color: 'red' }}>{errors?.selectionType}</p>}
                                                    </td>
                                                    <td><button className='' onClick={() => setModal({ bool: true, index: each?.Intselectionid })}>Details</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors?.selectProcess && <p style={{ color: 'red' }}>{errors?.selectProcess}</p>}
                            </div>
                            <div className="vertical-space-20"></div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={() => handleSuccessModalClose()}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isModal?.bool} onHide={() => setModal({ bool: false, index: 0 })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Detail</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Round</th>
                                                    <th scope="col">Process</th>
                                                    <th scope="col">Next Round</th>
                                                    <th scope="col">FeedBack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectionProcessList?.map((each, index) => {
                                                    return (isModal?.index === each?.Intselectionid) && (
                                                        <tr key={index}>
                                                            <td className='capitalize'>{each?.Phaseno}</td>
                                                            <td>{each?.Process}</td>
                                                            <td>{each?.NextPhases}</td>
                                                            <td>{each?.Feedback1}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default SelectionApproval;