import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IPurchaseOrderSaveModal {
    purchase_Order_No?: string = "";
    purchase_Order_Date?: string = new Date().toISOString().substr(0, 10);
    supplier_id?: number = 0;
    supplier_name?: string = "";
    isChecked?: boolean = false;
    type?: string = "";
    despatch_to?: string = "";
    despatch_address1?: string = "";
    despatch_address2?: string = "";
    despatch_address3?: string = "";
    despatch_city?: string = "";
    despatch_state?: string = "";
    despatch_country?: string = "";
    despatch_pincode?: string = "";
    gstIn?: string = "";
    contactperson?: string = "";
    contactno?: number = 0;
    packing_forward?: string = "";
    special_instruction?: string = "";
    supplier_ref?: string = "";
    transport?: string = "";
    sub_total?: string = "";
    discount?: string | number = 0;
    tax_amount?: string | number = 0;
    round_off?: string = "";
    net_amount?: string | number = 0;
    remarks?: string = "";
    payment_terms?: string = "";
    packing_charges?: string | number = 0;
    entered_by?: number = 0;
    delivery_date?: string = new Date().toISOString().substr(0, 10);
    payment_mode?: string = "";
    povalidity_date?: string = new Date().toISOString().substr(0, 10);
    quotation_refno?: string = "";
    purchaseOrderDetails?: PurchaseOrderDetails[] = [];
    purchaseOrderSchedule?: PurchaseOrderSchedule[] = [];
    purchaseOrderTeamsandcondition?: PurchaseOrderTeamsandcondition[]
}

export class PurchaseOrderDetails {
    itemid?: number = 0;
    purchase_qty?: string = '0'
    discount_per?: string = '0'
    purchase_rate?: string = '0'
    approved_qty?: string = '0'
    itemName?: string = ''
    tax_per?: string = ""
    tax_amount?: string = ""
    itemUOM?: string = ''
    purchase_value?: number = 0
    taxLedger?: number = 0
    taxLedgerId?: number = 0
    packingLedger?: number = 0
    packingLedgerId?: number = 0
}

export class PurchaseOrderSchedule {
    gsM_Purchase_Order_Details_Id?: number = 0;
    itemid?: number = 0;
    schedule_no?: number = 0;
    delivery_date?: string = new Date()?.toISOString()?.substr(0, 10);
    schedule_qty?: string = "0"
}

export class PurchaseOrderTeamsandcondition {
    gsM_Purchase_Order_Details_Id?: number = 0;
    ternsdetails?: string = ""
}

export class ProductItemList {
    purchase_approval_no: string = "";
    indent_details_id: number = 0;
    itemid: number = 0;
    rate: number = 0;
    iteM_NAME: string = "";
    supplierName: string = "";
    uom: string = "";
    indent_qty: number = 0;
    approval_qty: number = 0;
    item_total: number = 0;
    tax_id: number = 0;
    details: string = "";
    quoteno: string = "";
    taxpercentage: number = 0;
    tax_total: number = 0;
    net_total: number = 0;
    indent_no: string = "";
    isChecked: boolean = false
}
export class IPurchaseOrderSearchOptions {
    fromDate: string = getCurrentMonthFirstDate()?.firstDate;
    toDate: string = getCurrentMonthFirstDate()?.currentDate;
    po_no: string = '';
    vendor: string = '';
}