import { IOfferDisount } from "../model";

export function manageDiscountValidation(offerDiscount, qtyofferdetail, valueOffermasterLists, coponOffermasterList) {

  let errors: any = {};

  const alpha = /[a-zA-Z]*$/;

  if (!offerDiscount.offerName) {
    errors.offerName = "Enter Offer Name";
  }
  if (!offerDiscount.offerType) {
    errors.offerType = "Select Offer Type";
  }
  if (offerDiscount.offerName !== '' && offerDiscount.offerType === 'Qty Discount') {
    if (!qtyofferdetail.from_QV) {
      errors.from_QV = "Enter From Quantity";
    }
    if (!qtyofferdetail.to_QV) {
      errors.to_QV = "Enter To Quantity";
    }
    if (!qtyofferdetail.discount_PV) {
      errors.discount_PV = "Enter Discount";
    }
    if (!qtyofferdetail.dis_Mode) {
      errors.dis_Mode = "Select Discount Mode";
    }
  }
  if (offerDiscount.offerName !== '' && offerDiscount.offerType === 'Value Discount') {
    if (!valueOffermasterLists.from_QV) {
      errors.from_QV = "Enter From Quantity";
    }
    if (!valueOffermasterLists.to_QV) {
      errors.to_QV = "Enter To Quantity";
    }
    if (!valueOffermasterLists.discount_PV) {
      errors.discount_PV = "Enter Discount";
    }
    if (!valueOffermasterLists.dis_Mode) {
      errors.dis_Mode = "Select Discount Mode";
    }
  }
  if (offerDiscount.offerName !== '' && offerDiscount.offerType === 'Coupon Discount') {
    if (!coponOffermasterList.couponName) {
      errors.couponName = "Enter Coupon Name";
    }

    if (!coponOffermasterList.discount_PV) {
      errors.discount_PV = "Enter Discount";
    }
    if (!coponOffermasterList.dis_Mode) {
      errors.dis_Mode = "Select Discount Mode";
    }
  }
  return errors;
};

export function saveValidation(offerDiscount: IOfferDisount) {
  let error: any = {}

  if (!(offerDiscount?.coponOffermasterList?.length || offerDiscount?.valueOffermasterList?.length || offerDiscount?.qtyofferdetail?.length)) {
    error.addOffers = 'Add Offers to Grid'
  }
  if (!offerDiscount.offerName) {
    error.offerName = "Enter Offer Name";
  }
  if (!offerDiscount.offerType) {
    error.offerType = "Select Offer Type";
  }

  return error;
}