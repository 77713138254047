import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import moment from "moment";
import useStores from "../../hooks";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";

class FieldsProps {
    sdate?: string = getCurrentMonthFirstDate()?.firstDate;
    edate?: string = getCurrentMonthFirstDate()?.currentDate;
    empId: number = 0
    employeeName: string = ""
    designationId: number = 0
    designationName: string = ""
}

interface ExcelSheetData {
    S_No?: string,
    Employee_Code?: string,
    Employee_Name?: string,
    Attend_Date?: string,
    In_Time?: string,
    Break_Out_Time?: string,
    Break_In_Time?: string,
    Out_Time?: string,
    OT_hours?: string
}

const StrengthRegister = () => {
    const { hrReporsStore, designationStore } = useStores()

    const [designationList, setDesignationList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);

    const [localFields, setLocalFields] = useState<FieldsProps>(new FieldsProps())
    const [strengthRegister, setSalaryRegister] = useState<any[]>([])

    function createExportExcelObj(timeSheetReports: any[]) {
        if (timeSheetReports?.length >= 1) {
            let excelArr: ExcelSheetData[] = timeSheetReports?.map((employee, index) => {
                const { Empid, Employee_Name, AttnDate, InTime, BreakOutTime, BreakInTime, OutTime, TotalHours, Present, OThours } = employee;
                const excelObj: ExcelSheetData = {
                    S_No: (index + 1)?.toString(),
                    Employee_Code: Empid,
                    Employee_Name: Employee_Name,
                    Attend_Date: moment(AttnDate)?.format('DD-MMM-YYYY'),
                    In_Time: InTime ? moment(InTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_Out_Time: BreakOutTime ? moment(BreakOutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Break_In_Time: BreakInTime ? moment(BreakInTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    Out_Time: OutTime ? moment(OutTime, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                    OT_hours: OThours ? moment(OThours, "HH:mm:ss")?.format('hh:mm a') : '00:00',
                };
                return excelObj;
            })
            // exportToExcel(excelArr)
        } else {
            alert(` No Data found `);
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Strength Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Strength Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function handleGenerate(event) {
        let error: any = {}
        if (localFields?.sdate) {
            setSpinner(true)
            const strengthRegister = await hrReporsStore?.getReportStrengthRegister(localFields?.sdate)
            if (strengthRegister?.length) {
                setSalaryRegister([...strengthRegister])
            }
            setSpinner(false)
        } else if (event) {
            if (!localFields?.sdate) {
                error.sdate = 'Select From Date'
            }
        }
        setErrors({ ...error })
    }

    async function initialApiCalls() {
        const designationList = await designationStore.getDesignation();
        setDesignationList([...designationList])
        handleGenerate(null)
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false
        }
    }, [])
    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: "60%" }}> {/*LgInputField*/}
                            <div className='hrBox'>
                                <h3>Strength Register</h3>
                            </div>
                            {isSpinner ?
                                <div className='SpinnerBox'>
                                    <Spinner animation="grow" size="sm" />
                                </div> : null}
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="sdate"
                                                value={localFields?.sdate}
                                                onChange={(event) => setLocalFields({ ...localFields, sdate: event.target.value })}
                                            ></input>
                                            {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Designation</label>
                                            <AutoComplete clsName="full-width" options={designationList} value={localFields?.designationName} getOptionLabel='designationName'
                                                emitOption={(option) => setLocalFields({ ...localFields, designationId: option?.designationId, designationName: option?.designationName })} placeholder='Select designation..'
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => createExportExcelObj(strengthRegister)}>
                                                Print
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => setLocalFields(new FieldsProps())}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>S.No</th>
                                            <th rowSpan={2}>Designation</th>
                                            <th className="text-center" colSpan={2}>On Roll Strength</th>
                                            <th className="text-center" colSpan={2}>Present Strength</th>
                                        </tr>
                                        <tr>
                                            <th>Male</th>
                                            <th>Female</th>
                                            <th>Male</th>
                                            <th>Female</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {strengthRegister?.map((each, index) => {
                                            return (!localFields?.designationName || each?.Designation === localFields?.designationName) && (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{each?.Designation}</td>
                                                    <td>{each?.OnRollMale}</td>
                                                    <td>{each?.OnRollFemale}</td>
                                                    <td>{each?.PresentMale}</td>
                                                    <td>{each?.PresentFemale}</td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <td></td>
                                            <td>GRAND TOTAL</td>
                                            <td>{strengthRegister?.reduce((a, v) => a = a + v?.OnRollMale, 0)}</td>
                                            <td>{strengthRegister?.reduce((a, v) => a = a + v?.OnRollFemale, 0)}</td>
                                            <td>{strengthRegister?.reduce((a, v) => a = a + v?.PresentMale, 0)}</td>
                                            <td>{strengthRegister?.reduce((a, v) => a = a + v?.PresentFemale, 0)}</td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                            <div className="vertical-space-20"></div>
                        </div>
                    </>
            }

        </div>
    )
}

export default StrengthRegister;