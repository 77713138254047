import React, { useEffect, useMemo, useRef, useState } from "react"
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AutoComplete from "../../common/shared/autoComplete";
import useStores from "../../hooks";
import { observer } from "mobx-react-lite";
import { Autocomplete, TextField } from "@mui/material";
import { removeDuplicates } from "../../common/shared/utils";

const B2BVerificatioin = () => {

    const { memberStore, commonStore } = useStores()
    const navigate = useNavigate();

    const { getMembershipPlan, memberPlanList, getNewmember, assignMembershipSave } = memberStore;

    const { loadEmployee, loadEmployees } = commonStore;

    const [newmemberList, setnewmemberList] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false)
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const handleFailureModalClose = () => setFailureModal(false);

    const handleCancel = () => {
        navigate('/Admin/B2BVerification/List');
    }
    const handleSuccessModalClose = () => {
        navigate('/Admin/B2BVerification/List');
    }

    function handleInputChange(e) {
        const { name, value } = e.target;
        if (name === 'effectfrom' && value != null) {
        } else if (name === "paymemntmode" && value != null) {
        }
    }

    function handleAutoComplete(name, value) {
        if (name === "memberid" && value != null) {
        } else if (name === "planid" && value != null) {
        } else if (name === "duration" && value != null) {
        }
    }

    async function saveInwardDetails() {
        let error: any = {};
        // error = SubmitValidation(assignMemberSave)
        setErrors(error)
    }

    const isInitialRender = useRef(true);

    async function fetchPreLoadngData() {
        const newMemberList = await getNewmember();
        setnewmemberList([...newMemberList])
        setLoading(false)
    }
    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadngData();
            isInitialRender.current = false;
        }
        return () => { };
    }, []);

    return (
        <> {isLoading ? <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div> :
            <>
                <div className='container'>
                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox LgInputField'>
                        <div className='hrBox'>
                            <h3>B2B Verification</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='row'>
                                {isSpinner &&
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div>}
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Member <span>*</span></label>
                                        <AutoComplete options={newmemberList} placeholder={'Member...'}
                                            emitOption={(option: any) => handleAutoComplete('memberid', option)}
                                            getOptionLabel='memberName' />
                                        {errors.memberid && <p style={{ color: 'red' }}>{errors.memberid}</p>}
                                    </div>
                                    <div className='vertical-space-20'></div>
                                </div>
                                <div className="col-md-12">
                                    <div className='LgInputListsBox mr-left-0'>
                                        <div className='tableBox'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Bill No</th>
                                                        <th scope="col">Bill Date</th>
                                                        <th scope="col">Bill Amount</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ verticalAlign: 'middle' }}>
                                                    {[]?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='vertical-space-10'></div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Pan No <span>*</span></label>
                                        <input type="text"
                                            name='panNo'
                                            style={{ width: "100%" }}
                                            placeholder="Enter Pan No"></input>
                                        {errors.panNo && <p style={{ color: 'red' }}>{errors.panNo}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>GSTIN <span>*</span></label>
                                        <input type="text"
                                            name='gstin'
                                            style={{ width: "100%" }}
                                            placeholder="Enter GSTIN"></input>
                                        {errors.gstin && <p style={{ color: 'red' }}>{errors.gstin}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='imgBox'>
                                        <div className='inputBox'>
                                            <label>Photo <span>*</span></label>
                                            <input type="file" className='' style={{ width: "60%" }} accept='image/*'
                                                name='itemImage'
                                            // onChange={handleChangeImage} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='imgBox'>
                                        <div className='inputBox'>
                                            <label>File <span>*</span></label>
                                            <input type="file" className='' multiple style={{ width: "60%" }} accept='image/*'
                                                name='itemImage'
                                            // onChange={handleChangeImage} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Approved <span>*</span></label>
                                        <select className="form-selected"
                                            // name="type"
                                            // onChange={handleLegerMaster}
                                            // value={ledgerMasters.type}
                                            style={{ width: "100%" }} id="Openingtype" >
                                            <option value="" selected>Not Select</option>
                                            <option value="Yes"> Yes</option>
                                            <option value="No">No </option>
                                        </select>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Date <span>*</span></label>
                                        <input type="date"
                                            defaultValue={new Date().toISOString().substr(0, 10)}
                                            name='damageEntryDate'
                                            // value={damageTransferFields.damageEntryDate = damageTransferFields.damageEntryDate ?? new Date().toISOString().substr(0, 10)}
                                            // onChange={handleInputchange}
                                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                        {errors.damageEntryDate && <p style={{ color: 'red' }}>{errors.damageEntryDate}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='inputBox'>
                                        <label>Remarks <span>*</span></label>
                                        <textarea className='' style={{ width: "100%" }}
                                            name='remarks'
                                            // value={purchaseBill.remarks}
                                            // onChange={handleInputChange}
                                            placeholder='Remarks..'></textarea>
                                        {errors.remarks && <p style={{ color: 'red' }}>{errors.remarks}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-10'>
                                    <div className='inputBox'>
                                        <label>Created by </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={removeDuplicates(loadEmployees, 'employeeName')}
                                            // value={loadEmployees?.length > 0 && loadEmployees?.find((obj)=>obj?.employeeId == damageTransferFields?.empId)?.employeeName}
                                            onChange={(event, val) => handleAutoComplete('createdBy', val)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params} style={{ width: '40ch' }}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Created by..'
                                                    name='employeeName'
                                                />}
                                        />
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                        <div className='btnBox'>
                            <button className='secondaryBtn' onClick={handleCancel}>List</button>
                            <button className='dfBtn' type='submit' onClick={saveInwardDetails}>Submit</button>
                        </div>

                    </div>

                    <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/checked.png')} />
                                </div>
                                <h4>Succesfully Submitted</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleSuccessModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                        <Modal.Body>
                            <div className='Details Success'>
                                <div className='imgBox'>
                                    <Image src={require('../../../gsmecom/images/warning.png')} />
                                </div>
                                <h4>Failed</h4>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleFailureModalClose}>
                                Ok
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        }
        </>
    )
}

export default B2BVerificatioin;