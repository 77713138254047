import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Autocomplete, TextField } from '@mui/material';
import { IDamageEntryOutlet, IDamageEntryOutletDetails, IDamgeOutletBatchRequest } from './model';
import useStores from '../../hooks';
import Cookies from 'js-cookie';
import DamageEntryOutletValidation from './validation';
import { QRCode } from 'react-qrcode-logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import AutoComplete from '../../common/shared/autoComplete';



function DamageEntryOutlet() {
  const [damageEntryOutlet, setDamageEntryOutlet] = useState<IDamageEntryOutlet>({});
  const [damageEntryOutletDetail, setDamageEntryOutletDetail] = useState<IDamageEntryOutletDetails>({});
  const [damageEntryOutletDetails, setDamageEntryOutletDetails] = useState<IDamageEntryOutletDetails[]>([]);
  const navigate = useNavigate();
  const [isSpinner, setSpinner] = useState(false);
  const { supplierMasterStore, damageEntryOutletStore, commonStore, itemMasterStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [loadDamanItems, setLoadDamageItems] = useState<any[]>([]);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 


  const [itemName, setItemName] = useState<{ itemid: number, itemname: string }>({ itemid: 0, itemname: '' })
  const [batchNoObj, setBatchNoObj] = useState({ batchId: 0, batchNo: "", currentStock: 5, itemid: 3413, itemname: '', qty: 0, })
  const { loadDamageItemDetails, loadDamageItem } = commonStore;


  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setDamageEntryOutlet({ ...damageEntryOutlet, [name]: value });
  };

  console.log(damageEntryOutlet, 'damageEntryOutlet')

  function removeItem(index) {
    if (damageEntryOutletDetails?.some((obj, i) => i === index)) {
      damageEntryOutletDetails?.splice(index, 1)
      setDamageEntryOutletDetails([...damageEntryOutletDetails])
    }
  }

  const handleOutletItems = async (name, event, val) => {
    if (val != null) {

      setDamageEntryOutlet({ ...damageEntryOutlet, outletId: val.outletid });
      setLoading(true);
      const loadDamanItems = await loadDamageItem(val.outletid);
      setLoadDamageItems(loadDamanItems)
      setLoading(false);
    }
    else {
      damageEntryOutlet.outletId = 0;
      setLoadDamageItems([])
      itemName.itemid = 0;
      itemName.itemname = ''
      setItemName(itemName)
    }
  }
  const handleDamageItem = async (name, val) => {
    if (val != null) {
      setDamageEntryOutlet({ ...damageEntryOutlet, itemId: val.itemid, itemname: val.itemname });
      setLoading(true);
      setItemName({ ...itemName, itemid: val.itemid, itemname: val.itemname })
      let batchRequest: IDamgeOutletBatchRequest = {}
      batchRequest.itemId = val.itemid;
      batchRequest.outletId = damageEntryOutlet.outletId;
      await damageEntryOutletStore.loadDamageItemBatchNo(batchRequest);
      setLoading(false);
    }
    else {
      damageEntryOutlet.itemId = 0;
      batchNoObj.batchId = 0;
      setBatchNoObj(batchNoObj)
    }
  }
  const handleItemBatchNo = async (name: any, event: object, val: any) => {

    setDamageEntryOutlet({ ...damageEntryOutlet, batchNo: val.batchNo, currentStock: Number(val.currentStock), barcode: val?.barcode })
  }

  const addDamageEntry = () => {

    let newObject: IDamageEntryOutletDetails = {};
    let error: any = {};
    error = DamageEntryOutletValidation(damageEntryOutlet);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      damageEntryOutletDetail.damageBatchNo = damageEntryOutlet.batchNo;
      damageEntryOutletDetail.damageBarcode = damageEntryOutlet.barcode;
      damageEntryOutletDetail.damageDescription = damageEntryOutlet.damageDescription;
      damageEntryOutletDetail.damageItemId = damageEntryOutlet.itemId;
      damageEntryOutletDetail.damageItemQty = damageEntryOutlet.damageQty;
      damageEntryOutletDetail.damageItemName = damageEntryOutlet.itemname;


      setDamageEntryOutletDetails([...damageEntryOutletDetails, damageEntryOutletDetail])
      setDamageEntryOutletDetail(newObject);
      setDamageEntryOutlet({ ...damageEntryOutlet, itemId: undefined, itemname: '', damageQty: 0, damageDescription: '', batchNo: '', currentStock: 0 });
      setSubmitbtn(true);
    }
  }
  const saveDamageEntryOutlet = async (e) => {

    e.preventDefault();
    setLoading(true);
    damageEntryOutlet.damageEntryOutletdetails = damageEntryOutletDetails
    damageEntryOutlet.empid = Number(Cookies.get('employeeId'))
    const entryNos = await itemMasterStore.getEntryNo('DamageEntryOutlet');
    damageEntryOutlet.damageid = entryNos.entryNo + 1;
    const status = await damageEntryOutletStore.saveDamageEntryOutlet(damageEntryOutlet);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleCancel = () => {
    navigate('/Admin/DamageEntryOutlet/DamageEntryOutletDetail');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/DamageEntryOutlet/DamageEntryOutletDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const handleFailureModalClose = () => setFailureModal(false);

  async function fetchOultetItems() {
    await itemMasterStore.getEntryNo('DamageEntryOutlet')
    await damageEntryOutletStore.loadOutletItems();

    await damageEntryOutletStore.loadDamageItemBatchNo();
    setLoading(false);
  }
  // const isCurrentPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchOultetItems();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Damage_entry_outlet';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchOultetItems();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/DamageEntryOutlet/DamageEntryOutletDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {


    return (
      // <>
      //   {

      //     <div className='SpinnerBox'>
      //       <Spinner animation="border" role="status">
      //         <span className="visually-hidden">Loading...</span>
      //       </Spinner>
      //     </div>}

        <>
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Damage Entry (Outlet)</h3>
              </div>
              <div className='inputBoxLists pd-b-0'>
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text" className=''
                          name='damageid' disabled
                          value={damageEntryOutlet.damageid = itemMasterStore.entryNo.entryNo + 1}
                          onChange={handleInputchnage}
                          style={{ width: "100%" }} placeholder='Entry No..'></input>

                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Entry Date <span>*</span></label>
                        <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                          onChange={handleInputchnage}
                          name="damageEntryDate"
                          value={damageEntryOutlet.damageEntryDate = damageEntryOutlet.damageEntryDate ?? new Date().toISOString().substr(0, 10)}
                          data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.damageEntryDate && <p style={{ color: 'red' }}>{errors.damageEntryDate}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label> Outlet Name  <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={damageEntryOutletStore.outletItemDetails}
                          getOptionLabel={(option: any) => option.outletName}
                          onChange={(event, val) => handleOutletItems('outletId', event, val)}
                          renderInput={(params) =>
                            <TextField  {...params} style={{ width: '26ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Outlet..'
                              name='outletItemNo'
                            />}
                        />
                        {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label> Item Name<span>*</span></label>
                        <AutoComplete value={damageEntryOutlet?.itemname}
                          getOptionLabel='itemname' options={loadDamanItems}
                          emitOption={(option) => handleDamageItem('itemId', option)}
                          placeholder='Select Outlet..'
                        />
                        {errors.itemname && <p style={{ color: 'red' }}>{errors.itemname}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {damageEntryOutletStore?.loadDamageItemBatch?.length > 0 &&
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Batch No<span>*</span></label>
                          <AutoComplete placeholder='Select Batch No..' value={damageEntryOutlet?.batchNo}
                            emitOption={(val) => handleItemBatchNo('batchNo', {}, val)}
                            getOptionLabel="batchNo" options={damageEntryOutletStore?.loadDamageItemBatch} />
                          {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>}
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Current Stock </label>
                        <input type="text" className='' style={{ width: "100%" }} placeholder='Current Stock..'
                          value={damageEntryOutlet?.currentStock} name='currentStock'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-3'>
                      <div className='inputBox'>
                        <label>Damage Qty<span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }} placeholder='Damage Qty..'
                          // onChange={handleInputchnage}
                          onChange={(event) => Number(event?.target?.value) <= damageEntryOutlet?.currentStock! ? handleInputchnage(event) : {}}
                          name="damageQty"
                          value={damageEntryOutlet?.damageQty}
                        ></input>
                        {errors.damageQty && <p style={{ color: 'red' }}>{errors.damageQty}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-9'>
                      <div className='inputBox'>
                        <label>Damage Description<span>*</span></label>
                        <textarea className='' style={{ width: "100%" }} placeholder='Damage Description..'
                          onChange={handleInputchnage}
                          name="damageDescription"
                          value={damageEntryOutlet.damageDescription} ></textarea>
                        {errors.damageDescription && <p style={{ color: 'red' }}>{errors.damageDescription}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-2'>
                      <button className='dfBtn' onClick={addDamageEntry}>Add</button>
                    </div>
                    <div className='col-md-12'>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Item Name</th>
                              <th scope="col"> Qty </th>
                              <th scope="col"> Batch No </th>
                              <th scope="col"> Damage Description </th>
                              <th scope='col'></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              damageEntryOutletDetails.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.damageItemName}</td>
                                    <td>{val.damageItemQty}</td>
                                    <td><QRCode value={val.damageBatchNo} size={100} /></td>
                                    <td>{val.damageDescription}</td>
                                    <td><button onClick={() => removeItem(key)} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>

                  </div>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveDamageEntryOutlet}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </>
      // </>
    );
  }
};


export default DamageEntryOutlet;