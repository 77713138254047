import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';
import Cookies from 'js-cookie';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';


const AssignNotificationRollsList = observer((): JSX.Element => {
    const { userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();
    const [Isloading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);


    const [alertUser, setAlertUser] = useState({ alertid: 0, bool: false })

    const [newItemReqList, setNewItemReqList] = useState<any[]>([])


    console.log(newItemReqList, alertUser, 'newItemReqList>>')

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/NotificationRolls');
    }
    const editUnitDetials = (reqId) => {
        navigate('/Admin/NotificationRolls/' + reqId);
    }
    const deleteUnitDetials = async (unitId) => {
        setLoading(true);
        const status = "Success";
        if (status === 'Success') {
            fetchPreLoadingData()
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadingData();
    }

    async function fetchPreLoadingData() {
        let body: any = {
            userid: 0,
        }
        const userRights = await userCreationStore?.getUserRight('Assign_notification_roles')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const newItemReqList = await userCreationStore.loadAssignNotificationRolls(body);
        setNewItemReqList(newItemReqList)
        setLoading(false);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadingData()
            isCurrentPage.current = false
        }
        return () => { }
    }, []);
    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (newItemReqList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData([...newItemReqList])
        }
    }, [newItemReqList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(removeDuplicates(newItemReqList, 'Aleartid')?.slice()?.sort((a, b) => b.Aleartid - a.Aleartid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData([...currentPageList?.currentPageData])
    };
    return (
        <>
            {
                Isloading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>

                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={removeDuplicates(newItemReqList, 'Aleartid')}
                                searchTitles={[{ key: "formname", value: "Form Name" }, { key: "Aleartid", value: "Entry No" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='inputBoxLists'>
                                <div className='vertical-space-20'></div>
                                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className='btnBox'>
                                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(newItemReqList, 'Aleartid')?.length} Records</button>
                                    </div>
                                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                    </Stack>
                                </div>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Entry No</th>
                                            <th scope="col">Form Name</th>
                                            <th scope="col">Req Date</th>
                                            <th scope='col'>Alert Message</th>
                                            <th></th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val?.Aleartid}</td>
                                                        <td>{val.formname}</td>
                                                        <td>{moment(val.created_date).format('DD-MM-YYYY')}</td>
                                                        <td>{val?.AleartMessage}</td>
                                                        <td><button disabled={!userRights?.view} onClick={() => setAlertUser({ alertid: val?.Aleartid, bool: true })} className='tablemodeltext'> Detail</button></td>
                                                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit'  ><FontAwesomeIcon icon={faPenToSquare} onClick={() => editUnitDetials(val.Reqid)} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                    </tr>
                                                )
                                            }
                                            )}

                                    </tbody>
                                </table>
                            </div>

                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={alertUser?.bool} onHide={() => setAlertUser({ alertid: 0, bool: false })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Notification Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Alert User Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {newItemReqList?.map((alerts) => {
                                                    return alerts?.Aleartid === alertUser?.alertid && (
                                                        <tr>
                                                            <td>{alerts?.username}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </>
            }

        </>

    )
})

export default AssignNotificationRollsList;