
function DamageEntryOutletValidation(damageEntryOutlet){
    let errors :any={};
    
      
      if(!damageEntryOutlet.damageEntryDate){
        errors.damageEntryDate = "Enter Date";
      }
      if(!damageEntryOutlet.outletId){
        errors.outletId = "Select outlet Name";
      }
      if(!damageEntryOutlet.itemname){
        errors.itemname = "Select Item Name";
      }
      if(!damageEntryOutlet.batchNo){
        errors.batchNo = "Select BatchNo ";
      }
      if(!damageEntryOutlet.damageQty){
        errors.damageQty = "Damage Qty does not exist Stock Qty";
      }
      if(!damageEntryOutlet.damageDescription){
        errors.damageDescription = "Enter Damage Description";
      }
    return errors;
  };
    
    export default DamageEntryOutletValidation;