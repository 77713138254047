import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IDespatchEntry {
    orderdespatchid?: number;
    orderdespatchdate?: string = getCurrentMonthFirstDate()?.currentDate;
    orderthrough?: string;
    orderdate?: string;
    itemid?: number;
    orderMasterid?: number;
    orderNoId?: number;
    itemname?: string;
    category?: string;
    itemQty?: number;
    orderNo?: string;
    wareHouse?: string;
    outletName?: string;
    employeeName?: string;
    Remarks?: string;
    uom?: string;
    batchNo?: string;
    qty?: number;
    desqty?: number;
    mrp?: number;
    b2bprice?: number;
    b2cprice?: number;
    assigndespatchdetails?: DespatchEntryDetails[]
}
export interface IDespatchEntrySearchoptions {
    searchOption?: string;
    searchValue?: string;
}
export interface DespatchEntryDetails {
    orderdespatchid?: number;
    orderdespatchbatchno?: string;
    orderdespatchitemname?: string;
    DespatchEntryItemId?: number;
    DespatchEntryqty?: number;
    DespatchEntrydesqty?: number;
    DespatchEntrymrp?: number;
    DespatchEntryb2Bprice?: number;
    DespatchEntryb2Cprice?: number;


}
const DespatchEntry = observable<IDespatchEntry>({
    orderdespatchid: 0,
    orderdespatchdate: '',
    orderthrough: '',
    orderdate: '',
    itemid: 0,
    orderMasterid: 0,
    category: '',
    itemname: '',
    itemQty: 0,
    orderNo: '',
    wareHouse: '',
    outletName: '',
    employeeName: '',
    Remarks: '',
    uom: '',
    batchNo: '',
    qty: 0,
    mrp: 0,
    b2bprice: 0,
    b2cprice: 0,
    assigndespatchdetails: []
});

export default DespatchEntry;
