import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import moment from "moment";
import { IIndentDetails, IIndentListDetails, IPurchaseAppList, ISupplierDetail } from "./model";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Form, Image, Modal, Spinner } from "react-bootstrap";
import purchaseApprovalValidation from "./validate";
import Cookies from 'js-cookie';
import { Autocomplete, TextField } from "@mui/material";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";


const PurchaseApproval = () => {
  const navigate = useNavigate();
  const { supplierMasterStore, purchaseApproval, indentStore, commonStore } = useStores();
  const { loadItemConversion } = commonStore;

  const [purchaseAppFields, setPurchaseAppFields] = useState<any>({
    fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate,
    indentno: null, itemname: null
  })
  const [loadIndentList, setLoadIndentList] = useState<any[]>([])
  const [loadItemConversions, setloadItemConversions] = useState<any[]>([])
  const [isLoadModal, setLoadModal] = useState(false);
  const [indentList, setIndentList] = useState<IIndentListDetails[]>([]);
  const [indentItemList, setIndentItemList] = useState<IIndentDetails[]>([]);
  const [supplierDetails, setSupplierDetails] = useState<ISupplierDetail[]>([]);

  const [showSupplier, setShowSupplier] = useState(false);
  const [errors, setErrors] = useState<any>({})

  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 


  function handleSuccessModal() {
    setSuccessModal(false);
    setIndentItemList([])
    handleCancel()
  }

  function handleCancel() {
    navigate('/Admin/PurchaseApproval/PurchaseApprovalDetail')
  }

  async function showIndentDetail(indentId) {
    setLoading(true)
    const indentDetails = await purchaseApproval.getIndentDetails(indentId);
    if (indentDetails && indentDetails?.length) {
      setIndentItemList([...indentDetails])
    }
    setLoading(false)
  }

  async function getSupplierName(indentId, itemId, indent_details_id) {
    const supplierDetails = await purchaseApproval.loadSupplierName(indentId, itemId);
    setSupplierDetails(supplierDetails)
    setShowSupplier(true)
  }

  function handleInputChange(e, indent: IIndentDetails) {
    setIndentItemList((prevObjects) => prevObjects?.map((obj) => (obj?.indent_details_id == indent?.indent_details_id) ? { ...obj, approval_qty: Number(e.target.value) } : obj));
  }

  function handleInputFieldsChange(e) {
    const { name, value } = e.target;
    setPurchaseAppFields({ ...purchaseAppFields, [name]: value })
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  function handleValue(type: string, event, value) {
    if (type == 'indent_no') {
      setLoading(true);
      setPurchaseAppFields({ ...purchaseAppFields, indentno: value.indent_no, itemname: null });
      setLoading(false);
    } else {
      setPurchaseAppFields({ ...purchaseAppFields, itemname: value.itemName, indentno: null });
    }
  }

  function handleCheckItemBoxChange(indentItem: IIndentDetails) {
    let error: any = {}
    error = purchaseApprovalValidation(indentItem);
    if (Object.keys(error)?.length === 0) {
      setIndentItemList((prevObj) => prevObj?.map((obj) => obj?.indent_details_id === indentItem?.indent_details_id ? { ...obj, selectedItem: !obj?.selectedItem } : obj))
    } else {
      error.indent_details_id = indentItem?.indent_details_id
    }
    setErrors(error)
  }

  function handleCheckboxChange(supplier) {
    setIndentItemList((prevObj) => prevObj?.map((obj) => obj?.indent_details_id === supplier?.indent_details_id ? { ...obj, supplierid: supplier.supplierid, rate: supplier.rate, suppliername: supplier.suppliername, quotefromsupplierid: supplier.quotefromsupplierid } : obj))
    setShowSupplier(false);
  }

  async function handleSubmit() {

    let purchaseApprovalList = indentItemList?.filter((obj) => obj?.selectedItem && obj?.approval_qty && obj?.supplierid)

    const entryNo = await indentStore.getEntryNo('PurchaseApproval');

    const originalString = entryNo?.entrynumber;
    const [prefix, numericPart] = originalString && originalString?.split('-');

    let uploadedList = purchaseApprovalList?.map((obj, index) => {
      const incrementedNumericPart = String(Number(numericPart) + 1).padStart(5, '0');
      const newString = `${prefix}-${incrementedNumericPart}`;
      let purchaseApproval: IPurchaseAppList = {
        purchase_approval_no: newString,
        purhcase_approval_date: moment().format('YYYY-MM-DD'),
        supplier_id: obj?.supplierid,
        itemid: obj?.itemid,
        quotefromsupplierid: obj?.quotefromsupplierid,
        indent_id: obj?.indent_id,
        indent_details_id: obj?.indent_details_id,
        uom: obj?.Unitname ?? "",
        rate: obj?.rate?.toFixed(2),
        approval_qty: obj?.approval_qty?.toFixed(2),
        entered_by: Number(Cookies.get('employeeId')),
      }
      return purchaseApproval
    })
    let status: string = '';

    if (uploadedList?.length > 0) {
      setErrors({})
      setLoading(true)
      status = await purchaseApproval.submitPurchaseApproval(uploadedList);

      if (status === 'Success') {
        setLoading(false);
        setSuccessModal(true);
      }
      else {
        setLoading(false);
        setFailureModal(true);
      }
    } else {
      let error: any = {
        selectItem: 'Select Items First'
      }
      setErrors(error)
    }
  }

  const loadIndentNoList = React.useMemo(async () => {
    let indentListDetail: any = []
    if (purchaseAppFields?.fromDate && purchaseAppFields?.toDate) {
      indentListDetail = await purchaseApproval.getIndentMainDetails(purchaseAppFields?.fromDate, purchaseAppFields?.toDate);
    }
    setLoadIndentList(indentListDetail)
  }, [purchaseAppFields?.fromDate, purchaseAppFields?.toDate])

  async function handleGenerateStock() {
    let error: any = {}
    if (!purchaseAppFields?.fromDate) {
      error.fromDate = "Enter From Date"
    }
    if (!purchaseAppFields?.toDate) {
      error.toDate = 'Enter To Date'
    }
    if (Object.keys(error)?.length === 0) {
      setLoading(true)
      let body = {
        "indentno": purchaseAppFields?.indentno,
        "itemname": purchaseAppFields?.itemname,
        "fromDate": purchaseAppFields?.fromDate,
        "toDate": purchaseAppFields?.toDate,
      }

      const purchaseAppList = await purchaseApproval.getIndentMainDetails(body);
      setIndentList(Object.assign([], purchaseAppList))
      setErrors({});
    } else {
      setErrors(error)
    }
    setLoading(false);
  }

  function handleClear() {
    setPurchaseAppFields({ ...purchaseAppFields, indentno: undefined, itemname: undefined });
  }
  async function fetchLoadItemConversion() {
    const [entryNo, purchaseAppList, loadItemConversions] = await Promise.all([indentStore.getEntryNo('PurchaseApproval'), purchaseApproval.getIndentMainDetails(purchaseAppFields), loadItemConversion()]);
    setIndentList(Object.assign([], purchaseAppList))
    setloadItemConversions(loadItemConversions)
    setLoading(false)
  }

  // const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchLoadItemConversion();
  //     handleGenerateStock()
  //     isCurrentPage.current = false
  //   }
  //   return () => { }
  // }, []);
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Purchase_approval';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchLoadItemConversion();
             
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/PurchaseApproval/PurchaseApprovalDetail')
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  const handleFailureModalClose = () => setFailureModal(false);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <div className="container">

        <div className='vertical-space-20'></div>

        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Purchase Approval</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>
              <div className='row'>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>From Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      placeholder="DD-MMM-YYYY" name="fromDate" value={purchaseAppFields.fromDate = purchaseAppFields?.fromDate ?? getCurrentMonthFirstDate()} onChange={handleInputFieldsChange} data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>To Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      placeholder="DD-MMM-YYYY" name="toDate" value={purchaseAppFields.toDate = purchaseAppFields?.toDate ?? new Date().toISOString().substr(0, 10)} onChange={handleInputFieldsChange} data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                  <div className='vertical-space-20'></div>
                </div>
                <div className="col-sm-2" style={{ marginTop: '20px' }}>
                  <div className='inputBox'>
                    <AutoComplete
                      options={loadIndentList}
                      value={loadIndentList?.find((each) => each?.indent_no === purchaseAppFields?.indentno)?.indent_no ?? ''}
                      emitOption={(val) => handleValue('indent_no', {}, val)}
                      getOptionLabel='indent_no'
                      placeholder={'Select Indent No..'}
                    />
                  </div>
                </div>
                <div className="col-sm-2" style={{ marginTop: '20px' }}>
                  <div className='inputBox'>
                    <AutoComplete
                      options={loadItemConversions}
                      value={loadItemConversions?.find((each) => each?.itemName === purchaseAppFields?.itemname)?.itemName ?? ''}
                      emitOption={(val) => handleValue('itemId', {}, val)}
                      getOptionLabel='itemName'
                      placeholder={'Select Item..'}
                    />
                  </div>
                </div>
                <div className='btnBox col-sm-4' style={{ marginTop: '25px' }}>
                  <button className='secondaryBtn' type='submit' onClick={handleGenerateStock} >Search</button>
                  <button className='secondaryBtn' type='submit' onClick={handleClear} >Clear</button>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex full-width" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <h5>Waiting for Approval</h5>
          </div>
          <div className='tableListDetails'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Indent No</th>
                  <th scope="col"> Date</th>
                  <th scope="col">Remarks </th>
                </tr>
              </thead>
              <tbody>
                {indentList?.length > 0 && indentList?.map((indent, index) => {
                  return (
                    <tr>
                      <td onClick={() => { showIndentDetail(indent?.indent_id) }} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{indent?.indent_no}</td>
                      <td>{indent?.indentdate}</td>
                      <td>{indent?.remarks}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          {
            isLoading ?
              <div className='SpinnerBox'>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div> : null
          }

          <div className='vertical-space-20'></div>

          {indentItemList?.length > 0 ?
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Item Name</th>
                    <th scope="col">UOM</th>
                    <th scope="col">Indent Qty</th>
                    <th scope="col">Pending Qty</th>
                    <th scope="col">Approved Qty</th>
                    <th scope="col">Approval Qty</th>
                    <th scope="col">Rate/Unit</th>
                    <th scope="col">Supplier Name</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {indentItemList?.map((indentItem: IIndentDetails, index) => {
                    return (
                      <tr>
                        <td>
                          <input type="checkbox" checked={indentItem?.selectedItem == true} onChange={() => handleCheckItemBoxChange(indentItem)} />
                        </td>
                        <td>{indentItem?.itemname}</td>
                        <td>{indentItem?.Unitname}</td>
                        <td>{indentItem?.indent_qty}</td>
                        <td>{indentItem?.pening_qty}</td>
                        <td>{indentItem?.approved_qty}</td>
                        <td>
                          <input type='number' value={indentItem?.approval_qty} style={{ width: '100px' }} onChange={(e) => handleInputChange(e, indentItem)}></input>
                          {errors.indent_details_id == indentItem.indent_details_id && errors.approval_qty && <p style={{ color: 'red' }}>{errors.approval_qty}</p>}
                        </td>
                        <td>{indentItem?.rate}</td>
                        <td>{indentItem?.suppliername}</td>
                        <td>
                          <button onClick={() => getSupplierName(indentItem?.indent_id, indentItem?.itemid, indentItem?.indent_details_id)}>
                            Rates
                          </button>
                          {errors.indent_details_id == indentItem.indent_details_id && errors.supplierid && <p style={{ color: 'red' }}>{errors.supplierid}</p>}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              {errors.selectItem && <p style={{ color: 'red' }}>{errors.selectItem}</p>}
            </div> : null
          }

          <Modal show={showSupplier} onHide={() => setShowSupplier(false)} className='PriceHistoryModel'>
            <Modal.Body>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col"> </th>
                    <th scope="col">Supplier Name</th>
                    <th scope="col">UOM </th>
                    <th scope="col">Rate  </th>
                  </tr>
                </thead>
                <tbody>
                  {supplierDetails?.length > 0 && supplierDetails?.map((supplier, index) => {
                    return (
                      <tr>
                        <td>
                          <input type='checkbox' checked={supplier?.selected} onChange={() => handleCheckboxChange(supplier)}></input>
                        </td>
                        <td>{supplier?.suppliername}</td>
                        <td>{supplier?.uom}</td>
                        <td>{supplier?.rate}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>

          <div className='vertical-space-20'></div>

          {indentItemList?.length > 0 ? <div className='btnBox'>
            <button className='dfBtn' type='submit' onClick={handleSubmit}>Submit</button>
          </div> : null}

          <Modal show={isSuccessModal} onHide={handleSuccessModal} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModal}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setFailureModal(false)}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

        </div>

      </div>
    )
  }
}

export default PurchaseApproval;