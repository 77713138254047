export class ILoosedConversion {
    entryNo?: number = 0
    entryDate?: string = new Date().toISOString().substr(0, 10)
    wid?: number = 0
    convertedby?: number = 0
    looseConversiondetails?: ILoosedConversionDetails[]=[]
}

export class ILoosedConversionDetails {
    entryid?: number = 0
    itemid?: number = 0
    itemname?: string = ""
    uom?: string = ""
    qty?: number = 0
    brandType?: string = "";
    barcode?: string = "";
    batchNo?: string = "";
    batchQty?: number = 0;
    convertedItem?: number = 0
    convertedItemName?: string = "";
    convertedUom?: string = ""
    convertQty?: number = 0
    convertBarcode?: string = "";
    convertBatchno?: string = "";
}