export class IPossalesMaster {
    earnedPoints?: number = 0;
    currentEarnedPoints?: number = 0;
    batchNo?: string = "";
    batchid?: number = 0;
    posid?: number = 0;
    counterNumber?: number = 0;
    terminalId?: number = 0;
    employeeId?: number = 0;
    employeeName?: string = "";
    memberName?: string = "";
    memberType?: string = "";
    memberCategory?: string = "";
    memberAddress?: string = "";
    memberEmail?: string = "";
    itemName?: string = "";
    itemId?: number = 0;
    mrpPrice?: number = 0;
    comments?: string = "";
    totalPrice?: number = 0;
    itemQty?: number = 0;
    billValue?: number = 0;
    taxValue?: number = 0;
    discountValue?: number = 0;
    totalItems?: number = 0;
    itemImage?: string = "";
    fileName?: string = "";
    discountPercentage?: number = 0;
    taxPercentage?: number = 0;
    billNo?: number = 0;
    billDate?: string = new Date()?.toISOString()?.substr(0, 10);
    memberId?: number = 0;
    mobileNo?: string = "";
    paymentmode?: string = "";
    cardno?: string = "";
    discountvalue?: number = 0;
    taxvalue?: number = 0;
    othercharges?: string = "";
    netvalue?: number = 0;
    sellingRate?: number = 0;
    PosSalesDetails?: IPosSalesDetails[];
    hsnCode?: string = "";
    posBatchDetails?: IPosBatchDetails[];
    posSalescoinchange?: IPosSalescoinchange[] = [];
    isDelivery?: boolean;
    deliveryCharges?: number = 0;
    address?: IAddress;
    offerName?: string = "";
    offerDiscountValue?: number = 0;
    randampoints?: number = 0;
    discountMode?: string = "";
    redeemPoints?: number = 0;
    redeemPointsamt?: number = 0;
    isReedem?: boolean;
    finalAmount?: number = 0;
    billType?: string = "";
    isSameAddress?: boolean;
    outletId?: number = 0;
    cashAmount?: number = 0
    redeemAmount?: number = 0
    salesReturn?: number = 0
    cardAmount?: number = 0
    qRcodeAmount?: number = 0
    onlinestoreorder?: number = 0
    onlinebankTR?: number = 0
    sodexo?: number = 0
    replacementposid?: number = 0

}
export class IPosSalescoinchange {
    posid?: number = 0
    itemid?: number = 0
    barcode?: string = ""
    batchcode?: string = ""
    qty?: number = 0
    rate?: number = 0
    amount?: number = 0
}
export class IPosBatchDetails {
    itemId?: number = 0;
    barcode?: string = "";
    batchid?: number = 0;
    batchNo?: string = "";
    batchQty?: number = 0;
    checked?: boolean
}
export class IPosSalesDetails {
    postRowId?: number = 0
    posItemId?: number = 0;
    posItemName?: string = "";
    posItemMRP?: number = 0;
    posItemQty?: number = 0;
    posTotalPrice?: number = 0;
    posSubTotalPrice?: number = 0;
    posDiscountePercentage?: number = 0;
    posTaxPercentage?: number = 0;
    posTaxValue?: number = 0;
    posDiscountValue?: number = 0;
    batchNo?: string = "";
    batchid?: number = 0;
    PosTaxId?: number = 0;
}
export class IposReceipt {
    receiptid?: number = 0;
    receiptno?: string = "";
    receiptdate?: string = new Date()?.toISOString()?.substr(0, 10);
    memberid?: string = "";
    paymentmode?: string = "";
    referanceno?: string = "";
    posid?: string = "";
    billno?: string = "";
    receiptAmount?: string = "";

}
export class IPOSHoldDetails {

    posHoldTerminalId?: number = 0;
    posHoldEmployeeId?: number = 0;
    posHoldMemberId?: number = 0;

    posHoldItemDetails?: IPOSHoldItemDetails[];
    posBatchDetails?: IPOSHoldBatchDetails[]
}
export class IPOSHoldItemDetails {
    posItemId?: number = 0;
    posItemQty?: number = 0;
}
export class IPOSHoldBatchDetails {
    itemId: number = 0;
    batchId: number = 0;
    batchNo: string = "";
    batchqty: number = 0
}
export class IPOSProductDetails {
    postRowId?: number = 0;
    posFreeItemId?: number = 0;
    posItemId?: number = 0;
    posItemHsnCode?: string = "";
    posItemMRP?: number = 0;
    posItemName?: string = "";
    posSubCategoryId?: number = 0;
    posItemQty?: number = 0;
    posItemDQty?: number = 0;//optional
    posMinSalQty?: number = 0 // optional
    isdivisable?: boolean = false // optional
    isSodexo?: boolean = false // optional
    posSudexoAmt?: number = 0 // optional
    posItemSellingRate?: number = 0;
    posTotalPrice?: number = 0;
    posTaxPercentage?: number = 0;
    posDiscountPercentage?: number = 0;
    posDiscountValue?: number = 0;
    posTaxValue?: number = 0;
    posUnitOdMeasure?: string = "";
    posNetAmount?: number = 0;
    posItemImage?: string = "";
    posComments?: string = "";
    posbatchNo?: string = "";
    posBarcode?: string = "";
    posbatchid?: number = 0;
    posbatchqty?: number = 0;
    posDiscountMode?: string = "";
    PosTaxId?: number = 0;
    memberType?: string = "";
    availableQty?: number = 0;
    outletId?: number = 0;
    checked?: boolean;
}
export class IPOSCounterDetails {
    posCounterNumber?: number = 0;
    posTerminalId?: number = 0;
    posEmployeeId?: number = 0;
    posEmployeeName?: string = "";
    posOutletId?: number = 0;

}
export class IPaymentRequest {
    name?: string = "";
    email?: string = "";
    phoneNumber?: string = "";
    address?: string = "";
    amount?: number = 0;
}
export class IConfirmPaymentPayload {
    razorpay_order_id?: string = ""
    razorpay_payment_id?: string = "";
    razorpay_signature?: string = "";
}
export class IMemberDetails {
    memberName?: string = "";
    memberType?: string = "";
    memberCatId?: string = "";
    memberCategory?: string = "";
    memberAddress?: string = "";
    memberEmail?: string = "";
    memberId?: number = 0;
    mobileNo?: string = "";
    earnedPoints?: number = 0;
    balanceEarnedPoints?: number = 0;
    redeemPoints?: number = 0;
}
export class IAddress {
    name?: string = "";
    mobileNumber?: string = "";
    doorNoStreet?: string = "";
    area?: string = "";
    landMark?: string = "";
    city?: string = "";
    state?: string = "";
    country?: string = "";
    pinCode?: string = "";
}
export class IPOSFreeItemRequest {
    POSItemId?: number = 0;
    POSItemQty?: number = 0;
    POSBatchId?: number = 0;
}
export class IPOSCheckOfferRequest {
    billValue?: number = 0;
    earnedPoints?: number = 0;
    category?: string = "";
}
export class IEntryNoRequest {
    flag?: string = "";
    outletId?: number = 0;
}