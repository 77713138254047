import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Nav, Accordion, Navbar, Container, Button, InputGroup, Form, Image, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faBagShopping, faBars, faCalculator, faDollar, faEdit, faHome, faHomeAlt, faMarsDouble, faMasksTheater, faMinus, faMoneyBill, faPen, faPercent, faPlus, faRotate, faSearch, faTrash, faUser, faUserAlt, faUserPlus, faVials } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Ecom.css';
import Header from '../Header/Header';
import { useNavigate } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';
import { IProduct, IProductPriceDetails } from '../Products/model';
import { IProductDeatils } from './model';
import useEcomStores from '../hooks/index';
import { IConfirmPaymentPayload, IEntryNoRequest, IPOSProductDetails, IPaymentRequest, IPosBatchDetails, IPossalesMaster } from '../../gsmadmin/Admin/POS/model';
import { checkForFreeItem, checkForItemOffer, isEqual, updateBatchList } from '../common/commonFunc';
import useStores from '../../gsmadmin/hooks';
import moment from 'moment';

const CheckOut = observer((): JSX.Element => {

  let customerType = Cookies.get('customerType');
  let memberId = Cookies.get('memberId');

  const { cartsDetailsStore, checkoutStore, ecomHomeStore } = useEcomStores();
  const { posMasterStore, itemMasterStore } = useStores();

  const { getCartDetails, getOnlineItems, getLoadItemImages } = ecomHomeStore;


  const [posDetail, setPosDetail] = useState<IPossalesMaster>(new IPossalesMaster());

  const [isLoading, setLoading] = useState({ initialLoad: true, spinnerLoad: false, isSpinner: false });
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [isFailureModal, setFailureModal] = useState(false);

  const [posProductList, setPosProductList] = useState<any[]>([])
  const [posItemOffers, setPosItemOffers] = useState<any[]>(posMasterStore?.getItemOffers)
  const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)

  const [posItemDetils, setPOStItemDetails] = useState<any[]>([]);
  const [assignedOffers, setAssignedOff] = useState<any[]>([])
  const [posBatchDetails, setPOSBatchDetails] = useState<IPosBatchDetails[]>(new Array<IPosBatchDetails>());

  const navigate = useNavigate();

  const [priceDetails, setPriceDetails] = useState<IProductPriceDetails>({})
  const [product, setProduct] = useState<IProductDeatils>({});
  const [products, setProducts] = useState<IProduct[]>([]);

  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  const top100Films = [
    { label: ' Axis Bank' },
    { label: ' Bank of Baroda' },
    { label: 'Bank of India' },
    { label: 'Bank of Maharashtra' },
    { label: 'Canara Bank' },
    { label: 'Central Bank of India' },
    { label: "Indian Bank" },
    { label: 'Indian Overseas Bank' },
    { label: 'Punjab & Sind Bank' },
    { label: 'Punjab National Bank' },
    { label: 'State Bank of India' },
    { label: 'Union Bank of India' },
  ];

  let loadData: any[] = [];

  async function fetchPreLoadingData() {
    let totatlMRP = 0;
    let sellingPrice = 0;
    let discountValue = 0;
    let totalTaxPrice = 0;
    let totalQty = 0;

    debugger
    if (Cookies.get('Products') !== undefined) {
      loadData = JSON.parse(Cookies.get('Products'));
      setPOStItemDetails([...loadData])
    }

    priceDetails.totalTaxPrice = totalTaxPrice;
    priceDetails.totalDiscountValue = discountValue
    priceDetails.totalMRP = totatlMRP
    priceDetails.totalSellingPrice = sellingPrice
    priceDetails.netAmount = priceDetails.totalSellingPrice + Number(priceDetails.totalTaxPrice) - priceDetails.totalDiscountValue!;

    setPriceDetails(priceDetails);
    setProducts(loadData);
    setProduct({ ...product, memberId: Cookies.get('memberId') })
  }

  const saveProductOrder = async (e) => {
    e.preventDefault();
    product.memberId = Number(Cookies.get('memberId'));
    product.paymentType = "CashOnDelivery";
    product.productDetails = products;
    const status = await checkoutStore.saveProduct(product);
    if (status === "Success") {
      Cookies.remove('Products')
      navigate('/');
    }
  }

  function updateProductDetails(loadData, ecomItemDetails, imageList) {
    if (ecomItemDetails?.length) {
      let products: any[] = []
      ecomItemDetails?.forEach((product) => {
        const { itemid,   } = product;
        loadData?.forEach((cartItems) => {
          const { productId } = cartItems;
          if (productId === itemid) {
            let discountValue: number = 0;
            let totalPrice: number = 0;
            let taxValue: number = 0;
            let sellingRate: number = Number(product?.SellingRate!) ?? 0;

            discountValue = (Number(product?.Mrprate!) - sellingRate);
            totalPrice = (sellingRate * Number(cartItems?.productQty!))?.toFixed(2) as any;
            taxValue = ((Number(sellingRate!) - Number(sellingRate!)) / (1 + product?.taxper! / 100))?.toFixed(2) as any;

            let updateObj: any = {
              posItemId: product?.itemid,
              posItemSellingRate: product?.SellingRate,
              posbatchNo: cartItems?.batchNo,
              posCategory: product?.category,
              posItemMRP: product?.Mrprate,
              posBarcode: cartItems?.barcode,
              posbatchqty: product?.stock,
              posItemName: product?.itemname,
              posSubCategoryId: product?.subcategory,
              availableQty: product?.stock,
              posComments: product?.comments,
              posUnitOdMeasure: product?.unitOfMeasure,
              posItemQty: cartItems?.productQty,
              posTaxPercentage: product?.taxper,
              posTaxValue: Number(taxValue),
              posDiscountPercentage: product?.Discount_Pv,
              posDiscountValue: product?.Discount_Pv ?? discountValue,
              posDiscountMode: product?.Dis_Mode,
              posTotalPrice: Number(totalPrice ?? 0)?.toFixed(2),
              posNetAmount: (Number(totalPrice ?? 0))?.toFixed(2),
            }
            products?.push(updateObj)
          }
        })
      })
      if (products?.length) {
        imageList?.forEach((item) => {
          const { itemId,   } = item;
          products?.forEach((product) => {
            const { posItemId } = product;
            if (posItemId === itemId) {
              product.imageData = item?.itemImage
            }
            return product
          })
        })
      }

      setPOStItemDetails([...products])
    }
  }

  async function getCartProds() {
    debugger
    let loadData: any[] = [];

    if (Cookies.get('Products') !== undefined) {
      loadData = JSON.parse(Cookies.get('Products'));
    }

    if (loadData?.length) {
      const cartItems = await getCartDetails(Number(memberId))
      const imageList = await getLoadItemImages();
      updateProductDetails(loadData, cartItems, imageList)
    }
    // setLoading(false);
  }

  const isCurrenpage = useRef(true);

  useEffect(() => {
    if (isCurrenpage.current) {
      // fetchPreLoadingData();
      getCartProds()
      isCurrenpage.current = false;
    }
    return () => { }
  }, [])


  let address: any = {
    Address: "GSS Office 123/2, Gandhi Street, Gandhi Puram, Coimbatore, Tamil Nadu, 665665"
  }
  async function processItemsToOrder() {

    setLoading({ ...isLoading, spinnerLoad: true })
    const paymentRequest: IPaymentRequest = {};
    let amount: any = 0;
    paymentRequest.name = Cookies.get('memberName');
    paymentRequest.email = undefined;
    paymentRequest.phoneNumber = "9999999999";
    paymentRequest.address = address?.Address;
    posDetail.netvalue! = Number(posItemDetils?.length && posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount!), 0)?.toFixed(2));
    amount! = posDetail.billValue ? posDetail.billValue : posDetail.netvalue;

    if (posDetail.deliveryCharges !== undefined) {
      posDetail.netvalue = amount! + Number(posDetail.deliveryCharges);
    } else {
      posDetail.netvalue = amount!;
    }
    paymentRequest.amount = posDetail.netvalue;

    const response = await posMasterStore.ProceedPayment(paymentRequest);

    const options = {
      amount: paymentRequest.amount,
      name: 'Ganga Super Market',
      description: 'Ganga Super Market',
      image: '../../../image/Ganga_SuperMarket-1.png',
      order_id: response?.orderId,
      handler: async (response) => {
        setLoading({ ...isLoading, isSpinner: true })
        let confirmPaymentPayload: IConfirmPaymentPayload = {}
        confirmPaymentPayload.razorpay_order_id = response?.razorpay_order_id;
        confirmPaymentPayload.razorpay_payment_id = response?.razorpay_payment_id;
        confirmPaymentPayload.razorpay_signature = response?.razorpay_signature;

        const staus = await posMasterStore.confirmPayment(confirmPaymentPayload);

        if (staus === 'Payment Successful') {
          savePOSMaster();
        }
      },
      prefill: {
        name: posDetail?.memberName,
        email: paymentRequest?.email
      },
      theme: {
        color: '#DC3545'
      }
    };

    const _window = window as any;
    const rzp1 = new _window.Razorpay(options);
    rzp1.open();
    setLoading({ ...isLoading, spinnerLoad: false })
  }

  async function savePOSMaster() {
    posDetail.outletId = Number(Cookies.get('outletId'))
    posDetail.memberCategory = Cookies.get('customerType');
    posDetail.address = address;
    posDetail.memberId = Cookies.get('memberId');
    posDetail.counterNumber = Number(Cookies.get('terminalId'));
    posDetail.terminalId = Number(Cookies.get('terminalId'));
    posDetail.employeeId = Number(Cookies.get('userId'));
    posDetail.PosSalesDetails = posItemDetils;
    posDetail.posBatchDetails = posBatchDetails;
    posDetail.netvalue = calNetvalue();
    posDetail.billValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0) : 0;
    posDetail.billType = "POS";
    posDetail.billDate = moment().format('DD-MMM-YYYY hh:mm A');
    posDetail.itemImage = '';
    posDetail.earnedPoints = posDetail?.currentEarnedPoints;
    posDetail.mobileNo = "999999999";
    posDetail.redeemPoints = posDetail.isReedem ? posDetail.redeemPoints : 0;
    posDetail.taxValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posTaxValue!, 0) : 0;
    posDetail.discountValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posDiscountValue!, 0) : 0;
    posDetail.othercharges = "5";
    posDetail.offerDiscountValue = billDisounct;
    //posDetail.offerDiscountValue = posDetail.offerDiscountValue;
    let billnoRequest: IEntryNoRequest = {}; billnoRequest.flag = 'POSMaster';
    billnoRequest.outletId = posDetail.outletId;
    let entryNo = await posMasterStore.getPosBillNo(billnoRequest);
    entryNo = entryNo?.billNo.split('-');
    posDetail.billNo = Number(entryNo[1]);
    // const status: any = {statusMessage: "Success"};
    const status = await posMasterStore.savePosMaster(posDetail);

    // const invoiceDetails = await posMasterStore.getInvoiceDetails(0)

    // if (invoiceDetails) {
    //   setPosInvoiceDetails(invoiceDetails)
    // }
    if (status.statusMessage === "Success") {
      setLoading({ ...isLoading, spinnerLoad: false })
      setShow(false);
      setSuccessModal(true);
    } else {
      setFailureModal(true)
    }

  }

  function calNetvalue(): number {
    let netValue = posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0
      + Number(posDetail.deliveryCharges ?? 0)
      - billDisounct
      - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0)
      + paymentRoundOff)?.toFixed(2) : 0;

    return Number(netValue)
  }

  function useCartQuantityUpdater(type: string, obj: IPOSProductDetails, qty?: number) {
    let existsItem = posItemDetils?.find((items) => isEqual(items, obj));
    if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
      if (type == 'add') {
        existsItem!.posItemQty! += qty!;
      } else {
        existsItem!.posItemQty! -= qty!;
      }
    } else if (type == 'add') {
      obj.posItemQty = 0;
      obj.posItemQty! += qty!;
    } else {
      obj.posItemQty = 0
      obj.posItemQty! -= qty!;
    }
    addToCart(obj);
  }

  function addToCart(itemBatch: IPOSProductDetails) {
    let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemBatch))
    let taxValue: number = 0;
    let discountValue: number = 0;
    let offerDiscountValue: number = 0;
    let totalPrice: number = 0;

    if (itemBatch?.posTaxPercentage) {
      taxValue = ((Number(itemBatch?.posItemSellingRate) - Number(itemBatch?.posItemSellingRate!)) / (1 + itemBatch?.posTaxPercentage! / 100))?.toFixed(2) as any;
    }

    let { assignOffer, offerDiscoung } = checkForItemOffer(itemBatch, assignedOffers, posItemOffers)
    setAssignedOff([...assignOffer])

    offerDiscountValue = offerDiscoung;

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

    itemBatch.posItemQty = itemBatch?.posItemQty;
    itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
    itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
    itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
    itemBatch.posTaxValue = Number(taxValue);
    itemBatch.posDiscountValue = discountValue + offerDiscountValue;
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount);

    const freeItems: any[] = checkForFreeItem(itemBatch, posFreeItems)

    if (!isexistsItem) {
      posItemDetils?.push(itemBatch);
      if (freeItems?.length) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      }
      setPOStItemDetails([...posItemDetils, ...freeItems])
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode?.toString(),
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils,)
      setPOSBatchDetails([...batchDetails])
    } else {
      setPOStItemDetails(posItemDetils.map((item, i) => {
        if (item.posItemId === itemBatch.posItemId && itemBatch?.posbatchNo === item?.posbatchNo) {
          return item = itemBatch;
        }
        return item;
      }));
      const updatedArray = posItemDetils?.map((each, ind) => {
        const matchingItem = freeItems?.find((item, ind) =>
          !item?.posItemId &&
          item?.posFreeItemId === each?.posFreeItemId &&
          item?.posbatchNo === each?.posbatchNo
        )
        return matchingItem || each
      })
      setPOStItemDetails([...updatedArray]);
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode,
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      if (freeItems?.length) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      } else if (batchObj?.itemId) {
        const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils,)
        setPOSBatchDetails([...batchDetails])
      }
    }
  }

  const billDisounct: number = useMemo(() => {
    let offerDiscount: number = 0;
    if (posItemOffers?.length > 0 && posItemDetils?.length > 0) {
      let billlValue = posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0)
      posItemOffers?.map((offers) => {
        if (new Date() < new Date(offers?.effectivetoDate)) {
          if (offers?.AssinOfferType === "billValue") {
            if (billlValue >= offers?.From_QV && billlValue <= offers?.To_QV) {
              offerDiscount = offers?.Discount_PV
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscount = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          }
        }
      })
    }
    return offerDiscount;
  }, [posItemDetils, posItemOffers])

  const paymentRoundOff: number = useMemo(() => {
    let total: number = 0;
    let decimal = 0;
    let roundOff: number = 0;
    total = posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail?.deliveryCharges! ?? 0) - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0))
    decimal = Math.round(total);
    roundOff = total - decimal;
    let plusMinus = - roundOff;
    return plusMinus;
  }, [posItemDetils, posItemOffers, posDetail?.isReedem])

  let deliveryDate: string = ""
  let expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + 7)
  deliveryDate = moment(expiryDate).format('DD-MMM-YYYY')
  return (
    <>
      <div className="App" >
        <Header />
        <Container fluid className='Pos full-height'>
          <div className='row full-height'>
            <div className='col-sm-9'>
              {isLoading?.spinnerLoad ?
                <div className='SpinnerBox'>
                  <Spinner animation="grow" size="sm" />
                </div> :
                null
              }
              <div className='ProductDetailBox1 '>
                <Accordion defaultActiveKey="0" className='accordion'>
                  <Accordion.Item eventKey="0" className='accordionitem'>
                    <Accordion.Header >1 Select A Delivery Address</Accordion.Header>
                    <Accordion.Body>
                      <div className='row'>
                        <div className='deliveryAddress'>
                          <input type='radio' className='inputradio' name='addresss'></input>&nbsp;
                          <label>GSS Office 123/2, Gandhi Street, Gandhi Puram, Coimbatore, Tamil Nadu, 665665 &nbsp; <a href="">Edit address</a> | <a href="">Add delivery instruction</a></label>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div >
                          <input type='radio' className='inputradio' name='addresss'></input>&nbsp;
                          <label>Dharuman 123/2, Nehru Street, T.Nagar, Chennai, Tamil Nadu, 600008 &nbsp; <a href="">Edit address</a> | <a href="">Add delivery instruction</a> </label>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div>
                          <FontAwesomeIcon icon={faPlus} />&nbsp; <a href="">Add a new address</a>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div className='col-sm-2'>
                          <button className='usethisbtn btn-warning full-width'>Use this address </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" className='accordionitem'>
                    <Accordion.Header>2 Payment Method</Accordion.Header>
                    <Accordion.Body>
                      <div className='row'>
                        <div className='deliveryAddress'>
                          <input type='text' className='applyinput' placeholder='Enter Code'></input>
                          <label><Button className='applybtn btn-danger'>Apply</Button></label>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div >
                          <input type='radio' className='inputradio' name='select'></input>&nbsp;
                          <label>Credit or debit card </label>
                          <div className='col-sm-3'>
                            <div className='checkimage'>
                              <Image src={require('../../../image/visa.png')} />
                              <Image src={require('../../../image/maestro.png')} />
                              <Image src={require('../../../image/card.png')} />
                              <Image src={require('../../../image/bank.png')} />
                            </div>
                          </div>
                          <div className='vertical-space-20'></div>
                          <div className='entercard'>
                            <FontAwesomeIcon icon={faPlus} onClick={modalShow} />&nbsp;<label onClick={modalShow} >Enter Card  </label>
                          </div>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div>
                          <input type='radio' className='' name='select'></input>&nbsp;
                          <label>Net Banking</label>
                          <div className='vertical-space-10'></div>
                          <div className='locationInputBox' style={{ width: '20%' }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={top100Films}
                              renderInput={(params: any) =>
                                <TextField  {...params} style={{ width: '100%', color: '#fff' }}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Option..'
                                />}
                            />
                          </div>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div >
                          <input type='radio' className='inputradio' name='select'></input>&nbsp;
                          <label>Other UPI Apps </label>
                          <div className='deliveryAddress'>
                            <input type='text' className='applyinput' placeholder='Enter UPI ID'></input>
                            <Button className='applybtn btn-danger' >Verify</Button>
                          </div>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div >
                          <input type='radio' className='inputradio' name='select'></input>&nbsp;
                          <label>EMI</label>
                        </div>
                        <div>
                          <div className='vertical-space-5'></div>
                          <div className='locationInputBox' style={{ width: '20%' }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={top100Films}
                              renderInput={(params: any) =>
                                <TextField  {...params} style={{ width: '100%', color: '#fff' }}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select EMI Option..'
                                />}
                            />
                          </div>
                        </div>
                        <div className='vertical-space-20'></div>
                        <div >
                          <input type='radio' className='inputradio' name='select'></input>&nbsp;
                          <label>Cash on Delivery / Pay on Delivery</label>
                          <p className='coddes'>Cash,UPI and Cards accepted.&nbsp;<a href=''>Know more</a> </p>
                        </div>
                        <div className='vertical-space-10'></div>
                        <div className='col-sm-3'>
                          <button className='usethisbtn btn-warning full-width'>Use this payment method </button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2" className='accordionitem'>
                    <Accordion.Header>3  Offers</Accordion.Header>
                    <Accordion.Body>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3" className='accordionitem'>
                    <Accordion.Header>4 Item And Delivery</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div className='row itemtextline' >
                          <h6><b>Delivery Date :</b> {deliveryDate}</h6>
                          <h6>If you order in the next 13 hours and 11 minutes <a href=''>(Details)</a></h6>
                          <h6>Items dispatched by GSM </h6>
                          {posItemDetils?.map((val, index) => {
                            return (
                              <>
                                <div className='checkoutimg'>
                                  <div className='vertical-space-15'></div>
                                  <div className='row'>
                                    <div className='col-sm-2'>
                                      <div className='ProductDetailcheck'>
                                        <div className='imgBox'>
                                          {/* <Image src={require('../../../image/ProductImg.png')} /> */}
                                          <Image width={90} height={90} src={`data:image/jpeg;base64,${val?.imageData}`} />
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-sm-8'>
                                      <div >
                                        <h6><b>{val?.posItemName}</b></h6>
                                        {val?.comments && <h6>{val?.comments}</h6>}
                                      </div>
                                      <div>
                                        <h6><span>&#8377;</span>{val?.posNetAmount}</h6>
                                      </div>
                                      <div>
                                        <h6>Color : <span>Black</span></h6>
                                      </div>
                                      <div className='qtyoptn'>
                                        <InputGroup className="QutBox">
                                          <Button variant="outline-secondary"><FontAwesomeIcon icon={faMinus} /></Button>
                                          <Form.Control aria-label="Example text with two button addons" placeholder='0' />
                                          <Button variant="outline-secondary"><FontAwesomeIcon icon={faPlus} /></Button>
                                        </InputGroup>
                                      </div>
                                      <div className='vertical-space-10'></div>
                                      <div>
                                        <label >Select a delivery option</label><br></br>
                                        <input type='radio' className='inputradio' name='order'></input>&nbsp; <label>Free Delivery</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <hr></hr>
                <div className='col-sm-12 checkoutdescribe'>
                  <p>
                    Need help? Check our <a href=''>help pages</a> or <a href=''>contact us</a><br></br>
                    When your order is placed, we'll send you an e-mail message acknowledging receipt of your order.
                    If you Select to pay using an electronic payment method (credit card, debit card or net banking),
                    you will be directed to your bank's website to complete your payment.
                    Your contract to purchase an item will not be complete until we receive your electronic payment and dispatch your item.
                    If you Select to pay using Pay on Delivery (POD), you can pay using cash/card/net banking when you receive your item.<br></br>
                    See gss.in's <a href=''>Return Policy</a>.<br></br>
                    Need to add more items to your order? Continue shopping on the <a href=''>gss.in homepage</a> .</p>
                </div>
              </div>
            </div>

            <div className='col-sm-3'>
              <div className='ProductDetailBox1'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='ProductDetail'>
                      <div className='tableBox'>
                        <div className='col-sm-11'>
                          <button className='placeorderbtn btn-warning full-width' onClick={processItemsToOrder}>Place your order </button>
                          <div className='ProductDetailBox1' style={{ marginTop: '0px', background: 'none' }}>
                            <div className='row'>
                              <div className='col-sm-12'>
                                <div style={{ marginTop: '36px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 3px 2px', borderRadius: '2px', minHeight: '200px', background: 'white', color: 'black' }}>
                                  <div style={{ borderBottom: '1px solid #f0f0f0', fontSize: '26px', padding: '14px 20px', color: '#878787', fontWeight: '700', fontFamily: 'sans-serif' }}>
                                    PRICE DETAILS
                                  </div>
                                  <div style={{ width: '100%', padding: '0 24px' }}>
                                    <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Total Price :</div>
                                      <div>
                                        <span>
                                          <span>&#8377;</span>
                                          {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posNetAmount! ?? 0)), 0)?.toFixed(2) : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Total Discount <span style={{ fontSize: '12px' }}>(Inc)</span> :</div>
                                      <div>
                                        <span>
                                          <span>&#8377;</span>
                                          {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posItemQty!) * Number(v?.posDiscountValue! ?? 0)), 0 + billDisounct)?.toFixed(2) : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Total Tax <span style={{ fontSize: '12px' }}>(Inc)</span> :</div>
                                      <div>
                                        <span>
                                          <span>&#8377;</span>
                                          {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * Number(v?.posTaxValue! ?? 0)), 0)?.toFixed(2) : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Delivery Charges :</div>
                                      <div><span>{posDetail?.deliveryCharges! > 0 ? posDetail?.deliveryCharges! : 'Free'}</span></div>
                                    </div>
                                    <div style={{ borderTop: '1px dashed #e0e0e0', padding: '16px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Bill Amount :</div>
                                      <div>
                                        <span>
                                          <span>&#8377;</span>
                                          {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail?.deliveryCharges! ?? 0))?.toFixed(2) : 0}
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ borderTop: '1px dashed #e0e0e0', padding: '16px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Round Off :</div>
                                      <div>
                                        <span><span>&#8377;</span>
                                          {paymentRoundOff?.toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                    <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                      <div>Payable Amount :</div>
                                      <div>
                                        <span>
                                          <span>&#8377;</span>
                                          {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail.deliveryCharges! ?? 0) - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0) + paymentRoundOff)?.toFixed(2) : 0}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div style={{ background: 'rgb(211, 244, 234)', padding: '12px 16px', marginBottom: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
                                    <Image src='https://images.meesho.com/images/marketing/1657272960496.png' width={20} height={20} />
                                    <div style={{ fontSize: '17px', fontWeight: '700', color: 'rgb(3, 141, 99)' }}>Yay! Your total discount is ₹
                                      <span>
                                        {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posItemQty!) * Number(v?.posDiscountValue! ?? 0)), 0 + billDisounct)?.toFixed(2) : 0}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                  <div>
                                    <div style={{ fontSize: 'large', fontWeight: 900, color: 'cadetblue' }}>Your Safety, Our Priority</div>
                                    <div style={{ fontSize: '14px', color: 'black' }}>Safe and Secure Payments.</div>
                                  </div>
                                  <Image src={require('../../../image/deliveryimage.webp')} height={130} width={130} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='vertical-space-10'></div>
                        {/* <table className="table" >
                          <div className='order'>
                            <h4>Order Summary</h4>
                          </div>
                          <tbody>
                            <tr>
                              <td>MRP :</td>
                              <td><span><span>&#8377;</span> {priceDetails.totalMRP}</span></td>
                            </tr>
                            <tr>
                              <td>Selling Price :</td>
                              <td><span><span>&#8377;</span> {priceDetails.totalSellingPrice}</span></td>
                            </tr>
                            <tr>
                              <td>Tax Value :</td>
                              <td><span><span>&#8377;</span> {parseFloat(priceDetails.totalTaxPrice).toFixed(2)}</span></td>
                            </tr>
                            <tr>
                              <td>Discount :</td>
                              <td><span><span>&#8377;</span> {priceDetails.totalDiscountValue}</span></td>
                            </tr>
                            <tr className='checktotal'>
                              <td >Order Total :</td>
                              <td><span><span>&#8377;</span > {priceDetails.netAmount}</span></td>
                            </tr>
                            <tr className='checktotal'>
                              <td>Your Savings :</td>
                              <td><span>&#8377;{priceDetails.totalDiscountValue ? priceDetails.totalSellingPrice! - priceDetails.totalDiscountValue! : 0}</span></td>
                            </tr>
                          </tbody>
                        </table> */}
                        <div>
                          <ul>
                            <li>free delivery</li>
                            <li>item discounts</li>
                            <div title="Items :₹ 2900.00 
                            Delivery :₹ 100.00 
                            Promotion Applied :₹ -40.00 
                            Order Total :₹ 2800.00">Details</div>
                          </ul>
                        </div>
                        <div className='checkoutcalc'>
                          <a href=''>How are delivery costs calculated?</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='PriceHistoryModel' style={{ marginTop: '20px' }}>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setFailureModal(false)}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Enter Card Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='row full-height'>
                  <div className='col-sm-8'>
                    <table className='carddetail'>
                      <tbody>
                        <tr>
                          <td>Card Number :</td>
                          <td><input type='text'></input></td>
                        </tr>
                        <tr>
                          <td>Nick Name :</td>
                          <td><input type='text'></input></td>
                        </tr>
                        <tr>
                          <td>Enpiry Date :</td>
                          <td><select className='modalmonth'>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                          </select>
                            <span className='modalmonth'><select >
                              <option>2023</option>
                              <option>2024</option>
                              <option>2025</option>
                              <option>2026</option>
                              <option>2027</option>
                              <option>2028</option>
                              <option>2029</option>
                              <option>2030</option>
                              <option>2031</option>
                              <option>2032</option>
                              <option>2033</option>
                              <option>2034</option>
                              <option>2035</option>
                              <option>2036</option>
                              <option>2037</option>
                              <option>2038</option>
                              <option>2039</option>
                              <option>2040</option>
                              <option>2041</option>
                              <option>2042</option>
                              <option>2043</option>
                            </select> </span>
                          </td>

                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <div className='col-sm-4'>
                    <div>
                      <p>Please ensure that you enable your card for online payments from your bank’s app.</p>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='checkimage'>
                            <Image src={require('../../../image/visa.png')} />
                            <Image src={require('../../../image/maestro.png')} />
                            <Image src={require('../../../image/card.png')} />
                            <Image src={require('../../../image/bank.png')} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='vertical-space-10'></div>
                    <div className='col-sm-4 carddetailbtn'>
                      <button className='carddetailbtn btn-default full-width'>Cancel </button>
                    </div>

                    <div className='vertical-space-10'></div>
                    <div className='col-sm-7 carddetailbtn2'>
                      <button className='usethisbtn btn-warning full-width'>Enter card details </button>
                    </div>
                  </div>

                </div>

              </Modal.Body>
            </Modal>

          </div>

        </Container>
      </div>
    </>
  );

});

export default CheckOut;
