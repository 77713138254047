import { IDamageTransfer } from "../model";

export function saveValidation(damageTransfer: IDamageTransfer) {
    let error: any = {}
    if (!damageTransfer?.remarks) {
        error.remarks = 'Enter Remarks'
    }
    if (damageTransfer?.type === 'warehouse' && !damageTransfer?.wId) {
        error.wId = 'Select Warehouse'
    }
    if (!damageTransfer?.empId) {
        error.empId = 'Select Created By'
    }
    return error;
}