import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IsubCategory } from './model';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import subcategoryValidation from './validation';
import Cookies from 'js-cookie';


const SubCategory = observer((): JSX.Element => {
  const [isLoading, setLoading] = useState(true)
  const [subcategory, setSubcategory] = useState<IsubCategory>({})
  const { supplierMasterStore,subCategoryStore, brandStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  console.log(subcategory, 'subcategory>>')

  const handleCancel = () => {
    navigate('/Admin/SubCategory/SubCategoryDetail');
  }

  const { subCategoryId } = useParams();

  const saveSubcategory = async (e) => {
    e.preventDefault();

    let error: any = {};
    error = subcategoryValidation(subcategory);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      setLoading(true);
      let status: string = '';
      if (!subcategory?.nobatch) {
        subcategory.nobatch = "false"
      }
      if (subCategoryId !== undefined) {
        status = await subCategoryStore.updateSubcategory(subcategory);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true)
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        if (!subcategory?.sudexo) {
          subcategory.sudexo = 0;
        }
        const status = await subCategoryStore.savesubCategory(subcategory);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  function handleChangeInput(event: any) {

    const { name, value } = event.target;

    setSubcategory({ ...subcategory, [name]: value })
  }

  const handlebatchNo = (e) => {
    let { checked } = e.target;
    setSubcategory({ ...subcategory, nobatch: checked?.toString() })
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/SubCategory/SubCategoryDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  async function mapItemData(SubcategoryDels) {

    subcategory.subCategoryId = SubcategoryDels.subCategoryId
    subcategory.categoryName = SubcategoryDels.categoryName;
    subcategory.subCategoryName = SubcategoryDels.subCategoryName;
    setSubcategory(subcategory);
  }

  async function getBrandNo() {
    if (subCategoryId !== undefined) {
      const data = await subCategoryStore.SubcategoryDetails(parseInt(subCategoryId!));
      mapItemData(data);
    }
    else {
      const data = await brandStore.getEntryNo('SubCategory');
      if (data !== undefined) {
        subcategory.subCategoryId = data.entryNo + 1;
        setSubcategory(subcategory);
      }
    }
    await brandStore.getEntryNo('SubCategory');
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     getBrandNo();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Sub_category';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              getBrandNo();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/SubCategory/SubCategoryDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

 
  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

  return (
 
      <>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Sub Category</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='inputBox'>
              <label>Code</label>
              <input type="text" className='' name='' onChange={handleChangeInput}
                value={subcategory.subCategoryId} style={{ width: "40%" }} placeholder='Code..' disabled></input>
              <input type="checkbox" style={{ marginLeft: '12px' }} onChange={handlebatchNo}></input>
              <span style={{ marginLeft: '10px' }}>No Batch</span>
              <input type="checkbox" style={{ marginLeft: '12px' }} onChange={(event) => setSubcategory({ ...subcategory, sudexo: event?.target?.checked ? 1 : 0 })}></input>
              <span style={{ marginLeft: '10px' }}>Sudexo</span>
            </div>
            <div className='vertical-space-10'></div>
            <div className='inputBox'>
              <label>Category <span>*</span></label>
              <input type="text" className='' style={{ width: "80%" }} value={subcategory.categoryName} name='categoryName'
                onChange={handleChangeInput} placeholder='Enter Category..'></input>
              {errors.categoryName && <p style={{ color: 'red' }}>{errors.categoryName}</p>}
            </div>
            <div className='vertical-space-10'></div>
            <div className='inputBox'>
              <label>Sub Category <span>*</span></label>
              <input type="text" className='' style={{ width: "80%" }}
                value={subcategory.subCategoryName} name='subCategoryName'
                onChange={handleChangeInput}
                placeholder='Enter Sub Category..'></input>
              {errors.categoryName && <p style={{ color: 'red' }}>{errors.subCategoryName}</p>}
            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={saveSubcategory}>Submit</button>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
  );
  }
});

export default SubCategory;
