import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import { Autocomplete, Checkbox } from "@mui/material";
import { Accordion } from "react-bootstrap";
import { IIInterviewLetter } from "./model";
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { saveValidation } from "./validation";
import { removeDuplicates } from "../../common/shared/utils";

const InterviewLetter = () => {

    const navigate = useNavigate();

    const { interviewStore, jobMasterStore, commonStore, resumeBankStore } = useStores()
    const { loadEmployee, loadEmployees } = commonStore;

    const [interviewLetterFields, setInterviewLetter] = useState<IIInterviewLetter>(new IIInterviewLetter())

    const [resumeShortList, setResumeShortlist] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setErrors] = useState<any>({});

    const [onOpenDetail, setOnOpenDetail] = useState({ bool: false, ResumBankid: 0 })

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/InterviewLetter/List');
    }

    async function handleSaveCategory() {
        let error: any = {}
        error = saveValidation(interviewLetterFields)
        setErrors({ ...error })
        resumeShortList?.forEach((each) => {
            if (each?.ResumBankid === onOpenDetail?.ResumBankid) {
                interviewLetterFields.currentcity = each?.Currentcity;
                interviewLetterFields.hiringmanager = (each?.hiringmanager)?.toString();
                interviewLetterFields.jobcode = interviewLetterFields?.jobid?.toString();
                interviewLetterFields.resumeBankID = onOpenDetail?.ResumBankid;
            }
        })
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const entryNo = await jobMasterStore.getHREntryNo('Interviewletter');
            interviewLetterFields.intrviewid = Number(entryNo.entrynumber)
            let interviewLetters: IIInterviewLetter[] = [];
            interviewLetters?.push(interviewLetterFields)
            const status = await interviewStore.saveInterviewLetter(interviewLetters);
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
                setInterviewLetter(new IIInterviewLetter());
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleClickCallLetter(ResumBankid) {
        setOnOpenDetail({ bool: true, ResumBankid: ResumBankid })
    }

    function updateResumeList(candidates: any[], resumes: any[], jobMaster: any[], jobVacancy: any[]) {
        let resumeBankMasterDeatail: any[] = Object.assign([], resumes);

        candidates?.forEach((candidate) => {
            const { ResumBankid, ...candidateInfo } = candidate;
            resumeBankMasterDeatail?.forEach((resume) => {
                const { Resumcode, ...resumeInfo } = resume;
                if (Resumcode == ResumBankid) {
                    resume['Candidatename'] = candidateInfo?.Candidatename;
                    resume['NoticePeriod'] = candidateInfo?.NoticePeriod;
                    resume['Qualification'] = candidateInfo?.EducationQualification;
                    resume['ExpYear'] = candidateInfo?.ExpYear;
                    resume['ExpMonth'] = candidateInfo?.ExpMonth;
                    resume['Currentcity'] = candidateInfo?.Currentcity;
                }
            })
        })

        jobMaster?.forEach((jobs) => {
            const { Jobcode, ...jobInfo } = jobs;
            resumeBankMasterDeatail?.forEach((resume) => {
                const { Jobid, ...resumeInfo } = resume;
                if (Jobid == Jobcode) {
                    resume['Jobtitle'] = jobInfo?.Jobtitle;
                    resume['JobDescription'] = jobInfo?.JobDescription;
                }
            })
        })

        jobVacancy?.forEach((vacancy) => {
            const { Jvid, ...vacancyInfo } = vacancy;
            resumeBankMasterDeatail?.forEach((resume) => {
                const { Jobid, ...resumeInfo } = resume;
                if (Jobid == Jvid) {
                    resume['hiringmanager'] = vacancyInfo?.Workmanager;
                }
            })
        })

        const mergedResumes: any[] = resumeBankMasterDeatail;
        return mergedResumes;
    }

    async function fetchApicall() {
        const entryNo = await jobMasterStore.getHREntryNo('Interviewletter');
        setInterviewLetter({
            ...interviewLetterFields,
            intrviewid: Number(entryNo?.entrynumber),
        })
        await loadEmployee();
        const resumeBankShorlits = await resumeBankStore?.loadResumeBankshortlist();
        const jobMaster = await jobMasterStore?.loadJobMaster()
        const jobVacancy = await jobMasterStore.loadJobVacancy()
        const candidateDetails = await resumeBankStore.loadCandidatedetails()

        const updatedResumeList = updateResumeList(candidateDetails, resumeBankShorlits, jobMaster, jobVacancy)
        setResumeShortlist([...updatedResumeList])
        setLoading(false)
        // workmanagerid
    }
    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchApicall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>
                            <div className='hrBox'>
                                <h3>Interview Letter</h3>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Hiring Manager</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Qualification</th>
                                            <th scope="col">Experience</th>
                                            <th scope="col">Current City</th>
                                            <th scope="col">View</th>
                                            <th scope="col">Call Letter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resumeShortList?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{each?.Jobid}</td>
                                                    <td>{loadEmployees?.find(obj => obj?.employeeId == each?.hiringmanager)?.employeeName}</td>
                                                    <td>{each?.Candidatename}</td>
                                                    <td>{each?.Qualification}</td>
                                                    <td>{`${each?.ExpYear}y${each?.ExpMonth}m`}</td>
                                                    <td>{each?.Currentcity}</td>
                                                    <td>View</td>
                                                    <td><button className='' onClick={() => handleClickCallLetter(each?.ResumBankid)}>Call Letter</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                            </div>
                            <Modal show={onOpenDetail?.bool} onHide={() => { setOnOpenDetail({ bool: false, ResumBankid: 0 }) }} className='PriceHistoryModel flexImp' style={{ margin: 0, alignItems: 'center', justifyContent: 'center' }}>
                                <div className="outletInputField inputFormBox" style={{ width: '1200px' }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>INTERVIEW CALL LETTER</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inputBoxLists">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Letter Code</label>
                                                        <input type="text" name='brandId' disabled
                                                            value={interviewLetterFields?.intrviewid} style={{ width: "100%" }}
                                                            className='brandinput' ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label style={{ fontSize: '16px' }}>Date</label>
                                                        <input type="date" className="inputBoxDate" style={{ width: "100%", fontWeight: "lighter" }}
                                                            placeholder="DD/MMM/YYYY" data-date="" data-date-format="DD MMMM YYYY"
                                                            value={interviewLetterFields?.date} onChange={(event) => setInterviewLetter({ ...interviewLetterFields, date: event?.target?.value })}
                                                            name="fromdate"
                                                        ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}> Job Code<span>*</span> </label>
                                                        <input type="text" name='brandId'
                                                            value={interviewLetterFields.jobid = resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.Jobid}
                                                            disabled style={{ width: "100%" }} className='brandinput' ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Title</label>
                                                        <input type="text" name='brandId'
                                                            value={interviewLetterFields.jobTitle = resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.Jobtitle}
                                                            disabled style={{ width: "100%" }}
                                                            className='brandinput' placeholder="Enter Title" ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Job Description</label>
                                                        <input type="text" name='brandId'
                                                            value={interviewLetterFields.jobdescription = resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.JobDescription}
                                                            disabled style={{ width: "100%" }}
                                                            className='brandinput' placeholder="Enter Job Description" ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Name</label>
                                                        <input type="text" name='brandId'
                                                            value={resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.Candidatename}
                                                            disabled style={{ width: "100%" }}
                                                            className='brandinput' placeholder="Enter Name" ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Email Id</label>
                                                        <input type="text" name='brandId' disabled
                                                            value={resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.Emailid}
                                                            style={{ width: "100%" }} className='brandinput' ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Mobile</label>
                                                        <input type="text"
                                                            name='brandId'
                                                            value={resumeShortList?.find(each => each?.ResumBankid == onOpenDetail?.ResumBankid)?.mobileno}
                                                            disabled
                                                            style={{ width: "100%" }}
                                                            className='brandinput' ></input>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label style={{ fontSize: '16px' }}>Interview On</label>
                                                        <input type="date" name="fromdate" className="inputBoxDate"
                                                            style={{ width: "100%", fontWeight: "lighter" }}
                                                            placeholder="DD/MMM/YYYY" data-date=""
                                                            value={interviewLetterFields?.interviewdate}
                                                            onChange={(e) => setInterviewLetter({ ...interviewLetterFields, interviewdate: e.target.value })}
                                                            data-date-format="DD MMMM YYYY"
                                                        ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label style={{ fontSize: '16px' }}>Time</label>
                                                        <input type="time" className="inputBoxDate" name="fromdate"
                                                            style={{ width: "100%", fontWeight: "lighter" }}
                                                            value={interviewLetterFields?.interviewTime}
                                                            onChange={(e) => setInterviewLetter({ ...interviewLetterFields, interviewTime: e.target.value })}
                                                        ></input>
                                                    </div>
                                                    {errors.interviewTime && <p style={{ color: "red" }}>{errors.interviewTime}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Interview Location</label>
                                                        <input type="text" name='brandId' style={{ width: "100%" }}
                                                            onChange={(e) => setInterviewLetter({ ...interviewLetterFields, location: e.target.value })}
                                                            className='brandinput' placeholder="Enter Interview Location" ></input>
                                                    </div>
                                                    {errors.location && <p style={{ color: "red" }}>{errors.location}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}> Generatd By<span>*</span> </label>
                                                        <AutoComplete options={removeDuplicates(loadEmployees, 'employeeName')} placeholder="Generated By..." clsName="full-width"
                                                            value={loadEmployees?.find((each) => each?.employeeId == interviewLetterFields?.employeeid)?.employeeName ?? ''}
                                                            emitOption={(opiton) => setInterviewLetter({ ...interviewLetterFields, employeeid: opiton?.employeeId })}
                                                            getOptionLabel="employeeName" />
                                                    </div>
                                                    {errors.employeeid && <p style={{ color: "red" }}>{errors.employeeid}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label style={{ fontSize: '16px' }}>Mode</label>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox" checked={interviewLetterFields?.mode === 'Email'}
                                                                    onChange={(evt) => setInterviewLetter({ ...interviewLetterFields, mode: evt?.target?.checked ? 'Email' : '' })} />
                                                                <span className="flex align-items-center"> {"Email"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox" checked={interviewLetterFields?.mode === 'SMS'}
                                                                    onChange={(evt) => setInterviewLetter({ ...interviewLetterFields, mode: evt?.target?.checked ? 'SMS' : '' })} />
                                                                <span className="flex align-items-center"> {"SMS"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox" checked={interviewLetterFields?.mode === 'Courier'}
                                                                    onChange={(evt) => setInterviewLetter({ ...interviewLetterFields, mode: evt?.target?.checked ? 'Courier' : '' })} />
                                                                <span className="flex align-items-center"> {"Courier"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.mode && <p style={{ color: "red" }}>{errors.mode}</p>}
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Description</label>
                                                        <textarea name='brandId' style={{ width: "100%" }}
                                                            className='brandinput' placeholder="Enter Description"
                                                            value={interviewLetterFields?.description}
                                                            onChange={(evt) => setInterviewLetter({ ...interviewLetterFields, description: evt?.target?.value })}
                                                        ></textarea>
                                                    </div>
                                                    {errors.description && <p style={{ color: "red" }}>{errors.description}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <div className='btnBox'>
                                            <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                                        </div>
                                    </Modal.Footer>
                                </div>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default InterviewLetter;