import { observable } from "mobx";
export interface IShiftMaster{
    shiftId?:number;
    shiftName?:string;
    startingTime?:string; 
    endingTime?:string;
    breakTime?:string;
    breakstartingTime?:string;    
    breakendingTime?:string;   
    shiftValue?:number;
    shiftMasterDetails?:ShiftMasterDetails[]; 
}
export interface ShiftMasterDetails{
    shiftId?:number;
    shiftBreakTime?:string;
    shiftBreakStartingTime?:string;    
    shiftBreakEndingTime?:string;
    
}

export interface IShiftMasterSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const shiftMasterDetails=observable<IShiftMaster>({
    shiftId:0,
    shiftName:'',
    startingTime:'',  
    endingTime:'',
    breakTime:'',
    breakstartingTime:'',    
    breakendingTime:'',  
    shiftMasterDetails:[],
    
});

export default shiftMasterDetails;