
function CourierPartnerValidation(courierPartner){
    let errors :any={};
    
      
      if(!courierPartner.cargocompanyName){
        errors.cargocompanyName = "Enter Cargocompany Name";
      }
      if(!courierPartner.doorNo){
        errors.doorNo = "Enter Door No";
      }
      if(!courierPartner.street){
        errors.street = "Enter Street ";
      }
      if(!courierPartner.area){
        errors.area = "Enter Area ";
      }
      if(!courierPartner.city){
        errors.city = "Select City";
      }
      if(!courierPartner.contactNo){
        errors.contactNo = "Enter Contact No";
      }
      if(!courierPartner.emailid){
        errors.emailid = "Enter Email id";
      }
      if(!courierPartner.websitelink){
        errors.websitelink = "Enter Website Link";
      }
      if(!courierPartner.branchname){
        errors.branchname = "Enter Branch Name";
      }
      if(!courierPartner.contactperson){
        errors.contactperson = "Enter Contact Person";
      }
      if(!courierPartner.contactNumber){
        errors.contactNumber = "Enter Contact Number";
      }
      if(!courierPartner.mailid){
        errors.mailid = "Enter Mailid";
      }
    return errors;
  };
    
    export default CourierPartnerValidation;