import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const AssignOutletManagerDetail = observer((): JSX.Element => {

  const navigate = useNavigate();

  const { outletManagerStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const { OutletManagerList } = outletManagerStore;

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/AssignOutletManager');
  }

  const deleteOutletManagerDetials = async (omId) => {
    setLoading(true);
    const status = await outletManagerStore.deleteOutletManager(omId);
    if (status === 'Success') {
      fetchOutletManagerData();
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    fetchOutletManagerData();
    setSuccessModal(false);

  }

  async function fetchOutletManagerData() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Assign_outlet_managers'), outletManagerStore?.getOutletManager()])
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const isCurrenPage = useRef(true)

  useEffect(() => {
    if (isCurrenPage.current) {
      fetchOutletManagerData();
      isCurrenPage.current = false;
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (OutletManagerList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(OutletManagerList)
    }
  }, [OutletManagerList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(OutletManagerList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='outletInputField inputFormBox LgInputField'>
              <div className='container-fluid'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={OutletManagerList}
                  searchTitles={[{ key: "outletName", value: "Outlet Name" }, { key: "employeeName", value: "Manager" }]}
                  emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-sm-5'>
                      <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{OutletManagerList?.length} Records</button>
                      </div>
                    </div>
                    <div className=' col-sm-7' >
                      <Stack spacing={2}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                      </Stack>
                    </div>
                  </div>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope='col'>Entry No </th>
                        <th scope='col'>Date  </th>
                        <th scope='col'>Outlet Name </th>
                        <th scope='col'>Manager </th>
                        <th scope='col'>Effective Form  </th>
                        <th scope='col'>Create </th>
                        <th scope='col'>Update </th>
                        <th scope='col'>Delete </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                        (isSearch ? filteredData : currentPageData)?.slice().sort((a, b) => b.omId - a.omId).map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.omId}</td>
                              <td>{moment(val.date).format('DD-MMM-YYYY')}</td>
                              <td>{val.outletName}</td>
                              <td>{val.employeeName}</td>
                              <td>{moment(val.effectiveFrom).format('DD-MMM-YYYY')}</td>
                              <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                              <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                              <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteOutletManagerDetials(val.omId)} /></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Deleted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>
      }
    </>
  );

});

export default AssignOutletManagerDetail;
