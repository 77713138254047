export class PackingPlanSave {
    packingid?: number;
    packingdate?: string;
    teamid?: number;
    machineid?: number;
    productName?: string;
    productid?: number;
    expiryDays?: string;
    qty?: number;
    mrp?: number;
    unitid?: string;
    itemUOM?: string;
    batchNo?: string;
    barcode?: string;
    teamName?: string;
    machineName?: string;
}

export class IPrintSizeMaster {
    height?: number = 0
    width?: number = 0
    unit?: string = ""
}
export class Barcodelist {
    batchNo?: string;
    barcode?: string;
   
}