import React, { useEffect, useRef, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faPrint, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import moment from 'moment';

const InvoiceDetail = observer((): JSX.Element => {
  const [show, setShow] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false)
  const [isLoading, setLoading] = useState(true);
  const { invoiceStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const modalClose = () => setShow(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [posInvoiceDetails, setPosInvoiceDetails] = useState<any>({})

  const [salesLedgerList, setSalesLedgerList] = useState<any[]>([])
  const [selectedLedgerList, setSelectedLedgerList] = useState<any[]>([])

  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/Admin/Invoice")
  }

  const handleInvoceDetails = async (invoice) => {
    setLoading(true);
    await invoiceStore.getInvoiceProduts(invoice?.invoiceId);
    let filterLedger = salesLedgerList?.filter((each) => each?.SOrdMNo === invoice?.salesOrderNo)
    if (filterLedger?.length) {
      setSelectedLedgerList([...filterLedger])
    }
    setLoading(false);
    setShow(true)
  }

  async function loadInvoiceDetail() {
    setLoading(false);
    const [userRights, salesOrderList, salesOrderLedger] = await Promise.all([
      userCreationStore?.getUserRight('Invoice'), invoiceStore?.loadSalesOrederDetails(),
      invoiceStore?.loadSalesOrderLedger(), invoiceStore.getInvoices()
    ])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    let updatedList = salesOrderLedger?.map((each) => {
      salesOrderList?.map((obj) => {
        if (obj?.SOrdMID === each?.SaleOrdId) {
          each.SOrdMNo = obj?.SOrdMNo
          return each
        }
      })
      return each
    })
    setSalesLedgerList([...updatedList])
  }

  async function invoicePrint(posid) {
    const invoiceDetails = await invoiceStore.getInvoiceBillPrint(posid?.invoiceId)
    if (invoiceDetails) {
      invoiceDetails.salesOrderNo = posid?.salesOrderNo;
      setPosInvoiceDetails(invoiceDetails)
      setSuccessModal(true);
    }
  }

  function calcRoundOff() {
    const total = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)
    const decimalValue = Math.floor(total);
    const roundOff = total - decimalValue;
    const roundoffValue = total % 1 >= 0.5 ? `+ ${roundOff?.toFixed(2)}` : `- ${roundOff?.toFixed(2)}`
    return roundoffValue
  }

  function calcTotalAmt() {
    const total: number = posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0) - Number(posInvoiceDetails?.offerdiscount) - Number(posInvoiceDetails?.redeemPointsamt)
    // const roundoffValue = total % 1 >= 0.5 ? 1 : -1;
    const roundedTotal = Math.round(total);
    const totalAmount = roundedTotal + Number(posInvoiceDetails?.deliverycharge);

    return totalAmount;
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      loadInvoiceDetail();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (invoiceStore?.invoices && invoiceStore?.invoices?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(invoiceStore?.invoices)
    }
  }, [invoiceStore?.invoices])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(invoiceStore?.invoices?.slice()?.sort((a, b) => (new Date(b?.invoiceDate) < new Date(a?.invoiceDate)) ? -1 : 1), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  const convertArrayToMap = (students: any[]): Map<string, any[]> => {
    return students?.reduce((map, student) => {
      if (!map.has(student?.taxper)) {
        map.set(student?.taxper, []);
      }
      map.get(student?.taxper)!.push(student);
      return map;
    }, new Map<string, any[]>());
  };

  function roundOff() {
    let total = 0;
    let roundOffval = 0;
    let roundedVal = 0;
    if (invoiceStore?.invoicesProducts?.length > 0) {
      total = invoiceStore?.invoicesProducts?.reduce((a, v) => a = a + Number(v?.itemnettotal ?? 0), 0) + (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, b) => a = a + Number(b?.SaleOrdLedgerAmount), 0) : 0)
      roundedVal = Math.round(total)
      roundOffval = Math.abs(total - roundedVal)
    }
    return roundOffval;
  }

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={invoiceStore?.invoices}
        searchTitles={[{ key: "outletName", value: "Outlet Name" }]}
        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
      <div className='vertical-space-20'></div>
      <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='btnBox'>
          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{invoiceStore?.invoices?.length} Records</button>
        </div>
        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
        </Stack>
      </div>
      <div className='vertical-space-20'></div>
      <div className='tableListDetails'>
        {
          isLoading ?
            <div className='SpinnerBox'>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div> : null
        }
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Date   </th>
              <th scope='col'>Cus. Name  </th>
              <th scope='col'>Outlet </th>
              <th scope='col'>GSTIN </th>
              <th scope='col'>Payment Mode </th>
              <th scope='col'>Sales Order No </th>
              <th scope='col'></th>
              <th scope='col'>Create</th>
              <th scope='col'>Update</th>
              <th scope='col'>Delete</th>
              <th scope='col'>Print</th>
            </tr>
          </thead>
          <tbody>
            {(isSearch ? filteredData : currentPageData).length ?
              (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.invoiceDate}</td>
                    <td>{val.memberName}</td>
                    <td>{val.outletName}</td>
                    <td>{val.gstin}</td>
                    <td>{val.paymentMode}</td>
                    <td>{val.salesOrderNo}</td>
                    <td><button disabled={!userRights?.view} onClick={() => handleInvoceDetails(val)}>Detail</button></td>
                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                    <td><button disabled={!userRights?.print} className="Edit" onClick={() => invoicePrint(val)}><FontAwesomeIcon icon={faPrint} /></button></td>
                  </tr>
                )
              }) : <>No Data</>
            }
          </tbody>
        </table>
      </div>

      <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Invoice Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">Product </th>
                  <th scope="col">Qty</th>
                  <th scope="col">MRP</th>
                  <th scope="col">Selling Price</th>
                  <th scope="col">Tax</th>
                  <th scope="col">Discount </th>
                  <th scope="col">Total </th>
                  <th scope="col">Net Amount </th>

                </tr>
              </thead>
              <tbody>
                {invoiceStore.invoicesProducts.length > 0 &&
                  invoiceStore.invoicesProducts.map((val, index) => {
                    console.log(val, 'val>>')
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{val.itemname}</td>
                        <td>{val.invoiceqty}</td>
                        <td>{val.itemmrp}</td>
                        <td>{val.itemsellprice}</td>
                        <td>{val.itemtaxamount}</td>
                        <td>{val.itemdiscount}</td>
                        <td>{val.itemtotal}</td>
                        <td>{val.itemnettotal}</td>
                      </tr>
                    )
                  })
                }
                <tr>
                  <td colSpan={7}></td>
                  <td>Subtotal: </td>
                  <td>{invoiceStore?.invoicesProducts?.length > 0 ?
                    invoiceStore?.invoicesProducts?.reduce((a, v) => a = a + Number(v?.itemnettotal ?? 0), 0)?.toFixed(2) : "0.00"}</td>
                </tr>
                {selectedLedgerList?.map((each) => {
                  return (
                    <tr>
                      <td colSpan={7}></td>
                      <td> {each?.ledger_accout_name}  :</td>
                      <td>{each?.SaleOrdLedgerAmount ?? 0}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={7}></td>
                  <td>Round Off: </td>
                  <td>{roundOff()?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colSpan={7}></td>
                  <td>Net Total: </td>
                  <td>{invoiceStore?.invoicesProducts?.length > 0 ?
                    Math.round((invoiceStore?.invoicesProducts?.reduce((a, v) => a = a + Number(v?.itemnettotal ?? 0), 0) +
                      (selectedLedgerList?.length ? selectedLedgerList?.reduce((a, b) => a = a + Number(b?.SaleOrdLedgerAmount), 0) : 0)))?.toFixed(2) : '0.00'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='PriceHistoryModel' style={{ marginTop: '20px' }}>
        <Modal.Body style={{ width: '513px' }}>
          <div className='Details Success'>
            <div id='printablediv' className='printablediv'>
              <div className="address">
                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Ganga Super Market</h6>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</p>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Namakkal, 33 - Tamil Nadu, 637002</p>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>GSTIN / UIN: 33BZUPS6837H1ZL</p>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>FSSAI: 10020042007183</p>
              </div>
              <div className="taxinvoice">
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Tax Invoice</span>
                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.salesOrderNo}</span>
                  <span style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }} className='invoiceSpan'>{posInvoiceDetails?.billdate}</span>
                </div>
                <p style={{ fontFamily: 'system-ui', fontWeight: '700', marginTop: '12px' }} className='invoiceSpan'>{posInvoiceDetails?.paymentmode}</p>
              </div>
              <div className="Customer" style={{ marginTop: '0px' }}>
                <h6 style={{ fontFamily: 'system-ui', fontWeight: '700' }}>Customer:{posInvoiceDetails.memberName}</h6>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Dr.No.: {posInvoiceDetails?.doorNo}{" "}{posInvoiceDetails.area}</p>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{`${posInvoiceDetails?.city}, ${posInvoiceDetails?.state}, ${posInvoiceDetails?.pinCode}`}</p>
                <p style={{ marginBottom: '13px', lineHeight: 0, fontFamily: 'system-ui', fontWeight: '700' }}>Mobile: {posInvoiceDetails?.mobileNo}</p>
              </div>
              <hr />
              <h5 className="address">SKU - HSNC - Item Description</h5>
              <table className="itemtable"  >
                <thead>
                  <tr >
                    <th className="heading">Item</th>
                    <th className="heading">Qty</th>
                    <th className="heading">MRP</th>
                    <th className="heading">Price</th>
                    <th className="heading">Disc</th>
                    <th className="heading">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {convertArrayToMap(posInvoiceDetails?.posbilldetailsprint)?.size > 0 ?
                    Array.from(convertArrayToMap(posInvoiceDetails?.posbilldetailsprint))?.map(([key, value], ind) => {
                      return (
                        <>
                          <div style={{ color: 'black', padding: '10px 0px 0px 16px', fontWeight: '700', fontSize: '15px' }}>
                            {`${ind + 1}) CGST ${key ?? 0}% & SGST ${key ?? 0}%`}
                          </div>
                          {value?.map((val, index) => {
                            return val?.qty > 0 && (
                              <tr key={index}>
                                <td style={{ padding: '0px 15px' }}>
                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.hsncode}</div>
                                  <div style={{ fontFamily: 'system-ui', fontWeight: '700' }}>{val?.itemid === 0 ? "Free" : val.itemname}</div>
                                </td>
                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.qty}</td>
                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.mrp}</td>
                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.sellingPrice}</td>
                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.discountvalue}</td>
                                <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '700' }}>{val.amount}</td>
                              </tr>
                            )
                          })}
                        </>
                      )
                    }) : null}
                </tbody>
              </table>
              <hr style={{ margin: 0 }} />
              <table className="amounttable">
                <tr>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Qty: {posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.qty!, 0)}</td>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total Items: {posInvoiceDetails?.posbilldetailsprint?.length}</td>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0)?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Delivery Charges</td>
                  <td></td>
                  <td></td>
                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.deliverycharge}</td>
                </tr>
                <tr>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Round off:</td>
                  <td></td>
                  <td></td>
                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcRoundOff()}</td>
                </tr>
                <tr>
                  <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Redeem Amount</td>
                  <td> </td>
                  <td></td>
                  <td style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{posInvoiceDetails?.redeemPointsamt}</td>
                </tr>
                <tr>
                  <td style={{ padding: 0 }} className="tablesummeryvalue">Amount Paid</td>
                  <td> </td>
                  <td></td>
                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>{calcTotalAmt()?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td style={{ padding: 0, }} className="tablesummeryvalue">You Saved Today</td>
                  <td> </td>
                  <td></td>
                  <td className="tablesummeryvalue" style={{ padding: 0, display: 'flex', justifyContent: 'end' }}>
                    {(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.discountvalue!, 0 + Number(posInvoiceDetails?.offerdiscount)))?.toFixed(2)}
                  </td>
                </tr>
              </table>
              <table className="itemtable"  >
                <thead>
                  <tr>
                    <th className="heading">S.No</th>
                    <th className="heading">Taxable <br /> amount</th>
                    <th className="heading">CGST</th>
                    <th className="heading">SGST</th>
                    <th className="heading">IGST</th>
                    <th className="heading">CESS</th>
                    <th className="heading">Total <br /> amount</th>
                  </tr>
                </thead>
                <tbody>
                  {posInvoiceDetails?.posbilltaxdetailsprint?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{key + 1}</td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount - val?.taxvalue)?.toFixed(2)}</td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.cgstvalue}` + `(${val?.cgstper}%)`}</td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{`${val?.sgstvalue}` + `(${val?.sgstper}%)`}</td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}></td>
                        <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint[key]?.amount)?.toFixed(2)}</td>
                      </tr>
                    )
                  })
                  }
                  <tr style={{ margin: '1rem 0', color: 'inherit', border: 0, borderTop: '1px solid rgb(11,56,93, 0.25)' }}></tr>
                  <tr>
                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>Total</td>
                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + (v?.amount! - v.taxvalue!), 0))?.toFixed(2)}</td>
                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.cgstvalue!, 0)}</td>
                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{posInvoiceDetails?.posbilltaxdetailsprint?.reduce((a, v) => a = a + v?.sgstvalue!, 0)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ padding: 0, fontFamily: 'system-ui', fontWeight: '800' }}>{(posInvoiceDetails?.posbilldetailsprint?.reduce((a, v) => a = a + v.amount!, 0))?.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="bottom">
                <h4>Thank You</h4>
                <p>Customer Care: 9043426746</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSuccessModal(false)}>
            Ok
          </Button>
          <Button variant="secondary" onClick={() => setSuccessModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

});

export default InvoiceDetail;
