import { useEffect, useRef, useState } from "react";
import { Spinner, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { Autocomplete, TextField } from "@mui/material";
import useStores from "../../hooks";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import moment from "moment";

class FieldsProps {
    sdate?: string = getCurrentMonthFirstDate()?.firstDate;
    edate?: string = findWeekDay(getCurrentMonthFirstDate()?.firstDate)?.toISOString()?.substr(0, 10);
    empid?: number = 0;
}

interface ExcelSheetData {
    S_No?: string,
    Employee_Code?: string,
    Employee_Name?: string,
    DOB?: string,
    DOJ?: string,
    '1'?: string;
    '2'?: string;
    '3'?: string;
    '4'?: string;
    '5'?: string;
    '6'?: string;
    '7'?: string;
    shift?: string,
    CL?: string,
    EL?: string,
    LOP?: string,
    PL?: string,
    PR?: string,
    SL?: string,
    TL?: string,
    WO?: string,
    Total_Days?: string,
}

function findWeekDay(currenDate) {
    const currentDate = new Date(currenDate);
    const weekafterDay = new Date(currentDate);
    weekafterDay.setDate(currentDate.getDate() + 6);
    return weekafterDay;
}

const WeeklyAttendanceReport = () => {


    const { hrReporsStore, employeeStore } = useStores()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSpinner, setSpinner] = useState(false);

    function handleWeekDays(event) {
        const { name, value } = event?.target;
        const sdate = new Date(value)
        setLocalFields({ ...localFields, [name]: sdate?.toISOString()?.substr(0, 10), edate: findWeekDay(value)?.toISOString()?.substr(0, 10) })
    }

    const [localFields, setLocalFields] = useState<FieldsProps>(new FieldsProps())
    const [attendanceReport, setAttendanceReport] = useState<any[]>([])


    const objectRef = useRef<any>({});

    function createExportExcelObj(attendanceReport: any[]) {
        if (attendanceReport?.length >= 1) {
            let excelArr: ExcelSheetData[] = attendanceReport?.map((employee) => {
                const { empid, employeeName, shift, DoB, doj, CL, EL, LOP, PL, PR, SL, TL, WO, totaldays, } = employee;
                const excelObj: ExcelSheetData = {
                    Employee_Code: empid,
                    Employee_Name: employeeName,
                    shift: shift,
                    DOB: DoB,
                    DOJ: doj,
                    '1': employee['1'] ?? 'N/A',
                    '2': employee['2'] ?? 'N/A',
                    '3': employee['3'] ?? 'N/A',
                    '4': employee['4'] ?? 'N/A',
                    '5': employee['5'] ?? 'N/A',
                    '6': employee['6'] ?? 'N/A',
                    '7': employee['7'] ?? 'N/A',
                    CL: CL ?? 0,
                    EL: EL ?? 0,
                    LOP: LOP ?? 0,
                    PL: PL ?? 0,
                    PR: PR ?? 0,
                    SL: SL ?? 0,
                    TL: TL ?? 0,
                    WO: WO ?? 0,
                    Total_Days: totaldays ?? 0
                };
                return excelObj;
            })
            exportToExcel(excelArr)
            console.log(excelArr, 'excelArr>>', attendanceReport)
        } else {
            alert(` No Data found `);
        }
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Weekly Attendance Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Weekly Attendance Report ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function handleGenerate(event) {
        let error: any = {}
        if (localFields?.sdate && localFields?.edate) {
            setSpinner(true)
            const weeklyAttendance = await hrReporsStore?.getReportWeeklyAttendence(localFields?.sdate, localFields?.edate)
            const weeklyBalanceElCl = await hrReporsStore?.getReportWeeklyBalance(localFields?.sdate, localFields?.edate)

            let updateArray: any = []

            updateArray = weeklyAttendance;
            updateArray?.forEach((each) => {
                weeklyBalanceElCl?.forEach((obj) => {
                    if (each?.empid === obj?.empid) {
                        each['CL'] = obj?.CL;
                        each['EL'] = obj?.EL;
                        each['LOP'] = obj?.LOP;
                        each['PL'] = obj?.PL;
                        each['PR'] = obj?.PR;
                        each['SL'] = obj?.SL;
                        each['TL'] = obj?.TL;
                        each['WO'] = obj?.WO;
                    }
                    return each
                })
            })

            setAttendanceReport([...updateArray])

            console.log(weeklyAttendance, weeklyBalanceElCl, updateArray, 'response')
            setSpinner(false)
        } else if (event) {
            if (!localFields?.sdate) {
                error.sdate = 'Select From Date'
            }
            if (!localFields?.edate) {
                error.edate = 'Select year'
            }
        }
        setErrors({ ...error })
    }

    async function initialApiCalls() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        setLoading(false)
        handleGenerate(null)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCalls()
            isCurrentPage.current = false
        }
    }, [])

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField' >
                            <div className='hrBox'>
                                <h3>Weekly Attendance Report</h3>
                            </div>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>From Date</label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="sdate"
                                                value={localFields?.sdate}
                                                onChange={handleWeekDays}
                                            ></input>
                                            {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>To Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="edate"
                                                disabled
                                                value={localFields?.edate}
                                            // onChange={(event) => setLocalFields({ ...localFields, edate: event.target.value })}
                                            ></input>
                                            {errors.edate && <p style={{ color: "red" }}>{errors.edate}</p>}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>
                                                Employee<span>*</span>
                                            </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employeeMaster}
                                                getOptionLabel={(option: any) => option.Employee_Name}
                                                onChange={(event, val) => setLocalFields({ ...localFields, empid: val?.EmployeeId })}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Name..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="" style={{ marginTop: '25px' }}>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                                                View
                                            </button>
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { createExportExcelObj(attendanceReport) }}>
                                                Excel
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Employee_Code</th>
                                            <th scope="col">Employee_Name</th>
                                            <th scope="col" className=""><div>D.O.B</div><div>D.O.J</div></th>
                                            <th scope="col" className=""><div>GR</div><div>Sft</div></th>
                                            {[1, 2, 3, 4, 5, 6, 7]?.map((each) => { return (<th scope="col" className=""><div>{each}</div><div>{'S'}</div></th>) })}
                                            <th scope="col">CL</th>
                                            <th scope="col">EL</th>
                                            <th scope="col">LOP</th>
                                            <th scope="col">PL</th>
                                            <th scope="col">PR</th>
                                            <th scope="col">SL</th>
                                            <th scope="col">TL</th>
                                            <th scope="col">WO</th>
                                            <th scope="col">Total_Days</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceReport?.map((each, index) => {
                                            return (!localFields?.empid || each?.empid === localFields?.empid) && (
                                                <tr key={index}>
                                                    <td>{each?.empid}</td>
                                                    <td>{each?.empid}</td>
                                                    <td>{each?.Employee_Name}</td>
                                                    <td className=""><div>{moment(each?.DoB)?.format('DD-MMM-YYYY')}</div><div>{moment(each?.dob)?.format('DD-MMM-YYYY')}</div></td>
                                                    <td className=""><div>I</div><div>II</div></td>
                                                    {Object?.keys(each)?.map((key, index) => {
                                                        return !isNaN(Number(key)) && (
                                                            <td>
                                                                <div>{each[`${key}`] ?? 'N/A'}</div>
                                                                <div>{each?.shift}</div>
                                                            </td>
                                                        )
                                                    })}
                                                    <td>{each?.CL ?? 0}</td>
                                                    <td>{each?.EL ?? 0}</td>
                                                    <td>{each?.LOP ?? 0}</td>
                                                    <td>{each?.PL ?? 0}</td>
                                                    <td>{each?.PR ?? 0}</td>
                                                    <td>{each?.SL ?? 0}</td>
                                                    <td>{each?.TL ?? 0}</td>
                                                    <td>{each?.WO ?? 0}</td>
                                                    <td>{each?.totaldays ?? 0}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
            }

        </div>
    )
}

export default WeeklyAttendanceReport;