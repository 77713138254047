function purchaseApprovalValidation(purchaseApproval){
    let errors :any={};

    if(!purchaseApproval.approval_qty){
        errors.approval_qty = "Enter Approval Qty ";
    }
    if(!purchaseApproval.supplierid){
        errors.supplierid = "Select supplier";
    }   
    return errors;
};
export default purchaseApprovalValidation
