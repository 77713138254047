import { IMemberLogin } from "../model";

function memberValidation(memberLogin: IMemberLogin) {

    let errors: any = {};

    const alpha = /[a-zA-Z]*$/;
    const numeric = /^[0-9]*$/;
    const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const mobileNo = /^[6-9]\d{9}$/
    const aadharNNumber = /^[0-9]{12}$/;


    debugger
    if (!memberLogin.memberName) {
        errors.memberName = "Enter Your Member Name /Business Name";
    }
    if (!memberLogin.mobileNo) {
        errors.mobileNo = "Enter Your Mobile No";
    }
    if (memberLogin.mobileNo && !numeric.test(memberLogin.mobileNo!)) {
        errors.mobileNo = "Enter Valid Mobile No";
    }
    if (memberLogin.aadharNo && !aadharNNumber.test(memberLogin.aadharNo)) {
        errors.aadharNo = "Enter 12 digit aadhar No"
    }
    if (!memberLogin.email) {
        errors.email = "Enter Your Email ID";
    }

    if (!memberLogin.gender) {
        errors.gender = "Select Gender";
    }

    if (!memberLogin.customerType) {
        errors.customerType = "Select Type";
    }

    if (!memberLogin.customerCategory) {
        errors.customerCategory = "Select Your Category";
    }

    if (!memberLogin.pinCode) {
        errors.pinCode = "Enter PIN Code ";
    }

    if (!memberLogin.city) {
        errors.city = "Select City ";
    }

    if (!memberLogin.dob) {
        errors.dob = "Enter Date Of Birth ";
    }

    return errors;
};

export default memberValidation;