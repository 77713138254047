import { IOutletReturnForm } from "../model";

export function saveValidation(damageTransfer: IOutletReturnForm) {
    let error: any = {}
    if (damageTransfer?.damageoutlettransferdet?.some((each) => each?.doT_Type == 'Return')) {
        if (!damageTransfer?.doM_SupplierId) {
            error.doM_SupplierId = 'Select Supplier'
        }
        if (!damageTransfer?.doM_DebitNoteNo) {
            error.doM_DebitNoteNo = 'Generate Debit Note'
        }
    }
    if(!damageTransfer?.damageoutlettransferdet?.length) {
        error.damageoutlettransferdet = "Select items to submit"
    }
    if (!damageTransfer?.damageoutlettransferdet?.every((each) => (each?.isChecked && each?.doT_Rate))) {
        error.rate = 'Checked Rate field cannot be empty';
    }
    if (!damageTransfer?.damageoutlettransferdet?.every((each) => (each?.isChecked && each?.doT_Type))) {
        error.type = 'Checked Type field must be selected'
    }
    return error;
}