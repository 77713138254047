function PaymentVoucherSaveValidation(paymentVoucherSave) {
  let errors: any = {};
  if (!paymentVoucherSave.paymentDate) {
    errors.paymentDate = "Enter Date";
  }
  if (!paymentVoucherSave.referenceNo) {
    errors.referenceNo = "Enter Reference No";
  }
  if (!paymentVoucherSave.paymentDebit?.length) {
    errors.paymentDebit = "Debit List Cannot be empty";
  }
  if (!paymentVoucherSave.paymentCredit?.length) {
    errors.paymentCredit = "Credit List Cannot be empty"
  }
  if (paymentVoucherSave?.paymentCredit?.length && paymentVoucherSave?.paymentDebit?.length) {
    let debitSum = paymentVoucherSave?.paymentDebit?.reduce((a, v) => a + Number(v?.debitAmount), 0)
    let creditSum = paymentVoucherSave?.paymentCredit?.reduce((a, v) => a + Number(v?.creditAmount), 0)

    if (debitSum != creditSum) {
      errors.totalSum = "The total Cr Amount and total Dr Amount must be equal."
    }
  }
  return errors;
};

export default PaymentVoucherSaveValidation;

export function DebitObjValidation(debitObj) {
  let errors: any = {};
  if (debitObj?.debitAccountName == undefined || !debitObj?.debitAccountName) {
    errors.debitAccountName = "Select By Account";
  }
  if (debitObj?.debitAmount == undefined || !debitObj?.debitAmount) {
    errors.debitAmount = "Enter Debit Amount";
  }
  // if (debitObj?.debitNarration == undefined || !debitObj?.debitNarration) {
  //   errors.debitNarration = "Enter Narrations"
  // }
  return errors;
};

export function CreditObjValidation(creditObj) {
  let errors: any = {};
  // if (creditObj?.chequeNo == undefined || !creditObj?.chequeNo) {
  //   errors.creditChequeNo = "Enter Cheque No"
  // }
  if (creditObj?.creditAccountName == undefined || !creditObj?.creditAccountName) {
    errors.creditAccountName = "Select To Account";
  }
  if (creditObj?.creditAmount == undefined || !creditObj?.creditAmount) {
    errors.creditAmount = "Enter Credit Amount";
  }
  if (creditObj?.creditDate == undefined || !creditObj?.creditDate) {
    errors.creditDate = "Enter Credit Date"
  }
  // if (creditObj?.creditNarration == undefined || !creditObj?.creditNarration) {
  //   errors.creditNarration = "Enter Narrations"
  // }
  return errors;
};