import { IIJobMaster } from "../model";

export function saveValidation(jobMaster: IIJobMaster) {
    let error: any = {}

    if (!jobMaster?.jobcode) {
        error.jobcode = 'Enter Job Code'
    }
    if (!jobMaster?.jobtitle) {
        error.jobtitle = 'Enter Job Title'
    }
    if (!jobMaster?.jobDescription) {
        error.jobDescription = 'Enter Job Description'
    }
    return error;
}