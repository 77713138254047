import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../EcomAPI/index';
import { IProductMember } from '../../Products/model';


class CartsDetailsStore {
   
   @observable cartDetails=new Map();
   isLoading= false;
     
   getCartDetails=async (cartProduct:IProductMember)=> {
    
        try {
          const carts= await apiAgent.carts.getCartDetails(cartProduct);
          
            runInAction(() => {
                this.cartDetails = carts;
            });
            return this.cartDetails;
            ;
        }
        catch (error) {
            console.log(error);
        }
    }

    getCartDetailsCookies=async (productIds)=> {
        try {
          const carts= await apiAgent.carts.getCartDetailsCookies(productIds);
           runInAction(() => {
                this.cartDetails = carts;
           });
            return this.cartDetails;
            
        }
        catch (error) {
            console.log(error);
        }
    }
    
}
export default CartsDetailsStore;