import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { ICourierEntry } from '../model';

class CourierEntryStore {
    @observable despatchNoDetails= new Map();   
    @observable courierNameDetails= new Map();   
    @observable loadDespatchDetails= new Map(); 
    @observable getcourierEntryList= new Map();  
    @observable getDamageEntryWarehouseDetail= new Map(); 
    entryNo = new Map();     

    saveCourierEntry=async (courierEntry:ICourierEntry)=> {
        
        try {
          const status= await apiAgent.courierEntry.saveCourierEntry(courierEntry);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }  
    
    loadDespatchDetail= async (despatchNo) => {
        
        try {
            const loadDespatchDetails = await apiAgent.courierEntry.loadDespatchDetail(despatchNo);
            
            
            runInAction(() => {
                
                this.loadDespatchDetails = loadDespatchDetails;
                
            })
           return this.loadDespatchDetails;
        }
        catch (error) {
            console.log(error);
        }
    } 
    getCourierEntry=async ()=> {
        
        try {
          const getcourierEntry= await apiAgent.courierEntry.getCourierEntry();
            
            runInAction(() => {
                
                this.getcourierEntryList = getcourierEntry;
            });
            return this.getcourierEntryList;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDespatchNo  = async ()=> {
        try {
          const despatchNo= await apiAgent.courierEntry.loadDespatchNo();
            
            runInAction(() => {
                this.despatchNoDetails = despatchNo;
            });
            return this.despatchNoDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadCourierName  = async ()=> {
        try {
          const courierName= await apiAgent.courierEntry.loadCourierName();
            
            runInAction(() => {
                this.courierNameDetails = courierName;
            });
            return this.courierNameDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    
    deleteCourierEntry = async (courierEntryid) => {
        try {      
        const status=  await apiAgent.courierEntry.deleteCourierEntry(courierEntryid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getCourierEntrySearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.courierEntry.getCourierEntrySearch(SearchOptions);
            this.getcourierEntryList = Data;
            
            runInAction(() => {
                this.getcourierEntryList = Data;
                
            })
            return this.getcourierEntryList;        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    
}

export default CourierEntryStore;