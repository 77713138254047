import { action, set, makeObservable, observable, runInAction } from 'mobx';
import  { IAssignRedemption } from '../model/index';
import apiAgent from '../../../APIAgent';
import { stat } from 'fs';

class AssignRedemptionStore {
  
    @observable assignRedemptionList= new Map();
    @observable assignRedemptionDetails=new Map();

    saveAssignRedemption = async (assignRedemption: IAssignRedemption) => {
        try {
          const status=  await apiAgent.assignRedemption.saveAssignRedemption(assignRedemption);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getAssignRedemption = async () => {
        try {
          const data=  await apiAgent.assignRedemption.getAssignRedemption();
          
          runInAction(()=>{
            
            this.assignRedemptionList = data;
          })
          
          return this.assignRedemptionList;
        }
        catch (error) {
            console.log(error);
        }
    } 
    getAssignRedemptiondetails   = async (id) => {
      try {
        const data=  await apiAgent.assignRedemption.getAssignRedemptiondetails(id);
        
        runInAction(()=>{
          
          this.assignRedemptionDetails = data;
        })
        
        return this.assignRedemptionDetails;
      }
      catch (error) {
          console.log(error);
      }
  }  
    getAssignRedemptionSearch   = async (SearchOptions)=> {
      try {
        const manageLoyaltyData=await apiAgent.assignRedemption.getAssignRedemptionSearch(SearchOptions);
          this.assignRedemptionList = manageLoyaltyData;
          
          runInAction(() => {
              this.assignRedemptionList = manageLoyaltyData;
              
          })
          return this.assignRedemptionList;     
       
      }
      catch (error) {
          console.log(error);
      }
  }
  deleteAssignRedemption= async (assignRedemId) => {
    try {      
    const status=  await apiAgent.assignRedemption.deleteAssignRedemption(assignRedemId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}
    
}
export default AssignRedemptionStore;