import React, { useState, useEffect, } from 'react';
import { observer } from "mobx-react";

const InvoicePrint = observer((): JSX.Element => {

	useEffect(()=>{
	//	window.print();
	})
	return (
		<div className='container'>
			<div className="address">
				<h4>Ganga Super Market</h4>
				<p>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</p>
				<p>Namakkal</p>
				<p>33 - Tamil Nadu</p>
				<p>Pincode: 637002</p>
				<p>GSTIN / UIN: 33BZUPS6837H1ZL</p>
				<p>FSSAI: 10020042007183</p>
			</div>

			<div className="taxinvoice">
				<h4>Tax Invoice</h4>
				<p>Invoice No:<span>POS-23/24-06016</span></p>
				<p>Invoice Date:<span>26/09/2023 10:42:26 am</span></p>
				<p>Payment Mode: <span>Cash</span></p>
			</div>

			<div className="Customer">
				<h4>Customer:Muthusamy</h4>
				<p>Upstairs of Ganga Supermarket Thuraiyur Road, </p>
				<p>City: Namakkal</p>
				<p>State: 33 - Tamil Nadu</p>
				<p>Pincode: 637002</p>
				<p>Mobile: 9486068348</p>
			</div>
			<br />
			<hr />
			<h4 className="address">SKU - HSNC - Item Description</h4>
			<table className="itemtable"  >
				<tr >
					<th className="heading">TAX</th>
					<th className="heading">Price</th>
					<th className="heading">Disc</th>
					<th className="heading">Qty</th>
					<th className="heading">Total</th>
				</tr>

				<tr>
					<td>SNS-00111 - 13019099 - LG Asafoetida Powder-50Gm</td>
				</tr>
				<tr>
					<td className="taxvalue">5%</td>
					<td> 85.00</td>
					<td>17.00</td>
					<td>1.00000</td>
					<td>68.00</td>
				</tr>
				<tr>
					<td>PLS-00069 - 7132 - Chickpeas - White (Sundal)-500gm </td>
				</tr>
				<tr>
					<td className="taxvalue">5%</td>
					<td> 90.00</td>
					<td>14.40</td>
					<td>1.00000</td>
					<td>75.60</td>
				</tr>
				<tr>
					<td>SNS-00148 - 3301 - Coriander Seeds-500gm</td>
				</tr>
				<tr>
					<td className="taxvalue">5%</td>
					<td>75.00</td>
					<td>15.00</td>
					<td>2.00000</td>
					<td>120.00</td>
				</tr>
			</table>
			<hr />
			<h4 className="address">Tax Summary</h4>

			<table className="taxtable">
				<tr >
					<th >GST %</th>
					<th>GOODS</th>
					<th>CGST</th>
					<th>SGST</th>
					<th>TAX</th>
				</tr>
				<tr>
					<td>5%</td>
					<td> 251.05</td>
					<td>6.28</td>
					<td>6.28</td>
					<td>12.56</td>
				</tr>
			</table>

			<br />
			<table className="amounttable">
				<tr>
					<td>Delivery Charges</td>
					<td></td>
					<td></td>
					<td className="tablesummeryvalue">0.00</td>
				</tr>
				<tr>
					<td>Total Qty:4</td>
					<td>Total</td>
					<td></td>
					<td className="tablesummeryvalue">263.61</td>
				</tr>
				<tr>
					<td>Total Items: 3</td>
					<td>Round off:</td>
					<td></td>
					<td className="tablesummeryvalue">0.39</td>
				</tr>
				<tr>
					<td className="tablesummeryvalue">You Saved Today</td>
					<td> </td>
					<td></td>
					<td className="tablesummeryvalue">61.40</td>
				</tr>
				<tr>
					<td className="tablesummeryvalue">Amount</td>
					<td> </td>
					<td></td>
					<td className="tablesummeryvalue">264</td>
				</tr>
			</table>
			<hr />
			<div className="bottom">
				<h4>Thank You</h4>
				<p>Customer Care: 9043426746</p>
			</div>
			<div className="bottomDate">
				<p>28-09-2023</p>
			</div>
		</div>

	)
});


export default InvoicePrint