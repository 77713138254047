import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import moment from 'moment';
import ProgressBar from '../../common/shared/progressbar';
import { Link, useNavigate } from 'react-router-dom';
import { IPackingInwardSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const PackingInwardDetail = observer((): JSX.Element => {
  const [packingInwardSearchOptions, setPackingInwardSearchOptions] = useState<IPackingInwardSearchoptions>({})
  const { packingInwardStore } = useStores();
  const { getPackingInward, getPackingInwards, packingInwardDetails, getPackingInwardDetails } = packingInwardStore;
  const [isLoading, setLoading] = useState(true);
  const [isPackingConversionShow, setPackingConversionModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deletePackingInward = async (packingInwardId) => {
    setLoading(true);
    const status = await packingInwardStore.deletePackingInwardMaster(packingInwardId);

    if (status === 'Success') {
      PackingInwardDetail();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deletePackingDetail = async (packingInwardDetailId) => {
    setPackingConversionModal(false);
    setLoading(true);
    const status = await packingInwardStore.deletePackingInwardDetail(packingInwardDetailId);

    if (status === 'Success') {
      PackingInwardDetail();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    PackingInwardDetail();
  }

  async function PackingInwardDetail() {
    const data = await getPackingInward();
    if (data !== undefined) {

      setLoading(false);
    }
  }
  const handleCreate = () => {
    navigate('/Admin/PackingInward');
  }
  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      PackingInwardDetail();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  async function handlePacking(packingInwardId, e) {
    e.preventDefault();
    await getPackingInwardDetails(packingInwardId);
    setPackingConversionModal(true);

  }
  const handleClose = () => {
    setPackingConversionModal(false);
  }

  return (
    <>
      {
        isLoading ?
          <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getPackingInwards}
                searchTitles={[{ key: "teamName", value: "Team Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Team  </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : getPackingInwards).slice().sort((a, b) => b.packingInwardId - a.packingInwardId).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.packingInwardId}</td>
                            <td>{moment(val.packingInwardDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.teamName}</td>
                            <td><button onClick={(e) => handlePacking(val.packingInwardId, e)}>Detail</button></td>
                            <td><button className='Create' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button> </td>
                            <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deletePackingInward(val.packingInwardId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
              {
                isPackingConversionShow ?
                  <Modal show={isPackingConversionShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Inward Item Name</th>
                              <th scope="col"> UOM</th>
                              <th scope="col">Inward Qty</th>
                              {/* <th scope='col'>Delete </th> */}
                              {/* <th scope="col"></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              packingInwardDetails?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.packingItemName}</td>
                                    <td>{val.packingUOM}</td>
                                    <td>{val.packingQty}</td>
                                    {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deletePackingDetail(val.packingInwardDetailId)} /></button></td> */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null}
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>

      }
    </>
  );

});

export default PackingInwardDetail;
