export class IManualAttendance {
    attnDate?: string = ""
    empid?: number = 0
    levtype?: string = ""
    inDate?: string = new Date().toISOString()?.substr(0, 10)
    inTime?: string = "09:00"
    outdate?: string = new Date().toISOString()?.substr(0, 10)
    outTme?: string = "17:00"
    workingtime?: string = "0"
    attntype?: string = ""
    workingminuts?: number = 0
    // dummy
    otApplicable?: boolean = false
    shiftHrs?: string = ""
    oThours?: number = 0
}

export const handleAddDay = (currentDate: Date) => {
    // Create a new Date object with one day added to the current date
    currentDate = new Date(currentDate);
    const nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);
    // Update the state with the new date
    return nextDay?.toISOString()?.substr(0, 10);
};