import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Accordion } from "react-bootstrap";
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import moment from 'moment';

const EmployeePromotionList = () => {

    const { employeePromotionStore, payrollStore, departmentStore, designationStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [onOpenDetail, setOnOpenDetail] = useState({ Emp_id: 0, index: 0, bool: false })

    const [categoryList, setCategoryList] = useState<any[]>([])
    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [employeePromotionList, setEmployeePromotionList] = useState<any>()

    console.log(employeePromotionList, 'employeePromotionList>>')
    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/EmployeePromotion')
    }

    const editBrandDetials = (brandId) => {

    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function additionalApis() {
        const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        setCategoryList([...categoryMasterDetails])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
    }

    function updatePromoList(master, promoDetails, salDetails) {
        console.log(master, promoDetails, salDetails, 'Detailss')
        let employeeMasterDetail: any[] = Object.assign([], master);

        promoDetails?.forEach((promoDet) => {
            const { EPD_EPMID, ...familyInfo } = promoDet;
            employeeMasterDetail?.forEach((employee) => {
                const { EPM_ID, ...employeeInfo } = employee;
                if (EPM_ID === EPD_EPMID) {
                    if (!employee?.emppromotiondet?.length) {
                        employee.emppromotiondet = []
                    }
                    employee.emppromotiondet.push(promoDet)
                }
            })
        })

        salDetails?.forEach((promoDet) => {
            const { EPS_EMPID, ...familyInfo } = promoDet;
            employeeMasterDetail?.forEach((employee) => {
                const { EPM_ID, ...employeeInfo } = employee;
                if (EPM_ID === EPS_EMPID) {
                    if (!employee?.employeepromosaldet?.length) {
                        employee.employeepromosaldet = []
                    }
                    employee.employeepromosaldet.push(promoDet)
                }
            })
        })

        return employeeMasterDetail;
    }

    async function fetchPreLoadngData() {
        // await brandStore.getBrand();
        const userRights = await userCreationStore?.getUserRight('Employee_promotion')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const employeePromotion = await employeePromotionStore?.loadEmpPromotion()
        const employeePromotionDet = await employeePromotionStore?.loadEmployeePromotionDet()
        const employeePromoSalDet = await employeePromotionStore?.loadEmployeePromoSalDet()
        console.log(employeePromotion, employeePromotionDet, employeePromoSalDet, 'employeePromotion>>')
        const employeePromoList = updatePromoList(employeePromotion, employeePromotionDet, employeePromoSalDet)
        if (employeePromoList?.length) {
            setEmployeePromotionList([...employeePromoList])
        }
        setLoading(false)
        additionalApis()
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (employeePromotionList && employeePromotionList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(employeePromotionList)
        }
    }, [employeePromotionList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(employeePromotionList?.slice()?.sort((a, b) => b.vchr_no - a.vchr_no), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={employeePromotionList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{employeePromotionList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Record Code</th>
                                            <th scope="col">Employee ID.</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Photo</th>
                                            <th scope="col">Detail</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{each?.EPM_ID}</td>
                                                        <td>{each?.EPM_TicketNo} </td>
                                                        <td>{each?.Employee_Name}</td>
                                                        <td><button disabled={!userRights?.view} className='' onClick={() => { }}>Photo</button></td>
                                                        <td><button disabled={!userRights?.view} className='' onClick={() => setOnOpenDetail({ bool: true, index: index, Emp_id: each?.EPM_ID })}>Details</button></td>
                                                        <td><button disabled={!userRights?.add} className='Create' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit' onClick={() => { }}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete' onClick={() => { }}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                    </tr>
                                                )
                                            })}

                                    </tbody>
                                </table>
                            </div>
                            <Modal show={onOpenDetail?.bool} onHide={() => { setOnOpenDetail({ bool: false, index: 0, Emp_id: 0 }) }} className='PriceHistoryModel flexImp'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Promotion Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="inputBoxLists accordionLists">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Promotion Details</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='tableBox'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Salary Type</th>
                                                                    <th scope="col">Category </th>
                                                                    <th scope="col">Designation </th>
                                                                    <th scope="col">Department </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {employeePromotionList[onOpenDetail?.index]?.emppromotiondet?.map((each, index) => {
                                                                    return each?.EPD_ID == onOpenDetail?.Emp_id && (
                                                                        <tr key={index}>
                                                                            <td>{moment(each?.EPD_Date)?.format('DD-MMM-YYYY')}</td>
                                                                            <td className='capitalize'>{each?.EPD_SalType}</td>
                                                                            <td>{categoryList?.find((cate) => cate?.Categoryid === each?.EPD_CatID)?.Emp_Category_Name}</td>
                                                                            <td>{designationList?.find((cate) => cate?.designationId === each?.EPD_DegnID)?.designationName}</td>
                                                                            <td>{departmentList?.find((cate) => cate?.departmentId === each?.EPD_DeptID)?.department}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Salary Details</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='tableBox'>
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Gross Salary</th>
                                                                    <th scope="col">Earn Type</th>
                                                                    <th scope="col">Amount </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {employeePromotionList[onOpenDetail?.index]?.employeepromosaldet?.map((each, index) => {
                                                                    return each?.EPS_EMPID === onOpenDetail?.Emp_id && (
                                                                        <tr key={index}>
                                                                            <td>{each?.EPS_GrossSal}</td>
                                                                            <td className='capitalize'>{each?.EPS_EarnType}</td>
                                                                            <td className='capitalize'>{each?.EPS_Amount}</td>
                                                                        </tr>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default EmployeePromotionList;