import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import teamMasterDetail, { ITeamMaster } from '../model/index';

class TeamMasterStore {
    @observable teamMasterDetls = teamMasterDetail;
    @observable teamMasterList= new Map();
    isLoading=true;
    teamMasterLists= new Map();
    teamMasterDetail = new Map();
    isInwardLoding=true;
    constructor(){
        makeObservable(this);
    }
     saveTeamMaster= async (teamMaster: ITeamMaster) => {
        try {
          const status=  await apiAgent.teamMaster.saveTeamMaster(teamMaster);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }   
    getTeamMaster=async ()=> {
        
        try {
          const getTeamMaster= await apiAgent.teamMaster.getTeamMaster();
            
            runInAction(() => {
                
                this.teamMasterList = getTeamMaster;
            });
            return this.teamMasterList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getTeamMasterDetails=async (teamMasterId)=> {
        
        try {
          const getTeamMaster= await apiAgent.teamMaster.getTeamMasterDetails(teamMasterId);
            
            runInAction(() => {
                
                this.teamMasterDetail = getTeamMaster;
            });
            return this.teamMasterDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    getTeamMasterSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.teamMaster.getTeamMasterSearch(SearchOptions);
            this.teamMasterList = Data;
            
            runInAction(() => {
                this.teamMasterList = Data;
                
            })
            return this.teamMasterList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteTeamMasterDetail = async (itemConversionDetailId) => {
        try {      
        const status=  await apiAgent.teamMaster.deleteTeamMasterDetail(itemConversionDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteTeamMaster = async (teamMasterId) => {
        try {      
        const status=  await apiAgent.teamMaster.deleteTeamMaster(teamMasterId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    @action setTeamMaster = (state:ITeamMaster) =>{
        this.teamMasterList.set(0,state);
    }
    @action setTeamMasterList = (state: ITeamMaster[]) =>{
        set(state,this.teamMasterList);
        return this.teamMasterList;
    }
}
export default TeamMasterStore;