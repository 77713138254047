import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap";

const tableHeadings = [
    "Location", "Stores", "ProductGroup", "ProductCode",
    "ProductDescription","RackNo", "BinNo", "OpeningQty",
    "OpeningValue", "UOM", "ReceivedQty", "ReceivedValue",
    "IssuedQty", "IssuedValue", "ClosingQty", "ClosingValue",
    "ClosingRate", "Image", "Roundoff"
];
const StockValueSummary = () => {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})
    const [localFields, setLocalFields] = useState<any>({})

    function handleInputChange(e) {
        const { name, value } = e.target;
    }

    function fetchInitialLoad() {
         
        setLoading(false)
    }
    useEffect(() => {
        fetchInitialLoad()
    }, [])
    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '100%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Stock Value Summary</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input  type="date" style={{ width: '100%' }}
                                                    name="fromDate"
                                                    onChange={handleInputChange} value={localFields?.fromDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input  type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={localFields?.toDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className="col-sm-6"></div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                            // onClick={handleGenerateStock}
                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                            // onClick={() => createExportExcelObj(expiryRegisters)}
                                            >Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {tableHeadings?.map((heading, index) => {
                                                    return (
                                                        <th key={index}>{heading}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default StockValueSummary;