import { IInterviewProcess, IInterviewProcessDetials } from "../model";

export function addValidation(interviewProcessdetails: IInterviewProcessDetials) {
    let error: any = {}
    if (!interviewProcessdetails?.phaseno) {
        error.phaseno = 'Select Round'
    }
    if (!interviewProcessdetails?.process) {
        error.process = 'Enter Process'
    }
    if (!interviewProcessdetails?.feedback) {
        error.feedback = 'Enter Feed back'
    }
    if (!interviewProcessdetails?.conductby) {
        error.conductby = 'Select Conduct by'
    }
    if (!interviewProcessdetails?.nextPhases) {
        error.nextPhases = 'Select Next phases'
    }
    return error;
}

export function saveValidation(interviewProcess: IInterviewProcess) {
    let error: any = {}
    if (!interviewProcess?.jobcode) {
        error.jobcode = 'Select Job Code'
    }
    if (!interviewProcess?.callletterno) {
        error.callletterno = 'Select Call letter No.'
    }
    if (!interviewProcess?.interviewProcessdetails?.length) {
        error.interviewProcessdetails = 'Enter Interview Round Details'
    }
    if (!interviewProcess?.feedback) {
        error.feedbackmaster = 'Enter Feedback'
    }
    return error
}