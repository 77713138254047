import apiAgent from "../../../APIAgent"

class BarcodePriceHistoryStore{
    loadBarcodePriceHistory = async (barcodePostGet) => {
        try {
            const barcodePriceList = await apiAgent.barcodePriceHistory.getBarcodePriceHistoryList(barcodePostGet);

            return barcodePriceList
        } catch (error) {
            console.log(error)
        }
    }
}

export default BarcodePriceHistoryStore;