import { observable } from "mobx";
export interface ILedgerMaster{
    ledgerGroupId?:number;
    ledgerName?:string;
    ledgerGroup?:string;
    type?:string;
    openingBalance?:number ;
    entryDate?:string;
    userId?:string; 
    ledgerId?:number;
    percentage?:number;
   
}
export interface ILedgerMasterSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const LedgerMasterDetails=observable<ILedgerMaster>({
    ledgerGroupId:0,
    ledgerName:'',
    ledgerGroup:'',
    type:'',
    openingBalance:0,
    entryDate:'',
    userId:'',
    ledgerId:0,
    percentage:0
    
});

export default LedgerMasterDetails;