import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import OutletStockSummaryValidation from './validation';
import { currentPageDatas, sortedData } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';
import { IOutletStockAnalysis } from '../OutetStockAnalysis/model';

interface ExcelSheetData {
    Date: string,
    Item: string,
    UOM: string,
    Opening_Stock: string,
    In_Qty: number,
    Sales_Qty: number,
    Closing: number
}

const OutletStockSummary = observer((): JSX.Element => {

    const { outletStockSummaryStore, commonStore } = useStores();
    const { loadOutlet, loadOutlets, loadSubcategory,
        loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;

    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false })
    const [outletSummaryFields, setoutletSummaryFields] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis())
    const [outletStockSummaries, setOutStockSummaryies] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && outletStockSummaries?.length > 0) {
            return sortedData(outletStockSummaries, 'outwardDate')?.filter((stock) => {
                if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setoutletSummaryFields({ ...outletSummaryFields, [name]: value })
    }

    function handleSearchInput(e) {
        setSearchValue(e.target.value);
    }

    function handleQuoteSearch() {

    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;

        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true })
        }
    }

    const handleOutletValue = (name: any, event: object, val: any) => {
        if (val != null) {
            setoutletSummaryFields({ ...outletSummaryFields, [name]: val.outletid });
        }
        else {
            outletSummaryFields.outletId = 0;
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setoutletSummaryFields({ ...outletSummaryFields, [type]: value.subCategoryId });
        } else {
            setoutletSummaryFields({ ...outletSummaryFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = OutletStockSummaryValidation(outletSummaryFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                "outletId": outletSummaryFields?.outletId,
                "itemId": outletSummaryFields?.itemId,
                "subCategoryId": outletSummaryFields?.subCategoryId,
                "category": outletSummaryFields?.category,
                "fromDate": outletSummaryFields?.fromDate,
                "toDate": outletSummaryFields?.toDate,
                "warehouseId": outletSummaryFields?.outletId,
                "brand": "",
            }
            const stockSummaryDetails = await outletStockSummaryStore.getOutletStockSummary(body);
            setOutStockSummaryies(Object.assign([], stockSummaryDetails))
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Date: moment(stock?.datetime).format("DD-MMM-YYYY"),
                Item: stock?.itemname,
                UOM: stock?.unitOfMeasure,
                Opening_Stock: stock?.opening,
                In_Qty: stock?.inqty,
                Sales_Qty: stock?.outqty,
                Closing: stock?.balanceqty
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Outlet Stock Summary", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Outlet Stock Summary ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchOutletStockSummary() {
        await loadOutlet();
        await loadItemConversion();
        await loadSubcategory();
        handleGenerateStock()
        setLoading(false)
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletStockSummary()
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])


    useEffect(() => {
        if (outletStockSummaries?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(outletStockSummaries)
        }
    }, [outletStockSummaries])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(sortedData(outletStockSummaries, 'outwardDate'), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
            <div className='' style={{ width: '100%' }}>
                <div className='vertical-space-20'></div>
                <div className='outletInputField inputFormBox LgInputField'>
                    <div className='hrBox'>
                        <h3>Outlet Stock Summary</h3>
                    </div>
                    {loading ?
                        <div className='SpinnerBox'>
                            <Spinner animation="border" role="status"></Spinner>
                        </div> : <></>
                    }
                    <div className='inputBoxLists'>
                        <div className='ItemInwardInputBox'>
                            <div className="row">
                                <div className="col-sm-1">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                                        <input type="date" style={{ width: '100%' }}
                                            name="fromDate"
                                            onChange={handleInputChange} value={outletSummaryFields.fromDate}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                </div>
                                <div className="col-sm-1">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                                        <input type="date" style={{ width: "100%" }}
                                            name="toDate"
                                            onChange={handleInputChange} value={outletSummaryFields.toDate}
                                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                        ></input>
                                    </div>
                                    {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                    {loadOutlets?.length > 0 && <div className='inputBox'>
                                        <AutoComplete getOptionLabel='outletName' clsName='full-width' value={loadOutlets?.find((option) => option.outletid == outletSummaryFields?.outletId)?.outletName}
                                            options={loadOutlets} placeholder='Select Outlet Name..' disabled={!loadOutlets?.length} emitOption={(option: any) => handleOutletValue('outletId', {}, option)} />
                                    </div>}
                                    {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                                </div>
                                <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                    <div className="input-group mb-3">
                                        <input type="text"
                                            style={{ height: "47px" }}
                                            name='searchValue'
                                            value={searchValue}
                                            onChange={handleSearchInput}
                                            className="form-control" placeholder="Search Here.."
                                            aria-label="Search Outlet..."
                                            aria-describedby="search-button"
                                        ></input>
                                        <div className="input-group-append">
                                            <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                                                id="search-button">
                                                <FontAwesomeIcon icon={faSearch}
                                                    onClick={handleQuoteSearch}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-2' style={{ marginTop: '4px' }}>
                                    <div className='inputBox' style={{ marginTop: '15px' }}>
                                        <select style={{ paddingRight: "0px", height: "47px" }} className="form-selected full-width" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                            <option value="">Select Search Criteria</option>
                                            <option value="ItemName">Item Name</option>
                                            <option value="Category">CateGory </option>
                                            <option value="SubCategory">Sub Category </option>
                                        </select>
                                    </div>

                                </div>
                                <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                    {searchCriteria?.category ?
                                        <>
                                            <div className='inputBox'>
                                                <select className="form-selected full-width" onChange={handleInputChange} id="Category" name="category">
                                                    <option value="" selected>Not Select</option>
                                                    <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                    <option value="Pets">Pets</option>
                                                    <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                    <option value="Grocery">Grocery</option>
                                                </select>
                                            </div>
                                            <div className='vertical-space-10'></div>
                                        </> : searchCriteria?.itemName || searchCriteria?.subCategory ?
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={searchCriteria?.itemName ? loadItemConversions : loadSubcategories}
                                                    getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                    onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : handleValue('subCategoryId', event, val)}
                                                    renderInput={(params: any) =>
                                                        <TextField  {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                            name={searchCriteria?.itemName ? 'itemId' : 'subcategory'}
                                                        />}
                                                />
                                            </div> :
                                            <div className="">

                                            </div>
                                    }
                                </div>
                                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                    <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                </div>
                                <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                    <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(outletStockSummaries)}>Excel</button>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-5'>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletStockSummaries?.length} Records</button>
                                </div>
                            </div>
                            <div className=' col-sm-7' >
                                <Stack spacing={2}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                        </div>

                        <div className='vertical-space-20'></div>
                        <div className='tableListDetails'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th scope="col">Item</th>
                                        <th scope="col">UOM</th>
                                        <th scope="col">Opening Stock</th>
                                        <th scope='col'>In Qty</th>
                                        <th scope="col">Sales Qty</th>
                                        <th scope="col">Closing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(searchValue?.length > 0 ? filteredData : currentPageData)?.length > 0 &&
                                        (searchValue?.length > 0 ? filteredData : currentPageData)?.map((stock, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{moment(stock?.outwardDate)?.format('DD-MMM-YYYY')}</td>
                                                    <td scope="col">{stock?.itemname}</td>
                                                    <td scope="col">{stock?.unitOfMeasure}</td>
                                                    <td scope="col">{stock?.opening}</td>
                                                    <td scope='col'>{stock?.inqty}</td>
                                                    <td scope="col">{stock?.outqty}</td>
                                                    <td scope="col">{stock?.balanceqty}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default OutletStockSummary;