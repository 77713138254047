import React, { useEffect, useMemo, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import { Autocomplete, TextField } from "@mui/material";
import moment from "moment";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";

const tableHeadings = [
    "Location", "VoucherNo", "VoucherDate",
    "InvAmt", "CustomerCode", "CustomerName", "ItemCode",
    "ItemName", "Qty", "FreeQty", "BarCode",
    "BatchNo", "DeliveryCharges", "Price", "DiscountVal",
    "DisVal", "MRP", "Cust Group Disc%", "Taxable-Value",
    "CGST %", "CGST", "SGST %", "SGST", "TOTAL", "StockValue",
    "Margin", "MarginPercentage"
];

interface ExcelSheetData {
    Location: string, VoucherNo: string, VoucherDate: string,
    InvAmt: string, CustomerCode: string, CustomerName: string, ItemCode: string,
    ItemName: string, Qty: string, FreeQty: string, BarCode: string,
    BatchNo: string, DeliveryCharges: string, Price: string, DiscountVal: string,
    DisVal: string, MRP: string, Cust_Group_Disc: string, TaxableValue: string,
    CGST_Per: string, CGST: string, SGST_Per: string, SGST: string, TOTAL: string, StockValue: string,
    Margin: string, MarginPercentage: string,
}

const ItemwiseMargin = () => {

    const { itemwiseMarginStore, commonStore } = useStores();
    const { loadOutlet, loadOutlets, } = commonStore;

    const [itemwiseMarginList, setItemwiseMarginList] = useState<any[]>([])

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})
    const [localFields, setLocalFields] = useState<any>({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate, outletId: 0 })
    const [searchValue, setSearchValue] = useState('');

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && itemwiseMarginList?.length > 0) {
            return itemwiseMarginList?.filter((priceHistory) => {
                if (priceHistory?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return priceHistory;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value });
    }

    const handleOutletValue = (name: any, event: object, val: any) => {
        if (val != null) {
            localFields[name] = val?.outletid;
            handleGenerateStock(localFields)
            setLocalFields({ ...localFields });
        }
        else {
            localFields.outletId = 0;
        }
    }

    async function handleGenerateStock(localFields) {
        setLoading(true)
        let error: any = {}
        if (!localFields?.outletId) {
            error.outletId = "Select Outlet Name"
        }
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            const barcodePriceHistoryList = await itemwiseMarginStore.loadItemwiseMargin(localFields)
            setItemwiseMarginList(barcodePriceHistoryList);
        }
        setLoading(false)
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Location: stock?.outletname, VoucherNo: stock?.invno, VoucherDate: moment(stock?.invoicedate).format("DD-MMM-YYYY"),
                InvAmt: ``, CustomerCode: stock?.gsmcode, CustomerName: stock?.membername, ItemCode: ``,
                ItemName: stock?.itemname, Qty: stock?.qty, FreeQty: ``, BarCode: ``,
                BatchNo: stock?.batch_no, DeliveryCharges: stock?.deliveryCharge, Price: stock?.itemsellprice, DiscountVal: stock?.itemdiscount,
                DisVal: ``, MRP: stock?.itemmrp, Cust_Group_Disc: ``, TaxableValue: ``,
                CGST_Per: ``, CGST: ``, SGST_Per: ``, SGST: ``, TOTAL: stock?.itemnettotal,
                StockValue: ``, Margin: stock?.marginamount, MarginPercentage: stock?.marginper,
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Itemwise Margin Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Itemwise Margin Report ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchBarcodePriceHistory() {
        await loadOutlet();
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchBarcodePriceHistory()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '100%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Itemwise Margin</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input type="date" style={{ width: '100%' }}
                                                    name="fromDate"
                                                    onChange={handleInputChange} value={localFields.fromDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-2">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={localFields.toDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={loadOutlets}
                                                    getOptionLabel={(option: any) => option.outletName}
                                                    onChange={(event, val) => handleOutletValue('outletId', event, val)}
                                                    value={loadOutlets.find((option) => option.outletId == localFields?.outletId)}
                                                    renderInput={(params: any) =>
                                                        <TextField {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Outlet Name..'
                                                            name='outletName'
                                                        />
                                                    }
                                                />
                                            </div>
                                            {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                    style={{ height: "51px" }}
                                                    name='searchValue'
                                                    value={searchValue}
                                                    onChange={(e) => setSearchValue(e.target.value)}
                                                    className="form-control" placeholder="Search Here.."
                                                    aria-label="Search Outlet..."
                                                    aria-describedby="search-button"
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-1"></div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={() => handleGenerateStock(localFields)}
                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={() => createExportExcelObj(itemwiseMarginList)}
                                            >Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {tableHeadings?.map((heading, index) => {
                                                    return (
                                                        <th key={index}>{heading}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(searchValue?.length > 0 ? filteredData : itemwiseMarginList)?.length ? (searchValue?.length > 0 ? filteredData : itemwiseMarginList)?.map((itemwiseMargin, index) => {
                                                return (
                                                    <tr>
                                                        <td>{itemwiseMargin?.outletname}</td>
                                                        <td>{itemwiseMargin?.invno}</td>
                                                        <td>{moment(itemwiseMargin?.invoicedate).format("DD-MMM-YYYY")}</td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.gsmcode}</td>
                                                        <td>{itemwiseMargin?.membername}</td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.itemname}</td>
                                                        <td>{itemwiseMargin?.qty}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.batch_no}</td>
                                                        <td>{itemwiseMargin?.deliveryCharge}</td>
                                                        <td>{itemwiseMargin?.itemsellprice}</td>
                                                        <td>{itemwiseMargin?.itemdiscount}</td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.itemmrp}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.itemnettotal}</td>
                                                        <td></td>
                                                        <td>{itemwiseMargin?.marginamount}</td>
                                                        <td>{itemwiseMargin?.marginper}</td>
                                                    </tr>
                                                )
                                            }) : <div>No data found</div>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ItemwiseMargin;