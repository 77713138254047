import React,{useEffect,useState} from 'react';
import logo from './logo.svg';
import {useNavigate} from "react-router-dom";
import Header from '../Header/Header';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { Image } from 'react-bootstrap';
import Footer from '../Footer/Footer';

const TrackingPackage=observer(():JSX.Element=> {

  
  return (
    <>
    {
      
      <>
    <div className="App" >
      <Header />
      <div className="container" >
        <div className="row" >
						<div className="col-12 col-md-10  pt45 pb20">
							<div className="row justify-content-between">
								<div className="order-tracking completed">
									<span className="is-complete"></span>
									<p>Ordered<br/><span>Sat, Oct 28</span></p>
								</div>
								<div className="order-tracking completed">
									<span className="is-complete"></span>
									<p>Shipped<br/><span><a href=''>Tracking ID</a> <br/>Mon, Oct 30</span></p>
								</div>
								<div className="order-tracking">
									<span className="is-complete"></span>
									<p>Delivered<br/><span>Wed, Nov 1</span></p>
								</div>
							</div>
						</div>
					</div>
        </div>
        <div className="grid-container">
              <div className="grid-item">
              <div> <Image  className='accountimg' src={require("../../gsmecom/images/deliveryitem.png")} /> </div>           
                  Delivery Info <h6><a href=''>Update Delivery Instruction</a></h6></div>
              <div className="grid-item">
              <div><Image  className='accountimg' src={require("../../gsmecom/images/location.png")} /></div>
                  Shipping Address <h6><a href=''>GSM, Gandhi Street, Kovai</a></h6></div>
              <div className="grid-item">
              <div><Image  className='accountimg' src={require("../../gsmecom/images/order-fulfillment.png")} /></div>
                  Order Info <h6><a href=''>View Order</a> , <a href=''>Cancel Order</a></h6></div>  
              <div className="grid-item">
              <div><Image  className='accountimg' src={require("../../gsmecom/images/shopping-cart.png")} /></div>
                  Shippinng Info <h6><a href=''>Tracking ID</a>, <a href=''>Patner Name</a>, <a href=''>Contact No</a></h6></div>         
          <div className='vertical-space-120'></div> 
          </div>  
        <Footer/>
     </div>
    
      </>
    }
    </>
  
  );
})

export default TrackingPackage;
