import React, { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Modal, Spinner, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { observer } from 'mobx-react-lite';
import { Autocomplete, TextField } from '@mui/material';
import { IAssignProduct, IAssignProducts } from './model';
import assignProductValidation from './validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const AssignProductGrouping = observer((): JSX.Element => {
  const [assignProduct, setAssignProduct] = useState<IAssignProduct>({});
  const [assignProductList, setAssignProductList] = useState<IAssignProducts[]>([]);
  const { assignProductStore } = useStores();
  const { loadItems, loadItemMaster, saveAssignProduct, getAssignProduct,updateAssignProduct } = assignProductStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSubmitBtn, setSubmitBtn] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { productId } = useParams();
  const handleChangeview = () => {
    navigate('/Admin/AssignProductDetails');
  }
  const handleMainPartValue = (name: any, event: object, val: any) => {
    if(val!=null){
    setAssignProduct({ ...assignProduct, mPartItemId: val.itemId, mPartItemName: val.itemName });
  }
  else{
    assignProduct.mPartItemName='';
  }
}
  const handleGroupPartValue = (name: any, event: object, val: any) => {
    if(val!=null){
      
    setAssignProduct({ ...assignProduct, gPartItemId: val.itemId, gPartItemName: val.itemName });
  }
  else{
    assignProduct.gPartItemName='';
  }
}

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignProduct({ ...assignProduct, [name]: value })
  }

  const handleAddProduct = (e) => {
    let assignProducts: IAssignProducts = {};
    e.preventDefault();
    let error: any = {};
    error = assignProductValidation(assignProduct);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      assignProducts.groupPartItemId = assignProduct.gPartItemId;
      assignProducts.groupPartItemName = assignProduct.gPartItemName;
      assignProducts.productWebName = assignProduct.webName;

      setAssignProductList([...assignProductList, assignProducts]);
      setSubmitBtn(true);
    }
  }

  const saveAssignProducts = async (e) => {
    e.preventDefault();
    setLoading(true);
    assignProduct.assignProductDeatils = assignProductList;
    let status='';
    if(productId!==undefined){
       status=await updateAssignProduct(assignProduct.assignProductID,assignProduct);
    }
    else  {
      status = await saveAssignProduct(assignProduct);
    }
    
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignProductDetails');
  }

  const editAssignProduct= async(val,e)=>{
    
    e.preventDefault();
  setAssignProduct({...assignProduct,gPartItemName:val.groupPartItemName,gPartItemId:val.groupPartItemId,
  webName:val.productWebName});
  }
  const deleteAssignProduct=(val,e)=>{
    
    
    setAssignProductList(assignProductList=>assignProductList.filter((item)=>item.groupPartItemId !==val.groupPartItemId));
  }

  function mapAssignProduct(data) {
    
    assignProduct.assignProductID = data.assignProductID;
    assignProduct.mPartItemId = data.mPartItemId;
    assignProduct.mPartItemName = data.mPartItemName;

    assignProduct.assignProductDeatils = data.assignProductDeatils;
    setAssignProduct(assignProduct);
    setAssignProductList(data.assignProductDeatils);
  }
  async function prefetchLoadingData() {
    
    if (productId !== undefined) {
      const data = await getAssignProduct(productId);
      mapAssignProduct(data!);

    }
    await loadItemMaster();
    setLoading(false)
  }

  useEffect(() => {
    prefetchLoadingData();
  }, [])

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Assign Product Grouping</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Main PartName <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadItems}
                            getOptionLabel={(option: any) => option.itemName}
                            onChange={(event, val) => handleMainPartValue('mPartItemId', event, val)}
                            value={loadItems.find((option) => option.itemName === assignProduct.mPartItemName)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '25ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Main PartName..'
                                name='mainPartName'
                              />}
                          />
                          {errors.mPartItemName && <p style={{ color: 'red' }}>{errors.mPartItemName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Group PartName <span>*</span></label>
                          <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={loadItems}
                                getOptionLabel={(option: any) => option.itemName}
                                onChange={(event, val) => handleGroupPartValue('gPartItemId', event, val)}
                                value={loadItems.find((option) => option.itemName === assignProduct.gPartItemName) }
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '25ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Group Part Name..'
                                    name='gPartItemId'
                                  />}
                              />
                          {errors.gPartItemName && <p style={{ color: 'red' }}>{errors.gPartItemName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Web Name<span>*</span></label>
                          <input  type="text" name='webName'
                            onChange={handleInputChange}
                            value={assignProduct.webName}
                            className='' style={{ width: "100%" }} placeholder='Web Name..'></input>
                          {errors.webName && <p style={{ color: 'red' }}>{errors.webName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={handleAddProduct}>Add</button>
                      </div>
                      <div className='col-md-12'>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Group Part Name</th>
                                <th scope="col">Web Name </th>
                                <th scope="col"> </th>
                                <th scope="col"> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                assignProductList.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.groupPartItemName}</td>
                                      <td>{val.productWebName}</td>
                                      <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={(e) => editAssignProduct(val, e)} /></button></td>
                                      <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={(e) => deleteAssignProduct(val, e)} /></button></td>
                                    </tr>
                                  )
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                  <button className='dfBtn' type='submit' disabled={!isSubmitBtn} onClick={saveAssignProducts}>Submit</button>
                </div>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>

  );

});

export default AssignProductGrouping;
