import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import { ICategoryAssignSearchoptions } from './model';

const CategoryAssignMasterDetail = observer((): JSX.Element => {
    const [teamMasterSearchOptions, setTeamMasterSearchOptions] = useState<ICategoryAssignSearchoptions>({})
    const { teamMasterStore, userCreationStore, categoryAssignMasterStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    console.log(userRights, 'userRights');
    const [currentPageData, setCurrentPageData] = useState<any[]>([])
    const [detailsData, setDetailsData] = useState<any[]>([])
    const { getTeamMasterDetails } = teamMasterStore;
    const [isLoading, setLoading] = useState(true);
    const [isTeamMasterShow, setTeamMasterModal] = useState(false);
    const navigate = useNavigate();
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const deleteTeam = async (teamMasterId) => {
        setLoading(true);
        const status = await teamMasterStore.deleteTeamMaster(teamMasterId);

        if (status === 'Success') {
            fetchTeamMaster();
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }

    const editCategoryAssign = (offercustmid) => {
        navigate('/Admin/CategoryAssignOffers/' + offercustmid);
    }

    const deleteCategoryAssign = async (offercustmid) => {
        setTeamMasterModal(false);
        setLoading(true);
        const status = await categoryAssignMasterStore.deleteCategoryAssignMaster(offercustmid);
        if (status === 'Success') {
            fetchTeamMaster();
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchTeamMaster();
    }

    const getCategoryAssignMasterDetail = async (offercustmid) => {
        console.log(offercustmid,'offercustmid');
        
        const dataDet = await categoryAssignMasterStore?.getCategoryAssignMasterDetails(offercustmid);
        if (dataDet !== undefined || dataDet !== null) {
            setTeamMasterModal(true);
            setDetailsData(dataDet);
            console.log(dataDet,'dataDet');
            console.log(detailsData,'detailsData');
        }
    }

    const handleCreate = () => {
        navigate('/Admin/CategoryAssignOffers');
    }

    const handleClose = () => {
        setTeamMasterModal(false);
    }

    async function fetchTeamMaster() {
        setLoading(false)
        const userRights = await Promise.all([userCreationStore?.getUserRight('Team_master')])
        const updatedList = await categoryAssignMasterStore?.getCategoryAssignMasterDetails(0)
        console.log(updatedList,'updatedList');
        setCurrentPageData(updatedList)
        if (userRights) {
            setRightsObj({ ...userRights })
        }
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchTeamMaster();
            isCurrentPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);

    // useEffect(() => {
    //     if (categoryAssignMasterStore?.categoryAssignMasterDetail?.length) {
    //         goToPage(1)
    //     } else {
    //         setCurrentPageData(categoryAssignMasterStore?.categoryAssignMasterDetail)
    //     }
    // }, [categoryAssignMasterStore?.categoryAssignMasterDetail])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(
            (Array.isArray(categoryAssignMasterStore?.categoryAssignMasterDetail)
                ? categoryAssignMasterStore?.categoryAssignMasterDetail.slice()
                : []
            ).sort((a: any, b: any) => {
                if (!a.offercustmid || !b.offercustmid) return 0;
                return b.offercustmid - a.offercustmid;
            }),
            value,
            10
        );
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };
    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={teamMasterStore?.teamMasterList}
                                searchTitles={[{ key: "teamMasterName", value: "Team Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{teamMasterStore?.teamMasterList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            {/* <th scope='col'> Team ID </th> */}
                                            <th scope='col'> Offer Name </th>
                                            <th scope='col'></th>
                                            <th scope='col'>Create </th>
                                            <th scope='col'>Update </th>
                                            <th scope='col'>Delete </th>
                                            <th scope='col'> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData).map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        {/* <td>{val.teamMasterId}</td> */}
                                                        <td>{val.offername}</td>
                                                        <td><button disabled={!userRights?.[0]?.view} onClick={() => getCategoryAssignMasterDetail(val.offercustmid)}>Detail</button></td>
                                                        <td><button disabled={!userRights?.[0]?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.[0]?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editCategoryAssign(val.offercustmid)} /></button></td>
                                                        <td><button disabled={!userRights?.[0]?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteCategoryAssign(val.offercustmid)} /></button></td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            {
                                isTeamMasterShow ?
                                    <Modal show={isTeamMasterShow} onHide={handleClose} className='PriceHistoryModel'>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Detail</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            {/* <th scope="col">Employee ID</th> */}
                                                            <th scope="col">Customer Category</th>
                                                            {/* <th scope='col'>Delete </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            detailsData?.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        {/* <td>{val.employeeId}</td> */}
                                                                        <td>{val.custtype}</td>
                                                                        {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteTeamDetail(val.teamMasterDetailId)} /></button></td> */}
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Modal.Body>
                                    </Modal> : null
                            }
                        </div>
                        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/checked.png')} />
                                    </div>
                                    <h4>Succesfully Deleted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                            <Modal.Body>
                                <div className='Details Success'>
                                    <div className='imgBox'>
                                        <Image src={require('../../../gsmecom/images/warning.png')} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </>
            }
        </>
    );

});

export default CategoryAssignMasterDetail;
