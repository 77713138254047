import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import useStores from '../../hooks';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { currentPageDatas, getCurrentMonthFirstDate, removeDuplicates } from '../../common/shared/utils';

function PurchaseApprovalDetail() {
  const { purchaseApproval, commonStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();

  const { loadItemConversion, loadItemConversions } = commonStore;

  const [purchaseApprovalList, setPurchaseApprovalList] = useState<any[]>([])
  const [indentItemList, setIndentItemlist] = useState<any[]>([])

  const [purchaseAppFields, setPurchaseAppFields] = useState<any>({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate, indentno: null, itemname: null })
  const [loadIndentList, setLoadIndentList] = useState<any[]>([])
  const [searchCriteria, setSearchCriteria] = useState({ itemName: false, indentNo: false, field: '' })

  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState<any>({})

  const modalClose = () => setShow(false);
  const modalShow = async (indentId) => {
    const indentDetails = await purchaseApproval.getIndentNoDetails(indentId);
    setIndentItemlist(indentDetails)
    setShow(true);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setPurchaseAppFields({ ...purchaseAppFields, [name]: value })
  }

  function handleChangeInput(event: any) {
    const { name, value } = event.target;

    if (value == '') {
      setSearchCriteria({ ...searchCriteria, itemName: false, indentNo: false, field: '' })
    }
    if (value === 'itemName') {
      setSearchCriteria({ ...searchCriteria, itemName: true, indentNo: false, field: 'itemName' })
      setErrors({})
    }
    if (value == 'indentNo' && purchaseAppFields?.fromDate && purchaseAppFields?.toDate) {
      setSearchCriteria({ ...searchCriteria, itemName: false, indentNo: true, field: 'indentNo' })
      setErrors({})
    } else {
      let error: any = {};
      error = {
        fromDate: purchaseAppFields?.fromDate ? null : 'Enter From Date',
        toDate: purchaseAppFields?.toDate ? null : 'Enter To Date'
      }
      setErrors(error)
    }
  }

  function handleValue(type: string, event, value) {
    if (type == 'indent_id') {
      setLoading(true);
      setPurchaseAppFields({ ...purchaseAppFields, indentno: value.indent_no, itemname: null });
      setLoading(false);
    } else {
      setPurchaseAppFields({ ...purchaseAppFields, itemname: value.itemName, indentno: null });
    }
  }

  function handleCreate() {
    navigate("/Admin/PurchaseApproval")
  }

  async function handleDelete(indentId) {
    let index = purchaseApprovalList?.findIndex((order) => order?.purchase_order_id === indentId)
    if (index >= 0) {
      purchaseApprovalList?.splice(index, 1)
      setPurchaseApprovalList(purchaseApprovalList)
      await purchaseApproval.deletePurchaseApproval(indentId)
    }
  }

  async function handleGenerateStock() {
    let error: any = {}
    if (!purchaseAppFields?.fromDate) {
      error.fromDate = "Enter From Date"
    }
    if (!purchaseAppFields?.toDate) {
      error.toDate = 'Enter To Date'
    }
    if (Object.keys(error)?.length === 0) {
      setLoading(true)
      let body = {
        "indentno": purchaseAppFields?.indentno,
        "itemname": purchaseAppFields?.itemname,
        "fromDate": purchaseAppFields?.fromDate,
        "toDate": purchaseAppFields?.toDate,
      }

      const purchaseAppList = await purchaseApproval.loadPurchaseApprvalList(body);
      setPurchaseApprovalList(Object.assign([], purchaseAppList))
      setErrors({});
    } else {
      setErrors(error)
    }
    setLoading(false);
  }

  async function handleClear() {
    setLoading(true)
    let indentListDetail: any = []
    if (purchaseAppFields?.fromDate && purchaseAppFields?.toDate) {
      let body = {
        "indentno": null,
        "itemname": null,
        "fromDate": purchaseAppFields?.fromDate,
        "toDate": purchaseAppFields?.toDate,
      }
      indentListDetail = await purchaseApproval.loadPurchaseApprvalList(body);
    }
    setSearchCriteria({ ...searchCriteria, itemName: false, indentNo: false, field: '' })
    setPurchaseApprovalList(indentListDetail)
    setLoading(false)
  }

  function createExportExcelObj(stockSummary: any[]) {
    let excelArr = stockSummary?.map((stock) => {
      const excelObj = {
        Indent_No: stock?.indent_id
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: any) {
    const csvContent = ["Purchase Approval List", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Purchase Approval List ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function fetchLoadItemConversion() {
    if (purchaseAppFields?.fromDate && purchaseAppFields?.toDate) {
      let indentListDetail = await purchaseApproval.getIndentMainDetails(purchaseAppFields?.fromDate, purchaseAppFields?.toDate);
      setLoadIndentList(indentListDetail)
    }
    const userRights = await userCreationStore?.getUserRight('Purchase_approval')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await loadItemConversion()
    setLoading(false)
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchLoadItemConversion()
      handleGenerateStock()
      isCurrentPage.current = false
    }
    return () => { }
  }, [])


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (purchaseApprovalList && purchaseApprovalList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(purchaseApprovalList)
    }
  }, [purchaseApprovalList])

  const goToPage = (value: number) => {
    let arrayOfData = purchaseApprovalList?.slice()?.sort((a, b) => Number(b?.purchase_approval_no?.split('-')[1]) - Number(a?.purchase_approval_no?.split('-')[1]))
    const currentPageList = currentPageDatas(removeDuplicates(arrayOfData, 'purchase_approval_no'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>

      {loading ?
        <div className='SpinnerBox'>
          <Spinner animation="border" role="status"></Spinner>
        </div> :
        <div className="flex alignItemCenter justifyContentCenter">

          <div className='outletInputField inputFormBox LgInputField' style={{ width: '80%' }}>
            <div className='hrBox'>
              <h3>Purchase Approval Detail</h3>
            </div>
            <div className='inputBoxLists'>
              <div className='ItemInwardInputBox'>
                <div className='row'>
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>From Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        placeholder="DD-MMM-YYYY" name="fromDate" value={purchaseAppFields.fromDate} onChange={handleInputChange} data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    {errors.fromDate && <p style={{ color: 'red' }}>{errors.fromDate}</p>}
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox'>
                      <label>To Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        placeholder="DD-MMM-YYYY" name="toDate" value={purchaseAppFields.toDate} onChange={handleInputChange} data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    {errors.toDate && <p style={{ color: 'red' }}>{errors.toDate}</p>}
                    <div className='vertical-space-20'></div>
                  </div>
                  <div className='col-sm-2' style={{ marginTop: '19px' }}>
                    <div className='inputBox' style={{ paddingRight: "0px", height: "49px" }}>
                      <select value={searchCriteria?.field} className="form-selected full-width" id="Mode" name="searchfield" onChange={handleChangeInput} >
                        <option value="">Select Search Criteria</option>
                        <option value="indentNo">Indent No</option>
                        <option value="itemName">Item Name</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2" style={{ marginTop: '20px' }}>
                    {(searchCriteria?.indentNo || searchCriteria?.itemName) &&
                      <div className='inputBox'>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={searchCriteria?.itemName ? loadItemConversions : removeDuplicates(purchaseApprovalList, 'indent_no')}
                          getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.indent_no}
                          onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : handleValue('indent_id', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Indent No..'}
                              name={searchCriteria?.itemName ? 'itemId' : 'indentNo'}
                            />}
                        />
                      </div>
                    }
                  </div>
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Search</button>
                  </div>
                  {/* <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(purchaseApprovalList)}>Excel</button>
                  </div> */}
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' type='submit' onClick={handleClear}>Clear</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{removeDuplicates(purchaseApprovalList, 'purchase_approval_no')?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-10'></div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>P. Approval No  </th>
                    <th scope='col'>P. Approval Date</th>
                    <th scope='col'>Indent No  </th>
                    <th scope='col'></th>
                    <th scope='col'>Create </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData?.length ? currentPageData?.map((purchaseItem, index) => {
                    return (
                      <tr key={index}>
                        <td>{purchaseItem?.purchase_approval_no}</td>
                        <td>{purchaseItem?.indentdate}</td>
                        {/* <td>{purchaseItem?.indent_no}</td> */}
                        <td><button disabled={!userRights?.view} onClick={() => modalShow(purchaseItem?.purchase_approval_no)}>Item Detail</button></td>
                        <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon onClick={() => handleDelete(purchaseItem?.indent_id)} icon={faTrashAlt} /></button></td>
                      </tr>
                    )
                  })
                    :
                    <div>No Data Found</div>
                  }

                </tbody>
              </table>
            </div>
            <div className='vertical-space-20'></div>
            <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
              <Modal.Header closeButton>
                <Modal.Title>Item Detail</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope='col'>Indent No</th>
                        <th scope='col'>Supplier</th>
                        <th scope="col">Item Name</th>
                        <th scope="col">UOM</th>
                        <th scope="col">Indent Qty</th>
                        <th scope="col">Approved Qty</th>
                        <th scope="col">Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {indentItemList?.length ? indentItemList?.map((items) => {
                        return (
                          <tr>
                            <td>{items?.indent_no}</td>
                            <td>{items?.inwardsuppliername}</td>
                            <td>{items?.itemname}</td>
                            <td>{items?.Unitname}</td>
                            <td>{items?.indent_qty}</td>
                            <td>{items?.approved_qty}</td>
                            <td>{items?.inwardsupplierrate}</td>
                          </tr>
                        )
                      }) :
                        <div>
                          No Items </div>
                      }
                    </tbody>
                  </table>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      }
    </div>
  );

};

export default PurchaseApprovalDetail;
