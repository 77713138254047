import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Pagination, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { currentPageDatas } from '../../../common/shared/utils'

interface HoldCustomerModalProps {
    hold: boolean,
    holdClose: () => void,
    getHolds: Array<any>,
    holdMovetoBill: (HoldId: any) => void,
    deleteHoldBill: (holdId: any) => void,
}
const HoldCustomerModal: React.FC<HoldCustomerModalProps> = ({ hold, holdClose, getHolds, holdMovetoBill, deleteHoldBill }) => {

    function deleteHold(holdId) {
        let index = getHolds?.findIndex((each) => each?.HoldId === holdId)
        if (index >= 0) {
            getHolds?.splice(index, 1)
            if (getHolds && getHolds?.length) {
                goToPage(1)
            }
            deleteHoldBill(holdId)
        }
    }
    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (getHolds && getHolds?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(getHolds)
        }
    }, [getHolds])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(getHolds, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };
    return (
        <Modal show={hold} onHide={holdClose} className='PriceHistoryModel flexImp' style={{
            alignItems: 'center',
            margin: '0',
            justifyContent: 'center',
        }}>
            <Modal.Header closeButton>
                <Modal.Title>Hold Customers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getHolds?.length} Records</button>
                    </div>
                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableBox'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Hold Id  </th>
                                <th scope="col">Mobile No </th>
                                <th scope="col">Member </th>
                                <th scope="col">Counter</th>
                                <th scope="col">Employee Name</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData?.length > 0 &&
                                currentPageData.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{val.HoldId}</td>
                                            <td>{val.MobileNo}</td>
                                            <td>{val.MemberName}</td>
                                            <td>{val.CounterNumber}</td>
                                            <td>{val.EmployeeName}</td>
                                            <td><button className='dfBtn viewbillBtn width-auto' onClick={() => holdMovetoBill(val.HoldId)}>Biiling <FontAwesomeIcon icon={faEdit} /></button></td>
                                            <td><button className='dfBtn viewbillBtn width-auto' onClick={() => deleteHold(val?.HoldId)} >Delete <FontAwesomeIcon icon={faTrash} /></button></td>
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default HoldCustomerModal