import apiAgent from '../../../APIAgent';
import { IPurchaseOrderSaveModal } from '../modal';

class PurchaseOrderStore {
    loadSupplierName = async () => {
        try {
            const supplierDetails = await apiAgent.purchaseOrder.getSupplierNameDetails();
            return supplierDetails;
        } catch (error) {
            console.log(error)
        }
    }
    loadSameAddress = async (supid) => {
        try {
            const currentAddress = await apiAgent.purchaseOrder.getCurrentAddress(supid);
            return currentAddress;
        } catch (error) {
            console.log(error)
        }
    }
    loadSupplierItemList = async (supplierName) => {
        try {
            const supplierItemList = await apiAgent.purchaseOrder.getSupplierItemsList(supplierName);
            return JSON.parse(supplierItemList);
        } catch (error) {
            console.log(error)
        }
    }
    savePurchaseOrder = async (purchaseOrderSave: IPurchaseOrderSaveModal) => {
        try {

            const saveState = await apiAgent.purchaseOrder.savePurchaseOrder(purchaseOrderSave);

            return saveState
        } catch (error) {
            console.log(error)
        }
    }
    savePurchaseOrderDirect = async (purchaseOrderSave: IPurchaseOrderSaveModal) => {
        try {

            const saveState = await apiAgent.purchaseOrder.savePurchaseOrderDirect(purchaseOrderSave);

            return saveState
        } catch (error) {
            console.log(error)
        }
    }
    savePurchaseOrderWorkOrder = async (purchaseOrderSave: IPurchaseOrderSaveModal) => {
        try {

            const saveState = await apiAgent.purchaseOrder.savePurchaseOrderWorkOrder(purchaseOrderSave);

            return saveState
        } catch (error) {
            console.log(error)
        }
    }
    getPurchaseOrders = async (purcOrdOptions) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.getPurchaseOrders(purcOrdOptions);
            return JSON.parse(purchaserOrderList)
        } catch (error) {
            console.log(error)
        }
    }
    getPurchseOrdersMasters = async (purcOrdOptions) => {
        try {
            const purchaserOrderMasterList = await apiAgent.purchaseOrder.getPurchseOrdersMasters(purcOrdOptions);
            return purchaserOrderMasterList
        } catch (error) {
            console.log(error)
        }
    }
    getPurchaseWorkOrderMaster = async (purcOrdOptions) => {
        try {
            const purchaserOrderMasterList = await apiAgent.purchaseOrder.getPurchaseWorkOrderMaster(purcOrdOptions);
            return purchaserOrderMasterList
        } catch (error) {
            console.log(error)
        }
    }
    getPurchaseOrderDetails = async (purcOrdOptions) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.getPurchaseOrderDetails(purcOrdOptions);
            return (purchaserOrderList)
        } catch (error) {
            console.log(error)
        }
    }
    getPurchaseOrderDirectDetails = async (purcOrdOptions) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.getPurchaseOrderDirectDetails(purcOrdOptions);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    getPurchaseWorderOrderDetails = async (purcOrdOptions) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.getPurchaseWorderOrderDetails(purcOrdOptions);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    deletePurchaseOrder = async (purchaseOrderId) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.deletePurchaseOrder(purchaseOrderId);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    deletePurchaseOrderDirect = async (purchaseOrderId) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.deletePurchaseOrderDirect(purchaseOrderId);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    purchaseOrderPrint = async (poNo) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.purchaseOrderPrint(poNo);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    purchaseOrderDirectPrint = async (poNo) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.purchaseOrderDirectPrint(poNo);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
    purchaseWprkOrderPrint = async (poNo) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseOrder.purchaseWprkOrderPrint(poNo);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }

    purchaseorderTeamsandcondition = async () => {
        try {
            const loadPurchaseOrderTeamandcond = await apiAgent.purchaseOrder.purchaseorderTeamsandcondition()
            return loadPurchaseOrderTeamandcond;
        } catch(error) {
            console.log(error)
        }
    }
    
    purchaseOrderScheduleGet = async () => {
        try {
            const loadPurchaseOrderTeamandcond = await apiAgent.purchaseOrder.purchaseOrderScheduleGet()
            return loadPurchaseOrderTeamandcond;
        } catch(error) {
            console.log(error)
        }
    }

    purchaseorderdirectTeamsandcondition = async () => {
        try {
            const loadPurchaseOrderTeamandcond = await apiAgent.purchaseOrder.purchaseorderdirectTeamsandcondition()
            return loadPurchaseOrderTeamandcond;
        } catch(error) {
            console.log(error)
        }
    }
    purchaseorderWORKORDERTeamsandcondition = async () => {
        try {
            const loadPurchaseOrderTeamandcond = await apiAgent.purchaseOrder.purchaseorderWORKORDERTeamsandcondition()
            return loadPurchaseOrderTeamandcond;
        } catch(error) {
            console.log(error)
        }
    }}

export default PurchaseOrderStore;