import { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import useStores from "../../hooks";
import moment from "moment";

const LoanAndAdvanceApproval = () => {

    const { employeeStore, payrollStore, departmentStore } = useStores();

    const navigate = useNavigate();

    const [isSearch, setIsSearch] = useState(false)
    const [searchFields, setSearchFields] = useState<any>({ empid: 0, department: 0 })
    const [filteredData, setFilteredData] = useState<any[]>([])


    const [employeeList, setEmployeeList] = useState<any>([])
    const [departmentList, setDepartmentList] = useState<any>([])
    const [loadAndAdvanceJSON, setLoanAndAdvanceJSON] = useState<any>([])
    const [temploadAndAdvanceJSON, settempLoanAndAdvanceJSON] = useState<any>([])

    const [loanAndAdvApprovalEntry, setLoanAndAdvApprovalEntry] = useState<any[]>([]);

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate("/Admin/LoanAdvanceApproval/LoanAdvanceApprovalList");
    };

    function handleToLoanAndAdvanceApprovalList() {
        navigate("/Admin/LoanAdvanceApproval/LoanAdvanceApprovalList");
    }

    function handleSearchFunc() {
        debugger
        setIsSearch(true)
        let temparry: any[] = [];
        loadAndAdvanceJSON.filter((each) => {
            let isExists = temparry?.some((obj) => obj?.Empid != each?.Empid);
            if (searchFields?.empid && searchFields?.department) {
                if (searchFields?.empid == each?.Empid) {
                    temparry?.push(each)
                }
            } else if (searchFields?.empid && !searchFields?.department) {
                if (!isExists && each?.Empid == searchFields?.empid) {
                    temparry?.push(each)
                }
            } else if (!searchFields?.empid && searchFields?.department) {
                if (!isExists && each?.department == searchFields?.department) {
                    temparry?.push(each)
                }
            }
            return temparry
        });
        setFilteredData([...temparry])
    }

    async function handleSaveLoanAdvanceApproval() {
        if (loanAndAdvApprovalEntry?.length) {
            setLoading(true)
            let finalArr = loanAndAdvApprovalEntry?.map((obj) => {
                let finalObj: any = {
                    "loanid": obj?.Loanid,
                    "empid": obj?.Empid,
                    "appstatus": obj?.Appstatus
                }
                return finalObj;
            })
            const status = await payrollStore.saveLoanAdvanceApproval(finalArr)
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleAddToGrid(Loanid) {
        if (loanAndAdvApprovalEntry?.some((obj) => obj?.Loanid == Loanid)) {
            loadAndAdvanceJSON?.map((obj) => {
                if (obj?.Loanid == Loanid) {
                    obj['check'] = false;
                    return obj;
                }
                return obj
            })
            setLoanAndAdvanceJSON([...loadAndAdvanceJSON])
            let index = loanAndAdvApprovalEntry?.findIndex((obj) => obj?.Loanid == Loanid)
            loanAndAdvApprovalEntry?.splice(index, 1)
            setLoanAndAdvApprovalEntry([...loanAndAdvApprovalEntry])
        } else {
            let findObj = loadAndAdvanceJSON?.find((obj) => obj?.Loanid == Loanid)
            loadAndAdvanceJSON?.map((obj) => {
                if (obj?.Loanid == Loanid) {
                    obj['check'] = true;
                    return obj;
                }
                return obj
            })
            loanAndAdvApprovalEntry?.push(findObj!);
            setLoanAndAdvApprovalEntry([...loanAndAdvApprovalEntry]);
            setLoanAndAdvanceJSON([...loadAndAdvanceJSON])
        }
    }

    async function initialApiCall() {
        const employeeList = await employeeStore.loadEmployeeMaster();
        const departmentList = await departmentStore.getDepartment();
        const loadAndAdvanceJSON = await payrollStore.loadLoanAndAdvance()

        setLoanAndAdvanceJSON([...loadAndAdvanceJSON])
        settempLoanAndAdvanceJSON([...loadAndAdvanceJSON])
        setDepartmentList([...departmentList])
        setEmployeeList([...employeeList])
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox" style={{ width: '780px' }}>
                        <div className="hrBox">
                            <h3>Loan & Advanced Approval</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Employee Name <span>*</span>  </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={loadAndAdvanceJSON}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, val) => setSearchFields({ ...searchFields, empid: val?.Empid })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Employee..'
                                                    name='empid'
                                                />}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Department<span>*</span></label>
                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                            name="department" value={searchFields?.department} onChange={(event) => setSearchFields({ ...searchFields, department: event.target.value })}
                                        >
                                            <option value=""> - - Select - - </option>
                                            {departmentList?.map((each, index) => {
                                                return (
                                                    <option value={each?.department} key={index}>{each?.department}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="">
                                        <button className="dfBtn" onClick={handleSearchFunc}>
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="">
                                        <button className="dfBtn" onClick={() => setIsSearch(false)}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Emp Name</th>
                                        <th scope="col">Req.No/Date</th>
                                        <th scope="col">Reason</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">MD</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(isSearch ? filteredData : loadAndAdvanceJSON)?.map((each) => {
                                        return each?.Appstatus === 'No' && (
                                            <tr>
                                                <td>{each?.Employee_Name}</td>
                                                <td>{`${each?.Loanid}/${moment(each?.Advdate).format('DD-MMM-YYYY')}`}</td>
                                                <td>{each?.Reason}</td>
                                                <td>{each?.LoanAmount}</td>
                                                <td><input type="checkbox" defaultChecked={each?.check!} onClick={() => handleAddToGrid(each?.Loanid)} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="btnBox" style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <button className="secondaryBtn" onClick={handleToLoanAndAdvanceApprovalList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveLoanAdvanceApproval}>
                                Approve
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    );
};

export default LoanAndAdvanceApproval;
