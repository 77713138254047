import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IPackingDistribution, PackingDistributionDetails } from './model';
import { Autocomplete, TextField } from '@mui/material';
import ProgressBar from '../../common/shared/progressbar';
import packingDistributionValidation from './validation';
import { QRCode } from 'react-qrcode-logo';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import Cookies from 'js-cookie';

const PackingDistribution = observer((): JSX.Element => {
  const { supplierMasterStore, commonStore, packingDistributionStore, brandStore,
    itemConversionStore } = useStores();
  const [packingDistribution, setPackingDistribution] = useState<IPackingDistribution>({});
  const [packingOfDistribution, setPackingOfDistribution] = useState<PackingDistributionDetails>({});
  const [packingOfDistributions, setPackingOfDistributions] = useState<PackingDistributionDetails[]>([]);
  const [loadBatches, setLoadBatches] = useState<any>([])
  console.log(loadBatches, 'loadBatches');

  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSpinner, setSpinner] = useState(false);
  const [batchNo, setBatchNo] = useState(null);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const [loadPackingItems, setloadPackingItems] = useState<any>([])
  const [loadDisplayItems, setloadDisplayItems] = useState<any>([])
  console.log(loadPackingItems, 'loadPackingItems');

  const { loadPackingItem, loadTeamDetail, loadTeamDetails, loadWarehouse, loadWarehouses } = commonStore;

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'assignQty' && value !== undefined) {
      let error: any = {};;
      const assignQty = Number(value);
      if (assignQty! > packingDistribution.existingQty!) {
        error.assignQty = "Exceeds from Batch Quantity"
      }
      setErrors(error);
    }
    setPackingDistribution({ ...packingDistribution, [name]: value })
  }

  function handleRemove(index) {
    packingOfDistributions.splice(index, 1)
    setPackingOfDistributions(_.cloneDeep(packingOfDistributions))
    let updatePackingItem = loadPackingItems?.map((each) => {
      if (each?.warehouseid == packingDistribution?.warehouseId && each?.ItemId === packingDistribution?.ItemId) {
        each.WarehouseQty = Number(each?.WarehouseQty) + Number(packingDistribution?.assignQty!)
        return each
      }
      return each
    })
    setloadPackingItems([...updatePackingItem])
    setBatchNo(null)
  }
  const addPackingDistribution = () => {
    let newObject: PackingDistributionDetails = {};
    let error: any = {};
    error = packingDistributionValidation(packingDistribution);

    const checkQty = packingOfDistributions?.filter(m => m?.packingDistributionbatchNo === packingDistribution.batchNo && m?.packingDistributionItemId === packingDistribution.ItemId);
    if (checkQty.length > 0) {
      const totalQty = checkQty?.reduce((a, v) => a = a + v?.packingDistributionpackingAssignQty!, 0)
      if ((totalQty + Number(packingDistribution.assignQty!)) > packingDistribution.temppackingQty!) {
        error.assignQty = "Batch Quantity stock is " + packingDistribution.temppackingQty + " But Total Added will be " + (totalQty + Number(packingDistribution.assignQty!))
      }
    }
    if (packingDistribution.assignQty! > packingDistribution.temppackingQty!) {
      error.assignQty = "Batch Quantity stock is " + packingDistribution.temppackingQty + " But Required Added " + Number(packingDistribution.assignQty!)
    }
    setErrors(error);
    if (Object.keys(error).length === 0) {
      packingOfDistribution.packingDistributionItemId = packingDistribution.ItemId;
      packingOfDistribution.packingDistributionitemName = packingDistribution.itemName;
      packingOfDistribution.packingDistributionbatchNo = packingDistribution.batchNo;
      packingOfDistribution.packingDistributionbarcode = packingDistribution.barcode ?? "";
      packingOfDistribution.packingDistributionbatchId = packingDistribution.batchId
      packingOfDistribution.packingDistributionitemUOM = packingDistribution.itemUOM;
      packingOfDistribution.packingDistributionpackingQty = packingDistribution.packingQty!;
      packingOfDistribution.packingDistributionpackingAssignQty = Number(packingDistribution.assignQty);
      packingOfDistribution.packingDistributionwarehouseQty = packingDistribution.warehouseQty;
      packingDistribution.packingQty! = packingDistribution.packingQty! - packingDistribution.assignQty!;
      setSubmitbtn(true);
      setPackingOfDistributions([...packingOfDistributions, packingOfDistribution])
      setPackingOfDistribution(newObject);
      setPackingDistribution({
        ...packingDistribution,
        ItemId: 0, itemName: '', itemUOM: '',
        warehouseQty: '', assignQty: 0, barcode: '', batchNo: '',
        batchId: 0, packingQty: 0, existingQty: 0
      });
      let updatePackingItem = loadPackingItems?.map((each) => {
        if (each?.warehouseid == packingDistribution?.warehouseId && each?.ItemId === packingDistribution?.ItemId) {
          each.WarehouseQty = Number(each?.WarehouseQty) - Number(packingDistribution?.assignQty!)
          return each
        }
        return each
      })
      setloadPackingItems([...updatePackingItem])
      setBatchNo(null)
    }
  }
  const handleTeamValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setPackingDistribution({ ...packingDistribution, [name]: val.teamMasterId, teamName: val.teamMasterName });
    } else {
      packingDistribution.teamName = '';
    }
  }

  const handlePackingValue = async (name: any, val: any) => {
    if (val != null) {
      // console.log(loadPackingItems, 'loadPackingItems');
      // let UpdateproductStock = loadPackingItems?.filter((each) => {
      //   return each?.warehouseid === packingDistribution?.warehouseId && each?.ItemId === val?.itemId;
      // });
      // //  let UpdateproductStock = loadPackingItems?.filter((updateitem) => updateitem?.warehouseid === packingDistribution?.warehouseId && updateitem?.ItemId === val?.itemId)
      // console.log(UpdateproductStock, 'UpdateproductStock');
      // console.log(val?.itemId, 'val?.itemId');

      let findItem = loadPackingItems?.filter((each) => each?.warehouseid === packingDistribution?.warehouseId && each?.ItemId === val?.ItemId)
      let wareQty = findItem?.reduce((a, v) => a + Number(v?.WarehouseQty ?? 0), 0)
      setPackingDistribution({ ...packingDistribution, [name]: val.ItemId, ["itemName"]: val.ItemName, ["itemUOM"]: val.unitOfMeasure, ["warehouseQty"]: wareQty });
      setSpinner(true)
      const loadBatches = await packingDistributionStore.loadBatchs(val?.ItemId, 0);

      let updatedList = loadBatches?.map((batch: any) => {
        let findObj = packingOfDistributions?.find((each) => each?.packingDistributionbatchId === batch?.batchId && each?.packingDistributionbatchNo === batch?.batchNo)
        let findBatches = packingOfDistributions?.filter((each) => each?.packingDistributionbatchId === batch?.batchId && each?.packingDistributionbatchNo === batch?.batchNo)
        let findBatchQty = findBatches?.reduce((a, v) => a = a + Number(v?.packingDistributionpackingAssignQty), 0)
        if (findObj?.packingDistributionbatchId === batch?.batchId && findObj?.packingDistributionbatchNo === batch?.batchNo) {
          batch['tempBatchQty'] = batch?.batchQty;
          //  batch.batchQty = batch?.batchQty - Number(findBatchQty);
          batch.batchQty = batch?.batchQty;
          return batch
        }
        return batch
      })
      setLoadBatches([...updatedList])
      setLoadBatches([])
      if (loadBatches?.length) {
        setLoadBatches([...loadBatches])
      }
      setSpinner(false)
    } else {
      packingDistribution.itemName = '';
      packingDistribution.ItemId = 0;
      packingDistribution.itemUOM = '';
      packingDistribution.warehouseQty = '';
    }
  }

  const handleBatchValue = (name: any, val: any) => {
    console.log(JSON.stringify(val, null, 2), 'valvalues');
    if (name === "barcode" && val != null) {
      setPackingDistribution({ ...packingDistribution, barcode: val.barcode })
    } else if (name === "batchId" && val != null) {
      setBatchNo(val.batchNo);
      setPackingDistribution({ ...packingDistribution, batchNo: val.batchNo, batchId: val.batchId, packingQty: val.batchQty, temppackingQty: val?.tempBatchQty, existingQty: val.batchQty })
    }
  }

  const handleWarehouse = (name: any, event: object, val: any) => {
    if (val != null) {
      setPackingDistribution({ ...packingDistribution, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    } else {
      packingDistribution.wareHouse = '';
    }
  }

  async function savePackingDistribution(e) {
    e.preventDefault();
    setLoading(true);
    const entryNos = await brandStore.getEntryNo('PackingDistribution');
    packingDistribution.packingDistributionId = entryNos.entryNo + 1;
    packingDistribution.packingDistributionDetails = packingOfDistributions
    const status = await packingDistributionStore.savePackingDistribution(packingDistribution);
    if (status === "Success") {
      setLoading(false)
      setSuccessModal(true)
    }
    else {
      setLoading(false)
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    // setSuccessModal(false)
    navigate('/Admin/PackingDistribution/PackingDistributionDetail');
  }

  const handleCancel = () => {
    navigate('/Admin/PackingDistribution/PackingDistributionDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    const [entryNo, loadPackingItems] = await Promise?.all([brandStore.getEntryNo('PackingDistribution'), loadPackingItem(), loadWarehouse(), loadTeamDetail(), itemConversionStore?.getItemConversion()])
    setPackingDistribution({ ...packingDistribution, packingDistributionId: (entryNo.entryNo + 1), });
    setloadPackingItems([...loadPackingItems])
  }


  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchPreLoadngData();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Packing_distribution';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/PackingDistribution/PackingDistributionDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  useEffect(() => {
    setloadDisplayItems(itemConversionStore?.getItemConversions);
    console.log(loadDisplayItems, 'loadDisplayItems');

  }, [itemConversionStore?.getItemConversions])

  useEffect(() => {
    console.log(loadDisplayItems, 'Updated loadDisplayItems');
  }, [loadDisplayItems]);


  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {


    return (
      <>
        {

          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                  <h3>Packing Distribution</h3>
                </div>

                <div className='inputBoxLists pd-b-0'>
                  <div className=' mr-left-0'>
                    <div className='row'>
                      {
                        isSpinner ?
                          <div className='SpinnerBox'>
                            <Spinner animation="grow" size="sm" />
                          </div> : null
                      }
                      <div className='col-md-2'>
                        <div className='inputBox'>
                          <label>Entry No <span>*</span></label>
                          <input type="text" className=''
                            name='packingDistributionId' disabled
                            value={packingDistribution.packingDistributionId = brandStore.entryNo.entryNo + 1}
                            style={{ width: "100%" }} placeholder='Entry No..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-3'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input type="date" style={{ width: "100%" }}
                            name='packingDistributionDate'
                            value={packingDistribution.packingDistributionDate = packingDistribution.packingDistributionDate ?? new Date().toISOString().substr(0, 10)}
                            onChange={handleChangeInput}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.packingDistributionDate && <p style={{ color: 'red' }}>{errors.packingDistributionDate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-6'></div>
                      <div className='col-sm-4'>
                        <div className='inputBox'>
                          <label>Warehouse <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadWarehouses}
                            // value={warehouseName}
                            getOptionLabel={(option: any) => option.warehouseName}
                            onChange={(event, val) => handleWarehouse('warehouseId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Warehouse..'
                                name='wareHouse'
                              />}
                          />
                          {errors.wareHouse && <p style={{ color: 'red' }}>{errors.wareHouse}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      {loadTeamDetails?.length > 0 && <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Team Name  <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadTeamDetails}
                            getOptionLabel={(option: any) => option.teamMasterName}
                            onChange={(event, val) => handleTeamValue('teamId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select TeamName..'
                                name='teamName'
                              />}
                          />
                          {errors.teamName && <p style={{ color: 'red' }}>{errors.teamName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>}
                      {/* loadPackingItems */}
                      {(loadDisplayItems?.length > 0 && packingDistribution?.warehouseId) &&
                        <div className='col-md-4'>
                          <div className='inputBox'>
                            <label>Item Name<span>*</span></label>
                            <AutoComplete placeholder='Select ItemName..'
                              // options={loadDisplayItems}
                              options={loadPackingItems?.filter((each) => each?.warehouseid === packingDistribution?.warehouseId)} clsName='full-width'
                              value={packingDistribution?.itemName}
                              emitOption={(option) => handlePackingValue('ItemId', option)}
                              getOptionLabel='ItemName' />
                            {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>}
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Warehouse Stock<span>*</span></label>
                          <input
                            value={packingDistribution.warehouseQty} name='warehouseQty'
                            className='fullInput' style={{ width: "100%" }} placeholder='warehouseQty..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label> UOM<span>*</span></label>
                          <input
                            value={packingDistribution.itemUOM} name='itemUOM'
                            className='fullInput' style={{ width: "100%" }} placeholder='itemUOM..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      {loadBatches?.length > 0 &&
                        <div className='col-md-4'>
                          <div className='inputBox'>
                            <label>Barcode<span>*</span></label>
                            <AutoComplete clsName='full-width' value={packingDistribution?.barcode} placeholder='Select Barcode..'
                              options={removeDuplicates(loadBatches, 'barcode')} emitOption={(option) => handleBatchValue('barcode', option)}
                              getOptionLabel='barcode' />
                            {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>}
                      {loadBatches?.length > 0 && <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>Batch No<span>*</span></label>
                          <AutoComplete clsName='full-width' value={packingDistribution?.batchNo} placeholder='Select batchNo..'
                            options={removeDuplicates(loadBatches, 'batchNo')?.filter((each) => each?.wid === packingDistribution?.warehouseId)} getOptionLabel='batchNo' emitOption={(option) => handleBatchValue('batchId', option)} />
                          {errors.batchNo && <p style={{ color: 'red' }}>{errors.batchNo}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>}
                      {packingDistribution.batchNo ?
                        <div className='col-md-3'>
                          <div className='inputBox'>
                            <label> Batch Qty<span>*</span></label>
                            <input type="text"
                              value={packingDistribution?.packingQty} name='packingQty' disabled
                              className='' style={{ width: "100%" }} ></input>
                          </div>
                          <div className='vertical-space-10'></div>
                        </div> : null}
                      <div className='col-md-3'>
                        <div className='inputBox'>
                          <label> Qty<span>*</span></label>
                          <input type="text"
                            value={packingDistribution?.assignQty} name='assignQty'
                            onChange={(e) => Number(e?.target?.value) >= 0 && handleChangeInput(e)}
                            className='' style={{ width: "100%" }} placeholder='Enter PackingQty..'></input>
                          {errors.assignQty && <p style={{ color: 'red' }}>{errors.assignQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={addPackingDistribution}>Add</button>
                      </div>
                      <div className='vertical-space-10'></div>

                      <div className='col-md-12'>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>

                                <th scope="col"> Item Name</th>
                                <th scope="col"> UOM </th>
                                <th scope="col"> Batch Qty </th>
                                <th scope="col"> Ass Qty </th>
                                <th scope="col"> Batch No </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                packingOfDistributions?.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.packingDistributionitemName}</td>
                                      <td>{val.packingDistributionitemUOM}</td>
                                      <td>{val.packingDistributionpackingQty}</td>
                                      <td>{(val.packingDistributionpackingAssignQty)?.toFixed(3)}</td>
                                      <td>{val.packingDistributionbatchNo}</td>
                                      {/* <td><QRCode value={val.packingDistributionbatchNo} size={100} /></td> */}
                                      <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                                        onClick={() => handleRemove(key)}>Remove</button></td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>

                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={savePackingDistribution}>Submit</button>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>

              </div>
            </div>
          </>

        }
      </>
    );
  }
});

export default PackingDistribution;
