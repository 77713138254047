import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IUnitOfMeasurement } from './model';
import { useNavigate, useParams } from 'react-router-dom';
import useStores from '../../hooks';
import unitofmeasureValidation from './validation';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import Cookies from 'js-cookie';

const UnitOfMeasure = observer((): JSX.Element => {
  const [unitOfMeasurement, setUnitOfMeasurement] = useState<IUnitOfMeasurement>({});
  const {supplierMasterStore, unitOfMeasurementStore, brandStore } = useStores();
  const [errors, setErrors] = useState<any>({});
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 
  const { unitId } = useParams();

  async function saveUnitOfMeasurement(e) {
    e.preventDefault();
    let error: any = {};
    error = unitofmeasureValidation(unitOfMeasurement);
    setErrors(error);

    if (Object.keys(error).length === 0) {
      setLoading(true);

      unitOfMeasurement.unitId = Number(unitOfMeasurement.unitId);
      let status: string = '';
      if (unitId !== undefined) {
        status = await unitOfMeasurementStore.updateUnitOfMeasurement(unitOfMeasurement);

        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
      else {
        status = await unitOfMeasurementStore.saveUnitOfMeasurement(unitOfMeasurement);
        if (status === "Success") {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setLoading(false);
          setFailureModal(true);
        }
      }
    }
  };

  const handleCancel = () => {
    navigate('/Admin/UnitOfMeasurements/list');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setUnitOfMeasurement({ ...unitOfMeasurement, [name]: value });
  };

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/Admin/UnitOfMeasurements/list');
  }

  async function mapItemData(UnitDels) {
    unitOfMeasurement.unitId = UnitDels.unitId
    unitOfMeasurement.unitName = UnitDels.unitName;
    unitOfMeasurement.unitType = UnitDels.unitType;
    unitOfMeasurement.unitdescription = UnitDels.unitdescription;
    setUnitOfMeasurement(unitOfMeasurement);
  }

  async function fetchPreLoadingData() {
    setLoading(false)
    if (unitId !== undefined) {
      await unitOfMeasurementStore.UnitDetails(parseInt(unitId!));
      mapItemData(unitOfMeasurementStore.UnitDetls);
    } else {
      const data = await brandStore.getEntryNo('UnitOfMeasurement')
      if (data !== undefined) {
        unitOfMeasurement.unitId = data.entryNo + 1;
        setUnitOfMeasurement(unitOfMeasurement);
      }
    }
  }

  const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchPreLoadingData()
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Unit_of_measurements';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/UnitOfMeasurements/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

  return (
    <>
     
       
         
            <div className='vertical-space-20'></div>
            <form onSubmit={saveUnitOfMeasurement}>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Unit Of Measure </h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='inputBox'>
                    <label>ID<span>*</span></label>
                    <input type="text"
                      disabled
                      name="unitId"
                      value={unitOfMeasurement.unitId}
                      onChange={handleInputchnage}
                      className='' style={{ width: "50%" }} placeholder='Enter ID..' ></input>

                  </div>
                  <div className='inputBox'>
                    <label>UOM<span>*</span></label>
                    <input type="text" className=''
                      onChange={handleInputchnage}
                      name="unitName"
                      value={unitOfMeasurement.unitName}
                      style={{ width: "80%" }} placeholder='Enter UOM..'></input>
                    {errors.unitName && <p style={{ color: 'red' }}>{errors.unitName}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                  <div className='inputBox'>
                    <label>Description</label>
                    <textarea className=''
                      onChange={handleInputchnage}
                      name="unitdescription"
                      value={unitOfMeasurement.unitdescription = unitOfMeasurement.unitdescription ?? ""}
                      style={{ width: "100%" }} placeholder='Enter Description..'></textarea>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit'>Submit</button>
                </div>
              </div>
            </form>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
    
  

  );
  }
});

export default UnitOfMeasure;
