function assignBatchPackingValidation(assignBatchPacking){
    let errors :any={};

    if(!assignBatchPacking.packingAssignBatchdate){
      
        errors.packingAssignBatchdate = "Enter Assign Batch Packing Date";
    }
    if(!assignBatchPacking.createdBy){
      
        errors.createdBy = "Enter Employee Name ";
    }
    if(!assignBatchPacking.teamName){
      
        errors.teamName = "Enter Team Name ";
    }   
    if(!assignBatchPacking.packingInwardid){
      
        errors.packingInwardid = "Enter Packing Inwardid  ";
    }
    if(!assignBatchPacking.mfg_Date){
      
        errors.mfg_Date = "Enter Mfg Date   ";
    }
    if(!assignBatchPacking.expiry_date){
      
        errors.expiry_date = "Enter Expiry Date";
    }
    
    return errors;
};
export default assignBatchPackingValidation
