import { action, set, observable, runInAction } from "mobx";
import apiAgent from "../../../APIAgent";
import { IQuoteSupplier } from "../model";

class QuoteFromSupplierStore {
    @observable outletItemDetails = new Map();
    isLoading = true;
    entryNo = new Map();
    @observable quoteFromSupplierList = new Map();
    indentLists = new Map();
    quoteFromSupplierListDetail = new Map();
    quoteFromSupplierItemList = new Map();
    loadQuoteFromSupplierList = new Map();
    loadSuppliers = new Map();

    saveQuoteFromSupplier = async (quoteFromSupplier: IQuoteSupplier) => {
        try {
            const status = await apiAgent.quoteSupplier.saveQuoteSupplier(quoteFromSupplier);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    getquoteFromSupplier = async () => {

        try {
            const getquoteFromSupplier = await apiAgent.quoteSupplier.getquoteFromSupplier();

            runInAction(() => {

                this.quoteFromSupplierList = getquoteFromSupplier;
            });
            return JSON.parse(getquoteFromSupplier)
        }
        catch (error) {
            console.log(error);
        }
    }
    getquoteFromSupplierDetails = async (indent_id) => {

        try {
            const getquote = await apiAgent.quoteSupplier.getquoteFromSupplierDetails(indent_id);

            runInAction(() => {

                this.quoteFromSupplierListDetail = getquote;
            });
            return getquote
        }
        catch (error) {
            console.log(error);
        }
    }
    getquoteFromSupplierItemDetails = async (itemid, indent_id) => {

        try {
            const getquote = await apiAgent.quoteSupplier.getquoteFromSupplierItemDetails(itemid, indent_id);

            runInAction(() => {

                this.quoteFromSupplierItemList = getquote;
            });
            return getquote
        }
        catch (error) {
            console.log(error);
        }
    }
    loadQuoteFromSupplierDetails = async () => {

        try {
            const getquoteFromSupplier = await apiAgent.quoteSupplier.loadQuoteFromSupplierDetails();

            runInAction(() => {

                this.loadQuoteFromSupplierList = getquoteFromSupplier;
            });
            return JSON.parse(getquoteFromSupplier)
        }
        catch (error) {
            console.log(error);
        }
    }
    loadSupplierDetails = async (supplierId) => {

        try {
            const loadSuppliers = await apiAgent.quoteSupplier.loadSupplierDetails(supplierId);

            runInAction(() => {

                this.loadSuppliers = loadSuppliers;
            });
            return this.loadSuppliers;
        }
        catch (error) {
            console.log(error);
        }
    }
    getQuoteSearch = async (enteredBy) => {
        this.loadQuoteFromSupplierList = new Map();
        try {
            const getquoteFromSupplier = await apiAgent.quoteSupplier.getQuoteSearch(enteredBy);
            runInAction(() => {
                this.loadQuoteFromSupplierList = getquoteFromSupplier;
            });
            return getquoteFromSupplier;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default QuoteFromSupplierStore;