import apiAgent from "../../../APIAgent"

class ItemwiseMarginStore{
    loadItemwiseMargin = async (itemwiseMargin) => {
        try {
            const itemwiseMarginList = await apiAgent.itemwiseMarginApi.getItemwiseMarginList(itemwiseMargin);

            return itemwiseMarginList
        } catch (error) {
            console.log(error)
        }
    }
}

export default ItemwiseMarginStore;