import { action, set, makeObservable, observable, runInAction } from 'mobx';
import offerDiscountDetails, {  IOfferDisount } from '../model/index';
import apiAgent from '../../../APIAgent';

class OfferDiscountStore {
     offerDiscountDetls =offerDiscountDetails;
    @observable offerDiscountList= new Map();
    isLoading=true;
    @observable offerDiscountLists = new Map<any, any>();  
    offerDiscountDetail = new Map(); 
    constructor(){
        makeObservable(this);
    }

     saveOfferDiscounts = async (offerDiscount: IOfferDisount) => {
        try {
           const status=  await apiAgent.offerDiscount.saveOfferDiscounts(offerDiscount);
           
           if(status!==undefined){
            return status;           
           }
          
        }
        catch (error) {
            console.log(error);
        }
    }
    editOfferDiscounts = async (offerDiscount) => {
        try {
            const status = await apiAgent.offerDiscount.editOfferDiscounts(offerDiscount);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getOfferDiscounts = async () => {
        try {
            const offerDiscountData = await apiAgent.offerDiscount.getOfferDiscounts();
            
            runInAction(() => {
                this.offerDiscountList = offerDiscountData; 
              
            });
            
            return offerDiscountData; 
        } catch (error) {
            console.log(error);
        }
    }
        
     getOfferDiscountsDetails = async (offerId) =>{
        try{
            const offerData = await apiAgent.offerDiscount.getOfferDiscountsDetails(offerId);
            runInAction(()=>{
                
                this.offerDiscountDetail = offerData;
            })
            return offerData;
        }
        catch(error){
            console.log(error);
        }
    }
    getOfferDiscountSearch  = async (SearchOptions)=> {
        try {
          const OfferDiscountData=await apiAgent.offerDiscount.getOfferDiscountSearch(SearchOptions);
            this.offerDiscountList = OfferDiscountData;
            
            runInAction(() => {
                this.offerDiscountList = OfferDiscountData;
                
            })
            return this.offerDiscountList;       
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOfferDetail = async (offerDetailid) => {
        try {      
        const status=  await apiAgent.offerDiscount.deleteOfferDetail(offerDetailid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOfferMaster = async (offerId) => {
        try {      
        const status=  await apiAgent.offerDiscount.deleteOfferMaster(offerId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    @action setOfferDiscount = (state:IOfferDisount) =>{
        this.offerDiscountList.set(0,state);
    }
    @action setOfferDiscountList = (state: IOfferDisount[]) =>{
        set(state,this.offerDiscountList);
        return this.offerDiscountList;
    }
}
export default OfferDiscountStore;