import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import { Modal, Image, Button, Spinner, Form } from 'react-bootstrap';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { ILedgerMaster } from '../LedgerMaster/model';
import ledgerMasterValidation from '../LedgerMaster/validation';
import { ICreditAmount, IDebitAmount, ILedgerRequest, IReceipt } from './model';
import { receiptCreditValidation, receiptDebitValidation, receiptValidation } from './validation';
import Cookies from 'js-cookie';
import { stubString } from 'lodash';
import _ from 'lodash';
import AutoComplete from '../../common/shared/autoComplete';

const ReceiptEntry = observer((): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const { supplierMasterStore, itemMasterStore, ledgerGroupStore, commonStore, ledgerMasterStore, receiptStore } = useStores();
  const [isLedgerMaster, setLedgerMaster] = useState(false);
  const [ledgerMasters, setLedgerMasters] = useState<ILedgerMaster>({});
  const [receipt, setReceipt] = useState<IReceipt>({});
  const [creditAmount, setCreditAmount] = useState<ICreditAmount[]>([]);
  const [debitAmount, setDebitAmount] = useState<IDebitAmount[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [isCredit, setCredit] = useState(false);
  const [isDebit, setDebit] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setReceipt({ ...receipt, [name]: value });
  }
  const handleCancel = () => {
    navigate("/Admin/ReceiptEntry/ReceiptEntryDetail")
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/ReceiptEntry/ReceiptEntryDetail');
  }
  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const handleLegerMaster = (e) => {

    const { name, value } = e.target;
    setLedgerMasters({ ...ledgerMasters, [name]: value })
  }
  const handleReceiptLedgerGroup = (name, event, val) => {
    if (val != null) {
      setLedgerMasters({ ...ledgerMasters, ledgerGroupId: val.groupId, ledgerGroup: val.ledgerGroupName });
    }

  }

  const handleAddDebit = (e) => {

    e.preventDefault();
    let error: any = {};
    error = receiptDebitValidation(receipt);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      let debit: IDebitAmount = {};
      debit.debitAccountId = receipt.debitAccountId;
      debit.debitAccountName = receipt.debitAccountName;
      debit.debitAmount = Number(receipt.debitAmount);
      debit.debitNarration = receipt.debitNarration;
      setDebitAmount([...debitAmount, debit]);
      setReceipt({ ...receipt, debitAccountId: undefined, debitAccountName: '', debitAmount: '', debitNarration: '' });
      setDebit(true);
    }
  }
  const addCredit = (e) => {
    e.preventDefault();
    let error: any = {};
    error = receiptCreditValidation(receipt);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      let credit: ICreditAmount = {};
      credit.toAccountName = receipt.toAccountName;
      credit.creditAccountId = receipt.toAccountId;
      credit.chequeNo = receipt.chequeNo;
      credit.creditAmount = Number(receipt.creditAmount);
      credit.creditBankName = receipt.creditBankName;
      credit.creditDate = receipt.creditDate;
      credit.creditNarration = receipt.creditNarration;
      setCreditAmount([...creditAmount, credit]);
      setReceipt({
        ...receipt,
        toAccountId: undefined, toAccountName: '', chequeNo: '',
        creditAmount: 0, creditBankName: '', creditNarration: '',
        debitAmount: '', debitNarration: ''
      });

      setCredit(true);
    }
  }

  function handleRemove(index, type) {
    if (type === 'debit') {
      debitAmount?.splice(index, 1)
      setDebitAmount(_.cloneDeep(debitAmount))
    } else {
      creditAmount?.splice(index, 1)
      setCreditAmount(_.cloneDeep(creditAmount))
    }
  }

  const handleSaveReceipt = async (e) => {

    let error: any = {};
    e.preventDefault();
    error = receiptValidation(receipt);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      let debitAmounts = Number(debitAmount.reduce((a, v) => a = a + Number(v.debitAmount!), 0).toFixed(2));
      let creditAmounts = Number(creditAmount.reduce((a, v) => a = a + Number(v.creditAmount!), 0).toFixed(2));
      if (debitAmounts !== creditAmounts) {
        error.amountMismatch = 'Debit and Credit amount does not match';
        setErrors(error);
      }
      else {
        receipt.receiptDebits = debitAmount;
        receipt.receiptCredits = creditAmount;
        receipt.employeeId = Number(Cookies.get('employeeId'))
        setLoading(true);
        const status = await receiptStore.saveReceipt(receipt);
        if (status === 'Success') {
          setLoading(false);
          setSuccessModal(true);
        }
        else {
          setReceipt({});
          setLoading(false);
          setFailureModal(true);
        }
      }
    }

  }

  const handleLedgerMaster = async () => {
    setLoading(true);
    await commonStore.loadLedgerGroup();
    await ledgerGroupStore.getEntryNo('LedgerAccount');
    setLoading(false);
    setLedgerMaster(true);

  }

  const handleSaveLedgerMaster = async () => {
    setLedgerMaster(false);

    let error: any = {};
    error = ledgerMasterValidation(ledgerMasters);
    setErrors(error)
    if (Object.keys(error).length === 0) {
      setLoading(true);
      ledgerMasters.openingBalance = Number(ledgerMasters.openingBalance);
      ledgerMasters.percentage = Number(ledgerMasters.percentage);
      const ledgerStatus = await ledgerMasterStore.saveLedgerMaster(ledgerMasters);
      if (ledgerStatus === "Success") {
        await itemMasterStore.getTaxMaster('');
        setLoading(false);
        setLedgerMaster(false);
        //setSuccessModal(true);
      }
      else {
        setLoading(false);
        setLedgerMaster(true);
        //setFailureModal(true);
      }
    }
  }
  const handleAccount = (name, val) => {
    setReceipt({ ...receipt, toAccountId: val.ledgerAccountId, toAccountName: val.ledgerAccountName })
  }
  const handleByAccount = (name, val) => {
    setReceipt({ ...receipt, debitAccountId: val.ledgerAccountId, debitAccountName: val.ledgerAccountName })
  }
  const closeModalShow = () => {
    setLedgerMaster(false);
  }
  async function fetchPreLoadingData() {
    let ledgerRequest: ILedgerRequest = {}
    await ledgerGroupStore.getEntryNo('Receipt');
    ledgerRequest.flag = 'TO';
    ledgerRequest.type = 'Receipt'
    await receiptStore.getToLedgers(ledgerRequest);
    ledgerRequest.flag = 'BY';
    ledgerRequest.type = 'Receipt'
    await receiptStore.getByLedgers(ledgerRequest);

    setLoading(false);
  }

  // const isCurrentPage = useRef(true)
  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchPreLoadingData();
  //     isCurrentPage.current = false
  //   }
  //   return () => { }
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Receipt_entry';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/ReceiptEntry/ReceiptEntryDetail');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {


    return (
      <div className='container'>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField'>
          <div className='hrBox'>
            <h3>Receipt Entry</h3>
          </div>
          <div className='inputBoxLists pd-b-0'>
            {/* <div className='LgInputListsBox mr-left-0'> */}
            <div className='row'>
              {
                isLoading ?
                  <div className='SpinnerBox'>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div> : null
              }
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Receipt No</label>
                  <input type="text" className='' name='receiptId' disabled value={receipt.receiptId = ledgerGroupStore.entryNo.entryNo === 0 ? 1 : ledgerGroupStore.entryNo.entryNo + 1} style={{ width: "100%" }} placeholder='Receipt No..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-4'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                    onChange={handleInputChange}
                    value={receipt.receiptDate = receipt.receiptDate ?? new Date().toISOString().substr(0, 10)} name='receiptDate'
                    data-date="" data-date-format="DD MMMM YYYY"></input>
                  {errors.receiptDate && <p style={{ color: 'red' }}>{errors.receiptDate}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Reference No<span>*</span> </label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.referenceNo} name='referenceNo'
                    placeholder='Reference No..'></input>
                  {errors.referenceNo && <p style={{ color: 'red' }}>{errors.referenceNo}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'></div>
              <div className='col-md-9'>
                <div className='inputBox'>
                  <label>To Account <span>*</span></label>
                  <AutoComplete value={receipt?.toAccountName}
                    getOptionLabel='ledgerAccountName' placeholder='Select Account..'
                    emitOption={(option) => handleAccount('toAccountId', option)} options={receiptStore?.toLedgers} />
                  {errors.toAccountId && <p style={{ color: 'red' }}>{errors.toAccountId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2'>
                <button className='newdfBtn' onClick={handleLedgerMaster}>New</button>
              </div>
              <div className='vertical-space-60'></div>
              <div className='col-md-7'>
                <div className='inputBox'>
                  <label>Cr Amount(<span>&#8377;</span>) <span>*</span></label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.creditAmount} name='creditAmount'
                    placeholder='Cr Amount..'></input>
                  {errors.creditAmount && <p style={{ color: 'red' }}>{errors.creditAmount}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-5'></div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Cheque No </label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.chequeNo} name='chequeNo'
                    placeholder='Cheque No..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>

              <div className='col-md-4'>
                <div className='inputBox'>
                  <label>Date </label>
                  <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                    onChange={handleInputChange}
                    value={receipt.creditDate = receipt.creditDate ?? new Date().toISOString().substr(0, 10)} name='creditDate'
                    data-date="" data-date-format="DD MMMM YYYY"></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Bank Name</label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.creditBankName} name='creditBankName'
                    placeholder='Bank Name..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-9'>
                <div className='inputBox'>
                  <label>Narration</label>
                  <textarea className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.creditNarration} name='creditNarration'
                    placeholder='Narration..'
                  ></textarea>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2'>
                <button className='dfBtn' onClick={addCredit}>Add</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Cr Account</th>
                        <th scope="col">Cr Amount</th>
                        <th scope="col">Cheque No </th>
                        <th scope="col">Date</th>
                        <th scope="col">Bank Name</th>
                        <th scope="col">Narration</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        creditAmount.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.toAccountName}</td>
                              <td>{val.creditAmount}</td>
                              <td>{val.chequeNo}</td>
                              <td>{val.creditDate}</td>
                              <td>{val.creditBankName}</td>
                              <td>{val.creditNarration}</td>
                              <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                                onClick={() => handleRemove(key, 'credit')}>Remove</button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-60'></div>
              </div>
              <div className='col-md-9'>
                <div className='inputBox'>
                  <label>By Account<span>*</span></label>
                  <AutoComplete value={receipt?.debitAccountName}
                    getOptionLabel='ledgerAccountName' placeholder='Select Account..'
                    emitOption={(option) => handleByAccount('debitAccountId', option)} options={receiptStore?.byLedgers} />
                  {errors.debitAccountId && <p style={{ color: 'red' }}>{errors.debitAccountId}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2'>
                <button className='newdfBtn' onClick={handleLedgerMaster}>New</button>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Dr Amount(<span>&#8377;</span>)<span>*</span></label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputChange}
                    value={receipt.debitAmount} name='debitAmount'
                    placeholder='Dr Amount..'></input>
                  {errors.debitAmount && <p style={{ color: 'red' }}>{errors.debitAmount}</p>}
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-9'>
                <div className='inputBox'>
                  <label>Narration</label>
                  <textarea className='' style={{ width: "100%" }} placeholder='Narration..'
                    onChange={handleInputChange}
                    value={receipt.debitNarration} name='debitNarration'
                  ></textarea>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-2'>
                <button className='dfBtn' onClick={handleAddDebit}>Add</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">By Account</th>
                        <th scope="col">Dr Amount </th>
                        <th scope="col">Narration</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {debitAmount.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.debitAccountName}</td>
                            <td>{val.debitAmount}</td>
                            <td>{val.debitNarration}</td>
                            <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }} type='submit'
                              onClick={() => handleRemove(key, 'debit')}>Remove</button></td>
                          </tr>
                        )
                      })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
            {/* </div> */}
            <div className='vertical-space-10'></div>
          </div>
          {errors.amountMismatch && <p style={{ color: 'rd' }}>{errors.amountMismatch}</p>}
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            {
              isCredit && isDebit ?
                <button className='dfBtn' type='submit' onClick={handleSaveReceipt}>Submit</button> : null
            }

          </div>
        </div>
        {
          isLedgerMaster ? <Modal
            show={isLedgerMaster} onHide={closeModalShow}
            className=''>
            <Modal.Header closeButton>
              <Modal.Title>Add Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='col-sm-12'>
                <div className=' inputFormBox'>
                  <div className='inputBoxLists' >
                    <div className='row'>
                      <div className='col-md-8'>
                        <div className='inputBox'>
                          <div className='col-md-8'>
                            <div className='inputBox'>
                              <label>Ledger ID </label>
                              <input type="text" className='' style={{ width: "100%" }} name='ledgerId'
                                value={ledgerMasters.ledgerId = ledgerGroupStore.entryNo.entryNo + 1} disabled
                                placeholder='Ledger ID..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-12'>
                            <div className='inputBox'>
                              <label>Ledger Name <span>*</span></label>
                              <input type="text" className=''
                                name="ledgerName"
                                onChange={handleLegerMaster}
                                value={ledgerMasters.ledgerName}
                                style={{ width: "100%" }} placeholder='Ledger Name..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>

                          <div className='col-md-12'>
                            <div className='inputBox'>
                              <label>Ledger Group <span>*</span></label>
                              <Autocomplete size="small"
                                disablePortal
                                id="combo-box-demo"
                                options={commonStore.loadLedgerGroups}
                                getOptionLabel={(option: any) => option.ledgerGroupName}
                                onChange={(event, val) => handleReceiptLedgerGroup('ledgerGroupId', event, val)}
                                renderInput={(params: any) =>
                                  <TextField  {...params} style={{ width: '36ch' }}
                                    id="outlined-size-small"
                                    color='info'
                                    size="small"
                                    type="text"
                                    placeholder='Select Ledger..'
                                    name='ledgerGroupId'
                                  />}
                              />
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label> Opening Balance <span>*</span></label>
                              <input type="text" className=''
                                name="openingBalance"
                                onChange={handleLegerMaster}
                                value={ledgerMasters.openingBalance}
                                style={{ width: "100%" }} placeholder='Opening Balance..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Opening type <span>*</span></label>
                              <select className="form-selected"
                                name="type"
                                onChange={handleLegerMaster}
                                value={ledgerMasters.type}
                                style={{ width: "100%" }} id="Openingtype" >
                                <option value="" selected>Select</option>
                                <option value="Credit"> Credit</option>
                                <option value="Debit">Debit </option>
                              </select>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Percentage <span>*</span></label>
                              <input type="text"
                                name="percentage"
                                onChange={handleLegerMaster}
                                value={ledgerMasters.percentage}
                                style={{ width: "100%" }} placeholder="Ledger Percentage.." ></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='col-md-6'>
                            <div className='inputBox'>
                              <label>Date <span>*</span></label>
                              <input type="date"
                                name="entryDate"
                                onChange={handleLegerMaster}
                                value={ledgerMasters.entryDate = ledgerMasters.entryDate ?? new Date().toISOString().substr(0, 10)}
                                style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                          </div>
                          <div className='vertical-space-30'></div>
                          <div className='col-sm-6 carddetailbtn2'>
                            <button className='usethisbtn btn-warning' onClick={handleSaveLedgerMaster}>Submit </button>
                            <button className='secondaryBtn' onClick={closeModalShow}>Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-6'>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal> : null
        }
        <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../../image/checked.png')} />
              </div>

              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>

              <div className='imgBox'>
                <Image src={require('../../../../image/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

});

export default ReceiptEntry;
