import { action, set, makeObservable, observable, runInAction } from 'mobx';
import MemberLoginDetails, {  ILogin, IMemberLogin, IVerifyOTP } from '../model/index';
import apiAgent from '../../EcomAPI';


class EcomMemberStore {
    memberDetls = MemberLoginDetails;
    @observable memberList= new Map();
    isLoading=true;
    @observable memberLists = new Map<any, any>();
     entryNo = new Map();
     memberResponse=new Map();
     memberId=new Map();
    constructor() {
        makeObservable(this)
    }

    saveEcomMember = async (memberLogin: IMemberLogin) => {
        try {
            
          const status =  await apiAgent.memberLogin.saveEcomMember(memberLogin);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    verifyOTP= async (verifyOTP: IVerifyOTP) => {
        try {
            
          const status =  await apiAgent.memberLogin.verifyOTP(verifyOTP);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    UpdateEmail= async (verifyOTP: IVerifyOTP) => {
        try {
            
          const status =  await apiAgent.memberLogin.UpdateEmail(verifyOTP);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLogin=async (login: ILogin) => {
        try {
            
          const status =  await apiAgent.memberLogin.getLogin(login);
          sessionStorage.setItem("memberName", status.memberName);
          sessionStorage.setItem("memberId", status.memberId);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    checkEmailExist=async (emailAddress) => {
        try {
            
          const status =  await apiAgent.memberLogin.checkEmailExist(emailAddress);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default EcomMemberStore