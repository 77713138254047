

function assignProductValidation(assignProduct){
    
    let errors :any={};
    
      if(!assignProduct.mPartItemName){
        errors.mPartItemName = "Select Main PartName";
      }
      if(!assignProduct.gPartItemName){
        errors.gPartItemName = "Select Group PartName";
      }
      if(!assignProduct.webName){
        errors.webName = "Enter Web Name";
      }
    return errors;
  };
    
    export default assignProductValidation;