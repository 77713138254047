import { action, set, makeAutoObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class PurchaseBillRegStore {

    constructor() {
        makeAutoObservable(this);
    }

    getPurchaseBillRegDetails = async () => {
        try {
            const data = await apiAgent.purchaseBillReg.getPurchaseBillRegDetails();
            return data;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default PurchaseBillRegStore;