import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import moment from "moment";
import { Modal, Image, Button, Spinner } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { IIKeySkills, IILanguage, IIResumeBank, IIResumeCandidate, IIResumeContactDet, IIWorkExp } from "./model";
import { Country, State, City } from 'country-state-city';
import { addKeySill, addLangKnown, addWorkExp, saveValidation } from "./validate";

const ResumeBank = observer((): JSX.Element => {

    const navigate = useNavigate();

    const { resumeBankStore, jobMasterStore, employeeStore } = useStores()

    const gender = [{ key: 'male', value: 'Male' }, { key: 'female', value: 'Female' }, { key: 'others', value: 'Others' }]
    const martialStatus = [{ key: 'maried', value: 'Maried' }, { key: 'un-maried', value: 'Un Maried' }, { key: 'widow', value: 'Widow' }]
    const language = [{ key: 'tamil', value: 'Tamil' }, { key: 'english', value: 'English' }, { key: 'hindi', value: 'Hindi' }, { key: 'malayalam', value: 'Malayalam' }, { key: 'telugu', value: 'Telugu' }, { key: 'kanada', value: 'Kanada' }]

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [cityOptions, setCityOptions] = useState([]);

    const [resumeBankMaster, setResumeBankMaster] = useState<IIResumeBank>(new IIResumeBank())
    const [candidate, setCandidate] = useState<IIResumeCandidate>(new IIResumeCandidate())
    const [contactDet, setContactDet] = useState<IIResumeContactDet>(new IIResumeContactDet())

    const [experienceDetail, setExperienceDetail] = useState<IIWorkExp>(new IIWorkExp())
    const [workExperience, setWorkExperience] = useState<IIWorkExp[]>(new Array<IIWorkExp>())

    const [skillDetails, setSkillDetails] = useState<IIKeySkills>(new IIKeySkills())
    const [keySkills, setKeySkills] = useState<IIKeySkills[]>(new Array<IIKeySkills>())

    const [langKnown, setLangKnow] = useState<IILanguage>(new IILanguage())
    const [lngKnownList, setLngKnownList] = useState<IILanguage[]>(new Array<IILanguage>())

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    console.log(employeeMaster, 'employeeMaster>>')
    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);


    useEffect(() => {
        let getCities: any;
        getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
        setCityOptions(getCities);
    }, [])

    const getStateCountry = (e: any, value: any) => {
        if (value != null) {
            const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
            contactDet.country = countries[0].name
            const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
            contactDet.state = states[0].name;
            contactDet.city = value.name;
            setContactDet({ ...contactDet, country: countries[0]?.name, state: states[0]?.name, city: value?.name })
        } else {
            contactDet.country = '';
            contactDet.state = '';
            setContactDet({ ...contactDet, country: '', state: '', city: '' })
        }
    }

    function handleAddExperience(index?: number) {
        if (index! >= 0) {
            setWorkExperience(prevArr => prevArr?.splice(index!, 1))
        } else {
            let error: any = {};
            error = addWorkExp(experienceDetail);
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                workExperience?.push(experienceDetail);
                setWorkExperience([...workExperience])
                setExperienceDetail(new IIWorkExp())
            }
        }
    }

    function handleKeySkills(index?: number) {
        if (index! >= 0) {
            setKeySkills(prevArr => prevArr?.splice(index!, 1))
        } else {
            let error: any = {};
            error = addKeySill(skillDetails);
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                keySkills?.push(skillDetails);
                setKeySkills([...keySkills])
                setSkillDetails(new IIKeySkills())
            }
        }
    }

    function handleAddLang(index?: number) {
        if (index! >= 0) {
            setLngKnownList(prevArr => prevArr?.splice(index!, 1))
        } else {
            let error: any = {};
            error = addLangKnown(langKnown);
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                lngKnownList?.push(langKnown);
                setLngKnownList([...lngKnownList])
                setLangKnow(new IILanguage())
            }
        }
    }

    function updateMaster() {
        if (!resumeBankMaster?.candidate?.length) {
            resumeBankMaster.candidate = []
            resumeBankMaster?.candidate?.push(candidate);
        }
        if (!resumeBankMaster?.contact_Details?.length) {
            resumeBankMaster.contact_Details = []
            resumeBankMaster?.contact_Details?.push(contactDet);
        }
        if (!resumeBankMaster?.keyskills_Details?.length) {
            resumeBankMaster.keyskills_Details = []
        }
        resumeBankMaster.keyskills_Details = keySkills;
        if (!resumeBankMaster?.language?.length) {
            resumeBankMaster.language = []
        }
        resumeBankMaster.language = lngKnownList;
        if (!resumeBankMaster?.workexperience?.length) {
            resumeBankMaster.workexperience = []
        }
        resumeBankMaster.workexperience = workExperience;
    }


    const [resumeImg, setResumeImg] = useState();
    const [fileName, setResumeFile] = useState();

    const handleChangeImage = (e) => {
        resumeBankMaster.resumepath = '';
        e.preventDefault();
        setResumeImg(e.target.files[0]);
        setResumeFile(e.target.files[0].name);
    }

    const [photoImg, setPhotoImg] = useState();
    const [photoName, setPhotoFile] = useState();

    console.log(fileName, 'fileName>>', photoName, 'photoName>>')
    const handlePhotoChnage = (e) => {
        resumeBankMaster.resumepath = '';
        e.preventDefault();
        setPhotoImg(e.target.files[0]);
        setPhotoFile(e.target.files[0].name);
    }

    // empphotopath

    async function saveEmployee(e) {
        let error: any = {}
        error = saveValidation(resumeBankMaster, candidate, contactDet)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            updateMaster()
            setLoading(true)
            const entryNo = await jobMasterStore.getHREntryNo('Resumebank');
            resumeBankMaster.resumBankid = Number(entryNo?.entrynumber);
            resumeBankMaster.resumcode = entryNo?.entrynumber;
            resumeBankMaster.resumepath = fileName ?? "";
            resumeBankMaster.empphotopath = photoName ?? "";
            let status = await resumeBankStore.saveResumeBank(resumeBankMaster);
            if (status === 'Success') {
                status = await resumeBankStore.saveResumeImage(resumeBankMaster?.resumBankid, resumeImg, photoImg)
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    const handleCancel = () => {
        navigate("/Admin/ResumeBank/ResumeBankList");
    };
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        // navigate("/Admin/ResumeBank/ResumeBankList");
        setSuccessModal(false)
    };

    async function initialApiCall() {
        const entryNo = await jobMasterStore.getHREntryNo('Resumebank');
        setResumeBankMaster({
            ...resumeBankMaster,
            resumBankid: Number(entryNo?.entrynumber),
            resumcode: entryNo?.entrynumber
        })
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);
    return (
        <>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div
                        className="outletInputField inputFormBox"
                        style={{ width: "50%" }}
                    >
                        <div className="hrBox">
                            <h3>Resume Bank</h3>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Resume Code</label>
                                        <input type="text" className=""
                                            value={resumeBankMaster?.resumcode}
                                            disabled style={{ width: "100%" }}
                                        ></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className='col-sm-4'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Date <span>*</span></label>
                                        <input type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date="" data-date-format="DD MMMM YYYY"
                                            name="fromdate"
                                            value={new Date().toISOString().substr(0, 10) ?? new Date()}
                                        ></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label>Job Code <span>*</span></label>
                                        <select name="unittype" style={{ fontSize: "16px" }}
                                            className="form-selected full-width"
                                            value={resumeBankMaster?.jobid}
                                            onChange={(e) => setResumeBankMaster({ ...resumeBankMaster, jobid: Number(e.target.value) })}
                                        >
                                            <option value="" selected>- - Select - -</option>
                                            {jobMaster?.map((obj) => {
                                                return (
                                                    <option value={obj?.Jobcode}>{obj?.Jobcode}</option>
                                                )
                                            })}
                                        </select>
                                        {errors?.jobid && <p style={{ color: 'red' }}>{errors?.jobid}</p>}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Title</label>
                                        <input type="text" name='categoryName' style={{ width: '100%' }}
                                            value={jobMaster?.find((each) => each?.Jobcode == resumeBankMaster?.jobid)?.Jobtitle}
                                        ></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Job Description <span>*</span></label>
                                        <textarea style={{ width: '100%' }}
                                            value={jobMaster?.find((each) => each?.Jobcode == resumeBankMaster?.jobid)?.JobDescription}
                                        ></textarea>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                        <div className="inputBoxLists accordionLists">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Candidate Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className='col-sm-12'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Candidate Name <span>*</span></label>
                                                    <input type="text" className="" style={{ width: "100%" }}
                                                        value={candidate?.candidatename}
                                                        onChange={(e) => setCandidate({ ...candidate, candidatename: e.target.value })}
                                                        placeholder="Enter Candidate Name"
                                                    ></input>
                                                </div>
                                                {errors.candidatename && (<p style={{ color: "red" }}>{errors.candidatename}</p>)}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className='inputBox'>
                                                    <label style={{ fontSize: '16px' }}>DOB <span>*</span></label>
                                                    <input type="date"
                                                        className="inputBoxDate"
                                                        style={{ width: "100%", fontWeight: "lighter" }}
                                                        placeholder="DD/MMM/YYYY"
                                                        data-date=""
                                                        data-date-format="DD MMMM YYYY"
                                                        value={candidate?.dateofbirth}
                                                        onChange={(e) => setCandidate({ ...candidate, dateofbirth: e.target.value })}
                                                    ></input>
                                                    {errors.dateofbirth && (<p style={{ color: "red" }}>{errors.dateofbirth}</p>)}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}>Gender <span>*</span></label>
                                                    <select
                                                        className="form-selected full-width"
                                                        name="unittype"
                                                        value={candidate?.gender}
                                                        onChange={(e) => setCandidate({ ...candidate, gender: e.target.value })}
                                                        style={{ fontSize: "16px" }}
                                                    >
                                                        <option value="" selected>
                                                            - - - Select - - -
                                                        </option>
                                                        {gender?.map((obj) => {
                                                            return (
                                                                <option value={`${obj?.key}`}>{obj?.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {errors.gender && (<p style={{ color: "red" }}>{errors.gender}</p>)}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}> Martial Status </label>
                                                    <select
                                                        className="form-selected full-width"
                                                        name="unittype"
                                                        value={candidate?.martialstatus}
                                                        onChange={(e) => setCandidate({ ...candidate, martialstatus: e.target.value })}
                                                        style={{ fontSize: "16px" }}
                                                    >
                                                        <option value="" selected>- - Select - -</option>
                                                        {martialStatus?.map((obj) => {
                                                            return (
                                                                <option value={`${obj?.key}`}>{obj?.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>

                                            <div className="col-sm-6">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Blood Group</label>
                                                    <select className="form-select" name="bloodGroup" value={candidate?.bloodGroup}
                                                        onChange={(e) => setCandidate({ ...candidate, bloodGroup: e.target.value })} id="bloodGroup">
                                                        <option value="">--Select--</option>
                                                        <option value="A-">A-</option>
                                                        <option value="A+">A+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="O-">O-</option>
                                                        <option value="O+">O+</option>
                                                    </select>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="inputBox">
                                                    <label >Educational Qualification <span>*</span></label>
                                                    <input
                                                        type="text"
                                                        className=""
                                                        value={candidate?.educationQualification}
                                                        onChange={(e) => setCandidate({ ...candidate, educationQualification: e.target.value })}
                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Education Qualification"
                                                    ></input>
                                                    {errors.educationQualification && (<p style={{ color: "red" }}>{errors.educationQualification}</p>)}
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-5"></div>
                                            <div className="col-sm-8">
                                                <div className="inputBox" >
                                                    <label >Experience Required  (In Years)</label>
                                                    <div className="flex gap-3">
                                                        <input type="text" className="" style={{ width: "100%" }}
                                                            value={`${candidate?.expYear}${candidate?.expYear ? '.' : ''}${candidate?.expMonth}`}
                                                            onChange={(e) => setCandidate({ ...candidate, expYear: (e.target.value)?.split('.')![0] ?? '0', expMonth: (e.target.value)?.split('.')[1] ?? '0' })}
                                                            placeholder="Enter Exp. Year"
                                                        ></input>
                                                    </div>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>

                                            {(candidate?.expYear && candidate?.expYear != '0') ? <>
                                                <div className="col-sm-12">
                                                    <div className="inputBox">
                                                        <label>Current Company Details <span>*</span></label>
                                                        <textarea name="currentCompanyDetails" style={{ width: "100%" }}
                                                            value={candidate?.currentCompanyDetails}
                                                            onChange={(e) => setCandidate({ ...candidate, currentCompanyDetails: e.target.value })}
                                                            placeholder="Enter Current Company Details"
                                                        ></textarea>
                                                        {errors.currentCompanyDetails && <p style={{ color: "red" }}> {errors.currentCompanyDetails}</p>}
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label>Last Month Salary <span>*</span></label>
                                                        <input type="text" className="" style={{ width: "100%" }}
                                                            name="employeeName"
                                                            value={candidate?.lastMonthSalary}
                                                            onChange={(e) => setCandidate({ ...candidate, lastMonthSalary: e.target.value })}
                                                            placeholder="Enter Last Month Salary"
                                                        ></input>
                                                        {errors.lastMonthSalary && (<p style={{ color: "red" }}>{errors.lastMonthSalary}</p>)}
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label> Notice Period <span>*</span></label>
                                                        <input type="text"
                                                            value={candidate?.noticePeriod}
                                                            onChange={(e) => setCandidate({ ...candidate, noticePeriod: e.target.value })}
                                                            style={{ width: "100%" }}
                                                            placeholder="Enter Notice Period"
                                                        ></input>
                                                        {errors.noticePeriod && (<p style={{ color: "red" }}>{errors.noticePeriod}</p>)}
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="inputBox">
                                                        <label>Current City</label>
                                                        <input type="text"
                                                            value={candidate?.currentcity}
                                                            onChange={(e) => setCandidate({ ...candidate, currentcity: e.target.value })}
                                                            style={{ width: "100%" }}
                                                            placeholder="Enter Current City"
                                                        ></input>
                                                        {errors.currentcity && (<p style={{ color: "red" }}>{errors.currentcity}</p>)}
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                            </> : null}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Contact Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className='col-sm-4'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Door No<span>*</span></label>
                                                    <input type="text" className=""
                                                        value={contactDet?.doorno}
                                                        onChange={(e) => setContactDet({ ...contactDet, doorno: e.target.value })}
                                                        placeholder="Enter Door No"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                {errors.doorno && <p style={{ color: 'red' }}>{errors.doorno}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-8'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Street<span>*</span></label>
                                                    <input type="text" className=""
                                                        value={contactDet?.street}
                                                        onChange={(e) => setContactDet({ ...contactDet, street: e.target.value })}
                                                        placeholder="Enter Street"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                {errors.street && <p style={{ color: 'red' }}>{errors.street}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-md-6 '>
                                                <div className='inputBox'>
                                                    <label >Area <span>*</span></label>
                                                    <input type="text" className='full-width'
                                                        placeholder='Enter Area name' autoComplete='nope'
                                                        value={contactDet?.area}
                                                        onChange={(e) => setContactDet({ ...contactDet, area: e.target.value })}
                                                        name='area' />
                                                </div>
                                                {errors.area && <p style={{ color: 'red' }}>{errors.area}</p>}
                                                <div className='vertical-space-10'></div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label >City <span>*</span></label>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={cityOptions}
                                                        getOptionLabel={(option: any) => option.name}
                                                        onChange={getStateCountry}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params} className="full-width"
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder='Select City'
                                                                name='city'
                                                            />}
                                                    />
                                                    {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                                                </div>
                                                <div className='vertical-space-10'></div>
                                            </div>

                                            <div className='col-md-6 '>
                                                <div className='inputBox'>
                                                    <label >State </label>
                                                    <input type="text" className='full-width'
                                                        placeholder='Enter State' autoComplete='nope'
                                                        value={contactDet?.state}
                                                        name='state' />
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label >Country </label>
                                                    <input type="text" className='full-width'
                                                        placeholder='Enter Country' autoComplete='nope'
                                                        value={contactDet?.country}
                                                        name='country' />
                                                    <div className='vertical-space-10'></div>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                <div className='inputBox'>
                                                    <label >Pin Code <span>*</span></label>
                                                    <input type="text" className='full-width'
                                                        placeholder='Enter Pin Code' autoComplete='nope'
                                                        value={contactDet?.pincode}
                                                        onChange={(e) => setContactDet({ ...contactDet, pincode: e.target.value })}
                                                        name='pinCode' maxLength={6} />
                                                </div>
                                                {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                                                <div className='vertical-space-10'></div>
                                            </div>
                                            <div className='col-sm-6'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Mobile</label>
                                                    <input type="text" className=""
                                                        value={contactDet?.mobile}
                                                        onChange={(e) => e.target.value?.length < 11 && setContactDet({ ...contactDet, mobile: e.target.value })}
                                                        placeholder="Enter Mobile"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-8'></div>
                                            <div className='col-sm-8'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Email <span>*</span></label>
                                                    <input
                                                        type="text"
                                                        className=""
                                                        value={contactDet?.email}
                                                        onChange={(e) => setContactDet({ ...contactDet, email: e.target.value })}
                                                        placeholder="Enter Email"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-4'></div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Work Experience</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: "16px" }}> Company  </label>
                                                    <input type="text" className=""
                                                        name="company"
                                                        value={experienceDetail?.company}
                                                        onChange={(e) => setExperienceDetail({ ...experienceDetail, company: e.target.value })}
                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Company"
                                                    ></input>
                                                </div>
                                                {errors.company && <p style={{ color: 'red' }}>{errors.company}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: "16px" }}> Job Title  </label>
                                                    <input
                                                        type="text"
                                                        className=""
                                                        name="jobtitle"
                                                        value={experienceDetail?.jobtitle}
                                                        onChange={(e) => setExperienceDetail({ ...experienceDetail, jobtitle: e.target.value })}
                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Job Title"
                                                    ></input>
                                                </div>
                                                {errors.jobtitle && <p style={{ color: 'red' }}>{errors.jobtitle}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="inputBox">
                                                    <label>
                                                        From
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="inputBoxDate"
                                                        style={{ width: "100%", fontWeight: "lighter" }}
                                                        placeholder="DD/MMM/YYYY"
                                                        data-date=""
                                                        data-date-format="DD MMMM YYYY"
                                                        value={experienceDetail?.fromdate}
                                                        onChange={(e) => setExperienceDetail({ ...experienceDetail, fromdate: e.target.value })}
                                                        name="fromdate"
                                                    ></input>
                                                </div>
                                                {errors.fromdate && <p style={{ color: 'red' }}>{errors.fromdate}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="inputBox">
                                                    <label>
                                                        To
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="inputBoxDate"
                                                        style={{ width: "100%", fontWeight: "lighter" }}
                                                        placeholder="DD/MMM/YYYY"
                                                        data-date=""
                                                        data-date-format="DD MMMM YYYY"
                                                        value={experienceDetail?.todate}
                                                        onChange={(e) => setExperienceDetail({ ...experienceDetail, todate: e.target.value })}
                                                        name="todate"
                                                    ></input>
                                                </div>
                                                {errors.todate && <p style={{ color: 'red' }}>{errors.todate}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-6"></div>
                                            <div className="col-sm-6">
                                                <div className="btnBox">
                                                    <button
                                                        className="dfBtn"
                                                        type="submit"
                                                        style={{ height: "35px" }}
                                                        onClick={() => handleAddExperience(-1)}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tableListDetails"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Company Name</th>
                                                        <th scope="col">Job Title</th>
                                                        <th scope="col">From</th>
                                                        <th scope="col">To</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {workExperience?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{val?.company}</td>
                                                                <td>{val?.jobtitle}</td>
                                                                <td>{val?.fromdate}</td>
                                                                <td>{val?.todate}</td>
                                                                <td>
                                                                    <button className="delete">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashAlt}
                                                                            onClick={() => handleAddExperience(key)}
                                                                        />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Key Skills</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: "16px" }}>Key Skills  </label>
                                                    <input
                                                        type="text"
                                                        className=""
                                                        name="mobileNo"
                                                        value={skillDetails?.keyskills}
                                                        onChange={(e) => setSkillDetails({ ...skillDetails, keyskills: (e.target.value) })}
                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Key Skill"
                                                    ></input>
                                                </div>
                                                {errors?.keyskills && <p style={{ color: 'red' }}>{errors?.keyskills}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-8">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}>Total Experience (In numbers)</label>
                                                    <div className="flex gap-3">
                                                        <input type="text" className="" style={{ width: "100%" }}
                                                            value={skillDetails?.exp_year}
                                                            onChange={(e) => setSkillDetails({ ...skillDetails, exp_year: (e.target.value) })}
                                                            placeholder="Enter Exp. Year"
                                                        ></input>
                                                        <input type="text" className="" style={{ width: "100%" }}
                                                            value={skillDetails?.exp_month}
                                                            onChange={(e) => setSkillDetails({ ...skillDetails, exp_month: (e.target.value) })}
                                                            placeholder="Enter Months.."
                                                        ></input>
                                                    </div>
                                                </div>
                                                {(errors?.exp_year || errors?.exp_month) && <p style={{ color: 'red' }}>{errors?.exp_year ?? errors?.exp_month}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-6"></div>
                                            <div className="col-sm-6">
                                                <div className="btnBox">
                                                    <button
                                                        className="dfBtn"
                                                        type="submit"
                                                        style={{ height: "35px" }}
                                                        onClick={() => handleKeySkills(-1)}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tableListDetails"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Key Skills</th>
                                                        <th scope="col">Experience</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {keySkills?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{val?.keyskills}</td>
                                                                <td>{`${val?.exp_year}y${val?.exp_month}m`}</td>
                                                                <td>
                                                                    <button className="delete">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashAlt}
                                                                            onClick={() => handleKeySkills(key)}
                                                                        />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Languages Known</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Languages Known <span>*</span></label>
                                                    <input
                                                        type="text"
                                                        className=""
                                                        name="mobileNo"
                                                        value={langKnown?.mlanguage}
                                                        onChange={(e) => setLangKnow({ ...langKnown, mlanguage: (e.target.value) })}
                                                        style={{ width: "100%" }}
                                                        placeholder="Enter Known Language.."
                                                    ></input>
                                                </div>
                                                {errors?.mlanguage && <p style={{ color: 'red' }}>{errors?.mlanguage}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                    <input type="checkbox" checked={langKnown?.toSpeak === 'Yes'} onChange={(evt) => setLangKnow({ ...langKnown, toSpeak: evt?.target?.checked ? 'Yes' : 'No' })} />
                                                    <span className="flex align-items-center"> {"To Speak"}</span>
                                                </div>
                                                {errors?.level && <p style={{ color: 'red' }}>{errors?.level}</p>}
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                    <input type="checkbox" checked={langKnown?.toRead === 'Yes'} onChange={(evt) => setLangKnow({ ...langKnown, toRead: evt?.target?.checked ? 'Yes' : 'No' })} />
                                                    <span className="flex align-items-center"> {"To Read"}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                    <input type="checkbox" checked={langKnown?.toWrite === 'Yes'} onChange={(evt) => setLangKnow({ ...langKnown, toWrite: evt?.target?.checked ? 'Yes' : 'No' })} />
                                                    <span className="flex align-items-center"> {"To Write"}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-6"></div>
                                            <div className="col-sm-6">
                                                <div className="btnBox">
                                                    <button
                                                        className="dfBtn"
                                                        type="submit"
                                                        style={{ height: "35px" }}
                                                        onClick={() => handleAddLang(-1)}
                                                    >
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tableListDetails"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Language Known</th>
                                                        <th scope="col">Read</th>
                                                        <th scope="col">Speak</th>
                                                        <th scope="col">Write</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lngKnownList?.map((val, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{val?.mlanguage}</td>
                                                                <td>{val?.toRead}</td>
                                                                <td>{val?.toSpeak}</td>
                                                                <td>{val?.toWrite}</td>
                                                                <td>
                                                                    <button className="delete">
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashAlt}
                                                                            onClick={() => {
                                                                                handleAddLang(key);
                                                                            }}
                                                                        />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            {errors?.language && <p style={{ color: 'red' }}>{errors?.language}</p>}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                        <div className='inputBoxLists'>
                            <div className='row'>
                                <div className='col-sm-8'>
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Reference</label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={employeeMaster}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, value) => setResumeBankMaster({ ...resumeBankMaster, empid: Number(value?.EmployeeId) })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params} className="full-width"
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Ref Employee'
                                                    name='city'
                                                />}
                                        />
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label>Resume<span>*</span></label>
                                        <input
                                            type="file"
                                            className="full-width"
                                            accept="image/*"
                                            name="resumepath"
                                            onChange={handleChangeImage}

                                        />
                                        {errors.resumepath && (
                                            <p style={{ color: "red" }}>{errors.resumepath}</p>
                                        )}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label> Photo</label>
                                        <input
                                            type="file"
                                            className="full-width"
                                            accept="image/*"
                                            name="empphotopath"
                                            onChange={handlePhotoChnage}
                                        />
                                        {errors.empphotopath && (<p style={{ color: "red" }}>{errors.empphotopath}</p>)}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className='col-sm-12'>
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Description </label>
                                        <textarea
                                            name='categoryName'
                                            style={{ width: '100%' }}
                                            value={resumeBankMaster?.description}
                                            onChange={(evt) => setResumeBankMaster({ ...resumeBankMaster, description: evt.target.value })}
                                            className='brandinput' ></textarea>
                                        {errors.brandCode && <p style={{ color: 'red' }}>{errors.brandCode}</p>}
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleCancel}>
                                List
                            </button>
                            <button className="dfBtn" type="submit" onClick={saveEmployee}>
                                Submit
                            </button>
                        </div>

                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image
                                            src={require("../../../gsmecom/images/checked.png")}
                                        />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image
                                            src={require("../../../gsmecom/images/warning.png")}
                                        />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
});

export default ResumeBank;
