import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class PurchaseApprovalStore {
    @observable indentMainDetails = new Map();
    @observable indentDetails = new Map();
    @observable supplierDetails = new Map();

    constructor() {
        makeObservable(this);
    }

    getIndentMainDetails = async (body) => {
        try {
            const indentMainDetails = await apiAgent.purchaseApproval.getindentMainDetails(body);
            runInAction(() => {
                this.indentMainDetails = indentMainDetails;
            })
            if (typeof indentMainDetails === 'string') {
                return JSON.parse(indentMainDetails)
            } else if (Array.isArray(indentMainDetails)) {
                return indentMainDetails
            } else {
                return indentMainDetails
            }
        } catch (error) {
            console.log(error)
        }
    }
    getIndentDetails = async (indentId) => {
        try {
            const indentDetails = await apiAgent.purchaseApproval.getIndentDetails(indentId);
            runInAction(() => {
                this.indentDetails = indentDetails;
            })
            return JSON.parse(indentDetails);
        } catch (error) {
            console.log(error)
        }
    }
    getIndentNoDetails = async (indentId) => {
        try {
            const indentDetails = await apiAgent.purchaseApproval.getIndentNoDetails(indentId);
            runInAction(() => {
                this.indentDetails = indentDetails;
            })
            return JSON.parse(indentDetails);
        } catch (error) {
            console.log(error)
        }
    }
    loadSupplierName = async (indentId, itemId) => {
        try {
            const supplierDetails = await apiAgent.purchaseApproval.getSupplierNameDetails(indentId, itemId);
            runInAction(() => {
                this.supplierDetails = supplierDetails;
            })
            return this.supplierDetails;
        } catch (error) {
            console.log(error)
        }
    }
    submitPurchaseApproval = async (purchaseapprval) => {
        try {
            const status = await apiAgent.purchaseApproval.submitPurchaseApproval(purchaseapprval);

            return status
        } catch (error) {
            console.log(error)
        }

    }
    loadPurchaseApprvalList = async (purchaseapprval) => {

        try {
            const approvalList = await apiAgent.purchaseApproval.loadPurchaseApprvalList(purchaseapprval);
            return approvalList
        } catch (error) {
            console.log(error)
        }

    }
    deletePurchaseApproval = async (indentId) => {
        try {
            const purchaserOrderList = await apiAgent.purchaseApproval.deletePurchaseApproval(indentId);
            return purchaserOrderList
        } catch (error) {
            console.log(error)
        }
    }
}

export default PurchaseApprovalStore;