import { action, set, makeObservable, observable, runInAction } from 'mobx';
import LedgerGroupDetails, {  ILedgerGroup } from '../model/index';
import apiAgent from '../../../APIAgent';

class LedgerGroupStore {
    ledgerGroupDetls =LedgerGroupDetails;
    @observable ledgerGroupList= new Map();
    isLoading=true;
    @observable ledgerGroupLists=new Map<any, any>();
    @observable entryNo =new Map();
    @observable isEdit=false;
    @observable ledgerGroupStatus = new Map();
    //status = string;
    constructor(){
        makeObservable(this);
    }

     saveLedgerGroup = async (ledgerGroup: ILedgerGroup) => {
        try {
           const status=  await apiAgent.ledgerGroup.saveLedgerGroup(ledgerGroup);
           
            return status;
           
          
        }
        catch (error) {
            console.log(error);
        }
    }
    getLedgerGroup = async ()=>{
        try{
            const ledgerGroupData = await apiAgent.ledgerGroup.getLedgerGroup();
            this.ledgerGroupList = ledgerGroupData;
            
            runInAction(()=>{
                this.ledgerGroupList = ledgerGroupData;
                this.isLoading=false;
            })
            return this.ledgerGroupList;
        }
        catch(error){
            console.log(error);
    }
}
getEntryNo = async (flag)=>{
    try{
        const entryNo = await apiAgent.ledgerGroup.getEntryNo(flag);
        
        runInAction(()=>{
            this.entryNo = entryNo;
        })
        return this.entryNo;
    }
    catch(error){
        console.log(error);
}
}
updateLedgerGroup = async (ledgerGroup: ILedgerGroup) => {
    
    try {      
    const status=  await apiAgent.ledgerGroup.updateLedgerGroup(ledgerGroup);     
    runInAction(() => {
    this.ledgerGroupStatus = status;
    });

    return this.ledgerGroupStatus;
    }
    catch (error) {
        console.log(error);
    }
}
LedgerGroupDetails  = async (groupId:Number)=> {
    
    try {
    const ledgerGroupData= await apiAgent.ledgerGroup.LedgerGroupDetails(groupId);
        
      return ledgerGroupData;
    }
    catch (error) {
        console.log(error);
    }
}
deleteLedgerGroup= async (groupId) => {
    try {      
    const status=  await apiAgent.ledgerGroup.deleteLedgerGroup(groupId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}
getLedgerGroupSearch  = async (SearchOptions)=> {
    try {
      const data=await apiAgent.ledgerGroup.getLedgerGroupSearch(SearchOptions);
        this.ledgerGroupList = data;
        
        runInAction(() => {
            this.ledgerGroupList = data;
            
        })
        return this.ledgerGroupList;    
     
    }
    catch (error) {
        console.log(error);
    }
}

@action setLedgerGroup = (state:ILedgerGroup) =>{
    this.ledgerGroupList.set(0,state);
}
@action setLedgerGroupList = (state:ILedgerGroup[]) =>{
    set(state,this.ledgerGroupList);
    return this.ledgerGroupList;
}
}
export default LedgerGroupStore;