import React, { useEffect, useRef, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';



function CounterOpeningEntryDetail() {

  const [loadCounterOpeningMasterDetails, setLoadCoutnerOpeningMasterDetails] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false)
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false)
  const [show, setShow] = useState(false);

  const [denominationQty, setDenominationQty] = useState<any[]>([])

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const modalClose = () => setShow(false);
  const modalShow = (denominationmid) => {
    fetchDetailsOfCounterEntry(denominationmid);
    setShow(true);
  }

  async function fetchDetailsOfCounterEntry(denominationmid) {
    const counterEntryData = await posMasterStore.getConuterEntryDetails(denominationmid);
    setDenominationQty(counterEntryData)
  }

  const navigate = useNavigate()

  const { posMasterStore } = useStores();

  async function fetchingLoadMasterData() {
    let denominations = {
      denomationmid: 0
    }
    const loadCounterMasterDetails = await posMasterStore.loadCounterOpeningMaster(denominations)
    setLoadCoutnerOpeningMasterDetails(loadCounterMasterDetails)
  }

  const sortedData = loadCounterOpeningMasterDetails?.length > 0 ? loadCounterOpeningMasterDetails?.slice()?.sort((a, b) => {
    const dateA = new Date(a.denomationDate);
    const dateB = new Date(b.denomationDate);

    if (dateA > dateB) return -1;
    if (dateB < dateA) return 1;
    return 0;
  }) : [];

  const handleCreate = () => {
    navigate('/Admin/CounterOpeningEntry')
  }

  const editTerminalDetials = (denominationid) => {
    navigate('/Admin/CounterOpeningEntry/' + denominationid);
  }

  async function handleDelete(denominationid) {
    setLoading(true);

    const status = await posMasterStore.deleteCounterEntry(denominationid);

    if (status === 'Success') {
      fetchingLoadMasterData();
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchingLoadMasterData();
      isCurrentPage.current = false
    }
    return () => { }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData && sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='container-fluid'>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
      }
      <div className='vertical-space-20'></div>
      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={sortedData}
        searchTitles={[{ key: "denomationCounterid", value: "Counter No" }]}
        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
      <div className='vertical-space-20'></div>
      <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='btnBox'>
          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
        </div>
        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
        </Stack>
      </div>
      <div className='vertical-space-20'></div>
      <div className='tableListDetails'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Date  </th>
              <th scope='col'>Time  </th>
              <th scope='col'>Counter No  </th>
              <th scope='col'>Amount </th>
              <th scope='col'></th>
              <th scope='col'>Create </th>
              <th scope='col'>Update </th>
              <th scope='col'>Delete </th>
            </tr>
          </thead>
          <tbody>
            {(isSearch ? filteredData : currentPageData)?.length > 0 &&
              (isSearch ? filteredData : currentPageData)?.map((counterData, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(counterData.denomationDate).format('DD-MMM-YYYY')}</td>
                    <td>{counterData.denomationTime}</td>
                    <td>{counterData.denomationCounterid}</td>
                    <td>{counterData.denomationAmount}</td>
                    <td><button onClick={() => modalShow(counterData.denomationmid)}>Detail</button></td>
                    <td><button className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                    <td><button className='Edit' onClick={() => editTerminalDetials(counterData.denomationmid)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                    <td><button className='delete' onClick={() => handleDelete(counterData.denomationmid)}><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>

      <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Denomination Window</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='CustomerDetail'>
            <div className='tableBox'>
              <table className="table">
                <tbody>
                  {denominationQty?.map((denomination, index) => {
                    return (
                      <tr>
                        <td>{denomination.denomationno} </td>
                        <td> * </td>
                        <td> {denomination.denomationCount} </td>
                        <td> = </td>
                        <td>{denomination.denomationAmount}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>

          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../../image/checked.png')} />
            </div>
            <h4>Succesfully Deleted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSuccessModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../../image/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFailureModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

};

export default CounterOpeningEntryDetail;
