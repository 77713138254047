import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { IInwardDetailRequest, IPurchaseBill, IPurchaseBillDetails, IPurchaseDiscountDetails } from './model';
import moment from 'moment';
import purchaseBillValidation from './validation';
import Cookies from 'js-cookie';


const PurchaseBill = observer(() => {
	const navigate = useNavigate();
	const { purchaseBillStore, inwardStore, directInwardStore } = useStores();

	const { getLedgers } = inwardStore;

	const [isLoading, setLoading] = useState(true);
	const [purchaseBill, setPuchaseBill] = useState<IPurchaseBill>({})

	const [inwardId, setInwardId] = useState('')

	const [purchaseDetails, setPurchaseDetails] = useState<IPurchaseBillDetails[]>([]);
	const [discountDetails, setDiscountDetails] = useState<IPurchaseDiscountDetails[]>([]);
	const [ledgerDetails, setLedgerDetails] = useState<any>([])

	console.log(purchaseDetails, discountDetails, 'discountDetails>>')
	const [inwardsDetail, setInwardDetail] = useState<any[]>([]);
	const [errors, setErrors] = useState<any>({});
	const [isSuccessModal, setSuccessModal] = useState(false);
	const [isFailureModal, setFailureModal] = useState(false);

	function handleCancel() {
		navigate("/Admin/PurchaseBill/PurchaseBillDetail")
	}
	const handleInputChange = (e) => {

		let { name, value } = e.target;
		if (value === 'addrounfoff') {
			purchaseBill.roundoffAmount = 0;

		}
		if (value === 'lessroundoff') {
			purchaseBill.roundoffAmount = 0;
		}
		if (purchaseBill.roundOffOption === 'addrounfoff' && name === 'roundoffAmount') {
			purchaseBill.netAmount = 0;
			value = Number(value);
			purchaseBill.netAmount = purchaseBill.grandTotal! + value;
		}
		if (purchaseBill.roundOffOption === 'lessroundoff' && name === 'roundoffAmount') {
			purchaseBill.netAmount = 0;
			value = Number(value);
			purchaseBill.netAmount = purchaseBill.grandTotal! - value;
		}
		setPuchaseBill({ ...purchaseBill, [name]: value })

	}
	const handleSupplierValue = async (name: any, event: object, val: any) => {
		if (val != null) {
			setLoading(true);
			setPuchaseBill({ ...purchaseBill, purchaseBillSupplierId: val.supplierId })
			const inwards = await purchaseBillStore.getInwards(Number(val.supplierId));
			setPurchaseDetails(new Array<IPurchaseBillDetails>())
			setDiscountDetails(new Array<IPurchaseDiscountDetails>())
			setLedgerDetails(new Array<any>())
			setInwardDetail([])
			setInwardDetail(inwards)
			setLoading(false);
		}
		else {
			purchaseBill.purchaseBillSupplierId = 0;
			setPurchaseDetails(new Array<IPurchaseBillDetails>())
			setDiscountDetails(new Array<IPurchaseDiscountDetails>())
			setLedgerDetails(new Array<any>())

		}
	}

	const handleInwards = async (e, inwardId, inwardno: string) => {
		setLoading(true);
		let inwardRequest: IInwardDetailRequest = {};
		inwardRequest.inwardId = inwardno;
		inwardRequest.supplierId = purchaseBill.purchaseBillSupplierId;
		if (e.target.checked) {
			setInwardId(inwardno)
			const inwardDetail = await purchaseBillStore.getInwardDetail(inwardRequest);
			const inwardDiscounDetails = await purchaseBillStore.getInwardDetail({ supplierId: purchaseBill.purchaseBillSupplierId, inwardId: '' });
			let ledgers: any[] = [];
			if (inwardno?.startsWith('DI')) {
				ledgers = await directInwardStore.getDirectInwardLedgers(inwardId);
			} else if (inwardno?.startsWith('IW')) {
				ledgers = await getLedgers(inwardId);
			}

			inwardDiscounDetails?.forEach(element => {
				if (element.Inward_no === inwardno) {
					setDiscountDetails(discountDetils => [...discountDetils, element])
				}
			});

			inwardDetail?.forEach(element => {
				let obj: IPurchaseBillDetails = {
					inwardno: inwardno,
					inwardId: element.inwardId,
					inwardDetailId: element.Inwarddetailid,
					inwardDate: 0,
					itemName: element.itemname,
					itemId: element.itemid,
					billQty: element.billqty,
					freeQty: element.Freeqty,
					rate: element.rate,
					discountPercentage: element.Discount_per,
					discountValue: element.DiscountV,
					taxPercentage: element.gst_per,
					taxValue: element.gstvalue,
					subTotal: element.value,
					totalAmount: element.totalvalue,
					taxId: Number(element.taxname),
				}
				setPurchaseDetails(purchaseDetails => [...purchaseDetails, obj])
			});

			ledgers?.forEach(element => {
				element.inwardno = inwardno;
				element.inwardId = inwardId;
				element.ledgerAmount = element?.ledgerAmount ?? element?.directInwardLedgerAmount;
				setLedgerDetails(ledgerDetails => [...ledgerDetails, element])
			})

			setLoading(false);
		} else {
			let discountFilter = discountDetails?.filter((each) => each?.Inward_no != inwardno)
			let ledgerFilter = ledgerDetails?.filter((each) => each?.inwardno != inwardno)
			let purchaseFilter = purchaseDetails?.filter((each) => each?.inwardno != inwardno)
			setDiscountDetails([...discountFilter]);
			setLedgerDetails([...ledgerFilter]);
			setPurchaseDetails([...purchaseFilter]);
			setLoading(false);
		}
	}

	const handlePurchaseBillSave = async (e) => {
		e.preventDefault();
		let error: any = {};
		error = purchaseBillValidation(purchaseBill, purchaseDetails);
		setErrors(error)
		if (Object.keys(error).length === 0) {
			setLoading(true)
			purchaseBill.purchaseBillDetails = purchaseDetails;
			purchaseBill.purchaseBillNo = Number(purchaseBill.purchaseBillNo);
			purchaseBill.employeeId = Number(Cookies.get('employeeId'));
			purchaseBill.expense = purchaseBill.expense ? Number(purchaseBill.expense) : 0;
			if (purchaseBill.roundoff !== undefined) {
				purchaseBill.roundoff = purchaseBill.roundOffOption === 'addrounfoff' ? String(purchaseBill.roundoffAmount) : '-' + String(purchaseBill.roundoffAmount);
			}
			else {
				purchaseBill.roundoff = '0';
			}
			purchaseBill!.netAmount = purchaseBill.roundoffAmount ? purchaseBill.netAmount : purchaseBill.grandTotal
			const status = await purchaseBillStore.savePurchaseBill(purchaseBill);
			if (status === 'Success') {
				setLoading(false);
				setSuccessModal(true);
			}
			else {
				setLoading(false);
				setFailureModal(true);
			}
			// setLoading(false);

		}

	}

	const handleFailureModalClose = () => setFailureModal(false);
	const handleSuccessModalClose = () => {
		navigate('/Admin/PurchaseBill/PurchaseBillDetail');
	}

	async function preLoadingFunctions() {
		await purchaseBillStore.getPurchaseBillEntryNo('PurchaseBill');
		await purchaseBillStore.getInwardSupliers();
		setLoading(false);
	}

	const isCurrenPage = useRef(true)

	useEffect(() => {
		if (isCurrenPage.current) {
			preLoadingFunctions();
			isCurrenPage.current = false;
		}
		return () => { }
	}, [])

	return (
		<>
			<div className='container'>
				<div className='vertical-space-20'></div>
				<div className='outletInputField inputFormBox LgInputField'>
					<div className='hrBox'>
						<h3>Purchase Bill</h3>
					</div>
					<div className='inputBoxLists'>
						<div className='ItemInwardInputBox'>
							<div className='row'>
								<div className='col-sm-7'>
									<div className='row'>
										{
											isLoading ?
												<div className='SpinnerBox'>
													<Spinner animation="border" role="status">
														<span className="visually-hidden">Loading...</span>
													</Spinner>
												</div> : null
										}
										{
											purchaseBillStore.entryNo ?
												<div className='col-sm-4' >
													<div className='inputBox'>
														<label>Record No<span>*</span></label>
														<input type="text" style={{ width: "100%" }} name='PurchaseBillNo'
															placeholder="Record No" value={purchaseBill.purchaseBillId = purchaseBillStore.entryNo.entryNo} disabled></input>
													</div>
													<div className='vertical-space-10'></div>
												</div> : null
										}

										<div className='col-sm-4'>
											<div className='inputBox'>
												<label> Date <span>*</span></label>
												<input type="date" style={{ width: "100%" }}
													name='purchaseBillDate' value={purchaseBill.purchaseBillDate = purchaseBill.purchaseBillDate ?? new Date().toISOString().substr(0, 10)}
													onChange={handleInputChange}
													placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
												{errors.purchaseBillDate && <p style={{ color: 'red' }}>{errors.purchaseBillDate}</p>}
											</div>
											<div className='vertical-space-10'></div>
										</div>
										<div className='col-sm-6' >
											<div className='inputBox'>
												<label>Supplier <span>*</span></label>
												<Autocomplete size="small"
													disablePortal
													id="combo-box-demo"
													options={purchaseBillStore?.loadSuppliers?.length ? purchaseBillStore?.loadSuppliers : []}
													onChange={(event, val) => handleSupplierValue('purchaseBillSupplierId', event, val)}
													getOptionLabel={(option: any) => option.supplierName}
													renderInput={(params: any) =>
														<TextField  {...params} style={{ width: '40ch' }}
															id="outlined-size-small"
															color='info'
															size="small"
															type="text"
															placeholder='Select Supplier..'
															name='purchaseBillSupplierId'
														/>}
												/>
												{
													errors.purchaseBillSupplierId && <p style={{ color: 'red' }}>{errors.purchaseBillSupplierId}</p>
												}
											</div>
											<div className='vertical-space-10'></div>
										</div>
										<div className='col-sm-6'> </div>

										<div className='col-sm-4' >
											<div className='inputBox'>
												<label>Bill No<span>*</span></label>
												<input type="text" style={{ width: "100%" }}
													name='purchaseBillNo' value={purchaseBill.purchaseBillNo}
													onChange={handleInputChange}
													placeholder="Bill No" ></input>
												{
													errors.purchaseBillNo && <p style={{ color: 'red' }}>{errors.purchaseBillNo}</p>
												}
											</div>
										</div>
										<div className='col-sm-4'>
											<div className='inputBox'>
												<label> Date <span>*</span></label>
												<input type="date" style={{ width: "100%" }}
													name='billDate' value={purchaseBill.billDate = purchaseBill.billDate ?? new Date().toISOString().substr(0, 10)}
													onChange={handleInputChange}
													placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
												{
													errors.billDate && <p style={{ color: 'red' }}>{errors.billDate}</p>
												}
											</div>
											<div className='vertical-space-10'></div>
										</div>

										<div className='col-sm-6'>
											<div className='inputBox'>
												<label>Remarks <span>*</span></label>
												<textarea className='' style={{ width: "100%" }}
													name='remarks' value={purchaseBill.remarks}
													onChange={handleInputChange}
													placeholder='Remarks..'></textarea>
												{
													errors.remarks && <p style={{ color: 'red' }}>{errors.remarks}</p>
												}
											</div>
											<div className='vertical-space-10'></div>
										</div>
									</div>
								</div>
								{
									inwardsDetail?.length > 0 ?
										<div className='col-sm-5'>
											<div className='tableBox'>
												<table className="table table-striped">
													<thead >
														<tr>
															<th scope="col"></th>
															<th scope="col">Inward No</th>
															<th scope="col">Inward Date </th>
														</tr>
													</thead>
													<tbody >
														{inwardsDetail?.length > 0 &&
															inwardsDetail?.map((val, key) => {
																let isChecked = purchaseDetails?.some((each) => each?.inwardno === val?.inward_no)
																return (
																	<tr key={key}>
																		<td><input type='checkbox' checked={isChecked} onChange={(e) => handleInwards(e, val.inwardId, val.inward_no)}></input></td>
																		<td>{val.inward_no}</td>
																		<td>{moment(val.inwardDate).format('DD-MM-YYYY')}</td>
																	</tr>
																)
															})
														}
													</tbody>
												</table>
											</div>
											<div className='vertical-space-10'></div>
										</div> : null
								}

								<div className='col-sm-12'>
									<div className='tableBox'>
										<table className="table table-striped">
											<thead>
												<tr>
													<th scope="col">Name</th>
													<th scope="col">Bill Qty</th>
													<th scope="col">Free Qty</th>
													<th scope="col">Rate</th>
													<th scope="col">Dis %</th>
													<th scope="col">Dis Value</th>
													<th scope='col1'>Sub Total</th>
													<th scope="col">GST %</th>
													<th scope="col">GST Value</th>
													<th scope="col">Total</th>
												</tr>
											</thead>
											<tbody>
												{purchaseDetails.length > 0 &&
													purchaseDetails.map((val, key) => {
														return (
															<tr key={key}>
																<td>{val.itemName}</td>
																<td>{val.billQty}</td>
																<td>{val.freeQty}</td>
																<td>{val.rate}</td>
																<td>{val.discountPercentage}</td>
																<td>{val.discountValue}</td>
																<td>{val.subTotal}</td>
																<td>{val.taxPercentage}</td>
																<td>{val.taxValue}</td>
																<td>{val.totalAmount}</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>
								</div>
								{errors.purchaseBillDetails && <p style={{ color: 'red' }}>{errors.purchaseBillDetails}</p>}
								<div className='vertical-space-10'></div>
							</div>
						</div>
						<div className='row'>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >
								<div className='inputBox'>
									<label> Sub Total</label>
									<input type="text" style={{ width: "100%" }} disabled
										name='subtotal'
										value={purchaseBill.subtotal = Number(purchaseDetails.reduce((a, v) => a = a + Number(v.subTotal!), 0).toFixed(2))}></input>
								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >
								<div className='inputBox'>
									<label>Disc Amount</label>
									<input type="text" style={{ width: "100%" }} disabled
										value={purchaseBill.discount = discountDetails?.length ? Number(discountDetails?.reduce((a, v) => a = a + v?.discountAmount!, 0)) : 0}
										name='discount'></input>
								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >

								<div className='inputBox'>
									<label> Total Tax</label>
									<input type="text" style={{ width: "100%" }} disabled
										name='totalTax' value={purchaseBill.totalTax = Number(purchaseDetails.reduce((a, v) => a = a + Number(v.taxValue!), 0).toFixed(2))}></input>
								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >

								<div className='inputBox'>
									<label> Expense</label>
									<input type="text" style={{ width: "100%" }} disabled
										name='expense' value={purchaseBill.expense = ledgerDetails?.reduce((a, v) => a = a + v?.ledgerAmount, 0)}></input>

								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >
								<div className='inputBox'>
									<label> Grand Total</label>
									<input type="text" style={{ width: "100%" }} disabled
										name='grandTotal' value={purchaseBill.grandTotal = Number((purchaseBill.subtotal + purchaseBill.totalTax + Number(purchaseBill.expense! ?? 0) - purchaseBill.discount)?.toFixed(2))}></input>
								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >
								<div className='inputBox'>
									<label>Round Off</label>
									<select className="form-selected"
										name='roundOffOption'
										value={purchaseBill.roundOffOption}
										onChange={handleInputChange}
										style={{ width: "100%" }} id="roundOffOption">
										<option value="" selected>Select</option>
										<option value="addrounfoff"> Add </option>
										<option value="lessroundoff">Less </option>
									</select>
									<div className='vertical-space-10'></div>
									<div className='inputBox'>

										<input type="number" style={{ width: "100%" }} onChange={handleInputChange}
											name='roundoffAmount' value={purchaseBill.roundoffAmount}></input>

									</div>
								</div>
								<div className='vertical-space-10'></div>
							</div>
							<div className='col-sm-9' ></div>
							<div className='col-sm-3' >
								<div className='inputBox'>
									<label>Net Amt</label>
									{
										purchaseBill.roundoffAmount ? <input type="text" style={{ width: "100%" }}
											name='netAmount' value={purchaseBill.netAmount}
											disabled
											placeholder="" ></input> :
											<input type="text" style={{ width: "100%" }}
												name='netAmount' value={purchaseBill.grandTotal}
												disabled
												placeholder="" ></input>
									}

								</div>
								<div className='vertical-space-10'></div>
							</div>
						</div>
					</div>
					<div className='btnBox'>
						<button className='secondaryBtn' onClick={handleCancel}>List</button>
						<button className='dfBtn' type='submit' onClick={handlePurchaseBillSave}>Submit</button>
					</div>
				</div>
				<Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
					<Modal.Body>
						<div className='Details Success'>
							<div className='imgBox'>
								<Image src={require('../../../../image/checked.png')} />
							</div>

							<h4>Succesfully Submitted</h4>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleSuccessModalClose}>
							Ok
						</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
					<Modal.Body>
						<div className='Details Success'>

							<div className='imgBox'>
								<Image src={require('../../../../image/warning.png')} />
							</div>
							<h4>Failed</h4>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleFailureModalClose}>
							Ok
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</>

	)
});

export default PurchaseBill;
