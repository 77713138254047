import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IInwardRegister } from '../model';

class InwardRegisterStore {
    @observable outletItemDetails= new Map();   
    @observable inwardDetails= new Map();   

    entryNo = new Map(); 
    

    loadOutletItems  = async ()=> {
        try {
          const inwardData= await apiAgent.inwardRegister.loadOutletItems();
            
            runInAction(() => {
                this.outletItemDetails = inwardData;
            });
            return this.outletItemDetails;
        }
        catch (error) {
            console.log(error);
        }
    }    

    getInwardRegisterReport  = async (inwardRegister)=> {
        try {
          const inwardData= await apiAgent.adminReports.getInwardRegisterReport(inwardRegister);
            
            runInAction(() => {
                this.inwardDetails = inwardData;
            });
            return this.inwardDetails;
        }
        catch (error) {
            console.log(error);
        }
    } 
 
    
}

export default InwardRegisterStore;