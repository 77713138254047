import * as XLSX from 'xlsx';

interface ExcelObject {
    [key: string]: any;
}

const isDateField = (value: any): boolean => {
    const lowerBound = 25569; // January 1, 1970
    const upperBound = 73050; // Approx January 1, 2100
    return typeof value === 'number' && value >= lowerBound && value <= upperBound; // Excel dates are stored as numbers starting from 25569 (January 1, 1970)
}

const excelDateToJSDate = (serial: number): string => {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);
    const fractional_day = serial - Math.floor(serial) + 0.0000001;
    let total_seconds = Math.floor(86400 * fractional_day);
    const seconds = total_seconds % 60;
    total_seconds -= seconds;
    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    const tommorow = new Date(date_info);
    tommorow.setDate(tommorow.getDate() + 1)

    return new Date(date_info.getFullYear(), date_info.getMonth(), tommorow?.getDate(), hours, minutes, seconds)?.toISOString()?.substr(0, 10);
};

export const convertExcelToArrayOfObjects = (file: File): Promise<ExcelObject[]> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const data = new Uint8Array(event.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const headers = jsonData[0] as string[];
            const rows = jsonData.slice(1);

            const result = rows.map((row: any) => {
                const obj: ExcelObject = {};
                headers.forEach((header, index) => {
                    const cellValue = row[index];
                    obj[header] = isDateField(cellValue) ? excelDateToJSDate(cellValue) : cellValue;
                });
                return obj;
            });

            resolve(result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsArrayBuffer(file);
    });
};
