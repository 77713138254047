import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export interface IQuoteSupplier {
    quoteNo?: string;
    quoteDate?: string;
    effectiveFrom?: string;
    rate?: number;
    employeeId?: number;
    indentNo?: number;
    indentId?: number;
    indenDetailsId?: number;
    itemId?: number;
    supplierId?: number;
    enteredBy?: number;
    quoteFromSupplierDetails?: IQuoteFromSupplierDetails[];
    quoteItemDetails?: IItemDetails;
    // quoteSupplierDetails?:ISupplierDetails[];
}
export interface IQuoteFromSupplierDetails {
    rate?: number;
    effectiveFrom?: string;
    supplierId?: number;
    indentDetailsId?: number;
}
export class SearchDates {
    fromdate?: string = getCurrentMonthFirstDate()?.firstDate;
    todate?: string = getCurrentMonthFirstDate()?.currentDate
}
export interface IItemDetails {
    itemId?: number;
    itemName?: string;
    indentQty?: number;
    uom?: string;
    indentId?: number;
}
export interface ISupplierDetails {
    supplierName?: string;
    supplierId?: number;
    rate?: number;
    effectiveFrom?: string;
    indentDetailsId?: number;
    indentId?: number;
    itemId?: number;
}

export interface IQuoteFromSupplierSearchoptions {
    searchOption?: string;
    searchValue?: string;
    searchFilter?: boolean;
}