import { ILoosedConversion, ILoosedConversionDetails } from "../model";

export function AddValidation(addConversion: ILoosedConversionDetails) {
    let error: any = {};

    if (!addConversion?.itemid) {
        error.itemid = "Select Item Name"
    }
    if (!addConversion?.qty) {
        error.qty = "Entery Qty"
    }
    if (!addConversion?.uom) {
        error.uom = "Select Item Name"
    }
    // if (!addConversion?.barcode) {
    //     error.barcode = "Select Barcode"
    // }
    if (!addConversion?.batchNo) {
        error.batchNo = "Select Batch No"
    }
    if (!addConversion?.convertedItem) {
        error.convertedItem = "Select Converted Item"
    }
    if (!addConversion?.convertQty) {
        error.convertQty = "Enter Qty"
    }
    // if (!addConversion?.convertBarcode) {
    //     error.convertBarcode = "Select Barcode"
    // }
    if (!addConversion?.convertBatchno) {
        error.convertBatchno = "Select Batch No"
    }

    if (addConversion?.batchQty! < addConversion?.qty!) {
        error.qty = "Qty can't be exceed from batch qty"
    }
    return error;
}

export function saveValidation(loosedConversion: ILoosedConversion) {
    let error: any = {};
    if (!loosedConversion?.entryDate) {
        error.entryDate = "Enter Date"
    }
    if (!loosedConversion?.looseConversiondetails?.length) {
        error.length = "Add items to grid"
    }
    return error;
}