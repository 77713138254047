import { observable } from "mobx";
export interface IAssignPos{
    entryId?:number;
    entryDate?:string;
    counterNumber?:number;
    employeeId?:number;
    shiftId?:number;    
    effectDate?:string;
    shiftName?:string;
    employeeName?:string;
    terminalId?:number;
   
}

export interface IAssignPosSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const AssignPosDetails=observable<IAssignPos>({
    entryId:0,
    counterNumber:0,
    employeeId:0,
    shiftId:0,
    entryDate:'',
    effectDate:'',
    shiftName:'',
    employeeName:'',
    terminalId:0
});

export default AssignPosDetails;