import { action } from 'mobx';
//import {numeric} from '../../../common/commonStore/const';
function warehouseValidator(warehouse){
 
  
  let errors :any={};

  const alpha = /[a-zA-Z]*$/;
   const numeric =/^[0-9]*$/;
  if(!warehouse.warehouseName){
    
    errors.warehouseName = "Enter Warehouse Name";
  }
  else if(!alpha.test(warehouse.warehouseName)){
    
    errors.warehouseName = "Enter valid warehosue name";
  }
 if(!warehouse.fssaiNumber){
    errors.fssaiNumber = "Enter Fssai Number";
  }
  else if(!numeric.test(warehouse.fssaiNumber)){
    
    errors.fssaiNumber = "Enter valid FASSI Number";
  }
  if(!warehouse.dateOfIncrorpeted){
    errors.dateOfIncrorpeted = "Select Date of Incorporated";
  }

  if(!warehouse.city){
    errors.city = "Select City";
  }
  if(!warehouse.pincode){
    errors.pincode = "Enter PinCode";
  }
  return errors;
};
  
  export default warehouseValidator;