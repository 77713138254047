import { observer } from "mobx-react";
import { Spinner } from "react-bootstrap";

const ProgressBar=observer(():JSX.Element=>{
return(
<div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
);
});

export default ProgressBar;