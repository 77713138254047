
function categoryAssignValidation(teamMaster,teamofMaster){
    let errors :any={};
    
      
      if(!teamMaster.teamMasterName){
        errors.teamMasterName = "Enter Name";
      }
      if(!teamofMaster.employeeId){
        errors.employeeName = "Select Employee Name";
      }
    return errors;
  };
    
    export default categoryAssignValidation;