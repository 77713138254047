export class IAssignMemberShipSave {
    assignMembershipid?: number;
    memberid?: string;
    mobileno?: string;
    planid?: number;
    duration?: string;
    amount?: number;
    effectfrom?: string;
    effectto?: string;
    paymemntmode?: string;
    memberName?: string;
    planName?: string;
}