import { observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';

class MachineMasterStore {
    @observable machineMasterList = new Map();
    @observable packingPlanList = new Map();

    getPackingPlanList = async () => {
        try {
            const stocks = await apiAgent.machineMaster.getPackingPlanList();
            runInAction(() => {
                this.packingPlanList = JSON.parse(stocks);
            });
            return this.packingPlanList;
        }
        catch (error) {
            console.log(error);
        }
    }
    packingPlanSave = async (body) => {
        try {
            const status = await apiAgent.machineMaster.packingPlanSave(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getMachineMasterList = async () => {
        try {
            const stocks = await apiAgent.machineMaster.getMachineMasterList();
            runInAction(() => {
                this.machineMasterList = JSON.parse(stocks);
            });
            return this.machineMasterList;
        }
        catch (error) {
            console.log(error);
        }
    }
    machineMasterSave = async (body) => {
        try {
            const status = await apiAgent.machineMaster.machineMasterSave(body);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deletePackingPlan = async (Packingid) => {
        try {      
        const status=  await apiAgent.machineMaster.deletePackingPlan(Packingid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default MachineMasterStore;