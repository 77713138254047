import React, { useState } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useStores from "../../hooks";

class ILeaveSettingMaster {
    year?: number = 0
    leaveid?: number = 0
    category?: string = ""
    cacualleave?: string = ""
    sickleave?: string = ""
    earnedleave?: string = ""
}

const LeaveSettingMaster = () => {

    const navigate = useNavigate();

    const { payrollStore } = useStores()

    const [leaveMaster, setLeaveMaster] = useState<ILeaveSettingMaster>({})

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    function handleInputChange(event) {
        const { name, value } = event.target;
        setLeaveMaster({ ...leaveMaster, [name]: name === 'year' ? Number(value) : value })
    }

    async function handleSaveLeaveMaster() {
        let error: any = {}
        error = validation(leaveMaster)
        setErrors({ ...error })

        if (Object.keys(error)?.length === 0) {
            leaveMaster.leaveid = 0;
            setLoading(true)
            const status = await payrollStore?.saveLeaveMaster(leaveMaster)
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function validation(leaveMaster) {
        let error: any = {};
        if (!leaveMaster?.year) {
            error.year = "Enter Year";
        }
        if (!leaveMaster?.cacualleave) {
            error.cacualleave = "Enter Casual Leave";
        }
        if (!leaveMaster?.category) {
            error.category = "Select Category";
        }
        if (!leaveMaster?.earnedleave) {
            error.earnedleave = "Enter Earned Leave";
        }
        if (!leaveMaster?.sickleave) {
            error.sickleave = "Enter Sick Leave";
        }
        return error
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate("/Admin/LeaveSetting/leaveSettingList");
    };

    function handleToDeductionList() {
        navigate("/Admin/LeaveSetting/leaveSettingList");
    }


    // Get the current year
    const currentYear = new Date().getFullYear();

    // Create an array of the next five years with the specified model
    const yearsArray = Array.from({ length: 10 }, (_, index) => {
        const year = currentYear + index;
        return { key: Number(year), value: year.toString() };
    });
    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        <div className="hrBox">
                            <h3>Leave Setting Master</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Year<span>*</span></label>
                                        <select className="form-selected full-width" name="year" style={{ fontSize: "16px" }}
                                            value={leaveMaster?.year} onChange={handleInputChange}
                                        >
                                            <option value="" selected> - - Select - -</option>
                                            {yearsArray?.map((obj) => {
                                                return (
                                                    <option value={`${obj?.key}`}>{obj?.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors.year && (<p style={{ color: "red" }}>{errors.year}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-8">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Category<span>*</span> </label>
                                        <select className="form-selected full-width" name="category" style={{ fontSize: "16px" }}
                                            value={leaveMaster?.category} onChange={handleInputChange}
                                        >
                                            <option value="" selected>- - - Select - - -</option>
                                            <option value="Labour">Labour</option>
                                            <option value="Staff">Staff</option>
                                        </select>
                                    </div>
                                    {errors.category && (<p style={{ color: "red" }}>{errors.category}</p>)}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Casual Leave <span>*</span>  </label>
                                        <input
                                            type="text"
                                            name="cacualleave"
                                            value={leaveMaster?.cacualleave} onChange={handleInputChange}
                                            className="brandinput"
                                            placeholder="Days"
                                        ></input>
                                        {errors.cacualleave && (<p style={{ color: "red" }}>{errors.cacualleave}</p>)}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Sick Leave <span>*</span></label>
                                        <input
                                            type="text"
                                            name="sickleave"
                                            value={leaveMaster?.sickleave} onChange={handleInputChange}
                                            className="brandinput"
                                            placeholder="Days"
                                        ></input>
                                        {errors.sickleave && (<p style={{ color: "red" }}>{errors.sickleave}</p>)}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Earned Leave <span>*</span> </label>
                                        <input
                                            type="text" name="earnedleave"
                                            value={leaveMaster?.earnedleave} onChange={handleInputChange}
                                            className="brandinput"
                                            placeholder="Days"
                                        ></input>
                                        {errors.earnedleave && (<p style={{ color: "red" }}>{errors.earnedleave}</p>)}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToDeductionList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveLeaveMaster}>
                                Submit
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </div>
    )
}

export default LeaveSettingMaster;