import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { ITeamMaster, ITeamMasters } from './model';
import { Autocomplete, TextField } from '@mui/material';
import teamMasterValidation from './validation';
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { removeDuplicates } from '../../common/shared/utils';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';


const TeamMaster = observer((): JSX.Element => {
  const { supplierMasterStore, commonStore, teamMasterStore } = useStores();
  const { getTeamMasterDetails } = teamMasterStore;
  const [teamMaster, setTeamMaster] = useState<ITeamMaster>({});
  const [teamofMaster, setTeamofMaster] = useState<ITeamMasters>({});
  const [teamofMasters, setTeamofMasters] = useState<ITeamMasters[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const { loadEmployee, loadEmployees, } = commonStore;
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [submitbtn, setSubmitbtn] = useState(false);
  const { teamId } = useParams<{ teamId: string }>();
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false);

  function handleChangeInput(event: any) {
    const { name, value } = event.target;

    setTeamMaster({ ...teamMaster, [name]: value })
  }

  function addTeamMaster(e) {
    e.preventDefault();

    let error: any = {};
    error = teamMasterValidation(teamMaster, teamofMaster);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      teamofMaster.employeeId = teamofMaster.employeeId
      setTeamofMasters([...teamofMasters, teamofMaster])
      // setTeamMaster({...teamofMaster, employeeDetails: })
      setTeamofMaster({ ...teamofMaster, employeeId: 0, employeeName: '' });
      setSubmitbtn(true);
    }
  }

  function handleRemove(index) {
    teamofMasters.splice(index, 1)
    setTeamofMasters(_.cloneDeep(teamofMasters))
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setTeamofMaster({ ...teamofMaster, [name]: val.employeeId, ["employeeName"]: val.employeeName });
    } else {
      teamofMaster.employeeName = '';
    }
  }

  async function deleteTeamMasterDetail(teamId: Number) {
    await teamMasterStore.deleteTeamMasterDetail(teamId)
  }

  async function saveTeamMaster(e) {
    if (teamId) {
      teamMaster.employeeDetails = teamofMasters
      const status = await teamMasterStore.saveTeamMaster(teamMaster);
      if (status === "Success") {
        setSuccessModal(true)
      }
      else {
        setFailureModal(true);
      }
    }
    else {
      teamMaster.employeeDetails = teamofMasters
      const status = await teamMasterStore.saveTeamMaster(teamMaster);
      if (status === "Success") {
        setSuccessModal(true)
      }
      else {
        setFailureModal(true);
      }
    }


  }
  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/TeamMaster/TeamMasterDetail');
  }

  const handleCancel = () => {
    navigate('/Admin/TeamMaster/TeamMasterDetail');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  async function fetchPreLoadngData() {
    setLoading(false)
    await loadEmployee();
  }

  async function getTeamMasterDet() {
    if (teamId) {
      const data: ITeamMaster[] = await teamMasterStore.getTeamMaster();
      const formattedData = Array.from(data);
      console.log(JSON.stringify(formattedData, null, 2), 'data');
      const dataDet = await getTeamMasterDetails(teamId);
      console.log(dataDet, 'dataDet');
      setTeamofMasters(dataDet);
      console.log(teamofMasters, 'teamofMasters');

      const matchedUser = formattedData.find(team => Number(team.teamMasterId) === Number(teamId));
      if (matchedUser) {
        const updatedDetails = {
          ...teamMaster,
          teamMasterId: matchedUser.teamMasterId,
          teamMasterName: matchedUser.teamMasterName,
        };
        setTeamMaster(updatedDetails);
        console.log(teamMaster, 'teamMasterup');

      } else {
        console.error(`No user found with teamMasterId: ${teamId}`);
      }
    }
  }

  useEffect(() => {
    console.log(teamMaster, 'chkTeammat');
  }, [teamMaster]);



  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchPreLoadngData();
  //     getTeamMasterDet();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Team_master';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadngData();
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/outlet/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);


  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <>
        {/* {
         
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <> */}
        <div className='container'>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox'>
            <div className='hrBox' >
              <h3>Team Master</h3>
            </div>
            <div className='inputBoxLists pd-b-0'>
              <div className='LgInputListsBox mr-left-0'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='inputBox'>
                      <label>Team Name<span>*</span></label>
                      <input type="text" className=''
                        name='teamMasterName'
                        value={teamMaster.teamMasterName}
                        onChange={handleChangeInput}
                        style={{ width: "100%" }} placeholder='Team Name..'></input>
                      {errors.teamMasterName && <p style={{ color: 'red' }}>{errors.teamMasterName}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-md-8'>
                    <div className='inputBox'>
                      <label>Employee Name  <span>*</span></label>
                      <AutoComplete getOptionLabel='employeeName' options={removeDuplicates(loadEmployees, 'employeeName')}
                        emitOption={(option) => handleEmployeeValue('employeeId', {}, option)}
                        value={teamofMaster?.employeeName} placeholder='Select Employee..' />
                      {errors.employeeName && <p style={{ color: 'red' }}>{errors.employeeName}</p>}

                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-2'>
                    <button className='dfBtn' onClick={addTeamMaster}>Add</button>
                  </div>
                  <div className='col-md-12'>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Employee Name </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {teamofMasters?.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td>{val.employeeName}</td>
                                <td><button style={{ border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '600', borderRadius: '6px' }}
                                  type='submit' onClick={() => handleRemove(key)}>Remove</button></td>
                              </tr>
                            )
                          })
                          }
                        </tbody>
                      </table>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>

                </div>
              </div>
              <div className='vertical-space-10'></div>
            </div>
            <div className='btnBox'>

              <button className='secondaryBtn' onClick={handleCancel}>List</button>
              <button className='dfBtn' type='submit' onClick={saveTeamMaster} >Submit</button>
            </div>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../gsmecom/images/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
});

export default TeamMaster;
