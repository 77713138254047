import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';

class IResumeBankStore {
    constructor() {
        makeObservable(this);
    }

    saveResumeBank = async (resumeBank) => {
        try {
            const status = await apiAgent.resumeBank.saveResumeBank(resumeBank);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveResumeImage = async (resumeId, resumeImage, photo) => {
        const resumeFormData = new FormData();
        const resumeExt = resumeImage.name.split('.').pop();
        resumeFormData.append('file', resumeImage, resumeId + '.' + resumeExt);

        const photoFormData = new FormData();
        const photoExt = resumeImage.name.split('.').pop();
        photoFormData.append('file', resumeImage, resumeId + '.' + photoExt);

        try {
            const status = await apiAgent.resumeBank.saveResumeImage(resumeId, resumeFormData, photoFormData);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveResumeShorlisting = async (resumeBank) => {
        try {
            const status = await apiAgent.resumeBank.saveResumeShorlisting(resumeBank);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadResumeBankMasterDetail = async () => {
        try {
            const resumeBankMaster = await apiAgent.resumeBank.loadResumeBank();
            const candidateMaster = await apiAgent.resumeBank.loadCandidatedetails();
            const contactMaster = await apiAgent.resumeBank.loadcontactdetails();
            const keySkillMaster = await apiAgent.resumeBank.loadkeyskills();
            const languageMaster = await apiAgent.resumeBank.loadlanguage();
            const experienceMaster = await apiAgent.resumeBank.loadworkexperience();

            const mergedData = mergeEmployeeDetails(JSON.parse(resumeBankMaster), JSON.parse(candidateMaster), JSON.parse(contactMaster),
                JSON.parse(keySkillMaster), JSON.parse(languageMaster), JSON.parse(experienceMaster)
            )

            console.log(mergedData, 'mergedData>>>')

            return mergedData;

        } catch (error) {
            console.log(error);
        }
    }

    loadResumeBank = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadResumeBank();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadCandidatedetails = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadCandidatedetails();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadcontactdetails = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadcontactdetails();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadkeyskills = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadkeyskills();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadlanguage = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadlanguage();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadworkexperience = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadworkexperience();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadResumeBankshortlist = async () => {
        try {
            const jobMaster = await apiAgent.resumeBank.loadResumeBankshortlist();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }


}

export default IResumeBankStore

const mergeEmployeeDetails = (
    resumebankMaster: any[], familyDetails: any[],
    officialDetails: any[], salaryDetails: any[],
    educationDetails: any[], experienceDetails: any[],
): any[] => {

    let resumeBankMasterDeatail: any[] = Object.assign([], resumebankMaster);

    familyDetails?.forEach((family) => {
        const { ResumBankid, ...familyInfo } = family;
        resumeBankMasterDeatail?.forEach((employee) => {
            const { Resumcode, ...employeeInfo } = employee;
            if (Resumcode == ResumBankid) {
                if (!employee?.candidate?.length) {
                    employee.candidate = []
                }
                employee.candidate.push(familyInfo)
                employee['Candidatename'] = familyInfo?.Candidatename;
                employee['NoticePeriod'] = familyInfo?.NoticePeriod;
            }
        })
    })

    officialDetails?.forEach((family) => {
        const { ResumBankid, ...familyInfo } = family;
        resumeBankMasterDeatail?.forEach((employee) => {
            const { Resumcode, ...employeeInfo } = employee;
            if (Resumcode == ResumBankid) {
                if (!employee?.contact?.length) {
                    employee.contact = []
                }
                employee.contact.push(familyInfo)
                employee['Mobile'] = familyInfo?.Mobile;
                employee['Email'] = familyInfo?.Email;
            }
        })
    })

    salaryDetails?.forEach((family) => {
        const { ResumBankid, ...familyInfo } = family;
        resumeBankMasterDeatail?.forEach((employee) => {
            const { Resumcode, ...employeeInfo } = employee;
            if (Resumcode == ResumBankid) {
                if (!employee?.keySkill?.length) {
                    employee.keySkill = []
                }
                employee.keySkill.push(familyInfo)
            }
        })
    })

    educationDetails?.forEach((family) => {
        const { ResumBankid, ...familyInfo } = family;
        resumeBankMasterDeatail?.forEach((employee) => {
            const { Resumcode, ...employeeInfo } = employee;
            if (Resumcode == ResumBankid) {
                if (!employee?.language?.length) {
                    employee.language = []
                }
                employee.language.push(familyInfo)
            }
        })
    })

    experienceDetails?.forEach((family) => {
        const { ResumBankid, ...familyInfo } = family;
        resumeBankMasterDeatail?.forEach((employee) => {
            const { Resumcode, ...employeeInfo } = employee;
            if (Resumcode == ResumBankid) {
                if (!employee?.employExperience?.length) {
                    employee.employExperience = []
                }
                employee.employExperience.push(familyInfo)
            }
        })
    })

    const mergedEmployees: any[] = resumeBankMasterDeatail;
    return mergedEmployees;
};