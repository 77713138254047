import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { TextField, Autocomplete } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Ecom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { IMemberLogin } from './model';
import { Country, State, City } from 'country-state-city';
import memberValidator from './validation';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import useEcomStores from '../hooks/index';
import useStores from '../../gsmadmin/hooks';
import AutoComplete from '../../gsmadmin/common/shared/autoComplete';
import { removeDuplicates } from '../../gsmadmin/common/shared/utils';

const Registration = observer((): JSX.Element => {

  const navigate = useNavigate();

  const { ecomMemberStore } = useEcomStores();

  const { memberStore } = useStores();

  const [cityOptions, setCityOptions] = useState([]);
  const [memberDetail, setMemberDetail] = useState<IMemberLogin>({})

  const [memberPlanList, setMemberPlanList] = useState<any[]>([])
  const [errors, setErrors] = useState<any>({});

  const [isLoading, setLoading] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const getStateCountry = (e: any, value: any) => {
    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      memberDetail.country = countries[0].name
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      memberDetail.state = states[0].name;
      memberDetail.city = value.name;
    } else {
      memberDetail.country = '';
      memberDetail.state = '';
    }
  }

  async function initialApiCall() {
    const membershipPlanList = await memberStore.getMembershipPlan(0);
    setMemberPlanList([...membershipPlanList])
  }

  const isCurrenPage = useRef(true);

  useEffect(() => {
    if (isCurrenPage.current) {
      initialApiCall();
      isCurrenPage.current = false;
    }
    return () => { }
  }, [])

  useEffect(() => {
    let getCities: any;
    getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
    setCityOptions(getCities);
  }, [cityOptions])

  const handleInputChange = async (event: any) => {
    const { name, value } = event.target;
    if (name === 'dob') {
      memberDetail.age = getAge(value);
    }
    setMemberDetail({ ...memberDetail, [name]: value })
  }

  function handleCutomerType(value) {
    setMemberDetail({ ...memberDetail, customerCategory: value?.Membershipid?.toString(), customerType: value?.PlanName })
  }

  const saveMember = async () => {
    let error: any = {};
    error = memberValidator(memberDetail);
    setErrors(error);
    debugger
    if (Object.keys(error).length === 0) {
      setLoading(true);
      memberDetail.registrationType = "Online";
      const status = await ecomMemberStore.saveEcomMember(memberDetail);
      ecomMemberStore.memberResponse = status;
      if (status.message === "Success") {
        setLoading(false);
        setSuccessModal(true);
      } else {
        setLoading(false);
        setFailureModal(true)
      }
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    navigate('/otp');
  }
  const handleBack = () => {
    navigate('/login');
  }

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className="Login">
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3>Online Registration</h3>
                </div>
                <div className='inputBoxLists'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <div className='inputBox'>
                        <label>Your / Bussiness Name <span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }} placeholder='Member / Bussiness Name..'
                          value={memberDetail.memberName} name='memberName'
                          onChange={handleInputChange} required></input>
                      </div>
                      {errors.memberName && <p style={{ color: 'red' }}>{errors.memberName}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Gender <span>*</span></label>
                        <select className="form-select" id="gender"
                          value={memberDetail.gender} onChange={handleInputChange} name="gender">
                          <option>Not Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Not Applicable</option>
                        </select>
                        {errors.gender && <p style={{ color: 'red' }}>{errors.gender}</p>}
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Membership Plan <span>*</span></label>
                        <AutoComplete options={removeDuplicates(memberPlanList, 'PlanName')} getOptionLabel='PlanName' emitOption={(option) => handleCutomerType(option)} placeholder='Choose Customer Plan' />
                        {errors.customerCategory && <p style={{ color: 'red' }}>{errors.customerCategory}</p>}
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Door No / Street </label>
                        <input type="text" className='fullInput'
                          value={memberDetail.doorNo} name='doorNo'
                          onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Enter Door No / Street..'></input>
                      </div>
                      {errors.doorNo && <p style={{ color: 'red' }}>{errors.doorNo}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Area</label>
                        <input type="text" className='fullInput'
                          value={memberDetail.area} name='area'
                          onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Enter Your Area..'></input>
                      </div>
                      {errors.area && <p style={{ color: 'red' }}>{errors.area}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>City <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={cityOptions}
                          getOptionLabel={(option: any) => option.name}
                          onChange={getStateCountry}
                          value={memberDetail.city}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '21ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select City'
                              name='city'
                            />}
                        />
                      </div>
                      {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>State </label>
                        <input type="text" className='fullInput' style={{ width: "100%" }}
                          value={memberDetail.state} name='state' disabled
                          onChange={handleInputChange} placeholder='Enter State..'></input>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Country </label>
                        <input type="text" className='fullInput' style={{ width: "100%" }}
                          value={memberDetail.country} name='country' disabled
                          onChange={handleInputChange}
                          placeholder='Enter Country..'></input>
                        {errors.country && <p style={{ color: 'red' }}>{errors.country}</p>}
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>PIN Code<span>*</span> </label>
                        <input type="text" className='fullInput' style={{ width: "100%" }}
                          value={memberDetail.pinCode} name='pinCode'
                          onChange={handleInputChange}
                          placeholder='Enter PIN..'></input>
                        {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Mobile No <span>*</span></label>
                        <input type="text" className='' style={{ width: "100%" }}
                          value={memberDetail.mobileNo} name='mobileNo'
                          onChange={(ee)=>ee?.target?.value?.length < 11 && handleInputChange(ee)}
                          placeholder='Your Mobile No..'></input>
                      </div>
                      {errors.mobileNo && <p style={{ color: 'red' }}>{errors.mobileNo}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Pan Number</label>
                        <input type="text" className='fullInput'
                          value={memberDetail.panNo} name='panNo'
                          onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Pan Number..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Aadhar Number</label>
                        <input type="text" className='fullInput'
                          value={memberDetail.aadharNo} name='aadharNo'
                          onChange={(ee)=>ee?.target?.value?.length < 13 && handleInputChange(ee)}
                          style={{ width: "100%" }} placeholder='Aadhar Number..'></input>
                        {errors.aadharNo && <p style={{ color: 'red' }}>{errors.aadharNo}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Date Of Birth<span>*</span></label>
                        <input type="date" style={{ width: "100%" }}
                          value={memberDetail.dob} name='dob'
                          onChange={handleInputChange}
                          placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      {errors.dob && <p style={{ color: 'red' }}>{errors.dob}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Age</label>
                        <input type="text" className='fullInput' disabled
                          value={memberDetail.age} name='age'
                          onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Age..'></input>
                      </div>
                      {errors.age && <p style={{ color: 'red' }}>{errors.age}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Anniversary Date</label>
                        <input type="date" style={{ width: "100%" }}
                          value={memberDetail.anniverseryDate} name='anniverseryDate'
                          onChange={handleInputChange}
                          placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      {errors.anniverseryDate && <p style={{ color: 'red' }}>{errors.anniverseryDate}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-8'>
                      <div className='inputBox'>
                        <label>Email<span>*</span></label>
                        <input type="text" className='fullInput'
                          value={memberDetail.email} name='email'
                          onChange={handleInputChange}
                          style={{ width: "100%" }} placeholder='Enter Your Email..'></input>
                      </div>
                      {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                      <div className='vertical-space-10'></div>
                    </div>
                  </div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleBack}>Cancel</button>
                  <button className='dfBtn' type='submit'
                    onClick={saveMember}
                  ><span>Get OTP</span></button>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../image/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../image/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              <div className='vertical-space-20'></div>
            </div>
          </>
      }
    </>

  );
})

export default Registration;
