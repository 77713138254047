import { IIResignationMaster } from "../model";

export function saveValidation(resignation: IIResignationMaster) {
    let error: any = {}
    if (!resignation?.rM_DegnID) {
        error.rM_DegnID = 'Select Designation'
    }
    if (!resignation?.rM_DeptID) {
        error.rM_DeptID = 'Select Department'
    }
    if (!resignation?.rM_EmpID) {
        error.rM_EmpID = 'Select Employee'
    }
    if (!resignation?.rM_ManagerID) {
        error.rM_ManagerID = 'Select Manager'
    }
    if (!resignation?.rM_Reason) {
        error.rM_Reason = 'Enter Reason'
    }
    return error
}