import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import moment from 'moment';
import { Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { currentPageDatas, removeDuplicates } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import Cookies from 'js-cookie';

const SalesOrderList = observer((): JSX.Element => {

    const navigate = useNavigate();
    const { posMasterStore, invoiceStore, itemMasterStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const [isLoading, setLoading] = useState(true);
    const [isSaleDetailModal, setSaleDetailModal] = useState(false);

    const [itemMasterJson, setItemMasterJson] = useState<any[]>([])
    const [taxMaster, setGetTaxMaster] = useState<any[]>([])


    const [salesOrderList, setSalesOrderList] = useState<any[]>([])
    const [salesOrderDetList, setSalesOrderDetList] = useState<any[]>([])
    const [salesLedgerList, setSalesLedgerList] = useState<any[]>([])
    const [selectedLedgerList, setSelectedLedgerList] = useState<any[]>([])

    console.log(salesOrderDetList, 'salesOrderDetList>>')

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    async function fetchPOSProducts() {
        let outletId = Number(Cookies.get('outletId'));
        if (outletId) {
            const posProductList = await posMasterStore.loadPOSProductList(outletId);
            setItemMasterJson([...posProductList])
        }
    }


    async function fetchPreLoadngData() {
        setLoading(false);
        const [userRights, salesOrderList, salesOrderLedger, getTaxMaster] = await Promise.all([
            userCreationStore?.getUserRight('Sales_order'), invoiceStore?.loadSalesOrederDetails(),
            invoiceStore?.loadSalesOrderLedger(), itemMasterStore.getTaxMaster('InwardLedger')
        ])
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        setSalesOrderList([...salesOrderList])
        setSalesLedgerList([...salesOrderLedger])
        setGetTaxMaster([...getTaxMaster])
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage?.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false
        }
        return () => { }
    }, []);

    const handleCreate = () => {
        navigate('/Admin/SalesOrder')
    }

    const filterDetailItem = async (orderNo, orderId) => {
        let filterList = salesOrderList?.filter((each) => each?.SOrdMNo === orderNo)
        if (filterList?.length) {
            setSalesOrderDetList([...filterList])
        }
        let filterLedger = salesLedgerList?.filter((each) => each?.SaleOrdId === orderId)
        if (filterLedger?.length) {
            setSelectedLedgerList([...filterLedger])
        }
        setSaleDetailModal(true);
    }

    const handleClose = () => {
        setSaleDetailModal(false);
    }

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (salesOrderList && salesOrderList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(salesOrderList)
        }
    }, [salesOrderList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(removeDuplicates(salesOrderList, 'SOrdMNo')?.slice()?.sort((a, b) => b?.SOrdDMID - a?.SOrdDMID), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    function roundOff() {
        let total = 0;
        let roundOffval = 0;
        let roundedVal = 0;
        total = salesOrderDetList?.reduce((a, v) => a = a + Number(v?.SOrdDAmt ?? 0), 0)
        roundedVal = Math.round(salesOrderDetList?.reduce((a, v) => a = a + Number(v?.SOrdDAmt ?? 0), 0))
        roundOffval = Math.abs(total - roundedVal)
        return roundOffval;
    }

    const netAmount = useMemo(() => {
        let itemTotal = salesOrderDetList?.reduce((a, v) => a = a + Number(v?.SOrdDAmt ?? 0), 0)
        let ledgerTotal = selectedLedgerList?.reduce((a, v) => a = a + Number(v?.SaleOrdLedgerAmount ?? 0), 0)

        let totalAmount = Number(itemTotal! ?? 0) + Number(ledgerTotal! ?? 0)

        return Math.round(totalAmount)?.toFixed(2) ?? 0
    }, [selectedLedgerList])

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>

                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={salesOrderList}
                                searchTitles={[{ key: "SOrdMNo", value: "S.Ord.No" }, { key: "membername", value: "Customer Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{salesOrderList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope='col'>S.Ord No.</th>
                                            <th scope='col'>Date</th>
                                            <th scope='col'>Address</th>
                                            <th scope='col'>Contact Person</th>
                                            <th scope='col'>Contact No</th>
                                            <th scope='col'></th>
                                            <th scope='col'>Create </th>
                                            <th scope='col'>Update </th>
                                            <th scope='col'>Delete </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? removeDuplicates(filteredData, 'SOrdMNo') : currentPageData)?.length ?
                                            (isSearch ? removeDuplicates(filteredData, 'SOrdMNo') : currentPageData).map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.SOrdMNo}</td>
                                                        <td>{moment(val.SOrdMDate).format('YYYY-MM-DD')}</td>
                                                        <td>{`${val?.doorno} ${val?.area} ${val?.city} ${val?.state} ${val?.pin}`}</td>
                                                        <td>{val.membername}</td>
                                                        <td>{val.mobileno}</td>
                                                        <td><button disabled={!userRights?.view} onClick={() => filterDetailItem(val?.SOrdMNo, val?.SOrdDMID)}>Detail</button></td>
                                                        <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                    </tr>
                                                )
                                            }) : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <Modal show={isSaleDetailModal} onHide={handleClose} className='PriceHistoryModel'>
                            <Modal.Header closeButton>
                                <Modal.Title>Item Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No.</th>
                                                <th scope="col">ItemName</th>
                                                <th scope="col"> Quantity </th>
                                                <th scope="col">MRP</th>
                                                <th scope="col">Rate </th>
                                                <th scope="col">Discount</th>
                                                <th scope="col">Gst Amount</th>
                                                <th scope="col">Gst % </th>
                                                <th scope="col">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salesOrderDetList?.length ?
                                                salesOrderDetList?.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{val?.itemname}</td>
                                                            <td>{val?.SOrdDQty}</td>
                                                            <td>{val?.SOrdDMRP}</td>
                                                            <td>{val?.SOrdDRate}</td>
                                                            <td>{val?.SOrdDDis}</td>
                                                            <td>{val?.SOrdDGSTAmt}</td>
                                                            <td>{val?.SOrdDGST}</td>
                                                            <td>{val?.SOrdDAmt}</td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                            <tr>
                                                <td colSpan={7}></td>
                                                <td>Subtotal: </td>
                                                <td>{salesOrderDetList?.reduce((a, v) => a = a + Number(v?.SOrdDAmt ?? 0), 0)?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={7}></td>
                                                <td>Round Off: </td>
                                                <td>{roundOff()?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={7}></td>
                                                <td>Net Total: </td>
                                                <td>{Math.round(salesOrderDetList?.reduce((a, v) => a = a + Number(v?.SOrdDAmt ?? 0), 0))?.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Header >
                                <Modal.Title>Ledger Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='tableBox'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">S.No.</th>
                                                <th scope="col">Ledger</th>
                                                <th scope="col"> Amount </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedLedgerList?.length ?
                                                selectedLedgerList?.map((val, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{key + 1}</td>
                                                            <td>{taxMaster?.find((each) => each?.ledgerId === val?.SaleOrdLedgerId)?.ledgerName ?? ""}</td>
                                                            <td>{val?.SaleOrdLedgerAmount}</td>
                                                        </tr>
                                                    )
                                                }) : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <h5>
                                    <span>Net Amount: </span>
                                    <span>{netAmount}</span>
                                </h5>
                            </Modal.Footer>
                        </Modal>
                    </>
            }
        </>
    );

});

export default SalesOrderList;
