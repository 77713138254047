import { IAssignMemberShipSave } from "../model";

export function SubmitValidation(assignMembrship: IAssignMemberShipSave) {
    let error: any = {};

    if (!assignMembrship.planid) {
        error.planid = "Select Plan Name"
    }
    if (!assignMembrship.duration) {
        error.duration = "Select Duration"
    }
    if (!assignMembrship.effectfrom) {
        error.effectfrom = "Choose Purchased On"
    }
    if (!assignMembrship.memberid) {
        error.memberid = "Select Member"
    }
    if (!assignMembrship.paymemntmode) {
        error.paymemntmode = "Select Payment Mode"
    }

    return error;
}