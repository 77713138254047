import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { IIResignationApproval } from "./model";
import useStores from "../../hooks";
import moment from "moment";

const ResignationApproval = () => {

    const { employeeStore, payrollStore, departmentStore, designationStore, employeePromotionStore, jobMasterStore } = useStores();

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [resignationList, setResignationList] = useState<any[]>([])

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [promotionApprovalList, setPromotionApprovalList] = useState<IIResignationApproval[]>(new Array<IIResignationApproval>())
    const [approvalDate, setApprovalDate] = useState(new Date()?.toISOString()?.substr(0, 10))

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
        setSuccessModal(false)
    }

    function handleSelectManager(event, each) {
        let error: any = {}
        if (each?.checked) {
            promotionApprovalList?.forEach((obj) => {
                if (obj?.rM_ID === each?.RM_ID) {
                    obj['rM_ApprovedBy'] = event?.target?.value;
                }
                return obj
            });
            resignationList?.forEach((obj) => {
                if (obj?.RM_ID === each?.RM_ID) {
                    obj['rM_ApprovedBy'] = event?.target?.value;
                }
                return obj;
            })
        } else {
            error.rM_ID = each?.RM_ID;
            error.rM_ApprovedStatus = 'Select Approved Status';
        }
        setResignationList([...resignationList])
        setPromotionApprovalList([...promotionApprovalList])
        setErrors({ ...error })
    }

    async function handleCheckBox(event, each) {
        let error: any = {}
        const { checked } = event?.target;
        if (checked) {
            let findObj: IIResignationApproval = new IIResignationApproval();
            resignationList?.forEach((obj) => {
                if (obj?.RM_ID === each?.RM_ID) {
                    obj['checked'] = true;
                }
                return obj;
            })
            findObj['rM_ApprovedStatus'] = 'Yes';
            findObj['rM_ID'] = each?.RM_ID;
            findObj['rM_EmpID'] = each?.RM_EmpID;
            promotionApprovalList?.push(findObj);
        } else {
            let findIndex = promotionApprovalList?.findIndex((obj) => obj?.rM_ID === each?.RM_ID)
            if (findIndex !== -1) {
                promotionApprovalList?.splice(findIndex, 1)
            }
            resignationList?.forEach((obj) => {
                if (obj?.RM_ID === each?.RM_ID) {
                    obj['checked'] = false;
                }
                return obj;
            })
        }
        setErrors({ ...error })
        setResignationList([...resignationList])
        setPromotionApprovalList([...promotionApprovalList])
    }

    async function handleSaveCategory() {
        let error: any = {}
        if (promotionApprovalList?.length) {
            promotionApprovalList?.forEach((each) => {
                each['rM_ApprovedDate'] = approvalDate;
                return each;
            })
            const status = await employeePromotionStore?.saveResignationApproval(promotionApprovalList)
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            if (!promotionApprovalList?.length) {
                error.rM_ApprovedBy = 'Select Approved By'
            }
        }
        setErrors({ ...error })
    }

    function handleToPromotionApprovalMasterListList() {
        navigate('/Admin/PromotionApproval/List')
    }

    async function additionalApis() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        setEmployeeMaster([...employeeMaster])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
    }

    async function initialApiCall() {
        const resignationList = await employeePromotionStore?.loadResignationMaster()
        if (resignationList?.length) {
            setResignationList([...resignationList])
        }
        setLoading(false)
        additionalApis()
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Resignation Approval Master</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Approval Date <span>*</span></label>
                                            <input type="date" className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY" data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="rM_ApprovedDate"
                                                onChange={(event) => setApprovalDate(event?.target?.value)}
                                                value={approvalDate}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Date of Joining</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Resignation Request</th>
                                            <th scope="col">Manager</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Is Approved</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resignationList?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{moment(each?.RM_Date).format('DD-MMM-YYYY')}</td>
                                                    <td>{each?.Employee_Name}</td>
                                                    <td>{moment(each?.RM_DOJ).format('DD-MMM-YYYY')}</td>
                                                    <td>{designationList?.find((cate) => cate?.designationId === each?.RM_DegnID)?.designationName}</td>
                                                    <td>{departmentList?.find((cate) => cate?.departmentId === each?.RM_DeptID)?.department}</td>
                                                    <td>{moment(each?.RM_ResignReqDate).format('DD-MMM-YYYY')}</td>
                                                    <td>{employeeMaster?.find((emp) => emp?.EmployeeId === each?.RM_ManagerID)?.Employee_Name}</td>
                                                    <td>{each?.RM_Reason}</td>
                                                    <td className='row'>
                                                        <div className="col-md-12">
                                                            <div className='inputBox'>
                                                                <select className="full-width" name="type"
                                                                    value={each?.rM_ApprovedBy}
                                                                    onChange={(event) => handleSelectManager(event, each)} id="Openingtype" >
                                                                    <option value="" selected>--Select--</option>
                                                                    {employeeMaster?.map((each) => {
                                                                        return (
                                                                            <option value={each?.Employee_Name}>{each?.Employee_Name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            {((errors?.rM_ID === each?.RM_ID) && errors?.rM_ApprovedBy) ?
                                                                <p style={{ color: 'red' }}>{errors?.rM_ApprovedBy}</p> : null}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="checkbox" onChange={(event) => handleCheckBox(event, each)}></input>
                                                        {((errors?.rM_ID === each?.RM_ID) && errors?.rM_ApprovedStatus) && <p style={{ color: 'red' }}>{errors?.rM_ApprovedStatus}</p>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleToPromotionApprovalMasterListList}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </div>
    )
}

export default ResignationApproval;