import React, { useState, useEffect, ChangeEvent, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const Employeelist = observer((): JSX.Element => {
  const navigate = useNavigate();

  const { employeeStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [employeeMasterDetails, setEmployeeMasterDetails] = useState<any[]>([])

  // console.log(employeeMasterDetails, 'employeeMasterDetails>')
  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [openModal, setOpenModal] = useState({ bool: false, type: '', id: 0 })

  const familyTableHeads = [
    { key: "Name", value: 'Fname' }, { key: "RelationShip", value: 'Frelationship' }, { key: "DOB", value: 'FDOB' }, { key: "Mobile No", value: 'Fmobileno' }
  ]
  const officialTableHeads = [
    { key: "Company Name", value: 'Companyname' }, { key: "Category", value: 'Category' }, { key: "PF No.", value: 'Pfno' }, { key: "ESI No", value: 'Esino' },
    { key: "Aadhar No.", value: 'Aadhaarno' }, { key: "Dispansary", value: 'Dispansary' }, { key: "PAN Card No", value: 'PANCardNo' }, { key: "UAN No", value: 'UANNo' },
    { key: "Voter ID", value: 'Voterid' }, { key: "DL No", value: 'DLNo' }, { key: "TDS Applicable", value: 'TDSApplicable' }, { key: "PT Applicable", value: 'PTApplicable' },
    { key: "OT Applicable", value: 'OTApplicable' }, { key: "Shift Hours", value: 'Shithours' }
  ]
  const salaryTableHeads = [
    { key: "Payment Mode", value: 'Paymentmode' }, { key: "Salary Type", value: 'Salarytype' }, { key: "Effective Date", value: 'EffectDate' }, { key: "Gross Salary", value: 'GrossSalary' },
    { key: "Earn type", value: 'EarnType' }, { key: "Amount", value: 'Amount' }
  ]
  const experienceTableHeads = [
    { key: "Qualification", value: 'Qualification' }, { key: "Occupation", value: 'Occupation' }, { key: "Last Employer", value: 'LastEmployer' }, { key: "Designation", value: 'Designation' },
    { key: "Year", value: 'year' }, { key: "PF No.", value: 'PFno' }, { key: "ESI No", value: 'ESINo' }, { key: "Last Drawn", value: 'LastDrawn' },
  ]
  const educationTableHeads = [{ key: "Course", value: 'Courses' }, { key: "School/University", value: 'School_Univercity' }, { key: "Year of passing", value: 'yearofpassing' }]
  const contactTableHeads = [{ key: "Mobile no", value: 'Mobileno' }, { key: "Email id", value: 'Emailid' }, { key: "Present Address", value: 'Present_Add' }, { key: 'Permantent Address', value: 'Permantent_Add' }]
  const nomineeTableHeads = [
    { key: "Name", value: 'NomName' }, { key: "DOB", value: 'NomDOB' }, { key: "Age", value: 'NomAge' },
    { key: "Relationship", value: 'Relationship' }, { key: "Mobile", value: 'NomMobileno' }, { key: "Address", value: 'NomAddress' }]



  const deleteEmployee = async (empId) => {
    setLoading(true);
    const status = await employeeStore.deleteEmployee(empId);

    if (status === 'Success') {
      fetchOutletItemData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchOutletItemData();
  }

  const fetchOutletItemData = async () => {
    const userRights = await userCreationStore?.getUserRight('Employee_registration')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const data = await employeeStore.loadEmployeeMaster();
    setEmployeeMasterDetails([...data])
    setLoading(false);
  }

  async function handleOpenModal(type, employeeId) {

  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchOutletItemData();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const editEmployee = (empId) => {
    navigate('/Admin/employeeregistraion/' + empId);
  }

  const handleCreate = () => {
    navigate('/Admin/employeeregistraion');
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  console.log(currentPageData, 'currentPageData>>')
  useEffect(() => {
    if (employeeMasterDetails && employeeMasterDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(employeeMasterDetails)
    }
  }, [employeeMasterDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(employeeMasterDetails?.slice()?.sort((a, b) => Number(b.EmployeeId) - Number(a.EmployeeId)), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };


  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={employeeMasterDetails}
                searchTitles={[{ key: "Employee_Name", value: "Employee Name" }, { key: "Biometric_id", value: "Biometric Id" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='inputBoxLists'>
                <div className='vertical-space-20'></div>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{employeeMasterDetails?.length} Records</button>
                  </div>
                  <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Employee ID </th>
                      <th scope='col'>Employee Name  </th>
                      <th scope='col'> Father Name</th>
                      <th scope='col'>Biometric ID </th>
                      <th scope='col'>DOB  </th>
                      <th scope='col'>DOJ</th>
                      <th scope='col'>Age  </th>
                      <th scope='col'>Deparment</th>
                      <th scope='col'>Designation</th>
                      <th scope='col'>Gender  </th>
                      <th scope='col'>Marital Status</th>
                      <th scope='col'>Nationality</th>
                      <th scope='col'>Religion</th>
                      <th scope='col'>Blood Group</th>
                      <th scope='col'>Height</th>
                      <th scope='col'>Weight</th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length ?
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val?.EmployeeId}</td>
                            <td>{val?.Employee_Name}</td>
                            <td>{val?.Employee_Fname}</td>
                            <td>{val?.Biometric_id}</td>
                            <td>{moment(val?.DoB).format('DD-MMM-YYYY')}</td>
                            <td>{val?.doj}</td>
                            <td>{val?.Age}</td>
                            <td>{val?.department1}</td>
                            <td>{val?.designation1}</td>
                            <td>{val?.Gender}</td>
                            <td>{val?.Maritial_status}</td>
                            <td>{val?.Nationality}</td>
                            <td>{val?.Religion}</td>
                            <td>{val?.Bloodgroup}</td>
                            <td>{val?.Height}</td>
                            <td>{val?.weight}</td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Family', id: val?.EmployeeId })}>Family</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Contact', id: val?.EmployeeId })}>Contact</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Official', id: val?.EmployeeId })}>Official</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Salary', id: val?.EmployeeId })}>Salary</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Experience', id: val?.EmployeeId })}>Experience</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Education', id: val?.EmployeeId })}>Education</button></td>
                            <td><button disabled={!userRights?.view} className='' onClick={() => setOpenModal({ bool: true, type: 'Nominee', id: val?.EmployeeId })}>Nominee</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editEmployee(val?.EmployeeId)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteEmployee(val?.EmployeeId)} /></button></td>
                          </tr>
                        )
                      }) : null
                    }
                  </tbody>
                </table>
              </div>

              <Modal show={openModal?.bool} onHide={() => setOpenModal({ bool: false, type: '', id: 0 })} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>{openModal?.type} Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='tableBox'>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          {(openModal?.type === 'Family' ? familyTableHeads : openModal?.type === 'Official' ? officialTableHeads :
                            openModal?.type === 'Salary' ? salaryTableHeads : openModal?.type === 'Experience' ? experienceTableHeads :
                              openModal?.type === 'Education' ? educationTableHeads : openModal?.type === 'Contact' ? contactTableHeads : nomineeTableHeads)?.map((heads, index) => {
                                return (
                                  <th>{heads?.key}</th>
                                )
                              })}
                        </tr>
                      </thead>
                      <tbody>
                        {employeeMasterDetails?.map((employee, index) => {
                          return employee?.EmployeeId === openModal?.id && (
                            <>
                              {employee[(openModal?.type === 'Family' ? 'employeeFamily' : openModal?.type === 'Official' ? 'employeOfficial' :
                                openModal?.type === 'Salary' ? 'employeSalary' : openModal?.type === 'Experience' ? 'employExperience' :
                                  openModal?.type === 'Education' ? 'employEducation' : openModal?.type === 'Contact' ? 'employeeContact' :
                                    'employeNominee')]?.map((each, ind) => {
                                      return each != undefined && (
                                        // 
                                        <tr>
                                          {(openModal?.type === 'Family' ? familyTableHeads : openModal?.type === 'Official' ? officialTableHeads :
                                            openModal?.type === 'Salary' ? salaryTableHeads : openModal?.type === 'Experience' ? experienceTableHeads :
                                              openModal?.type === 'Education' ? educationTableHeads : openModal?.type === 'Contact' ? contactTableHeads : nomineeTableHeads)?.map((heads, inx) => {
                                                return (
                                                  <td style={{ textTransform: 'capitalize' }}>{isoDateRegex?.test(each[heads?.value]) ? moment(each[heads?.value]).format('DD-MMM-YYYY') : (each[heads?.value])?.toString()}</td>
                                                )
                                              })}
                                        </tr>
                                      )
                                    })}
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>
                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }
    </>
  );
});

export default Employeelist;
