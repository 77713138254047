import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import useStores from '../../hooks';
import moment from 'moment';
import ExpiryRegisterValidation from './validation';
import { currentPageDatas } from '../../common/shared/utils';

interface ExcelSheetData {
    Item_Name: string,
    Batch_No: string,
    Expiry_Date: string,
    Days_Balance: number,
    Qty: number,
}

class ExpiryRegFields {
    fromDate?: string;
    toDate?: string;
    outletId?: number;
    category?: string;
    subCategoryId?: number;
    itemId?: number;
    mdays?: number;
    warehouseId?: number;

}

const ExpiryRegister = observer((): JSX.Element => {

    const { expiryRegStore, commonStore } = useStores();
    const { loadOutlet, loadOutlets, loadSubcategory,
        loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;

    const [loading, setLoading] = useState(true);
    const [searchCriteria, setSearchCriteria] = useState({ itemName: false, category: false, subCategory: false })
    const [expiryRegisterFields, setExpiryRegisterFields] = useState<ExpiryRegFields>(new ExpiryRegFields())
    const [expiryRegisters, setExpiryRegisters] = useState<any[]>([]);
    const [searchValue, setSearchValue] = useState('');
    const [errors, setErrors] = useState<any>({})

    const filteredData = useMemo(() => {
        if (searchValue != '' && searchValue?.length > 0 && expiryRegisters?.length > 0) {
            return expiryRegisters?.filter((stock) => {
                if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
                    return stock;
                }
            })
        } else {
            return [];
        }
    }, [searchValue])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setExpiryRegisterFields({ ...expiryRegisterFields, [name]: name == 'mdays' ? Number(value) : value })
    }

    function handleSearchInput(e) {
        setSearchValue(e.target.value);
    }

    function handleQuoteSearch() {

    }

    function handleChangeInput(event: any) {
        const { name, value } = event.target;

        if (name == 'offerType' && value == '') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: false })
        }
        if (value === 'ItemName') {
            setSearchCriteria({ ...searchCriteria, itemName: true, category: false, subCategory: false })
        }
        if (value == 'Category') {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: true, subCategory: false })
        }
        if (value === "SubCategory") {
            setSearchCriteria({ ...searchCriteria, itemName: false, category: false, subCategory: true })
        }
    }

    const handleOutletValue = (name: any, event: object, val: any) => {
        if (val != null) {
            setExpiryRegisterFields({ ...expiryRegisterFields, [name]: val.outletid });
        }
        else {
            expiryRegisterFields.outletId = 0;
        }
    }

    function handleValue(type: string, event, value) {
        if (type == 'subCategoryId') {
            setExpiryRegisterFields({ ...expiryRegisterFields, [type]: value.subCategoryId });
        } else {
            setExpiryRegisterFields({ ...expiryRegisterFields, [type]: value.itemId });
        }
    }

    async function handleGenerateStock() {
        let error: any = {}
        error = ExpiryRegisterValidation(expiryRegisterFields)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                "outletId": expiryRegisterFields?.outletId,
                "itemId": expiryRegisterFields?.itemId,
                "subCategoryId": expiryRegisterFields?.subCategoryId,
                "category": expiryRegisterFields?.category,
                "fromDate": expiryRegisterFields?.fromDate,
                "toDate": expiryRegisterFields?.toDate,
                "mdays": expiryRegisterFields?.mdays,
                "warehouseId": expiryRegisterFields?.warehouseId,
            }
            const expiryRegisters = await expiryRegStore.loadExpiryRegister(body);
            setExpiryRegisters(Object.assign([], expiryRegisters))
            setErrors({});
        } else {
            setErrors(error)
        }
        setLoading(false);
    }

    function createExportExcelObj(stockSummary: any[]) {
        let excelArr: ExcelSheetData[] = stockSummary?.map((stock) => {
            const excelObj: ExcelSheetData = {
                Item_Name: stock?.itemname,
                Batch_No: stock?.batchNo,
                Expiry_Date: moment(stock?.expiryDate).format('DD-MMM-YYYY'),
                Days_Balance: stock?.edays,
                Qty: stock?.stock,

            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Expiry Register", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Expiry Register ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    async function fetchOutletStockSummary() {
        await loadOutlet();
        await loadItemConversion();
        await loadSubcategory();
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            fetchOutletStockSummary()
            isCurrenPage.current = false
        };
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])


    useEffect(() => {
        if (expiryRegisters?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(expiryRegisters)
        }
    }, [expiryRegisters])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(expiryRegisters, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className='' style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '90%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>Expiry Register</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        {/* <div className="col-sm-1">
                                            <div className='inputBox'>
                                                <label>From Date <span>*</span></label>
                                                <input  type="date" style={{ width: '100%' }}
                                                    name="fromDate"
                                                    onChange={handleInputChange} value={expiryRegisterFields?.fromDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.fromDate && <p style={{color:'red'}}>{errors.fromDate}</p>}
                                        </div>
                                        <div className="col-sm-1">
                                            <div className='inputBox'>
                                                <label>To Date <span>*</span></label>
                                                <input  type="date" style={{ width: "100%" }}
                                                    name="toDate"
                                                    onChange={handleInputChange} value={expiryRegisterFields?.toDate}
                                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                ></input>
                                            </div>
                                            {errors.toDate && <p style={{color:'red'}}>{errors.toDate}</p>}
                                        </div> */}
                                        <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                            <input type="text"
                                                style={{ height: "51px" }}
                                                value={expiryRegisterFields?.mdays}
                                                name="mdays" onChange={handleInputChange}
                                                className="form-control" placeholder="Expiry Days..."
                                            ></input>
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '17px' }}>
                                            <div className="input-group mb-3">
                                                <input type="text"
                                                    style={{ height: "51px" }}
                                                    name='searchValue'
                                                    value={searchValue}
                                                    onChange={handleSearchInput}
                                                    className="form-control" placeholder="Search Here.."
                                                    aria-label="Search Outlet..."
                                                    aria-describedby="search-button"
                                                ></input>
                                                {/* <div className="input-group-append">
                                                    <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                                                        id="search-button">
                                                        <FontAwesomeIcon icon={faSearch}
                                                            onClick={handleQuoteSearch}
                                                        />
                                                    </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                            <div className='inputBox'>
                                                <Autocomplete size="small"
                                                    disablePortal
                                                    id="combo-box-demo"
                                                    options={loadOutlets}
                                                    getOptionLabel={(option: any) => option.outletName}
                                                    onChange={(event, val) => handleOutletValue('outletId', event, val)}
                                                    value={loadOutlets.find((option) => option.outletId == expiryRegisterFields?.outletId)}
                                                    renderInput={(params: any) =>
                                                        <TextField {...params}
                                                            id="outlined-size-small"
                                                            color='info'
                                                            size="small"
                                                            type="text"
                                                            placeholder='Select Outlet Name..'
                                                            name='outletName'
                                                        />
                                                    }
                                                />
                                            </div>
                                            {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                                        </div>
                                        <div className='col-sm-2' style={{ marginTop: '19px' }}>
                                            <div className='inputBox' style={{ paddingRight: "0px", height: "49px" }}>
                                                <select className="form-selected full-width" id="Mode" name="searchfield" onChange={handleChangeInput} >
                                                    <option value="">Select Search Criteria</option>
                                                    <option value="ItemName">Item Name</option>
                                                    <option value="Category">CateGory </option>
                                                    <option value="SubCategory">Sub Category </option>
                                                </select>
                                            </div>

                                        </div>
                                        <div className='col-sm-2' style={{ marginTop: '20px', }}>
                                            {searchCriteria?.category ?
                                                <>
                                                    <div className='inputBox'>
                                                        <select className="form-selected" onChange={handleInputChange} id="Category" name="category">
                                                            <option value="" selected>Not Select</option>
                                                            <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                                                            <option value="Pets">Pets</option>
                                                            <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                                                            <option value="Grocery">Grocery</option>
                                                        </select>
                                                    </div>
                                                    <div className='vertical-space-10'></div>
                                                </> : searchCriteria?.itemName || searchCriteria?.subCategory ?
                                                    <div className='inputBox'>
                                                        <Autocomplete size="small"
                                                            disablePortal
                                                            id="combo-box-demo"
                                                            options={searchCriteria?.itemName ? loadItemConversions : loadSubcategories}
                                                            getOptionLabel={(option: any) => searchCriteria?.itemName ? option.itemName : option.subCategoryName}
                                                            onChange={(event, val) => searchCriteria?.itemName ? handleValue('itemId', event, val) : handleValue('subCategoryId', event, val)}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder={searchCriteria?.itemName ? 'Select Item..' : 'Select Subcategory..'}
                                                                    name={searchCriteria?.itemName ? 'itemId' : 'subcategory'}
                                                                />}
                                                        />
                                                    </div> :
                                                    <div className="">

                                                    </div>
                                            }
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={handleGenerateStock}>Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit' onClick={() => createExportExcelObj(expiryRegisters)}>Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{expiryRegisters?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>

                                                <th scope="col">Item Name</th>
                                                <th scope="col">Batch No</th>
                                                <th scope="col">Expiry Date</th>
                                                <th scope='col'>Days Balance</th>
                                                <th scope="col">Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(searchValue?.length > 0 ? filteredData : currentPageData)?.length > 0 && (searchValue?.length > 0 ? filteredData : currentPageData)?.map((stock, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td scope="col">{stock?.itemname}</td>
                                                        <td scope="col">{stock?.batchNo}</td>
                                                        <td scope="col">{moment(stock?.exp_Date)?.format('DD-MMM-YYYY')}</td>
                                                        <td scope='col'>{stock?.edays}</td>
                                                        <td scope="col">{stock?.stock}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

export default ExpiryRegister;