
function MachineMasterValidation(machineMasterDet) {
  let errors: any = {};
  const numeric = /^[0-9\b]+$/
  const zipCode = /[1-9][0-9]{5}$/
  if (!machineMasterDet.machineName) {

    errors.machineName = "Enter Name";
  }
  if (!machineMasterDet.machineNo) {
    errors.machineNo = "Enter Machine No";
  }
  if (!machineMasterDet.machineMake) {
    errors.machineMake = "Enter Make";
  }
  if (!machineMasterDet.machinemodelno) {
    errors.machinemodelno = "Enter Modal No";
  }
  if (!machineMasterDet.doi) {
    errors.doi = "Enter DOI";
  }
  if (!machineMasterDet.description) {
    errors.description = "Enter Description";
  }
  if (!machineMasterDet.manufact_year) {
    errors.manufact_year = "Enter Manufacturer Year";
  }
  if (!machineMasterDet.department) {
    errors.department = "Enter Department";
  }
  if (!machineMasterDet.machineValue) {
    errors.machineValue = "Enter Machine Value";
  }
  if (!machineMasterDet.maint_Card_No) {
    errors.maint_Card_No = "Enter Maintaince Card No";
  }
  return errors;
};

export default MachineMasterValidation;