import React, { useState, useEffect, useRef, } from 'react';
import { Country, State, City } from 'country-state-city';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import { AssignOrderDetails, IAssignOrder } from './model';
import { Autocomplete, TextField } from '@mui/material';
import useStores from '../../hooks';
import moment from 'moment';
import AssignOrderStore from './store';
import { removeDuplicates } from '../../common/shared/utils';


function AssignOrder() {

  const [singleSelections, setSingleSelections] = useState([]);
  const [assignOrder, setAssignOrder] = useState<IAssignOrder>({});
  const [assignOrderDetail, setAssignOrderDetail] = useState<AssignOrderDetails>({});
  const [assignOrderDetails, setAssignOrderDetails] = useState<AssignOrderDetails[]>([]);
  const { assignOrderStore, commonStore, userCreationStore } = useStores();
  const [isOutelt, setOutelt] = useState(false);
  const [isWarewhouse, setWarewhouse] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isSpinner, setSpinner] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])

  const { loadWarehouse, loadWarehouses, loadOutlet, loadOutlets,
    loadEmployee, loadEmployees, loadAssignOrderDetails, loadAssignOrderDetail, AssignItemDetails,
  } = commonStore;

  const handleCancel = () => {
    navigate('/Admin/AssignOnlineOrder/AssignOrderDetail');
  }

  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignOnlineOrder/AssignOrderDetail');
  }

  const handleAccessModalClose = () => setAccessModal(false);

  const handleWarehouse = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignOrder({ ...assignOrder, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    }
    else {
      assignOrder.wareHouse = '';
    }
  }
  const handleOutletValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignOrder({ ...assignOrder, [name]: val.outletid, ['outletName']: val.outletName });
    }
    else {
      assignOrder.outletName = '';
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignOrder({ ...assignOrder, [name]: val.employeeId, ["employeeName"]: val.employeeName });

    }
    else {
      assignOrder.employeeName = '';
    }
  }

  const handleOrderDetails = async (name: any, event: object, val: any) => {

    setAssignOrder({ ...assignOrder, [name]: val.orderNo, ["orderNoId"]: val.orderNoId, ["orderMasterid"]: val.orderMasterid, ["orderdate"]: val.orderdate, ["itemid"]: val.itemid, ["itemname"]: val.itemname, ["uom"]: val.uom, ["itemQty"]: val.itemQty });
    // assignOrder.orderMasterid=val.orderMasterid;
    setSpinner(true)
    await assignOrderStore.loadAssignOrderItem(val.orderMasterid);
    setSpinner(false)

  }
  const handleItemValue = async (name: any, event: object, val: any) => {

    setAssignOrder({ ...assignOrder, itemid: val.itemid, itemname: val.itemname, itemQty: val.itemqty, uom: val.unitofmeasure })

  }

  const addAssignOrder = () => {

    let newObject: AssignOrderDetails = {};
    assignOrderDetail.orderassignid = assignOrder.orderassignid;
    assignOrderDetail.AssignOrderItemId = assignOrder.itemid;
    assignOrderDetail.AssignOrderItemName = assignOrder.itemname;
    assignOrderDetail.AssignOrderitemQty = assignOrder.itemQty;
    assignOrderDetail.AssignOrderuom = assignOrder.uom;

    setAssignOrderDetails([...assignOrderDetails, assignOrderDetail])
    setAssignOrderDetail(newObject);
  }

  async function fetchOultetItems() {
    setLoading(false);
    await Promise.all([assignOrderStore.getEntryNo('AssignOrder'), loadEmployee(),
    loadWarehouse(), loadOutlet(), loadAssignOrderDetail(), assignOrderStore.loadAssignOrderItem()])
    const userRights = await Promise.all([userCreationStore?.getUserRight('Assign_online_order')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }
  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      setLoadModal(true);
      fetchOultetItems();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);


  const handleFailureModalClose = () => setFailureModal(false);

  const saveAssignOrder = async (e) => {

    e.preventDefault();
    assignOrder.orderAssignOrderdetails = assignOrderDetails
    assignOrder.orderassignid = assignOrderStore.entryNo.entryNo + 1

    const status = await assignOrderStore.saveAssignOrder(assignOrder);
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setAssignOrder({ ...assignOrder, [name]: value });
  };


  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'Assginedin' && value === '') {
      setOutelt(false);
      setWarewhouse(false);
    }
    if (value === 'Outlet') {
      setOutelt(true);
      setWarewhouse(false);
    }
    if (value === 'Warehouse') {
      setOutelt(false);
      setWarewhouse(true);
    }
    setAssignOrder({ ...assignOrder, [name]: value })
  }

  if (isLoadModal) {
    return (
      <div className="container">
        {isAccessModal && <h1>Access Denied</h1>}
        <Modal show={isAccessModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <>
        <div className='vertical-space-20'></div>
        {
          isLoading ? <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
        }
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Assign Online Order</h3>
          </div>
          <div className='inputBoxLists'>
            <div className='row'>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Order Assign ID <span>*</span></label>
                  <input type="text" className='' style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="orderassignid"
                    value={assignOrder.orderassignid = assignOrderStore.entryNo.entryNo + 1}
                    disabled placeholder='Order Assign ID..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input type="date"
                    onChange={handleInputchnage}
                    name="orderassigndate"
                    value={assignOrder.orderassigndate}
                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" ></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>

              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Assigned By </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={removeDuplicates(loadEmployees, 'employeeName')}
                    getOptionLabel={(option: any) => option.employeeName}
                    onChange={(event, val) => handleEmployeeValue('employeeId', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '25ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Employee..'
                        name='employeeId'
                      />}
                  />
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-6'>
                <div className='inputBox'>
                  <label>Assigned In <span>*</span></label>
                  <select className="form-selected" style={{ width: "100%" }} onChange={handleChangeInput} id="Category" name="Orderthrough">
                    <option value="" selected>Select</option>
                    <option value="Outlet">Outlet </option>
                    <option value="Warehouse">Warehouse</option>
                  </select>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              {
                isOutelt ?
                  <>
                    <div className='col-md-6'>
                      <label>Outlet Name <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadOutlets}
                        getOptionLabel={(option: any) => option.outletName}
                        onChange={(event, val) => handleOutletValue('outletid', event, val)}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '25ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Outlet Name..'
                            name='outletid'
                          />}
                      />
                    </div>
                  </> : null
              }
              {
                isWarewhouse ?
                  <>
                    <div className='col-md-6'>
                      <label>Warehouse <span>*</span></label>
                      <Autocomplete size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={loadWarehouses}
                        getOptionLabel={(option: any) => option.warehouseName}
                        onChange={(event, val) => handleWarehouse('warehouseId', event, val)}
                        renderInput={(params: any) =>
                          <TextField  {...params} style={{ width: '25ch' }}
                            id="outlined-size-small"
                            color='info'
                            size="small"
                            type="text"
                            placeholder='Select Warehouse..'
                            name='warehouseId'
                          />}
                      />
                    </div>
                  </> : null

              }
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Order No </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={loadAssignOrderDetails}
                    getOptionLabel={(option: any) => option.orderNo}
                    onChange={(event, val) => handleOrderDetails('orderMasterid', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '25ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Order No..'
                        name='orderMasterid'
                      />}
                  />
                  <div className='vertical-space-10'></div>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Order Date </label>
                  <input
                    // value={moment(assignOrder.orderdate).format('DD-MM-YYYY')} name='orderdate'
                    value={assignOrder.orderdate} name='orderdate'
                    //value={assignOrder.orderdate} name='orderdate'
                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="YYYY MMMM DD"></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  {
                    isSpinner ?
                      <div className='SpinnerBox'>
                        <Spinner animation="grow" size="sm" />
                      </div> : null
                  }
                  <label>Item Name </label>
                  <Autocomplete size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={assignOrderStore.assignOrderItem}
                    getOptionLabel={(option: any) => option.itemname}
                    onChange={(event, val) => handleItemValue('itemid', event, val)}
                    renderInput={(params: any) =>
                      <TextField  {...params} style={{ width: '25ch' }}
                        id="outlined-size-small"
                        color='info'
                        size="small"
                        type="text"
                        placeholder='Select Item..'
                        name='itemid'
                      />}
                  />
                  <div className='vertical-space-10'></div>
                </div>


                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Qty<span>*</span></label>
                  <input type="text" className=''
                    value={assignOrder.itemQty}
                    name="itemQty"
                    style={{ width: "100%" }} placeholder='Qty..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>UOM<span>*</span></label>
                  <input type="text" className=''
                    value={assignOrder.uom}
                    name="uom"
                    style={{ width: "100%" }} placeholder='Uom..'></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>

              <div className='col-md-6'>
                <div className='inputBox'>
                  <label>Schedule Date</label>
                  <input type="date" style={{ width: "100%" }}
                    onChange={handleInputchnage}
                    name="scheduledate"
                    value={assignOrder.scheduledate}
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
              <div className='col-sm-9'>
                <div className='inputBox'>
                  <label>Remarks <span>*</span></label>
                  <textarea className=''
                    onChange={handleInputchnage}
                    name="Remarks"
                    value={assignOrder.Remarks}
                    style={{ width: "100%" }}
                    placeholder='Enter Remarks..'></textarea>
                </div>
              </div>
              <div className='col-sm-2'>
                <button className='dfBtn' onClick={addAssignOrder}>Add</button>
              </div>
              <div className='col-md-12'>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope="col">Order No</th>
                        <th scope="col">Order Date</th>  */}
                        <th scope="col">Item Name</th>
                        <th scope="col"> Qty</th>
                        <th scope="col">UOM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        assignOrderDetails.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.AssignOrderItemName}</td>
                              <td>{val.AssignOrderitemQty}</td>
                              <td>{val.AssignOrderuom}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className='vertical-space-10'></div>
              </div>

            </div>
          </div>
          <div className='btnBox'>
            <button className='secondaryBtn' onClick={handleCancel}>List</button>
            <button className='dfBtn' type='submit' onClick={saveAssignOrder}>Submit</button>
          </div>
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }

};

export default AssignOrder;
