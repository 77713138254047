import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';


class MinimumStockStore {
   @observable minimumStocks=new Map();
    
    getMinimumStock=async ()=> {
        
        try {
          const stocks= await apiAgent.adminReports.getMinimumStock();
            runInAction(()=>{
                this.minimumStocks = stocks;
            });
            return stocks;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default MinimumStockStore;