import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { observer } from 'mobx-react';
import { TextField, Autocomplete } from '@mui/material';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Country, State, City } from 'country-state-city';
import 'bootstrap/dist/css/bootstrap.min.css';
import outletDetails, { IOutlet } from './model/index';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Form, Modal, Button, Image } from 'react-bootstrap';
import './Outlet.css';
import outletValidator from './validation';
import { faL } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '../../common/shared/progressbar';
import Cookies from 'js-cookie';

const Outlet = observer((): JSX.Element => {
  const [cityOptions, setCityOptions] = useState([]);
  const [outlet, setOutletModel] = useState<IOutlet>({})
  const { supplierMasterStore, outletStores, userCreationStore } = useStores();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isAccessModal, setAccessModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [userRights, setRightsObj] = useState<any[]>([])
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  const handleCancel = () => {
    navigate('/Admin/outlet/list');
  }
  const handleSuccessModalClose = () => {
    navigate('/Admin/outlet/list');
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const getStateCountry = (e: any, value: any) => {

    if (value != null) {
      const countries = Country.getAllCountries().filter(x => x.isoCode === value.countryCode);
      outlet.country = countries[0].name
      const states = State.getAllStates().filter(x => x.countryCode === value.countryCode && x.isoCode === value.stateCode);
      outlet.state = states[0].name;
      outlet.city = value.name;
    }
    else {
      outlet.country = '';
      outlet.state = '';
    }
  }

  const saveOutlet = async (e) => {
    e.preventDefault();
    let error: any = {};

    error = outletValidator(outlet);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setSaveLoading(true);
      const status = await outletStores.saveOutlet(outlet);
      if (status === "Success") {
        setSaveLoading(false);
        setSuccessModal(true);
      }
      else {
        setSaveLoading(false);
        setFailureModal(true);
      }
    }

  }

  function handleChangeInput(event: any) {
    const { name, value } = event.target;

    const re = /^[0-9\b]+$/;

    // if value is not blank, then test the regex

    if (name === 'mobileNo' && re.test(value)) {
      value.replace(/[^0-9]/g, "")
      //alert('')
    }
    setOutletModel({ ...outlet, [name]: value })
  }

  console.log(outlet, 'outlet>>')

  const checkSpecialChar = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  async function fetchPreLoadingData() {

    const userRights = await Promise.all([userCreationStore?.getUserRight('Outlet_registration')])
    console.log(userRights, 'userRightschk');
    setRightsObj([userRights]);
    console.log(userRights[0]?.add, 'userRights');

    if (userRights[0]?.add || userRights[0]?.edit || userRights[0]?.delete || userRights[0]?.print || userRights[0]?.update || userRights[0]?.view) {
      setAccessModal(false);
      setLoadModal(false);
    } else {
      setAccessModal(true);
      setLoadModal(true);
    }
  }

  // useEffect(() => {
  //   setLoadModal(true);
  //   fetchPreLoadingData();
  //   let getCities: any;
  //   getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
  //   setCityOptions(getCities);
  // }, [])
  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Outlet_registration';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchPreLoadingData();
              let getCities: any;
              getCities = City.getAllCities().filter(m => m.countryCode === 'IN' && m.stateCode === 'TN');
              setCityOptions(getCities);
              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/outlet/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  const handleFailureModalClose = () => setFailureModal(false);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {
    return (
      <>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <div className='hrBox'>
            <h3>Outlet Registration</h3>
          </div>
          <form autoComplete='nope'>
            <div className='inputBoxLists'>
              <div className='inputBox '>
                <label >Outlet Name <span>*</span></label>
                <input type="text" className='' style={{ width: "80%" }} autoComplete='nope'
                  name="outletName"
                  placeholder='Enter Outlet Name'
                  value={outlet.outletName}
                  onChange={handleChangeInput} />
                {errors.outletName && <p style={{ color: 'red' }}>{errors.outletName}</p>}
                <div className='vertical-space-10'></div>

              </div>
              <div className='inputBox'>
                <label >Prefix<span>*</span></label>
                <input type="text" className='fullInput'
                  style={{ width: "40%" }}
                  placeholder='Prefix should be 3 characters' autoComplete='nope'
                  value={outlet.prefix}
                  onChange={handleChangeInput}
                  name='prefix' />
              </div>
              <div className='vertical-space-10'></div>

              {errors.prefix && <p style={{ color: 'red' }}>{errors.prefix}</p>}
              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label >Email ID </label>
                <input type="email" autoComplete="nope" className='fullInput' style={{ width: "80%" }}
                  placeholder='Enter Email ID'
                  value={outlet.emailID}
                  onChange={handleChangeInput}
                  name='emailID' />
              </div>

              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label >Mobile No</label>
                <input autoComplete='nope' onKeyPress={checkSpecialChar} maxLength={10} className='fullInput'
                  style={{ width: "80%" }}
                  placeholder='Enter Mobile No'
                  value={outlet.mobileNo}
                  onChange={handleChangeInput}
                  name='mobileNo' />
              </div>

              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label >Bank Account Number </label>
                <input type="text" className='fullInput'
                  style={{ width: "80%" }}
                  placeholder='Enter Bank Account Number' autoComplete='nope'
                  value={outlet.bankAccount}
                  onChange={handleChangeInput}
                  name='bankAccount' />
              </div>

              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label>FSSAI Number <span>*</span></label>
                <input type="number" className='fullInput'
                  style={{ width: "80%" }}
                  placeholder='Enter FSSAI Number' autoComplete='nope'
                  value={outlet.fssainumber}
                  onChange={handleChangeInput}
                  name='fssainumber' />
                {errors.fssainumber && <p style={{ color: 'red' }}>{errors.fssainumber}</p>}
              </div>

              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label> Date of Incorporated <span>*</span></label>
                <input type="date" placeholder="DD-MMM-YYYY"
                  name='dateOfIncrorpeted' value={outlet.dateOfIncrorpeted}
                  data-date="" data-date-format="DD MMMM YYYY"
                  onChange={handleChangeInput}></input>
                {errors.dateOfIncrorpeted && <p style={{ color: 'red' }}>{errors.dateOfIncrorpeted}</p>}
              </div>

              <div className='vertical-space-10'></div>
              <div className='inputBox'>
                <label >Door No / Street </label>
                <input type="text" className='fullInput'
                  style={{ width: "100%" }}
                  placeholder='Enter Door No / Street' autoComplete='nope'
                  value={outlet.doorNo}
                  onChange={handleChangeInput}
                  name='doorNo' />
              </div>

              <div className='vertical-space-10'></div>
              <div className='row'>
                <div className='col-md-6 '>
                  <div className='inputBox'>
                    <label >Area </label>
                    <input type="text" className='fullInput' style={{ width: "95%" }}
                      placeholder='Enter Area name' autoComplete='nope'
                      value={outlet.area}
                      onChange={handleChangeInput}
                      name='area' />
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label >City <span>*</span></label>
                    <Autocomplete size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={cityOptions}
                      getOptionLabel={(option: any) => option.name}
                      onChange={getStateCountry}
                      value={outlet.city}
                      renderInput={(params: any) =>
                        <TextField  {...params} style={{ width: '25ch' }}
                          id="outlined-size-small"
                          color='info'
                          size="small"
                          type="text"
                          placeholder='Select City'
                          name='city'
                        />}
                    />
                    {errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}
                  </div>
                  <div className='vertical-space-10'></div>
                </div>

                <div className='col-md-6 '>
                  <div className='inputBox'>
                    <label >State </label>
                    <Form.Group style={{ width: "100%" }}>
                      <TextField
                        value={outlet.state}
                        placeholder='Select State' autoComplete='nope'
                        onChange={handleChangeInput}
                        name='state'
                      />
                    </Form.Group>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label >Country </label>
                    <Form.Group style={{ width: "100%" }}>
                      <TextField
                        placeholder='Select Country'
                        value={outlet.country}
                        onChange={handleChangeInput} autoComplete='nope'
                        name='country'
                      />
                    </Form.Group>
                    <div className='vertical-space-10'></div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='inputBox'>
                    <label >Pin Code <span>*</span></label>
                    <input type="text" className='fullInput' style={{ width: "95%" }}
                      placeholder='Enter Pin Code' autoComplete='nope'
                      value={outlet.pinCode}
                      onChange={handleChangeInput}
                      name='pinCode' maxLength={6} />
                    {errors.pinCode && <p style={{ color: 'red' }}>{errors.pinCode}</p>}
                    <div className='vertical-space-10'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='btnBox'>
              <button className='secondaryBtn' onClick={handleCancel} >List</button>
              <button className="dfBtn" type="submit" onClick={saveOutlet} disabled={!canAdd}
                title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
              >
                Submit
              </button>

            </div>
          </form>
          {
            isSaveLoading ?
              <ProgressBar /> : null
          }
          <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>
                <div className='imgBox'>
                  <Image src={require('../../../../image/checked.png')} />
                </div>

                <h4>Succesfully Submitted</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleSuccessModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
            <Modal.Body>
              <div className='Details Success'>

                <div className='imgBox'>
                  <Image src={require('../../../../image/warning.png')} />
                </div>
                <h4>Failed</h4>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleFailureModalClose}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

      </>
    );
  }
});

export default Outlet;
