function terminalCreationValidation(terminalCreation){
    let errors :any={};
    
    if(!terminalCreation.terminalName){
      
        errors.terminalName = "Enter Terminal Name";
    }
    if(!terminalCreation.brand){
      
        errors.brand = "Enter Brand Name";
    }
    if(!terminalCreation.outletName){
      
        errors.outletName = "Enter Outlet Name";
    }
    if(!terminalCreation.installationDate){
      
        errors.installationDate = "Enter Installation Date ";
    }
    if(!terminalCreation.counterNumber){
      
        errors.counterNumber = "Enter Counter Number ";
    }   
    return errors;
};
export default terminalCreationValidation