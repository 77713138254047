import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const NewItemReqDetails = observer((): JSX.Element => {
    const { unitOfMeasurementStore, salesReplaceRetrunStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();
    const [Isloading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [newItemReqList, setNewItemReqList] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/NewItemRequest');
    }
    const editUnitDetials = (reqId) => {
        navigate('/Admin/NewItemRequest/' + reqId);
    }
    const deleteUnitDetials = async (unitId) => {
        setLoading(true);
        const status = await unitOfMeasurementStore.deleteUnitOfMeasurement(unitId);

        if (status === 'Success') {
            fetchPreLoadingData()
            setLoading(false);
            setSuccessModal(true);
        }
        else {
            setLoading(false);
            setFailureModal(true);
        }
    }
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadingData();
    }

    async function fetchPreLoadingData() {
        const userRights = await userCreationStore?.getUserRight('New_item_request')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const newItemReqList = await salesReplaceRetrunStore.loadNewItemRequest();
        setNewItemReqList(newItemReqList)
        setLoading(false);
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadingData()
            isCurrentPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    console.log(currentPageData, 'currentPageData>>')
    useEffect(() => {
        if (newItemReqList && newItemReqList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(newItemReqList)
        }
    }, [newItemReqList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(newItemReqList?.slice().sort((a, b) => b.Reqdid - a.Reqdid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                Isloading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={newItemReqList}
                                searchTitles={[{ key: "Itemname", value: "Item Name" }, { key: "Outletname", value: "Outlet Name" }, { key: "Employee_Name", value: "Employee Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{newItemReqList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Item Name</th>
                                            <th scope="col">Req Date</th>
                                            <th scope='col'>Employee Name</th>
                                            <th scope="col">Outlet Name</th>
                                            <th scope="col">Remarks</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>{val.Itemname}</td>
                                                        <td>{moment(val.Reqdate).format('DD-MM-YYYY')}</td>
                                                        <td>{val?.Employee_Name}</td>
                                                        <td>{val.Outletname}</td>
                                                        <td>{val.Remarks}</td>
                                                        <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit'  ><FontAwesomeIcon icon={faPenToSquare} onClick={() => editUnitDetials(val.Reqid)} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </>

    )
})

export default NewItemReqDetails;