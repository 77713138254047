import { IDamageEntryApproval } from "../model";

export function SubmitValidation(damageApproval: IDamageEntryApproval) {
    let error: any ={};
    if(!damageApproval?.entryDate) {
        error.entryDate = "Enter Date";
    }
    if(!damageApproval?.convertedby) {
        error.convertedby = "Select Created By";
    }
    if(!damageApproval?.damageApprovaldetails?.length) {
        error.damageApprovaldetails = "Select Approval from List";
    }
    return error
}