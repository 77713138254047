import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IOutletStockAnalysis } from './model';
import { Autocomplete, Pagination, Stack, TextField } from '@mui/material';
import useStores from '../../hooks';
import ProgressBar from '../../common/shared/progressbar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { currentPageDatas, sortedData } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';
import { Console, log } from 'console';

export interface ExcelSheetData {
  Type?: string;
  Outward_Date?: string;
  Outlet_Name?: string;
  Category?: string;
  Subcategory?: string;
  Barcode?: string;
  BatchNo?: string;
  Item_Name?: string;
  UOM?: string;
  Opening_Stk?: number;
  In_qty?: number;
  Sal_qty?: number;
  Closing_stk?: number;
}

function OutletStockAnalysis() {
  const navigate = useNavigate();

  const { commonStore, outletStockAnalysisStore } = useStores();
  const { loadOutlet, loadOutlets, loadSubcategory, loadSubcategories, loadItemConversion, loadItemConversions } = commonStore;
  const { getOutletStockAnalysis, outletstockReportDetails } = outletStockAnalysisStore

  const [outletstockAnalysis, setOutletStockAnalysis] = useState<IOutletStockAnalysis>(new IOutletStockAnalysis());

  const [isLoading, setLoading] = useState(true);
  const [isItemName, setItemName] = useState(false);
  const [isCategory, setCategory] = useState(false);
  const [isSubCategory, setSubCategory] = useState(false);
  const [isOutlet, setOutlet] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [errors, setErrors] = useState<any>({})

  const filteredData = useMemo(() => {
    if (searchValue != '' && searchValue?.length > 0 && outletstockReportDetails?.length > 0) {
      return sortedData(outletstockReportDetails, 'outwardDate')?.filter((stock) => {
        if (stock?.itemname?.toLowerCase().includes(searchValue?.toLowerCase())) {
          return stock;
        }
      })
    } else {
      return [];
    }
  }, [searchValue])

  const handleSubcategoryValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, subCategoryId: val.subCategoryId });
    }
  }

  function handleSearchInput(e) {
    setSearchValue(e.target.value);
  }

  const handleOutlet = async (name, val) => {
    debugger
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, outletId: val.outletid });
    }
  }
  const handleItemValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setOutletStockAnalysis({ ...outletstockAnalysis, itemId: val.itemId });
    }
  }

  const searchStockAnalysis = async () => {
    setLoading(true);
    let error: any = {};
    if (outletstockAnalysis?.outletId) {
      await getOutletStockAnalysis(outletstockAnalysis);
    } else {
      error.outletId = 'Select Outlet'
    }
    setErrors({ ...error })
    setLoading(false);
  }

  const handleInputchnage = (e) => {
    const { name, value } = e.target;
    setOutletStockAnalysis({ ...outletstockAnalysis, [name]: value });
  };

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setItemName(false);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'ItemName') {
      setItemName(true);
      setCategory(false);
      setSubCategory(false);
    }
    if (value === 'Category') {
      setItemName(false);
      setCategory(true);
      setSubCategory(false);
    }
    if (value === 'SubCategory') {
      setItemName(false);
      setCategory(false);
      setSubCategory(true);
    }
    setOutletStockAnalysis({ ...outletstockAnalysis, [name]: value })
  }

  function createExportExcelObj(stockReportDetails: any[]) {
    let excelArr: ExcelSheetData[] = stockReportDetails?.map((stockReport) => {
      const excelObj: ExcelSheetData = {
        Type: stockReport?.ttype,
        Outward_Date: stockReport?.outwardDate ? moment(stockReport?.outwardDate)?.format('DD-MMM-YYYY') : '',
        Outlet_Name: stockReport?.Outletname,
        Category: stockReport?.Category,
        Subcategory: stockReport?.subcategoryname,
        Barcode: stockReport?.barcode,
        BatchNo: stockReport?.batchno,
        Item_Name: stockReport?.itemname,
        UOM: stockReport?.unitOfMeasure,
        Opening_Stk: stockReport?.opening,
        In_qty: stockReport?.inqty,
        Sal_qty: stockReport?.outqty,
        Closing_stk: stockReport?.balanceqty,
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Outlet Stock Analysis", Object.keys(excelArr[0]).join(','), ...excelArr?.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Outlet Stock Analysis ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function fetchPreLoadngData() {
    await loadItemConversion();
    await loadOutlet();
    await loadSubcategory();
    searchStockAnalysis()
    setLoading(false);
  }

  console.log(loadOutlet, 'loadOutlet');

  const isCurrentPage = useRef(true)

  useEffect(() => {
    console.log(loadOutlet);
    console.log(loadOutlets);
    if (isCurrentPage?.current) {

      fetchPreLoadngData();
      // loadOutlet();

      isCurrentPage.current = false;
    }
    return () => {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (outletstockReportDetails?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(outletstockReportDetails)
    }
  }, [outletstockReportDetails])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData(outletstockReportDetails, 'outwardDate'), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>

      <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <div className='' style={{ width: '100%' }}>
          <div className='vertical-space-20'></div>
          <div className='outletInputField inputFormBox LgInputField'>
            <div className='hrBox'>
              <h3>Outlet Stock Analysis</h3>
            </div>
            {isLoading ? <ProgressBar /> : <></>}
            <div className='inputBoxLists'>
              <div className='ItemInwardInputBox'>
                <div className='row'>
                  <div className='col-sm-1'>
                    <div className='inputBox'>
                      <label style={{ fontSize: "small" }}>From Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={outletstockAnalysis.fromDate}
                        onChange={handleInputchnage}
                        name="fromDate"
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-1'>
                    <div className='inputBox'>
                      <label style={{ fontSize: "small" }}>To Date <span>*</span></label>
                      <input type="date" style={{ width: "100%" }}
                        value={outletstockAnalysis.toDate}
                        onChange={handleInputchnage}
                        name="toDate"
                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                    </div>
                    <div className='vertical-space-20'></div>
                  </div>
                  <div className="col-sm-2" style={{ marginTop: '18px' }}>

                    <div className='inputBox'>

                      {/* <AutoComplete getOptionLabel='outletName'
                        value={loadOutlet.find((option) => option?.outletid == outletstockAnalysis?.outletId)?.outletName}
                        options={loadOutlet} placeholder='Select Outlet Name..'
                        emitOption={(option: any) => handleOutlet(option, '')} /> */}
                      <AutoComplete placeholder='Select Outlet..' clsName='full-width' getOptionLabel='outletName'
                        emitOption={(option: any) => handleOutlet('', option)} options={loadOutlets} />
                    </div>
                    {errors.outletId && <p style={{ color: 'red' }}>{errors.outletId}</p>}
                  </div>
                  <div className="col-sm-2" style={{ marginTop: '17px' }}>
                    <div className="input-group mb-3">
                      <input type="text"
                        style={{ height: "47px" }}
                        name='searchValue'
                        value={searchValue}
                        onChange={handleSearchInput}
                        className="form-control" placeholder="Search Here.."
                        aria-label="Search Outlet..."
                        aria-describedby="search-button"
                      ></input>
                      <div className="input-group-append">
                        <button style={{ height: "47px" }} className="btn btn-outline-secondary" type="button"
                          id="search-button">
                          <FontAwesomeIcon icon={faSearch}
                          // onClick={handleQuoteSearch}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='inputBox' style={{ marginTop: '17px' }}>
                      <label></label>
                      <select style={{ paddingRight: "0px", height: "47px" }} className="form-selected" id="Mode" name="searchfield" onChange={handleChangeInput} >
                        <option value="">Select Search Criteria</option>
                        <option value="ItemName">Item Name</option>
                        <option value="Category">CateGory </option>
                        <option value="SubCategory">Sub Category </option>
                        {/* <option value="Outlet">Outlet </option> */}
                      </select>
                    </div>

                  </div>
                  <div className='col-sm-2' style={{ marginTop: '17px' }}>
                    {
                      isItemName ?
                        <>
                          <div className='inputBox' style={{ marginLeft: "16px" }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadItemConversions}
                              getOptionLabel={(option: any) => option.itemName}
                              onChange={(event, val) => handleItemValue('itemId', event, val)}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Item..'
                                  name='itemId'
                                />}
                            />
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null

                    }
                    {
                      isCategory ?
                        <>
                          <div className='inputBox'>
                            <select className="form-selected" onChange={handleInputchnage} style={{ width: "18ch", marginLeft: "17px" }} id="Category" name="category">
                              <option value="" selected>Not Select</option>
                              <option value="Home&amp;Kitchen">Home &amp; Kitchen</option>
                              <option value="Pets">Pets</option>
                              <option value="Beauty&amp;Health">Beauty &amp; Health</option>
                              <option value="Grocery">Grocery</option>
                            </select>
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                    {
                      isSubCategory ?
                        <>
                          <div className='inputBox' style={{ marginLeft: "16px" }}>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadSubcategories}
                              getOptionLabel={(option: any) => option.subCategoryName}
                              onChange={(event, val) => handleSubcategoryValue('subCategoryId', event, val)}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Subcategory..'
                                  name='subcategory'
                                />}
                            />

                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                    {
                      isOutlet ?
                        <>
                          <div className='inputBox'>
                            <Autocomplete size="small"
                              disablePortal
                              id="combo-box-demo"
                              options={loadOutlets}
                              getOptionLabel={(option: any) => option.outletName}
                              onChange={(event, val) => handleOutlet(val, '')}
                              renderInput={(params: any) =>
                                <TextField  {...params}
                                  id="outlined-size-small"
                                  color='info'
                                  size="small"
                                  type="text"
                                  placeholder='Select Outlet'
                                  name='outletId'
                                />}
                            />
                          </div>
                          <div className='vertical-space-10'></div>
                        </> : null
                    }
                  </div>
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' type='submit' onClick={searchStockAnalysis}>Generate</button>
                  </div>
                  <div className="col-sm-1" style={{ marginTop: '24px' }}>
                    <button className='secondaryBtn' onClick={() => createExportExcelObj(outletstockReportDetails)}>Excel</button>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-5'>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{outletstockReportDetails?.length} Records</button>
                  </div>
                </div>
                <div className=' col-sm-7' >
                  <Stack spacing={2}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>

              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>

                      <th scope="col">Outward Date</th>
                      <th scope="col">Outlet Name </th>
                      <th scope="col">Category</th>
                      <th scope="col">Sub Category</th>
                      <th scope="col">Item Name</th>
                      <th scope="col">Barcode</th>
                      <th scope='col'>Batch No</th>
                      <th scope="col">UOM</th>
                      <th scope="col">Opening Stk</th>
                      <th scope="col">In Qty</th>
                      <th scope="col">Sal Qty</th>
                      <th scope="col">Closing Stk</th>

                    </tr>
                  </thead>
                  <tbody>

                    {(searchValue?.length > 0 ? filteredData : currentPageData).length > 0 ?
                      (searchValue?.length > 0 ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.ttype} </td>
                            <td>{moment(val.outwardDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.Outletname} </td>
                            <td>{val.Category} </td>
                            <td>{val.subcategoryname} </td>
                            <td>{val.itemname} </td>
                            <td>{val?.barcode}</td>
                            <td>{val?.batchno}</td>
                            <td>{val.unitOfMeasure} </td>
                            <td>{val.opening}</td>
                            <td>{val.inqty} </td>
                            <td>{val.outqty} </td>
                            <td>{val.balanceqty}</td>
                          </tr>
                        )
                      }) : <h6> No Data Available</h6>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default OutletStockAnalysis;
