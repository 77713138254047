import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SchoolIcon from "@mui/icons-material/School";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import InventoryIcon from '@mui/icons-material/Inventory';
import BusinessIcon from '@mui/icons-material/Business';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FactoryIcon from '@mui/icons-material/Factory';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StoreIcon from '@mui/icons-material/Store';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';

export function getIcons(type) {
    if (type === 'CRM') {
        return <SupervisorAccountIcon />
    } else if (type === 'Admin') {
        return <ManageAccountsIcon />
    } else if (type === 'Finance') {
        return <TrendingUpIcon />
    } else if (type === 'Academic') {
        return <SchoolIcon />
    } else if (type === 'N/A') {
        return <DoNotDisturbIcon />
    } else if (type === 'Purchase') {
        return <InventoryIcon />
    } else if (type === 'BackOffice') {
        return <BusinessIcon />
    } else if (type === 'Warehouse') {
        return <WarehouseIcon />
    } else if (type === 'Factory') {
        return <FactoryIcon />
    } else if (type === 'Reports') {
        return <AssessmentIcon />
    } else if (type === 'Outlet') {
        return <StoreIcon />
    } else if (type === 'HR') {
        return <Diversity3Icon />
    } else if (type === 'HRReports') {
        return <AssessmentIcon />
    } else if (type === 'Payrolls') {
        return <ReceiptIcon />
    } else if (type === 'Accounts') {
        return <PersonIcon />
    }
}

export default function NestedList({ selectedMod, setSelectedMod, mapOfModForm }) {

    const handleClickType = (type) => {
        if (selectedMod === type) {
            type = "";
        }
        setSelectedMod(type)
    }

    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    User Rights By Forms
                </ListSubheader>
            }
        >
            {Object.entries(mapOfModForm)?.map((modules, index) => {
                return (
                    <ListItemButton key={index} onClick={() => handleClickType(modules[0])}>
                        <ListItemIcon>
                            {getIcons(modules[0])}
                        </ListItemIcon>
                        <ListItemText primary={modules[0]} />
                        {selectedMod === modules[0] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                )
            })}
        </List >
    );
}