import { runInAction, set, makeObservable, observable, action } from 'mobx';
import apiAgent from '../../../APIAgent';
import packingDistributionDetails, { IPackingDistribution } from '../model/index';

class PackingDistributionStore {
    @observable packingDistributionDetls = packingDistributionDetails;
    @observable packingDistributionList = new Map<number, IPackingDistribution>();
    getPackingDistributions = new Map();
    packingDistributionDetails = new Map();
    @observable loadBatches = new Map();
    isInwardLoding = true;
    isLoading = true;
    @observable entryNo = new Map();
    constructor() {
        makeObservable(this);
    }
    savePackingDistribution = async (packingDistribution: IPackingDistribution) => {

        try {
            const status = await apiAgent.packingDistribution.savePackingDistribution(packingDistribution);
            if (status !== undefined) {
                return status;
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingDistribution = async () => {

        try {
            const getPacking = await apiAgent.packingDistribution.getPackingDistribution();

            runInAction(() => {

                this.getPackingDistributions = getPacking;
            });
            return this.getPackingDistributions;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingDistributionDetails = async (packingDistributionId) => {

        try {
            const getPacking = await apiAgent.packingDistribution.getPackingDistributionDetails(packingDistributionId);

            runInAction(() => {

                this.packingDistributionDetails = getPacking;
            });
            return this.packingDistributionDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getEntryNo = async (flag) => {
        try {
            const entryNo = await apiAgent.packingDistribution.getEntryNo(flag);

            runInAction(() => {
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingDistributionSearch = async (SearchOptions) => {
        try {
            const Data = await apiAgent.packingDistribution.getPackingDistributionSearch(SearchOptions);
            this.getPackingDistributions = Data;

            runInAction(() => {
                this.getPackingDistributions = Data;

            })
            return this.getPackingDistributions;


        }
        catch (error) {
            console.log(error);
        }
    }
    getPackingSizeMaster = async () => {
        try {
            const Data = await apiAgent.packingDistribution.getPackingSizeMaster();
            return JSON.parse(Data);
        }
        catch (error) {
            console.log(error);
        }
    }
    packingSizeMasterSave = async (printSizeMaster: any) => {
        try {
            const status = await apiAgent.packingDistribution.packingSizeMasterSave(printSizeMaster);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deletePackingDistributionDetail = async (packingDistributionDetailId) => {
        try {
            const status = await apiAgent.packingDistribution.deletePackingDistributionDetail(packingDistributionDetailId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deletePackingDistributionMaster = async (PackingDistributionId) => {
        try {
            const status = await apiAgent.packingDistribution.deletePackingDistributionMaster(PackingDistributionId);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadBatchs = async (itemId, wid) => {
        try {
            const loadBatch = await apiAgent.packingDistribution.loadBatchs(itemId, wid);
            runInAction(() => {
                this.loadBatches = loadBatch;
            });
            return this.loadBatches;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default PackingDistributionStore;