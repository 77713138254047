import { useEffect, useRef, useState } from "react";
import ProgressBar from "../../common/shared/progressbar";
import { Accordion, Button, Image, Modal } from "react-bootstrap";
import useStores from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { employeePromoDetValidation, employeeSalaryValidation, saveValidation } from "./validation";
import { IIEmployeeIncrementDet, IIEmployeeIncrementSalDet, IIIncrementApproval } from "./model";

const IncrementEntry = () => {

    const navigate = useNavigate();

    const { employeeStore, payrollStore, departmentStore, designationStore, employeePromotionStore, jobMasterStore } = useStores();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [employeeSalary, setEmployeeSalary] = useState<any[]>([])
    const [categoryList, setCategoryList] = useState<any[]>([])
    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])
    const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])


    const [employeePromotion, setEmployeePromotion] = useState<IIIncrementApproval>(new IIIncrementApproval())
    const [empPromotionDet, setPromotionDet] = useState<IIEmployeeIncrementDet>(new IIEmployeeIncrementDet())
    const [employeePromoSalDet, setEmployeePromoSalDet] = useState<IIEmployeeIncrementSalDet>(new IIEmployeeIncrementSalDet())

    function handleAddSalary(index) {
        if (index >= 0) {
            let employeeMasDet = Object.assign({}, employeePromotion)
            employeeMasDet.employeeincrementsaldet?.splice(index, 1)
            setEmployeePromotion({ ...employeePromotion })
        } else {
            let error: any = {}
            if (!employeePromotion?.employeeincrementsaldet && !employeePromotion?.employeeincrementsaldet?.length) {
                employeePromotion.employeeincrementsaldet = [];
                error = employeeSalaryValidation(employeePromoSalDet)
                if (Number(employeePromoSalDet?.eiS_Amount) <= Number(employeePromoSalDet?.eiS_GrossSal)) {
                } else {
                    error.eiS_Amount = "Amount total cannot be greater/lesser than Gross Salary";
                }
                setErrors({ ...error })
                if (Object.keys(error)?.length === 0) {
                    employeePromoSalDet['epS_ID'] = employeePromotion?.eiM_ID;
                    employeePromotion.employeeincrementsaldet.push(employeePromoSalDet)
                    setEmployeePromotion({ ...employeePromotion })
                    setEmployeePromoSalDet(new IIEmployeeIncrementSalDet())
                }
            } else {
                const disallowedTypeNotPresent = employeePromotion.employeeincrementsaldet!.every(obj => obj.eiS_EarnType !== employeePromoSalDet?.eiS_EarnType);
                if (disallowedTypeNotPresent) {
                    let salaryArray: IIEmployeeIncrementSalDet[] = Object.assign([], employeePromotion.employeeincrementsaldet);
                    let salaryAmountSum = salaryArray?.reduce((a, v: IIEmployeeIncrementSalDet) => a = a + Number(v?.eiS_Amount!), 0)
                    let checkSum = salaryAmountSum + Number(employeePromoSalDet?.eiS_Amount)
                    error = employeeSalaryValidation(employeePromoSalDet);
                    if (checkSum <= Number(employeePromoSalDet?.eiS_GrossSal)) {
                    } else {
                        error.eiS_Amount = "Amount total cannot be greater/lesser than Gross Salary";
                    }
                    setErrors({ ...error })
                    if (Object.keys(error)?.length === 0) {
                        employeePromoSalDet['epS_ID'] = employeePromotion?.eiM_ID;
                        employeePromotion.employeeincrementsaldet?.push(employeePromoSalDet)
                        setEmployeePromotion({ ...employeePromotion })
                        setEmployeePromoSalDet(new IIEmployeeIncrementSalDet())
                    }
                } else {
                    let error: any = {
                        eiS_EarnType: `${employeePromoSalDet?.eiS_EarnType} already exists`
                    }
                    setErrors({ ...error })
                }
            }
        }
    }

    const handleCancel = () => {
        navigate("/Admin/IncrementMaster/List");
    };

    function handleSuccessModalClose() {
        setSuccessModal(false)
    }

    function handleAutoComplete(name, value) {
        if (name === 'eiM_TicketNo') {
            let findCategory = categoryList?.find((each) => each?.Emp_Category_Name === value?.EmpType)
            setEmployeePromotion({
                ...employeePromotion,
                eiM_TicketNo: value?.EmployeeId, eiM_EmpID: value?.EmployeeId,
                eiM_DeptID: value?.department, eiM_DegnID: value?.designation,
                eiM_CatID: findCategory?.Categoryid
            })
        }
    }

    async function handleSavePromotion() {
        let error: any = {}
        error = employeePromoDetValidation(empPromotionDet)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            employeePromotion.employeeincrementdet = [];
            empPromotionDet['epD_ID'] = employeePromotion?.eiM_ID;
            employeePromotion.employeeincrementdet?.push(empPromotionDet)
            error = saveValidation(employeePromotion)
            setErrors({ ...error })
            if (Object.keys(error)?.length === 0) {
                setLoading(true);
                const entryNo = await jobMasterStore?.getHREntryNo('IncrementApproval')
                employeePromotion.eiM_ID = Number(entryNo?.entrynumber)
                const status = await employeePromotionStore.saveEmployeeIncrement(employeePromotion);
                if (status === 'Success') {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            }
        }
    }

    async function initialApiCall() {
        const entryNo = await jobMasterStore?.getHREntryNo('IncrementApproval')
        setEmployeePromotion({ ...employeePromotion, eiM_ID: Number(entryNo.entrynumber) })
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const employeeSalary = await employeeStore?.loadEmployeeSalaryDetails()
        const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
        setIncentiveMaster([...incentiveMaster])
        setCategoryList([...categoryMasterDetails])
        setEmployeeMaster([...employeeMaster])
        setEmployeeSalary([...employeeSalary])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
        setLoading(false);
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    return (
        <>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <div className="container">
                    <div className="outletInputField inputFormBox LgInputField">
                        <div className="hrBox">
                            <h3>Increment Entry</h3>
                        </div>
                        <div className="inputBoxLists accordionLists">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Employee Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}> Employee ID</label>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={employeeMaster}
                                                        getOptionLabel={(option: any) => option.EmployeeId}
                                                        onChange={(event, val) => handleAutoComplete('eiM_TicketNo', val)}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params}
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder='Select Name..'
                                                                name='ledgerGroupId'
                                                            />}
                                                    />
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Employee Name</label>
                                                    <input type="text" className="full-width"
                                                        disabled value={employeeMaster?.find((each) => each?.EmployeeId === employeePromotion?.eiM_TicketNo)?.Employee_Name}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Category</label>
                                                    <input type="text" className="full-width"
                                                        disabled value={employeeMaster?.find((each) => each?.EmployeeId === employeePromotion?.eiM_TicketNo)?.EmpType}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Department</label>
                                                    <input type="text" className="full-width"
                                                        disabled value={employeeMaster?.find((each) => each?.EmployeeId === employeePromotion?.eiM_TicketNo)?.department1}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Designation</label>
                                                    <input type="text" className="full-width"
                                                        disabled value={employeeMaster?.find((each) => each?.EmployeeId === employeePromotion?.eiM_TicketNo)?.designation1}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                        </div>
                                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Earning Type</th>
                                                        <th scope="col">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employeeSalary?.map((each) => {
                                                        return each?.EmployeeId === employeePromotion?.eiM_TicketNo && (
                                                            <tr>
                                                                <td>{each?.EarnType}</td>
                                                                <td>{each?.Amount}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Increment & Salary Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <label style={{ fontSize: '18px' }}>Increment Details</label>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Code</label>
                                                    <input type="text" className="full-width"
                                                        value={'1'} disabled
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}> Date</label>
                                                    <input type="date"
                                                        className="inputBoxDate"
                                                        style={{ width: "100%", fontWeight: "lighter" }}
                                                        placeholder="DD/MMM/YYYY" data-date=""
                                                        data-date-format="DD MMMM YYYY" name="fromdate"
                                                        value={empPromotionDet?.eiD_Date}
                                                        onChange={(event) => setPromotionDet({ ...empPromotionDet, eiD_Date: event?.target?.value })}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}> Effective Date</label>
                                                    <input type="date"
                                                        className="inputBoxDate"
                                                        style={{ width: "100%", fontWeight: "lighter" }}
                                                        placeholder="DD/MMM/YYYY" data-date=""
                                                        data-date-format="DD MMMM YYYY" name="fromdate"
                                                        value={empPromotionDet?.eiD_EffDate}
                                                        onChange={(event) => setPromotionDet({ ...empPromotionDet, eiD_EffDate: event?.target?.value })}
                                                    ></input>
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            {/* <div className="col-sm-3">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}> Category</label>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={categoryList}
                                                        getOptionLabel={(option: any) => option.Emp_Category_Name}
                                                        onChange={(event, val) => setPromotionDet({ ...empPromotionDet, eiD_CatID: val?.Categoryid })}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params}
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder='Select Category..'
                                                                name='ledgerGroupId'
                                                            />}
                                                    />
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}> Designation</label>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={designationList}
                                                        getOptionLabel={(option: any) => option.designationName}
                                                        onChange={(event, val) => setPromotionDet({ ...empPromotionDet, epD_DegnID: val?.designationId })}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params}
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder='Select Designation..'
                                                                name='ledgerGroupId'
                                                            />}
                                                    />
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="inputBox" >
                                                    <label style={{ fontSize: '16px' }}> Department</label>
                                                    <Autocomplete size="small"
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        options={departmentList}
                                                        getOptionLabel={(option: any) => option.department}
                                                        onChange={(event, val) => setPromotionDet({ ...empPromotionDet, epD_DeptID: val?.departmentId })}
                                                        renderInput={(params: any) =>
                                                            <TextField  {...params}
                                                                id="outlined-size-small"
                                                                color='info'
                                                                size="small"
                                                                type="text"
                                                                placeholder='Select Department..'
                                                                name='ledgerGroupId'
                                                            />}
                                                    />
                                                </div>
                                                <div className="vertical-space-10"></div>
                                            </div> */}
                                            <div className="col-sm-4">
                                                <div className="inputBox">
                                                    <label>Salary Type <span>*</span></label>
                                                    <select className="form-select" style={{ fontSize: "16px" }} name="salarytype"
                                                        value={empPromotionDet?.eiD_SalType}
                                                        onChange={(event) => setPromotionDet({ ...empPromotionDet, eiD_SalType: event?.target?.value })} id="salarytype">
                                                        <option value="none">Select</option>
                                                        <option value="monthlyBases">Monthly Basis</option>
                                                        <option value="perDayBases">Per Day Basis</option>
                                                        <option value="weeklyBases">Weekly Basis</option>
                                                    </select>
                                                </div>
                                                {errors.eiD_SalType && (<p style={{ color: "red" }}>{errors.eiD_SalType}</p>)}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-12" style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                <label style={{ fontSize: '18px' }}> Salary Details</label>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Gross Salary</label>
                                                    <input type="text"
                                                        className="full-width"
                                                        value={employeePromoSalDet?.eiS_GrossSal}
                                                        onChange={(event) => setEmployeePromoSalDet({ ...employeePromoSalDet, eiS_GrossSal: Number(event?.target?.value) })}
                                                        placeholder="Enter Gross Salary"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                {errors?.eiS_GrossSal && <p style={{ color: 'red' }}>{errors?.eiS_GrossSal}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Earning Type</label>
                                                    <select className="form-select" style={{ fontSize: "16px" }} name="earnType"
                                                        value={employeePromoSalDet?.eiS_EarnType}
                                                        onChange={(event) => setEmployeePromoSalDet({ ...employeePromoSalDet, eiS_EarnType: (event?.target?.value) })}
                                                    >
                                                        <option value="none">Select</option>
                                                        {incentiveMaster?.map((each) => {
                                                            return each?.IType === "Allowance" && (
                                                                <option value={each?.IDescription}>{each?.IDescription}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {errors?.eiS_EarnType && <p style={{ color: 'red' }}>{errors?.eiS_EarnType}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className="inputBox">
                                                    <label style={{ fontSize: '16px' }}>Amount</label>
                                                    <input
                                                        type="text"
                                                        className="full-width"
                                                        value={employeePromoSalDet?.eiS_Amount}
                                                        onChange={(event) => setEmployeePromoSalDet({ ...employeePromoSalDet, eiS_Amount: Number(event?.target?.value) })}
                                                        placeholder="Enter Amount"
                                                        style={{ width: "100%" }}
                                                    ></input>
                                                </div>
                                                {errors?.eiS_Amount && <p style={{ color: 'red' }}>{errors?.eiS_Amount}</p>}
                                                <div className="vertical-space-10"></div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="btnBox">
                                                    <button className="dfBtn" onClick={handleAddSalary}>
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Earning Type</th>
                                                        <th scope="col">Amount</th>
                                                        <th scope="col">Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {employeePromotion?.employeeincrementsaldet?.map((salary, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{salary?.eiS_EarnType}</td>
                                                                <td>{salary?.eiS_Amount}</td>
                                                                <td>
                                                                    <button className="delete">
                                                                        <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleAddSalary(index)} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="btnBox">
                                <button className="secondaryBtn" onClick={handleCancel}>
                                    List
                                </button>
                                <button className="dfBtn" type="submit" onClick={handleSavePromotion}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            )}
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className="SubmitModal">
                <Modal.Body>
                    <div className="Details Success">
                        <div className="imgBox">
                            <Image src={require("../../../gsmecom/images/checked.png")} />
                        </div>
                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={isFailureModal}
                onHide={() => setFailureModal(false)}
                className="SubmitModal"
            >
                <Modal.Body>
                    <div className="Details Success">
                        <div className="imgBox">
                            <Image
                                src={require("../../../gsmecom/images/warning.png")}
                            />
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setFailureModal(false)}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default IncrementEntry;