import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent/index';
import { IAssignProduct } from '../model';


class AssignProductStore {
   
   @observable loadItems=new Map();
   @observable loadAssignProducts=new Map();
   @observable loadAssignProduct=new Map();
   @observable loadAssignProductDeatils=new Map();
   @observable loadAssignProductDeatil=new Map();
   isLoading= false;
     
   loadItemMaster=async ()=> {
        try {
          const getItems= await apiAgent.assignProduct.loadItemMaster();
            runInAction(() => {
                
                this.loadItems = getItems;
            });
            return this.loadItems;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveAssignProduct=async (assignProduct:IAssignProduct)=> {
        
        try {
          const status= await apiAgent.assignProduct.saveAssignProduct(assignProduct);
           
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    updateAssignProduct=async (assignProductId,assignProduct:IAssignProduct)=> {
        try {
            const status= await apiAgent.assignProduct.updateAssignProduct(assignProductId,assignProduct);
            
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteAssignProduct=async (assignProductId)=> {
        try {
            const status= await apiAgent.assignProduct.deleteAssignProduct(assignProductId);
           
            return  status;
        }
        catch (error) {
            console.log(error);
        }
    }
    LoadingAssignProduct=async ()=> {
        try {
          const getAssignProduct= await apiAgent.assignProduct.LoadingAssignProduct();
            runInAction(() => {
                
                this.loadAssignProducts =  getAssignProduct;
            });
            return this.loadAssignProducts;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignProductDetails=async (assignProductId)=> {
        try {
          const getAssignProductDetails= await apiAgent.assignProduct.getAssignProductDetails(assignProductId);
            runInAction(() => {
                
                this.loadAssignProductDeatils =  getAssignProductDetails;
            });
            return this.loadAssignProductDeatils;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignProducts=async (itemSearchOptions)=> {
        try {
          const getAssignProducts= await apiAgent.assignProduct.getAssignProducts(itemSearchOptions);
            runInAction(() => {
                
                this.loadAssignProducts =  getAssignProducts;
            });
            return this.loadAssignProducts;
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignProduct=async (assignProductId)=> {
        try {
          const getAssignProduct= await apiAgent.assignProduct.getAssignProduct(assignProductId);
            runInAction(() => {
                
                this.loadAssignProduct =  getAssignProduct;
            });
            return this.loadAssignProduct;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default AssignProductStore;