import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, ProgressBar, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IAssignRedemptionSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import moment from 'moment';

const AssignRedemptionList = observer((): JSX.Element => {

  const [isLoading, setLoading] = useState(true);
  const { assignRedemptionStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [show, setShow] = useState(false);

  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const handleCreate = () => {
    navigate('/Admin/AssignRedemption')
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
      setSuccessModal(false);
      loadAssign();
  }

  const editAssignRedem = (assignRedemId) => {
    navigate('/Admin/AssignRedemption/' + assignRedemId);
  }

  const deleteAssignRedem = async (assignRedemId) => {
    setLoading(true);
    const status = await assignRedemptionStore.deleteAssignRedemption(assignRedemId);
    if (status === 'Success') {
      loadAssign();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleRedemptiondetail = async (id) => {
    setLoading(true);
    await assignRedemptionStore.getAssignRedemptiondetails(id);
    setLoading(false);
    setShow(true);
  }
  async function loadAssign() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Assign_redemption'), assignRedemptionStore.getAssignRedemption()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      loadAssign();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (assignRedemptionStore.assignRedemptionList && assignRedemptionStore.assignRedemptionList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(assignRedemptionStore.assignRedemptionList)
    }
  }, [assignRedemptionStore.assignRedemptionList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(assignRedemptionStore.assignRedemptionList.slice().sort((a, b) => b.assignRedemptionId - a.assignRedemptionId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  const sortedData = useMemo((): any => {
    if (assignRedemptionStore?.assignRedemptionDetails?.length) {
      const listedData = assignRedemptionStore?.assignRedemptionDetails?.slice()?.sort((a, b) => {
        const dateA = new Date(a.redemEffectiveFrom);
        const dateB = new Date(b.redemEffectiveFrom);

        if (dateA > dateB) return -1;
        if (dateB < dateA) return 1;
        return 0;
      });
      return listedData;
    }
  }, [assignRedemptionStore?.assignRedemptionDetails])

  return (
    <div className='container-fluid'>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> : null
      }
      <div className='vertical-space-20'></div>
      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={assignRedemptionStore.assignRedemptionList}
        searchTitles={[{ key: "category", value: "Category" }]}
        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
      <div className='vertical-space-20'></div>
      <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className='btnBox'>
          <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{assignRedemptionStore?.assignRedemptionList?.length} Records</button>
        </div>
        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
          <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
        </Stack>
      </div>
      <div className='vertical-space-20'></div>
      <div className='tableListDetails'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Category  </th>
              <th scope='col'>Amount Per Point  </th>
              <th scope='col'></th>
              <th scope='col'>Create </th>
              <th scope='col'>Update </th>
              <th scope='col'>Delete </th>
            </tr>
          </thead>
          <tbody>
            {(isSearch ? filteredData : currentPageData).length > 0 &&
              ((isSearch ? filteredData : currentPageData)).map((val, key) => {
                return (
                  <tr key={key}>
                    <td>{val.category}</td>
                    <td>{val.amountPerPoints}</td>
                    <td><button disabled={!userRights?.view} onClick={(e) => handleRedemptiondetail(val.assignRedemptionId)}>Detail</button></td>
                    <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editAssignRedem(val?.assignRedemptionId)} /></button></td>
                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignRedem(val?.assignRedemptionId)} /></button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Bill Value From</th>
                  <th scope="col"> Bill Value To</th>
                  <th scope="col"> Redemption % as per cart value </th>
                  <th scope="col"> Effective From</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {sortedData?.length > 0 &&
                  sortedData?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val.redemBillValueFrom}</td>
                        <td>{val.redemBillValueTo}</td>
                        <td>{val.redemPercentage}</td>
                        <td>{moment(val.redemEffectiveFrom).format('DD-MMM-YYYY')}</td>
                      </tr>
                    )
                  })
                }
                {/* <tr>
                  <td></td>
                  <td></td>                 
                  <td></td>                 
                  <td></td>                 
                  <td><button className='delete'><FontAwesomeIcon icon={faTrash}/></button></td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/checked.png')} />
            </div>
            <h4>Succesfully Deleted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../gsmecom/images/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleFailureModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );

});

export default AssignRedemptionList;
