export class IIEmployee {
    employeeId?: number = 0;
    employee_Name?: string = ""
    employee_Fname?: string = ""
    biometric_id?: string = ""
    doB?: string = new Date().toISOString().substr(0, 10)
    doj?: string = new Date().toISOString().substr(0, 10)
    age?: number = 0;
    emptype?: string = ""
    department?: number = 0;
    designation?: number = 0;
    gender?: string = ""
    maritial_status?: string = ""
    bloodgroup?: string = ""
    height?: string = ""
    weight?: string = ""
    nationality?: string = ""
    religion?: string = ""
    employee_photo?: string = ""
    prefix?: string = ""
    bankName?: string = ""
    accountNo?: string = ""
    ifscCode?: string = ""
    branch?: string = ""
    employeedetails?: IIEmployeeDetails[]
    employeecontact?: IIEmployeeContact[]
    employeeFamily?: IIEmployeeFamily[]//missing
    employEducation?: IIEmployeeEducation[]//missing
    employExperience?: IIEmployeeExperience[]//missing
    employeNominee?: IIEmployeeNominee[]//missing
    employeOfficial?: IIEmployeeOfficial[]
    employeSalary?: IIEmployeeSalary[]//missing
    insuranceList?: IInsurenceList[]
}

export class IIEmployeeDetails {
    empdid?: number = 0
    company?: string = ""
    department?: string = ""
    category?: string = ""
    pfno?: string = ""
    esino?: string = ""
    departmentId?: number = 0
    designationId?: number = 0
    designation?: string = ""
    prefix?: string = ""
    doj?: string = new Date().toISOString().substr(0, 10)
    aadhaarno?: string = ""
    dispensary?: string = ""
    pancardno?: string = ""
    uanno?: string = ""
    dlno?: string = ""
    reportinghr?: string = ""
    reportinghead?: string = ""
    tds_per?: string = ""
    ptapplicable?: string = ""
    pt_per?: string = ""
}

export class IIEmployeeContact {
    empcid?: number = 0
    mobileNo?: string = ""
    alternateMobileNo?: string = ""
    emailId?: string = ""
    area?: string = ""
    permantent_Add?: string = ""
    present_Add?: string = ""
}

export class IIEmployeeFamily {
    empfid?: number = 0
    fName?: string = ""
    relationship?: string = ""
    relationShipDOB?: string = new Date().toISOString().substr(0, 10)
    relationShipMobileNo?: string = ""
}

export class IIEmployeeEducation {
    empeduid?: number = 0
    employeeid?: number = 0
    courses?: string = ""
    school_Univercity?: string = ""
    yearofpassing?: string = ""
}

export class IIEmployeeExperience {
    empexpid?: number = 0
    employeeid?: number = 0
    qualification?: string = ""
    occupation?: string = ""
    designation?: string = ""
    lastEmployer?: string = ""
    yearofExperience?: string = ""
    experpfno?: string = ""
    experEsino?: string = ""
    lastDrawn?: number = 0
}

export class IIEmployeeNominee {
    empnimid?: number = 0
    employeeid?: number = 0
    nomName?: string = ""
    nomDOB?: string = new Date().toISOString().substr(0, 10)
    nomAge?: string = ""
    nomMobileno?: string = ""
    relationship?: string = ""
    nomAddress?: string = ""
}

export class IIEmployeeOfficial {
    empofficid?: number = 0
    employeeid?: number = 0
    companyname?: string = ""
    category?: string = ""
    pfno?: string = ""
    esino?: string = ""
    aadhaarno?: string = ""
    dispansary?: string = ""
    uanNo?: string = ""
    panCardNo?: string = ""
    voterid?: string = ""
    dlNo?: string = ""
    otApplicable?: number = 0
    shithours?: string = ""
    tdsApplicable?: number = 0
    ptApplicable?: number = 0
    empstatus?: string = "Working"
    probation?: number = 0
}

export class IIEmployeeSalary {
    empsalid?: number = 0
    employeeid?: number = 0
    paymentmode?: string = ""
    salarytype?: string = ""
    effectDate?: string = new Date().toISOString().substr(0, 10)
    grossSalary?: string = ""
    earnType?: string = ""
    amount?: string = ""
}

export class IInsurenceList {
    policyNo?: string = ""
    nominee?: string = ""
    insuranceCompany?: string = ""
    nomineeRelation?: string = ""
}