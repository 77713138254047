import React, { useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";

const IncentiveAndRecoveryMaster = () => {

  const navigate = useNavigate();

  const { payrollStore } = useStores();


  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [incentiveRecovery, setIncentiveRecovery] = useState<any>({})

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    // navigate('/Admin/Brand/list');
    handleToIncentiveList()
    setSuccessModal(false)
  }
  async function handleSaveCategory() {
    let error: any = {};
    if (!incentiveRecovery?.Category) {
      error.Category = 'Enter Description'
    }
    if (!incentiveRecovery?.IType) {
      error.IType = "Choose Type"
    }
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      setLoading(true);
      const status = await payrollStore.saveIncentiveRecoverySave(incentiveRecovery)
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true);
      } else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }

  function handleToIncentiveList() {
    navigate('/Admin/IncentiveRecovery/IncentiveRecoveryList')
  }

  return (
    <div>
      {
        isLoading ? <ProgressBar /> :
          <>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox'>
              <div className='hrBox'>
                <h3>Incentive & Recovery Master</h3>
              </div>
              <div className='inputBoxLists'>
                <div className='vertical-space-10'></div>
                <div className='row'>
                  <div className='col-sm-8'>
                    <div className='inputBox'>
                      <label style={{ fontSize: '16px' }}>Description <span>*</span></label>
                      <input type="text"
                        name='Category'
                        style={{ width: "100%" }}
                        value={incentiveRecovery.Category} onChange={(evt) => setIncentiveRecovery({ ...incentiveRecovery, Category: evt.target.value })}
                        className='brandinput' placeholder='Enter Description'></input>
                      {errors.Category && <p style={{ color: 'red' }}>{errors.Category}</p>}
                    </div>
                    <div className='vertical-space-10'></div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='inputBox'>
                      <label style={{ fontSize: '16px' }}>Type<span>*</span></label>
                      <select className="form-selected full-width"
                        name='Type'
                        style={{ fontSize: "16px" }}
                        value={incentiveRecovery.IType} onChange={(evt) => setIncentiveRecovery({ ...incentiveRecovery, IType: evt.target.value })}
                      >
                        <option value="" selected>- -Select - -</option>
                        <option value="Allowance">Allowance </option>
                        <option value="Deduction">Deduction</option>
                      </select>
                    </div>
                    {errors.IType && <p style={{ color: 'red' }}>{errors.IType}</p>}
                    <div className='vertical-space-10'></div>
                  </div>
                </div>
              </div>
              <div className='btnBox'>
                <button className='secondaryBtn' onClick={handleToIncentiveList}>List</button>
                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </div>
  )
}

export default IncentiveAndRecoveryMaster;