import { useEffect, useMemo, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import { PartyLedgerSearch } from "../../Admin/PartyLedger/model";
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { currentPageDatas } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import LedgerSearchValidation from "../../Admin/PartyLedger/validation";


interface ExcelSheetData {
    Date?: string,
    Voucher_No?: string,
    Particulars?: string,
    Debit?: number,
    Credit?: number,
    Closing?: string,
    Transaction_Type?: string,
    Entry_Date?: string
}

const CashBook = () => {

    const [cashBookSearch, setCashBookSearch] = useState<PartyLedgerSearch>(new PartyLedgerSearch())
    const [partyLedgerStatement, setpartyLedgerStatement] = useState<any[]>([])

    console.log(partyLedgerStatement, 'partyLedgerStatement>>')

    const [ledgerNameArr, setLedgerNameArr] = useState<any[]>([])
    const [selectedLedger, setSelectedLedger] = useState<any>({})

    const { partyLedgerStore } = useStores();

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState<any>({})

    function handleChangeclear() {
        setCashBookSearch(new PartyLedgerSearch());
        setpartyLedgerStatement([]);
    }

    function handleInputChange(event) {
        const { name, value } = event?.target;
        setCashBookSearch({ ...cashBookSearch, [name]: value })
    }

    async function fetchPartyLedgerList() {
        let error: any = {};
        error = LedgerSearchValidation(cashBookSearch);
        setErrors(error);
        if (Object.keys(error).length === 0) {
            setIsLoading(true);
            const partyLedgerStatement = await partyLedgerStore.getLedgerCashBook(cashBookSearch);
            if (partyLedgerStatement?.length) {
                setpartyLedgerStatement([...updateClosing(partyLedgerStatement)]);
            }
            setIsLoading(false);
        }
    }

    function updateClosing(updatedArray) {
        updatedArray?.forEach((each, index) => {
            let closingAmt: number = 0;
            if (each?.sort_order != 7 && each?.sort_order != 6 && each?.sort_order != 5 && each?.sort_order != 4 && each?.sort_order != 3) {
                each['closing'] = closingAmt + each?.Debit - each?.Credit
                if (Math.sign(closingAmt)) {
                    each['clsType'] = 'DR';
                } else {
                    each['clsType'] = 'CR';
                }
                return each
            } else {
                each['closing'] = 0;
                each['clsType'] = '';
                closingAmt = 0;
            }
        })
        return updatedArray;
    }

    function createExportExcelObj(partyLedgerDetails: any[]) {
        let excelArr: ExcelSheetData[] = partyLedgerDetails?.map((partyLedger) => {
            const excelObj: ExcelSheetData = {
                Date: ((partyLedger?.sort_order !== 3 && partyLedger?.sort_order !== 4 &&
                    partyLedger?.sort_order !== 5 && partyLedger?.sort_order !== 6 &&
                    partyLedger?.sort_order !== 7) && partyLedger?.vchr_date) ? moment(partyLedger?.vchr_date).format("DD-MMM-YYYY") : '',
                Voucher_No: partyLedger?.vchr_no,
                Particulars: (partyLedger?.ledger_accout_name + " " + partyLedger?.vchr_narration),
                Debit: partyLedger?.Debit > 0 ? partyLedger?.Debit : '0.00',
                Credit: partyLedger?.Credit > 0 ? partyLedger?.Credit : '0.00',
                Closing: Math.abs(partyLedger?.closing)?.toString() + "" + partyLedger?.clsType,
            }
            return excelObj;
        })
        exportToExcel(excelArr)
    }

    function exportToExcel(excelArr: ExcelSheetData[]) {
        const csvContent = ["Cash Book", selectedLedger?.group_name, selectedLedger?.ledgeraccoutname, Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

        const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const today = moment();
        link.download = `Cash Book ${today.format('DD-MM-YYYY')}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function handlePrint() {
        let printContents = document.getElementById('printablediv')!.innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    async function fetchPartyLedgerName() {
        let ledgerNameSearchOption = {
            searchOption: "LedgerAccountName",
            searchValue: ""
        };
        const ledgerNameArry = await partyLedgerStore.loadPartyLedgername(ledgerNameSearchOption)
        if (ledgerNameArry?.length) {
            setLedgerNameArr([...ledgerNameArry])
        }
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPartyLedgerName();
            isCurrentPage.current = false
        }
        return () => { }
    }, [])

    const sortedData = useMemo(() => {
        const listOfData = partyLedgerStatement?.slice()?.sort((a: any, b: any) => {
            const dateA = new Date(a?.entrydate);
            const dateB = new Date(b?.entrydate);

            if (dateA > dateB) return -1;
            if (dateB < dateA) return 1;
            return 0;
        });
        return listOfData?.length ? listOfData : []
    }, [partyLedgerStatement])

    return (
        <div className="contaienr">
            {isLoading ? <div className='SpinnerBox'>
                <Spinner animation="grow" size="sm" />
            </div> : null}
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
                <div className='hrBox'>
                    <h3>Cash Book</h3>
                </div>
                <div className='inputBoxLists'>
                    <div className='ItemInwardInputBox'>
                        <div className="row">
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>From Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        onChange={handleInputChange}
                                        name="searchDatefrom"
                                        value={cashBookSearch?.searchDatefrom}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>To Date <span>*</span></label>
                                    <input type="date" style={{ width: "100%" }}
                                        value={cashBookSearch?.searchDateto}
                                        name="searchDateto"
                                        onChange={handleInputChange}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                                </div>
                                <div className='vertical-space-20'></div>
                            </div>
                            <div className='col-sm-2'>
                                <div className='inputBox'>
                                    <label>Ledger Name <span>*</span></label>
                                    <AutoComplete options={ledgerNameArr?.length ? ledgerNameArr : []} placeholder='Party Name...'
                                        emitOption={(option) => { setCashBookSearch({ ...cashBookSearch, ledgername: option?.ledgeraccoutname, ledgerId: option?.ledger_accout_id }); setSelectedLedger(option) }} getOptionLabel='ledgeraccoutname' clsName='full-width' />
                                </div>
                                {errors?.ledgername && <p style={{ color: 'red' }}>{errors?.ledgername}</p>}
                            </div>
                            <div className="col-sm-5" style={{ marginTop: '30px' }}>
                                <div className='btnBox'>
                                    <button className='secondaryBtn' onClick={fetchPartyLedgerList}> Search</button>
                                    <button className='secondaryBtn' onClick={handlePrint}> Print</button>
                                    <button className='secondaryBtn' onClick={() => createExportExcelObj(sortedData)}>Excel</button>
                                    <button className="secondaryBtn" onClick={handleChangeclear}> Clear</button>
                                    <button className="secondaryBtn" onClick={() => { }}> Close </button>
                                </div>
                            </div>
                        </div>
                        <div style={partyLedgerStatement?.length > 0 ? { visibility: 'visible', width: '100%' } : { visibility: "hidden" }}>
                            <div id='printablediv' className="tableListDetails">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Voucher No</th>
                                            <th scope="col">Particulars</th>
                                            <th scope="col">Credit</th>
                                            <th scope="col">Debit</th>
                                            <th scope="col">Closing</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textTransform: 'uppercase', color: 'green' }} colSpan={6}>{selectedLedger?.group_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textTransform: 'uppercase', color: 'blue' }} colSpan={6}>{selectedLedger?.ledgeraccoutname}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}></td>
                                            <td>Opening balance</td>
                                            <td>0.00</td>
                                            <td>0.00</td>
                                            <td></td>
                                        </tr>
                                        {partyLedgerStatement?.map((statement) => {
                                            return (
                                                <tr>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { visibility: 'hidden' } :
                                                        statement?.sort_order === 4 ? { visibility: 'hidden' } :
                                                            statement?.sort_order === 5 ? { visibility: 'hidden' } :
                                                                statement?.sort_order === 6 ? { visibility: 'hidden' } :
                                                                    statement?.sort_order === 7 ? { visibility: 'hidden' } :
                                                                        {}}>{statement?.vchr_date ? moment(statement?.vchr_date).format("DD-MMM-YYYY") : ''}</td>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { visibility: 'hidden' } :
                                                        statement?.sort_order === 4 ? { visibility: 'hidden' } :
                                                            statement?.sort_order === 5 ? { visibility: 'hidden' } :
                                                                statement?.sort_order === 6 ? { visibility: 'hidden' } :
                                                                    statement?.sort_order === 7 ? { visibility: 'hidden' } :
                                                                        {}}>{statement?.vchr_no}</td>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { fontWeight: '700' } :
                                                        statement?.sort_order === 4 ? { color: 'darkmagenta' } :
                                                            statement?.sort_order === 5 ? { color: 'red' } :
                                                                statement?.sort_order === 6 ? { color: 'black' } :
                                                                    statement?.sort_order === 7 ? { color: 'red', fontWeight: '700' } :
                                                                        {}}>{statement?.ledger_accout_name + " " + statement?.vchr_narration}</td>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { fontWeight: '700' } :
                                                        statement?.sort_order === 4 ? { color: 'darkmagenta' } :
                                                            statement?.sort_order === 5 ? { color: 'red' } :
                                                                statement?.sort_order === 6 ? { color: 'black' } :
                                                                    statement?.sort_order === 7 ? { color: 'red', fontWeight: '700' } :
                                                                        {}}>{statement?.Credit > 0 ? statement?.Credit : '0.00'}</td>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { fontWeight: '700' } :
                                                        statement?.sort_order === 4 ? { color: 'darkmagenta' } :
                                                            statement?.sort_order === 5 ? { color: 'red' } :
                                                                statement?.sort_order === 6 ? { color: 'black' } :
                                                                    statement?.sort_order === 7 ? { color: 'red', fontWeight: '700' } :
                                                                        {}}>{statement?.Debit > 0 ? statement?.Debit : '0.00'}</td>

                                                    <td scope='col' style={statement?.sort_order === 3 ? { visibility: 'hidden' } :
                                                        statement?.sort_order === 4 ? { visibility: 'hidden' } :
                                                            statement?.sort_order === 5 ? { visibility: 'hidden' } :
                                                                statement?.sort_order === 6 ? { visibility: 'hidden' } :
                                                                    statement?.sort_order === 7 ? { visibility: 'hidden' } :
                                                                        {}}>{Math.abs(statement?.closing)?.toString() + "" + statement?.clsType}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='vertical-space-20'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CashBook;