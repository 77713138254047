
function departmentValidation(departmentDetails){
  let errors :any={};
    if(!departmentDetails.department){
      errors.department = "Enter Department Name";
    }
    // if(!departmentDetails.description){
    //   errors.description = "Enter Department Description";
    // }
    
  
  return errors;
};
  
  export default departmentValidation;