import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
// import { IAssignOfferAndDiscountSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const StockAdjustmentWarehouseList = observer((): JSX.Element => {
//   const [assignOfferDiscountSearchOptions, setAssignOfferDiscounttSearchOptions] = useState<IAssignOfferAndDiscountSearchoptions>({})
  const { stockAdjustmentStore, userCreationStore, outwardtoOutletStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [isLoading, setLoading] = useState(true);
  const [isAssignedOfferDetails, setAssignOfferDiscountModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [stockAdjustWareList, setStockAdjustWareList] = useState<any[]>([])
  const [stockAdjustWareDetails, setStockAdjustWareDetails] = useState<any[]>([])
  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteAssignOfferMaster = async (stockadjmid) => {
    setLoading(true);
    const status = await stockAdjustmentStore.deleteStockAdjustmentWarehouse(stockadjmid,stockadjmid);

    if (status === 'Success') {
      fetchAssignOfferDiscouts();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  // const deleteAssignOfferDetail = async (assignOffeDetailId) => {
  //   setAssignOfferDiscountModal(false);
  //   setLoading(true);
  //   const status = await assignOfferDiscountStores.deleteAssignOfferDetail(assignOffeDetailId);

  //   if (status === 'Success') {
  //     fetchAssignOfferDiscouts();
  //     setLoading(false);
  //     setSuccessModal(true);
  //   }
  //   else {
  //     setLoading(false);
  //     setFailureModal(true);
  //   }
  // }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchAssignOfferDiscouts();
  }

  const handleEditAssignOffer = (stockadjsno) => {
    navigate('/Admin/StockAdjustment/Warehouse/' + stockadjsno);
  }

  const getAssignOfferDiscountDetails = async (stockvalueDet) => {
    const stockAdjustWareDetails = await stockAdjustmentStore.getStockAdjustmentWarehouse(stockvalueDet?.stockadjsno,stockvalueDet?.stockadjsno);
    setStockAdjustWareDetails([...stockAdjustWareDetails])
    console.log(stockAdjustWareDetails,'stockAdjustWareDetails');
    
    if (stockAdjustWareDetails !== undefined || stockAdjustWareDetails !== null) {
      setAssignOfferDiscountModal(true);
    }
  }
  const handleClose = () => {
    setAssignOfferDiscountModal(false);
  }

  async function fetchAssignOfferDiscouts() {
    setLoading(false)
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Assign_offer_discounts')])
    const stockAdjustWareList = await stockAdjustmentStore.getStockAdjustmentWarehouse(0,0);
    setStockAdjustWareList([...stockAdjustWareList])
    console.log(stockAdjustWareList,'stockAdjustWareList');
    
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchAssignOfferDiscouts();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);


  const handleCreate = () => {
    navigate('/Admin/StockAdjustment/Warehouse');
  }


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (stockAdjustWareList && stockAdjustWareList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(stockAdjustWareList)
    }
  }, [stockAdjustWareList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(stockAdjustWareList?.slice()?.sort((a, b) => b.assignOfferId - a.assignOfferId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={stockAdjustWareList}
                searchTitles={[{ key: "assignedByName", value: "AssignedBy Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{stockAdjustWareList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                    <th scope='col'>Stock Adjustment No </th>
                    <th scope='col'>Stock Adjustment Date </th>
                      <th scope='col'>Warehouse </th>
                      <th scope='col'></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Array.isArray(isSearch ? filteredData : currentPageData) &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>   
                            <td>{val.stockadjsno}</td> 
                            <td>{moment(val.Stockadjdate).format('DD-MM-YYYY')}</td>                      
                            <td>{val.Warename}</td>
                            <td>
                              <button disabled={!userRights?.view} onClick={() => getAssignOfferDiscountDetails(val)}>
                                Detail
                              </button>
                            </td>
                            <td>
                              <button disabled={!userRights?.add} className='Add'>
                                <FontAwesomeIcon icon={faAdd} onClick={handleCreate} />
                              </button>
                            </td>
                            <td>
                              <button disabled={!userRights?.edit} className='Edit'>
                                <FontAwesomeIcon icon={faPenToSquare} onClick={() => handleEditAssignOffer(val.stockadjsno)} />
                              </button>
                            </td>
                            <td>
                              <button disabled={!userRights?.delete} className='delete'>
                                <FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignOfferMaster(val.stockadjsno)} />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>

                </table>
              </div>

              {
                isAssignedOfferDetails ?
                  <Modal show={isAssignedOfferDetails} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>

                              <th scope="col">Item Name</th>
                              <th scope="col">Unit Name </th>
                              <th scope="col">Barcode </th>
                              <th scope="col">Batch No </th>
                              <th scope="col">Add Qty</th>
                              <th scope="col">Less Qty</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              stockAdjustWareDetails?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.itemname}</td>
                                    <td>{val.Unitname}</td>
                                    <td>{val.barcode}</td>
                                    <td>{val.batchno}</td>
                                    <td>{val.AddQty}</td>
                                    <td>{val.LessQty}</td>
                                    {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignOfferDetail(val.assignOffeDetailId)} /></button></td> */}
                                  </tr>
                                )
                              })
                            }

                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default StockAdjustmentWarehouseList;
