import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IDamageEntryOutlet } from '../model';
import { IDamageTransfer } from '../../../Stores/damageTransfer/model';
import { IDamageEntryApproval } from '../../damageEntryApproval/model';

class DamageEntryOutletStore {
    @observable outletItemDetails = new Map();
    @observable loadDamageItemBatch = new Map();
    @observable getDamageEntryOutletList = new Map();
    @observable getDamageEntryOutletDetail = new Map();
    @observable loadDamageEntryOutletDetails = new Map();
    entryNo = new Map();


    loadOutletItems = async () => {
        try {
            const inwardData = await apiAgent.damageEntryOutlet.loadOutletItems();

            runInAction(() => {
                this.outletItemDetails = inwardData;
            });
            return this.outletItemDetails;
        }
        catch (error) {
            console.log(error);
        }
    }


    saveDamageEntryOutlet = async (damageEntryOutlet: IDamageEntryOutlet) => {

        try {
            const status = await apiAgent.damageEntryOutlet.saveDamageEntryOutlet(damageEntryOutlet);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageItemBatchNo = async (batchRequest) => {

        try {
            const loadDamageItemBatch = await apiAgent.damageEntryOutlet.loadDamageItemBatchNo(batchRequest);


            runInAction(() => {

                this.loadDamageItemBatch = loadDamageItemBatch;

            })
            return this.loadDamageItemBatch;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDamageEntryOutlet = async () => {

        try {
            const getDamageEntry = await apiAgent.damageEntryOutlet.getDamageEntryOutlet();

            runInAction(() => {

                this.getDamageEntryOutletList = getDamageEntry;
            });
            return this.getDamageEntryOutletList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDamageEntryOutletDetails = async (damagentryMaterid) => {

        try {
            const getDamageEntryOutlet = await apiAgent.damageEntryOutlet.getDamageEntryOutletDetails(damagentryMaterid);

            runInAction(() => {

                this.getDamageEntryOutletDetail = getDamageEntryOutlet;
            });
            return this.getDamageEntryOutletDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageEntryOutletDetail = async (outletId) => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.loadDamageEntryOutletDetail(outletId);
            runInAction(() => {
                this.loadDamageEntryOutletDetails = JSON.parse(loadDamageEntryOutletDetails);
            });

            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveDamageTransferDetail = async (damageTranser: IDamageTransfer) => {

        try {
            const status = await apiAgent.damageEntryOutlet.saveDamageTransferDetail(damageTranser);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    saveDamageOutletReturn = async (damageOutletReturn: IDamageTransfer) => {
        try {
            const status = await apiAgent.damageEntryOutlet.saveDamageOutletReturn(damageOutletReturn);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageTransferDetails = async (id?: number) => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.loadDamageTransferDetail(id);
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageTransferPendingDetails = async (id, mode) => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.loadDamageTransferPendingDetails(id, mode);
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    damageTransfterPrint = async (id?: number) => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.damageTransfterPrint(id);
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    outletReturnPrint = async (id?: number) => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.outletReturnPrint(id);
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveDamageEntryApproval = async (damageEntryApproval: IDamageEntryApproval) => {

        try {
            const status = await apiAgent.damageEntryOutlet.saveDamageEntryApproval(damageEntryApproval);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDamageEntryOutletDetail = async (damagedetailid) => {

        try {
            const status = await apiAgent.damageEntryOutlet.deleteDamageEntryOutletDetail(damagedetailid);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDamageEntryOutlet = async (damageid) => {

        try {
            const status = await apiAgent.damageEntryOutlet.deleteDamageEntryOutlet(damageid);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDamageEntryOutletSearch = async (SearchOptions) => {
        try {
            const Data = await apiAgent.damageEntryOutlet.getDamageEntryOutletSearch(SearchOptions);
            this.getDamageEntryOutletList = Data;

            runInAction(() => {
                this.getDamageEntryOutletList = Data;

            })
            return this.getDamageEntryOutletList;


        }
        catch (error) {
            console.log(error);
        }
    }

    // Outlet Return Form

    loadDamageTransferOutletDetails = async () => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.loadDamageTransferOutletDetails();
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }
    saveDamageOutletTransferMst = async (damageTranser) => {
        try {
            const status = await apiAgent.damageEntryOutlet.saveDamageOutletTransferMst(damageTranser);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadDamageTransferOutletDet = async () => {
        try {
            const loadDamageEntryOutletDetails = await apiAgent.damageEntryOutlet.loadDamageTransferOutletDet();
            return JSON.parse(loadDamageEntryOutletDetails);
        }
        catch (error) {
            console.log(error);
        }
    }


}

export default DamageEntryOutletStore;