import { observable } from "mobx";
export interface IDesignation{
    designationId?:number;
    designationName?:string;
    description?:string;
   
}
export interface IDesignationSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const Designation=observable<IDesignation>({
    designationId:0,
    designationName:'',
    description:'',
    
});

export default Designation;