import { observable } from "mobx";
export class IitemMaster {
    itemCode?: string = "";
    extraCode?: string = "";
    barcode?: string // new parameter
    itemName?: string = "";
    itemCategory?: string = "";
    netWeight?: string = "";
    subcategoryName?: string = "";
    brand?: number = 0;
    grossWeight?: string = "";
    rackNo?: number = 0;
    mrpp?: string = "0";
    purchasePrice?: string = "";
    currentStock?: string = "";
    unitOfMeasure?: string = "";
    stocksLimit?: string = "";
    comments?: string = "";
    taxName?: string = "";
    taxId?: number = 0;
    suppliers?: ISupplier[] = [];
    subCategoryId?: number
    fileName?: string = "";
    supplier?: string = "";
    subcategory?: string = "";
    brnd?: string = "";
    hsnCode?: string = "";
    gsmCode?: string = "";
    imageData?: any;
    brandCompany?: string = "";
    cessName?: string = "";
    cessId?: number = 0;
    itemId?: number = 0;
    unitId?: number = 0;
    unitName?: string = "";
    unittype?: string = "";
    expiryDays?: number = 0;
    brandType?: string = "";
    convuomid?: number = 0;
    convQty?: number = 0;
    purchaseuomid?: number = 0;
    oldmrp?: string = ""
    b2BoldPrice?: number = 0
    b2ColdPrice?: number = 0
    b2BnewPrice?: number = 0
    b2CNewPrice?: number = 0
    b2Bper?: number = 0
    b2Cper?: number = 0
    effectdate?: string
    batchNo?: string
}

export class IGsmCodeDetails {
    category?: string = "";
    subCategory?: string = "";
    brand?: string = "";
}

export class IItemSearchoptions {
    searchOption?: string = "";
    searchValue?: string = "";
}

export class ILoyalties {
    pointOfrReclaimName?: string = ""
    points?: string = ""
    pointsOfNo?: string = ""
}

export class IPromotionals {
    offerName?: string = ""
    startDate?: string = new Date()?.toISOString()?.substr(0, 10)
    endDate?: string = new Date()?.toISOString()?.substr(0, 10)
}

export class ISupplier {
    id?: number = 0;
    supplierName?: string = "";
    supplierId?: number = 0;
    commissionPercentage?: string = "";
    supplier?: string
}

export class IItemMaster {
    itemId?: number = 0
    itemCode?: number = 0
    hsnCode?: string = ""
    automatic?: string = ""
    extraCode?: string = ""
    barcode?: string = "" // new parameter
    eangtin?: string = ""
    itemName?: string = ""
    itemCategory?: string = ""
    netWeight?: string = "0"
    subCategoryId?: number = 0
    brand?: number = 0
    grossWeight?: string = "0"
    rackNo?: string = ""
    mrpp?: string = "0"
    automatic2?: string = ""
    allowPriceChangeDuringTheSale?: string = ""
    purchasePrice?: string = "0"
    currentStock?: string = "0"
    unitOfMeasure?: string = "";
    unitId?: number = 0
    unitName?: string = ""
    unittype?: string = ""
    allowFractioningByWeightLength?: string = ""
    stocksLimit?: string = "0"
    comments?: string = ""
    taxId?: number = 0
    taxName?: string = ""
    suppliers?: ISupplier[] = []
    promotionals?: IPromotionals[] = []
    loyalities?: ILoyalties[] = []
    fileName?: string = ""
    subcategory?: string = "";
    brnd?: string = "";
    imageData?: any;
    brandCompany?: string = "";
    gsmCode?: string = ""
    subcategoryName?: string = ""
    brandName?: string = ""
    cessName?: string = ""
    cessId?: number = 0
    expiryDays?: number = 0
    brandType?: string = ""
    convuomid?: number = 0
    convQty?: number = 0
    purchaseuomid?: number = 0
    oldmrp?: string = ""
    b2BoldPrice?: number = 0
    b2ColdPrice?: number = 0
    b2BnewPrice?: number = 0
    b2CNewPrice?: number = 0
    b2Bper?: number = 0
    b2Cper?: number = 0
    effectdate?: string = new Date()?.toISOString()?.substr(0, 10)
    batchNo?: string = ""
    ismsqty?: boolean = false
    msqty?: number = 0
    coinqty?: number = 0
}

export class IExcelSheetData {
    Gsmcode?: string;
    itemname?: string;
    Regional_Itemname?: string;
    Category?: string;
    Hsncode?: number;
    extraCode?: string;
    netWeight?: number;
    grossWeight?: number; 
    subCategory?: string;
    brand?: string;
    Own_Brand?: string;
    MRP?: number;
    Effective_Date?: Date;
    purchasePrice?: number;
    coinchange?: string;
    Min_Sal?: string;
    Min_Selling_Qty?: number;
    Current_Stock?: number;
    Unit_of_Measure?: string;
    Unit_Type?: string;
    Stocks_Limit?: number;
    Expiry_Days?: number;
    Comments?: string;
    taxname?: string;
    cessname?: string;
    supplierName_item?: string;
    Commission_per?: number;
    purchaseuom?: string;
    Convuom?: string;
    ConvQty?: number;
}

export class IIExceluploadModal {
    impsno?: number = 1
    itemmaster_import?: IIExcelUpload[] = []
}

export class IIExcelUpload {
    sno?: number = 0
    gsmcode?: string = ""
    itemName?: string = ""
    regionaitemname?: string = ""
    hsncode?: string = "0"
    category?: string = ""
    grossWeight?: number = 0
    netWeight?: number = 0
    subcategoryName?: string = ""
    brandName?: string = ""
    ownBrand?: string = ""
    mrp?: number = 0
    effectdate?: string = ""
    purchasePrice?: number = 0
    unitofMesurement?: string = ""
    stocksLimit?: number = 0
    expiryDays?: number = 0
    commands?: string = ""
    taxname?: string = ""
    cessname?: string = ""
    supplierName?: string = ""
    commission_per?: string = ""
    unittype?: string = ""
    brandType?: string = ""
    purchase_Uom?: string = ""
    stock_Uom?: string = ""
    convQty?: number = 0
    minsalqty?: number = 0
    coinchange?: string = ""
    extraCode?: string=""
    minsal?: string=""
    convuom?: string=""
}

export function excelKeyupdate(excelSheeData: any[]) {
    let excelArry: IIExcelUpload[] = []
    if (excelSheeData?.length) {
        let excelArry = excelSheeData?.map((each: IExcelSheetData, index) => {
            let excelObj: IIExcelUpload = {
                brandName: each?.brand,
              //  brandType: each?.BrandType,
                category: each?.Category,
                cessname: each?.cessname,
                coinchange: !isNaN(Number(each?.coinchange)) ? each?.coinchange?.toString() : "0",
                commands: each?.Comments,
                commission_per: !isNaN(Number(each?.Commission_per)) ? each?.Commission_per?.toString() : "0",
                convQty: !isNaN(Number(each?.ConvQty)) ? Number(each?.ConvQty) : 0,
                effectdate: new Date(each?.Effective_Date!)?.toISOString()?.substr(0, 10),
                expiryDays: !isNaN(Number(each?.Expiry_Days)) ? Number(each?.Expiry_Days) : 0,
                grossWeight: !isNaN(Number(each?.grossWeight)) ? Number(each?.grossWeight) : 0,
                gsmcode: each?.Gsmcode,
                hsncode: !isNaN(Number(each?.Hsncode)) ? each?.Hsncode?.toString() : "0",
                itemName: each?.itemname,
                minsalqty: !isNaN(Number(each?.Min_Sal)) ? Number(each?.Min_Sal) : 0,
                mrp: !isNaN(Number(each?.MRP)) ? Number(each?.MRP) : 0,
                netWeight: !isNaN(Number(each?.netWeight)) ? Number(each?.netWeight) : 0,
                ownBrand: each?.Own_Brand,
                purchase_Uom: each?.purchaseuom,
                purchasePrice: !isNaN(Number(each?.purchasePrice)) ? Number(each?.purchasePrice) : 0,
                regionaitemname: each?.Regional_Itemname,
            //    sno: each?.Sno,
             //   stock_Uom: each?.Stock_Uom,
                stocksLimit: !isNaN(Number(each?.Stocks_Limit)) ? Number(each?.Stocks_Limit) : 0,
                subcategoryName: each?.subCategory,
                supplierName: each?.supplierName_item,
                taxname: each?.taxname,
                unitofMesurement: each?.Unit_of_Measure,
                extraCode: each?.extraCode,
                minsal: each?.Min_Sal,
                convuom: each?.Convuom
             //   unittype: each?.Unit_Type
            }
            return excelObj
        })
        return excelArry
    }
    return excelArry
}