import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Container, Button, Image, Spinner, Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Ecom.css';
import { observer } from 'mobx-react-lite';
import Cookies from 'js-cookie';
import { ICartProduct, IProduct, IProductMember, IProductPriceDetails } from '../Products/model';
import { useNavigate } from "react-router-dom";
import useEcomStores from '../hooks/index';
import useStores from '../../gsmadmin/hooks';
import HeaderComp from '../Header/newHeader';
import InfoIcon from '@mui/icons-material/Info';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { checkForFreeItem, checkForItemOffer, isEqual, updateBatchList } from '../common/commonFunc';
import { IPOSProductDetails, IPosBatchDetails, IPossalesMaster } from '../../gsmadmin/Admin/POS/model';



import './cart.css'

const Carts = observer((): JSX.Element => {

  let memberId = Cookies.get('memberId');

  const { posMasterStore, itemMasterStore } = useStores();

  const { cartsDetailsStore, ecomHomeStore } = useEcomStores();

  const { getCartDetails, getOnlineItems, getLoadItemImages } = ecomHomeStore;


  const [product, setProduct] = useState<IProduct>({})

  const [priceDetails, setPriceDetails] = useState<IProductPriceDetails>({})

  const [posDetail, setPosDetail] = useState<IPossalesMaster>(new IPossalesMaster());

  const [posProductList, setPosProductList] = useState<any[]>([])
  const [posItemOffers, setPosItemOffers] = useState<any[]>(posMasterStore?.getItemOffers)
  const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)

  const [posItemDetils, setPOStItemDetails] = useState<any[]>([]);
  const [assignedOffers, setAssignedOff] = useState<any[]>([])
  const [posBatchDetails, setPOSBatchDetails] = useState<IPosBatchDetails[]>(new Array<IPosBatchDetails>());

  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [isRemoveProduct, setRemoveProduct] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [removeProdId, setRemoveProductId] = useState(0);

  let totatlMRP = 0;
  let sellingPrice = 0;
  let discountValue = 0;
  let totalTaxPrice = 0;
  let totalQty = 0;

  const addQty = (productId) => {
    cartsDetailsStore.cartDetails.forEach(element => {

      if (productId === element.itemId) {
        element.productQty! = element.productQty! + 1;
        priceDetails.totalMRP! += element.mrp;
        priceDetails.totalSellingPrice! += element.sellingPrice;
        priceDetails.totalDiscountValue += element.discountPercentageorValue;
        priceDetails.totalTaxPrice += element.taxPercentage / 100 * element.sellingPrice
        totalQty += element.productQty!;
      }
    });
    priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue!;
    setPriceDetails(priceDetails)
    setProduct({ ...product, productQty: product.productQty })
  }

  const subtarctQty = (productId, deleteOptions?) => {
    cartsDetailsStore.cartDetails.forEach(element => {
      if (productId === element.itemId) {
        const qty = element.productQty;
        if (qty === 1 && deleteOptions === undefined) {
          setRemoveProductId(productId);
          setRemoveProduct(true);
        } else {
          element.productQty! = deleteOptions === "No" ? 1 : element.productQty! - 1
          priceDetails.totalMRP = deleteOptions === "No" ? priceDetails.totalMRP : priceDetails.totalMRP! - element.mrp;
          priceDetails.totalSellingPrice = deleteOptions === "No" ? priceDetails.totalSellingPrice : priceDetails.totalSellingPrice! - element.sellingPrice;
          priceDetails.totalDiscountValue = deleteOptions === "No" ? priceDetails.totalDiscountValue : priceDetails.totalDiscountValue! - element.discountPercentageorValue;
          priceDetails.totalTaxPrice = deleteOptions === "No" ? priceDetails.totalTaxPrice : priceDetails.totalTaxPrice! - element.taxPercentage / 100 * element.sellingPrice
          totalQty -= element.productQty!;
        }
      }
    });
    priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue!;
    setPriceDetails(priceDetails)
    setProduct({ ...product, productQty: product.productQty })
  }

  async function removeProduct() {
    if (Cookies.get('ProductCarts') !== undefined) {
      let product: IProduct = {};
      let products: IProduct[] = [];
      const cookiesProduct = JSON.parse(Cookies.get('ProductCarts'));
      let productIds = cookiesProduct.filter(m => m.productId !== removeProdId).map(x => x.productId).join(",");
      await cartsDetailsStore.getCartDetailsCookies(productIds ? productIds : 0);
      if (productIds !== "") {
        totatlMRP = 0;
        sellingPrice = 0;
        discountValue = 0;
        totalTaxPrice = 0;
        totalQty = 0;
        cartsDetailsStore.cartDetails.forEach(element => {
          let qty = cookiesProduct.find(m => m.productId === element.itemId)
          totatlMRP += element.mrp * qty.productQty;
          sellingPrice += element.sellingPrice * qty.productQty;
          discountValue += element.discountPercentageorValue;
          totalTaxPrice += element.taxPercentage / 100 * element.sellingPrice
          product.productQty = qty.productQty;
          product.productId = element.itemId;
          totalQty += qty.productQty;
          element.productQty = qty.productQty;
          products.push(product);
        });
        setPriceDetails({});
        priceDetails.totalMRP = totatlMRP ? totatlMRP : 0;
        priceDetails.totalSellingPrice = sellingPrice ? sellingPrice : 0;
        priceDetails.totalDiscountValue = discountValue ? discountValue : 0;
        priceDetails.totalTaxPrice = totalTaxPrice ? totalTaxPrice : 0;
        priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue;
        priceDetails.totalQty = totalQty;
        setProduct(product);
        setPriceDetails(priceDetails);
        Cookies.remove('ProductCarts');
        Cookies.set('ProductCarts', JSON.stringify(products));
        const productCookies = JSON.parse(Cookies.get('ProductCarts'));
        Cookies.set('cartCount', productCookies.length);
        setRemoveProduct(false);
      }
    } else if (memberId !== undefined) {
      let productMemeberId: IProductMember = {};
      productMemeberId.memberId = Number(memberId);
      productMemeberId.productId = removeProdId;
      await cartsDetailsStore.getCartDetails(productMemeberId);
      totatlMRP = 0;
      sellingPrice = 0;
      discountValue = 0;
      totalTaxPrice = 0;
      totalQty = 0;
      cartsDetailsStore.cartDetails.map((item) => {
        totatlMRP += item.mrp * item.productQty;
        sellingPrice += item.sellingPrice * item.productQty;
        discountValue += item.discountPercentageorValue;
        totalTaxPrice += item.taxPercentage / 100 * item.sellingPrice
        product.productQty = item.productQty;
        totalQty += item.productQty;
      })
      priceDetails.totalMRP = totatlMRP ? totatlMRP : 0;
      priceDetails.totalSellingPrice = sellingPrice ? sellingPrice : 0;
      priceDetails.totalDiscountValue = discountValue ? discountValue : 0;
      priceDetails.totalTaxPrice = totalTaxPrice ? totalTaxPrice : 0;
      priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue;
      priceDetails.totalQty = totalQty;
      product.productQty = cartsDetailsStore.cartDetails.reduce((a, v) => a = a + v.productQty!, 0);
      setProduct(product);
      setPriceDetails(priceDetails);
      Cookies.remove('cartCount');
      setRemoveProduct(false);
    } else {
      Cookies.remove('ProductCarts');
      Cookies.remove('cartCount');
      navigate('/');
    }
  }

  async function removeCartProduct() {
    if (Cookies.get('ProductCarts') !== undefined) {
      let productCart: ICartProduct = {};
      let productCarts: ICartProduct[] = [];

      let filteredArry = posItemDetils?.filter((each, index) => index != removeProdId);

      productCarts = filteredArry?.map((each, index) => {
        productCart = {
          itemName: each?.posItemName,
          productId: each?.posItemId,
          mrpPrice: Number(each?.posItemMRP),
          totalPrice: each?.posTotalPrice,
          productQty: each?.posItemQty,
          billValue: each?.posNetAmount,
          taxValue: each?.posTaxValue,
          discountValue: each?.posDiscountValue,
          sellingRate: Number(each?.posItemSellingRate),
          webName: "",
          taxPercentage: each?.posTaxPercentage,
          discountPercentage: each?.posDiscountPercentage,
          comments: each?.posComments,
          itemImage: each?.imageData,
          stock: each?.availableQty,
          batchNo: each?.posbatchNo,
          batchQty: each?.posbatchqty,
          uom: each?.posUnitOdMeasure,
          barcode: each?.posBarcode,
          category: each?.posCategory,
          subId: each?.posSubCategoryId
        }
        return productCart;
      })

      let data = JSON.parse(Cookies.get('ProductCarts'));
      data = [...productCarts];
      if (data?.length) {
        Cookies.set('ProductCarts', JSON.stringify(data))
        Cookies.set('cartCount', data?.length)
      } else {
        Cookies.remove('ProductCarts');
        Cookies.remove('cartCount');
      }
      setPOStItemDetails([...filteredArry]);
      setRemoveProduct(false);
    } else if (memberId !== undefined) {
      let productMemeberId: IProductMember = {};
      productMemeberId.memberId = Number(memberId);
      productMemeberId.productId = removeProdId;
      await cartsDetailsStore.getCartDetails(productMemeberId);
      totatlMRP = 0;
      sellingPrice = 0;
      discountValue = 0;
      totalTaxPrice = 0;
      totalQty = 0;
      cartsDetailsStore.cartDetails.map((item) => {
        totatlMRP += item.mrp * item.productQty;
        sellingPrice += item.sellingPrice * item.productQty;
        discountValue += item.discountPercentageorValue;
        totalTaxPrice += item.taxPercentage / 100 * item.sellingPrice
        product.productQty = item.productQty;
        totalQty += item.productQty;
      })
      priceDetails.totalMRP = totatlMRP ? totatlMRP : 0;
      priceDetails.totalSellingPrice = sellingPrice ? sellingPrice : 0;
      priceDetails.totalDiscountValue = discountValue ? discountValue : 0;
      priceDetails.totalTaxPrice = totalTaxPrice ? totalTaxPrice : 0;
      priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue;
      priceDetails.totalQty = totalQty;
      product.productQty = cartsDetailsStore.cartDetails.reduce((a, v) => a = a + v.productQty!, 0);
      setProduct(product);
      setPriceDetails(priceDetails);
      Cookies.remove('cartCount');
      setRemoveProduct(false);
    } else {
      Cookies.remove('ProductCarts');
      Cookies.remove('cartCount');
      navigate('/');
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleRemoveModalClose = () => {
    removeCartProduct()
    // removeProduct();
  }

  const handleCancelModalClose = () => {
    setRemoveProduct(false);
    // subtarctQty(removeProdId, "No");
    setRemoveProductId(0)
  }

  async function fetchPreLoadingData() {
    setPriceDetails({});
    if (memberId !== undefined) {
      let productMemeber: IProductMember = {};
      productMemeber.memberId = Number(memberId);
      productMemeber.productId = 0;
      await cartsDetailsStore.getCartDetails(productMemeber)
      totatlMRP = 0;
      sellingPrice = 0;
      discountValue = 0;
      totalTaxPrice = 0;
      totalQty = 0;
      cartsDetailsStore.cartDetails.map((item) => {
        totatlMRP += item.mrp * item.productQty;
        sellingPrice += item.sellingPrice * item.productQty;
        discountValue += item.discountPercentageorValue;
        totalTaxPrice += item.taxPercentage / 100 * item.sellingPrice
        product.productQty = item.productQty;
        totalQty += item.productQty;
      });
      setPriceDetails({});
      priceDetails.totalMRP = totatlMRP ? totatlMRP : 0;
      priceDetails.totalSellingPrice = sellingPrice ? sellingPrice : 0;
      priceDetails.totalDiscountValue = discountValue ? discountValue : 0;
      priceDetails.totalTaxPrice = totalTaxPrice ? totalTaxPrice : 0;
      priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue;
      priceDetails.totalQty = totalQty;
      product.productQty = cartsDetailsStore.cartDetails.reduce((a, v) => a = a + v.productQty!, 0);
      setProduct(product);
      setPriceDetails(priceDetails);
    } else {
      if (Cookies.get('ProductCarts') !== undefined) {
        const cookiesProduct = JSON.parse(Cookies.get('ProductCarts'));
        let productIds = cookiesProduct.map(x => x.productId).join(",");
        await cartsDetailsStore.getCartDetailsCookies(productIds);
        totatlMRP = 0;
        sellingPrice = 0;
        discountValue = 0;
        totalTaxPrice = 0;
        totalQty = 0;
        cartsDetailsStore?.cartDetails?.forEach(element => {
          let qty = cookiesProduct.find(m => m.productId === element.itemId)
          totatlMRP += element.mrp * qty.productQty;
          sellingPrice += element.sellingPrice * qty.productQty;
          discountValue += element.discountPercentageorValue;
          totalTaxPrice += element.taxPercentage / 100 * element.sellingPrice
          product.productQty = qty.productQty;
          totalQty += qty.productQty;
          element.productQty = qty.productQty;
        });
        setPriceDetails({});
        priceDetails.totalMRP = totatlMRP ? totatlMRP : 0;
        priceDetails.totalSellingPrice = sellingPrice ? sellingPrice : 0;
        priceDetails.totalDiscountValue = discountValue ? discountValue : 0;
        priceDetails.totalTaxPrice = totalTaxPrice ? totalTaxPrice : 0;
        priceDetails.netAmount = priceDetails.totalSellingPrice + priceDetails.totalTaxPrice - priceDetails.totalDiscountValue;
        priceDetails.totalQty = totalQty;
        setProduct(product);
        setPriceDetails(priceDetails);
      } else {
      }
    }
    setLoading(false);
  }

  async function memberCartItems() {
    let productMemeber: IProductMember = {};
    productMemeber.memberId = Number(memberId);
    productMemeber.productId = 0;
    await cartsDetailsStore.getCartDetails(productMemeber);
  }

  function updateArray() {
    let updatedArray: any[] = [];
    if (memberId !== undefined) {
      memberCartItems()
      cartsDetailsStore?.cartDetails?.forEach((cartItem) => {
        let discountValue: number = 0;
        let totalPrice: number = 0;
        let taxValue: number = 0;
        let sellingRate: number = Number(cartItem?.sellingPrice) ?? 0;
        discountValue = (Number(cartItem?.mrp!) - sellingRate);
        totalPrice = (sellingRate * Number(cartItem?.productQty!))?.toFixed(2) as any;
        taxValue = ((Number(sellingRate) - Number(sellingRate!)) / (1 + cartItem?.taxPercentage! / 100))?.toFixed(2) as any;
        let updatedObj = {
          posItemId: cartItem?.productId,
          posItemSellingRate: cartItem?.sellingPrice,
          posItemMRP: cartItem?.mrp,
          // posbatchqty: cartItem?.stock,
          posItemName: cartItem?.itemname,
          // posSubCategoryId: cartItem?.subcategory,
          // availableQty: cartItem?.stock,
          // posComments: cartItem?.comments,
          // posUnitOdMeasure: cartItem?.unitOfMeasure,
          posItemQty: cartItem?.productQty,
          posTaxPercentage: cartItem?.taxPercentage,
          posTaxValue: Number(taxValue),
          posDiscountPercentage: cartItem?.discountPercentageorValue,
          posDiscountValue: cartItem?.discountPercentageorValue ?? discountValue,
          // posDiscountMode: cartItem?.Dis_Mode,
          posTotalPrice: Number(totalPrice ?? 0)?.toFixed(2),
          posNetAmount: (Number(totalPrice ?? 0))?.toFixed(2),
          // posbatchNo: cartItem?.batchno,
          // posCategory: cartItem?.category,
          // posBarcode: cartItem?.Barcode,
        }
        updatedArray?.push(updatedObj)
      })
    } else {
      if (Cookies.get('ProductCarts') !== undefined) {
        let cartItems: any[] = JSON.parse(Cookies.get('ProductCarts'))
        let cartItemsList: any[] = Object.assign([], cartItems);
        posProductList?.forEach((product) => {
          const { itemid,   } = product;
          cartItemsList?.forEach((cartItems) => {
            const { productId, bacthNo } = cartItems;
            if (productId === itemid) {
              let discountValue: number = 0;
              let totalPrice: number = 0;
              let taxValue: number = 0;

              let sellingRate: number = Number(product?.BTCSellingRate! ?? product?.BTBSellingRate!) ?? 0;
              discountValue = (Number(product?.Mrprate!) - sellingRate);

              totalPrice = (sellingRate * Number(cartItems?.productQty!))?.toFixed(2) as any;
              taxValue = ((Number(sellingRate) - Number(sellingRate!)) / (1 + product?.taxper! / 100))?.toFixed(2) as any;

              let updateObj: any = {
                posItemId: product?.itemid,
                posItemSellingRate: product?.BTCSellingRate ?? product?.BTBSellingRate,
                posbatchNo: product?.batchno,
                posCategory: product?.category,
                posItemMRP: product?.Mrprate,
                posBarcode: product?.Barcode,
                posbatchqty: product?.stock,
                posItemName: product?.itemname,
                posSubCategoryId: product?.subcategory,
                availableQty: product?.stock,
                posComments: product?.comments,
                posUnitOdMeasure: product?.unitOfMeasure,
                posItemQty: cartItems?.productQty,
                posTaxPercentage: product?.taxper,
                posTaxValue: Number(taxValue),
                posDiscountPercentage: product?.Discount_Pv,
                posDiscountValue: product?.Discount_Pv ?? discountValue,
                posDiscountMode: product?.Dis_Mode,
                posTotalPrice: Number(totalPrice ?? 0)?.toFixed(2),
                posNetAmount: (Number(totalPrice ?? 0))?.toFixed(2),
              }
              updatedArray?.push(updateObj)
            }
          })
        })

        itemMasterStore?.itemMasterList?.forEach((item) => {
          const { itemId,   } = item;
          if (updatedArray?.length) {
            updatedArray?.forEach((cartItems) => {
              const { posItemId } = cartItems;
              if (posItemId === itemId) {
                cartItems.imageData = item?.imageData
              }
              return cartItems
            })
          } else if (cartItemsList?.length) {
            cartItemsList?.forEach((cartItems) => {
              const { productId } = cartItems;
              if (productId === itemId) {
                let updateObj: any = {
                  posItemId: item?.itemId,
                  posItemSellingRate: item?.purchasePrice ?? 0,
                  posbatchNo: item?.batchno ?? '',
                  posCategory: item?.category ?? '',
                  posItemMRP: item?.mrp ?? 0,
                  posBarcode: item?.Barcode ?? '',
                  posbatchqty: item?.stock ?? 0,
                  posItemName: item?.itemName ?? '',
                  posSubCategoryId: item?.subcategory ?? '',
                  availableQty: item?.stock ?? 0,
                  posComments: item?.comments ?? '',
                  posUnitOdMeasure: item?.unitName ?? '',
                  posItemQty: cartItems?.productQty,
                  posTaxPercentage: item?.taxper ?? 0,
                  posTaxValue: 0,
                  posDiscountPercentage: item?.Discount_Pv ?? 0,
                  posDiscountValue: item?.Discount_Pv ?? 0,
                  posDiscountMode: item?.Dis_Mode ?? '',
                  posTotalPrice: 0,
                  posNetAmount: 0,
                  imageData: item?.imageData,
                }
                updatedArray?.push(updateObj)
              }
            })
          }
        })
      }
    }
    return updatedArray;
  }

  // useEffect(() => {
  //   if (itemMasterStore?.itemMasterList?.length) {
  //     let productLists: any = updateArray()
  //     if (!productLists?.length) {
  //       productLists = [];
  //     }
  //     setPOStItemDetails([...productLists])
  //   }
  // }, [itemMasterStore?.itemMasterList, posProductList])

  function useCartQuantityUpdater(type: string, obj: IPOSProductDetails, qty?: number) {
    let existsItem = posItemDetils?.find((items) => isEqual(items, obj));
    if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
      if (type == 'add') {
        existsItem!.posItemQty! += qty!;
      } else {
        existsItem!.posItemQty! -= qty!;
      }
    } else if (type == 'add') {
      obj.posItemQty = 0;
      obj.posItemQty! += qty!;
    } else {
      obj.posItemQty = 0
      obj.posItemQty! -= qty!;
    }
    addToCart(obj);
  }

  function addToCart(itemBatch: IPOSProductDetails) {
    let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemBatch))
    let taxValue: number = 0;
    let discountValue: number = 0;
    let offerDiscountValue: number = 0;
    let totalPrice: number = 0;

    if (itemBatch?.posTaxPercentage) {
      taxValue = ((Number(itemBatch?.posItemSellingRate) - Number(itemBatch?.posItemSellingRate!)) / (1 + itemBatch?.posTaxPercentage! / 100))?.toFixed(2) as any;
    }

    let { assignOffer, offerDiscoung } = checkForItemOffer(itemBatch, assignedOffers, posItemOffers)
    setAssignedOff([...assignOffer])

    offerDiscountValue = offerDiscoung;

    discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
    itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

    totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

    const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

    itemBatch.posItemQty = itemBatch?.posItemQty;
    itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
    itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
    itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
    itemBatch.posTaxValue = Number(taxValue);
    itemBatch.posDiscountValue = discountValue + offerDiscountValue;
    itemBatch.posTotalPrice = Number(totalPrice);
    itemBatch.posNetAmount = Number(netAmount);

    const freeItems: any[] = checkForFreeItem(itemBatch, posFreeItems)

    if (!isexistsItem) {
      posItemDetils?.push(itemBatch);
      if (freeItems?.length) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      }
      setPOStItemDetails([...posItemDetils, ...freeItems])
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode?.toString(),
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      const batchDetails: any = updateBatchList(batchObj, isexistsItem, posBatchDetails, posItemDetils,)
      setPOSBatchDetails([...batchDetails])
    } else {
      setPOStItemDetails(posItemDetils.map((item, i) => {
        if (item.posItemId === itemBatch.posItemId && itemBatch?.posbatchNo === item?.posbatchNo) {
          return item = itemBatch;
        }
        return item;
      }));
      const updatedArray = posItemDetils?.map((each, ind) => {
        const matchingItem = freeItems?.find((item, ind) =>
          !item?.posItemId &&
          item?.posFreeItemId === each?.posFreeItemId &&
          item?.posbatchNo === each?.posbatchNo
        )
        return matchingItem || each
      })
      setPOStItemDetails([...updatedArray]);
      let batchObj: IPosBatchDetails = {
        itemId: itemBatch?.posItemId,
        barcode: itemBatch?.posBarcode,
        batchNo: itemBatch?.posbatchNo,
        batchQty: itemBatch?.posItemQty
      }
      if (freeItems?.length) {
        freeItems?.forEach((each) => {
          let batchObj: IPosBatchDetails = {
            itemId: each?.posFreeItemId,
            barcode: each?.posBarcode?.toString(),
            batchNo: each?.posbatchNo,
            batchQty: each?.posItemQty
          }
          if (batchObj?.itemId) {
            const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils, each)
            setPOSBatchDetails([...batchDetails])
          }
        })
      } else if (batchObj?.itemId) {
        const batchDetails: any = updateBatchList(batchObj, true, posBatchDetails, posItemDetils,)
        setPOSBatchDetails([...batchDetails])
      }
    }
  }

  const billDisounct: number = useMemo(() => {
    let offerDiscount: number = 0;
    if (posItemOffers?.length > 0 && posItemDetils?.length > 0) {
      let billlValue = posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0)
      posItemOffers?.map((offers) => {
        if (new Date() < new Date(offers?.effectivetoDate)) {
          if (offers?.AssinOfferType === "billValue") {
            if (billlValue >= offers?.From_QV && billlValue <= offers?.To_QV) {
              offerDiscount = offers?.Discount_PV
              if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
                setAssignedOff([...assignedOffers, offers])
              }
            } else {
              offerDiscount = 0;
              let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
              if (index >= 0) {
                assignedOffers?.splice(index, 1)
                setAssignedOff([...assignedOffers])
              }
            }
          }
        }
      })
    }
    return offerDiscount;
  }, [posItemDetils, posItemOffers])

  const paymentRoundOff: number = useMemo(() => {
    let total: number = 0;
    let decimal = 0;
    let roundOff: number = 0;
    total = posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail?.deliveryCharges! ?? 0) - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0))
    decimal = Math.round(total);
    roundOff = total - decimal;
    let plusMinus = - roundOff;
    return plusMinus;
  }, [posItemDetils, posItemOffers, posDetail?.isReedem])

  function moveToCart() {
    navigate('/cartsview');
  }

  const inputStyle: React.CSSProperties = {
    border: '1px solid #c2c2c2',
    outline: 'none',
    fontSize: '20px',
    textAlign: 'center',
    width: '100%', // Add focus style conditionally
  };

  async function fetchPosMasterApis() {
    let outletId = Number(Cookies.get('outletId'));
    if (outletId) {
      const posProductList = await posMasterStore.loadPOSProductList(outletId);
      setPosProductList([])
      setPosProductList([...posProductList])
    }
    const posItemOffer = await posMasterStore.getPosItemOffers()
    const posFreeItems = await posMasterStore.getPosFreeItems();
    setPosFreeItems([...posFreeItems])
    setPosItemOffers([...posItemOffer])
    setLoading(false);
  }

  // cart function 

  const proceedtoOrder = () => {
    let productCart: any = {};
    let productCarts: any[] = [];
    productCarts = posItemDetils?.map((each, index) => {
      productCart = {
        cartId: 0,
        productId: each.posItemId,
        productQty: each.posItemQty ?? 1,
        webName: "",
        barcode: each.posBarcode,
        batchNo: each.posbatchNo,
        memberId: Number(memberId ?? 0),
      }

      return productCart;
    })
    // productCart = {
    //   itemName: each?.posItemName,
    //   productId: each?.posItemId,
    //   mrpPrice: Number(each?.posItemMRP),
    //   totalPrice: each?.posTotalPrice,
    //   productQty: each?.posItemQty,
    //   billValue: each?.posNetAmount,
    //   taxValue: each?.posTaxValue,
    //   discountValue: each?.posDiscountValue,
    //   sellingRate: Number(each?.posItemSellingRate),
    //   webName: "",
    //   taxPercentage: each?.posTaxPercentage,
    //   discountPercentage: each?.posDiscountPercentage,
    //   comments: each?.posComments,
    //   itemImage: each?.imageData,
    //   stock: each?.availableQty,
    //   batchNo: each?.posbatchNo,
    //   batchQty: each?.posbatchqty,
    //   uom: each?.posUnitOdMeasure,
    //   barcode: each?.posBarcode,
    //   category: each?.posCategory,
    //   subId: each?.posSubCategoryId
    // }
    Cookies.set('ProductCarts', JSON.stringify(productCarts))
    Cookies.set('Products', JSON.stringify(productCarts));
    if (memberId !== undefined) {
      navigate('/checkoutorder');
    } else {
      Cookies.set('cartLogin', 'cartafterLogin')
      navigate('/Login');
    }
  }

  function updateProductDetails(ecomItemDetails, imageList) {
    if (ecomItemDetails?.length) {
      let products: any[] = ecomItemDetails?.map((cartItem) => {
        let filterItem = ecomItemDetails?.filter((item) => item?.itemid === cartItem?.itemid)
        let discountValue: number = 0;
        let totalPrice: number = 0;
        let taxValue: number = 0;
        let sellingRate: number = Number(cartItem?.SellingRate) ?? 0;
        discountValue = (Number(cartItem?.Mrprate!) - sellingRate);
        totalPrice = (sellingRate * Number(cartItem?.productQty!))?.toFixed(2) as any;
        taxValue = ((Number(cartItem?.Mrprate) - Number(sellingRate!)) / (1 + cartItem?.taxper! / 100))?.toFixed(2) as any;
        let updateObj: any = {
          posItemId: cartItem?.itemid,
          posItemName: cartItem?.itemname,
          posBarcode: cartItem?.Barcode,
          posbatchNo: cartItem?.batchno,
          posbatchqty: cartItem?.stock,
          availableQty: filterItem?.length ? filterItem?.reduce((a, v) => a = a + v?.stock!, 0) : 0,
          posItemSellingRate: cartItem?.SellingRate,
          posCategory: cartItem?.category,
          posItemMRP: cartItem?.Mrprate,
          posSubCategoryId: cartItem?.subcategory,
          posSubCateogry: cartItem?.subcategoryname,
          posComments: cartItem?.Comments,
          posUnitOdMeasure: cartItem?.unitOfMeasure,
          posItemQty: cartItem?.productQty,
          posTaxPercentage: cartItem?.taxper,
          posDiscountPercentage: cartItem?.Discount_PV,
          posDiscountValue: cartItem?.Discount_PV + discountValue,
          posDiscountMode: cartItem?.Dis_Mode,
          posTaxValue: Number(taxValue),
          posTotalPrice: Number(totalPrice ?? 0)?.toFixed(2),
          posNetAmount: (Number(totalPrice ?? 0))?.toFixed(2),
        }
        return updateObj;
      })
      if (products?.length) {
        imageList?.forEach((item) => {
          const { itemId,   } = item;
          products?.forEach((product) => {
            const { posItemId } = product;
            if (posItemId === itemId) {
              product.imageData = item?.itemImage
            }
            return product
          })
        })
      }
      setPOStItemDetails([...products])
    }
  }

  async function getCartProds() {
    const cartItems = await getCartDetails(memberId)
    if (cartItems?.length) {
      Cookies.set('cartCount', cartItems?.length);
      const imageList = await getLoadItemImages();
      updateProductDetails(cartItems, imageList)
    }
    setLoading(false);
  }

  const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage.current) {
  //     fetchPosMasterApis();
  //     fetchPOSProducts();
  //     // fetchPreLoadingData();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, [])

  useEffect(() => {
    if (isCurrentPage.current) {
      getCartProds()
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])

  return (
    <>
      <div className="App" >
        <div className="" style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1000 }}>
          <HeaderComp moveToCart={moveToCart} />
        </div>
        {isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <Container fluid className='Pos full-height' style={{ marginTop: '169px' }}>
              {posItemDetils?.length! > 0 ?
                <div className='row full-height'>
                  <div className='col-sm-8'>
                    <div className='ProductDetailBox1'>
                      <div className='row'>
                        <div className='hrBox'>
                          <h4>Shopping Cart</h4>
                        </div>
                        <hr></hr>
                      </div>
                      <div className='cart-view'>
                        {posItemDetils?.map((val, key) => {
                          return (
                            <div className='cart-item '>
                              <div className='image-view'>
                                <div className='img-qty'>
                                  <div className='img-box'>
                                    <Image width={90} height={90} src={`data:image/jpeg;base64,${val?.imageData}`} />
                                  </div>
                                  <div className="qty-update">
                                    <div className='minus'
                                      onClick={() => { val?.posItemQty! > 1 && useCartQuantityUpdater('minus', val, 1) }}
                                    >
                                      -
                                    </div>
                                    <input value={val?.posItemQty} type='text'
                                      disabled style={inputStyle}
                                      placeholder="Enter text"
                                    />
                                    <div className='plus'
                                      onClick={() => { val?.posItemQty! < val?.availableQty! && useCartQuantityUpdater('add', val, 1) }}
                                    >
                                      +
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className='item-detail'>
                                <div>
                                  <h5 className='itemName'>{val?.posItemName}</h5>
                                </div>
                                <div className='item-qty' style={{ color: `${val?.availableQty ? '#007600' : 'maroon'}` }}>
                                  {val?.availableQty ? 'In Stock' : 'Out of Stock'}
                                </div>
                                {val?.comments && <h6>{val?.comments}</h6>}
                                <div className='comments'>
                                  Size: Free Size
                                </div>
                                <div className='price-list' >
                                  <div className='flex-center' style={{ justifyContent: 'start' }}>
                                    <div className='sell-price'><span>&#8377;</span>{val?.posItemSellingRate}</div>
                                    <div className='MRP'><span>&#8377;</span>{val?.posItemMRP}</div>
                                    <div className='avail-offer'> 20% Off</div>
                                    <div className='avail-offer'>2 offers applied <InfoIcon sx={{ color: '#388e3c', }} /> </div>
                                  </div>
                                  <div className='remove-bar' onClick={() => {
                                    setRemoveProductId(key);
                                    setRemoveProduct(true);
                                  }}>
                                    <CloseRoundedIcon /> <span style={{ padding: '1px' }}>Remove</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        )}
                        <div className='cartBottom'>
                          <div className='subtotalLayout' onClick={() => navigate('/')}>
                            <div className='subtotal'>
                              <span>Continue Shopping</span>
                            </div>
                          </div>
                          <div className='subtotalLayout'>
                            <div className='subtotal' style={{ background: '#dc3545' }} onClick={proceedtoOrder}>
                              <span>Place to order</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='ProductDetailBox1'>
                      <div className='row'>
                        <div className='col-sm-12'>
                          <div style={{ marginTop: '36px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 3px 2px', borderRadius: '2px', minHeight: '200px', background: 'white', color: 'black' }}>
                            <div style={{ borderBottom: '1px solid #f0f0f0', fontSize: '26px', padding: '14px 20px', color: '#878787', fontWeight: '700', fontFamily: 'sans-serif' }}>
                              PRICE DETAILS
                            </div>
                            <div style={{ width: '100%', padding: '0 24px' }}>
                              <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Price :</div>
                                <div>
                                  <span>
                                    <span>&#8377;</span>
                                    {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posNetAmount! ?? 0)), 0)?.toFixed(2) : 0}
                                  </span>
                                </div>
                              </div>
                              <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Discount <span style={{ fontSize: '12px' }}>(Inc)</span> :</div>
                                <div>
                                  <span>
                                    <span>&#8377;</span>
                                    {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posItemQty!) * Number(v?.posDiscountValue! ?? 0)), 0 + billDisounct)?.toFixed(2) : 0}
                                  </span>
                                </div>
                              </div>
                              <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Total Tax <span style={{ fontSize: '12px' }}>(Inc)</span> :</div>
                                <div>
                                  <span>
                                    <span>&#8377;</span>
                                    {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (v?.posItemQty! * Number(v?.posTaxValue! ?? 0)), 0)?.toFixed(2) : 0}
                                  </span>
                                </div>
                              </div>
                              <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Delivery Charges :</div>
                                <div><span>{posDetail?.deliveryCharges! > 0 ? posDetail?.deliveryCharges! : 'Free'}</span></div>
                              </div>
                              <div style={{ borderTop: '1px dashed #e0e0e0', padding: '16px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Bill Amount :</div>
                                <div>
                                  <span>
                                    <span>&#8377;</span>
                                    {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail?.deliveryCharges! ?? 0))?.toFixed(2) : 0}
                                  </span>
                                </div>
                              </div>
                              <div style={{ borderTop: '1px dashed #e0e0e0', padding: '16px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Round Off :</div>
                                <div>
                                  <span><span>&#8377;</span>
                                    {paymentRoundOff?.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                              <div style={{ margin: '12px 0px', display: 'flex', justifyContent: 'space-between' }}>
                                <div>Payable Amount :</div>
                                <div>
                                  <span>
                                    <span>&#8377;</span>
                                    {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + Number(v?.posNetAmount! ?? 0), 0 + Number(posDetail.deliveryCharges! ?? 0) - billDisounct - (posDetail?.isReedem! ? posDetail?.redeemPointsamt! : 0) + paymentRoundOff)?.toFixed(2) : 0}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div style={{ background: 'rgb(211, 244, 234)', padding: '12px 16px', marginBottom: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
                              <Image src='https://images.meesho.com/images/marketing/1657272960496.png' width={20} height={20} />
                              <div style={{ fontSize: '17px', fontWeight: '700', color: 'rgb(3, 141, 99)' }}>Yay! Your total discount is ₹
                                <span>
                                  {posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + (Number(v?.posItemQty!) * Number(v?.posDiscountValue! ?? 0)), 0 + billDisounct)?.toFixed(2) : 0}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <div>
                              <div style={{ fontSize: 'large', fontWeight: 900, color: 'cadetblue' }}>Your Safety, Our Priority</div>
                              <div style={{ fontSize: '14px', color: 'black' }}>Safe and Secure Payments.</div>
                            </div>
                            <Image src={require('../../../image/deliveryimage.webp')} height={130} width={130} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> :
                <div className="flex-center full-height">
                  <div className='noitemcart'>
                    <Image src={require("../../../image/emptyCart.png")} />
                    <div className='col-sm-6 noitemcart'>
                      <button className='orderbtn btn-warning full-width' type='submit' onClick={() => navigate('/')}>Go To Home </button>
                    </div>
                  </div>
                </div>
              }
            </Container>
          </>
        }
        <Modal show={isRemoveProduct} onHide={handleCancelModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../gsmecom/images/checked.png')} />
              </div>
              <h4>Are you sure want to remove this product from cart</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleRemoveModalClose}>
              Yes
            </Button>
            <Button variant="secondary" onClick={handleCancelModalClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFailureModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    </>
  );

});

export default Carts;
