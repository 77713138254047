import { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { calculateOTHours, calculateTimeDiff } from "../../common/shared/utils";
import { IMDApprovalEntrySave } from "./model";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";

const MDApprovalEntry = () => {

    const { payrollStore, employeeStore, departmentStore } = useStores()

    const navigate = useNavigate()

    const [employeeList, setEmployeeList] = useState<any[]>([])
    const [departmentList, setDeaprtmentList] = useState<any[]>([])

    const [loadAttendance, setLoadAttendance] = useState<IMDApprovalEntrySave[]>([])
    const [temploadAttendance, settempLoadAttendance] = useState<IMDApprovalEntrySave[]>([])

    const [mdApprovalEntryList, setMdApprovalEntryList] = useState<IMDApprovalEntrySave[]>([]);

    const [searchFields, setSearchFields] = useState<any>({ month: '', empid: '', department: '' })

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [errors, setErrors] = useState<any>({});
    const [isErrorModal, setIsErrorModal] = useState({ bool: false, Message: '' });

    const handleFailureModalClose = () => setFailureModal(false);
    const handleErrorModalClose = () => setIsErrorModal({ bool: false, Message: '' })
    const handleSuccessModalClose = () => {
        // setLoading(true)
        setSuccessModal(false)
        // initialApiCall()
        handleList()
    };

    async function handleSaveManualAttendance() {
        if (mdApprovalEntryList?.length) {

            let listOfEmployee = mdApprovalEntryList?.map((each) => {
                return !checkEmployeeCl(each?.empid!, false) ? each.employeename : undefined;
            }).filter(Boolean);

            if (Array.from(new Set(listOfEmployee))?.length) {
                setIsErrorModal({ bool: true, Message: `${Array.from(new Set(listOfEmployee))?.map(each => each)}, CL Limit Exceeded` })
            } else {
                setLoading(true)
                const status = await payrollStore.saveMDApprovalEntrySave(mdApprovalEntryList)
                if (status === "Success") {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            }
        } else {
            let error: any = {}
            if (!mdApprovalEntryList?.length) {
                error.list = 'Check Entry'
            }
            setErrors({ ...error })
        }
    }

    function handleList() {
        navigate('/Admin/MDApprovalEntry/list')
    }

    function checkEmployeeCl(empid: number, isAdd) {
        debugger
        let findEmployee = mdApprovalEntryList.find(each => each?.empid === empid);
        let employeeCl = mdApprovalEntryList.filter(each => each?.levtype === 'cl' && each?.empid === empid).length;
        const isClExists = mdApprovalEntryList!.every(obj => obj.levtype === 'cl');
        if (findEmployee) {
            if (findEmployee?.BalanceCL && (isAdd ? employeeCl < findEmployee?.BalanceCL : employeeCl <= findEmployee?.BalanceCL)) {
                return true
            } else if (!isClExists && !isAdd) {
                return true
            } else {
                return false
            }
        }
    }

    function handleChangeInput(event, Attnid?: any) {
        const { name, value } = event.target;
        let findObj = temploadAttendance?.find((each) => each?.attnid === Attnid)
        loadAttendance?.forEach((obj) => {
            if (obj?.attnid == Attnid) {
                if (name === 'attntype') {
                    obj[name] = value;
                    obj['levtype'] = ((value == 'present' || value == "permission" || value == "lateEntry") ? 'PR' : '');
                    obj['inTime'] = ((value == 'present' || value == "permission" || value == "lateEntry") ? findObj?.inTime : '');
                    obj['outTime'] = ((value == 'present' || value == "permission" || value == "lateEntry") ? findObj?.outTime : '');
                    obj['workingtime'] = (value == 'present' || value == "permission" || value == "lateEntry") ? calculateTimeDiff(obj['inTime']!, obj['outTime']!) : '';
                } else if (name === 'levtype') {
                    if (value == 'cl') {
                        if (checkEmployeeCl(obj?.empid!, true)) {
                            obj[name] = value;
                        } else {
                            setIsErrorModal({ bool: true, Message: 'This Employee CL Limit Exceeded' })
                        }
                    } else {
                        obj[name] = value;
                    }
                } else if (value) {
                    obj[name] = value;
                }
                return obj;
            }
            return obj;
        })
        setLoadAttendance([...loadAttendance])
    }

    // Create an array of the next five years with the specified model
    const employee = [{ key: 'name', value: 'name' }, { key: 'name', value: 'name' }, { key: 'name', value: 'name' }]
    const status = [{ key: 'Present', value: 'Present' }, { key: 'Absent', value: 'Absent' }, { key: 'wo', value: 'WO' }, { key: '1/a', value: '1/A' }, { key: 'permission', value: 'Permission' }, { key: 'onDuty', value: 'On Duty' }, { key: 'lateEntry', value: 'Late Entry' }]
    const leaveType = [{ key: 'wo', value: 'WO' }, { key: 'cl', value: 'CL' }, { key: 'pl', value: 'PL' }, { key: 'lop', value: 'LOP' }, { key: 'sl', value: 'SL' }]

    const months = [
        { key: "january", value: "January", month: 1 },
        { key: "february", value: "February", month: 2 },
        { key: "march", value: "March", month: 3 },
        { key: "april", value: "April", month: 4 },
        { key: "may", value: "May", month: 5 },
        { key: "june", value: "June", month: 6 },
        { key: "july", value: "July", month: 7 },
        { key: "august", value: "August", month: 8 },
        { key: "september", value: "September", month: 9 },
        { key: "october", value: "October", month: 10 },
        { key: "november", value: "November", month: 11 },
        { key: "december", value: "December", month: 12 }
    ];

    function handleSelectAllCheckBox(event) {
        const { checked } = event.target
        if (event.target) {
            debugger
            loadAttendance?.map((obj) => {
                if (obj?.filter && obj?.filter == 'filtered') {
                    obj['check'] = checked;
                }
                return obj
            })
            setLoadAttendance([...loadAttendance])
            if (checked) {
                setMdApprovalEntryList([...loadAttendance?.filter((each) => each?.check === true)]);
            } else {
                setMdApprovalEntryList([])
            }
        }
    }

    function handleAddToGrid(attnid) {
        if (mdApprovalEntryList?.some((obj) => obj?.attnid == attnid)) {
            loadAttendance?.map((obj) => {
                if (obj?.attnid == attnid) {
                    obj['check'] = false;
                    return obj;
                }
                return obj
            })
            setLoadAttendance([...loadAttendance])
            let index = mdApprovalEntryList?.findIndex((obj) => obj?.attnid == attnid)
            mdApprovalEntryList?.splice(index, 1)
            setMdApprovalEntryList([...mdApprovalEntryList])
        } else {
            let findObj = loadAttendance?.find((obj) => obj?.attnid == attnid)
            loadAttendance?.map((obj) => {
                if (obj?.attnid == attnid) {
                    obj['check'] = true;
                    return obj;
                }
                return obj
            })
            mdApprovalEntryList?.push(findObj!);
            setMdApprovalEntryList([...mdApprovalEntryList]);
            setLoadAttendance([...loadAttendance])
        }
    }

    function handleInputChange(event) {
        setSearchFields({ ...searchFields, month: event.target.value })
        setLoadAttendance([]);
        setMdApprovalEntryList([])
        settempLoadAttendance([])
    }

    function mapData(tempArr: any[], elClList: any[]) {
        console.log(tempArr, elClList, 'Mappeddata')
        let mappedArray: IMDApprovalEntrySave[] = [];
        mappedArray = tempArr?.map((each) => {
            let tempObj: IMDApprovalEntrySave = {
                attnid: each?.Attnid,
                attnDate: each?.AttnDate,
                empid: each?.Empid,
                employeename: each?.Employee_Name,
                attntype: (each?.Attntype == 'present' || each?.Attntype == 'Present') ? 'Present' : (each?.Attntype == 'absent' || each?.Attntype == 'Absent') ? 'Absent' : '',
                levtype: each?.levtype,
                inTime: moment(each?.InTime).format('HH:MM'),
                outTime: moment(each?.OutTme).format('HH:MM'),
                workingtime: moment(each?.workingtime).format('HH:MM'),
                department: each?.department,
                BalanceCL: elClList?.length ? elClList?.find(obj => obj?.empid === each?.Empid)?.BalanceCL : 0,
                BalanceEL: elClList?.length ? elClList?.find(obj => obj?.empid === each?.Empid)?.BalanceEL : 0,
                appstatus: each?.Appstatus,
            }
            return tempObj;
        })
        return mappedArray;
    }

    function handleFilterFunction() {
        const filteredArray = loadAttendance.filter((each) => {
            return (!searchFields?.month || moment(each?.attnDate)?.format('MMMM')?.toLowerCase() === searchFields?.month) &&
                (!searchFields?.empid || each?.empid === searchFields?.empid) &&
                (!searchFields?.department || each?.department === searchFields?.department);
        });
        let filterArr = filteredArray?.map((each) => {
            let isExists = loadAttendance?.some((obj) => obj?.attnid === each?.attnid);
            if (isExists) {
                each['filter'] = 'filtered';
            } else {
                each['filter'] = '';
            }
            return each
        })
        return filterArr;
    }

    function handleClear() {
        setLoadAttendance([]);
        setSearchFields({ month: '', empid: '', department: '' })
    }

    async function handleSearchFunc() {
        let error: any = {}
        if (!searchFields?.month) {
            error.month = 'Select month'
        }
        setErrors(error)
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            let body = {
                empid: 0,
                month: months?.find((each) => each?.key == searchFields?.month)?.month,
            }
            const loadAttendanceJSON = await payrollStore.loadManualAttendanceJSON()
            const loadElClJSON = await payrollStore.getElClBalance(body)
            setLoadAttendance([...mapData(loadAttendanceJSON, loadElClJSON)])
            settempLoadAttendance([...mapData(loadAttendanceJSON, loadElClJSON)])
            setLoading(false)
            handleFilterFunction()
        } else {
            handleFilterFunction()
        }
    }

    async function initialApiCall() {
        const employeeList = await employeeStore.loadEmployeeMaster();
        const departmentList = await departmentStore.getDepartment();
        setEmployeeList([...employeeList])
        setDeaprtmentList([...departmentList])
        setLoading(false)
    }

    const isCurrenPage = useRef(true)

    useEffect(() => {
        if (isCurrenPage.current) {
            initialApiCall()
            isCurrenPage.current = false;
        }
        return () => { }
    }, [])

    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <div className="container">
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox LgInputField">
                        <div className="hrBox">
                            <h3>MD Approval Entry</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Month<span>*</span></label>
                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                            name="month" value={searchFields?.month} onChange={(event) => handleInputChange(event)}
                                        >
                                            <option value="" selected> - - - Select - - -</option>
                                            {months?.map((obj) => {
                                                return obj?.month < Number(moment()?.format('MM')) && (
                                                    <option value={`${obj?.key}`}>{obj?.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {errors?.month && <p style={{ color: 'red' }}>{errors?.month}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Employee<span>*</span></label>
                                        <Autocomplete size="small" disablePortal
                                            id="combo-box-demo" options={employeeList}
                                            value={employeeList?.find((each) => each?.EmployeeId === searchFields?.employee)}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, val) => setSearchFields({ ...searchFields, empid: val?.EmployeeId })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info' size="small" type="text"
                                                    placeholder='Select Employee..'
                                                    name='EmployeeId'
                                                />}
                                        />
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Department<span>*</span></label>
                                        <Autocomplete size="small" disablePortal
                                            id="combo-box-demo" options={departmentList}
                                            value={departmentList?.find((each) => each?.department === searchFields?.department)}
                                            getOptionLabel={(option: any) => option.department}
                                            onChange={(event, val) => setSearchFields({ ...searchFields, department: val?.department })}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info' size="small" type="text"
                                                    placeholder='Select Department..'
                                                    name='department'
                                                />}
                                        />
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-1">
                                    <div className="">
                                        <button className="dfBtn" onClick={handleSearchFunc}>
                                            Search
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-1">
                                    <div className="">
                                        <button className="dfBtn" onClick={handleClear}>
                                            Clear
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                {errors?.list && <p style={{ color: 'red' }}>{errors?.list}</p>}
                                <thead>
                                    <tr>
                                        <th scope="col"><input type="checkbox" onClick={handleSelectAllCheckBox} /></th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Employee</th>
                                        <th scope="col">Balance CL</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Leave Type</th>
                                        <th scope="col">In Time</th>
                                        <th scope="col">Out Time</th>
                                        <th scope="col">Worked Time</th>
                                        <th scope="col">Total Time</th>
                                        <th scope="col">OT Hours</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {handleFilterFunction()?.length > 0 &&
                                        (handleFilterFunction())?.map((each, index) => {
                                            let timeDiff = calculateTimeDiff(each?.inTime!, each?.outTime!);
                                            let otTime = calculateOTHours(each?.inTime!, each?.outTime!, '08:00');
                                            return (
                                                <tr key={index}>
                                                    <td><input type="checkbox" checked={each?.check!} onClick={() => handleAddToGrid(each?.attnid)} /></td>
                                                    <td>{moment(each?.attnDate).format('DD-MMM-YYYY')}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{each?.employeename}</td>
                                                    <td>{each?.BalanceCL}</td>
                                                    <td>
                                                        <select className="form-selected full-width" name="attntype" disabled={!each?.check}
                                                            value={each?.attntype} onChange={(event) => handleChangeInput(event, each?.attnid)} style={{ fontSize: "16px" }}
                                                        >
                                                            <option value="" selected>--Select--</option>
                                                            {status?.map((obj) => {
                                                                return (
                                                                    <option value={obj?.key}>{obj?.value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                                            disabled={!each?.check ? true : (each?.attntype == 'present' || each?.attntype == "permission" || each?.attntype == "lateEntry")}
                                                            name="levtype" value={each?.levtype} onChange={(event) => handleChangeInput(event, each?.attnid)}
                                                        >
                                                            <option value="" selected> - -Select- -</option>
                                                            {leaveType?.map((obj) => {
                                                                return (
                                                                    <option value={`${obj?.key}`}>{obj?.value}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </td>
                                                    <td>
                                                        <div className="inputBox">
                                                            <input type="time"
                                                                disabled={!each?.check ? true : (each?.attntype != 'present' && each?.attntype != "permission" && each?.attntype != "lateEntry")}
                                                                style={{ width: "100%" }} className="brandinput" placeholder="Days"
                                                                name="inTime" value={each?.inTime} onChange={(event) => handleChangeInput(event, each?.attnid)}
                                                            ></input>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="inputBox">
                                                            <input type="time"
                                                                disabled={!each?.check ? true : (each?.attntype != 'present' && each?.attntype != "permission" && each?.attntype != "lateEntry")}
                                                                style={{ width: "100%" }} className="brandinput" placeholder="Days"
                                                                name="outTime" value={each?.outTime} onChange={(event) => handleChangeInput(event, each?.attnid)}
                                                            ></input>
                                                        </div>
                                                    </td>
                                                    <td>{each.workingtime = timeDiff?.includes('NaN') ? "" : timeDiff}</td>
                                                    <td>{each.totaltime = timeDiff?.includes('NaN') ? "" : timeDiff}</td>
                                                    <td>{each.oTtime = otTime?.includes('NaN') ? "" : otTime}</td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                            </table>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleList}>
                                List
                            </button>
                            <button className="dfBtn" onClick={handleSaveManualAttendance}>
                                Submit
                            </button>
                        </div>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={isErrorModal?.bool}
                            onHide={handleErrorModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>{isErrorModal?.Message}</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleErrorModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </div>
                </div>
            )}
        </div>
    )
}

export default MDApprovalEntry;