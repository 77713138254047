
function supplierValidation(supplierMasterDetails) {
  let errors: any = {};
  const numeric = /^[0-9\b]+$/
  const zipCode = /[1-9][0-9]{5}$/
  if (!supplierMasterDetails.supplierName) {

    errors.supplierName = "Enter Supplier Name";
  }
  if (!supplierMasterDetails.supplierType) {
    errors.supplierType = "Select Supplier Type";
  }
  if (!supplierMasterDetails.supplierGroup) {
    errors.supplierGroup = "Select Supplier Group";
  }
  // if (!supplierMasterDetails.gstIn) {
  //   errors.gstIn = "Enter GST IN";
  // }
  // if(!supplierMasterDetails.phoneNo){
  //   errors.phoneNo = "Enter Phone No";
  // }
  // else  if(!numeric.test(supplierMasterDetails.phoneNo)){
  //   errors.phoneNo = "Enter Valid Phone No";
  // }
  if (!supplierMasterDetails.mobileNo) {
    errors.mobileNo = "Enter Mobile No";
  }
  else if (!numeric.test(supplierMasterDetails.mobileNo)) {
    errors.mobileNo = "Enter Valid Mobile No";
  }
  if (!supplierMasterDetails.city) {
    errors.city = "Select  City";
  }
  if (!supplierMasterDetails.pinCode) {
    errors.pinCode = "Enter  Pin Code ";
  }
  return errors;
};

export default supplierValidation;