import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import ProgressBar from '../../common/shared/progressbar';
import { Button, Image, Modal } from 'react-bootstrap';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const UserCreationDetail = observer((): JSX.Element => {
  const { userCreationStore } = useStores();

  const [userRights, setRightsObj] = useState<any>({})
  console.log(userRights, 'userRights');

  const { getUserCreation, userCreationList } = userCreationStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  async function fetchUserCreationData() {
    setLoading(false);
    const userRights = await Promise.all([userCreationStore?.getUserRight('User_creation'), getUserCreation()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const editUserCreationDetials = (userId) => {
    navigate('/Admin/usercreation/' + userId);
  }

  const deleteUsercreationDetials = async (userId) => {
    setLoading(true);
    const status = await userCreationStore.deleteUserCreation(userId);
    if (status === 'Success') {
      fetchUserCreationData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchUserCreationData();
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchUserCreationData();
      isCurrentPage.current = false;
    }
    return () => { }
  }, []);

  const handleCreate = () => {
    navigate('/Admin/UserCreation');
  }


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (userCreationList && userCreationList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(userCreationList)
    }
  }, [userCreationList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(userCreationList?.slice().sort((a, b) => b.userId - a.userId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <ProgressBar /> :
          <>
            <div className='container-fluid'>
              <div className='outletInputField inputFormBox LgInputField'>
                <div className='vertical-space-20'></div>
                <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={userCreationList}
                  searchTitles={[{ key: "employeename", value: "Employee Name" }, { key: "user_name", value: "User Name" }]}
                  emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                <div className='inputBoxLists'>
                  <div className='vertical-space-20'></div>
                  <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className='btnBox'>
                      <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{userCreationList?.length} Records</button>
                    </div>
                    <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                      <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                    </Stack>
                  </div>
                </div>
                <div className='vertical-space-20'></div>
                <div className='tableListDetails'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        {/* <th scope='col'>Entry No </th> */}
                        <th scope='col'>Employee Name  </th>
                        <th scope='col'>User Name </th>
                        <th scope='col'>Option </th>
                        <th scope='col'>Create </th>
                        <th scope='col'>Update </th>
                        <th scope='col'>Delete </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                        (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                          return (
                            <tr key={key}>
                              {/* <td>{val.userId}</td> */}
                              <td>{val.employeename}</td>
                              <td>{val.user_name}</td>
                              <td>{val.options}</td>
                              <td><button disabled={userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                              <td><button disabled={userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editUserCreationDetials(val.userId)} /></button></td>
                              <td><button disabled={userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteUsercreationDetials(val.userId)} /></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>

                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>
                      <h4>Succesfully Deleted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>
      }
    </>
  );

});

export default UserCreationDetail;
