import { IIOfferLetter } from "../model";

export function saveValidation(offerLetterFields: IIOfferLetter) {
    let error: any = {}

    if (!offerLetterFields?.offerJobLoc) {
        error.offerJobLoc = 'Enter Job Location'
    }
    if (!offerLetterFields?.offerBaseSal) {
        error.offerBaseSal = 'Enter Basic Salary'
    }
    if (!offerLetterFields?.offerHRA) {
        error.offerHRA = 'Enter HRA'
    }
    if (!offerLetterFields?.offerConveyance) {
        error.offerConveyance = 'Enter Conveyance'
    }
    if (!offerLetterFields?.offerMode) {
        error.offerMode = 'Select Mode'
    }
    if (!offerLetterFields?.offerCatId) {
        error.offerCatId = 'Select Category'
    }
    if (!offerLetterFields?.offerDesignId) {
        error.offerDesignId = 'Select Designation'
    }
    if (!offerLetterFields?.offerEmpId) {
        error.offerEmpId = 'Select Generated By'
    }

    return error;
}