import { observable } from "mobx";
export interface IsupplierMaster {
    supplierId?: number;
    supplierName?: string;
    supplierType?: string;
    supplierGroup?: string;
    gstIn?: string;
    phoneNo?: string;
    mobileNo?: string;
    doorNo?: string;
    area?: string;
    city?: string;
    pinCode?: string;
    country?: string;
    state?: string;
    name?: string;
    fssaino?: string;
    creditdays?: number;
}

export interface ISupplierMasterSearchoptions {
    searchOption?: string;
    searchValue?: string;
}

const supplierMasterDetails = observable<IsupplierMaster>({
    supplierId: 0,
    supplierName: '',
    supplierType: '',
    supplierGroup: '',
    gstIn: '',
    phoneNo: '',
    mobileNo: '',
    doorNo: '',
    area: '',
    city: '',
    state: '',
    pinCode: '',
    country: '',
    name: '',
    fssaino: '',
    creditdays: 0,
});

export default supplierMasterDetails;

export class IExcelSheetData {
    Supplier_Name?: string = "";
    Supplier_Type?: string = "";
    Supplier_Group?: string = "";
    Gst_In?: string = "";
    Phone_No?: string = "";
    Mobile_No?: number = 0;
    Door_No?: string = "";
    Area?: string = "";
    City?: string = "";
    State?: string = "";
    Pincode?: number = 0;
    Country?: string = "";
    Fssai_No?: string = "";
    Credit_days?: number = 0
}

export class IIExceluploadModal {
    impsno?: number = 1
    supplierMaster_import?: IIExcelUpload[] = []
}

export class IIExcelUpload {
    supplierName?: string = "";
    supplierType?: string = "";
    supplierGroup?: string = "";
    gstIn?: string = "";
    phoneNo?: string = "";
    mobileNo?: number = 0;
    doorNo?: string = "";
    area?: string = "";
    city?: string = "";
    state?: string = "";
    pincode?: number = 0;
    country?: string = "";
    fssaiNo?: string = "";
    creditdays?: number = 0
}

export function excelKeyupdate(data: IExcelSheetData[]) {
    let excelArr: IIExcelUpload[] = []
    excelArr = data?.map((each: IExcelSheetData) => {
        let excleObj: IIExcelUpload = {
            area: each.Area?.toString(),
            city: each?.City?.toString(),
            country: each?.Country?.toString(),
            creditdays: !isNaN(Number(each?.Credit_days)) ? Number(each?.Credit_days) : 0,
            doorNo: each?.Door_No?.toString(),
            fssaiNo: each?.Fssai_No?.toString(),
            gstIn: each?.Gst_In?.toString(),
            mobileNo: !isNaN(Number(each?.Mobile_No)) ? Number(each?.Mobile_No) : 0,
            phoneNo: each?.Phone_No?.toString(),
            pincode: !isNaN(Number(each?.Pincode)) ? Number(each?.Pincode) : 0,
            state: each?.State?.toString(),
            supplierGroup: each?.Supplier_Group?.toString(),
            supplierName: each?.Supplier_Name?.toString(),
            supplierType: each?.Supplier_Type?.toString()
        }
        return excleObj
    })
    return excelArr;
}