import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Accordion } from "react-bootstrap";

const ResignationApprovalList = () => {
    const [brandSearchOptions, setBrandSearchOptions] = useState<any>({})
    const { brandStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [onOpenDetail, setOnOpenDetail] = useState(false)
    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleChangeclear = () => {
        setLoading(true);
        fetchPreLoadngData();
    }

    const handleCreate = () => {
        navigate('/Admin/ResignationApproval')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function fetchPreLoadngData() {
        // await brandStore.getBrand();
        // setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={brandStore?.brandList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Request Date</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Date of Joining</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Approved Date</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {list?.map((val, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{moment(val?.date).format('DD-MMM-YYYY')}</td>
                                                <td>{val?.employee}</td>
                                                <td>{val?.status}</td>
                                                <td>{val?.leaveType}</td>
                                                <td>{val?.inTime}</td>
                                                <td>{val?.outTime}</td>
                                                <td>{val?.breakTime}</td>
                                                <td>{val?.workedTime}</td>
                                                <td>{val?.totalHours}</td>
                                                <td>{val?.otHours}</td>
                                                <td>
                                                    <button className="delete">
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            onClick={() => {
                                                                handleDeleteList(key);
                                                            }}
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })} */}

                                        <tr>
                                            <td>Ramu</td>
                                            <td>12-Apr-2024</td>
                                            <td>Accounts</td>
                                            <td>Manager</td>
                                            <td>Staff</td>
                                            <td>12-Apr-2022</td>
                                            <td>John</td>
                                            <td>1-Apr-2022</td>
                                            <td><button className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                            <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <Modal show={onOpenDetail} onHide={() => { setOnOpenDetail(!onOpenDetail) }} className='PriceHistoryModel'>
                                <div style={{ width: '1200px', height: '400px' }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>SELECTION APPROVAL DETAILS</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inputBoxLists accordionLists">
                                            <Accordion> {/* defaultActiveKey="0" */}
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Candidate Details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">DOB  </th>
                                                                        <th scope="col">Gender </th>
                                                                        <th scope="col">Martial Status  </th>
                                                                        <th scope="col">Blood Group </th>
                                                                        <th scope="col">Edu Qualification  </th>
                                                                        <th scope="col">Experience</th>
                                                                        <th scope="col">Current Experience  </th>
                                                                        <th scope="col">Last Month Salary  </th>
                                                                        <th scope="col">Notice Period  </th>
                                                                        <th scope="col">Current City  </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Key Skills</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Key Skills</th>
                                                                        <th scope="col">Total Experience </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {ledgers?.length ? ledgers?.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.ledgerDetlId}</td>
                                                                            <td>{val.ledgerName}</td>
                                                                            <td>{val.ledgerAmount}</td>
                                                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) : null} */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Languages Known</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Language Known</th>
                                                                        <th scope="col">To Speak </th>
                                                                        <th scope="col">To Read </th>
                                                                        <th scope="col">To Write </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {ledgers?.length ? ledgers?.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.ledgerDetlId}</td>
                                                                            <td>{val.ledgerName}</td>
                                                                            <td>{val.ledgerAmount}</td>
                                                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) : null} */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Work Experience</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Company</th>
                                                                        <th scope="col">Job Title </th>
                                                                        <th scope='col'>From </th>
                                                                        <th scope='col'>To </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* <tr>
                                                                        <td>{inwardDetail.discountType}</td>
                                                                        <td>{inwardDetail.discount}</td>
                                                                        <td>{inwardDetail.discountAmount}</td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default ResignationApprovalList;