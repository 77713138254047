import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Spinner from 'react-bootstrap/Spinner';
import useStores from '../../hooks';
import { PartyLedgerBalance, PartyLedgerName, PartyLedgerSearch, PartyLedgerStatement } from './model';
import { Pagination, Stack } from '@mui/material';
import LedgerSearchValidation from './validation';
import moment from 'moment';
import { currentPageDatas } from '../../common/shared/utils';
import AutoComplete from '../../common/shared/autoComplete';

interface ExcelSheetData {
  Date?: string,
  Voucher_No?: string,
  Ledger_AC_Name?: string,
  Debit?: number,
  Credit?: number,
  Voucher_Type?: string,
  Transaction_Type?: string,
  Entry_Date?: string
}

function PartyLedger() {
  const [partyledgerSearch, setPartyLedgerSearch] = useState<PartyLedgerSearch>(new PartyLedgerSearch())
  const [partyLedgerStatement, setpartyLedgerStatement] = useState<PartyLedgerStatement[]>([])
  const [balanceCalculation, setBalanceCalculation] = useState(new PartyLedgerBalance())

  const [ledgerNameArr, setLedgerNameArr] = useState<any[]>([])

  const { partyLedgerStore } = useStores();
  const navigate = useNavigate();

  const [errors, setErrors] = useState<any>({})

  const [isLoading, setIsLoading] = useState(false);

  function handleChangeclear() {
    setPartyLedgerSearch(new PartyLedgerSearch());
    setBalanceCalculation(new PartyLedgerBalance());
    setpartyLedgerStatement(new Array<PartyLedgerStatement>());
  }

  const handleCancel = () => {
    navigate("/Admin/PartyLedger/PartyLedgerDetail")
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setPartyLedgerSearch({ ...partyledgerSearch, [name]: value });
  }

  async function fetchPartyLedgerList() {
    let error: any = {};
    error = LedgerSearchValidation(partyledgerSearch);
    setErrors(error);
    if (Object.keys(error).length === 0) {
      setIsLoading(true);
      const partyLedgerStatement = await partyLedgerStore.loadPartyLedgerList(partyledgerSearch);
      balanceAmtCalculation(partyLedgerStatement);
      setpartyLedgerStatement(partyLedgerStatement);
      setIsLoading(false);
    }
  }

  function balanceAmtCalculation(partyLedgerStatement?: PartyLedgerBalance[]) {
    if (partyLedgerStatement?.length) {

      let balanceAmt = new PartyLedgerBalance();

      balanceAmt.Credit = partyLedgerStatement?.reduce((acc, obj) => acc + (obj.Credit ?? 0), 0)
      balanceAmt.debit = partyLedgerStatement?.reduce((acc, obj) => acc + (obj.debit ?? 0), 0)

      if (balanceAmt.Credit < balanceAmt.debit) {
        balanceAmt.balance = balanceAmt.debit - balanceAmt.Credit;
      } else {
        balanceAmt.balance = balanceAmt.Credit - balanceAmt.debit;
      }

      setBalanceCalculation(balanceAmt);
    }

  }

  function createExportExcelObj(partyLedgerDetails: any[]) {
    let excelArr: ExcelSheetData[] = partyLedgerDetails?.map((partyLedger) => {
      const excelObj: ExcelSheetData = {
        Date: moment(partyLedger?.vchr_date).format("DD-MMM-YYYY"),
        Voucher_No: partyLedger?.vchr_no,
        Ledger_AC_Name: partyLedger?.ledger_accout_name,
        Debit: partyLedger?.debit,
        Credit: partyLedger?.Credit,
        Voucher_Type: partyLedger?.vchr_type,
        Transaction_Type: partyLedger?.transaction_Type,
        Entry_Date: moment(partyLedger?.entrydate).format("DD-MMM-YYYY")
      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Party Ledger", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(',')), ].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Party Ledger ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleLedgerName(name, val) {
    if (val != null) {
      setPartyLedgerSearch({ ...partyledgerSearch, [name]: val.ledgeraccoutname })
    }
  }

  function handlePrint() {
    let printContents = document.getElementById('printablediv')!.innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  async function fetchPartyLedgerName() {
    let ledgerNameSearchOption = {
      searchOption: "LedgerAccountName",
      searchValue: ""
    };
    const ledgerNameArry = await partyLedgerStore.loadPartyLedgername(ledgerNameSearchOption)
    if (ledgerNameArry?.length) {
      setLedgerNameArr([...ledgerNameArry])
    }
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPartyLedgerName();
      isCurrentPage.current = false
    }
    return () => { }
  }, [])

  const sortedData = React.useMemo(() => {
    const listOfData = partyLedgerStatement?.slice()?.sort((a: any, b: any) => {
      const dateA = new Date(a?.entrydate);
      const dateB = new Date(b?.entrydate);

      if (dateA > dateB) return -1;
      if (dateB < dateA) return 1;
      return 0;
    });
    return listOfData?.length ? listOfData : []
  }, [partyLedgerStatement?.length])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (sortedData?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(sortedData)
    }
  }, [sortedData])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(sortedData, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {isLoading ? <div className='SpinnerBox'>
        <Spinner animation="grow" size="sm" />
      </div> : null}
      <div>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
          <div className='hrBox'>
            <h3>Party Ledger</h3>
          </div>

          <div className='inputBoxLists'>
            <div className='ItemInwardInputBox'>

              <div className='row'>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>From Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      onChange={handleInputChange}
                      name="searchDatefrom"
                      value={partyledgerSearch.searchDatefrom}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>To Date <span>*</span></label>
                    <input type="date" style={{ width: "100%" }}
                      value={partyledgerSearch.searchDateto}
                      name="searchDateto"
                      onChange={handleInputChange}
                      placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"></input>
                  </div>
                  <div className='vertical-space-20'></div>
                </div>
                <div className='col-sm-2'>
                  <div className='inputBox'>
                    <label>Party Name <span>*</span></label>
                    <AutoComplete options={ledgerNameArr?.length ? ledgerNameArr : []} placeholder='Party Name...'
                      emitOption={(option) => handleLedgerName('ledgername', option)} getOptionLabel='ledgeraccoutname' clsName='full-width' />
                  </div>
                  {errors?.ledgername && <p style={{ color: 'red' }}>{errors?.ledgername}</p>}
                </div>
                <div className="col-sm-2">
                  <div className="inputBox">
                    <label>Credit</label>
                    <input readOnly value={balanceCalculation?.Credit != undefined ? balanceCalculation?.Credit?.toFixed(2) : 0} type="text" style={{ width: "100%" }} placeholder='0.00' />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="inputBox">
                    <label>Debit</label>
                    <input readOnly value={balanceCalculation?.debit != undefined ? balanceCalculation?.debit?.toFixed(2) : 0} type="text" style={{ width: "100%" }} placeholder='0.00' />
                  </div>
                </div>
                <div className="col-sm-2">
                  <div className="inputBox">
                    <label>Balance</label>
                    <input readOnly value={balanceCalculation?.balance != undefined && balanceCalculation?.balance > 0 ? balanceCalculation?.balance?.toFixed(2) : 0} type="text" style={{ width: "100%" }} placeholder='0.00' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-3'>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
                  </div>
                </div>
                <div className=' col-sm-4' >
                  <Stack spacing={2}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
                <div className="col-sm-5">
                  <div className='btnBox'>
                    <button className='secondaryBtn' onClick={fetchPartyLedgerList}> Search</button>
                    <button className='secondaryBtn' onClick={handlePrint}> Print</button>
                    <button className='secondaryBtn' onClick={() => createExportExcelObj(sortedData)}>Excel</button>
                    <button className="secondaryBtn" onClick={handleChangeclear}> Clear</button>
                    <button className="secondaryBtn" onClick={handleCancel}> Close </button>
                  </div>
                </div>
              </div>
              <div className='vertical-space-20'></div>
              <div style={currentPageData?.length > 0 ? { visibility: 'visible' } : { visibility: "hidden" }}>
                <div id='printablediv' className="tableListDetails">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Voucher No</th>
                        <th scope="col">Ledger AC Name</th>
                        <th scope="col">Debit</th>
                        <th scope="col">Credit</th>
                        <th scope="col">Voucher Type</th>
                        <th scope="col">Transaction Type</th>
                        <th scope="col">Entry Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPageData?.map((statement) => {
                        return (
                          <tr>
                            <td scope='col'>{moment(statement?.vchr_date).format("DD-MMM-YYYY")}</td>
                            <td scope='col'>{statement?.vchr_no}</td>
                            <td scope='col'>{statement?.ledger_accout_name}</td>
                            <td scope='col'>{statement?.debit}</td>
                            <td scope='col'>{statement?.Credit}</td>
                            <td scope='col'>{statement?.vchr_type}</td>
                            <td scope='col'>{statement?.transaction_Type}</td>
                            <td scope='col'>{moment(statement?.entrydate).format("DD-MMM-YYYY")}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className='vertical-space-20'></div>
                  <div className='btnBox' style={{ width: "100%" }}>
                    <div className='inputradio'>
                      <label>Total Credit : <span>{balanceCalculation?.Credit?.toFixed(2)}</span></label><br />
                      <label>Total Debit : <span>{balanceCalculation?.debit?.toFixed(2)}</span></label><br />
                      <label>Closing : <span>{balanceCalculation?.balance != undefined && balanceCalculation?.balance > 0 ? balanceCalculation?.balance?.toFixed(2) : 0}</span></label><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default PartyLedger;