import React, { useEffect, useState, useRef } from "react";
import { Modal, Spinner, Image, Button } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { IAllowancdModalFields, IDeductionModalFields } from "./model";
import { getCurrentMonthFirstDate, toFixedReduce } from "../../common/shared/utils";
import { Autocomplete, TextField } from "@mui/material";

const SalaryGeneration = () => {

    const { departmentStore, employeeStore, payrollStore } = useStores();

    const navigate = useNavigate();

    const currentYear = new Date().getFullYear();

    const yearsArray = Array.from({ length: 10 }, (_, index) => {
        const year = currentYear + index;
        return { key: year.toString(), value: year.toString() };
    });

    const months = [
        { key: "january", value: "January" },
        { key: "february", value: "February" },
        { key: "march", value: "March" },
        { key: "april", value: "April" },
        { key: "may", value: "May" },
        { key: "june", value: "June" },
        { key: "july", value: "July" },
        { key: "august", value: "August" },
        { key: "september", value: "September" },
        { key: "october", value: "October" },
        { key: "november", value: "November" },
        { key: "december", value: "December" }
    ];

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [allowanceModal, setAllowanceModal] = useState<any>({ bool: false, empid: 0 })
    const [deductionModal, setDeductionModal] = useState<any>({ bool: false, empid: 0 })


    const [employeeAttendance, setEmployeeAttendance] = useState<any[]>([])
    const [mdPendingApprovalJSON, setMdPendingApprovalJSON] = useState<any[]>([])

    const [departmentList, setDepartmentList] = useState<any[]>([])
    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [employeeSalaries, setEmployeeSalaries] = useState<any[]>([])
    const [deductionList, setDeductionList] = useState<any[]>([])
    const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])
    const [loanBalanceList, setLoanBalanceList] = useState<any[]>([])


    const [filterFields, setFilterFields] = useState<any>({})
    const [searchFields, setSearchFields] = useState<any>({})

    // console.log(employeeAttendance, 'employeeAttendance>>',mdPendingApprovalJSON)

    let findDaysInMonth = moment(searchFields?.sdate)?.daysInMonth()

    const [allowanceModalFields, setAllowanceModalFields] = useState<IAllowancdModalFields>()
    const [deductionModalFields, setDeductionModalFields] = useState<IDeductionModalFields>()

    async function addAllowaneToSalary() {
        let findEmployeeSalaries = employeeSalaries?.filter((obj) => (obj?.EmployeeId === allowanceModalFields?.Employeeid));
        if (findEmployeeSalaries?.length) {
            const disallowedTypeNotPresent = findEmployeeSalaries!.every(obj => obj.EarnType !== allowanceModalFields?.EarnType);
            if (disallowedTypeNotPresent) {
                if (allowanceModalFields?.additionalAmt) {
                    let salaryObj = {
                        Amount: 0,
                        EarnType: allowanceModalFields?.EarnType,
                        EffectDate: findEmployeeSalaries[0]?.EffectDate,
                        EmployeeId: allowanceModalFields?.Employeeid,
                        GrossSalary: findEmployeeSalaries[0]?.GrossSalary,
                        Paymentmode: findEmployeeSalaries[0]?.Paymentmode,
                        Salarytype: findEmployeeSalaries[0]?.Salarytype,
                        additional: allowanceModalFields?.additional,
                        additionalAmt: allowanceModalFields?.additionalAmt
                    }
                    let body = {
                        "mode": 2,
                        "empid": allowanceModalFields?.Employeeid,
                        "ad_Type": allowanceModalFields?.EarnType,
                        "sdate": searchFields?.enddate,
                        "amount": allowanceModalFields?.additionalAmt
                    }
                    const status = await payrollStore.saveAllowanceDeduction(body, 'Allowance')
                    if (status === 'Success') {
                        employeeSalaries?.push(salaryObj)
                        setEmployeeSalaries([...employeeSalaries])
                        setAllowanceModalFields(new IAllowancdModalFields())
                    }
                }
            } else {
                let error: any = {
                    EarnType: `${allowanceModalFields?.EarnType} already exists`
                }
                setErrors({ ...error })
            }
        }
    }

    function handleRemoveAllowance(index) {
        let findObj = employeeSalaries[index];
        if (findObj?.additional) {
            let filterArr = employeeSalaries?.filter((each, ind) => index != ind)
            setEmployeeSalaries([...filterArr])
        }
    }

    async function addDeductionToSalary() {
        if (deductionList?.length) {
            const disallowedTypeNotPresent = deductionList!.every(obj => obj.Deductiontype !== deductionModalFields?.Deductiontype);
            if (disallowedTypeNotPresent) {
                if (deductionModalFields?.additionalAmt) {
                    let loanObj = {
                        Deductiontype: deductionModalFields?.Deductiontype,
                        Employeeid: deductionModalFields?.Employeeid,
                        additional: deductionModalFields?.additional,
                        additionalAmt: deductionModalFields?.additionalAmt
                    }
                    let body = {
                        "mode": 1,
                        "empid": deductionModalFields?.Employeeid,
                        "ad_Type": deductionModalFields?.Deductiontype,
                        "sdate": searchFields?.enddate,
                        "amount": deductionModalFields?.additionalAmt
                    }
                    const status = await payrollStore.saveAllowanceDeduction(body, 'Deduction')
                    if (status === 'Success') {
                        deductionList?.push(loanObj);
                        setDeductionList([...deductionList])
                        setDeductionModalFields(new IDeductionModalFields())
                    }
                }
            } else {
                let error: any = {
                    Deductiontype: `${deductionModalFields?.Deductiontype} already exists`
                }
                setErrors({ ...error })
            }
        }
    }

    function handleAutoComplete(name, value) {
        setFilterFields({ ...filterFields, [name]: value })
    }

    function handleSearchInputChange(event) {
        const { name, value } = event.target;
        setSearchFields({ ...searchFields, [name]: value })
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
        setSuccessModal(false)
    };

    async function handleSaveCategory(attendanceDet?: any[]) {
        let body = {
            sdate: searchFields?.sdate,
            enddate: searchFields?.enddate
        }
        const loadSalaryGenerationList = await payrollStore.getSalaryGeneration(body)
        const mdPendingApprovalJSON = await payrollStore.loadManualAttendanceJSON()
        if (loadSalaryGenerationList?.length) {
            const updatedAttendanceArray = (attendanceDet?.length! > 0 ? attendanceDet : employeeAttendance)?.map(attendance => {
                const matchingSalaries = loadSalaryGenerationList?.filter(salary => salary.Empid === attendance.empid);
                const isSalaryProvidedForMonth = matchingSalaries?.some(salary => moment(salary?.sdate)?.format('MMMM')?.toUpperCase() === attendance?.salary_month);

                if (isSalaryProvidedForMonth) {
                    return { ...attendance, salaryProvided: true };
                } else {
                    return { ...attendance, salaryProvided: false };
                }
            });
            setEmployeeAttendance([...updatedAttendanceArray!])
        }
        if (mdPendingApprovalJSON?.length) {
            setMdPendingApprovalJSON([...mdPendingApprovalJSON])
        }
        setLoading(false)
    }

    async function handleGenerateSalary(empid) {
        if (searchFields?.sdate && searchFields?.enddate && empid) {
            // if(mdPendingApprovalJSON?.filter)
            setLoading(true)
            let body = {
                sdate: searchFields?.sdate,
                edate: searchFields?.enddate,
                empid: empid,
            }
            const status = await payrollStore.saveSalaryGeneration(body)
            if (status === "Success") {
                handleSaveCategory()
                setSuccessModal(true);
                setLoading(false);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleToDeductionList() {
        navigate("/Admin/SalaryGeneration/SalaryGenerationList");
    }

    const updateOtHourSalary = async (attendance) => {
        if (attendance?.OThours) {
            let findEmployeeSalaries = employeeSalaries?.filter((obj) => (obj?.EmployeeId === attendance?.empid));
            if (findEmployeeSalaries?.length) {
                const disallowedTypeNotPresent = findEmployeeSalaries!.every(obj => obj.EarnType !== 'OT Hours');
                if (disallowedTypeNotPresent) {
                    let findEmployeeSalary = employeeSalaries?.find((each) => each?.EmployeeId === attendance?.empid)
                    if (findEmployeeSalary) {
                        let otCalculation = ((findEmployeeSalary?.GrossSalary / findDaysInMonth) / Number(attendance?.Shithours)) * attendance?.OThours;
                        if (otCalculation) {
                            let salaryObj = {
                                Amount: 0,
                                EarnType: 'OT Hours',
                                EffectDate: findEmployeeSalary?.EffectDate,
                                EmployeeId: attendance?.empid,
                                GrossSalary: findEmployeeSalary?.GrossSalary,
                                Paymentmode: findEmployeeSalary?.Paymentmode,
                                Salarytype: findEmployeeSalary?.Salarytype,
                                additional: true,
                                additionalAmt: otCalculation
                            }
                            let body = {
                                "mode": 2,
                                "empid": attendance?.empid,
                                "ad_Type": 'OT Hours',
                                "sdate": searchFields?.enddate,
                                "amount": otCalculation
                            }
                            const status = await payrollStore.saveAllowanceDeduction(body, 'Allowance')
                            if (status === 'Success') {
                                employeeSalaries?.push(salaryObj)
                                setEmployeeSalaries([...employeeSalaries])
                                setAllowanceModalFields(new IAllowancdModalFields())
                            }
                        }
                    }
                }
            }
        }
    }


    async function getEmployeeDetails() {
        setLoading(true)
        let body = {
            sdate: searchFields?.sdate,
            enddate: searchFields?.enddate,
        }
        const getAttendanceSalary = await payrollStore.getAttendanceForSalary(body)
        if (getAttendanceSalary && getAttendanceSalary?.length) {
            getAttendanceSalary?.map((attendance) => {
                updateOtHourSalary(attendance)
            })
            setEmployeeAttendance([...getAttendanceSalary])
            handleSaveCategory(getAttendanceSalary)
        } else {
            setLoading(false)
        }
    }

    async function initialApiCall() {
        const departmentList = await departmentStore.getDepartment();
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const employeeSalaries = await employeeStore.loadEmployeeSalaryDetails();
        const deductionList = await payrollStore.loadDeductionMaster()
        const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
        const loanBalanceList = await payrollStore.getLoadBalance()

        setIncentiveMaster([...incentiveMaster])
        if (deductionList?.length) {
            let loanObj = {
                Dedid: 0,
                Deductiontype: "Loan",
                Percentage: 0,
                cellingamount: 0,
                effectdate: '',
            }
            deductionList?.push(loanObj);
        }
        setDeductionList([...deductionList])
        setLoanBalanceList([...loanBalanceList])
        setDepartmentList([...departmentList])
        setEmployeeMaster([...employeeMaster])
        setEmployeeSalaries([...employeeSalaries])
        getEmployeeDetails()
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApiCall()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    function calculateTotalAllowance() {
        let totalAllowance: number = 0;

        totalAllowance = employeeSalaries?.filter((salary) => salary?.EmployeeId == allowanceModal?.empid)?.reduce((a, v) => {
            let findEmpAttend = employeeAttendance?.find((each) => each?.empid == allowanceModal?.empid);
            return a = a + Number(toFixedReduce(v?.additional ? v?.additionalAmt :
                (v?.Amount / (findDaysInMonth)) * (findEmpAttend?.net_payable_days + findEmpAttend?.CL)))
        }, 0)

        return toFixedReduce(totalAllowance)
    }
    return (
        <div>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox" style={{ width: "1240px" }}>
                        <div className="hrBox">
                            <h3>Salary Generation</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>From Date</label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="sdate" value={searchFields.sdate = searchFields?.sdate ?? getCurrentMonthFirstDate()?.firstDate} onChange={handleSearchInputChange}
                                        ></input>
                                        {errors.brandCode && (
                                            <p style={{ color: "red" }}>{errors.brandCode}</p>
                                        )}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>To Date</label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="enddate" value={searchFields.enddate = searchFields?.enddate ?? new Date().toISOString().substr(0, 10)} onChange={handleSearchInputChange}
                                        ></input>
                                        {errors.brandCode && (
                                            <p style={{ color: "red" }}>{errors.brandCode}</p>
                                        )}
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-2">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Department<span>*</span> </label>
                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                            name="department" value={searchFields?.department} onChange={handleSearchInputChange}
                                        >
                                            <option value="" selected>- - Select - -</option>
                                            {departmentList?.map((obj) => {
                                                return (
                                                    <option value={`${obj?.departmentId}`}>{obj?.department}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}> Employee<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={employeeMaster}
                                            value={employeeMaster?.find((obj) => obj?.Employee_Name == filterFields?.employee)}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, val) => handleAutoComplete('employee', val?.Employee_Name)}
                                            renderInput={(params: any) =>
                                                <TextField {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Employee..'
                                                    name="empid"
                                                />}
                                        />
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={getEmployeeDetails}>
                                        Search
                                    </button>
                                    <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                        Clear
                                    </button>
                                    <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="tableListDetails" style={{ marginBottom: "10px" }}>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">EmpID/EmpName</th>
                                        <th scope="col">Salary Type</th>
                                        <th scope="col">Working Days</th>
                                        <th scope="col">Payable Days</th>
                                        <th scope="col">CL</th>
                                        <th scope="col">SL</th>
                                        <th scope="col">WO</th>
                                        <th scope="col">LOP</th>
                                        <th scope="col">OT</th>
                                        <th scope="col">Salary</th>
                                        <th scope="col">Allowance</th>
                                        <th scope="col">Deduction</th>
                                        <th scope="col"></th>
                                        <th scope="col">Delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeAttendance?.map((attendance, index) => {
                                        return (!attendance?.salaryProvided && (!filterFields?.employee || attendance?.first_name == filterFields?.employee)) && (
                                            <tr>
                                                <td>{`${attendance?.empid} / ${attendance?.first_name}`}</td>
                                                <td>{attendance?.emp_salary_type}</td>
                                                <td>{attendance?.working_day}</td>
                                                <td>{attendance?.net_payable_days}</td>
                                                <td>{attendance?.CL}</td>
                                                <td>{attendance?.SL}</td>
                                                <td>{attendance?.WO}</td>
                                                <td>{attendance?.LOP}</td>
                                                <td>{attendance?.OThours}</td>
                                                <td>{attendance?.basic_salary}</td>
                                                <td><button onClick={() => setAllowanceModal({ bool: true, empid: attendance?.empid })}>Allowance</button></td>
                                                <td><button onClick={() => setDeductionModal({ bool: true, empid: attendance?.empid })}>Deduction</button></td>
                                                <td><button onClick={() => !attendance?.salaryProvided ? handleGenerateSalary(attendance?.empid) : {}}>Generate</button></td>
                                                <td><button onClick={() => { }} className="delete"><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={handleToDeductionList}>
                                List
                            </button>
                            {/* <button className="dfBtn" onClick={handleSaveCategory}>
                                Submit
                            </button> */}
                        </div>
                        <Modal show={allowanceModal?.bool} onHide={() => setAllowanceModal({ bool: false, empid: 0 })} className="PriceHistoryModel">
                            <Modal.Header closeButton>
                                <h4>Allowance Details</h4>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="inputBoxLists">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <AutoComplete
                                                options={incentiveMaster?.filter((each) => each?.IType == "Allowance")}
                                                getOptionLabel="IDescription"
                                                emitOption={(option) =>
                                                    setAllowanceModalFields({
                                                        ...allowanceModalFields, additional: true,
                                                        EarnType: option?.IDescription, Employeeid: allowanceModal?.empid
                                                    })}
                                                placeholder={"Select"}
                                                value={allowanceModalFields?.EarnType}
                                                clsName="full-width"
                                                freeSolo
                                            />
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className='inputBox'>
                                                <input type="text"
                                                    name='brandId'
                                                    value={allowanceModalFields?.additionalAmt}
                                                    onChange={(event) => setAllowanceModalFields({ ...allowanceModalFields, additionalAmt: Number(event.target.value) })}
                                                    style={{ border: '1px solid #ccc', height: '40px', borderRadius: '6px', padding: '5px 15px', color: '#0b385d', width: "100%" }}
                                                />
                                            </div>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <button onClick={addAllowaneToSalary} style={{ border: '1px solid #ccc', height: '40px', borderRadius: '6px', padding: '5px 15px', color: '#0b385d' }}>
                                                Add
                                            </button>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tableBox" >
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Fixed</th>
                                                <th scope="col">Earnings</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employeeSalaries?.map((each, index) => {
                                                let findEmp = employeeAttendance?.find((each) => each?.empid == allowanceModal?.empid)
                                                return (each?.EmployeeId) == allowanceModal?.empid && (
                                                    <tr>
                                                        <td>{each?.EarnType}</td>
                                                        <td>{each?.Amount}</td>
                                                        <td>{toFixedReduce(each?.additional ? each?.additionalAmt : ((each?.Amount / (findDaysInMonth)) * (findEmp?.net_payable_days + findEmp?.CL)))}</td>
                                                        <td><button
                                                            style={{
                                                                visibility: each?.additional ? 'visible' : 'hidden',
                                                                border: '1px solid #ccc',
                                                                background: 'transparent',
                                                                minWidth: '46px',
                                                                height: '40px',
                                                                borderRadius: ' 6px',
                                                                fontSize: '16px',
                                                                padding: '5px 15px',
                                                            }} onClick={() => handleRemoveAllowance(index)}>Remove</button></td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td>Total Allowance</td>
                                                <td></td>
                                                <td>
                                                    {calculateTotalAllowance()}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {errors.EarnType && (<p style={{ color: "red" }}>{errors.EarnType}</p>)}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal show={deductionModal?.bool} onHide={() => setDeductionModal({ bool: false, empid: 0 })} className="PriceHistoryModel">
                            <Modal.Header closeButton>
                                <h4>Deduction</h4>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="inputBoxLists">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <AutoComplete
                                                options={incentiveMaster?.filter((each) => each?.IType == "Deduction")}
                                                getOptionLabel="IDescription"
                                                emitOption={(option) =>
                                                    setDeductionModalFields({
                                                        ...deductionModalFields, additional: true, Deductiontype: option?.IDescription,
                                                        Employeeid: deductionModal?.empid
                                                    })}
                                                placeholder={"Select"}
                                                value={deductionModalFields?.Deductiontype}
                                                clsName="full-width"
                                                freeSolo
                                            />
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className='inputBox'>
                                                <input type="text"
                                                    name='brandId'
                                                    value={deductionModalFields?.additionalAmt}
                                                    onChange={(event) => setDeductionModalFields({ ...deductionModalFields, additionalAmt: Number(event.target.value) })}
                                                    style={{ border: '1px solid #ccc', height: '40px', borderRadius: '6px', padding: '5px 15px', color: '#0b385d', width: "100%" }}
                                                />
                                            </div>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-6">
                                            <button onClick={addDeductionToSalary} style={{ border: '1px solid #ccc', height: '40px', borderRadius: '6px', padding: '5px 15px', color: '#0b385d' }}>
                                                Add
                                            </button>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tableBox" >
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Description</th>
                                                <th scope="col">Fixed</th>
                                                <th scope="col">Earnings</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employeeAttendance?.map((attendance, index) => {
                                                let findObj = (attendance?.empid == deductionModal?.empid) ? attendance : null;

                                                let totalAllowance = employeeSalaries?.filter((salary) => salary?.EmployeeId == deductionModal?.empid)?.reduce((a, v) => {
                                                    let findEmpAttend = employeeAttendance?.find((each) => each?.empid == deductionModal?.empid);
                                                    return a = a + Number(toFixedReduce((v?.Amount / (findDaysInMonth)) * (findEmpAttend?.net_payable_days + findEmpAttend?.CL)))
                                                }, 0) ?? 0;

                                                return attendance?.empid == deductionModal?.empid && (
                                                    <>
                                                        {deductionList?.map((deduction, ind) => {
                                                            let esiDeduction = 0;
                                                            let pfDeduction = 0;
                                                            let tdsDeduction = 0;
                                                            let ptDeduction = 0;
                                                            let loanDeduction = 0;

                                                            if (deduction?.Deductiontype === 'ESI') {
                                                                esiDeduction = attendance?.esino ? ((totalAllowance < 21000) ?
                                                                    (totalAllowance * deduction?.Percentage) / 100 : 0) : 0;
                                                            }
                                                            if (deduction?.Deductiontype === 'PF') {
                                                                pfDeduction = attendance?.pfno ?
                                                                    ((totalAllowance >= 15000) ?
                                                                        ((15000 * deduction?.Percentage) / 100) :
                                                                        ((totalAllowance * deduction?.Percentage) / 100)) : 0;
                                                            }
                                                            if (deduction?.Deductiontype === 'TDS' && attendance?.TDSApplicable) {
                                                                tdsDeduction = ((totalAllowance * deduction?.Percentage) / 100);
                                                            }
                                                            if (deduction?.Deductiontype === 'PT' && attendance?.PTApplicable) {
                                                                ptDeduction = ((totalAllowance * deduction?.Percentage) / 100);
                                                            }

                                                            if (loanBalanceList?.length) {
                                                                const findLoan = loanBalanceList?.find((loan, index) => loan?.Empid == deductionModal?.empid)
                                                                if (findLoan?.Balancedue) {
                                                                    loanDeduction = findLoan?.Balanceamount / findLoan?.Balancedue;
                                                                }
                                                            }

                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{deduction?.Deductiontype}</td>
                                                                        <td>{deduction?.Percentage}</td>
                                                                        <td>{toFixedReduce((deduction?.additional && findObj?.empid == deduction?.Employeeid) ? deduction?.additionalAmt :
                                                                            deduction?.Deductiontype === 'ESI' ? esiDeduction :
                                                                                deduction?.Deductiontype === 'PF' ? pfDeduction :
                                                                                    deduction?.Deductiontype === 'TDS' ? tdsDeduction :
                                                                                        deduction?.Deductiontype === 'PT' ? ptDeduction :
                                                                                            loanDeduction ?? 0)}</td>
                                                                        <td><button onClick={() => { }}
                                                                            style={{
                                                                                border: '1px solid #ccc',
                                                                                background: 'transparent',
                                                                                minWidth: '46px',
                                                                                height: '40px',
                                                                                borderRadius: ' 6px',
                                                                                fontSize: '16px',
                                                                                padding: '5px 15px',
                                                                            }}>Remove</button>
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {errors.Deductiontype && (<p style={{ color: "red" }}>{errors.Deductiontype}</p>)}
                                </div>
                            </Modal.Body>
                        </Modal>
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )
            }
        </div >
    )
}

export default SalaryGeneration;