import { IPurchaseReturnSave } from "../model";

export function SubmitValidation(purchaseReturn: IPurchaseReturnSave) {
    let error: any = {};

    if(!purchaseReturn?.rtndate) {
        error.rtndate = "Enter Date"
    }
    if(!purchaseReturn?.createdby) {
        error.createdby = "Select Created By"
    }
    if(!purchaseReturn?.dnoteno) {
        error.dnoteno = "Generate Debit Note"
    }
    if(!purchaseReturn?.supplierid) {
        error.supplierid = "Select Supplier"
    }
    return error
}