import { observable } from "mobx";
export interface IDepartment{
    departmentId?:number;
    department?:string;
    description?:string;
   
}
export interface IDepartmentSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const Department=observable<IDepartment>({
    departmentId:0,
    department:'',
    description:'',
    
});

export default Department;