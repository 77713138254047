import { action, set, makeObservable, observable,runInAction } from 'mobx';
import outletDetails, { IOutlet } from '../model/index';
import apiAgent from '../../../APIAgent';

// rootStore: RootStore;
// constructor(rootStore: RootStore) {
//     this.rootStore = rootStore
// }

class outletStore {
    outletDetls = outletDetails;
    @observable outletList=new Map();
    isLoading=true;
    @observable outletLists = new Map<any, any>();
    constructor(){
        makeObservable(this);
    }

    @action saveOutlet = async (outlet: IOutlet) => {
        try {
          const status=  await apiAgent.outlets.saveOutlet(outlet);
          return status;
        }
        catch (error) {
            console.log(error);
        }
    }
     getOutlets  = async (outletSearchOptions)=> {
        try {
          const outlestData=await apiAgent.outlets.getOutlets(outletSearchOptions);
            this.outletList = outlestData;
            
            runInAction(() => {
                this.outletList = outlestData;
                this.isLoading = false;
            })
            return outlestData;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    @action getOutletDetails = async (outletId) => {
        try {
          const outlestData= await apiAgent.outlets.getOutletDetails(outletId);
          //this.isLoading()
        }
        catch (error) {
            console.log(error);
        }
    }
    @action updateOutletDetails = async (outlet:IOutlet) => {
        try {
            const status= await apiAgent.outlets.updateOutletDetails(outlet);
          
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteOutlet = async (outletId) => {
        try {      
        const status=  await apiAgent.outlets.deleteOutlet(outletId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    @action setOutlet = (state: IOutlet) => {
        //set(state,this.outletDetls);
          this.outletList.set(0,state);
    }
   @action  setOutletList = (state: IOutlet[]) => {
        //set(state,this.outletDetls);
          set(state,this.outletList);
          return this.outletList;
    }

}
export default outletStore;