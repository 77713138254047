import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, getCurrentMonthFirstDate } from '../../common/shared/utils';
import moment from 'moment';

const LeaveRequestList = observer((): JSX.Element => {

    const { payrollStore, employeeStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();

    const [leaveRequestList, setLeaveRequestList] = useState<any>([])
    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [searchFields, setSearchFields] = useState<any>({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    function handleSearchInputChange(event) {
        const { name, value } = event.target;
        setSearchFields({ ...searchFields, [name]: value })
    }

    const handleCreate = () => {
        navigate('/Admin/LeaveRequest')
    }

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Leave_request')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const leaveReqList = await payrollStore.loadLeaveRecordsDetail()
        setLeaveRequestList([...leaveReqList])
        const employeeMaster = await employeeStore?.loadEmployeeMaster()
        if (employeeMaster?.length) {
            setEmployeeMaster([...employeeMaster])
        }
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (leaveRequestList && leaveRequestList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(leaveRequestList)
        }
    }, [leaveRequestList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(leaveRequestList?.slice()?.sort((a, b) => b.Loanid - a.Loanid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    function handleFindDates(fromDate, toDate) {
        if (searchFields?.fromDate && searchFields?.toDate) {
            const yesterday = new Date(searchFields?.fromDate);
            if (new Date(fromDate) >= yesterday && new Date(toDate) <= new Date(searchFields?.toDate) && new Date(fromDate) <= new Date(toDate)) {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container'>
                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox LgInputField'>
                                <div className='inputBoxLists'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label style={{ fontSize: '16px' }}>In Date</label>
                                                <input
                                                    type="date"
                                                    className="inputBoxDate"
                                                    style={{ width: "100%", fontWeight: "lighter" }}
                                                    placeholder="DD/MMM/YYYY"
                                                    data-date=""
                                                    data-date-format="DD MMMM YYYY"
                                                    name="fromDate" value={searchFields.fromDate} onChange={handleSearchInputChange}
                                                ></input>
                                            </div>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label style={{ fontSize: '16px' }}>Out Date</label>
                                                <input
                                                    type="date"
                                                    className="inputBoxDate"
                                                    style={{ width: "100%", fontWeight: "lighter" }}
                                                    placeholder="DD/MMM/YYYY"
                                                    data-date=""
                                                    data-date-format="DD MMMM YYYY"
                                                    name="toDate" value={searchFields.toDate} onChange={handleSearchInputChange}
                                                ></input>
                                            </div>
                                            <div className="vertical-space-10"></div>
                                        </div>
                                        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={leaveRequestList}
                                            searchTitles={[{ key: "Employee_Name", value: "Employee Name" }, { key: "levtype", value: "Leave Type" }]}
                                            emitSearchArray={(filterArray: any) => setFilteredData(filterArray)}
                                            isEmitClear emitClear={() => setSearchFields({ fromDate: getCurrentMonthFirstDate()?.firstDate, toDate: getCurrentMonthFirstDate()?.currentDate })} />
                                    </div>
                                    <div className='vertical-space-20'></div>
                                    <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{leaveRequestList?.length} Records</button>
                                        </div>
                                        <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                    <div className='vertical-space-20'></div>
                                    <div className='tableListDetails'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">S. No.</th>
                                                    <th scope="col">From Date</th>
                                                    <th scope="col">To Date</th>
                                                    <th scope="col">Employee Name</th>
                                                    <th scope="col">Leave type</th>
                                                    <th scope="col">Reason</th>
                                                    <th scope="col">Create</th>
                                                    <th scope="col">Update</th>
                                                    <th scope="col">Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                                                        return (((searchFields?.fromDate && searchFields?.toDate) ? handleFindDates(val?.LR_FromDate, val?.LR_ToDate) : val?.LR_FromDate) && val?.LR_Appstatus == 'No') && (
                                                            <tr>
                                                                <td style={{ textTransform: 'capitalize' }}>{val?.LR_MDNO}</td>
                                                                <td style={{ textTransform: 'capitalize' }}>{moment(val?.LR_FromDate)?.format('DD-MMM-YYYY')}</td>
                                                                <td style={{ textTransform: 'capitalize' }}>{moment(val?.LR_ToDate)?.format('DD-MMM-YYYY')}</td>
                                                                <td style={{ textTransform: 'capitalize' }}>{employeeMaster?.find((obj) => obj?.EmployeeId == val?.LR_EmpID)?.Employee_Name}</td>
                                                                <td style={{ textTransform: 'capitalize' }}>{val?.LR_LevType}</td>
                                                                <td style={{ textTransform: 'capitalize' }}>{val?.LR_Reason}</td>
                                                                <td><button disabled={!userRights?.add} onClick={handleCreate} className='Create'><FontAwesomeIcon icon={faAdd} /></button></td>
                                                                <td><button disabled={!userRights?.edit} onClick={() => { }} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                                                <td><button disabled={!userRights?.delete} onClick={() => { }} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                                                            </tr>
                                                        )
                                                    }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <Modal show={isSuccessModal} onHide={() => setSuccessModal(false)} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setSuccessModal(false)}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>

    )
})

export default LeaveRequestList;