function shifMasterValidation(shiftMaster){
    let errors :any={};
    
    if(!shiftMaster.shiftName){
      
        errors.shiftName = "Enter Shift Name";
    }
    if(!shiftMaster.startingTime){
      
        errors.startingTime = "Enter Shift Time";
    }
    if(!shiftMaster.endingTime){
      
        errors.endingTime = "Enter Ending Time";
    }
    if(!shiftMaster.breakTime){
      
        errors.breakTime = "Enter Break Name";
    }
    if(!shiftMaster.breakstartingTime){
      
        errors.breakstartingTime = "Enter Starting Time";
    }
    if(!shiftMaster.breakendingTime){
      
        errors.breakendingTime = "Enter Ending Time";
    }

    return errors;
};
export default shifMasterValidation