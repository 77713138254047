import { IIEmpPromotionDet, IIEmployeePromoSalDet, IIEmployeePromotion } from "../model"

export function employeeSalaryValidation(employeeSalary: IIEmployeePromoSalDet) {
    let error: any = {}
    if (!employeeSalary.epS_Amount) {
        error.epS_Amount = "Enter Amount"
    }
    if (!employeeSalary.epS_EarnType) {
        error.epS_EarnType = "Select Earn Type"
    }
    if (!employeeSalary.epS_GrossSal) {
        error.epS_GrossSal = "Enter Gross Salary"
    }
    return error;
}

export function employeePromoDetValidation(employeePromoDet: IIEmpPromotionDet) {
    let error: any = {}
    if (!employeePromoDet.epD_CatID) {
        error.epD_CatID = "Select Category"
    }
    if (!employeePromoDet.epD_DegnID) {
        error.epD_DegnID = "Select Designation"
    }
    if (!employeePromoDet.epD_DeptID) {
        error.epD_DeptID = "Select Department"
    }
    if (!employeePromoDet.epD_SalType) {
        error.epD_SalType = "Select Salary Type"
    }
    return error;
}

export function saveValidation(employeePromotioni: IIEmployeePromotion) {
    let error: any = {}
    if (!employeePromotioni.epM_TicketNo) {
        error.epM_TicketNo = "Select Employee ID"
    }
    if (!employeePromotioni.emppromotiondet?.length) {
        error.emppromotiondet = "Enter Promotion Details"
    }
    if (!employeePromotioni.employeepromosaldet?.length) {
        error.employeepromosaldet = "Enter Salary Details"
    }
    return error;
}