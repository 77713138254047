import { IIKeySkills, IILanguage, IIResumeBank, IIResumeCandidate, IIResumeContactDet, IIWorkExp } from "../model";

function validate(value) {
    var re = /\S+@\S+\.\S+/;
    return re.test(value);
}

export function addWorkExp(workExperience: IIWorkExp) {
    let error: any = {}

    if (!workExperience?.company) {
        error.company = 'Enter Company Name'
    }
    if (!workExperience?.jobtitle) {
        error.jobtitle = 'Enter Job title'
    }
    if (!workExperience?.fromdate) {
        error.fromdate = 'Enter From Date'
    }
    if (!workExperience?.todate) {
        error.todate = 'Enter To Date'
    }
    return error;
}

export function addKeySill(workExperience: IIKeySkills) {
    let error: any = {}

    if (!workExperience?.keyskills) {
        error.keyskills = 'Enter Key Skill'
    }
    if (!workExperience?.exp_year) {
        error.exp_year = 'Enter Years in No.'
    }
    if (!workExperience?.exp_month) {
        error.exp_month = 'Enter Months in No.'
    }
    return error;
}

export function addLangKnown(langKnown: IILanguage) {
    let error: any = {}
    if (!langKnown?.mlanguage) {
        error.mlanguage = 'Enter Language'
    }
    if (!(langKnown?.toRead || langKnown?.toSpeak || langKnown?.toWrite)) {
        error.level = 'Check Known Level'
    }
    return error;
}


export function saveValidation(resumeBank: IIResumeBank, candidate: IIResumeCandidate, contactDet: IIResumeContactDet) {
    let error: any = {};
    if (!resumeBank?.jobid) {
        error.jobid = 'Select Job Code'
    }
    // if (!resumeBank?.language?.length) {
    //     error.language = 'Add Languages Known'
    // }
    error = candidateValidation(candidate, error);
    error = contactValidation(contactDet, error)
    return error;
}

export function candidateValidation(candidate: IIResumeCandidate, error) {
    if (!candidate?.candidatename) {
        error.candidatename = 'Enter Candidate Name'
    }
    if (!candidate?.gender) {
        error.gender = 'Enter Candidate Name'
    }
    // gender
    if (candidate?.expYear && candidate?.expYear != '0') {
        if (!candidate?.educationQualification) {
            error.educationQualification = 'Enter Educational Qualification'
        }
        if (!candidate?.currentCompanyDetails) {
            error.currentCompanyDetails = 'Enter Current Compay Details'
        }
        if (!candidate?.lastMonthSalary) {
            error.lastMonthSalary = 'Enter Last Month Salary'
        }
        if (!candidate?.noticePeriod) {
            error.noticePeriod = 'Enter Notice Period'
        }
    }
    return error;
}

export function contactValidation(contactDet: IIResumeContactDet, error) {
    if (!contactDet?.doorno) {
        error.doorno = 'Enter Door No'
    }
    if (!contactDet?.street) {
        error.street = 'Enter Street'
    }
    if (!contactDet?.area) {
        error.area = 'Enter Street'
    }
    if (!contactDet?.city) {
        error.city = 'Enter City'
    }
    if (!contactDet?.pincode) {
        error.pincode = 'Enter Pincode'
    }
    if (!contactDet.email) {
        error.email = "Enter Email Id"
    } else if (!validate(contactDet.email)) {
        error.email = "Enter Valid Email"
    }
    return error;
}