
function assignBatchNoValidation(assignBatchNo,assignBatchNoDetails){
    let errors :any={};
    
      
      if(!assignBatchNo.assigndate){
        errors.assigndate = "Select Assign Date";
      }
      if(!assignBatchNoDetails.inwardmasterid){
        errors.inwardId = "Select Inward Id";
      }
      if(!assignBatchNo.supplierId){
        errors.supplierId = "Select Supplier";
      }
      if(!assignBatchNoDetails.inwarddate){
        errors.inwarddate = "Select Inward Date";
      }
      if(!assignBatchNoDetails.itemName){
        errors.itemName = "Enter Item Name";
      }
      if(!assignBatchNoDetails.batchqty){
        errors.batchqty = "Enter Qty";
      }
      if(!assignBatchNoDetails.mfg_Date){
        errors.mfg_Date = "Select Mfg Date";
      }
      if(!assignBatchNoDetails.exp_Date){
        errors.exp_Date = "Select Expiry Date";
      }
    
    return errors;
  };
    
    export default assignBatchNoValidation;