import { action, set, makeObservable, observable, runInAction } from 'mobx';
import departmentDetails, { IDepartment } from '../model/index';
import apiAgent from '../../../APIAgent';

class departmentStore {
    departmentDetls = departmentDetails;
    @observable departmentList= new Map();
    isLoading=true;
    @observable departmentLists = new Map<any, any>();
    @observable isEdit=false;
    @observable departmentStatus = new Map();
    constructor() {
        makeObservable(this)
    }

    @action saveDepartment = async (department: IDepartment) => {
        try {
          const status=  await apiAgent.departmentMasters.saveDepartment(department);
          return status;
          
        }
        catch (error) {
            console.log(error);
        }
    }
    getDepartment = async ()=>{
        try{
            const departmentData = await apiAgent.departmentMasters.getDepartment();
            this.departmentList = departmentData;
            
            runInAction(()=>{
                this.departmentList = departmentData;
                this.isLoading=false;
            })
            return departmentData;
        }
        catch(error){
            console.log(error);
        }
    }
    updateDepartment = async (department: IDepartment) => {
        
        try {      
        const status=  await apiAgent.departmentMasters.updateDepartment(department);     
        runInAction(() => {
        this.departmentStatus = status;
        });
    
        return this.departmentStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    DepartmentDetails  = async (departmentId:Number)=> {
    
        try {
        const departmentData= await apiAgent.departmentMasters.DepartmentDetails(departmentId);
            
          return departmentData;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDepartment= async (departmentId) => {
        try {      
        const status=  await apiAgent.departmentMasters.deleteDepartment(departmentId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDepartmentSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.departmentMasters.getDepartmentSearch(SearchOptions);
            this.departmentList = data;
            
            runInAction(() => {
                this.departmentList = data;
                
            })
            return this.departmentList;    
         
        }
        catch (error) {
            console.log(error);
        }
    }


@action setDepartment = (state:IDepartment) =>{
    this.departmentList.set(0,state);
}
@action setDepartmentList = (state:IDepartment[]) =>{
    set(state,this.departmentList);
    return this.departmentList;
}
}
export default departmentStore;