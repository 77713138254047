import React, { useEffect, useRef, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faPrint, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { useNavigate } from 'react-router-dom';
import useStores from '../../hooks';
import moment from 'moment';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import numberToWords from 'number-to-words';



function PurchaseReturnDetail() {

  const { purchaseBillStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate()

  const [puchaseReturnDetail, setPurchaseReturnDetail] = useState<any[]>([])
  const [itemList, setItemList] = useState<any[]>([])

  const [showModal, setshowModal] = useState(false);

  const [isLoading, setLoading] = useState(true)

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  function handleCreate() {
    navigate('/Admin/PurchaseReturn');
  }

  async function handleDetailJson(id) {
    const purchaseReturnDetail = await purchaseBillStore.purchasereturndetailsJson(id)
    setItemList([])
    setItemList([...purchaseReturnDetail])
    setshowModal(true)
  }

  const objectRef = useRef<any>({});

  async function handlePrint(val: any) {
    // purchasereturndetailsprint
    const getOutletDCData = await purchaseBillStore.purchasereturndetailsprint(val?.Rtnid);
    objectRef.current = getOutletDCData;
    console.log(getOutletDCData, 'getOutletDCData>>')
    let logo = require('../../../../image/Ganga_Supermarket-1.png')

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      printWindow.document.write(
        `
      <html>
        <head>
         <title style="text-align: center ; padding-right: 100px;"  class="centered-div">. </title>
          <style>
            /* Add any additional styles here */
            .table {
              /* Add table styles here */
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              /* Add th and td styles here */
              // border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              /* Add th styles here */
              background-color: #f2f2f2;
            }
            body {
              margin: 0;
              height: 100vh;
              // display: flex;
              // justify-content: center;
              // align-items: center;
            }
            .container {
             display:flex;
            }
        
            .centered-div {
              text-align: center;
            }
            
            .right-div {

            }
            .border-top {
              border-top: 1px solid #ddd; /* Add top border to the element with this class */
          }
          .border-bottom {
              border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
          }
          .border-right {
            border-right: 1px solid #ddd; /* Add top border to the element with this class */
        }
        .border-left {
            border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
        }
        .border{
          /* border: 1px solid #ddd;*/
        }
        .content {
          padding: 20px;
          page-break-before: always; /* Ensure Content 1 starts on a new page */
      }
      @page {
        size: auto;
        margin: 0mm;
        border: 2px solid #ddd;
    }
          </style>
        </head>
        <body >
        <div class = "border content">
        <!-- <div class="container"> -->
        <div style="height: 25px;"></div>
        <div  class="centered-div">Purchase Return DC</div>
        <div style="height: 25px;"></div>
        <!-- <div  class="right-div">(Original)</div>
        </div> -->
          <table class="table table-striped">
            <thead>
              <tr>
                <td colspan="2" class="border-right border-bottom border-left pad-left border-top">
                    <div style="display: flex; align-items: center; justify-content: space-evenly;">
                        <img src=`+ logo + ` width="260" height="170" />
                    </div>
                </td>
                <td colspan="2" class="border-right border-bottom pad-left border-top">
                  <div style="display: flex; align-items: center; justify-content: space-evenly;">
                      <div style="text-align: center;">
                          <div style="font-weight: 800; font-size: x-large;">Ganga Super Market</div>
                          <div>3/824 A, Thuraiyur Road, Near Ponni Gas Godown</div>
                          <div>Namakkal - 637002, Tamil Nadu</div>
                          <div>GSTIN/UIN: 33BZUPS6837H1ZL</div>
                          <div>FSSAI No: 10020042007183</div>
                          <div>Contact No: `+ getOutletDCData[0]?.contactno + `</div>
                          <div>Email: gsmsupermarket@gmail.com</div>
                      </div>
                  </div>
                </td>
              </tr>
              <tr >
                <th class="border-bottom border-top border-left" scope="col">Ganga Super Market</th>
                <th class="border-bottom border-top  border-right border-left" scope="col">DC No</th>
                <th class="border-bottom border-top  border-right" scope="col">Date</th>
              </tr>
            </thead>` +
        `
          <thead style="padding-bottom: 10px;">
          <tr>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        ` <div>` + getOutletDCData[0]?.Warename + "," + ` </div> <div>` + getOutletDCData[0]?.doorno1 + "," + getOutletDCData[0]?.area1 + "," + getOutletDCData[0]?.city1 + "," + getOutletDCData[0]?.Pincode + `</div>`


        + `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.Rtnid +
        `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.Rtndate).format("DD-MMM-YYYY") +
        `</th>
          </tr>
        </thead>
        <thead style="margin-bottom: 20px;">
          <tr>
            <th class="border-bottom border-top border-left" scope="col">Despatch To</th>
            <th class="border-bottom border-top border-left border-right" scope="col">Request No</th>
            <th class="border-bottom border-top border-right" scope="col">Date</th>
          </tr>
        </thead>
        ` +
        `
        <thead>
        <tr>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">`
        +
        ` <div>` + getOutletDCData[0]?.SupplierName + "," + ` </div> <div>` + getOutletDCData[0]?.doorNo + "," + getOutletDCData[0]?.area + "," + getOutletDCData[0]?.city + "," + getOutletDCData[0]?.pincode1 + `</div>`


        +
        `</th>          
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.supplierid +
        `</th>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outletItemDate).format("DD-MMM-YYYY") +
        `</th>
        </tr>
      </thead>
      </table>
      
      <table  class="border-top" style="width: 100%">
          <thead>
            <tr class="">
              <th  class="border-bottom border-left " scope="col">S.No</th>
              <th class="border-left border-right border-bottom " scope="col">Item Description</th>
              <th class=" border-right border-bottom " scope="col">UOM</th>
              <th class=" border-right border-bottom " scope="col">Qty</th>
              <th class=" border-right border-bottom " scope="col">Amount</th>
              <th class="border-bottom border-right" scope="col" style="padding-top: 25px;padding-left: 30px;">Value</th>
              </tr>          
          </thead>
          <thead>
            <tr class="border-bottom">`+
        `<tbody class="border-bottom border-left border-right">
                  ${getOutletDCData.map((each, index) => `
                    <tr class="">
                      <td class="  border-bottom border-left">${index + 1}</td>
                      <td class=" border-left border-right  border-bottom">${each?.itemname}</td>
                      <td class=" border-bottom border-right">${each?.Unitname}</td>
                      <td class=" border-bottom border-right">${each?.Qty}</td>
                      <td class=" border-bottom border-right">${each?.Rate}</td>
                      <td class=" border-bottom border-right" style="padding-top: 8px; padding-left: 30px; font-weight: 300;">${each?.Qty * each?.Rate}</td>
                    </tr>
                  `).join('')}
              </tbody>
            </tr>         
          </thead>
            <thead>
              <tr class="border-bottom border-left border-right">
                <th class="border-bottom border-left" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom border-left" scope="col">Total</th>
                <th class="border-bottom border-left border-right" scope="col"></th>
                <th class="border-bottom border-right" scope="col">`+ getOutletDCData?.reduce((a, v) => a = a + v?.RtnAmount, 0) + `</th>
              </tr>        
            </thead>
          </table>
          <table style="width: 100%">
          <thead>
          <tr style="height: 50px;"/>
          <tr>
            <th scope="col">
            <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords.toWords(getOutletDCData?.reduce((a, v) => a = a + v?.RtnAmount, 0)) + `</div>
            <div>Amount (In words)</div>
            </th>
          </tr>
        </thead>
        <div style="height: 30px;"></div>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;"> For Ganga Super Market</th>
          </tr>        
        </thead>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;">  Authorised Signatory</th>
          </tr>       
        </thead>
        </table>
          <div style="height: 50px;"></div>
          <div class="centered-div">This is a Computer generated Document</div>
         </div>         
        </body>
      </html>
    `
      );
      printWindow.document.write(
        `
      <html>
        <head>
         <title style="text-align: center ; padding-right: 100px;"  class="centered-div">. </title>
          <style>
            /* Add any additional styles here */
            .table {
              /* Add table styles here */
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              /* Add th and td styles here */
              // border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              /* Add th styles here */
              background-color: #f2f2f2;
            }
            body {
              margin: 0;
              height: 100vh;
              // display: flex;
              // justify-content: center;
              // align-items: center;
            }
            .container {
             display:flex;
            }
        
            .centered-div {
              text-align: center;
            }
            
            .right-div {

            }
            .border-top {
              border-top: 1px solid #ddd; /* Add top border to the element with this class */
          }
          .border-bottom {
              border-bottom: 1px solid #ddd; /* Add bottom border to the element with this class */
          }
          .border-right {
            border-right: 1px solid #ddd; /* Add top border to the element with this class */
        }
        .border-left {
            border-left: 1px solid #ddd; /* Add bottom border to the element with this class */
        }
        .border{
          /* border: 1px solid #ddd;*/
        }
        .content {
          padding: 20px;
          page-break-before: always; /* Ensure Content 1 starts on a new page */
      }
      @page {
        size: auto;
        margin: 0mm;
        border: 2px solid #ddd;
    }
          </style>
        </head>
        <body >
        <div class = "border content">
        <!-- <div class="container"> -->
        <div style="height: 25px;"></div>
        <div  class="centered-div">Purchase Return DC</div>
        <div style="height: 25px;"></div>
        <!-- <div  class="right-div">(Original)</div>
        </div> -->
          <table class="table table-striped">
            <thead>
              <tr >
                <th class="border-bottom border-top border-left" scope="col">Ganga Super Market</th>
                <th class="border-bottom border-top  border-right border-left" scope="col">DC No</th>
                <th class="border-bottom border-top  border-right" scope="col">Date</th>
              </tr>
            </thead>` +
        `
          <thead style="padding-bottom: 10px;">
          <tr>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        ` <div>` + getOutletDCData[0]?.Warename + "," + ` </div> <div>` + getOutletDCData[0]?.doorno1 + "," + getOutletDCData[0]?.area1 + "," + getOutletDCData[0]?.city1 + "," + getOutletDCData[0]?.Pincode + `</div>`


        + `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.Rtnid +
        `</th>
            <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.Rtndate).format("DD-MMM-YYYY") +
        `</th>
          </tr>
        </thead>
        <thead style="margin-bottom: 20px;">
          <tr>
            <th class="border-bottom border-top border-left" scope="col">Despatch To</th>
            <th class="border-bottom border-top border-left border-right" scope="col">Request No</th>
            <th class="border-bottom border-top border-right" scope="col">Date</th>
          </tr>
        </thead>
        ` +
        `
        <thead>
        <tr>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">`
        +
        ` <div>` + getOutletDCData[0]?.SupplierName + "," + ` </div> <div>` + getOutletDCData[0]?.doorNo + "," + getOutletDCData[0]?.area + "," + getOutletDCData[0]?.city + "," + getOutletDCData[0]?.pincode1 + `</div>`


        +
        `</th>          
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        getOutletDCData[0]?.supplierid +
        `</th>
        <th  class="border-bottom border-left border-right" scope="col" style="font-weight: 300;">` +
        moment(getOutletDCData[0]?.outletItemDate).format("DD-MMM-YYYY") +
        `</th>
        </tr>
      </thead>
      </table>
      
      <table  class="border-top" style="width: 100%">
          <thead>
            <tr class="">
              <th  class="border-bottom border-left " scope="col">S.No</th>
              <th class="border-left border-right border-bottom " scope="col">Item Description</th>
              <th class=" border-right border-bottom " scope="col">UOM</th>
              <th class=" border-right border-bottom " scope="col">Qty</th>
              <th class=" border-right border-bottom " scope="col">Amount</th>
              <th class="border-bottom border-right" scope="col" style="padding-top: 25px;padding-left: 30px;">Value</th>
              </tr>          
          </thead>
          <thead>
            <tr class="border-bottom">`+
        `<tbody class="border-bottom border-left border-right">
                  ${getOutletDCData.map((each, index) => `
                    <tr class="">
                      <td class="  border-bottom border-left">${index + 1}</td>
                      <td class=" border-left border-right  border-bottom">${each?.itemname}</td>
                      <td class=" border-bottom border-right">${each?.Unitname}</td>
                      <td class=" border-bottom border-right">${each?.Qty}</td>
                      <td class=" border-bottom border-right">${each?.Rate}</td>
                      <td class=" border-bottom border-right" style="padding-top: 8px; padding-left: 30px; font-weight: 300;">${each?.Qty * each?.Rate}</td>
                    </tr>
                  `).join('')}
              </tbody>
            </tr>         
          </thead>
            <thead>
              <tr class="border-bottom border-left border-right">
                <th class="border-bottom border-left" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom" scope="col"></th>
                <th class="border-bottom border-left" scope="col">Total</th>
                <th class="border-bottom border-left border-right" scope="col"></th>
                <th class="border-bottom border-right" scope="col">`+ getOutletDCData?.reduce((a, v) => a = a + v?.RtnAmount, 0) + `</th>
              </tr>        
            </thead>
          </table>
          <table style="width: 100%">
          <thead>
          <tr style="height: 50px;"/>
          <tr>
            <th scope="col">
            <div style="font-weight: 300; text-transform: capitalize;">`+ numberToWords.toWords(getOutletDCData?.reduce((a, v) => a = a + v?.RtnAmount, 0)) + `</div>
            <div>Amount (In words)</div>
            </th>
          </tr>
        </thead>
        <div style="height: 30px;"></div>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;"> For Ganga Super Market</th>
          </tr>        
        </thead>
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col" style="text-align: right;">  Authorised Signatory</th>
          </tr>       
        </thead>
        </table>
          <div style="height: 50px;"></div>
          <div class="centered-div">This is a Computer generated Document</div>
         </div>         
        </body>
      </html>
    `
      );

      printWindow.document.close();
      // printWindow.location.replace("Naveen");
      printWindow.print();
    } else {
      console.error("Unable to open print window.");
    }
  };

  async function fetchLoadData() {
    setLoading(false)
    const [userRights, purchaseReturnDetail] = await Promise.all([userCreationStore?.getUserRight('Purchase_return'), purchaseBillStore.purchasereturndetailsJson()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    setPurchaseReturnDetail([...purchaseReturnDetail])
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchLoadData();
      isCurrentPage.current = false;
    }
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (puchaseReturnDetail && puchaseReturnDetail?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(puchaseReturnDetail)
    }
  }, [puchaseReturnDetail])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(puchaseReturnDetail?.slice()?.sort((a, b) => b.Rtnid - a.Rtnid), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {isLoading &&
        <div className='SpinnerBox'>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>}
      <div className='container-fluid'>
        <div className='vertical-space-20'></div>
        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={puchaseReturnDetail}
          searchTitles={[{ key: "SupplierName", value: "Supplier Name" }]}
          emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
        <div className='vertical-space-20'></div>
        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{puchaseReturnDetail?.length} Records</button>
          </div>
          <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
        <div className='vertical-space-20'></div>
        <div className='tableListDetails'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope='col'>P.Return No    </th>
                <th scope='col'>P.Return Date  </th>
                <th scope='col'>Supplier Name</th>
                <th scope='col'>Return Amount </th>
                <th scope='col'>Debit Amount</th>
                <th></th>
                <th scope='col'>Create </th>
                <th scope='col'>Update </th>
                <th scope='col'>Delete </th>
                <th scope="col">Print </th>
              </tr>
            </thead>
            <tbody>
              {(isSearch ? filteredData : currentPageData)?.map((obj, index) => {
                return (
                  <tr key={index}>
                    <td>{obj?.Rtnid}</td>
                    <td>{moment(obj?.Rtndate).format("DD-MMM-YYYY")}</td>
                    <td>{obj?.SupplierName}</td>
                    <td>{obj?.RtnAmount}</td>
                    <td>{obj?.RtnAmount}</td>
                    <td><button disabled={!userRights?.view} onClick={() => handleDetailJson(obj?.Rtnid)} className='tablemodeltext'> Detail</button></td>
                    <td><button disabled={!userRights?.add} className='Add' onClick={handleCreate}><FontAwesomeIcon icon={faAdd} /></button></td>
                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                    <td>
                      <button className="Edit" disabled={!userRights?.preview}
                        onClick={() => { handlePrint(obj); }}
                      >
                        <FontAwesomeIcon icon={faPrint} />
                      </button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <Modal show={showModal} onHide={() => setshowModal(false)} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Purchase Return Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Item Name</th>
                    <th scope='col'>Barcode</th>
                    <th scope='col'>Batch No  </th>
                    <th scope='col'>Qty  </th>
                    <th scope='col'>Rate  </th>
                    <th scope='col'>Amount  </th>
                  </tr>
                </thead>
                <tbody>
                  {itemList?.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>{val?.itemname}</td>
                        <td>{val?.Barcode}</td>
                        <td>{val?.Batchno}</td>
                        <td>{val?.Qty}</td>
                        <td>{val?.Rate}</td>
                        <td>{val?.Amount}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>

      </div>
    </>
  );

};

export default PurchaseReturnDetail;
