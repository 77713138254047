import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IAssignOfferAndDiscountSearchoptions } from '../AssignOfferDiscount/model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import moment from 'moment';

const AssignFreeItemList = observer((): JSX.Element => {
  const { assignFreeItemStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])


  async function fetchAssignFreeItems() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Assign_free_item'), assignFreeItemStore.getAssignFreeItems()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const handleCreate = () => {
    navigate('/Admin/AssignFreeItem')
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);

  }
  const deleteAssignFreeItem = async (assignofferid) => {
    setLoading(true);
    const status = await assignFreeItemStore.deleteAssignFreeItem(assignofferid);
    if (status === 'Success') {
      fetchAssignFreeItems();
      setLoading(false);
      setSuccessModal(true);

    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const editAssignFreeItem = (assignofferid) => {

    navigate('/Admin/AssignFreeItem/' + assignofferid)
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchAssignFreeItems();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);


  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (assignFreeItemStore?.assignFreeItems && assignFreeItemStore?.assignFreeItems?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(assignFreeItemStore?.assignFreeItems)
    }
  }, [assignFreeItemStore?.assignFreeItems])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(assignFreeItemStore?.assignFreeItems?.slice()?.sort((a, b) => b.assignfreeitemid - a.assignfreeitemid), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={assignFreeItemStore.assignFreeItems}
                searchTitles={[{ key: "itemname", value: "ItemName" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{assignFreeItemStore?.assignFreeItems?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Assign Date </th>
                      <th scope='col'>Item Name </th>
                      <th scope='col'>Batch No</th>
                      <th scope='col'>Assigned By</th>
                      <th scope='col'>Sale</th>
                      <th scope='col'>Same Item</th>
                      <th scope='col'>Other Item</th>
                      <th scope='col'>Free Qty</th>
                      <th scope='col'>Effective From</th>
                      <th scope='col'>Effective To</th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.assignfreeitemdate}</td>
                            <td>{val.itemname}</td>
                            <td>{val.batch_no}</td>
                            <td>{val.assignedby}</td>
                            <td>{val.saleqty}</td>
                            <td>{val?.otheritem1 === 'No' ? 'Yes' : 'No'}</td>
                            <td>{val.otheritem != "" ? val.otheritem : 'No'}</td>
                            <td>{val.freeqty}</td>
                            <td>{moment(val.efffromdate)?.format('DD-MMM-YYYY hh:mm A')}</td>
                            <td>{moment(val.efftodate)?.format('DD-MMM-YYYY hh:mm A')}</td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editAssignFreeItem(val.assignFreeId)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignFreeItem(val.assignFreeId)} /></button></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>

            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

});

export default AssignFreeItemList;
