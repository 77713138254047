import apiAgent from '../../../APIAgent';
import { ILoadLedger, IPaymentVoucherSave, IPurchasebill } from '../model';

class PaymentVoucherStore {
    loadLedgers = async (loadLedger: ILoadLedger) => {
        try {
            const loadLedgerAccounts = await apiAgent.paymentVoucher.loadLedgers(loadLedger);

            return loadLedgerAccounts
        } catch (error) {
            console.log(error)
        }
    }
    savePaymentVoucher = async (paymentVoucherSave: IPaymentVoucherSave) => {
        try {
            const status = await apiAgent.paymentVoucher.savePaymentVoucher(paymentVoucherSave);
             
            return status
        } catch (error) {
            console.log(error)
        }
    }
    loadPurchaseBill =  async (supid) => {
        try {
            const purchaeBillList = await apiAgent.paymentVoucher.purchaseBill(supid);

            return purchaeBillList
        } catch (error) {
            console.log(error)
        }
    }
    voucherMasterGet = async (body) =>{
        try {
            const voucherMasterList = await apiAgent.paymentVoucher.voucherMasterGet(body);

            return JSON.parse(voucherMasterList)
        } catch (error) {
            console.log(error)
        }
    }
    loadDebitNoteMaster = async () =>{
        try {
            const debitNoteMasterList = await apiAgent.paymentVoucher.loadDebitNoteMaster();

            return JSON.parse(debitNoteMasterList)
        } catch (error) {
            console.log(error)
        }
    }
    loadDebitNoteDetails= async (id) =>{
        try {
            const debitNotDetailList = await apiAgent.paymentVoucher.loadDebitNoteDetails(id);

            return JSON.parse(debitNotDetailList)
        } catch (error) {
            console.log(error)
        }
    }
    loadPaymentBillDetail = async (paymentmasterid) => {
        try {
            const loadPaymentBillDetails = await apiAgent.paymentVoucher.loadPaymentBillDetail(paymentmasterid);
            return JSON.parse(loadPaymentBillDetails)
        } catch (error) {
            console.log(error)
        }
    }

}
export default PaymentVoucherStore;