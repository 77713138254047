import React, { useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import { Accordion } from "react-bootstrap";
import { Autocomplete, TextField, Checkbox } from "@mui/material";

const SalaryRevisionRegistry = () => {
    const employee = [{ key: 'name', value: 'name' }, { key: 'name', value: 'name' }, { key: 'name', value: 'name' }]
    const currentYear = new Date().getFullYear();

    const yearsArray = Array.from({ length: 10 }, (_, index) => {
        const year = currentYear + index;
        return { key: year.toString(), value: year.toString() };
    });
    const months = [
        { key: "january", value: "January" },
        { key: "february", value: "February" },
        { key: "march", value: "March" },
        { key: "april", value: "April" },
        { key: "may", value: "May" },
        { key: "june", value: "June" },
        { key: "july", value: "July" },
        { key: "august", value: "August" },
        { key: "september", value: "September" },
        { key: "october", value: "October" },
        { key: "november", value: "November" },
        { key: "december", value: "December" }
    ];

    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [category, setCategory] = useState<any>({})
    const handleFailureModalClose = () => setFailureModal(false);
    const [onOpenDetail, setOnOpenDetail] = useState(false)

    const handleSuccessModalClose = () => {
        // navigate('/Admin/Brand/list');
    }
    async function handleSaveCategory() {

    }

    function handleClickDetail() {
        setOnOpenDetail(true)
    }

    function handleChangeInput(label, value) {
        setCategory({ ...category, [label]: value })
    }


    function handleToSelectionApprovalList() {
        navigate('/Admin/SelectionApproval/SelectionApprovalList')
    }
    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{width:"60%"}}> {/*LgInputField*/}
                            <div className='hrBox'>
                                <h3>Salary Revision Registry</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className="col-sm-2">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}>Date</label>
                                            <input
                                                type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="fromdate"
                                                value={new Date().toISOString().substr(0, 10) ?? new Date()}
                                                // onChange={(evt) => {
                                            //     handleChangeInput("outDate", evt.target.value);
                                            // }}
                                            ></input>
                                            {errors.brandCode && (
                                                <p style={{ color: "red" }}>{errors.brandCode}</p>
                                            )}
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-4">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>
                                                Employee<span>*</span>
                                            </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employee}
                                                getOptionLabel={(option: any) => option.value}
                                                // onChange={(event, val) => handleReceiptLedgerGroup('ledgerGroupId', event, val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Employee..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-8"></div>
                                    <div className="col-sm-4">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>
                                                Designation<span>*</span>
                                            </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employee}
                                                getOptionLabel={(option: any) => option.value}
                                                // onChange={(event, val) => handleReceiptLedgerGroup('ledgerGroupId', event, val)}
                                                renderInput={(params: any) =>
                                                    <TextField  {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Designation..'
                                                        name='ledgerGroupId'
                                                    />}
                                            />
                                        </div>
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="" style={{ marginTop: '30px' }}>
                                            {/* <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                            View
                                        </button> */}
                                            <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Print
                                            </button>
                                            <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => { }}>
                                                Clear
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2}>S.No</th>
                                            <th rowSpan={2}>Designation</th>
                                            <th className="text-center" colSpan={2}>On Roll Strength</th>
                                            <th className="text-center" colSpan={2}>Present Strength</th>
                                        </tr>
                                        <tr>
                                            <th>Male</th>
                                            <th>Female</th>
                                            <th>Male</th>
                                            <th>Female</th>
                                        </tr>
                                       
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Accountant</td>
                                            <td>10.00</td>
                                            <td>11.00</td>
                                            <td>01.00</td>
                                            <td>02.00</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>GRAND TOTAL</td>
                                            <td>10.00</td>
                                            <td>11.00</td>
                                            <td>01.00</td>
                                            <td>02.00</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div> */}
                            {/* <div className='btnBox'>
                                <button className='secondaryBtn' onClick={() => { handleToSelectionApprovalList() }}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div> */}
                            <Modal show={onOpenDetail} onHide={() => { setOnOpenDetail(!onOpenDetail) }} className='PriceHistoryModel'>
                                <div style={{ width: '1200px', height: '400px' }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>SELECTION APPROVAL DETAILS</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inputBoxLists accordionLists">
                                            <Accordion> {/* defaultActiveKey="0" */}
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Candidate Details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">DOB  </th>
                                                                        <th scope="col">Gender </th>
                                                                        <th scope="col">Martial Status  </th>
                                                                        <th scope="col">Blood Group </th>
                                                                        <th scope="col">Edu Qualification  </th>
                                                                        <th scope="col">Experience</th>
                                                                        <th scope="col">Current Experience  </th>
                                                                        <th scope="col">Last Month Salary  </th>
                                                                        <th scope="col">Notice Period  </th>
                                                                        <th scope="col">Current City  </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Key Skills</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Key Skills</th>
                                                                        <th scope="col">Total Experience </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {ledgers?.length ? ledgers?.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.ledgerDetlId}</td>
                                                                            <td>{val.ledgerName}</td>
                                                                            <td>{val.ledgerAmount}</td>
                                                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) : null} */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>Languages Known</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">S.No</th>
                                                                        <th scope="col">Language Known</th>
                                                                        <th scope="col">To Speak </th>
                                                                        <th scope="col">To Read </th>
                                                                        <th scope="col">To Write </th>
                                                                        {/* <th scope='col'>Delete </th> */}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* {ledgers?.length ? ledgers?.map((val, key) => {
                                                                    return (
                                                                        <tr key={key}>
                                                                            <td>{val.ledgerDetlId}</td>
                                                                            <td>{val.ledgerName}</td>
                                                                            <td>{val.ledgerAmount}</td>
                                                                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgers(val.ledgerDetlId)} /></button></td>
                                                                        </tr>
                                                                    )
                                                                }) : null} */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Work Experience</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Company</th>
                                                                        <th scope="col">Job Title </th>
                                                                        <th scope='col'>From </th>
                                                                        <th scope='col'>To </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* <tr>
                                                                        <td>{inwardDetail.discountType}</td>
                                                                        <td>{inwardDetail.discount}</td>
                                                                        <td>{inwardDetail.discountAmount}</td>
                                                                    </tr> */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            {/* <Image src={require('../../../../image/checked.png')} /> */}
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            {/* <Image src={require('../../../../image/warning.png')} /> */}
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default SalaryRevisionRegistry;