import { action, set, makeObservable, observable, runInAction } from 'mobx';
import apiAgent from '../../../APIAgent';
import { ICategoryAssignMaster } from '../model/index';
import categoryAssignDetail from '../model/index';

class CategoryAssignMasterStore {
    @observable categoryAssignDetls = categoryAssignDetail;
    @observable categoryAssignMasterList= new Map();
    isLoading=true;
    categoryAssignMasterLists= new Map();
    categoryAssignMasterDetail = new Map();
    isInwardLoding=true;
    constructor(){
        makeObservable(this);
    }
     saveCategoryAssignMaster= async (categoryAssignMaster : ICategoryAssignMaster) => {
        try {
          const status=  await apiAgent.categoryAssignMaster.saveCategoryAssignMaster(categoryAssignMaster);
          if(status!==undefined){
            return status;
           }
        }
        catch (error) {
            console.log(error);
        }
    }   
    getTeamMaster=async ()=> {
        
        try {
          const getTeamMaster= await apiAgent.categoryAssignMaster.getTeamMaster();
            
            runInAction(() => {
                
                this.categoryAssignMasterList = getTeamMaster;
            });
            return this.categoryAssignMasterList;
        }
        catch (error) {
            console.log(error);
        }
    }   
    // getCategoryAssignMasterDetails=async (offerid)=> {
    //     try {
    //       const getCategoryAssignMaster = await apiAgent.categoryAssignMaster.getCategoryAssignMasterDetails(offerid);
            
    //         runInAction(() => {
                
    //             this.categoryAssignMasterDetail = getCategoryAssignMaster;
    //         });
    //         return this.categoryAssignMasterDetail;
    //     }
    //     catch (error) {
    //         console.log(error);
    //     }
    // }
    getCategoryAssignMasterDetails = async (offerid) => {

        try {
            const getCategoryAssignMaster = await apiAgent.categoryAssignMaster.getCategoryAssignMasterDetails(offerid);

            runInAction(() => {
                this.categoryAssignMasterDetail = JSON.parse(getCategoryAssignMaster);
            })
            return JSON.parse(getCategoryAssignMaster);
        }
        catch (error) {
            console.log(error);
        }
    }
    getTeamMasterSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.categoryAssignMaster.getTeamMasterSearch(SearchOptions);
            this.categoryAssignMasterList = Data;
            
            runInAction(() => {
                this.categoryAssignMasterList = Data;
                
            })
            return this.categoryAssignMasterList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteTeamMasterDetail = async (itemConversionDetailId) => {
        try {      
        const status=  await apiAgent.categoryAssignMaster.deleteTeamMasterDetail(itemConversionDetailId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteCategoryAssignMaster = async (offercustmid) => {
        try {      
        const status=  await apiAgent.categoryAssignMaster.deleteCategoryAssignMaster(offercustmid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    @action setTeamMaster = (state:ICategoryAssignMaster) =>{
        this.categoryAssignMasterList.set(0,state);
    }
    @action setTeamMasterList = (state: ICategoryAssignMaster[]) =>{
        set(state,this.categoryAssignMasterList);
        return this.categoryAssignMasterList;
    }
}
export default CategoryAssignMasterStore;