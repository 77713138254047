import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faL, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, ProgressBar, Spinner, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import useStores from '../../hooks';
import { IMRPPrice, ISellingPrice, ISellingPriceUpdateSearchoptions } from './model';
import moment from 'moment';
import { QRCode } from 'react-qrcode-logo';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const SellingPriceUpdate = observer((): JSX.Element => {
  const [sellingPriceUpdateSearchOptions, setSellingPriceUpdateSearchOptions] = useState<ISellingPriceUpdateSearchoptions>({})
  const { sellingPriceUpdateStore } = useStores();
  const [mrpPriceDetail, setMRPPriceDetail] = useState<IMRPPrice>({})
  const [sellingPriceDetail, setSellingPriceDetail] = useState<ISellingPrice>({})
  const { getSellingPriceUpdate, getsellingPriceUpdates,
    getSellingHistory,
    getsellingPriceMRPDetls, getMRPHistory, getsellingHistoryDetls, getSellingDetails, saveSellingDetails,
    getLastInwardDetls, getLastInwardHistory, getMRPPriceDetails, saveMRPDetails } = sellingPriceUpdateStore;
  const [isLoading, setLoading] = useState(true);
  const [isSellingPrice, setSellingPriceModal] = useState(false);
  const [isSellingPriceMRP, setSellingPriceModalMRP] = useState(false);
  const [isLastInward, setLastInwardModel] = useState(false);
  const [isUpdateMRP, setUpdateMRP] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isUpdateSelling, setUpdateSelling] = useState(false);
  const [isSpinner, setSpinner] = useState(true);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleFailureModalClose = () => setFailureModal(false);


  async function fetchSellingPrice() {
    const data = await getSellingPriceUpdate();
    if (data !== undefined) {
      setLoading(false);
    }

  }
  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchSellingPrice();
      isCurrentPage.current = false;
    }
    return () => { }
  }, [])
  const handleSuccessModalClose = () => {
    setSuccessModal(false)
    setLoading(true);
    fetchSellingPrice();
  }

  async function handleMRPHistory(itemId, e) {
    e.preventDefault();
    await getMRPHistory(itemId);
    setSellingPriceModal(true);
  }

  async function handleSellingPriceMRP(itemId, batchno, e) {
    e.preventDefault();
    await getSellingHistory(itemId, batchno, '');
    setSellingPriceModalMRP(true);
  }

  async function handleLastInward(itemId, e) {
    e.preventDefault();
    await getLastInwardHistory(itemId);
    setLastInwardModel(true);
  }

  async function handleSellingPricee(itemId, batchNo, e) {
    let sellingObject: ISellingPrice = {};
    setSellingPriceDetail(sellingObject);
    e.preventDefault();
    const data = await getSellingDetails(itemId);

    if (data !== undefined) {
      setUpdateSelling(true);

      sellingPriceDetail.itemName = data.itemName
      sellingPriceDetail.Batchno = batchNo
      sellingPriceDetail.itemId = data.itemId
      sellingPriceDetail.itemMrpId = data.itemMRPId
      sellingPriceDetail.b2BPrice = data.b2BPrice;
      sellingPriceDetail.b2CPrice = data.b2CPrice;
      sellingPriceDetail.b2BNewPrice = 0;
      sellingPriceDetail.b2CnewPrice = 0;
      sellingPriceDetail.b2BStPer = 0;
      sellingPriceDetail.b2CStPer = 0;
      sellingPriceDetail.oldEffectiveDate = moment(data.oldEffectiveDate).format('YYYY-MM-DD hh:mm A');
      sellingPriceDetail.mrpPrice = data.mrppRice;
      setSellingPriceDetail(sellingPriceDetail);
    }
    setUpdateSelling(true);
  }
  const handleClose = () => {
    setSellingPriceModal(false)
    setUpdateMRP(false)
    setUpdateSelling(false);
    setUpdateSelling(false);
  }
  const handleCloseMRP = () => {
    setSellingPriceModalMRP(false)
  }
  const handleCloseInward = () => {
    setLastInwardModel(false)
  }
  const handleMRPPrice = async (itemId, batchNo) => {
    setLoading(true);

    let mrpObject: IMRPPrice = {};
    setMRPPriceDetail(mrpObject);
    const data = await getMRPPriceDetails(itemId);
    if (data !== undefined) {


      mrpPriceDetail.itemName = data.itemName
      mrpPriceDetail.Batchno = batchNo
      mrpPriceDetail.itemId = data.itemId
      mrpPriceDetail.itemMrpId = data.itemMRPId
      mrpPriceDetail.mrp = data.mrp;
      mrpPriceDetail.oldEffectiveDate = moment(data.oldEffectiveDate).format('YYYY-MM-DD hh:mm A');
      mrpPriceDetail.newMRP = undefined;
      setMRPPriceDetail(mrpPriceDetail);
      setUpdateMRP(true);
      setLoading(false);
    }

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMRPPriceDetail({ ...mrpPriceDetail, [name]: value })
  }

  const handleSellingInput = (e) => {
    const { name, value } = e.target;
    setSellingPriceDetail({ ...sellingPriceDetail, [name]: value })
  }
  async function UpdateSellingPrice(e) {

    let b2cdiscount: Number = 0;
    let b2bdiscount: Number = 0;
    let b2cnewPrice: Number = 0;
    let b2bnewPrice: Number = 0;

    setLoading(true);
    if (Number(sellingPriceDetail.b2CStPer) === 0 && Number(sellingPriceDetail.b2BStPer) === 0) {

      b2cdiscount = Number(sellingPriceDetail.b2CStPer) ? Number(sellingPriceDetail.b2CStPer) : ((Number(sellingPriceDetail.mrpPrice) - Number(sellingPriceDetail.b2CnewPrice)) / Number(sellingPriceDetail.mrpPrice)) * 100;;
      b2bdiscount = Number(sellingPriceDetail.b2BStPer) ? Number(sellingPriceDetail.b2BStPer) : ((Number(sellingPriceDetail.mrpPrice) - Number(sellingPriceDetail.b2BNewPrice)) / Number(sellingPriceDetail.mrpPrice)) * 100;

    }
    else {
      b2cnewPrice = (Number(sellingPriceDetail.mrpPrice) * Number(sellingPriceDetail.b2CStPer!)) / 100;
      b2cnewPrice = Number(sellingPriceDetail.mrpPrice) - Number(b2cnewPrice!);

      b2bnewPrice = (Number(sellingPriceDetail.mrpPrice) * Number(sellingPriceDetail.b2BStPer!)) / 100;
      b2bnewPrice = Number(sellingPriceDetail.mrpPrice) - Number(b2bnewPrice!);
    }
    sellingPriceDetail.b2BNewPrice! = Number(sellingPriceDetail.b2BNewPrice) ? Number(sellingPriceDetail.b2BNewPrice) : Number(b2bnewPrice);
    sellingPriceDetail.b2CnewPrice! = Number(sellingPriceDetail.b2CnewPrice) ? Number(sellingPriceDetail.b2CnewPrice) : Number(b2cnewPrice);
    sellingPriceDetail.b2BStPer! = Number(sellingPriceDetail.b2BStPer!) ? Number(sellingPriceDetail.b2BStPer!) : Number(b2bdiscount);
    sellingPriceDetail.b2CStPer! = Number(sellingPriceDetail.b2CStPer!) ? Number(sellingPriceDetail.b2CStPer!) : Number(b2cdiscount);
    sellingPriceDetail.effectiveDate = moment(sellingPriceDetail.effectiveDate).format('YYYY-MM-DD hh:mm A');
    sellingPriceDetail.mrpPrice = Number(sellingPriceDetail.mrpPrice);
    setUpdateSelling(false);
    e.preventDefault();

    const status = await saveSellingDetails(sellingPriceDetail);

    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setUpdateSelling(true);
      setFailureModal(true);
    }
  }
  async function UpdateMRPPrice(e) {
    mrpPriceDetail.effectiveDate = moment(mrpPriceDetail.effectiveDate).format('YYYY-MM-DD hh:mm A');

    setUpdateMRP(false);
    e.preventDefault();
    const status = await saveMRPDetails(mrpPriceDetail);
    if (status === "Success") {
      fetchSellingPrice();
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setUpdateMRP(true);
      setFailureModal(true);
    }
  }

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (getsellingPriceUpdates?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(getsellingPriceUpdates)
    }
  }, [getsellingPriceUpdates])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(getsellingPriceUpdates, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <div className='container-fluid'>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='vertical-space-20'></div>

            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={getsellingPriceUpdates}
              searchTitles={[{ key: "itemName", value: "Item Name" }]}
              emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
            <div className='inputBoxLists'>
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{getsellingPriceUpdates?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr style={{ textAlign: 'center' }}>
                    <th scope='col'>Item Name </th>
                    <th scope='col'>Batchno </th>
                    <th scope='col'>MRP</th>
                    <th scope='col'>Effective Date</th>
                    <th scope='col'>New MRP</th>
                    <th scope='col'>New Effective Date</th>
                    <th scope='col'>MRP History </th>
                    <th scope='col'>L Inward </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>B2B <br></br> Price</th>
                    <th scope='col'>B2C <br></br> Price</th>
                    <th scope='col'> New  Price <br></br> B2B </th>
                    <th scope='col'> New  Price <br></br> B2C  </th>
                    <th scope='col'>B2B st <br></br>Dis %</th>
                    <th scope='col'>B2C st <br></br> Dis %</th>
                    <th scope='col'>Effective Date & Time</th>
                    <th scope='col'>SP History </th>
                    <th scope='col'>Update </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                    (isSearch ? filteredData : currentPageData).map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.itemName} </td>
                          {val.batchno ?
                            <td><QRCode value={val.batchno} size={100} /></td> :
                            <td>{val.batchno}</td>
                          }
                          {/* <td><QRCode value={val.batchno} size={100} /></td> */}
                          <td>{val.mrp} </td>
                          <td >{val.oldEffectiveDateTime}</td>
                          <td  >{(val.newMrp)}</td>
                          <td  >{(val.newEffectiveDateTime)}</td>
                          <td><button onClick={(e) => handleMRPHistory(val.itemId, e)}>History</button></td>
                          <td><button onClick={(e) => handleLastInward(val.itemId, e)}>Inward</button></td>
                          <td><button style={{ backgroundColor: '#dc3545', color: 'white' }} type='submit' onClick={() => handleMRPPrice(val.itemId, val.batchno)}>Update</button></td>
                          <td >{val.b2B} </td>
                          <td >{val.b2C} </td>
                          <td >{val.b2BNewPrice} </td>
                          <td >{val.b2CNewPrice} </td>
                          <td >{val.b2BStP} </td>
                          <td >{val.b2CStP} </td>
                          <td >{val.b2effectiveDateTime ? moment(val.b2effectiveDateTime).format('YYYY-MM-DD hh:mm A') : ''}</td>
                          <td><button onClick={(e) => handleSellingPriceMRP(val.itemId, val?.batchno, e)}>History</button></td>
                          <td><button style={{ backgroundColor: '#dc3545', color: 'white' }} type='submit' onClick={(e) => handleSellingPricee(val.itemId, val.batchno, e)}>Update</button></td>
                        </tr>
                      )
                    })
                  }

                </tbody>
              </table>
            </div>

            {
              isSellingPrice ?
                <Modal show={isSellingPrice} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">MRP Rate</th>
                            <th scope="col">Eff. Date</th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            getsellingPriceMRPDetls && getsellingPriceMRPDetls.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.mrpRate} </td>
                                  <td>{(val.effecticeDate)}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            {
              isSellingPriceMRP ?
                <Modal show={isSellingPriceMRP} onHide={handleCloseMRP} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Detail</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">B2B Price</th>
                            <th scope="col">B2B st.Dis % </th>
                            <th scope="col">B2C Price </th>
                            <th scope="col">B2C st.Dis %</th>
                            <th scope="col">Eff.From </th>

                          </tr>
                        </thead>
                        <tbody>
                          {
                            getsellingHistoryDetls.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.b2BNewPriceHistory} </td>
                                  <td>{val.b2BStPerHistory} </td>
                                  <td>{val.b2CnewPriceHistory} </td>
                                  <td>{val.b2CStPerHistory} </td>
                                  <td>{(val.b2EffectiveDateHistory)}</td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            {
              isLastInward ?
                <Modal show={isLastInward} onHide={handleCloseInward} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Inward</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='tableBox'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Inward no</th>
                            <th scope="col">Supplier Name</th>
                            <th scope="col">Inward Date</th>
                            <th scope="col">Rate </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            getLastInwardDetls.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.inwardNo} </td>
                                  <td>{val.supplierName} </td>
                                  <td>{(val.inwardDate)}</td>
                                  <td>{val.rate} </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            {
              mrpPriceDetail ? <Modal show={isUpdateMRP} onHide={handleClose} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Update MRP Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='container'>

                    <div className='vertical-space-20'></div>
                    <div className='outletInputField inputFormBox'>
                      <div className='inputBoxLists pd-b-0'>
                        <div className='LgInputListsBox mr-left-0'>
                          <div className='row'>
                            <div className='col-md-6'>
                              <div className='inputBox'>
                                <label>Item Name <span>*</span></label>
                                <input type="input" disabled style={{ width: "100%" }} name='itemName'
                                  value={mrpPriceDetail.itemName}
                                ></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-5'>
                              <div className='inputBox'>
                                <label>MRP <span>*</span></label>
                                <input type="input" disabled style={{ width: "100%" }} name='mrp'
                                  value={mrpPriceDetail.mrp}
                                ></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-5'>
                              <div className='inputBox'>
                                <label>New MRP <span>*</span></label>
                                <input type="input" style={{ width: "100%" }} name='newMRP'
                                  onChange={handleInputChange}
                                  value={mrpPriceDetail.newMRP}
                                ></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-md-5'>
                              <div className='inputBox'>
                                <label>Effective Date <span>*</span></label>
                                <input type="datetime-local" style={{ width: "100%" }} name='oldEffectiveDate'
                                  placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" disabled
                                  value={(mrpPriceDetail.oldEffectiveDate || '').toString().substring(0, 16)}
                                ></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                            <div className='col-md-5'>
                              <div className='inputBox'>
                                <label>New Effective Date <span>*</span></label>
                                <input type="datetime-local" style={{ width: "100%" }} name='effectiveDate'
                                  onChange={handleInputChange}
                                  value={mrpPriceDetail.effectiveDate}
                                ></input>
                              </div>
                              <div className='vertical-space-10'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='btnBox'>
                        <button className='secondaryBtn' >Cancel</button>
                        <button className='dfBtn' type='submit' onClick={UpdateMRPPrice} >Submit</button>
                      </div>
                    </div>

                  </div>
                </Modal.Body>
              </Modal> : null
            }
            {
              sellingPriceDetail ?
                <Modal show={isUpdateSelling} onHide={handleClose} className='PriceHistoryModel'>
                  <Modal.Header closeButton>
                    <Modal.Title>Update Selling Price</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='container'>

                      <div className='vertical-space-20'></div>
                      <div className='outletInputField inputFormBox'>
                        <div className='inputBoxLists pd-b-0'>
                          <div className='LgInputListsBox mr-left-0'>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='inputBox'>
                                  <label>Item Name <span>*</span></label>
                                  <input type="input" disabled style={{ width: "100%" }} name='itemName'
                                    value={sellingPriceDetail.itemName}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6'>
                                <div className='inputBox'>
                                  <label> Batch No <span>*</span></label>
                                  <input type="input" disabled style={{ width: "100%" }} name='Batchno'
                                    value={sellingPriceDetail.Batchno}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>B2B Price <span>*</span></label>
                                  <input type="input" disabled style={{ width: "100%" }} name='b2BPrice'
                                    value={sellingPriceDetail.b2BPrice}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>New B2B Price <span>*</span></label>
                                  <input type="input" style={{ width: "100%" }} name='b2BNewPrice'
                                    value={sellingPriceDetail.b2BNewPrice}
                                    onChange={handleSellingInput}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>B2B Std Dis% <span>*</span></label>
                                  <input type="input" style={{ width: "100%" }} name='b2BStPer'
                                    value={sellingPriceDetail.b2BStPer}
                                    onChange={handleSellingInput}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>B2C Price <span>*</span></label>
                                  <input type="input" disabled style={{ width: "100%" }} name='b2CPrice'
                                    value={sellingPriceDetail.b2CPrice}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>New B2C Price <span>*</span></label>
                                  <input type="input" style={{ width: "100%" }} name='b2CnewPrice'
                                    value={sellingPriceDetail.b2CnewPrice}
                                    onChange={handleSellingInput}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                              <div className='col-md-3'>
                                <div className='inputBox'>
                                  <label>B2C Std Dis% <span>*</span></label>
                                  <input type="input" style={{ width: "100%" }} name='b2CStPer'
                                    value={sellingPriceDetail.b2CStPer}
                                    onChange={handleSellingInput}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-5'>
                                <div className='inputBox'>
                                  <label>Effective Date <span>*</span></label>
                                  <input type="datetime-local" style={{ width: "100%" }} name='oldEffectiveDate'
                                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY" disabled
                                    value={(sellingPriceDetail.oldEffectiveDate || '').toString().substring(0, 16)}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                              <div className='col-md-5'>
                                <div className='inputBox'>
                                  <label>New Effective Date <span>*</span></label>
                                  <input type="datetime-local" style={{ width: "100%" }} name='effectiveDate'
                                    value={sellingPriceDetail.effectiveDate}
                                    onChange={handleSellingInput}
                                  ></input>
                                </div>
                                <div className='vertical-space-10'></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='btnBox'>
                          <button className='secondaryBtn' onClick={handleClose}>Cancel</button>
                          <button className='dfBtn' type='submit' onClick={UpdateSellingPrice} >Submit</button>
                        </div>
                      </div>

                    </div>
                  </Modal.Body>
                </Modal> : null
            }
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Submitted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </div>
  );
});

export default SellingPriceUpdate;
