import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { IILeaveReq } from "./model";
import useStores from "../../hooks";
import ProgressBar from "../../common/shared/progressbar";
import { Autocomplete, TextField } from "@mui/material";
import { Button, Image, Modal } from "react-bootstrap";
import { saveValidation } from "./validation";

const LeaveRequest = () => {

    const { employeeStore, inwardOultetStore, payrollStore } = useStores();

    const navigate = useNavigate()

    const leaveType = [{ key: 'wo', value: 'WO' }, { key: 'cl', value: 'CL' }, { key: 'pl', value: 'PL' }, { key: 'lop', value: 'LOP' }, { key: 'sl', value: 'SL' }]

    const [isLoading, setLoading] = useState(true)
    const [isSuccessModal, setSuccessModal] = useState(false)
    const [isFailureModal, setFailureModal] = useState(false)

    const [errors, setErrors] = useState<any>({})

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [leaveReq, setLeaveReq] = useState<IILeaveReq>(new IILeaveReq())

    function handleChangeInput(event) {
        const { name, value } = event.target;
        handleAutoComplete(name, value)
    }
    function handleAutoComplete(name, value) {
        setLeaveReq({ ...leaveReq, [name]: value })
    }

    function handleSuccessModalClose() {
        navigate('/Admin/LeaveRequest/List')
        setSuccessModal(false)
    }

    async function handleSaveLeaveReq() {
        let error: any = {}
        error = saveValidation(leaveReq)
        setErrors({ ...error })
        const status = await payrollStore.saveLeaveRequest(leaveReq);
        if (status === 'Success') {
            setLoading(false);
            setSuccessModal(true);
        } else {
            setLoading(false);
            setFailureModal(true);
        }
    }

    async function initialApicall() {
        const EntryNos = await inwardOultetStore.getEntryNo('LeaveRecords');
        setLeaveReq({ ...leaveReq, lR_MDNO: EntryNos?.entryNo });
        const employeeMaster = await employeeStore?.loadEmployeeMaster()
        if (employeeMaster?.length) {
            setEmployeeMaster([...employeeMaster])
        }
        setLoading(false)
    }

    const isCurrentPage = useRef(true)

    useEffect(() => {
        if (isCurrentPage.current) {
            initialApicall();
            isCurrentPage.current = false;
        }
    }, [])

    return (
        <div>
            {isLoading ? <ProgressBar /> :
                <>
                    <div className="container">
                        <div className="vertical-space-20"></div>
                        <div className="outletInputField inputFormBox">
                            <div className="hrBox">
                                <h3>Leave Request</h3>
                            </div>
                            <div className="inputBoxLists">
                                <div className="row">
                                    <div className='col-md-7'>
                                        <div className='inputBox'>
                                            <label>Entry No. <span>*</span></label>
                                            <input type="text" className=''
                                                name='mcode'
                                                value={leaveReq?.lR_MDNO}
                                                disabled style={{ width: "100%" }} placeholder='Entry No...'></input>
                                            {errors?.lR_MDNO && <p style={{ color: 'red' }}>{errors?.lR_MDNO}</p>}
                                            <div className="vertical-space-10"></div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}> From Date </label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="lR_FromDate"
                                                value={leaveReq?.lR_FromDate}
                                                onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.lR_FromDate && (<p style={{ color: "red" }}>{errors.lR_FromDate}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="inputBox">
                                            <label style={{ fontSize: '16px' }}> To Date </label>
                                            <input type="date"
                                                className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY"
                                                data-date="" data-date-format="DD MMMM YYYY"
                                                name="lR_ToDate"
                                                value={leaveReq?.lR_ToDate}
                                                onChange={handleChangeInput}
                                            ></input>
                                        </div>
                                        {errors.lR_ToDate && (<p style={{ color: "red" }}>{errors.lR_ToDate}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Employee<span>*</span> </label>
                                            <Autocomplete size="small"
                                                disablePortal
                                                id="combo-box-demo"
                                                options={employeeMaster}
                                                value={employeeMaster?.find((obj) => obj?.EmployeeId == leaveReq?.lR_EmpID)}
                                                getOptionLabel={(option: any) => option.Employee_Name}
                                                onChange={(event, val) => handleAutoComplete('lR_EmpID', val?.EmployeeId)}
                                                renderInput={(params: any) =>
                                                    <TextField {...params}
                                                        id="outlined-size-small"
                                                        color='info'
                                                        size="small"
                                                        type="text"
                                                        placeholder='Select Employee..'
                                                        name="lR_EmpID"
                                                    />}
                                            />
                                        </div>
                                        {errors.lR_EmpID && (<p style={{ color: "red" }}>{errors.lR_EmpID}</p>)}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="inputBox" >
                                            <label style={{ fontSize: '16px' }}>Leave Type<span>*</span></label>
                                            <select
                                                className="form-selected full-width"
                                                name="lR_LevType"
                                                value={leaveReq?.lR_LevType}
                                                onChange={handleChangeInput}
                                                style={{ fontSize: "16px" }}
                                            >
                                                <option value="" selected>
                                                    - - - Select - - -
                                                </option>
                                                {leaveType?.map((obj) => {
                                                    return (
                                                        <option value={`${obj?.key}`}>{obj?.value}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        {errors.lR_LevType && <p style={{ color: "red" }}>{errors.lR_LevType}</p>}
                                        <div className="vertical-space-10"></div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='inputBox'>
                                            <label>Reason <span>*</span></label>
                                            <textarea className='TypeaheadSelectInput2' style={{ width: "100%" }}
                                                value={leaveReq.lR_reason} onChange={handleChangeInput} name='lR_reason'
                                                placeholder='Reason..'></textarea>
                                            {errors.lR_reason && <p style={{ color: 'red' }}>{errors.lR_reason}</p>}
                                        </div>
                                        <div className='vertical-space-20'></div>
                                    </div>
                                </div>
                                <div className="btnBox">
                                    <button className="secondaryBtn" onClick={handleSuccessModalClose}>
                                        List
                                    </button>
                                    <button className="dfBtn" onClick={handleSaveLeaveReq}>
                                        Submit
                                    </button>
                                </div>

                                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className="SubmitModal">
                                    <Modal.Body>
                                        <div className="Details Success">
                                            <div className="imgBox">
                                                <Image src={require("../../../../image/checked.png")} />
                                            </div>

                                            <h4>Succesfully Submitted</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleSuccessModalClose}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className="SubmitModal"
                                >
                                    <Modal.Body>
                                        <div className="Details Success">
                                            <div className="imgBox">
                                                <Image src={require("../../../../image/warning.png")} />
                                            </div>
                                            <h4>Failed</h4>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default LeaveRequest;