import React, { useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';



function PartyLedgerDetail() {
  const [show, setShow] = useState(false);

  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);
  return (
    <div className='container-fluid'>
      <div className='vertical-space-20'></div>
      <div className='SearchBox col-md-7'>
        <select className="form-selected" id="Mode" name="searchOption" >
          <option value="">Select Search Criteria</option>
          <option value="Supplier">Supplier</option>
        </select>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Search Here.." aria-label="Search Here" aria-describedby="search-button"></input>
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="button" id="search-button"><FontAwesomeIcon icon={faSearch} /></button>
          </div>
        </div>
        <div className='btnBox'>
          <button className='searchclearBtn' >Clear</button>
        </div>
      </div>
      <div className='tableListDetails'>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope='col'>Party Name   </th>
              <th scope='col'>From Date  </th>
              <th scope='col'>To No </th>
              <th scope='col'></th>
              <th scope='col'>Create </th>
              <th scope='col'>Update </th>
              <th scope='col'>Delete </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1812023  </td>
              <td>19-12-2023 </td>
              <td>19-01-2024  </td>
              <td><button onClick={modalShow}>Detail</button></td>
              <td><button className='Add'><FontAwesomeIcon icon={faAdd} /></button></td>
              <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
              <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
            </tr>

          </tbody>
        </table>
      </div>
      <Modal show={show} onHide={modalClose} className='PriceHistoryModel'>
        <Modal.Header closeButton>
          <Modal.Title>Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='tableBox'>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col"> Date</th>
                  <th scope="col">Bill No</th>
                  <th scope="col">Bill Amount </th>
                  <th scope="col">Recepit Amount</th>
                  <th scope="col">Total </th>
                  <th scope="col">Balance </th>

                </tr>
              </thead>
              <tbody>
                <tr>


                </tr>

              </tbody>
            </table>

          </div>
        </Modal.Body>
      </Modal>
    </div>
  );

};

export default PartyLedgerDetail;
