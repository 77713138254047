import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IInwardRegister {
    warehouseId?: number = 0;
    fromDate?: string = getCurrentMonthFirstDate()?.firstDate;
    toDate?: string = getCurrentMonthFirstDate()?.currentDate;
}



