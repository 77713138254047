import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { excelKeyupdate, IExcelSheetData, IIExceluploadModal, ISupplierMasterSearchoptions } from './model';
import moment from "moment";
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import Cookies from 'js-cookie';
import { convertExcelToArrayOfObjects } from '../../common/shared/convertExcToarrObj/covertExctoArrObj';

export interface ExcelSheetData {
  Supplier_id?: number;
  Supplier_name?: string;
  Supplier_type?: string;
  Supplier_group?: string
  Credit_days?: number;
  GST_IN?: number;
  FSSAI_no?: string | number;
  Mobile_no?: number;
  Phone_no?: number;
  Address?: string;
}
interface ExcelObject {
  [key: string]: any;
}
const SupplierMasterList = observer((): JSX.Element => {

  const [supplierMasterSearchOptions, setSupplierMasterSearchOptions] = useState<ISupplierMasterSearchoptions>({})
  const { supplierMasterStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { importExportFormSave } = supplierMasterStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isDeleteSuccess, setDeleteSuccess] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [isExcel, setExcel] = useState(false)
  const [data, setData] = useState<ExcelObject[]>([]);

  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  const [canPrint, setCanPrint] = useState(false);  
  const [canUpdate, setCanUpdate] = useState(false); 
  const [canDelete, setCanDelete] = useState(false); 

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const result = await convertExcelToArrayOfObjects(file);
        setData(result);
      } catch (error) {
        console.error('Error reading Excel file:', error);
      }
    }
  };

  const handleCreate = () => {
    navigate('/Admin/SupplierMaster');
  }

  // const handleChangeclear = () => {
  //   setLoading(true);
  //   fetchPreLoadngData();
  // }
  const editSupplierDetials = (supplierId) => {
    navigate('/Admin/SupplierMaster/' + supplierId);
  }
  const deleteSupplierDetials = async (supplierId) => {
    setLoading(true);
    const status = await supplierMasterStore.deleteSupplier(supplierId);
    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setDeleteSuccess(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setDeleteSuccess(false);
    fetchPreLoadngData();
  }

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setSupplierMasterSearchOptions({ ...supplierMasterSearchOptions, [name]: value });

  // }
  // const handleSearchInput = async (e) => {
  //   const { name, value } = e.target;
  //   setSupplierMasterSearchOptions({ ...supplierMasterSearchOptions, [name]: value });

  // }

  const handleSearchSupplier = async (e) => {
    setLoading(true);
    await supplierMasterStore.getSupplierSearch(supplierMasterSearchOptions);
    setLoading(false);
  }

  function excelNewSheet() {
    let excelArr = new Array<IExcelSheetData>();
    let intitalObj = new IExcelSheetData();
    excelArr.push(intitalObj)
    const csvContent = [Object.keys(excelArr[0]).join(',')].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Supplier Master ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function createExportExcelObj(supplierDetails: any) {
    let excelArr: ExcelSheetData[] = supplierDetails.supplierMasterList.slice().sort((a, b) => b.Supid - a.Supid).map((supplier) => {
      let address = (supplier?.address.replaceAll(',', ' ') + " " + supplier.pinCode)
      const excelObj: ExcelSheetData = {
        Supplier_id: supplier?.Supid,
        Supplier_name: supplier?.SupplierName,
        Supplier_type: supplier?.SupplierType,
        Supplier_group: supplier?.SupplierGroup,
        Credit_days: supplier?.creditdays,
        GST_IN: supplier?.gstIn,
        FSSAI_no: supplier?.FSSAINo,
        Mobile_no: supplier?.mobileNo,
        Phone_no: supplier?.phoneNo,
        Address: address

      }
      return excelObj;
    })
    exportToExcel(excelArr)
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Supplier Detail", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    let today = moment();
    link.download = `Supplier Detail ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleUploadExcel() {
    let excleMaster: IIExceluploadModal = {};
    let updatedArr = excelKeyupdate(data);
    excleMaster.impsno = 1;
    excleMaster.supplierMaster_import = updatedArr;
    const status = await importExportFormSave(excleMaster)
    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  async function fetchPreLoadngData() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Supplier_master'), supplierMasterStore.getSupplierMaster()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const isCurrentPage = useRef(true)

  // useEffect(() => {
  //   if (isCurrentPage?.current) {
  //     fetchPreLoadngData();
  //     isCurrentPage.current = false;
  //   }
  //   return () => { }
  // }, []);

  const empid = Number(Cookies.get('userId'));      
  const formName = 'Supplier_master';

      useEffect(() => {   
          const fetchRights = async () => {     
            try {          
                  const data = await supplierMasterStore.fetchRights(empid,formName);
                  if (!data || data.length === 0) {              
                    navigate('/Admin/Warehouse/SupplierMaster/Denied');
                  }else{
                  if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts; 
                    const rightsValue = data[0].rights;                     
                    const lettersArray  = rightsValue.split('');
                    if (specificFieldValue === 1) {  
                      if (isCurrentPage.current) {
                        fetchPreLoadngData();
                        isCurrentPage.current = false    
                        if (lettersArray.includes('a')) {
                          setCanAdd(true);  
                        }                
                        if (lettersArray.includes('e')) {
                          setCanEdit(true); 
                        }
                        if (lettersArray.includes('v')) {
                          setCanView(true); 
                        }     
                        if (lettersArray.includes('p')) {
                          setCanPrint(true); 
                        }       
                        if (lettersArray.includes('u')) {
                          setCanUpdate(true); 
                        }    
                        if (lettersArray.includes('d')) {
                          setCanDelete(true); 
                        }                    
                      }         
                  }else if(specificFieldValue === 0){            
                    // navigate('/Admin/Warehouse/SupplierMaster/Denied');  
                  }     
                }
              }
            } catch (error) {
              console.error("Error fetching rights:", error);
          }
        };
          fetchRights(); 
        }, [empid, formName]);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (supplierMasterStore?.supplierMasterList && supplierMasterStore?.supplierMasterList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(supplierMasterStore?.supplierMasterList)
    }
  }, [supplierMasterStore?.supplierMasterList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(supplierMasterStore?.supplierMasterList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>

              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={supplierMasterStore?.supplierMasterList}
                searchTitles={[{ key: "SupplierName", value: "Supplier Name" }, { key: "gstIn", value: "GSTIN" }, { key: "mobileNo", value: "MobileNo" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} buttonChildren={<>
                  <button className='searchclearBtn' onClick={() => { createExportExcelObj(supplierMasterStore) }}>Excel</button>
                  <button className='searchclearBtn' onClick={excelNewSheet}>Export</button>
                  <button className='searchclearBtn' onClick={() => setExcel(true)}>Import</button>
                </>} />
              <div className='inputBoxLists'>
                <div className='vertical-space-20'></div>
                <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className='btnBox'>
                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{supplierMasterStore?.supplierMasterList?.length} Records</button>
                  </div>
                  <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                  </Stack>
                </div>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Supplier ID</th>
                      <th scope="col">Supplier Name</th>
                      <th scope="col">Supplier Type</th>
                      <th scope="col">Supplier Group</th>
                      <th scope='col'>Credit days</th>
                      <th scope="col">GSTIN</th>
                      <th scope="col">FSSAI No</th>
                      <th scope="col">Mobile No</th>
                      <th scope="col">Phone No</th>
                      <th scope="col">Address</th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {((isSearch ? filteredData : currentPageData)?.length > 0) &&
                      ((isSearch ? filteredData : currentPageData)?.slice().sort((a, b) => b.Supid - a.Supid))?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val?.Supid}</td>
                            <td>{val?.SupplierName}</td>
                            <td>{val?.SupplierType}</td>
                            <td>{val?.SupplierGroup}</td>
                            <td>{val.creditdays}</td>
                            <td>{val.gstIn}</td>
                            <td>{val?.FSSAINo}</td>
                            <td>{val.mobileNo}</td>
                            <td>{val.phoneNo}</td>
                            <td>{val.address + '-' + val.pincode}</td>
                            <td><button disabled={!canAdd} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} 
                             title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'} /></button> </td>
                            <td><button disabled={!canEdit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editSupplierDetials(val.Supid)} 
                              title={!canEdit ? 'You do not have permission to Edit.' : 'Click to Edit'}/></button></td>
                            <td><button disabled={!canDelete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteSupplierDetials(val.Supid)} 
                            title={!canDelete ? 'You do not have permission to Delete.' : 'Click to Delete'}  /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>

              <Modal show={isExcel} onHide={() => { setExcel(false); setData([]) }} className='PriceHistoryModel'>
                <Modal.Header closeButton>
                  <Modal.Title>Excel Import </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='imgBox'>
                    <div className='col-sm-8'>
                      <div className='inputBox'>
                        <label>Upload Excel <span>*</span></label>
                        <input type="file" accept=".xlsx, .xls, .csv" onChange={handleFileChange} />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleUploadExcel}>
                    Upload
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isDeleteSuccess} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>
                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isDeleteSuccess} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </>

  )
})

export default SupplierMasterList;