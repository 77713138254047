import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import useStores from '../../hooks';
import { Button, Image, Modal } from 'react-bootstrap';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate } from 'react-router-dom';
import { IAssignBatchOutletSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';

const AssignBatchOutletDetail = observer((): JSX.Element => {
  const [assignBatchSearchOptions, setAssignBatchSearchOptions] = useState<IAssignBatchOutletSearchoptions>({})
  const { assignBatchOutletStore } = useStores();
  const [isLoading, setLoading] = useState(true);
  const [isAssignBatch, setAssignBatchModal] = useState(false);
  const navigate = useNavigate();

  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const deleteAssignbatchMaster = async (assignDetilsNo) => {
    setLoading(true);
    const status = await assignBatchOutletStore.deleteAssignbatchOutletMaster(assignDetilsNo);

    if (status === 'Success') {
      fetchAssignBatch();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteAssignbatchetail = async (assignDetilsNo) => {
    setAssignBatchModal(false)
    setLoading(true);
    const status = await assignBatchOutletStore.deleteAssignbatchOutletDetail(assignDetilsNo);

    if (status === 'Success') {
      fetchAssignBatch();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchAssignBatch();
  }

  async function fetchAssignBatch() {
    await assignBatchOutletStore.getAssignbatchOutlet();
    setLoading(false);
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchAssignBatch();      
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const editAssignBatch = (assignNo) => {

  }

  async function handleAssignDetails(assignNo, e) {
    e.preventDefault();

    await assignBatchOutletStore.getAssignbatchOutletDetails(assignNo);
    setAssignBatchModal(true);
  }
  const handleClose = () => {
    setAssignBatchModal(false)
  }
  const handleCreate = () => {
    navigate('/Admin/AssignBatchOutlet');
  }
  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :null}

         
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>

              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={assignBatchOutletStore.getAssignbatchsOutlet}
                searchTitles={[{ key: "SupplierName", value: "Supplier Name" }, { key: "EmployeeName", value: "Employee Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />

              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>Entry No </th>
                      <th scope='col'>Date  </th>
                      <th scope='col'>Supplier </th>
                      <th scope='col'>Created by </th>
                      <th></th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (isSearch ? filteredData : assignBatchOutletStore.getAssignbatchsOutlet).length>0 &&
                      (isSearch ? filteredData : assignBatchOutletStore.getAssignbatchsOutlet).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.assignNo} </td>
                            <td>{moment(val.assignDate).format('DD-MMM-YYYY')}</td>
                            <td>{val.supplierName} </td>
                            <td>{val.employeeName}</td>
                            <td><button className='changeBtn' onClick={(e) => handleAssignDetails(val.assignNo, e)}>Assign Batch Details</button></td>
                            <td><button className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button className='Edit' ><FontAwesomeIcon icon={faPenToSquare} onClick={() => editAssignBatch(val.assignNo)} /></button></td>
                            <td><button className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignbatchMaster(val.assignNo)} /></button></td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
              {

                isAssignBatch ?
                  <Modal show={isAssignBatch} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Assign Batch Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>

                              <th scope="col">InwardId</th>
                              <th scope="col">InwardDate</th>
                              <th scope="col">Item Name</th>
                              <th scope="col">Batch Qty</th>
                              <th scope="col">Batch No</th>
                              <th scope="col">Mfg Date</th>
                              <th scope="col">Exp Date</th>
                              {/* <th scope='col'>Delete </th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {assignBatchOutletStore.getAssignbatchOutletDetls.length>0 &&
                              assignBatchOutletStore.getAssignbatchOutletDetls.map((val, key) => {
                                return (
                                  <tr key={key}>

                                    <td>{val.inwardId} </td>
                                    <td>{moment(val.inwardDate).format('DD-MMM-YYYY')}</td>
                                    <td>{val.itemName} </td>
                                    <td>{val.batchqty}</td>
                                    <td><QRCode value={val.batchNo} /></td>
                                    <td>{moment(val.mfg_Date).format('DD-MMM-YYYY')}</td>
                                    <td>{moment(val.exp_Date).format('DD-MMM-YYYY')}</td>
                                    {/* <td><button className='delete' ><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteAssignbatchetail(val.assignDetilsNo)} /></button></td> */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          
    </>
  );

});

export default AssignBatchOutletDetail;
