import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import moment from "moment";
import { IIOfferLetter } from "./model";
import AutoComplete from "../../common/shared/autoComplete";
import { saveValidation } from "./validation";
import { removeDuplicates } from "../../common/shared/utils";

const OfferLetterGeneration = () => {

    const { jobMasterStore, commonStore, interviewStore, payrollStore, departmentStore, designationStore } = useStores();

    const { loadInterviewLetter, loadSelectionApproval } = interviewStore;
    const { loadEmployee, loadEmployees } = commonStore;

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [jobMaster, setJobMaster] = useState<any[]>([])
    const [categoryList, setCategorylist] = useState<any[]>([])
    const [interviewLetterList, setInterviewLetterList] = useState<any[]>([])
    const [selectionApprovalList, setSelectionApprovalList] = useState<any[]>([])

    const [offerLetterFields, setOfferLetterFields] = useState<IIOfferLetter>(new IIOfferLetter())

    console.log(offerLetterFields, 'offerLetterFields>>')
    // console.log(offerLetterFields, selectionApprovalList, jobMaster, 'selectionApprovalList>>', interviewLetterList)

    const [onOpenDetail, setOnOpenDetail] = useState(false)

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/OfferLetterGeneration/List');
    }

    function handleOfferLetterClose() {
        setOfferLetterFields(new IIOfferLetter())
    }

    function handleInputchange(event) {
        const { name, value } = event.target;
        if (name === 'offerBaseSal' || name === 'offerHRA' || name === 'offerConveyance') {
            setOfferLetterFields({
                ...offerLetterFields, [name]: Number(value)
            })
        } else {
            setOfferLetterFields({
                ...offerLetterFields, [name]: value
            })
        }
    }
    async function handleSaveCategory() {
        let error: any = {}
        error = saveValidation(offerLetterFields)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            setLoading(true)
            const entryNo = await jobMasterStore.getHREntryNo('OfferLetter');
            offerLetterFields.offerId = Number(entryNo?.entrynumber);
            let offerLetterList: any[] = [];
            offerLetterList?.push(offerLetterFields);
            const status = await interviewStore?.saveOfferLetter(offerLetterList)
            if (status === 'Success') {
                setLoading(false);
                setOfferLetterFields(new IIOfferLetter())
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleClickCallLetter(each) {
        setOnOpenDetail(true)
        let findCand = interviewLetterList?.find((obj) => obj?.Interviewdid === each?.Callletterno)
        console.log(findCand, 'findCand>>')
        setOfferLetterFields({ ...offerLetterFields, offerJobCode: (each?.Jobcode)?.toString(), offerIntrCode: (each?.Callletterno)?.toString(), offerCandidate: findCand?.Candidatename })
    }


    async function additionApicals() {
        const categoryMasterDetails = await payrollStore.loadCatergoryMaster()
        setCategorylist([...categoryMasterDetails])
        await departmentStore.getDepartment();
        await designationStore.getDesignation();
        await loadEmployee()
    }

    const fetchOutletItemData = async () => {
        const entryNo = await jobMasterStore.getHREntryNo('OfferLetter');
        setOfferLetterFields({ ...offerLetterFields, offerId: Number(entryNo?.entrynumber) })
        const selectionApprovalList = await loadSelectionApproval();
        setSelectionApprovalList([...selectionApprovalList])
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        const interviewletter = await loadInterviewLetter()
        setInterviewLetterList([...interviewletter])
        additionApicals()
        setLoading(false);
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletItemData();
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Offer Letter Generation</h3>
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope='col'>Approval Id</th>
                                            <th scope='col'>Process Id </th>
                                            <th scope='col'>Date  </th>
                                            <th scope='col'>Candidate Name</th>
                                            <th scope='col'>Edu. Qualification</th>
                                            <th scope='col'>Job Code</th>
                                            <th scope='col'>Job Title</th>
                                            <th scope='col'>Job Description</th>
                                            <th scope='col'>Feedback</th>
                                            <th scope='col'>Conductby  </th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Offer Letter</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectionApprovalList?.map((val, index) => {
                                            return val?.Intselectionappid > 0 && (
                                                <tr>
                                                    <td>{val.Intselectionappid}</td>
                                                    <td>{val.Intprocessid}</td>
                                                    <td>{moment(val?.Interviewdate)?.format('DD-MMM-YYYY')}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === val?.Callletterno)?.Candidatename}</td>
                                                    <td >{interviewLetterList?.find((obj) => obj?.Interviewdid === val?.Callletterno)?.EducationQualification}</td>
                                                    <td>{val?.Jobcode}</td>
                                                    <td>{jobMaster?.find((each) => Number(each?.Jobcode) === Number(val?.Jobcode))?.Jobtitle}</td>
                                                    <td style={{ maxWidth: '300px' }}>{jobMaster?.find((each) => Number(each?.Jobcode) === Number(val?.Jobcode))?.JobDescription}</td>
                                                    <td>{val.Feedback}</td>
                                                    <td>{val.ApprovedBy}</td>
                                                    <td>Email</td>
                                                    <td>Mobile</td>
                                                    <td><button className='' onClick={() => handleClickCallLetter(val)}>Offer Letter</button></td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                            </div>
                            <Modal show={onOpenDetail} onHide={() => { setOnOpenDetail(!onOpenDetail); handleOfferLetterClose() }} className='PriceHistoryModel flexImp'>
                                <Modal.Header closeButton>
                                    <Modal.Title>OFFER LETTER</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="outletInputField inputFormBox" style={{ width: '1024px' }}>
                                        <div className="inputBoxLists">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <label style={{ fontSize: '20px' }}>Candidate Details</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Offer Letter No</label>
                                                        <input type="text"
                                                            name='brandId' disabled
                                                            value={offerLetterFields?.offerId}
                                                            style={{ width: "100%" }}
                                                            className='brandinput' ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="inputBox">
                                                        <label style={{ fontSize: '16px' }}>Date</label>
                                                        <input
                                                            type="date"
                                                            className="inputBoxDate"
                                                            style={{ width: "100%", fontWeight: "lighter" }}
                                                            placeholder="DD/MMM/YYYY"
                                                            data-date=""
                                                            data-date-format="DD MMMM YYYY"
                                                            value={offerLetterFields?.offDate}
                                                            onChange={handleInputchange}
                                                            name="offDate"
                                                        ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}> Job Code<span>*</span> </label>
                                                        <input type="text"
                                                            value={offerLetterFields?.offerJobCode}
                                                            // onChange={handleInputchange}
                                                            name="offerJobCode"
                                                            style={{ width: "100%" }}
                                                            className='brandinput' ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Job Title</label>
                                                        <input type="text"
                                                            value={offerLetterFields.offerJobTitle = jobMaster?.find((each) => each?.Jobcode == offerLetterFields?.offerJobCode)?.Jobtitle}
                                                            // onChange={handleInputchange}
                                                            name="offerJobTitle"
                                                            style={{ width: "100%" }}
                                                            className='brandinput'  ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}> Description</label>
                                                        <input type="text"
                                                            value={offerLetterFields.offerDesc = jobMaster?.find((each) => each?.Jobcode == offerLetterFields?.offerJobCode)?.JobDescription}
                                                            name="offerDesc"
                                                            style={{ width: "100%" }}
                                                            className='brandinput'  ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}> Interview Code</label>
                                                        <input type="text"
                                                            value={offerLetterFields?.offerIntrCode}
                                                            // onChange={handleInputchange}
                                                            name="offerIntrCode"
                                                            style={{ width: "100%" }}
                                                            className='brandinput'  ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Candidate</label>
                                                        <input type="text"
                                                            value={offerLetterFields?.offerCandidate}
                                                            // onChange={handleInputchange}
                                                            name="offerCandidate"
                                                            style={{ width: "100%" }}
                                                            className='brandinput'  ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}>Category<span>*</span> </label>
                                                        <select className="form-selected full-width" style={{ fontSize: "16px" }}
                                                            name="offerCatId" value={offerLetterFields?.offerCatId}
                                                            onChange={(e) => { setOfferLetterFields({ ...offerLetterFields, offerCatId: Number(e.target.value) }); }}
                                                        >
                                                            <option value="" selected> - - - Select - - - </option>
                                                            {categoryList?.map((each) => {
                                                                return (
                                                                    <option value={each?.Categoryid}>{each?.Emp_Category_Name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    {errors.offerCatId && <p style={{ color: "red" }}>{errors.offerCatId}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}>Designation <span>*</span></label>
                                                        <AutoComplete value={offerLetterFields?.designation} clsName="full-width" options={designationStore.designationList} getOptionLabel="designationName" placeholder="Select Designation"
                                                            emitOption={(val) => { setOfferLetterFields({ ...offerLetterFields, offerDesignId: val?.designationId, designation: val?.designationName }) }} />
                                                    </div>
                                                    {errors.offerDesignId && (<p style={{ color: "red" }}>{errors.offerDesignId}</p>)}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Job Location <span>*</span></label>
                                                        <input type="text"
                                                            placeholder="Enter Job Location"
                                                            value={offerLetterFields?.offerJobLoc}
                                                            onChange={handleInputchange}
                                                            name="offerJobLoc"
                                                            style={{ width: "100%" }}
                                                            className='brandinput'  ></input>
                                                    </div>
                                                    {errors.offerJobLoc && <p style={{ color: "red" }}>{errors.offerJobLoc}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Report Date</label>
                                                        <input type="date"
                                                            className="inputBoxDate"
                                                            style={{ width: "100%", fontWeight: "lighter" }}
                                                            placeholder="DD/MMM/YYYY"
                                                            data-date="" data-date-format="DD MMMM YYYY"
                                                            value={offerLetterFields?.offerRepDate}
                                                            onChange={handleInputchange}
                                                            name="offerRepDate" ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Base Salary (₹) <span>*</span> </label>
                                                        <input type="text"
                                                            className="full-width"
                                                            placeholder="Enter Base Salary"
                                                            value={offerLetterFields?.offerBaseSal}
                                                            onChange={handleInputchange}
                                                            name="offerBaseSal" ></input>
                                                    </div>
                                                    {errors.offerBaseSal && <p style={{ color: "red" }}>{errors.offerBaseSal}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>HRA (₹) <span>*</span> </label>
                                                        <input type="text"
                                                            className="full-width"
                                                            placeholder="Enter HRA"
                                                            value={offerLetterFields?.offerHRA}
                                                            onChange={handleInputchange}
                                                            name="offerHRA" ></input>
                                                    </div>
                                                    {errors.offerHRA && <p style={{ color: "red" }}>{errors.offerHRA}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Conveyance (₹) <span>*</span> </label>
                                                        <input type="text" placeholder="Enter Conveyance"
                                                            className="full-width"
                                                            value={offerLetterFields?.offerConveyance}
                                                            onChange={handleInputchange}
                                                            name="offerConveyance" ></input>
                                                    </div>
                                                    {errors.offerConveyance && <p style={{ color: "red" }}>{errors.offerConveyance}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className='inputBox'>
                                                        <label style={{ fontSize: '16px' }}>Description</label>
                                                        <input type="text"
                                                            placeholder="Enter Description"
                                                            value={offerLetterFields?.offerDescr}
                                                            onChange={handleInputchange}
                                                            name="offerDescr" ></input>
                                                    </div>
                                                    <div className="vertical-space-10"></div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <label style={{ fontSize: '16px' }}>Mode</label>
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox"
                                                                    checked={offerLetterFields?.offerMode === 'Email'}
                                                                    onChange={(evt) => setOfferLetterFields({ ...offerLetterFields, offerMode: evt?.target?.checked ? 'Email' : '' })}
                                                                />
                                                                <span className="flex align-items-center"> {"Email"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox"
                                                                    checked={offerLetterFields?.offerMode === 'SMS'}
                                                                    onChange={(evt) => setOfferLetterFields({ ...offerLetterFields, offerMode: evt?.target?.checked ? 'SMS' : '' })}
                                                                />
                                                                <span className="flex align-items-center"> {"SMS"}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="inputBox align-items-center gap-2" style={{ display: 'flex' }}>
                                                                <input type="checkbox"
                                                                    checked={offerLetterFields?.offerMode === 'Courier'}
                                                                    onChange={(evt) => setOfferLetterFields({ ...offerLetterFields, offerMode: evt?.target?.checked ? 'Courier' : '' })}
                                                                />
                                                                <span className="flex align-items-center"> {"Courier"}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.offerMode && <p style={{ color: "red" }}>{errors.offerMode}</p>}
                                                </div>
                                                {loadEmployees?.length > 0 ? < div className="col-sm-4">
                                                    <div className="inputBox" >
                                                        <label style={{ fontSize: '16px' }}> Generatd By<span>*</span> </label>
                                                        <AutoComplete options={removeDuplicates(loadEmployees, 'employeeName')} placeholder="Generated By..." clsName="full-width"
                                                            value={loadEmployees?.find((each) => each?.employeeId == offerLetterFields?.offerEmpId)?.employeeName ?? ''}
                                                            emitOption={(opiton) => setOfferLetterFields({ ...offerLetterFields, offerEmpId: opiton?.employeeId })}
                                                            getOptionLabel="employeeName" />
                                                    </div>
                                                    {errors.offerEmpId && <p style={{ color: "red" }}>{errors.offerEmpId}</p>}
                                                    <div className="vertical-space-10"></div>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className='btnBox'>
                                        <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                                    </div>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div >
    )
}

export default OfferLetterGeneration;