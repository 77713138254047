
function OutletStockSummaryValidation(stockSummary) {
    let errors: any = {};

    if (!stockSummary.fromDate) {
        errors.fromDate = "Enter From Date";
    }
    if (!stockSummary.toDate) {
        errors.toDate = "Enter To Date";
    }
    if (!stockSummary.outletId) {
        errors.outletId = "Select Outlet";
    }
    return errors;
};

export default OutletStockSummaryValidation;