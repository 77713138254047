import React, { useState, useEffect, useRef, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import { Modal, Image, Button, Spinner, Form } from 'react-bootstrap';
import useStores from '../../hooks';
import { Autocomplete, TextField } from '@mui/material';
import { ILedgerMaster } from '../LedgerMaster/model';
import ledgerMasterValidation from '../LedgerMaster/validation';
import { ICreditAmount, IDebitAmount, IJournalCredit, IJournalDebit, IJournalSave, ILedgerRequest, IReceipt } from '../JournalEntry/modal';
import Cookies from 'js-cookie';
import AutoComplete from '../../common/shared/autoComplete';
import _ from 'lodash';
import { CreditObjValidation, DebitObjValidation } from '../JournalEntry/validation';

const CreditNote = observer((): JSX.Element => {
    const navigate = useNavigate();
    const { itemMasterStore, ledgerGroupStore, commonStore, ledgerMasterStore, receiptStore } = useStores();

    const [journalSave, setJournalSave] = useState<IJournalSave>(new IJournalSave());
    const [journalCredit, setJournalCredit] = useState<IJournalCredit>(new IJournalCredit());
    const [journalDebit, setJournalDebit] = useState<IJournalDebit>(new IJournalDebit());

    const [byPaymentLedger, setByPayment] = useState<any[]>([])
    const [toPaymentLedger, setToPayment] = useState<any[]>([])

    const [isLoading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isLedgerMaster, setLedgerMaster] = useState(false);
    const [ledgerMasters, setLedgerMasters] = useState<ILedgerMaster>({});
    const [receipt, setReceipt] = useState<IReceipt>({});
    const [creditAmount, setCreditAmount] = useState<ICreditAmount[]>([]);
    const [debitAmount, setDebitAmount] = useState<IDebitAmount[]>([]);
    const [isCredit, setCredit] = useState(false);
    const [isDebit, setDebit] = useState(false);

    const handleCancel = () => navigate("/Admin/CreditNote/CreditNoteDetail")

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => navigate('/Admin/CreditNote/CreditNoteDetail');

    function handleJournalChange(e) {
        const { name, value } = e.target;
        setJournalSave({ ...journalSave, [name]: value });
    }

    function handleCreditObj(e) {
        const { name, value } = e.target;
        setJournalCredit({ ...journalCredit, [name]: name == 'creditAmount' ? Number(value) : value })
    }

    function hanldeDebitObj(e) {
        const { name, value } = e.target;
        setJournalDebit({ ...journalDebit, [name]: name === "debitAmount" ? Number(value) : value })
    }

    async function updateObj(options, type) {
        if (type == 'debit') {
            let debObj: any = Object.assign({}, journalCredit);
            debObj = {
                debitAccountId: options?.ledgerAccountId,
                debitAccountName: options?.ledgerAccountName,
            }
            setJournalDebit(debObj)
        } else if (type == 'credit') {
            let credObj: any = Object.assign({}, journalDebit);
            credObj = {
                creditAccountId: options?.ledgerAccountId,
                creditAccountName: options?.ledgerAccountName,
            }
            setJournalCredit(credObj)
        }
    }

    function handleRemove(index, type) {
        if (type === 'debit') {
            journalSave?.journalDebit?.splice(index, 1)
            setJournalSave(_.cloneDeep(journalSave))
        } else {
            journalSave?.journalCredit?.splice(index, 1)
            setJournalSave(_.cloneDeep(journalSave))
        }
    }

    function pushObjtoArr(type) {
        if (type == 'debit') {
            let error: any = {};
            error = DebitObjValidation(journalDebit!)
            setErrors(error)
            if (Object.keys(error).length == 0) {
                let objArr = Object.assign([], journalSave?.journalDebit)
                objArr?.push(journalDebit!)
                if (!objArr?.length) {
                    objArr = []
                }
                if (!journalSave?.journalDebit?.length) {
                    journalSave!.journalDebit = []
                }
                journalSave!.journalDebit = objArr
                setJournalSave(journalSave)
                setJournalDebit({
                    ...journalDebit, debitAmount: 0,
                    debitNarration: '',
                    debitAccountId: 0,
                    debitAccountName: '',
                    dbtype: ''
                })
                setErrors({})
            }
        } else {
            let error: any = {};
            error = CreditObjValidation(journalCredit!)
            setErrors(error)
            if (Object.keys(error).length == 0) {
                let objArr = Object.assign([], journalSave?.journalCredit);
                objArr?.push(journalCredit!)
                if (!journalSave?.journalCredit) {
                    journalSave!.journalCredit = []
                }
                journalSave!.journalCredit! = objArr;
                setJournalSave(journalSave)
                setJournalCredit({
                    ...journalCredit, creditAccountName: '',
                    creditAmount: 0,
                    chequeNo: '',
                    creditDate: '',
                    creditBankName: '',
                    creditNarration: '',
                    creditAccountId: 0,
                    cbtype: ''
                })
                setErrors({})
            }

        }
    }

    const handleSaveReceipt = async (e) => {

        let error: any = {};
        e.preventDefault();
        // error = receiptValidation(receipt);
        setErrors(error);
        if (Object.keys(error).length === 0) {

            let debitAmounts = Number(journalSave!.journalDebit!.reduce((a, v) => a = a + Number(v.debitAmount!), 0).toFixed(2));
            let creditAmounts = Number(journalSave!.journalCredit!.reduce((a, v) => a = a + Number(v.creditAmount!), 0).toFixed(2));
            if (debitAmounts !== creditAmounts) {
                error.amountMismatch = 'Debit and Credit amount does not match';
                setErrors(error);
            } else {

                journalSave.employeeId = Number(Cookies.get('employeeId'))
                journalSave.journaltId = 0;
                setLoading(true);
                // const status = "Success"
                const status = await receiptStore.saveJournal(journalSave);
                if (status === 'Success') {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setReceipt({});
                    setLoading(false);
                    setFailureModal(true);
                }
            }
        }

    }

    const handleLegerMaster = (e) => {
        const { name, value } = e.target;
        setLedgerMasters({ ...ledgerMasters, [name]: value })
    }

    const handleReceiptLedgerGroup = (name, event, val) => {
        if (val != null) {
            setLedgerMasters({ ...ledgerMasters, ledgerGroupId: val.groupId, ledgerGroup: val.ledgerGroupName });
        }
    }

    const handleLedgerMaster = async () => {
        setLoading(true);
        await commonStore.loadLedgerGroup();
        await ledgerGroupStore.getEntryNo('LedgerAccount');
        setLoading(false);
        setLedgerMaster(true);

    }

    const handleSaveLedgerMaster = async () => {
        setLedgerMaster(false);

        let error: any = {};
        error = ledgerMasterValidation(ledgerMasters);
        setErrors(error)
        if (Object.keys(error).length === 0) {
            setLoading(true);
            ledgerMasters.openingBalance = Number(ledgerMasters.openingBalance);
            ledgerMasters.percentage = Number(ledgerMasters.percentage);
            const ledgerStatus = await ledgerMasterStore.saveLedgerMaster(ledgerMasters);
            if (ledgerStatus === "Success") {
                await itemMasterStore.getTaxMaster('');
                setLoading(false);
                setLedgerMaster(false);
                //setSuccessModal(true);
            }
            else {
                setLoading(false);
                setLedgerMaster(true);
                //setFailureModal(true);
            }
        }
    }

    async function fetchPreLoadingData() {
        let ledgerRequest: ILedgerRequest = {}
        await ledgerGroupStore.getEntryNo('Journal');
        ledgerRequest.flag = 'TO';
        ledgerRequest.type = 'Receipt'
        const toLedgers = await receiptStore.getToLedgers(ledgerRequest);
        setToPayment(toLedgers)
        ledgerRequest.flag = 'BY';
        ledgerRequest.type = 'Receipt'
        const byLedgers = await receiptStore.getByLedgers(ledgerRequest);
        setByPayment(byLedgers)
        setLoading(false);
    }

    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            fetchPreLoadingData();
            isInitialRender.current = false;
        } else {
        }
        return () => {
            // Cleanup logic (if needed)
        };
    }, [])

    return (
        <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField'>
                <div className='hrBox'>
                    <h3>Credit Note</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                    {/* <div className='LgInputListsBox mr-left-0'> */}
                    <div className='row'>
                        {isLoading &&
                            <div className='SpinnerBox'>
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>}
                        <div className='col-md-6'>
                            <div className='inputBox'>
                                <label>Journal No</label>
                                <input type="text" className='' name='journalNo'
                                    disabled value={journalSave.journalNo = (ledgerGroupStore.entryNo.entryNo + 1)?.toString()}
                                    style={{ width: "100%" }} placeholder='Journal No..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-4'>
                            <div className='inputBox'>
                                <label>Date <span>*</span></label>
                                <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                    onChange={handleJournalChange}
                                    value={journalSave.journalDate = journalSave.journalDate ?? new Date().toISOString().substr(0, 10)} name='journalDate'
                                    data-date="" data-date-format="DD MMMM YYYY"></input>
                                {errors.journalDate && <p style={{ color: 'red' }}>{errors.journalDate}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-6'>
                            <div className='inputBox'>
                                <label>Reference No<span>*</span> </label>
                                <input type="text" className='' style={{ width: "100%" }}
                                    onChange={handleJournalChange}
                                    value={journalSave.referenceNo} name='referenceNo'
                                    placeholder='Reference No..'></input>
                                {errors.referenceNo && <p style={{ color: 'red' }}>{errors.referenceNo}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-6'></div>
                        <div className='col-md-9'>
                            <div className='inputBox'>
                                <label>To Account <span>*</span></label>
                                <AutoComplete options={toPaymentLedger} placeholder={'To Account...'}
                                    emitOption={(option: any) => updateObj(option, 'credit')}
                                    getOptionLabel='ledgerAccountName' />
                                {errors.toAccountId && <p style={{ color: 'red' }}>{errors.toAccountId}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-2'>
                            <button className='newdfBtn' onClick={handleLedgerMaster}>New</button>
                        </div>
                        <div className='vertical-space-60'></div>
                        <div className='col-md-7'>
                            <div className='inputBox'>
                                <label>Cr Amount(<span>&#8377;</span>) <span>*</span></label>
                                <input type="text" className='' style={{ width: "100%" }}
                                    onChange={handleCreditObj}
                                    value={journalCredit.creditAmount} name='creditAmount'
                                    placeholder='Cr Amount..'></input>
                                {errors.creditAmount && <p style={{ color: 'red' }}>{errors.creditAmount}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-5'></div>
                        <div className='col-md-6'>
                            <div className='inputBox'>
                                <label>Cheque No </label>
                                <input type="text" className='' style={{ width: "100%" }}
                                    onChange={handleCreditObj}
                                    value={journalCredit.chequeNo} name='chequeNo'
                                    placeholder='Cheque No..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-4'>
                            <div className='inputBox'>
                                <label>Date </label>
                                <input type="date" style={{ width: "100%" }} placeholder="DD-MMM-YYYY"
                                    onChange={handleCreditObj}
                                    value={journalCredit.creditDate = journalCredit.creditDate ?? new Date().toISOString().substr(0, 10)} name='creditDate'
                                    data-date="" data-date-format="DD MMMM YYYY"></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-12'>
                            <div className='inputBox'>
                                <label>Bank Name</label>
                                <input type="text" className='' style={{ width: "100%" }}
                                    onChange={handleCreditObj}
                                    value={journalCredit.creditBankName} name='creditBankName'
                                    placeholder='Bank Name..'></input>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-9'>
                            <div className='inputBox'>
                                <label>Narration</label>
                                <textarea className='' style={{ width: "100%" }}
                                    onChange={handleCreditObj}
                                    value={journalCredit.creditNarration} name='creditNarration'
                                    placeholder='Narration..'
                                ></textarea>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-2'>
                            <button className='dfBtn' onClick={() => pushObjtoArr('credit')}>Add</button>
                        </div>
                        <div className='col-md-12'>
                            <div className='tableBox'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Cr Account</th>
                                            <th scope="col">Cr Amount</th>
                                            <th scope="col">Cheque No </th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Bank Name</th>
                                            <th scope="col">Narration</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {journalSave?.journalCredit?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val.creditAccountName}</td>
                                                    <td>{val.creditAmount}</td>
                                                    <td>{val.chequeNo}</td>
                                                    <td>{val.creditDate}</td>
                                                    <td>{val.creditBankName}</td>
                                                    <td>{val.creditNarration}</td>
                                                    <td><button style={{
                                                        border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                                        borderRadius: '6px', fontSize: '12px'
                                                    }} type='submit'
                                                        onClick={() => handleRemove(key, 'credit')}>Remove</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='vertical-space-60'></div>
                        </div>
                        <div className='col-md-9'>
                            <div className='inputBox'>
                                <label>By Account<span>*</span></label>
                                <AutoComplete options={byPaymentLedger} placeholder={'By Account...'}
                                    emitOption={(option: any) => updateObj(option, 'debit')} getOptionLabel='ledgerAccountName' />
                                {errors.debitAccountId && <p style={{ color: 'red' }}>{errors.debitAccountId}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-2'>
                            <button className='newdfBtn' onClick={handleLedgerMaster}>New</button>
                        </div>
                        <div className='col-md-6'>
                            <div className='inputBox'>
                                <label>Dr Amount(<span>&#8377;</span>)<span>*</span></label>
                                <input type="text" className='' style={{ width: "100%" }}
                                    onChange={hanldeDebitObj}
                                    value={journalDebit.debitAmount} name='debitAmount'
                                    placeholder='Dr Amount..'></input>
                                {errors.debitAmount && <p style={{ color: 'red' }}>{errors.debitAmount}</p>}
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-md-9'>
                            <div className='inputBox'>
                                <label>Narration</label>
                                <textarea className='' style={{ width: "100%" }} placeholder='Narration..'
                                    onChange={hanldeDebitObj}
                                    value={journalDebit.debitNarration} name='debitNarration'
                                ></textarea>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                        <div className='col-sm-2'>
                            <button className='dfBtn' onClick={() => pushObjtoArr('debit')}>Add</button>
                        </div>
                        <div className='col-md-12'>
                            <div className='tableBox'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">By Account</th>
                                            <th scope="col">Dr Amount </th>
                                            <th scope="col">Narration</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {journalSave?.journalDebit?.map((val, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{val.debitAccountName}</td>
                                                    <td>{val.debitAmount}</td>
                                                    <td>{val.debitNarration}</td>
                                                    <td><button style={{
                                                        border: '2px solid #dc3545', background: '#dc3545', color: 'white', fontWeight: '400',
                                                        borderRadius: '6px', fontSize: '12px'
                                                    }} type='submit'
                                                        onClick={() => handleRemove(key, 'debit')}>Remove</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='vertical-space-10'></div>
                        </div>
                    </div>
                    {/* </div> */}
                    <div className='vertical-space-10'></div>
                </div>
                {errors.amountMismatch && <p style={{ color: 'rd' }}>{errors.amountMismatch}</p>}
                <div className='btnBox'>
                    <button className='secondaryBtn' onClick={handleCancel}>List</button>
                    <button className='dfBtn' type='submit' onClick={handleSaveReceipt}>Submit</button>
                </div>
            </div>
            {
                isLedgerMaster ?
                    <Modal
                        show={isLedgerMaster} onHide={() => setLedgerMaster(false)}
                        className=''>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Account</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='col-sm-12'>
                                <div className=' inputFormBox'>
                                    <div className='inputBoxLists' >
                                        <div className='row'>
                                            <div className='col-md-8'>
                                                <div className='inputBox'>
                                                    <div className='col-md-8'>
                                                        <div className='inputBox'>
                                                            <label>Ledger ID </label>
                                                            <input type="text" className='' style={{ width: "100%" }} name='ledgerId'
                                                                value={ledgerMasters.ledgerId = ledgerGroupStore.entryNo.entryNo + 1} disabled
                                                                placeholder='Ledger ID..'></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='inputBox'>
                                                            <label>Ledger Name <span>*</span></label>
                                                            <input type="text" className=''
                                                                name="ledgerName"
                                                                onChange={handleLegerMaster}
                                                                value={ledgerMasters.ledgerName}
                                                                style={{ width: "100%" }} placeholder='Ledger Name..'></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='inputBox'>
                                                            <label>Ledger Group <span>*</span></label>
                                                            <Autocomplete size="small"
                                                                disablePortal
                                                                id="combo-box-demo"
                                                                options={commonStore.loadLedgerGroups}
                                                                getOptionLabel={(option: any) => option.ledgerGroupName}
                                                                onChange={(event, val) => handleReceiptLedgerGroup('ledgerGroupId', event, val)}
                                                                renderInput={(params: any) =>
                                                                    <TextField  {...params} style={{ width: '36ch' }}
                                                                        id="outlined-size-small"
                                                                        color='info'
                                                                        size="small"
                                                                        type="text"
                                                                        placeholder='Select Ledger..'
                                                                        name='ledgerGroupId'
                                                                    />}
                                                            />
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='inputBox'>
                                                            <label> Opening Balance <span>*</span></label>
                                                            <input type="text" className=''
                                                                name="openingBalance"
                                                                onChange={handleLegerMaster}
                                                                value={ledgerMasters.openingBalance}
                                                                style={{ width: "100%" }} placeholder='Opening Balance..'></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='inputBox'>
                                                            <label>Opening type <span>*</span></label>
                                                            <select className="form-selected"
                                                                name="type"
                                                                onChange={handleLegerMaster}
                                                                value={ledgerMasters.type}
                                                                style={{ width: "100%" }} id="Openingtype" >
                                                                <option value="" selected>Select</option>
                                                                <option value="Credit"> Credit</option>
                                                                <option value="Debit">Debit </option>
                                                            </select>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='inputBox'>
                                                            <label>Percentage <span>*</span></label>
                                                            <input type="text"
                                                                name="percentage"
                                                                onChange={handleLegerMaster}
                                                                value={ledgerMasters.percentage}
                                                                style={{ width: "100%" }} placeholder="Ledger Percentage.." ></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='inputBox'>
                                                            <label>Date <span>*</span></label>
                                                            <input type="date"
                                                                name="entryDate"
                                                                onChange={handleLegerMaster}
                                                                value={ledgerMasters.entryDate = ledgerMasters.entryDate ?? new Date().toISOString().substr(0, 10)}
                                                                style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                                        </div>
                                                        <div className='vertical-space-10'></div>
                                                    </div>
                                                    <div className='vertical-space-30'></div>
                                                    <div className='col-sm-6 carddetailbtn2'>
                                                        <button className='usethisbtn btn-warning' onClick={handleSaveLedgerMaster}>Submit </button>
                                                        <button className='secondaryBtn' onClick={() => setLedgerMaster(false)}>Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-6'>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal> : null
            }
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>
                        <div className='imgBox'>
                            <Image src={require('../../../../image/checked.png')} />
                        </div>

                        <h4>Succesfully Submitted</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                    <div className='Details Success'>

                        <div className='imgBox'>
                            <Image src={require('../../../../image/warning.png')} />
                        </div>
                        <h4>Failed</h4>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                        Ok
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );

});

export default CreditNote;
