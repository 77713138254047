export class IPurchaseReturnSave {
    rtnid?: string;
    rtndate?: string;
    supplierid?: number;
    rtnAmount?: number;
    dtype?: string;
    dnoteno?: number;
    createdby?: number;
    purchaseReturndetails?: IPurchseReturnDetails[]
}

export class IPurchseReturnDetails {
    itemid?: string;
    barcode?: string;
    batchno?: string;
    qty?: number;
    rate?: number;
    amount?: number;
    entryno?: number;
}