import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react"
import { Button, Image, Modal, Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import AutoComplete from "../../common/shared/autoComplete";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const AssignNotificationRolls = () => {

    const { supplierMasterStore, userCreationStore, outwardtoOutletStore, employeeStore } = useStores();
    const navigate = useNavigate()
    const { getUserCreation, loadForms, alertNotifications } = userCreationStore;

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [loadFormsList, setLoadFormsList] = useState<any[]>([])
    const [alertObj, setAlertObj] = useState({ formname: '', aleartMessage: '', employeename: '', userid: 0, aleartid: 0 })
    const [entryNos, setEntryNos] = useState(0)
    const [alertArr, setAlertArr] = useState<any[]>([]);
    const [isLoadModal, setLoadModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canView, setCanView] = useState(false);
    // const [canPrint, setCanPrint] = useState(false);  
    // const [canUpdate, setCanUpdate] = useState(false); 

    function handleAdd() {
        let error: any = {};
        if (alertArr?.length) {
            if (!alertArr?.some((each) => (each?.aleartMessage === alertObj?.aleartMessage) && (each?.employeename === alertObj?.employeename))) {
                if (alertObj?.aleartMessage !== '' && alertObj?.formname && alertObj?.employeename) {
                    alertObj['aleartid'] = entryNos;
                    setAlertArr([...alertArr, alertObj])
                    setAlertObj({ formname: '', aleartMessage: '', employeename: '', userid: 0, aleartid: 0 })
                } else {
                    if (!alertObj?.formname) {
                        error.formname = "Select Form name.."
                    }
                    if (!alertObj?.aleartMessage) {
                        error.aleartMessage = "Enter Notification Subject"
                    }
                    if (!alertObj?.employeename) {
                        error.employeename = "Select Alert Users.."
                    }
                }
            } else {
                error.duplicate = "Can't Allow Duplicate Datas"
            }
        } else {
            if (alertObj?.aleartMessage !== '' && alertObj?.formname && alertObj?.employeename) {
                alertObj['aleartid'] = entryNos;
                setAlertArr([...alertArr, alertObj])
                setAlertObj({ formname: '', aleartMessage: '', employeename: '', userid: 0, aleartid: 0 })
            } else {
                if (!alertObj?.formname) {
                    error.formname = "Select Form name.."
                }
                if (!alertObj?.aleartMessage) {
                    error.aleartMessage = "Enter Notification Subject"
                }
                if (!alertObj?.employeename) {
                    error.employeename = "Select Alert Users.."
                }
            }
        }
        setErrors(error)
    }

    function hanldeCloseModal() {
        setSuccessModal(false);
        navigate('/Admin/NotificationRolls/List')
    }
    function handleInputChange(e) {
        const { name, value } = e.target;
        setAlertObj({ ...alertObj, [name]: value })
    }
    const handleAccessModalClose = () => {
        navigate('/Admin/Home');
    }


    const handleNotify = (name: any, val: any) => {
        if (name === 'userId') {
            if (val !== null) {
                setAlertObj({ ...alertObj, employeename: val.Employee_Name, userid: val.EmployeeId })
            } else {
                setAlertObj({ ...alertObj, employeename: '', userid: 0 })
            }
        } else if (name === "formname") {
            if (val != null) {
                setAlertObj({ ...alertObj, formname: val.formname })
            }
            else {
                setAlertObj({ ...alertObj, formname: '' })
            }
        }
    }

    async function saveAssignNotification() {
        setLoading(true)
        let saveArr = alertArr?.map((alert) => {
            let obj = {
                formname: alert?.formname,
                aleartMessage: alert?.aleartMessage,
                userid: alert?.userid
            }
            return obj
        })

        let body = {
            aleartid: entryNos,
            aleartMessage: alertObj?.aleartMessage,
            aleartnotificationmdetails: saveArr,
        }

        if (saveArr?.length > 0) {
            const status = await alertNotifications(body);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true)
                fetchUserCreationData()
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            setLoading(false)
        }
    }

    async function fetchUserCreationData() {
        const entryNo = await outwardtoOutletStore.getEntryNo('AleartMessage')
        setEntryNos(entryNo?.entryNo + 1)

        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])

        const loadFormsList = await loadForms();
        let updatedList = loadFormsList?.map((each) => {
            if (each?.formname) {
                each.label = each?.formname?.split("_")?.join(" ")
                return each
            }
            return each
        })
        setLoadFormsList([...updatedList])
        setLoading(false);
    }

    // const isCurrenPage = useRef(true)
    // useEffect(() => {
    //     if (isCurrenPage.current) {
    //         fetchUserCreationData()
    //         isCurrenPage.current = false;
    //     }
    //     return () => { }
    // }, [])
    const isCurrenPage = useRef(true)
    const empid = Number(Cookies.get('userId'));
    const formName = 'Assign_notification_roles';

    useEffect(() => {

        const fetchRights = async () => {

            try {
                const data = await supplierMasterStore.fetchRights(empid, formName);

                if (Array.isArray(data) && data.length > 0) {
                    const specificFieldValue = data[0].sts;
                    const rightsValue = data[0].rights;
                    const lettersArray = rightsValue.split('');
                    if (specificFieldValue === 1) {
                        if (isCurrenPage.current) {
                            fetchUserCreationData()

                            isCurrenPage.current = false
                            if (lettersArray.includes('a')) {
                                setCanAdd(true);
                            } else {
                                navigate('/Admin/NotificationRolls/List');
                            }
                            if (lettersArray.includes('e')) {
                                setCanEdit(true);
                            }
                            // if (lettersArray.includes('v')) {
                            //   setCanView(true); 
                            // }     
                            // if (lettersArray.includes('p')) {
                            //   setCanPrint(true); 
                            // }       
                            // if (lettersArray.includes('u')) {
                            //   setCanUpdate(true); 
                            // }                     
                        }
                    } else if (specificFieldValue === 0) {
                        setLoadModal(true)
                    }
                } else {
                    setLoadModal(true)
                }

            } catch (error) {
                console.error("Error fetching rights:", error);
            }
        };
        fetchRights();
    }, [empid, formName]);



    if (isLoadModal) {
        return (
            <div className="container">
                {isLoadModal && <h1>Access Denied</h1>}
                <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
                    <Modal.Body>
                        <div className='Details Success'>
                            <div className='imgBox'>
                                <Image src={require('../../../gsmecom/images/warning.png')} />
                            </div>
                            <h4>Access Denied</h4>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
    else {
        return (
            <>
                
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className="flex justifyContentCenter alignItemCenter" style={{ width: '100%' }}>
                            <div className='inputFormBox box-shadow5' style={{ width: '90%', borderRadius: '12px' }}>
                                <div className='hrBox'>
                                    <h3>Assign Notification Roles</h3>
                                </div>

                                <div className='inputBoxLists'>
                                    <div className='ItemInwardInputBox'>
                                        <div className="row">
                                            <div className="col-sm-2"></div>
                                            <div className='col-md-2' style={{ marginTop: '18px' }}>
                                                <div className='inputBox'>
                                                    <label>Entry No <span>*</span></label>
                                                    <input type="text" className=''
                                                        name='mcode'
                                                        value={entryNos}
                                                        disabled
                                                        style={{ width: "100%" }} placeholder='mcode...'></input>
                                                    {errors.mcode && <p style={{ color: 'red' }}>{errors.mcode}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                                <div className="">Form name<span style={{ color: 'red', fontSize: '14px' }}> *</span></div>
                                                <div className='inputBox'>
                                                    <AutoComplete value={alertObj?.formname} clsName="full-width" getOptionLabel="label"
                                                        options={loadFormsList} emitOption={(opt) => handleNotify("formname", opt)} placeholder="Select Form Name.." />
                                                </div>
                                                {errors.formname && <p style={{ color: 'red' }}>{errors.formname}</p>}
                                            </div>
                                            <div className="col-sm-4" style={{ marginTop: '18px' }}>
                                                <div className="">Notification Subject<span style={{ color: 'red', fontSize: '14px' }}> *</span></div>
                                                <div className='inputBox'>
                                                    <input type="text" value={alertObj?.aleartMessage} onChange={handleInputChange} name="aleartMessage" placeholder="Enter Notification Subject..." className="full-width" />
                                                </div>
                                                {errors.aleartMessage && <p style={{ color: 'red' }}>{errors.aleartMessage}</p>}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4"></div>
                                            <div className="col-sm-2" style={{ marginTop: '18px' }}>
                                                <div className="">Alert to<span style={{ color: 'red', fontSize: '14px' }}> *</span></div>
                                                <div className='inputBox'>
                                                    <AutoComplete value={alertObj?.employeename} clsName="full-width" getOptionLabel="Employee_Name" options={employeeMaster}
                                                        emitOption={(opt) => handleNotify("userId", opt)} placeholder="Select Users" />

                                                </div>
                                                {errors.employeename && <p style={{ color: 'red' }}>{errors.employeename}</p>}
                                            </div>
                                            <div className='col-sm-2' style={{ marginTop: '14px' }}>
                                                <button className='dfBtn' onClick={handleAdd}>Add</button>
                                            </div>
                                        </div>

                                        <div className="vertical-space-20"></div>
                                        <div className="vertical-space-20"></div>
                                        <div className="tableListDetails">
                                            <div className='tableBox'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Notification Subject</th>
                                                            <th scope="col">Alert User</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {alertArr?.length ?
                                                            alertArr?.map((alerts, index) => {
                                                                console.log(alertArr, 'alertArr>>')
                                                                return (
                                                                    <tr>
                                                                        <td>{alerts?.aleartMessage}</td>
                                                                        <td>{alerts?.employeename}</td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            }) :
                                                            <div>No data</div>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {errors.duplicate && <p style={{ color: 'red' }}>{errors.duplicate}</p>}
                                        </div>
                                        <div className='btnBox'>
                                            <button className='secondaryBtn'
                                                onClick={hanldeCloseModal}
                                            >List</button>
                                            <button className='dfBtn' type='submit'
                                                onClick={saveAssignNotification}
                                            >Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal show={isSuccessModal} onHide={hanldeCloseModal} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={hanldeCloseModal}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setFailureModal(false)}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                    </>
                
            </>
        )
    }
};

export default AssignNotificationRolls;