import { getCurrentMonthFirstDate } from "../../../common/shared/utils";

export class IStockAnalysis {
    fromDate: string = getCurrentMonthFirstDate()?.firstDate
    toDate: string = getCurrentMonthFirstDate()?.currentDate
    itemId?: number = 0;
    category?: string = "";
    subCategoryId?: number = 0;
    wareHouseId?: number = 0;
}
