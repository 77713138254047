import { IIInterveiwMaster, IIInterviewMasterDetails } from "../model";

export function addValidation(interviewMaster: IIInterveiwMaster) {
    let error: any = {}

    if (!interviewMaster.jobcode) {
        error.jobcode = 'Select Job Code'
    }
    if (!interviewMaster.phases) {
        error.phases = 'Select Round'
    }
    if (!interviewMaster.process) {
        error.process = 'Enter Process'
    }
    if (!interviewMaster.duration) {
        error.duration = 'Enter Duration'
    }
    return error;
}

export function saveValidation(interviewMasterList: IIInterviewMasterDetails[]) {
    let error: any = {}

    if (!interviewMasterList?.length) {
        error.intermasterList = "Add Interview to Grid"
    }
    return error;
}