import React, { useState, useEffect, useMemo, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IAssignBatchPacking, AssignBatchPackingDetails } from './model';
import { Autocomplete, TextField } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import assignBatchPackingValidation from './validation';
import moment from 'moment';
import { removeDuplicates } from '../../common/shared/utils';

const AssignBatchPacking = observer((): JSX.Element => {
  const { commonStore, assignBatchPackingStore, brandStore } = useStores();
  const [assignBatchPacking, setAssignBatchPacking] = useState<IAssignBatchPacking>({});
  const [assignBatchPackingDetail, setAssignBatchPackingDetail] = useState<AssignBatchPackingDetails>({});
  const [assignBatchPackingDetails, setAssignBatchPackingDetails] = useState<AssignBatchPackingDetails[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [itemFilter, setItemFilter] = useState<any[]>([])

  const handleCancel = () => {
    navigate('/Admin/AssignBatchPacking/AssignBatchPackingDetail');
  }

  const { loadTeamDetail, loadTeamDetails, isloadTeamDetail,
    loadBatchPackingDetail, loadBatchPackingDetails, isloadBatchPackingDetail,
    loadEmployee, loadEmployees, isloadEmployeeLoading, loadWarehouse, loadWarehouses } = commonStore;

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    if (name === 'mfg_Date' && assignBatchPacking.expiryDays !== 0) {
      let expiryDate = new Date(value);
      const exp = expiryDate.setDate(expiryDate.getDate() + assignBatchPacking.expiryDays!)
      assignBatchPacking.expiry_date = moment(exp).format('YYYY-MM-DD');


    };
    setAssignBatchPacking({ ...assignBatchPacking, [name]: value })
  }

  const addAssignBatchPacking = async () => {

    let newObject: AssignBatchPackingDetails = {};
    let error: any = {};
    error = assignBatchPackingValidation(assignBatchPacking);
    setErrors(error);
    if (Object.keys(error).length === 0) {

      assignBatchPackingDetail.assignBatchpackingInwardid = assignBatchPacking.packingInwardid
      assignBatchPackingDetail.assignBatchpackingPackingInwardDate = assignBatchPacking.packingAssignBatchdate
      assignBatchPackingDetail.assignBatchpackingInwardItemid = assignBatchPacking.packingInwardItemid
      assignBatchPackingDetail.assignBatchpackingItemname = assignBatchPacking.itemname
      assignBatchPackingDetail.assignBatchpackingQty = assignBatchPacking.qty
      assignBatchPackingDetail.assignBatchpackingExpiry_date = assignBatchPacking.expiry_date
      assignBatchPackingDetail.assignBatchpackingMfg_Date = assignBatchPacking.mfg_Date

      const runningN0 = assignBatchPackingDetails.length;
      assignBatchPackingDetail.assignBatchpackingBatchNo = assignBatchPacking.packingTeamid!.toString()
        + assignBatchPackingDetail.assignBatchpackingInwardid?.toString()
        + assignBatchPackingDetail.assignBatchpackingPackingInwardDate?.replace(/[: -]/g, '') +
        assignBatchPackingDetail.assignBatchpackingInwardItemid?.toString() +
        assignBatchPackingDetail.assignBatchpackingMfg_Date?.replace(/[: -]/g, '') +
        assignBatchPackingDetail.assignBatchpackingExpiry_date?.replace(/[: -]/g, '');

      //assignBatchPackingDetail.assignBatchpackingQty = Number(assignBatchPackingDetail.assignBatchpackingQty);
      //assignBatchPackingDetail.assignBatchpackingGetqty = Number(assignBatchPackingDetail.assignBatchpackingExistingQty) - Number(assignBatchPackingDetail.assignBatchpackingGetqty);
      setSubmitbtn(true);
      setAssignBatchPackingDetails([...assignBatchPackingDetails, assignBatchPackingDetail]);
      setAssignBatchPackingDetail(newObject);
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignBatchPacking({ ...assignBatchPacking, [name]: val.employeeId });
    }
    else {
      assignBatchPacking.createdBy = 0;
    }
  }
  const handleWarehouse = (name: any, event: object, val: any) => {

    if (val != null) {

      setAssignBatchPacking({ ...assignBatchPacking, [name]: val.warehouseId, ['wareHouse']: val.warehouseName });
    }
    else {
      assignBatchPacking.wareHouse = '';
    }
  }

  const handleTeamValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setAssignBatchPacking({ ...assignBatchPacking, [name]: val.teamMasterId, "teamName": val.teamMasterName });
    }
    else {
      assignBatchPacking.teamName = '';
    }
  }

  function hanldeFilterItems(name: any, val: any) {
    let itemFilters = loadBatchPackingDetails?.filter((obj) => obj?.packingInwardid === val?.packingInwardid)
    setItemFilter(itemFilters)
  }

  const handlePackingValue = (name: any, val: any) => {

    if (val != null) {
      setAssignBatchPacking({
        ...assignBatchPacking, [name]: val.packingInwardid, packingInwardDate: val.packingInwardDate,
        packingInwardItemid: val.packingInwardItemid, itemname: val.itemname, qty: val.packingQty, expiryDays: val.expiryDays
      });
    }
    else {
      assignBatchPacking.packingInwardItemid = 0;
    }
  }


  async function saveAssignBatchPacking(e) {

    setLoading(true);
    const entryNos = await brandStore.getEntryNo('AssignBatchPacking');
    assignBatchPacking.packingAssignBatchid = entryNos.entryNo + 1;

    assignBatchPacking.assignBatchPackingDetails = assignBatchPackingDetails;
    assignBatchPacking.packingTeamid = Number(assignBatchPacking.packingTeamid);
    const status = await assignBatchPackingStore.saveAssignBatchPacking(assignBatchPacking);

    if (status === "Success") {
      setLoading(false)
      setSuccessModal(true)
    }
    else {
      setLoading(false)
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/AssignBatchPacking/AssignBatchPackingDetail');
  }



  async function fetchAssignDefaultLoad() {
    await brandStore.getEntryNo('AssignBatchPacking')
    await loadTeamDetail();
    await loadEmployee();
    await loadBatchPackingDetail();
    await loadWarehouse();
    setLoading(false)
  }

  useEffect(() => {
    fetchAssignDefaultLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const uniqueOptions = useMemo(() => {
    if (loadBatchPackingDetails?.length) {
      return loadBatchPackingDetails?.filter((option, index, self) => index === self.findIndex((t) => t.packingInwardid === option.packingInwardid));
    }
  }, [loadBatchPackingDetails])

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <div className='container'>
            <div className='vertical-space-20'></div>
            <div className='outletInputField inputFormBox LgInputField'>
              <div className='hrBox'>
                <h3>Assign Batch Packing</h3>
              </div>
              <div className='inputBoxLists'>
                <div className='LgInputListsBox mr-left-0'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Entry No <span>*</span></label>
                        <input type="text" className=''
                          name='packingAssignBatchid' disabled
                          value={assignBatchPacking.packingAssignBatchid = brandStore.entryNo.entryNo + 1}
                          onChange={handleChangeInput}
                          style={{ width: "100%" }} placeholder='Entry No..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Date <span>*</span></label>
                        <input type="date"
                          name='packingAssignBatchdate'
                          value={assignBatchPacking.packingAssignBatchdate}
                          onChange={handleChangeInput}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.packingAssignBatchdate && <p style={{ color: 'red' }}>{errors.packingAssignBatchdate}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-12'>
                      <div className='inputBox'>
                        <label>Created By<span>*</span> </label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={removeDuplicates(loadEmployees, 'employeeName')}
                          getOptionLabel={(option: any) => option.employeeName}
                          onChange={(event, val) => handleEmployeeValue('createdBy', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '40ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Created by..'
                              name='createdBy'
                            />}
                        />
                        {errors.createdBy && <p style={{ color: 'red' }}>{errors.createdBy}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-12'>
                      <div className='inputBox'>
                        <label> Team Name <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={loadTeamDetails}
                          getOptionLabel={(option: any) => option.teamMasterName}
                          onChange={(event, val) => handleTeamValue('packingTeamid', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '40ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Team Name..'
                              name='teamName'
                            />}
                        />
                        {errors.teamName && <p style={{ color: 'red' }}>{errors.teamName}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-sm-12'>
                      <div className='inputBox'>
                        <label>Warehouse <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={loadWarehouses}
                          // value={warehouseName}
                          getOptionLabel={(option: any) => option.warehouseName}
                          onChange={(event, val) => handleWarehouse('warehouseId', event, val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '40ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Select Warehouse..'
                              name='wareHouse'
                            />}
                        />
                        {errors.wareHouse && <p style={{ color: 'red' }}>{errors.wareHouse}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Packing Inward No <span>*</span></label>
                        <Autocomplete size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={uniqueOptions}
                          getOptionLabel={(option: any) => option.packingInwardid}
                          onChange={(event, val) => hanldeFilterItems('packingInwardid', val)}
                          renderInput={(params: any) =>
                            <TextField  {...params} style={{ width: '25ch' }}
                              id="outlined-size-small"
                              color='info'
                              size="small"
                              type="text"
                              placeholder='Packing Inward No..'
                              name='packingInwardid'
                            />}
                        />
                        {errors.packingInwardid && <p style={{ color: 'red' }}>{errors.packingInwardid}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-6'>
                      <div className='inputBox'>
                        <label>Packing Inward Date <span>*</span></label>
                        <input
                          value={assignBatchPacking.packingInwardDate} name='packingInwardDate'
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-12'>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Item name</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {itemFilter?.map((items) => {
                              return (
                                <tr>
                                  <td><input type='radio' className='' value='onaccount' name='option' onChange={() => handlePackingValue('packingInwardid', items)}></input></td>
                                  <td>{items?.itemname}</td>
                                  <td></td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className='vertical-space-10'></div>

                    </div>
                    <div className='col-md-2'>
                      <div className='inputBox'>
                        <label>Qty </label>
                        <input type="text"
                          value={assignBatchPacking.qty} name='qty'
                          className='fullInput' style={{ width: "100%" }} placeholder='Qty..'></input>
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    <div className='col-md-4'>
                      <div className='inputBox'>
                        <label>Mfg Date <span>*</span></label>
                        <input type="date"
                          name='mfg_Date' value={assignBatchPacking.mfg_Date = assignBatchPacking.mfg_Date ?? new Date().toISOString().substr(0, 10)}
                          onChange={handleChangeInput}
                          style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                        {errors.mfg_Date && <p style={{ color: 'red' }}>{errors.mfg_Date}</p>}
                      </div>
                      <div className='vertical-space-10'></div>
                    </div>
                    {
                      assignBatchPacking.expiryDays !== 0 ?
                        <div className='col-md-4'>
                          <div className='inputBox'>
                            <label>Expiry Date <span>*</span></label>
                            <input type="date"
                              name='expiry_date' value={assignBatchPacking.expiry_date}
                              disabled
                              style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                            {errors.expiry_date && <p style={{ color: 'red' }}>{errors.expiry_date}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div> : <div className='col-md-4'>
                          <div className='inputBox'>
                            <label>Expiry Date <span>*</span></label>
                            <input type="date"
                              name='expiry_date' value={assignBatchPacking.expiry_date}
                              onChange={handleChangeInput}
                              style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                            {errors.expiry_date && <p style={{ color: 'red' }}>{errors.expiry_date}</p>}
                          </div>
                          <div className='vertical-space-10'></div>
                        </div>
                    }

                    <div className='col-sm-2'>
                      <button className='dfBtn pd-0-12 full-width' type='submit' onClick={addAssignBatchPacking}>ADD</button>
                    </div>
                  </div>
                </div>
                <div className='vertical-space-10'></div>
                <div className='tableBox'>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Packing Inward No</th>
                        <th scope="col">Packing Inward Date</th>
                        <th scope="col">Item</th>
                        <th scope="col">Item ID</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Mfg Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">Batch No</th>
                      </tr>
                    </thead>
                    <tbody >
                      {
                        assignBatchPackingDetails.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td>{val.assignBatchpackingInwardid}</td>
                              <td>{val.assignBatchpackingPackingInwardDate}</td>
                              <td>{val.assignBatchpackingItemname}</td>
                              <td>{val.assignBatchpackingInwardItemid}</td>
                              <td>{val.assignBatchpackingQty}</td>
                              <td>{val.assignBatchpackingMfg_Date}</td>
                              <td>{val.assignBatchpackingExpiry_date}</td>
                              <td><QRCode value={val.assignBatchpackingBatchNo} size={100} /></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='btnBox'>

                <button className='secondaryBtn' onClick={handleCancel}>List</button>
                <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveAssignBatchPacking}>Submit</button>
              </div>
              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/checked.png')} />
                    </div>
                    <h4>Succesfully Submitted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../gsmecom/images/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

      }
    </>
  );

});

export default AssignBatchPacking;
