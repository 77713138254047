import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IIJobMaster, IIJobVacancy, IIVacancyApproval } from '../model';

class IJobMasterStore {

    constructor() {
        makeObservable(this);
    }

    getHREntryNo = async (flag) => {
        try {
            const entryNo = await apiAgent.jobmaster.hrEntryNo(flag);
            return entryNo;
        }
        catch (error) {
            console.log(error);
        }
    }

    saveJobMaster = async (jobMaster: IIJobMaster) => {
        try {
            const status = await apiAgent.jobmaster.saveJobMaster(jobMaster);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadJobMaster = async () => {
        try {
            const jobMaster = await apiAgent.jobmaster.loadJobMaster();
            return JSON.parse(jobMaster);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveJobVacancy = async (jobvacancy: IIJobVacancy) => {
        try {
            const status = await apiAgent.jobmaster.saveJobVacancy(jobvacancy);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    loadJobVacancy = async () => {
        try {
            const loadData = await apiAgent.jobmaster.loadJobVacancy();
            return JSON.parse(loadData);
        }
        catch (error) {
            console.log(error);
        }
    }
    
    loadPendingVacanceyApp = async () => {
        try {
            const loadData = await apiAgent.jobmaster.loadPendingVacanceyApp();
            return JSON.parse(loadData);
        }
        catch (error) {
            console.log(error);
        }
    }

    saveVacancyApproval = async (vacancyApp: IIVacancyApproval) => {
        try {
            const status = await apiAgent.jobmaster.saveVacancyApproval(vacancyApp);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
}

export default IJobMasterStore;