import React, { useState, useEffect, useMemo, useRef, } from 'react';
import { Container, Image, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from "react-router-dom"
import useEcomStores from '../hooks/index';
import { ICartProduct } from './model';
import Cookies from 'js-cookie';
import Footer from '../Footer/Footer';
import HeaderComp from '../Header/newHeader';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import StarIcon from '@mui/icons-material/Star';
import useStores from '../../gsmadmin/hooks';
import { IPosBatchDetails } from '../../gsmadmin/Admin/POS/model';
import _ from 'lodash';
import ProductsByCategory from '../common/components/categories/productByCategory';
import { comparePosItemMasterArrays } from '../common/commonFunc';

const Product = observer((): JSX.Element => {
	const navigate = useNavigate();

	const { productStore } = useEcomStores();

	const { posMasterStore, itemMasterStore } = useStores();

	const [posProductList, setPosProductList] = useState<any[]>(posMasterStore?.loadPOSProducts)
	const [posItemMaster, setPosItemMaster] = useState<any[]>(posMasterStore?.posItemMaster)
	const [posItemOffers, setPosItemOffers] = useState<any[]>(posMasterStore?.getItemOffers)
	const [posFreeItems, setPosFreeItems] = useState<any[]>(posMasterStore?.getFreeItems)

	const [posItemDetils, setPOStItemDetails] = useState<any[]>([]);
	const [posBatchDetails, setPOSBatchDetails] = useState<any[]>(new Array<any>());

	const [assignedOffers, setAssignedOff] = useState<any[]>([])

	const [currentProdData, setCurrentProdData] = useState<any>({})

	const [isLoading, setLoading] = useState(true);

	const { productId } = useParams();


	const combinedItemData = useMemo(() => {
		if (itemMasterStore?.itemMasterList?.length) {
			let productLists: any[] = comparePosItemMasterArrays(posMasterStore?.loadPOSProducts, itemMasterStore?.itemMasterList)
			return productLists!;
		}
	}, [itemMasterStore?.itemMasterList, posMasterStore?.loadPOSProducts])

	function isEqual(currentItem, newItem) {
		return (
			currentItem?.posItemMRP == newItem?.posItemMRP &&
			currentItem?.posItemSellingRate == newItem?.posItemSellingRate
		)
	}

	function useCartQuantityUpdater(type: string, obj: any, qty?: number) {
		let existsItem = posItemDetils?.find((items) => isEqual(items, obj));
		if (existsItem && Object?.keys(existsItem!)?.length !== 0) {
			if (type == 'add') {
				existsItem!.posItemQty! += qty!;
			} else {
				existsItem!.posItemQty! -= qty!;
			}
		} else if (type == 'add') {
			obj.posItemQty = 0;
			obj.posItemQty! += qty!;
		} else {
			obj.posItemQty = 0
			obj.posItemQty! -= qty!;
		}
		addToCart(obj);
	}

	function addToCart(itemBatch: any) {
		let isexistsItem = posItemDetils?.some((items) => isEqual(items, itemBatch))
		let taxValue: number = 0;
		let discountValue: number = 0;
		let offerDiscountValue: number = 0;
		let totalPrice: number = 0;

		if (itemBatch?.posTaxPercentage) {
			taxValue = ((Number(itemBatch?.posItemSellingRate) - Number(itemBatch?.posItemSellingRate!)) / (1 + itemBatch?.posTaxPercentage! / 100))?.toFixed(2) as any;
		}

		posItemOffers?.map((offers) => {
			if (new Date() < new Date(offers?.effectivetoDate)) {
				if (offers?.AssinOfferType === "currentProdData" && offers?.itemId && itemBatch?.posItemId === offers?.itemId) {
					if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
						let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
						if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
							offerDiscountValue = offers.Discount_PV!;
							if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
								setAssignedOff([...assignedOffers, offers])
							}
						} else {
							offerDiscountValue = 0;
							let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
							if (index >= 0) {
								assignedOffers?.splice(index, 1)
								setAssignedOff([...assignedOffers])
							}
						}
					} else if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc %") {
						let sumTotalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any;
						if (sumTotalPrice >= offers.From_QV && sumTotalPrice <= offers.To_QV) {
							offerDiscountValue = sumTotalPrice * (itemBatch?.posDiscountPercentage! / 100)
						} else {
							offerDiscountValue = 0;
						}
					} else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
						let itemQty = itemBatch?.posItemQty!;
						if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
							offerDiscountValue = offers.Discount_PV!;
							if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
								setAssignedOff([...assignedOffers, offers])
							}
						} else {
							offerDiscountValue = 0;
							let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
							if (index >= 0) {
								assignedOffers?.splice(index, 1)
								setAssignedOff([...assignedOffers])
							}
						}
					} else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc %") {
						let itemQty = itemBatch?.posItemQty!;
						if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
							offerDiscountValue = itemQty * (itemBatch?.posDiscountPercentage! / 100)
						} else {
							offerDiscountValue = 0;
						}
					} else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Value") {

					} else if (offers?.offertype === "Coupon Discount" && offers?.Dis_Mode === "Disc %") {

					}
				}
				if (offers?.AssinOfferType === "category" && offers?.categoryId && itemBatch?.posSubCategoryId === offers?.categoryId) {
					if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Value") {
						let sumSellingRate = itemBatch?.posItemSellingRate! * itemBatch?.posItemQty!
						if (sumSellingRate >= offers.From_QV && sumSellingRate <= offers.To_QV) {
							offerDiscountValue = offers.Discount_PV!;
							if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
								setAssignedOff([...assignedOffers, offers])
							}
						} else {
							offerDiscountValue = 0;
							let index = assignedOffers?.findIndex((obj) => obj?.offerid === offers?.offerid)
							if (index >= 0) {
								assignedOffers?.splice(index, 1)
								setAssignedOff([...assignedOffers])
							}
						}
					} if (offers?.offertype === "Value Discount" && offers?.Dis_Mode === "Disc %") {

					} else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Value") {
						let itemQty = itemBatch?.posItemQty!;
						if (itemQty >= offers.From_QV && itemQty <= offers.To_QV) {
							offerDiscountValue = offers.Discount_PV!;
							if (offers?.offerid && !(assignedOffers?.some(offer => offer?.offerid === offers?.offerid))) {
								setAssignedOff([...assignedOffers, offers])
							}
						} else {
							offerDiscountValue = (itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!);
							setAssignedOff(assignedOffers?.filter((obj) => obj?.offerid != offers?.offerid))
						}
					} else if (offers?.offertype === "Qty Discount" && offers?.Dis_Mode === "Disc %") {

					}
				}
			}
		})

		discountValue = itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!
		itemBatch.posDiscountPercentage = (((itemBatch?.posItemMRP! - itemBatch?.posItemSellingRate!) / itemBatch?.posItemMRP!) * 100)?.toFixed(2) as any

		totalPrice = (Number(itemBatch?.posItemSellingRate!) * Number(itemBatch?.posItemQty!))?.toFixed(2) as any

		const netAmount = (Number(totalPrice) - offerDiscountValue)?.toFixed(2);

		itemBatch.posItemQty = itemBatch?.posItemQty;
		itemBatch.posItemMRP = Number(itemBatch?.posItemMRP);
		itemBatch.posDiscountPercentage = !isNaN(itemBatch.posDiscountPercentage!) ? Number(itemBatch?.posDiscountPercentage) : 0;
		itemBatch.posItemSellingRate = Number(itemBatch?.posItemSellingRate);
		itemBatch.posTaxValue = Number(taxValue);
		itemBatch.posDiscountValue = discountValue + offerDiscountValue;
		itemBatch.posTotalPrice = Number(totalPrice);
		itemBatch.posNetAmount = Number(netAmount);

		const freeItems = checkForFreeItem(itemBatch)

		if (!isexistsItem) {
			posItemDetils?.push(itemBatch);
			if (freeItems) {
				posItemDetils?.push(freeItems)
				let batchObj: IPosBatchDetails = {
					itemId: freeItems?.posFreeItemId,
					barcode: freeItems?.posBarcode?.toString(),
					batchNo: freeItems?.posbatchNo,
					batchQty: freeItems?.posItemQty
				}
				if (batchObj?.itemId) {
					updateBatchList(batchObj, isexistsItem, freeItems)
				}
			}
			setPOStItemDetails(_.cloneDeep(posItemDetils))
			let batchObj: IPosBatchDetails = {
				itemId: itemBatch?.posItemId,
				barcode: itemBatch?.posBarcode?.toString(),
				batchNo: itemBatch?.posbatchNo,
				batchQty: itemBatch?.posItemQty
			}
			updateBatchList(batchObj, isexistsItem)
		} else {
			setPOStItemDetails(posItemDetils.map((item, i) => {
				if (item.posItemId === itemBatch.posItemId && itemBatch?.posbatchNo === item?.posbatchNo) {
					return item = itemBatch;
				}
				return item;
			}));
			setPOStItemDetails(posItemDetils.map((item, i) => {
				if (!item?.posItemId && item?.posFreeItemId === freeItems?.posFreeItemId && item?.posbatchNo === freeItems?.posbatchNo) {
					return item = freeItems;
				}
				return item;
			}));
			let batchObj: IPosBatchDetails = {
				itemId: itemBatch?.posItemId,
				barcode: itemBatch?.posBarcode,
				batchNo: itemBatch?.posbatchNo,
				batchQty: itemBatch?.posItemQty
			}
			if (freeItems) {
				let batchObj: IPosBatchDetails = {
					itemId: freeItems?.posFreeItemId,
					barcode: freeItems?.posBarcode?.toString(),
					batchNo: freeItems?.posbatchNo,
					batchQty: freeItems?.posItemQty
				}
				if (batchObj?.itemId) {
					updateBatchList(batchObj, true, freeItems)
				}
			} else if (batchObj?.itemId) {
				updateBatchList(batchObj, true)
			}
		}
	}

	function checkForFreeItem(itemDetail: any) {
		let freeItems = posFreeItems?.filter((obj) => obj?.itemid === itemDetail?.posItemId);
		const freeItemRet = freeItems?.map((obj) => {
			let freeItem: any;
			if (new Date() < new Date(obj?.efftodate)) {
				if (obj?.isbatch === "Yes" && obj?.batch_no === itemDetail?.posbatchNo) {
					return freeItem = {
						posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
						posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
						posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
						posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
						posItemQty: itemDetail?.posItemQty! * obj?.freeqty,
						posTaxValue: 0,
						posDiscountValue: 0,
						posTotalPrice: 0,
						posNetAmount: 0,
						posTaxPercentage: 0,
						posDiscountPercentage: 0,
					}
				} else if (obj?.issales === "Yes" && obj?.saleqty) {
					const divisions = Math.floor(itemDetail?.posItemQty! / obj?.saleqty);
					return freeItem = {
						posFreeItemId: obj?.is_same_item ? obj?.itemid : obj?.otheritemid,
						posItemName: obj?.is_same_item ? obj?.itemname : obj?.otheritem,
						posbatchNo: obj?.is_same_item ? obj?.batch_no : obj?.otheritembatchno,
						posBarcode: obj?.is_same_item ? itemDetail?.posBarcode?.toString() : obj?.otheritembarcode,
						// posbatchNo: itemDetail?.posbatchNo,
						posItemQty: divisions * obj?.freeqty,
						posTaxValue: 0,
						posDiscountValue: 0,
						posTotalPrice: 0,
						posNetAmount: 0,
						posTaxPercentage: 0,
						posDiscountPercentage: 0
					}
				}
			}
		})

		return freeItemRet[0]!;
	}

	function updateBatchList(batchObj, isexistsItem, freeItems?: any, isRemove?: boolean) {
		if (!isexistsItem) {
			let batchItem: IPosBatchDetails = {
				itemId: freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId,
				barcode: freeItems?.posFreeItemId ? freeItems?.posBarcode?.toString() : batchObj?.barcode,
				batchNo: freeItems?.posFreeItemId ? freeItems?.posbatchNo : batchObj?.batchNo,
				batchQty: freeItems?.posFreeItemId ? freeItems?.posItemQty : batchObj?.batchQty
			}
			posBatchDetails?.push(batchItem);
			setPOSBatchDetails([...posBatchDetails])
		} else if (isRemove || (!posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) &&
			posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)))) {

			setPOSBatchDetails(posBatchDetails?.filter((batches) => (batches?.barcode != batchObj?.barcode && batches?.itemId != (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && batches?.batchNo != batchObj?.batchNo)))

		} else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
			setPOSBatchDetails(posBatchDetails?.map((obj) => {
				if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId) && obj?.batchNo === obj?.batchNo) {
					return obj = batchObj;
				}
				return obj
			}))
		} else if (posItemDetils?.some((obj) => obj?.posItemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) && !posBatchDetails?.some((obj) => obj?.itemId === (freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId))) {
			if ((freeItems?.posFreeItemId ? freeItems?.posFreeItemId : batchObj?.itemId)) {
				posBatchDetails?.push(batchObj)
				setPOSBatchDetails(posBatchDetails)
			}
		}
	}

	const addtoCartPorduct = async (e) => {
		let productCart: ICartProduct = {};
		let productCarts: ICartProduct[] = [];
		e.preventDefault();
		if (currentProdData && Object.keys(currentProdData).length != 0) {
			productCart.productId = currentProdData.posItemId;
			productCart.productQty = currentProdData.posItemQty;
			productCart.webName = "";
			productCart.barcode = currentProdData.posBarcode;
			productCart.batchNo = currentProdData.posbatchNo;

			if (Cookies.get('memberId') !== undefined) {
				productCart.memberId = Number(Cookies.get('memberId'));
				productCarts.push(productCart);
				const cartResponse = await productStore.saveProductCart(productCarts);
				if (cartResponse !== undefined && cartResponse.message === 'Success') {
					Cookies.set('cartCount', cartResponse.cartCount);
					navigate('/cartsview');
				}
			} else {
				let data: any = [];
				if (Cookies.get('ProductCarts') !== undefined) {
					data = JSON.parse(Cookies.get('ProductCarts'));
					data.push(productCart);
					Cookies.set('ProductCarts', JSON.stringify(data))
					Cookies.set('cartCount', data.length);
				} else {
					productCarts.push(productCart);
					Cookies.set('ProductCarts', JSON.stringify(productCarts));
					Cookies.set('cartCount', productCarts.length);
				}
				navigate('/cartsview');
			}
		}
	}

	async function fetchPOSProducts() {
		let outletId = Number(Cookies.get('outletId'));
		if (outletId) {
			await posMasterStore.loadPOSProductList(outletId);
		}
		await itemMasterStore.getItemMasters();
		setLoading(false);
	}

	React.useMemo(() => {
		let findProduct = combinedItemData?.find((obj) => obj?.posItemId == productId)
		setCurrentProdData({})
		setCurrentProdData(_.cloneDeep(findProduct))
	}, [productId, combinedItemData])

	const isCurrentPage = useRef(true);

	useEffect(() => {
		if (isCurrentPage.current) {
			fetchPOSProducts();
			isCurrentPage.current = false;
		}
		return () => { }
	}, []);

	function handleNavigate(itemId) {
		if (itemId) {
			navigate(`/products/${itemId}`)
		}
	}

	function moveToCart() {
		navigate('/cartsview');
	}

	return (
		<div className="App">
			<div className="" style={{ position: 'fixed', top: 0, right: 0, left: 0, zIndex: 1000 }}>
				<HeaderComp moveToCart={moveToCart} />
			</div>
			{isLoading ? <div className='SpinnerBox'>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</Spinner>
			</div> :
				<>
					<Container>
						<div style={{ display: 'flex', paddingTop: '16px', paddingBottom: '16px', marginTop: '182px' }}>
							<div className="">
								<div style={{ marginTop: '20px' }}>
									<div className="" style={{ border: '1px solid rgb(159, 32, 137)', borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
										<Image src={`data:image/png;base64,${currentProdData?.imageData}`} width={90} height={90} />
									</div>
								</div>
							</div>
							<div style={{ background: 'white', margin: '0 20px', width: '100%', borderRadius: '8px' }}>
								<div className="" style={{ border: '1px solid rgb(234, 234, 242)', height: '472px', borderRadius: '4px', position: 'relative' }}>
									<Image src={`data:image/png;base64,${currentProdData?.imageData}`} width={90} height={90} style={{ position: 'absolute', height: '100%', width: '100%', inset: '0px', objectFit: 'contain', color: 'transparent', }} />
								</div>
								<div className="" style={{ background: 'white', borderRadius: '8px', marginTop: '20px' }}>
									<div className="" style={{ padding: '12px 0', alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%', }}>
										<button style={{
											flex: '1 1 0%', marginRight: '10px', display: 'flex',
											alignItems: 'center', justifyContent: 'center', fontSize: '20px', fontFamily: 'monospace',
											cursor: 'pointer', padding: '12px', border: '1px solid rgb(234, 234, 242)',
											textAlign: 'center', borderRadius: '8px', background: '#2d2c2c', color: 'white', gap: '16px'
										}} onClick={addtoCartPorduct}>
											Add to Cart <FontAwesomeIcon icon={faCartShopping} />
										</button>
										<button style={{
											flex: '1 1 0%', display: 'flex',
											alignItems: 'center', justifyContent: 'center', fontSize: '22px', fontFamily: 'monospace',
											cursor: 'pointer', padding: '12px', border: '1px solid #2d2c2c',
											textAlign: 'center', borderRadius: '8px', background: 'white'
										}}>
											Buy Now
										</button>
									</div>
								</div>
								<div className="" style={{ background: 'white', borderRadius: '8px', marginTop: '20px' }}>
									<div className="" style={{ color: '#353543', fontSize: 'x-large', fontWeight: '800' }}>
										5 Similar Products
									</div>
									<div className="" style={{ marginTop: '20px', display: 'flex', }}>
										<div style={{}}>
											<div className="" style={{ border: '1px solid rgb(159, 32, 137)', borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
												<Image src={require("../../gsmecom/images/product.jpg")} width={90} height={90} />
											</div>
										</div>
										<div style={{ marginLeft: '20px' }}>
											<div className="" style={{ borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
												<Image src={require("../../gsmecom/images/product.jpg")} width={90} height={90} />
											</div>
										</div>
										<div style={{ marginLeft: '20px' }}>
											<div className="" style={{ borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
												<Image src={require("../../gsmecom/images/product.jpg")} width={90} height={90} />
											</div>
										</div>
										<div style={{ marginLeft: '20px' }}>
											<div className="" style={{ borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
												<Image src={require("../../gsmecom/images/product.jpg")} width={90} height={90} />
											</div>
										</div>
										<div style={{ marginLeft: '20px' }}>
											<div className="" style={{ borderRadius: '4px', objectFit: 'contain', padding: '0 4px' }}>
												<Image src={require("../../gsmecom/images/product.jpg")} width={90} height={90} />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="" style={{
								width: '100%',
							}}>
								<div className="" style={{
									padding: '20px 16px', marginBottom: '20px', border: '1px solid rgb(234, 234, 242)', background: 'white',
									borderRadius: '8px', display: 'flex', flexDirection: 'column',
									overflowWrap: 'break-word',
								}}>
									<div className="" style={{ color: '#8b8ba3', fontSize: '22px', fontWeight: 'bold' }}>
										{currentProdData?.posItemName}
									</div>
									<div className="" style={{ paddingTop: '16px', display: 'flex' }}>
										<div style={{ fontSize: '32px', fontWeight: 500, fontFamily: 'sans-serif', color: 'black', width: '100%' }}>&#8377;
											{currentProdData?.posItemSellingRate ?? 0} <span style={{ fontSize: '16px', fontWeight: 500, fontFamily: 'sans-serif', color: 'black' }}>Inc Tax</span></div>
										<div className="" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
											<div
												style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#dc3545', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
												// onClick={() => { currentProdData?.posItemQty! > 1 && useCartQuantityUpdater('minus', currentProdData, 1) }}
												onClick={() => {
													currentProdData?.posItemQty! > 1 &&
														setCurrentProdData({ ...currentProdData, posItemQty: (currentProdData?.posItemQty! - 1) })
												}}
											>
												-
											</div>
											<input type='text' className='full-height' placeholder='Enter Qty...' value={currentProdData?.posItemQty} style={{ fontSize: '16px', textAlign: 'center' }} />
											<div
												// onClick={() => { currentProdData?.posItemQty! < currentProdData?.availableQty! && useCartQuantityUpdater('add', currentProdData, 1) }}
												onClick={() => {
													currentProdData?.posItemQty < currentProdData?.availableQty &&
														setCurrentProdData({ ...currentProdData, posItemQty: (currentProdData?.posItemQty! + 1) })
												}}
												style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#0ac074', color: 'white', borderRadius: '3px', fontSize: '30px', padding: '8px 18px', cursor: 'pointer' }}
											>
												+
											</div>
										</div>
									</div>
									<div className="" style={{ marginTop: '12px' }}>
										<div className="CartLayout" style={{ display: 'flex' }}>
											<div style={{
												display: ' inline-flex',
												justifyContent: 'center',
												alignItems: 'center',
												borderRadius: '16px',
												fontSize: '20px',
												color: 'white',
												padding: '6px 12px',
												background: 'rgb(35, 187, 117)',
												fill: 'white',
											}}>
												3.7
												<StarIcon sx={{ display: 'inline-block', alignSelf: 'center', fontSize: '24px', paddingLeft: '4px' }} />
											</div>
											<div style={{ fontSize: '20px', color: 'rgb(139, 139, 163)', fontWeight: '600', display: 'flex', alignItems: 'end', marginLeft: '16px' }}>
												120382 Views
											</div>
										</div>
									</div>
									<div style={{ paddingTop: '16px', backgroundColor: 'white', display: 'flex', alignItems: 'center' }}>
										<div style={{ padding: '6px 12px', borderRadius: '48px', backgroundColor: '#F3F3F3', display: 'inline-flex', pointerEvents: 'none' }}>
											<div style={{ color: 'rgb(97, 97, 115)', fontSize: '18px', fontWeight: '600', textAlign: 'center' }}>
												Free Delivery
											</div>
										</div>
										{!currentProdData?.availableQty && <div className="" style={{ fontSize: '18px', color: 'maroon', opacity: '0.8', marginLeft: '12px' }}>
											Out of Stock*
										</div>}
									</div>
								</div>
								<div className="" style={{
									padding: '20px 16px', marginBottom: '20px', border: '1px solid rgb(234, 234, 242)', background: 'white',
									borderRadius: '8px', display: 'flex', flexDirection: 'column',
									overflowWrap: 'break-word',
								}}>
									<div className="" style={{ color: 'black', fontSize: '22px', fontWeight: 'bold', marginBottom: '16px' }}>
										Select Size
									</div>
									<div className="flex">
										{['S', 'M', 'L', 'XL', 'XXL']?.map((size, index) => {
											return (
												<div key={index} className="" style={{ fontSize: '22px', color: 'black', border: '1px solid #353543', width: 'fit-content', padding: '4px 28px', borderRadius: '40px', margin: '6px 12px 6px 0px', }}>
													{size}
												</div>
											)
										})}
									</div>
								</div>
								<div className="" style={{
									padding: '20px 16px', marginBottom: '20px', border: '1px solid rgb(234, 234, 242)', background: 'white',
									borderRadius: '8px', display: 'flex', flexDirection: 'column',
									overflowWrap: 'break-word',
								}}>
									<div className="" style={{ color: 'black', fontSize: '22px', fontWeight: 'bold', marginBottom: '16px' }}>
										Product Details
									</div>
									<div className="" style={{ display: 'inline', color: '#616173', fontSize: 'large' }}>
										<span className="">
											Name :
										</span>
										<span className="" style={{ marginLeft: '4px' }}>
											{currentProdData?.posItemName}
										</span>
									</div>
									<div className="" style={{ display: 'inline', color: '#616173', fontSize: 'large' }}>
										<span className="">
											Brand :
										</span>
										<span className="" style={{ marginLeft: '4px' }}>
											{currentProdData?.posItemName}
										</span>
									</div>
									<div className="" style={{ display: 'inline', color: '#616173', fontSize: 'large' }}>
										<span className="">
											Net Quantity :
										</span>
										<span className="" style={{ marginLeft: '4px' }}>
											{currentProdData?.availableQty ?? 0}
										</span>
									</div>
									<div className="" style={{ display: 'inline', color: '#616173', fontSize: 'large' }}>
										<span className="">
											Net Weight :
										</span>
										<span className="" style={{ marginLeft: '4px' }}>
											{currentProdData?.posItemName}
										</span>
									</div>
								</div>
							</div>
						</div>
					</Container>
				</>
			}

			{combinedItemData?.length ? <div style={{ marginBottom: '40px' }}>
				<ProductsByCategory categoryName='Related' combinedItemData={combinedItemData!} handleNavigate={handleNavigate} addToCart={addtoCartPorduct} />
			</div> : <></>}


			<Footer />
		</div>
	);

});

export default Product;
