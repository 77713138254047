import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import moment from "moment";

import * as XLSX from 'xlsx';
import { currentPageDatas, getCurrentMonthFirstDate } from "../../common/shared/utils";
import { Pagination, Stack } from "@mui/material";

const GSTRAnnexure = () => {

    const { gstrStore } = useStores()

    const tableHeadings = ["Type", "Invoice No.", "Place of Suppy", "Rate", "Taxable Value", "CESS Amount", "EconGstIn"];

    const tableHeadings3 = [
        "UR Type", "GSTIN UIN of Recipient", "Invoice Advance Receipt Number", "Invoice Advance Receipt Date",
        "Note Refund Voucher Number", "Note Refund Voucher Date", "Document Type", "Place Of Supply",
        "Note Refund Voucher Value", "TaxRate", "Rate", "Taxable Value", "Cess Amount", "Pre GST"
    ];

    const tableHeadings2 = [
        "HSN", "Description", "UQC", "Total Quantity",
        "Total Value", "Taxable Value", "Integrated Tax Amount", "State Tax Amount",
        "Central Tax Amount", "CESS Amount",
    ];

    const tableHeadings4 = [
        "Location", "Nature Of Document", "Sr No From", "Sr No To",
        "TotalNumber", "Cancelled",
    ];

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})

    const [localFields, setLocalFields] = useState<any>({ mode: '1', fdate: getCurrentMonthFirstDate()?.firstDate, tdate: getCurrentMonthFirstDate()?.currentDate })

    const [gstrAnnexureList, setGstrAnnexureList] = useState<any[]>([])

    function handleInputChange(e) {
        const { name, value } = e.target;
        localFields[name] = value;
        if (name === 'mode') {
            fetchInitialLoad(localFields)
        }
        setLocalFields({ ...localFields })
    }

    async function createExportExcelObj() {
        if (localFields?.fdate && localFields?.tdate) {
            setLoading(true);

            let excelData: { sheetName: string; data: any[] };
            let dataSets: { sheetName: string; data: any[] }[] = [];

            let body1 = {
                ...localFields,
                mode: 1,
            }
            const gstrAnnexureList1 = await gstrStore.getGstrAnnexureList(body1);
            if (gstrAnnexureList1?.length) {
                let dataArry: [] = gstrAnnexureList1?.map((each) => {
                    let obj: {} = {
                        Type: each?.Membertype,
                        Invoice_No: each?.Billno,
                        PlaceOfSupply: each?.state,
                        Rate: each?.taxper,
                        TaxableValue: each?.Taxvalue,
                        CessAmount: each?.CessAmount,
                        EconGstIn: each?.EcomGstIn
                    }
                    return obj;
                })
                excelData = {
                    sheetName: 'B2CSmall',
                    data: dataArry
                }
                dataSets?.push(excelData);
            }
            let body2 = {
                ...localFields,
                mode: 2,
            }
            const gstrAnnexureList2 = await gstrStore.getGstrAnnexureList(body2)
            if (gstrAnnexureList2?.length) {
                let dataArry: [] = gstrAnnexureList2?.map((each) => {
                    let obj: {} = {
                        HSN: each?.Hsncode,
                        Description: each?.itemdescription,
                        UQC: each?.Unitname,
                        TotalQuantity: each?.TotalQty,
                        TotalValue: each?.Totalamount,
                        TaxableValue: each?.Taxamblevalue,
                        IntegratedTaxAmount: each?.IntegratedTax,
                        StateTaxAmount: each?.sgstvalue,
                        CentalTaxAmount: each?.Cgsvalue,
                        CessAmount: each?.CessAmount
                    }
                    return obj;
                })
                excelData = {
                    sheetName: 'HSN',
                    data: dataArry
                }
                dataSets?.push(excelData);
            }
            let body3 = {
                ...localFields,
                mode: 3,
            }
            const gstrAnnexureList3 = await gstrStore.getGstrAnnexureList(body3)
            if (gstrAnnexureList3?.length) {
                let dataArry: [] = gstrAnnexureList3?.map((each) => {
                    let obj: {} = {
                        URType: each?.Membertype,
                        GSTIN_UIN_of_Recipient: each?.uinofreceipt,
                        Invoice_Advance_Receipt_Number: each?.billno,
                        Invoice_Advance_Receipt_Date: '',
                        Note_Refund_Voucher_Number: each?.rtnno,
                        Note_Refund_Voucher_Date: moment(each?.rtndate)?.format('DD-MMM-YYYY'),
                        Document_Type: '',
                        Place_Of_Supply: each?.state,
                        Note_Refund_Voucher_Value: each?.NoteRefundVoucherValue,
                        TaxRate: each?.taxper,
                        Rate: '',
                        Taxable_Value: each?.Taxvalue,
                        Cess_Amount: each?.CessAmount,
                        Pre_GST: each?.NoteRefundVoucherValue
                    }
                    return obj;
                })
                excelData = {
                    sheetName: 'SalRtnUnReg',
                    data: dataArry
                }
                dataSets?.push(excelData);
            }
            let body4 = {
                ...localFields,
                mode: 4,
            }
            const gstrAnnexureList4 = await gstrStore.getGstrAnnexureList(body4)
            if (gstrAnnexureList4?.length) {
                let dataArry: [] = gstrAnnexureList4?.map((each) => {
                    let obj: {} = {
                        Location: each?.Location,
                        Nature_Of_Document: each?.sales,
                        Sr_No_From: each?.fromno,
                        Sr_No_To: each?.tono,
                        TotalNumber: each?.nos,
                        Cancelled: each?.canceled,
                    }
                    return obj;
                })
                excelData = {
                    sheetName: 'DOC',
                    data: dataArry
                }
                dataSets?.push(excelData);
            }

            setLoading(false)
            exportToExcel(dataSets)
        }

    }

    function exportToExcel(dataSets: { sheetName: string; data: any[] }[]) {

        // multiple excel sheets sheets
        // multiple new sheet excel form, multiple table row column

        const workbook = XLSX.utils.book_new();

        let fromDate = moment(localFields?.fdate).format('DD-MMM-YYYY');
        let toDate = moment(localFields?.tdate).format('DD-MMM-YYYY');

        let staticTable = [
            'Location: Ganga Supermarket-NKL', 'Company: Ganga Super Market', 'User: Ganga Supermarket', `Generated Time: ${new Date()}`,
            `Period: ${fromDate} to ${toDate}`, 'Locations: Ganga Supermarket-NKL,Namakkal - WH'
        ]?.map((each) => {
            let obj: {} = {
                GSTR_Annexure: each,
            }
            return obj;
        })

        dataSets.forEach(({ sheetName, data }) => {
            const worksheetData = [
                Object.keys(staticTable[0]),
                ...staticTable.map(obj => Object.values(obj)),
                ['', '', ''],
                Object.keys(data[0]),
                ...data.map(obj => Object.values(obj)),
            ];
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        const today = moment();

        XLSX.writeFile(workbook, `GSTR Annexure ${today.format('DD-MM-YYYY')}.xlsx`);

    }

    async function fetchInitialLoad(localFields) {
        if (localFields?.fdate && localFields?.tdate) {
            setLoading(true)
            const gstrAnnexureList = await gstrStore.getGstrAnnexureList(localFields)
            setGstrAnnexureList([...gstrAnnexureList])
        }
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchInitialLoad(localFields)
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (gstrAnnexureList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(gstrAnnexureList)
        }
    }, [gstrAnnexureList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(gstrAnnexureList, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '100%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>{localFields?.mode! === '1' ? 'B2C Small' : localFields?.mode! === '2' ? 'HSN' : localFields?.mode! === '3' ? 'Sales Return Un-Reg' : localFields?.mode! === '4' && 'DOC'} </h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label>From Date <span>*</span></label>
                                                <input type="date"
                                                    name="fdate"
                                                    onChange={handleInputChange}
                                                    value={localFields.fdate = localFields.fdate ?? getCurrentMonthFirstDate()?.firstDate}
                                                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label>To Date <span>*</span></label>
                                                <input type="date"
                                                    name="tdate"
                                                    onChange={handleInputChange}
                                                    value={localFields.tdate = localFields.tdate ?? getCurrentMonthFirstDate()?.currentDate}
                                                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label>Mode <span>*</span></label>
                                                <select className="form-selected" style={{ width: "100%" }} id="Openingtype"
                                                    name="mode" onChange={handleInputChange} value={localFields?.mode}>
                                                    <option selected value={''}>-Select-</option>
                                                    <option selected value={'1'}>B2C Small</option>
                                                    <option selected value={'2'}>HSN</option>
                                                    <option selected value={'3'}>Sales Return Un-Reg</option>
                                                    <option selected value={'4'}>Doc</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={() => fetchInitialLoad(localFields)}
                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={createExportExcelObj}
                                            >Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{gstrAnnexureList?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>

                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {(localFields?.mode! == '1' ? tableHeadings : localFields?.mode! == '2' ? tableHeadings2 :
                                                    localFields?.mode! == '3' ? tableHeadings3 : localFields?.mode! == '4' ? tableHeadings4 : [])?.map((heading, index) => {
                                                        return (
                                                            <th key={index}>{heading}</th>
                                                        )
                                                    })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData?.map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        {tableBody(localFields?.mode!, each)}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            }
        </>
    )
}

function tableBody(mode, each) {
    return (
        <>
            {mode! == '1' ? <>
                <td>{each?.Membertype}</td>
                <td>{each?.Billno}</td>
                <td>{each?.state}</td>
                <td>{each?.taxper}</td>
                <td>{each?.Taxvalue}</td>
                <td>{each?.CessAmount}</td>
                <td>{each?.EcomGstIn}</td>
            </> : mode! == '2' ? <>
                <td>{each?.Hsncode}</td>
                <td>{each?.itemdescription}</td>
                <td>{each?.Unitname}</td>
                <td>{each?.TotalQty}</td>
                <td>{each?.Totalamount}</td>
                <td>{each?.Taxamblevalue}</td>
                <td>{each?.IntegratedTax}</td>
                <td>{each?.sgstvalue}</td>
                <td>{each?.Cgsvalue}</td>
                <td>{each?.CessAmount}</td>
            </> : mode! === '3' ? <>
                <td>{each?.Membertype}</td>
                <td>{each?.uinofreceipt}</td>
                <td>{each?.billno}</td>
                <td></td>
                <td>{each?.rtnno}</td>
                <td>{moment(each?.rtndate)?.format('DD-MMM-YYYY')}</td>
                <td></td>
                <td>{each?.state}</td>
                <td>{each?.NoteRefundVoucherValue}</td>
                <td>{each?.taxper}</td>
                <td></td>
                <td>{each?.Taxvalue}</td>
                <td>{each?.CessAmount}</td>
                <td>{each?.NoteRefundVoucherValue}</td>
            </> : mode! == '4' ? <>
                <td>{each?.Location}</td>
                <td>{each?.sales}</td>
                <td>{each?.fromno}</td>
                <td>{each?.tono}</td>
                <td>{each?.nos}</td>
                <td>{each?.canceled}</td>
            </> : <></>}
        </>
    )
}
export default GSTRAnnexure;

