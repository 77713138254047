import React, { useEffect, useMemo, useRef, ChangeEvent, useState } from 'react'
import { Button, Image, Modal, Spinner } from 'react-bootstrap'
import useStores from '../../hooks'
import { IQuoteSupplier } from './model';
import Cookies from 'js-cookie';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const QuoteFromSupplier = () => {
  const { supplierMasterStore, quoteFromSupplierStore, indentStore } = useStores();
  const navigate = useNavigate();
  const { getquoteFromSupplierDetails, quoteFromSupplierListDetail, getquoteFromSupplierItemDetails, quoteFromSupplierItemList } = quoteFromSupplierStore;
  const [quoteFromSupplier, setQuoteFromSupplier] = useState<IQuoteSupplier>({});

  const [quoteItemSupplierList, setQuoteItemSupplierList] = useState<any[]>([])
  const [quoteFromSupplierList, setQuoteFromSupplierList] = useState<any[]>([])
  const [isLoadModal, setLoadModal] = useState(false);
  const [loader, setLoader] = useState({ isLoading: true, supplierModal: false, quoteModal: false });
  const [successModal, setSuccessModal] = useState(false);
  const [failureModal, setFailureModal] = useState(false);

  const [errors, setErrors] = useState<any>({})
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 


  const handleSuccessModalClose = () => {
    navigate('/Admin/QuoteFromSupplier/QuoteFromSupplierDetail');
  }

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    setQuoteFromSupplier({ ...quoteFromSupplier, [name]: value })
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }


  const handleSupplierChangeInput = (event, supplierDetail) => {

    const { name, value } = event.target;
    const updatedArray = updateRate(supplierDetail?.indent_details_id, supplierDetail?.supplierid, name, value)
    setQuoteItemSupplierList(updatedArray)
  }

  const updateRate = (indentDetailsId: number, supplierId: number, name: string, value: string) => {

    quoteItemSupplierList.forEach((item) => {
      if (item?.suppliers && item?.indent_details_id === indentDetailsId) {
        const updatedSuppliers = item?.suppliers.map((supplier) => {
          if (supplier.supplierid === supplierId) {
            return {
              ...supplier,
              [name]: name == 'rate' ? Number(value) : value,
            };
          }
          return supplier;
        });
        item.suppliers = updatedSuppliers;
      }
    });

    return quoteItemSupplierList;
  };

  const newEntryNo = useMemo(() => {
    if (indentStore?.entryNo && indentStore?.entryNo?.entrynumber) {
      const originalString = indentStore?.entryNo?.entrynumber;
      const [prefix, numericPart] = originalString && originalString?.split('-');
      if (Number(numericPart) < 1) {
        const incrementedNumericPart = String(Number(numericPart) + 1).padStart(5, '0');
        const newString = `${prefix}-${incrementedNumericPart}`;
        return newString;
      } else {
        return originalString
      }
    } else {
      return '';
    }
  }, [indentStore?.entryNo?.entrynumber])

  const saveQuoteFromSupplier = async (e) => {
    e.preventDefault();

    quoteFromSupplier.quoteNo = newEntryNo;

    const today = moment();

    quoteFromSupplier.quoteDate = quoteFromSupplier?.quoteDate ? quoteFromSupplier?.quoteDate : today.format('YYYY-MM-DD');
    quoteFromSupplier.enteredBy = Number(Cookies.get('employeeId'));
    const submitData = extractSupplierData(quoteItemSupplierList)

    let error: any = {};
    if (!submitData?.length) {
      error.supplierList = 'Enter Supplier Rate & Date'
      setErrors(error)
    } else {
      setErrors(error)
    }
    quoteFromSupplier.quoteFromSupplierDetails = submitData;

    // const status = 'Success'
    if (Object.keys(error).length === 0) {
      setLoader({ ...loader, isLoading: true, quoteModal: false, supplierModal: false })
      const status = await quoteFromSupplierStore.saveQuoteFromSupplier(quoteFromSupplier);

      if (status === "Success") {
        setLoader({ ...loader, isLoading: false, quoteModal: false, supplierModal: false })
        setSuccessModal(true)
      }
      else {
        setLoader({ ...loader, isLoading: false, quoteModal: false, supplierModal: false })
        setFailureModal(true);
      }
    }
  }

  const extractSupplierData = (submitData: Array<any>): { rate: number; supplierId: number, effectiveFrom: string, indentDetailsId: number, indentId: number, itemId: number }[] => {
    const result: { rate: number; supplierId: number, effectiveFrom: string, indentDetailsId: number, indentId: number, itemId: number }[] = [];
    submitData.forEach((item) => {
      if (item.suppliers) {
        item.suppliers.forEach((supplier) => {
          if (supplier?.rate && supplier?.effectiveFrom) {
            result.push({
              rate: supplier.rate,
              supplierId: supplier.supplierid,
              effectiveFrom: supplier.effectiveFrom,
              indentDetailsId: supplier.indent_details_id,
              indentId: supplier?.indent_id,
              itemId: supplier?.itemid
            });
          }
        });
      }
    });

    return result;
  };

  async function getQuotefromItemDetail(indentId: string) {
    setLoader({ ...loader, isLoading: true });
    const itemList = await getquoteFromSupplierDetails(indentId);
    setQuoteItemSupplierList(itemList)
    setLoader({ ...loader, isLoading: false, quoteModal: true });
  }

  const getquoteFromSupplierDetail = async (indent_id, itemid: any) => {
    // setQuoteFromSupplier({ ...quoteFromSupplier, indentId: indent_id, itemId: itemid });
    setLoader({ ...loader, isLoading: true, quoteModal: false });
    const supplierList = await getquoteFromSupplierItemDetails(itemid, indent_id);
    const listofItemSupplier = mergeSupplierToItems(supplierList, quoteItemSupplierList);
    setQuoteItemSupplierList(listofItemSupplier)
    let error: any = {}
    if (supplierList?.length) {
      setLoader({ ...loader, isLoading: false, supplierModal: true });
    } else {
      error.suplist = "Supplier was not Assigned.";
      error.indentid = indent_id;
      error.itemid = itemid;
      setLoader({ ...loader, isLoading: false, supplierModal: false });
    }
    setErrors({ ...error })
  }

  const mergeSupplierToItems = (suppliers: Array<any>, items: Array<any>) => {
    const mapItemsByIndentDetailsId = new Map<number, any>();

    items.forEach((item) => {
      mapItemsByIndentDetailsId.set(item.indent_details_id, item);
    });

    suppliers.forEach((supplier) => {
      const indentDetailsId = supplier.indent_details_id;
      const item = mapItemsByIndentDetailsId.get(indentDetailsId);

      if (item) {
        if (item.suppliers) {

          const existingSupplierIndex = item.suppliers.findIndex(
            (existingSupplier) => existingSupplier.supplierid === supplier.supplierid
          );

          if (existingSupplierIndex !== -1) {
            item.suppliers[existingSupplierIndex] = supplier;
          } else {
            item.suppliers.push(supplier);
          }

        } else {
          item.suppliers = [supplier];
        }
      }
    });

    return items
  };

  async function fetchLoadData() {
    setLoader({ ...loader, isLoading: false })
    const [quoteFromSupplierList] = await Promise.all([quoteFromSupplierStore.getquoteFromSupplier(), indentStore.getEntryNo('QuoteFromSupplier')]);
    if (quoteFromSupplierList?.length) {
      setQuoteFromSupplierList([...quoteFromSupplierList])
    } else {
      setQuoteFromSupplierList([])
    }
  }

  // useEffect(() => {
  //   if (isCurrenPage.current) {
  //     fetchLoadData();
  //     isCurrenPage.current = false;
  //   }
  //   return () => { }
  // }, [])

  const isCurrenPage = useRef(true)
  const empid = Number(Cookies.get('userId'));
  const formName = 'Quote_from_supplier';

  useEffect(() => {

    const fetchRights = async () => {

      try {
        const data = await supplierMasterStore.fetchRights(empid, formName);

        if (Array.isArray(data) && data.length > 0) {
          const specificFieldValue = data[0].sts;
          const rightsValue = data[0].rights;
          const lettersArray = rightsValue.split('');
          if (specificFieldValue === 1) {
            if (isCurrenPage.current) {
              fetchLoadData();

              isCurrenPage.current = false
              if (lettersArray.includes('a')) {
                setCanAdd(true);
              } else {
                navigate('/Admin/outlet/list');
              }
              if (lettersArray.includes('e')) {
                setCanEdit(true);
              }
              // if (lettersArray.includes('v')) {
              //   setCanView(true); 
              // }     
              // if (lettersArray.includes('p')) {
              //   setCanPrint(true); 
              // }       
              // if (lettersArray.includes('u')) {
              //   setCanUpdate(true); 
              // }                     
            }
          } else if (specificFieldValue === 0) {
            setLoadModal(true)
          }
        }

      } catch (error) {
        console.error("Error fetching rights:", error);
      }
    };
    fetchRights();
  }, [empid, formName]);

  const handleFailureModalClose = () => setFailureModal(false);

  if (isLoadModal) {
    return (
      <div className="container">
        {isLoadModal && <h1>Access Denied</h1>}
        <Modal show={isLoadModal} onHide={handleAccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Access Denied</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAccessModalClose}>Ok</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  else {

    return (
      <div className='container'>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox LgInputField'>

          <div className='hrBox'>
            <h3>Quote From Supplier</h3>
          </div>

          <div className='inputBoxLists'>
            <div className='row'>
              {loader?.isLoading && <div className={'SpinnerBox'}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>}
              <div className='vertical-space-10'></div>
              <div className='col-sm-1' ></div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Quote No </label>
                  <input type="text"
                    value={quoteFromSupplier.quoteNo = newEntryNo} disabled
                    onChange={handleChangeInput}
                    name='quoteno'
                    style={{ width: "100%" }} placeholder='Quote No..'
                  >
                  </input>
                </div>
              </div>
              <div className='col-md-2'>
                <div className='inputBox'>
                  <label>Date <span>*</span></label>
                  <input type="date" style={{ width: "100%" }}
                    value={quoteFromSupplier.quoteDate = quoteFromSupplier.quoteDate ?? new Date().toISOString().substr(0, 10)} name='quotedate'
                    onChange={handleChangeInput}
                    placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"
                  ></input>
                </div>
              </div>
              <div className='col-sm-4' >
                <div className='inputBox'>
                  <label>Entered By <span>*</span></label>
                  <input type="text" className='' style={{ width: "100%" }}
                    value={Cookies.get('employee_name')}
                    placeholder='Entered By..'
                  ></input>
                </div>
              </div>
              <div className="col-sm-1"></div>
              <div className="col-sm-1" style={{ marginTop: '25px' }}>
                <button className='secondaryBtn' type='submit' onClick={handleSuccessModalClose}>List</button>
              </div>
            </div>
          </div>
          <div className="flex full-width" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <h5>Waiting for Indent Approval</h5>
          </div>
          <div className="">
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Indent No</th>
                    <th scope="col"> Date</th>
                    <th scope="col">Outlet  </th>
                    <th scope="col">Indent By </th>
                    <th scope="col">Raised By </th>
                    <th scope='col'></th>
                  </tr>
                </thead>
                <tbody>
                  {quoteFromSupplierList?.length > 0 ?
                    quoteFromSupplierList?.slice().sort((a, b) => b.indent_id - a.indent_id)?.map((val, index) => {
                      return (
                        <tr key={index}>
                          <td>{val.indent_no}</td>
                          <td>{val.indentdate}</td>
                          <td>{val.indent_from}</td>
                          <td>{val.indentby}</td>
                          <td>{val.raisedby}</td>
                          <td><button onClick={() => getQuotefromItemDetail(val.indent_id)}>Item Details</button></td>
                        </tr>
                      )
                    }) : null}
                </tbody>
              </table>
            </div>
            <div className='vertical-space-20'></div>
          </div>
        </div>

        <Modal show={loader?.quoteModal} onHide={() => setLoader({ ...loader, quoteModal: false })} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Item Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Item Name</th>
                    <th scope="col">Req Qty</th>
                    <th scope="col">UOM  </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {quoteFromSupplierListDetail?.length > 0 ? quoteFromSupplierListDetail?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val.itemname}</td>
                        <td>{val.indent_qty}</td>
                        <td>{val.uom}</td>
                        <td>
                          <div>
                            <button onClick={(e: any) => getquoteFromSupplierDetail(val.indent_id, val.itemid)}>Supplier Rates</button>
                            <div>{(errors?.suplist && errors?.indentid === val?.indent_id && errors?.itemid === val?.itemid) && <p style={{ color: 'red' }}>{errors?.suplist}</p>}</div>
                          </div>
                        </td>
                      </tr>
                    )
                  }) : null
                  }
                </tbody>
              </table>
              {errors.supplierList && <p style={{ color: 'red' }}>{errors.supplierList}</p>}
            </div>
            <div className='vertical-space-10'></div>
            <div className='row' >
              <div className='col-sm-8'></div>
              <div className='btnBox col-sm-2'>
                <button className='dfBtn' type='submit' onClick={saveQuoteFromSupplier}>Save</button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={loader?.supplierModal} onHide={() => setLoader({ ...loader, supplierModal: false })} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Rates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Supplier Id</th>
                    <th scope="col">Supplier Name</th>
                    <th scope="col">Rate </th>
                    <th scope="col">Eff. From  </th>
                  </tr>
                </thead>
                <tbody>
                  {quoteFromSupplierItemList?.length > 0 && quoteFromSupplierItemList?.map((val, key) => {
                    return (
                      <tr key={key}>
                        <td>{val.supplierid}</td>
                        <td>{val.SupplierName}</td>
                        <td><input type='text' name='rate'
                          onChange={(e) => handleSupplierChangeInput(e, val)} style={{ width: '70%' }}></input> </td>
                        <td><input type='date' name='effectiveFrom'
                          onChange={(e) => handleSupplierChangeInput(e, val)} style={{ width: '100%' }}
                          placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input></td>
                      </tr>
                    )
                  })
                  }
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={successModal} onHide={handleSuccessModalClose} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/checked.png')} />
              </div>
              <h4>Succesfully Submitted</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleSuccessModalClose}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={failureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
          <Modal.Body>
            <div className='Details Success'>
              <div className='imgBox'>
                <Image src={require('../../../gsmecom/images/warning.png')} />
              </div>
              <h4>Failed</h4>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setFailureModal(false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div >
    )
  }
}

export default QuoteFromSupplier;