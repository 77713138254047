import { IILeaveReq } from "../model";

export function saveValidation(leaveRequest: IILeaveReq) {
    let error: any = {}
    if (!leaveRequest?.lR_EmpID) {
        error.lR_EmpID = 'Select Employee'
    }
    if (!leaveRequest?.lR_FromDate) {
        error.lR_FromDate = 'Select Employee'
    }
    if (!leaveRequest?.lR_ToDate) {
        error.lR_ToDate = 'Select Employee'
    }
    if (!leaveRequest?.lR_LevType) {
        error.lR_LevType = 'Select Employee'
    }
    if (!leaveRequest?.lR_reason) {
        error.lR_reason = 'Select Employee'
    }
    return error
}