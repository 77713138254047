import { useEffect, useRef, useState } from "react";
import { toPng } from "html-to-image";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import { getCurrentMonthFirstDate, splitWord } from "./utils";
import axios from 'axios';

class LocalFields {
    itemName: string = '';
    tamilName: string = '';
    mrp: number = 0;
    uspper: string = '';
    batchNo: string = '';
    dateofpack: string = getCurrentMonthFirstDate()?.currentDate;
    dateuseby: string = getCurrentMonthFirstDate()?.currentDate;
    width: string = '';
    height: string = '';
    printId: number = 0;
}

function ImageCoverter({ printObj, printSizeList }) {

    const [dataURL, setDataURL] = useState("")
    const imageRef = useRef<HTMLImageElement | null>(null);
    const elementRef = useRef<HTMLDivElement>(null);

    const [fileds, setFields] = useState<LocalFields>(new LocalFields())

    const [errors, setErrors] = useState<any>({})

    async function handlePrintDes() {
        try {
            await axios.post('https://api.varthagam.in/api/print', imageRef);
            console.log('Print request sent successfully');
        } catch (error) {
            console.error('Error sending print request:', error);
            alert('Error sending print request')
        }
    }

    async function handlePrintItemName() {
        try {
            await axios.post('https://api.varthagam.in/api/print', imageRef);
            console.log('Print request sent successfully');
        } catch (error) {
            console.error('Error sending print request:', error);
            alert('Error sending print request')
        }
    }

    const htmlToImageConvert = () => {
        let error: any = {};
        if (fileds?.printId) {
            const node = elementRef.current!;

            // Set the size of the element containing the data
            node.style.width = fileds?.width;
            node.style.height = fileds?.height;

            toPng(elementRef.current!, { cacheBust: false })
                .then((dataUrl) => {
                    imageRef.current!.src! = dataUrl;
                    setDataURL(dataUrl)
                })
                .catch((error) => {
                    console.error("Error converting text to image:", error);
                });
        } else {
            error = {
                selectSize: 'Select Print Size'
            }
        }
        setErrors({ ...error })
    };

    const handlePrint = () => {
        handlePrintDes();
        handlePrintItemName()
        if (imageRef.current) {
            const printWindow = window.open("", "_blank");
            if (printWindow) {
                printWindow.document.write(`<img src="${imageRef.current.src}" />`);
                printWindow.document.close();
                printWindow.print();
            }
        }
    };

    let batchNos: any[] = printObj?.Batchno ? splitWord(printObj?.Batchno, 16) : []

    useEffect(() => {
        if (printObj) {
            setFields({ ...fileds, itemName: printObj?.itemname, tamilName: printObj?.itemname, mrp: printObj?.mrp, dateofpack: printObj?.packofdate, dateuseby: printObj?.usedbydate, uspper: printObj?.uspper })
        }
    }, [printObj])

    function handleAutoComplete(name, value) {
        setFields({ ...fileds, width: value?.width, height: value?.height, printId: value?.id })
    }

    const inputStyle: React.CSSProperties = {
        outline: 'none',
        fontSize: '20px',
        // textAlign: 'center',
        position: 'relative',
        visibility: fileds?.printId ? 'visible' : 'hidden',
        width: `${Number(fileds?.width!)}px`,
        height: `${Number(fileds?.height!)}px`, // Add focus style conditionally
    };

    const descStyle: React.CSSProperties = {
        position: 'absolute',
        top: fileds?.printId === 3 ? '60px' : '60px',
        left: fileds?.printId === 3 ? '-11px' : '-11px'
    }

    const prodStyle: React.CSSProperties = {
        position: 'absolute',
        top: fileds?.printId === 3 ? '150px' : '150px',
        left: fileds?.printId === 3 ? '206px' : '206px'
    }

    //print
    const printInputStyle: React.CSSProperties = {
        outline: 'none',
        fontSize: '20px',
        position: 'relative',
        width: `${Number(fileds?.width!) * 2}mm`,
        height: `${Number(fileds?.height!) * 2}mm`, // Add focus style conditionally
    };

    const printDescStyle: React.CSSProperties = {
        position: 'absolute',
        top: '90mm',
        left: '6mm'
    }

    const printProdStyle: React.CSSProperties = {
        position: 'absolute',
        top: '150mm',
        left: '170mm',
    }
    return (
        <>
            <div className='outletInputField inputFormBox'>
                <div className='inputBoxLists p-0'>
                    <div className='LgInputListsBox mr-left-0'>
                        <div className="">
                            <div className="row" >
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Select size <span>*</span></label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={printSizeList}
                                            onChange={(event, val) => handleAutoComplete('size', val)}
                                            renderInput={(params) =>
                                                <TextField  {...params}
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    className="full-width"
                                                    placeholder='Select Size..'
                                                />}
                                        />
                                    </div>
                                    {errors?.selectSize && <p style={{ color: 'red' }}>{errors?.selectSize}</p>}
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label>Item name <span>*</span></label>
                                        <input className=''
                                            name='itemName'
                                            value={fileds?.itemName}
                                            onChange={(event) => setFields({ ...fileds, itemName: event.target.value })}
                                            style={{ width: "100%" }} placeholder='Item name..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='inputBox'>
                                        <label style={{ whiteSpace: 'nowrap' }}>Item name (in tamil) <span>*</span></label>
                                        <input className=''
                                            name='tamilName'
                                            value={fileds?.tamilName}
                                            onChange={(event) => setFields({ ...fileds, tamilName: event.target.value })}
                                            style={{ width: "100%" }} placeholder='Item name..'></input>
                                    </div>
                                    <div className='vertical-space-10'></div>
                                </div>
                            </div>
                            <div className="flexImp p-3" style={{ border: '1px solid #c2c2c2' }}>
                                <div style={inputStyle}>
                                    <div style={descStyle}>
                                        <div className="" style={{ width: 'fit-content', transform: 'rotate(270deg)', fontSize: '12px', padding: '12px' }}>
                                            <div>{fileds?.mrp}</div>
                                            <div>{fileds?.uspper}</div>
                                            <div>
                                                {batchNos?.map((substring, index) => (
                                                    <div key={index}>{substring}</div>
                                                ))}
                                            </div>
                                            <div>{fileds?.dateofpack}</div>
                                            <div>{fileds?.dateuseby}</div>
                                        </div>
                                    </div>
                                    <div style={prodStyle}>
                                        <div className="" style={{ width: 'fit-content', fontSize: '12px', padding: '12px' }}>
                                            <div style={{ textAlign: 'center' }}>
                                                <div>{fileds?.itemName}</div>
                                                <div>{fileds?.tamilName}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='btnBox' style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '15px 0 0' }}>
                                <button className='secondaryBtn' style={{ width: 'fit-content', whiteSpace: 'nowrap' }} onClick={htmlToImageConvert}>Convert to Image</button>
                                {imageRef?.current && <button className='secondaryBtn' style={{ width: 'fit-content', whiteSpace: 'nowrap' }} onClick={handlePrint}>Print Image</button>}
                            </div>
                        </div>
                        <div>
                            <div className="ImageCoverter">
                                <div className="flexImp" style={{ padding: '10px' }}>
                                    <img ref={imageRef} alt="Generated Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-0">
                <div style={printInputStyle} ref={elementRef}>
                    <div style={printDescStyle}>
                        <div className="" style={{ width: 'fit-content', transform: 'rotate(270deg)', fontSize: '30px', padding: '12px' }}>
                            <div>{fileds?.mrp}</div>
                            <div>{fileds?.uspper}</div>
                            <div>
                                {batchNos?.map((substring, index) => (
                                    <div key={index}>{substring}</div>
                                ))}
                            </div>
                            <div>{fileds?.dateofpack}</div>
                            <div>{fileds?.dateuseby}</div>
                        </div>
                    </div>
                    <div style={printProdStyle}>
                        <div className="" style={{ width: 'fit-content', fontSize: '30px', padding: '12px' }}>
                            <div style={{ textAlign: 'center' }}>
                                <div>{fileds?.itemName}</div>
                                <div>{fileds?.tamilName}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImageCoverter;
