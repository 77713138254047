import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faPrint, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import moment from 'moment';

import numberToWords from 'number-to-words';
import { getCurrentMonthFirstDate } from '../../common/shared/utils';

const SalaryGenerationDetail = observer((): JSX.Element => {
    const { payrollStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();

    const [searchFields, setSearchFields] = useState<any>({ sdate: getCurrentMonthFirstDate()?.firstDate, enddate: getCurrentMonthFirstDate()?.currentDate })

    const [isLoading, setLoading] = useState(true);
    const [isSpinner, setSpinner] = useState(false);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const [salaryList, setSalaryList] = useState<any[]>([])

    const [loanBalanceList, setLoanBalanceList] = useState<any[]>([])

    const [modal, setModal] = useState({ bool: false, index: 0 })

    const [errors, setErrors] = useState<any>({})

    const handleCreate = () => {
        navigate('/Admin/SalaryGeneration')
    }

    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function handleSearchSalaries() {
        if (searchFields?.sdate && searchFields?.enddate) {
            const getSalariesList = await payrollStore.getSalaryGeneration(searchFields);
            setSalaryList([...getSalariesList])
            setErrors({})
        }
        if (!searchFields?.enddate) {
            setErrors({ ...errors, enddae: 'Enter To Date' })
        }
        if (!searchFields?.sdate) {
            setErrors({ ...errors, enddae: 'Enter From Date' })
        }
    }

    const objectRef = useRef<any>({});

    // console?.log(salaryList,'salaryList>>')

    async function handlePrintItem(each) {
        setSpinner(true)
        const getOutletDCData = salaryList?.filter((salary) => salary?.Empid === each?.Empid)
        let empBody = {
            salaryid: each?.Salaryid,
            Empid: each?.Empid
        }
        const getAllowanceDetails = await payrollStore.getAllowanceDetails(empBody);
        const getDeductionDetails = await payrollStore.getDeductionDetails(empBody);
        const loadDetails = loanBalanceList?.find((obj) => obj?.Empid === each?.Empid)

        let loanDeduction: number = 0;
        if (loadDetails?.AmountDeductmode == "monthly" && loadDetails?.Balancedue) {
            loanDeduction = loadDetails?.Balanceamount / loadDetails?.Balancedue;
            if (loanDeduction) {
                let loan = {
                    dedtype: 'Loan',
                    Amount: loanDeduction
                }
                getDeductionDetails?.push(loan)
            }
        } else if (loadDetails?.AmountDeductmode === "total" && loadDetails?.Balanceamount) {
            let loan = {
                dedtype: 'Loan',
                Amount: loadDetails?.Balanceamount
            }
            getDeductionDetails?.push(loan)
        }
        let attenBody = {
            sdate: moment(each?.sdate).startOf('month')?.format('DD-MM-YYYY'),
            enddate: moment(each?.sdate)?.format('DD-MMM-YYYY')
        }
        const getAttendanceSalary = await payrollStore.getAttendanceForSalary(attenBody)

        let findEmpAtten = getAttendanceSalary?.find((obj) => obj?.empid === each?.Empid)
        setSpinner(false)
        objectRef.current = getOutletDCData;
        let logo = require('../../../../image/Ganga_Supermarket-1.png')
        const printWindow = window.open("", "_blank");
        if (printWindow) {
            printWindow.document.write(
                `
                <html>
                <head>
                    <title style="text-align: center ; padding-right: 100px;" class="centered-div">. </title>
                    <style>
                        /* Add any additional styles here */

                        body {
                            margin: 0;
                            height: 100vh;
                        }

                        span {
                            width: 100%;
                        }

                        .border {
                            border: 1px solid black
                        }

                        .border-y {
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                        }

                        .border-bottom {
                            border-bottom: 1px solid black;
                        }

                        .border-top {
                            border-top: 1px solid black;
                        }

                        .text-center {
                            text-align: center;
                        }

                        .flex-center-between {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }

                        .flex {
                            display: flex;
                        }

                        .border-r {
                            border-right: 1px solid black;
                        }

                        .w-full {
                            width: 100%;
                        }

                        .p-12 {
                            padding: 12px;
                        }

                        .flex-col-center-between {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-between;
                        }

                        @page {
                            size: auto;
                            margin: 0mm;
                            border: 2px solid #ddd;
                        }
                    </style>
                </head>

                <body>
                    <div class="border" style="margin: 20px;">
                        <div class="p-12">
                            <div class="text-center" style="font-size: larger; font-weight: 700;">Ganga Super Market</div>
                            <div class="text-center">3/824 A, Thuraiyur Road, Near Ponni Gas Godown, Namakkal, Tamil Nadu - 637002</div>
                            <div class="flex-center-between" style="padding-top: 8px;">
                                <span>Period From: `+ moment(each?.sdate).startOf('month')?.format('DD-MMM-YYYY') + `  To: ` + moment(each?.sdate)?.format('DD-MMM-YYYY') + `</span><span>Licence No: </span><span>Date: ` + moment()?.format('DD-MMM-YYYY') + `</span>
                            </div>
                        </div>
                        <div class="border-top flex-center-between p-12">
                            <div class="text-center">
                                <div style="font-weight: 600;">Wage Slip /</div>
                                <div>(UNDER RULE 27(2) OF THE MIN WAGES CHENNAI RULES 1953)</div>
                            </div>
                            <div>
                                <div style="font-weight: 600;">TIME CARD 25-B / </div>
                                <div>(PRESCRIBED UNDER RULE 103-B)</div>
                            </div>
                        </div>
                        <div class="border-top p-12">
                            <div class="flex-center-between">
                                <span>Employee Code /</span><span>`+ getOutletDCData[0]?.Empid + `</span><span>Designation /</span><span> ` + getOutletDCData[0]?.Designation1 + ` </span>
                            </div>
                            <div class="w-full p-12"></div>
                            <div class="flex-center-between">
                                <span>Employee Name /</span><span style="text-transform: uppercase;">`+ getOutletDCData[0]?.Employee_Name + `</span><span>Department /</span><span>` + getOutletDCData[0]?.Department1 + `</span>
                            </div>
                        </div>
                        <div class="flex">
                            <table style="width: 66%" class="border-top">
                                <tr>
                                    <td class="text-center border-r border-bottom p-12" style="font-size: larger; font-weight: 600;">Earnings</td>
                                    <td class="text-center border-r border-bottom p-12" style="font-size: larger; font-weight: 600;">Deduction</td>
                                </tr>
                                <tr class="border-top">
                                    <td class="text-center border-r p-12">
                                        <div class="p-12">
                                        ${getAllowanceDetails?.map((each) => {
                    return (
                        `
                                                <div>
                                                    <span>${each?.Earn_Type}</span><span>:</span><span>${each?.Amount}</span>
                                                </div>`
                    )
                })}
                                            <div class="flex-center-between" style="padding-top: 12px;">
                                                <span style="text-align: left;">Total</span>
                                                <span style="text-align: right;">${getAllowanceDetails?.reduce((a, v) => a = a + v?.Amount, 0)}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center border-r p-12">
                                        <div class="p-12">
                                        ${getDeductionDetails?.map((each) => {
                    return (
                        `
                                                <div style="display:flex;">
                                                    <span>${each?.dedtype}</span><span>:</span><span>${each?.Amount}</span>
                                                </div>
                                            `)
                })}
                                            <div class="flex-center-between" style="padding-top: 12px;">
                                                <span style="text-align: left;">Total</span>
                                                <span style="text-align: right;">${getDeductionDetails?.reduce((a, v) => a = a + v?.Amount, 0)}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="p-12 border-top border-r">
                                        <div style="font-weight: 600;">Net Amount Paid</div>
                                        <div style="text-align: center;">
                                            Rs: ${(Math.abs((getAllowanceDetails?.reduce((a, v) => a = a + v?.Amount, 0)) - (getDeductionDetails?.reduce((a, v) => a = a + v?.Amount, 0)) ?? 0))}
                                        </div>
                                        <div style="padding-top: 4px;">In Words: `+ numberToWords?.toWords(Math.abs((getAllowanceDetails?.reduce((a, v) => a = a + v?.Amount, 0)) - (getDeductionDetails?.reduce((a, v) => a = a + v?.Amount, 0)) ?? 0)) + `</div>
                                    </td>
                                </tr>
                            </table>
                            <div style="width: 34%;" class="border-top p-12 flex-col-center-between">
                                <div class="flex w-full"><span>No. Of Shift Worked</span>:<span>${findEmpAtten?.net_payable_days + findEmpAtten?.CL}</span></div>
                                <div class="flex w-full"><span>OT</span>:<span>${findEmpAtten?.OThours ?? '00:00'}</span></div>
                                <div class="flex w-full"><span>Min Rage of Wages</span>:<span>${((Math.abs((getAllowanceDetails?.reduce((a, v) => a = a + v?.Amount, 0)) - (getDeductionDetails?.reduce((a, v) => a = a + v?.Amount, 0)) ?? 0)) / moment(each?.sdate)?.daysInMonth())?.toFixed(2)}</span></div>
                                <div class="flex w-full"><span>P.F. No.</span>:<span>${findEmpAtten?.pfno}</span></div>
                                <div class="flex w-full"><span>E.S.I No.</span>:<span>${findEmpAtten?.esino}</span></div>
                            </div>
                        </div>
                        <div class="p-12 border-top flex-center-between">
                            <div style="font-weight: 600;">Employee Signature</div>
                            <div style="font-weight: 600;">Authorised Signatory</div>
                        </div>
                        <div class="p-12"></div>
                        <div class="p-12"></div>
                    </div>
                </body>

                </html>
                `
            );

            printWindow.document.close();
            // printWindow.location.replace("Naveen");
            printWindow.print();
        } else {
            console.error("Unable to open print window.");
        }
    };

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Salary_generation')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const getSalariesList = await payrollStore.getSalaryGeneration(searchFields);
        setSalaryList([...getSalariesList])
        const loanBalanceList = await payrollStore.getLoadBalance()
        setLoanBalanceList([...loanBalanceList])
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='vertical-space-20'></div>
                            <div className='outletInputField inputFormBox LgInputField'>
                                <div className='inputBoxLists'>
                                    <div className='ItemInwardInputBox'>
                                        <div className='row'>
                                            <div className="col-sm-2">
                                                <div className='inputBox'>
                                                    <label>From Date <span>*</span></label>
                                                    <input type="date"
                                                        value={searchFields.sdate = searchFields.sdate ?? new Date().toISOString().substr(0, 10)}
                                                        style={{ height: "51px", width: '100%' }}
                                                        name="sdate"
                                                        onChange={(event) => setSearchFields({ ...searchFields, sdate: event.target.value })}
                                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                    ></input>
                                                </div>
                                                {errors.sdate && <p style={{ color: 'red' }}>{errors.sdate}</p>}
                                            </div>
                                            <div className="col-sm-2">
                                                <div className='inputBox'>
                                                    <label>To Date <span>*</span></label>
                                                    <input type="date" style={{ height: "51px", width: '100%' }}
                                                        name="enddate"
                                                        onChange={(event) => setSearchFields({ ...searchFields, enddate: event.target.value })}
                                                        value={searchFields.enddate = searchFields.enddate ?? new Date().toISOString().substr(0, 10)}
                                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                                    ></input>
                                                </div>
                                                {errors.enddate && <p style={{ color: 'red' }}>{errors.enddate}</p>}
                                            </div>
                                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={salaryList}
                                                searchTitles={[{ key: "employee", value: "Employee" }]}
                                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)}
                                                buttonChildren={
                                                    <button className='searchclearBtn' onClick={handleSearchSalaries}>Search</button>
                                                } />
                                        </div>
                                        <div className='vertical-space-20'></div>
                                        <div className='tableListDetails'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">EmpID/EmpName</th>
                                                        {/* <th scope="col">Salary Type</th> */}
                                                        <th scope="col">Department</th>
                                                        <th scope="col">Designation</th>
                                                        <th scope="col">Working days</th>
                                                        <th></th>
                                                        <th scope="col">Create</th>
                                                        <th scope="col">Update</th>
                                                        <th scope="col">Delete</th>
                                                        <th scope="col">Print</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(isSearch ? filteredData : salaryList)?.map((each, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{`${each?.Empid} / ${each?.Employee_Name}`}</td>
                                                                <td>{each?.Department1}</td>
                                                                <td>{each?.Designation1}</td>
                                                                <td>{each?.Wagdays}</td>
                                                                <td><button disabled={!userRights?.add} className='changeBtn' onClick={() => setModal({ bool: true, index: index })}>Details</button></td>
                                                                <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                                <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                                <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                                <td><button disabled={!userRights?.add} className="Edit" onClick={() => handlePrintItem(each)}><FontAwesomeIcon icon={faPrint} /></button></td>

                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={modal?.bool} onHide={() => setModal({ bool: false, index: 0 })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Detail</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Salary Month</th>
                                                    <th scope="col">Basic</th>
                                                    <th scope='col'>Net salary</th>
                                                    <th scope="col">Allowance</th>
                                                    <th scope="col">Deduction</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {salaryList?.map((each, index) => {
                                                    return modal?.index === index && (
                                                        <tr>
                                                            <td>{moment(each?.sdate)?.format('MMMM')}</td>
                                                            <td>{each?.basic}</td>
                                                            <td>{each?.Netsalary}</td>
                                                            <td>{each?.Allowance}</td>
                                                            <td>{each?.Deduction}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </>
            }
        </>

    )
})

export default SalaryGenerationDetail;