import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

function DebitNoteDetails() {

  const navigate = useNavigate()
  const { paymentVocherStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})

  const [paymentVoucherList, setPaymentVoucherList] = useState<any[]>([])
  const [paymentByDetails, setPaymentByDetails] = useState<any[]>([])

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [loader, setLoader] = useState(true)
  const [byAccount, setByAccount] = useState(false);

  const byAccountShow = async (vocno) => {

    const paymetnList = await paymentVocherStore.loadDebitNoteDetails(vocno);
    setPaymentByDetails(paymetnList);

    setByAccount(true)
  }

  const byAccountClose = () => setByAccount(false);

  async function fetchPreLoadingData() {
    // 
    const userRights = await userCreationStore?.getUserRight('Debit_note')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    const paymetnList = await paymentVocherStore.loadDebitNoteMaster();
    setPaymentVoucherList(paymetnList)
    setLoader(false)
  }

  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      fetchPreLoadingData();
      isInitialRender.current = false;
    } else {
    }
    return () => { };
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (paymentVoucherList && paymentVoucherList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(paymentVoucherList)
    }
  }, [paymentVoucherList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(paymentVoucherList?.slice()?.sort((a, b) => b.receiptId - a.receiptId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {loader ? <div className='SpinnerBox'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> : <div className='container-fluid'>
        <div className='vertical-space-20'></div>
        <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={paymentVoucherList}
          searchTitles={[{ key: "referenceNo", value: "Ref. Type" }]}
          emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
        <div className='vertical-space-20'></div>
        <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='btnBox'>
            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{paymentVoucherList?.length} Records</button>
          </div>
          <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
          </Stack>
        </div>
        <div className='vertical-space-20'></div>
        <div className='tableListDetails'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope='col'>Ref. No</th>
                <th scope='col'>Ref. Type</th>
                <th scope='col'> Date   </th>
                <th scope='col'></th>
                <th scope='col'>Add</th>
                <th scope='col'>Edit</th>
                <th scope='col'>Delete</th>
              </tr>
            </thead>
            <tbody>
              {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                (isSearch ? filteredData : currentPageData)?.map((oobj) => {
                  return (
                    <tr>
                      <td>{oobj?.receiptId}</td>
                      <td>{oobj?.referenceNo}</td>
                      <td>{moment(oobj?.receiptDate).format("DD-MMM-YYYY")}</td>
                      <td><button disabled={!userRights?.view} onClick={() => byAccountShow(oobj?.receiptId)} className='tablemodeltext'> Detail</button></td>
                      <td><button disabled={!userRights?.add} className='Add' onClick={() => navigate('/Admin/DebitNote')}><FontAwesomeIcon icon={faAdd} /></button></td>
                      <td><button disabled={!userRights?.edit} className='Edit' onClick={() => navigate('/Admin/DebitNote/' + oobj?.vchr_no)}><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                      <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>

        <Modal show={byAccount} onHide={byAccountClose} className='PriceHistoryModel'>
          <Modal.Header closeButton>
            <Modal.Title>Debit Note Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='tableBox'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Vchr No</th>
                    <th scope='col'>By Account </th>
                    <th scope='col'> Dr Account   </th>
                    <th scope='col'> Cr Account   </th>
                    <th scope='col'>Date  </th>
                    <th scope='col'>Narration  </th>
                  </tr>
                </thead>
                <tbody>
                  {paymentByDetails?.map((payment) => {
                    return payment?.vchr_cr_amount > 0 && (
                      <tr>
                        <td>{payment?.vchr_tb}</td>
                        <td>{payment?.ledger_accout_name}</td>
                        <td>{payment?.vchr_dr_amount}</td>
                        <td>{payment?.vchr_cr_amount}</td>
                        <td>{moment(payment?.vchr_date).format("DD-MMM-YYYY")}</td>
                        <td>{payment?.vchr_narration}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </div>}
    </>
  );
};

export default DebitNoteDetails;