import React, { useState, useEffect, } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import useStores from '../hooks';
import { observer } from 'mobx-react-lite';
import LogoImage from '../../../image/Ganga_Supermarket-1.png';
import '../../../App.css';
import Cookies from 'js-cookie';
import { ILogin } from '../../gsmecom/Login/model';
import { iAdmin } from './model';


const AdminLogin = observer((): JSX.Element => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState<iAdmin>({});
  const { adminMemberStore } = useStores();


  const adiminLoginClick = async () => {
    let headersList = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Headers': '*',
      "Accept": "*/*",
      "Content-Type": "application/json"
    }

    let bodyContent = JSON.stringify({
      "userName": "abcd",
      "password": "1234"
    });

    let response = await fetch("https://api.varthagam.in/api/Member/GetAdminLogin", {
      method: "POST",
      body: bodyContent,
      headers: headersList
    });

    let data = await response.text();
    console.log(data, '<<<logindata');
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const status = await adminMemberStore.getAdminLogin(loginDetails);
    console.log(status,'status');
    
    if (status !== undefined && status.employee_Name !== null) {
      Cookies.set('employeeId', status.employeeId);
      Cookies.set('userId', status.userId);
      Cookies.set('employee_name', status.employee_Name);
      Cookies.set('user_type', status.user_type);
      Cookies.set('counterNumber', status.counterNumber)
      Cookies.set('isAdminLogin', true);
      Cookies.set('outletId', status.outletId);
      Cookies.set('terminalId', status.terminalId);
      Cookies.set('outletName', status.outletName);
      navigate('/admin/home');
    }
    else {
      navigate('/admin')
    }

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({ ...loginDetails, [name]: value });
  }

  return (
    <>
      <div className="Loginform">
        <div className='vertical-space-20'></div>
        <div className='vertical-space-20'></div>
        <div className='vertical-space-20'></div>
        <div className='vertical-space-20'></div>
        <div className='outletInputField inputFormBox'>
          <Image src={LogoImage} />

          <div className='inputBoxLists'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>User Name <span>*</span></label>
                  <input type="text" className='' style={{ width: "100%" }}
                    placeholder='User Name..'
                    value={loginDetails.adminusername} name='userName'
                    onChange={handleInputChange} required></input>
                </div>
                <div className='vertical-space-10'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='inputBox'>
                  <label>Password <span>*</span></label>
                  <input type="password" className='' style={{ width: "100%" }} placeholder='Password..'
                    value={loginDetails.adminpassword} name='password'
                    onChange={handleInputChange} required></input>
                  <div className='vertical-space-10'></div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 btnBox'>
            <div className=' col-md-6 forgotbox'>
              <a href='/forgot' >Forgot Password</a>
            </div>
            <button className='col-md-6 dfBtn ' type='submit' style={{ width: "50%" }} onClick={handleSubmit}><span>Login</span></button>
          </div>
        </div>
        <div className='vertical-space-20'></div>
      </div>

    </>
  )
})

export default AdminLogin;
