import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IDirectInward } from '../model';

class DirectInwardStore {
    @observable directInwardDetls = new Map();
    @observable directInwardList= new Map();
    @observable directInwardDetails=new Map();
    @observable loadItemMasters = new Map();
    @observable ledgers = new Map();
    isInwardLoding=true;

    saveDirectInward = async (itemInward: IDirectInward) => {
        try {
          const status=  await apiAgent.directInward.saveDirectInward(itemInward);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDirectInwardMaster  = async ()=> {
        try {
          const inwardData= await apiAgent.directInward.getDirectInwardMaster();
            
            runInAction(() => {
                this.directInwardList = JSON.parse(inwardData);
            });
            return this.directInwardList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDirectInwardDetails = async (inwardNo)=> {
        try {
          const inwardDetls= await apiAgent.directInward.getDirectInwardDetails(inwardNo);
            runInAction(() => {
                this.directInwardDetails = inwardDetls;
            });
            return this.directInwardDetails;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDirectInwardSearch  = async (SearchOptions)=> {
        try {
          const data=await apiAgent.directInward.getDirectInwardSearch(SearchOptions);
            this.directInwardList = data;
            
            runInAction(() => {
                this.directInwardList = data;
                
            })
            return this.directInwardList;    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    // getLedgers
    getDirectInwardLedgers = async (inwardNo)=> {
        try {
          const data=await apiAgent.directInward.getDirectLedgers(inwardNo);
            this.ledgers = data;
            
            runInAction(() => {
                this.ledgers = data;
                
            })
            return data    
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDirectInwardDetail = async (inwardDetailNo) => {
        try {      
        const status=  await apiAgent.directInward.deleteDirectInwardDetail(inwardDetailNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDirectInwardMaster = async (inwardNo) => {
        try {      
        const status=  await apiAgent.directInward.deleteDirectInwardMaster(inwardNo);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    loadInwardItemMaster = async (inwardNo) => {
        try {
            const itemMaster = await apiAgent.commonMasters.loadItemMaster();
            runInAction(() => {
                this.loadItemMasters = itemMaster;
            })
           return this.loadItemMasters;
        }
        catch (error) {
            console.log(error);
        }
    }
    getLedgers = async (inwardNo) => {
        try {
            const ledgers = await apiAgent.itemInward.getLedgers(inwardNo);
            runInAction(() => {
                this.ledgers = ledgers;
            })
           return this.ledgers;
        }
        catch (error) {
            console.log(error);
        }
    }
}
export default DirectInwardStore;