function manageLoyaltyValidation(manageLoyalty){
    let errors :any={};

    if(!manageLoyalty.category){
      
        errors.category = "Select Category";
    }
    if(!manageLoyalty.billFrom){
      
        errors.billFrom = "Enter Bill Value From ";
    }
    if(!manageLoyalty.billTo){
      
        errors.billTo = "Enter Bill Value To ";
    }   
    if(!manageLoyalty.points){
      
        errors.points = "Enter Points  ";
    }
    return errors;
};
export default manageLoyaltyValidation
