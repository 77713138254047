import React, { useState, useEffect, } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IPackingInward, PackingInwardDetails } from './model';
import { Autocomplete, TextField } from '@mui/material';
import ProgressBar from '../../common/shared/progressbar';
import packingInwardValidation from './validation';
import AutoComplete from '../../common/shared/autoComplete';

const PackingInward = observer((): JSX.Element => {
  const { commonStore, packingInwardStore, brandStore, inwardStore } = useStores();
  const [packingInward, setPackingInward] = useState<IPackingInward>({});
  const [packingInwardDetail, setPackingInwardDetail] = useState<PackingInwardDetails>({});
  const [packingInwardDetails, setPackingInwardDetails] = useState<PackingInwardDetails[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [submitbtn, setSubmitbtn] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);


  const handleChangeview = () => {
    navigate('/Admin/PackingInward/PackingInwardDetail');
  }

  const { isloadPackingItem, loadPackingItem, loadPackingItems,
    isloadTeamDetail, loadTeamDetail, loadTeamDetails } = commonStore;
  const { loadItemMasters, loadInwardItemMaster } = inwardStore

  function handleChangeInput(event: any) {
    const { name, value } = event.target;

    setPackingInward({ ...packingInward, [name]: value })
  }


  const addPackingInward = async () => {

    let newObject: PackingInwardDetails = {};

    let error: any = {};
    error = packingInwardValidation(packingInward);
    setErrors(error);
    if (Object.keys(error).length === 0) {

      packingInwardDetail.InwardPackingItemId = packingInward.itemId
      packingInwardDetail.InwardItemName = packingInward.itemName
      packingInwardDetail.InwardItemUOM = packingInward.itemUOM
      packingInwardDetail.InwardPackingQty = packingInward.packingQty
      setSubmitbtn(true);
      setPackingInwardDetails([...packingInwardDetails, packingInwardDetail])
      setPackingInwardDetail(newObject);
    }
  }

  const handleTeamValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setPackingInward({ ...packingInward, [name]: val.teamMasterId, "teamName": val.teamMasterName });

    }
    else {
      packingInward.teamName = '';
    }
  }
  const handleItemMaster = (name: any, val: any) => {

    if (val != null) {

      setPackingInward({ ...packingInward, itemId: val.itemid, "itemName": val.itemName, "itemUOM": val.itemUOM });

    }
    else {
      packingInward.itemId = 0;
      packingInward.itemName = '';
      packingInward.itemUOM = '';
    }
  }
  async function savePackingInward(e) {
    e.preventDefault();
    setLoading(true);
    const entryNos = await brandStore.getEntryNo('PackingInward');
    packingInward.packingInwardId = entryNos.entryNo + 1;

    packingInward.packingInwardDetails = packingInwardDetails
    const status = await packingInwardStore.savePackingInward(packingInward);

    if (status === "Success") {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/PackingInward/PackingInwardDetail');
  }

  async function fetchPreLoadingData() {
    await brandStore.getEntryNo('PackingInward')
    await loadPackingItem();
    await loadInwardItemMaster();
    await loadTeamDetail();
    setLoading(false)
  }

  useEffect(() => {
    fetchPreLoadingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {
        isLoading ?
          <ProgressBar /> :
          <>
            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>

                <div className='hrBox'>
                  <h3>Packing Inward</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Entry No <span>*</span></label>
                          <input  type="text" className=''
                            name='packingInwardId' disabled
                            value={packingInward.packingInwardId = brandStore.entryNo.entryNo + 1}
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder='Entry No..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input  type="date"
                            name='packingInwardDate'
                            value={packingInward.packingInwardDate  = packingInward.packingInwardDate ?? new Date().toISOString().substr(0, 10)}
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.packingInwardDate && <p style={{ color: 'red' }}>{errors.packingInwardDate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Team Name  <span>*</span></label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={loadTeamDetails}
                            getOptionLabel={(option: any) => option.teamMasterName}
                            onChange={(event, val) => handleTeamValue('teamId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select TeamName..'
                                name='teamName'
                              />}
                          />
                          {errors.teamName && <p style={{ color: 'red' }}>{errors.teamName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label> Item Name<span>*</span></label>
                          {/* <Autocomplete size="small"
                            disablePortal={true}
                            id="combo-box-demo"
                            options={loadItemMasters}
                            getOptionLabel={(option: any) => option.itemName}
                            onChange={(event, val) => handleItemMaster('itemId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '100%' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select ItemName..'
                                name='itemId'
                              />}
                          /> */}

                          <AutoComplete
                            options={inwardStore?.loadItemMasters}
                            emitOption={(val) => handleItemMaster('itemId', val)}
                            placeholder={'Select Item Name..'}
                            clsName='full-width'
                            getOptionLabel='itemName'
                          />
                          {errors.itemName && <p style={{ color: 'red' }}>{errors.itemName}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label> UOM<span>*</span></label>
                          <input  type="text" className=''
                            value={packingInward.itemUOM} name='itemUOM'
                            style={{ width: "100%" }} placeholder=' Uom..'></input>

                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Inward Qty<span>*</span></label>
                          <input  type="text" className=''
                            value={packingInward.packingQty} name='packingQty'
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder='Inward Qty..'></input>
                          {errors.packingQty && <p style={{ color: 'red' }}>{errors.packingQty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={addPackingInward}>Add</button>
                      </div>
                      <div className='col-md-12'>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Inward Item Name</th>
                                <th scope="col">Inward Item ID</th>
                                <th scope="col">UOM </th>
                                <th scope="col">Inward Qty </th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                packingInwardDetails.map((val, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{val.InwardItemName}</td>
                                      <td>{val.InwardPackingItemId}</td>
                                      <td>{val.InwardItemUOM}</td>
                                      <td>{val.InwardPackingQty}</td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>

                  <button className='secondaryBtn' onClick={handleChangeview}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={savePackingInward}>Submit</button>
                </div>

                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>

                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>

                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>

      }
    </>
  );

});

export default PackingInward;
