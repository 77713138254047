// src/components/PDFViewer.tsx
import React, { useEffect, useRef } from 'react';

interface PDFViewerProps {
    fileUrl: string;
    showPdf: boolean;
    setShowPDF: (value: boolean) => void;
}

const EmbedPDFViewer: React.FC<PDFViewerProps> = ({ fileUrl, showPdf, setShowPDF }) => {
    useEffect(() => {
        if (showPdf) {
            const newWindow = window.open('', '_blank');
            if (newWindow) {
                newWindow.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>PDF Viewer</title>
                    </head>
                    <body style="height: 100vh; margin: 0;">
                    <div id="viewer" style="width: 100%; height: 100%;"></div>
                    <script type="text/javascript" src="https://cloudpdf.io/viewer.min.js"></script>
                    <script>
                        const config = { 
                            documentId: '3925ce97-885a-47fd-9bcc-9019e3abba17',
                            darkMode: true, 
                        };
                        CloudPDF(config, document.getElementById('viewer')).then((instance) => {
            
                        });
                    </script> 
                    </body>
                    </html>
          `);
                newWindow.document.close();
            }
            setShowPDF(false)
        }
    }, [fileUrl, showPdf]);

    return null;
};

export default EmbedPDFViewer;
