import React, { useEffect, useRef, useState, } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Image, Modal, Spinner, } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { IIdentDetails, IIndent } from './model';
import { Autocomplete, TextField } from '@mui/material';
import indentValidation, { saveValidation } from './validation';
import AutoComplete from '../../common/shared/autoComplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import { removeDuplicates } from '../../common/shared/utils';

const Indent = observer((): JSX.Element => {
  const { commonStore, indentStore } = useStores();
  const [indent, setIndent] = useState<IIndent>({});
  const [indentDetail, setIndentDetail] = useState<IIdentDetails>({});
  const [indentDetails, setIndentDetails] = useState<IIdentDetails[]>([]);
  const [isloading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const { loadIndentItemName, loadIndentItemNames } = commonStore;
  const [errors, setErrors] = useState<any>({});
  const [isoutlet, setOutlet] = useState(false);
  const [isWarehouse, setWarehouse] = useState(false);
  const [submitbtn, setSubmitbtn] = useState(false);

  const { loadIndentWarehouse, loadIndentWarehouses, loadIndentOutlet, loadIndentOutlets,
    loadEmployee, loadEmployees, } = commonStore;

  function handleChangeInput(event: any) {
    const { name, value } = event.target;
    setIndent({ ...indent, [name]: value })
  }

  function addIntent(e) {
    let newObject: IIdentDetails = {};
    e.preventDefault();
    let error: any = {};

    error = indentValidation(indent);
    setErrors(error)
    if (Object.keys(error).length === 0) {
      indentDetail.iitemid = indent.itemid
      indentDetail.IitemName = indent.itemname
      indentDetail.iuom = indent.uom
      indentDetail.iindentqty = indent.indentqty
      setIndentDetails([...indentDetails, indentDetail])
      setIndentDetail(newObject);
      setIndent({ ...indent, itemid: 0, itemname: '', uom: '', indentqty: '' });
      setSubmitbtn(true);
    }
  }

  const handleEmployeeValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setIndent({ ...indent, raisedby: val.employeeId, employeeName: val.employeeName });
    }
    else {
      indent.employeeName = '';
    }
  }

  const handleOutletValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setIndent({ ...indent, outletid: val.outletid, outletname: val.outletname });
    }
    else {
    }

  }

  const handleWareHouseValue = (name: any, event: object, val: any) => {
    if (val != null) {
      setIndent({ ...indent, [name]: val.warehouseid, warehousename: val.warehousename });
    }
    else {
    }

  }

  const handleItemName = (name: any, event: object, val: any) => {

    if (val != null) {
      setIndent({ ...indent, [name]: val.itemid, itemname: val.itemname, uom: val.uom });
    }
    else {
      indent.itemname = '';
    }
  }

  const saveIndent = async (e) => {
    e.preventDefault();
    const entryNos = await indentStore.getEntryNo('GeneralIndent');
    indent.indentno = entryNos.entrynumber;
    if (!indent?.remarks) {
      indent.remarks = ""
    }
    indent.generalIndentDetails = indentDetails;
    let error: any = {};
    error = saveValidation(indent);
    setErrors({ ...error })
    if (Object.keys(error)?.length === 0) {
      setLoading(true);
      const status = await indentStore.saveIndent(indent);
      if (status === "Success") {
        setLoading(false);
        setSuccessModal(true)
      } else {
        setLoading(false);
        setFailureModal(true);
      }
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);

  const handleSuccessModalClose = () => {
    navigate('/Admin/Indent/IndentDetail');
  }

  const handleCancel = () => {
    navigate('/Admin/Indent/IndentDetail');
  }

  function handleChangeInputvalue(event: any) {
    const { name, value } = event.target;
    if (name === 'offerType' && value === '') {
      setOutlet(false);
      setWarehouse(false);

    }
    if (value === 'Outlet') {
      setOutlet(true);
      setWarehouse(false);

    }
    if (value === 'Warehouse') {
      setOutlet(false);
      setWarehouse(true);
    }
    setIndent({ ...indent, [name]: value })
  }

  function handleRemove(index) {
    indentDetails.splice(index, 1)
    setIndentDetails(_.cloneDeep(indentDetails))
  }

  async function fetchpreloadnigData() {
    setLoading(false);
    await Promise.all([indentStore.getEntryNo('GeneralIndent'), loadIndentItemName(),
    loadEmployee(), loadIndentWarehouse(), loadIndentOutlet(),])
  }

  const isCurrentPage = useRef(true);

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchpreloadnigData();
      isCurrentPage.current = false;
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        isloading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>

            <div className='container'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox'>
                <div className='hrBox'>
                  <h3> Indent</h3>
                </div>
                <div className='inputBoxLists pd-b-0'>
                  <div className='LgInputListsBox mr-left-0'>
                    <div className='row'>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Indent No </label>
                          <input type="text"
                            value={indent.indentno = indentStore.entryNo.entrynumber}
                            onChange={handleChangeInput}
                            name='indentno'
                            style={{ width: "50%" }} placeholder='Indent No..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-6'>
                        <div className='inputBox'>
                          <label>Date <span>*</span></label>
                          <input type="date" style={{ width: "70%" }}
                            value={indent.indentdate = indent.indentdate ?? new Date().toISOString().substr(0, 10)} name='indentdate'
                            onChange={handleChangeInput}
                            max={moment().format('YYYY-MM-DD')}
                            placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                          {errors.indentdate && <p style={{ color: 'red' }}>{errors.indentdate}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Item Name  <span>*</span></label>
                          <AutoComplete
                            clsName='autoComplete'
                            placeholder="Select Item Name.."
                            value={indent?.itemname}
                            emitOption={(val) => handleItemName('itemid', {}, val)}
                            options={loadIndentItemNames}
                            getOptionLabel={'itemname'}
                          />
                          {errors.itemname && <p style={{ color: 'red' }}>{errors.itemname}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label>UOM </label>
                          <input type="text"
                            value={indent.uom} name='uom'
                            className='' style={{ width: "70%" }} placeholder='Enter UOM..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-4'>
                        <div className='inputBox'>
                          <label> Qty</label>
                          <input type="text"
                            value={indent.indentqty} name='indentqty'
                            onChange={handleChangeInput}
                            className='' style={{ width: "60%" }} placeholder='Enter Qty..'></input>
                          {errors.indentqty && <p style={{ color: 'red' }}>{errors.indentqty}</p>}
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>

                      <div className='col-sm-2'>
                        <button className='dfBtn' onClick={addIntent}>Add</button>
                      </div>
                      <div className='col-md-12'>
                        <div className='tableBox'>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col"> Item Name</th>
                                <th scope="col"> UOM</th>
                                <th scope="col"> Qty </th>
                                <th scope='col'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {indentDetails.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.IitemName}</td>
                                    <td>{val.iuom}</td>
                                    <td>{val.iindentqty}</td>
                                    <td><button className='delete' onClick={() => handleRemove(key)} ><FontAwesomeIcon icon={faTrashAlt} /></button></td>

                                  </tr>
                                )
                              })
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='inputBox' >
                          <label>Raised From <span>*</span></label>
                          <select className="form-selected" id="Mode" name="indentfrom" onChange={handleChangeInputvalue} >
                            <option value="">Select Search Criteria</option>
                            <option value="Outlet">Outlet </option>
                            <option value="Warehouse">Warehouse </option>
                          </select>
                        </div>
                        {errors.indentfrom && <p style={{ color: 'red' }}>{errors.indentfrom}</p>}
                      </div>
                      <div className='col-sm-4' style={{ marginTop: '20px' }}>

                        {
                          isoutlet ?
                            <>
                              <div className='inputBox'>
                                <Autocomplete size="small"
                                  disablePortal
                                  id="combo-box-demo"
                                  options={loadIndentOutlets}
                                  getOptionLabel={(option: any) => option.outletname}
                                  onChange={(event, val) => handleOutletValue('outletid', event, val)}
                                  renderInput={(params: any) =>
                                    <TextField  {...params} style={{ width: '25ch' }}
                                      id="outlined-size-small"
                                      color='info'
                                      size="small"
                                      type="text"
                                      placeholder='Select Outlet..'
                                      name='outletid'
                                    />}
                                />

                              </div>
                              {errors.outletid && <p style={{ color: 'red' }}>{errors.outletid}</p>}
                              <div className='vertical-space-10'></div>
                            </> : null
                        }
                        {
                          isWarehouse ?
                            <>
                              <div className='inputBox'>
                                <Autocomplete size="small"
                                  disablePortal
                                  id="combo-box-demo"
                                  options={loadIndentWarehouses}
                                  getOptionLabel={(option: any) => option.warehousename}
                                  onChange={(event, val) => handleWareHouseValue('warehouseid', event, val)}
                                  renderInput={(params: any) =>
                                    <TextField  {...params} style={{ width: '25ch' }}
                                      id="outlined-size-small"
                                      color='info'
                                      size="small"
                                      type="text"
                                      placeholder='Select WareHouse Name..'
                                      name='warehouseid'
                                    />}
                                />

                              </div>
                              {errors.warehouseid && <p style={{ color: 'red' }}>{errors.warehouseid}</p>}
                              <div className='vertical-space-10'></div>
                            </> : null
                        }
                      </div>
                      <div className='vertical-space-10'></div>
                      <div className='col-md-12'>
                        <div className='inputBox'>
                          <label>Raised By <span>*</span> </label>
                          <Autocomplete size="small"
                            disablePortal
                            id="combo-box-demo"
                            options={removeDuplicates(loadEmployees, 'employeeName')}
                            getOptionLabel={(option: any) => option.employeeName}
                            onChange={(event, val) => handleEmployeeValue('employeeId', event, val)}
                            renderInput={(params: any) =>
                              <TextField  {...params} style={{ width: '35ch' }}
                                id="outlined-size-small"
                                color='info'
                                size="small"
                                type="text"
                                placeholder='Select Employee..'
                                name='employeeId'
                              />}
                          />
                        </div>
                        {errors.raisedby && <p style={{ color: 'red' }}>{errors.raisedby}</p>}
                        <div className='vertical-space-10'></div>
                      </div>
                      <div className='col-md-8'>
                        <div className='inputBox'>
                          <label> Remarks</label>
                          <input type="text" className=''
                            value={indent.remarks} name='remarks'
                            onChange={handleChangeInput}
                            style={{ width: "100%" }} placeholder='Enter Remarks..'></input>
                        </div>
                        <div className='vertical-space-10'></div>
                      </div>
                    </div>
                  </div>
                  <div className='vertical-space-10'></div>
                </div>
                <div className='btnBox'>
                  <button className='secondaryBtn' onClick={handleCancel}>List</button>
                  <button className='dfBtn' type='submit' disabled={!submitbtn} onClick={saveIndent}>Submit</button>
                </div>
                <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/checked.png')} />
                      </div>
                      <h4>Succesfully Submitted</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                  <Modal.Body>
                    <div className='Details Success'>
                      <div className='imgBox'>
                        <Image src={require('../../../gsmecom/images/warning.png')} />
                      </div>
                      <h4>Failed</h4>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleFailureModalClose}>
                      Ok
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </>
      }
    </>
  );

});

export default Indent;
