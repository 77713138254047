
function ledgerGroupValidation(ledgerGroup){
  let errors :any={};
  
    
   
    if(!ledgerGroup.ledgerGroupName){
      errors.ledgerGroupName = "Enter Group Name";
    }
    if(!ledgerGroup.subGroup){
      errors.subGroup = "Enter Sub Group";
    }
    if(!ledgerGroup.undergroup){
      errors.undergroup = "Select under Group";
    }
    
    
  return errors;
};
  
  export default ledgerGroupValidation;