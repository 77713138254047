import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "react-bootstrap";
import useStores from "../../hooks";
import moment from "moment";

import * as XLSX from 'xlsx';
import { currentPageDatas, getCurrentMonthFirstDate } from "../../common/shared/utils";
import { Pagination, Stack } from "@mui/material";

const GSTRAnnexure2 = () => {

    const { gstrStore } = useStores()

    const tableHeadings = ["PARTICULARS", "VOUCHERCOUNT", "TAXABLEVALUE", "IGST", "SGST", "CGST", "CESS", "TAXAMOUNT", "INVOICEAMOUNT"];

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState<any>({})

    const [localFields, setLocalFields] = useState<any>({ mode: '1', fdate: getCurrentMonthFirstDate()?.firstDate, tdate: getCurrentMonthFirstDate()?.currentDate })

    const [gstrAnnexureList, setGstrAnnexureList] = useState<any[]>([])

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    async function createExportExcelObj() {
        if (localFields?.fdate && localFields?.tdate) {
            setLoading(true);

            let excelData: { sheetName: string; data: any[] };
            let dataSets: { sheetName: string; data: any[] }[] = [];

            let body1 = {
                ...localFields,
                mode: 1,
            }

            // [{"PARTICULARS":"B2C(Small) Invoices","VOUCHERCOUNT":1,"Cgstper":9.00,"Sgstper":9.00,"Cgsvalue":1800.00,"sgstvalue":1800.00,"Taxvalue":3600.00,"CessAmount":0,"EcomGstIn":0}]
            const gstrAnnexureList1 = await gstrStore.getGstr2AnnexureList(body1);
            if (gstrAnnexureList1?.length) {
                let dataArry: [] = gstrAnnexureList1?.map((each) => {
                    let obj: {} = {
                        PARTICULARS: each?.PARTICULARS,
                        VOUCHERCOUNT: each?.VOUCHERCOUNT,
                        TAXABLEVALUE: each?.Taxvalue,
                        IGST: each?.igst,
                        SGST: each?.sgstvalue,
                        CGST: each?.Cgsvalue,
                        CESS: each?.CessAmount,
                        TaxAmount: each?.taxAmount,
                        InvoiceAmount: each?.invoiceAmount,
                        EconGstIn: each?.EcomGstIn
                    }
                    return obj;
                })
                excelData = {
                    sheetName: 'Sheet 1',
                    data: dataArry
                }
                dataSets?.push(excelData);
            }
            // let body2 = {
            //     ...localFields,
            //     mode: 2,
            // }
            // const gstrAnnexureList2 = await gstrStore.getGstrAnnexureList(body2)
            // if (gstrAnnexureList2?.length) {
            //     let dataArry: [] = gstrAnnexureList2?.map((each) => {
            //         let obj: {} = {
            //             HSN: each?.Hsncode,
            //             Description: each?.itemdescription,
            //             UQC: each?.Unitname,
            //             TotalQuantity: each?.TotalQty,
            //             TotalValue: each?.Totalamount,
            //             TaxableValue: each?.Taxamblevalue,
            //             IntegratedTaxAmount: each?.IntegratedTax,
            //             StateTaxAmount: each?.sgstvalue,
            //             CentalTaxAmount: each?.Cgsvalue,
            //             CessAmount: each?.CessAmount
            //         }
            //         return obj;
            //     })
            //     excelData = {
            //         sheetName: 'HSN',
            //         data: dataArry
            //     }
            //     dataSets?.push(excelData);
            // }
            // let body3 = {
            //     ...localFields,
            //     mode: 3,
            // }
            // const gstrAnnexureList3 = await gstrStore.getGstrAnnexureList(body3)
            // if (gstrAnnexureList3?.length) {
            //     let dataArry: [] = gstrAnnexureList3?.map((each) => {
            //         let obj: {} = {
            //             URType: each?.Membertype,
            //             GSTIN_UIN_of_Recipient: each?.uinofreceipt,
            //             Invoice_Advance_Receipt_Number: each?.billno,
            //             Invoice_Advance_Receipt_Date: '',
            //             Note_Refund_Voucher_Number: each?.rtnno,
            //             Note_Refund_Voucher_Date: moment(each?.rtndate)?.format('DD-MMM-YYYY'),
            //             Document_Type: '',
            //             Place_Of_Supply: each?.state,
            //             Note_Refund_Voucher_Value: each?.NoteRefundVoucherValue,
            //             TaxRate: each?.taxper,
            //             Rate: '',
            //             Taxable_Value: each?.Taxvalue,
            //             Cess_Amount: each?.CessAmount,
            //             Pre_GST: each?.NoteRefundVoucherValue
            //         }
            //         return obj;
            //     })
            //     excelData = {
            //         sheetName: 'SalRtnUnReg',
            //         data: dataArry
            //     }
            //     dataSets?.push(excelData);
            // }
            // let body4 = {
            //     ...localFields,
            //     mode: 4,
            // }
            // const gstrAnnexureList4 = await gstrStore.getGstrAnnexureList(body4)
            // if (gstrAnnexureList4?.length) {
            //     let dataArry: [] = gstrAnnexureList4?.map((each) => {
            //         let obj: {} = {
            //             Location: each?.Location,
            //             Nature_Of_Document: each?.sales,
            //             Sr_No_From: each?.fromno,
            //             Sr_No_To: each?.tono,
            //             TotalNumber: each?.nos,
            //             Cancelled: each?.canceled,
            //         }
            //         return obj;
            //     })
            //     excelData = {
            //         sheetName: 'DOC',
            //         data: dataArry
            //     }
            //     dataSets?.push(excelData);
            // }

            setLoading(false)
            exportToExcel(dataSets)
        }

    }

    function exportToExcel(dataSets: { sheetName: string; data: any[] }[]) {


        const workbook = XLSX.utils.book_new();

        let fromDate = moment(localFields?.fdate).format('DD-MMM-YYYY');
        let toDate = moment(localFields?.tdate).format('DD-MMM-YYYY');

        let staticTable = [
            'Location: Ganga Supermarket-NKL', 'Company: Ganga Super Market', 'User: Ganga Supermarket', `Generated Time: ${new Date()}`,
            `Period: ${fromDate} to ${toDate}`, 'Locations: Ganga Supermarket-NKL,Namakkal - WH'
        ]?.map((each) => {
            let obj: {} = {
                GSTR_Annexure: each,
            }
            return obj;
        })

        dataSets.forEach(({ sheetName, data }) => {
            const worksheetData = [
                Object.keys(staticTable[0]),
                ...staticTable.map(obj => Object.values(obj)),
                ['', '', ''],
                Object.keys(data[0]),
                ...data.map(obj => Object.values(obj)),
            ];
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        });

        const today = moment();

        XLSX.writeFile(workbook, `GSTR Annexure ${today.format('DD-MM-YYYY')}.xlsx`);

    }

    async function fetchInitialLoad() {
        if (localFields?.fdate && localFields?.tdate) {
            const gstrAnnexureList = await gstrStore.getGstr2AnnexureList(localFields)
            setGstrAnnexureList([...gstrAnnexureList])
        }
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchInitialLoad()
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])


    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (gstrAnnexureList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(gstrAnnexureList)
        }
    }, [gstrAnnexureList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(gstrAnnexureList, value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {loading ?
                <div className='SpinnerBox'>
                    <Spinner animation="border" role="status"></Spinner>
                </div> :
                <div className="" style={{ display: 'flex', alignItems: "center", justifyContent: 'center', width: '100%' }}>
                    <div className='' style={{ width: '100%' }}>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>

                            <div className='hrBox'>
                                <h3>GSTR Annexure</h3>
                            </div>

                            <div className='inputBoxLists'>
                                <div className='ItemInwardInputBox'>
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label>From Date <span>*</span></label>
                                                <input type="date"
                                                    name="fdate"
                                                    onChange={handleInputChange}
                                                    value={localFields.fdate}
                                                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="inputBox">
                                                <label>To Date <span>*</span></label>
                                                <input type="date"
                                                    name="tdate"
                                                    onChange={handleInputChange}
                                                    value={localFields.tdate}
                                                    style={{ width: "100%" }} placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MMMM YYYY"></input>
                                            </div>
                                        </div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={fetchInitialLoad}
                                            >Generate</button>
                                        </div>
                                        <div className="col-sm-1" style={{ marginTop: '24px' }}>
                                            <button className='secondaryBtn' type='submit'
                                                onClick={createExportExcelObj}
                                            >Excel</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='row'>
                                    <div className='col-sm-5'>
                                        <div className='btnBox'>
                                            <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{gstrAnnexureList?.length} Records</button>
                                        </div>
                                    </div>
                                    <div className=' col-sm-7' >
                                        <Stack spacing={2}>
                                            <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                        </Stack>
                                    </div>
                                </div>
                                <div className='vertical-space-20'></div>
                                <div className='tableListDetails'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {tableHeadings?.map((heading, index) => {
                                                    return (
                                                        <th key={index}>{heading}</th>
                                                    )
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData?.map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{each?.PARTICULARS}</td>
                                                        <td>{each?.VOUCHERCOUNT}</td>
                                                        <td>{each?.Taxvalue}</td>
                                                        <td>{each?.TotalQty}</td>
                                                        <td>{each?.sgstvalue}</td>
                                                        <td>{each?.Cgsvalue}</td>
                                                        <td>{each?.taxAmount}</td>
                                                        <td>{each?.InvoiceAmount}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            }
        </>
    )
}

export default GSTRAnnexure2;