import { observable } from "mobx";
export interface IAssignBatchNo{
    assignNo?:number;
    assigndate?:string;
    supplierId?:number;    
    assignDetails?:IAssignBatchNoDetails[];
    createdBy?:number
   
}
export interface IAssignBatchNoDetails{
    inwardmasterid?:number;
    inwarddate?:string;
    itemId?:number;
    itemName?:string;
    batchqty?:number;
    inwardQty?:number;
    mfg_Date?:string;
    exp_Date?:string;
    batchNo?:string;
    existingQty?:number;
    qty?:number;
    expiryDays?:number;

}

export interface IAssignBatchNoSearchoptions{
    searchOption?:string;
    searchValue?:string;
}
export interface ILoadOptions{
    flag?:string;
    supplierId?:number;
    inwardNo?:number;
    itemId?:number;
}

const assignBatchNoDetails=observable<IAssignBatchNo>({
    assignNo:0,
    assigndate:'',
    supplierId:0,   
    assignDetails:[],
    
});

export default assignBatchNoDetails;