import { observable } from "mobx";
export interface ITeamMaster{
    teamMasterId?:number;
    teamMasterName?:string;
    employeeDetails?:ITeamMasters[];

}   
export interface ITeamMasters{
    employeeId?:number;
    employeeName?:string;
    teamMasterDetailId?:number;
}
export interface ITeamMasterSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const teamMasterDetail=observable<ITeamMaster>({  
    teamMasterId:0,
    teamMasterName:'',
    employeeDetails:[],
});

export default teamMasterDetail;