import { IInwardDetails, IItemInward } from "../model";

export function addInwardDetailValidation(itemInWard: IInwardDetails) {
  let errors: any = {};

  if (!itemInWard.inwardItemName) {
    errors.inwardItemName = "Select Item";
  }
  if (!itemInWard.inwardItemQuantity) {
    errors.inwardItemQuantity = "Enter Bill Quantity";
  }
  if (!itemInWard.inwardItemRate) {
    errors.inwardItemRate = "Enter Item Rate";
  }
  // if (!itemInWard.barcode) {
  //   errors.barcode = "Enter Barcode";
  // }
  // if (!itemInWard.batchNo) {
  //   errors.batchNo = "Enter Batch No";
  // }
  if (!itemInWard?.inwardItemMrpRate) {
    errors.inwardItemMrpRate = "Enter MRP Rate";
  }
  if (!itemInWard.exp_Date) {
    errors.exp_Date = "Enter Expiry Date";
  }
  if (!itemInWard.mfg_Date) {
    errors.mfg_Date = "Enter Mfg Date";
  }

  return errors;
}
function inwardMasterValidation(itemInWard, type: 'itemInward' | 'directInward') {
  let errors: any = {};

  if (!itemInWard.inwardDate) {
    errors.inwardDate = "Select Inward Date";
  }
  if (!itemInWard.supplierId) {
    errors.supplierId = "Select Supplier";
  }
  if (!itemInWard.warehouseId && type == 'itemInward') {
    errors.warehouseId = "Select Warehouse";
  } 
  if (!itemInWard.outletId && type == 'directInward') {
    errors.outletId = "Select Warehouse";
  }
  if (!itemInWard.billNo) {
    errors.billNo = "Enter Bill Number";
  }
  if (!itemInWard.billDate) {
    errors.billDate = "Select Bill Date";
  }
  if(!itemInWard?.inwardDetailList?.length) {
    errors.inwardDetailList = 'Add Items to the Grid'
  }
  return errors;
}
export function addLedgerValidation(ledger) {
  let error: any = {};

  if (!ledger.ledgerId) {
    error.ledgerId = "Select Ledger Name"
  }
  if (!ledger.ledgerAmount) {
    error.ledgerAmount = "Enter Amount"
  }

  return error;
}

export default inwardMasterValidation;