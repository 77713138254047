import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Accordion } from "react-bootstrap";
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';
import moment from 'moment';

const ResignationEntryList = () => {

    const { employeePromotionStore, departmentStore, designationStore, employeeStore } = useStores();

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [resignationList, setResignationList] = useState<any[]>([])
    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])


    const handleCreate = () => {
        navigate('/Admin/ResignationMaster')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function additionalApis() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        setEmployeeMaster([...employeeMaster])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
    }

    async function fetchPreLoadngData() {
        const resignationList = await employeePromotionStore?.loadResignationMaster()
        if (resignationList?.length) {
            setResignationList([...resignationList])
        }
        additionalApis()
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (resignationList && resignationList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(resignationList)
        }
    }, [resignationList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(resignationList?.slice()?.sort((a, b) => b.vchr_no - a.vchr_no), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={resignationList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{resignationList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Date of Joining</th>
                                            <th scope="col">Designation</th>
                                            <th scope="col">Department</th>
                                            <th scope="col">Resignation Request</th>
                                            <th scope="col">Manager</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((each, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{moment(each?.RM_Date).format('DD-MMM-YYYY')}</td>
                                                        <td>{each?.Employee_Name}</td>
                                                        <td>{moment(each?.RM_DOJ).format('DD-MMM-YYYY')}</td>
                                                        <td>{designationList?.find((cate) => cate?.designationId === each?.RM_DegnID)?.designationName}</td>
                                                        <td>{departmentList?.find((cate) => cate?.departmentId === each?.RM_DeptID)?.department}</td>
                                                        <td>{moment(each?.RM_ResignReqDate).format('DD-MMM-YYYY')}</td>
                                                        <td>{employeeMaster?.find((emp) => emp?.EmployeeId === each?.RM_ManagerID)?.Employee_Name}</td>
                                                        <td>{each?.RM_Reason}</td>
                                                        <td><button className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                        <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default ResignationEntryList;