import React, { useState, useEffect, ChangeEvent, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Form, Modal, Spinner, Button, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IItemSearchoptions } from '../../Warehouse/ItemMaster/model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const AssignProductGroupingDetail = observer((): JSX.Element => {
  const { assignProductStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { loadAssignProducts, LoadingAssignProduct, deleteAssignProduct, getAssignProductDetails, getAssignProducts } = assignProductStore;
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isAssignProductDetails, setAssignProductDetails] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  async function fetchPreLoadingData() {
    const userRights = await userCreationStore?.getUserRight('Assign_products')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await LoadingAssignProduct();
    setLoading(false);
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleModalClose = () => setAssignProductDetails(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false)
    fetchPreLoadingData();
  }

  const handleCreate = () => {
    navigate('/Admin/AssignProduct');
  }

  const handleEdit = (productId) => {
    navigate('/Admin/AssignProduct/' + productId);
  }
  const handleDelete = async (productId) => {
    setLoading(true);
    const data = await deleteAssignProduct(productId);
    if (data === 'Success') {
      setLoading(false);
      setSuccessModal(true)
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleProductDetails = async (productId) => {
    setLoading(true);
    await getAssignProductDetails(productId);
    setLoading(false);
    setAssignProductDetails(true);
  }
  const isCurrentPage = useRef(true)
  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadingData();
      isCurrentPage.current = false
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (loadAssignProducts?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(loadAssignProducts)
    }
  }, [loadAssignProducts])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(loadAssignProducts, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };


  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={loadAssignProducts}
                searchTitles={[{ key: "itemName", value: "Item Name" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{loadAssignProducts?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope='col'>ProductId </th>
                      <th scope='col'>Main Part Name  </th>
                      <th scope='col'>Details</th>
                      <th scope='col'>Create </th>
                      <th scope='col'>Update </th>
                      <th scope='col'>Delete </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.assignProductID}</td>
                            <td>{val.mPartItemName}</td>
                            <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => handleProductDetails(val.assignProductID)}>Details</button></td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => handleEdit(val.assignProductID)} /> </button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDelete(val.assignProductID)} /></button></td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </table>
              </div>

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed Delete</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              {
                isAssignProductDetails ?
                  <Modal show={isAssignProductDetails} onHide={handleModalClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">Group Part Id</th>
                              <th scope="col">Group Part Name</th>
                              <th scope="col">Web Name </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              assignProductStore.loadAssignProductDeatils.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.groupPartItemId}</td>
                                    <td>{val.groupPartItemName}</td>
                                    <td>{val.productWebName}</td>
                                  </tr>
                                )
                              })
                            }

                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal>
                  : null
              }

            </div>
          </>
      }
    </>

  );

});

export default AssignProductGroupingDetail;
