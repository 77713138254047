import React, { useEffect, useRef, useState, } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { IIdentSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas, getCurrentMonthFirstDate } from '../../common/shared/utils';

function IndentDetail() {
  const { indentStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getIndentDetails, indentListDetail } = indentStore;
  const [isLoading, setLoading] = useState(true);
  const [isIndentShow, setIndentModal] = useState(false);
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const [searchFileds, setSearchFields] = useState({ fromdate: getCurrentMonthFirstDate()?.firstDate, todate: getCurrentMonthFirstDate()?.currentDate })

  const deleteIndent = async (indentid) => {
    setLoading(true);
    const status = await indentStore.deleteIndent(indentid);

    if (status === 'Success') {
      fetchIndentLoading();
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchIndentLoading();
  }

  const getIndentDetail = async (indentid) => {
    const data = await getIndentDetails(indentid);
    if (data !== undefined || data !== null) {
      setIndentModal(true);
    }
  }

  const handleCreate = () => {
    navigate('/Admin/Indent');
  }

  const handleClose = () => {
    setIndentModal(false);
  }

  async function fetchIndentLoading() {
    setLoading(false)
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Indent'), indentStore.getIndent()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
  }

  const customSort = (a, b): number => {
    const numericA = Number(a.indentno.split('-')[1]);
    const numericB = Number(b.indentno.split('-')[1]);

    return numericB - numericA;
  };

  const sortedData = (indentStore?.indentList?.length > 0) ? indentStore?.indentList?.slice().sort(customSort) : [];

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchIndentLoading();
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (indentStore?.indentList && indentStore?.indentList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(indentStore?.indentList)
    }
  }, [indentStore?.indentList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(indentStore?.indentList?.slice()?.sort((a, b) => Number(b.indentno.split('-')[1]) - Number(a.indentno.split('-')[1])), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  function handleFindDate(date) {
    if (searchFileds?.fromdate && searchFileds?.todate) {
      const yesterday = new Date(searchFileds?.fromdate);
      yesterday.setDate(yesterday.getDate() - 1)
      if (new Date(date) >= yesterday && new Date(date) < new Date(searchFileds?.todate)) {
        return true;
      } else {
        return false
      }
    } else if (searchFileds?.fromdate) {
      if (new Date(date) > new Date(searchFileds?.fromdate)) {
        return true
      } else {
        return false
      }
    } else if (searchFileds?.todate) {
      if (new Date(date) < new Date(searchFileds?.todate)) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>
              <div className='outletInputField inputFormBox LgInputField' style={{ width: '90%' }}>
                <div className='inputBoxLists'>
                  <div className='ItemInwardInputBox'>
                    <div className='row'>
                      <div className="col-md-2">
                        <label>From Date</label>
                        <div className="inputBox">
                          <input type="date"
                            placeholder="DD/MMM/YYYY"
                            data-date="" style={{ width: "100%" }}
                            data-date-format="DD MMMM YYYY"
                            value={searchFileds.fromdate}
                            onChange={(e) => setSearchFields({ ...searchFileds, fromdate: e.target.value })}
                            name="fromdate"
                          ></input>
                        </div>
                        <div className="vertical-space-10"></div>
                      </div>
                      <div className="col-md-2">
                        <div className="inputBox">
                          <label> To Date</label>
                          <input type="date"
                            style={{ width: "100%" }}
                            placeholder="DD/MMM/YYYY"
                            data-date=""
                            data-date-format="DD MMMM YYYY"
                            value={searchFileds.todate}
                            onChange={(e) => setSearchFields({ ...searchFileds, todate: e.target.value })}
                            name="todate"
                          ></input>
                        </div>
                        <div className="vertical-space-10"></div>
                      </div>
                      <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={sortedData}
                        searchTitles={[{ key: "indentno", value: "Indent No" }, { key: "indentfrom", value: "Indent From" }, { key: "indentby", value: "Indent By" }]}
                        emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <div className='btnBox'>
                        <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{sortedData?.length} Records</button>
                      </div>
                      <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                        <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                      </Stack>
                    </div>
                    <div className='vertical-space-20'></div>
                    <div className='tableListDetails'>
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope='col'>Indent No </th>
                            <th scope='col'>Date  </th>
                            <th scope='col'>Indent From  </th>
                            <th scope='col'>Indent By  </th>
                            <th scope='col'></th>
                            <th scope='col'>Create </th>
                            <th scope='col'>Update </th>
                            <th scope='col'>Delete </th>
                          </tr>
                        </thead>
                        <tbody>
                          {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                            (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                              return ((searchFileds?.fromdate && searchFileds?.todate) ? handleFindDate(val?.indentdate) : val?.indentdate) && (
                                <tr key={key}>
                                  <td>{val.indentno}</td>
                                  <td>{val.indentdate}</td>
                                  <td>{val.indentfrom}</td>
                                  <td>{val.indentby}</td>
                                  <td><button disabled={!userRights?.view} onClick={() => getIndentDetail(val.indentid)}>Detail</button></td>
                                  <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                  <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} /></button></td>
                                  <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteIndent(val.indentid)} /></button></td>
                                </tr>
                              )
                            }
                            )}

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              {
                isIndentShow ?
                  <Modal show={isIndentShow} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col"> Item Name</th>
                              <th scope="col"> UOM</th>
                              <th scope="col"> Qty</th>
                              {/* <th scope="col"></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {
                              indentListDetail?.map((val, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{val.itemname}</td>
                                    <td>{val.uom}</td>
                                    <td>{val.indentqty}</td>
                                    {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} /></button></td>                     */}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }
            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>
      }
    </>
  );

};

export default IndentDetail;
