
function assignBatchOutletValidation(assignBatchOutlet,assignBatchOutletDetails){
    let errors :any={};
    
       
      if(!assignBatchOutlet.assigndate){
        errors.assigndate = "Select Assign Date";
      }
      if(!assignBatchOutletDetails.inwardmasterid){
        errors.inwardId = "Select Inward Id";
      }
      if(!assignBatchOutlet.supplierId){
        errors.supplierId = "Select Supplier";
      }
    
      if(!assignBatchOutletDetails.itemId){
        errors.itemName = "Enter Item Name";
      }
      if(!assignBatchOutletDetails.batchqty){
        errors.batchqty = "Enter Qty";
      }
      if(!assignBatchOutletDetails.mfg_Date){
        errors.mfg_Date = "Select Mfg Date";
      }
      if(!assignBatchOutletDetails.exp_Date){
        errors.exp_Date = "Select Expiry Date";
      }
    
    return errors;
  };
    
    export default assignBatchOutletValidation;