import { observable } from "mobx";
import { getCurrentMonthFirstDate } from "../../../common/shared/utils";
export class IRackAllotment{
    rackEntryNo?:number;
    entryDate?:string = getCurrentMonthFirstDate()?.currentDate;
    outletid?:number; 
    outletName?:string; 
    itemId?:number;
    itemName?:string;
    rackNo?:string;    
    batchNo?:string;
    batchQty?:number;  
    rackallotmentDetails?:RackallotmentDetails[];
    
   
}
export interface RackallotmentDetails{
    rackAllotrackEntryNo?:number;
    rackAllotitemId?:number;
    rackAllotitemName?:string;
    rackAllotrackNo?:string;    
    rackAllotbatchNo?:string;
    rackAllotbatchQty?:number;
    
}
export interface IRackAllotmentSearchoptions{
    searchOption?:string;
    searchValue?:string;
}
const rackallotmentdts=observable<IRackAllotment>({
    rackEntryNo:0,
    entryDate:'',
    outletid:0,  
    outletName:'', 
    itemId:0,
    itemName:'',
    rackNo:'',    
    batchNo:'',
    batchQty:0,
    rackallotmentDetails:[],
    
});

export default rackallotmentdts;