import React, { useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import moment from "moment";
import { Modal, Image, Button, Spinner } from "react-bootstrap";
import ProgressBar from "../../common/shared/progressbar";
import { Autocomplete, TextField, Checkbox } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { IIResignationMaster } from "./model";
import { saveValidation } from "./validation";

const ResignationEntry = observer((): JSX.Element => {

    const { employeeStore, departmentStore, designationStore, payrollStore, jobMasterStore, employeePromotionStore } = useStores();
    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
    const [designationList, setDesignationList] = useState<any[]>([])
    const [departmentList, setDepartmentList] = useState<any[]>([])

    const [resignationEntry, setResignationEntry] = useState<IIResignationMaster>(new IIResignationMaster())

    async function saveResignation(e) {
        let error: any = {}
        error = saveValidation(resignationEntry)
        setErrors({ ...error })
        if (Object.keys(error)?.length === 0) {
            const status = await employeePromotionStore.saveResignationMaster(resignationEntry);
            if (status === "Success") {
                setLoading(false);
                setSuccessModal(true);
            }
            else {
                setLoading(false);
                setFailureModal(true);
            }
        }
    }

    function handleInputChange(event: any) {
        const { name, value } = event.target;
        handleAutoComplete(name, value)
    }

    function handleAutoComplete(name, value) {
        setResignationEntry({ ...resignationEntry, [name]: value })
    }

    const handleList = () => {
        navigate("/Admin/ResignationMaster/List");
    };
    const handleFailureModalClose = () => setFailureModal(false);
    const handleSuccessModalClose = () => {
        navigate("/Admin/ResignationMaster/List");
    };

    async function initialApicall() {
        const entryNo = await jobMasterStore?.getHREntryNo('ResignationMaster')
        setResignationEntry({ ...resignationEntry, rM_ID: Number(entryNo.entrynumber), rM_EntryNo: Number(entryNo.entrynumber) })
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        const departmentList = await departmentStore.getDepartment();
        const designationList = await designationStore.getDesignation();
        setEmployeeMaster([...employeeMaster])
        setDepartmentList([...departmentList])
        setDesignationList([...designationList])
        setLoading(false);
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApicall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);
    return (
        <>
            {isLoading ? (
                <ProgressBar />
            ) : (
                <>
                    <div className="vertical-space-20"></div>
                    <div className="outletInputField inputFormBox">
                        {/* <form > */}
                        <div className="hrBox">
                            <h3>Resignation Entry</h3>
                        </div>
                        <div className="inputBoxLists">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className='inputBox'>
                                        <label style={{ fontSize: '16px' }}>Entry No</label>
                                        <input type="text"
                                            name='rM_EntryNo' disabled
                                            readOnly value={resignationEntry?.rM_EntryNo}
                                            className='full-width' ></input>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Date</label>
                                        <input type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date="" data-date-format="DD MMMM YYYY"
                                            name="rM_Date"
                                            value={resignationEntry?.rM_Date}
                                        ></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Employee Name<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={employeeMaster}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, val) => handleAutoComplete('rM_EmpID', val?.EmployeeId)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Employee Name..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors.rM_EmpID && <p style={{ color: "red" }}>{errors.rM_EmpID}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}> Date of Joining</label>
                                        <input type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="rM_DOJ"
                                            value={resignationEntry?.rM_DOJ}
                                            onChange={handleInputChange}
                                        ></input>
                                    </div>
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Designation<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={designationList}
                                            getOptionLabel={(option: any) => option.designationName}
                                            onChange={(event, val) =>
                                                handleAutoComplete('rM_DegnID', val?.designationId)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Designation Name..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors.rM_DegnID && <p style={{ color: "red" }}>{errors.rM_DegnID}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Department<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={departmentList}
                                            getOptionLabel={(option: any) => option.department}
                                            onChange={(event, val) =>
                                                handleAutoComplete('rM_DeptID', val?.departmentId)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Department Name..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors.rM_DeptID && <p style={{ color: "red" }}>{errors.rM_DeptID}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label style={{ fontSize: '16px' }}>Resignation Request From</label>
                                        <input
                                            type="date"
                                            className="inputBoxDate"
                                            style={{ width: "100%", fontWeight: "lighter" }}
                                            placeholder="DD/MMM/YYYY"
                                            data-date=""
                                            data-date-format="DD MMMM YYYY"
                                            name="rM_ResignReqDate"
                                            value={resignationEntry?.rM_ResignReqDate}
                                            onChange={handleInputChange}
                                        ></input>
                                    </div>
                                    {errors.rM_ResignReqDate && <p style={{ color: "red" }}>{errors.rM_ResignReqDate}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox" >
                                        <label style={{ fontSize: '16px' }}>Manager<span>*</span> </label>
                                        <Autocomplete size="small"
                                            disablePortal
                                            id="combo-box-demo"
                                            options={employeeMaster}
                                            getOptionLabel={(option: any) => option.Employee_Name}
                                            onChange={(event, val) => handleAutoComplete('rM_ManagerID', val?.EmployeeId)}
                                            renderInput={(params: any) =>
                                                <TextField  {...params}
                                                    id="outlined-size-small"
                                                    color='info'
                                                    size="small"
                                                    type="text"
                                                    placeholder='Select Manager Name..'
                                                    name='ledgerGroupId'
                                                />}
                                        />
                                    </div>
                                    {errors.rM_ManagerID && <p style={{ color: "red" }}>{errors.rM_ManagerID}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="inputBox">
                                        <label>
                                            Reason <span>*</span>
                                        </label>
                                        <textarea className="" name="rM_Reason"
                                            value={resignationEntry?.rM_Reason}
                                            onChange={handleInputChange}
                                            style={{ width: "100%" }}
                                            placeholder="Enter Reason"
                                        ></textarea>
                                    </div>
                                    {errors.rM_Reason && <p style={{ color: "red" }}>{errors.rM_Reason}</p>}
                                    <div className="vertical-space-10"></div>
                                </div>
                            </div>
                        </div>
                        <div className="btnBox">
                            <button className="secondaryBtn" onClick={() => { handleList() }}>
                                List
                            </button>
                            <button className="dfBtn" onClick={saveResignation}>
                                Submit
                            </button>
                        </div>
                        {/* </form> */}
                        <Modal
                            show={isSuccessModal}
                            onHide={handleSuccessModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/checked.png")} />
                                    </div>

                                    <h4>Succesfully Submitted</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleSuccessModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={isFailureModal}
                            onHide={handleFailureModalClose}
                            className="SubmitModal"
                        >
                            <Modal.Body>
                                <div className="Details Success">
                                    <div className="imgBox">
                                        <Image src={require("../../../../image/warning.png")} />
                                    </div>
                                    <h4>Failed</h4>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleFailureModalClose}>
                                    Ok
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
});

export default ResignationEntry;
