
function memberValidation(memberDetail){
 
  let errors :any={};

  const alpha = /[a-zA-Z]*$/;
   const numeric =/^[0-9]*$/;
   const emailId =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
   const mobileNo = /^[6-9]\d{9}$/
   const aadharNo = /^[0-9]{12}$/;
  if(!memberDetail.dateofRegn){
    errors.dateofRegn = "select Date of Regn";
  }
  if(!memberDetail.memberName){
    errors.memberName = "Enter Member /BusinessName";
  }
  
  if(!memberDetail.gender){
    errors.gender = "Select Gender";
  }
  // if(!memberDetail.customertype){
  //   errors.customertype = "Select Customer Type";
  // }
  if(!memberDetail.mobileNo){
    errors.mobileNo = "Enter Mobile No";
  }
  if(!memberDetail.customerCategory){
    errors.customerCategory = "Customer Category";
  }
  if(memberDetail.customerCategory=='B2B' && !memberDetail.gstIN){
    errors.gstIN = "Enter GSTIN No";
  }
  if(memberDetail.aadharNumber && !aadharNo.test(memberDetail.aadharNumber)) {
    errors.aadharNumber = "Enter 12 digit aadhar No"
  }
   if(!numeric.test(memberDetail.mobileNo)){
    errors.mobileNo = "Enter Valid Mobile No";
  }
  if(!memberDetail.city ){
      errors.city = "Select City";
  }
  if(memberDetail.pinCode!==undefined ){
    if(!numeric.test(memberDetail.pinCode)){
      errors.pinCode = "Enter Valid Pin Code";
    }
  }
  
  return errors;
};
  
  export default memberValidation;