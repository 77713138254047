import { makeObservable } from "mobx";
import apiAgent from "../../../APIAgent";

class IAppoinmentStore {
    constructor() {
        makeObservable(this);
    }

    saveImageUpload = async (appionmentImages, empId) => {
        try {

            const formdata = new FormData();
            const extension = appionmentImages.name.split('.').pop();

            formdata.append('file', appionmentImages, empId + '.' + extension);

            // const status = await apiAgent.interviewMaster.saveImageUpload(formdata, empId);

            // if (status !== null) {
            //     return status;
            // }

        }
        catch (error) {
            console.log(error);
        }
    }

    saveAppoinmentProcess = async (appoinmentProcess, empId) => {
        try {
            const status = await apiAgent.interviewMaster.saveAppoinmentProcess(appoinmentProcess);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }


    loadAppoinmentProcess = async () => {
        try {
            const loadAppoinmentProcesss = await apiAgent.interviewMaster.loadAppoinmentProcess();
            return JSON.parse(loadAppoinmentProcesss);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadAppoinmentJobDet = async () => {
        try {
            const loadAppoinmentProcesss = await apiAgent.interviewMaster.loadAppoinmentJobDet();
            return JSON.parse(loadAppoinmentProcesss);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadAppoinmentOtherDoc = async () => {
        try {
            const loadAppoinmentProcesss = await apiAgent.interviewMaster.loadAppoinmentOtherDoc();
            return JSON.parse(loadAppoinmentProcesss);
        }
        catch (error) {
            console.log(error);
        }
    }

    loadAppointmentSalaryDet = async () => {
        try {
            const loadAppoinmentProcesss = await apiAgent.interviewMaster.loadAppointmentSalaryDet();
            return JSON.parse(loadAppoinmentProcesss);
        }
        catch (error) {
            console.log(error);
        }
    }

}

export default IAppoinmentStore;