import { runInAction, set, makeObservable, observable } from 'mobx';
import apiAgent from '../../../APIAgent';
import { IDamageEntryWarehouse } from '../model';

class DamageEntryWareHouseStore {
    @observable outletItemDetails= new Map();   
    @observable loadDamageWarehouseItemBatch= new Map(); 
    @observable getDamageEntryWarehouseList= new Map();  
    @observable getDamageEntryWarehouseDetail= new Map(); 
    entryNo = new Map();     

    saveDamageEntryWarehouse=async (damageEntryWarehouse:IDamageEntryWarehouse)=> {
        try {
          const status= await apiAgent.damageEntryWarehouse.saveDamageEntryWarehouse(damageEntryWarehouse);
            return status;
        }
        catch (error) {
            console.log(error);
        }
    }  
   
    loadDamageWarehouseItemBatchNo= async (request) => {
        
        try {
            const loadDamageWarehouseItemBatch = await apiAgent.damageEntryWarehouse.loadDamageWarehouseItemBatchNo(request);
            
            
            runInAction(() => {
                
                this.loadDamageWarehouseItemBatch = loadDamageWarehouseItemBatch;
                
            })
           return this.loadDamageWarehouseItemBatch;
        }
        catch (error) {
            console.log(error);
        }
    } 
    getDamageEntryWarehouse=async ()=> {
        
        try {
          const getDamageEntry= await apiAgent.damageEntryWarehouse.getDamageEntryWarehouse();
            
            runInAction(() => {
                
                this.getDamageEntryWarehouseList = getDamageEntry;
            });
            return this.getDamageEntryWarehouseList;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDamageEntryWarehouseDetails=async (damagentryMaterid)=> {
        
        try {
          const getDamageEntryWarehouse= await apiAgent.damageEntryWarehouse.getDamageEntryWarehouseDetails(damagentryMaterid);
            
            runInAction(() => {
                
                this.getDamageEntryWarehouseDetail = getDamageEntryWarehouse;
            });
            return this.getDamageEntryWarehouseDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDamageEntryWarehouseDetail = async (damagedetailid) => {
        try {      
        const status=  await apiAgent.damageEntryWarehouse.deleteDamageEntryWarehouseDetail(damagedetailid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteDamageEntryWarehouse = async (damagewareid) => {
        try {      
        const status=  await apiAgent.damageEntryWarehouse.deleteDamageEntryWarehouse(damagewareid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    getDamageEntryWarehouseSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.damageEntryWarehouse.getDamageEntryWarehouseSearch(SearchOptions);
            this.getDamageEntryWarehouseList = Data;
            
            runInAction(() => {
                this.getDamageEntryWarehouseList = Data;
                
            })
            return this.getDamageEntryWarehouseList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    
}

export default DamageEntryWareHouseStore;