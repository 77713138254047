import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';
import moment from 'moment';

const InterviewMasterList = () => {
    const { interviewStore, jobMasterStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [interviewList, setInterviewList] = useState<any[]>([])
    const [jobMaster, setJobMaster] = useState<any[]>([])

    const [interviewRoundList, setInterviewRounList] = useState({ bool: false, intId: 0 })

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/InterviewMaster')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Interview_master')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        let interviewList: any[] = [];
        interviewList = await interviewStore.loadInterviewMaster()
        if (interviewList?.length) {
            setInterviewList([...interviewList])
        }
        const jobMaster = await jobMasterStore?.loadJobMaster()
        setJobMaster([...jobMaster])
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (interviewList && interviewList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(interviewList)
        }
    }, [interviewList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(interviewList?.slice()?.sort((a, b) => b.vchr_no - a.vchr_no), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={interviewList}
                                searchTitles={[{ key: "Jobtitle", value: "Job title" }, { key: "JobDescription", value: "Job Description" },]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{interviewList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S.No</th>
                                            <th scope="col">Job Title</th>
                                            <th scope='col'>Details</th>

                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                                            (isSearch ? filteredData : currentPageData)?.map((val, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{val?.Interviewcode}</td>
                                                        <td>{jobMaster?.find((each) => each?.Jobcode == val?.Jobcode)?.Jobtitle}</td>
                                                        <td><button disabled={!userRights?.view} className='changeBtn' onClick={() => setInterviewRounList({ bool: true, intId: Number(val?.Interviewid) })}>Details</button></td>
                                                        <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                        <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                        <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </table>
                            </div>

                            <Modal show={interviewRoundList?.bool} onHide={() => setInterviewRounList({ bool: false, intId: 0 })} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Interview Round List</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='tableBox'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope='col'>Rounds</th>
                                                    <th scope='col'>Process</th>
                                                    <th scope='col'>Duration</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentPageData?.map((val, index) => {
                                                    return val?.Interviewid === interviewRoundList?.intId && (
                                                        <tr key={index}>
                                                            <td>{val?.GID_Phases}</td>
                                                            <td>{val?.GID_Process}</td>
                                                            <td>{moment(val?.GID_Duration)?.format('HH:mm')}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default InterviewMasterList;