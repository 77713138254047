import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ILedgerMasterSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Pagination, Stack } from '@mui/material';
import { currentPageDatas } from '../../common/shared/utils';

const LedgerMasterDetail = observer((): JSX.Element => {
  const { ledgerMasterStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/LedgerMaster')
  }

  const editLedgerMasterDetials = (ledgerId) => {

    navigate('/Admin/LedgerMaster/' + ledgerId);
  }
  const deleteLedgerMasterDetials = async (ledgerId) => {
    setLoading(true);
    const status = await ledgerMasterStore.deleteLedgerMaster(ledgerId);

    if (status === 'Success') {
      fetchPreLoadngData();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    fetchPreLoadngData();
    setSuccessModal(false);

  }
  async function fetchPreLoadngData() {
    const userRights = await userCreationStore?.getUserRight('Ledger_master')
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    }
    await ledgerMasterStore.getLedgerMaster();
    setLoading(false);
  }
  const iscurrentpgae = useRef(true)
  useEffect(() => {
    if (iscurrentpgae.current) {
      fetchPreLoadngData();
      iscurrentpgae.current = false
    }
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  console.log(currentPageData, 'currentPageData>>')
  useEffect(() => {
    if (ledgerMasterStore?.ledgerMasterList && ledgerMasterStore?.ledgerMasterList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(ledgerMasterStore?.ledgerMasterList)
    }
  }, [ledgerMasterStore?.ledgerMasterList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(ledgerMasterStore?.ledgerMasterList?.slice()?.sort((a, b) => b.ledger_accout_id - a.ledger_accout_id), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>{
      isLoading ? <div className='SpinnerBox'>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div> :
        <>
          <div className='container-fluid'>
            <div className='vertical-space-20'></div>
            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={ledgerMasterStore?.ledgerMasterList}
              searchTitles={[{ key: "ledgeraccoutname", value: "Ledger Account Name" }, { key: "group_name", value: "Ledger Group Name" }, { key: "ledgerType", value: "Opening Type" }]}
              emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
            <div className='vertical-space-20'></div>
            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div className='btnBox'>
                <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{ledgerMasterStore?.ledgerMasterList?.length} Records</button>
              </div>
              <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
              </Stack>
            </div>
            <div className='vertical-space-20'></div>
            <div className='tableListDetails'>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope='col'>Ledger ID </th>
                    <th scope='col'>Ledger Name  </th>
                    <th scope='col'>Ledger Group </th>
                    <th scope='col'>Opening Balance </th>
                    <th scope='col'>Opening Type </th>
                    <th scope='col'>Percentage</th>
                    <th scope='col'>Date</th>
                    <th scope='col'>Create </th>
                    <th scope='col'>Update </th>
                    <th scope='col'>Delete </th>
                  </tr>
                </thead>
                <tbody>
                  {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                    (isSearch ? filteredData : currentPageData)?.map((val, key) => {
                      return (
                        <tr key={key}>
                          <td>{val.ledger_accout_id}</td>
                          <td>{val.ledgeraccoutname}</td>
                          <td>{val.group_name}</td>
                          <td>{val.opening_balance}</td>
                          <td>{val.ledgerType}</td>
                          <td>{val.percen}</td>
                          <td>{moment(val.dt).format('DD-MMM-YYYY')}</td>
                          <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                          <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editLedgerMasterDetials(val.ledger_accout_id)} /></button></td>
                          <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteLedgerMasterDetials(val.ledger_accout_id)} /></button></td>
                        </tr>
                      )
                    }
                    )}
                </tbody>
              </table>
            </div>

            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../../image/checked.png')} />
                  </div>

                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>

                  <div className='imgBox'>
                    <Image src={require('../../../../image/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
    }
    </>

  );

});

export default LedgerMasterDetail;
