import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import moment from "moment";
import useStores from "../../hooks";
import { getCurrentMonthFirstDate } from "../../common/shared/utils";
import AutoComplete from "../../common/shared/autoComplete";

class FieldsProps {
  sdate: string = getCurrentMonthFirstDate()?.currentDate;
  employeeName: string = "";
  empId: number = 0;
  departementId: number = 0
  department: string = ""
}

interface ExcelSheetData {
  EmployeeId?: string,
  Employee_Name?: string,
  Gender?: string,
  Qualification?: string,
  Mobileno?: string,
  Emailid?: string,
  Category?: string,
  Department?: string,
}

const ReleavedEmployee = () => {
  const { hrReporsStore, employeeStore, departmentStore } = useStores()

  const [employeeMaster, setEmployeeMaster] = useState<any[]>([])
  const [departmentList, setDepartmentList] = useState<any[]>([])

  const [errors, setErrors] = useState<any>({});

  const [isLoading, setLoading] = useState(true);
  const [isSpinner, setSpinner] = useState(false);

  const [localFields, setLocalFields] = useState<FieldsProps>(new FieldsProps())
  const [releavedEmpReport, setReleavedEmpReport] = useState<any[]>([])

  // console.log(releavedEmpReport, 'releavedEmpReport>>')

  function createExportExcelObj(timeSheetReports: any[]) {
    if (timeSheetReports?.length >= 1) {
      let excelArr: ExcelSheetData[] = timeSheetReports?.map((employee, index) => {
        const { EmployeeId, Employee_Name, Gender, Qualification, Mobileno, Emailid, Category, Department } = employee;
        const excelObj: ExcelSheetData = {
          EmployeeId: EmployeeId,
          Employee_Name: Employee_Name,
          Gender: Gender,
          Qualification: Qualification,
          Mobileno: Mobileno,
          Emailid: Emailid,
          Category: Category,
          Department: Department,
        };
        return excelObj;
      })
      exportToExcel(excelArr)
    } else {
      alert(` No Data found `);
    }
  }

  function exportToExcel(excelArr: ExcelSheetData[]) {
    const csvContent = ["Employee Information Report", Object.keys(excelArr[0]).join(','), ...excelArr.map(obj => Object.values(obj).join(','))].join('\n');

    const blob = new Blob([csvContent], { type: "data:text/csv;charset=utf-8;" });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const today = moment();
    link.download = `Employee Information Report ${today.format('DD-MM-YYYY')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async function handleGenerate(event) {
    setSpinner(true)
    const releavedEmpReport = await hrReporsStore?.getReportRelievedEmployee()
    if (releavedEmpReport?.length) {
      setReleavedEmpReport([...releavedEmpReport])
    }
    setSpinner(false)
  }

  async function initialApiCalls() {
    const employeeMaster = await employeeStore.loadEmployeeMaster();
    setEmployeeMaster([...employeeMaster])
    const departmentList = await departmentStore.getDepartment();
    setDepartmentList([...departmentList])
    handleGenerate(null)
    setLoading(false)
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      initialApiCalls()
      isCurrentPage.current = false
    }
  }, [])

  return (
    <div>
      {isLoading ? <ProgressBar />
        : (
          <>
            <div className="vertical-space-20"></div>
            <div className="outletInputField inputFormBox " style={{ width: "60%" }}>
              <div className="hrBox">
                <h3>Releaved Employee</h3>
              </div>
              {
                isSpinner ?
                  <div className='SpinnerBox'>
                    <Spinner animation="grow" size="sm" />
                  </div> : null
              }
              <div className="inputBoxLists">
                <div className='row'>
                  <div className="col-sm-2">
                    <div className="inputBox">
                      <label style={{ fontSize: '16px' }}>As on Date</label>
                      <input type="date"
                        className="inputBoxDate"
                        style={{ width: "100%", fontWeight: "lighter" }}
                        placeholder="DD/MMM/YYYY"
                        data-date="" data-date-format="DD MMMM YYYY"
                        name="sdate"
                        value={localFields?.sdate}
                        onChange={(event) => setLocalFields({ ...localFields, sdate: event.target.value })}
                      ></input>
                      {errors.brandCode && <p style={{ color: "red" }}>{errors.brandCode}</p>}
                    </div>
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-2">
                    <div className="inputBox" >
                      <label style={{ fontSize: '16px' }}>Employee<span>*</span></label>
                      <AutoComplete clsName="full-width" options={employeeMaster} value={localFields?.employeeName} getOptionLabel='Employee_Name'
                        emitOption={(option) => setLocalFields({ ...localFields, employeeName: option?.Employee_Name, empId: option?.EmployeeId })} placeholder='Select Category..'
                      />
                    </div>
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-2">
                    <div className="inputBox" >
                      <label style={{ fontSize: '16px' }}>Department</label>
                      <AutoComplete clsName="full-width" options={departmentList} value={localFields?.department} getOptionLabel='department'
                        emitOption={(option) => setLocalFields({ ...localFields, departementId: option?.departmentId, department: option?.department })} placeholder='Select department..'
                      />
                    </div>
                    <div className="vertical-space-10"></div>
                  </div>
                  <div className="col-sm-5">
                    <div className="" style={{ marginTop: '30px' }}>
                      <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={handleGenerate}>
                        Search
                      </button>
                      <button className="secondaryBtn text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => createExportExcelObj(releavedEmpReport)}>
                        Excel
                      </button>
                      <button className="secondaryBtn  text-white" style={{ backgroundColor: "#dc3545" }} onClick={() => setLocalFields(new FieldsProps())}>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tableListDetails" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No</th>
                      <th scope="col">Employee ID</th>
                      <th scope="col">Employee Name</th>
                      <th scope="col">Department</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Father Name</th>
                      <th scope="col">DOB</th>
                      <th scope="col">DOJ</th>
                      <th scope="col">Releaved Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {releavedEmpReport?.map((each, index) => {
                      return (!localFields?.empId || each?.EmployeeId === localFields?.empId) &&
                        (!localFields?.departementId || each?.Departmentid === localFields?.departementId) && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{each?.EmployeeId}</td>
                            <td>{each?.Employee_Name}</td>
                            <td>{each?.Department}</td>
                            <td>{each?.Designation}</td>
                            <td>{each?.FatherName}</td>
                            <td>{each?.DoB ? moment(each?.DoB)?.format('DD-MMM-YYYY') : 'N/A'}</td>
                            <td>{each?.doj ? moment(each?.doj)?.format('DD-MMM-YYYY') : 'N/A'}</td>
                            <td>{each?.RelievedDate ? moment(each?.RelievedDate)?.format('DD-MMM-YYYY') : 'N/A'}</td>
                          </tr>
                        )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="vertical-space-20"></div>
            </div>
          </>
        )}
    </div>
  );
};

export default ReleavedEmployee;
