import React, { useEffect, useRef, useState } from "react";
import { Modal, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import { Autocomplete, Pagination, Stack, TextField } from "@mui/material";
import { Accordion } from "react-bootstrap";
import { currentPageDatas } from "../../common/shared/utils";
import useStores from "../../hooks";
import { IIResumeShortlisting } from "./model";
import moment from "moment";
import AutoComplete from "../../common/shared/autoComplete";

const ResumeShortlisting = () => {

    const navigate = useNavigate()

    const { jobMasterStore, resumeBankStore } = useStores();

    const { loadResumeBankMasterDetail } = resumeBankStore;

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [resumeBankList, setResumeBankList] = useState<any[]>([])

    const [resumeShortlistingList, setResumeShortListingList] = useState<IIResumeShortlisting[]>(new Array<IIResumeShortlisting>())

    const [onOpenDetail, setOnOpenDetail] = useState({ bool: false, id: 0 })

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/ResumeShortlisting/List');
    }

    async function handleSaveCategory() {
        let error: any = {};
        if (resumeShortlistingList?.length) {
            if (resumeShortlistingList?.every((obj) => obj?.selectionType != "")) {
                setLoading(true)
                const entryNo = await jobMasterStore.getHREntryNo('Shortlist');
                resumeShortlistingList?.forEach((each) => {
                    if (each?.resumBankid) {
                        each.resumbankshortno = Number(entryNo?.entrynumber)
                    }
                })
                const status = await resumeBankStore.saveResumeShorlisting(resumeShortlistingList);
                if (status === 'Success') {
                    setLoading(false);
                    setSuccessModal(true);
                } else {
                    setLoading(false);
                    setFailureModal(true);
                }
            } else {
                let find = resumeShortlistingList?.find((obj) => obj?.selectionType === "")
                error = {
                    ResumBankid: find?.resumBankid,
                    selectionType: 'Select Selection Type',
                }
            }
        } else {
            error = {
                selectResume: 'Check Resumes to Submit',
            }
        }
        setErrors({ ...error })
    }

    function handleUpdateStatus(val, each) {
        let error: any = {};
        if (!resumeShortlistingList?.some((obj) => obj?.resumBankid === each?.ResumBankid)) {
            error.ResumBankid = each?.ResumBankid;
            error.checkfirst = 'Check Shortlist status';
        } else {
            resumeShortlistingList?.forEach((obj) => {
                if (obj?.resumBankid === each?.ResumBankid) {
                    obj['selectionType'] = val?.value;
                }
                return obj;
            })
            currentPageData?.forEach((obj) => {
                if (obj?.ResumBankid === each?.ResumBankid) {
                    obj['selection'] = val;
                }
                return obj;
            })
            setCurrentPageData([...currentPageData])
            setResumeShortListingList([...resumeShortlistingList])
        }
        setErrors({ ...error })
    }

    function handleCheckBox(event, value) {
        if (!resumeShortlistingList?.some((each) => each?.resumBankid === value?.ResumBankid) && event.target.checked) {
            let obj: IIResumeShortlisting = {
                resumbankshortno: 0,
                resumBankid: value?.ResumBankid,
                resumcode: value?.Resumcode,
                resumdate: moment()?.format('YYYY-MM-DD'),
                jobid: value?.Jobid,
                empid: value?.Empid,
                notisperiod: value?.NoticePeriod,
                mobileno: value?.Mobile,
                emailid: value?.Email,
                empphotopath: value?.Empphotopath,
                resumepath: value?.Resumepath,
            }
            setResumeShortListingList([...resumeShortlistingList, obj])
        } else {
            let findIndex = resumeShortlistingList?.findIndex((each) => each?.resumBankid === value?.ResumBankid)
            if (findIndex >= 0) {
                resumeShortlistingList?.splice(findIndex, 1)
                setResumeShortListingList([...resumeShortlistingList])
            }
        }
    }

    const fetchOutletItemData = async () => {
        const resumeBankMaster = await loadResumeBankMasterDetail();
        setResumeBankList([...resumeBankMaster])
        setLoading(false);
    }

    const isCCurrenPage = useRef(true)

    useEffect(() => {
        if (isCCurrenPage.current) {
            fetchOutletItemData();
            isCCurrenPage.current = false
        }
        return () => { }
    }, []);

    const [totalPages, setTotalPage] = useState(1);
    const [currentPageData, setCurrentPageData] = useState<any[]>([])

    useEffect(() => {
        if (resumeBankList && resumeBankList?.length) {
            goToPage(1)
        } else {
            setCurrentPageData(resumeBankList)
        }
    }, [resumeBankList])

    const goToPage = (value: number) => {
        const currentPageList = currentPageDatas(resumeBankList?.slice()?.sort((a, b) => b.ResumBankid - a.ResumBankid), value, 10)
        setTotalPage(currentPageList?.totalPages)
        setCurrentPageData(currentPageList?.currentPageData)
    };

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        <div className='outletInputField inputFormBox LgInputField'>
                            <div className='hrBox'>
                                <h3>Resume Shortlisting</h3>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='btnBox'>
                                    <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{resumeBankList?.length} Records</button>
                                </div>
                                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                                    <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                                </Stack>
                            </div>
                            <div className='vertical-space-20'></div>
                            <div className='tableListDetails' style={{ marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Select</th>
                                            <th scope="col">Resume Code</th>
                                            <th scope="col">Resume Date</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Notice Period</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Selected Type</th>
                                            <th scope="col">Detail</th>
                                            <th scope="col">Resume</th>
                                            <th scope="col">Photo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData?.map((each) => {
                                            console.log(currentPageData, 'currentPageData>>')
                                            return (
                                                <tr>
                                                    <td>
                                                        <input type="checkbox" onChange={(e) => handleCheckBox(e, each)} />
                                                        {((errors?.ResumBankid == each?.ResumBankid) && errors?.checkfirst) && <p style={{ color: 'red' }}>{errors?.checkfirst}</p>}
                                                    </td>
                                                    <td>{each?.ResumBankid}</td>
                                                    <td>Date</td>
                                                    <td>{each?.Candidatename ?? ''}</td>
                                                    <td>{each?.NoticePeriod ?? ''}</td>
                                                    <td>{each?.Mobile ?? ''}</td>
                                                    <td>{each?.Email ?? ''}</td>
                                                    <td>
                                                        <Autocomplete size="small" disablePortal id="combo-box-demo"
                                                            options={[{ type: 'Selected', value: 'Selected' }, { type: 'Rejected', value: 'Rejected' }, { type: 'Hold', value: 'Hold' }]}
                                                            onChange={(event, val) => handleUpdateStatus(val, each)} getOptionLabel={(option: any) => option.value}
                                                            renderInput={(params: any) =>
                                                                <TextField  {...params} style={{ width: '36ch' }}
                                                                    id="outlined-size-small"
                                                                    color='info'
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="Selection Type..."
                                                                    name='selectionType'
                                                                />}
                                                        />
                                                        {((errors?.ResumBankid == each?.ResumBankid) && errors?.selectionType) && <p style={{ color: 'red' }}>{errors?.selectionType}</p>}
                                                    </td>
                                                    <td><button className='' onClick={() => setOnOpenDetail({ bool: true, id: each?.ResumBankid })}>Details</button></td>
                                                    <td><button className='' onClick={() => { }}>Resume</button></td>
                                                    <td><button className='' onClick={() => { }}>Photo</button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {errors?.selectResume && <p style={{ color: 'red' }}>{errors?.selectResume}</p>}
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleSuccessModalClose}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={onOpenDetail?.bool} onHide={() => { setOnOpenDetail({ bool: false, id: 0 }) }} className='PriceHistoryModel'>
                                <Modal.Header closeButton>
                                    <Modal.Title>Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Candidate Details</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">DOB  </th>
                                                                <th scope="col">Gender </th>
                                                                <th scope="col">Martial Status  </th>
                                                                <th scope="col">Blood Group </th>
                                                                <th scope="col">Edu Qualification  </th>
                                                                <th scope="col">Experience</th>
                                                                <th scope="col">Current Company</th>
                                                                <th scope="col">Last Month Salary  </th>
                                                                <th scope="col">Notice Period  </th>
                                                                <th scope="col">Current City  </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(currentPageData)?.length ?
                                                                (currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.candidate?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Candidatename}</td>
                                                                                <td>{moment(obj.Dateofbirth).format('DD-MMM-YYYY')}</td>
                                                                                <td>{obj?.Gender}</td>
                                                                                <td>{obj.Martialstatus}</td>
                                                                                <td>{obj.BloodGroup}</td>
                                                                                <td>{obj.EducationQualification}</td>
                                                                                <td>{obj?.CurrentCompanyDetails}</td>
                                                                                <td>{`${obj.ExpYear}y${obj.ExpMonth}m`}</td>
                                                                                <td>{obj.LastMonthSalary}</td>
                                                                                <td>{obj.NoticePeriod}</td>
                                                                                <td>{obj?.Currentcity}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Contact Details</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Door No</th>
                                                                <th scope="col">Street </th>
                                                                <th scope="col">Area  </th>
                                                                <th scope='col'>City </th>
                                                                <th scope="col">State</th>
                                                                <th scope="col">Pincode  </th>
                                                                <th scope="col">Country </th>
                                                                <th scope="col">Mobile  </th>
                                                                <th scope="col">Email  </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/*  */}
                                                            {(currentPageData)?.length ?
                                                                (currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.contact?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Doorno}</td>
                                                                                <td>{obj.Street}</td>
                                                                                <td>{obj?.Area}</td>
                                                                                <td>{obj.City}</td>
                                                                                <td>{obj.State}</td>
                                                                                <td>{obj.Pincode}</td>
                                                                                <td>{obj.Country}</td>
                                                                                <td>{obj.Mobile}</td>
                                                                                <td>{obj.Email}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>Work Experience</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Company</th>
                                                                <th scope="col">Job Title </th>
                                                                <th scope='col'>From </th>
                                                                <th scope='col'>To </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(currentPageData)?.length ?
                                                                (currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.employExperience?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Company}</td>
                                                                                <td>{obj.Jobtitle}</td>
                                                                                <td>{moment(obj?.Fromdate)?.format('DD-MMM-YYYY')}</td>
                                                                                <td>{moment(obj?.Todate)?.format('DD-MMM-YYYY')}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>Key Skills</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Key Skills</th>
                                                                <th scope="col">Total Experience </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(currentPageData)?.length ?
                                                                (currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.keySkill?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.Keyskills}</td>
                                                                                <td>{`${obj.Exp_year}y${obj.Exp_month}m`}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>Languages Known</Accordion.Header>
                                            <Accordion.Body>
                                                <div className='tableBox'>
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Language Known</th>
                                                                <th scope="col">To Speak </th>
                                                                <th scope="col">To Read </th>
                                                                <th scope="col">To Write </th>
                                                                {/* <th scope='col'>Delete </th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(currentPageData)?.length ?
                                                                (currentPageData)?.map((val: any, key) => {
                                                                    return val?.ResumBankid === onOpenDetail?.id ? val?.language?.map((obj, index) => {
                                                                        return (
                                                                            <tr key={key}>
                                                                                <td>{obj.MLanguage}</td>
                                                                                <td>{obj.ToSpeak}</td>
                                                                                <td>{obj?.ToRead}</td>
                                                                                <td>{obj.ToWrite}</td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                                }) : null
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Modal.Body>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>

                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>

                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }

        </div>
    )
}

export default ResumeShortlisting;