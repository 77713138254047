import { observable } from "mobx";
export interface IWarehouse{
    warehouseId?:number;
    warehouseName?:string;   
    fssainumber?:string;
    dateOfIncrorpeted?:string;
    doorNoStreet?:string;
    area?:string;
    city?:string;
    state?:string;
    country?:string;
    pinCode?:string;  
   
}
export interface IWarehouseSearchoptions{
    searchOption?:string;
    searchValue?:string;
}

const warehouseDetails=observable<IWarehouse>({
    warehouseId:0,
    warehouseName:'',    
    fssainumber:'',
    dateOfIncrorpeted:'',
    doorNoStreet:'',
    area:'',
    city:'',
    state:'',
    country:'',
    pinCode:'',
    
});

export default warehouseDetails;