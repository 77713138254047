import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { Modal, Image, Button, Spinner } from 'react-bootstrap';
import { IUnitSearchoptions } from './model';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const UnitOfMeasurementList = observer((): JSX.Element => {
  const [unitSearchOptions, setUnitSearchOptions] = useState<IUnitSearchoptions>({})
  const { unitOfMeasurementStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const navigate = useNavigate();
  const [Isloading, setLoading] = useState(true);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const handleCreate = () => {
    navigate('/Admin/UnitOfMeasurements');
  }

  const editUnitDetials = (unitId) => {
    navigate('/Admin/UnitOfMeasurements/' + unitId);
  }
  const deleteUnitDetials = async (unitId) => {
    setLoading(true);
    const status = await unitOfMeasurementStore.deleteUnitOfMeasurement(unitId);

    if (status === 'Success') {
      fetchPreLoadingData()
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchPreLoadingData();
  }

  async function fetchPreLoadingData() {
    setLoading(false);
    const [userRights] = await Promise.all([userCreationStore?.getUserRight('Unit_of_measurements'), unitOfMeasurementStore.getUnitOfMeasurement()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCurrentPage = useRef(true)

  useEffect(() => {
    if (isCurrentPage.current) {
      fetchPreLoadingData()
      isCurrentPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (unitOfMeasurementStore?.unitOfMeasurementList && unitOfMeasurementStore?.unitOfMeasurementList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(unitOfMeasurementStore?.unitOfMeasurementList)
    }
  }, [unitOfMeasurementStore?.unitOfMeasurementList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(unitOfMeasurementStore?.unitOfMeasurementList, value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };
  return (
    <>
      {
        Isloading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>

              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={unitOfMeasurementStore.unitOfMeasurementList}
                searchTitles={[{ key: "unitName", value: "Unit Name" }, { key: "unitdescription", value: "Unit Description" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{unitOfMeasurementStore?.unitOfMeasurementList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">UnitId</th>
                      <th scope="col">Unit Name</th>
                      <th scope="col">Unit Description</th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      ((isSearch ? filteredData : currentPageData)?.slice().sort((a, b) => b.unitId - a.unitId)).map((val, key) => {
                        return (
                          <tr key={key}>
                            <td>{val.unitId}</td>
                            <td>{val.unitName}</td>
                            <td>{val.unitdescription}</td>
                            <td><button disabled={!userRights?.add} className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button disabled={!userRights?.edit} className='Edit'  ><FontAwesomeIcon icon={faPenToSquare} onClick={() => editUnitDetials(val.unitId)} /></button></td>
                            <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteUnitDetials(val.unitId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>

              <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>
                    <div className='imgBox'>
                      <Image src={require('../../../../image/checked.png')} />
                    </div>

                    <h4>Succesfully Deleted</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleSuccessModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                <Modal.Body>
                  <div className='Details Success'>

                    <div className='imgBox'>
                      <Image src={require('../../../../image/warning.png')} />
                    </div>
                    <h4>Failed</h4>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleFailureModalClose}>
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </>
      }

    </>

  )
})

export default UnitOfMeasurementList;