import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'

interface OffersProps {
    isOffers: boolean,
    modalClose: () => void,
    posMasterStore: any,
    posDetail: any,
    handleRedeemChange: (e) => void,
    returnAmt?: number,
    totOfferAmt?: number,
    availOffers?: Array<any>,
    assignedOffers?: Array<any>,
    posItemDetils?: Array<any>,
}

const CheckOffers: React.FC<OffersProps> = ({ isOffers, modalClose, posDetail, posMasterStore, handleRedeemChange, returnAmt, totOfferAmt, availOffers, assignedOffers, posItemDetils }) => {
    let totalDiscount = 0;

    assignedOffers?.forEach((offersAssigned) => {
        const calculateDiscounts = () => {
            const totalAmount = posItemDetils?.length
                ? posItemDetils.reduce((a, v) => a + (v?.posNetAmount || 0), 0)
                : 0;
    
            const deliveryCharges = Number(posDetail?.deliveryCharges) || 0;
            const discountPercentage = Number(offersAssigned?.Discount_PV) / 100;
    
            const discountAmount = (totalAmount + deliveryCharges) * discountPercentage;
            return discountAmount;
        };
    
        totalDiscount += calculateDiscounts(); 
    });
    
    const formattedTotalDiscount = Number(totalDiscount).toFixed(2);
   
    const newApplicableOffers: any[] = useMemo(() => {
        let offerAvailble: any[] = [];
        posItemDetils?.forEach((products) => {
            availOffers?.forEach((offerAvail) => {
                if ((offerAvail?.itemId === products?.posItemId || offerAvail?.categoryId == products?.posSubCategoryId) &&
                    (!assignedOffers?.length || assignedOffers?.some((obj) => obj?.offerid != offerAvail?.offerid))) {
                    if (offerAvail && !offerAvailble?.some((obj) => obj?.offerid === offerAvail?.offerid)) {
                        offerAvailble?.push(offerAvail)
                    }
                }
            })
        })
        return offerAvailble;
    }, [])

    const assignOffersUpdated = useMemo(() => {
        let billValue: number = posMasterStore?.offers?.billValue;
        assignedOffers?.forEach((each) => {
            if (each?.Dis_Mode === "Dis%") {
                each['Discount_value'] = billValue * (each?.Discount_PV / 100);
            } else {
                each['Discount_value'] = each?.Discount_PV;
            }
            return each;
        })
        return assignedOffers;
    }, [])

    console.log(assignOffersUpdated, posMasterStore?.offers, 'posMasterStore>>')
    console.log(assignedOffers, 'assignedOffers');
    console.log(totOfferAmt, 'chkoffertotOfferAmt');

    return (
        <Modal show={isOffers} onHide={modalClose} className=''>
            <Modal.Header closeButton>
                <Modal.Title>Offers</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='priceDetailBox'>
                    {(assignedOffers?.length! > 0) && <div>
                        <div className="mb-2">Assigned Offers</div>
                      <div>
                      <span style={{ fontWeight: '800', color: 'red', fontSize: '18px' }}>Total Discount Amount : <span style={{ fontWeight: '800', color: 'red', fontSize: '18px' }}>{formattedTotalDiscount}</span></span>
                      </div>
                        <ul>
                            {assignedOffers?.map((offersAssigned) => {
                              
                                
                                // Use Bill Disocunt
                                // const totalNetAmount = posItemDetils?.length
                                //     ? posItemDetils.reduce((a, v) => a + (v?.posNetAmount || 0), 0)
                                //     : 0;

                                // const deliveryCharges = posDetail?.deliveryCharges || 0;

                                // const discountPercentage = Number(offersAssigned?.Discount_PV) / 100;

                                // const discountWithDeliveryCharges = (totalNetAmount + deliveryCharges) * discountPercentage;
                                // const discountWithoutDeliveryCharges = totalNetAmount * discountPercentage;
                                return (
                                    <>
                                      {/* {discountAmounts.map((amount, index) => (
            <div key={index}>
                Discount Amount {index + 1}: <span style={{ fontWeight: '800', color: 'red' }}>&#8377; {Number(amount).toFixed(2)}</span>
            </div>
        ))} */}
                                        <li>
                                            <span>Offer Name : <span style={{ fontWeight: '800' }}>{offersAssigned?.offername}</span></span>{" | "}
                                            <span>Offer Value :
                                                {offersAssigned?.Dis_Mode === "Dis%" ?
                                                    <span style={{ fontWeight: '800' }}>
                                                        {` ${offersAssigned?.Discount_PV} %`}
                                                    </span> :
                                                    <span style={{ fontWeight: '800' }}>
                                                        &#8377;{` ${offersAssigned?.Discount_PV}`}&nbsp;
                                                    </span>
                                                }
                                            </span>{" | "}
                                            {posDetail?.deliveryCharges ?
                                                <div>Offer Discount : <span><span>&#8377;</span> {(Number(posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0 + Number(posDetail.deliveryCharges))?.toFixed(2) : 0) * (Number(offersAssigned?.Discount_PV) / 100))?.toFixed(2)}</span></div> :
                                                <div>Offer Discount : <span><span>&#8377;</span> {(Number(posItemDetils?.length ? posItemDetils?.reduce((a, v) => a = a + v?.posNetAmount!, 0)?.toFixed(2) : 0) * (Number(offersAssigned?.Discount_PV) / 100))?.toFixed(2)}</span></div>
                                            }
                                            {/* offerDiscount = Number(billlValue) * (Number(offers?.Discount_PV) / 100) */}
                                        </li>
                                    </>
                                )
                            })}
                        </ul>
                    </div>}
                    {(newApplicableOffers?.length > 0) &&
                        <div>
                            <div className="mb-2">Available Offers</div>
                            <ul>
                                {newApplicableOffers?.map((availOffer) => {
                                    return (
                                        <li>
                                            <span>Get &#8377;{availOffer?.Discount_PV}&nbsp; off on
                                                {" "} {posItemDetils?.find((obj) => obj?.posItemId === availOffer?.itemId)?.posItemName} when you buy {availOffer?.To_QV} of more
                                            </span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                    {/* <ul >
                        <li>Offer Name : <span>{posMasterStore.offers.offerName}</span></li>
                    </ul>
                    <ul >
                        <li>Bill Value Amount : <span><span>&#8377;{posMasterStore.offers.billValue}</span> </span></li>
                    </ul> */}
                    {returnAmt != 0 && <ul>
                        <li>Return Amount : <span><span>&#8377;{(returnAmt)?.toFixed(2)}&nbsp;</span> </span></li>
                    </ul>}
                    {/* <ul >
                        <li>DisCount Value Amount : <span><span>&#8377;{posMasterStore.offers.discountValue}&nbsp;</span> </span></li>
                    </ul> */}
                    {posMasterStore?.offers?.earnedPoints > 0 && <ul>
                        <li>Earned Points : <span>{posMasterStore.offers.earnedPoints} </span></li>
                    </ul>}
                    {posMasterStore.offers.earnedPoints > 0 && <ul >
                        <li>Redeem Points : <span>{posMasterStore.offers.redeemPoints} </span></li>
                    </ul>}
                    {posMasterStore.offers.earnedPoints > 0 && <ul >
                        <li>Redeem Amount : <span>{posMasterStore.offers.redeemAmount} </span><div>check</div></li>
                    </ul>}
                    {/* <ul >
                        <li>Pay Bill Amount : <span><span>&#8377;{returnAmt ? Math.abs(posMasterStore.offers.netBillValue - returnAmt)?.toFixed(2) : posMasterStore.offers.netBillValue}
                        </span> </span></li>
                    </ul> */}
                    {(posMasterStore.offers.finalValue && posMasterStore?.offers?.earnedPoints && posMasterStore.offers.redeemAmount) ?
                        <ul >
                            <li>Redeem Bill Amount : <span><span>&#8377;
                                {(posMasterStore.offers.finalValue - assignOffersUpdated?.reduce((a, v) => a = a + v?.Discount_value!, 0))?.toFixed(2)}
                                &nbsp;&nbsp;<input type='checkbox' checked={posDetail?.isReedem} name='isRedeem' onChange={handleRedeemChange} className='checkbox' /> &nbsp;Is Redeem Points Applicable</span>
                            </span></li>
                        </ul> : null
                    }
                    <button className='dfBtn SuccessBtn full-width' onClick={modalClose}>Ok</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default CheckOffers;