import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faPenToSquare, faSearch, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react-lite';
import useStores from '../../hooks';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { currentPageDatas } from '../../common/shared/utils';
import { Pagination, Stack } from '@mui/material';

const ManageOffersOrDiscountsList = observer((): JSX.Element => {
  const { offerDiscountStore, userCreationStore } = useStores();
  const [userRights, setRightsObj] = useState<any>({})
  const { getOfferDiscountsDetails, offerDiscountDetail } = offerDiscountStore;
  const [isLoading, setLoading] = useState(true);
  const [isSupplierShow, setOfferDiscountModal] = useState({ bool: false, type: '' });
  const navigate = useNavigate();
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);

  const [isSearch, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([])

  const editOfferDiscount = (offerId) => {
    navigate('/Admin/ManageOffersOrDiscounts/' + offerId);           
  }

  const handleCreate = () => {
    navigate('/Admin/ManageOffersOrDiscounts')
  }

  const deleteOfferMaster = async (offerId) => {
    setLoading(true);
    const status = await offerDiscountStore.deleteOfferMaster(offerId);

    if (status === 'Success') {
      fetchOfferDiscouts();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const deleteOfferDetail = async (offerDetailid) => {
    // setOfferDiscountModal(false);
    setLoading(true);
    const status = await offerDiscountStore.deleteOfferDetail(offerDetailid);

    if (status === 'Success') {
      fetchOfferDiscouts();
      setLoading(false);
      setSuccessModal(true);
    }
    else {
      setLoading(false);
      setFailureModal(true);
    }
  }
  const handleFailureModalClose = () => setFailureModal(false);
  const handleSuccessModalClose = () => {
    setSuccessModal(false);
    fetchOfferDiscouts();
  }

  const getOfferDiscountDetails = async (offerId, type) => {

    const data = await getOfferDiscountsDetails(offerId);
    console.log(data,'data');
    
    if (data !== undefined || data !== null) {

      setOfferDiscountModal({ bool: true, type: type });
    }

  }
  const handleClose = () => {
    setOfferDiscountModal({ bool: false, type: '' });
  }


  async function fetchOfferDiscouts() {
    setLoading(false)
    const [userRights] = await ([userCreationStore?.getUserRight('Manage_offer_discounts'), offerDiscountStore.getOfferDiscounts()])
    console.log(userRights, 'userRights>>')
    if (userRights) {
      setRightsObj({ ...userRights })
    };
  }

  const isCCurrenPage = useRef(true)

  useEffect(() => {
    if (isCCurrenPage.current) {
      fetchOfferDiscouts();
      isCCurrenPage.current = false
    }
    return () => { }
  }, []);

  const [totalPages, setTotalPage] = useState(1);
  const [currentPageData, setCurrentPageData] = useState<any[]>([])

  useEffect(() => {
    if (offerDiscountStore?.offerDiscountList && offerDiscountStore?.offerDiscountList?.length) {
      goToPage(1)
    } else {
      setCurrentPageData(offerDiscountStore?.offerDiscountList)
    }
  }, [offerDiscountStore?.offerDiscountList])

  const goToPage = (value: number) => {
    const currentPageList = currentPageDatas(offerDiscountStore?.offerDiscountList?.slice().sort((a, b) => b.offerId - a.offerId), value, 10)
    setTotalPage(currentPageList?.totalPages)
    setCurrentPageData(currentPageList?.currentPageData)
  };

  return (
    <>
      {
        isLoading ?
          <div className='SpinnerBox'>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div> :
          <>
            <div className='container-fluid'>
              <div className='vertical-space-20'></div>

              <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={offerDiscountStore?.offerDiscountList}
                searchTitles={[{ key: "offerName", value: "Offer Name" }, { key: "offerType", value: "Offer Type" }]}
                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
              <div className='vertical-space-20'></div>
              <div className='ItemInwardInputBox' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='btnBox'>
                  <button className='totalrecordBtn' type='submit' style={{ width: '20ch' }}>{offerDiscountStore?.offerDiscountList?.length} Records</button>
                </div>
                <Stack spacing={2} style={{ padding: '0px 24px 20px' }}>
                  <Pagination count={totalPages} onChange={(ev, value) => goToPage(value)} color='primary' />
                </Stack>
              </div>
              <div className='vertical-space-20'></div>
              <div className='tableListDetails'>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      {/* <th scope="col">OfferId</th> */}
                      <th scope="col">Offer Name</th>
                      <th scope="col">Offer Type</th>
                      <th scope='col'></th>
                      <th scope="col">Create</th>
                      <th scope="col">Update</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(isSearch ? filteredData : currentPageData)?.length > 0 &&
                      ((isSearch ? filteredData : currentPageData)).map((val, key) => {
                        return (
                          <tr key={key}>
                            {/* <td>{val.offerId}</td> */}
                            <td>{val.offerName}</td>
                            <td>{val.offerType}</td>
                            
                            <td><button  onClick={() => getOfferDiscountDetails(val.offerId, val.offerType)}>Detail</button></td>
                            <td><button className='Add'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                            <td><button  className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => editOfferDiscount(val.offerId)} /></button></td>
                            <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteOfferMaster(val.offerId)} /></button></td>
                          </tr>
                        )
                      }
                      )}
                  </tbody>
                </table>
              </div>
              {
                isSupplierShow?.bool ?
                  <Modal show={isSupplierShow?.bool} onHide={handleClose} className='PriceHistoryModel'>
                    <Modal.Header closeButton>
                      <Modal.Title>Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='tableBox'>
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">From {`${isSupplierShow?.type === 'Value Discount' ? 'value' : 'qty'}`}</th>
                              <th scope="col">To {`${isSupplierShow?.type === 'Value Discount' ? 'value' : 'qty'}`}</th>
                              <th scope="col">Coupon</th>
                              <th scope="col">Discount</th>
                              <th scope="col">Mode</th>
                              {/* <th scope="col">Delete</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {offerDiscountDetail?.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.from_QV}</td>
                                  <td>{val.to_QV}</td>
                                  <td>{val.copponName}</td>
                                  <td>{val.discount_PV}</td>
                                  <td>{val.dis_Mode}</td>
                                  {/* <td><button className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => deleteOfferDetail(val.offerDetailid)} /></button></td> */}
                                </tr>
                              )
                            })
                            }
                          </tbody>
                        </table>
                      </div>
                    </Modal.Body>
                  </Modal> : null
              }

            </div>
            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/checked.png')} />
                  </div>
                  <h4>Succesfully Deleted</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleSuccessModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
              <Modal.Body>
                <div className='Details Success'>
                  <div className='imgBox'>
                    <Image src={require('../../../gsmecom/images/warning.png')} />
                  </div>
                  <h4>Failed</h4>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleFailureModalClose}>
                  Ok
                </Button>
              </Modal.Footer>
            </Modal>
          </>

      }
    </>
  );

});

export default ManageOffersOrDiscountsList;
