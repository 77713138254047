
function ExpiryRegisterValidation(stockSummary) {
    let errors: any = {};

    if (!stockSummary.outletId) {
        errors.outletId = "Select Outlet";
    }
    // if (!stockSummary.fromDate) {
    //     errors.fromDate = "Select Outlet";
    // }
    // if (!stockSummary.toDate) {
    //     errors.toDate = "Select Outlet";
    // }

    return errors;
};

export default ExpiryRegisterValidation;