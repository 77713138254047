export class IIResumeBank {
    resumBankid?: number = 0
    resumcode?: string = ""
    jobid?: number = 0
    empid?: number = 0
    empphotopath?: string = ""
    resumepath?: string = ""
    candidate?: IIResumeCandidate[] = []
    contact_Details?: IIResumeContactDet[] = []
    workexperience?: IIWorkExp[] = []
    keyskills_Details?: IIKeySkills[] = []
    language?: IILanguage[]
    description?: string = ""
}

export class IIResumeCandidate {
    resumBankid?: number = 0
    resumcode?: string = ""
    jobid?: number = 0
    empid?: number = 0
    empphotopath?: string = ""
    resumepath?: string = ""
    candidatename?: string = ""
    dateofbirth?: string = new Date()?.toISOString()?.substr(0, 10)
    gender?: string = ""
    martialstatus?: string = ""
    bloodGroup?: string = ""
    educationQualification?: string = ""
    expYear?: string = "0"
    expMonth?: string = "0"
    currentCompanyDetails?: string = ""
    lastMonthSalary?: string = ""
    noticePeriod?: string = ""
    currentcity?: string = ""
}

export class IIResumeContactDet {
    doorno?: string = ""
    street?: string = ""
    area?: string = ""
    pincode?: string = ""
    city?: string = ""
    state?: string = ""
    country?: string = ""
    mobile?: string = ""
    email?: string = ""
}

export class IIWorkExp {
    company?: string = ""
    jobtitle?: string = ""
    fromdate?: string = new Date()?.toISOString()?.substr(0, 10)
    todate?: string = new Date()?.toISOString()?.substr(0, 10)
}

export class IIKeySkills {
    keyskills?: string = ""
    exp_year?: string = "0"
    exp_month?: string = "0"
}

export class IILanguage {
    mlanguage?: string = ""
    toRead?: string = "No"
    toSpeak?: string = "No"
    toWrite?: string = "No"
}