import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

interface CustomerInvoiceModalProps {
    hold: boolean,
    holdClose: () => void,
    getInvoice: Array<any>,
    invoicePrint: (posid: any) => void,
    generateInvoice: (invoiceObj) => void
}
const CustomerInvoiceModal: React.FC<CustomerInvoiceModalProps> = ({ hold, holdClose, getInvoice, invoicePrint, generateInvoice }) => {
    const [errors, setErrors] = useState<any>({})
    const [localFields, setLocalFields] = useState<any>({ fromdate: '', mobileno: '', billno: '' });
console.log(getInvoice,'getInvoice');

    function handleInputChange(e) {
        const { name, value } = e.target;
        setLocalFields({ ...localFields, [name]: value })
    }

    function handleGenerate() {
        // let error: any = {};
        // if (localFields?.fromdate || localFields?.mobileno || localFields?.billno) {
        //     setErrors({})
        // } else if (!localFields?.fromdate) {
        //     error.fromdate = "Enter From Date"
        // } else if (!localFields?.billno) {
        //     error.billno = "Enter Invoice No"
        // }
        // if (localFields?.mobileno && localFields?.mobileno?.length !== 10) {
        //     error.mobileno = "Enter Correct Mobile No"
        // }
        // setErrors(error);

        // if (Object.keys(error)?.length === 0) {
            generateInvoice(localFields)
       // }
    }

    function hanldeCloseDial(posid) {
        setLocalFields({ ...localFields, fromdate: '', mobileno: '', billno: '' })
        invoicePrint(posid)
    }
    return (
        <Modal show={hold} onHide={holdClose} className='PriceHistoryModel' style={{ marginTop: '50px' }}>
            <Modal.Header closeButton>
                <Modal.Title>Invoice List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='inputFormBox '>
                    <div className="inputBoxLists" style={{ padding: '0px 0px 10px' }}>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className='inputBox'>
                                    <label>From Date <span>*</span></label>
                                    <input type="date" style={{ width: '100%' }}
                                        name="fromdate"
                                        onChange={handleInputChange} value={localFields?.fromdate}
                                        placeholder="DD-MMM-YYYY" data-date="" data-date-format="DD MM YYYY"
                                    ></input>
                                </div>
                                {errors.fromdate && <p style={{ color: 'red' }}>{errors.fromdate}</p>}
                            </div>
                            <div className="col-sm-3">
                                <div className='inputBox'>
                                    <label>Invoice No <span>*</span></label>
                                    <input type="text" style={{ width: '100%' }}
                                        name="billno"
                                        onChange={handleInputChange} value={localFields?.billno}
                                        placeholder="Search Invoice..." data-date="" data-date-format="DD MM YYYY"
                                    ></input>
                                </div>
                                {errors.billno && <p style={{ color: 'red' }}>{errors.billno}</p>}
                            </div>
                            <div className="col-sm-3">
                                <div className='inputBox'>
                                    <label>Mobile No <span>*</span></label>
                                    <input type="text" style={{ width: '100%' }}
                                        name="mobileno"
                                        onChange={handleInputChange} value={localFields?.mobileno}
                                        placeholder="Search Mobile..." data-date="" data-date-format="DD MM YYYY"
                                    ></input>
                                </div>
                                {errors.mobileno && <p style={{ color: 'red' }}>{errors.mobileno}</p>}
                            </div>
                            <div className="col-sm-3">
                                <button className='dfBtn' type='submit' onClick={handleGenerate} >Generate</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tableBox'>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Customer Name</th>
                                <th scope="col">Mobile No</th>
                                <th scope="col">Bill No</th>
                                <th scope="col">Bill Date</th>
                                <th scope="col">Net value</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {getInvoice?.length > 0 && getInvoice.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{val.membername}</td>
                                        <td>{val.Mobileno}</td>
                                        <td>{val.Billno}</td>
                                        <td>{moment(val.Billdate)?.format('DD-MMM-YYYY')}</td>
                                        <td>{val.Netvalue}</td>
                                        <td><button className='dfBtn viewbillBtn width-auto' onClick={() => hanldeCloseDial(val?.posid)}>View<FontAwesomeIcon icon={faEdit} /></button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default CustomerInvoiceModal