import { IIInterviewLetter } from "../model";

export function saveValidation(interviewLetterFields: IIInterviewLetter) {
    let error: any = {}
    if (!interviewLetterFields?.employeeid) {
        error.employeeid = 'Select Generated By'
    }
    if (!interviewLetterFields?.mode) {
        error.mode = 'Select Mode'
    }
    if (!interviewLetterFields?.interviewTime) {
        error.interviewTime = 'Select Interview time'
    }
    if (!interviewLetterFields?.description) {
        error.description = 'Enter Description'
    }
    if (!interviewLetterFields?.location) {
        error.location = 'Enter Description'
    }
    return error;
}