export class IIResignationMaster {
    rM_ID?: number = 0
    rM_EntryNo?: number = 0
    rM_Date?: string = new Date()?.toISOString()?.substr(0, 10)
    rM_EmpID?: number = 0
    rM_DOJ?: string = new Date()?.toISOString()?.substr(0, 10)
    rM_DegnID?: number = 0
    rM_DeptID?: number = 0
    rM_ResignReqDate?: string = new Date()?.toISOString()?.substr(0, 10)
    rM_ManagerID?: number = 0
    rM_Reason?: string = ""
}