import { IIJobVacancy } from "../../JobMaster/model";

export function saveValidation(jobMaster: IIJobVacancy) {
  let error: any = {}

  if (!jobMaster.jvcode) {
    error.jvcode = 'Enter Job Code'
  }
  if (!jobMaster.jtitle) {
    error.jtitle = 'Enter Job Title'
  }
  if (!jobMaster.jDescription) {
    error.jDescription = 'Enter Job Description'
  }
  if (!jobMaster.createdBy) {
    error.createdBy = 'Enter Created By'
  }
  if (!jobMaster.experience_Required) {
    error.experience_Required = 'Enter Experience'
  }
  if (!jobMaster.qualification) {
    error.qualification = 'Enter Qualification'
  }
  if (!jobMaster.noofPosision) {
    error.noofPosision = 'Enter No. of Positions'
  }
  if (!jobMaster.skills_Required) {
    error.skills_Required = 'Enter Skills Required'
  }
  if (!jobMaster.worklocation) {
    error.worklocation = 'Enter Work Location'
  }
  return error;
}