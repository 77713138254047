import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faEdit, faFileEdit, faL, faPenToSquare, faSearch, faTrashAlt, } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import useStores from '../../hooks';
import { Button, Image, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SelectSearchCriteria from '../../common/shared/selectSearchCriteria';
import { Accordion } from "react-bootstrap";
import moment from 'moment';

const AppointmentProcessList = () => {

    const { appoinmentStore, payrollStore, userCreationStore } = useStores();
    const [userRights, setRightsObj] = useState<any>({})

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [isSpinner, setSpinner] = useState(false);

    const [appoinmentProcessList, setAppoinmentProcessList] = useState<any[]>([])

    const [appoinmentJobDetails, setAppoinmentJobDetails] = useState<any[]>([])
    const [appoinmentOtherDocDetails, setAppoinmentOtherDocDetails] = useState<any[]>([])
    const [appoinmentSalaryDetails, setAppoinmentSalaryDetails] = useState<any[]>([])

    const [onOpenDetail, setOnOpenDetail] = useState({ bool: false, id: 0 })

    const [incentiveMaster, setIncentiveMaster] = useState<any[]>([])

    const [isSearch, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState<any[]>([])

    const handleCreate = () => {
        navigate('/Admin/AppointmentProcess')
    }

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        setSuccessModal(false);
        fetchPreLoadngData();
    }

    async function hanldeDetails(appoiId) {
        if (appoiId) {
            setSpinner(true)
            const jobDetails = await appoinmentStore?.loadAppoinmentJobDet();
            const otherDocDetails = await appoinmentStore?.loadAppoinmentOtherDoc();
            const salaryDetails = await appoinmentStore?.loadAppointmentSalaryDet();
            setAppoinmentSalaryDetails([...salaryDetails])
            setAppoinmentOtherDocDetails([...otherDocDetails])
            setAppoinmentJobDetails([...jobDetails])
            setOnOpenDetail({ bool: true, id: appoiId })
            setSpinner(false)
        }
    }

    async function fetchPreLoadngData() {
        const userRights = await userCreationStore?.getUserRight('Appoinment_process')
        console.log(userRights, 'userRights>>')
        if (userRights) {
            setRightsObj({ ...userRights })
        }
        const appoinmentMasterList = await appoinmentStore?.loadAppoinmentProcess();
        setAppoinmentProcessList([...appoinmentMasterList])
        const incentiveMaster = await payrollStore.loadIncentiveRecoverMaster()
        setIncentiveMaster([...incentiveMaster])
        setLoading(false)
    }

    const isCurrentPage = useRef(true);

    useEffect(() => {
        if (isCurrentPage.current) {
            fetchPreLoadngData();
            isCurrentPage.current = false;
        }
        return () => { }
    }, [])
    return (
        <>
            {
                isLoading ?
                    <div className='SpinnerBox'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div> :
                    <>
                        <div className='container-fluid'>
                            {
                                isSpinner ?
                                    <div className='SpinnerBox'>
                                        <Spinner animation="grow" size="sm" />
                                    </div> : null
                            }
                            <div className='vertical-space-20'></div>
                            <SelectSearchCriteria searchEnable={(bool) => setSearch(bool)} searchArray={appoinmentProcessList}
                                searchTitles={[{ key: "CategoryName", value: "Category Name" }]}
                                emitSearchArray={(filterArray: any) => setFilteredData(filterArray)} />
                            <div className='tableListDetails'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Record Code</th>
                                            <th scope="col">Date </th>
                                            <th scope="col">Generated By</th>
                                            <th scope="col">Job Code</th>
                                            <th scope="col">Candidate</th>
                                            <th scope="col">Job Location</th>
                                            <th scope="col">Date Of Joining</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Biometric ID</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">Testimonials</th>
                                            <th scope="col">Photo</th>
                                            <th scope="col">Detail</th>
                                            <th scope="col">Create</th>
                                            <th scope="col">Update</th>
                                            <th scope="col">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appoinmentProcessList?.map((each, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{each?.AppoinRecordId}</td>
                                                    <td>{moment(each?.AppoinDate)?.format('DD-MMM-YYYY')}</td>
                                                    <td>{each?.AppoinEmpId}</td>
                                                    <td>{each?.AppoinJobCode}</td>
                                                    <td>{each?.AppoinCandidate}</td>
                                                    <td>{each?.AppoinJobLoc}</td>
                                                    <td>{moment(each?.AppoinDOJ)?.format('DD-MMM-YYYY')}</td>
                                                    <td>{moment(each?.AppoinTime)?.format('HH:mm')}</td>
                                                    <td>{each?.AppoinBioID}</td>
                                                    <td>{each?.AppoinCompName}</td>
                                                    <td><button disabled={!userRights?.view} className='' onClick={() => { }}>Testimonials</button></td>
                                                    <td><button disabled={!userRights?.view} className='' onClick={() => { }}>Photo</button></td>
                                                    <td><button disabled={!userRights?.view} className='' onClick={() => hanldeDetails(each?.AppoinRecordId)}>Details</button></td>
                                                    <td><button disabled={!userRights?.add} className='Create'><FontAwesomeIcon icon={faAdd} onClick={handleCreate} /></button></td>
                                                    <td><button disabled={!userRights?.edit} className='Edit'><FontAwesomeIcon icon={faPenToSquare} onClick={() => { }} /></button></td>
                                                    <td><button disabled={!userRights?.delete} className='delete'><FontAwesomeIcon icon={faTrashAlt} onClick={() => { }} /></button></td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal show={onOpenDetail?.bool} onHide={() => setOnOpenDetail({ bool: false, id: 0 })} className='PriceHistoryModel'>
                                <div style={{ width: '1200px', height: '400px' }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Appoinment Process Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="inputBoxLists accordionLists">
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Job Details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Job code</th>
                                                                        <th scope="col">Designation</th>
                                                                        <th scope="col">Job Duty</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {appoinmentJobDetails?.map((each, index) => {
                                                                        return onOpenDetail?.id === each?.AJD_AppoinRecordId && (
                                                                            <tr key={index}>
                                                                                <td>{each?.AJD_AppoinRecordId}</td>
                                                                                <td>{each?.AJD_AppoinDesign}</td>
                                                                                <td>{each?.AJD_AppoinJobDuty}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Other Documents</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Doc type</th>
                                                                        <th scope="col">Key Skills</th>
                                                                        <th scope="col">Total Experience </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {appoinmentOtherDocDetails?.map((each, index) => {
                                                                        return onOpenDetail?.id === each?.AOD_AppoinRecordId && (
                                                                            <tr key={index}>
                                                                                <td>{each?.AOD_AppoinDocType}</td>
                                                                                <td>{each?.AOD_AppoinDocName}</td>
                                                                                <td>{each?.AOD_AppoinOtherDoc}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header> Salary Details (Normal)</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='tableBox'>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope='col'>Salary Type</th>
                                                                        <th scope="col">Earning Type</th>
                                                                        <th scope="col">Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {appoinmentSalaryDetails?.map((each, index) => {
                                                                        return onOpenDetail?.id === each?.ASD_AppoinRecordId && (
                                                                            <tr key={index}>
                                                                                <td>{each?.ASD_AppoinSalType}</td>
                                                                                <td>{incentiveMaster?.find((obj) => obj?.incid === each?.ASD_AppoinDescId)?.IDescription}</td>
                                                                                <td>{each?.ASD_AppoinAmt}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </Modal.Body>
                                </div>
                            </Modal>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Deleted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </>
    )
}

export default AppointmentProcessList;