import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner, Image, Button } from 'react-bootstrap';
import ProgressBar from '../../common/shared/progressbar';
import { useNavigate } from "react-router-dom";
import useStores from "../../hooks";
import { IILeaveReqApproval } from "./model";
import moment from "moment";
import { getMonthDates } from "../../common/shared/utils";

const LeaveApproval = () => {

    const { employeeStore, payrollStore, } = useStores();

    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [isLoading, setLoading] = useState(true);
    const [isSuccessModal, setSuccessModal] = useState(false);
    const [isFailureModal, setFailureModal] = useState(false);

    const [leaveRequList, setLeaveRequstList] = useState<any>()

    const [employeeMaster, setEmployeeMaster] = useState<any[]>([])

    const [leaveReqApproval, setLeaveReqApproval] = useState<IILeaveReqApproval[]>(new Array<IILeaveReqApproval>())
    const [approvalDate, setApprovalDate] = useState(new Date()?.toISOString()?.substr(0, 10))

    const [isSelected, setSelected] = useState(1)
    const [isSpinner, setSpinner] = useState(false);

    const handleFailureModalClose = () => setFailureModal(false);

    const handleSuccessModalClose = () => {
        navigate('/Admin/LeaveApproval/List');
        setSuccessModal(false)
    }

    async function handleSelectMonth(month) {
        setSpinner(true)
        let body = {
            empid: 0,
            month: month
        }
        const loadElClJSON = await payrollStore.getElClBalance(body)
        let updatedArray: any[] = Object.assign([], leaveRequList);
        updatedArray?.forEach((each) => {
            let findEmp = loadElClJSON?.find(obj => obj?.empid === each?.LR_EmpID)
            if (each?.LR_EmpID === findEmp?.empid) {
                each['BalanceCL'] = findEmp?.BalanceCL;
                each['BalanceEL'] = findEmp?.BalanceEL;
            }
            return each
        })
        setLeaveRequstList([...updatedArray])
        setSelected(month);
        setSpinner(false)
    }

    function handleSelectManager(event, each) {
        let error: any = {}
        if (each?.checked) {
            leaveReqApproval?.forEach((obj) => {
                if (obj?.lR_MDNO === each?.LR_MDNO) {
                    obj['lR_ApprovedBy'] = event?.target?.value;
                }
                return obj
            });
            leaveRequList?.forEach((obj) => {
                if (obj?.LR_MDNO === each?.LR_MDNO) {
                    obj['lR_ApprovedBy'] = event?.target?.value;
                }
                return obj;
            })
        } else {
            error.lR_MDNO = each?.LR_MDNO;
            error.lR_ApprovedStatus = 'Select Approved Status';
        }
        setLeaveRequstList([...leaveRequList])
        setLeaveReqApproval([...leaveReqApproval])
        setErrors({ ...error })
    }

    async function handleCheckBox(event, each) {
        let error: any = {}
        const { checked } = event?.target;
        if (checked) {
            let findObj: IILeaveReqApproval = new IILeaveReqApproval();
            leaveRequList?.forEach((obj) => {
                if (obj?.LR_MDNO === each?.LR_MDNO) {
                    obj['checked'] = true;
                }
                return obj;
            })
            findObj['lR_ApprovedStatus'] = 'Yes';
            findObj['lR_MDNO'] = each?.LR_MDNO;
            leaveReqApproval?.push(findObj);
        } else {
            let findIndex = leaveReqApproval?.findIndex((obj) => obj?.lR_MDNO === each?.LR_MDNO)
            if (findIndex !== -1) {
                leaveReqApproval?.splice(findIndex, 1)
            }
            leaveRequList?.forEach((obj) => {
                if (obj?.LR_MDNO === each?.LR_MDNO) {
                    obj['checked'] = false;
                }
                return obj;
            })
        }
        setErrors({ ...error })
        setLeaveRequstList([...leaveRequList])
        setLeaveReqApproval([...leaveReqApproval])
    }

    async function handleSaveCategory() {
        let error: any = {}
        if (leaveReqApproval?.length) {
            leaveReqApproval?.forEach((each) => {
                each['lR_ApprovedDate'] = approvalDate;
                return each;
            })
            const status = await payrollStore?.saveLeaveRequestApproval(leaveReqApproval)
            if (status === 'Success') {
                setLoading(false);
                setSuccessModal(true);
            } else {
                setLoading(false);
                setFailureModal(true);
            }
        } else {
            if (!leaveReqApproval?.length) {
                error.approval = 'Check Approvals '
            }
        }
        setErrors({ ...error })
    }

    function handleToPromotionApprovalMasterListList() {
        navigate('/Admin/LeaveApproval/List')
    }

    async function additionalApis() {
        const employeeMaster = await employeeStore.loadEmployeeMaster();
        setEmployeeMaster([...employeeMaster])
    }

    async function initialApiCall() {
        const leaveReqList = await payrollStore.loadLeaveRecordsDetail()
        setLeaveRequstList([...leaveReqList])
        setLoading(false)
        additionalApis()
    }

    const isCurentPage = useRef(true);

    useEffect(() => {
        if (isCurentPage.current) {
            initialApiCall()
            isCurentPage.current = false;
        }
        return () => { };
    }, []);

    const months = [
        { key: "january", value: "Jan", month: 1 },
        { key: "february", value: "Feb", month: 2 },
        { key: "march", value: "Mar", month: 3 },
        { key: "april", value: "Apr", month: 4 },
        { key: "may", value: "May", month: 5 },
        { key: "june", value: "Jun", month: 6 },
        { key: "july", value: "Jul", month: 7 },
        { key: "august", value: "Aug", month: 8 },
        { key: "september", value: "Sep", month: 9 },
        { key: "october", value: "Oct", month: 10 },
        { key: "november", value: "Nov", month: 11 },
        { key: "december", value: "Dec", month: 12 }
    ];

    function handleFindDates(fromDate, toDate) {
        const { firstDate, lastDate } = getMonthDates(isSelected, Number(moment()?.format('YYYY')));
        if (firstDate && lastDate) {
            const yesterday = new Date(firstDate);
            if (new Date(fromDate) >= yesterday && new Date(toDate) <= new Date(lastDate) && new Date(fromDate) <= new Date(toDate)) {
                return true
            } else {
                return false
            }
        }
    }

    return (
        <div>
            {
                isLoading ? <ProgressBar /> :
                    <>
                        <div className='vertical-space-20'></div>
                        {
                            isSpinner ?
                                <div className='SpinnerBox'>
                                    <Spinner animation="grow" size="sm" />
                                </div> : null
                        }
                        <div className='outletInputField inputFormBox ' style={{ width: '60%' }}>
                            <div className='hrBox'>
                                <h3>Leave Approval Master</h3>
                            </div>
                            <div className='inputBoxLists'>
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <div className='inputBox'>
                                            <label style={{ fontSize: '16px' }}>Approval Date <span>*</span></label>
                                            <input type="date" className="inputBoxDate"
                                                style={{ width: "100%", fontWeight: "lighter" }}
                                                placeholder="DD/MMM/YYYY" data-date=""
                                                data-date-format="DD MMMM YYYY"
                                                name="lR_ApprovedDate"
                                                onChange={(event) => setApprovalDate(event?.target?.value)}
                                                value={approvalDate}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flexImp" style={{ gap: '4px' }}>
                                {months?.map((obj) => {
                                    return (
                                        <span className={`chips ${isSelected === obj?.month ? 'active' : ''}`} onClick={() => handleSelectMonth(obj?.month)}>{obj?.value}</span>
                                    )
                                })}
                            </div>
                            <div className='tableListDetails' style={{ marginTop: '20px', marginBottom: '20px' }}>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">From Date</th>
                                            <th scope="col">To Date</th>
                                            <th scope="col">Employee Name</th>
                                            <th scope="col">Balance CL</th>
                                            <th scope="col">Leave type</th>
                                            <th scope="col">Reason</th>
                                            <th scope="col">Approved By</th>
                                            <th scope="col">Approve</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaveRequList?.map((each, index) => {
                                            return (each?.LR_Appstatus == 'No' && handleFindDates(each?.LR_FromDate, each?.LR_ToDate)) && (
                                                <tr key={index}>
                                                    <td style={{ textTransform: 'capitalize' }}>{each?.LR_MDNO}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{moment(each?.LR_FromDate)?.format('DD-MMM-YYYY')}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{moment(each?.LR_ToDate)?.format('DD-MMM-YYYY')}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{employeeMaster?.find((obj) => obj?.EmployeeId == each?.LR_EmpID)?.Employee_Name}</td>
                                                    <td>{each?.BalanceCL}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{each?.LR_LevType}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{each?.LR_Reason}</td>
                                                    <td className='row'>
                                                        <div className="col-md-12">
                                                            <div className='inputBox'>
                                                                <select className="full-width" name="type"
                                                                    value={each?.lR_ApprovedBy}
                                                                    onChange={(event) => handleSelectManager(event, each)} id="Openingtype" >
                                                                    <option value="" selected>--Select--</option>
                                                                    {employeeMaster?.map((each) => {
                                                                        return (
                                                                            <option value={each?.Employee_Name}>{each?.Employee_Name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                            {((errors?.lR_MDNO === each?.LR_MDNO) && errors?.lR_ApprovedBy) ?
                                                                <p style={{ color: 'red' }}>{errors?.lR_ApprovedBy}</p> : null}                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input checked={each?.checked ? true : false} type="checkbox" onChange={(event) => handleCheckBox(event, each)}></input>
                                                        {((errors?.lR_MDNO === each?.LR_MDNO) && errors?.lR_ApprovedStatus) && <p style={{ color: 'red' }}>{errors?.lR_ApprovedStatus}</p>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {(errors?.approval) && <p style={{ color: 'red' }}>{errors?.approval}</p>}
                            </div>
                            <div className='btnBox'>
                                <button className='secondaryBtn' onClick={handleToPromotionApprovalMasterListList}>List</button>
                                <button className='dfBtn' onClick={handleSaveCategory}>Submit</button>
                            </div>
                            <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/checked.png')} />
                                        </div>
                                        <h4>Succesfully Submitted</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleSuccessModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            <Modal show={isFailureModal} onHide={handleFailureModalClose} className='SubmitModal'>
                                <Modal.Body>
                                    <div className='Details Success'>
                                        <div className='imgBox'>
                                            <Image src={require('../../../../image/warning.png')} />
                                        </div>
                                        <h4>Failed</h4>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleFailureModalClose}>
                                        Ok
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </>
            }
        </div>
    )
}

export default LeaveApproval;