import { action, set, makeObservable, observable, runInAction } from 'mobx';
import AssignPosDetails, {  IAssignPos } from '../model/index';
import apiAgent from '../../../APIAgent';

class AssignPosStore {
    assignPosDetls =AssignPosDetails;
    @observable assignPosList= new Map();
    isLoading=true;
    @observable assignPosLists=new Map<any, any>();
    //status = string;
    constructor(){
        makeObservable(this);
    }

     saveAssignPos = async (assignPos: IAssignPos) => {
        try {
            
           const status=  await apiAgent.assignPos.saveAssignPos(assignPos);           
            return status;           
        }
        catch (error) {
            console.log(error);
        }
    }
    getAssignPos = async ()=>{
        try{
            const assignPosData = await apiAgent.assignPos.getAssignPos();
            this.assignPosList = assignPosData;
            
            runInAction(()=>{
                this.assignPosList = assignPosData;
                this.isLoading=false;
            })
            return this.assignPosList;
        }
        catch(error){
            console.log(error);
    }
}
getAssignPosSearch  = async (SearchOptions)=> {
    try {
      const assignPosData=await apiAgent.assignPos.getAssignPosSearch(SearchOptions);
        this.assignPosList = assignPosData;
        
        runInAction(() => {
            this.assignPosList = assignPosData;
            
        })
        return this.assignPosList;
    
     
    }
    catch (error) {
        console.log(error);
    }
}
deleteAssignPos = async (entryId) => {
    try {      
    const status=  await apiAgent.assignPos.deleteAssignPos(entryId);     
   return status;
    }
    catch (error) {
        console.log(error);
    }
}
}
export default AssignPosStore;