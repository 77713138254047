import { action, set, makeObservable, observable, runInAction } from 'mobx';
import OutletManagerDetails, { ICourierPartner } from '../model/index';
import apiAgent from '../../../APIAgent';

class CourierPartnerStore {
    
    @observable OutletManagerList= new Map();
    isOutletManagerRequest = true;
    courierPartnerList= new Map();
    @observable saveimageStatus=new Map();
    courierPartnerDetail = new Map();
    @observable entryNo = new Map();
    @observable CourierStatus = new Map();    

     saveCourierPartner = async (CourierPartner: ICourierPartner) => {
        
        try {
            const extension =  CourierPartner.fileName?.split('.').pop();
     
            CourierPartner.fileName = CourierPartner.courierid+'.'+ extension;
          
          const status=  await apiAgent.CourierPartner.saveCourierPartner(CourierPartner);
          runInAction(() => {
            this.CourierStatus = status;
        });
        return this.CourierStatus;
        }
        catch (error) {
            console.log(error);
        }
    }

    
      saveCourierImage = async(logo,courierid)=>{
        try {
            const formdata = new FormData();
           const extension =  logo.name.split('.').pop();
          
            formdata.append('file',logo,courierid+'.'+extension);
           
            const status=  await apiAgent.CourierPartner.saveCourierImage(formdata,courierid);
            
            runInAction(() => {
                this.saveimageStatus = status;
            });
            
             return this.saveimageStatus;
           
        }
         catch (error) {
             console.log(error);
        }
      }

    getEntryNo = async (flag)=>{
        try{
            const entryNo = await apiAgent.adminModule.getEntryNo(flag);
            
            runInAction(()=>{
                this.entryNo = entryNo;
            })
            return this.entryNo;
        }
        catch(error){
        console.log(error);
        }
    }

    getCourierPartner=async ()=> {
        
        try {
          const getCourierPartner= await apiAgent.CourierPartner.getCourierPartner();
            
            runInAction(() => {
                
                this.courierPartnerList = getCourierPartner;
            });
            return this.courierPartnerList;
        }
        
        catch (error) {
            console.log(error);
        }
    }
    getCourierPartnerDetails=async (courierid)=> {
        
        try {
          const getCourierPartner= await apiAgent.CourierPartner.getCourierPartnerDetails(courierid);
            
            runInAction(() => {
                
                this.courierPartnerDetail = getCourierPartner;
            });
            return this.courierPartnerDetail;
        }
        catch (error) {
            console.log(error);
        }
    }
    getCourierPartnerSearch  = async (SearchOptions)=> {
        try {
          const Data=await apiAgent.CourierPartner.getCourierPartnerSearch(SearchOptions);
            this.courierPartnerList = Data;
            
            runInAction(() => {
                this.courierPartnerList = Data;
                
            })
            return this.courierPartnerList;       
         
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteCourierPartnerDetail = async (courierdid) => {
        
        try {      
        const status=  await apiAgent.CourierPartner.deleteCourierPartnerDetail(courierdid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }
    
    deleteCourierPartnerMaster = async (courierid) => {
        try {      
        const status=  await apiAgent.CourierPartner.deleteCourierPartnerMaster(courierid);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }

}
export default CourierPartnerStore;