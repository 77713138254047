function packingDistributionValidation(packingDistribution){
    let errors :any={};

    if(!packingDistribution.packingDistributionDate){
      
        errors.packingDistributionDate = "Enter PackingDistribution Date";
    }
    if(!packingDistribution.teamName){
      
        errors.teamName = "Enter Team Name ";
    }
    if(!packingDistribution.itemName){
      
        errors.itemName = "Enter Item Name ";
    }
    if(!packingDistribution.warehouseQty){
      
        errors.warehouseQty = "Enter Warehouse Qty  ";
    }
    if(!packingDistribution.itemUOM){
      
        errors.itemUOM = "Enter Item Qty  ";
    }    
    if(!packingDistribution.packingQty){
      
        errors.packingQty = "Enter packingQty ";
    }
    if(!packingDistribution.assignQty){
      
        errors.assignQty = "Enter PackingQty ";
    }
    return errors;
};
export default packingDistributionValidation
