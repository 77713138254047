import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Checkbox, Radio, RadioGroup, TextField } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IICustomerFeedback } from "./model";
import moment from "moment";
import useStores from "../../hooks";
import { Button, Image, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const CostomerFeedback = () => {

  const {supplierMasterStore, memberStore } = useStores()

  const navigate = useNavigate()

  const [feedBackMaster, setFeedBackMaster] = useState<IICustomerFeedback>(new IICustomerFeedback())

  const [isLoading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isFailureModal, setFailureModal] = useState(false);
  const [isLoadModal, setLoadModal] = useState(false);
  const [canAdd, setCanAdd] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canView, setCanView] = useState(false);
  // const [canPrint, setCanPrint] = useState(false);  
  // const [canUpdate, setCanUpdate] = useState(false); 

  function handleInputChange(event) {
    const { name, value } = event?.target;
    if (name === 'email_Commands' || name === 'testimonial') {
      let num = (value === 'Yes') ? 1 : 0;
      setFeedBackMaster({ ...feedBackMaster, [name]: num })
    } else {
      setFeedBackMaster({ ...feedBackMaster, [name]: value })
    }
  }

  async function handleSave() {
    let details = Object.assign({}, feedBackMaster);
    details['feeddate'] = moment()?.format('DD-MM-YYYY')
    const status = await memberStore.saveCustomerFeedBack(details)
    if (status === 'Success') {
      setLoading(false);
      setSuccessModal(true);
    } else {
      setLoading(false);
      setFailureModal(true);
    }
  }

  function handleSuccessModalClose() {
    navigate('/Admin/CustomerFeedBack/List')
  }

  const handleAccessModalClose = () => {
    navigate('/Admin/Home');
  }

  return (
    <div className="container">
      <div className="outletInputFieldCustomerFeedback inputFormBox">
        <div className="hrBox">
          <h3>Customer FeedBack</h3>
        </div>
        <div className="p-2">
          <div className="w-full flex gap-2 pb-2">
            <TextField name="name" onChange={handleInputChange} value={feedBackMaster?.name} id="outlined-basic" sx={{ width: "50%" }} label="First Name" variant="outlined" />
            <TextField name="lastname" onChange={handleInputChange} value={feedBackMaster?.lastname} id="outlined-basic" sx={{ width: "50%" }} label="Last Name" variant="outlined" />
          </div>
          <div className="w-full flex gap-2 pb-2">
            <TextField name="email" onChange={handleInputChange} value={feedBackMaster?.email} id="outlined-basic" sx={{ width: "50%" }} label="Email" variant="outlined" />
            <TextField name="city" onChange={handleInputChange} value={feedBackMaster?.city} id="outlined-basic" sx={{ width: "50%" }} label="City" variant="outlined" />
          </div>
          <TextField name="state" onChange={handleInputChange} value={feedBackMaster?.state} id="outlined-basic" sx={{ width: "50%" }} label="State" variant="outlined" />
          <div className="flex justify-start items-center gap-4" style={{ display: "flex", alignItems: "center" }}>
            <div className="pt-2" style={{ fontSize: "large" }}>
              May We Contact You Via E-mail About Your Comments?
            </div>
            <div className="pt-1">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="gap-1 pl-2 pt-1"
              >
                {["Yes", "No"]?.map((eachRadio, ind) => {
                  return (
                    <FormControlLabel
                      value={eachRadio}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "skyblue",
                            },
                          }}
                        />
                      }
                      name="email_Commands"
                      label={eachRadio}
                      onClick={handleInputChange}
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
          <div className="flex justify-start items-center gap-4" style={{ display: "flex", alignItems: "center" }}>
            <div className="pt-2" style={{ fontSize: "large" }}>
              May We Your Comments as a Testimonial?
            </div>
            <div className="pt-1">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="gap-1 pl-2 pt-1"
              >
                {["Yes", "No"]?.map((eachRadio, ind) => {
                  return (
                    <FormControlLabel
                      value={eachRadio}
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": {
                              color: "skyblue",
                            },
                          }}
                        />
                      }
                      name="testimonial"
                      label={eachRadio}
                      onClick={handleInputChange}
                    />
                  );
                })}
              </RadioGroup>
            </div>
          </div>
          <div>
            <div
              className="text-[#FF6868]"
              style={{ color: "#FF6868", fontSize: "medium" }}
            >
              How did you hear about us?
            </div>
            <div className="row">
              {[
                "TV", "Radio", "Newspaper", "Friend", "Phonebook", "Billboard",
                "Search Engine", "Dealer Locator", "Internet", "Other", "Social Media",
              ].map((each, index) => {
                return (
                  <div className="col-4 flex items-center" style={{ display: "flex", alignItems: "center" }} key={index}>
                    <input
                      style={{ marginTop: "8px", marginRight: "8px" }}
                      type="checkbox"
                      value={each}
                      onChange={handleInputChange}
                      name="hear_about_us"
                      checked={feedBackMaster?.hear_about_us === each}
                      aria-label="controlled-checkbox"
                    />
                    <div className="pt-2"> {each}</div>
                  </div>
                );
              })}
            </div>
            <div className="py-2">
              <TextField name="hear_about_us" onChange={handleInputChange} id="outlined-basic"
                sx={{ width: "50%" }} label="Others" variant="outlined" />
            </div>
          </div>
          <div>
            <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>
              How satisfied are you with the customer service you received at
              this store?
            </div>
            <div
              className="flex justify-between"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div></div>
              <div className="row">
                {["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied",]?.map((each) => {
                  return <div className="col-2">{each}</div>;
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F0F0F0",
              }}
            >
              <div>Overall Rating</div>
              <div className="row" style={{ marginRight: "50px" }}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1"
                >
                  {["Very Satisfied",
                    "Satisfied",
                    "Neutral",
                    "Dissatisfied",
                    "Very Dissatisfied",]?.map((eachRadio, ind) => {
                      return (
                        <FormControlLabel
                          value={eachRadio}
                          control={
                            <Radio
                              style={{ margin: "20px" }}
                              sx={{
                                "&.Mui-checked": {
                                  color: "black",
                                },
                              }}
                            />
                          }
                          name="overallRateing"
                          label=''
                          onClick={handleInputChange}
                        />
                      );
                    })}
                </RadioGroup>
              </div>
            </div>
            <div>
              <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>Please let us know the reason for the above rating</div>
              <TextField name="reason_For_Rating" onChange={handleInputChange} value={feedBackMaster?.reason_For_Rating}
                id="outlined-multiline-flexible" sx={{ width: "75%" }} variant="outlined" multiline maxRows={4} />
            </div>
            <div>
              <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>How satisfied are you with the following services?(Please check 'Not applicable' if you did not use the service.)</div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "45%" }}></div>
                <div className="row full-width">
                  {[
                    "Very Satisfied", "Satisfied", "Neutral", "Dissatisfied",
                    "Very Dissatisfied", "Not applicable"
                  ]?.map((each) => {
                    return <div className="col-2">{each}</div>;
                  })}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
                width: "100%"
              }}
            >
              <div style={{ width: "45%" }}>Did the store staff's attitude , attire and product knowledge leave a favorable impression?</div>
              <div className="row full-width">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1 full-width"
                >
                  {["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied", "Not applicable"]?.map((eachRadio, ind) => {
                    return (
                      <FormControlLabel
                        style={{
                          width: "15.666667%",
                          flex: '0 0 auto',
                        }}
                        value={eachRadio}
                        control={
                          <Radio
                            style={{ margin: "20px" }}
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                          />
                        }
                        name="favorable_impression"
                        label=''
                        onClick={handleInputChange}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <div style={{ width: "45%" }}>was service at the cash register speedy and polite?</div>
              <div className="row full-width">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1 full-width"
                >
                  {["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied", "Not applicable"]?.map((eachRadio, ind) => {
                    return (
                      <FormControlLabel
                        style={{
                          width: "15.666667%",
                          flex: '0 0 auto',
                        }}
                        value={eachRadio}
                        control={
                          <Radio
                            style={{ margin: "20px" }}
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                          />
                        }
                        label={''}
                        name="speedy_polite"
                        onClick={handleInputChange}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
                width: "100%"
              }}
            >
              <div style={{ width: "45%" }}>Was service on the sales floor informative and counteous?</div>
              <div className="row full-width">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1 full-width"
                >
                  {["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied", "Not applicable"]?.map((eachRadio, ind) => {
                    return (
                      <FormControlLabel
                        style={{
                          width: "15.666667%",
                          flex: '0 0 auto',
                        }}
                        value={eachRadio}
                        control={
                          <Radio
                            style={{ margin: "20px" }}
                            sx={{
                              "&.Mui-checked": {
                                color: "black",
                              },
                            }}
                          />
                        }
                        name="informativeandcontieous"
                        label={''}
                        onClick={handleInputChange}
                      />
                    );
                  })}
                </RadioGroup>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%"
              }}
            >
              <div style={{ width: "45%" }}>
                Was service in the filling rooms counteous and appropriate?</div>
              <div className="row full-width">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1 full-width"
                >
                  {["Very Satisfied",
                    "Satisfied",
                    "Neutral",
                    "Dissatisfied",
                    "Very Dissatisfied",
                    "Not applicable"]?.map((eachRadio, ind) => {
                      return (
                        <FormControlLabel
                          style={{
                            width: "15.666667%",
                            flex: '0 0 auto',
                          }}
                          value={eachRadio}
                          control={
                            <Radio
                              style={{ margin: "20px" }}
                              sx={{
                                "&.Mui-checked": {
                                  color: "black",
                                },
                              }}
                            />
                          }
                          name="appropriate"
                          label={''}
                          onClick={handleInputChange}
                        />
                      );
                    })}
                </RadioGroup>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#F0F0F0",
                width: "100%"
              }}
            >
              <div style={{ width: "45%" }}>
                Were you satisfied with your alteration?</div>
              <div className="row full-width">
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="gap-1 pl-2 pt-1 full-width"
                >
                  {["Very Satisfied",
                    "Satisfied",
                    "Neutral",
                    "Dissatisfied",
                    "Very Dissatisfied",
                    "Not applicable"]?.map((eachRadio, ind) => {
                      return (
                        <FormControlLabel
                          style={{
                            width: "15.666667%",
                            flex: '0 0 auto',
                          }}
                          value={eachRadio}
                          control={
                            <Radio
                              style={{ margin: "20px" }}
                              sx={{
                                "&.Mui-checked": {
                                  color: "black",
                                },
                              }}
                            />
                          }
                          name="allterations"
                          label={''}
                          onClick={handleInputChange}
                        />
                      );
                    })}
                </RadioGroup>
              </div>
            </div>
            <div style={{ paddingTop: "16px" }}>
              <div>Please use the space provided for any comments you have regarding your purchased items.</div>
              <div style={{ paddingBottom: "8px" }}>{'(If possible , please provide the item name and 13-digit item code printed on your receipt)'}</div>
              <TextField name="purchased_items" onChange={handleInputChange} value={feedBackMaster?.purchased_items}
                id="outlined-multiline-flexible" sx={{ width: "75%" }} variant="outlined" multiline maxRows={4} />
            </div>
          </div>
        </div>
        <div className='btnBox flex gap-2'>
          <button className="dfBtn" type="submit" onClick={handleSave} disabled={!canAdd}
                title={!canAdd ? 'You do not have permission to Add.' : 'Click to Add'}
              >
                Submit
              </button>


          <button className='secondaryBtn' onClick={handleSuccessModalClose}>Cancel</button>
        </div>
      </div>


      <Modal show={isSuccessModal} onHide={handleSuccessModalClose} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>
            <div className='imgBox'>
              <Image src={require('../../../../image/checked.png')} />
            </div>

            <h4>Succesfully Submitted</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSuccessModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isFailureModal} onHide={() => setFailureModal(false)} className='SubmitModal'>
        <Modal.Body>
          <div className='Details Success'>

            <div className='imgBox'>
              <Image src={require('../../../../image/warning.png')} />
            </div>
            <h4>Failed</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFailureModal(false)}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CostomerFeedback;
