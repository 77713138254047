import apiAgent from "../../../APIAgent"

class ExpiryRegisterStore{
    loadExpiryRegister = async (expirtyReg) => {
        try {
            const expiryRegList = await apiAgent.expiryReg.getExpiryRegList(expirtyReg);

            return expiryRegList
        } catch (error) {
            console.log(error)
        }
    }
}

export default ExpiryRegisterStore;