import { IOutletItemRequest, OutletItemRequestDetail } from "../model";

function outletRequestItemValidation(outletItemRequest) {
  let errors: any = {};


  if (!outletItemRequest.outletItemDate) {
    errors.outletItemDate = "Select Date";
  }
  if (!outletItemRequest.itemId) {
    errors.itemId = "Select Item Name";
  }
  if (!outletItemRequest.stock) {
    errors.stock = "Enter Outlet Stock";
  }
  if (!outletItemRequest.required) {
    errors.required = "Enter Required Quantity";
  }

  return errors;
};

export default outletRequestItemValidation;

export function addValidation(itemReq: OutletItemRequestDetail) {
  let error: any = {};

  if (!itemReq?.requestItemId) {
    error.requestItemId = 'Select Item Name'
  }
  if (!itemReq?.requiredQuantity) {
    error.requiredQuantity = 'Enter Required Quantity'
  }
  if (!itemReq?.warehouse) {
    error.warehouse = 'Select Warehouse'
  }

  return error;
}

export function submitVaidation(outletItemReq: IOutletItemRequest) {
  let error: any = {};

  if (!outletItemReq?.outletItemDate) {
    error.outletItemDate = 'Enter Date'
  }
  if (!outletItemReq?.outletId) {
    error.outletId = 'Select Outlet'
  }
  if (!outletItemReq?.employeeId) {
    error.employeeId = 'Select Created By'
  }
  if(!outletItemReq?.outletItemRequestDetails?.length) {
    error.outletItemRequestDetails = 'Add Req Items to Grid'
  }

  return error;
}


