const currentYear = new Date().getFullYear();

export const yearsArray = Array.from({ length: 10 }, (_, index) => {
    const year = currentYear + index;
    return { key: year.toString(), value: year.toString() };
});
export const months = [
    { key: "january", value: "January", month: 1, },
    { key: "february", value: "February", month: 2, },
    { key: "march", value: "March", month: 3, },
    { key: "april", value: "April", month: 4, },
    { key: "may", value: "May", month: 5, },
    { key: "june", value: "June", month: 6, },
    { key: "july", value: "July", month: 7, },
    { key: "august", value: "August", month: 8, },
    { key: "september", value: "September", month: 9, },
    { key: "october", value: "October", month: 10, },
    { key: "november", value: "November", month: 11, },
    { key: "december", value: "December", month: 12, }
];