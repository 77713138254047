import { action, set, makeObservable, observable, runInAction } from 'mobx';
import UnitOfMeasurementDetails, {  IUnitOfMeasurement } from '../model/index';
import apiAgent from '../../../APIAgent';

class UnitOfMeasurementStore {
    unitOfMeasurementDetls =UnitOfMeasurementDetails;
    @observable unitOfMeasurementList= new Map();
    isLoading = true;
    @observable unitOfMeasurementLists=new Map();
    @observable entryNo= new Map();
    @observable UnitDetls = new Map();
    @observable isEdit=false;
    @observable UnitOfMeasurementStatus = new Map();

    //status = string;
    constructor(){
        makeObservable(this);
    }

     saveUnitOfMeasurement = async (unitOfMeasurement: IUnitOfMeasurement) => {
        try {
            
           const status=  await apiAgent.unitOfMeasurement.saveUnitOfMeasurement(unitOfMeasurement);
            return status;          
          
        }
        catch (error) {
            console.log(error);
        }
    }
    getUnitOfMeasurement = async ()=>{
        try{
            const unitOfMeasurementData = await apiAgent.unitOfMeasurement.getUnitOfMeasurement();
            this.unitOfMeasurementList = unitOfMeasurementData;
            runInAction(()=>{
                this.unitOfMeasurementList = unitOfMeasurementData;
                this.isLoading=false;
            })
            return unitOfMeasurementData;
        }
        catch(error){
            console.log(error);
        }
    }
    updateUnitOfMeasurement = async (unitOfMeasurement: IUnitOfMeasurement) => {
        try {      
        const status=  await apiAgent.unitOfMeasurement.updateUnitOfMeasurement(unitOfMeasurement);     
        runInAction(() => {
        this.UnitOfMeasurementStatus = status;
        });

        return this.UnitOfMeasurementStatus;
        }
        catch (error) {
            console.log(error);
        }
    }
    deleteUnitOfMeasurement = async (unitId) => {
        try {      
        const status=  await apiAgent.unitOfMeasurement.deleteteUnitOfMeasurement(unitId);     
       return status;
        }
        catch (error) {
            console.log(error);
        }
    }

    UnitDetails  = async (unitId:Number)=> {
        
        try {
        const unitData= await apiAgent.unitOfMeasurement.UnitDetails(unitId);
            
            runInAction(() => {
                this.UnitDetls = unitData;
                
            });
            return this.UnitDetls;
        }
        catch (error) {
            console.log(error);
        }
    }
    getUnitSearch  = async (SearchOptions)=> {
        try {
          const unitOfMeasurementData=await apiAgent.unitOfMeasurement.getUnitSearch(SearchOptions);
            this.unitOfMeasurementList = unitOfMeasurementData;
            
            runInAction(() => {
                this.unitOfMeasurementList = unitOfMeasurementData;
                
            })
            return this.unitOfMeasurementList;
        
         
        }
        catch (error) {
            console.log(error);
        }
    }
    
    

@action setUnitOfMeasurement = (state:IUnitOfMeasurement) =>{
    this.unitOfMeasurementList.set(0,state);
}
@action setUnitOfMeasurementList = (state:IUnitOfMeasurement[]) =>{
    set(state,this.unitOfMeasurementList);
    return this.unitOfMeasurementList;
}
}
export default UnitOfMeasurementStore;