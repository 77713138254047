import { IISalesOrder, IISalesOrderDet } from "../model";

export const saveValidation = (salesOrderMaster: IISalesOrder) => {
    let error: any = {}
    if (!salesOrderMaster?.sOrdMCustID) {
        error.sOrdMCustID = 'Select Customer'
    }
    if (Object?.keys(error)?.length !== 0) {
        error.despatchto = "Select Despatch to"
    }
    if (!salesOrderMaster?.salesorderdet?.length) {
        error.salesorderdet = 'Add Item to Grid'
    }
    return error;
}

export function addItemValidation(salesOrderMaster: IISalesOrder, salesOrderDet: IISalesOrderDet) {
    let error: any = {}
    if (!salesOrderMaster?.sOrdMCustID) {
        error.sOrdMCustID = 'Select Customer'
    }
    if (Object?.keys(error)?.length !== 0) {
        error.despatchto = "Select Despatch to"
    }
    if (!salesOrderDet?.sOrdDItemID) {
        error.sOrdDItemID = 'Select Item name'
    }
    return error
}


export function addLedgerValidation(ledger) {
    let error: any = {};

    if (!ledger.saleOrdLedgerId) {
        error.saleOrdLedgerId = "Select Ledger Name"
    }
    if (!ledger.saleOrdLedgerAmount) {
        error.saleOrdLedgerAmount = "Enter Amount"
    }

    return error;
}